import { Col, Row, Timeline, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { isCurrent, isPast } from "features/Order/helper";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import useModalTrackingPurchaseOrder from "./useModalTrackingOrder";

const ModalTrackingPurchaseOrder = ({ data }) => {
  const { timeLine } = useModalTrackingPurchaseOrder({ fullEvents: data?.fullEvents, status: data?.status });
  return (
    <CustomModal
      customComponent={
        <Typography.Link italic style={{ fontSize: "12px" }}>
          {t("order.orderDetail.seeDetails")}
        </Typography.Link>
      }
      centered={true}
      footer={false}
      hideConfirmButton={true}
      cancelButtonLabel={t("common.close")}
      width="50%"
      closable={false}
      className="modal-status-order-by-export-serial"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row
            gutter={16}
            style={{
              background: "#E7F0FF",
              padding: "16px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Typography.Text strong>
              {"Trạng thái đơn mua hàng"} <Typography.Link strong>{data?.code}</Typography.Link>
            </Typography.Text>
          </Row>
        </Col>

        <Col span={24}>
          <Timeline mode="left">
            {timeLine?.map(({ createdAt, name, state, createdBy, dot }, index) => (
              <Timeline.Item
                key={index}
                label={
                  isPast(state) && (
                    <Row gutter={16}>
                      <Col span={12} offset={11}>
                        <Typography.Text style={{ color: "#052987" }}>{formatDateTime(createdAt)}</Typography.Text>
                      </Col>
                    </Row>
                  )
                }
                dot={dot}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text style={{ color: `${isCurrent(state) ? "#2246DC" : "#052987"}` }}>
                      {name}
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    {isPast(state) && <Typography.Text italic>Bởi: {createdBy?.fullname}</Typography.Text>}
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ModalTrackingPurchaseOrder;
