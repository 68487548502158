import { Card, Col, Collapse, Divider, Row, Space, Tooltip, Typography } from "antd";
import "./index.scss";
import { formatCurrency, formatPadStart } from "utils/helperFuncs";
import { t } from "i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { groupBy } from "lodash";
import { COMBO_CAMPAIGN, PRODUCT_TYPE } from "features/Campaign/constants";
import ModalListProduct from "./ModalListProduct";
import CustomTable from "components/CustomTable";
import { Link } from "react-router-dom";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import PopupShowAllProduct from "../PopupShowAllProduct";

const InfoDetail = ({ data }) => {
  const { Panel } = Collapse;
  const promotionCampaignTierGroups = data?.promotionCampaignTierGroups;

  const groupedData = groupBy(promotionCampaignTierGroups, "type");

  const discountMoneyArray = groupedData[COMBO_CAMPAIGN.DISCOUNT_MONEY] || [];
  const fixedPriceArray = groupedData[COMBO_CAMPAIGN.FIXED_PRICE] || [];
  const giftArray = groupedData[COMBO_CAMPAIGN.GIFT] || [];

  const renderComboItems = (tierGroups, indexTierGroup, tierGroupsType) => {
    const checkHeaderTitle = (type) => {
      switch (type) {
        case COMBO_CAMPAIGN.DISCOUNT_MONEY:
          return "campaign.detailPromo.comboDiscount";
        case COMBO_CAMPAIGN.FIXED_PRICE:
          return "campaign.detailPromo.comboFixedPrice";
        case COMBO_CAMPAIGN.GIFT:
          return "campaign.detailPromo.comboGift";

        default:
          break;
      }
    };

    const ConditionProducts = ({ conditionProducts }) => {
      return (
        <Card>
          <Space direction="vertical" style={{ display: "flex" }}>
            <Divider
              orientation="left"
              plain
              style={{ margin: 0 }}
              children={<Typography.Text>{t("campaign.combo.chooseProductAndQtyCombo")}</Typography.Text>}
            />
            {conditionProducts?.map((itemPro, indexPro) => {
              const listProduct = itemPro?.conditionPromotionProducts?.map((item) => ({
                code: item?.product?.code,
                id: item?.product?.id,
                name: item?.product?.name,
              }));
              return (
                <Row key={indexPro} gutter={[16, 8]} className="info-detail-row">
                  <Col span={14}>
                    <Row style={{ alignItems: "center", flexWrap: "nowrap" }} gutter={16}>
                      <Col span={4}>
                        <Typography.Text strong>
                          [{t("campaign.combo.product")} {indexPro + 1}]
                        </Typography.Text>
                      </Col>
                      <Col className="info-detail-product" style={{ width: "400px" }}>
                        <ModalListProduct indexPro={indexPro} itemPro={itemPro} listProduct={listProduct} />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Space style={{ justifyContent: "center" }}>
                      <Typography.Text
                        ellipsis={{
                          suffix: (
                            <Tooltip title={t("campaign.detailPromo.quantityProduct")}>
                              <QuestionCircleOutlined />
                            </Tooltip>
                          ),
                        }}
                        strong
                      >
                        {t("campaign.combo.quantity")}{" "}
                      </Typography.Text>
                      <Typography.Text>{itemPro?.quantity}</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              );
            })}
          </Space>
        </Card>
      );
    };

    const ConditionGifts = ({ conditionGifts }) => {
      return (
        <Card>
          <Space direction="vertical" style={{ display: "flex" }}>
            <Divider
              orientation="left"
              plain
              style={{ margin: 0 }}
              children={<Typography.Text>{t("campaign.combo.chooseGiftAndQtyGift")}</Typography.Text>}
            />
            {conditionGifts?.map((itemPro, indexPro) => {
              const columns = [
                {
                  title: (
                    <PopupShowAllProduct
                      title={t("campaign.detailPromo.listGiftCampaign")}
                      data={itemPro}
                      index={indexPro}
                      customComponent={
                        <Typography.Link italic underline>
                          {t("campaign.combo.giftCode")}({formatPadStart(itemPro?.conditionPromotionProducts?.length)})
                        </Typography.Link>
                      }
                    />
                  ),
                  align: "left",
                  width: "50%",
                  render: (_, record, index) => {
                    return (
                      <Space direction="vertical">
                        <Link key={index} to={`/product/detail/${record?.product?.id}`} target="_blank">
                          <Typography.Link strong>{record?.product.code}</Typography.Link>
                        </Link>
                        <CustomTextHiddenLine text={record?.product?.name} line={1} />
                      </Space>
                    );
                  },
                },
                {
                  title: <Typography.Text strong>{t("campaign.combo.giftBudget")}</Typography.Text>,
                  align: "center",
                  render: (_, record, index) => record?.budgetQuantity,
                },
                {
                  title: <Typography.Text strong>{t("campaign.combo.warrantyDate")}</Typography.Text>,
                  align: "center",
                  render: (_, record, index) => record?.productWarranty,
                },
              ];
              return (
                <Row key={indexPro} gutter={[16, 8]} className="info-detail-row">
                  <Col span={14}>
                    <Row style={{ alignItems: "center", flexWrap: "nowrap" }} gutter={16}>
                      <Col span={4}>
                        <Typography.Text strong>
                          [{t("campaign.combo.gift")} {indexPro + 1}]
                        </Typography.Text>
                      </Col>
                      <Col>
                        <div className="info-detail-product">
                          <CustomTable
                            columns={columns}
                            dataSource={itemPro?.conditionPromotionProducts}
                            scroll={{
                              y: 240,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Space style={{ justifyContent: "center" }}>
                      <Typography.Text
                        ellipsis={{
                          suffix: (
                            <Tooltip title={t("campaign.detailPromo.quantityGift")}>
                              <QuestionCircleOutlined />
                            </Tooltip>
                          ),
                        }}
                        strong
                      >
                        {t("campaign.combo.quantity")}{" "}
                      </Typography.Text>
                      <Typography.Text>{itemPro?.quantity}</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              );
            })}
          </Space>
        </Card>
      );
    };

    return (
      <Collapse key={indexTierGroup} defaultActiveKey={[indexTierGroup]}>
        <Panel header={`${t(checkHeaderTitle(tierGroupsType))} ${indexTierGroup + 1}`}>
          <Space direction="vertical" style={{ width: "100%", gap: "20px", padding: "16px" }}>
            {tierGroupsType === COMBO_CAMPAIGN.FIXED_PRICE && (
              <Card title={t("campaign.combo.comboCondition")}>
                <Space
                  className="space-card"
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Row>
                    <Col span={12}>
                      <Typography.Text strong>{t("campaign.combo.maxNumberOfComboPerDealer")}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      {tierGroups?.maxQuantityPerSeller
                        ? tierGroups?.maxQuantityPerSeller
                        : t("campaign.combo.noLimit")}
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      span={12}
                    >
                      <Typography.Text strong>{t("campaign.combo.fixedTotalProductQty")}</Typography.Text>
                      <Tooltip title={t("campaign.combo.tooltipFixedTotalProductQty")}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Col>
                    <Col span={12}>{tierGroups?.budgetProductQuantity}</Col>
                  </Row>
                </Space>
              </Card>
            )}
            {tierGroups?.promotionCampaignTiers?.map((itemCombo, indexCombo) => {
              const conditionProducts = itemCombo?.conditionProductGroups.filter(
                (item) => item.type === PRODUCT_TYPE.CONDITION_PRODUCT
              );
              const conditionGifts = itemCombo?.conditionProductGroups.filter(
                (item) => item.type === PRODUCT_TYPE.GIFT_PRODUCT
              );

              return (
                <Collapse defaultActiveKey={["1"]} expandIconPosition="left" key={`comboCollapse-${indexCombo}`}>
                  <Panel header={`${t("campaign.detailPromo.preferentialLevel")} ${indexCombo + 1}`} key="1">
                    {tierGroupsType === COMBO_CAMPAIGN.DISCOUNT_MONEY && (
                      <Row>
                        <Col span={12} className="collapse-content-left">
                          <Space direction="vertical" style={{ display: "flex" }}>
                            {tierGroupsType === COMBO_CAMPAIGN.DISCOUNT_MONEY && (
                              <Divider orientation="left">{t("campaign.combo.chooseProductAndQtyCombo")}</Divider>
                            )}

                            <Space direction="vertical" style={{ display: "flex" }}>
                              {itemCombo?.conditionProductGroups?.map((itemPro, indexPro) => {
                                const listProduct = itemPro?.conditionPromotionProducts?.map((item) => ({
                                  code: item?.product?.code,
                                  id: item?.product?.id,
                                  name: item?.product?.name,
                                }));

                                return (
                                  <Row key={indexPro} className="info-detail-row">
                                    <Col
                                      span={5}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Row
                                        style={{
                                          justifyContent: "center",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        [{t("campaign.combo.product")} {indexPro + 1}]
                                      </Row>
                                    </Col>
                                    <Col span={11} className="info-detail-product">
                                      <ModalListProduct
                                        indexPro={indexPro}
                                        itemPro={itemPro}
                                        listProduct={listProduct}
                                      />
                                    </Col>
                                    <Col
                                      span={8}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Row style={{ justifyContent: "center" }}>
                                        <Space>
                                          <Typography.Text strong>{t("campaign.combo.quantity")}</Typography.Text>
                                          <Tooltip title={t("campaign.combo.tooltipQtyFixed")}>
                                            <QuestionCircleOutlined />
                                          </Tooltip>
                                          <Typography.Text>{itemPro?.quantity}</Typography.Text>
                                        </Space>
                                      </Row>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Space>
                          </Space>
                        </Col>
                        <Col span={12} className="collapse-content-right">
                          {tierGroupsType === COMBO_CAMPAIGN.DISCOUNT_MONEY && (
                            <Divider orientation="left">{t("campaign.combo.comboCondition")}</Divider>
                          )}
                          <Space direction="vertical" style={{ display: "flex", marginBottom: "16px" }}>
                            <Row gutter={[16, 8]} style={{ marginTop: "16px" }}>
                              <Col span={10}>
                                <Typography.Text strong>{t("campaign.detailPromo.minQuantity")}</Typography.Text>{" "}
                                <Tooltip title={t("campaign.combo.tooltipQtyComboEstimate")}>
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </Col>
                              <Col span={1}></Col>
                              <Col span={13}>
                                <Typography.Text>{itemCombo?.minQuantity}</Typography.Text>
                              </Col>
                            </Row>
                            <Row gutter={[16, 8]}>
                              <Col span={10}>
                                <Typography.Text strong>{t("campaign.combo.typeDiscount")}</Typography.Text>{" "}
                                <Tooltip title={t("campaign.combo.tooltipTypeDiscount")}>
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </Col>
                              <Col span={1}></Col>
                              <Col span={13}>
                                <Typography.Text>
                                  {itemCombo?.discountType === COMBO_CAMPAIGN.DISCOUNT_MONEY
                                    ? formatCurrency(itemCombo?.discountAmount)
                                    : itemCombo?.discountAmount + "%"}
                                </Typography.Text>
                              </Col>
                            </Row>
                            <Row gutter={[16, 8]}>
                              <Col span={10}>
                                <Typography.Text strong>
                                  {t("campaign.combo.maxNumberOfComboPerDealer")}
                                </Typography.Text>
                              </Col>
                              <Col span={1}></Col>
                              <Col span={13}>
                                <Typography.Text>
                                  {itemCombo?.maxQuantityPerSeller
                                    ? itemCombo?.maxQuantityPerSeller
                                    : t("campaign.combo.noLimit")}
                                </Typography.Text>
                              </Col>
                            </Row>
                            <Row gutter={[16, 8]}>
                              <Col span={10}>
                                <Typography.Text strong>{t("campaign.detailPromo.sumByMoney")}</Typography.Text>
                              </Col>
                              <Col span={1}></Col>
                              <Col span={13}>
                                <Typography.Text>{formatCurrency(itemCombo?.budgetAmount)}</Typography.Text>
                              </Col>
                            </Row>
                          </Space>
                        </Col>
                      </Row>
                    )}
                    {tierGroupsType === COMBO_CAMPAIGN.FIXED_PRICE && (
                      <Row gutter={[16, 16]} style={{ alignItems: "center", padding: "16px 32px" }}>
                        <Col span={24} xl={8}>
                          <Space className="space-product" direction="horizontal" style={{ width: "100%" }}>
                            <Typography.Text strong>{t("campaign.combo.applyProduct")}</Typography.Text>
                            <Col className="info-detail-product">
                              {itemCombo?.conditionProductGroups?.map((itemPro, indexPro) => {
                                const listProduct = itemPro?.conditionPromotionProducts?.map((item) => ({
                                  code: item?.product?.code,
                                  id: item?.product?.id,
                                  name: item?.product?.name,
                                }));
                                return (
                                  <ModalListProduct indexPro={indexPro} itemPro={itemPro} listProduct={listProduct} />
                                );
                              })}
                            </Col>
                          </Space>
                        </Col>
                        <Col span={24} xl={8}>
                          <Space>
                            <Typography.Text strong>{t("campaign.combo.quantity")}</Typography.Text>
                            <Tooltip title={t("campaign.combo.tooltipQtyFixed")}>
                              <QuestionCircleOutlined />
                            </Tooltip>
                            {itemCombo?.conditionProductGroups?.map((itemPro, indexPro) => (
                              <Typography.Text>{itemPro?.quantity}</Typography.Text>
                            ))}
                          </Space>
                        </Col>
                        <Col span={24} xl={8}>
                          <Space>
                            <Typography.Text strong>{t("campaign.combo.comboOfPrice")}</Typography.Text>
                            <Typography.Text>{formatCurrency(itemCombo?.fixedComboPrice)}</Typography.Text>
                          </Space>
                        </Col>
                      </Row>
                    )}
                    {tierGroupsType === COMBO_CAMPAIGN.GIFT && (
                      <Row gutter={[16, 16]} style={{ alignItems: "center", padding: "16px" }}>
                        <Col span={24}>
                          <Card>
                            <Space direction="vertical" style={{ display: "flex" }}>
                              <Divider
                                orientation="left"
                                plain
                                style={{ margin: 0 }}
                                children={<Typography.Text>{t("campaign.combo.comboCondition")}</Typography.Text>}
                              />
                              <Row gutter={[50]}>
                                <Col>
                                  <Typography.Text strong>
                                    {t("campaign.combo.maxNumberOfComboPerDealer")}
                                  </Typography.Text>
                                </Col>

                                <Col>
                                  <Typography.Text>
                                    {itemCombo?.maxQuantityPerSeller
                                      ? itemCombo?.maxQuantityPerSeller
                                      : t("campaign.combo.noLimit")}
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </Space>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <ConditionProducts conditionProducts={conditionProducts} />
                        </Col>
                        <Col span={24}>
                          <ConditionGifts conditionGifts={conditionGifts} />
                        </Col>
                      </Row>
                    )}
                  </Panel>
                </Collapse>
              );
            })}
          </Space>
        </Panel>
      </Collapse>
    );
  };

  return (
    <Space className={"info-detail"} direction="vertical" style={{ width: "100%", gap: "20px" }}>
      {discountMoneyArray?.map((tierGroups, indexTierGroup) =>
        renderComboItems(tierGroups, indexTierGroup, tierGroups?.type)
      )}
      {giftArray?.map((tierGroups, indexTierGroup) => renderComboItems(tierGroups, indexTierGroup, tierGroups?.type))}
      {fixedPriceArray?.map((tierGroups, indexTierGroup) =>
        renderComboItems(tierGroups, indexTierGroup, tierGroups?.type)
      )}
    </Space>
  );
};

export default InfoDetail;
