import { Form } from "antd";
import { useUpdateSellerContact } from "hooks/seller";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

const useModalUpdateAddress = ({ sellerID, refetch, contact }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { handleUpdateContact } = useUpdateSellerContact();

  let initialValues = { ...contact };

  const handleValuesChange = async (changedValues, allValues) => {
    const values = await form.getFieldsValue();
    if (changedValues?.cityID) {
      form.setFieldsValue({
        ...values,
        districtID: null,
        wardID: null,
      });
    }
    if (changedValues?.districtID) {
      form.setFieldsValue({
        ...values,
        wardID: null,
      });
    }
  };

  const handleOpen = () => {
    form.setFieldsValue({ ...contact });
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  async function updateContact() {
    try {
      const values = await form.validateFields();
      await handleUpdateContact({
        id: contact?.id,
        contact: {
          ...values,
          email: values?.email || null,
          sellerID: sellerID,
        },
      })
        .then(() => {
          notify.success({
            message: t("seller.componentAddressList.messageUpdateAddressSuccess"),
          });
          setVisible(false);
          form.resetFields();
          refetch();
        })
        .catch(() => {
          notify.error({
            message: t("seller.componentAddressList.messageUpdateAddressError"),
          });
          setVisible(true);
        });
    } catch (err) {
      console.info(err);
    }
  }

  return { updateContact, initialValues, visible, handleCancel, form, handleOpen, handleValuesChange };
};

export default useModalUpdateAddress;
