import { PlusOutlined } from "@ant-design/icons";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { PRODUCT_INPUT_TYPE } from "pages/App/Product/ProductGroup/conts";
import { getFile } from "utils/helperFuncs";
import styles from "./index.module.scss";

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  nameInput,
  options,
  rowIndex,
  children,
  initialValue,
  isDisableUpload,
  ...restProps
}) => {
  const uploadButton = (
    <div className={styles.uploadButton}>
      <PlusOutlined />
    </div>
  );

  const inputOptionsPrices = {
    min: 0,
    maxLength: 11,
  };

  const inputOptionsVat = {
    min: 0,
    max: 100,
    maxLength: 4,
  };

  const inputNode = () => {
    switch (inputType) {
      case PRODUCT_INPUT_TYPE.UPLOAD_IMAGE:
        return (
          <FormInput
            type={INPUT_TYPE.IMAGE_UPLOAD}
            formItemOptions={{
              getValueFromEvent: getFile,
              name: [rowIndex, ...nameInput],
              valuePropName: "fileList",
            }}
            inputOptions={{
              showUploadList: {
                showPreviewIcon: false,
              },
              className: "upload-image",
            }}
            inputChildren={isDisableUpload ? null : uploadButton}
          />
        );

      case PRODUCT_INPUT_TYPE.INPUT_PRODUCT_CODE:
        return <FormInput formItemOptions={{ name: [rowIndex, ...nameInput] }} />;

      case PRODUCT_INPUT_TYPE.INPUT_SHORT_CODE:
        return <FormInput formItemOptions={{ name: [rowIndex, ...nameInput] }} />;

      case PRODUCT_INPUT_TYPE.INPUT_CURRENCY:
        return (
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{ name: [rowIndex, ...nameInput] }}
            inputOptions={{ ...inputOptionsPrices }}
          />
        );

      case PRODUCT_INPUT_TYPE.INPUT_VAT:
        return (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            formItemOptions={{ name: [rowIndex, ...nameInput] }}
            inputOptions={{ ...inputOptionsVat }}
          />
        );

      case PRODUCT_INPUT_TYPE.SELECT:
        return (
          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{ name: [rowIndex, ...nameInput], initialValue: initialValue }}
            inputOptions={{ options: options }}
          />
        );

      default:
        return;
    }
  };
  return (
    <td {...restProps}>
      <div>{editing ? inputNode() : children}</div>
    </td>
  );
};

export default EditableCell;
