import React, { useState } from "react";
import { FilterLoadMore } from "components/CustomFilterMenu";
import { useGetSellerCreateCart } from "hooks/seller";

function FilterSellerIDs({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData } = useGetSellerCreateCart(params);
  const options = data?.map((item) => {
    return { label: item?.fullName, value: item?.id };
  });

  const handleSearch = (value) => {
    setParams({
      ...params,
      filters: { ...params?.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return (
    <FilterLoadMore
      params={params}
      data={options || []}
      onLoadMoreData={onLoadMoreData}
      onFilter={(value) => onFilter(value, "sellerIDs")}
      onSearchTextChange={handleSearch}
      hasMore={hasMoreData}
    />
  );
}

export default FilterSellerIDs;
