export const getPercentBar = (total) => {
  const percentBar = {
    all: total?.all,
    process: (total?.waitingForCheck / total?.all) * 100,
    waitingForProcessing:
      ((total?.checking +
        total?.waitingForDistribute +
        total?.repairing +
        total?.waitingForReturn +
        total?.delivering) /
        total?.all) *
      100,
    completed: (total?.completed / total?.all) * 100,
    canceled: (total?.canceled / total?.all) * 100,
  };
  return percentBar;
};

export const truncateAddDots = (string, limit = 40) => {
  var dots = "...";
  if (string?.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }

  return string;
};
