import { STATUS_ORDER } from "config/constants";
import { useTranslation } from "react-i18next";

import { CAMPAIGN_ACTIONS, PROMOTION_CAMPAIGN_STATUS, PROMOTION_CAMPAIGN_TYPE } from "./constants";

export const useCampaign = () => {
  const { t } = useTranslation();

  const TEXT_STATUS_CAMPAIGN = {
    UPCOMING: <span style={{ color: "#EF9351" }}>{t("campaign.upcoming")}</span>,
    ON_GOING: <span style={{ color: "#32BF46" }}>{t("campaign.onGoing")}</span>,
    RECONCILING: <span>{t("campaign.reconciling")}</span>,
    ENDED: <span>{t("campaign.ended")}</span>,
  };

  const STATUS_CAMPAIGN = [
    { label: t("campaign.upcoming"), value: PROMOTION_CAMPAIGN_STATUS.UPCOMING },
    { label: t("campaign.onGoing"), value: PROMOTION_CAMPAIGN_STATUS.ON_GOING },
    { label: t("campaign.reconciling"), value: PROMOTION_CAMPAIGN_STATUS.RECONCILING },
    { label: t("campaign.ended"), value: PROMOTION_CAMPAIGN_STATUS.ENDED },
  ];

  const TEXT_TYPES_CAMPAIGN = {
    TOP_SALES: t("campaign.type.top"),
    COMBO_PROMO: t("campaign.type.promotionByCombo"),
    PRODUCT_PROMO: t("campaign.type.promotionByProduct"),
  };

  const TYPE_CAMPAIGN = [
    // { label: TEXT_TYPES_CAMPAIGN.TOP_SALES, value: PROMOTION_CAMPAIGN_TYPE.TOP_SALES },
    {
      label: TEXT_TYPES_CAMPAIGN.COMBO_PROMO,
      value: PROMOTION_CAMPAIGN_TYPE.COMBO_PROMO,
    },
    {
      label: TEXT_TYPES_CAMPAIGN.PRODUCT_PROMO,
      value: PROMOTION_CAMPAIGN_TYPE.PRODUCT_PROMO,
    },
  ];

  const ACTIONS_CAMPAIGN_TAG = {
    ACTIVE: t("campaign.on"),
    DISABLED: t("campaign.off"),
  };
  const ACTIONS_CAMPAIGN = [
    { label: ACTIONS_CAMPAIGN_TAG.ACTIVE, value: CAMPAIGN_ACTIONS.ACTIVE },
    { label: ACTIONS_CAMPAIGN_TAG.DISABLED, value: CAMPAIGN_ACTIONS.DISABLED },
  ];

  const ORDER_STATUS_FILTER = [
    { label: t("order.orderStatus.waitingForApprovalStock"), value: STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK },
    {
      label: t("order.orderStatus.waitingForEnoughStock"),
      value: STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK,
    },
    { label: t("order.orderStatus.waitingForPayment"), value: STATUS_ORDER.WAITING_FOR_PAYMENT },
    {
      label: t("order.orderStatus.waitingForExporting"),
      value: STATUS_ORDER.WAITING_FOR_EXPORTING,
    },
    { label: t("order.orderStatus.waitingForAssembly"), value: STATUS_ORDER.WAITING_FOR_ASSEMBLY },
    {
      label: t("order.orderStatus.waitingForPackaging"),
      value: STATUS_ORDER.WAITING_FOR_PACKAGING,
    },
    { label: t("order.orderStatus.waitingForDelivery"), value: STATUS_ORDER.WAITING_FOR_SHIPPING },
    { label: t("order.orderStatus.delivering"), value: STATUS_ORDER.DELIVERING },
    { label: t("order.orderStatus.completed"), value: STATUS_ORDER.COMPLETED },
    { label: t("order.orderStatus.cancel"), value: STATUS_ORDER.CANCELED },
  ];

  return {
    TEXT_STATUS_CAMPAIGN,
    TEXT_TYPES_CAMPAIGN,
    TYPE_CAMPAIGN,
    ACTIONS_CAMPAIGN,
    STATUS_CAMPAIGN,
    ACTIONS_CAMPAIGN_TAG,
    ORDER_STATUS_FILTER,
  };
};
