import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { INPUT_TYPE, REGEX } from "config/constants";
import { optionsCauseReject, styleHeaderCard, styleTitleCard } from "features/Purchase/constant";
import { useConfirmRejectCheckQualityPurchaseOrder } from "hooks/purchase";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFile, notify } from "utils/helperFuncs";
import "./index.scss";

export const ButtonReject = ({ record }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [receivedQuantity, setReceivedQuantity] = useState([]);
  const [notQualifiedQuantity, setNotQualifiedQuantity] = useState([]);

  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const { handleConfirmRejectCheckQualityPurchaseOrder } = useConfirmRejectCheckQualityPurchaseOrder();

  const onReject = async () => {
    try {
      const values = await form.validateFields(null, { force: true });
      setLoading(true);

      await handleConfirmRejectCheckQualityPurchaseOrder({
        id: record?.id,
        reasonInput: {
          reason: values?.reason,
          fileURLs: values?.fileURLs,
        },
        items: values?.items,
      })
        .then(() => {
          setVisible(false);
          setLoading(false);
          notify.success({
            message: "Từ chối nhập hàng thành công",
          });
        })
        .catch((error) => {
          setLoading(false);
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: <span className="title-column">{t("common.index")}</span>,
      align: "center",
      width: "5%",
      render: (_, _record, index) => index + 1,
    },
    {
      title: <span>THÔNG SỐ</span>,
      align: "start",
      width: "25%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Link strong>
              <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
                <Typography.Link strong>{record?.product?.code}</Typography.Link>
              </Link>
            </Typography.Link>
            <CustomTextHiddenLine line={3} text={record?.product?.name || "--"} />
          </div>
        );
      },
    },
    {
      title: <span>SỐ LƯỢNG ĐẶT HÀNG</span>,
      align: "center",
      width: "10%",
      render: (_, record) => {
        return <span>{record?.quantity}</span>;
      },
    },
    {
      title: <span>SỐ LƯỢNG THỰC GIAO</span>,
      align: "center",
      width: "15%",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "receivedQuantity"],
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập số lượng thực giao",
                  },
                  {
                    pattern: REGEX.NUMBER,
                  },
                ],
              },
              inputOptions: {
                maxLength: 4,
                min: 0,
                style: { width: "80px" },
                className: "form-text-center form-border-radius-right",
                placeholder: "0",
              },
            }}
          />
        );
      },
    },
    {
      title: <span>SỐ LƯỢNG KHÔNG ĐỦ ĐIỀU KIỆN</span>,
      align: "center",
      width: "15%",
      render: (_, record, index) => {
        const receivedQuantityFieldName = [index, "receivedQuantity"];
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "notQualifiedQuantity"],
                dependencies: [receivedQuantityFieldName],
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập số lượng không đủ điều kiện",
                  },
                  {
                    pattern: REGEX.NUMBER,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const inputValue = parseFloat(value);
                      if (!isNaN(inputValue) && inputValue > (receivedQuantity[index] || 0)) {
                        return Promise.reject("Số lượng không đủ điều kiện ≤ số lượng thực giao");
                      }
                      return Promise.resolve();
                    },
                  }),
                ],
              },
              inputOptions: {
                maxLength: 4,
                min: 0,
                style: { width: "80px" },
                className: "form-text-center form-border-radius-right",
                placeholder: "0",
              },
            }}
          />
        );
      },
    },
    {
      title: <span>SỐ LƯỢNG ĐỦ ĐIỀU KIỆN</span>,
      align: "center",
      width: "10%",
      render: (_, record, index) => {
        const currentNotQualified = notQualifiedQuantity && notQualifiedQuantity[index];
        const currentQuantity = receivedQuantity && receivedQuantity[index];
        const result =
          currentNotQualified >= 0 && currentQuantity > currentNotQualified ? currentQuantity - currentNotQualified : 0;
        return <Typography.Text>{result}</Typography.Text>;
      },
    },
    {
      title: <span>LÝ DO TỪ CHỐI</span>,
      align: "start",
      width: "20%",
      maxWidth: "20%",
      render: (_, record, index) => {
        return (
          <div>
            <FormInput
              {...{
                type: INPUT_TYPE.SELECT,
                formItemOptions: {
                  name: [index, "causes"],
                  rules: [
                    {
                      required: true,
                      message: "Chọn lý do từ chối",
                    },
                  ],
                },
                inputOptions: {
                  options: optionsCauseReject,
                  maxTagCount: 1,
                  dropdownMatchSelectWidth: false,
                  placeholder: "Chọn lý do từ chối",
                  mode: "multiple",
                  style: {
                    width: "100%",
                    fontWeight: "500",
                  },
                },
              }}
            />
          </div>
        );
      },
    },
  ];
  const initialValues = {
    items: [],
    fileURLs: null,
    reason: null,
  };

  const handleValuesChange = (changedValue, allValues) => {
    const calculatedReceivedQuantity = allValues?.items?.map((item) => {
      return item.receivedQuantity;
    });
    const calculatedNotQualifiedQuantity = allValues?.items?.map((item) => {
      return item.notQualifiedQuantity;
    });
    setReceivedQuantity(calculatedReceivedQuantity);
    setNotQualifiedQuantity(calculatedNotQualifiedQuantity);
  };

  useEffect(() => {
    const formData = form.getFieldsValue();
    form.setFieldsValue({
      ...formData,
      items: record?.items?.map((item) => ({
        purchaseOrderItemID: item?.id,
      })),
    });
  }, [record]);

  return (
    <Form form={form} name="validate_other" initialValues={initialValues} onValuesChange={handleValuesChange}>
      <CustomModal
        {...{
          width: "65%",
          title: (
            <span style={{ fontSize: "18px" }}>
              <Typography.Text strong>{"Từ chối đơn mua hàng"}</Typography.Text>{" "}
            </span>
          ),
          customComponent: (
            <Button type="secondary" loading={loading}>
              {"Từ chối"}
            </Button>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              <Button type="secondary" htmlType="submit" onClick={handleCancel} loading={loading}>
                {"Huỷ bỏ"}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onReject} loading={loading}>
                {"Xác nhận"}
              </Button>
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
          className: "button-reject-check-info-purchase-order",
        }}
      >
        <div className="form-check-info-purchase-order">
          <CustomCard
            headStyle={styleHeaderCard}
            title={
              <Typography.Text style={styleTitleCard} strong>
                {"DANH SÁCH SẢN PHẨM"}
              </Typography.Text>
            }
            content={
              <>
                <div className="message-extra-reject">
                  <ExclamationCircleOutlined /> Lưu ý: Sau khi bạn từ chối, đơn mua hàng sẽ chuyển sang trạng thái “Đang
                  xử lý” để nhân viên mua hàng tiếp tục xử lý đơn mua hàng
                </div>
                <Form.List name="items">
                  {(items, { remove }) => {
                    return <CustomTable columns={columns} dataSource={record?.items} />;
                  }}
                </Form.List>
              </>
            }
          />
          <Row gutter={[16]} className="form-bottom">
            <Col span={13}>
              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{
                  label: <b>{"Lý do từ chối"}</b>,
                  labelCol: { span: 24, offset: 0 },
                  name: "reason",
                }}
                inputOptions={{
                  rows: 4,
                  placeholder: "Nhập lý do từ chối",
                }}
              />
            </Col>
            <Col span={11}>
              <Form.Item
                labelCol={{ span: 24, offset: 0 }}
                name={"fileURLs"}
                getValueFromEvent={getFile}
                valuePropName={"fileList"}
                label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
                extra={t("warrantyRequest.create.noteAttack")}
              >
                <UploadFiles />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </CustomModal>
    </Form>
  );
};
