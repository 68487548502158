import { Card, Col, Form, Row, Typography } from "antd";
import CustomButton from "components/CustomButton";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import { IMPORT_REQUEST_TYPE, optionSalesReturnType } from "../../constant";
import AddInfo from "./components/AddInfo";
import InfoOrder from "./components/InfoOrder";
import InfoProvider from "./components/InfoProvider";
import PaymentInfo from "./components/PaymentInfo";
import SelectOrder from "./components/SelectOrder";
import SelectProduct from "./components/SelectProduct";
import TableProduct from "./components/Table";
import { TitleCard } from "./components/TitleCard";
import "./index.scss";
import useCreate from "./useCreate";

function CreateSalesReturn() {
  const {
    form,
    sellers,
    dataTableProduct,
    handleGetProduct,
    handleGetOrder,
    handleFinish,
    initialValues,
    handleChangeInfoSeller,
    sellerInfo,
    loadMoreSeller,
    hasMoreSeller,
    onSearchSeller,
    history,
    loadingCreate,
    handleDeleteProduct,
    setSumPrice,
    setCurrencyFirst,
    currencyFirst,
    sumPrice,
    handleCreateClick,
    formData,
    handleKeyPress,
    optionSelectProduct,
    orders,
    orderInfo,
    onSearchOrder,
    hasMoreOrder,
    loadMoreOrder,
    loadingOrders,
    handleChangeTypeSalesReturn,
    typeSalesReturn,
    isDisabledSelectProduct,
    handleChangeInfoOrder,
    visiblePopupChangeOrder,
    handleClosePopupChangeOrder,
    handleOkPopupChangeOrder,
    paramsOrderSeller,
    setParamsOrderSeller,
    visiblePopupChangeSeller,
    handleClosePopupChangeSeller,
    handleOkPopupChangeSeller,
    valueSelectOrder,
    setValueSelectOrder,
    loadingSeller,
    sellerMemory,
    orderMemory,
  } = useCreate();

  return (
    <div className="create-recommend-import">
      <Form layout="horizontal" form={form} onFinish={handleFinish} initialValues={initialValues}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("warehouse.salesReturn.titleCreateSalesReturn")}
              routes={[
                { path: "#", name: t("warehouse.salesReturn.sales") },
                { path: "/sales-return", name: t("warehouse.salesReturn.salesReturn") },
                { path: "/", name: t("warehouse.salesReturn.titleCreateSalesReturn") },
              ]}
            />
          </Col>

          <Col span={24} className="form-head">
            <Row gutter={[16, 8]}>
              <Col span={6}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("warehouse.create.typeRecommend")}</Typography.Text>,
                    name: "type",
                  }}
                  inputOptions={{
                    options: optionSalesReturnType,
                    defaultValue: typeSalesReturn,
                    onChange: (event) => handleChangeTypeSalesReturn(event),
                  }}
                />
              </Col>
              <Col span={8}></Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={10} className="card-container-info">
                <Card title={<TitleCard title={t("warehouse.salesReturn.infoSeller")} />} style={{ flexGrow: 1 }}>
                  {typeSalesReturn === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY ? (
                    <InfoProvider
                      sellers={sellers}
                      handleChangeInfoSeller={handleChangeInfoSeller}
                      sellerInfo={sellerInfo}
                      loadMoreSeller={loadMoreSeller}
                      hasMoreSeller={hasMoreSeller}
                      onSearchSeller={onSearchSeller}
                      visiblePopupChangeSeller={visiblePopupChangeSeller}
                      handleClosePopupChangeSeller={handleClosePopupChangeSeller}
                      handleOkPopupChangeSeller={handleOkPopupChangeSeller}
                      loadingSeller={loadingSeller}
                      sellerMemory={sellerMemory}
                    />
                  ) : (
                    <InfoOrder
                      orders={orders}
                      handleChangeInfoOrder={handleChangeInfoOrder}
                      orderInfo={orderInfo}
                      loadMoreOrder={loadMoreOrder}
                      hasMoreOrder={hasMoreOrder}
                      onSearchOrder={onSearchOrder}
                      loadingOrders={loadingOrders}
                      visiblePopupChangeOrder={visiblePopupChangeOrder}
                      handleClosePopupChangeOrder={handleClosePopupChangeOrder}
                      handleOkPopupChangeOrder={handleOkPopupChangeOrder}
                      orderMemory={orderMemory}
                    />
                  )}
                </Card>
              </Col>
              <Col span={7} className="card-container-info">
                <Card title={<TitleCard title={t("warehouse.salesReturn.infoRecept")} />} style={{ flexGrow: 1 }}>
                  <PaymentInfo />
                </Card>
              </Col>
              <Col span={7}>
                <Card title={<TitleCard title={t("warehouse.create.addInfo")} />}>
                  <AddInfo />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: "30px" }}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card title={<TitleCard title={t("warehouse.create.listProduct")} />}>
                  <Row gutter={[16, 8]}>
                    {typeSalesReturn === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY && (
                      <Col span={4}>
                        <SelectOrder
                          handleGetOrder={handleGetOrder}
                          sellerInfo={sellerInfo}
                          paramsOrderSeller={paramsOrderSeller}
                          setParamsOrderSeller={setParamsOrderSeller}
                          valueSelectOrder={valueSelectOrder}
                          setValueSelectOrder={setValueSelectOrder}
                        />
                      </Col>
                    )}
                    <Col span={9}>
                      <SelectProduct
                        handleGetProduct={handleGetProduct}
                        optionSelectProduct={optionSelectProduct}
                        orderInfo={orderInfo}
                        isDisabledSelectProduct={isDisabledSelectProduct}
                      />
                    </Col>
                  </Row>
                  <Col span={24}>
                    <Form.List name="items">
                      {(items, { remove }) => {
                        return (
                          <div>
                            <TableProduct
                              remove={remove}
                              form={form}
                              dataTableProduct={dataTableProduct}
                              handleDeleteProduct={handleDeleteProduct}
                              setCurrencyFirst={setCurrencyFirst}
                              setSumPrice={setSumPrice}
                              handleKeyPress={handleKeyPress}
                              typeSalesReturn={typeSalesReturn}
                            />
                            {formData?.items?.length > 0 ? (
                              <div style={{ marginTop: "10px" }}>
                                <Typography.Link strong>
                                  {t("warehouse.recommendImport.sumByMoney")}:{" "}
                                  {currencyFirst === "USD"
                                    ? formatCurrencyDollar(sumPrice || 0)
                                    : formatCurrency(sumPrice || 0)}
                                </Typography.Link>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row gutter={[16, 8]}></Row>
              </Col>
              <Col span={24}>
                <Card className="form-footer">
                  <CustomModal
                    {...{
                      customComponent: <CustomButton title={t("common.cancel")} />,
                      footer: false,
                      message: t("warehouse.create.cancelCreateSalesReturn"),
                      centered: true,
                      onOke: () => {
                        history.push("/sales-return");
                      },
                    }}
                  />
                  <CustomButton
                    type={"primary"}
                    title={t("warehouse.create.create")}
                    htmlType={"submit"}
                    loading={loadingCreate}
                    onClick={handleCreateClick}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateSalesReturn;
