import { Col, Row, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { EXPORT_TYPE } from "features/Serial/constant";
import { t } from "i18next";
import { sumBy } from "lodash";
import TableOrder from "../TypeOrder/TableOrder";
import TableReturnSupplier from "../TypeReturnSupplier/TableReturnSupplier";
import TableTransferSerial from "../TypeTransferSerial/TableTransferSerial";
import TableWarranty from "../TypeWarranty/TableWarranty";
import styles from "./index.module.scss";
import useList from "./useList";

function TableExport({ dataDetail, sumProductByOrder, sumSerialByOrder, sumSerialScanned }) {
  const { renderLack, getQuantityById } = useList({ dataDetail });

  const { columnsBySeller } = TableWarranty();

  const { columnsBySupplier } = TableReturnSupplier({ renderLack, getQuantityById });

  const { columnsByOrders } = TableOrder({ renderLack, getQuantityById });

  const { columns: columnsTransferSerial } = TableTransferSerial({ renderLack, getQuantityById });

  const renderSubTotal = (sumSerialByOrder, sumSerialScanned) => {
    if (sumSerialByOrder < sumSerialScanned) {
      return (
        <>
          <Col span={20}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={4} style={{ color: "red" }}>
            : {(sumSerialScanned - sumSerialByOrder)?.toString().padStart(2, "0")}
          </Col>
        </>
      );
    }
    if (sumSerialScanned === sumSerialByOrder) {
      return (
        <>
          <Col span={20}>
            <Typography.Text strong className={styles["summary"]} style={{ color: "#00AB78" }}>
              {t("serialIE.productInfo.enough")}
            </Typography.Text>
          </Col>
          <Col span={4}>
            <Typography.Text style={{ color: "#00AB78" }}>
              : {sumSerialScanned?.toString().padStart(2, "0")}
            </Typography.Text>
          </Col>
        </>
      );
    }
    if (sumSerialScanned < sumSerialByOrder) {
      return (
        <>
          <Col span={20}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}:
            </Typography.Text>
          </Col>
          <Col span={4} style={{ color: "#ef9351" }}>
            : {(sumSerialByOrder - sumSerialScanned)?.toString().padStart(2, "0")}
          </Col>
        </>
      );
    }
  };
  const summaryTotalOrder = () => {
    return (
      <Row gutter={[]} className={styles["summary-total"]}>
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(sumProductByOrder || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumSerialByOrder || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumSerialScanned || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>{sumProductByOrder > 0 && renderSubTotal(sumSerialByOrder || 0, sumSerialScanned || 0)}</Row>
        </Col>
      </Row>
    );
  };

  const summaryTotalTransferSerial = () => {
    return (
      <Row gutter={[]} className={styles["summary-total"]}>
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(sumProductByOrder || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {sumBy(dataDetail?.items, "expectedQuantity")?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumSerialScanned || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            {sumProductByOrder > 0 &&
              renderSubTotal(sumBy(dataDetail?.items, "expectedQuantity") || 0, sumSerialScanned || 0)}
          </Row>
        </Col>
      </Row>
    );
  };

  const summaryTotalReturnSupplier = () => {
    return (
      <Row gutter={[]} className={styles["summary-total"]}>
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(dataDetail?.items?.length || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumBy(dataDetail?.items, "quantity") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumBy(dataDetail?.items, "exportItems.length") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            {(dataDetail?.items?.length || 0) > 0 &&
              renderSubTotal(
                sumBy(dataDetail?.items, "expectedQuantity") || 0 || 0,
                sumBy(dataDetail?.items, "exportItems.length") || 0 || 0
              )}
          </Row>
        </Col>
      </Row>
    );
  };

  const renderTableExportSerial = () => {
    switch (dataDetail?.type) {
      case EXPORT_TYPE.ORDER:
        return (
          <div>
            <CustomTable columns={columnsByOrders} rowKey={(record) => record.id} dataSource={dataDetail?.items} />
            {summaryTotalOrder()}
          </div>
        );
      case EXPORT_TYPE.WARRANTY:
        return (
          <div className="custom-table-seller">
            <CustomTable columns={columnsBySeller} dataSource={dataDetail?.items} rowClassName={() => "editable-row"} />
            <div className="footer-table-seller">
              <div></div>
              <div>
                {t("serialIE.productInfo.sumSerial")} : {(dataDetail?.items?.length || 0)?.toString().padStart(2, "0")}
              </div>
            </div>
          </div>
        );
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return (
          <div>
            <CustomTable columns={columnsBySupplier} rowKey={(record) => record.id} dataSource={dataDetail?.items} />
            {summaryTotalReturnSupplier()}
          </div>
        );
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return (
          <div>
            <CustomTable
              columns={columnsTransferSerial}
              rowKey={(record) => record.id}
              dataSource={dataDetail?.items}
            />
            {summaryTotalTransferSerial()}
          </div>
        );
      case EXPORT_TYPE.OTHER:
        return (
          <div>
            <CustomTable
              columns={columnsTransferSerial}
              rowKey={(record) => record.id}
              dataSource={dataDetail?.items}
            />
            {summaryTotalTransferSerial()}
          </div>
        );
      default:
        return "";
    }
  };
  return renderTableExportSerial();
}

export default TableExport;
