import { Typography } from "antd";
import { t } from "i18next";
import { warrantyRequestLabel, WARRANTY_REQUEST_STATUS, WARRANTY_STATUS } from "./constant";

export const renderWarrantyStatusLabel = {
  [`${WARRANTY_STATUS.STILL_VALIDATED}`]: (
    <Typography.Text style={{ color: "#00AB78" }}>{t("warranty.warrantyDetail.stillValidated")}</Typography.Text>
  ),
  [`${WARRANTY_STATUS.INVALIDATED}`]: (
    <Typography.Text style={{ color: "#EF9351" }}>{t("warranty.warrantyDetail.invalidated")}</Typography.Text>
  ),
  [`${WARRANTY_STATUS.EXPIRED}`]: (
    <Typography.Text style={{ color: "#D2434D" }}>{t("warranty.warrantyDetail.expire")}</Typography.Text>
  ),
  undefined: t("warrantyRequest.detail.asRequired"),
};

export const renderWarrantyRequestStatusLabel = {
  [`${WARRANTY_REQUEST_STATUS.WAITING_FOR_PROCESS}`]: (
    <Typography.Text style={{ color: "#EF9351" }}>
      {warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.WAITING_FOR_PROCESS}`]}
    </Typography.Text>
  ),
  [`${WARRANTY_REQUEST_STATUS.PROCESSING}`]: (
    <Typography.Text style={{ color: "#052987" }}>
      {warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.PROCESSING}`]}
    </Typography.Text>
  ),
  [`${WARRANTY_REQUEST_STATUS.COMPLETED}`]: (
    <Typography.Text style={{ color: "rgba(0, 171, 120, 1)" }}>
      {warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.COMPLETED}`]}
    </Typography.Text>
  ),
  [`${WARRANTY_REQUEST_STATUS.CANCELED}`]: (
    <Typography.Text style={{ color: "#D2434D" }}>
      {warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.CANCELED}`]}
    </Typography.Text>
  ),
  undefined: "--",
};
