import { Col, Form, Row, Spin, Tooltip, Typography } from "antd";
import { DATE_FORMAT, INPUT_TYPE, REGEX, SHIPPING_TYPE } from "config/constants";
import { DistributeMethod, WRequestSolutionMethod } from "features/WarrantyRequest/constant";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useGetSellerCreateCart } from "hooks/seller";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { omit } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";

function usePopupSolution({ confirmDistribute, solution, itemIDs, setIsOpen, record, selectedRows }) {
  const [form] = Form.useForm();
  const [shippingType, setShippingType] = useState(SHIPPING_TYPE.URBAN_COD);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [technicalPhone, setTechnicalPhone] = useState(undefined);
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      // permission: USER_PERMISSIONS.STAFF_ALL,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(params);

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    setTechnicalPhone(option.telephone);
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClick = async () => {
    await form.validateFields();
    setLoadingBtn(true);
    const assignee = {
      [`${WRequestSolutionMethod.PROVIDER}`]: DistributeMethod.PROVIDER,
      [`${WRequestSolutionMethod.THIRD_PARTY}`]: DistributeMethod.THIRD_PARTY,
      [`${WRequestSolutionMethod.SELF}`]: DistributeMethod.TECHNICAL_STAFF,
    };

    form
      .validateFields()
      .then((values) => {
        const distributeRequest = omit(values, ["sendNoteNumber", "technicalStaffPhone"]);
        setLoadingBtn(false);
        setIsOpen(false);
        confirmDistribute({
          warrantyRequestItemIDs: itemIDs,
          assignee: assignee?.[solution],
          distributeRequest,
        });
        form.resetFields();
      })
      .catch((info) => {
        console.info("Validate Failed:", info);
      });
  };
  const providerOfWarrantyNoteFirst =
    selectedRows?.length > 0 ? selectedRows[0]?.warrantyNote?.provider : selectedRows?.warrantyNote?.provider;
  const initialValues = {
    sendNoteNumber: record?.sendNoteNumber,
    receivePartyName: providerOfWarrantyNoteFirst?.fullName,
    receivePartyPhone: providerOfWarrantyNoteFirst?.telephone,
    receivePartyAddress: providerOfWarrantyNoteFirst?.address,
    warrantySendDate: moment(),
    technicalStaffID: null,
    distributeShippingType: shippingType,
  };

  const { platformName } = useGetInfoPlatform();

  const getShippingTypeOptions = () => {
    const SHIPPING_TYPE_OPTIONS = [
      {
        value: SHIPPING_TYPE.BUS,
        label: t("warrantyRequest.create.shippingType.bus"),
      },
      {
        value: SHIPPING_TYPE.DELIVER_3PARTY,
        label: t("warrantyRequest.create.shippingType.shippingProvider"),
      },
      {
        value: SHIPPING_TYPE.PICK_UP_COUNTER,
        label: t("warrantyRequest.create.shippingType.pickUp"),
      },
      {
        value: SHIPPING_TYPE.URBAN_COD,
        label: t("warrantyRequest.create.shippingType.shipper", { platformName }),
      },
    ];

    let initial = SHIPPING_TYPE.BUS;
    let options = SHIPPING_TYPE_OPTIONS;
    return {
      options,
      initial,
    };
  };

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isActive: true, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const { sellers, loading: loadingSeller } = useGetSellerCreateCart(paramsSeller, "cache-first");

  const onSearchSeller = (valueSearch) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: valueSearch || null, isSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      receivePartyName: valueSearch || undefined,
    });
  };
  const sellerOptions = sellers?.map((item) => ({
    value: item?.value,
    label: (
      <Row gutter={[16]}>
        <Col span={5} className={styles["item"]} style={{ padding: "5px 0 0 15px", textAlign: "left" }}>
          <Typography.Link strong>{item?.code}</Typography.Link>
        </Col>
        <Col span={7} className={styles["item"]} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.sellername}>
            <Typography.Text ellipsis>{item?.sellername}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={4} style={{ paddingTop: "5px", textAlign: "center" }}>
          <Typography.Text>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={8} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.address}>
            <Typography.Text ellipsis>{item?.address}</Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    seller: item,
    labelSeller: item?.sellername,
  }));

  const handleChangeSeller = (value, option) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      receivePartyAddress: option?.seller?.address,
      receivePartyPhone: option?.seller?.telephone,
      receivePartyName: option?.seller?.sellername,
    });
  };
  const allFields = {
    ticketNumber: {
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.ticketNumber")}</Typography.Text>,
        name: "sendNoteNumber",
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.ticketNumberplc"),
        disabled: true,
      },
    },
    providerRepair: {
      type: INPUT_TYPE.AUTO_COMPLETE,
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.warrantyParty")}</Typography.Text>,
        name: "receivePartyName",
        rules: [{ required: true, message: t("common.validateRequired") }],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.warrantyParty"),
        allowClear: true,
        options: sellerOptions,
        dropdownMatchSelectWidth: 1000,
        optionLabelProp: "labelSeller",
        onSearch: onSearchSeller,
        onChange: (value, option) => handleChangeSeller(value, option),
        dropdownRender: (originNode) => (
          <>
            <Row gutter={16} className={styles["custom-dropdown"]}>
              <Col span={4} className={styles["item"]} style={{ padding: "10px 0 0 20px", textAlign: "center" }}>
                <Typography.Text strong>{t("supplier.table.supplierCode")}</Typography.Text>
              </Col>
              <Col span={7} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                <Typography.Text strong>{t("supplier.table.supplierName")}</Typography.Text>
              </Col>
              <Col span={5} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                <Typography.Text strong>{t("supplier.table.phoneNumber")}</Typography.Text>
              </Col>
              <Col span={8} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                <Typography.Text strong>{t("supplier.table.address")}</Typography.Text>
              </Col>
            </Row>
            <Spin spinning={loadingSeller}>{originNode}</Spin>
          </>
        ),
        dropdownStyle: { zIndex: "1000" },
      },
    },
    warrantyParty: {
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.warrantyParty")}</Typography.Text>,
        name: "receivePartyName",
        rules: [{ required: true, message: t("common.validateRequired") }],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.warrantyParty"),
      },
    },
    telephoneStaff: {
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.telephone")}</Typography.Text>,
        name: "technicalStaffPhone",
        rules: [
          {
            pattern: REGEX.PHONE,
            message: t("common.wrongPhone"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.telephoneplc"),
        disabled: true,
        // value: initPhoneStaff,
      },
    },
    telephone: {
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.telephone")}</Typography.Text>,
        name: "receivePartyPhone",
        rules: [
          {
            pattern: REGEX.PHONE,
            message: t("common.wrongPhone"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.telephoneplc"),
      },
    },
    sendDate: {
      type: INPUT_TYPE.DATE_PICKER,
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.sendDate")}</Typography.Text>,
        name: "warrantySendDate",
        rules: [{ required: true, message: t("common.validateRequired") }],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.date"),
        format: DATE_FORMAT,
      },
    },
  };

  const formFieldsFormality = [
    {
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.address")}</Typography.Text>,
        name: "receivePartyAddress",
        rules: [{ required: true, message: t("common.validateRequired") }],
      },
      inputOptions: {
        placeholder: t("warrantyRequest.popup.address"),
      },
    },
    {
      type: INPUT_TYPE.RADIO_GROUP,
      formItemOptions: {
        label: <Typography.Text strong>{t("warrantyRequest.popup.formality")}</Typography.Text>,
        name: "distributeShippingType",
        rules: [{ required: true, message: t("common.validateRequired") }],
      },
      inputOptions: {
        options: getShippingTypeOptions().options,
        onChange: (e) => setShippingType(e.target.value),
      },
    },
  ];

  const { providerRepair, warrantyParty, telephone, sendDate, telephoneStaff } = allFields;

  const formProvider = [providerRepair, telephone, sendDate];
  const formThird = [warrantyParty, telephone, sendDate];
  const formSelf = [telephoneStaff, sendDate];

  useEffect(() => {
    form.setFieldsValue({ technicalStaffPhone: technicalPhone });
  }, [initialValues]);

  return {
    formProvider,
    formThird,
    formFieldsFormality,
    formSelf,
    form,
    initialValues,
    shippingType,
    handleClick,
    handleOpen,
    loadingBtn,
    staffs,
    onLoadMoreData,
    hasMoreData,
    onSearch,
    handleChange,
  };
}

export default usePopupSolution;
