import { useGetOrderFilterLoadMore } from "hooks/order";
import { useState } from "react";
import { FilterLoadMore } from "../Filter";

function FilterOrderIDs({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: { offset: 0, limit: 10 },
  });

  const { orders, onLoadMoreData, hasMoreData, loading } = useGetOrderFilterLoadMore(params, "cache-first");

  const options = orders?.map((item) => {
    return { label: item?.number, value: item?.id };
  });

  const handleSearch = (value) => {
    setParams({
      filters: { ...params?.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return (
    <FilterLoadMore
      params={params}
      data={options || []}
      onLoadMoreData={onLoadMoreData}
      onFilter={(value) => onFilter(value?.length ? value : undefined)}
      onSearch={handleSearch}
      hasMore={hasMoreData}
      loading={loading}
    />
  );
}

export default FilterOrderIDs;
