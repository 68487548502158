import { Space } from "antd";
import useButtonGroup from "features/WarrantyRequest/Detail/ButtonGroup/useButtonGroup";
import {
  ButtonChecking,
  ButtonChooseProcess,
  ButtonComplete,
  ButtonCustomerDeli,
  ButtonGetResult,
  ButtonReturnWaitingForCheck,
  ButtonTransfer,
} from "features/WarrantyRequest/components/ButtonActions";
import ModalEditSerial from "features/WarrantyRequest/components/ModalEditSerial";
import { TOTAL_ITEMS_STATUS } from "features/WarrantyRequest/constant";
import { useFlags } from "flagsmith/react";

function ButtonGroup({ record, refetch }) {
  const {
    switchClose,
    loadingConfirmSolution,
    loadingConfirmCheck,
    loadingConfirmDistribute,
    loadingComplete,
    loadingConfirmReturn,
    loadingUpdateSerial,
    loadingReturnWaitingForCheck,
    returnWaitingForCheck,
    confirmSolution,
    confirmCheck,
    confirmDistribute,
    complete,
    confirmReturn,
    updateSerial,
    handleOpenModal,
    handleCancelModal,
  } = useButtonGroup({
    refetch,
    itemID: record?.id,
  });

  const flags = useFlags(["is_disable_edit_serial"]);
  const isDisableEditSerial = !flags.is_disable_edit_serial.enabled;

  const renderButtonByStatus = {
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_CHECK}`]: (
      <>
        <ModalEditSerial
          disabled={!isDisableEditSerial}
          record={record}
          updateSerial={updateSerial}
          loading={loadingUpdateSerial}
        />

        <ButtonChecking
          confirmCheck={confirmCheck}
          loading={loadingConfirmCheck}
          switchClose={switchClose}
          handleOpen={handleOpenModal}
          handleCancel={handleCancelModal}
        />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.CHECKING}`]: (
      <>
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          record={record}
          returnWaitingForCheck={returnWaitingForCheck}
        />
        <ButtonChooseProcess record={record} loading={loadingConfirmSolution} confirmSolution={confirmSolution} />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE}`]: (
      <>
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          record={record}
          returnWaitingForCheck={returnWaitingForCheck}
        />
        <ButtonTransfer
          confirmDistribute={confirmDistribute}
          loading={loadingConfirmDistribute}
          solution={record?.solution}
          record={record}
          switchClose={switchClose}
          handleOpen={handleOpenModal}
          handleCancel={handleCancelModal}
        />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.REPAIRING}`]: (
      <>
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          record={record}
          returnWaitingForCheck={returnWaitingForCheck}
        />
        <ButtonGetResult refetch={refetch} record={record} solution={record?.solution} />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN}`]: (
      <>
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          record={record}
          returnWaitingForCheck={returnWaitingForCheck}
        />
        <ButtonCustomerDeli record={record} loading={loadingConfirmReturn} confirmReturn={confirmReturn} />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.DELIVERING}`]: (
      <>
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          record={record}
          returnWaitingForCheck={returnWaitingForCheck}
        />
        <ButtonComplete complete={complete} loading={loadingComplete} />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.COMPLETED}`]: (
      <ButtonReturnWaitingForCheck
        loading={loadingReturnWaitingForCheck}
        record={record}
        returnWaitingForCheck={returnWaitingForCheck}
      />
    ),
    [`${TOTAL_ITEMS_STATUS.CANCELED}`]: (
      <ButtonReturnWaitingForCheck
        loading={loadingReturnWaitingForCheck}
        record={record}
        returnWaitingForCheck={returnWaitingForCheck}
      />
    ),
  };

  return (
    <Space style={{ width: "100%", justifyContent: "flex-end" }}>{renderButtonByStatus?.[`${record?.status}`]}</Space>
  );
}

export default ButtonGroup;
