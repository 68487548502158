import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Form, Radio, Row, Tooltip, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import Card from "features/Cart/components/Card";
import { SUPPLIER_TYPE } from "features/Supplier/constant";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";
import CardInfoBankAccount from "../CardInfoBankAccount";
import "./index.scss";

const titleCard = {
  level: 5,
  style: {
    color: "#052987",
  },
};

function CommonForm({
  isSellerDetail,
  isDisabledInput,
  isSeller,
  typeSupplier,
  paymentMethodOptions,
  banksOptions,
  data,
  sellerID,
  form,
  bankAccounts,
  handleGetBankAccounts,
}) {
  const formFieldsPayment = [
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.paymentMethod"),
        name: "paymentMethod",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.paymentMethod"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.paymentMethod"),
        options: paymentMethodOptions,
        disabled: isSellerDetail,
      },
    },
    {
      type: INPUT_TYPE.CURRENCY_INPUT,
      formItemOptions: {
        label: t("seller.labelInput.debtLimit"),
        name: "debtLimit",
        rules: [
          {
            required: !isDisabledInput,
            message: t("seller.validateMessage.debtLimit"),
          },
          validator({
            type: "number",
          }),
          validator({
            type: "debt",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.debtLimit"),
        maxLength: 14,
        disabled: isDisabledInput,
        controls: false,
      },
    },
    {
      type: INPUT_TYPE.NUMBER,
      formItemOptions: {
        label: t("seller.labelInput.debtAge"),
        name: "debtAge",
        rules: [
          {
            required: !isDisabledInput,
            message: t("seller.validateMessage.debtAge"),
          },
          validator({
            type: "number",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.debtAge"),
        maxLength: 4,
        disabled: isDisabledInput,
      },
    },
  ];

  const formFieldsContactInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.contactName"),
        name: "contactName",
        rules: [
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactName"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.contactPhone"),
        name: "contactPhone",
        rules: [
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactPhone"),
        maxLength: 10,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.contactEmail"),
        name: "contactEmail",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactEmail"),
      },
    },
  ];

  const formFieldsVatCompany = [
    {
      formItemOptions: {
        label: t("seller.labelInput.representative"),
        name: "representative",
        rules: [
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.representative"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.legal_representative_phone"),
        name: "legal_representative_phone",
        rules: [
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.legal_representative_phone"),
        maxLength: 10,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.companyAddress"),
        name: "companyAddress",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.companyAddress"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.companyAddress"),
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.company_email"),
        name: "company_email",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.company_email"),
      },
    },
  ];

  const optionVatType = [
    {
      value: true,
      label: t("v2.product.drawer_edit_product.export_vat"),
    },
    {
      value: false,
      label: t("v2.product.drawer_edit_product.not_export_vat"),
    },
  ];

  const renderFormUI = {
    [`${SUPPLIER_TYPE.COMPANY}`]: (
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {isSeller && (
            <Col span={12}>
              <Card title={<Typography.Title {...titleCard}>{t("supplier.policyPayment")}</Typography.Title>}>
                {formFieldsPayment?.map((field, index) => {
                  return (
                    <FormInput
                      key={index}
                      {...{
                        ...field,
                        formItemOptions: {
                          ...field.formItemOptions,
                          label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                        },
                      }}
                    />
                  );
                })}
              </Card>
            </Col>
          )}
          <Col span={isSeller ? 12 : 8}>
            <Card title={<Typography.Title {...titleCard}>{t("supplier.contactInfo")}</Typography.Title>}>
              {formFieldsContactInfo?.map((field, index) => {
                return (
                  <FormInput
                    key={index}
                    {...{
                      ...field,
                      formItemOptions: {
                        ...field.formItemOptions,
                        label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                      },
                    }}
                  />
                );
              })}
            </Card>
          </Col>
          <Col span={isSeller ? 12 : 8}>
            <Card title={<Typography.Title {...titleCard}>{t("supplier.vatInfo")}</Typography.Title>}>
              {isSeller && (
                <div className="radio-group-type-group">
                  <div>
                    <Typography.Text className="title-label">
                      {t("v2.product.drawer_edit_product.seller_prioritize_export")}
                    </Typography.Text>
                    <Form.Item
                      name="defaultExportVat"
                      rules={[
                        {
                          required: true,
                          message: "Chưa chọn loại đại lý theo VAT",
                        },
                      ]}
                      style={{ marginBottom: "0px" }}
                    >
                      <Radio.Group>
                        {optionVatType?.map((item) => {
                          return <Radio.Button value={item?.value}>{item?.label}</Radio.Button>;
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <FormInput
                    type={INPUT_TYPE.CHECK_BOX}
                    formItemOptions={{
                      name: "allowEditVat",
                      valuePropName: "checked",
                    }}
                    inputChildren={
                      <Row className="title-checkbox">
                        <span>Được phép tùy chỉnh VAT &nbsp;</span>
                        <Tooltip
                          title={
                            "Đại lý được phép tùy chỉnh VAT có thể chủ động lựa chọn xuất VAT hoặc không xuất VAT cho những sản phẩm được cài đặt tùy chỉnh VAT"
                          }
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  />
                </div>
              )}
              {formFieldsVatCompany?.map((field, index) => {
                return (
                  <FormInput
                    key={index}
                    {...{
                      ...field,
                      formItemOptions: {
                        ...field.formItemOptions,
                        label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                      },
                    }}
                  />
                );
              })}
            </Card>
          </Col>
          <Col span={isSeller ? 12 : 8}>
            <CardInfoBankAccount
              bankAccounts={bankAccounts}
              handleGetBankAccounts={handleGetBankAccounts}
              form={form}
              sellerID={sellerID}
              banksOptions={banksOptions}
            />
          </Col>
        </Row>
      </Col>
    ),
    [`${SUPPLIER_TYPE.PERSONAL}`]: (
      <Col span={24}>
        {!isSeller && (
          <CardInfoBankAccount
            form={form}
            sellerID={sellerID}
            widthNotFull={true}
            banksOptions={banksOptions}
            bankAccounts={bankAccounts}
            handleGetBankAccounts={handleGetBankAccounts}
          />
        )}
        {isSeller && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title={<Typography.Title {...titleCard}>{t("supplier.policyPayment")}</Typography.Title>}>
                {formFieldsPayment?.map((field, index) => {
                  return (
                    <FormInput
                      key={index}
                      {...{
                        ...field,
                        formItemOptions: {
                          ...field.formItemOptions,
                          label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                        },
                      }}
                    />
                  );
                })}
              </Card>
            </Col>
            <Col span={12}>
              <div style={{ marginBottom: "16px" }}>
                <Card title={<Typography.Title {...titleCard}>{t("supplier.infoBankAccount")}</Typography.Title>}>
                  {isSeller && (
                    <div className="radio-group-type-group">
                      <div>
                        <Typography.Text className="title-label">
                          {t("v2.product.drawer_edit_product.seller_prioritize_export")}
                        </Typography.Text>
                        <Form.Item
                          name="defaultExportVat"
                          rules={[
                            {
                              required: true,
                              message: "Chưa chọn loại đại lý theo VAT",
                            },
                          ]}
                        >
                          <Radio.Group>
                            {optionVatType?.map((item) => {
                              return <Radio.Button value={item?.value}>{item?.label}</Radio.Button>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </div>

                      <FormInput
                        type={INPUT_TYPE.CHECK_BOX}
                        formItemOptions={{
                          name: "allowEditVat",
                          valuePropName: "checked",
                        }}
                        inputChildren={
                          <Row style={{ display: "flex", alignItems: "center" }}>
                            <span>Được phép tùy chỉnh VAT &nbsp;</span>
                            <Tooltip
                              title={
                                "Đại lý được phép tùy chỉnh VAT có thể chủ động lựa chọn xuất VAT hoặc không xuất VAT cho những sản phẩm được cài đặt tùy chỉnh VAT"
                              }
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Row>
                        }
                      />
                    </div>
                  )}
                </Card>
              </div>

              <CardInfoBankAccount
                form={form}
                sellerID={sellerID}
                banksOptions={banksOptions}
                bankAccounts={bankAccounts}
                handleGetBankAccounts={handleGetBankAccounts}
              />
            </Col>
          </Row>
        )}
      </Col>
    ),
  };

  return <Row gutter={[16, 16]}>{renderFormUI?.[typeSupplier]}</Row>;
}

export default CommonForm;
