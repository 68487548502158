import LockedIcon from "./locked-icon.PNG";
import UnlockedIcon from "./unlocked-icon.PNG";
import DeleteIcon from "./delete-icon.PNG";
import AvatarPlaceHolder from "./avatar-placeholder.png";
import SupportIcon from "./support-icon.png";
import PlaceholderImageFailed from "./Placeholder-image-failed.png";
import QuestionIcon from "./question-icon.png";

const Img = {
  LockedIcon,
  UnlockedIcon,
  DeleteIcon,
  AvatarPlaceHolder,
  SupportIcon,
  PlaceholderImageFailed,
  QuestionIcon,
};

export default Img;
