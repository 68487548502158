import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const { Option } = Select;

const PeriodSelect = ({ onChange, params }) => {
  const { t } = useTranslation();
  const periodOptions = [
    {
      label: "common.all",
      value: "all",
    },
    {
      label: "component.periodSelect.thisMonth",
      value: "this_month",
    },
    {
      label: "component.periodSelect.thisQuarter",
      value: "this_quarter",
    },
    {
      label: "component.periodSelect.thisYear",
      value: "this_year",
    },
    {
      label: "component.periodSelect.firstSixMonths",
      value: "first_six_months",
    },
    {
      label: "component.periodSelect.lastSixMonths",
      value: "six_months_late",
    },
  ];

  return (
    <div className="period-filter">
      <span className="filter-label">{t("component.periodSelect.period")}</span>
      <Select
        onChange={onChange}
        defaultValue={params?.filters?.periodSelected || "all"}
        className="filter custom-select"
      >
        {periodOptions.map((period, i) => (
          <Option key={i} value={period.value}>
            {t(period.label)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default PeriodSelect;
