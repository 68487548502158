import { Input } from "antd";

import { FontAwesomeIcon, Icon } from "assets/icons";
import { useToolbar } from "./useToolbar";

import "./index.scss";

function Toolbar({ params, onChangeParams, handleSearch, placeholder }) {
  const { renderTags } = useToolbar({
    params,
    onChangeParams,
  });

  return (
    <>
      <div className="filter-category-list-container">
        <div className="search-input">
          <Input
            size="middle"
            className="custom-input input-seller"
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={placeholder}
            allowClear={true}
            onChange={(value) => handleSearch(value)}
          />
        </div>
      </div>
      <div className="tags-list">{renderTags}</div>
    </>
  );
}

export default Toolbar;
