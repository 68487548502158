import { Col, Typography } from "antd";
import { SHIPPING_PROVIDER_TYPE, SHIPPING_TYPE } from "features/Cart/constants";
import { SHIPPING_VERSION } from "features/Order/constants";
import { renderShippingType } from "features/Order/helper";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";

const DeliveryMethod = ({ dataInfoAgent }) => {
  const { shippingType, shippingConfiguration, shippingVersion } = dataInfoAgent;

  const renderInfoDeliveryMethod = {
    [`${SHIPPING_TYPE.BUS}`]: (
      <>
        <Col span={6}>
          <Typography.Text strong>{t("order.orderDetail.busName")}:</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong style={{ color: "#052987" }}>
            {shippingConfiguration?.busConfig?.busName}
          </Typography.Text>
        </Col>

        <Col span={6}>
          <Typography.Text strong>{t("order.orderDetail.busPhone")}:</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong style={{ color: "#052987" }}>
            {shippingConfiguration?.busConfig?.telephone}
          </Typography.Text>
        </Col>

        <Col span={6}>
          <Typography.Text strong>{t("order.orderDetail.busStation")}:</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong style={{ color: "#052987" }}>
            {shippingConfiguration?.busConfig?.busStation}
          </Typography.Text>
        </Col>
      </>
    ),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: (
      <>
        <Col span={6}>
          <Typography.Text strong>{t("order.orderDetail.ĐVVC")}:</Typography.Text>
        </Col>
        <Col span={10}>
          <Typography.Text strong style={{ color: "#052987" }}>
            {shippingVersion === SHIPPING_VERSION?.V2
              ? shippingConfiguration?.deliver3PartyConfig?.provider?.name
                ? `${SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod]} - ${
                    shippingConfiguration?.deliver3PartyConfig?.provider?.name
                  }`
                : SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod]
              : shippingConfiguration?.deliver3PartyConfig?.provider?.name}
          </Typography.Text>
        </Col>
      </>
    ),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: <></>,
    [`${SHIPPING_TYPE.URBAN_COD}`]: (
      <>
        <Col span={6}>
          <Typography.Text strong>{t("order.orderDetail.shippingFee")}:</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong style={{ color: "#052987" }}>
            {formatCurrency(shippingConfiguration?.urbanConfig?.fee)}
          </Typography.Text>
        </Col>
      </>
    ),
  };

  return (
    <>
      <Col span={6}>
        <Typography.Text strong>{t("cart.deliveryMethodLabel")}</Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text strong style={{ color: "#052987" }}>
          {renderShippingType[shippingType]}
        </Typography.Text>
      </Col>
      <Col span={12}></Col>
      {renderInfoDeliveryMethod[shippingType]}
    </>
  );
};

export default DeliveryMethod;
