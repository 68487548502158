import { Card, Col, Form, Row, Space, Spin, Typography } from "antd";
import PageHeader from "components/PageHeader";
import PopoverExport from "components/PopoverExport";
import { EXPORT_TYPE, typeExport } from "features/Serial/constant";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import PopoverPrint from "../../../../components/PopoverPrint";
import AddInfo from "./components/AddInfo";
import TableExport from "./components/TableExport";
import { SubTitleCard, TitleCard } from "./components/TitleCard";
import "./index.scss";
import useList from "./useList";

function DetailSerialExport() {
  const {
    isReady,
    dataDetail,
    handleExport,
    handlePrint,
    loadingExport,
    sumProductByOrder,
    sumSerialByOrder,
    sumSerialScanned,
    checkTypeInfo,
    checkTypeTitleInfo,
    loading,
  } = useList();
  return (
    <Spin spinning={loading || loadingExport}>
      <div className="serial-export-detail-page">
        <Row>
          <Col span={24}>
            <PageHeader
              pageTitle={t("serialExportDetail.watchDetailExportSerial")}
              routes={[
                { path: "/export-serial", name: <Typography.Link>{t("serialExportDetail.Serial")}</Typography.Link> },
                {
                  path: "/export-serial",
                  name: <Typography.Link>{t("serialExportDetail.exportSerial")}</Typography.Link>,
                },
                {
                  path: "",
                  name: <Typography.Text>{t("serialExportDetail.watchDetailExportSerial")}</Typography.Text>,
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <Row style={{ marginBottom: "10px" }}>
              <Col span={24} xl={12}>
                <Row gutter={20}>
                  <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <PopoverExport handleExport={handleExport} />
                  </Col>
                  <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <PopoverPrint handlePrint={handlePrint} />
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                <Space style={{ background: "transparent", flexDirection: "column", alignItems: "end" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Col span={24}>
                      <Space>
                        <Typography.Text italic style={{ color: "#5B6673" }}>
                          {t("warranty.warrantyDetail.dateCreated")}: {formatDateTime(dataDetail?.createdAt) || "--"}
                        </Typography.Text>
                        <Typography.Text italic style={{ color: "#5B6673" }}>
                          {t("common.by")}: {dataDetail?.createdBy?.fullname || "--"}
                        </Typography.Text>
                      </Space>
                    </Col>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form layout="horizontal">
              <Row gutter={[16, 8]}>
                <Col span={24} className="col-export-serial-header">
                  <Card
                    title={<TitleCard value={dataDetail?.code} title={t("serialIE.ExportNotesSerial")} />}
                    style={{ flexGrow: 1 }}
                  >
                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                      <Col span={15} className="form-control-radio-group">
                        {isReady && (
                          <Row>
                            <SubTitleCard subTitle={t("serialIE.TypeExport")} />:
                            <Typography.Text style={{ color: "#052987" }}>
                              &nbsp;{typeExport[dataDetail?.type]}
                            </Typography.Text>
                          </Row>
                        )}
                      </Col>
                      {dataDetail?.type === EXPORT_TYPE.RETURN_SUPPLIER && (
                        <span>
                          <Typography.Text strong style={{ fontSize: "13px" }}>
                            {t("order.orderTag.exportWarehouse")}
                          </Typography.Text>{" "}
                          :{" "}
                          <Typography.Text strong style={{ fontSize: "13px" }}>
                            {dataDetail?.items &&
                              dataDetail?.items[0]?.exportItems &&
                              dataDetail?.items[0]?.exportItems[0]?.exportedWarehouse?.name}
                          </Typography.Text>
                        </span>
                      )}
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12} className="card-container-info">
                        <Card
                          title={<TitleCard title={checkTypeTitleInfo()} />}
                          style={{ flexGrow: 1 }}
                          className="card-children"
                        >
                          {checkTypeInfo()}
                        </Card>
                      </Col>
                      <Col span={12} className="card-container-info">
                        <Card
                          title={<TitleCard title={t("serialIE.addInfo.title")} />}
                          className="card-children"
                          style={{ flexGrow: 1 }}
                        >
                          <AddInfo dataDetail={dataDetail} />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col style={{ marginTop: "16px" }} span={24} className="col-export-serial-footer">
                  <Card
                    title={
                      <div>
                        <TitleCard title={t("serialIE.productInfo.title")} />
                      </div>
                    }
                    className="card-children"
                  >
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.List name="items">
                          {(items, { remove }) => {
                            return (
                              <TableExport
                                items={items}
                                remove={remove}
                                sumProductByOrder={sumProductByOrder}
                                sumSerialByOrder={sumSerialByOrder}
                                sumSerialScanned={sumSerialScanned}
                                dataDetail={dataDetail}
                              />
                            );
                          }}
                        </Form.List>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default DetailSerialExport;
