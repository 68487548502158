import { Col, Row, Space, Typography } from "antd";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import Card from "features/Cart/components/Card";
import { SubTitleCard } from "features/Serial/components/TitleCard";
import { IMPORT_TYPE } from "features/Serial/constant";
import { t } from "i18next";

function ViewReimport({ data }) {
  return (
    data?.type === IMPORT_TYPE.RE_IMPORT_WARRANTY && (
      <Col span={24} style={{ marginTop: "16px" }}>
        <Card>
          <Row gutter={[16, 8]}>
            <Col span={12} style={{ borderRight: "1px dashed #9cadc4" }}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <SubTitleCard subTitle={t("serialIE.providerInfo.infoRetype")} />
                </Col>
                <Col span={4}>
                  <Typography.Text className="sub-title">{t("serialImportEdit.nameSeller")}</Typography.Text>
                </Col>
                <Col span={20}>
                  <Typography.Text>{data?.seller?.fullName}</Typography.Text>
                </Col>
                <Col span={4}>
                  <Typography.Text className="sub-title">{t("serialIE.providerInfo.phone")}</Typography.Text>
                </Col>
                <Col span={20}>
                  <Typography.Text>{data?.seller?.telephone}</Typography.Text>
                </Col>
                <Col span={4}>
                  <Typography.Text className="sub-title">{t("serialIE.providerInfo.email")}</Typography.Text>
                </Col>
                <Col span={20}>
                  <Typography.Text>{data?.seller?.email || "- -"}</Typography.Text>
                </Col>
                <Col span={4}>
                  <Typography.Text className="sub-title">{t("serialIE.providerInfo.address")}</Typography.Text>
                </Col>
                <Col span={20}>
                  <Typography.Text>{data?.seller?.address}</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <SubTitleCard subTitle={t("serialIE.addInfo.title")} />
                </Col>
                <Col span={24}>
                  <Typography.Text strong>{t("warrantyRequest.create.note")}</Typography.Text>
                </Col>
                <Col span={24}>{data?.note}</Col>
                <Col span={24}>
                  <Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Space style={{ flexWrap: "wrap" }}>
                    {data?.fileURLs?.map((fileURL, index) => {
                      return <CustomViewDocumentFiles file={fileURL} key={index} />;
                    }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  );
}

export default ViewReimport;
