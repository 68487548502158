import { ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Col, Dropdown, Menu, message, Row, Space, Tag, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { renderLack } from "utils/helperFuncs";
import CustomTitle from "../../../components/CustomTitle";
import { convertTotalNumber } from "../../../constant";
import styles from "../../../index.module.scss";
import InputSearch from "../InputSearch";

export const useTableReturn = ({
  data,
  handleSearchSerials,
  tagsValueResult,
  visibleTags,
  visibleSearchResult,
  searchResult,
}) => {
  const copyToClipboard = (value) => {
    const listSerialCopy = value?.map((item) => item?.serial?.code);
    if (listSerialCopy?.length > 0) {
      const textToCopy = listSerialCopy.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  const renderSubTotalTable = (record) => {
    if (record.quantity < record?.serialItems?.length) {
      return (
        <Typography.Text style={{ color: "red" }}>
          {t("serialIE.productInfo.residual")}: {record?.serialItems?.length - record?.quantity}
        </Typography.Text>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>;
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <Typography.Text style={{ color: "#ef9351" }}>
          {t("common.lack")}: {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
        </Typography.Text>
      );
    }
  };

  const renderSubTotal = (record) => {
    if (record?.quantity < record?.serialItems?.length) {
      return (
        <>
          <Col span={6}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={18} style={{ color: "red" }}>
            {record?.serialItems?.length - record?.quantity}
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return (
        <>
          <Col span={6}>
            <Typography.Text strong style={{ color: "" }}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <>
          <Col span={6}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}
            </Typography.Text>
          </Col>
          <Col span={18} style={{ color: "#ef9351" }}>
            {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
          </Col>
        </>
      );
    }
  };
  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Link to={`/product/detail/${record?.product?.id}`} style={{ textAlign: "start", margin: 0 }}>
            <Typography.Link strong> {record?.product?.code}</Typography.Link>
          </Link>
          <Tooltip title={record?.product?.name}>
            <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
              {truncateAddDots(record?.product?.name, 80)}
            </Typography.Text>
          </Tooltip>
          <Typography.Text style={{ textAlign: "end", margin: 0 }} italic>
            {record?.product?.uom?.name}
          </Typography.Text>
        </div>
      ),
      isHidden: false,
      width: 250,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.providerOfWarranty2")} />,
      render: (_, record, index) => (
        <Typography.Text>
          {record?.warrantyOnSale} {convertUnit("MONTH")}
        </Typography.Text>
      ),
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.byOrder")} />,
      render: (_, record, index) => {
        return <Typography.Text>{record?.quantity}</Typography.Text>;
      },
      width: 120,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.return")} />,
      width: 120,
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Typography.Text className={styles["title"]}>{record?.serialItems?.length || 0}</Typography.Text>
            {renderSubTotalTable(record)}
          </Space>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.detailSerialC")} />,
      render: (_, record, index) => {
        return (
          <div className="input-tag-container-detail">
            <div className={`${"isHidden"}`}>
              <div className="input-tag-tags">
                {record?.serialItems?.slice(0, 8)?.map((item, index) => {
                  return (
                    <Tag
                      className={`input-tag-tag  ${
                        item?.serial?.status === "AVAILABLE" ? "custom-tags-by-serial" : "custom-tags-unvailable"
                      }`}
                      key={index}
                      color="error"
                    >
                      {item?.serial?.code}
                    </Tag>
                  );
                })}
                {record?.serialItems?.length > 8 && (
                  <Tag className="input-tag-tag custom-tags-by-serial" key={index}>
                    <Typography.Link italic>+{record?.serialItems?.length - 8} serial...</Typography.Link>
                  </Tag>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("campaign.actions")} />,
      render: (text, record, index) => {
        const listSerial = record?.serialItems?.map((item) => ({
          code: item.serial.code,
          status: item.serial.status,
        }));
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <CustomModal
                    {...{
                      footer: false,
                      width: "50%",
                      customComponent: (
                        <>
                          <SvgIcon.IconViewList />
                          {t("common.seemore")}
                        </>
                      ),
                      title: (
                        <Typography.Text strong style={{ fontSize: "20px" }}>
                          {t("serialIE.productInfo.detailSerial")}
                        </Typography.Text>
                      ),
                      hideConfirmButton: true,
                      cancelButtonLabel: t("common.close"),
                    }}
                  >
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Card
                          style={{ background: "#F7F8FB", marginBottom: "16px" }}
                          children={
                            <Row gutter={[16, 8]} className={styles[""]}>
                              <Col span={6}>
                                <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                              </Col>
                              <Col span={18}>
                                <Typography.Link strong href={`/product/detail/${record?.product?.id}`}>
                                  {record?.product?.code}
                                </Typography.Link>
                              </Col>
                              <Col span={6}>
                                <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                              </Col>
                              <Col span={18}> {record?.product?.name}</Col>
                              <Col span={6}>
                                <Typography.Text strong>{t("serialIEDetail.ticketSerial")}</Typography.Text>
                              </Col>
                              <Col span={18}>
                                <Typography.Link strong># {data?.code}</Typography.Link>
                              </Col>
                              <Col span={6}>
                                <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                              </Col>
                              <Col span={18}> {convertTotalNumber(record?.quantity)}</Col>
                              <Col span={6}>
                                <Typography.Text strong>{t("serialIE.productInfo.serialScanned")}</Typography.Text>
                              </Col>
                              <Col span={18}> {convertTotalNumber(record?.serialItems?.length)}</Col>

                              {renderSubTotal(record)}
                            </Row>
                          }
                        />
                      </Col>
                      <Col span={24}>
                        <InputSearch onSearch={(value) => handleSearchSerials(value, listSerial)} />
                      </Col>
                      <Col span={24}>
                        <Space style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <ExclamationCircleFilled style={{ color: "#EF9351" }} />
                            <Typography.Text italic type="warning">
                              {t("serialIEDetail.warning")}
                            </Typography.Text>
                          </div>
                          <Tooltip title={t("serialIE.copy")}>
                            <SvgIcon.CopyIcon
                              style={{ fontSize: "30px", cursor: "pointer" }}
                              width="24px"
                              height="24px"
                              onClick={() => copyToClipboard(record?.serialItems)}
                            />
                          </Tooltip>
                        </Space>
                      </Col>
                      <Col span={24} style={{ marginBottom: "15px" }}>
                        <div className="input-tag-container-detail">
                          <div className="input-tag-tags">
                            {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
                              <div className="message-no-data">{t("serialIEDetail.notFind")}</div>
                            ) : (
                              visibleTags &&
                              record?.serialItems?.map((item, index) => (
                                <Tag
                                  className={`input-tag-tag  ${
                                    item?.serial?.status === "AVAILABLE"
                                      ? "custom-tags-by-serial"
                                      : "custom-tags-unvailable"
                                  }`}
                                  key={index}
                                >
                                  {item?.serial?.code}
                                </Tag>
                              ))
                            )}
                            {visibleSearchResult &&
                              searchResult?.map((val) => (
                                <Tag
                                  className={`input-tag-tag  ${
                                    val?.status === "AVAILABLE" ? "custom-tags-by-serial" : "custom-tags-unvailable"
                                  }`}
                                  key={val?.code}
                                  closable
                                >
                                  {val?.code}
                                </Tag>
                              ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CustomModal>
                </Menu.Item>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => copyToClipboard(record?.serialItems)}
                  >
                    <SvgIcon.IconCoppy />
                    <Typography.Text>{t("serialIE.copy")}</Typography.Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
            className="button-action-recommend button-offers-recommend"
          >
            <SvgIcon.IconMore style={{ fontSize: "16px" }} />
          </Dropdown>
        );
      },
      width: 70,
    },
  ];

  return { columns };
};
