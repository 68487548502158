import { ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import ProductLink from "../../../../../../components/CustomRedirectLink/ProductLink";
import InputSearch from "../InputSearch";

const ModalSelectSerial = ({ record, index, quantity, copyToClipboard }) => {
  const [dataSerial, setDataSerial] = useState(record?.exportItems?.map((item) => item?.serial?.code));
  const handleSearchSerials = (values) => {
    if (values.length === 0) {
      setDataSerial(record?.exportItems?.map((item) => item?.serial?.code));
    } else {
      setDataSerial(record?.exportItems?.map((item) => item?.serial?.code).filter((item) => values.includes(item)));
    }
  };
  const renderLack = ({ quantity, scanned }) => {
    if (scanned) {
      return quantity - scanned;
    } else {
      return quantity || 0;
    }
  };

  return (
    <div className="modal-detail-export-serial">
      <Space>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "20px",
          }}
        >
          <CustomModal
            footer={false}
            width="50%"
            title={
              <Typography.Text strong style={{ fontSize: "18px" }}>
                {t("serialIE.productInfo.detailSerial")}
              </Typography.Text>
            }
            customComponent={
              <>
                <SvgIcon.IconViewList />
                {t("common.seemore")}
              </>
            }
            hideConfirmButton={true}
            cancelButtonLabel={t("serialExportDetail.close")}
            className="form-model-list-tags-export-serial"
          >
            <Row gutter={[16, 8]} style={{ marginTop: "12px" }}>
              <Col span={24}>
                <Card
                  style={{ background: "#F7F8FB", marginBottom: "16px", fontWeigth: "600" }}
                  children={
                    <Row gutter={[16, 8]}>
                      <Col span={8}>
                        <Typography.Text strong>{t("serialIE.productInfo.productCode")}</Typography.Text>
                      </Col>
                      <Col span={16}>
                        <ProductLink product={record}></ProductLink>
                      </Col>
                      <Col span={8}>
                        <Typography.Text strong>{t("serialIE.productInfo.productName")}</Typography.Text>
                      </Col>
                      <Col span={16}>
                        <Typography.Text>{record?.product?.name}</Typography.Text>
                      </Col>
                      <Col span={8}>
                        <Typography.Text strong>{t("serialIE.ExortNotes")}</Typography.Text>
                      </Col>
                      <Col span={16}>
                        <Typography.Link strong>
                          <Link to={`/import-serial/detail/${record?.serialExportNote?.id}`} target="_blank">
                            {record?.serialExportNote?.code}
                          </Link>
                        </Typography.Link>
                      </Col>
                      <Col span={8}>
                        <Typography.Text strong>{t("serialExportDetail.serialNumberOrder")}</Typography.Text>
                      </Col>
                      <Col span={16}>{quantity || record?.quantity || record?.expectedQuantity}</Col>
                      <Col span={8}>
                        <Typography.Text strong>{t("serialExportDetail.serialNumberScanned")}</Typography.Text>
                      </Col>
                      <Col span={16}>
                        <div>{dataSerial?.length}</div>
                        {
                          <Typography.Text>
                            {renderLack({
                              quantity: quantity || record?.quantity || record?.expectedQuantity || 0,
                              scanned: record?.exportItems?.length || 0,
                            }) < 0 ? (
                              <span style={{ color: "#D2434D" }}>
                                {t("serialIE.productInfo.residual")} :
                                {Math.abs(
                                  renderLack({
                                    quantity: quantity || record?.quantity || record?.expectedQuantity || 0,
                                    scanned: record?.exportItems?.length || 0,
                                  })
                                )}
                              </span>
                            ) : renderLack({
                                quantity: quantity || record?.quantity || record?.expectedQuantity || 0,
                                scanned: record?.exportItems?.length || 0,
                              }) > 0 ? (
                              <span style={{ color: "#EF9351" }}>
                                {t("serialIE.productInfo.lack")} :
                                {renderLack({
                                  quantity: quantity || record?.quantity || record?.expectedQuantity || 0,
                                  scanned: record?.exportItems?.length || 0,
                                })}
                              </span>
                            ) : (
                              <span style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</span>
                            )}
                          </Typography.Text>
                        }
                      </Col>
                    </Row>
                  }
                />
                <InputSearch onSearch={handleSearchSerials} />
                <Space
                  style={{
                    marginTop: "5px",
                    fontStyle: "italic",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <ExclamationCircleFilled style={{ color: "#EF9351" }} />{" "}
                    <Typography.Text type="warning" style={{ fontSize: "12px" }}>
                      {t("serialIEDetail.warning")}
                    </Typography.Text>
                  </div>
                  <Tooltip title={t("serialIE.copy")}>
                    <SvgIcon.CopyIcon
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      width="20px"
                      height="20px"
                      onClick={() => copyToClipboard(record)}
                    />
                  </Tooltip>
                </Space>
              </Col>
              <Col span={24}>
                <div
                  className="input-tag-tags-detail"
                  style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}
                >
                  {record?.exportItems?.length > 0
                    ? dataSerial?.map((val) => {
                        return (
                          <Tag className="input-tag-tags-detail-serial" key={val}>
                            {val}
                          </Tag>
                        );
                      })
                    : t("serialExportCreate.noCheckedSerial")}
                </div>
              </Col>
            </Row>
          </CustomModal>
        </div>
      </Space>
    </div>
  );
};
export default ModalSelectSerial;
