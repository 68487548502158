import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Typography } from "antd";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import ModalCopyProductPrice from "../ModalCopyProductPrice";
import ModalSwitchContact from "../ModalSwitchContact";

function BatchTools({ selectedRowKeys, selectedRows, handleSetSelectedRows, handleSetSelectedRowKeys }) {
  const menu = (
    <Menu>
      <Menu.Item>
        <ModalCopyProductPrice
          selectedRowKeys={selectedRowKeys}
          data={selectedRows}
          handleSetSelectedRows={handleSetSelectedRows}
          handleSetSelectedRowKeys={handleSetSelectedRowKeys}
        />
      </Menu.Item>
      <Menu.Item>
        <ModalSwitchContact
          selectedRowKeys={selectedRowKeys}
          data={selectedRows}
          handleSetSelectedRows={handleSetSelectedRows}
          handleSetSelectedRowKeys={handleSetSelectedRowKeys}
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <PrimaryButtonOutLine
        style={{ background: "white" }}
        title={
          <Space>
            <Typography.Text style={{ color: "#2246dc" }}>Công cụ hàng loạt</Typography.Text>
            <DownOutlined />
          </Space>
        }
      />
    </Dropdown>
  );
}

export default BatchTools;
