import React, { useState } from "react";
import { LoadingOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography, Upload } from "antd";
import Img from "assets/images";
import FormInput from "components/FormInput";
import { ACCEPT_IMG_TYPES, INPUT_TYPE, REGEX } from "config/constants";
import { t } from "i18next";
import { dummyRequest, notify, validateFile, validator } from "utils/helperFuncs";
import CreateSellerGroup from "features/SellerGroup/components/CreateSeller";
import { SUPPLIER_TYPE } from "features/Supplier/constant";

const styleOriginNode = {
  borderTop: "1px solid grey",
  color: "blue",
  padding: "12px",
  textAlign: "center",
  fontWeight: "600",
  cursor: "pointer", // "ant-btn ant-btn-primary btn-create"
};

function GeneralInformation({
  data,
  avatar,
  onUploadChange,
  uploading,
  // isCompany,
  typeSupplier,
  isSeller,
  warehousesOptions,
  customerOptions,
  form,
  refetch,
  onSearch,
  hasMoreData,
  loadMoreDataCustomers,
  citiesOptions,
  handleChangeCity,
  districtsOptions,
  handleChangeDistrict,
  handleSearchSelect,
  wardsOptions,
}) {
  const [open, setOpen] = useState(false);

  const formFieldsCompanyInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.taxIDNumber"),
        name: "taxIDNumber",
        rules: [
          {
            pattern: REGEX.NUMBER_WITH_HYPHEN,
            message: t("seller.validateMessage.wrongTaxIDNumber"),
          },
          {
            required: true,
            message: t("seller.validateMessage.taxIDNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.taxIDNumber"),
        maxLength: 14,
      },
    },
    {
      formItemOptions: {
        label: t("supplier.supplierName"),
        name: "fullName",
        rules: [
          {
            required: true,
            message: t("supplier.validateMessage.supplierName"),
          },
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("supplier.placeSuppilerName"),
        maxLength: 255,
      },
    },
  ];

  const formFieldsPersonalInfo = [
    {
      formItemOptions: {
        label: t("supplier.supplierName"),
        name: "fullName",
        rules: [
          { required: true, message: t("supplier.validateMessage.supplierName") },
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.fullName"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.idNumber"),
        name: "idNumber",
        rules: [
          validator({
            type: "number",
          }),
          {
            required: true,
            message: t("seller.validateMessage.idNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("supplier.placeholderInput.idNumber"),
        maxLength: 12,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.taxIDNumber"),
        name: "taxIDNumber",
        rules: [
          {
            pattern: REGEX.NUMBER_WITH_HYPHEN,
            message: t("seller.validateMessage.wrongTaxIDNumber"),
          },
          {
            required: false,
            message: t("seller.validateMessage.taxIDNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.taxIDNumber"),
        maxLength: 14,
      },
    },
  ];

  const formFieldsCommonInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.email"),
        name: "email",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("supplier.placeholderInput.email"),
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.telephone"),
        name: "telephone",
        rules: [
          { required: true, message: t("seller.validateMessage.telephone") },
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.telephone"),
        maxLength: 10,
      },
    },
    {
      type: INPUT_TYPE.SELECT_LOAD_MORE,
      formItemOptions: {
        label: t("seller.labelInput.groupCustomer"),
        name: "sellerGroupID",
        rules: [{ required: true, message: t("seller.validateMessage.groupCustomer") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.groupCustomer"),
        options: customerOptions,
        dropdownRender: (originNode) => (
          <>
            {originNode}
            <div style={styleOriginNode}>
              <CreateSellerGroup
                formCreateSeller={form}
                refetch={refetch}
                customComponent={
                  <span onClick={() => setOpen(false)}>
                    <PlusCircleFilled />
                    <span style={{ paddingLeft: "12px" }}>{t("customer.addNewFast")}</span>
                  </span>
                }
              />
            </div>
          </>
        ),
        open: open,
        onDropdownVisibleChange: (visible) => setOpen(visible),
      },
      onSearch: onSearch,
      hasMoreData: hasMoreData,
      onLoadMore: loadMoreDataCustomers,
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.cityID"),
        name: "cityID",
        rules: [{ required: true, message: t("seller.validateMessage.cityID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.cityID"),
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
        options: citiesOptions,
        onChange: handleChangeCity,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.districtID"),
        name: "districtID",
        rules: [{ required: true, message: t("seller.validateMessage.districtID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.districtID"),
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
        options: districtsOptions,
        onChange: handleChangeDistrict,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.wardID"),
        name: "wardID",
        rules: [{ required: true, message: t("seller.validateMessage.wardID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.wardID"),
        showSearch: true,

        filterOption: (search, option) => handleSearchSelect(search, option),
        options: wardsOptions,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.address"),
        name: "address",
        rules: [
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.address"),
        maxLength: 255,
      },
    },
  ];
  return (
    <Row gutter={[16, 16]}>
      <Col span={5}>
        <Space direction="vertical" align="center">
          <Typography.Text strong className="title">
            {t("supplier.avatar")}
          </Typography.Text>
          <div className="avatar">
            {uploading ? (
              <LoadingOutlined />
            ) : (
              <img
                alt="avatar"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                loading="lazy"
                src={avatar?.base64String || avatar?.src || data?.logoUrl || Img.AvatarPlaceHolder}
              />
            )}
          </div>
          <Upload
            showUploadList={false}
            onChange={onUploadChange}
            accept={ACCEPT_IMG_TYPES.join(",")}
            customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
            beforeUpload={(file) => {
              if (!validateFile(file)) {
                notify.error({
                  message: t("uploadDocument.limitErrorMessage"),
                  description: file?.name,
                });
                return Upload.LIST_IGNORE;
              }
              return validateFile(file);
            }}
          >
            <Button loading={uploading} className="upload-btn">
              {t("supplier.uploadImg")}
            </Button>
          </Upload>
          <Typography.Text italic>{t("supplier.noteAttack")}</Typography.Text>
        </Space>
      </Col>
      <Col span={19}>
        <Row gutter={[16, 16]}>
          {typeSupplier === SUPPLIER_TYPE.COMPANY
            ? formFieldsCompanyInfo.concat(formFieldsCommonInfo).map((field, index) => {
                return (
                  <Col span={8} key={index}>
                    <FormInput
                      {...{
                        ...field,
                        formItemOptions: {
                          ...field.formItemOptions,
                          label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                        },
                      }}
                    />
                  </Col>
                );
              })
            : formFieldsPersonalInfo.concat(formFieldsCommonInfo).map((field, index) => {
                return (
                  <Col span={8} key={index}>
                    <FormInput
                      {...{
                        ...field,
                        formItemOptions: {
                          ...field.formItemOptions,
                          label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                        },
                      }}
                    />
                  </Col>
                );
              })}
          {isSeller && (
            <Col span={8}>
              <FormInput
                {...{
                  type: INPUT_TYPE.SELECT,
                  formItemOptions: {
                    label: <Typography.Text strong>{t("seller.labelInput.warehouseIDs")}</Typography.Text>,
                    name: "warehouseIDs",
                    rules: [{ required: true, message: t("seller.validateMessage.warehouseIDs") }],
                  },
                  inputOptions: {
                    placeholder: t("seller.placeholderInput.warehouseIDs"),
                    options: warehousesOptions,
                    mode: "multiple",
                  },
                }}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default GeneralInformation;
