import { gql } from "@apollo/client";

export const CREATE_CART_MUTATION = gql`
  mutation CreateCart($sellerID: ID!, $items: [CartItemCreateInput!], $warehouseID: ID!) {
    cart {
      create(cart: { sellerID: $sellerID, items: $items, warehouseID: $warehouseID }) {
        id
      }
    }
  }
`;

export const DELETE_CART_MUTATION = gql`
  mutation DeleteCart($id: ID!) {
    cart {
      delete(id: $id)
    }
  }
`;

export const UPDATE_CART_ITEMS_MUTATION = gql`
  mutation updateCartItem($cartID: ID!, $request: CartItemUpdateRequestInput) {
    cart {
      updateCartItem(cartID: $cartID, request: $request)
    }
  }
`;

export const UPDATE_CART_ITEMS_MUTATION_V2 = gql`
  mutation UpdateCartItemV2($cartID: ID!, $request: CartItemUpdateRequestInput) {
    cart {
      updateCartItemV2(cartID: $cartID, request: $request) {
        result
        isNeedFetchProvider
        isNeedUpdateShippingFee
      }
    }
  }
`;

export const UPDATE_CART = gql`
  mutation UpdateCart($cartID: ID!, $request: UpdateCartInput) {
    cart {
      updateCart(cartID: $cartID, request: $request)
    }
  }
`;

export const UPDATE_CART_V2 = gql`
  mutation UpdateCartV2($cartID: ID!, $request: UpdateCartInput) {
    cart {
      updateCartV2(cartID: $cartID, request: $request) {
        result
        isNeedFetchProvider
        isNeedUpdateShippingFee
      }
    }
  }
`;

export const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem($cartID: ID!, $request: CartItemUpdateRequestInput) {
    cart {
      updateCartItem(cartID: $cartID, request: $request)
    }
  }
`;

export const UPDATE_CART_ITEM_V2 = gql`
  mutation UpdateCartItemV2($cartID: ID!, $request: CartItemUpdateRequestInput) {
    cart {
      updateCartItemV2(cartID: $cartID, request: $request) {
        result
        isNeedFetchProvider
        isNeedUpdateShippingFee
      }
    }
  }
`;

export const UPDATE_CART_SELLER = gql`
  mutation updateCartSeller($cartID: ID!, $newSellerID: ID!) {
    cart {
      updateCartSeller(cartID: $cartID, newSellerID: $newSellerID)
    }
  }
`;

export const CLONE_CART = gql`
  mutation cloneCart($cloneCartID: ID!) {
    cart {
      clone(cloneCartID: $cloneCartID) {
        id
      }
    }
  }
`;

export const EXPORT_CART = gql`
  mutation exportCart($cartID: ID!, $fileType: ExportFileType!) {
    cart {
      exportFile(cartID: $cartID, fileType: $fileType) {
        path
        url
      }
    }
  }
`;
