import React, { useEffect, useState } from "react";
import { Button, Form, TreeSelect, Upload } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { FORM_LAYOUT, FORM_TYPE, INPUT_TYPE, ACCEPT_IMG_TYPES } from "config/constants";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  notify,
  passwordGenerator,
  validator,
  dummyRequest,
  readFile,
  debounce,
  validateFile,
} from "utils/helperFuncs";
import Img from "assets/images";
import { useUpload } from "hooks/upload";
import { useCities, useDistricts, useWards } from "hooks/common";
import { useCreateUser, useGetUser, useStaffPermissions, useUpdateUser } from "hooks/user/user";
import { useGetRoles } from "hooks/role";
import { useDepartments } from "hooks/department/department";
import "./index.scss";
import { omit } from "utils/object";
import Page403 from "pages/PageError/403";
import { useGetUserPermissions } from "hooks/user/user";
import Spinner from "components/Spinner";
import { useGetBranchQuery } from "hooks/branch";
import { t } from "i18next";
import { SvgIcon } from "assets/icons";
import { useGetCategoriesLevel1 } from "hooks/category/category";
import { hasPermissionDataUser } from "../constants";

const ACCOUNT_LIST_PATH = "/account";
const MEDIUM_INPUT_LENGTH = 255;
const DEBOUNCE_TIMEOUT = 1000;

//data must be { id:"", name:"" } array
const convertOptions = (data) => {
  return data
    ? data.map(({ id, name, permissions }) => ({
        label: name,
        value: id,
        permissions: permissions,
      }))
    : [];
};
export const CreateAccountForm = ({ type = FORM_TYPE.CREATE }) => {
  const { id } = useParams();
  const { user } = useGetUser({ id });

  const [avatar, setAvatar] = useState({
    base64String: "",
    src: "",
  });
  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });

  const [uploading, setUploading] = useState(false);
  const [departmentSearchText, setDepartmentSearchText] = useState("");
  const [roleSearchText, setRoleSearchText] = useState("");
  const [permissionsSelected, setPermissionsSelected] = useState([]);
  const [isProductAll, setIsProductAll] = useState(false);
  const [rolesIdArray, setRolesIdArray] = useState([]);

  const [form] = Form.useForm();
  const { handleUpload } = useUpload();
  const history = useHistory();

  const values = form?.getFieldsValue();

  const permissionProductAll = hasPermissionDataUser(user, "product_all");

  const permissionProductAllClient = permissionsSelected
    ?.flatMap((item) => item?.map((subItem) => subItem.code))
    ?.includes("product_all");

  const { cities } = useCities();
  const { districts, refetch: refetchDistricts } = useDistricts(location?.cityId);
  const { wards, refetch: refetchWards } = useWards(location?.districtId);
  const { roles, loading: loadingRoles } = useGetRoles({
    offset: 0,
    query: roleSearchText || undefined,
  });

  const { data: dataAllCategories } = useGetCategoriesLevel1();

  const { data: dataBranch } = useGetBranchQuery();
  const { handleCreateUser } = useCreateUser();
  const { handleUpdateUser } = useUpdateUser();

  const { departments = [], loading: loadingDepartments } = useDepartments({
    filters: {
      query: departmentSearchText || undefined,
    },
    pagination: {
      offset: 0,
      limit: 100,
    },
  });

  const password = type === FORM_TYPE.CREATE ? passwordGenerator(8) : "********";

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.roleIDs) {
      setRolesIdArray(changedValues?.roleIDs);
    }
  };

  const handleSearchDepartment = ([text]) => {
    if (text[0] === " ") {
      return;
    }
    const inputText = text.length > 0 ? text : null;
    setDepartmentSearchText(inputText);
  };

  const handleDebouncedSearchDepartment = debounce(handleSearchDepartment, DEBOUNCE_TIMEOUT);

  const handleSearchRole = ([text]) => {
    if (text[0] === " ") {
      return;
    }
    const inputText = text.length > 0 ? text : null;
    setRoleSearchText(inputText);
  };

  const handleDebouncedSearchRole = debounce(handleSearchRole, DEBOUNCE_TIMEOUT);

  const validateWhitespace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(t("account.noSpace"));
    } else {
      return Promise.resolve();
    }
  };

  const handleSearchSelect = (search, option) => {
    return option?.label?.toLowerCase()?.includes(search?.toLowerCase());
  };

  const formFields = [
    {
      formItemOptions: {
        label: <b>{t("account.name")}</b>,
        name: "fullname",
        rules: [{ required: true, message: t("account.pleaseEnterName") }, { validator: validateWhitespace }],
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterName"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: <b>{t("account.code")}</b>,
        // name: 'code',
        rules: [{ required: true, message: t("account.pleaseEnterCode") }],
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterCode"),
      },
    },
    {
      formItemOptions: {
        label: <b>Email</b>,
        name: "email",
        rules: [
          { required: true, message: t("account.pleaseWenterEmail") },
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterEmailForLogin"),
      },
    },
    {
      formItemOptions: {
        label: <b>{t("account.phoneNumber")}</b>,
        name: "telephone",
        rules: [
          { required: false, message: t("account.pleaseEnterPhoneNumber") },
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterMax"),
        maxLength: 10,
      },
    },
    {
      formItemOptions: {
        label: <b>{t("account.password")}</b>,
        name: "raw_password",
        initialValue: password,
        rules: [
          { required: true, message: t("account.pleaseEnterPassword") },
          type === FORM_TYPE.CREATE &&
            validator({
              type: "password",
            }),
        ],
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterPassword"),
        maxLength: 50,
        disabled: type === FORM_TYPE.DETAIL,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.province")}</b>,
        name: "cityID",
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.enterProvince"),
        options: cities?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        onChange: handleChangeCity,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.district")}</b>,
        name: "districtID",
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.pleaseSelectDistrict"),
        options: districts?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        onChange: handleChangeDistrict,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.wards")}</b>,
        name: "wardID",
      },
      inputOptions: {
        size: "large",
        placeholder: t("account.pleaseSelectWards"),
        options: wards?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
    },
    {
      formItemOptions: {
        label: <b>{t("account.address")}</b>,
        name: "address",
        className: "address",
      },
      inputOptions: {
        placeholder: t("account.enterAdress"),
        maxLength: MEDIUM_INPUT_LENGTH,
        showCount: true,
        size: "large",
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.branch")}</b>,
        name: "branchIDs",
        rules: [{ required: true, message: t("account.messageSlectBranch") }],
      },
      inputOptions: {
        placeholder: t("account.selectBranch"),
        mode: "multiple",
        allowClear: true,
        showSearch: true,
        optionFilterProp: "label",
        placement: "topLeft",
        loading: loadingRoles,
        size: "large",
        filterOption: (search, option) => handleSearchSelect(search, option),

        onBlur: () => {
          setRoleSearchText("");
        },
        options: dataBranch,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.department")}</b>,
        name: "departmentID",
        rules: [{ required: true, message: t("account.enterDepartment") }],
      },
      inputOptions: {
        placeholder: t("account.selectDepartment"),
        allowClear: true,
        showSearch: true,
        filterOption: false,
        optionFilterProp: "label",
        placement: "topLeft",
        size: "large",
        loading: loadingDepartments,
        onSearch: handleDebouncedSearchDepartment,
        onFocus: () => {
          setDepartmentSearchText("");
        },
        options: convertOptions(departments),
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <b>{t("account.roles")}</b>,
        name: "roleIDs",
        rules: [{ required: true, message: t("account.pleaseSelectRoles") }],
      },
      inputOptions: {
        placeholder: t("account.selectRoles"),
        mode: "multiple",
        allowClear: true,
        showSearch: true,
        filterOption: false,
        optionFilterProp: "label",
        placement: "topLeft",
        loading: loadingRoles,
        size: "large",
        onSearch: handleDebouncedSearchRole,
        onBlur: () => {
          setRoleSearchText("");
        },
        onChange: (value, option) => setPermissionsSelected(option?.map(({ permissions }) => permissions)),
        options: convertOptions(roles),
      },
    },
  ];

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }

  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  function onUploadChange(info) {
    if (info.file.status === "uploading") {
      setUploading(true);
    }
    if (info.file.status === "done") {
      if (!ACCEPT_IMG_TYPES.includes(info?.file?.type)) {
        notify.error({
          message: t("account.conditionImage"),
        });
        setUploading(false);
        return;
      }
      setUploading(false);
      readFile({
        getResult: async (result) => {
          const uploadUrl = await handleUpload({ file: info?.file?.originFileObj });
          setAvatar({
            base64String: result,
            src: uploadUrl,
          });
        },
        type: "Base64String",
        file: info?.file?.originFileObj,
      });
    } else if (info.file.status === "error") {
      return;
    }
  }

  async function createUser(values) {
    try {
      await handleCreateUser({
        request: {
          avatarURL: avatar?.src,
          ...values,
        },
      });
      notify.success({
        message: t("account.createAccountSuccess"),
      });
      history.push({
        pathname: ACCOUNT_LIST_PATH,
      });
    } catch (err) {
      notify.error({
        message: t("account.createAccountFaild"),
        description: err.message,
      });
    }
  }

  function initForm() {
    form.setFieldsValue({
      ...(user || {}),
      roleIDs: user?.roles?.map(({ id }) => id),
      categoryIDs: user?.categories?.map(({ id }) => id),
      branchIDs: user?.branches?.map(({ id }) => id),
    });
  }

  async function updateUser(values) {
    try {
      // does not support update password
      values = omit(values, "raw_password");
      await handleUpdateUser({
        request: {
          id,
          avatarURL: avatar.src,
          categoryIDs: isProductAll ? values?.categoryIDs : [],
          ...values,
        },
      });
      notify.success({
        message: t("account.updateAccountSuccess"),
      });
      history.push({
        pathname: ACCOUNT_LIST_PATH,
      });
    } catch (err) {
      notify.error({
        message: t("account.updateAccountFaild"),
        description: err.message,
      });
    }
  }

  useEffect(() => {
    type === FORM_TYPE.DETAIL && initForm();
    setLocation({
      cityId: user?.cityID,
      districtId: user?.districtID,
    });
    // set avatar url
    setAvatar({
      src: user?.avatarURL,
    });
    setIsProductAll(permissionProductAll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (location?.cityId) {
      refetchDistricts(location?.cityId);
    }
    if (location?.districtId) {
      refetchWards(location?.districtId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setPermissionsSelected([...permissionsSelected]);
    setIsProductAll(permissionProductAllClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsSelected?.length]);

  useEffect(() => {
    // type === FORM_TYPE.CREATE &&
    form.setFieldsValue({
      ...(user || {}),
      categoryIDs: isProductAll ? user?.categories?.map(({ id }) => id) : [],
    });
  }, [isProductAll]);

  return (
    <div className="create-account-container">
      <PageHeader
        pageTitle={type === FORM_TYPE.DETAIL ? t("account.infoAccount") : t("account.createAccount")}
        routes={[
          {
            path: "/setting",
            name: t("account.setup"),
          },
          {
            path: "/account",
            name: t("account.accountManagament"),
          },
          {
            path: "",
            name: type === FORM_TYPE.DETAIL ? t("account.infoAccount") : t("account.createAccount"),
          },
        ]}
      />

      <Form
        onFinish={type === FORM_TYPE.DETAIL ? updateUser : createUser}
        form={form}
        {...FORM_LAYOUT}
        onValuesChange={handleValuesChange}
      >
        <div className="form-content">
          <div className="avatar-upload">
            <p className="title">{t("account.avatar")}</p>
            <div className="avatar">
              <img alt="avatar" src={avatar?.base64String || avatar?.src || Img.AvatarPlaceHolder} />
            </div>

            <div className="upload">
              <Upload
                loading={uploading}
                showUploadList={false}
                onChange={onUploadChange}
                customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
                beforeUpload={(file) => {
                  if (!validateFile(file)) {
                    notify.error({
                      message: t("uploadDocument.limitErrorMessage"),
                      description: file?.name,
                    });
                    return Upload.LIST_IGNORE;
                  }
                  return validateFile(file);
                }}
              >
                {!avatar?.base64String && !avatar?.src ? (
                  <div className="icon_upload">
                    <SvgIcon.IconUploadImage />
                    <span> {t("account.uploadImage")}</span>
                  </div>
                ) : (
                  <div className="icon_upload"> </div>
                )}
              </Upload>
              <p className="title-rules">
                <span>{t("account.conditionAvatar")}</span>
                <div>{t("account.maxFiveMB")}</div>
              </p>
            </div>
          </div>
          <div className="common-info">
            <div className="form-fields">
              {formFields.map((field, index) => {
                return <FormInput key={index} {...field} />;
              })}
              {values?.roleIDs?.length && isProductAll ? (
                <FormInput
                  {...{
                    type: INPUT_TYPE.SELECT,
                    formItemOptions: {
                      label: <b>{t("campaign.form.category")}</b>,
                      name: "categoryIDs",
                      rules: [{ required: true, message: t("account.pleaseSelectCategories") }],
                    },
                    inputOptions: {
                      placeholder: t("campaign.form.categoryPlaceholder"),
                      mode: "multiple",
                      allowClear: true,
                      showSearch: true,
                      optionFilterProp: "label",
                      placement: "topLeft",
                      loading: loadingRoles,
                      size: "large",
                      filterOption: (search, option) => handleSearchSelect(search, option),
                      onBlur: () => {
                        setRoleSearchText("");
                      },
                      options: convertOptions(dataAllCategories),
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="button-group">
              <Link
                to={{
                  pathname: "/account",
                  state: {
                    tab: "account-list",
                  },
                }}
              >
                <Button className="cancel-btn custom-button">{t("account.cancel")}</Button>
              </Link>
              <Form.Item>
                <Button className="custom-button" type="primary" htmlType="submit">
                  {t("account.save")}
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const CreateAccount = () => {
  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canCreate } = useStaffPermissions();

  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canCreate ? (
    <CreateAccountForm type={FORM_TYPE.CREATE} />
  ) : (
    <Page403 />
  );
};

export default CreateAccount;
