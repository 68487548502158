import { Space, Spin, Tabs } from "antd";
import useTabsCart from "./useTabsCart";
import "./index.scss";
import ModalSelectSeller from "../ModalSelectSeller";
import ModalRemoveCart from "../ModalRemoveCart";
import ModalMaximumCart from "../ModalMaximumCart";
import { MAXIMUM_CART } from "features/Cart/constants";
import { Fragment } from "react";
import Spinner from "components/Spinner";

const TabsCart = ({
  canCreateCart = false,
  onChangeTab,
  hasCart,
  carts,
  loadingGetAllCarts,
  warehouses,
  onGetWarehouses,
}) => {
  const {
    activeKey,
    tabs,
    onChange,
    tabBarExtraContent,
    onEdit,
    onCancelRemoveCart,
    onCancelCloneCart,
    onSubmit,
    isOpenRemoveCart,
    isOpenCloneCart,
    loading,
    loadingExportCart,
  } = useTabsCart({ onChangeTab, hasCart, carts, loadingGetAllCarts });

  return (
    <>
      <Spin spinning={loadingExportCart} />

      <Tabs
        className="cart-list"
        type="editable-card"
        activeKey={activeKey}
        onChange={onChange}
        addIcon={
          canCreateCart ? (
            <ModalSelectSeller warehouses={warehouses} onGetWarehouses={onGetWarehouses} onChange={onChange} />
          ) : null
        }
        hideAdd={tabs.length === MAXIMUM_CART}
        tabBarExtraContent={{
          right: (
            <Space>
              {tabBarExtraContent?.right?.map((item, index) => (
                <Fragment key={index}>{item}</Fragment>
              ))}
            </Space>
          ),
        }}
        destroyInactiveTabPane={true}
        onEdit={onEdit}
      >
        {tabs.map((tab) => (
          <Tabs.TabPane key={tab?.key} {...tab} />
        ))}
      </Tabs>
      <ModalRemoveCart
        open={isOpenRemoveCart}
        onCancel={onCancelRemoveCart}
        onSubmit={onSubmit}
        buttonLoading={loading}
      />
      <ModalMaximumCart open={isOpenCloneCart} onCancel={onCancelCloneCart} />
    </>
  );
};

export default TabsCart;
