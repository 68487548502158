import React from "react";
import { t } from "i18next";

import PageHeader from "components/PageHeader";
import Toolbar from "./Toolbar";
import TableSellerGroup from "./Table";
import useTable from "./Table/useTable";

function List() {
  const { columns, data, loading, paginationData, onTableChange, handleSearch, refetch } = useTable();

  return (
    <>
      <PageHeader
        pageTitle={t("customer.customerList")}
        routes={[
          {
            path: "/seller",
            name: t("common.seller"),
          },
          {
            path: `/seller-group`,
            name: t("customer.manageCustomer"),
          },
        ]}
      />
      <div className="">
        <Toolbar handleSearch={handleSearch} data={data} refetch={refetch} />
        <TableSellerGroup
          columns={columns}
          data={data}
          loading={loading}
          paginationData={paginationData}
          onTableChange={onTableChange}
        />
      </div>
    </>
  );
}

export default List;
