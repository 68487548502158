import { Form, Typography } from "antd";
import useSplitStepFirst from "./useSplitStepFirst";

export const SplitStepFirst = ({ form }) => {
  const { renderTableFullOrderFirstStep, renderTableExtraOrderFirstStep } = useSplitStepFirst({ form });

  const formValues = form.getFieldsValue();
  return (
    <div className="content-steps-1">
      <div>
        <Typography.Text className="title-type-table">ĐƠN ĐỦ HÀNG</Typography.Text>
        <Form.List name="remainPurchaseOrder">
          {(items, { remove }) => {
            return renderTableFullOrderFirstStep(formValues?.remainPurchaseOrder?.items);
          }}
        </Form.List>
      </div>
      <div>
        <Typography.Text className="title-type-table">ĐƠN BỔ SUNG HÀNG</Typography.Text>
        <Form.List name="extraPurchaseOrder">
          {(items, { remove }) => {
            return renderTableExtraOrderFirstStep(formValues?.extraPurchaseOrder?.items);
          }}
        </Form.List>
      </div>
    </div>
  );
};
