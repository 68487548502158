import React from "react";
import { Button } from "antd";
import { t } from "i18next";

function PrimaryButton({ title, ...rest }) {
  return (
    <Button type="primary" {...rest}>
      {title || t("common.save")}
    </Button>
  );
}

function PrimaryButtonOutLine({ title, style, ...rest }) {
  const primaryColor = "#2246dc";
  const styles = { color: primaryColor, borderColor: primaryColor, ...style };
  return (
    <Button style={styles} {...rest}>
      {title || t("common.save")}
    </Button>
  );
}

export { PrimaryButton, PrimaryButtonOutLine };
