import CustomModal from "components/CustomModal";
import CustomButton from "components/CustomButton";
import Card from "features/Cart/components/Card";
import styles from "../index.module.scss";
import { useHistory } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";

import { notify } from "utils/helperFuncs";
import { useState } from "react";

export default function Footer(props) {
  const { t, loadingCreateSerialExport, loadingConfirmExportOrder, handleCreate, email, totalInfo } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    if (totalInfo.stateType !== "danger") {
      setVisible(true);
    } else {
      notify.error({
        message: t("v2.keyManagement.exportNoteCreate.createFail"),
      });
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Card className={styles["form-footer"]}>
      <CustomModal
        {...{
          customComponent: <CustomButton className={styles["button-cancel"]} title={t("common.cancel")} />,
          footer: false,
          message: t("v2.keyManagement.exportNoteCreate.alertCancel"),
          centered: true,
          onOke: () => {
            history.push("/suggested-entry");
          },
          className: styles["modal-cancel"],
        }}
      />
      <CustomModal
        customComponent={
          <CustomButton
            className={styles["button-submit"]}
            type={"primary"}
            title={t("v2.keyManagement.exportAndSendEmail")}
            htmlType={"submit"}
            loading={loadingCreateSerialExport || loadingConfirmExportOrder}
          />
        }
        customIcon={<WarningOutlined />}
        onOpen={handleOpen}
        onOke={handleCreate}
        onCancel={handleClose}
        visible={visible}
        footer={false}
        title={
          <span className={styles["title"]}>
            <Trans
              i18nKey="v2.keyManagement.exportNoteCreate.alertSubmit"
              values={{ email: email }}
              components={{ style: <span className={styles["blue"]} /> }}
            ></Trans>
          </span>
        }
        subTitle={
          <span className={styles["sub-title"]}>{t("v2.keyManagement.exportNoteCreate.alertSubmitWarning")}</span>
        }
        centered={true}
        className={styles["modal-submit"]}
      />
    </Card>
  );
}
