import { Col, Form, Row, Typography } from "antd";
import "./index.scss";
import useSplitStepSecond from "./useSplitStepSecond";

export const SplitStepSecond = ({ form }) => {
  const { renderFullOrder, renderExtraOrder } = useSplitStepSecond({
    form,
  });

  return (
    <div className="split-step-second-purchase-order">
      <div>
        <Typography.Text className="title-type-table">ĐƠN ĐỦ HÀNG</Typography.Text>
        <Col span={24}>
          <Row gutter={[16, 8]}>
            <Form.List name="remainPurchaseOrder">
              {(items, { remove }) => {
                return renderFullOrder();
              }}
            </Form.List>
          </Row>
        </Col>
      </div>
      <hr className="device-split" />
      <div>
        <Typography.Text className="title-type-table">ĐƠN BỔ SUNG HÀNG</Typography.Text>
        <Col span={24}>
          <Row gutter={[16, 8]}>
            <Form.List name="extraPurchaseOrder">
              {(items, { remove }) => {
                return renderExtraOrder();
              }}
            </Form.List>
          </Row>
        </Col>
      </div>
    </div>
  );
};
