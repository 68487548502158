import React from "react";
import { Col, Row, Spin, Tooltip, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { t } from "i18next";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import CustomInfoAddress from "components/CustomInfoAddress";
import CustomModal from "components/CustomModal";
import { WarningOutlined } from "@ant-design/icons";
import { checkOptionDuplicate } from "utils/helperFuncs";

function InfoProvider({
  sellers,
  loadMoreSeller,
  hasMoreSeller,
  sellerInfo,
  handleChangeInfoSeller,
  onSearchSeller,
  visiblePopupChangeSeller,
  handleClosePopupChangeSeller,
  handleOkPopupChangeSeller,
  loadingSeller,
  sellerMemory,
}) {
  const combinedArray = (sellers || []).concat(sellerMemory || []);

  const sellerOptions = checkOptionDuplicate(combinedArray)?.map((item) => ({
    value: item?.value,
    label: (
      <Row gutter={[16]}>
        <Col span={5} className={styles["item"]} style={{ padding: "10px 0 0 20px", textAlign: "left" }}>
          <Typography.Link strong>{item?.code}</Typography.Link>
        </Col>
        <Col span={7} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "left" }}>
          <Tooltip title={item?.sellername}>
            <Typography.Text ellipsis>{item?.sellername}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={4} style={{ paddingTop: "10px", textAlign: "center" }}>
          <Typography.Text>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={8} style={{ paddingTop: "10px", textAlign: "left" }}>
          <Tooltip title={<CustomInfoAddress value={item} />}>
            <Typography.Text ellipsis>
              <CustomInfoAddress value={item} />
            </Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    seller: item,
    labelSeller: item?.sellername,
  }));
  return (
    <Row gutter={[16, 8]}>
      <CustomModal
        footer={false}
        width="600px"
        title={<WarningOutlined style={{ color: "#EF9351", fontSize: "50px" }} />}
        okeButtonLabel={t("common.confirm")}
        centered
        onOke={handleOkPopupChangeSeller}
        onCancel={handleClosePopupChangeSeller}
        visible={visiblePopupChangeSeller}
      >
        <div style={{ textAlign: "center", margin: "0px 0 20px 0" }}>
          {t("warehouse.salesReturn.questionChangeSeller")}
        </div>
      </CustomModal>
      <Col span={24}>
        <div>
          <SelectLoadMore
            formItemOptions={{
              className: "form-seller-select",
              name: "sellerID",
              rules: [{ required: true, message: t("warehouse.salesReturn.chooseSeller") }],
            }}
            inputOptions={{
              placeholder: t("warehouse.salesReturn.searchSealler"),
              options: sellerOptions,
              optionLabelProp: "labelSeller",
              onSelect: (value, options) => handleChangeInfoSeller(value, options),
              dropdownMatchSelectWidth: 1000,
              dropdownRender: (originNode) => (
                <>
                  <Row gutter={16} className={styles["custom-dropdown"]}>
                    <Col span={5} className={styles["item"]} style={{ padding: "10px 0 0 20px", textAlign: "center" }}>
                      <Typography.Text>{t("campaign.ranking.columns.codeDealer")}</Typography.Text>
                    </Col>
                    <Col span={7} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                      <Typography.Text>{t("campaign.ranking.columns.nameDealer")}</Typography.Text>
                    </Col>
                    <Col span={4} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                      <Typography.Text>{t("supplier.table.phoneNumber")}</Typography.Text>
                    </Col>
                    <Col span={8} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                      <Typography.Text>{t("supplier.table.address")}</Typography.Text>
                    </Col>
                  </Row>
                  <Spin spinning={loadingSeller}>{originNode}</Spin>
                </>
              ),
            }}
            onLoadMore={loadMoreSeller}
            hasMoreData={hasMoreSeller}
            onSearch={onSearchSeller}
          />
        </div>
      </Col>
      <Col span={24} className="form-info-provider">
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t("warehouse.salesReturn.codeSeller")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Link to={`/seller/detail/${sellerInfo?.value}`} target="_blank">
              <Typography.Link strong>{sellerInfo?.code || "---"}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t("warehouse.salesReturn.nameSeller")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.sellername || "---"}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{"SĐT"}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.telephone || "---"}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t("common.address")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>
              <CustomInfoAddress value={sellerInfo} />
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ textAlign: "center", width: "100%" }}>
            {!sellerInfo && <Typography.Link strong>{t("warehouse.salesReturn.pleaseChooseSeller")}!</Typography.Link>}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoProvider;
