import React from "react";
import useFiltersTag from "./useFiltersTag";
import { PROMOTION_PRICE_BID_STATUS } from "features/Products/constants";

export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  sellerOptions,
  optionOwnerType,
  OPTION_PRICE_BID_STATUS,
  rangeDateTimeFilter,
  statusTabs,
}) => {
  const {
    renderTagsSellerID,
    renderTagCreatedAtFilter,
    renderTagsOwnerType,
    renderTagsStatusPriceBid,
    renderTagsLastEventTypes,
    renderTagsLastPerformUsers,
  } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    sellerOptions,
    optionOwnerType,
    OPTION_PRICE_BID_STATUS,
    rangeDateTimeFilter,
  });

  return (
    <div style={{ marginBottom: "16px" }}>
      {params?.filters?.status?.length > 0 && statusTabs === PROMOTION_PRICE_BID_STATUS.ALL
        ? renderTagsStatusPriceBid
        : null}
      {params?.filters?.sellerIDs?.length > 0 ? renderTagsSellerID : ""}
      {params?.filters?.ownerType?.length > 0 ? renderTagsOwnerType : ""}
      {params?.filters?.lastEventTypes?.length > 0 ? renderTagsLastEventTypes : ""}
      {params?.filters?.lastPerformUsers?.ids?.length > 0 || params?.filters?.lastPerformUsers?.types?.length > 0
        ? renderTagsLastPerformUsers
        : ""}
      {rangeDateTimeFilter?.from !== undefined ? renderTagCreatedAtFilter : ""}
    </div>
  );
};
