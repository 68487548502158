import React, { useMemo, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Space, Tree } from "antd";
import MenuLayout from "components/CustomFilterMenu/MenuLayout";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon, Icon } from "assets/icons";
import { useGetProductCategories } from "hooks/category/category";
import { find } from "lodash";

//converted source data
const convertData = (data) => {
  if (data)
    return data?.map((item) => {
      if (item?.children?.length > 0)
        return {
          title: item.name,
          key: item.id,
          level: item.level,
          parentKey: item.parentID,
          children: convertData(item.children),
        };
      else
        return {
          title: item.name,
          key: item.id,
          level: item.level,
          parentKey: item.parentID,
        };
    });
  else return [];
};

//flattened converted data
const flattenData = (data) => {
  return data.reduce(function (flattened, { title, key, level, parentKey, children }) {
    return flattened.concat([{ title, key, level, parentKey }]).concat(children ? flattenData(children) : []);
  }, []);
};

const CategoryTreeFilter = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();

  const [checkedKeys, setCheckedKeys] = useState(defaultValue || []);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const { data } = useGetProductCategories();
  const convertedData = convertData(data);
  const flattenedData = flattenData(convertedData);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChecked = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
    setExpandedKeys(checkedKeys);
    setAutoExpandParent(true);
  };

  const handleFilter = () => {
    onFilter && onFilter(checkedKeys);
  };

  const handleResetFilter = () => {
    setCheckedKeys([]);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const newExpandedKeys = flattenedData
      .filter((item) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1)
      .map((item) => {
        if (item.parentKey) return item.parentKey;
        else return item.key;
      });
    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const renderBtnText = () => {
    return defaultValue
      .map((item) => find(flattenedData, { key: item }))
      .map((item) => item?.title)
      .join(", ");
  };

  //loop through converted data and highlight the searched title
  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const strTitleLower = strTitle.toLocaleLowerCase();
        const index = strTitleLower.indexOf(searchValue.toLocaleLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: "#f50" }}>{strTitle.slice(index, index + searchValue.length)}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
          };
        }

        return {
          title,
          key: item.key,
        };
      });

    return loop(convertedData);
  }, [searchValue, convertedData]);

  return (
    <Dropdown
      trigger="click"
      overlay={
        <MenuLayout
          style={{ width: "400px" }}
          applyFilter={handleFilter}
          resetFilter={handleResetFilter}
          filterContent={
            <>
              <Input
                prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
                placeholder={t("common.search")}
                allowClear={true}
                onChange={handleSearch}
              />
              <Tree
                height={400}
                checkable={true}
                selectable={false}
                showLine={{ showLeafIcon: false }}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                checkedKeys={checkedKeys}
                treeData={treeData}
                onCheck={onChecked}
              />
            </>
          }
        />
      }
    >
      <Button>
        <Space>
          <div style={{ width: "175px", textOverflow: "ellipsis", overflow: "hidden" }}>
            {defaultValue ? renderBtnText() : t("product.productCategoryLabel")?.toLocaleUpperCase()}
          </div>

          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default CategoryTreeFilter;
