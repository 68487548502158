import React from "react";
import { t } from "i18next";
import { Col, Form, Row, Tag, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import PageHeader from "components/PageHeader";
import SupplierToolbar from "./Toolbar";
import CustomTable from "components/CustomTable";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import TextAlignLeft from "components/TextAlignLeft";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { isCustomerOptions, statusOptions, supplierTypeLabel, supplierTypeOptions } from "../constant";
import { FilterLoadMore } from "components/CustomFilterMenu";

import useSupplierList from "./useSupplierList";
import ModalSwitch from "./ModalSwitch";
import useFilter from "./useFilterLoadmore";

function SupplierList() {
  const { hasMoreData, options, loadMoreDataCustomers, onSearch } = useFilter();
  const {
    params,
    reason,
    supplierList,
    paginationData,
    loading,
    form,
    initialValue,
    selectedSwitch,
    isActiveSupplier,
    handleSearch,
    onChangeStatus,
    onChangeParams,
    onFilter,
    onTableChange,
    handleValuesChange,
    filterSellerByTimeRange,
  } = useSupplierList();

  const columns = [
    {
      title: <Typography.Text strong>{t("supplier.table.stt")}</Typography.Text>,
      align: "center",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: <Typography.Text strong>{t("supplier.table.supplierCode")}</Typography.Text>,
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <Link to={`/supplier/detail/${record?.id}`}>
            <TextAlignLeft value={record?.code} strong={true} color="#2246DC" />
          </Link>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("supplier.table.supplierName")}</Typography.Text>,
      align: "center",
      render: (_, record) => {
        const supplierName = record?.fullName;
        return (
          <Tooltip title={supplierName}>
            <TextAlignLeft value={truncateAddDots(supplierName, 80)} />
          </Tooltip>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("supplier.table.phoneNumber")}</Typography.Text>,
      width: 200,
      align: "center",
      render: (_, record) => {
        return <Typography.Text>{record?.telephone || "--"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text strong>{t("supplier.table.address")}</Typography.Text>,
      align: "center",
      render: (_, record) => {
        const fullAddress = `${record?.address ? record?.address + "," : ""} ${record?.ward?.name}, ${
          record?.district?.name
        }, ${record?.city?.name}`;

        return (
          <Tooltip title={fullAddress}>
            <TextAlignLeft value={truncateAddDots(fullAddress, 80)} />
          </Tooltip>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("supplier.table.customerGroup")}</Typography.Text>,
      width: 250,
      align: "center",
      render: (_, record) => {
        const sellerGroupName = record?.sellerGroup?.name;
        return (
          <Tooltip title={sellerGroupName}>
            <TextAlignLeft value={truncateAddDots(sellerGroupName)} />
          </Tooltip>
        );
      },
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilter("sellerGroupIDs", value)}
          data={options}
          onLoadMoreData={loadMoreDataCustomers}
          onSearchTextChange={onSearch}
          hasMore={hasMoreData}
        />
      ),
    },
    {
      title: <Typography.Text strong>{t("supplier.table.supplierType")}</Typography.Text>,
      align: "center",
      width: 120,
      render: (_, record) => {
        return <Tag>{supplierTypeLabel[record?.type]}</Tag>;
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("types", value)} type="radio" options={supplierTypeOptions} />
      ),
    },
    {
      title: <Typography.Text strong>{t("supplier.table.isCustomer")}</Typography.Text>,
      align: "center",
      width: 120,
      render: (_, record) => {
        return record?.isSeller ? <CheckOutlined style={{ color: "#2246DC", fontSize: 22 }} /> : <CloseOutlined />;
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("isSeller", value)} type="radio" options={isCustomerOptions} />
      ),
    },
    {
      title: <Typography.Text strong>{t("supplier.table.status")}</Typography.Text>,
      align: "center",
      width: 30,
      render: (_, record) => {
        return (
          <Form form={form} initialValues={initialValue} onValuesChange={handleValuesChange}>
            <ModalSwitch
              selectedSwitch={selectedSwitch}
              form={form}
              record={record}
              onChangeStatus={onChangeStatus}
              isActiveSupplier={isActiveSupplier}
              reason={reason}
            />
          </Form>
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("isActiveSupplier", value)} type="radio" options={statusOptions} />
      ),
    },
  ];

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("supplier.supplierList")}
          routes={[
            { path: "/supplier-list", name: t("supplier.title") },
            { path: "#", name: t("supplier.supplierManagement") },
          ]}
        />
      </Col>
      <Col span={24}>
        <SupplierToolbar
          params={params}
          onChangeParams={onChangeParams}
          customerGroupOptions={options}
          supplierTypeOptions={supplierTypeOptions}
          onSearch={handleSearch}
          filterSellerByTimeRange={filterSellerByTimeRange}
        />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={columns}
          dataSource={supplierList}
          rowKey={(record) => record?.id}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
}

export default SupplierList;
