import { Col, Descriptions, Row } from "antd";
import { renderLabelStatus } from "features/Order/helper";
import { t } from "i18next";
import { Link } from "react-router-dom";
import ModalTrackingOrder from "../ModalTrackingOrder";

function OrderInfo({ dataDetail }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
          <Descriptions.Item label={t("serialIE.providerInfo.orderCode")}>
            <Link to={`/order/${dataDetail?.order?.id}`} target="_blank">
              <b>#{dataDetail?.order?.number || "- -"}</b>
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.status")}>
            {renderLabelStatus[dataDetail?.order?.status]}
            <ModalTrackingOrder data={dataDetail} />
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.seller")}>
            {dataDetail?.order?.seller?.fullName || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.phone")}>
            {dataDetail?.order?.seller?.telephone || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.email")}>
            {dataDetail?.order?.seller?.email || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.warehouse")}>
            {dataDetail?.order?.exportedWarehouse?.name || "- -"}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default OrderInfo;
