import { t } from "i18next";

export const CAMPAIGN_ACTIONS = {
  ACTIVE: true,
  DISABLED: false,
};

export const PROMOTION_CAMPAIGN_TYPE = {
  TOP_SALES: "TOP_SALES",
  COMBO_PROMO: "COMBO_PROMO",
  PRODUCT_PROMO: "PRODUCT_PROMO",
};

export const PROMOTION_CAMPAIGN_STATUS = {
  ALL: "ALL",
  UPCOMING: "UPCOMING",
  ON_GOING: "ON_GOING",
  RECONCILING: "RECONCILING",
  ENDED: "ENDED",
};

export const TABS_KEY = {
  CAMPAIGN_INFO: "campaignInfo",
  RANKING_BOARD: "rankingBoard",
};

export const COMBO_CAMPAIGN = {
  DISCOUNT_MONEY: "DISCOUNT_MONEY",
  GIFT: "GIFT",
  FIXED_PRICE: "FIXED_PRICE",
  DISCOUNT_PRODUCT: "DISCOUNT_PRODUCT",
};

export const CONDITION_PRODUCT_TYPE = {
  CONDITION_PRODUCT: "CONDITION_PRODUCT",
  GIFT_PRODUCT: "GIFT_PRODUCT",
};

export const COMBO_CAMPAIGN_LABEL = {
  [`${COMBO_CAMPAIGN.DISCOUNT_MONEY}`]: t("campaign.combo.comboDiscount"),
  [`${COMBO_CAMPAIGN.GIFT}`]: t("campaign.combo.comboGift"),
  [`${COMBO_CAMPAIGN.FIXED_PRICE}`]: t("campaign.combo.comboFixedPrice"),
};

export const DISCOUNT_TYPE = {
  DISCOUNT_MONEY: "DISCOUNT_MONEY",
  DISCOUNT_PERCENTAGE: "DISCOUNT_PERCENTAGE",
};

export const DISCOUNT_TYPE_LABEL = {
  [`${DISCOUNT_TYPE.DISCOUNT_MONEY}`]: t("campaign.combo.discountMoney"),
  [`${DISCOUNT_TYPE.DISCOUNT_PERCENTAGE}`]: t("campaign.combo.discountPercent"),
};

export const OPTIONS_TYPE_DISCOUNT = [
  { value: DISCOUNT_TYPE.DISCOUNT_MONEY, label: t("campaign.combo.discountMoney") },
  { value: DISCOUNT_TYPE.DISCOUNT_PERCENTAGE, label: t("campaign.combo.discountPercent") },
];

export const TYPE_LIMIT = {
  LIMIT: "LIMITED",
  NO_LIMIT: "UNLIMITED",
};

export const OPTIONS_TYPE_LIMIT = [
  { value: TYPE_LIMIT.LIMIT, label: t("campaign.combo.limit") },
  { value: TYPE_LIMIT.NO_LIMIT, label: t("campaign.combo.noLimit") },
];

export const PRODUCT_TYPE = {
  CONDITION_PRODUCT: "CONDITION_PRODUCT",
  GIFT_PRODUCT: "GIFT_PRODUCT",
};

export const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

export const convertOrderSortBy = {
  deliveryDeadline: "DELIVER_DEADLINE",
  createdAt: "CREATED_AT",
};

export const CAMPAIGN_TYPE = {
  COMBO_PROMO: "COMBO_PROMO",
  PRODUCT_PROMO: "PRODUCT_PROMO",
  TOP_SALES: "TOP_SALES",
};
