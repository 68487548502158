import { Form, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import SelectSeller from "../SelectSeller";
import useModalSelectSelect from "./useModalSelectSelect";

const ModalSelectSeller = ({ onChange, warehouses, onGetWarehouses }) => {
  const { form, onSubmit, loading, switchClose } = useModalSelectSelect({ onChange });

  const handleValuesChange = (valueChange, allValues) => {
    if (valueChange?.sellerID) {
      form.setFieldsValue({
        sellerID: valueChange?.sellerID,
        warehouseID: null,
      });
    }
  };

  return (
    <CustomModal
      title={<Typography.Text strong>{t("cart.create.selectSeller")}</Typography.Text>}
      footer={false}
      centered={true}
      customComponent={<SvgIcon.AddCartIcon />}
      isBlockCloseOnOke
      onOke={onSubmit}
      buttonLoading={loading}
      switchClose={switchClose}
    >
      <Form form={form} onValuesChange={handleValuesChange}>
        <SelectSeller
          isPadding
          isModal={true}
          onGetWarehouses={onGetWarehouses}
          warehouses={warehouses}
          style={{ justifyContent: "flex-end" }}
        />
      </Form>
    </CustomModal>
  );
};

export default ModalSelectSeller;
