import { FileExcelOutlined, FilePdfOutlined, FilePptOutlined, FileWordTwoTone } from "@ant-design/icons";
import { Image, Space, Spin, Tooltip, Typography } from "antd";
import { t } from "i18next";
import { useState } from "react";
import "./index.scss";

const CustomViewDocumentFiles = ({ file }) => {
  let xlsx = file?.indexOf(".xlsx") !== -1;
  let docx = file?.indexOf(".docx") !== -1;
  let ppt = file?.indexOf(".ppt") !== -1;
  let pdf = file?.indexOf(".pdf") !== -1;
  const urlPathname = new URL(file);
  const filename = urlPathname.pathname.split("/").pop();

  const [loading, setLoading] = useState(false);

  const onLoading = () => {
    setLoading(true);
  };

  const handleDownloadFile = (file, filename) => {
    onLoading();
    fetch(file)
      .then((response) => {
        response.ok && setLoading(false);
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        link.download = filename;
        link.click();
      });
  };

  if (xlsx) {
    return (
      <div className="custom-view-document-files">
        <Spin spinning={loading}>
          <Tooltip title={t("common.onClickDownload")}>
            <Space className="file-size" direction="vertical" align="center">
              <FileExcelOutlined
                style={{ color: "green", fontSize: "50px" }}
                onClick={() => handleDownloadFile(file, filename)}
              />
              <Typography.Link onClick={() => handleDownloadFile(file, filename)}>{filename}</Typography.Link>
            </Space>
          </Tooltip>
        </Spin>
      </div>
    );
  }
  if (docx) {
    return (
      <div className="custom-view-document-files">
        <Spin spinning={loading}>
          <Tooltip title={t("common.onClickDownload")}>
            <Space className="file-size" direction="vertical" align="center">
              <FileWordTwoTone
                style={{ color: "blue", fontSize: "50px" }}
                onClick={() => handleDownloadFile(file, filename)}
              />
              <Typography.Link onClick={() => handleDownloadFile(file, filename)}>{filename}</Typography.Link>
            </Space>
          </Tooltip>
        </Spin>
      </div>
    );
  }
  if (ppt) {
    return (
      <div className="custom-view-document-files">
        <Spin spinning={loading}>
          <Tooltip title={t("common.onClickDownload")}>
            <Space className="file-size" direction="vertical" align="center">
              <FilePptOutlined
                style={{ color: "yellow", fontSize: "50px" }}
                onClick={() => handleDownloadFile(file, filename)}
              />
              <Typography.Link onClick={() => handleDownloadFile(file, filename)}>{filename}</Typography.Link>
            </Space>
          </Tooltip>
        </Spin>
      </div>
    );
  }
  if (pdf) {
    return (
      <div className="custom-view-document-files">
        <Spin spinning={loading}>
          <Tooltip title={t("common.onClickDownload")}>
            <Space className="file-size" direction="vertical" align="center">
              <FilePdfOutlined
                style={{ color: "red", fontSize: "50px" }}
                onClick={() => handleDownloadFile(file, filename)}
              />
              <Typography.Link onClick={() => handleDownloadFile(file, filename)}>{filename}</Typography.Link>
            </Space>
          </Tooltip>
        </Spin>
      </div>
    );
  }

  return (
    <div className="custom-view-document-files">
      <Image src={file} className="img-size" />
    </div>
  );
};

export default CustomViewDocumentFiles;
