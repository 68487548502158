import { Skeleton } from "antd";
import "./index.scss";

function SkeletonCustom({ oneLine, children, loading = true, paragraph, props, rows = 1 }) {
  if (oneLine) {
    return (
      <Skeleton.Button
        active
        size={"small"}
        style={{
          width: "200px",
          height: "20px",
        }}
      />
    );
  }

  return (
    <Skeleton className="skeleton-custom" loading={loading} paragraph={paragraph || { rows: rows }} active {...props}>
      {children}
    </Skeleton>
  );
}

export default SkeletonCustom;
