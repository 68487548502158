import { TabsMenu } from "components/CustomTabs";
import "./styles.scss";

const TabsCard = ({ tabs, onChange, ...rest }) => {
  return (
    <TabsMenu
      type="card"
      tabs={tabs}
      tabBarGutter={10}
      moreIcon={null}
      className="tabs-card"
      onChange={onChange}
      {...rest}
    />
  );
};

export default TabsCard;
