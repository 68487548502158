import useFiltersTag from "./useFiltersTag";

export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  staffOptions,
  importNotTypeOptions,
  optionExportRadio,
  warehouseOptions,
  sellersOptions,
}) => {
  const { renderDateTag, renderTags } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    staffOptions,
    importNotTypeOptions,
    optionExportRadio,
    warehouseOptions,
    sellersOptions,
  });

  return (
    <div style={{ margin: "12px 0", display: "flex", flexWrap: "wrap" }}>
      {renderTags}
      {renderDateTag()}
    </div>
  );
};
