import List from "features/Cart/components/List";
import React from "react";
import { Affix, Button, Col, Row, Space, Spin, Typography } from "antd";
import { t } from "i18next";
import { getQuery } from "utils/helperFuncs";
import { useGetOrderDetail } from "hooks/order";
import { renderLabelStatus } from "../helper";
import { ASSIGN_UPDATE_ORDER_BY_STATUS } from "../constants";
import useEdit from "./useEdit";
import Page404 from "pages/PageError/404";

const Edit = () => {
  const { orderID } = getQuery();
  const { order, loading, error } = useGetOrderDetail({ id: orderID });
  const { onCancelUpdateDetail, onConfirmUpdateDetail } = useEdit();

  if (!orderID || error) {
    return <Page404 />;
  }

  if (loading) {
    return (
      <Space align="center" style={{ width: "100%", justifyContent: "center", height: "100%" }}>
        <Spin />
      </Space>
    );
  }

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Row gutter={[0, 8]} style={{ background: "#ffffff", padding: "16px", borderRadius: "8px", width: "100%" }}>
          <Col xxl={2} xl={3}>
            <Typography.Text strong>{t("order.orderDetail.orderCode")}</Typography.Text>
          </Col>
          <Col xxl={3} xl={4}>
            <Typography.Text style={{ color: "#052987" }}>#{order?.number}</Typography.Text>
          </Col>

          <Col xxl={6} xl={8}>
            <Row>
              <Col span={6}>
                <Typography.Text strong>{t("order.orderDetail.orderStatus")}</Typography.Text>
              </Col>
              <Col span={18}>
                <Typography.Text>{renderLabelStatus[order?.status]}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <List useInOrder={ASSIGN_UPDATE_ORDER_BY_STATUS[order?.status]} />
      </Col>
      <Col span={24}>
        <Affix offsetBottom={10}>
          <Space
            style={{
              background: "#FFFFFF",
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button onClick={onCancelUpdateDetail}>Hủy bỏ</Button>
            <Button type="primary" onClick={onConfirmUpdateDetail}>
              Lưu lại
            </Button>
          </Space>
        </Affix>
      </Col>
    </Row>
  );
};

export default Edit;
