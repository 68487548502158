import { Button, Space } from "antd";
import React, { useState } from "react";
import CustomModalCancelOrder from "../CustomModalCancelOrder";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";
import CustomModalApprovalOrder from "../CustomModalApprovalOrder";
import { useOrderPermissions } from "hooks/order";

const WaitingForApprovalOrderButtons = ({ checkedKeyList, selectedList, resetCheckedList }) => {
  const [openModalCancelOrder, setOpenModalCancelOrder] = useState(false);
  const [openModalApprovalOrder, setOpenModalApprovalOrder] = useState(false);
  const { canApproveOrder } = useOrderPermissions();

  const handleCancelOrder = () => {
    if (selectedList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      setOpenModalCancelOrder(true);
    }
  };

  const handleApprovalOrder = () => {
    if (selectedList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      if (selectedList.every((item, index, arr) => item.preOrder === arr[0].preOrder)) {
        setOpenModalApprovalOrder(true);
      } else {
        notify.error({
          message: "Không thể thực hiện duyệt đơn do loại đơn khác nhau",
        });
      }
    }
  };

  return (
    <Space>
      <Button onClick={handleCancelOrder} style={{ color: "#2246dc", border: "1px solid #2246dc" }}>
        {t("order.orderList.cancelOrder")}
      </Button>
      {openModalCancelOrder && (
        <CustomModalCancelOrder
          setOpenModalCancelOrder={setOpenModalCancelOrder}
          orderIDs={checkedKeyList}
          resetCheckedList={resetCheckedList}
          selectedList={selectedList}
        />
      )}

      {canApproveOrder && (
        <Button onClick={handleApprovalOrder} type="primary">
          {t("order.approvalOrder.title")}
        </Button>
      )}
      {openModalApprovalOrder && (
        <CustomModalApprovalOrder
          setOpenModalApprovalOrder={setOpenModalApprovalOrder}
          orderIDs={checkedKeyList}
          selectedList={selectedList}
          resetCheckedList={resetCheckedList}
        />
      )}
    </Space>
  );
};

export default WaitingForApprovalOrderButtons;
