import { Button, Form, Steps, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomCard from "components/CustomCard";
import CustomModal from "components/CustomModal";
import { styleHeaderCard } from "features/Purchase/constant";
import { t } from "i18next";
import { SplitStepFirst } from "./SplitStepFirst";
import { SplitStepSecond } from "./SplitStepSecond";
import "./index.scss";
import useButtonSplitOrder from "./useButtonSplitOrder";

const { Step } = Steps;

export const ButtonSplitOrder = ({ record }) => {
  const {
    form,
    visible,
    showModal,
    handleCancel,
    handleNextStep,
    handlePrevStep,
    current,
    initialValues,
    handleValuesChange,
    onSplitPurchaseOrder,
    loading,
    visibleConfirm,
    setVisibleConfirm,
    handleCancelConfirm,
  } = useButtonSplitOrder({
    record,
  });

  const steps = [
    {
      title: "Thông tin sản phẩm",
      content: <SplitStepFirst form={form} />,
    },
    {
      title: "Thông tin thanh toán",
      content: <SplitStepSecond form={form} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <Form form={form} name="validate_other" initialValues={initialValues} onValuesChange={handleValuesChange}>
      <CustomModal
        {...{
          width: "80%",
          title: (
            <span style={{ fontSize: "18px" }}>
              <Typography.Text strong>{"Phân tách đơn mua hàng"}</Typography.Text>{" "}
              <Typography.Link strong>{record?.code}</Typography.Link>
            </span>
          ),
          customComponent: (
            <Button type="primary" loading={loading}>
              {"Tách đơn"}
            </Button>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              {current === 0 && (
                <>
                  <Button type="secondary" htmlType="submit" onClick={handleCancel} loading={loading}>
                    {"Huỷ bỏ"}
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={handleNextStep} loading={loading}>
                    {"Tiếp tục"}
                  </Button>
                </>
              )}
              {current === 1 && (
                <>
                  <Button type="secondary" htmlType="submit" onClick={handlePrevStep} loading={loading}>
                    {"Quay lại"}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setVisibleConfirm(true);
                    }}
                    loading={loading}
                  >
                    {"Tách đơn"}
                  </Button>
                </>
              )}
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
          className: "button-split-order-purchase-order",
        }}
      >
        <div className="form-split-order-purchase-order">
          <CustomCard
            headStyle={styleHeaderCard}
            title={
              <Steps labelPlacement="horizontal" current={current}>
                {items?.map((event, index) => (
                  <Step key={index} title={<span className={["title"]}>{event.title}</span>} />
                ))}
              </Steps>
            }
            content={<div className="steps-content">{steps[current].content}</div>}
          />
        </div>
      </CustomModal>
      <CustomModal
        {...{
          width: "30%",
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          className: "form-accept-purchase-order",
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              <Button type="secondary" htmlType="submit" onClick={handleCancelConfirm} loading={loading}>
                {t("productBrand.cancel")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onSplitPurchaseOrder} loading={loading}>
                {t("common.confirm")}
              </Button>
            </div>
          ),
          visible: visibleConfirm,
          onOpen: showModal,
          onCancel: handleCancelConfirm,
          hideCancelButton: true,
        }}
      >
        <div className="form-accept-content">
          <SvgIcon.IconInfoConfirm />
          <div>
            <Typography.Text>
              <b>Bạn có chắc chắn muốn tách đơn mua hàng không?</b>
              <br />
              <i style={{ fontSize: "11px" }}>
                Lưu ý: Sau khi xác nhận tách đơn mua hàng, hệ thống sẽ không hỗ trợ bạn tạo lại đơn mua hàng ban đầu!
              </i>
            </Typography.Text>
          </div>
        </div>
      </CustomModal>
    </Form>
  );
};
