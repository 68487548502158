import { Col, Row, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomInputGroup from "components/CustomInputGroup";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import {
  PAYMENT_TERMS,
  optionCurrency,
  optionPaymentRequest,
  styleHeaderCard,
  styleTitleCard,
} from "features/Purchase/constant";
import { t } from "i18next";

function PaymentInfo({ paymentTerms }) {
  const paymentTermsOptions = [
    {
      value: PAYMENT_TERMS.ADVANCE_PAYMENT,
      label: t("purchase.deposit"),
    },
    {
      value: PAYMENT_TERMS.CASH_ON_DELIVERY,
      label: <span style={{ textWrap: "nowrap" }}>{t("purchase.paymentOnDelivery")}</span>,
    },
    {
      value: PAYMENT_TERMS.DEFERRED_PAYMENT,
      label: t("purchase.payLater"),
    },
  ];

  const inputOptionsFirst = {
    placeholder: 0,
    maxLength: 11,
    min: 0,
  };

  return (
    <CustomCard
      headStyle={styleHeaderCard}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.paymentInfo")}
        </Typography.Text>
      }
      content={
        <Row gutter={[16, 8]} style={{ display: "flex", justifyContent: "space-between" }}>
          <Col span={24} xl={12} style={{ maxWidth: 500 }}>
            <Space direction="vertical" style={{ width: "100%", justifyContent: "space-between" }}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                {...{
                  formItemOptions: {
                    label: <b>{t("purchase.paymentMethods")}</b>,
                    labelCol: { span: 24, offset: 0 },
                    name: "paymentMethods",
                    rules: [
                      {
                        required: true,
                        message: t("purchase.rules.selectPaymentMethod"),
                      },
                    ],
                  },
                  inputOptions: {
                    placeholder: t("purchase.placeHolder.selectPaymentMethod"),
                    mode: "multiple",
                    allowClear: true,
                    showSearch: true,
                    filterOption: false,
                    optionFilterProp: "label",
                    placement: "topLeft",
                    options: optionPaymentRequest,
                  },
                }}
              />
              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{
                  label: <b>{t("purchase.transferContent")}</b>,
                  labelCol: { span: 24, offset: 0 },
                  name: "transferNote",
                }}
                inputOptions={{
                  rows: 3,
                  placeholder: t("purchase.placeHolder.typeTransferContent"),
                }}
              />
            </Space>
          </Col>
          <Col span={24} xl={12} style={{ paddingLeft: "18px" }} className="paymentTerms">
            <Row
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                gap: 16,
              }}
            >
              <Col span={8}>
                <FormInput
                  {...{
                    type: INPUT_TYPE.CHECK_BOX_GROUP,
                    formItemOptions: {
                      label: <b>{t("purchase.paymentPolicy")}</b>,
                      labelCol: { span: 24, offset: 0 },
                      name: "paymentTerms",
                      rules: [
                        {
                          required: true,
                          message: t("purchase.rules.paymentPolicy"),
                        },
                      ],
                    },
                    inputOptions: {
                      options: paymentTermsOptions,
                      style: {
                        display: "inline-flex",
                        flexDirection: "column",
                        gap: 40,
                        marginTop: 12,
                      },
                    },
                  }}
                />
              </Col>
              <Col
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  gap: 30,
                  marginTop: 20,
                }}
              >
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={!paymentTerms?.includes(PAYMENT_TERMS.ADVANCE_PAYMENT)}
                  formItemOptionsFirst={{
                    name: "paymentDepositAmount",
                    rules: [
                      {
                        required: paymentTerms?.includes(PAYMENT_TERMS.ADVANCE_PAYMENT) && true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={!paymentTerms?.includes(PAYMENT_TERMS.CASH_ON_DELIVERY)}
                  formItemOptionsFirst={{
                    name: "paymentOnDeliveryAmount",
                    rules: [
                      {
                        required: paymentTerms?.includes(PAYMENT_TERMS.CASH_ON_DELIVERY) && true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={!paymentTerms?.includes(PAYMENT_TERMS.DEFERRED_PAYMENT)}
                  formItemOptionsFirst={{
                    name: "paymentDebtAmount",
                    rules: [
                      {
                        required: paymentTerms?.includes(PAYMENT_TERMS.DEFERRED_PAYMENT) && true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
}

export default PaymentInfo;
