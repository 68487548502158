import { Col, Form, Row, Spin, Typography } from "antd";
import SearchableSelect from "components/CustomSelect/SearchableSelect";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { SubTitleCard, TitleCard } from "../../components/TitleCard";
import UiByImportType from "../../components/UiByImportType";
import { IMPORT_TYPE } from "../../constant";
import styles from "../../index.module.scss";

import AffixButton from "./components/AffixButton";
import FooterTable from "./components/FooterTable";
import SelectProduct from "./components/SelectProduct";
import SelectSerial from "./components/SelectSerial";
import TableProduct from "./components/Table";
import { SelectWarehouseToAll } from "./SelectWarehouseToAll";
import useCreateSerial from "./useCreateSerial";

function CreateSerial() {
  const {
    form,
    hasMoreProvider,
    warehouseOptions,
    providerOptions,
    hasMoreOrder,
    loading,
    orders,
    initialValues,
    providerInfo,
    importType,
    orderInfo,
    serialInfo,
    dataTableFirstTimeImport,
    dataTableReturn,
    loadingCreateBtn,
    loadingCreate,
    tags,
    visible,
    dataTableReImportWarranty,
    pendingWarrantyNoteLoading,
    loadMoreProvider,
    onSearch,
    loadMoreOrder,
    handleRemoveSerial,
    handleRemoveProduct,
    handleValuesChange,
    handleChangeInfo,
    handleGetSerial,
    handleGetProduct,
    handleGetProductTransfer,
    handleFinish,
    handleGetTags,
    onCancelCreate,
    handleChange,
    handleCreate,
    showOffModalConfirmCreate,
    handleSerialSelected,
    handleGetTableOrder,
    loadingWarehouse,
    warehouseInfo,
    handleSelectWarehouse,
    handleClearAllSerials,
  } = useCreateSerial();

  const sumSerialByOrder = useMemo(() => sumBy(dataTableFirstTimeImport, "quantity") || 0, [dataTableFirstTimeImport]);
  const sumSerialByScanned = useMemo(() => sumBy(dataTableFirstTimeImport, "scanned") || 0, [dataTableFirstTimeImport]);
  const sumSerialByLack = useMemo(() => sumBy(dataTableFirstTimeImport, "lack") || 0, [dataTableFirstTimeImport]);

  const sumSerialByOrderTypeReimport = useMemo(() => sumBy(dataTableReturn, "quantity") || 0, [dataTableReturn]);
  const sumReturnByOrderTypeReimport = useMemo(
    () => sumBy(dataTableReturn, "serialItems.length") || 0,
    [dataTableReturn]
  );
  const sumLackByOrderTypeReimport = useMemo(
    () => sumSerialByOrderTypeReimport - sumReturnByOrderTypeReimport,
    [sumReturnByOrderTypeReimport, sumSerialByOrderTypeReimport]
  );

  const orderOptions = orders?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={16}>
        <Col span={6}>
          <Typography.Text style={{ color: "#2246DC" }}>{item?.number}</Typography.Text>
        </Col>
        <Col span={10}>
          <Typography.Text ellipsis>{item?.seller?.fullName}</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text ellipsis>{item?.exportedWarehouse?.name}</Typography.Text>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <Typography.Text ellipsis>{}</Typography.Text>
        </Col>
      </Row>
    ),
    order: item,
    labelOrder: item?.number,
  }));

  const { platformName } = useGetInfoPlatform();

  const optionRadio = [
    { label: t("serialIE.firstTimeImport"), value: IMPORT_TYPE.FIRST_TIME_IMPORT },
    { label: t("serialIE.return", { platformName }), value: IMPORT_TYPE.RETURN },
    { label: t("serialIE.reImportWarranty"), value: IMPORT_TYPE.RE_IMPORT_WARRANTY },
    { label: t("serialIE.transfer_serial"), value: IMPORT_TYPE.TRANSFER_SERIAL },
    { label: t("serialIE.other"), value: IMPORT_TYPE.OTHER },
  ];

  return (
    <Spin spinning={loadingCreate}>
      <Row gutter={[16, 8]} className={styles["create-serial"]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("serialIE.createTitle")}
            routes={[
              { path: "/import-serial", name: t("serialIE.serial") },
              { path: "/import-serial", name: t("serialIE.importSerial") },
              { path: "#", name: t("serialIE.createTitle") },
            ]}
          />
        </Col>
        <Col span={24}>
          <Form
            layout="horizontal"
            initialValues={initialValues}
            form={form}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          >
            <Row style={{ marginBottom: "12px" }}>
              <Col span={20}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    name: "importType",
                    label: <SubTitleCard subTitle={t("serialIE.importType")} />,
                    style: { marginBottom: "0px", width: "fit-content", minWidth: "500px" },
                  }}
                  inputOptions={{
                    options: optionRadio,
                  }}
                />
              </Col>
              {(importType === IMPORT_TYPE.FIRST_TIME_IMPORT || importType === IMPORT_TYPE.RETURN) && (
                <Col span={4}>
                  <SearchableSelect
                    formItemOptions={{
                      name: "warehouseID",
                      rules: [
                        {
                          required: true,
                          message: t("serialIE.error.warehouseID"),
                        },
                      ],
                      style: { marginBottom: "0px" },
                    }}
                    selectOptions={{
                      options: warehouseOptions,
                      placeholder: t("serialIE.placeholder.selectWarehouse"),
                    }}
                  />
                </Col>
              )}
            </Row>

            <Row gutter={[16, 8]}>
              <Col span={24}>
                {/* <Card title={<TitleCard title={t("serialIE.ticketImportSerial")} />}> */}
                <Row gutter={[16, 16]}>
                  {importType !== IMPORT_TYPE.RE_IMPORT_WARRANTY && (
                    <Col span={24}>
                      <UiByImportType
                        importType={importType}
                        providerOptions={providerOptions}
                        orderOptions={orderOptions}
                        loadMoreOrder={loadMoreOrder}
                        loadMoreProvider={loadMoreProvider}
                        hasMoreOrder={hasMoreOrder}
                        hasMoreProvider={hasMoreProvider}
                        onSearch={onSearch}
                        loading={loading}
                        orders={orders}
                        handleChangeInfo={handleChangeInfo}
                        providerInfo={providerInfo}
                        orderInfo={orderInfo}
                        handleChange={handleChange}
                        loadingWarehouse={loadingWarehouse}
                        warehouseOptions={warehouseOptions}
                        warehouseInfo={warehouseInfo}
                        handleSelectWarehouse={handleSelectWarehouse}
                      />
                    </Col>
                  )}
                </Row>
                {/* </Card> */}
              </Col>
              <Col style={{ marginTop: "16px" }} span={24}>
                <Card
                  title={
                    <div>
                      <TitleCard title={t("serialIE.productInfo.title")} />
                    </div>
                  }
                >
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          {importType === IMPORT_TYPE.FIRST_TIME_IMPORT && (
                            <SelectProduct handleGetProduct={handleGetProduct} />
                          )}
                          {importType === IMPORT_TYPE.RE_IMPORT_WARRANTY && (
                            <SelectSerial
                              onSerialSelected={handleSerialSelected}
                              handleGetSerial={handleGetSerial}
                              handleChangeInfo={handleChangeInfo}
                            />
                          )}
                          {(importType === IMPORT_TYPE.TRANSFER_SERIAL || importType === IMPORT_TYPE.OTHER) && (
                            <SelectProduct handleGetProduct={handleGetProductTransfer} />
                          )}
                        </Col>
                        <Col span={5}></Col>
                        {importType === IMPORT_TYPE.RE_IMPORT_WARRANTY && (
                          <Col span={7}>
                            <SelectWarehouseToAll exportedWarehouseOptions={warehouseOptions} form={form} />
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Form.List name="items">
                        {(items, { remove }) => {
                          return (
                            <TableProduct
                              importType={importType}
                              handleRemoveSerial={handleRemoveSerial}
                              handleRemoveProduct={handleRemoveProduct}
                              warehouseOptions={warehouseOptions}
                              handleGetTags={handleGetTags}
                              tags={tags}
                              remove={remove}
                              pendingWarrantyNoteLoading={pendingWarrantyNoteLoading}
                              form={form}
                              handleGetTableOrder={handleGetTableOrder}
                              dataTableFirstTimeImport={dataTableFirstTimeImport}
                              sumSerialByLack={sumSerialByLack}
                              sumSerialByOrder={sumSerialByOrder}
                              sumSerialByScanned={sumSerialByScanned}
                              dataTableReturn={dataTableReturn}
                              dataTableReImportWarranty={dataTableReImportWarranty}
                              handleClearAllSerials={handleClearAllSerials}
                              warehouseInfo={warehouseInfo}
                            />
                          );
                        }}
                      </Form.List>
                    </Col>
                    <Col span={24} xl={18}></Col>
                    <Col span={24} xl={6}>
                      <FooterTable
                        importType={importType}
                        dataTableFirstTimeImport={dataTableFirstTimeImport}
                        sumSerialByLack={sumSerialByLack}
                        sumSerialByOrder={sumSerialByOrder}
                        sumSerialByScanned={sumSerialByScanned}
                        dataTableReturn={dataTableReturn}
                        sumLackByOrderTypeReimport={sumLackByOrderTypeReimport}
                        sumReturnByOrderTypeReimport={sumReturnByOrderTypeReimport}
                        sumSerialByOrderTypeReimport={sumSerialByOrderTypeReimport}
                        dataTableReImportWarranty={dataTableReImportWarranty}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              {importType === IMPORT_TYPE.RE_IMPORT_WARRANTY && (
                <Col style={{ marginTop: "16px" }} span={24}>
                  <Card>
                    <UiByImportType
                      importType={importType}
                      providerOptions={providerOptions}
                      orderOptions={orderOptions}
                      loadMoreOrder={loadMoreOrder}
                      loadMoreProvider={loadMoreProvider}
                      hasMoreOrder={hasMoreOrder}
                      hasMoreProvider={hasMoreProvider}
                      onSearch={onSearch}
                      loading={loading}
                      orders={orders}
                      serialInfo={serialInfo}
                    />
                  </Card>
                </Col>
              )}
              <Col span={24}>
                <AffixButton
                  handleCreate={handleCreate}
                  importType={importType}
                  loadingCreateBtn={loadingCreateBtn}
                  onCancelCreate={onCancelCreate}
                  showOffModalConfirmCreate={showOffModalConfirmCreate}
                  visible={visible}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
}

export default CreateSerial;
