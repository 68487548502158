import { MAX_SCREEN, MIN_SCREEN, PURCHASE_STATUS, PURCHASE_TABS, SORT_TYPES } from "features/Purchase/constant";
import { useGetPurchaseOrderList, useGetPurchaseStatusCount, useGetSellerLoadMore } from "hooks/purchase";
import useDebounce from "hooks/useDebounce";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";

function useAccordingPurchaseOrder() {
  const GET_QUERY = getQuery();

  const [tabCardValue, setTabCardValue] = useState(GET_QUERY.key || PURCHASE_STATUS.ALL);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isTableSelectedRow, setIsTableSelectedRow] = useState(true);
  const [sizeScrollPageAll, setSizeScrollPageAll] = useState({ x: 1600, y: MAX_SCREEN });
  const [selectedHighlight, setSelectedHighlight] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangeRadioGroup = (e) => {
    setIsFilterSelected(e?.target?.value);
  };

  const handleToggleRows = () => {
    setSizeScrollPageAll(isTableSelectedRow ? { x: 1600, y: MIN_SCREEN } : { x: 1600, y: MAX_SCREEN });
    setIsTableSelectedRow(!isTableSelectedRow);
  };

  const handleRowClick = (record, index) => {
    setSelectedHighlight(index);
    setSelectedRow(record);
    setIsTableSelectedRow(false);
    setSizeScrollPageAll({ x: 1600, y: MIN_SCREEN });
  };

  const debouncedValue = useDebounce(searchTerm?.trim());

  const countSelectedRowKeys = selectedRowKeys?.length ? selectedRowKeys?.length : 0;

  const [params, setParams] = useState({
    activeKey: PURCHASE_TABS.PURCHASE_ORDER,
    key: GET_QUERY.key,
    filters: {
      query: GET_QUERY.query || undefined,
      statuses: GET_QUERY.statuses || undefined,
      sellerIDs: GET_QUERY.sellerIDs || undefined,
      warehouseIDs: GET_QUERY.warehouseIDs || undefined,
      types: GET_QUERY.types || undefined,
      timeRange: {
        from: GET_QUERY.from,
        to: GET_QUERY.to,
      },
      createdByIDs: GET_QUERY.createdByIDs || undefined,
      updatedByIDs: GET_QUERY.updatedByIDs || undefined,
    },
    pagination: {
      limit: 10,
      offset: GET_QUERY?.offset || 0,
    },
    sorts: [],
  });

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: undefined, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const [paramsWareHouse, setParamsWareHouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { warehouses } = useWarehouse(paramsWareHouse);

  const { statusCount, loading: loadingStatusCount } = useGetPurchaseStatusCount();
  const { purchaseOrderList, loading: loadingPurchaseOrderList, paginationData } = useGetPurchaseOrderList(params);
  const {
    data: dataSeller,
    onLoadMoreData: onLoadMoreSeller,
    hasMoreData: hasMoreSeller,
    loading: loadingSeller,
  } = useGetSellerLoadMore(paramsSeller);
  const {
    dataOptions: dataStaffs,
    onLoadMoreData: onLoadMoreStaff,
    hasMoreData: hasMoreStaff,
    loading: loadingStaff,
  } = useGetStaff(paramsStaff);

  const optionsSeller = dataSeller?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));

  const optionsStaff = dataStaffs?.map((item) => ({
    value: item?.id,
    label: item?.fullname,
  }));

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const handleChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const onFilter = (key, value) => {
    handleChangeParams && handleChangeParams({ [`${key}`]: value });
  };

  const handleSearchSellerLoadMore = (value) => {
    setParamsSeller({
      filters: { ...setParamsSeller.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchStaffLoadMore = (value) => {
    setParamsStaff({
      filters: { ...setParamsStaff.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sorts: sorter?.order ? [{ by: "CREATED_AT", type: SORT_TYPES[sorter?.order] }] : [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        key: newParams.key,
        activeKey: newParams.activeKey,
      },
    });
  };

  const handleChangeTabsCard = (value) => {
    setTabCardValue(value);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSizeScrollPageAll({ x: 1600, y: MAX_SCREEN });
    setSearchTerm(null);
    const newParams = {
      ...params,
      key: value,
      filters: {
        statuses: value !== "ALL" ? value : undefined,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        key: newParams.key,
        activeKey: newParams.activeKey,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: { ...newParams?.filters, ...newParams?.pagination },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (isFilterSelected) {
      if (selectedRows?.length) {
        handleRowClick(selectedRows && selectedRows[0], 0);
      } else {
        setSelectedRow(undefined);
      }
    } else {
      if (purchaseOrderList?.length) {
        handleRowClick(purchaseOrderList && purchaseOrderList[0], 0);
      }
    }
  }, [JSON.stringify(purchaseOrderList), isFilterSelected, JSON.stringify(selectedRows?.length)]);

  useEffect(() => {
    handleChangeRadioGroup(false);
  }, [GET_QUERY.key]);

  useEffect(() => {
    if (selectedRows?.length === 0) {
      handleChangeRadioGroup(false);
    }
  }, [JSON.stringify(selectedRows?.length)]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params?.filters,
        statuses: GET_QUERY.key !== "ALL" ? GET_QUERY.key : undefined,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
  }, []);

  return {
    tabCardValue,
    countSelectedRowKeys,
    setIsFilterSelected,
    handleSearch,
    handleChangeParams,
    params,
    optionsSeller,
    optionsStaff,
    warehouseOptions,
    isFilterSelected,
    selectedRows,
    purchaseOrderList,
    loadingPurchaseOrderList,
    handleChangeTable,
    selectedHighlight,
    rowSelection,
    sizeScrollPageAll,
    handleRowClick,
    paginationData,
    handleToggleRows,
    isTableSelectedRow,
    selectedRow,
    loadingStatusCount,
    statusCount,
    handleChangeTabsCard,
    onLoadMoreSeller,
    hasMoreSeller,
    handleSearchSellerLoadMore,
    loadingSeller,
    onFilter,
    onLoadMoreStaff,
    hasMoreStaff,
    handleSearchStaffLoadMore,
    loadingStaff,
    handleChangeRadioGroup,
  };
}

export default useAccordingPurchaseOrder;
