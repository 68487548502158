import { Col, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE, PAYMENT_METHOD } from "config/constants";
import { t } from "i18next";
import { useState } from "react";
import { formatCurrency } from "utils/helperFuncs";
import "./index.scss";
import usePaymentMethods from "./usePaymentMethods";

const PaymentMethods = ({ data, handleUpdate }) => {
  const { customerPaymentMethodOptions, isSeller, onChange } = usePaymentMethods({ data, handleUpdate });

  const [paymentMethod, setPaymentMethod] = useState(data?.paymentMethod);
  const handleChangePaymentMethods = (e) => {
    setPaymentMethod(e.target.value);
    handleUpdate({ paymentMethod: e.target.value });
  };
  return (
    <Col span={24} style={{ background: "#f7f8fb", padding: "12px", borderRadius: "8px" }}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Typography.Text style={{ color: "#2246DC" }} strong>
            {t("cart.paymentMethod")}
          </Typography.Text>
        </Col>
        <Col span={24} className="form-change-payment-method-on-cart">
          <Row>
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{
                name: "paymentMethod",
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              inputOptions={{
                onChange: handleChangePaymentMethods,
                value: paymentMethod,
                defaultValue: paymentMethod,
                options: [
                  {
                    label: t("cart.paymentMethods.debt"),
                    value: PAYMENT_METHOD.Debt,
                  },
                  {
                    label: t("cart.paymentMethods.cash_2"),
                    value: PAYMENT_METHOD.Cash,
                  },
                  {
                    label: t("cart.paymentMethods.cod"),
                    value: PAYMENT_METHOD.COD,
                  },
                ],
              }}
            />
          </Row>
          <div className="title-content-select-payment-method">
            <span className="block-start">&nbsp;</span>
            {data?.paymentMethod === PAYMENT_METHOD.Debt && (
              <span style={{ width: "100%" }}>
                {data?.seller?.availabilityLimit < data?.paymentByDebit && (
                  <>
                    <Typography.Text style={{ color: "#EF9351" }}>
                      {t("bank_transfer.cart.waning_deb_limit")}
                    </Typography.Text>
                    <br />
                  </>
                )}
                <Row>
                  <Col span={8}>
                    <Typography.Text>{t("bank_transfer.cart.debt_limits")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text strong>{formatCurrency(data?.seller?.debtLimit)}</Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Typography.Text>{t("bank_transfer.cart.available_limits")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text strong>{formatCurrency(data?.seller?.availabilityLimit)}</Typography.Text>
                  </Col>
                </Row>
              </span>
            )}
            {data?.paymentMethod === PAYMENT_METHOD.Cash && (
              <Typography.Text>{t("bank_transfer.cart.seller_need_payment_order")}</Typography.Text>
            )}
            {data?.paymentMethod === PAYMENT_METHOD.COD && (
              <Typography.Text>{t("bank_transfer.cart.seller_need_payment_product")}</Typography.Text>
            )}
          </div>
        </Col>

        <Col span={24}>
          {!isSeller && (
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{
                label: (
                  <Typography.Text strong style={{ color: "#052987", textAlign: "start" }}>
                    {t("cart.customer")}
                  </Typography.Text>
                ),
                labelCol: { span: 4, offset: 0 },
                wrapperCol: { span: 19, offset: 1 },
                name: "customerPaymentMethod",
              }}
              inputOptions={{
                options: customerPaymentMethodOptions,
                onChange: (e) => onChange({ customerPaymentMethod: e.target.value }),
              }}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default PaymentMethods;
