import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, Divider, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";

import { useState } from "react";
import { useHistory } from "react-router-dom";

function ModalSelectCampaign({ visible, handleGetValueVisibleModal }) {
  const history = useHistory();
  const [valueCampaign, setValueCampaign] = useState();

  // check i18n
  const buttonData = [
    {
      key: "TOP_SALES",
      onClick: () => setValueCampaign("TOP_SALES"),
      text: "Đua top",
    },
    {
      key: "COMBO_PROMO",
      onClick: () => setValueCampaign("COMBO_PROMO"),
      text: "Khuyến mãi theo combo",
      description: [
        "Mua sản phẩm A kèm sản phẩm B, giảm tiền trên combo",
        "Mua sản phẩm A kèm sản phẩm B, tặng quà",
        "Mua gói X sản phẩm chỉ với giá",
      ],
    },
    {
      key: "PRODUCT_PROMO",
      onClick: () => setValueCampaign("PRODUCT_PROMO"),
      text: "Khuyến mãi trên 1 sản phẩm",
      description: ["Mua sản phẩm A được giảm tiền"],
    },
  ];

  const handleClose = () => {
    handleGetValueVisibleModal(!visible);
    setValueCampaign(undefined);
  };

  const handleOke = () => {
    if (valueCampaign) {
      history.push({
        pathname: "/campaign/create",
        state: valueCampaign,
        hash: valueCampaign,
      });
    }
  };

  return (
    <CustomModal
      {...{
        message: <Typography.Title level={4}>Chọn loại chương trình</Typography.Title>,
        footer: false,
        okeButtonLabel: "Áp dụng",
        width: "max-content",
        onCancel: handleClose,
        visible: visible,
        closable: false,
        onOke: handleOke,
        children: (
          <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
            {buttonData.map((button) => (
              <Button
                key={button?.key}
                onClick={button?.onClick}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Space style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "32px" }}>{button?.icon || <QuestionCircleTwoTone />}</div>
                  <Divider type="vertical" dashed={true} style={{ borderColor: "grey", height: "4em" }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "baseline",
                      padding: "0 12px",
                    }}
                  >
                    <Typography.Text
                      strong
                      style={{ fontSize: "16px", color: button.key === valueCampaign && "#1953D8" }}
                    >
                      {button?.text}
                    </Typography.Text>
                    {button?.description?.map((text) => (
                      <Typography.Text strong style={{ fontSize: "12px", color: "#5B6673" }}>
                        {text}
                      </Typography.Text>
                    ))}
                  </div>
                </Space>
              </Button>
            ))}
          </Space>
        ),
      }}
    />
  );
}

export default ModalSelectCampaign;
