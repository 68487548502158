import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Page503 = () => {
  const { t } = useTranslation();
  return <Result status="500" title={t("pageError.maintenance")} subTitle={t("pageError.maintenanceTitle")} />;
};

export default Page503;
