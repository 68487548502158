import { Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import { renderLabelStatus } from "features/Order/helper";
import { t } from "i18next";
import ModalTrackingOrder from "../ModalTrackingOrder";
import "./index.scss";
import { reconciliationStatusConverter } from "features/Accountant/ProfitReconciliation/constants";

const OrderCodeAndOrderStatus = ({ dataOrderCodeAndOrderStatus }) => {
  const { number, status, preOrder, accountingNumber, saleChannel, orderType, orderReconciliationStatus } =
    dataOrderCodeAndOrderStatus;

  return (
    <SpaceCustom direction="horizontal" gap={12} className="order-code-and-order-status">
      {/* Mã đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <div>
          <Typography.Text strong>{t("order.orderDetail.orderCode")}</Typography.Text>
        </div>
        <div>
          <Typography.Link>{number}</Typography.Link>
          {accountingNumber && <Typography.Text>(AMIS: {accountingNumber})</Typography.Text>}
        </div>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Loại đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <Typography.Text strong>{t("order.orderList.orderType")}</Typography.Text>
        <Typography.Text>
          {preOrder ? t("order.approvalOrder.preOrder") : t("order.approvalOrder.regularOrder")}
        </Typography.Text>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Kênh bán */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <Typography.Text strong>{t("order.orderDetail.saleChannel")}</Typography.Text>
        <Typography.Text>{saleChannel?.name}</Typography.Text>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Trạng thái đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <div>
          <Typography.Text strong>{t("order.orderDetail.orderStatus")}</Typography.Text>
        </div>
        <div>
          <div>{renderLabelStatus[status]}</div>
        </div>
        <div>
          <ModalTrackingOrder data={dataOrderCodeAndOrderStatus} />
        </div>
      </SpaceCustom>
      {orderReconciliationStatus && (
        <>
          <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
          <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
            <div>
              <Typography.Text strong>Trạng thái đối soát lợi nhuận</Typography.Text>
            </div>
            <div>{reconciliationStatusConverter(orderReconciliationStatus)?.text}</div>
          </SpaceCustom>
        </>
      )}
    </SpaceCustom>
  );
};

export default OrderCodeAndOrderStatus;
