import { Input } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import { t } from "i18next";
import FilterMenuDate from "../FilterMenuDate";
import { FiltersTag } from "../FiltersTag";
import "./index.scss";

function Toolbar({
  params,
  handleSearch,
  statusTabs,
  OPTION_OWNER_TYPE,
  OPTION_PRICE_BID_STATUS,
  sellersOptions,
  handleRemoveFilterTag,
  filterTags,
  setParams,
  setFilterTags,
}) {
  return (
    <div className="filter-product-bargain-container">
      <div className="filter-box">
        <FilterMenuDate
          params={params}
          setParams={setParams}
          staffs={sellersOptions}
          filterTags={filterTags}
          setFilterTags={setFilterTags}
        />
        <div className="search-input">
          <Input
            {...{
              onChange: handleSearch,
              placeholder: t("product.priceBid.searchByCodeOrName"),
              prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
              defaultValue: params?.filters?.query,
              allowClear: true,
            }}
          />
        </div>
      </div>
      <FiltersTag
        statusTabs={statusTabs}
        sellerOptions={sellersOptions}
        optionOwnerType={OPTION_OWNER_TYPE}
        rangeDateTimeFilter={params?.filters?.timeRange || []}
        params={params}
        handleRemoveFilterTag={handleRemoveFilterTag}
        OPTION_PRICE_BID_STATUS={OPTION_PRICE_BID_STATUS}
      />
    </div>
  );
}

export default Toolbar;
