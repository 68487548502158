import { warrantyStatusLabel } from "features/Warranty/constant";
import React from "react";
import { SelectLoadMore } from "components/CustomSelect/index";
import { t } from "i18next";
import { Col, Row, Spin, Typography } from "antd";
import ModalAddProduct from "../ModalAddProduct";
import useSelectProductWarranty from "./useSelectProductWarranty";

const styles = {
  padding: "12px 8px",
  color: "#5B6673",
  fontWeight: "bold",
};

const SelectProduct = ({ onSerialSelected, onSubmitNewProduct }) => {
  const { data, onLoadMoreData, hasMoreData, loading, onSearch } = useSelectProductWarranty();
  const options = data?.map((item) => ({
    value: item.id,
    serialImei: item?.serialImei,
    label: (
      <Row gutter={16}>
        <Col span={5}>
          <Typography.Link style={{ whiteSpace: "pre-wrap" }}>{item?.code}</Typography.Link>
        </Col>
        <Col span={4}>
          <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{item?.serialImei}</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text ellipsis={{ tooltip: true }}>{item?.product?.code}</Typography.Text>
        </Col>
        <Col span={7}>
          <Typography.Text ellipsis={{ tooltip: true }}>{item?.product?.name}</Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text style={{ float: "right" }}>{warrantyStatusLabel?.[item?.status]}</Typography.Text>
        </Col>
      </Row>
    ),
    item: item,
  }));

  function handleChange(value, option) {
    const selected = data?.find((item) => item.id === option?.value);
    onSerialSelected(selected);
  }

  return (
    <SelectLoadMore
      inputOptions={{
        placeholder: t("warrantyRequest.create.placeHolder.search"),
        options: options,
        value: null,
        dropdownMatchSelectWidth: 1500,
        allowClear: false,
        dropdownRender: (originNode) => (
          <>
            <Row gutter={16} style={{ background: "#F7F8FB" }}>
              <Col span={5}>
                <Typography style={styles}>{t("warranty.warrantyList.warrantyCode")}</Typography>
              </Col>
              <Col span={4}>
                <Typography style={styles}>{t("warranty.warrantyList.SERIAL/IMEITitle")}</Typography>
              </Col>
              <Col span={5}>
                <Typography style={styles}>{t("warranty.warrantyList.productTitle")}</Typography>
              </Col>
              <Col span={7}>
                <Typography style={styles}>{t("warranty.warrantyList.nameProductTitle")}</Typography>
              </Col>
              <Col span={3}>
                <Typography style={styles}>{t("warranty.warrantyList.statusTitle")}</Typography>
              </Col>
            </Row>
            <Spin spinning={loading}>{originNode}</Spin>
            <ModalAddProduct onSubmit={onSubmitNewProduct} supportAddMore={true} />
          </>
        ),
        dropdownStyle: { zIndex: "1000" },
        defaultActiveFirstOption: true,
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
};

export default SelectProduct;
