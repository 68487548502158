import { useLazyQuery } from "@apollo/client";
import { GENERATE_LINK } from "graphql/generateLink/query";

export const useGenerateLink = () => {
  const [generateLink, { data, loading }] = useLazyQuery(GENERATE_LINK);

  const handleGenerateLink = async (params) => {
    return await generateLink({
      variables: params,
    });
  };

  return {
    handleGenerateLink,
    data: data?.linkQuery?.generateDynamicLinkFromUrl,
    loading,
  };
};
