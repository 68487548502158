import { Typography } from "antd";
import BadgeStatusDot from "components/_storybooks/BadgeStatusDotCustom";
import EllipsisText from "components/_storybooks/EllipsisText";
import { FilterLoadMore, FilterProductIDs, FilterSellerIDs } from "components/_storybooks/FilterLoadMore";
import FilterOrderIDs from "components/_storybooks/FilterLoadMore/FilterOrderIDs";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import PopupDetailSerial from "features/Serial/components/PopupDetailSerial";
import { importTypeSerialConverter, optionExportRadio } from "features/Serial/constant";
import { useGetUserCurrent } from "hooks/auth/auth";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { getQuery, useBreakpointPage } from "utils/helperFuncs";

function TablesList({ params, handleFilter, propsFilterCreated }) {
  const GET_QUERY = getQuery();
  const { userCurrentData } = useGetUserCurrent();
  const { xl, xxl } = useBreakpointPage();

  const isMobile = xxl || xl;

  const isListBySerial = GET_QUERY.activeKey === "listBySerial";

  const columns = {
    stt: {
      title: "STT",
      align: "center",
      width: 50,
      fixed: isMobile ? "left" : undefined,
      render: (_, record, index) => index + 1,
    },
    //NGÀY XUẤT KHO
    exportWarehouseDate: {
      title: t("serialIE.createdAtExport"),
      fixed: isMobile ? "left" : undefined,
      sorter: true,
      align: "center",
      width: isListBySerial ? 150 : 130,
      render: (_, record) =>
        formatDateTime(record?.serialExportNoteItem?.serialExportNote?.createdAt || record?.createdAt),
    },
    // SỐ SERIAL/ IMEI
    serialNumberImei: {
      title: t("serialIE.CODE_SERIAL/IMEI"),
      render: (_, record) => <PopupDetailSerial button={record?.serial?.code} id={record?.serial?.id} />,
      fixed: isMobile ? "left" : undefined,
    },
    // Thông số
    parameter: {
      width: 250,
      title: "Thông số",
      render: (_, record) => (
        <SpaceCustom>
          <Link target="_blank" to={`/product/detail/${record?.serial?.product?.id}`}>
            <b>{record?.serial?.product?.code}</b>
          </Link>
          <EllipsisText line={2} title={record?.serial?.product?.name} />
        </SpaceCustom>
      ),
      filterDropdown: (
        <FilterProductIDs params={params} onFilter={(value, option) => handleFilter({ productIDs: value })} />
      ),
    },
    // NHÀ CUNG CẤP/ ĐẠI LÝ
    providerAgent: {
      title: t("serialIE.provider"),
      width: 300,
      render: (_, record) => {
        const providerFromByImportSerial = record?.provider || record?.seller;
        const providerFromBySerial =
          record?.serialExportNoteItem?.serialExportNote?.seller ||
          record?.serialExportNoteItem?.serialExportNote?.provider;

        const provider = isListBySerial ? providerFromBySerial : providerFromByImportSerial;
        return (
          <SpaceCustom>
            {provider?.id && (
              <Typography.Link target="_blank" strong href={`/seller/detail/${provider?.id}`}>
                {provider?.idNumber || provider?.code}
              </Typography.Link>
            )}
            <EllipsisText title={provider?.fullName} />
          </SpaceCustom>
        );
      },
      filterDropdown: <FilterSellerIDs onFilter={(value, option) => handleFilter({ sellerIDs: value })} />,
    },
    // MÃ PHIẾU XUẤT SERIAL
    fromExportSerialCode: {
      title: t("serialIE.EXPORT_SERIAL_CODE"),
      width: 200,
      fixed: isMobile && !isListBySerial ? "left" : undefined,
      render: (_, record) => (
        <EllipsisText
          strong
          isLink
          link={`/serial-export/detail/${record?.serialExportNoteItem?.serialExportNote?.id || record?.id}`}
          title={record?.serialExportNoteItem?.serialExportNote?.code || record.code}
        />
      ),
    },
    // Mã đơn hàng
    orderCode: {
      title: t("serialIE.ORDER_NUMBER"),
      width: 200,
      render: (_, record) => {
        const title = record?.serialExportNoteItem?.serialExportNote?.order?.number || record?.order?.number;
        return (
          <EllipsisText
            strong={!!title}
            isLink={!!title}
            link={!!title && `/order/${record?.serialExportNoteItem?.serialExportNote?.order?.id || record?.order?.id}`}
            title={title}
          />
        );
      },
      filterDropdown: (
        <FilterOrderIDs params={params} onFilter={(value, option) => handleFilter({ orderIDs: value })} />
      ),
    },
    // NHÂN VIÊN PHỤ TRÁCH
    staffInCharge: {
      title: t("serialIE.staffInCharge"),
      align: "left",
      width: 220,
      render: (_, record) => {
        const staffInCharge = isListBySerial
          ? record?.serialExportNoteItem?.serialExportNote?.createdBy
          : record?.createdBy;
        const me = userCurrentData?.id === staffInCharge?.id ? `(tôi)` : "";
        return <EllipsisText title={staffInCharge?.fullname ? `${staffInCharge?.fullname} ${me}` : "--"} />;
      },
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={propsFilterCreated?.staffs}
          onLoadMoreData={propsFilterCreated?.onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearch={propsFilterCreated?.handleSearchStaffs}
          hasMore={propsFilterCreated?.hasMoreData}
          loading={propsFilterCreated?.loading}
        />
      ),
    },
    // HÌNH THỨC XUẤT
    exportFrom: {
      title: t("serialIE.typeExport"),
      fixed: isMobile ? "right" : undefined,
      width: 200,
      render: (_, record, index) => {
        const type = isListBySerial ? record?.serialExportNoteItem?.serialExportNote?.type : record?.type;
        return (
          <BadgeStatusDot
            status={importTypeSerialConverter(type)?.status}
            text={importTypeSerialConverter(type)?.text}
          />
        );
      },
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ type: value })}
          type="checkbox"
          options={optionExportRadio}
        />
      ),
    },
    // Tổng số mã sản phẩm
    categoriesTotal: {
      title: "Tổng số mã sản phẩm",
      width: 80,
      align: "center",
      render: (_, record) => record?.quantityKindOfProduct,
    },
    // Tổng số serial/imei
    serialImeiQty: {
      title: "Tổng số serial/imei",
      width: 100,
      align: "center",
      render: (_, record) => record?.quantitySerial,
    },
  };

  const {
    stt,
    exportWarehouseDate,
    serialNumberImei,
    parameter,
    providerAgent,
    fromExportSerialCode,
    orderCode,
    staffInCharge,
    exportFrom,
    categoriesTotal,
    serialImeiQty,
  } = columns;

  const BY_SERIAL_COLUMN = [
    stt,
    exportWarehouseDate,
    serialNumberImei,
    parameter,
    providerAgent,
    fromExportSerialCode,
    orderCode,
    staffInCharge,
    exportFrom,
  ];
  const BY_EXPORT_SERIAL_COLUMN = [
    stt,
    exportWarehouseDate,
    fromExportSerialCode,
    providerAgent,
    orderCode,
    categoriesTotal,
    serialImeiQty,
    staffInCharge,
    exportFrom,
  ];

  return {
    BY_EXPORT_SERIAL_COLUMN,
    BY_SERIAL_COLUMN,
  };
}

export default TablesList;
