import React from "react";
import CustomTags from "components/CustomTags";
import { formatDateTime } from "utils/dateTime";
import { DATE_FORMAT } from "config/constants";
import { t } from "i18next";
import { formatDateTimeFilterTag, getQuery } from "utils/helperFuncs";
import { ACTIONS_OPTION } from "features/Products/constants";
import { useGetCreatedByWithIds } from "hooks/order";
import { compact } from "lodash";
import { useGetSellersWithIds } from "hooks/seller";

const useFiltersTag = ({
  params,
  handleRemoveFilterTag,
  optionOwnerType,
  OPTION_PRICE_BID_STATUS,
  rangeDateTimeFilter,
}) => {
  const GET_QUERY = getQuery();

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (typeof arrValue !== "undefined" && arrValue !== null && typeof arrValue[Symbol.iterator] === "function") {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const { data: sellerOptions } = useGetSellersWithIds({
    ids: GET_QUERY.sellerIDs ? GET_QUERY.sellerIDs : undefined,
  });

  const tagsSellerID = [
    {
      title: t("product.priceBid.sellerName"),
      options: sellerOptions?.map(({ id, fullName }) => ({ value: id, label: fullName })),
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
    },
  ];

  const tagsOwnerType = [
    {
      title: t("product.priceBid.suggestOf"),
      options: optionOwnerType,
      selected: params?.filters?.ownerType,
      key: "ownerType",
    },
  ];

  const tagsTimeRange = [
    {
      title: t("product.priceBid.Time"),
      selected: rangeDateTimeFilter,
      key: "timeRange",
    },
  ];

  const tagsStatusPriceBid = [
    {
      title: t("product.priceBid.status"),
      selected: params?.filters?.status,
      options: OPTION_PRICE_BID_STATUS,
      key: "status",
    },
  ];

  const tagsLastEventTypes = [
    {
      title: t("product.priceBid.status"),
      selected: params?.filters?.lastEventTypes,
      options: ACTIONS_OPTION,
      key: "lastEventTypes",
    },
  ];

  const { data: dataCreatedByWithIds } = useGetCreatedByWithIds({
    ids: GET_QUERY.ids ? GET_QUERY.ids : undefined,
  });

  const tagsLastPerformUsers = [
    {
      title: t("product.priceBid.implementer"),
      options: [{ label: "Đại lý", value: "SELLER" }]?.concat(dataCreatedByWithIds),
      selected: params?.filters?.lastPerformUsers?.types?.concat(params?.filters?.lastPerformUsers?.ids),
      key: "lastPerformUsers",
    },
  ];

  const renderTagsSellerID = tagsSellerID.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  const renderTagsOwnerType = tagsOwnerType.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  const renderTagCreatedAtFilter = tagsTimeRange.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={`${title} : ${t("order.orderTag.from")} ${formatDateTime(selected?.from, DATE_FORMAT)} ${t(
          "order.orderTag.to"
        )} ${formatDateTimeFilterTag(selected?.to)}`}
      />
    );
  });

  const renderTagsStatusPriceBid = tagsStatusPriceBid.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  const renderTagsLastEventTypes = tagsLastEventTypes.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  const renderTagsLastPerformUsers = tagsLastPerformUsers.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, compact(selected), title)}
      />
    );
  });

  return {
    renderTagsSellerID,
    renderTagCreatedAtFilter,
    renderTagsOwnerType,
    renderTagsStatusPriceBid,
    renderTagsLastEventTypes,
    renderTagsLastPerformUsers,
  };
};

export default useFiltersTag;
