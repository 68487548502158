import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Space, Tag, Typography } from "antd";
import { DATE_FORMAT } from "config/constants";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "utils/dateTime";
import { isNil, findIndex, uniqBy, map, compact, isArray } from "lodash";
import { formatCurrency } from "utils/helperFuncs";

const COMBO_CAMPAIGN = {
  DISCOUNT_MONEY: "DISCOUNT_MONEY",
  GIFT: "GIFT",
  FIXED_PRICE: "FIXED_PRICE",
};

const DISCOUNT_TYPE = {
  DISCOUNT_MONEY: "DISCOUNT_MONEY",
  DISCOUNT_PERCENTAGE: "DISCOUNT_PERCENTAGE",
};

const PROMOTION_CAMPAIGN_STATUS = {
  UPCOMING: "UPCOMING",
  ON_GOING: "ON_GOING",
};

const filterPromotionCampaignTierByType = (conditionProductGroups, type) => {
  if (isArray(conditionProductGroups)) {
    return conditionProductGroups.filter((obj) => obj.promotionCampaignTier.type === type);
  }
  return [];
};

const convertConditionPromotionProductsOnGoing = (conditionProductGroups, typePromotion) => {
  const promotionCampaign = uniqBy(conditionProductGroups, (obj) => obj.promotionCampaign.id).map(
    (obj) => obj.promotionCampaign
  );

  const promotionCampaignTiers = promotionCampaign.map((obj) => {
    const getPromotionCampaignTiers = conditionProductGroups
      .filter((element) => {
        return element.promotionCampaign.id === obj.id;
      })
      .map((element) => element.promotionCampaignTier);

    return {
      typePromotion,
      promotionCampaign: obj,
      promotionCampaignTiers: getPromotionCampaignTiers,
    };
  });

  return promotionCampaignTiers;
};

const arrangeConditionProductGroups = (conditionProductGroups = [], codeFirstItem) => {
  const getIndexConditionProductGroupByCodeFirstItem = findIndex(
    conditionProductGroups,
    function (conditionProductGroup, index) {
      return conditionProductGroup.conditionPromotionProducts.find(
        (conditionPromotionProducts) => conditionPromotionProducts.product.code === codeFirstItem
      );
    }
  );

  return [
    ...conditionProductGroups.slice(-getIndexConditionProductGroupByCodeFirstItem),
    ...conditionProductGroups.slice(0, -getIndexConditionProductGroupByCodeFirstItem),
  ]
    .map((product, index) => {
      if (index === 0) {
        return `[${product.quantity} ${codeFirstItem}]`;
      }
      return `[${product.quantity} ${product.conditionPromotionProducts[0].product.code}]`;
    })
    .join(" + ");
};

const renderDiscountType = (discountAmount) => {
  return {
    [`${DISCOUNT_TYPE.DISCOUNT_MONEY}`]: formatCurrency(discountAmount),
    [`${DISCOUNT_TYPE.DISCOUNT_PERCENTAGE}`]: `${discountAmount} %`,
  };
};

const PopoverPromotion = ({ conditionPromotionProductsOnGoing, productCode }) => {
  const { t } = useTranslation();
  const renderTypePromotions = {
    [`${COMBO_CAMPAIGN.DISCOUNT_MONEY}`]: t("campaign.combo.comboDiscount"),
    [`${COMBO_CAMPAIGN.GIFT}`]: t("campaign.combo.comboGift"),
    [`${COMBO_CAMPAIGN.FIXED_PRICE}`]: t("campaign.combo.comboFixedPrice"),
  };
  const dataTagPromotion = compact(
    map(
      Object.values(COMBO_CAMPAIGN).map((item) =>
        convertConditionPromotionProductsOnGoing(
          filterPromotionCampaignTierByType(conditionPromotionProductsOnGoing, item),
          item
        )
      ),
      0
    )
  );

  const renderPromotionCampaignTierType = {
    [`${PROMOTION_CAMPAIGN_STATUS.UPCOMING}`]: {
      background: "#FFF4EC",
      color: "#EF9351",
      label: t("campaign.upcoming"),
    },
    [`${PROMOTION_CAMPAIGN_STATUS.ON_GOING}`]: {
      background: "#EDF9F0",

      color: "#32BF46",
      label: t("campaign.onGoing"),
    },
  };

  const renderCombos = (promotionCampaignTiers, typePromotion) =>
    // eslint-disable-next-line array-callback-return
    promotionCampaignTiers.map((item, index) => {
      switch (typePromotion) {
        case COMBO_CAMPAIGN.DISCOUNT_MONEY:
          return (
            <Typography.Paragraph>
              <Typography.Text strong>
                {t("campaign.combo.comboDiscount")} {index + 1}:{" "}
              </Typography.Text>
              {t("product.buy")}{" "}
              <Typography.Text strong>
                {arrangeConditionProductGroups(item.conditionProductGroups, productCode)}
              </Typography.Text>{" "}
              {t("product.minimum")}
              <Typography.Text strong> [{item.minQuantity}] </Typography.Text>
              {t("product.combo")}, {t("product.reduce")}{" "}
              <Typography.Text strong>
                [{renderDiscountType(item.discountAmount)[item.discountType]}]/1{" "}
              </Typography.Text>
              {t("product.combo")}
            </Typography.Paragraph>
          );
        case COMBO_CAMPAIGN.GIFT:
          break;
        case COMBO_CAMPAIGN.FIXED_PRICE:
          return (
            <Typography.Paragraph>
              <Typography.Text strong>
                {t("campaign.combo.comboFixedPrice")} {index + 1}:{" "}
              </Typography.Text>
              {t("product.buy")}{" "}
              <Typography.Text strong>
                {arrangeConditionProductGroups(item.conditionProductGroups, productCode)}
              </Typography.Text>{" "}
              {t("product.justForThePrice")}
              <Typography.Text strong> [{formatCurrency(item.fixedComboPrice)}] </Typography.Text>
            </Typography.Paragraph>
          );
      }
    });

  return (
    !isNil(conditionPromotionProductsOnGoing) && (
      <Space direction="vertical" style={{ width: "100%" }}>
        {dataTagPromotion.map(({ typePromotion, promotionCampaign, promotionCampaignTiers }) => (
          <Popover
            trigger={["click"]}
            content={
              <Row style={{ width: 500 }}>
                <Col span={12}>
                  <Typography.Text strong>{t("product.belongsToTheProgram")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Link>{promotionCampaign.name}</Typography.Link>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>{t("product.programTime")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Space size={0} direction="vertical">
                    <Typography.Text>
                      {t("product.from")}: {formatDateTime(promotionCampaign?.startDate, DATE_FORMAT)}
                    </Typography.Text>
                    <Typography.Text>
                      {t("product.to")}: {formatDateTime(promotionCampaign?.endDate, DATE_FORMAT)}
                    </Typography.Text>
                  </Space>
                </Col>
                <Col span={24}>
                  <Typography>
                    {renderCombos(promotionCampaignTiers, typePromotion)}

                    <Typography.Paragraph>
                      {t("product.cannotBeAppliedConcurrentlyWithProgramType")}
                    </Typography.Paragraph>
                  </Typography>
                </Col>
              </Row>
            }
            title={
              <Space style={{ width: "100%", justifyContent: "end" }}>
                <Tag color={renderPromotionCampaignTierType[`${promotionCampaign?.status}`].background}>
                  <Typography.Text
                    style={{ color: renderPromotionCampaignTierType[`${promotionCampaign?.status}`].color }}
                  >
                    {renderPromotionCampaignTierType[`${promotionCampaign?.status}`].label}
                  </Typography.Text>
                </Tag>
              </Space>
            }
          >
            <Tag
              color="#E7F0FF"
              style={{
                color: "#000",
                borderColor: "#D8DFEA",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Space align="center" style={{ height: "24px" }}>
                <Typography.Text>{renderTypePromotions[typePromotion]}</Typography.Text>
                <InfoCircleOutlined />
              </Space>
            </Tag>
          </Popover>
        ))}
      </Space>
    )
  );
};

export default PopoverPromotion;
