import React from "react";
import CustomTable from "components/CustomTable";
import { t } from "i18next";
import useActivityLogs from "./useActivityLogs";
import { actionActivityLogsOptions, statusOptions } from "features/Agent/constants";
import Toolbar from "./Toolbar";
import { v4 as uuidv4 } from "uuid";

function ActivityLogs({ isSupplier }) {
  const { columns, onTableChange, onChangeParams, params, activityLogs, loading, paginationData } = useActivityLogs({
    isSupplier,
  });
  return (
    <>
      <Toolbar
        showInput={false}
        showButton={false}
        params={params}
        onChangeParams={onChangeParams}
        statusOptions={statusOptions}
        actionActivityLogsOptions={actionActivityLogsOptions}
      />
      <CustomTable
        columns={columns}
        onChange={onTableChange}
        dataSource={activityLogs}
        rowKey={() => uuidv4()}
        loading={loading}
        pagination={{
          total: paginationData?.total,
          pageSize: params?.pagination?.limit,
          current: params?.pagination?.offset / params?.pagination?.limit + 1 || 1,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default ActivityLogs;
