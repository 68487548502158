import { Form } from "antd";
import CustomTable from "components/CustomTable";

function useFormList({ dataSource, columnsDataSelected }) {
  const renderCustomTable = () => {
    return <CustomTable columns={columnsDataSelected} dataSource={dataSource} scroll={{ x: "max-content" }} />;
  };

  return (
    <Form.List name={["promotionCampaignTierGroupsProductPromo", 0, "promotionCampaignTiers"]}>
      {(fields, { add, remove }) => {
        return renderCustomTable();
      }}
    </Form.List>
  );
}

export default useFormList;
