import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

function useBankAccount({ sellerID, bankAccounts, handleGetBankAccounts }) {
  const [form] = useForm();

  const [visible, setVisible] = useState(false);
  const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);

  const updateBankAccountHandler = async () => {
    const values = await form.validateFields();
    const updatedAccounts = [...bankAccounts];
    const index = updatedAccounts.findIndex((obj) => obj.id === currentModalData?.id);

    if (index !== -1) {
      updatedAccounts[index] = {
        ...values,
      };
      setVisibleModalUpdate(false);

      handleGetBankAccounts(updatedAccounts);
      notify.success({
        message: t("supplier.bankAccount.updateSuccess"),
      });
    }
  };

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const confirmDelete = (id) => {
    const newArray = [...bankAccounts];
    const newBankAccounts = newArray?.filter((item) => item?.id !== id);
    handleGetBankAccounts([...newBankAccounts]);
  };

  const openModalUpdate = (data) => {
    setVisibleModalUpdate(true);
    setCurrentModalData(data);
  };

  const closeModalUpdate = () => {
    setVisibleModalUpdate(false);
    setCurrentModalData(null);
  };

  const submitHandler = async () => {
    const values = await form.validateFields();

    try {
      handleGetBankAccounts &&
        handleGetBankAccounts([
          ...bankAccounts,
          {
            bankID: values?.bankID,
            ownerBankAccountName: values?.name,
            bankAccountNumber: values?.bankAccountNumber,
            bankBranch: values?.bankBranch,
            id: uuidv4(),
          },
        ]);
      notify.success({
        message: t("supplier.bankAccount.createSuccess"),
      });
      closeModal();
      closeModalUpdate();
      form.resetFields();
    } catch (error) {
      notify.error({
        message: t("supplier.bankAccount.createFail"),
        description: error.message,
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [visible, visibleModalUpdate]);

  return {
    form,
    visible,
    visibleModalUpdate,
    openModalUpdate,
    closeModalUpdate,
    submitHandler,
    closeModal,
    openModal,
    updateBankAccountHandler,
    confirmDelete,
    currentModalData,
  };
}

export default useBankAccount;
