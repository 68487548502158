import { Form, Radio, Space, Typography, Input } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import { REJECT_CUSTOMER_REASON } from "features/PotentialCustomer/constants";
import { useRejectCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { has } from "lodash";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function RejectButton({ customerIds }) {
  const [form] = Form.useForm();
  const [currentNoteEnabled, setCurrentNoteEnabled] = useState();
  const [showModal, setShowModal] = useState(false);

  const { loadingRejectCustomer, handleRejectCustomer } = useRejectCustomer();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        await handleRejectCustomer({
          customerIDs: customerIds,
          reason: {
            defaultReason: values.defaultReason,
            note:
              currentNoteEnabled === REJECT_CUSTOMER_REASON.MISSING_INFO
                ? values.missingInfoNote
                : currentNoteEnabled === REJECT_CUSTOMER_REASON.INVALID_INFO
                ? values.invalidInfoNote
                : currentNoteEnabled === REJECT_CUSTOMER_REASON.OTHER
                ? values.otherNote
                : null,
          },
        })
          .then(() => {
            handleClose();
            notify.success({ message: t("potentialCustomer.rejectSuccess") });
            form.resetFields();
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
            handleOpen();
          });
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "defaultReason")) {
      setCurrentNoteEnabled(formValues.defaultReason);
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={showModal}
      onCancel={handleClose}
      onOpen={handleOpen}
      customComponent={<PrimaryButtonOutLine title={t("potentialCustomer.buttonGroup.cancel")} />}
      title={
        <Typography.Text strong style={{ textTransform: "uppercase", fontSize: "18px" }}>
          {t("potentialCustomer.actionLabel.reject")}
        </Typography.Text>
      }
      message={<Typography.Text type="warning">{t("potentialCustomer.messageModalReject")}</Typography.Text>}
      onOke={onSubmit}
      buttonLoading={loadingRejectCustomer}
      children={
        <Form form={form} onValuesChange={handleValuesChange}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "defaultReason",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("defaultReason")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(notify.warning({ message: t("potentialCustomer.pleaseSelect") }));
                  },
                }),
              ],
            }}
            inputChildren={
              <Space style={{ width: "100%" }} direction="vertical">
                <Radio value={REJECT_CUSTOMER_REASON.MISSING_INFO}>{t("potentialCustomer.missingInfo")}</Radio>
                <FormInput
                  type={INPUT_TYPE.TEXT_AREA}
                  formItemOptions={{
                    name: "missingInfoNote",
                  }}
                  inputOptions={{
                    placeholder: t("potentialCustomer.pleaseFillMissingInfo"),
                    maxLength: 500,
                    disabled: !(currentNoteEnabled === REJECT_CUSTOMER_REASON.MISSING_INFO),
                  }}
                />
                <Radio value={REJECT_CUSTOMER_REASON.INVALID_INFO}>{t("potentialCustomer.invalidInfo")}</Radio>
                <FormInput
                  type={INPUT_TYPE.TEXT_AREA}
                  formItemOptions={{
                    name: "invalidInfoNote",
                  }}
                  inputOptions={{
                    placeholder: t("potentialCustomer.pleaseFillInvalidInfo"),
                    maxLength: 500,
                    disabled: !(currentNoteEnabled === REJECT_CUSTOMER_REASON.INVALID_INFO),
                  }}
                />
                <Radio value={REJECT_CUSTOMER_REASON.OTHER}>{t("potentialCustomer.other")}</Radio>
                <FormInput
                  type={INPUT_TYPE.TEXT_AREA}
                  formItemOptions={{
                    name: "otherNote",
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("defaultReason") !== "OTHER") {
                            return Promise.resolve();
                          }
                          if (getFieldValue("defaultReason") === "OTHER" && value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("potentialCustomer.errorOrderReason")));
                        },
                      }),
                    ],
                  }}
                  inputOptions={{
                    placeholder: t("order.cancelOrder.otherPlaceholder"),
                    maxLength: 500,
                    disabled: !(currentNoteEnabled === REJECT_CUSTOMER_REASON.OTHER),
                  }}
                />
              </Space>
            }
          />
        </Form>
      }
    />
  );
}

export default RejectButton;
