import { useState } from "react";
import { t } from "i18next";
import { TabsMenu } from "components/CustomTabs";
import TableCampaign from "features/Campaign/components/Table";
import PageHeader from "components/PageHeader";
import useListCampaign from "./useListCampaign";
import Toolbar from "../Toolbar";
import ModalSelectCampaign from "./ModalSelectCampaign";

import "./index.scss";

function List() {
  const {
    params,
    tabs,
    handleChange,
    onChangeParams,
    data,
    statusTabs,
    paginationData,
    loading,
    isStatusAll,
    handleSearch,
    onTableChange,
    refetch,
  } = useListCampaign();

  const [visibleModalSelectComboCampaign, setVisibleModalSelectComboCampaign] = useState(false);

  const handleGetValueVisibleModal = (value) => {
    setVisibleModalSelectComboCampaign(value);
  };

  return (
    <>
      <PageHeader pageTitle={t("common.campaignList")} />
      <div className="campaign-container">
        <TabsMenu defaultActiveKey={statusTabs} tabs={tabs} onChange={handleChange} />
        <Toolbar
          handleSearch={handleSearch}
          isStatusAll={isStatusAll}
          params={params}
          onChangeParams={onChangeParams}
          statusTabs={statusTabs}
          placeholder={t("campaign.searchCampaign")}
          titleFilterData={t("campaign.timeCampaign")}
          titleButton={t("campaign.createNewCampaign")}
          handleGetValueVisibleModal={handleGetValueVisibleModal}
        />
        <TableCampaign
          refetch={refetch}
          isStatusAll={isStatusAll}
          params={params}
          paginationData={paginationData}
          loading={loading}
          statusTabs={statusTabs}
          data={data}
          onTableChange={onTableChange}
          onChangeParams={onChangeParams}
        />
        <ModalSelectCampaign
          handleGetValueVisibleModal={handleGetValueVisibleModal}
          visible={visibleModalSelectComboCampaign}
        />
      </div>
    </>
  );
}

export default List;
