import { Typography } from "antd";

function CustomInfoAdress({ value }) {
  return (
    <Typography.Text>
      {`${value?.address || "---"} - ${value?.ward?.name || "---"} - ${value?.district?.name || "---"} - ${
        value?.city?.name || "---"
      }`}
    </Typography.Text>
  );
}

export default CustomInfoAdress;
