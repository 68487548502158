import React from "react";
import { Affix, Col, Row, Space, Spin, Typography } from "antd";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import styles from "../index.module.scss";
import Card from "features/Cart/components/Card";
import useDetail from "./useDetail";
import Percent from "components/Percent";
import FormEditCustomerInfoRequest from "../components/FormEditCustomerInfoRequest";
import FormEditAddInfo from "../components/FormEditAddInfo";
import {
  renderPercentBar,
  totalItemStatusMethod,
  WarrantyReceiveMethod,
  warrantyRequestStatusLabel,
} from "../constant";
import DetailTable from "./Table/DetailTable";
import Toolbar from "./Toolbar/Toolbar";
import { ButtonShippingType } from "../components/ButtonActions";
import PopoverPrint from "./PopoverPrint";
import PopoverExport from "./PopoverExport";
import { useGetInfoPlatform } from "hooks/info-platform";

function WarrantyRequestDetail() {
  const {
    data,
    columns,
    dataTable,
    loading,
    params,
    canceled,
    completed,
    process,
    waitingForProcessing,
    isCheckingForReturn,
    loadingConfirmReturn,
    loadingExport,
    handleSearch,
    onChangeParams,
    refetch,
    handleChangeTable,
    confirmReturn,
    onPrint,
    onExport,
    handleExportFile,
  } = useDetail();
  const { platformName } = useGetInfoPlatform();

  const warrantyRequestLabel = {
    [`${WarrantyReceiveMethod.BUS}`]: t("warrantyRequest.create.shippingType.bus"),
    [`${WarrantyReceiveMethod.SHIPPING_PROVIDER}`]: t("warrantyRequest.create.shippingType.shippingProvider"),
    [`${WarrantyReceiveMethod.CUSTOMER_BRING}`]: t("warrantyRequest.create.shippingType.customerBring"),
    [`${WarrantyReceiveMethod.SHIPPER}`]: t("warrantyRequest.create.shippingType.shipper", {
      platformName,
    }),
  };

  return (
    <Spin spinning={loadingExport}>
      <Row className={styles["form-warranty-request"]} gutter={[16, 16]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("warrantyRequest.title")}
            routes={[
              { path: "/warranty-request", name: t("common.warranty") },
              { path: "/warranty-request", name: t("warrantyRequest.create.title1") },
              { path: "", name: t("warrantyRequest.detail.title3") },
            ]}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} xl={12}>
              <Row gutter={20}>
                <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <PopoverExport
                    data={data}
                    handleExportFile={handleExportFile}
                    loading={loadingExport}
                    onExport={onExport}
                  />
                </Col>
                <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <PopoverPrint
                    data={data}
                    handleExportFile={handleExportFile}
                    loading={loadingExport}
                    onPrint={onPrint}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space style={{ background: "transparent", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Col span={24}>
                    <Typography.Text italic style={{ color: "#5B6673" }}>
                      {t("warranty.warrantyDetail.dateCreated")}: {data?.createdAt || "--"}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text italic style={{ color: "#5B6673" }}>
                      {t("common.updatedAt")}: {data?.updatedAt || "--"} {t("common.by")} {data?.updatedBy?.fullname}
                    </Typography.Text>
                  </Col>
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card
            title={
              <Row gutter={[16]}>
                <Col span={12} style={{ display: "flex" }}>
                  <Space>
                    <Typography.Text className={styles["title-card"]}>
                      {t("warrantyRequest.create.codeWarrantyRequest")}
                    </Typography.Text>
                    <Typography.Text style={{ color: "#2246DC" }} className={styles["title-card"]}>
                      #{data?.code}
                    </Typography.Text>
                  </Space>
                </Col>
                <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Space className={styles[""]} style={{ background: "transparent", flexDirection: "column" }}>
                    <Typography.Text strong>{warrantyRequestStatusLabel?.[data?.status]}</Typography.Text>
                    <Percent
                      percentBars={renderPercentBar(
                        totalItemStatusMethod,
                        canceled,
                        completed,
                        process,
                        waitingForProcessing
                      )}
                    />
                  </Space>
                </Col>
              </Row>
            }
          >
            <Row gutter={[50, 16]}>
              <Col span={24} className={styles["sub-title"]}>
                <Typography.Text className={styles["color-title"]}>
                  {t("warrantyRequest.detail.serial")}
                </Typography.Text>
              </Col>
              <Col span={12}>
                {/* toobar */}
                <Toolbar handleSearch={handleSearch} onChangeParams={onChangeParams} params={params} />
              </Col>
              <Col span={24}>
                {/* table */}
                <DetailTable
                  onTableChange={handleChangeTable}
                  columns={columns}
                  data={data}
                  dataTable={dataTable}
                  loading={loading}
                  refetch={refetch}
                  // rowSelection={rowSelection}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row gutter={50}>
              <Col span={12} style={{ borderRight: "1px dashed #9CADC4" }}>
                <Row gutter={[50, 16]}>
                  <Col span={24}>
                    <Row gutter={16}>
                      <Col>
                        <Typography.Text className={`${styles["sub-title"]} ${styles["color-title"]}`}>
                          {t("warrantyRequest.create.customerRequest")}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <FormEditCustomerInfoRequest data={data} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.fullname")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {data?.warrantyCustomerName}
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.numberphone")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {data?.warrantyCustomerPhone}
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.address")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {data?.warrantyCustomerAddress}
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.receive")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {warrantyRequestLabel?.[data?.warrantyReceiveMethod]}
                  </Col>
                </Row>
              </Col>
              <Col span={12} className={styles[""]}>
                <Row gutter={[50, 16]}>
                  <Col span={24}>
                    <Row gutter={16}>
                      <Col>
                        <Typography.Text className={`${styles["sub-title"]} ${styles["color-title"]}`}>
                          {t("warrantyRequest.create.addInfomartion")}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <FormEditAddInfo data={data} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.branch")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {data?.branch?.name}
                  </Col>
                  <Col span={24} xl={12}>
                    <Typography.Text strong>{t("warrantyRequest.detail.note")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={12}>
                    {data?.note}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Affix offsetBottom={10}>
            <Space className={styles["space"]}>
              {isCheckingForReturn?.length > 1 ? (
                <ButtonShippingType confirmReturn={confirmReturn} loading={loadingConfirmReturn} />
              ) : null}
            </Space>
          </Affix>
        </Col>
      </Row>
    </Spin>
  );
}

export default WarrantyRequestDetail;
