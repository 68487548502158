import { ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Col, Form, message, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { convertTotalNumber, EXPORT_TYPE } from "features/Serial/constant";
import { useGetSerialExportNotesWarranty } from "hooks/serial/query";
import { t } from "i18next";
import { uniq } from "lodash";
import { useState } from "react";
import { renderLack } from "utils/helperFuncs";
import styles from "../../../../../index.module.scss";
import InputSearch from "../../../components/InputSearch";
import "../../../index.scss";
import InputTag from "../InputTag";

function SerialsInputTag({
  index,
  record,
  tagsIndex,
  form,
  handleGetTags,
  setVisible,
  visible,
  handleDeleteAllSerial,
}) {
  const { handleGetSerialExportNotesWarranty, loading } = useGetSerialExportNotesWarranty();

  const tags = record?.serialItems?.map((item) => item);
  const [tagsCopy, setTagsCopy] = useState(tags); //onModal
  const [tagsCopy2, setTagsCopy2] = useState(tags); //offModal

  const handleChange = (e) => {
    visible ? setTagsCopy(e) : setTagsCopy2(e);
  };
  const [searchResult, setSearchResult] = useState([]);
  const [tagsValueResult, setTagsValueResult] = useState([]);

  const moreThanNumber = tagsIndex?.length > 2;
  const handleSearchSerials = (values) => {
    const sortedArray1 = tagsCopy2;
    const sortedArray2 = uniq(values);
    setTagsValueResult(sortedArray2);
    const searchValues = sortedArray1?.filter((value) => sortedArray2?.includes(value?.code));
    if (searchValues?.length > 0) {
      setSearchResult(searchValues);
    } else {
      setSearchResult([]);
    }
  };

  const renderSubTotal = (record) => {
    if (record?.quantity < record?.serialItems?.length) {
      return (
        <>
          <Col span={7}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={17} style={{ color: "red" }}>
            {record?.serialItems?.length - record?.quantity}
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return (
        <>
          <Col span={7}>
            <Typography.Text strong style={{ color: "" }}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <>
          <Col span={7}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}
            </Typography.Text>
          </Col>
          <Col span={17} style={{ color: "#ef9351" }}>
            {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
          </Col>
        </>
      );
    }
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    if (tagsCopy2?.length > 0) {
      setTagsCopy([...tagsCopy2]);
    }
    setVisible(false);
  };

  const handleOke = () => {
    setTagsCopy2([...tagsCopy]);
    setVisible(false);
  };

  const copyToClipboard = () => {
    if (tags?.length > 0) {
      const textToCopy = tags?.map((item) => item.code).join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };
  return (
    <Spin spinning={loading}>
      <Space className="custom-input-tag">
        <Form.Item name={[index, "serialItems"]}>
          <InputTag
            isHidden={true}
            tags={tags}
            onChange={handleChange}
            moreThanNumber={moreThanNumber}
            idPopup={false}
            record={record}
            form={form}
            handleGetSerialExportNotesWarranty={handleGetSerialExportNotesWarranty}
          />
        </Form.Item>
        {/* {moreThanNumber && ( */}
        <CustomModal
          {...{
            // buttonLabel: <Typography.Link>{t('common.seemore')}</Typography.Link>,
            footer: false,
            title: (
              <Typography.Title style={{ fontSize: "18px" }} strong>
                {t("serialIE.popupAddSerial")}
              </Typography.Title>
            ),
            width: "50%",
            visible: visible === index,
            onCancel: handleCancel,
            onOpen: handleOpen,
            onOke: handleOke,
            cancelButtonLabel: t("common.close"),
            hideConfirmButton: true,
          }}
        >
          <Row gutter={[16, 8]} style={{ fontSize: "13px" }}>
            <Col span={24}>
              <Card
                style={{ background: "#F7F8FB", marginBottom: "16px", fontSize: "13px" }}
                children={
                  <Row gutter={[16, 8]} className={styles["summary"]}>
                    <Col span={7}>
                      <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                    </Col>
                    <Col span={17}>
                      <Typography.Link strong href={`/product/detail/${record?.productID}`} target="_blank">
                        {record?.productCode}
                      </Typography.Link>
                    </Col>
                    <Col span={7}>
                      <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                    </Col>
                    <Col span={17}> {record?.productName}</Col>
                    <Col span={7}>
                      <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                    </Col>
                    <Col span={17}> {convertTotalNumber(record?.quantity)}</Col>
                    <Col span={7}>
                      <Typography.Text strong>{t("serialIE.productInfo.serialScanned")}</Typography.Text>
                    </Col>
                    <Col span={17}> {convertTotalNumber(record?.serialItems?.length)}</Col>
                    {renderSubTotal(record)}
                  </Row>
                }
              />
            </Col>
            <Col span={24}>
              <InputSearch onSearch={handleSearchSerials} />
            </Col>
            <Col span={24}>
              <Space style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <ExclamationCircleFilled style={{ color: "#EF9351" }} />{" "}
                  <Typography.Text type="warning" style={{ fontStyle: "italic" }}>
                    {t("serialIEDetail.warning")}
                  </Typography.Text>
                </div>
                <div>
                  <Popconfirm
                    title="Bạn có chắc chắn muốn xóa tất cả serial/IMEI không?"
                    onConfirm={() => handleDeleteAllSerial(index, EXPORT_TYPE.RETURN_SUPPLIER)}
                    okText="Xác nhận"
                    cancelText="Đóng"
                  >
                    <SvgIcon.IconDelete
                      style={{ fontSize: "20px", cursor: "pointer", marginRight: "8px" }}
                      width="21px"
                      height="21px"
                    />
                  </Popconfirm>
                  <Tooltip title={t("serialIE.copy")}>
                    <SvgIcon.CopyIcon
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      width="20px"
                      height="20px"
                      onClick={() => copyToClipboard()}
                    />
                  </Tooltip>
                </div>
              </Space>
            </Col>
            <Col span={24}>
              <Form.Item
                name={[index, "serialItems"]}
                rules={[
                  {
                    required: true,
                    message: t("serialIE.error.serialItems"),
                  },
                ]}
              >
                <InputTag
                  // value={tags}
                  tags={tags}
                  searchResult={searchResult}
                  tagsValueResult={tagsValueResult}
                  onChange={handleChange}
                  form={form}
                  idPopup={true}
                  record={record}
                  handleGetSerialExportNotesWarranty={handleGetSerialExportNotesWarranty}
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomModal>
        {/* )} */}
      </Space>
    </Spin>
  );
}

export default SerialsInputTag;
