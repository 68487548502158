import React from "react";
import { Col, Row, Typography } from "antd";
import { t } from "i18next";
import { solutionLabel, WRequestSolutionMethod } from "features/WarrantyRequest/constant";
import { convertTimeDate } from "hooks/warranty/converter";
import { DATE_FORMAT, SHIPPING_TYPE } from "config/constants";
import InfoAgent from "features/Warranty/Detail/components/InfoAgent";
import { useGetInfoPlatform } from "hooks/info-platform";

const CustomCol = ({ label, value }) => {
  return (
    <>
      <Col span={24} xl={7}>
        <Typography.Text strong>{label}</Typography.Text>
      </Col>
      <Col span={24} xl={17}>
        <Typography.Text>{value || "--"}</Typography.Text>
      </Col>
    </>
  );
};

const Provider = ({ data }) => {
  return (
    <>
      <CustomCol label={t("warrantyRequest.popup.ticketNumber")} value={data?.sendNoteNumber} />
      <CustomCol label={t("warrantyRequest.popup.warrantyParty")} value={data?.receivePartyName} />
      <CustomCol label={t("serial.sdt")} value={data?.receivePartyPhone} />
    </>
  );
};

const ThirdParty = ({ data }) => {
  return (
    <>
      <CustomCol label={t("warrantyRequest.popup.ticketNumber")} value={data?.sendNoteNumber} />
      <CustomCol label={t("warrantyRequest.popup.warrantyParty")} value={data?.receivePartyName} />
      <CustomCol label={t("serial.sdt")} value={data?.receivePartyPhone} />
    </>
  );
};

const Self = ({ data }) => {
  return (
    <>
      <CustomCol label={t("warrantyRequest.popup.selectStaff")} value={data?.technicalStaff?.fullname} />
      <CustomCol label={t("serial.sdt")} value={data?.technicalStaff?.telephone} />
    </>
  );
};

function TabSolution({ data, warrantyNote }) {
  const RenderBySolution = () => {
    switch (data?.solution) {
      case WRequestSolutionMethod.PROVIDER:
        return <Provider data={data} />;
      case WRequestSolutionMethod.THIRD_PARTY:
        return <ThirdParty data={data} />;
      case WRequestSolutionMethod.SELF:
        return <Self data={data} />;
      case WRequestSolutionMethod.FOREIGN_WARRANTY:
        return <></>;
      default:
        break;
    }
  };
  const { platformName } = useGetInfoPlatform();

  const SHIPPING_METHOD = {
    [`${SHIPPING_TYPE.BUS}`]: t("serial.bus"),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: t("serial.deliver3Party"),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: t("serial.pickUpCounter"),
    [`${SHIPPING_TYPE.URBAN_COD}`]: t("serial.urbanCOD", {
      platformName,
    }),
    null: "--",
  };

  return (
    <div>
      {data?.warrantySendDate || data?.reimportFileURLs ? (
        <Row gutter={[16, 16]}>
          <Col span={24} xl={12}>
            <Row gutter={[16, 8]} style={{ borderRight: "1px dashed #9CADC4" }}>
              <CustomCol label={t("serial.wayOfDistribute")} value={solutionLabel?.[data?.solution]} />
              <RenderBySolution />
            </Row>
          </Col>
          {data?.solution !== WRequestSolutionMethod.FOREIGN_WARRANTY && (
            <Col span={24} xl={12}>
              <Row gutter={[16, 8]}>
                {data?.solution !== WRequestSolutionMethod.SELF && (
                  <CustomCol label={t("serial.address")} value={data?.receivePartyAddress} />
                )}
                <CustomCol
                  label={t("warrantyRequest.popup.sendDate")}
                  value={convertTimeDate(data?.warrantySendDate, DATE_FORMAT)}
                />

                {data?.solution !== WRequestSolutionMethod.SELF && (
                  <Col span={24} xl={7}>
                    <Typography.Text strong>{t("serial.distributorDeliverMethod")}</Typography.Text>
                  </Col>
                )}
                <Col span={24} xl={17}>
                  <Typography.Text>{SHIPPING_METHOD?.[data?.distributeShippingType]}</Typography.Text>
                  <br />
                  <Typography.Text>
                    {data?.distributeShippingConfig?.busConfig
                      ? data?.distributeShippingConfig?.busConfig?.busName +
                        " - " +
                        data?.distributeShippingConfig?.busConfig?.telephone
                      : data?.distributeShippingConfig?.deliver3PartyConfig?.provider?.name}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      ) : (
        <InfoAgent warrantyDetail={warrantyNote} solution={data?.solution} />
      )}
    </div>
  );
}

export default TabSolution;
