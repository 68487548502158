import { Tag } from "antd";
import { find, reject } from "lodash";
import { useTranslation } from "react-i18next";
import "./index.scss";

const TagCustom = ({ label, filterText, handleOnClose }) => {
  return (
    <Tag closable onClose={handleOnClose}>
      <span className="label">{label}:</span>
      <span>&nbsp;{filterText}</span>
    </Tag>
  );
};

const TagsList = ({ listFilter, dataManufactures, dataWarehouses, dataAttributes, params, setParams }) => {
  const { t } = useTranslation();

  const pricesLevelList = [
    {
      label: t("product.priceLevel1"),
      key: "priceLevel1",
    },
    {
      label: t("product.priceLevel2"),
      key: "priceLevel2",
    },
    {
      label: t("product.priceLevel3"),
      key: "priceLevel3",
    },
    {
      label: t("product.priceLevel4"),
      key: "priceLevel4",
    },
  ];

  const renderTagNameManufactures = (key) => {
    if (listFilter.hasOwnProperty(key) && listFilter[`${key}`]) {
      const manufacturersList = listFilter[`${key}`]
        .map((item) => find(dataManufactures, { value: item }))
        .map((item) => item?.label)
        .join(", ");

      return (
        manufacturersList && (
          <TagCustom
            label={t("product.brand")}
            filterText={manufacturersList}
            handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
          />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagPriceLevel = (label, key) => {
    if (listFilter.hasOwnProperty([`${key}`]) && listFilter[`${key}`] !== undefined) {
      const renderFilterText = (objectKey) => {
        switch (objectKey?.isSpecified) {
          case true: {
            if (objectKey.range.from && !objectKey.range.to) {
              return `>=${objectKey.range.from}`;
            }
            if (!objectKey.range.from && objectKey.range.to) {
              return `<=${objectKey.range.to}`;
            }

            if (objectKey.range.to && objectKey.range.from) {
              return `${objectKey.range.from} - ${objectKey.range.to}`;
            } else {
              return t("product.havePrice");
            }
          }
          case false: {
            return t("product.haveNotPrice");
          }
          default: {
            return t("common.all");
          }
        }
      };
      return listFilter[`${key}`] === undefined ? (
        <></>
      ) : (
        <TagCustom
          key={label}
          label={label}
          filterText={renderFilterText(listFilter[`${key}`])}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    }
  };

  const renderTagRealStocks = (key) => {
    if (listFilter[`${key}`] && listFilter.hasOwnProperty([`${key}`])) {
      const listWarehouse = listFilter[`${key}`].map((item) => find(dataWarehouses, { id: item.warehouseID }));

      const renderRealStocks = (from, to) => {
        if (!from && to) {
          return `<=${to}`;
        }
        if (from && !to) {
          return `>=${from}`;
        }

        if (from && to) {
          return `${from} - ${to}`;
        }
        return "";
      };

      return listFilter[`${key}`].map((item, index) => (
        <TagCustom
          key={item.warehouseID}
          label={`${t("product.realStock")} (${listWarehouse[index]?.code})`}
          filterText={renderRealStocks(item?.range?.from, item?.range?.to)}
          handleOnClose={() => handleOnCloseTagWarehouses(item.warehouseID, key)}
        />
      ));
    } else {
      return null;
    }
  };

  const renderTagVat = (key) => {
    const renderVatText = (value) => {
      if (value === true) {
        return t("common.yes");
      } else if (value === false) {
        return t("common.no");
      } else if (value === null) {
        return t("common.all");
      } else {
        return "";
      }
    };

    if (listFilter.hasOwnProperty([`${key}`]) && listFilter[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("product.exportVat")}
          filterText={renderVatText(listFilter[`${key}`])}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagVatPercent = (key) => {
    if (listFilter[`${key}`] && listFilter.hasOwnProperty([`${key}`])) {
      const renderVatPercent = (from, to) => {
        if (typeof from !== "number" && to) return `<= ${to}`;
        if (from && typeof to !== "number") return `>= ${from}`;
        if (from === to && typeof from === "number" && typeof to === "number") return from;
        if (typeof from === "number" && typeof to === "number") return `${from} - ${to}`;
        return t("common.other");
      };

      return (
        <TagCustom
          label={`${t("product.vatPercent")}`}
          filterText={renderVatPercent(listFilter[`${key}`]?.from, listFilter[`${key}`]?.to)}
          handleOnClose={() => handleOnCloseTagPriceManufacturesVat(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagAttributeValues = (key) => {
    if (listFilter[`${key}`] && listFilter.hasOwnProperty([`${key}`])) {
      const listAttribute = listFilter[`${key}`].map((item) => find(dataAttributes, { id: item.attributeID }));

      const renderAttributeText = (valueFilter, valueIndex) => {
        return valueFilter.valueIDs
          .map((valueID) => listAttribute[valueIndex]?.values?.find((value) => value.id === valueID).value)
          .join(", ");
      };

      return listFilter[`${key}`].map((item, index) => (
        <TagCustom
          key={item.attributeID}
          label={`${listAttribute[index]?.name}`}
          filterText={renderAttributeText(item, index)}
          handleOnClose={() => handleOnCloseTagAttributes(item.attributeID, key)}
        />
      ));
    } else {
      return null;
    }
  };

  const handleOnCloseTagPriceManufacturesVat = (key) => {
    const cloneParams = { ...params };
    delete cloneParams.filters[`${key}`];
    setParams({
      ...cloneParams,
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    });
  };

  const handleOnCloseTagWarehouses = (idWarehouses, key) => {
    const cloneParams = { ...params };
    //array real stock after delete item tag
    const arrayRealStocks = reject(cloneParams.filters[`${key}`], (item) => item.warehouseID === idWarehouses);
    //new params after delete item tag
    const newParams = {
      ...cloneParams,
      filters: { ...cloneParams.filters, [`${key}`]: [...arrayRealStocks] },
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    };
    setParams({ ...newParams });
  };

  const handleOnCloseTagAttributes = (idAtt, key) => {
    const cloneParams = { ...params };
    const arrayAttributes = reject(cloneParams.filters[`${key}`], (item) => item.attributeID === idAtt);
    const newParams = {
      ...cloneParams,
      filters: {
        ...cloneParams.filters,
        [`${key}`]: arrayAttributes.length > 0 ? [...arrayAttributes] : undefined,
      },
      pagination: {
        ...cloneParams.pagination,
        offset: 0,
      },
    };
    setParams({ ...newParams });
  };

  return (
    <div className="tags-list">
      {/* Tag NSX */}
      {renderTagNameManufactures("manufacturerIDs")}
      {/* Tag giá cấp */}
      {pricesLevelList.map(({ label, key }) => renderTagPriceLevel(label, key))}
      {/* Tồn kho */}
      {renderTagRealStocks("realStocks")}
      {/* vat */}
      {renderTagVat("vat")}
      {/* % vat */}
      {renderTagVatPercent("vatPercent")}
      {/* Thuộc tính*/}
      {renderTagAttributeValues("attributeValues")}
    </div>
  );
};

export default TagsList;
