import React, { useEffect, useState } from "react";
import { t } from "i18next";
import CustomModal from "components/CustomModal";
import { Switch } from "antd";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { Link } from "react-router-dom";
import { useGetSellerCustomer, useUpdateSellerGroup } from "hooks/seller";
import { buildQueryString, getQuery, notify } from "utils/helperFuncs";
import useDebounce from "hooks/useDebounce";
import useSellerGroupOptions from "features/SellerGroup/hooks";
import { SELLER_GROUP_ACTIONS } from "features/SellerGroup/constant";
import Img from "assets/images";
import "./index.scss";

function useTable() {
  const GET_QUERY = getQuery();
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);
  const { filterStatus } = useSellerGroupOptions();

  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || undefined,
      isActive: GET_QUERY?.isActive || undefined,
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: 10,
    },
  });

  const { data, paginationData, loading, refetch } = useGetSellerCustomer(params);
  const { handleUpdateSellerGroup } = useUpdateSellerGroup();

  const onOke = async (record) => {
    try {
      await handleUpdateSellerGroup({
        sellerGroupInfo: {
          code: record?.code,
          name: record?.name,
          managerID: record?.managerID,
          isActive: !record?.isActive,
        },
        id: record?.id,
      });
      notify.success({
        message: t("customer.updateStatusSuccess"),
      });
      refetch();
    } catch (err) {
      notify.error({
        message: err?.message,
      });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };

    setParams(newParams);
  }

  const columns = [
    {
      title: t("common.index"),
      dataIndex: "id",
      align: "center",
      width: "2%",
      render: (_, _record, index) => index + 1,
    },
    {
      title: t("customer.table.customerCode"),
      width: "10%",
      dataIndex: "code",
      render: (_, record) => (
        <Link to={`seller-group/detail/${record?.id}`} style={{ color: "#2246DC" }}>
          {record?.code}
        </Link>
      ),
    },
    {
      title: t("customer.table.customerName"),
      dataIndex: "name",
      width: "15%",
    },
    {
      title: t("customer.table.desDetail"),
      align: "center",
      width: "20%",
      render: (_, record) => <div className="text-overflow">{record?.description || "- -"}</div>,
    },
    {
      title: t("customer.table.quantityAgent"),
      dataIndex: "sellerQuantity",
      align: "center",
      width: "10%",
    },
    {
      title: t("customer.table.status"),
      dataIndex: "isActive",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <CustomModal
          message={
            <p className="user-name">
              {record?.isActive === SELLER_GROUP_ACTIONS?.ACTIVE ? t("customer.confirmOff1") : t("customer.confirmOn")}
            </p>
          }
          centered={true}
          footer={false}
          onOke={() => onOke(record)}
          icon={record?.isActive === SELLER_GROUP_ACTIONS?.ACTIVE ? Img.LockedIcon : Img.UnlockedIcon}
          buttonLoading={loading}
          customComponent={
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={record?.isActive === SELLER_GROUP_ACTIONS.ACTIVE}
            />
          }
        />
      ),
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("isActive", value)} type="radio" options={filterStatus} />
      ),
    },
  ];

  useEffect(() => {
    buildQueryString({
      params: {
        offset: params?.pagination?.offset,
        limit: params?.pagination?.limit,
        isActive: params?.filters?.isActive,
        query: params?.filters?.query,
      },
    });
  }, [params]);

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue || null,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  }, [debouncedValue]);

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        isActive: GET_QUERY.isActive,
      },
    };
    setParams(newParams);
    buildQueryString({ params: { ...newParams } });
  }, []);

  return {
    columns,
    data,
    paginationData,
    loading,
    handleSearch,
    onTableChange,
    refetch,
  };
}

export default useTable;
