import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { useState, useEffect } from "react";

const useToolbar = ({ onChange, onSearch, countSelectedRowKeys }) => {
  const [searchTerm, setSearchTerm] = useState(undefined);
  const debounceValue = useDebounce(searchTerm);
  const [isFilterItems, setIsFilterItems] = useState(false);

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const handleChange = (e) => {
    onChange && onChange(e.target.value);
    setIsFilterItems(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    onSearch && onSearch(debounceValue);
  }, [debounceValue]);

  return { filterItemsTypeOptions, handleChange, handleSearch, isFilterItems };
};

export default useToolbar;
