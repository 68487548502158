import { useLazyQuery, useQuery } from "@apollo/client";
import { SALES_CHANNEL_INPUT_OPTIONS, SALES_CHANNEL_WITH_IDS } from "graphql/salesChannel/query";
import { convertOptions } from "./converter";

export const useSalesChannelInputOptions = (params) => {
  const { data, error, loading } = useQuery(SALES_CHANNEL_INPUT_OPTIONS, {
    variables: {
      filters: {},
    },
  });
  return {
    data: data?.saleChannel?.listSalesChannel,
    error,
    loading,
    convertedData: convertOptions(data?.saleChannel?.listSalesChannel),
  };
};

export const useSalesChannelWithIds = (params) => {
  const { data, error, loading } = useQuery(SALES_CHANNEL_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });

  return {
    data: data?.saleChannel?.listSalesChannel,
    error,
    loading,
    convertedData: convertOptions(data?.saleChannel?.listSalesChannel),
  };
};

export const useLazySalesChannel = () => {
  const [pagination, { loading, data, error }] = useLazyQuery(SALES_CHANNEL_INPUT_OPTIONS);

  const getSalesChannel = async () => {
    pagination({
      variables: {
        filters: {},
      },
    });
  };

  return {
    data: data?.saleChannel?.listSalesChannel,
    error,
    loading,
    convertedData: convertOptions(data?.saleChannel?.listSalesChannel),
    getSalesChannel,
  };
};
