/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import { SERIAL_AVAILABILITY } from "config/constants";
import RadioGroup from "features/Serial/components/RadioGroup";
import { useGetSerialExistWarehouse, useGetSerialExportNotesWarranty } from "hooks/serial/query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import InputSearch from "../../InputSearch";

const CheckboxGroup = Checkbox.Group;

const ModalSelectSerial = ({ record, form, handleGetTableOrder, index }) => {
  const PAGE_SIZE = 40;
  const defaultParams = {
    filter: {
      queries: [""],
      productIDs: record?.productID,
      statuses: SERIAL_AVAILABILITY.AVAILABLE,
    },
    pagination: {
      offset: 0,
      limit: PAGE_SIZE,
      skipTotalCount: true,
    },
  };
  const [params, setParams] = useState(defaultParams);
  const { loading, paginationData } = useGetSerialExistWarehouse(params, "cache-first");
  const [currentPage, setCurrentPage] = useState(1);
  const [isTypeChecked, setIsTypeChecked] = useState(true);
  const [listCheckedSerial, setListCheckedSerial] = useState([]);
  const formData = form.getFieldsValue();
  const [visible, setVisible] = useState(false);
  const [valueSearchInputSerial, setValueSearchInputSerial] = useState([]);
  const [listCheckedShowInTable, setListCheckedShowInTable] = useState([]);

  const { handleGetSerialExportNotesWarranty, loading: loadingGetSerial } = useGetSerialExportNotesWarranty();

  const filterItemsTypeOptions = [
    {
      label: `${t("cart.selected")} (${[...new Set([].concat(...Object.values(listCheckedSerial)))]?.length})`,
      value: true,
    },
  ];

  //handle convert object list array to array
  const convertObjListArrToArr = (object) => {
    return [...new Set([].concat(...Object?.values(object)))];
  };

  const startItemIndex = (currentPage - 1) * PAGE_SIZE;
  const endItemIndex = currentPage * PAGE_SIZE;
  const renderCheckboxChecked = convertObjListArrToArr(listCheckedSerial)?.slice(startItemIndex, endItemIndex);

  const handleSearchSerials = async (values) => {
    const newValues = values[0]?.split(/[, ]+/)?.map((value) => value?.trim());
    setValueSearchInputSerial([]);

    const serialData = await handleGetSerialExportNotesWarranty({
      filter: {
        queries: newValues,
        productIDs: record?.productID,
        statuses: SERIAL_AVAILABILITY.AVAILABLE,
      },
      pagination: {
        offset: 0,
        limit: newValues?.length,
        skipTotalCount: true,
      },
    });

    const serialGetData = serialData?.data?.serial?.pagination?.Serials?.map((item) => item?.code);

    newValues
      ?.filter((item) => !serialGetData?.includes(item))
      ?.map((item) => notify.warning({ message: `Serial/ IMEI ${item} không thỏa mãn điều kiện. Vui lòng thử lại` }));

    if (newValues?.length > 0) {
      setListCheckedSerial({
        ...listCheckedSerial,
        [currentPage]: [...(listCheckedSerial[currentPage] || []), ...serialGetData],
      });
    } else {
      setCurrentPage(1);
    }
  };

  const handleChangeRadioTypeFilterSerial = (e) => {
    setIsTypeChecked(e?.target?.value);
    setValueSearchInputSerial([]);
    setParams(defaultParams);
    setCurrentPage(1);
  };

  const onCheckboxChange = (page, list) => {
    setListCheckedSerial({ ...listCheckedSerial, ...{ [page]: list } });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setParams({
      filter: {
        queries: [""],
        productIDs: record?.productID,
        statuses: SERIAL_AVAILABILITY.AVAILABLE,
      },
      pagination: {
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        skipTotalCount: true,
      },
    });
  };

  const handleAddSerialModel = async () => {
    setValueSearchInputSerial([]);
    setListCheckedShowInTable(convertObjListArrToArr(listCheckedSerial));
    const serialData = await handleGetSerialExportNotesWarranty({
      filter: {
        queries:
          convertObjListArrToArr(listCheckedSerial)?.length > 0 ? convertObjListArrToArr(listCheckedSerial) : [" "],
        productIDs: record?.productID,
        statuses: SERIAL_AVAILABILITY.AVAILABLE,
      },
      pagination: {
        offset: 0,
        limit: convertObjListArrToArr(listCheckedSerial)?.length,
        skipTotalCount: true,
      },
    });
    const newItems = formData?.items?.map((item, i) => {
      const serialGetData = serialData?.data?.serial?.pagination?.Serials;
      const listSerialGetData = serialGetData?.filter((item) =>
        convertObjListArrToArr(listCheckedSerial)?.includes(item?.code)
      );
      if (i === index) {
        return {
          ...item,
          serialItems: listCheckedSerial,
          listSerialCheckedItems: listCheckedSerial,
          quantitySerialScanned: (listSerialGetData || [])?.length,
          serial: listSerialGetData || [],
        };
      } else {
        return item;
      }
    });
    form.setFieldsValue({
      ...formData,
      items: newItems,
    });
    handleGetTableOrder && handleGetTableOrder(newItems);
    setVisible(false);
  };

  const handleDeleteAllSerial = () => {
    setCurrentPage(1);
    setListCheckedSerial([]);
  };

  useEffect(() => {
    setListCheckedSerial(formData?.items[index]?.listSerialCheckedItems || []);
    setParams(defaultParams);
    setCurrentPage(1);
    setIsTypeChecked(true);
  }, [visible]);

  const onRemoveSelected = (value) => {
    const newSelectedValues = {};
    Object.keys(listCheckedSerial).forEach((key) => {
      const values = listCheckedSerial[key].filter((v) => v !== value);
      if (values.length > 0) {
        newSelectedValues[key] = values;
      }
    });
    setListCheckedSerial(newSelectedValues);
    if (renderCheckboxChecked.length === 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSelectedOption = (option) => (
    <div key={option}>
      <Checkbox
        checked={convertObjListArrToArr(listCheckedSerial)?.includes(option)}
        onChange={() => onRemoveSelected(option)}
        className="serial-checkbox-custom"
      >
        <div style={{ display: "flex", justifyContent: "space-around", gap: "10px" }}>
          <div>{option}</div>
          <div>
            <CloseOutlined />
          </div>
        </div>
      </Checkbox>
    </div>
  );

  const copyToClipboard = (listCheckedShowInTable) => {
    if (listCheckedShowInTable?.length > 0) {
      const textToCopy = listCheckedShowInTable.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };
  return (
    <div>
      <Space>
        <div className="input-tag-tags-detail">
          {listCheckedShowInTable?.length > 0 ? (
            listCheckedShowInTable?.map((val) => {
              return (
                <Tag className="input-tag-tag" key={val}>
                  {val}
                </Tag>
              );
            })
          ) : (
            <Typography.Text>{t("serialExportCreate.noCheckedSerial")}</Typography.Text>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "20px",
          }}
        >
          <CustomModal
            footer={false}
            width="50%"
            title={
              <Typography.Text strong style={{ fontSize: "16px" }}>
                {t("serialIE.selectSerialImei")}
              </Typography.Text>
            }
            customComponent={<Typography.Link className="select-serial">{t("serialIE.SelectSerial")}</Typography.Link>}
            visible={visible}
            onOke={handleAddSerialModel}
            onOpen={() => setVisible(true)}
            onCancel={() => setVisible(false)}
            className="form-model-list-tags-export-serial"
          >
            <Spin spinning={loadingGetSerial || loading}>
              <Row gutter={[16, 8]} style={{ marginTop: "12px", fontSize: "13px" }}>
                <Col span={24}>
                  <Card
                    style={{ background: "#F7F8FB", marginBottom: "16px" }}
                    children={
                      <Row gutter={[16, 8]}>
                        <Col span={8}>
                          <Typography.Text>{t("serialIE.productInfo.productCode")}</Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
                            <ProductLink product={record} />
                          </Typography.Link>
                        </Col>
                        <Col span={8}>
                          <Typography.Text>{t("serialIE.productInfo.productName")}</Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Typography.Text> {record?.product?.name || record?.productName}</Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Typography.Text> {t("serialIE.actualInventory")}</Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Typography.Text> {record?.inStock || 0}</Typography.Text>
                        </Col>
                      </Row>
                    }
                  />
                  <InputSearch onSearch={handleSearchSerials} valueSearchSerial={valueSearchInputSerial} />
                </Col>
                <Col span={24}>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Space>
                      <ExclamationCircleFilled style={{ color: "#EF9351" }} />
                      <Typography.Text
                        type="warning"
                        style={{ color: "#EF9351", fontStyle: "italic", fontSize: "12px" }}
                      >
                        {t("serialIE.addCondition")}
                      </Typography.Text>
                    </Space>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Popconfirm
                        title="Bạn có chắc chắn muốn xóa tất cả serial/IMEI không?"
                        onConfirm={() => handleDeleteAllSerial(index)}
                        okText="Xác nhận"
                        cancelText="Đóng"
                      >
                        <SvgIcon.IconDelete
                          style={{ fontSize: "20px", cursor: "pointer", marginRight: "8px" }}
                          width="21px"
                          height="21px"
                        />
                      </Popconfirm>

                      <RadioGroup
                        filterItemsTypeOptions={filterItemsTypeOptions}
                        handleChange={handleChangeRadioTypeFilterSerial}
                        value={isTypeChecked}
                      />
                    </div>
                  </Space>
                  <Divider />
                  <Form.Item name={[index, "serialItems"]} valuePropName="checked">
                    {isTypeChecked === true && (
                      <div>
                        <div className="ant-checkbox-group">{renderCheckboxChecked?.map(renderSelectedOption)}</div>
                        <Pagination
                          pageSize={PAGE_SIZE}
                          total={convertObjListArrToArr(listCheckedSerial)?.length || 0}
                          size="small"
                          showTotal={(total, range) => (
                            <Typography.Text>{`${t("serialExportCreate.show")} ${range[0]} - ${range[1]} ${t(
                              "serialExportCreate.of"
                            )}  ${total} (${Math.ceil(total / PAGE_SIZE)} ${t(
                              "serialExportCreate.page"
                            )})`}</Typography.Text>
                          )}
                          showSizeChanger={false}
                          onChange={handlePageChange}
                          current={currentPage}
                        />
                      </div>
                    )}
                    {isTypeChecked === false && (
                      <div>
                        <CheckboxGroup
                          value={listCheckedSerial[currentPage] || []}
                          loading={loading}
                          onChange={(list) => onCheckboxChange(currentPage, list)}
                        />
                        <Pagination
                          pageSize={PAGE_SIZE}
                          total={paginationData?.total || 0}
                          size="small"
                          showSizeChanger={false}
                          showTotal={(total, range) => (
                            <Typography.Text>{`${t("serialExportCreate.show")} ${range[0]} - ${range[1]} ${t(
                              "serialExportCreate.of"
                            )}  ${total} (${Math.ceil(total / PAGE_SIZE)} ${t(
                              "serialExportCreate.page"
                            )})`}</Typography.Text>
                          )}
                          onChange={handlePageChange}
                          current={currentPage}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </CustomModal>
          <Tooltip title={t("serialIE.copy")}>
            <SvgIcon.CopyIcon
              style={{ fontSize: "30px", cursor: "pointer" }}
              width="20px"
              height="20px"
              onClick={() => copyToClipboard(listCheckedShowInTable)}
            />
          </Tooltip>
        </div>
      </Space>
    </div>
  );
};
export default ModalSelectSerial;
