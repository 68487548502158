import { Form } from "antd";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { t } from "i18next";
import { formatCurrency, formatInStock, isMaterialProduct } from "utils/helperFuncs";
import { isNull } from "lodash";
import { TYPE_PRODUCT } from "pages/App/Order/conts";
import "./index.scss";

const calcSum = (arrNumber) =>
  formatCurrency(arrNumber.reduce((previousValue, currentValue) => previousValue + currentValue, 0));

const calcSumByProductType = (arr, type, fieldTotal) =>
  formatCurrency(
    arr
      ?.filter((item) => item?.product?.productType === type)
      ?.map((item) => item[`${fieldTotal}`])
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  );

const validatePrice = (_, value) => {
  if (isNull(value)) {
    return Promise.reject(new Error(t("order.splitOrder.validateBlank")));
  } else {
    return value.toString().length <= 10
      ? Promise.resolve()
      : Promise.reject(new Error(t("order.splitOrder.maximumPrice")));
  }
};

const CardOrder = ({ item, orderSpilt, infoOrder, isHidden, getFieldsValue }) => {
  const totalPrice = calcSumByProductType(orderSpilt?.items, TYPE_PRODUCT.MATERIAL, "total");
  const validatePositiveInteger = {
    pattern: new RegExp(/^\+?(0|[1-9]\d*)$/gm),
    message: t("order.splitOrder.wrongFormat"),
  };

  const isEnoughOrder = item?.name === 0;
  const isDebt = infoOrder.paymentMethod === "DEBT";

  const totalCustomerPrice = calcSumByProductType(orderSpilt?.items, TYPE_PRODUCT.MATERIAL, "customerTotal");

  const totalServicePrice = calcSumByProductType(orderSpilt?.items, TYPE_PRODUCT.SERVICE, "total");

  const columns = [
    {
      title: t("order.splitOrder.no"),
      render: (text, record, index) => index + 1,
      isHidden: false,
      align: "center",
    },
    {
      title: t("order.splitOrder.productCode"),
      dataIndex: "productCode",
      isHidden: false,
      align: "center",
    },
    {
      title: t("order.splitOrder.productName"),
      dataIndex: "productName",
      isHidden: false,
      align: "center",
    },
    {
      title: (
        <div className="table-tittle">
          <p>{t("order.orderDetail.availableInventory")}</p>
          <p className="table-subtitle">({infoOrder?.nameStock})</p>
        </div>
      ),
      dataIndex: "inStock",
      render: (_, record) => {
        if (isMaterialProduct(record.product.productType)) {
          return formatInStock(record?.inStock);
        }
      },
      align: "center",
      isHidden: false,
    },
    {
      title: t("order.splitOrder.quantity"),
      dataIndex: "quantitySpilt",
      render: (text, record, index) => (
        <FormInput
          type="NUMBER"
          formItemOptions={{
            name: [index, "quantitySpilt"],
            rules: [
              { required: true, message: t("order.splitOrder.validateBlank") },
              validatePositiveInteger,
              {
                ...validatePositiveInteger,
              },
              {
                validator: (_, value) =>
                  value <= record.quantity
                    ? Promise.resolve()
                    : Promise.reject(new Error(t("order.splitOrder.maximumQuantity"))),
              },
            ],
          }}
          inputOptions={{ className: "input-align-center" }}
        />
      ),
      width: 100,
      isHidden: false,
      align: "center",
    },
    {
      title: t("order.splitOrder.price"),
      dataIndex: "price",
      isHidden: false,
      align: "center",
      render: (text, record, index) => (
        <FormInput
          type="CURRENCY_INPUT"
          formItemOptions={{
            name: [index, "price"],
            rules: [
              {
                validator: validatePrice,
              },
              validatePositiveInteger,
            ],
          }}
          inputOptions={{ className: "input-align-end" }}
        />
      ),
      width: 200,
    },
    {
      title: t("order.splitOrder.total"),
      dataIndex: "total",
      isHidden: false,
      align: "end",
      render: (text, record, index) => formatCurrency(text),
    },
    {
      title: t("order.splitOrder.customerPrice"),
      dataIndex: "customerPrice",
      isHidden: isHidden,
      align: "center",
      render: (text, record, index) => (
        <FormInput
          type="CURRENCY_INPUT"
          formItemOptions={{
            name: [index, "customerPrice"],
            rules: [
              {
                validator: validatePrice,
              },
              validatePositiveInteger,
            ],
          }}
          inputOptions={{ className: "input-align-end" }}
        />
      ),
      width: 200,
    },
    {
      title: t("order.splitOrder.customerTotal"),
      dataIndex: "customerTotal",
      isHidden: isHidden,
      align: "end",
      render: (text, record, index) => formatCurrency(text),
    },
  ];

  const columnsRender = columns.filter((column) => column.isHidden === false);

  return (
    <div className="container-spilt-order">
      <p className="order-code">{orderSpilt.title}</p>
      <p className="order-info">{t("order.splitOrder.orderInfo")}</p>
      <Form.List name={[item.name, "items"]}>
        {(fields) => {
          return (
            <CustomTable
              className="table"
              columns={columnsRender}
              scroll={{ x: "2000", y: null }}
              dataSource={orderSpilt?.items}
              rowKey={(obj) => obj?.id}
            />
          );
        }}
      </Form.List>

      <div className="bill">
        <p>{t("order.splitOrder.subTotal")}</p>
        <b>{totalPrice}</b>
        {!isHidden && (
          <>
            <p>{t("order.splitOrder.customerSubTotal")}</p>
            <b>{totalCustomerPrice}</b>
          </>
        )}
        <p>{t("order.splitOrder.serviceFee")}</p>
        <b>{totalServicePrice}</b>
        <p>{t("order.splitOrder.discountAmount")}</p>
        <FormInput
          type="CURRENCY_INPUT"
          formItemOptions={{
            name: [orderSpilt.index, "discount"],
            rules: [
              {
                validator: validatePrice,
              },

              validatePositiveInteger,
            ],
          }}
          inputOptions={{ className: "input-align-end" }}
        />
        <p>{t("order.splitOrder.shippingFee")}</p>
        <FormInput
          type="CURRENCY_INPUT"
          formItemOptions={{
            name: [orderSpilt.index, "shippingFee"],
            rules: [
              {
                validator: validatePrice,
              },
              validatePositiveInteger,
            ],
          }}
          inputOptions={{
            className: "input-align-end",
            defaultValue: infoOrder?.shippingFee || 0,
          }}
        />
        <p>{t("order.splitOrder.totalBill")}</p>
        <b>
          {calcSum([
            ...orderSpilt?.items?.map((item) => item?.total),
            infoOrder?.serviceFee,
            -getFieldsValue(["request", orderSpilt.index, "discount"]),
            getFieldsValue(["request", orderSpilt.index, "shippingFee"]),
          ])}
        </b>
        {!isHidden && (
          <>
            <p>{t("order.splitOrder.customerTotalBill")}</p>
            <b>
              {calcSum([
                ...orderSpilt?.items?.map((item) => item?.customerTotal),
                infoOrder?.serviceFee,
                -getFieldsValue(["request", orderSpilt.index, "discount"]),
                getFieldsValue(["request", orderSpilt.index, "shippingFee"]),
              ])}
            </b>
          </>
        )}
        <p className="text-blue">
          {isDebt ? t("order.splitOrder.paymentByDebt") : t("order.splitOrder.paymentByCash")}
        </p>
        <p className="text-blue">
          {calcSum([
            ...orderSpilt?.items?.map((item) => item?.total),
            infoOrder?.serviceFee,
            -getFieldsValue(["request", orderSpilt.index, "discount"]),
            getFieldsValue(["request", orderSpilt.index, "shippingFee"]),
          ])}
        </p>
      </div>
    </div>
  );
};

export default CardOrder;
