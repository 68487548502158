import { Col, Form, Row, Space, Table, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import {
  CURRENCY_INPUT,
  PURCHASE_STATUS,
  dataPeriodUnitLabel,
  styleHeaderCardChildren,
  styleTitleCard,
} from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar, formatDate } from "utils/helperFuncs";

function ProductsListLoadMore({ data }) {
  const showExpectedDeliveryColumn =
    data?.status === PURCHASE_STATUS.WAITING_FOR_DEPOSIT || data?.status === PURCHASE_STATUS.WAITING_FOR_RECEIVING;

  const columns = [
    {
      title: t("purchase.table.stt"),
      fixed: true,
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("purchase.table.parameter"),
      fixed: true,
      width: 300,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Link strong>
              <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
                <Typography.Link strong>{record?.product?.code}</Typography.Link>
              </Link>
            </Typography.Link>
            <CustomTextHiddenLine line={2} text={record?.product?.name || "--"} />
          </div>
        );
      },
    },
    {
      title: t("warehouse.recommendImport.quantity"),
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.quantity || 0;
      },
    },
    {
      title: t("purchase.table.AmountReceived"),
      width: 120,
      align: "center",
      render: (_, record, index) => {
        function handleCheckQuantity(receivedQuantity, quantity) {
          if (receivedQuantity > quantity) {
            return (
              <Typography.Text type="danger">
                {t("purchase.table.redundant")} {Math.abs(receivedQuantity - quantity)}
              </Typography.Text>
            );
          } else if (receivedQuantity < quantity) {
            return (
              <Typography.Text type="warning">
                {t("purchase.table.lack")} {Math.abs(receivedQuantity - quantity)}
              </Typography.Text>
            );
          } else {
            return <Typography.Text type="success">{t("purchase.table.enough")}</Typography.Text>;
          }
        }

        return (
          <Space direction="vertical" style={{ alignItems: "center" }}>
            <Typography.Text>{record?.receivedQuantity - (record?.notQualifiedQuantity || 0)}</Typography.Text>
            {data?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING &&
              handleCheckQuantity(record?.receivedQuantity - (record?.notQualifiedQuantity || 0), record?.quantity)}
          </Space>
        );
      },
    },
    {
      title: t("purchase.table.unitPriceAfterTax"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <Typography.Text>
            {record.currency === CURRENCY_INPUT.VND
              ? formatCurrency(record.afterVatPrice)
              : formatCurrencyDollar(record.afterVatPrice)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.percentVat"),
      width: 80,
      align: "center",
      render: (_, record, index) => {
        return <Typography.Text>{record.vatPercent}</Typography.Text>;
      },
    },
    {
      title: t("purchase.table.unitPriceBeforeTax"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record.currency === CURRENCY_INPUT.VND
              ? formatCurrency(record?.beforeVatPrice)
              : formatCurrencyDollar(record?.beforeVatPrice)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.intoMoney"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record.currency === CURRENCY_INPUT.VND
              ? formatCurrency(record?.subTotal)
              : formatCurrencyDollar(record?.subTotal)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.vatMoney"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record.currency === CURRENCY_INPUT.VND
              ? formatCurrency(record?.vatAmount)
              : formatCurrencyDollar(record?.vatAmount)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.unitPriceAfterDiscount"),
      align: "center",
      width: 220,
      render: (_, record, index) => {
        return (
          <Typography.Text>
            {record.currency === CURRENCY_INPUT.VND
              ? formatCurrency(record?.afterDiscountPrice)
              : formatCurrencyDollar(record?.afterDiscountPrice)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.warrantyPeriod"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <Typography.Text>
            {record?.warranty} {dataPeriodUnitLabel[record?.warrantyPeriod]}
          </Typography.Text>
        );
      },
    },
    {
      title: t("purchase.table.numberDaysDebt"),
      width: 100,
      align: "center",
      render: (_, record, index) => {
        return <Typography.Text>{record?.creditDays}</Typography.Text>;
      },
    },
    showExpectedDeliveryColumn
      ? {
          title: t("purchase.table.expectedDelivery"),
          width: 200,
          align: "center",
          render: (_, record, index) => {
            return <Typography.Text>{formatDate(record?.expectedDeliveryTime)}</Typography.Text>;
          },
        }
      : {},
  ];

  const renderSummaryTableSelected = (pageData) => {
    const currency = pageData?.[0]?.currency === CURRENCY_INPUT.VND;

    var recommendedQuantity = data?.items?.reduce(function (total, current) {
      return total + (current?.receivedQuantity - (current?.notQualifiedQuantity || 0));
    }, 0);

    var quantityToBuy = data?.items?.reduce(function (total, current) {
      return total + current?.quantity;
    }, 0);

    var totalIntoMoney = data?.items?.reduce(function (total, current) {
      return total + current?.subTotal;
    }, 0);

    var totalVatMoney = data?.items?.reduce(function (total, current) {
      return total + current?.vatAmount;
    }, 0);

    const stylesSummary = {
      color: "#052987",
      textAlign: "center",
      fontWeight: "bold",
    };

    const colorTotals = { color: "#052987" };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.total")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            <Typography.Text style={colorTotals}>{quantityToBuy}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            <Typography.Text style={colorTotals}> {recommendedQuantity || 0}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} colSpan={4}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currency ? formatCurrency(totalIntoMoney) : formatCurrencyDollar(totalIntoMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currency ? formatCurrency(totalVatMoney) : formatCurrencyDollar(totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.totalPayment")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={7}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currency
                  ? formatCurrency(totalIntoMoney + totalVatMoney)
                  : formatCurrencyDollar(totalIntoMoney + totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <CustomCard
      headStyle={styleHeaderCardChildren}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.productList")}
        </Typography.Text>
      }
      content={
        <Row>
          <Col span={24}>
            <Form.List name={"items"}>
              {(fields, { add, remove }) => {
                return (
                  <CustomTable
                    scroll={{
                      x: "max-content",
                    }}
                    columns={columns}
                    dataSource={data?.items}
                    summary={renderSummaryTableSelected}
                  />
                );
              }}
            </Form.List>
          </Col>
        </Row>
      }
    />
  );
}

export default ProductsListLoadMore;
