import React from "react";
import { t } from "i18next";
import { TabsMenu } from "components/CustomTabs";
import { ACTIVITY_TABS } from "features/Agent/constants";
import ActivityLogs from "../ActivityLogs";
import BuyHistory from "../BuyHistory";
import AddressBook from "../AddressBook";
import { useSupplierPermissions } from "hooks/supplier";
import { useSellerPermissions } from "hooks/seller";

const ActivityTabs = ({ isSupplier, supplierDetail }) => {
  const { canSupplierContactView } = useSupplierPermissions();
  const { canViewContact } = useSellerPermissions();

  const tabs = [
    {
      title: <span className="title"> {t("seller.activitylogtabs.tabs.activeHistory")}</span>,
      key: ACTIVITY_TABS.ACTIVE_HISTORY,
      children: <ActivityLogs isSupplier={isSupplier} />,
    },
    ...(supplierDetail?.isSeller
      ? [
          {
            title: <span className="title"> {t("seller.activitylogtabs.tabs.buyHistory")}</span>,
            key: ACTIVITY_TABS.BUY_HISTORY,
            children: <BuyHistory />,
          },
        ]
      : []),
    ...((isSupplier ? canSupplierContactView : canViewContact)
      ? [
          {
            title: <span className="title"> {t("seller.activitylogtabs.tabs.bookAddress")}</span>,
            key: ACTIVITY_TABS.BOOK_ADDRESS,
            children: <AddressBook isSupplier={isSupplier} />,
          },
        ]
      : []),
  ];

  return (
    <div className="wrapper-tabs">
      <TabsMenu tabs={tabs} />
    </div>
  );
};

export default ActivityTabs;
