import { Col, Row, Space } from "antd";
import "./index.scss";
import { dimensionUnitLabel, weightLabel } from "pages/App/Product/constants";

function ItemOdd({ data, colIcon, spanNumber, fullCol = false, limitCol = 7, dimension, weight }) {
  // Kiểm tra xem dimension và weight đã được thêm vào data chưa
  let hasDimension = false;
  let hasWeight = false;

  data?.forEach((item) => {
    if (item.label === "Kích thước") {
      hasDimension = true;
    }
    if (item.label === "Cân nặng") {
      hasWeight = true;
    }
  });

  // Thêm dimension và weight vào data nếu chúng tồn tại và chưa được thêm trước đó
  if (dimension && !hasDimension) {
    data?.unshift({
      label: "Kích thước",
      value: `${dimension?.length} x ${dimension?.width} x ${dimension?.height} (${
        dimensionUnitLabel?.[dimension?.unit]
      })`,
    });
  }
  if (weight && !hasWeight) {
    data?.unshift({ label: "Cân nặng", value: `${weight?.weight} ${weightLabel?.[weight?.unit]}` });
  }

  return (
    <div className="custom-item-odd">
      <Space direction="vertical" className="wrap-items-odd">
        {data?.map((item, index) => {
          const isHiddenItem = index === limitCol;
          if (fullCol) {
            return (
              <Row className={`item-odd`} key={index}>
                <Col style={{ color: "rgba(101, 108, 120, 1)" }} span={spanNumber?.[0] || 10}>
                  {item?.label}
                </Col>
                <Col span={spanNumber?.[1] || 14}>{item?.value}</Col>
                {colIcon && <Col span={spanNumber?.[2]}>{item?.icon}</Col>}
              </Row>
            );
          } else if (index < limitCol || isHiddenItem) {
            return (
              <Row className={`item-odd ${isHiddenItem ? "hidden-item" : ""}`} key={index}>
                <Col style={{ color: "rgba(101, 108, 120, 1)" }} span={spanNumber?.[0] || 10}>
                  {item?.label}
                </Col>
                <Col span={spanNumber?.[1] || 14}>{item?.value}</Col>
                {colIcon && <Col span={spanNumber?.[2]}>{item?.icon}</Col>}
              </Row>
            );
          } else {
            return null;
          }
        })}
      </Space>
    </div>
  );
}

export default ItemOdd;
