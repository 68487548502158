import { Form } from "antd";
import { PURCHASE_REQUEST_TYPE } from "features/Purchase/constant";
import { useCreateProductGroup } from "hooks/product/product";
import { useCreatePurchase } from "hooks/purchase";
import { useUploadDocument } from "hooks/upload-document";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const calcBeforeVatPrice = (afterVatPrice, vatPercent) => {
  return afterVatPrice / (1 + (vatPercent || 0) / 100);
};

const calcIntoMoney = (afterVatPrice, vatPercent, quantityToBuy) => {
  return (afterVatPrice / (1 + (vatPercent || 0) / 100)) * quantityToBuy;
};

const calcVatMoney = (afterVatPrice, vatPercent, quantityToBuy) => {
  return (afterVatPrice / (1 + (vatPercent || 0) / 100)) * quantityToBuy * (vatPercent / 100);
};

const convertDataItems = (data) => {
  return data?.map((item) => ({
    ...item,
    id: item?.id,
    productID: item?.product?.id,
    productName: item?.product?.name || item?.productName,
    productCode: item?.product?.code,
    currency: item?.currency, // currency unit
    warranty: item?.warranty, // thời gian bảo hành
    warrantyPeriod: item?.warrantyPeriod, // data unit
    recommendedQuantity: item.quantity, // số lượng đề nghị
    creditDays: item?.creditDays, // ngày được nợ
    quantity: item?.quantity, // số lượng cần mua
    afterVatPrice: item?.price || 0, // đơn giá sau thuế
    vatPercent: item?.product?.vatPercent || 0, // %VAT
    afterDiscountPrice: item?.price, // đơn giá sau chiết khấu
    expectedDeliveryTime: item?.expectedDeliveryTime ? moment(item?.expectedDeliveryTime) : null,
    beforeVatPrice: calcBeforeVatPrice(item?.price, item?.product?.vatPercent) || 0, // đơn giá trước thuế
    intoMoney: calcIntoMoney(item?.price, item?.product?.vatPercent, item.quantity) || 0, // thành tiền
    vatMoney: calcVatMoney(item?.price, item?.product?.vatPercent, item.quantity) || 0, // Tiền thuế GTGT
  }));
};

function usePurchaseCreate() {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dataState = { ...location.state };
  const [paymentTerms, setPaymentTerms] = useState();
  const [productListSelected, setProductListSelected] = useState(convertDataItems(dataState?.items) || []);
  const [isShowFully, setIsShowFully] = useState(false);
  const [isShowPartially, setIsShowPartially] = useState(false);
  const [loadingCreatePurchase, setLoadingCreatePurchase] = useState(false);
  const { warehouses } = useWarehouse({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { handleUpload } = useUploadDocument();
  const { handleCreatePurchase } = useCreatePurchase();
  const { handleCreateProductGroup } = useCreateProductGroup();

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const initialValues = {
    fileURLs:
      dataState?.fileURLs?.map((url) => {
        return {
          url: url,
        };
      }) || [],
    importWarehouseID: dataState?.warehouseID || undefined,
    internalNote: dataState?.note || undefined,
    items: convertDataItems(dataState?.items) || [],
    paymentDebtAmount: undefined,
    paymentDepositAmount: undefined,
    paymentMethods: dataState?.paymentMethods || undefined,
    paymentOnDeliveryAmount: undefined,
    paymentTerms: dataState?.paymentTerms || undefined,
    requestType: dataState?.type || PURCHASE_REQUEST_TYPE.DOMESTIC,
    sellerID: dataState?.supplier?.fullName || undefined,
    transferNote: dataState?.paymentNotes || undefined,
  };

  const handleAddCodeProduct = (values, idItems, attributes) => {
    const formData = form.getFieldsValue();
    const newData = {
      isAdd: true,
      id: idItems,
      productCode: values?.productCode,
      productName: values?.productName,
      brandID: values?.brandID,
      categoryID: values?.categoryID,
      productWarranty: values?.productWarranty,
      uomID: values?.uomID,
      productDescription: values?.productDescription,
      dimension: {
        length: values?.dimension?.length,
        width: values?.dimension?.width,
        height: values?.dimension?.height,
        unit: values?.dimension?.unit,
      },
      weight: {
        weight: values?.weight?.weight,
        unit: values?.weight?.unit,
      },
      attributeValues: values?.attributeValues?.map((item, index) => ({
        attributeID: attributes[index]?.attributeID,
        attributeValueIDs: item.attributeValueID,
        isVariant: attributes[index]?.isVariant,
      })),
    };
    const updateItemsArray = (itemsArray) => {
      const existingIndex = itemsArray.findIndex((item) => item.id === idItems);
      return [
        ...itemsArray.slice(0, existingIndex),
        { ...itemsArray[existingIndex], ...newData },
        ...itemsArray.slice(existingIndex + 1),
      ];
    };

    form.setFieldsValue({
      ...formData,
      items: updateItemsArray(formData.items),
    });

    setProductListSelected((prevList) => {
      const updatedList = prevList?.map((item) => (item.id === idItems ? { ...item, ...newData, isAdd: true } : item));
      if (!updatedList?.some((item) => item.id === idItems)) {
        updatedList.push({ ...newData, id: idItems });
      }
      return updatedList;
    });
  };

  const handleOpenFully = async () => {
    const formValues = await form.validateFields();
    const allHaveIdOrIsAdd = formValues?.items.every((item) => item.productID || item.isAdd);
    if (allHaveIdOrIsAdd) {
      setIsShowFully(true);
    } else {
      notify.error({
        message: "Đơn mua chứa sản phẩm chưa có mã. Vui lòng thêm mới mã sản phẩm!",
      });
    }
  };

  const handleCancelFully = () => {
    setIsShowFully(false);
  };

  const handleOpenPartially = async () => {
    const formValues = await form.validateFields();
    const allHaveIdOrIsAdd = formValues?.items.every((item) => item.productID || item.isAdd);
    if (allHaveIdOrIsAdd) {
      setIsShowPartially(true);
    } else {
      notify.error({
        message: "Đơn mua chứa sản phẩm chưa có mã. Vui lòng thêm mới mã sản phẩm!",
      });
    }
  };

  const handleCancelPartially = async () => {
    setIsShowPartially(false);
  };

  const handleGetProductList = (value) => {
    setProductListSelected(value);
  };

  const handleValuesChange = (changedValue, allValues) => {
    const formValues = form.getFieldsValue();
    if (changedValue.paymentTerms) {
      setPaymentTerms(changedValue.paymentTerms);
    }
    if (changedValue.items) {
      const newItems = allValues?.items?.map((item, index) => {
        return {
          ...item,
          beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item.vatPercent) || 0, // đơn giá trước thuế
          intoMoney: calcIntoMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // thành tiền
          vatMoney: calcVatMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // Tiền thuế GTGT
        };
      });
      form.setFieldsValue({
        ...formValues,
        items: newItems,
      });

      setProductListSelected(newItems);
    }
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t("uploadDocument.uploadImageError"),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const handleCreate = async ({ orderType }) => {
    try {
      const formValues = await form.validateFields();

      if (!productListSelected?.length) {
        notify.error({ message: t("purchase.errorMessage.productLength") });
      }
      setLoadingCreatePurchase(true);
      const imageURLs = await uploadImage(formValues?.fileURLs);

      const convertValues = {
        requestType: formValues.requestType,
        importWarehouseID: formValues.importWarehouseID,
        orderType: orderType,
        paymentMethods: formValues?.paymentMethods,
        transferNote: formValues?.transferNote,
        paymentTerms: formValues?.paymentTerms,
        paymentDepositAmount: formValues?.paymentDepositAmount || 0,
        paymentOnDeliveryAmount: formValues?.paymentOnDeliveryAmount || 0,
        paymentDebtAmount: formValues?.paymentDebtAmount || 0,
        sellerID:
          dataState?.supplier?.fullName === formValues?.sellerID ? dataState?.supplier?.id : formValues?.sellerID,
        internalNote: formValues?.internalNote,
        fileURLs: imageURLs || [],
        refImportRequestID: dataState.id,
        items: formValues?.items?.map((item, index) => ({
          productID: item?.productID,
          currency: item?.currency,
          productGroupInput: !item?.productID
            ? {
                name: item?.productName,
                code: item?.productCode,
                description: item?.productDescription,
                brandID: item?.brandID,
                categoryID: item?.categoryID,
                uomID: item?.uomID,
                vat: item?.isVat,
                warranty: item?.productWarranty,
                attributeValues: item?.attributeValues?.map((i) => ({
                  attributeID: i?.attributeID,
                  attributeValueIDs: i?.attributeValueIDs,
                  isVariant: i?.isVariant,
                })),
                hasVariant: false,
                dimension: {
                  height: item?.dimension?.height,
                  length: item?.dimension?.length,
                  unit: item?.dimension?.unit,
                  width: item?.dimension?.width,
                },
                weight: { weight: item?.weight?.weight, unit: item?.weight?.unit },
              }
            : undefined,
          warranty: item?.warranty,
          warrantyPeriod: item?.warrantyPeriod,
          creditDays: item?.creditDays,
          quantity: item?.quantity,
          afterVatPrice: item?.afterVatPrice,
          vatPercent: item?.vatPercent,
          afterDiscountPrice: item?.afterDiscountPrice,
          expectedDeliveryTime: item?.expectedDeliveryTime,
        })),
      };
      handleCreatePurchase(convertValues)
        .then((data) => {
          notify.success({ message: t("purchase.createSuccess") });
          form.resetFields();
          handleCancelFully();
          handleCancelPartially();
          setLoadingCreatePurchase(false);
          history.push(`/purchase/detail/${data?.data?.purchaseOrder?.create?.id}`);
        })
        .catch((error) => {
          setLoadingCreatePurchase(false);
          notify.error({
            message: t("common.error"),
            description: error.message,
          });
        });
    } catch (info) {
      setLoadingCreatePurchase(false);
      console.info("Validate Failed:", info);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    });
    setPaymentTerms(initialValues.paymentTerms);
  }, [JSON.stringify(initialValues)]);

  return {
    warehouseOptions,
    form,
    handleValuesChange,
    paymentTerms,
    initialValues,
    handleCreate,
    handleGetProductList,
    productListSelected,
    dataState,
    loadingCreatePurchase,
    isShowFully,
    isShowPartially,
    handleOpenFully,
    handleCancelFully,
    handleOpenPartially,
    handleCancelPartially,
    handleAddCodeProduct,
  };
}

export default usePurchaseCreate;
