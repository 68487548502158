import { Col } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX, SHIPPING_TYPE } from "config/constants";
import { useGetShippingProvider } from "hooks/warranty";
import { t } from "i18next";

function ShippingType({ shippingType }) {
  const { data } = useGetShippingProvider();
  const shippingProviderOptions = data?.map(({ id, name }) => ({ value: id, label: name }));

  const delivers = {
    [`${SHIPPING_TYPE.BUS}`]: (
      <>
        <Col span={12}>
          <FormInput
            formItemOptions={{
              name: ["busConfig", "busName"],
              rules: [{ required: true, message: t("cart.blankBusName") }],
            }}
            inputOptions={{
              placeholder: t("cart.busNamePlaceholder"),
            }}
          />
        </Col>

        <Col span={12}>
          <FormInput
            formItemOptions={{
              name: ["busConfig", "telephone"],
              rules: [
                {
                  pattern: REGEX.PHONE,
                  message: t("common.wrongPhone"),
                },
              ],
            }}
            inputOptions={{
              placeholder: t("cart.telephonePlaceholder"),
            }}
          />
        </Col>
      </>
    ),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: (
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            style: { width: "100%" },
            name: ["deliver3PartyConfig", "providerID"],
            rules: [{ required: true, message: "Vui lòng chọn đơn vị vận chuyển" }],
          }}
          inputOptions={{
            placeholder: t("cart.serviceCodePlaceholder"),
            options: shippingProviderOptions,
          }}
        />
      </Col>
    ),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: null,
    [`${SHIPPING_TYPE.URBAN_COD}`]: null,
  };

  return delivers[`${shippingType}`];
}

export default ShippingType;
