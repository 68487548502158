import { Button, Form, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import FormUpdateNotOwner from "features/WarrantyRequest/SerialDetail/UpdateSerial/NotOwner";
import FormUpdateOwner from "features/WarrantyRequest/SerialDetail/UpdateSerial/Owner";
import { WARRANTY_PRODUCT_TYPE } from "features/WarrantyRequest/constant";
import { useFlags } from "flagsmith/react";
import { t } from "i18next";
import useModelEditSerial from "./useModelEditSerial";

const ModalEditSerial = ({ status, record, title, loadingDataSerial, refetch }) => {
  const {
    isModalOpen,
    form,
    showModal,
    handleCancel,
    initialValues,
    handleOk,
    loading,
    confirmSolution,
    loadingConfirmSolution,
    staffs,
    onLoadMoreData,
    hasMoreData,
    onSearch,
    handleChange,
    handleGetProduct,
  } = useModelEditSerial({
    record,
    loadingDataSerial,
    refetch,
  });

  const flags = useFlags(["is_disable_edit_serial"]);
  const isDisableEditSerial = flags.is_disable_edit_serial.enabled;

  const handleChangeValues = (values) => {
    if (values?.technicalStaffID) {
      form.setFieldsValue({
        ...values,
        technicalStaffPhone: staffs.find(({ value }) => values?.technicalStaffID === value).telephone,
      });
    }
  };

  return (
    <CustomModal
      {...{
        title: <Typography.Title level={4}> {t("warrantyRequest.detail.updateSerial")}</Typography.Title>,
        customComponent: <PrimaryButtonOutLine title={t("common.EDIT")} />,
        width: "50%",
        footer: false,
        onOpen: () => showModal(status),
        onCancel: handleCancel,
        visible: !isDisableEditSerial && isModalOpen,

        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={handleOk} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
      }}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" onValuesChange={handleChangeValues}>
        {record?.productType === WARRANTY_PRODUCT_TYPE.OWNER ? (
          <FormUpdateOwner
            record={record}
            status={status}
            confirmSolution={confirmSolution}
            loadingConfirmSolution={loadingConfirmSolution}
            onLoadMoreData={onLoadMoreData}
            hasMoreData={hasMoreData}
            onSearch={onSearch}
            handleChange={handleChange}
            staffs={staffs}
            handleGetProduct={handleGetProduct}
          />
        ) : (
          <FormUpdateNotOwner record={record} status={status} />
        )}
      </Form>
    </CustomModal>
  );
};

export default ModalEditSerial;
