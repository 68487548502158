import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import React from "react";
import "./index.scss";
import useList from "./useList";
import { t } from "i18next";
import { Col, Row, Typography } from "antd";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import { FiltersTag } from "../components/FiltersTag";

const DetailAPriceLog = ({ useInModal }) => {
  const {
    columns,
    data,
    paginationData,
    loading,
    handleChangeTable,
    params,
    staffs,
    dataProductDetail,
    handleRemoveFilterTag,
  } = useList();
  return (
    <div className="list-watch-price-log-detail">
      <PageHeader
        pageTitle={t("common.historyUpdatePriceProduct")}
        routes={[
          { path: "/product", name: t("common.productList") },
          { path: "/", name: t("common.historyUpdatePriceProduct") },
        ]}
      />
      <div className="header-box">
        <Row gutter={[16, 8]}>
          <Col span={2}>
            <Typography.Text strong>{t("product.detail.code")}</Typography.Text>
          </Col>
          <Col span={22}>
            <ProductLink product={dataProductDetail} />
          </Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: "10px" }}>
          <Col span={2}>
            <Typography.Text strong>{t("product.priceBid.nameProduct")}</Typography.Text>
          </Col>
          <Col span={22}>
            <Typography.Text>{dataProductDetail?.name}</Typography.Text>
          </Col>
        </Row>
      </div>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <FiltersTag staffOptions={staffs} params={params} handleRemoveFilterTag={handleRemoveFilterTag} />
      </div>
      <div>
        <CustomTable
          columns={columns}
          dataSource={data}
          rowKey={(record) => record?.id}
          scroll={{ x: 1900 }}
          loading={loading}
          onChange={handleChangeTable}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default DetailAPriceLog;
