import React from "react";
import List from "features/SellerGroup/CustomerList";
import { useSellerPermissions } from "hooks/seller";
import Spinner from "components/Spinner";
import { useGetUserPermissions } from "hooks/user/user";
import Page403 from "pages/PageError/403";
import { useSupplierPermissions } from "hooks/supplier";

function SellerGroupList() {
  const { canView } = useSellerPermissions();
  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canViewListSupplier } = useSupplierPermissions();
  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canView || canViewListSupplier ? (
    <List />
  ) : (
    <Page403 />
  );
}

export default SellerGroupList;
