import { Col, Row, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomInfoAddress from "components/CustomInfoAddress";
import { styleHeaderCardChildren, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";

function SupplierInfo({ dataSupplier }) {
  return (
    <CustomCard
      headStyle={styleHeaderCardChildren}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.supplierInfo")}
        </Typography.Text>
      }
      content={
        <Space direction="vertical" style={{ width: "100%", fontSize: "13px" }}>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t("purchase.supplier")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Link to={`/supplier/detail/${dataSupplier?.value || dataSupplier?.id}`} target="_blank">
                <Typography.Link strong>{dataSupplier?.code || "---"}</Typography.Link>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={7}></Col>
            <Col span={17}>
              <Typography.Text>{dataSupplier?.label || dataSupplier?.fullName || "---"}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t("purchase.sdt")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{dataSupplier?.telephone || "---"}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t("purchase.address")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>
                <CustomInfoAddress value={dataSupplier} />
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t("purchase.bankAccountInfo")}</Typography.Text>
            </Col>
            <Col span={17}>
              {dataSupplier?.bankAccounts?.map((item) => (
                <div>
                  <Typography.Text>
                    {`${item?.bank?.name} - ${item?.bankAccountNumber} - ${item?.name} - chi nhánh ${item?.bankBranch}` ||
                      "---"}
                  </Typography.Text>
                </div>
              ))}
            </Col>
          </Row>
        </Space>
      }
    />
  );
}

export default SupplierInfo;
