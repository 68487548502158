import { useForm } from "antd/lib/form/Form";
import { useCreateProductBrand } from "hooks/product/product";
import { t } from "i18next";
import { sumBy } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "utils/helperFuncs";

function useList() {
  const [form] = useForm();
  const history = useHistory();
  const formData = form.getFieldsValue();
  const sumSerialScanned = sumBy(formData.items, "quantitySerialScanned");

  const [visibleCreateModal, setVisibleCreateModel] = useState(false);

  const showOnModalConfirmCreate = () => {
    setVisibleCreateModel(true);
  };

  const showOffModalConfirmCreate = () => {
    setVisibleCreateModel(false);
  };

  const { handleCreateProductBrand, loading } = useCreateProductBrand();
  const initialValues = {
    name: null,
    description: null,
    fileURLs: [],
  };

  const [loadingCreate, setloadingCreate] = useState(loading);
  const [loadingCreateSave, setloadingCreateSave] = useState(loading);

  const handleSubmitCreateSave = async () => {
    const values = await form.getFieldsValue();
    setloadingCreateSave(true);
    try {
      const params = {
        name: values.name,
        logo: values?.logo,
        catalogs: values?.catalogs,
        description: values.description,
      };
      if (values?.name) {
        await form.validateFields();
        await handleCreateProductBrand({ request: params })
          .then(() => {
            notify.success({ message: t("serialIE.notify.successCreate") });
            form.setFieldsValue({ ...initialValues });
            form.resetFields();
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
          });
      }
    } catch (error) {
      console.info(error);
    } finally {
      setloadingCreateSave(false);
      showOffModalConfirmCreate();
    }
  };
  const handleSubmitCreate = async () => {
    setloadingCreate(true);
    const values = await form.getFieldsValue();
    try {
      const params = {
        name: values.name,
        logo: values?.logo,
        catalogs: values?.catalogs,
        description: values.description,
      };
      if (values?.name) {
        await form.validateFields();
        await handleCreateProductBrand({ request: params })
          .then(() => {
            notify.success({ message: t("serialIE.notify.successCreate") });
            history.push("/product-brand");
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
          });
      }
    } catch (error) {
      console.info(error);
    } finally {
      setloadingCreate(false);
      showOffModalConfirmCreate();
    }
  };

  const onCancelCreate = () => {
    history.push("/product-brand");
  };

  return {
    form,
    initialValues,
    visibleCreateModal,
    showOnModalConfirmCreate,
    showOffModalConfirmCreate,
    sumSerialScanned,
    handleSubmitCreate,
    onCancelCreate,
    handleSubmitCreateSave,
    loadingCreateSave,
    loadingCreate,
  };
}

export default useList;
