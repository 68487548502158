import { gql } from "@apollo/client";

export const GET_ALL_LIST_PRICE_BID = gql`
  query GetAllListPriceBid(
    $filters: PriceBidSessionFilters
    $sort: [PriceBidSessionSort!] = [{ by: ID, sortType: DESC }]
    $pagination: PaginationRequest! = { offset: 0, limit: 100 }
  ) {
    priceBidSession {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        priceBidSessions {
          id
          createdAt
          cartItemID
          productID
          status
          product {
            name
            code
            uom {
              name
            }
          }
          sellerID
          seller {
            fullName
            id
          }
          ownerType
          originalPrice
          currentSuggestedPrice
          currentSuggestedQuantity
          # currentDeviantPrice
          events {
            eventType
            createdBy {
              fullname
            }
            createdAt
            ownerType
            suggestedPrice
            suggestedQuantity
            # deviantPrice
          }
        }
      }
    }
  }
`;

export const GET_SUMMARY_TABS_PRICE_BID = gql`
  query CountPriceBidSessionByStatus {
    priceBidSession {
      getTotalStatus {
        all
        waitingForStaffConfirm
        waitingForSellerConfirm
        approved
        rejected
        completed
        cancelled
      }
    }
  }
`;
