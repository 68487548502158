import React from "react";
import { Popconfirm, Tooltip } from "antd";
import { SvgIcon } from "assets/icons";
import { t } from "i18next";

function PopconfirmDelete({ title, onConfirm, ...props }) {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText={t("common.confirm")}
      cancelText={t("common.cancel")}
      {...props}
    >
      <Tooltip title={t("common.delete")}>
        <SvgIcon.TrashFullCartIcon style={{ cursor: "pointer" }} />
      </Tooltip>
    </Popconfirm>
  );
}

export default PopconfirmDelete;
