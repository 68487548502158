import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Typography } from "antd";
import { Icon, SvgIcon } from "assets/icons";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { PRODUCT_INPUT_TYPE } from "config/constants";
import {
  FilterLevelPrice,
  FilterManufacture,
} from "features/Campaign/components/Detail/ProductPromo/components/FilterProduct";
import RadioGroup from "features/Warranty/WarrantyList/component/RadioGroup";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";
import TagsList from "../TagsList";
import useModalSelectProduct from "./useModalSelectProduct";

import { PlusOutlined } from "@ant-design/icons";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import CategoryTreeFilter from "../CategoryTreeFilter";
import "./index.scss";

const FORM_ITEM_STYLE = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const optionVAT = [
  { label: t("product.vatCheckbox.yes"), value: true },
  { label: t("product.vatCheckbox.no"), value: false },
];

function ModalSelectProduct({
  form,
  isUpdate,
  dataProductsServer,
  handleGetDataSource,
  dataSourceSelected,
  idDeleted,
}) {
  const {
    isModalSelectProduct,
    loadingCheckDuplicateCodes,
    filterItemsTypeOptions,
    rowSelection,
    loadingProduct,
    isFilterSelected,
    selectedRows,
    productData,
    paginationData,
    dataDuplicateCode,
    prodAtt,
    warehouses,
    openModalSelectProduct,
    closeModalSelectProduct,
    handleSelectProduct,
    filterProductByCategoryId,
    handleSearch,
    handleChange,
    onTableChange,
    renderProductAttributes,
    renderChildrenInStock,
    handleFilter,
    params,
    dataManufactures,
    loadingManufactures,
    listWarehouse,
    loadingWarehouses,
    setParams,
    filterProductExtra,
    filterProductPrices,
  } = useModalSelectProduct({
    form,
    isUpdate,
    dataProductsServer,
    handleGetDataSource,
    dataSourceSelected,
    idDeleted,
  });

  const columns = [
    //Mã sản phẩm
    {
      title: t("product.tableTitle.code"),
      dataIndex: "code",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link target={"_blank"} to={`/product/detail/${record?.id}`} className="linked-text">
              {record?.code}
            </Link>
            <CustomTextHiddenLine text={record?.name} line={1} />
          </div>
        );
      },
    },
    //Thương hiệu
    {
      title: t("product.tableTitle.brand"),
      dataIndex: ["manufacturer", "name"],
      align: "center",
      render: (_, record) => <div>{record?.manufacturer?.name ? record.manufacturer.name : "- -"}</div>,
      filterDropdown: <FilterManufacture dataManufactures={dataManufactures} onFilter={filterProductExtra} />,
    },
    //Bảo hành
    {
      title: t("product.tableTitle.warranty"),
      dataIndex: "warranty",
      align: "center",
      render: (_, record) => <div>{record?.warranty ? `${record.warranty} ${t("common.month")}` : "- -"}</div>,
    },
    ...renderProductAttributes(prodAtt),
    //Giá cấp 1
    {
      title: t("product.tableTitle.priceLevel1"),
      dataIndex: ["productPrices", "level1Price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.level1Price)}</div>,
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceLevel1");
          }}
          defaultValue={params.filters.priceLevel1}
        />
      ),
    },
    //Giá cấp 2
    {
      title: t("product.tableTitle.priceLevel2"),
      dataIndex: ["productPrices", "level2Price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.level2Price)}</div>,
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceLevel2");
          }}
          defaultValue={params.filters.priceLevel2}
        />
      ),
    },
    //Giá cấp 3
    {
      title: t("product.tableTitle.priceLevel3"),
      dataIndex: ["productPrices", "level3Price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.level3Price)}</div>,
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceLevel3");
          }}
          defaultValue={params.filters.priceLevel3}
        />
      ),
    },
    //Giá cấp 4
    {
      title: t("product.tableTitle.priceLevel4"),
      dataIndex: ["productPrices", "level4Price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.level4Price)}</div>,
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceLevel4");
          }}
          defaultValue={params.filters.priceLevel4}
        />
      ),
    },
    //Tồn thực
    {
      title: t("product.tableTitle.inStock"),
      children: renderChildrenInStock(warehouses),
      align: "center",
    },
    //Tồn kho
    {
      title: t("product.tableTitle.inStock"),
      align: "right",
      render: (_, record) => <div>{record?.stocks?.at(0).quantity || "- -"}</div>,
    },
    //Xuất vat
    {
      title: t("product.EXPORT_VAT"),
      render: (_, record) => (record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.CloseIcon />),
      align: "center",
      filterDropdown: (
        <FilterMenu type="radio" options={optionVAT} onFilter={(value) => handleFilter({ vat: value })} />
      ),
    },
  ];

  const dataSource = isFilterSelected ? selectedRows : productData;

  return (
    <div className="custom-modal-select-product">
      <CustomModal
        customComponent={
          <Typography.Link underline>
            <PlusOutlined /> {t("campaign.combo.addProduct")}
          </Typography.Link>
        }
        {...{
          message: <Typography.Title level={3}>{t("campaign.combo.addProductToPromotion")}</Typography.Title>,
          footer: false,
          width: "70%",
          visible: isModalSelectProduct,
          onOpen: openModalSelectProduct,
          onCancel: closeModalSelectProduct,
          onOke: handleSelectProduct,
          buttonLoading: loadingCheckDuplicateCodes,
          okeButtonLabel: t("campaign.combo.addProduct"),
          children: (
            <>
              <Row
                gutter={[16, 4]}
                style={{
                  alignItems: "center",
                  borderRadius: "8px",
                  margin: 0,
                }}
              >
                <Col span={24}>
                  <Row gutter={[16]} style={{ alignItems: "flex-end" }}>
                    <Col span={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text style={{ marginBottom: "4px" }} strong>
                          {t("product.productForm.category")}
                        </Typography.Text>
                        <div>
                          <CategoryTreeFilter
                            onFilter={filterProductByCategoryId}
                            defaultValue={params.filters.categoryIDs}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={10}>
                      <FormInput
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: <Typography.Text strong>{t("campaign.combo.searchAdvance")}</Typography.Text>,
                          style: { marginBottom: 0 },
                        }}
                        inputOptions={{
                          className: "custom-input",
                          prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
                          placeholder: t("product.searchPlaceholder"),
                          allowClear: true,
                          onChange: handleSearch,
                        }}
                      />
                    </Col>
                    <Col span={8} style={{ paddingTop: "30px" }}>
                      <RadioGroup
                        filterItemsTypeOptions={filterItemsTypeOptions}
                        handleChange={handleChange}
                        isUpdate={isUpdate}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <TagsList
                    listFilter={params.filters}
                    dataManufactures={dataManufactures}
                    loadingManufactures={loadingManufactures}
                    dataWarehouses={listWarehouse}
                    loadingWarehouses={loadingWarehouses}
                    dataAttributes={prodAtt}
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <CustomTable
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    columns={columns}
                    loading={loadingCheckDuplicateCodes || loadingProduct}
                    dataSource={dataSource ?? dataSource}
                    rowKey={(record) => record?.id}
                    onChange={onTableChange}
                    pagination={{
                      total: isFilterSelected ? selectedRows?.length : paginationData?.total,
                      pageSize: paginationData?.limit,
                      current: paginationData?.offset / paginationData?.limit + 1 || 1,
                      showSizeChanger: false,
                    }}
                    scroll={{ x: "max-content" }}
                  />
                </Col>
                <Col span={24}>
                  {dataDuplicateCode?.length ? (
                    <Typography>
                      <Typography.Paragraph type="danger">
                        {t("campaign.combo.errorDuplicateCodes", {
                          productLength: dataDuplicateCode?.length,
                        })}
                        :{" "}
                        <Typography.Text type="danger" strong>
                          {dataDuplicateCode?.map((item) => item).join(`, `)}
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph type="danger">
                        {t("campaign.combo.errorDuplicateCodesWarning")}
                      </Typography.Paragraph>
                    </Typography>
                  ) : null}
                </Col>
              </Row>
            </>
          ),
        }}
      />
    </div>
  );
}

export default ModalSelectProduct;
