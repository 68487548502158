import { Spin } from "antd";
import PageHeader from "components/PageHeader";
import { CAMPAIGN_TYPE } from "features/Campaign/constants";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import CampaignForm from "../Form";
import useEditForm from "./useEditForm";

const EditForm = () => {
  const {
    form,
    id,
    handleUpdateClick,
    buttonGroupAction,
    data,
    isApplyWithPromotion,
    handleValuesChange,
    handleCheckIsApplyWithPromo,
    promotionCampaignTiers,
    visibleConfirmModal,
    handleOpenConfirmModal,
    handleCancelConfirmModal,
    dataConfirmModal,
    loadingDataUpdate,
    initValues,
    loadingAll,
  } = useEditForm();

  const location = useLocation();

  const hashURL = [CAMPAIGN_TYPE.COMBO_PROMO, CAMPAIGN_TYPE.TOP_SALES, CAMPAIGN_TYPE.PRODUCT_PROMO];

  const pageTitle = () => {
    switch (location.hash.replace(/#/g, "")) {
      case hashURL[0]:
        return "Cập nhật khuyến mãi theo combo";
      case hashURL[1]:
        return "Cập nhật khuyến mãi đua top";
      case hashURL[2]:
        return "Cập nhật khuyến mãi trên 1 sản phẩm";
      default:
        return "#";
    }
  };

  return (
    <Spin spinning={loadingAll || loadingDataUpdate}>
      <PageHeader
        pageTitle={pageTitle()}
        routes={[
          {
            path: "/campaign",
            name: t("campaign.form.list"),
          },
          {
            path: `/campaign/detail/${id}`,
            name: data?.name,
          },
        ]}
      />
      <CampaignForm
        form={form}
        buttonGroupAction={buttonGroupAction}
        isUpdate={true}
        dataCampaign={data}
        isApplyWithPromotion={isApplyWithPromotion}
        handleValuesChange={handleValuesChange}
        handleCheckIsApplyWithPromo={handleCheckIsApplyWithPromo}
        promotionCampaignTiers={promotionCampaignTiers}
        handleActionCampaign={handleUpdateClick}
        visibleConfirmModal={visibleConfirmModal}
        handleOpenConfirmModal={handleOpenConfirmModal}
        handleCancelConfirmModal={handleCancelConfirmModal}
        dataConfirmModal={dataConfirmModal}
        loadingUpdate={loadingAll}
        dataTiersServer={initValues}
        initialValues={initValues}
      />
    </Spin>
  );
};

export default EditForm;
