import { Divider, Typography } from "antd";

function CustomDivider({ title }) {
  return (
    <div className="custom-divider">
      <Divider orientation="left" plain style={{ margin: 0, borderTopColor: "rgba(5, 41, 135, 1)" }}>
        <Typography.Text style={{ color: "rgba(5, 41, 135, 1)", fontWeight: "bold" }}>{title}</Typography.Text>
      </Divider>
    </div>
  );
}

export default CustomDivider;
