import { Col, Row } from "antd";
import { AccountLoginList } from "../../AccountLoginList";

export const AccountLoginTab = ({ dataDetail }) => {
  return (
    <Row gutter={[16]} style={{ padding: "0 12px" }}>
      <Col span={24}>
        <AccountLoginList dataDetail={dataDetail} />
      </Col>
    </Row>
  );
};
