import React, { useState } from "react";
import { Collapse, Checkbox, DatePicker } from "antd";
import "./index.scss";
import "styles/custom_component.scss";
import { DATE_FORMAT } from "config/constants";
import useComboCheckbox from "hooks/useComboCheckbox";
import { parseMomentDate } from "utils/dateTime";

const FilterBox = ({ onFilter }) => {
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;

  const defaultCheckedList = {
    type: [],
    source: [],
    timeRange: {
      from: null,
      to: null,
    },
  };

  const [checkedList, setCheckedList] = useState({
    type: defaultCheckedList?.type,
    source: defaultCheckedList?.source,
    timeRange: defaultCheckedList?.timeRange,
  });

  const typeCheckbox = useComboCheckbox(
    [
      { label: "Giao dich cộng tiền", value: "TOP_UP" },
      { label: "Giao dịch trừ tiền", value: "PAYMENT" },
    ],
    defaultCheckedList?.type
  );
  const sourceCheckbox = useComboCheckbox(
    [
      { label: "TK ngân hàng", value: "BANK_ACCOUNT" },
      { label: "Ví thanh toán", value: "WALLET" },
    ],
    defaultCheckedList?.source
  );

  function onChangeTimeRange(dates, key) {
    let timeRange = {
      from: "",
      to: "",
    };
    if (dates?.length) {
      const newDate = dates.map((date) => date.toISOString(true));
      timeRange = {
        from: newDate[0],
        to: newDate[1],
      };
    } else {
      timeRange = null;
    }
    setCheckedList({
      ...checkedList,
      [`${key}`]: timeRange,
    });
  }

  function resetFilter() {
    setCheckedList(defaultCheckedList);
    typeCheckbox.onResetFilter();
    sourceCheckbox.onResetFilter();
    onFilter(defaultCheckedList);
  }

  function applyFilter() {
    const newCheckedList = {
      ...checkedList,
      type: typeCheckbox.checkedList,
      source: sourceCheckbox.checkedList,
    };
    setCheckedList(newCheckedList);
    onFilter(newCheckedList);
  }

  return (
    <menu className="menu">
      <div className="menu-header">
        <p className="title">Bộ lọc</p>
        <p className="setting">
          <span onClick={resetFilter}>Thiết lập lại</span> <a onClick={applyFilter}>Áp dụng</a>
        </p>
      </div>
      <Collapse accordion ghost expandIconPosition="right">
        <Panel header="Loại giao dịch" key="type">
          <div className="panel-content">
            <Checkbox
              indeterminate={typeCheckbox.indeterminate}
              onChange={typeCheckbox.onCheckAllChange}
              checked={typeCheckbox.checkAll}
            >
              Tất cả
            </Checkbox>
            <Checkbox.Group
              className="check-box-group"
              onChange={typeCheckbox.onChange}
              value={typeCheckbox.checkedList}
              options={typeCheckbox.options}
            />
          </div>
        </Panel>

        <Panel header="Nguồn tiền" key="source">
          <div className="panel-content">
            <Checkbox
              indeterminate={sourceCheckbox.indeterminate}
              onChange={sourceCheckbox.onCheckAllChange}
              checked={sourceCheckbox.checkAll}
            >
              Tất cả
            </Checkbox>
            <Checkbox.Group
              className="check-box-group"
              onChange={sourceCheckbox.onChange}
              value={sourceCheckbox.checkedList}
              options={sourceCheckbox.options}
            />
          </div>
        </Panel>

        <Panel header="Thời gian" key="timeRange">
          <div className="panel-content">
            <RangePicker
              placeholder={["Từ ngày", "Đến ngày"]}
              format={DATE_FORMAT}
              onChange={(e) => onChangeTimeRange(e, "timeRange")}
              value={[parseMomentDate(checkedList?.timeRange?.from), parseMomentDate(checkedList?.timeRange?.to)]}
            />
          </div>
        </Panel>
      </Collapse>
    </menu>
  );
};

export default FilterBox;
