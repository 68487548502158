import { PrimaryButton } from "components/PrimaryButton";
import { useConfirmSuggestedExportCode } from "hooks/keyManagement/mutation";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { useHistory } from "react-router-dom";

function ModalSuggestedConfirm({ selectedRows }) {
  const { handleConfirmSuggestedExportCode } = useConfirmSuggestedExportCode();

  const history = useHistory();

  //todo: check logic permission
  const handleClick = async () => {
    if (!selectedRows?.length) {
      notify.warning({ message: "Vui lòng chọn đề nghị xuất mã key license/ account!" });
    } else {
      // try {
      //   setLoading(true);
      //   await handleConfirmSuggestedExportCode({
      //     orderIDs: selectedRows?.map((item) => item?.order?.id),
      //   })
      //     .then(() => {
      //       notify.success({ message: "Xác nhận xuất kho thành công" });
      //       setLoading(false);
      //     })
      //     .catch((error) => {
      //       notify.error({
      //         message: error.message,
      //       });
      //       setLoading(false);
      //     });
      // } catch (error) {
      //   console.info(error);
      // }
    }
  };

  const queryParams = new URLSearchParams();
  queryParams.set("orderId", JSON.stringify(selectedRows && selectedRows[0]));

  return selectedRows?.length ? (
    <Link
      to={{
        pathname: "/create-export-key",
        hash: selectedRows?.[0]?.id,
      }}
      target="_blank"
    >
      <PrimaryButton title={"Xác nhận xuất kho"} />
    </Link>
  ) : (
    <PrimaryButton title={"Xác nhận xuất kho"} onClick={handleClick} />
  );
}

export default ModalSuggestedConfirm;
