import { Form } from "antd";
import { useGetPurchaseOrderDetail, useGetPurchaseOrderReferences, useUpdatePurchaseOrder } from "hooks/purchase";
import { useUploadDocument } from "hooks/upload-document";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const calcBeforeVatPrice = (afterVatPrice, vatPercent) => {
  return afterVatPrice / (1 + (vatPercent || 0) / 100);
};

const calcIntoMoney = (afterVatPrice, vatPercent, quantityToBuy) => {
  return (afterVatPrice / (1 + (vatPercent || 0) / 100)) * quantityToBuy;
};

const calcVatMoney = (afterVatPrice, vatPercent, quantityToBuy) => {
  return (afterVatPrice / (1 + (vatPercent || 0) / 100)) * quantityToBuy * (vatPercent / 100);
};

const convertDataItems = (data) => {
  return data?.map((item) => ({
    ...item,
    id: item?.id,
    productID: item?.product?.id,
    productName: item?.product?.name || item?.productName,
    productCode: item?.product?.code,
    expectedDeliveryTime: item?.expectedDeliveryTime ? moment(item?.expectedDeliveryTime) : null,
    intoMoney: calcIntoMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // thành tiền
    vatMoney: calcVatMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // Tiền thuế GTGT
  }));
};

function usePurchaseEdit() {
  const [form] = Form.useForm();

  const history = useHistory();
  const { id } = useParams();
  const { data: dataDetail } = useGetPurchaseOrderDetail({ id: id });
  const { data: dataReferences, loading: loadingReferences } = useGetPurchaseOrderReferences({ id: id });

  const [paymentTerms, setPaymentTerms] = useState();
  const [productListSelected, setProductListSelected] = useState([]);
  const [loadingUpdatePurchase, setLoadingUpdatePurchase] = useState(false);
  const [deletedIDs, setDeletedIDs] = useState([]);

  const { warehouses } = useWarehouse({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { handleUpload } = useUploadDocument();
  const { handleUpdatePurchaseOrder } = useUpdatePurchaseOrder();

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const initialValues = {
    fileURLs:
      dataDetail?.fileURLs?.map((url) => {
        return {
          url: url,
        };
      }) || [],
    importWarehouseID: dataDetail?.warehouse?.id,
    internalNote: dataDetail?.internalNote,
    items: convertDataItems(dataDetail?.items) || [],
    paymentOnDeliveryAmount: dataDetail?.paymentOnDeliveryAmount,
    paymentDebtAmount: dataDetail?.paymentDebtAmount,
    paymentDepositAmount: dataDetail?.paymentDepositAmount,
    paymentMethods: dataDetail?.paymentMethods || undefined,
    paymentTerms: dataDetail?.paymentTerms || undefined,
    requestType: dataDetail?.requestType,
    sellerID: dataDetail?.seller?.id,
    transferNote: dataDetail?.transferNote,
  };

  const handleGetProductList = (value) => {
    setProductListSelected(value);
  };

  const handleValuesChange = (changedValue, allValues) => {
    const formValues = form.getFieldsValue();
    if (changedValue.paymentTerms) {
      setPaymentTerms(changedValue.paymentTerms);
    }
    if (changedValue.items) {
      const newItems = allValues?.items?.map((item, index) => {
        return {
          ...item,
          beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item.vatPercent) || 0, // đơn giá trước thuế
          intoMoney: calcIntoMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // thành tiền
          vatMoney: calcVatMoney(item?.afterVatPrice, item.vatPercent, item.quantity) || 0, // Tiền thuế GTGT
        };
      });
      form.setFieldsValue({
        ...formValues,
        items: newItems,
      });

      setProductListSelected(newItems);
    }
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t("uploadDocument.uploadImageError"),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const onUpdatePurchaseOrder = async ({ isResubmit }) => {
    try {
      const formValues = await form.validateFields();

      const listItemUpdate = formValues?.items?.filter((item) => item.id);
      const listItemCreate = formValues?.items?.filter((item) => !item.id);

      if (!productListSelected?.length) {
        notify.error({ message: t("purchase.errorMessage.productLength") });
      } else {
        setLoadingUpdatePurchase(true);
        const imageURLs = await uploadImage(formValues?.fileURLs);
        const purchaseOrder = {
          sellerID:
            dataDetail?.supplier?.fullName === formValues?.sellerID ? dataDetail?.supplier?.id : formValues?.sellerID,
          importWarehouseID: formValues.importWarehouseID,
          requestType: formValues.requestType,
          orderType: dataDetail?.orderType,
          paymentMethods: formValues?.paymentMethods,
          paymentTerms: formValues?.paymentTerms,
          paymentNotes: formValues?.transferNote,
          paymentDepositAmount: formValues?.paymentDepositAmount || 0,
          paymentOnDeliveryAmount: formValues?.paymentOnDeliveryAmount || 0,
          paymentDebtAmount: formValues?.paymentDebtAmount || 0,
          fileURLs: imageURLs || [],
          internalNote: formValues?.internalNote,
          deletedIDs: deletedIDs,
          updateItems: listItemUpdate?.map((item, index) => ({
            id: item?.id,
            productID: item?.productID,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            creditDays: item?.creditDays,
            quantity: item?.quantity,
            afterVatPrice: item?.afterVatPrice,
            vatPercent: item?.vatPercent,
            afterDiscountPrice: item?.afterDiscountPrice,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          })),
          newItems: listItemCreate?.map((item, index) => ({
            productID: item?.productID,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            creditDays: item?.creditDays,
            quantity: item?.quantity,
            afterVatPrice: item?.afterVatPrice,
            vatPercent: item?.vatPercent,
            afterDiscountPrice: item?.afterDiscountPrice,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          })),
        };
        handleUpdatePurchaseOrder({ id: dataDetail?.id, purchaseOrder: purchaseOrder, isResubmit: isResubmit })
          .then((data) => {
            notify.success({
              message: isResubmit
                ? "Cập nhật và gửi yêu cầu nhập kho theo đơn mua hàng thành công"
                : "Cập nhật đơn mua hàng thành công",
            });
            form.resetFields();
            setLoadingUpdatePurchase(false);
            history.push(`/purchase/detail/${dataDetail?.id}`);
          })
          .catch((error) => {
            setLoadingUpdatePurchase(false);
            notify.error({
              message: t("common.error"),
              description: error.message,
            });
          });
      }
    } catch (info) {
      setLoadingUpdatePurchase(false);
      console.info("Validate Failed:", info);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    });
    setPaymentTerms(initialValues.paymentTerms);
    setProductListSelected(convertDataItems(dataDetail?.items || []));
  }, [dataDetail]);

  return {
    form,
    initialValues,
    warehouseOptions,
    handleValuesChange,
    paymentTerms,
    handleGetProductList,
    productListSelected,
    dataDetail,
    loadingUpdatePurchase,
    onUpdatePurchaseOrder,
    setDeletedIDs,
    deletedIDs,
    dataReferences,
  };
}

export default usePurchaseEdit;
