import React from "react";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { QuestionCircleFilled } from "@ant-design/icons";

function UpdateGroup({ onSwitchUpdate, onSubmitUpdate, loadingUpdate, handleOkeModalCloseDrawer }) {
  const handleOkCancel = () => {
    onSwitchUpdate && onSwitchUpdate(false);
    handleOkeModalCloseDrawer();
  };
  return (
    <>
      <CustomModal
        footer={false}
        onOke={handleOkCancel}
        customComponent={<PrimaryButtonOutLine title={t("common.cancel")} />}
        customIcon={
          <QuestionCircleFilled
            style={{
              fontSize: "50px",
              color: "#2246DC",
              padding: "20px",
              borderRadius: 50,
              background:
                "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
            }}
          />
        }
        message={t("potentialCustomer.update.cancelMessage")}
      />
      <PrimaryButton loading={loadingUpdate} onClick={onSubmitUpdate} title={t("common.save")} />
    </>
  );
}

export default UpdateGroup;
