import { useMutation } from "@apollo/client";
import { CREATE_IMPORT_REQUEST, UPDATE_IMPORT_REQUEST } from "graphql/recommendimport/mutation";
import { useUploadDocument } from "hooks/upload-document";

export const useCreateImportRequest = () => {
  const [create, { loading, error }] = useMutation(CREATE_IMPORT_REQUEST);
  const { handleUpload } = useUploadDocument();

  const handleCreateImportReques = async (params) => {
    const newImageURLs = await handleUpload({
      files: params?.importRequest?.fileURLs?.map((item) => item?.originFileObj),
    });
    return await create({
      variables: {
        ...params,
        importRequest: { ...params?.importRequest, fileURLs: newImageURLs?.map(({ url }) => url) },
      },
    });
  };

  return {
    handleCreateImportReques,
    loading,
    error,
  };
};

export const useUpdateImportRequest = () => {
  const [update, { loading, error }] = useMutation(UPDATE_IMPORT_REQUEST);
  const { handleUpload } = useUploadDocument();

  const handleUpdateImportReques = async (params) => {
    const newImages = params?.request?.fileURLs?.filter((item) => !item?.url);
    const oldImageURLs = params?.request?.fileURLs?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];
    return await update({
      variables: {
        ...params,
        id: params?.id,
        request: { ...params?.request, fileURLs: allImageURLs },
      },
    });
  };

  return {
    handleUpdateImportReques,
    loading,
    error,
  };
};
