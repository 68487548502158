import { gql } from "@apollo/client";

export const CREATE_SUPPLIER = gql`
  mutation CREATE_SUPPLIER(
    $sellerInfo: CreateSellerInfoRequest!
    $vatInfo: CreateVatInfoRequest
    $warehouseIDs: [ID!]!
    $saleCategories: [SaleCategoryInput!]
    $bankAccounts: [BankAccountInput!]
  ) {
    seller {
      create(
        sellerInfo: $sellerInfo
        vatInfo: $vatInfo
        warehouseIDs: $warehouseIDs
        saleCategories: $saleCategories
        bankAccounts: $bankAccounts
      ) {
        id
      }
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation UPDATE_SUPPLIER(
    $sellerID: ID!
    $sellerInfo: CreateSellerInfoRequest!
    $vatInfo: CreateVatInfoRequest
    $warehouseIDs: [ID!]!
    $saleCategories: [SaleCategoryInput!]
    $bankAccounts: [BankAccountInput!]
  ) {
    seller {
      update(
        sellerID: $sellerID
        sellerInfo: $sellerInfo
        vatInfo: $vatInfo
        warehouseIDs: $warehouseIDs
        saleCategories: $saleCategories
        bankAccounts: $bankAccounts
      ) {
        id
      }
    }
  }
`;

export const ACTIONS_BANK_ACCOUNT = gql`
  mutation ACTIONS_BANK_ACCOUNT($sellerID: ID!, $request: [UpsertBankAccountRequest!]!) {
    bankAccount {
      upsert(sellerID: $sellerID, request: $request) {
        id
        bankID
        bank {
          id
          name
          fullName
          icon
        }
        bankAccountNumber
        ownerBankAccountName
        bankBranch
      }
    }
  }
`;
