import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "components/CustomCard";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";

function ContactInfoCompanyCard({ dataMulti, notBordered, loadingDetail }) {
  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t(`potentialCustomer.form.contactInfo`)}
        </Typography.Text>
      }
      content={
        <Skeleton active loading={loadingDetail}>
          <Row className="content-contact-info-company">
            <Col span={24}>
              {notBordered ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.contact")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{dataMulti?.representativeName || "--"}</Typography.Text>
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.contact")}</Typography.Text>,
                    labelAlign: "left",
                    name: "representativeName",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: false,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.contact"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.contact"),
                    maxLength: 255,
                  }}
                />
              )}
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.INPUT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>,
                  labelAlign: "left",
                  name: "representativeEmail",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.email"),
                      }),
                    },
                    validator({
                      type: "email",
                    }),
                  ],
                }}
                inputOptions={{ ...notBordered, placeholder: t("potentialCustomer.placeHolder.email") }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>,
                  labelAlign: "left",
                  name: "representativeTelephone",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.telephone"),
                      }),
                    },
                    validator({
                      type: "phone",
                    }),
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.telephone"),
                }}
              />
            </Col>
          </Row>
        </Skeleton>
      }
    />
  );
}

export default ContactInfoCompanyCard;
