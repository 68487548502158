import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import { PURCHASE_TABS } from "../constant";
import AccordingPurchaseOrder from "./AccordingPurchaseOrder";
import AccordingProposedPurchase from "./AccordingProposedPurchase";

const usePurchaseOrder = () => {
  const GET_QUERY = getQuery();

  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.activeKey || PURCHASE_TABS.PROPOSED_PURCHASE);

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    buildQueryString({
      params:
        activeKey === PURCHASE_TABS.PROPOSED_PURCHASE ? { activeKey: activeKey } : { activeKey: activeKey, key: "ALL" },
    });
  };

  const tabList = [
    {
      key: PURCHASE_TABS.PROPOSED_PURCHASE,
      tab: t("purchase.proposedPurchase"),
    },
    {
      key: PURCHASE_TABS.PURCHASE_ORDER,
      tab: t("purchase.purchaseOrder"),
    },
  ];

  const contentList = {
    PROPOSED_PURCHASE: <AccordingProposedPurchase />,
    PURCHASE_ORDER: <AccordingPurchaseOrder />,
  };

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: GET_QUERY.activeKey || PURCHASE_TABS.PROPOSED_PURCHASE,
        key: GET_QUERY.key,
      },
    });
  }, [GET_QUERY.activeKey]);

  return {
    activeKeyMain,
    handleChangeTab,
    tabList,
    contentList,
  };
};

export default usePurchaseOrder;
