import { Form } from "antd";
import { USER_PERMISSIONS } from "config/constants";
import { useUpdatePotentialCustomer } from "hooks/potentialCustomer/mutation";
import {
  useGetCareStaffFilter,
  useGetCustomerDuplicate,
  useGetInChargeStaffFilter,
} from "hooks/potentialCustomer/query";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { convertPhoneNumber, getQuery, notify } from "utils/helperFuncs";
import { SELLER_TYPE } from "../constants";

const undefinedValue = "undefinedundefined";

function useFormUpdate({ dataDetail, setIsUpdate, isUpdate }) {
  const GET_QUERY = getQuery();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState();

  const [typePersonal, setTypeCustomer] = useState();
  const [inChargeStaff, setInChargeStaff] = useState();
  const [careStaff, setCareStaff] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [paramsCareStaff, setParamsCareStaff] = useState({
    filters: { query: null, status: "ACTIVE" },
    pagination: { offset: 0, limit: 10 },
  });

  const [paramsInChargeStaff, setParamsInChargeStaff] = useState({
    filters: { query: null, status: "ACTIVE", permission: USER_PERMISSIONS.CUSTOMER_CREATE },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: optionsCareStaffs,
    onLoadMoreData: onLoadMoreCareStaff,
    hasMoreData: hasMoreCareStaff,
  } = useGetCareStaffFilter(paramsCareStaff);

  const {
    data: optionsInChargeStaff,
    onLoadMoreData: onLoadMoreInChargeStaff,
    hasMoreData: hasMoreInChargeStaff,
  } = useGetInChargeStaffFilter(paramsInChargeStaff);

  const handleSearchCareStaffs = (value) => {
    setParamsCareStaff({
      ...paramsCareStaff,
      filters: { ...paramsCareStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchInChargeStaffs = (value) => {
    setParamsInChargeStaff({
      ...paramsInChargeStaff,
      filters: { ...paramsInChargeStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleChangeInChargeStaff = (value, option) => {
    setInChargeStaff(option);
  };

  const handleChangeCareStaff = (value, option) => {
    setCareStaff(option);
  };

  const handleGetType = (value) => {
    setTypeCustomer(value);
  };

  const [filters, setFilters] = useState({
    emails: GET_QUERY.emails,
    telephones: GET_QUERY.telephones,
    idNumbers: GET_QUERY.idNumbers,
    vatNumbers: GET_QUERY.vatNumbers,
  });

  const debouncedValueEmail = useDebounce(filters.emails);
  const debouncedValueTelephone = useDebounce(filters.telephones);
  const debouncedValueIdNumber = useDebounce(filters.idNumbers);
  const debouncedValueVatNumber = useDebounce(filters.vatNumbers);

  const {
    data: valueIdNumber,
    loading: loadingIdNumber,
    handleGetDataDuplicate: handleGetIdNumberDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueTaxCode,
    loading: loadingTaxCode,
    handleGetDataDuplicate: handleGetVatNumberDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueEmail,
    loading: loadingEmail,
    handleGetDataDuplicate: handleGetEmailDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueTelephone,
    loading: loadingTelephone,
    handleGetDataDuplicate: handleGetTelephoneDuplicate,
  } = useGetCustomerDuplicate();

  const checkDuplicateCustomer = (formValue, apiData) => {
    if (formValue && apiData) {
      return true;
    }
    return false;
  };

  let isDuplicateIdNumber = checkDuplicateCustomer(
    formValues?.idNumber,
    valueIdNumber?.seller?.idNumber || valueIdNumber?.potentialCustomer?.idNumber
  );
  let isDuplicateTaxCode = checkDuplicateCustomer(
    formValues?.vatNumber,
    valueTaxCode?.seller?.vatInfo?.taxIDNumber || valueTaxCode?.potentialCustomer?.vatNumber
  );
  let isDuplicateEmail = checkDuplicateCustomer(
    formValues?.email,
    valueEmail?.seller?.email || valueEmail?.potentialCustomer?.email
  );

  let isDuplicateTelephone = checkDuplicateCustomer(
    convertPhoneNumber(formValues?.telephone),
    valueTelephone?.seller?.telephone || valueTelephone?.potentialCustomer?.telephone
  );

  const handlerGetEmailDup = async (value) => {
    if (value)
      await handleGetEmailDuplicate({
        filters: {
          emails: value || undefinedValue,
          IDFilter: {
            filterType: "EXCLUDE",
            IDs: dataDetail?.id,
          },
        },
      });
  };
  const handlerGetIdNumberDup = async (value) => {
    if (value)
      await handleGetIdNumberDuplicate({
        filters: {
          idNumbers: value || undefinedValue,
          IDFilter: {
            filterType: "EXCLUDE",
            IDs: dataDetail?.id,
          },
        },
      });
  };
  const handlerGetVatNumberDup = async (value) => {
    if (value)
      await handleGetVatNumberDuplicate({
        filters: {
          vatNumbers: value || undefinedValue,
          IDFilter: {
            filterType: "EXCLUDE",
            IDs: dataDetail?.id,
          },
        },
      });
  };
  const handlerGetTelephoneDup = async (value) => {
    if (value)
      await handleGetTelephoneDuplicate({
        filters: {
          telephones: value || undefinedValue,
          IDFilter: {
            filterType: "EXCLUDE",
            IDs: dataDetail?.id,
          },
        },
      });
  };

  const handleSearchIdNumber = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, idNumbers: e.target.value.trim() }));
  };
  const handleSearchTaxCode = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, vatNumbers: e.target.value.trim() }));
  };
  const handleSearchEmail = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, emails: e.target.value.trim() }));
  };
  const handleSearchTelephone = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, telephones: e.target.value.trim() }));
  };

  useEffect(() => {
    handlerGetEmailDup(debouncedValueEmail);
    handlerGetIdNumberDup(debouncedValueIdNumber);
    handlerGetVatNumberDup(debouncedValueVatNumber);
    handlerGetTelephoneDup(convertPhoneNumber(debouncedValueTelephone));
  }, [debouncedValueEmail, debouncedValueIdNumber, debouncedValueVatNumber, debouncedValueTelephone]);

  const [visibleModalCloseDrawer, setVisibleModalCloseDrawer] = useState(false);

  const resetDataDuplicate = () => {
    handlerGetEmailDup(undefinedValue);
    handlerGetIdNumberDup(undefinedValue);
    handlerGetVatNumberDup(undefinedValue);
    handlerGetTelephoneDup(undefinedValue);
    setFormValues(null);
  };

  const handleResetAll = () => {
    const newFilter = {
      emails: undefined,
      telephones: undefined,
      idNumbers: undefined,
      vatNumbers: undefined,
    };
    setTypeCustomer(SELLER_TYPE.PERSONAL);
    setFilters(newFilter);
    setInChargeStaff(undefined);
    form.resetFields();
  };

  const handleOkeModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(false);
    setIsUpdate(false);
    handleResetAll();
    resetDataDuplicate();
  };

  const handleCancelModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(false);
    resetDataDuplicate();
    form.resetFields();
  };

  const handleOpenModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(true);
  };

  const handleValuesChange = async (changeValue, values) => {
    if (changeValue.type) {
      setTypeCustomer(changeValue.type);
    }

    setFormValues(values);
  };

  const { handleUpdatePotentialCustomer } = useUpdatePotentialCustomer();

  const handleSubmitDrawer = async () => {
    try {
      const values = await form.validateFields();
      if (isDuplicateIdNumber || isDuplicateTaxCode || isDuplicateEmail || isDuplicateTelephone) {
        console.info("Validated");
      } else {
        setLoadingUpdate(true);
        const newRequest = {
          ...values,
          inChargeStaffID: inChargeStaff?.value || dataDetail?.inChargeStaffID,
          careStaffID: careStaff?.value || dataDetail?.careStaffID,
          email: values?.email?.length ? values.email : undefined,
          idNumber: values?.idNumber?.length ? values.idNumber : undefined,
          vatNumber: values?.vatNumber?.length ? values.vatNumber : undefined,
          telephone: convertPhoneNumber(values.telephone) || undefined,
          legalRepresentativeTelephone: convertPhoneNumber(values.legalRepresentativeTelephone) || undefined,
          representativeTelephone: convertPhoneNumber(values.representativeTelephone) || undefined,
        };

        await handleUpdatePotentialCustomer({
          id: dataDetail?.id,
          request: newRequest,
        })
          .then(() => {
            setLoadingUpdate(false);
            handleResetAll();
            setInChargeStaff(undefined);
            setCareStaff(undefined);
            setIsUpdate(false);
            notify.success({
              message: t("potentialCustomer.update.updateCustomerSuccess"),
            });
          })
          .catch((error) => {
            setLoadingUpdate(false);
            notify.error({
              message: t("common.updateError"),
              description: error.message,
            });
          });
      }
    } catch (error) {
      console.info(error);
    }
  };

  const funcInitForm = () => {
    form.setFieldsValue({
      ...dataDetail,
      interestedCategoryIDs: dataDetail?.interestedCategories?.map(({ id }) => id),
      inChargeStaffID: dataDetail?.inChargeStaff?.fullname,
      careStaffID: dataDetail?.careStaff?.fullname,
    });
  };

  useEffect(() => {
    funcInitForm();
    setTypeCustomer(dataDetail?.type);
  }, [dataDetail]);

  return {
    formUpdate: form,
    handleValuesChange,
    typePersonal,
    formValues,
    loadingIdNumber,
    loadingTaxCode,
    isDuplicateIdNumber,
    isDuplicateTaxCode,
    handleSearchIdNumber,
    handleSearchTaxCode,
    loadingEmail,
    loadingTelephone,
    isDuplicateEmail,
    isDuplicateTelephone,
    handleSearchEmail,
    handleSearchTelephone,
    valueIdNumber,
    valueTaxCode,
    valueEmail,
    valueTelephone,
    handleSubmitDrawer,
    loadingUpdate,
    funcInitForm,
    handleGetType,
    visibleModalCloseDrawer,
    handleOkeModalCloseDrawer,
    handleOpenModalCloseDrawer,
    handleCancelModalCloseDrawer,
    handleResetAll,
    handleChangeCareStaff,
    handleChangeInChargeStaff,
    optionsCareStaffs,
    onLoadMoreCareStaff,
    hasMoreCareStaff,
    optionsInChargeStaff,
    onLoadMoreInChargeStaff,
    hasMoreInChargeStaff,
    handleSearchCareStaffs,
    handleSearchInChargeStaffs,
  };
}

export default useFormUpdate;
