import { gql } from "@apollo/client";

export const LOCK_KEY = gql`
  mutation LockKey($id: ID!, $lockReason: SerialLockReasonInput) {
    serial {
      lock(id: $id, lockReason: $lockReason)
    }
  }
`;

export const UNLOCK_KEY = gql`
  mutation UnlockKey($id: ID!) {
    serial {
      unlock(id: $id)
    }
  }
`;

export const CANCEL_SUGGESTED_EXPORT_CODE = gql`
  mutation CancelSuggestedExportCode($orderIDs: [ID!], $reason: String) {
    order {
      cancel(orderIDs: $orderIDs, reason: $reason)
    }
  }
`;
export const CONFIRM_SUGGESTED_EXPORT_CODE = gql`
  mutation ConfirmSuggestedExportCode($orderIDs: [ID!]!) {
    order {
      export(orderIDs: $orderIDs)
    }
  }
`;

export const UPLOAD_FILE_DIGITAL_PRODUCT = gql`
  mutation UploadFileDigitalProduct($file: Upload!, $productType: ProductType!) {
    product {
      createByExcel(file: $file, productType: $productType) {
        status
        errorType
        errorMessage
        file {
          path
          url
        }
      }
    }
  }
`;

export const SEND_EMAIL_KEY_LICENSE_ACCOUNT = gql`
  mutation SendEmailKeyLicenseAccount($serialExportNoteID: ID!, $email: String!) {
    serialExportNotes {
      sendEmail(serialExportNoteID: $serialExportNoteID, email: $email)
    }
  }
`;

export const GET_EXPORT_FILE_SERIAL = gql`
  mutation GetExportFileSerial(
    $serialExportNoteID: ID!
    $serialExportNoteItemIDs: [ID!]
    $type: SerialNoteType!
    $fileType: ExportFileType!
    $serialType: SerialType
  ) {
    serialExportNotes {
      exportFile(
        serialExportNoteID: $serialExportNoteID
        serialExportNoteItemIDs: $serialExportNoteItemIDs
        type: $type
        fileType: $fileType
        serialType: $serialType
      ) {
        path
        url
      }
    }
  }
`;
