import { Col, Row, Space, Spin } from "antd";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import InfoWarranty from "./components/InfoWarranty";
import Tabs from "./components/Tabs";
import useDetail from "./useDetail";

const WarrantyDetailUi = () => {
  const { warrantyDetail, handleClick, loading } = useDetail();

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("common.warrantyInfo")}
            routes={[
              { path: "/warranty", name: t("common.warranty") },
              { path: "/warranty-list", name: t("common.warrantyList") },
              {
                path: "",
                name: t("common.warrantyInfo"),
              },
            ]}
          />
        </Col>

        <Col span={24}>
          <Space style={{ width: "100%", justifyContent: "end", fontStyle: "italic" }}>
            {t("warranty.warrantyDetail.dateCreated")}:{warrantyDetail?.createdAt}
          </Space>
        </Col>

        <Col span={24}>
          <InfoWarranty warrantyDetail={warrantyDetail} handleClick={handleClick} />
        </Col>
        <Col span={24}>
          <Tabs warrantyDetail={warrantyDetail} />
        </Col>
      </Row>
    </Spin>
  );
};

export default WarrantyDetailUi;
