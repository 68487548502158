import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";

import Img from "assets/images";
import CustomModal from "components/CustomModal";
import { flatten, map, min } from "lodash";
import { validator } from "utils/helperFuncs";
import ModalSelectProduct from "../ModalSelectProduct";

function calculateStockInfo(conditionProducts) {
  return map(conditionProducts, (item) => {
    const quantitiesArray = item?.conditionPromotionProducts?.map((product) => {
      const validRegionItemsClient = product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];
      const validRegionItemsServer =
        product?.product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];

      return [...validRegionItemsClient, ...validRegionItemsServer]?.reduce(
        (acc, stock) => acc + (stock?.quantity || 0),
        0
      );
    });
    const flattenedArray = flatten(quantitiesArray);
    const minValue = min(flattenedArray);

    return {
      quantity: item.quantity,
      smallestValueInStock: minValue,
      conditionPromotionProducts: item.conditionPromotionProducts,
    };
  });
}

function ProductCol({
  useInModal,
  remove,
  indexConditionProductGroups,
  indexPromotionCampaignTiers,
  form,
  promotionCampaignTiers,
  isUpdate,
  selectedProducts,
  dataTiersServer,
  indexComboDiscountMoney,
  fields,
  valuesForm,
}) {
  const data = calculateStockInfo(
    dataTiersServer?.[indexComboDiscountMoney]?.promotionCampaignTiers?.[indexPromotionCampaignTiers]
      ?.conditionProductGroups
  );

  const extractedData = calculateStockInfo(
    valuesForm?.promotionCampaignTierGroupsDiscountMoney?.[indexComboDiscountMoney]?.promotionCampaignTiers?.[
      indexPromotionCampaignTiers
    ]?.conditionProductGroups
  );

  const dataProductsServer = data?.[indexConditionProductGroups]?.conditionPromotionProducts?.map(
    (item) => item?.product
  );

  const isValidateHelp =
    extractedData?.[indexConditionProductGroups]?.quantity >
    extractedData?.[indexConditionProductGroups]?.smallestValueInStock;

  return (
    <Row gutter={[36]} style={{ alignItems: "center", justifyContent: "space-between", padding: "12px 0" }}>
      <Col span={15}>
        <Space>
          <Typography.Text strong>
            [{t("campaign.combo.product")} {indexConditionProductGroups + 1}]
          </Typography.Text>
          <ModalSelectProduct
            indexConditionProductGroups={indexConditionProductGroups}
            indexPromotionCampaignTiers={indexPromotionCampaignTiers}
            useInModal={useInModal}
            dataProductsServer={dataProductsServer}
            form={form}
            promotionCampaignTiers={promotionCampaignTiers}
            isUpdate={isUpdate}
            selectedProducts={selectedProducts}
            indexComboDiscountMoney={indexComboDiscountMoney}
            isCheckboxType={true}
          />
        </Space>
      </Col>
      <Col span={7}>
        <Space>
          <Typography.Text>{t("campaign.combo.quantity")}</Typography.Text>
          <FormInput
            type={INPUT_TYPE.NUMBER}
            {...{
              formItemOptions: {
                name: [indexConditionProductGroups, "quantity"],
                validateStatus: isValidateHelp ? "warning" : undefined,
                help: isValidateHelp ? t("cart.lackOfStock") : undefined,
                style: { margin: 0 },
                rules: [
                  { required: true, message: t("campaign.combo.errorMessage.qty") },
                  {
                    type: "number",
                    min: 1,
                    message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                  },
                  validator({
                    type: "number",
                  }),
                ],
              },
              inputOptions: {
                placeholder: t("campaign.combo.placeholder.type"),
                className: "input-number",
                maxLength: 10,
              },
            }}
          />
        </Space>
      </Col>
      <Col span={2}>
        {fields?.length !== 1 && (
          <CustomModal
            {...{
              message: t("campaign.combo.errorMessage.removeProductCol"),
              onOke: remove,
              customComponent: <DeleteOutlined />,
              footer: false,
              icon: Img.DeleteIcon,
            }}
          />
        )}
      </Col>
    </Row>
  );
}

export default ProductCol;
