import { Space, Typography } from "antd";

const CustomTitle = ({ title, subTitle }) => {
  return subTitle ? (
    <Space direction="vertical">
      <Typography.Text strong style={{ fontSize: "13px" }}>
        {title}
      </Typography.Text>
      <span style={{ fontSize: "12px" }}>{subTitle}</span>
    </Space>
  ) : (
    <Typography.Text strong style={{ fontSize: "13px" }}>
      {title}
    </Typography.Text>
  );
};
export default CustomTitle;
