import React from "react";
import { Button, Col, Form, Radio, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE, SHIPPING_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import usePopupShippingType from "./usePopupShippingType";
import CustomTable from "components/CustomTable";
import ShippingType from "./ShippingType";
import { useGetInfoPlatform } from "hooks/info-platform";

function PopupShippingType({ title, confirmReturn, loading }) {
  const {
    form,
    filterItemsTypeOptions,
    handleOk,
    handleChange,
    shippingType,
    setShippingType,
    columnsPopup,
    dataPopup,
    rowSelection,
    selectedProduct,
    initialValues,
    refetch,
    loadingPrint,
  } = usePopupShippingType({
    confirmReturn,
  });
  const { platformName } = useGetInfoPlatform();

  const getShippingTypeOptions = () => {
    const SHIPPING_TYPE_OPTIONS = [
      {
        value: SHIPPING_TYPE.BUS,
        label: t("warrantyRequest.create.shippingType.bus"),
      },
      {
        value: SHIPPING_TYPE.DELIVER_3PARTY,
        label: t("warrantyRequest.create.shippingType.shippingProvider"),
      },
      {
        value: SHIPPING_TYPE.PICK_UP_COUNTER,
        label: t("warrantyRequest.create.shippingType.pickUp"),
      },
      {
        value: SHIPPING_TYPE.URBAN_COD,
        label: t("warrantyRequest.create.shippingType.shipper", { platformName }),
      },
    ];

    let initial = SHIPPING_TYPE.BUS;
    let options = SHIPPING_TYPE_OPTIONS;
    return {
      options,
      initial,
    };
  };

  return (
    <CustomModal
      {...{
        title: (
          <Typography.Text strong style={{ fontSize: "20px" }}>
            {t("warrantyRequest.popup.selectShipper")}
          </Typography.Text>
        ),
        width: "70%",
        footer: false,
        customComponent: (
          <Button onClick={() => refetch()} type="primary">
            {title}
          </Button>
        ),
        styleButton: "notButton",
        hideConfirmButton: true,
        destroyOnClose: true,
        addMoreButton: (
          <>
            <Button type="primary" onClick={handleOk} loading={loading}>
              {t("common.confirm")}
            </Button>
            <Button type="primary" onClick={() => handleOk({ isPrint: true })} loading={loadingPrint}>
              {t("common.acceptAprint")}
            </Button>
          </>
        ),
      }}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              title={<Typography.Text strong>{t("warrantyRequest.popup.selectSerial")}</Typography.Text>}
              children={
                <Space direction="vertical">
                  <Radio.Group
                    options={filterItemsTypeOptions}
                    defaultValue={false}
                    onChange={handleChange}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    optionType="button"
                  />
                  <CustomTable
                    columns={columnsPopup}
                    dataSource={selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows : dataPopup?.items}
                    rowSelection={{
                      ...rowSelection,
                    }}
                    rowKey={(record) => record?.id}
                    loading={loading}
                  />
                </Space>
              }
            />
          </Col>
          <Col span={24}>
            <Card
              title={<Typography.Text strong>{t("warrantyRequest.popup.selectType")}</Typography.Text>}
              children={
                <>
                  <Col span={24}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.RADIO_GROUP,
                        formItemOptions: {
                          label: <Typography.Text strong>{t("warrantyRequest.popup.type")}</Typography.Text>,
                          name: "shippingType",
                          rules: [{ required: true, message: t("common.validateRequired") }],
                        },
                        inputOptions: {
                          options: getShippingTypeOptions().options,
                          onChange: (e) => setShippingType(e.target.value),
                        },
                      }}
                    />
                  </Col>
                  <ShippingType shippingType={shippingType} />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default PopupShippingType;
