/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Tag, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu";
import {
  useGetCountStatusRequestImport,
  useGetListRecommendImport,
  useSalesReturnPermissions,
} from "hooks/recommendimport";
import { useGetSupplierOnLoadMore } from "hooks/supplier";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, formatCurrencyDollar, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";
import {
  IMPORT_REQUEST_TYPE,
  SALES_RETURN,
  SaleReturnStatus,
  convertActiveKey,
  importRequestType,
  optionImportRequestType,
  optionSalesReturnStatus,
  optionSalesReturnType,
  setColorStatusSalesReturn,
} from "../../constant";
import { ButtonActionRequest } from "./components/ButtonActionRequest";
import { ButtonCancelSalesReturn } from "./components/ButtonCancelSalesReturn";
import { ButtonCompleteSalesReturn } from "./components/ButtonCompleteSalesReturn";
import FilterMenu from "./components/FilterMenu";

export default function useList() {
  const GET_QUERY = getQuery();
  const MAX_SCREEN = "calc(80vh - 280px)";
  const MIN_SCREEN = "calc(60vh - 280px)";
  const { me } = useGetUserBranch();

  const {
    canImportSalesReturnCancel,
    canImportSalesReuturnCreate,
    canImportSalesReturnUpdate,
    canImportSalesReuturnView,
    canImportSalesReturnViewCreatesByMe,
    canImportSalesReturnManagerApprove,
    canImportSalesReturnTechnicalApprove,
    canImportSalesReturnManagerReject,
    canImportSalesReturnTechnicalReject,
    canImportSalesReturnComplete,
  } = useSalesReturnPermissions();

  const [selectedRow, setSelectedRow] = useState(null);
  const [showAllRows, setShowAllRows] = useState(true);
  const [sizeScrollPageAll, setSizeScrollPageAll] = useState({ x: 1600, y: MAX_SCREEN });
  const [selectedHightline, setSelectedHightline] = useState(null);
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: GET_QUERY?.query || "",
        statuses: GET_QUERY.statuses || [],
        timeRange: {
          from: GET_QUERY?.from || undefined,
          to: GET_QUERY?.to || undefined,
        },
        types: GET_QUERY?.types || [IMPORT_REQUEST_TYPE.REFUND, IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY],
        warehouseIDs: GET_QUERY?.warehouseIDs || [],
        supplierIDs: GET_QUERY?.supplierIDs || [],
        createdByIDs: GET_QUERY?.createdByIDs || [],
        updatedByIDs: GET_QUERY?.updatedByIDs || [],
        codes: GET_QUERY?.codes || [],
      },
      pagination: {
        limit: 10,
        offset: GET_QUERY?.offset || 0,
      },
      sorts: [],
    };
  });

  const handleRowClick = (record, index) => {
    setSelectedHightline(index);
    setSelectedRow(record);
    setShowAllRows(false);
    setSizeScrollPageAll({ x: 1600, y: MIN_SCREEN });
  };

  const handleToggleRows = () => {
    setSizeScrollPageAll(showAllRows ? { x: 1600, y: MIN_SCREEN } : { x: 1600, y: MAX_SCREEN });
    setShowAllRows(!showAllRows);
  };

  const [statusTabs, setStatusTabs] = useState(
    GET_QUERY?.statuses && GET_QUERY?.statuses?.length === 1 ? GET_QUERY?.statuses[0] : SALES_RETURN.ALL
  );

  const handleChangeTabs = (activeKey) => {
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1600, y: MAX_SCREEN });
    setStatusTabs(activeKey);
    setSelectedRow(null);
    setParams({
      filters: {
        timeRange: {
          from: undefined,
          to: undefined,
        },
        query: "",
        statuses: convertActiveKey(activeKey),
        types: [IMPORT_REQUEST_TYPE.REFUND, IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY],
        warehouseIDs: [],
        supplierIDs: [],
        createdByIDs: canImportSalesReuturnView === true ? [] : params?.filters?.createdByIDs,
        updatedByIDs: [],
        codes: [],
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sorts: [],
    });
  };

  const isStatusAll = statusTabs === SALES_RETURN?.ALL;
  const [filterTags, setFilterTags] = useState([]);

  const { data, paginationData, loading } = useGetListRecommendImport(params);

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: sellers,
    onLoadMoreData: onLoadMoreDataSeller,
    hasMoreData: hasMoreDataSeller,
  } = useGetSupplierOnLoadMore(paramsSeller);

  const sellersOptions = sellers?.map(({ id, fullName }) => ({ value: id, label: fullName }));

  const handleSearchSellers = (value) => {
    setParamsSeller({
      ...paramsSeller,
      pagination: { offset: 0, limit: 10 },
      filters: { query: value === "" ? null : value },
    });
  };

  const handleFilter = (value) => {
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1800, y: MAX_SCREEN });
    setSelectedRow(null);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      sorts: params?.sorts,
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
      },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...(key === "types"
          ? {
              ...params.filters,
              types: [IMPORT_REQUEST_TYPE.REFUND, IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY],
            }
          : { ...omit({ ...params.filters }, [key]) }),
      },
      sorts: params?.sorts,
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };

  const handleSort = (by) => {
    if (params.sorts[0]?.type === "ASC") {
      const newParams = {
        ...params,
        sorts: [{ by: by, type: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sorts: [{ by: by, type: "ASC" }],
      };
      setParams(newParams);
    }
  };

  const columns = {
    stt: {
      title: "STT",
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    actionEdit: {
      key: "actionEdit",
      render: (_, record) => {
        if (
          (record?.status === SALES_RETURN.WAITING_APPROVAL && record?.waitingApprovalCount === 1) ||
          record?.status === SALES_RETURN.REJECTED
        ) {
          return (
            <Link to={`/sales-return/edit/${record?.id}`}>
              <SvgIcon.EditIcon style={{ cursor: "pointer" }} />
            </Link>
          );
        } else {
          return (
            <button disabled={true} style={{ opacity: "0.5", border: "none" }}>
              <SvgIcon.EditIcon />
            </button>
          );
        }
      },
      width: 60,
      align: "center",
    },
    createdAt: {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("CREATED_AT")} style={{ color: "#5B6673" }}>
          {t("warehouse.recommendImport.CREATED_AT")}
          <SvgIcon.SortIcon className={params?.sorts[0]?.type} />
        </Typography.Text>
      ),
      width: 165,
      render: (_, record) => formatDateTime(record?.createdAt),
      align: "center",
    },
    code: {
      title: t("warehouse.salesReturn.CODESALESRETURN"),
      render: (_, record) => (
        <Link to={`/sales-return/detail/${record.id}`}>
          <Typography.Link strong>{record?.code || "- -"}</Typography.Link>
        </Link>
      ),
    },
    seller: {
      title: t("warehouse.salesReturn.SELLER"),
      width: "20%",
      render: (_, record) => (
        <Tooltip placement="top" title={record?.supplier?.fullName}>
          <Typography.Text className="text-hidden-two">{record?.supplier?.fullName || "- -"}</Typography.Text>
        </Tooltip>
      ),
      filterDropdown: (
        <FilterLoadMore
          params={paramsSeller}
          data={sellersOptions}
          onLoadMoreData={onLoadMoreDataSeller}
          hasMore={hasMoreDataSeller}
          onFilter={(value) => handleFilter({ supplierIDs: value })}
          onSearchTextChange={handleSearchSellers}
        />
      ),
    },
    sumMoney: {
      title: t("warehouse.recommendImport.SUMMONEY"),
      align: "right",
      render: (_, record) => {
        let sumPrice = 0;
        for (let i = 0; i < record?.items?.length; i++) {
          const { quantity, price } = record?.items[i];
          sumPrice += quantity * price;
        }
        return record?.items && record?.items[0]?.currency === "VND"
          ? formatCurrency(sumPrice)
          : formatCurrencyDollar(sumPrice);
      },
    },
    count: {
      title: t("warehouse.salesReturn.COUTNPENDING"),
      render: (_, record) => <Typography.Text>{record?.waitingApprovalCount || "- -"}</Typography.Text>,
      align: "center",
    },
    type: {
      title: t("warehouse.recommendImport.TYPERECOMMEND"),
      render: (_, record) => <Typography.Text> {importRequestType[record?.type] || "- -"}</Typography.Text>,
      align: "left",
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ types: [value] })}
          type="radio"
          options={optionSalesReturnType}
        />
      ),
    },
    status: {
      title: t("warehouse.recommendImport.STATUS"),
      render: (_, record) => {
        return <Tag color={setColorStatusSalesReturn(record?.status)}>{SaleReturnStatus[record?.status]}</Tag>;
      },
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ statuses: value })}
          type="checkbox"
          options={optionSalesReturnStatus}
        />
      ),
      align: "left",
    },
    createdBy: {
      title: t("warehouse.recommendImport.CREATEDBY"),
      render: (_, record) => <Typography.Text>{record?.createdBy?.fullname || "- -"}</Typography.Text>,
      align: "left",
      filterDropdown: (
        <FilterLoadMore
          params={paramsStaff}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    approvedBy: {
      title: t("warehouse.recommendImport.APPROVEBY"),
      render: (_, record) => <Typography.Text>{record?.updatedBy?.fullname || "- -"}</Typography.Text>,
      align: "left",
      filterDropdown: (
        <FilterLoadMore
          params={paramsStaff}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ updatedByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    action: {
      title: t("warehouse.recommendImport.ACTIONALL"),
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {(canImportSalesReturnTechnicalApprove || canImportSalesReturnTechnicalReject) &&
            record?.status === SALES_RETURN.WAITING_TECHNICAL_CONFIRM && <ButtonActionRequest record={record} />}
          {(canImportSalesReturnManagerApprove || canImportSalesReturnManagerReject) &&
            record?.status === SALES_RETURN.WAITING_APPROVAL && <ButtonActionRequest record={record} />}
          {((record?.status === SALES_RETURN.WAITING_APPROVAL && record?.waitingApprovalCount === 1) ||
            record?.status === SALES_RETURN.REJECTED) &&
            canImportSalesReturnCancel && <ButtonCancelSalesReturn record={record} />}
          {canImportSalesReturnComplete && record?.status === SALES_RETURN.WAITING_FOR_STOCK && (
            <ButtonCompleteSalesReturn record={record} />
          )}
        </div>
      ),
      align: "center",
    },
  };

  const checkIsCancel = data?.map((item) => item?.waitingApprovalCount)?.every((item) => item !== 1);

  const { stt, actionEdit, createdAt, code, seller, sumMoney, count, type, status, createdBy, approvedBy, action } =
    columns;
  const ALL_COLUMNS_TABLE = [
    stt,
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    status,
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
  ];
  const WAITING_APPROVAL_TABLE = [
    ...(canImportSalesReturnUpdate ? [actionEdit] : []),
    createdAt,
    code,
    seller,
    sumMoney,
    count,
    type,
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),

    ...((canImportSalesReturnManagerApprove ||
      canImportSalesReturnManagerReject ||
      (canImportSalesReturnCancel && !checkIsCancel)) &&
    action
      ? [action]
      : []),
  ];
  const REJECTED_TABLE = [
    ...(canImportSalesReturnUpdate ? [actionEdit] : []),
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    ...((canImportSalesReuturnView === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...(canImportSalesReturnCancel && action ? [action] : []),
  ];
  const WAITING_TECHNICAL_CONFIRM_TABLE = [
    stt,
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    ...((canImportSalesReuturnView === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...((canImportSalesReturnTechnicalApprove || canImportSalesReturnTechnicalReject) && action ? [action] : []),
  ];
  const WAITING_FOR_STOCK_TABLE = [
    stt,
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    ...((canImportSalesReuturnView === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...(canImportSalesReturnComplete && action ? [action] : []),
  ];
  const COMPLETE_TABLE = [
    stt,
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    ...((canImportSalesReuturnView === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
  ];
  const CANCELED_TABLE = [
    stt,
    createdAt,
    code,
    seller,
    sumMoney,
    type,
    ...((canImportSalesReuturnView === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...((canImportSalesReturnViewCreatesByMe === false ||
      (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === true)) &&
    createdBy
      ? [createdBy]
      : []),
  ];

  const renderColumns = (type) => {
    switch (type) {
      case SALES_RETURN.ALL:
        return ALL_COLUMNS_TABLE;
      case SALES_RETURN.WAITING_APPROVAL:
        return WAITING_APPROVAL_TABLE;
      case SALES_RETURN.REJECTED:
        return REJECTED_TABLE;
      case SALES_RETURN.WAITING_TECHNICAL_CONFIRM:
        return WAITING_TECHNICAL_CONFIRM_TABLE;
      case SALES_RETURN.WAITING_FOR_STOCK:
        return WAITING_FOR_STOCK_TABLE;
      case SALES_RETURN.COMPLETED:
        return COMPLETE_TABLE;
      case SALES_RETURN.CANCELED:
        return CANCELED_TABLE;
      default:
        return;
    }
  };

  const handleChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params?.pagination, offset: (current - 1) * pageSize },
      sorts: params?.sorts,
    };
    setParams(newParams);
    const queryString = {
      ...newParams.filters,
      ...newParams.pagination,
      ...newParams.filters.timeRange,
    };
    buildQueryString({ params: { ...queryString } });
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1800, y: MAX_SCREEN });
    setSelectedRow(null);
  };

  const paramsStatus = (status) => {
    return {
      filters: {
        statuses: status,
        query: "",
        createdByIDs:
          canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === false
            ? params?.filters?.createdByIDs
            : canImportSalesReturnViewCreatesByMe === false && canImportSalesReuturnView === false
            ? [""]
            : [],
        types: [IMPORT_REQUEST_TYPE.REFUND, IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY],
      },
    };
  };
  const { data: COUNT_ALL } = useGetCountStatusRequestImport(paramsStatus(undefined));
  const { data: COUNT_WAITING_APPROVAL } = useGetCountStatusRequestImport(paramsStatus(SALES_RETURN.WAITING_APPROVAL));
  const { data: COUNT_REJECTED } = useGetCountStatusRequestImport(paramsStatus(SALES_RETURN.REJECTED));
  const { data: COUNT_WAITING_TECHNICAL_CONFIRM } = useGetCountStatusRequestImport(
    paramsStatus(SALES_RETURN.WAITING_TECHNICAL_CONFIRM)
  );
  const { data: COUNT_WAITING_FOR_STOCK } = useGetCountStatusRequestImport(
    paramsStatus(SALES_RETURN.WAITING_FOR_STOCK)
  );
  const { data: COUNT_COMPLETED } = useGetCountStatusRequestImport(paramsStatus(SALES_RETURN.COMPLETED));
  const { data: COUNT_CANCELED } = useGetCountStatusRequestImport(paramsStatus(SALES_RETURN.CANCELED));
  const dataCount = {
    ALL: COUNT_ALL,
    WAITING_APPROVAL: COUNT_WAITING_APPROVAL,
    REJECTED: COUNT_REJECTED,
    WAITING_TECHNICAL_CONFIRM: COUNT_WAITING_TECHNICAL_CONFIRM,
    WAITING_FOR_STOCK: COUNT_WAITING_FOR_STOCK,
    COMPLETED: COUNT_COMPLETED,
    CANCELED: COUNT_CANCELED,
  };

  useEffect(() => {
    if (canImportSalesReturnViewCreatesByMe === true && canImportSalesReuturnView === false) {
      setParams({
        ...params,
        filters: {
          ...params.filters,
          createdByIDs: [me?.id],
        },
      });
    } else if (canImportSalesReturnViewCreatesByMe === false && canImportSalesReuturnView === false) {
      setParams({
        ...params,
        filters: { ...params.filters, createdByIDs: [""] },
      });
    } else {
      setParams({
        ...params,
        filters: { ...params.filters, createdByIDs: [] },
      });
    }
  }, [
    JSON.stringify(me),
    JSON.stringify(canImportSalesReturnViewCreatesByMe),
    JSON.stringify(canImportSalesReuturnView),
  ]);

  useEffect(() => {
    buildQueryString({
      params: {
        offset: 0,
        limit: 10,
        statuses: params?.filters?.statuses,
        from: params?.filters?.timeRange?.from,
        to: params?.filters?.timeRange?.to,
        supplierIDs: params?.filters?.supplierIDs,
        updatedByIDs: params?.filters?.updatedByIDs,
        codes: params?.filters?.codes,
        warehouseIDs: params?.filters?.warehouseIDs,
        types: params?.filters?.types?.length === 2 ? [] : params?.filters?.types,
        createdByIDs: params?.filters?.createdByIDs,
      },
    });
  }, [params]);

  useEffect(() => {
    if (data?.length > 0) {
      handleRowClick(data && data[0], 0);
    }
  }, [JSON.stringify(data)]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const debouncedValue = useDebounce(searchTerm, 500);

  useEffect(() => {
    if ((data || [])?.length > 0) {
      handleRowClick(data && data[0], 0);
    } else {
      handleRowClick(null, 0);
    }
  }, [JSON.stringify(data)]);
  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue?.trim(),
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sorts: [],
    });
    setSelectedRow(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  useEffect(() => {
    setParams(() => {
      return {
        filters: {
          query: "",
          statuses: GET_QUERY?.statuses || [],
          timeRange: {
            from: undefined,
            to: undefined,
          },
          types: [IMPORT_REQUEST_TYPE.REFUND, IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY],
          warehouseIDs: [],
          supplierIDs: [],
          createdByIDs: [],
          updatedByIDs: [],
          codes: [],
        },
        pagination: {
          limit: 10,
          offset: GET_QUERY?.offset || 0,
        },
        sorts: [],
      };
    });
    buildQueryString({
      params: {},
    });
  }, []);

  return {
    data,
    renderColumns,
    statusTabs,
    isStatusAll,
    columns,
    setFilterTags,
    filterTags,
    GET_QUERY,
    handleRowClick,
    selectedRow,
    showAllRows,
    handleToggleRows,
    selectedHightline,
    sizeScrollPageAll,
    optionImportRequestType,
    setSelectedHightline,
    setSizeScrollPageAll,
    setSelectedRow,
    handleChangeTabs,
    paginationData,
    loading,
    setParams,
    handleSearch,
    params,
    handleRemoveFilterTag,
    sellersOptions,
    staffs,
    handleChangeTable,
    dataCount,
    canImportSalesReuturnCreate,
    canImportSalesReuturnView,
    canImportSalesReturnViewCreatesByMe,
  };
}
