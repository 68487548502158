import { CheckCircleFilled, CloseCircleFilled, HistoryOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Skeleton, Space, Typography } from "antd";
import CustomAffix from "components/CustomAffix";
import CustomCard from "components/CustomCard";
import CustomModal from "components/CustomModal";
import PageHeader from "components/PageHeader";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useGetProduct, useLazyGetVariantByAttValue, useProductPermissions } from "hooks/product/product";
import { useGetUserPermissions } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { Link, useHistory, useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { PRODUCT_TYPE } from "../../constants";
import ButtonSeeMore from "./components/ButtonSeeMore";
import CommonInfo from "./components/CommonInfo";
import ItemOdd from "./components/ItemOdd";
import "./index.scss";

const ProductDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { data, loading } = useGetProduct({ id });
  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canViewProduct, canUpdate } = useProductPermissions();
  const { warehouses } = useWarehouse();
  const { getVariant } = useLazyGetVariantByAttValue();

  const handleChangeVariant = async (attValueIDs) => {
    await getVariant({
      variables: { productID: id, variantAttributeValueIDs: attValueIDs },
    })
      .then((res) => {
        const newVariant = res.data.product.getByVariantAttribute;
        if (newVariant) {
          history.replace(`/product/detail/${newVariant.id}`);
        }
      })
      .catch((error) => {
        notify.error({
          message: t("common.error"),
          description: error.message,
        });
      });
  };

  const handleClickEdit = () => {
    if (data?.group?.id) {
      history.push(`/product-group/edit/${data.group.id}`);
    }
  };

  const divRef = useRef(null);
  const [heightDescription, setHeightDescription] = useState(0);
  console.log(heightDescription);
  useEffect(() => {
    const divElement = divRef?.current;
    if (divElement && data?.description) {
      const contentHeight = divElement?.scrollHeight;
      setHeightDescription(contentHeight || 0);
    }
  }, [JSON.stringify(data)]);

  return (
    <Row className="product-detail-container" gutter={[16, 16]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("product.detail.productDetail")}
          routes={[
            {
              path: "/product",
              name: t("common.productList"),
            },
            {
              path: `/product/detail/${data?.id}`,
              name: t("product.detail.productDetail"),
            },
            {
              path: "",
              name: truncateAddDots(data?.name, 50),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Skeleton active loading={loading}>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <Space>
                <Typography.Text strong>{t("common.productType")}</Typography.Text>
                <Typography.Text>
                  {data?.group?.defaultProduct?.productType === PRODUCT_TYPE.MATERIAL ||
                  data?.group?.defaultProduct?.productType === PRODUCT_TYPE.SERVICE
                    ? t("product.productManual")
                    : t("product.productKey")}
                </Typography.Text>
              </Space>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Space direction="vertical" style={{ width: "100%" }}>
                <Space>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    Ngay tao:{" "}
                  </Typography.Text>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    00:00:00 00/00/0000
                  </Typography.Text>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    boi Hang Nguyen
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    Ngay cap nhat:{" "}
                  </Typography.Text>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    00:00:00 00/00/0000
                  </Typography.Text>
                  <Typography.Text italic style={{ color: "#5B6673" }}>
                    boi Hang Nguyen
                  </Typography.Text>
                </Space>
              </Space> */}
            </Col>
          </Row>
        </Skeleton>
      </Col>
      <Col span={24}>
        <CustomCard
          headStyle={{ backgroundColor: "#e7f0ff" }}
          title={
            <Typography.Text strong style={{ color: "#052987" }}>
              {t("product.productForm.title")}
            </Typography.Text>
          }
          content={<CommonInfo product={data} onChangeVariant={handleChangeVariant} />}
        />
      </Col>
      <Col span={18} className="block-product-detail">
        <CustomCard
          headStyle={{ backgroundColor: "#e7f0ff" }}
          title={
            <Typography.Text strong style={{ color: "#052987" }}>
              {t("product.productForm.productDetail")}
            </Typography.Text>
          }
          content={
            <Skeleton active loading={loading}>
              {data?.group?.description ? (
                <div
                  style={{
                    paddingBottom: "12px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid rgba(228, 228, 235, 1)",
                      borderRadius: "8px",
                      padding: "16px",
                      height: "500px",
                      overflow: "hidden",
                    }}
                  >
                    <ReactQuill value={data?.group?.description} readOnly={true} theme={"bubble"} />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      width: "fit-content",
                      transform: "translate(-50%,-50%)",
                      left: "50%",
                      bottom: "-1%",
                    }}
                  >
                    <CustomModal
                      footer={false}
                      customComponent={
                        <div className="btn-see-more">
                          <ButtonSeeMore type="default" />
                        </div>
                      }
                      hideCancelButton={true}
                      okeButtonLabel={t("common.close")}
                      width={"800px"}
                      title={
                        <Typography.Text strong style={{ fontSize: "16px" }}>
                          {t("product.productForm.productDetail")}
                        </Typography.Text>
                      }
                      children={
                        <Space direction="vertical" style={{ padding: "20px 0", width: "100%" }}>
                          <Typography.Link
                            strong
                            style={{
                              borderRadius: "8px",
                              padding: "12px",
                              background: "rgba(231, 240, 255, 1)",
                              display: "block",
                            }}
                          >
                            {data?.name}
                          </Typography.Link>
                          <div style={{ height: "500px", overflow: "scroll" }}>
                            <ReactQuill value={data?.group?.description} readOnly={true} theme={"bubble"} />
                          </div>
                        </Space>
                      }
                    />
                  </div>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ padding: 20, margin: 0 }} />
              )}
            </Skeleton>
          }
        />
      </Col>
      <Col span={6} className="stock-info">
        <CustomCard
          headStyle={{ backgroundColor: "#e7f0ff" }}
          title={
            <Typography.Text strong style={{ color: "#052987" }}>
              {t("product.productForm.productInWarehouses", {
                number: data?.stocks?.filter((item) => item?.quantity > 0)?.length || 0,
              })}
            </Typography.Text>
          }
          content={
            <Skeleton active loading={loading}>
              {data?.stocks ? (
                <div>
                  <ItemOdd
                    data={data?.stocks?.map((item) => {
                      return {
                        label: item?.warehouse?.name,
                        value: item?.quantity,
                        icon:
                          item?.quantity > 0 ? (
                            <CheckCircleFilled
                              style={{
                                color: "rgba(0, 171, 120, 1)",
                              }}
                            />
                          ) : (
                            <CloseCircleFilled style={{ color: "rgba(210, 67, 77, 1)" }} />
                          ),
                      };
                    })}
                    colIcon={true}
                    spanNumber={[16, 4, 4]}
                  />
                  {data?.stocks?.length > 7 && (
                    <CustomModal
                      footer={false}
                      hideCancelButton={true}
                      okeButtonLabel={t("common.close")}
                      customComponent={<ButtonSeeMore type={"link"} />}
                      title={
                        <Typography.Text strong style={{ fontSize: "16px" }}>
                          {t("product.productForm.stockInfo")}
                        </Typography.Text>
                      }
                      children={
                        <Space direction="vertical" style={{ padding: "20px 0", width: "100%" }}>
                          <Typography.Link
                            strong
                            style={{
                              borderRadius: "8px",
                              padding: "12px",
                              background: "rgba(231, 240, 255, 1)",
                              display: "block",
                            }}
                          >
                            {data?.name}
                          </Typography.Link>
                          <ItemOdd
                            fullCol={true}
                            data={data?.stocks?.map((item) => {
                              return {
                                label: item?.warehouse?.name,
                                value: item?.quantity,
                                icon:
                                  item?.quantity > 0 ? (
                                    <CheckCircleFilled
                                      style={{
                                        color: "rgba(0, 171, 120, 1)",
                                      }}
                                    />
                                  ) : (
                                    <CloseCircleFilled style={{ color: "rgba(210, 67, 77, 1)" }} />
                                  ),
                              };
                            })}
                            colIcon={true}
                            spanNumber={[16, 4, 4]}
                          />
                        </Space>
                      }
                    />
                  )}
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ padding: 20, margin: 0 }} />
              )}
            </Skeleton>
          }
        />
      </Col>
      <Col span={24}>
        <CustomAffix
          showButtonDefault={false}
          addButton={
            <>
              <Link to={`/product-price-log-detail/${id}`}>
                <PrimaryButtonOutLine icon={<HistoryOutlined />} title={t("common.historyUpdatePriceProduct")} />
              </Link>
              <PrimaryButton title={t("product.detail.createCart")} />
              {canUpdate && (
                <Button className="custom-button" type="primary" onClick={handleClickEdit}>
                  {t("product.detail.editLabel")}
                </Button>
              )}
            </>
          }
        />
      </Col>
    </Row>
  );
};

export default ProductDetail;
