import { Card, Col, Form, message, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { convertTotalNumber } from "features/Serial/constant";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { SvgIcon } from "assets/icons";

import { uniq } from "lodash";
import InputSearch from "../../../../../components/InputSearch";
import styles from "../../../../../index.module.scss";

import { renderLack } from "utils/helperFuncs";

import { InputTagOther } from "../../InputTagOther";
import "./index.scss";

function OtherInputTag({ index, record, tagsIndex, form, setVisible, visible, handleClearAllSerials, warehouseInfo }) {
  var tags = record?.serialItems?.map((item) => item);
  const [tagsCopy, setTagsCopy] = useState(tags); //onModal
  const [tagsCopy2, setTagsCopy2] = useState(tags); //offModal

  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tagsValueResult, setTagsValueResult] = useState([]);

  const moreThanNumber = tagsIndex?.length > 2;
  const handleSearchSerials = (values) => {
    setSearchValue(values);
    const sortedArray1 = tagsCopy2;
    const sortedArray2 = uniq(values);
    setTagsValueResult(sortedArray2);
    const searchValues = sortedArray1?.filter((value) => sortedArray2?.includes(value));
    if (searchValues?.length > 0) {
      setSearchResult(searchValues);
    } else {
      setSearchResult([]);
    }
  };

  const renderSubTotal = (record) => {
    if (record?.quantity < record?.serialItems?.length) {
      return (
        <>
          <Col span={6}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={18} style={{ color: "red" }}>
            {record?.serialItems?.length - record?.quantity}
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return (
        <>
          <Col span={6}>
            <Typography.Text strong style={{ color: "" }}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <>
          <Col span={6}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}
            </Typography.Text>
          </Col>
          <Col span={18} style={{ color: "#ef9351" }}>
            {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
          </Col>
        </>
      );
    }
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    if (tagsCopy2?.length > 0) {
      setTagsCopy([...tagsCopy2]);
    }
    setVisible(false);
  };

  const handleOke = () => {
    setTagsCopy2([...tagsCopy]);
    setVisible(false);
  };

  const copyToClipboard = () => {
    if (tags?.length > 0) {
      const textToCopy = tags.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  useEffect(() => {
    setSearchValue([]);
  }, [visible]);

  const validScanSerials = (_, value) => {
    if (value?.length === 0) {
      return Promise.reject(new Error("Vui lòng không để trống trường này"));
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={loading}>
      <Space className="custom-input-tag" style={{ width: "100%" }}>
        <Form.Item
          name={[index, "serialItems"]}
          rules={[
            {
              validator: validScanSerials,
            },
          ]}
        >
          <InputTagOther
            isHidden={true}
            tags={tags}
            moreThanNumber={moreThanNumber}
            idPopup={false}
            record={record}
            warehouseInfo={warehouseInfo}
            setLoading={setLoading}
          />
        </Form.Item>
        <CustomModal
          {...{
            footer: false,
            title: (
              <Typography.Title level={4} strong>
                {t("serialIE.popupAddSerial")}
              </Typography.Title>
            ),
            width: "60%",
            visible: visible === index,
            onCancel: handleCancel,
            onOpen: handleOpen,
            onOke: handleOke,
            cancelButtonLabel: t("common.close"),
            hideConfirmButton: true,
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Card
                style={{ background: "#F7F8FB", marginBottom: "16px" }}
                children={
                  <Row gutter={[16, 8]} className={styles["summary"]}>
                    <Col span={6}>
                      <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                    </Col>
                    <Col span={18}>
                      <Typography.Link strong href={`/product/detail/${record?.id}`}>
                        {record?.code}
                      </Typography.Link>
                    </Col>
                    <Col span={6}>
                      <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                    </Col>
                    <Col span={18}> {record?.name}</Col>
                    <Col span={6}>
                      <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                    </Col>
                    <Col span={18}> {convertTotalNumber(record?.quantity)}</Col>
                    <Col span={6}>
                      <Typography.Text strong>{t("serialIE.productInfo.serialScanned")}</Typography.Text>
                    </Col>
                    <Col span={18}> {convertTotalNumber(record?.serialItems?.length)}</Col>

                    {renderSubTotal(record)}
                  </Row>
                }
              />
            </Col>
            <Col span={24}>
              <InputSearch onSearch={handleSearchSerials} valueSearchSerial={searchValue} />
            </Col>
            <Col span={24}>
              <Space style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <ExclamationCircleFilled style={{ color: "#EF9351" }} />
                  <Typography.Text type="warning" style={{ fontStyle: "italic" }}>
                    {t("serialIEDetail.warning")}
                  </Typography.Text>
                </div>
                <div>
                  <Popconfirm
                    title="Bạn có chắc chắn muốn xóa tất cả serial/IMEI không?"
                    onConfirm={() => handleClearAllSerials(index)}
                    okText="Xác nhận"
                    cancelText="Đóng"
                  >
                    <SvgIcon.IconDelete
                      style={{ fontSize: "20px", cursor: "pointer", marginRight: "8px" }}
                      width="21px"
                      height="21px"
                    />
                  </Popconfirm>

                  <Tooltip title={t("serialIE.copy")}>
                    <SvgIcon.CopyIcon
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      width="24px"
                      height="24px"
                      onClick={() => copyToClipboard()}
                    />
                  </Tooltip>
                </div>
              </Space>
            </Col>
            <Col span={24}>
              <Form.Item
                name={[index, "serialItems"]}
                rules={[
                  {
                    required: true,
                    message: t("serialIE.error.serialItems"),
                  },
                ]}
              >
                <InputTagOther
                  tags={tags}
                  searchResult={searchResult}
                  tagsValueResult={tagsValueResult}
                  form={form}
                  idPopup={true}
                  record={record}
                  warehouseInfo={warehouseInfo}
                  setLoading={setLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomModal>
        {/* )} */}
      </Space>
    </Spin>
  );
}

export default OtherInputTag;
