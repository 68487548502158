export const PAYMENT_METHOD = {
  DEBT: "DEBT",
  WALLET: "WALLET",
};

export const COMPANY_TYPE = {
  COMPANY: "COMPANY",
  PERSONAL: "PERSONAL",
};

export const IS_USED_APP = {
  USED: "USED",
  INVITED: "INVITED",
  UNINVITED: "UNINVITED",
};

export const SELLER_APPROVAL_STATUS = {
  WAITING_APPROVAL: "WAITING_APPROVAL",
  PROCESSING: "PROCESSING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
