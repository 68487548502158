import { Progress } from "antd";

import "./index.scss";

function ProgressBarSoldQuantity({ soldQuantityOfTier }) {
  const percent = (soldQuantityOfTier?.soldQuantity / soldQuantityOfTier?.totalQuantity) * 100;
  const remainingQuantity = soldQuantityOfTier?.totalQuantity - soldQuantityOfTier?.soldQuantity;

  const progressText = () => {
    if (percent >= 100) {
      return "Đã bán hết";
    } else if (remainingQuantity <= 10) {
      return `Chỉ còn ${remainingQuantity}`;
    } else {
      return `Đã bán ${soldQuantityOfTier?.soldQuantity}/${soldQuantityOfTier?.totalQuantity}`;
    }
  };

  return (
    <Progress
      className="progress-bar-sold-quantity"
      strokeColor={{
        "0%": "#FF5A70",
        "100%": "#F22449",
      }}
      status="active"
      percent={percent}
      strokeWidth={14}
      trailColor="#ff4d4f7d"
      strokeLinecap="square"
      format={progressText}
    />
  );
}

export default ProgressBarSoldQuantity;
