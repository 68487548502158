import { Icon } from "assets/icons";
import { STATUS_ORDER } from "config/constants";
import { useCountStatusOrder } from "hooks/order";
import { SHIPPING_STATUS } from "pages/App/Order/conts";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { getQuery } from "utils/helperFuncs";

const StatusTabs = ({ onChange, statusOrder }) => {
  const GET_QUERY = getQuery();
  const categoriesContainer = useRef(null);
  const [currentActived, setCurrentActived] = useState(statusOrder);
  const { t } = useTranslation();

  const paramsStatus = (status, shippingStatus) => {
    return {
      filters: {
        statuses: status,
        query: "",
        shippingStatus: shippingStatus,
        // shippingTypes: [SHIPPING_TYPE.BUS, SHIPPING_TYPE.URBAN_COD, SHIPPING_TYPE.DELIVER_3PARTY],
      },
    };
  };
  const { data: COUNT_ALL } = useCountStatusOrder(paramsStatus([], undefined));
  const { data: COUNT_WAITING_FOR_APPROVAL_ORDER } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER], undefined)
  );
  const { data: COUNT_WAITING_FOR_APPROVAL_STOCK } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK], undefined)
  );
  const { data: COUNT_WAITING_FOR_ENOUGH_STOCK } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK], undefined)
  );
  const { data: COUNT_WAITING_FOR_PAYMENT } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_PAYMENT], undefined)
  );
  const { data: COUNT_WAITING_FOR_PACKAGING } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_PACKAGING], undefined)
  );
  const { data: COUNT_WAITING_FOR_EXPORTING } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_EXPORTING], undefined)
  );
  const { data: COUNT_WAITING_FOR_ASSEMBLY } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_ASSEMBLY], undefined)
  );
  const { data: COUNT_WAITING_FOR_SHIPPING_NOT_SET } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_SHIPPING], SHIPPING_STATUS.NOT_SET)
  );
  const { data: COUNT_WAITING_FOR_SHIPPING_WAITING } = useCountStatusOrder(
    paramsStatus([STATUS_ORDER.WAITING_FOR_SHIPPING], SHIPPING_STATUS.WAITING)
  );
  const { data: COUNT_DELIVERING } = useCountStatusOrder(paramsStatus([STATUS_ORDER.DELIVERING], undefined));
  const { data: COUNT_COMPLETED } = useCountStatusOrder(paramsStatus([STATUS_ORDER.COMPLETED], undefined));
  const { data: COUNT_CANCELED } = useCountStatusOrder(paramsStatus([STATUS_ORDER.CANCELED], undefined));

  useEffect(() => {
    setCurrentActived(statusOrder);
  }, [statusOrder]);

  const renderTotalStatus = (valueStatus) => (valueStatus !== undefined ? `(${valueStatus})` : "");

  const status = [
    {
      label: `${t("common.all")} ${renderTotalStatus(COUNT_ALL)}`,
      value: STATUS_ORDER.ALL,
    },
    {
      label: `${t("order.orderStatus.waitingForApprovalOrder")} ${renderTotalStatus(COUNT_WAITING_FOR_APPROVAL_ORDER)}`,
      value: STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER,
    },
    {
      label: `${t("order.orderStatus.waitingForApprovalStock")} ${renderTotalStatus(COUNT_WAITING_FOR_APPROVAL_STOCK)}`,
      value: STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK,
    },
    {
      label: `${t("order.orderStatus.waitingForEnoughStock")} ${renderTotalStatus(COUNT_WAITING_FOR_ENOUGH_STOCK)}`,
      value: STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK,
    },
    {
      label: `${t("order.orderStatus.waitingForPayment")} ${renderTotalStatus(COUNT_WAITING_FOR_PAYMENT)}`,
      value: STATUS_ORDER.WAITING_FOR_PAYMENT,
    },
    {
      label: `${t("order.orderStatus.waitingForPackaging")} ${renderTotalStatus(COUNT_WAITING_FOR_PACKAGING)}`,
      value: STATUS_ORDER.WAITING_FOR_PACKAGING,
    },
    {
      label: `${t("order.orderStatus.waitingForExporting")} ${renderTotalStatus(COUNT_WAITING_FOR_EXPORTING)}`,
      value: STATUS_ORDER.WAITING_FOR_EXPORTING,
    },
    {
      label: `${t("order.orderStatus.waitingForAssembly")} ${renderTotalStatus(COUNT_WAITING_FOR_ASSEMBLY)}`,
      value: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
    },
    {
      label: `${t("order.orderStatus.waitingForDispatch")} ${renderTotalStatus(COUNT_WAITING_FOR_SHIPPING_NOT_SET)}`,
      value: STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET,
    },
    {
      label: `${t("order.orderStatus.waitingForDelivery")} ${renderTotalStatus(COUNT_WAITING_FOR_SHIPPING_WAITING)}`,
      value: STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING,
    },
    {
      label: `${t("order.orderStatus.delivering")} ${renderTotalStatus(COUNT_DELIVERING)}`,
      value: STATUS_ORDER.DELIVERING,
    },
    {
      label: `${t("order.orderStatus.completed")} ${renderTotalStatus(COUNT_COMPLETED)}`,
      value: STATUS_ORDER.COMPLETED,
    },
    {
      label: `${t("order.orderStatus.cancel")} ${renderTotalStatus(COUNT_CANCELED)}`,
      value: STATUS_ORDER.CANCELED,
    },
  ];

  function scrollToLeft() {
    if (categoriesContainer) {
      categoriesContainer.current.scrollLeft -= 100;
    }
  }

  function scrollToRight() {
    if (categoriesContainer) {
      categoriesContainer.current.scrollLeft += 100;
    }
  }

  function selectCurrentActived({ label, value }) {
    setCurrentActived(value);
    onChange && onChange({ label, value });
  }

  useEffect(() => {
    setCurrentActived(GET_QUERY.activeKey || "ALL");
  }, []);

  return (
    <div className="status-tabs">
      <div className="left-arrow" onClick={scrollToLeft}>
        <Icon.CustomIcon icon={Icon.faChevronLeft} />
      </div>

      <div className="right-arrow" onClick={scrollToRight}>
        <Icon.CustomIcon icon={Icon.faChevronRight} />
      </div>

      <div ref={categoriesContainer} className="status-list-container">
        {status?.map((item, index) => (
          <div className="status-list" key={index}>
            <div
              className={`status-item ${currentActived === item?.value && "item-actived"}`}
              onClick={() => selectCurrentActived(item)}
            >
              {item?.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusTabs;
