import { useLazyQuery, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { PRIORITY_CUSTOMER } from "features/PotentialCustomer/constants";
import {
  CHECK_DUPLICATE_CUSTOMER,
  COUNT_POTENTIAL_CUSTOMER,
  GET_FILE_SAMPLE_CUSTOMER,
  GET_STAFF_WITH_IDS,
  POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
  POTENTIAL_CUSTOMER_DETAIL,
  POTENTIAL_CUSTOMER_LINK_ABLE,
  POTENTIAL_CUSTOMER_LIST,
} from "graphql/potentialCustomer/query";
import { GET_STAFFS } from "graphql/user/query";
import { useGetUserPermissions } from "hooks/user/user";
import { isEqual } from "lodash";
import { hasPermission } from "utils/helperFuncs";

export const useGetPotentialCustomerList = (params, fetchPolicy = "cache-first") => {
  const { data, loading, refetch, fetchMore } = useQuery(POTENTIAL_CUSTOMER_LIST, {
    variables: {
      ...params,
      filters: {
        ...params.filters,
        priorityFilter: {
          filterType: params.filters?.priorityFilter?.filterType,
          priorities: params.filters?.priorityFilter?.priorities?.filter(
            (item) => item !== PRIORITY_CUSTOMER.UNCATEGORIZED
          ),
        },
        inChargeStaffIDFilter: {
          filterType: params.filters?.inChargeStaffIDFilter?.filterType,
          inChargeStaffIDs: params.filters?.inChargeStaffIDFilter?.inChargeStaffIDs?.filter(
            (item) => item !== PRIORITY_CUSTOMER.UNCATEGORIZED
          ),
        },
        careStaffIDFilter: {
          filterType: params.filters?.careStaffIDFilter?.filterType,
          careStaffIDs: params.filters?.careStaffIDFilter?.careStaffIDs?.filter(
            (item) => item !== PRIORITY_CUSTOMER.UNCATEGORIZED
          ),
        },
      },
    },
    fetchPolicy: fetchPolicy,
  });

  const potentialCustomer = data?.potentialCustomer?.pagination?.customers || [];
  const currentTotalPotentialCustomer = potentialCustomer?.length;
  const paginationData = data?.potentialCustomer?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: {
          offset: currentTotalPotentialCustomer,
          limit: 10,
        },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.potentialCustomer?.pagination?.customers) return pv;
        const newData = {
          customers: {
            pagination: {
              paginationData: { ...fetchMoreResult?.potentialCustomer?.pagination?.paginationData },
              potentialCustomers: [
                ...pv?.potentialCustomer?.pagination?.customers,
                ...fetchMoreResult?.potentialCustomer?.pagination?.customers,
              ],
            },
          },
        };
        return newData;
      },
    });
  };
  const hasMoreData = !(currentTotalPotentialCustomer === paginationData?.total);

  return {
    loading,
    potentialCustomer,
    onLoadMoreData,
    hasMoreData,
    refetch,
    paginationData,
  };
};

export const useGetCustomerDuplicate = (params, limit) => {
  const [getDataDuplicate, { data, loading }] = useLazyQuery(CHECK_DUPLICATE_CUSTOMER);

  const handleGetDataDuplicate = async (params) => {
    return getDataDuplicate({
      variables: {
        filters: {
          ...params?.filters,
          isValidate: true,
        },
        pagination: {
          offset: 0,
          limit: 1,
        },
      },
      fetchPolicy: "cache-first",
    });
  };

  return {
    data: data?.potentialCustomer?.getLinkableCustomers?.customers?.[0],
    loading,
    handleGetDataDuplicate,
  };
};

export const useGetCountPotentialCustomer = () => {
  const { data, refetch } = useQuery(COUNT_POTENTIAL_CUSTOMER);

  return {
    newCount: data?.new?.count,
    takingCareCount: data?.takingCare?.count,
    stopCount: data?.stop?.count,
    completeCaringCount: data?.completeCaring?.count,
    refetch,
  };
};

export const useGetPotentialCustomerDetail = (params) => {
  const { data, loading, refetch } = useQuery(POTENTIAL_CUSTOMER_DETAIL, {
    variables: params,
    fetchPolicy: "cache-first",
    skip: !params?.id,
  });

  return {
    data: data?.potentialCustomer?.get,
    loading,
    refetch,
  };
};

export const useGetPotentialCustomerActivityLogs = (params) => {
  const { data, loading, refetch } = useQuery(POTENTIAL_CUSTOMER_ACTIVITY_LOGS, {
    variables: params,
    fetchPolicy: "cache-first",
    skip: !params?.customerID,
  });

  return {
    activityLogsData: data?.potentialCustomer?.getActivityLogs?.potentialCustomerLogs,
    activityLogsLoading: loading,
  };
};

export const useGetSampleFileCustomer = (params) => {
  const [handleGetFileSampleCustomer, { data, loading }] = useLazyQuery(GET_FILE_SAMPLE_CUSTOMER);

  return {
    fileSampleCustomer: data?.potentialCustomer?.getBatchCreateExampleFile?.url || undefined,
    loadingFileSample: loading,
    handleGetFileSampleCustomer,
  };
};

export const useGetCareStaffFilter = (params, fetchPolicy = "cache-first") => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_STAFFS, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: fetchPolicy,
  });

  const paginationData = data?.user?.pagination?.paginationData;
  const currentTotal = data?.user?.pagination?.users?.length;
  const staffs = data?.user?.pagination?.users;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.user?.pagination?.paginationData,
          fetchMoreResult?.user?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);
  return {
    loading,
    paginationData,
    data: staffs?.map(({ id, fullname, telephone }) => ({
      label: fullname,
      value: id,
      telephone: telephone,
      labelStaff: fullname,
    })),
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useGetInChargeStaffFilter = (params, fetchPolicy = "cache-first") => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_STAFFS, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: fetchPolicy,
  });

  const paginationData = data?.user?.pagination?.paginationData;
  const currentTotal = data?.user?.pagination?.users?.length;
  const staffs = data?.user?.pagination?.users;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.user?.pagination?.paginationData,
          fetchMoreResult?.user?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);
  return {
    loading,
    paginationData,
    data: staffs?.map(({ id, fullname, telephone }) => ({
      label: fullname,
      value: id,
      telephone: telephone,
      labelStaff: fullname,
    })),
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useGetCreatedByFilter = (params, fetchPolicy = "cache-first") => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_STAFFS, {
    variables: {
      filters: {
        ...params?.filters,
      },
      pagination: params?.pagination,
    },
    fetchPolicy: fetchPolicy,
  });

  const paginationData = data?.user?.pagination?.paginationData;
  const currentTotal = data?.user?.pagination?.users?.length;
  const staffs = data?.user?.pagination?.users;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv?.user?.pagination?.paginationData,
          fetchMoreResult?.user?.pagination?.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);
  return {
    loading,
    paginationData,
    data: staffs?.map(({ id, fullname, telephone }) => ({
      label: fullname,
      value: id,
      telephone: telephone,
      labelStaff: fullname,
    })),
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useCustomerPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();

  return {
    canCustomerView: hasPermission(permissions, [USER_PERMISSIONS.CUSTOMER_VIEW]),
    canCustomerCreate: hasPermission(permissions, [USER_PERMISSIONS.CUSTOMER_CREATE]),
    canCustomerUpdate: hasPermission(permissions, [USER_PERMISSIONS.CUSTOMERS_UPDATE]),
    canCustomerReportStaffView: hasPermission(permissions, [USER_PERMISSIONS.CUSTOMER_REPORT_STAFF_VIEW]),
    canCustomerReportManagerView: hasPermission(permissions, [USER_PERMISSIONS.CUSTOMER_REPORT_MANAGER_VIEW]),
    loading,
  };
};

export const useGetPotentialCustomerLinkAble = (params, fetchPolicy = "cache-first") => {
  const { data, loading, refetch, fetchMore } = useQuery(POTENTIAL_CUSTOMER_LINK_ABLE, {
    variables: params,
    fetchPolicy: fetchPolicy,
  });

  const paginationData = data?.potentialCustomer?.getLinkableCustomers?.paginationData;
  const currentTotal = data?.potentialCustomer?.getLinkableCustomers?.customers?.length;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: currentTotal, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (fetchMoreResult?.potentialCustomer?.getLinkableCustomers?.customers?.length === 0) return pv;
        const newData = {
          potentialCustomer: {
            getLinkableCustomers: {
              paginationData: { ...fetchMoreResult?.potentialCustomer?.getLinkableCustomers?.paginationData },
              customers: [
                ...pv?.potentialCustomer?.getLinkableCustomers?.customers,
                ...fetchMoreResult?.potentialCustomer?.getLinkableCustomers?.customers,
              ],
            },
          },
        };
        return newData;
      },
    });
  };
  const hasMoreData = !(currentTotal === paginationData?.total);

  return {
    onLoadMoreData,
    hasMoreData,
    loading,
    refetch,
    data: data?.potentialCustomer?.getLinkableCustomers?.customers,
  };
};

export const useGetStaffWithIds = (params) => {
  const { data } = useQuery(GET_STAFF_WITH_IDS, {
    variables: {
      filters: { ids: params?.ids?.filter((item) => item !== "UNCATEGORIZED") },
    },
    skip: !params?.ids,
  });

  return {
    data: data?.user?.pagination?.users,
  };
};
