import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import { useGetSellerLoadMore } from "hooks/seller";
import { useState } from "react";
import { FilterLoadMore } from "../Filter";

export function FilterSellerIDs({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: { offset: 0, limit: 10 },
  });

  const { hasMoreData, loadMoreDataSellers, loading, sellersList } = useGetSellerLoadMore(params, "cache-first");

  const options = sellersList?.map((item) => {
    return {
      value: item?.id,
      label: (
        <SpaceCustom>
          <EllipsisText limit={50} line={1} isLink title={item?.code} />
          <EllipsisText limit={50} line={2} title={item?.fullName} />
        </SpaceCustom>
      ),
    };
  });

  const handleSearch = (value) => {
    setParams({
      filters: { query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return (
    <FilterLoadMore
      params={params}
      data={options || []}
      onLoadMoreData={loadMoreDataSellers}
      onFilter={(value) => onFilter(value?.length ? value : undefined)}
      onSearch={handleSearch}
      hasMore={hasMoreData}
      loading={loading}
    />
  );
}
