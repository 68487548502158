import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, Col, List, Row, Space, Typography } from "antd";
import { Icon, SvgIcon } from "assets/icons";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { INPUT_TYPE, PRODUCT_INPUT_TYPE } from "config/constants";
import {
  FilterLevelPrice,
  FilterManufacture,
} from "features/Campaign/components/Detail/ProductPromo/components/FilterProduct";
import RadioGroup from "features/Warranty/WarrantyList/component/RadioGroup";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";
import TagsList from "../TagsList";
import useModalSelectProduct from "./useModalSelectProduct";

import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import "./index.scss";

const FORM_ITEM_STYLE = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const optionVAT = [
  { label: t("product.vatCheckbox.yes"), value: true },
  { label: t("product.vatCheckbox.no"), value: false },
];

function ModalSelectProduct({
  indexConditionProductGroups,
  useInModal,
  indexPromotionCampaignTiers,
  form,
  promotionCampaignTiers,
  isUpdate,
  dataProductsServer,
  indexComboFixedPrice,
  isCheckboxType,
  indexComboDiscountMoney,
}) {
  const {
    isModalSelectProduct,
    loadingCheckDuplicateCodes,
    dataAllCategories,
    filterItemsTypeOptions,
    rowSelection,
    loadingProduct,
    isFilterSelected,
    selectedRows,
    productData,
    paginationData,
    dataDuplicateCode,
    prodAtt,
    warehouses,
    openModalSelectProduct,
    closeModalSelectProduct,
    handleSelectProduct,
    filterProductByCategoryId,
    handleSearchTreeSelect,
    handleSearch,
    handleChange,
    onTableChange,
    renderProductAttributes,
    renderChildrenInStock,
    handleFilter,
    params,
    dataManufactures,
    loadingManufactures,
    listWarehouse,
    loadingWarehouses,
    setParams,
    filterProductExtra,
    filterProductPrices,
    isVisibleQuestionModal,
    handleCancelModalQuestion,
    handleSubmitQuestion,
    selectedProducts,
  } = useModalSelectProduct({
    form,
    indexConditionProductGroups,
    indexPromotionCampaignTiers,
    promotionCampaignTiers,
    isUpdate,
    dataProductsServer,
    indexComboFixedPrice,
    isCheckboxType,
    indexComboDiscountMoney,
  });

  const columns = [
    //Mã sản phẩm
    {
      title: <Typography.Text>{t("product.tableTitle.code")}</Typography.Text>,
      dataIndex: "code",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link target={"_blank"} to={`/product/detail/${record?.id}`} className="linked-text">
              {record?.code}
            </Link>
            <CustomTextHiddenLine text={record?.name} line={1} />
          </div>
        );
      },
    },
    //Thương hiệu
    {
      title: <Typography.Text>{t("product.tableTitle.brand")}</Typography.Text>,
      dataIndex: ["manufacturer", "name"],
      align: "center",
      render: (_, record) => <div>{record?.manufacturer?.name ? record.manufacturer.name : "- -"}</div>,
      filterDropdown: <FilterManufacture dataManufactures={dataManufactures} onFilter={filterProductExtra} />,
    },
    //Bảo hành
    {
      title: <Typography.Text>{t("product.tableTitle.warranty")}</Typography.Text>,
      dataIndex: "warranty",
      align: "center",
      render: (_, record) => <div>{record?.warranty ? `${record.warranty} ${t("common.month")}` : "- -"}</div>,
    },
    ...renderProductAttributes(prodAtt),
    //Giá cấp 1
    {
      title: (
        <Typography.Text>
          <div className="title-table">{t("product.tableTitle.priceLevel1")}</div>
        </Typography.Text>
      ),
      dataIndex: ["productPrices", "level1Price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.level1Price)}</div>,
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceLevel1");
          }}
          defaultValue={params.filters.priceLevel1}
        />
      ),
    },
    //Tồn thực
    {
      title: <Typography.Text>{t("product.tableTitle.inStock")}</Typography.Text>,
      children: renderChildrenInStock(warehouses),
      align: "center",
    },
    //Tồn kho
    ...(useInModal
      ? [
          {
            title: (
              <Typography.Text>
                <div className="title-table">{t("product.tableTitle.inStock")}</div>
              </Typography.Text>
            ),
            align: "right",
            render: (_, record) => <div>{record?.stocks?.at(0).quantity || "- -"}</div>,
          },
        ]
      : []),
    //Xuất vat
    {
      title: <Typography.Text>{t("product.EXPORT_VAT")}</Typography.Text>,
      render: (_, record) => (record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.CloseIcon />),
      align: "center",
      filterDropdown: (
        <FilterMenu type="radio" options={optionVAT} onFilter={(value) => handleFilter({ vat: value })} />
      ),
    },
  ];
  const dataSelected = selectedProducts || [];

  const dataSource = isFilterSelected ? selectedRows : productData;

  const renderCustomComponent = () => {
    if (dataSelected?.length) {
      return (
        <Space>
          <div>
            <List
              style={isCheckboxType ? { width: "300px" } : {}}
              dataSource={dataSelected?.slice(0, 3)}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <Space direction="vertical">
                    <CustomTextHiddenLine
                      text={
                        <Link target={"_blank"} to={`/product/detail/${item?.id}`} className="linked-text">
                          {item?.code}
                        </Link>
                      }
                      line={1}
                    />
                    <CustomTextHiddenLine text={item?.name} line={1} />
                  </Space>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Badge count={dataSelected?.length} showZero>
              <SvgIcon.EditSquare />
            </Badge>
          </div>
        </Space>
      );
    }
    return <Typography.Link underline>{t("campaign.combo.selectProduct")}</Typography.Link>;
  };

  return (
    <div className="custom-modal-select-product">
      <CustomModal
        footer={false}
        centered={true}
        visible={isVisibleQuestionModal}
        title={<Typography.Text style={{ fontSize: "22px" }}>{t("campaign.combo.changeCategory")}</Typography.Text>}
        message={
          <div style={{ textAlign: "justify", marginTop: "-20px" }}>{t("campaign.combo.questionModalCategory")}</div>
        }
        onCancel={handleCancelModalQuestion}
        onOke={() => handleSubmitQuestion()}
      />
      <CustomModal
        customComponent={
          <Space direction="vertical">
            <Card className="custom-card-select-product">{renderCustomComponent()}</Card>
          </Space>
        }
        {...{
          message: <Typography.Title level={3}>{t("campaign.combo.addProductToPromotion")}</Typography.Title>,
          footer: false,
          width: "fit-content",
          visible: isModalSelectProduct,
          onOpen: openModalSelectProduct,
          onCancel: closeModalSelectProduct,
          onOke: handleSelectProduct,
          buttonLoading: loadingCheckDuplicateCodes,
          okeButtonLabel: t("campaign.combo.addProduct"),
          children: (
            <>
              <Row
                gutter={[16, 4]}
                style={{
                  alignItems: "center",
                  borderRadius: "8px",
                  margin: 0,
                }}
              >
                <Col span={6}>
                  <FormInput
                    type={INPUT_TYPE.TREE_SELECT}
                    formItemOptions={{
                      ...FORM_ITEM_STYLE,
                      placeholder: t("product.productForm.placeholderCategory"),
                      label: <Typography.Text strong>{t("product.productForm.category")}</Typography.Text>,
                      // name: 'categoryIDs',
                      style: { marginBottom: 0 },
                    }}
                    inputOptions={{
                      placeholder: t("product.productForm.placeholderCategory"),
                      treeData: dataAllCategories,
                      showSearch: true,
                      allowClear: true,
                      onChange: filterProductByCategoryId,
                      filterTreeNode: (search, item) => handleSearchTreeSelect(search, item),
                    }}
                  />
                </Col>
                <Col span={10}>
                  <FormInput
                    formItemOptions={{
                      ...FORM_ITEM_STYLE,
                      label: <Typography.Text strong>{t("campaign.combo.searchAdvance")}</Typography.Text>,
                      style: { marginBottom: 0 },
                    }}
                    inputOptions={{
                      className: "custom-input",
                      prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
                      placeholder: t("product.searchPlaceholder"),
                      allowClear: true,
                      onChange: handleSearch,
                    }}
                  />
                </Col>
                <Col span={8} style={{ paddingTop: "30px" }}>
                  <RadioGroup
                    filterItemsTypeOptions={filterItemsTypeOptions}
                    handleChange={handleChange}
                    isUpdate={isUpdate}
                  />
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ color: "orange" }}>{t("campaign.combo.notingModal")}</Typography.Text>
                </Col>
                <Col span={24}>
                  <TagsList
                    listFilter={params.filters}
                    dataManufactures={dataManufactures}
                    loadingManufactures={loadingManufactures}
                    dataWarehouses={listWarehouse}
                    loadingWarehouses={loadingWarehouses}
                    dataAttributes={prodAtt}
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <CustomTable
                    rowSelection={{
                      type: isCheckboxType ? "checkbox" : "radio",
                      ...rowSelection,
                    }}
                    columns={columns}
                    loading={loadingCheckDuplicateCodes || loadingProduct}
                    dataSource={dataSource}
                    rowKey={(record) => record?.id}
                    onChange={onTableChange}
                    pagination={{
                      total: isFilterSelected ? selectedRows?.length : paginationData?.total,
                      pageSize: paginationData?.limit,
                      current: paginationData?.offset / paginationData?.limit + 1 || 1,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
                <Col span={24}>
                  {dataDuplicateCode?.length ? (
                    <Typography>
                      <Typography.Paragraph type="danger">
                        {t("campaign.combo.errorDuplicateCodes", {
                          productLength: dataDuplicateCode?.length,
                        })}
                        :{" "}
                        <Typography.Text type="danger" strong>
                          {dataDuplicateCode?.map((item) => item).join(`, `)}
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph type="danger">
                        {t("campaign.combo.errorDuplicateCodesWarning")}
                      </Typography.Paragraph>
                    </Typography>
                  ) : null}
                </Col>
              </Row>
            </>
          ),
        }}
      />
    </div>
  );
}

export default ModalSelectProduct;
