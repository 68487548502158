import { Col, Descriptions, Row, Space, Typography } from "antd";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { SubTitleCard } from "features/Serial/components/TitleCard";
import { t } from "i18next";
import "./index.scss";

export const AddInfo = ({ data }) => {
  return (
    <Col span={12}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <SubTitleCard subTitle={t("serialIE.addInfo.title")} />
        </Col>
        <Col span={24}>
          <Descriptions bordered column={2} size="small" className="add-info-detail-serial-import-notes">
            <Descriptions.Item span={2} label={t("warrantyRequest.create.note")}>
              {data?.note || "- -"}
            </Descriptions.Item>
            <Descriptions.Item span={1} label={t("warrantyRequest.popup.files")}>
              <Space style={{ flexWrap: "wrap" }}>
                {data?.fileURLs?.map((fileURL, index) => {
                  return <CustomViewDocumentFiles file={fileURL} key={index} />;
                }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Col>

    // <Col span={10}>
    //   <Row gutter={[16, 8]}>
    //     <Col span={24}>
    //       <SubTitleCard subTitle={t("serialIE.addInfo.title")} />
    //     </Col>
    //     <Col span={24}>
    //       <Typography.Text strong>{t("warrantyRequest.create.note")}</Typography.Text>
    //     </Col>
    //     <Col span={24}>{data?.note}</Col>
    //     <Col span={24}>
    //       <Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>
    //     </Col>
    //     <Col span={24}>

    //     </Col>
    //   </Row>
    // </Col>
  );
};
