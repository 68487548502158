import { Dropdown, Menu, Space, Typography, message } from "antd";
import CustomTable from "components/CustomTable";
import CustomTitle from "features/Serial/components/CustomTitle";
import { t } from "i18next";
import { useState } from "react";
import useTable from "./useTable";

import { SvgIcon } from "assets/icons";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { warrantyUnitLabel } from "features/Serial/constant";
import { Link } from "react-router-dom";
import "../../../index.scss";
import SerialsInputTag from "../SerialsInputTag";
import styles from "./index.module.scss";

function TableProduct({
  handleGetTags,
  tags,
  pendingWarrantyNoteLoading,
  form,
  dataTableProduct,
  sumSerialByLack,
  sumSerialByOrder,
  sumSerialByScanned,
}) {
  const { renderLack } = useTable();

  const copyToClipboard = (record) => {
    if (record?.serialItems?.length > 0) {
      const textToCopy = record?.serialItems.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCoppy"));
    }
  };
  const renderSubTotal = (record) => {
    if (record.quantity < record?.serialItems?.length) {
      return (
        <Typography.Text style={{ color: "red" }}>
          {t("serialIE.productInfo.residual")}: {record?.serialItems?.length - record?.quantity}
        </Typography.Text>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>;
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <Typography.Text className={styles["sub-title"]}>
          {t("common.lack")}: {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
        </Typography.Text>
      );
    }
  };

  const [visible, setVisible] = useState(null);

  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "start" }}>
            <Link to={`/product/detail/${record?.product?.id}`}>
              <Typography.Link strong>{record?.product?.code}</Typography.Link>
            </Link>
            <CustomTextHiddenLine text={record?.product?.name} line={2} />
          </div>
        );
      },
      isHidden: false,
      width: 300,
    },

    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.byOrder")} />,
      render: (_, record, index) => <Typography.Text>{record?.quantity || 0}</Typography.Text>,
      width: 180,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.scanned")} />,
      width: 180,
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Typography.Text>{record?.serialItems?.length || 0}</Typography.Text>
            {renderSubTotal(record)}
          </Space>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.providerOfWarranty")} />,
      render: (_, record, index) => (
        <Typography.Text>
          {record?.warranty || 0} {warrantyUnitLabel[record?.warrantyPeriod]}
        </Typography.Text>
      ),
      width: 200,
    },
    {
      align: "center",
      title: (
        <CustomTitle
          title={t("serialIE.productInfo.detailSerialB")}
          subTitle={<Typography.Text className={styles["sub-title"]}>{t("serialIE.productInfo.note")}</Typography.Text>}
        />
      ),
      render: (_, record, index) => {
        return (
          <div>
            <SerialsInputTag
              tagsIndex={record?.serialItems}
              tags={tags}
              handleGetTags={handleGetTags}
              index={index}
              sumSerialByOrder={sumSerialByOrder}
              sumSerialByScanned={sumSerialByScanned}
              sumSerialByLack={sumSerialByLack}
              record={record}
              form={form}
              setVisible={setVisible}
              visible={visible}
            />
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("campaign.actions")} />,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => setVisible(index)}
                  >
                    <SvgIcon.IconViewList />
                    <Typography.Text>{t("serialIE.viewAll")}</Typography.Text>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => copyToClipboard(record)}
                  >
                    <SvgIcon.IconCoppy />
                    <Typography.Text>{t("serialIE.coppy")}</Typography.Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
            className="button-action-recommend button-offers-recommend"
          >
            <SvgIcon.IconMore style={{ fontSize: "16px" }} />
          </Dropdown>
        );
      },
      width: 70,
    },
  ];

  return (
    <div className={styles["custom-table"]}>
      <CustomTable
        columns={columns}
        dataSource={dataTableProduct}
        rowKey={(record) => record?.id}
        scroll={{
          x: 900,
          // y: +height,
        }}
        loading={pendingWarrantyNoteLoading}
      />
    </div>
  );
}

export default TableProduct;
