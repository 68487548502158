import { useGetKeyManagement, useKeyManagementPermissions } from "hooks/keyManagement/query";
import useDebounce from "hooks/useDebounce";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { getQuery } from "utils/helperFuncs";
import { KEY_MANAGEMENT, SORT_TYPES, formatBuildQueryString } from "../constants";

function useKeyList() {
  const GET_QUERY = getQuery();
  const {
    canKeyLicenseAccountViewAll,
    canKeyLicenseAccountViewPart,
    canKeyLicenseAccountCreate,
    canKeyLicenseAccountDetail,
  } = useKeyManagementPermissions();

  const [params, setParams] = useState({
    filters: {
      queries: GET_QUERY.queries || undefined,
      serialTypes: GET_QUERY.serialTypes || KEY_MANAGEMENT,
      statuses: GET_QUERY.statuses || undefined,
      sellerIDs: GET_QUERY.sellerIDs,
      providerIDs: GET_QUERY.providerIDs,
      createdByIDs: GET_QUERY.createdByIDs,
      productIDs: GET_QUERY.productIDs,
      timeRange: GET_QUERY.from
        ? {
            from: GET_QUERY.from,
            to: GET_QUERY.to,
          }
        : undefined,
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: GET_QUERY.limit || 10,
    },
    sorts: {
      sortType: GET_QUERY.sortType,
    },
  });

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const { dataKeyList, paginationData, loadingKeyList } = useGetKeyManagement(params);

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sorts: sorter?.order ? { by: "CREATED_AT", sortType: SORT_TYPES[`${sorter?.order}`] } : {},
    };

    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (value, objKey) => {
    const newParams = {
      ...params,
      filters: { ...params?.filters, [`${objKey}`]: value },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const handleChangeParams = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, queries: debouncedValue.trim() || undefined },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      formatBuildQueryString(newParams);
    }
  }, [debouncedValue]);

  useEffect(() => {
    const newParams = {
      ...params,
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  }, []);

  return {
    params,
    dataKeyList,
    loadingKeyList,
    paginationData,
    handleFilter,
    handleSearch,
    handleChangeParams,
    handleChangeTable,
    canKeyLicenseAccountViewAll,
    canKeyLicenseAccountViewPart,
    canKeyLicenseAccountCreate,
    canKeyLicenseAccountDetail,
  };
}

export default useKeyList;
