import React from "react";
import { t } from "i18next";
import { Col, Row, Space } from "antd";

import { PrimaryButton } from "components/PrimaryButton";
import PeriodSelect from "components/PeriodSelect";
import InputSearch from "../InputSearch";
import useToolbar from "./useToolbar";
import { useHistory } from "react-router-dom";
import { useSupplierPermissions } from "hooks/supplier";

function SupplierToolbar({
  onSearch,
  customerGroupOptions,
  onChangeParams,
  params,
  supplierTypeOptions,
  filterSellerByTimeRange,
}) {
  const { renderTags, renderActionsTag, renderSupplierTypeTag, renderIsSellerTag } = useToolbar({
    customerGroupOptions,
    onChangeParams,
    params,
    supplierTypeOptions,
  });

  const history = useHistory();
  const { canCreateSupplier } = useSupplierPermissions();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xl={12}>
        <InputSearch onSearch={onSearch} />
      </Col>
      <Col span={9} xl={3} offset={3}>
        <PeriodSelect onChange={filterSellerByTimeRange} />
      </Col>
      {canCreateSupplier && (
        <Col span={9} xl={3} offset={3}>
          <Space style={{ width: "100%", justifyContent: "end" }}>
            <PrimaryButton title={t("supplier.createSupplier")} onClick={() => history.push("/supplier/create")} />
          </Space>
        </Col>
      )}
      <Col span={24}>
        {renderActionsTag()}
        {renderSupplierTypeTag()}
        {renderIsSellerTag()}
        {renderTags}
      </Col>
    </Row>
  );
}

export default SupplierToolbar;
