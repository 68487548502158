import React, { useState } from "react";
import { FilterLoadMore } from "components/CustomFilterMenu";
import { useGetSupplierOnLoadMore } from "hooks/supplier";

function FilterProviderIDs({ onFilter }) {
  const [params, setParams] = useState({
    filters: { query: null, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData } = useGetSupplierOnLoadMore(params);

  const options = data?.map((item) => {
    return { label: item?.fullName, value: item?.id };
  });

  const handleSearch = (value) => {
    setParams({
      ...params,
      filters: { ...params?.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return (
    <FilterLoadMore
      params={params}
      data={options || []}
      onLoadMoreData={onLoadMoreData}
      onFilter={(value) => onFilter(value, "providerIDs")}
      onSearchTextChange={handleSearch}
      hasMore={hasMoreData}
    />
  );
}

export default FilterProviderIDs;
