import { DELIVER_ORDER_STATUS } from "pages/App/Order/conts";

export const makePermissionsUnique = (data) => [...new Set(data)];

export const convertShipperList = (data) => {
  const convertTotalOrderDelivery = (totalOrderDelivery) => {
    let inDeliverOrders = [];

    if (totalOrderDelivery?.totalWaiting > 0) {
      inDeliverOrders.push({
        status: DELIVER_ORDER_STATUS.WAIT_TO_DELIVER,
        count: totalOrderDelivery.totalWaiting,
      });
    }

    if (totalOrderDelivery?.totalDelivering > 0) {
      inDeliverOrders.push({
        status: DELIVER_ORDER_STATUS.IN_DELIVER,
        count: totalOrderDelivery.totalDelivering,
      });
    }

    return inDeliverOrders;
  };

  return data?.map((record) => ({
    value: record?.id,
    name: record?.fullname,
    label: `${record?.fullname || "???"} - ${record?.telephone || "???"}`,
    inDeliverOrders: convertTotalOrderDelivery(record?.totalOrderDelivery),
  }));
};
