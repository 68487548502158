import React from "react";
import { Col, Space } from "antd";
import { t } from "i18next";
import useToolbar from "./useToolbar";
import RadioGroup from "../component/RadioGroup";
import ButtonAdd from "../component/ButtonAdd";
import InputSearch from "../component/InputSearch";

const Toolbar = ({
  onSearch,
  countSelectedRowKeys,
  selectedProduct,
  setIsFilterSelected,
  params,
  setParams,
  onChangeParams,
}) => {
  const { filterItemsTypeOptions, handleChange, handleSelectedData, renderTags } = useToolbar({
    onSearch,
    countSelectedRowKeys,
    selectedProduct,
    setIsFilterSelected,
    params,
    setParams,
    onChangeParams,
  });

  return (
    <>
      <Col span={12}>
        <InputSearch onSearch={onSearch} params={params} />
      </Col>

      <Col span={3} offset={9}>
        <Space style={{ width: "100%", justifyContent: "end" }}>
          <ButtonAdd
            handleSelectedData={handleSelectedData}
            selectedProduct={selectedProduct}
            title={t("warranty.warrantyList.warrantyClaim")}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <div>{renderTags}</div>
          <RadioGroup filterItemsTypeOptions={filterItemsTypeOptions} handleChange={handleChange} />
        </Space>
      </Col>
    </>
  );
};

export default Toolbar;
