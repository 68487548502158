/* eslint-disable @typescript-eslint/no-unused-vars */
import { Skeleton } from "antd";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import ProductPromo from "./ProductPromo";
import useCampaignDetail from "./useCampaignDetail";

const Detail = () => {
  const { data, loading, refetch } = useCampaignDetail();
  return (
    <div>
      <PageHeader
        pageTitle={t("campaign.detailPromo.detailCampaign")}
        routes={[
          {
            path: "/campaign",
            name: t("campaign.form.list"),
          },
          {
            path: "",
            name: data?.name ?? (
              <Skeleton.Button
                active
                size={"small"}
                style={{
                  width: "200px",
                  height: "20px",
                }}
              />
            ),
          },
        ]}
      />
      <ProductPromo data={data} refetch={refetch} loadingDetail={loading} />
    </div>
  );
};

export default Detail;
