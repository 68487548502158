import { useEffect } from "react";
import { t } from "i18next";

import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import { PROMOTION_CAMPAIGN_STATUS } from "features/Campaign/constants";
import { useCampaign } from "features/Campaign/hooks";
import { useLazyGetCategoriesOptions } from "hooks/campaign/campaign";
import { useLazyGetManufacturers } from "hooks/manufacturer/manufacturer";
import { useSalesChannelWithIds } from "hooks/salesChannel";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import { useGetCreatedByWithIds } from "hooks/order";

export function useToolbar({ params, onChangeParams, isStatusAll, statusTabs, sellerName }) {
  const GET_QUERY = getQuery();

  const { TYPE_CAMPAIGN, STATUS_CAMPAIGN, ACTIONS_CAMPAIGN_TAG, ORDER_STATUS_FILTER } = useCampaign();

  const { dataManufactures, getDataManufacturers } = useLazyGetManufacturers();

  const { data: dataAllCategories, getDataCategories } = useLazyGetCategoriesOptions();

  const { convertedData: dataSalesChannel } = useSalesChannelWithIds({
    ids: GET_QUERY.saleChannelIDs ? GET_QUERY.saleChannelIDs : undefined,
  });

  const { data: createdByOptions } = useGetCreatedByWithIds({
    ids: GET_QUERY.createdByIDs ? GET_QUERY.createdByIDs : undefined,
  });

  const handleChangeDate = (value) => {
    const { from, to } = value;
    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
  };

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const renderActionsTag = () => {
    return (
      params?.filters?.isActive !== undefined && (
        <CustomTags
          handleOnClose={() => handleOnClose("isActive")}
          label={
            <div>
              <span className="label">{t("campaign.tags.actions")}</span>:{" "}
              {params?.filters?.isActive === true ? ACTIONS_CAMPAIGN_TAG.ACTIVE : ACTIONS_CAMPAIGN_TAG.DISABLED}
            </div>
          }
        />
      )
    );
  };

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };
  const tagsConfig = [
    {
      title: t("campaign.tags.types"),
      options: TYPE_CAMPAIGN,
      selected: params?.filters?.types,
      key: "types",
    },
    {
      title: t("campaign.tags.channelBuy"),
      options: dataSalesChannel,
      selected: params?.filters?.saleChannelIDs,
      key: "saleChannelIDs",
    },
    {
      title: t("campaign.tags.categorys"),
      options: dataAllCategories,
      selected: params?.filters?.categoryIDs,
      key: "categoryIDs",
    },
    {
      title: t("campaign.tags.brands"),
      options: dataManufactures,
      selected: params?.filters?.brandIDs,
      key: "brandIDs",
    },
    {
      title: t("campaign.tags.status"),
      options: STATUS_CAMPAIGN,
      selected: params?.filters?.statuses,
      key: "statuses",
    },
    {
      title: t("campaign.tags.status"),
      options: ORDER_STATUS_FILTER,
      selected: params?.filters?.status,
      key: "status",
    },
    {
      title: t("common.seller"),
      options: sellerName,
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
    },
    {
      title: t("campaign.createdBy"),
      options: createdByOptions,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
  ];
  const renderTagsByTab = isStatusAll ? tagsConfig : tagsConfig?.filter(({ key }) => key !== "statuses");

  const handleOnClose = (key) => {
    switch (key) {
      case "isActive":
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
      case "timeRange":
        onChangeParams && onChangeParams({ [`${key}`]: {} });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const getTagsToRender = (type) => {
    switch (type) {
      case PROMOTION_CAMPAIGN_STATUS.UPCOMING:
      case PROMOTION_CAMPAIGN_STATUS.ON_GOING:
      case PROMOTION_CAMPAIGN_STATUS.RECONCILING:
      case PROMOTION_CAMPAIGN_STATUS.ENDED:
        return renderTagsByTab;
      default:
        return tagsConfig;
    }
  };

  const renderTags = getTagsToRender(statusTabs)
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  useEffect(() => {
    if (GET_QUERY.brandIDs) {
      !dataManufactures && getDataManufacturers();
    }
    if (GET_QUERY.categoryIDs) {
      !dataAllCategories && getDataCategories();
    }
  }, [GET_QUERY]);

  return { handleChangeDate, renderDateTag, renderTags, renderActionsTag };
}
