import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const PageHeader = ({ pageTitle, routes }) => {
  return (
    <div className="page-header">
      <div className="page-title">{pageTitle}</div>
      <div className="bread-crumb">
        {routes?.map((item, index) => {
          if (index !== routes.length - 1) {
            return (
              <Link to={item?.path} key={index}>
                {item?.name} <span>/ </span>
              </Link>
            );
          }
          return <span key={index}> {item?.name}</span>;
        })}
      </div>
    </div>
  );
};

export default PageHeader;
