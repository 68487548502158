/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "antd/lib/form/Form";
import { CURRENCY_INPUT, IMPORT_REQUEST_TYPE } from "features/Purchase/constant";
import { useGetOrderLoadMore } from "hooks/order";
import { useGetSellerCreateCart } from "hooks/seller";
import { useWarehouse } from "hooks/warehouse";
import { useCreateImportRequest } from "hooks/warehouse/mutation";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const useCreate = () => {
  const [form] = useForm();
  const formData = form.getFieldsValue();
  const [sellerInfo, setSellerInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [typeSalesReturn, setTypeSalesReturn] = useState(IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY);
  const [isDisabledSelectProduct, setIsDisabledProduct] = useState(true);

  const [visiblePopupChangeOrder, setVisiblePopupChangeOrder] = useState(false);
  const [visiblePopupChangeSeller, setVisiblePopupChangeSeller] = useState(false);

  const [paramsWarehouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { warehouses } = useWarehouse(paramsWarehouse);
  const valueImportWarehouse = warehouses?.map(({ id, name }) => ({ value: id, label: name }));
  const [dataTableProduct, setDataTableProduct] = useState([]);
  const history = useHistory();

  const [sumPrice, setSumPrice] = useState(0);
  const [currencyFirst, setCurrencyFirst] = useState(0);

  const [paramsOrder, setParamsOrder] = useState({
    filters: {
      query: "",
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const [paramsOrderSeller, setParamsOrderSeller] = useState({
    filters: {
      query: "",
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const handleChangeTypeSalesReturn = (value) => {
    setTypeSalesReturn(value);
    setIsDisabledProduct(true);
    setDataTableProduct([]);
    setOrderInfo(null);
    setSellerInfo(null);
    form.setFieldsValue({
      sellerID: undefined,
      orderID: undefined,
      type: value,
      items: [],
    });
  };
  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isSeller: true },
    pagination: { offset: 0, limit: 10 },
  });

  const [paramsSellerMemory, setParamsSellerMemory] = useState({
    filters: {
      query: sellerInfo?.sellername || sellerInfo?.fullName,
      isSeller: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });
  const { sellers: sellerMemory } = useGetSellerCreateCart(paramsSellerMemory);
  const {
    sellers,
    loading: loadingSeller,
    loadMoreData: loadMoreSeller,
    hasMoreData: hasMoreSeller,
  } = useGetSellerCreateCart(paramsSeller, "cache-first");

  const onSearchSeller = (value) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: value || null, isSeller: true },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSellerMemory({
      filters: {
        ...paramsSeller.filters,
        query: value || sellerInfo?.sellername || sellerInfo?.fullName,
        isSeller: true,
      },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const [paramsOrderMemory, setParamsOrderMemory] = useState({
    filters: {
      query: orderInfo?.orderCode || orderInfo?.number,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { orders: orderMemory } = useGetOrderLoadMore(paramsOrderMemory, "cache-first");
  const {
    orders,
    hasMoreData: hasMoreOrder,
    onLoadMoreData: loadMoreOrder,
    loading: loadingOrders,
  } = useGetOrderLoadMore(paramsOrder, "cache-first");

  const onSearchOrder = (value) => {
    setParamsOrder({
      filters: { ...paramsOrder.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsOrderMemory({
      filters: {
        ...paramsOrder.filters,
        query: value || orderInfo?.orderCode || orderInfo?.number,
      },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const [valueSelectOrder, setValueSelectOrder] = useState();
  const [optionSelectProduct, setOptionSelectProduct] = useState([]);

  const handleGetOrder = (data) => {
    const listProduct = data.items?.map((item) => ({
      ...item?.product,
      orderID: data.id,
      orderCode: data.number,
    }));
    setOptionSelectProduct(listProduct);
    setIsDisabledProduct(false);
  };

  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      product: {
        id: data.id,
        code: data.code,
        name: data.name,
      },
      productID: data?.id,
      orderID: data?.orderID,
      productName: data?.name,
      quantity: null,
      price: null,
      currency: CURRENCY_INPUT?.VND,
      isVat: false,
      paymentNote: null,
      importReason: null,
      serialCodes: [],
      id: `${data?.id}-${data?.orderID}`,
    };

    const isDuplicateFormData = formData.items.some(
      (item) => item.productID === newData.productID && item.orderID === newData.orderID
    );
    if (!isDuplicateFormData) {
      formData.items.push(newData);
      form.setFieldsValue({
        ...formData,
      });
    }

    const newDataItemKey = `${data.id}-${data.orderID}`;
    const isDuplicate = dataTableProduct.some((item) => {
      const itemKey = `${item.productID}-${item.orderID}`;
      return itemKey === newDataItemKey;
    });
    const codeProduct = data?.code;
    const nameProduct = data?.name;
    if (!isDuplicate) {
      setDataTableProduct([
        ...dataTableProduct,
        {
          ...data,
          id: `${data?.id}-${data?.orderID}`,
          productID: data?.id,
          productName: data?.name,
          productCode: data?.code,
        },
      ]);
    } else {
      notify.error({
        message: t("warehouse.salesReturn.errorDuplicateProduct", { codeProduct, nameProduct }),
      });
    }
  };

  const handleDeleteProduct = (id, index) => {
    const dataFormDelete = formData?.items?.filter((item) => item.id !== id);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });
    const dataTableDelete = dataTableProduct?.filter((item) => item.id !== id);
    setDataTableProduct(dataTableDelete);
  };

  const initialValues = {
    items: [],
    type: IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY,
  };
  const { handleCreateImportReques } = useCreateImportRequest();
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const passwordInputRef = useRef(null);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const handlePasswordKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setIsFormCompleted(true);
      passwordInputRef.current.blur();
    }
  };

  const handleCreateClick = () => {
    setIsFormCompleted(true);
  };

  function areAllElementsSame(arr) {
    if (arr.length <= 1) {
      return true;
    }
    const firstElement = arr[0];
    return arr.every((item) => item === firstElement);
  }

  const handleFinish = async (values) => {
    setLoadingCreate(true);
    await form.validateFields().then(async (values) => {
      if (isFormCompleted) {
        const params = {
          type: values?.type,
          supplierID: values?.sellerID || orderInfo?.seller?.id,
          warehouseID: warehouses && warehouses[0]?.id,
          expandSalesReturnInput: {
            sellerID: values?.sellerID || orderInfo?.seller?.id,
            receivingAddress: values?.receivingAddress,
            processDeadline: values?.processDeadline,
            receiveMethod: values?.receiveMethod,
          },
          note: values?.note,
          fileURLs: values?.fileURLs,
          items: values?.items?.map((item, i) => {
            return {
              productID: item?.isAdd ? undefined : item?.productID,
              productName: item?.productName,
              quantity: item?.quantity,
              price: item?.price,
              currency: item?.currency,
              isVat: item?.isVat,
              expandSalesReturnItemInput: {
                orderID: item?.orderID,
                importReason: item?.importReason,
                serialCodes: item?.serialCodes,
              },
            };
          }),
        };
        const checkCurrency = values?.items?.map((item) => item?.currency);

        try {
          if (values?.items?.length > 0) {
            const resultCheckCurrency = areAllElementsSame(checkCurrency);
            if (resultCheckCurrency) {
              await form.validateFields();
              const dataCreate = await handleCreateImportReques({ importRequest: params });
              const idParams = dataCreate?.data?.importRequests?.create?.id;
              history.push(`/sales-return/detail/${idParams}`);
              notify.success({ message: t("serialIE.notify.successCreate") });
            } else {
              notify.warning({
                message: t("warehouse.salesReturn.checkCurrencyMessage"),
              });
            }
          } else {
            notify.error({
              message: t("warehouse.create.PleaseChooseProduct"),
            });
          }
        } catch (error) {
          console.info(error);
        } finally {
          setLoadingCreate(false);
        }
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const [optionSellerMemory, setOptionSellerMemory] = useState(null);

  const handleOkPopupChangeSeller = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        items: [],
      });
    }
    setIsDisabledProduct(true);
    setVisiblePopupChangeSeller(false);
    setDataTableProduct([]);
    setParamsOrderSeller({
      ...paramsOrderSeller,
      filters: {
        ...paramsOrderSeller?.filters,
        sellerIDs: [optionSellerMemory?.id],
      },
    });
    setValueSelectOrder(undefined);
    setSellerInfo(optionSellerMemory);
  };

  const handleChangeInfoSeller = (value, option) => {
    if (formData?.items?.length === 0) {
      setSellerInfo(option?.seller);
      setParamsOrderSeller({
        ...paramsOrderSeller,
        filters: {
          ...paramsOrderSeller?.filters,
          sellerIDs: [option?.seller?.id],
        },
      });
      setValueSelectOrder(undefined);
      setIsDisabledProduct(true);
    }
    if (formData?.items?.length > 0) {
      setVisiblePopupChangeSeller(true);
    }
    setOptionSellerMemory(option?.seller);
  };

  const handleClosePopupChangeSeller = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        sellerID: sellerInfo?.id,
      });
    }
    setVisiblePopupChangeSeller(false);
  };

  const [temporary, setTemporary] = useState(null);

  const handleOkPopupChangeOrder = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        items: [],
      });
    }
    setDataTableProduct([]);
    setVisiblePopupChangeOrder(false);
    setOptionSelectProduct(temporary);
  };

  const handleChangeInfoOrder = (value, option) => {
    const listProduct = option?.order?.items.map((item) => ({
      code: item?.product?.code,
      id: item?.product?.id,
      name: item?.product?.name,
      quantityOrder: item?.quantity,
      orderCode: option?.order?.number,
      orderID: option?.order?.id,
      uom: {
        id: item?.product?.uom?.id,
        name: item?.product?.uom?.name,
      },
    }));

    if (formData?.items?.length === 0) {
      setOrderInfo(option?.order);
      setIsDisabledProduct(false);
      setOptionSelectProduct(listProduct);
    }

    if (formData?.items?.length > 0) {
      setVisiblePopupChangeOrder(true);
    }
    setTemporary(listProduct);
  };

  const handleClosePopupChangeOrder = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        orderID: orderInfo?.orderID || orderInfo?.id,
      });
    }
    setVisiblePopupChangeOrder(false);
  };

  useEffect(() => {
    setParamsSellerMemory({
      filters: {
        query: sellerInfo?.sellername || sellerInfo?.fullName,
        isSeller: true,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  }, [JSON.stringify(sellerInfo)]);

  useEffect(() => {
    setParamsOrderMemory({
      filters: {
        query: orderInfo?.orderCode || orderInfo?.number,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  }, [orderInfo]);

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, []);

  return {
    form,
    valueImportWarehouse,
    sellers,
    dataTableProduct,
    handleGetProduct,
    handleFinish,
    initialValues,
    handleChangeInfoSeller,
    sellerInfo,
    loadMoreSeller,
    hasMoreSeller,
    onSearchSeller,
    history,
    loadingCreate,
    handleDeleteProduct,
    formData,
    setSumPrice,
    setCurrencyFirst,
    currencyFirst,
    sumPrice,
    handleCreateClick,
    handlePasswordKeyPress,
    handleKeyPress,
    handleGetOrder,
    optionSelectProduct,
    orders,
    orderInfo,
    onSearchOrder,
    hasMoreOrder,
    loadMoreOrder,
    loadingOrders,
    handleChangeTypeSalesReturn,
    typeSalesReturn,
    isDisabledSelectProduct,
    handleChangeInfoOrder,
    visiblePopupChangeOrder,
    handleClosePopupChangeOrder,
    handleOkPopupChangeOrder,
    paramsOrderSeller,
    setParamsOrderSeller,
    visiblePopupChangeSeller,
    handleClosePopupChangeSeller,
    handleOkPopupChangeSeller,
    valueSelectOrder,
    setValueSelectOrder,
    loadingSeller,
    sellerMemory,
    orderMemory,
  };
};

export default useCreate;
