import React from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";

const TagCustom = ({ label = "", filterText = "", handleOnClose }) => {
  return (
    <Tag closable onClose={handleOnClose}>
      <span className="label">{label}:</span>
      <span>&nbsp;{filterText}</span>
    </Tag>
  );
};

const TagsList = ({ listFilter, params, setParams }) => {
  const { t } = useTranslation();

  const renderIsCommonText = (value) => {
    if (value) {
      return t("common.yes");
    } else if (value === false) {
      return t("common.no");
    } else {
      return "";
    }
  };

  const renderIsActiveText = (value) => {
    if (value) {
      return t("common.active");
    } else if (value === false) {
      return t("common.inactive");
    } else {
      return "";
    }
  };

  const renderTagBoolean = (key, labelI18nKey, renderText) => {
    const handleCloseTag = (key) => {
      const cloneParams = { ...params };
      delete cloneParams.filters[`${key}`];
      setParams({ ...cloneParams });
    };

    if (listFilter.hasOwnProperty([`${key}`]) && listFilter[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={labelI18nKey ? t(labelI18nKey) : ""}
          filterText={renderText(listFilter[`${key}`])}
          handleOnClose={(e) => handleCloseTag(key)}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="tags-list">
      {/* apply all categories */}
      {renderTagBoolean("isCommon", "product.categoryAttribute.applyAllCategory", renderIsCommonText)}
      {/* status */}
      {renderTagBoolean("isActive", "common.status", renderIsActiveText)}
    </div>
  );
};

export default TagsList;
