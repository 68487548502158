import { Typography } from "antd";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { DATE_TIME_FORMAT3 } from "config/constants";
import { t } from "i18next";
import moment from "moment";
import { formatDateTime } from "utils/dateTime";

const DeliveryDeadline = ({ deliveryDeadline, strong = false }) => {
  const deadline = deliveryDeadline;
  if (!deadline) return <CustomTextHiddenLine />;

  const now = moment();
  const deadlineMoment = moment(deadline);
  const diffMinutes = deadlineMoment.diff(now, "minutes");

  let color = "#262F3B";
  let warningText = "";

  if (diffMinutes <= 0) {
    color = "#D2434D";
    warningText = "order.approvalOrder.outOfDate";
  } else if (diffMinutes <= 30) {
    color = "#EF9351";
    warningText = "order.approvalOrder.aboutToExpire";
  } else if (deadlineMoment.isSame(now, "day")) {
    color = "#EF9351";
  }

  return (
    <div>
      <Typography.Text strong={strong} style={{ color }}>
        {formatDateTime(deadline, DATE_TIME_FORMAT3)}
      </Typography.Text>
      <br />
      {warningText && (
        <Typography.Text strong={strong} style={{ color }}>
          ({t(warningText)})
        </Typography.Text>
      )}
    </div>
  );
};

export default DeliveryDeadline;
