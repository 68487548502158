import PotentialCustomerDetail from "../PotentialCustomerDetail";

function DetailMulti({ openDrawerMulti, onClose, handleOpenDrawerMulti, loading, data }) {
  return (
    <PotentialCustomerDetail
      visible={openDrawerMulti}
      onClose={onClose}
      loadingDetail={loading}
      dataDetail={data}
      handleOpenDrawerMulti={handleOpenDrawerMulti}
    />
  );
}

export default DetailMulti;
