import { Card, Col, Row, Space, Typography, message } from "antd";
import { useGetProducts, useGetProductsPriceDrop } from "hooks/product/product";
import ModalRecentlyDiscountedProduct from "../ModalRecentlyDiscountedProduct";
import { useState } from "react";
import { useGenerateLink } from "hooks/generateLink/query";
import moment from "moment";
import { DATE_ISO_8601_FORMAT } from "config/constants";
import copy from "copy-to-clipboard";

const gridStyle = {
  width: "fit-content",
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "10px",
};

const QUOTATION_FORM = {
  LINK: "LINK",
  INFO: "INFO",
};

const PRICE_DROP = "PRICE_DROP";

function PriceDrop() {
  const [quotationForm, setQuotationForm] = useState(QUOTATION_FORM.LINK);

  const [params, setParams] = useState({
    withCart: false,
    filters: {
      featureType: PRICE_DROP,
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const { data: productData, paginationData, loading: loadingData } = useGetProducts(params);
  const { handleGenerateLink, loading: loadingGenLink } = useGenerateLink();

  const {
    data: productDataNoLimit,
    loading: loadingDataNoLimit,
    handleGetProductsPriceProp: handleGetProductsPricePropNoLimit,
  } = useGetProductsPriceDrop();

  const paramsProductNoLimit = {
    withCart: false,
    filters: {
      featureType: PRICE_DROP,
    },
    pagination: {
      limit: paginationData?.total,
      offset: 0,
    },
  };

  const handleGetProductNoLimit = async () => {
    await handleGetProductsPricePropNoLimit(paramsProductNoLimit);
  };

  const handleGetQuotationForm = async (value) => {
    setQuotationForm(value);
    try {
      if (value === QUOTATION_FORM.INFO) {
        handleGetProductNoLimit();
      }
    } catch (error) {
      console.info(error);
    }
  };

  const handleCopyLink = async () => {
    try {
      const shopURL = process.env.REACT_APP_SHOP;
      await handleGenerateLink({
        url: `${shopURL}/products?feature_type=PRICE_DROP&time=${moment().format(DATE_ISO_8601_FORMAT)}`,
      }).then((res) => {
        const dynamicLayout = `DANH SÁCH SẢN PHẨM CÓ GIÁ TỐT GẦN ĐÂY
Xem tại: ${res?.data?.linkQuery?.generateDynamicLinkFromUrl}
${productDataNoLimit?.map((item) => `- ${item?.name}`).join("\n")}`;

        copy(dynamicLayout);

        message.success("Đã sao chép link vào bộ nhớ tạm");
      });
    } catch (error) {
      console.info(error);
    }
  };

  const loading = loadingData || loadingDataNoLimit;

  return (
    <Card.Grid style={gridStyle}>
      <Row gutter={[16, 8]} style={{ display: "flex", flexDirection: "column" }}>
        <Col>
          <Typography.Text strong>Sản phẩm giảm giá gần đây</Typography.Text>
        </Col>
        <Col>
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Link strong>{paginationData?.total || 0} sản phẩm</Typography.Link>
            <ModalRecentlyDiscountedProduct
              data={productData}
              paginationData={paginationData}
              setParams={setParams}
              params={params}
              productDataNoLimit={productDataNoLimit}
              loading={loading}
              quotationForm={quotationForm}
              handleGetQuotationForm={handleGetQuotationForm}
              handleCopyLink={handleCopyLink}
              loadingGenLink={loadingGenLink}
              handleGetProductNoLimit={handleGetProductNoLimit}
            />
          </Space>
        </Col>
      </Row>
    </Card.Grid>
  );
}

export default PriceDrop;
