import { t } from "i18next";

export const ORDER_STATUS = {
  WAITING_FOR_APPROVAL_ORDER: "WAITING_FOR_APPROVAL_ORDER",
  WAITING_FOR_APPROVAL_STOCK: "WAITING_FOR_APPROVAL_STOCK",
  WAITING_FOR_ENOUGH_STOCK: "WAITING_FOR_ENOUGH_STOCK",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  WAITING_FOR_PACKAGING: "WAITING_FOR_PACKAGING",
  WAITING_FOR_EXPORTING: "WAITING_FOR_EXPORTING",
  WAITING_FOR_ASSEMBLY: "WAITING_FOR_ASSEMBLY",
  WAITING_FOR_SHIPPING: "WAITING_FOR_SHIPPING",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const ASSIGN_UPDATE_ORDER_BY_STATUS = {
  [`${ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: true,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: true,
    changeDebtLimit: true,
    changeProductInCart: true,
    changePrice: true,
    changeCustomerPrice: true,
    changePaymentMethod: true,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: true,
  },
  [`${ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: true,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: true,
    changeDebtLimit: true,
    changeProductInCart: true,
    changePrice: true,
    changeCustomerPrice: true,
    changePaymentMethod: true,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: true,
  },
  [`${ORDER_STATUS.WAITING_FOR_PAYMENT}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: true,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: true,
    changeDebtLimit: true,
    changeProductInCart: true,
    changePrice: true,
    changeCustomerPrice: true,
    changePaymentMethod: true,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: true,
  },
  [`${ORDER_STATUS.WAITING_FOR_PACKAGING}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: true,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: true,
    changeDebtLimit: true,
    changeProductInCart: true,
    changePrice: true,
    changeCustomerPrice: true,
    changePaymentMethod: true,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: true,
  },
  [`${ORDER_STATUS.WAITING_FOR_EXPORTING}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: true,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: true,
    changeDebtLimit: true,
    changeProductInCart: true,
    changePrice: true,
    changeCustomerPrice: true,
    changePaymentMethod: true,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: true,
  },
  [`${ORDER_STATUS.WAITING_FOR_ASSEMBLY}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: false,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: false,
    changeDebtLimit: false,
    changeProductInCart: false,
    changePrice: false,
    changeCustomerPrice: true,
    changePaymentMethod: false,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: false,
  },
  [`${ORDER_STATUS.WAITING_FOR_SHIPPING}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: false,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: false,
    changeDebtLimit: false,
    changeProductInCart: false,
    changePrice: false,
    changeCustomerPrice: true,
    changePaymentMethod: false,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: false,
  },
  [`${ORDER_STATUS.DELIVERING}`]: {
    changeReceiver: true,
    changeDeliveryMethod: true,
    changeShippingFee: false,
    changeInfoExportVAT: true,
    changeWarranty: true,
    changeExportVAT: false,
    changeDebtLimit: false,
    changeProductInCart: false,
    changePrice: false,
    changeCustomerPrice: true,
    changePaymentMethod: false,
    changeNoteAndInternalNode: true,
    changeDiscountAmount: false,
  },
  [`${ORDER_STATUS.COMPLETED}`]: {
    changeReceiver: false,
    changeDeliveryMethod: false,
    changeShippingFee: false,
    changeInfoExportVAT: false,
    changeWarranty: false,
    changeExportVAT: false,
    changeDebtLimit: false,
    changeProductInCart: false,
    changePrice: false,
    changeCustomerPrice: false,
    changePaymentMethod: false,
    changeNoteAndInternalNode: false,
    changeDiscountAmount: false,
  },
  [`${ORDER_STATUS.CANCELED}`]: {
    changeReceiver: false,
    changeDeliveryMethod: false,
    changeShippingFee: false,
    changeInfoExportVAT: false,
    changeWarranty: false,
    changeExportVAT: false,
    changeDebtLimit: false,
    changeProductInCart: false,
    changePrice: false,
    changeCustomerPrice: false,
    changePaymentMethod: false,
    changeNoteAndInternalNode: false,
    changeDiscountAmount: false,
  },
};

//tồn kho khả dụng
export const RENDER_AVAILABLE = [`${ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK}`, `${ORDER_STATUS.WAITING_FOR_PAYMENT}`];

//tồn kho thực tế
export const RENDER_ACTUAL = [
  `${ORDER_STATUS.WAITING_FOR_ASSEMBLY}`,
  `${ORDER_STATUS.WAITING_FOR_SHIPPING}`,
  `${ORDER_STATUS.DELIVERING}`,
  `${ORDER_STATUS.COMPLETED}`,
  `${ORDER_STATUS.CANCELED}`,
];

//tồn kho khả dụng thực tế
export const RENDER_ACTUAL_AND_RENDER_AVAILABLE = [`${ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK}`];

export const EXPORT_ORDER_TYPE = {
  JPEG: "JPEG",
  PDF: "PDF",
  XLSX: "XLSX",
};

export const CANCEL_ORDER_REASON_OPTIONS = {
  LACK_OF_STOCK: "LACK_OF_STOCK",
  OUT_OF_DEBT: "OUT_OF_DEBT",
  OTHER: "OTHER",
};

export const STOCK_STATUS_TYPE = {
  FULL: "FULL",
  LACK: "LACK",
  OUT_OF_STOCK: "OUT_OF_STOCK",
};

export const ITEM_DATA_TYPE = { ORDER_ITEM: "ORDER_ITEM", COMBO_ORDER_ITEM: "COMBO_ORDER_ITEM" };

export const SHIPPING_PROVIDER_STATUS = {
  READY_TO_PICK: "READY_TO_PICK",
  PICKING: "PICKING",
  CANCEL: "CANCEL",
  MONEY_COLLECT_PICKING: "MONEY_COLLECT_PICKING",
  PICKED: "PICKED",
  STORING: "STORING",
  TRANSPORTING: "TRANSPORTING",
  SORTING: "SORTING",
  DELIVERING: "DELIVERING",
  MONEY_COLLECT_DELIVERING: "MONEY_COLLECT_DELIVERING",
  DELIVERED: "DELIVERED",
  DELIVERY_FAIL: "DELIVERY_FAIL",
  WAITING_TO_RETURN: "WAITING_TO_RETURN",
  RETURN: "RETURN",
  RETURN_TRANSPORTING: "RETURN_TRANSPORTING",
  RETURN_SORTING: "RETURN_SORTING",
  RETURNING: "RETURNING",
  RETURN_FAIL: "RETURN_FAIL",
  RETURNED: "RETURNED",
  EXCEPTION: "EXCEPTION",
  DAMAGE: "DAMAGE",
  LOST: "LOST",
};
export const shippingProviderLabel = {
  [`${SHIPPING_PROVIDER_STATUS.READY_TO_PICK}`]: t("order.SHIPPING_PROVIDER_STATUS.READY_TO_PICK"),
  [`${SHIPPING_PROVIDER_STATUS.PICKING}`]: t("order.SHIPPING_PROVIDER_STATUS.PICKING"),
  [`${SHIPPING_PROVIDER_STATUS.CANCEL}`]: t("order.SHIPPING_PROVIDER_STATUS.CANCEL"),
  [`${SHIPPING_PROVIDER_STATUS.MONEY_COLLECT_PICKING}`]: t("order.SHIPPING_PROVIDER_STATUS.MONEY_COLLECT_PICKING"),
  [`${SHIPPING_PROVIDER_STATUS.PICKED}`]: t("order.SHIPPING_PROVIDER_STATUS.PICKED"),
  [`${SHIPPING_PROVIDER_STATUS.STORING}`]: t("order.SHIPPING_PROVIDER_STATUS.STORING"),
  [`${SHIPPING_PROVIDER_STATUS.TRANSPORTING}`]: t("order.SHIPPING_PROVIDER_STATUS.TRANSPORTING"),
  [`${SHIPPING_PROVIDER_STATUS.SORTING}`]: t("order.SHIPPING_PROVIDER_STATUS.SORTING"),
  [`${SHIPPING_PROVIDER_STATUS.DELIVERING}`]: t("order.SHIPPING_PROVIDER_STATUS.DELIVERING"),
  [`${SHIPPING_PROVIDER_STATUS.MONEY_COLLECT_DELIVERING}`]: t(
    "order.SHIPPING_PROVIDER_STATUS.MONEY_COLLECT_DELIVERING"
  ),
  [`${SHIPPING_PROVIDER_STATUS.DELIVERED}`]: t("order.SHIPPING_PROVIDER_STATUS.DELIVERED"),
  [`${SHIPPING_PROVIDER_STATUS.DELIVERY_FAIL}`]: t("order.SHIPPING_PROVIDER_STATUS.DELIVERY_FAIL"),
  [`${SHIPPING_PROVIDER_STATUS.WAITING_TO_RETURN}`]: t("order.SHIPPING_PROVIDER_STATUS.WAITING_TO_RETURN"),
  [`${SHIPPING_PROVIDER_STATUS.RETURN}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURN"),
  [`${SHIPPING_PROVIDER_STATUS.RETURN_TRANSPORTING}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURN_TRANSPORTING"),
  [`${SHIPPING_PROVIDER_STATUS.RETURN_SORTING}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURN_SORTING"),
  [`${SHIPPING_PROVIDER_STATUS.RETURNING}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURNING"),
  [`${SHIPPING_PROVIDER_STATUS.RETURN_FAIL}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURN_FAIL"),
  [`${SHIPPING_PROVIDER_STATUS.RETURNED}`]: t("order.SHIPPING_PROVIDER_STATUS.RETURNED"),
  [`${SHIPPING_PROVIDER_STATUS.EXCEPTION}`]: t("order.SHIPPING_PROVIDER_STATUS.EXCEPTION"),
  [`${SHIPPING_PROVIDER_STATUS.DAMAGE}`]: t("order.SHIPPING_PROVIDER_STATUS.DAMAGE"),
  [`${SHIPPING_PROVIDER_STATUS.LOST}`]: t("order.SHIPPING_PROVIDER_STATUS.LOST"),
};

export const SHIPPING_VERSION = {
  V1: "V1",
  V2: "V2",
};

export const PAYMENT_METHOD = {
  COD: "COD",
  CASH: "CASH",
  DEBT: "DEBT",
};

export const renderStringPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.CASH:
      return t("order.orderList.cash");
    case PAYMENT_METHOD.DEBT:
      return t("order.orderList.debt");
    case PAYMENT_METHOD.COD:
      return t("order.orderList.cod");
    default:
      return t("order.orderList.cash");
  }
};

export const PAYMENT_STATUS = {
  PENDING: "PENDING",
  WAITING_APPROVAL: "WAITING_APPROVAL",
  PAID: "PAID",
};

export const renderStringPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.PENDING:
      return t("bank_transfer.order.pending");
    case PAYMENT_STATUS.WAITING_APPROVAL:
      return t("bank_transfer.order.waiting_approval");
    case PAYMENT_STATUS.PAID:
      return t("bank_transfer.order.paid");
    default:
      return "- -";
  }
};
