import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import ListProfile from "./ListProfile";
import ChangePassword from "./ChangePassWord";
import { FontAwesomeIcon, Icon } from "assets/icons";
import { t } from "i18next";

function useList() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY.activeKey || "listProfile");
  const [activeKeyStatus, setActiveKeyStatus] = useState(GET_QUERY.key);
  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    setActiveKeyStatus("listProfile");
    buildQueryString({
      params: activeKey === "listProfile" ? { activeKey: activeKey } : { activeKey: activeKey, key: "changePassword" },
    });
  };

  const handleChangeTabStatus = (value) => {
    setActiveKeyStatus(value);
  };

  const tabsMain = [
    {
      title: (
        <div>
          <FontAwesomeIcon icon={Icon.faUser} style={{ marginRight: "10px" }} />
          {t("account.infoAccount")}
        </div>
      ),
      children: <ListProfile />,
      key: "listProfile",
    },
    {
      title: (
        <div>
          <FontAwesomeIcon icon={Icon.faLock} style={{ marginRight: "10px" }} />
          {t("account.changePassword")}
        </div>
      ),
      children: <ChangePassword />,
      key: "changePassword",
    },
  ];

  useEffect(() => {
    buildQueryString({
      params: { activeKey: GET_QUERY.activeKey },
    });
  }, [GET_QUERY.activeKey]);

  return {
    tabsMain,
    handleChangeTab,
    handleChangeTabStatus,
    activeKeyMain,
    activeKeyStatus,
    GET_QUERY,
  };
}

export default useList;
