import { useEffect, useState } from "react";

function useComboCheckbox(options, defaultValues) {
  const defaultValueString = JSON.stringify(defaultValues);
  const [checkedList, setCheckedList] = useState(defaultValues);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    list && list.length !== 0 ? setCheckedList(list) : setCheckedList(undefined);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map((option) => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onResetFilter = () => {
    setCheckedList(undefined);
    setIndeterminate(false);
    setCheckAll(false);
  };

  useEffect(() => {
    setCheckedList(defaultValues);
    setIndeterminate(!!defaultValues?.length && defaultValues?.length < options?.length);
    setCheckAll(defaultValues?.length === options?.length);
  }, [defaultValueString]);

  return {
    checkedList,
    indeterminate,
    checkAll,
    options,
    onChange,
    onCheckAllChange,
    onResetFilter,
  };
}

export default useComboCheckbox;
