/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { DATE_ISO_8601_FORMAT } from "config/constants";
import { WARRANTY_STATUS } from "features/Warranty/constant";
import { useGetBranchQuery } from "hooks/branch";
import { useGetWarrantyRequestCreate, useGetWarrantyTablePending } from "hooks/warranty";
import { t } from "i18next";
import { includes } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { notify } from "utils/helperFuncs";
import { v4 as uuidv4 } from "uuid";
import { WARRANTY_PRODUCT_TYPE } from "../constant";

function useCreate() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [activeSerial, setActiveSerial] = useState();
  let defaultSerials = [];
  let defaultWarrantyRequestItems = [];

  if (location.state?.ownedSerials) {
    defaultSerials = location.state?.ownedSerials || [];
  } else {
    const notOwnedSerials = location.state?.notOwnedSerials || [];
    defaultWarrantyRequestItems = notOwnedSerials;
    defaultSerials = defaultWarrantyRequestItems?.map((item) => item.serialImei);
  }

  const [serials, setSerials] = useState([...defaultSerials]);

  const [warrantyRequestItems, setWarrantyRequestItems] = useState(defaultWarrantyRequestItems);
  const history = useHistory();
  const { handleCreateWarrantyRequest, loading } = useGetWarrantyRequestCreate();
  const { data: branchQuery } = useGetBranchQuery();
  const initCustomer = warrantyRequestItems?.[0];

  const initialValues = {
    warrantyCustomerName: initCustomer?.seller?.fullName || initCustomer?.customerName || "",
    warrantyCustomerPhone: initCustomer?.seller?.telephone,
    warrantyCustomerAddress: initCustomer?.seller?.address,
    warrantyReceiveMethod: null,
    branchID: branchQuery?.[0]?.value,
  };
  const createWarrantyRequest = async () => {
    const values = await form.validateFields();
    const newData = {
      request: {
        warrantyCustomerName: values?.warrantyCustomerName,
        warrantyCustomerPhone: values?.warrantyCustomerPhone,
        warrantyCustomerAddress: values?.warrantyCustomerAddress,
        warrantyReceiveMethod: values?.warrantyReceiveMethod,
        note: values?.note,
        branchID: values?.branchID,
        items: warrantyRequestItems?.map((item) => {
          return {
            warrantyNoteID: includes(item?.id, "-") ? null : item?.id,
            serialImei: item?.serialImei,
            categoryID: item?.categoryID,
            productID: item?.productID || item?.product?.id,
            productName: item?.productName || item?.product?.name,
            warrantyProductType: item?.warrantyProductType,
            warrantyRequestType: item?.warrantyRequestType,
            accessoryAttachment: item?.accessoryAttachment,
            itemAmount: item?.itemAmount,
            customerRequest: item?.customerRequest,
            defectDescription: item?.defectDescription,
            promisedReturnDate: formatDateTime(moment(item?.promisedReturnDate), DATE_ISO_8601_FORMAT),
            imageURLs: item?.imageURLs,
          };
        }),
      },
    };

    await handleCreateWarrantyRequest(newData)
      .then(() => {
        notify.success({ message: t("notiActions.create") });
        form.resetFields();
        history.push("/warranty-request");
      })
      .catch((error) => {
        notify.error({
          message: t("common.error"),
          description: t("warrantyRequest.create.checkInfo"),
        });
      });
  };

  const handleCancel = () => {
    history.push("/warranty-list");
  };

  const serialToWarrantyRequestItem = warrantyRequestItems?.reduce((agg, current) => {
    agg[current?.serialImei] = current;
    return agg;
  }, {});

  const fetchWarrantyNoteParams = {
    filters: {
      query: null,
      status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
      serials: serials,
    },
    pagination: {
      offset: 0,
      limit: serials?.length,
    },
    sort: [],
  };

  const {
    data: warrantyNotes,
    loading: pendingWarrantyNoteLoading,
    refetch: refetchWarrantyNotes,
  } = useGetWarrantyTablePending(fetchWarrantyNoteParams);
  const existedSerials = warrantyRequestItems?.map((item) => item?.serialImei);

  function handleRemoveSerial(id) {
    const filteredSerials = warrantyRequestItems.filter((item) => item.id !== id);
    setWarrantyRequestItems(filteredSerials);
  }
  function handleSubmitWarrantyRequestItem(values) {
    if (!values.id) {
      // append new uuid
      const newValues = {
        ...values,
        id: uuidv4(),
        warrantyProductType: WARRANTY_PRODUCT_TYPE.NOT_OWNER,
      };
      setSerials([...serials, values?.serialImei]);
      setWarrantyRequestItems([...warrantyRequestItems, newValues]);
    } else {
      const newItems = warrantyRequestItems.map((item) => {
        if (item.id === values.id) {
          return { ...item, ...values };
        }
        return item;
      });
      setWarrantyRequestItems(newItems);
    }
  }

  useEffect(() => {
    if (existedSerials?.length > 0 && (!activeSerial || !existedSerials?.includes(activeSerial))) {
      // default to first serial
      setActiveSerial(existedSerials[0]);
    }
  }, [JSON.stringify(existedSerials)]);

  // fetch warranty notes if list of serials changed
  useEffect(() => {
    refetchWarrantyNotes(fetchWarrantyNoteParams);
  }, [JSON.stringify(fetchWarrantyNoteParams)]);

  // when warranty notes updated then update warranty request items
  useEffect(() => {
    // merge fields
    const serverSerialToWarrantyNote = warrantyNotes.reduce((agg, curr) => {
      agg[curr?.serialImei] = curr;
      return agg;
    }, {});

    const newItems = serials?.map((serialItem) => {
      return {
        id: uuidv4(),
        serialImei: serialItem,
        ...(serialToWarrantyRequestItem[serialItem] || {}),
        ...(serverSerialToWarrantyNote[serialItem] || {}),
      };
    });

    setWarrantyRequestItems(
      location?.state?.ownedSelected || location?.state?.ownedSerials || location?.state?.notOwnedSerials
    );
  }, [JSON.stringify(serials), JSON.stringify(warrantyNotes)]);

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [JSON.stringify(initialValues)]);

  return {
    form,
    initialValues,
    createWarrantyRequest,
    loading,
    branchQuery,
    handleCancel,
    pendingWarrantyNoteLoading,
    handleRemoveSerial,
    handleSubmitWarrantyRequestItem,
    setSerials,
    serials,
    warrantyRequestItems,
    setWarrantyRequestItems,
    setActiveSerial,
    activeSerial,
    serialToWarrantyRequestItem,
  };
}

export default useCreate;
