import CreateButton from "components/CreateButton";
import { EXPORT_CART_TYPE } from "config/constants";
import { useExportOrder, usePackageOrder } from "hooks/order";
import printJS from "print-js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import CustomModalWaitingPackaging from "../CustomModalWaitingPackaging";

const WaitingPackagingButtons = ({ checkedKeyList, resetCheckedList }) => {
  const { t } = useTranslation();
  const { handleExportOrder } = useExportOrder();
  const [openModalWaitingPackaging, setOpenModalWaitingPackaging] = useState(false);

  const handleWaitingPackagingPrint = async () => {
    if (checkedKeyList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      try {
        await handleExportOrder({
          orderIDs: checkedKeyList,
          fileType: EXPORT_CART_TYPE.PDF,
        }).then((res) => {
          const fileUrl = res?.data?.order?.exportFile?.url;
          printJS({
            printable: fileUrl,
            type: EXPORT_CART_TYPE.PDF.toLowerCase(),
            showModal: true,
            modalMessage: t("common.loadingData"),
            onPrintDialogClose: () => setOpenModalWaitingPackaging(true),
          });
          // notify.success({
          //   message: t('order.orderList.packageSuccess'),
          // });
          // resetCheckedList && resetCheckedList();
        });
      } catch (err) {
        notify.error({
          message: t("order.orderList.packageError"),
        });
      }
    }
  };

  return (
    <>
      <CreateButton title={t("order.orderDetail.printAndPackage")} onClick={handleWaitingPackagingPrint} />
      {openModalWaitingPackaging && (
        <CustomModalWaitingPackaging
          setOpenModalWaitingPackaging={setOpenModalWaitingPackaging}
          orderIDs={checkedKeyList}
          isScreenListWaitingPackaging={true}
          resetCheckedList={resetCheckedList}
        />
      )}
    </>
  );
};

export default WaitingPackagingButtons;
