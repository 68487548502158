import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import {
  PURCHASE_TABS,
  PURCHASE_TYPE_OPTIONS,
  proposedPurchaseStatusOptions,
  purchaseStatusOptions,
} from "features/Purchase/constant";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";

import { getQuery } from "utils/helperFuncs";

export function useToolbar({
  params,
  countSelectedRowKeys,
  onChangeParams,
  optionsSeller,
  optionsStaff,
  warehouseOptions,
}) {
  const GET_QUERY = getQuery();
  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const handleChangeDate = (value) => {
    const { from, to } = value;
    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
  };

  const handleOnClose = (key) => {
    switch (key) {
      case "isActive":
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
      case "timeRange":
        onChangeParams && onChangeParams({ [`${key}`]: {} });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };
  const allTag = [
    {
      title: t("purchase.table.recommendedSupplier"),
      options: optionsSeller,
      selected: params?.filters?.supplierIDs,
      key: "supplierIDs",
    },
    {
      title: t("purchase.table.supplier"),
      options: optionsSeller,
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
    },
    ...(GET_QUERY.key === "ALL" || GET_QUERY.activeKey === PURCHASE_TABS.PROPOSED_PURCHASE
      ? [
          {
            title: t("purchase.table.status"),
            options: [...purchaseStatusOptions, ...proposedPurchaseStatusOptions],
            selected: params?.filters?.statuses,
            key: "statuses",
          },
        ]
      : []),
    {
      title: t("purchase.table.wareHouseImport"),
      options: warehouseOptions,
      selected: params?.filters?.warehouseIDs,
      key: "warehouseIDs",
    },
    {
      title: t("purchase.table.recommendedType"),
      options: PURCHASE_TYPE_OPTIONS,
      selected: params?.filters?.types,
      key: "types",
    },
    {
      title: t("purchase.table.recommendedType"),
      options: PURCHASE_TYPE_OPTIONS,
      selected: params?.filters?.typesProposed,
      key: "typesProposed",
    },
    {
      title: t("purchase.table.createdBy"),
      options: optionsStaff,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
  ];

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => truncateAddDots(item?.label, 50)).join(", ")}
      </div>
    );
  };

  const renderTags = allTag
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return {
    filterItemsTypeOptions,
    handleChangeDate,
    renderDateTag,
    renderTags,
  };
}
