import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Input, Skeleton, Space } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import { t } from "i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import useDebounce from "hooks/useDebounce";
import MenuLayout from "../MenuLayout";
import "./index.scss";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";

const MENU_LAYOUT = {
  width: "300px",
};
const SPACE_LAYOUT = {
  display: "flex",
  flexDirection: "column",
};

export const FilterLoadMore = ({
  onFilter,
  data,
  onLoadMoreData,
  onSearchTextChange,
  hasMore = true,
  params,
  loading,
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const valueDebounce = useDebounce(searchValue?.trim());
  const handleFilter = () => {
    onFilter && onFilter(checkedList);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChecked = (e) => {
    if (e.target.checked) {
      setCheckedList((prev) => [...prev, e.target.value]);
    } else {
      setCheckedList((prev) => prev.filter((item) => item !== e.target.value));
    }
  };
  const handleResetFilter = () => {
    setCheckedList([]);
    setSearchValue("");
  };

  useEffect(() => {
    onSearchTextChange && onSearchTextChange(valueDebounce?.trim());
  }, [valueDebounce]);

  useEffect(() => {
    onLoadMoreData();
  }, []);

  return (
    <MenuLayout
      style={MENU_LAYOUT}
      applyFilter={handleFilter}
      resetFilter={handleResetFilter}
      filterContent={
        <div className="custom-content-filter">
          <Input
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t("common.search")}
            allowClear={true}
            value={searchValue}
            onChange={handleChange}
          />
          <Checkbox.Group value={checkedList}>
            <InfiniteScroll
              dataLength={data?.length || 0}
              next={onLoadMoreData}
              hasMore={hasMore}
              style={SPACE_LAYOUT}
              height={200}
              loader={
                <Skeleton
                  paragraph={{
                    rows: 1,
                  }}
                  active
                />
              }
            >
              <Skeleton loading={loading} active>
                <Space direction="vertical">
                  {data?.map((item) => (
                    <Checkbox key={item?.value} value={item?.value} onChange={handleChecked}>
                      <CustomTextHiddenLine line={2} text={item?.label} />
                    </Checkbox>
                  ))}
                </Space>
              </Skeleton>
            </InfiniteScroll>
          </Checkbox.Group>
        </div>
      }
    />
  );
};

export default FilterLoadMore;
