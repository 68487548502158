import React from "react";
import { Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { t } from "i18next";

function SelectStaffs({ staffs, onLoadMoreData, hasMoreData, onSearch, handleChange }) {
  return (
    <SelectLoadMore
      {...{
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.popup.selectStaff")}</Typography.Text>,
          name: "technicalStaffID",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          options: staffs,
          allowClear: false,
          placeholder: t("warrantyRequest.popup.selectStaffplc"),
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectStaffs;
