import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Card, Checkbox, Col, Row, Tag, Tooltip, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import { TitleCard } from "../TitleCard";
import { IMPORT_REQUEST_TYPE, RECOMMEND_IMPORT, SALES_RETURN } from "features/Purchase/constant";
import PopupViewAllSerial from "features/Purchase/SalesReturn/components/PopupViewAllSerial";

function TableProductList({ selectedRow, GET_QUERY }) {
  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      align: "center",
      key: "codeorder",
      title: <span>{t("serialImportEdit.codeOrder")}</span>,
      render: (_, record, index) => (
        <Link to={`/order/${record?.order?.id}`} target="_blank">
          <Typography.Link strong>{record?.order?.number || "- -"}</Typography.Link>
        </Link>
      ),
      width: 200,
      fixed: "left",
    },
    {
      title: t("warehouse.recommendImport.PARAMETER"),
      align: "left",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link strong>
            <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
              <Typography.Link strong>{record?.product?.code}</Typography.Link>
            </Link>
          </Typography.Link>
          <Typography.Text strong ellipsis={{ tooltip: true }}>
            {record?.product?.name || record?.productName}
          </Typography.Text>
        </div>
      ),
      width: 350,
      fixed: "left",
    },
    {
      align: "center",
      key: "quantityOrder",
      title: <span>{t("warehouse.salesReturn.quantityOrder")}</span>,
      render: (_, record, index) => {
        const quantityOrder = record?.order?.items?.find((i) => i?.product?.id === record?.product?.id)?.quantity;
        return quantityOrder;
      },
    },
    {
      align: "center",
      title:
        selectedRow?.type === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY
          ? t("warehouse.recommendImport.quantity")
          : t("warehouse.salesReturn.quantityReturn"),
      dataIndex: "quantity",
      render: (_, record) => record?.quantity,
    },
    {
      align: "center",
      title: <span>{t("warehouse.salesReturn.PRICERETURN")}</span>,
      dataIndex: "price",
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === "VND" ? formatCurrency(record?.price) : formatCurrencyDollar(record?.price)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: <span>{t("warehouse.recommendImport.money")}</span>,
      render: (_, record, index) =>
        record?.currency === "VND"
          ? formatCurrency(record?.price * record?.quantity)
          : formatCurrencyDollar(record?.price * record?.quantity),
    },
    {
      align: "center",
      title: "VAT",
      render: (_, record) => <Checkbox checked={record?.isVat} />,
    },
    {
      align: "center",
      title: t("warehouse.salesReturn.reasonReturn"),
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip placement="top" title={record?.importReason}>
            <Typography.Text className="text-hidden-two">{record?.importReason || "- -"}</Typography.Text>
          </Tooltip>
        </div>
      ),
    },
    {
      align: "left",
      width: "20%",
      title: t("warehouse.salesReturn.serialImeiDetail"),
      render: (_, record, index) => (
        <>
          {record?.serials?.slice(0, 2)?.map((item, index) => {
            return (
              <Tag className="input-tag-tag custom-tags-by-serial" key={index}>
                {item?.code}
              </Tag>
            );
          })}
          {record?.serials?.length > 2 && (
            <PopupViewAllSerial
              listSerial={record?.serials}
              components={
                <Tag className="input-tag-tag custom-tags-by-serial" key={index}>
                  <Typography.Link italic>+{record?.serials?.length - 2} serial...</Typography.Link>
                </Tag>
              }
            />
          )}
        </>
      ),
    },
  ];
  const isRejected = GET_QUERY?.statuses?.join("");

  let sumPrice = 0;
  for (let i = 0; i < selectedRow?.items?.length; i++) {
    const { quantity, price } = selectedRow?.items[i];
    sumPrice += quantity * price;
  }
  const filteredColumns =
    selectedRow?.type === IMPORT_REQUEST_TYPE.REFUND
      ? columns.filter((column) => column.key !== "codeorder")
      : columns.filter((column) => column.key !== "quantityOrder");
  return (
    <div className="header-card-table-list-product">
      <Col style={{ marginTop: "16px" }} span={24}>
        <Card
          title={
            <div style={{ display: "flex" }}>
              <TitleCard title={t("warehouse.recommendImport.listproductby")} />
              <Typography.Link strong>{selectedRow.code}</Typography.Link>
            </div>
          }
        >
          {selectedRow?.status === SALES_RETURN.WAITING_APPROVAL && selectedRow?.waitingApprovalCount >= 2 && (
            <Alert
              message={
                <Row>
                  <Typography.Text strong>
                    <Col>{t("warehouse.salesReturn.rejectTech")}: </Col>
                  </Typography.Text>
                  <Col>
                    <Typography.Text style={{ marginLeft: "4px" }}>{selectedRow?.rejectedReason}</Typography.Text>
                  </Col>
                </Row>
              }
              icon={<ExclamationCircleOutlined />}
              type="error"
              showIcon
              style={{ width: "100%", margin: "-10px 0 15px 0" }}
            />
          )}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              {isRejected === RECOMMEND_IMPORT?.REJECTED && (
                <Alert
                  message={
                    <Row>
                      <Typography.Text strong>
                        <Col>{t("warehouse.salesReturn.reasonSalesReturn")}</Col>
                      </Typography.Text>
                      <Col>
                        <Typography.Text style={{ marginLeft: "4px" }}>{selectedRow?.rejectedReason}</Typography.Text>
                      </Col>
                    </Row>
                  }
                  icon={<ExclamationCircleOutlined />}
                  type="error"
                  showIcon
                  style={{ width: "100%", margin: "-10px 0 15px 0" }}
                />
              )}

              <CustomTable columns={filteredColumns} dataSource={selectedRow?.items} />
              <div style={{ marginTop: "10px" }}>
                <Typography.Link strong>
                  {t("warehouse.recommendImport.sumByMoney")}:{" "}
                  {selectedRow?.items && selectedRow?.items[0]?.currency === "VND"
                    ? formatCurrency(sumPrice)
                    : formatCurrencyDollar(sumPrice)}
                </Typography.Link>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
}

export default TableProductList;
