import LoadingComponent from "components/LoadingComponent";
import PageHeader from "components/PageHeader";
import { STATUS_ORDER } from "config/constants";
import { useGetOrders, useOrderPermissions } from "hooks/order";
import { useGetUserPermissions } from "hooks/user/user";
import Page403 from "pages/PageError/403";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import OrderTable from "./components/OrderTable";
import StatusTabs from "./components/StatusTabs";

const OrderList = () => {
  const GET_QUERY = getQuery();
  const { t } = useTranslation();
  const [statusOrder, setStatusOrder] = useState(GET_QUERY.activeKey);

  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { canViewOrder } = useOrderPermissions();
  const { loading } = useGetOrders();

  const handleChange = (data) => {
    setStatusOrder(data.value);

    const newParams = {
      offset: 0,
      limit: 10,
      statuses: data.value !== STATUS_ORDER.ALL ? [data.value] : [],
    };

    buildQueryString({
      params: {
        ...newParams,
        activeKey: data?.value,
      },
    });
  };

  const isLoading = loading || loadUserPermissions;

  useEffect(() => {
    if (!GET_QUERY.activeKey) {
      setStatusOrder("ALL");
      buildQueryString({
        params: {
          activeKey: "ALL",
        },
      });
    }
  }, [GET_QUERY.activeKey]);

  return (
    <LoadingComponent loading={isLoading}>
      {canViewOrder ? (
        <div>
          <PageHeader pageTitle={t("order.orderList.pageTitle")} />
          <StatusTabs statusOrder={statusOrder} onChange={handleChange} />
          <OrderTable />
        </div>
      ) : (
        <Page403 />
      )}
    </LoadingComponent>
  );
};

export default OrderList;
