import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space, Typography } from "antd";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import ComboGiftForm from "../components/ComboGiftForm";

function GiftForm({
  dataTiersServer,
  form,
  handleRemoveValueComboSelected,
  isUpdate,
  promotionCampaignTiers,
  initFormGift,
  onGetDataGift,
}) {
  return (
    <Card
      title={
        <Typography.Title level={5} style={{ textTransform: "uppercase" }}>
          {t("campaign.combo.comboGift")}
        </Typography.Title>
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Typography.Text italic className="title-main">
          {t("campaign.combo.noteComboGift")}
        </Typography.Text>
        <Form.List name={"promotionCampaignTierGroupsGift"}>
          {(fields, { add, remove }) => {
            const handleAddCollapse = () => {
              add(initFormGift);
            };
            return (
              <Row gutter={[16, 16]}>
                {fields.map((field, index) => (
                  <Col span={24} key={field.key}>
                    <ComboGiftForm
                      form={form}
                      indexComboGift={index}
                      remove={() => remove(field.name)}
                      onRemoveValueComboSelected={handleRemoveValueComboSelected}
                      isUpdate={isUpdate}
                      promotionCampaignTiers={promotionCampaignTiers}
                      dataTiersServer={dataTiersServer}
                      onGetDataGift={onGetDataGift}
                    />
                  </Col>
                ))}
                <Col span={24}>
                  <Typography.Link underline onClick={() => handleAddCollapse()}>
                    <PlusOutlined /> {t("campaign.combo.addComboGift")}
                  </Typography.Link>
                </Col>
              </Row>
            );
          }}
        </Form.List>
      </Space>
    </Card>
  );
}

export default GiftForm;
