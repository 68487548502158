import { Col, Row, Typography } from "antd";
import CustomTable from "components/CustomTable";
import Card from "features/Cart/components/Card";
import { IMPORT_TYPE, convertTotalNumber } from "features/Serial/constant";
import { t } from "i18next";
import { sum } from "lodash";
import styles from "../index.module.scss";
import { useTableFirstTimeImport } from "./useTableFirstTimeImport";
import { useTableReImportWarranty } from "./useTableReImportWarranty";
import { useTableReturn } from "./useTableReturn";
import { useTableTransferSerial } from "./useTableTransferSerial";

function TableDetail({ data, handleSearchSerials, tagsValueResult, visibleTags, visibleSearchResult, searchResult }) {
  const { columns: columnsFirstTimeImport } = useTableFirstTimeImport({
    data,
    handleSearchSerials,
    tagsValueResult,
    visibleTags,
    visibleSearchResult,
    searchResult,
  });

  const { columns: columnsReImportWarranty } = useTableReImportWarranty();

  const { columns: columnsReturn } = useTableReturn({
    data,
    handleSearchSerials,
    tagsValueResult,
    visibleTags,
    visibleSearchResult,
    searchResult,
  });

  const { columns: columnsTransferSerial } = useTableTransferSerial({
    data,
    handleSearchSerials,
    tagsValueResult,
    visibleTags,
    visibleSearchResult,
    searchResult,
  });

  const renderSubTotalFooter = (a, b) => {
    if (a > b) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "red" }}>
            : {a - b}
          </Col>
        </>
      );
    }
    if (a === b) {
      return (
        <>
          <Col span={12}>
            <Typography.Text strong className={styles["summary"]}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ color: "#00AB78" }}>: {t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (a < b) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}:
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "#ef9351" }}>
            : {b - a}
          </Col>
        </>
      );
    }
  };

  const renderFooterTable = (importType) => {
    switch (importType) {
      case IMPORT_TYPE.FIRST_TIME_IMPORT:
        return (
          <Row gutter={[]} className={styles["summary"]}>
            <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantityKindOfProduct)}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={12}>: {convertTotalNumber(sum(data?.items?.map((item) => item.quantity)))}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantitySerial)}</Col>

            {renderSubTotalFooter(data?.quantitySerial, sum(data?.items?.map((item) => item.quantity)))}
          </Row>
        );
      case IMPORT_TYPE.RETURN:
        return (
          <Row gutter={[]} className={styles["summary"]}>
            <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantityKindOfProduct)}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={12}>: {convertTotalNumber(sum(data?.items?.map((item) => item.quantity)))}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialReturn")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantitySerial)}</Col>
            {renderSubTotalFooter(data?.quantitySerial, sum(data?.items?.map((item) => item.quantity)))}
          </Row>
        );
      case IMPORT_TYPE.RE_IMPORT_WARRANTY:
        return (
          <Row gutter={[]} className={styles["summary"]}>
            <Col span={12}>{t("serialIE.productInfo.sumSerial")}</Col>
            <Col span={12}>: {data?.quantityKindOfProduct}</Col>
          </Row>
        );
      case IMPORT_TYPE.TRANSFER_SERIAL:
        return (
          <Row gutter={[]} className={styles["summary"]}>
            <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantityKindOfProduct)}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={12}>: {convertTotalNumber(sum(data?.items?.map((item) => item.quantity)))}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantitySerial)}</Col>
            {renderSubTotalFooter(data?.quantitySerial, sum(data?.items?.map((item) => item.quantity)))}
          </Row>
        );
      case IMPORT_TYPE.OTHER:
        return (
          <Row gutter={[]} className={styles["summary"]}>
            <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantityKindOfProduct)}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={12}>: {convertTotalNumber(sum(data?.items?.map((item) => item.quantity)))}</Col>
            <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={12}>: {convertTotalNumber(data?.quantitySerial)}</Col>

            {renderSubTotalFooter(data?.quantitySerial, sum(data?.items?.map((item) => item.quantity)))}
          </Row>
        );
      default:
        break;
    }
  };

  const renderColumns = {
    [IMPORT_TYPE.FIRST_TIME_IMPORT]: columnsFirstTimeImport,
    [IMPORT_TYPE.RETURN]: columnsReturn,
    [IMPORT_TYPE.RE_IMPORT_WARRANTY]: columnsReImportWarranty,
    [IMPORT_TYPE.TRANSFER_SERIAL]: columnsTransferSerial,
    [IMPORT_TYPE.OTHER]: columnsTransferSerial,
  };

  return (
    <Col span={24} style={{ marginTop: "16px" }}>
      <Card
        title={
          <Typography.Text style={{ fontSize: "16px", fontWeight: 600 }}>
            {t("serialIE.productInfo.title")}
          </Typography.Text>
        }
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <CustomTable columns={renderColumns[data?.type]} dataSource={data?.items} rowKey={(record) => record.id} />
          </Col>
          <Col span={24} xl={18}></Col>
          <Col span={24} xl={6}>
            {renderFooterTable(data?.type)}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default TableDetail;
