import React from "react";
import CustomModal from "components/CustomModal";
import "./index.scss";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import { useApproveStock, useDenyStock, useWaitingStock } from "hooks/order";
import { WARNING_MODAL_ACTION } from "pages/App/Order/conts";
import LoadingComponent from "components/LoadingComponent";
import { SvgIcon } from "assets/icons";

const ValidateMessage = ({ message }) => {
  return (
    <div className="validate-message">
      <WarningOutlined className="warning-icon" />
      {message}
    </div>
  );
};

function CustomModalWarning({ orderIDs, openModalWarning, setOpenModalWarning, action, resetCheckedList }) {
  const { t } = useTranslation();
  const { handleApproveStock, loading: loadingApproveStock } = useApproveStock();
  const { handleWaitingStock, loading: loadingWaitingStock } = useWaitingStock();
  const { handleDenyStock, loading: loadingDenyStock } = useDenyStock();

  const handleApprove = async () => {
    await handleApproveStock({
      orderIDs: orderIDs,
    })
      .then(() => {
        notify.success({
          message: t("order.orderList.approvedEnoughStockSuccess"),
        });
        resetCheckedList && resetCheckedList();

        setOpenModalWarning(false);
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.approvedEnoughStockError"),
          description: error.message,
        });
      });
  };

  const handleWaiting = async () => {
    await handleWaitingStock({
      orderIDs: orderIDs,
    })
      .then(() => {
        notify.success({
          message: t("order.orderList.waitingForStockSuccess"),
        });
        resetCheckedList && resetCheckedList();

        setOpenModalWarning(false);
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.waitingForStockError"),
          description: error.message,
        });
      });
  };

  const handleDeny = async () => {
    await handleDenyStock({
      orderIDs: orderIDs,
    })
      .then(() => {
        notify.success({
          message: t("order.orderList.denyStockSuccess"),
        });
        resetCheckedList && resetCheckedList();

        setOpenModalWarning(false);
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.denyStockError"),
          description: error.message,
        });
      });
  };

  const propertyModalByAction = {
    [`${WARNING_MODAL_ACTION.APPROVAL_STOCK_LIST}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.approvalStockList")} />,
      action: handleApprove,
      hideConfirmButton: true,
    },
    [`${WARNING_MODAL_ACTION.APPROVAL_STOCK_DETAIL}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.approvalStockDetail")} />,
      action: handleApprove,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.WAITING_STOCK_LIST}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.waitingStockList")} />,
      action: handleWaiting,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.WAITING_STOCK_DETAIL}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.waitingStockDetail")} />,
      action: handleWaiting,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.DENY_STOCK_LIST}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.denyStockList")} />,
      action: handleDeny,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.DENY_STOCK_DETAIL}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.denyStockDetail")} />,
      action: handleDeny,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.CONFIRM_STOCK_LIST}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.confirmStockList")} />,
      action: handleApprove,
      hideConfirmButton: false,
    },
    [`${WARNING_MODAL_ACTION.CONFIRM_STOCK_DETAIL}`]: {
      message: <ValidateMessage message={t("order.changeOrderStatusWarning.confirmStockDetail")} />,
      action: handleApprove,
      hideConfirmButton: false,
    },
  };

  const handleCancel = () => {
    setOpenModalWarning(false);
  };

  const isLoading = loadingApproveStock || loadingWaitingStock || loadingDenyStock;

  return (
    <LoadingComponent loading={isLoading}>
      <CustomModal
        closable={false}
        centered={true}
        footer={false}
        isBlockCloseOnOke
        switchClose={openModalWarning}
        selfVisibleControlled={false}
        onOke={propertyModalByAction[`${action}`]?.action}
        onCancel={handleCancel}
        hideConfirmButton={propertyModalByAction[`${action}`]?.hideConfirmButton}
      >
        <SvgIcon.WarningFilledIcon className="modal-warning-icon" />

        <div className="cart-validations">{propertyModalByAction[`${action}`]?.message}</div>
      </CustomModal>
    </LoadingComponent>
  );
}

export default CustomModalWarning;
