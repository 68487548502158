import { Button, Col, Form, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { SHIPPING_TYPE } from "config/constants";
import { t } from "i18next";
import ShippingType from "./ShippingType";
import usePopupSelectShipper from "./usePopupSelectShipper";

function PopupSelectShipper({ title, props, itemIDs }) {
  const { confirmReturn, shippingType, loading, record } = props;
  const { form, handleOk, isModalOpen, showModal, handleCancel, loadingExport } = usePopupSelectShipper({
    confirmReturn,
    record,
    shippingType,
    itemIDs,
  });
  return (
    <CustomModal
      {...{
        title: (
          <Typography.Text strong style={{ fontSize: "20px" }}>
            {shippingType === SHIPPING_TYPE.DELIVER_3PARTY
              ? t("warrantyRequest.popup.selectShipper")
              : t("warrantyRequest.popup.infoBus")}
          </Typography.Text>
        ),
        width: "40%",
        footer: false,
        customComponent: title,
        styleButton: "notButton",
        hideConfirmButton: true,
        visible: isModalOpen,
        onOpen: showModal,
        onCancel: handleCancel,
        addMoreButton: (
          <>
            <Button type="primary" onClick={handleOk} loading={loading}>
              {t("common.confirm")}
            </Button>
            <Button type="primary" onClick={() => handleOk({ isPrint: true })} loading={loadingExport}>
              {t("common.acceptAprint")}
            </Button>
          </>
        ),
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24} style={{ padding: "15px" }}>
            <Typography.Text strong>
              {shippingType === SHIPPING_TYPE.BUS
                ? t("warrantyRequest.popup.subTitleShipper")
                : t("warrantyRequest.popup.subTitleTH3D")}
            </Typography.Text>
          </Col>
          <ShippingType shippingType={shippingType} />
        </Row>
      </Form>
    </CustomModal>
  );
}

export default PopupSelectShipper;
