import React from "react";
import { t } from "i18next";

import CustomTags from "components/CustomTags";
import { useGetCategoriesOptions } from "hooks/campaign/campaign";

export function useToolbar({ params, onChangeParams }) {
  const { data: dataAllCategories } = useGetCategoriesOptions();

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const tags = [
    {
      title: t("campaign.tags.categorys"),
      options: dataAllCategories,
      selected: params?.filters?.categoriesIDs,
      key: "categoriesIDs",
    },
  ];

  const handleOnClose = (key) => {
    onChangeParams && onChangeParams({ [`${key}`]: [] });
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return { renderTags };
}
