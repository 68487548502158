import { Col, Row, Space, Spin, Typography } from "antd";
import CustomAffix from "components/CustomAffix";
import PageHeader from "components/PageHeader";
import PopoverExport from "components/PopoverExport";
import PopoverPrint from "components/PopoverPrint";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { SubTitleCard } from "../../components/TitleCard";
import { IMPORT_TYPE, typeImport } from "../../constant";

import { AddInfo } from "./AddInfo";
import ViewReimport from "./ComponentViewReimport";
import { InfoByType } from "./InfoByType/InfoByType";
import TableDetail from "./TableDetail";
import TitleCard from "./TitleCard";
import "./index.scss";
import useDetail from "./useDetail";

function DetailSerialImport() {
  const history = useHistory();
  const { id } = useParams();
  const {
    data,
    loading,
    handleSearchSerials,
    tagsValueResult,
    visibleTags,
    visibleSearchResult,
    searchResult,
    handleExport,
    loadingExport,
    handlePrint,
  } = useDetail();

  const { renderInfoByType } = InfoByType({ data });

  return (
    <Spin spinning={loading ? loading : loadingExport}>
      <Row>
        <Col span={24}>
          <PageHeader
            pageTitle={t("serialIEDetail.detailSerial")}
            routes={[
              { path: "/import-serial", name: t("serialIEDetail.serial") },
              { path: "/import-serial", name: t("serialIEDetail.importSerial") },
              { path: "", name: t("serialIEDetail.detailSerial") },
            ]}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} xl={12}>
              <Row gutter={20}>
                <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <PopoverExport handleExport={handleExport} />
                </Col>
                <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <PopoverPrint handlePrint={handlePrint} />
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space style={{ background: "transparent", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Col span={24}>
                    <Typography.Text italic style={{ color: "#5B6673" }}>
                      {t("warranty.warrantyDetail.dateCreated")}: {formatDateTime(data?.createdAt) || "--"}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text italic style={{ color: "#5B6673" }}>
                      {t("common.updatedAt")}: {formatDateTime(data?.updatedAt) || "--"} {t("common.by")}{" "}
                      {data?.updatedBy?.fullname}
                    </Typography.Text>
                  </Col>
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: "16px" }}>
          <Card title={<TitleCard value={data?.code} title={t("serialIEDetail.ticketSerial")} />}>
            <Row gutter={[16, 16]}>
              {/* Hình thức nhập kho */}
              <Col span={20}>
                <Space>
                  <Typography.Text strong>{t("serialIE.importType")}: </Typography.Text>
                  {typeImport[data?.type]}
                </Space>
              </Col>
              {(data?.type === IMPORT_TYPE.FIRST_TIME_IMPORT || data?.type === IMPORT_TYPE.RETURN) && (
                <Col span={4}>
                  <Space style={{ display: "flex", justifyContent: "end" }}>
                    <Typography.Text strong>{t("serialIEDetail.warehouseImport")}: </Typography.Text>
                    <Typography.Text strong>{data?.warehouse?.name}</Typography.Text>
                  </Space>
                </Col>
              )}
              {data?.type !== IMPORT_TYPE.RE_IMPORT_WARRANTY && (
                <>
                  <Col span={12} style={{ borderRight: "1px dashed #9cadc4" }}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <SubTitleCard
                          subTitle={
                            data?.type === IMPORT_TYPE.FIRST_TIME_IMPORT
                              ? t("serialIE.providerInfo.title")
                              : t("serialIE.providerInfo.title2")
                          }
                        />
                      </Col>
                      {renderInfoByType[data?.type]}
                    </Row>
                  </Col>
                  {/* Thông tin bổ sung */}
                  <AddInfo data={data}></AddInfo>
                </>
              )}
            </Row>
          </Card>
        </Col>
        <TableDetail
          data={data}
          handleSearchSerials={handleSearchSerials}
          tagsValueResult={tagsValueResult}
          visibleTags={visibleTags}
          visibleSearchResult={visibleSearchResult}
          searchResult={searchResult}
        />
        <ViewReimport data={data} />
        {data?.type === IMPORT_TYPE.FIRST_TIME_IMPORT && (
          <Col span={24} style={{ marginTop: "16px" }}>
            <CustomAffix
              title={t("common.EDIT")}
              onClick={() => {
                history.push({
                  pathname: `/import-serial/edit/${id}`,
                  state: data,
                });
              }}
            />
          </Col>
        )}
      </Row>
    </Spin>
  );
}

export default DetailSerialImport;
