import React from "react";
import { t } from "i18next";

import { formatDateTime } from "utils/dateTime";
import { DATE_FORMAT } from "config/constants";
import CustomTags from "components/CustomTags";
import { isNil } from "lodash";
import { useGetCreatedByWithIds } from "hooks/order";

export function useToolbar({ params, onChangeParams, statusOptions, actionActivityLogsOptions }) {
  const handleChangeDate = (value) => {
    const { from, to } = value;
    onChangeParams &&
      onChangeParams({
        updatedAtTimeRange: {
          from: from,
          to: to,
        },
      });
  };
  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.updatedAtTimeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.updatedAtTimeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.updatedAtTimeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("updatedAtTimeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const { data: createdByOptions } = useGetCreatedByWithIds({
    ids: params?.filters?.updatedByIDs ? params?.filters?.updatedByIDs : undefined,
  });

  const tags = [
    {
      title: t("campaign.tags.status"),
      options: statusOptions,
      selected: !isNil(params?.filters?.status) && [params?.filters?.status],
      key: "status",
    },
    {
      title: t("campaign.tags.actions"),
      options: actionActivityLogsOptions,
      selected: !isNil(params?.filters?.action) && [params?.filters?.action],
      key: "action",
    },
    {
      title: t("seller.activitylogtabs.table.updateBy"),
      options: createdByOptions,
      selected: params?.filters?.updatedByIDs,
      key: "updatedByIDs",
    },
  ];

  const handleOnClose = (key) => {
    switch (key) {
      case "status":
        onChangeParams && onChangeParams({ [`${key}`]: null });
        break;
      case "action":
        onChangeParams && onChangeParams({ [`${key}`]: null });
        break;
      case "updatedAtTimeRange":
        onChangeParams && onChangeParams({ [`${key}`]: {} });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
    }
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return { handleChangeDate, renderDateTag, renderTags };
}
