import React, { useState } from "react";
import { Modal, Upload } from "antd";
import { ACCEPT_FILE_TYPES } from "config/constants";
import { dummyRequest, notify, validateFile } from "utils/helperFuncs";
import { t } from "i18next";
import "./index.scss";
import { SvgIcon } from "assets/icons";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function UploadBannerButton({ onUploadSuccess, switchClear, multiple, accept, listType, loading, ...rest }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  return (
    <div className="custom-upload-catalog-product-brand">
      <Upload
        accept={accept || ACCEPT_FILE_TYPES.join(",")}
        listType={listType || "picture-card"}
        onPreview={handlePreview}
        customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
        beforeUpload={(file) => {
          if (!validateFile(file)) {
            notify.error({
              message: t("uploadDocument.limitErrorMessage"),
              description: file?.name,
            });
            return Upload.LIST_IGNORE;
          }
          return validateFile(file);
        }}
        {...rest}
      >
        <div className="ant-upload-drag-icon">
          <SvgIcon.LogoFileWordIcon />
        </div>
        <div className="ant-upload-text">{t("productBrand.addImageOrFile")}</div>
      </Upload>
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt={previewTitle}
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
}

export default React.memo(UploadBannerButton);
