import { t } from "i18next";

export const SHIPPING_TYPE = {
  BUS: "BUS",
  DELIVER_3PARTY: "DELIVER_3PARTY",
  PICK_UP_COUNTER: "PICK_UP_COUNTER",
  URBAN_COD: "URBAN_COD",
  URBAN_NOT_COD: "URBAN_NOT_COD",
};

export const ORDER_PRIORITY = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const ORDER_TYPE = {
  SELLER: "SELLER",
  CUSTOMER: "CUSTOMER",
};

export const CUSTOM_PAYMENT_METHOD = {
  COD: "COD",
  CASH: "CASH",
};

export const PAYMENT_METHOD = {
  CASH: "CASH",
  DEBT: "DEBT",
};

export const EXPORT_CART_TYPE = {
  JPEG: "JPEG",
  PDF: "PDF",
  XLSX: "XLSX",
};

export const FILTER_ITEMS_TYPE = {
  ALL: "ALL",
  SELECTED: "SELECTED",
};

export const MAXIMUM_LENGTH_CURRENCY = 9;

export const MAXIMUM_CURRENCY = 999999999;
export const MINIMUM_CURRENCY = 0;

export const MAXIMUM_LENGTH_WARRANTY = 2;

export const MAXIMUM_LENGTH_MAX_DAYS_OF_DEBT = 2;

export const MAXIMUM_CART = 5;

export const MAXIMUM_QUANTITY = 5000;

export const SHIPPING_PROVIDER_TYPE = {
  INSTANT: t("cart.shippingType.INSTANT"),
  STANDARD: t("cart.shippingType.STANDARD"),
  ECONOMY: t("cart.shippingType.ECONOMY"),
};

export const CARRIER_METHOD = {
  INSTANT: "INSTANT",
  STANDARD: "STANDARD",
  ECONOMY: "ECONOMY",
};
