import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon, Icon } from "assets/icons";
import StateSwitch from "components/StateSwitch";
import { useTranslation } from "react-i18next";
import CheckedIcon from "components/CheckedIcon";
import { FilterBooleanRadio } from "../FilterProdAtt";
import { Table } from "antd";

const FilterIcon = () => {
  return <FontAwesomeIcon icon={Icon.faFilter} className="filter-icon" />;
};

const TableTitleHeader = ({ titleString, titleI18nKey }) => {
  const { t } = useTranslation();
  return (
    <span className="title-table">
      {titleString}
      {titleI18nKey ? t(titleI18nKey) : ""}
    </span>
  );
};

const ProdAttTable = ({ tableData, loading, onFilter, defaultParams, onChangeStatus }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeStatus = (id, status) => {
    onChangeStatus && onChangeStatus(id, status);
  };

  const columns = [
    {
      title: <TableTitleHeader titleI18nKey={"common.index"} />,
      align: "center",
      width: "5%",
      render: (_, record) => tableData?.findIndex((item) => item.id === record.id) + 1,
    },
    {
      title: <TableTitleHeader titleI18nKey={"product.categoryAttribute.attributeName"} />,
      dataIndex: "name",
      align: "left",
      width: "20%",
      render: (text, record) => {
        return (
          <span
            className="text-link"
            onClick={() => {
              history.push(`/product-attribute/edit/${record?.id}`, { id: record?.id });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: <TableTitleHeader titleI18nKey={"product.categoryAttribute.attributeValueList"} />,
      dataIndex: ["attributeValueList", "value"],
      ellipsis: true,
      render: (_, record) => record?.attributeValueList?.map((category) => category.value).join(", "),
    },
    {
      title: <TableTitleHeader titleI18nKey={"product.categoryAttribute.usedByCategory"} />,
      dataIndex: "usedByCategory",
      align: "center",
      width: "12%",
    },
    {
      title: <TableTitleHeader titleI18nKey={"product.categoryAttribute.applyAllCategory"} />,
      dataIndex: "applyAllCategory",
      align: "center",
      width: "15%",
      filterDropdown: (
        <FilterBooleanRadio
          labelOfTrue={t("common.yes")}
          labelOfFalse={t("common.no")}
          onFilter={(value) => onFilter({ isCommon: value })}
          defaultParams={defaultParams?.filters?.isCommon}
        />
      ),
      filterIcon: <FilterIcon />,
      render: (_, record) => <CheckedIcon checked={record?.applyAllCategory} />,
    },
    {
      title: <TableTitleHeader titleI18nKey={"common.status"} />,
      dataIndex: "status",
      align: "center",
      width: "15%",
      filterDropdown: (
        <FilterBooleanRadio
          labelOfTrue={t("common.active")}
          labelOfFalse={t("common.inactive")}
          onFilter={(value) => onFilter({ isActive: value })}
          defaultParams={defaultParams?.filters?.isActive}
        />
      ),
      filterIcon: <FilterIcon />,
      render: (_, record) => (
        <StateSwitch
          disabled={record?.usedByCategory ? true : false}
          defaultChecked={record?.status}
          onChange={(checked) => handleChangeStatus(record?.id, checked)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 800, y: null }}
      pagination={{
        size: "small",
        showSizeChanger: false,
      }}
      loading={loading}
      rowKey={(obj) => obj?.id}
    />
  );
};

export default ProdAttTable;
