import React from "react";
import { t } from "i18next";
import CustomTable from "components/CustomTable";
import useAddressBook from "./useAddressBook";
import { ModalAddAddress } from "features/Agent/components";
import { Col, Input, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "assets/icons";
import styles from "./index.module.scss";

function AddressBook({ isSupplier }) {
  const { filterAddressByTextDebounced, id, columns, sellerContacts, fetching, refetch, canUpdateContactAll } =
    useAddressBook({
      isSupplier,
    });

  return (
    <div className={styles["address-list-container"]}>
      <Row gutter={[16, 16]} className={styles["filter-box"]}>
        <Col span={12} className="search-input">
          <Input
            className="custom-input"
            allowClear={true}
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t("seller.componentAddressList.searchAddress")}
            onChange={(e) => filterAddressByTextDebounced(e.target.value)}
            // defaultValue={getQuery().fullTextSearch || ""}
          />
        </Col>

        {canUpdateContactAll && (
          <Col>
            <ModalAddAddress sellerID={id} refetch={refetch} />
          </Col>
        )}
      </Row>

      <CustomTable
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={sellerContacts?.map((item, index) => ({
          ...item,
          no: index + 1,
        }))}
        scroll={{ x: 800, y: null }}
        loading={fetching}
      />
    </div>
  );
}

export default AddressBook;
