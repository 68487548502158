import React from "react";
import { useGetManufacturersAndWarehouse } from "hooks/product/product";
import CustomTags from "components/CustomTags";
import { convertManufacturer, convertWarehouse } from "features/Products/helper";
import { has, isNil } from "lodash";
import { t } from "i18next";

const useFiltersTag = ({ params, handleRemoveFilterTag }) => {
  const { manufacturer, warehouses } = useGetManufacturersAndWarehouse();

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const tags = [
    {
      title: t("product.manufactures"),
      options: convertManufacturer(manufacturer),
      selected: params?.filters?.manufacturerIDs,
      key: "manufacturerIDs",
    },
  ];

  const renderRange = (range) => {
    if (isNil(range.to)) {
      return `>= ${range.from}`;
    }

    if (isNil(range?.from)) {
      return `<= ${range.to}`;
    }

    return `${range.from} - ${range.to}`;
  };

  const renderTagRealStocks = () => {
    const tags = convertWarehouse(warehouses)
      ?.filter((item1) => params?.filters?.realStocks?.some((item2) => item1.value === item2?.warehouseID))
      ?.map((item, index) => ({
        ...item,
        range: params?.filters?.realStocks[index]?.range,
      }))
      ?.filter((item) => item?.range?.from || has(item?.range, "to"))
      ?.map((item) => ({
        label: `${t("product.realStock")} (${item?.label}) : ${renderRange(item.range)}`,
      }));
    return tags?.map((tagObj) => (
      <CustomTags label={tagObj?.label} handleOnClose={() => handleRemoveFilterTag("realStocks")} />
    ));
  };

  const renderTagVat = () => {
    if (has(params?.filters, "vat")) {
      if (!isNil(params?.filters?.vat)) {
        if (params?.filters?.vat) {
          return (
            <CustomTags
              label={`${t("product.exportVat")} : ${t("common.yes")}`}
              handleOnClose={() => handleRemoveFilterTag("vat")}
            />
          );
        } else {
          return (
            <CustomTags
              label={`${t("product.exportVat")} : ${t("common.no")}`}
              handleOnClose={() => handleRemoveFilterTag("vat")}
            />
          );
        }
      }
    }
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags
          handleOnClose={() => handleRemoveFilterTag(key)}
          key={key}
          label={renderTag(options, selected, title)}
        />
      );
    });

  return {
    renderTags,
    renderTagRealStocks,
    renderTagVat,
  };
};

export default useFiltersTag;
