import React from "react";
import useFiltersTag from "./useFiltersTag";
export const FiltersTag = ({
  params,
  handleRemoveFilterTag,
  staffOptions,
  importNotTypeOptions,
  rangeDateTimeFilter,
}) => {
  const { renderTagsCreatedByID, renderTagsImportNoteType, renderTagsCreatedByIDPriceLogs, renderTagCreatedAtFilter } =
    useFiltersTag({
      params,
      rangeDateTimeFilter,
      handleRemoveFilterTag,
      staffOptions,
      importNotTypeOptions,
    });
  return (
    <div style={{ marginBottom: "16px" }}>
      {rangeDateTimeFilter?.from !== undefined ? renderTagCreatedAtFilter : ""}
      {params?.createdByIDs?.length > 0 ? renderTagsCreatedByIDPriceLogs : ""}
      {params?.filters?.createdByIDs?.length > 0 ? renderTagsCreatedByID : ""}
      {params?.filters?.serialImportNoteType?.length > 0 ? renderTagsImportNoteType : ""}
    </div>
  );
};
