/* eslint-disable react-hooks/exhaustive-deps */
import { useGetBranchQuery } from "hooks/branch";
import { useGetListSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { isNil, omit } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import { SORT_TYPES } from "../../../constant";

const GET_QUERY = getQuery();

const useList = () => {
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branchIDsParam = searchParams.get("branchIDs");
  const [branchIDs, setBranchIDs] = useState("");

  const onChangeBranchIDs = (e) => {
    setBranchIDs(e);
    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
        branchIDs: e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParamsListBySerial(newParams);
  };

  const [paramsListBySerial, setParamsListBySerial] = useState();
  const { data, paginationData, loading } = useGetListSerial(paramsListBySerial);
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: staffs, onLoadMoreData, hasMoreData, loading: loadingStaff } = useGetStaff(paramsStaff);
  const { warehouses } = useWarehouse(paramsStaff);

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };
  const handleFilter = (value) => {
    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParamsListBySerial(newParams);
  };

  const handleChangeDate = (value) => {
    const { from, to } = value;

    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
        timeRange: {
          from: from,
          to: to,
        },
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParamsListBySerial(newParams);
  };

  const [searchTerm, setSearchTerm] = useState(GET_QUERY.query);
  const debouncedValue = useDebounce(searchTerm);

  const onSearchBySerial = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleRemoveFilterTag = (key) => {
    setParamsListBySerial({
      ...paramsListBySerial,
      filters: omit({ ...paramsListBySerial?.filters }, [`${key}`]),
      pagination: { offset: 0, limit: 10 },
      sort: paramsListBySerial.sort || [],
    });
  };
  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
      },
      pagination: {
        ...paramsListBySerial.pagination,
      },
      sort: sorter?.order ? [{ by: "ID", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParamsListBySerial(newParams);
  };

  const handlePrevNextPage = (offset) => {
    const newParams = {
      ...paramsListBySerial,
      pagination: {
        offset: offset,
        limit: 10,
      },
      filters: {
        ...paramsListBySerial.filters,
      },
    };
    setParamsListBySerial(newParams);
  };
  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...paramsListBySerial,
        filters: { ...paramsListBySerial?.filters, query: debouncedValue.trim() },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParamsListBySerial(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setParamsListBySerial(() => {
      return {
        filters: {
          query: "",
          createdByIDs: [],
          serialImportNoteType: [],
          branchIDs: branchIdByUser,
        },
        pagination: {
          offset: 0,
          limit: 10,
        },
        sort: [
          {
            by: "ID",
            sortType: "DESC",
          },
        ],
      };
    });
    buildQueryString({
      params: { activeKey: "listBySerial" },
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchIDs(branchIDsParam || branchIdByUser);
  }, [branchIDsParam, branchIdByUser]);

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: "listBySerial",
      },
    });
  }, [paramsListBySerial]);

  return {
    valueFormSelectCity,
    loading,
    paramsListBySerial,
    paramsStaff,
    paginationData,
    data,
    onSearchBySerial,
    handleChangeTable,
    onChangeBranchIDs,
    branchIDs,
    staffs,
    handleRemoveFilterTag,
    GET_QUERY,
    warehouseOptions,
    handlePrevNextPage,
    handleFilter,
    handleChangeDate,
    propsFilterCreated: {
      staffs,
      onLoadMoreData,
      handleSearchStaffs,
      hasMoreData,
      loading: loadingStaff,
    },
  };
};

export default useList;
