import ProductList from "./Product/List";
import ProductDetail from "./Product/Detail";
import ProductGroupCreate from "./ProductGroup/Create/index";
import ProductGroupEdit from "./ProductGroup/Edit";
import { Icon, FontAwesomeIcon } from "assets/icons";
import { USER_PERMISSIONS } from "config/constants";
import ProductCategoryList from "./ProductCategory/List";
import CreateProductCategory from "./ProductCategory/Create";
import EditProductCategory from "./ProductCategory/Edit";
import ProductAttribute from "./ProductAttribute/List";
import ProductAttributeCreate from "./ProductAttribute/Create";
import ProductAttributeEdit from "./ProductAttribute/Edit";
import ProductPriceLog from "./ProductPriceLogs/List";
import ProductBargain from "./ProductBargain/List";
import ProductBrand from "./ProductBrand";
import CreateProductBrand from "features/Products/ProductBrand/CreateProductBrand";
import EditProductBrand from "features/Products/ProductBrand/EditProductBrand";
import DetailAPriceLog from "features/Products/ProductLog/DetailAPriceLog";

const router = [
  {
    key: "product",
    title: "common.product",
    icon: <FontAwesomeIcon icon={Icon.faBox} />,
    permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
    children: [
      {
        key: "product-list",
        title: "common.productList",
        path: "/product",
        component: ProductList,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
      {
        key: "product-category-list",
        title: "product.productCategory",
        path: "/product-category-list",
        component: ProductCategoryList,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
      {
        key: "product-attribute-list",
        title: "common.categoryAttribute",
        path: "/product-attribute",
        component: ProductAttribute,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
      {
        key: "product-price-logs-list",
        title: "common.historyUpdatePriceProduct",
        path: "/product-price-logs",
        component: ProductPriceLog,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
      {
        key: "product-bargain-list",
        title: "common.priceBid",
        path: "/product-bargain",
        component: ProductBargain,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
      {
        key: "product-brand-list",
        title: "productBrand.barndmanagement",
        path: "/product-brand",
        component: ProductBrand,
        exact: true,
        permissions: [USER_PERMISSIONS.PRODUCT_VIEW],
      },
    ],
  },
  { key: "product-detail", path: "/product/detail/:id", component: ProductDetail, exact: true },
  {
    key: "product-group-list-create",
    path: "/product-group/create",
    component: ProductGroupCreate,
    exact: true,
  },
  {
    key: "product-group-list-edit",
    path: "/product-group/edit/:id",
    component: ProductGroupEdit,
    exact: true,
  },
  {
    key: "product-attribute-create",
    path: "/product-attribute/create",
    component: ProductAttributeCreate,
    exact: true,
  },
  {
    key: "product-attribute-edit",
    path: "/product-attribute/edit/:id",
    component: ProductAttributeEdit,
    exact: true,
  },
  {
    key: "product-category-create",
    path: "/product-category-create",
    component: CreateProductCategory,
    exact: true,
  },
  {
    key: "product-category-edit",
    path: "/product-category-edit/:id",
    component: EditProductCategory,
    exact: true,
  },
  {
    key: "product-brand-create",
    path: "/product-brand-create",
    component: CreateProductBrand,
    exact: true,
  },
  {
    key: "product-brand-edit",
    path: "/product-brand-edit/:id",
    component: EditProductBrand,
    exact: true,
  },
  {
    key: "product-price-log-detail",
    path: "/product-price-log-detail/:id",
    component: DetailAPriceLog,
    exact: true,
  },
  // {
  //   key: 'product-edit',
  //   path: '/product-edit/:id',
  //   component: EditProduct,
  //   exact: true,
  // },
];

export default router;
