import { Card as CustomCard } from "antd";

const Card = ({ children, title, bordered = false, styles, className }) => {
  return (
    <CustomCard
      size="small"
      title={title}
      bordered={bordered}
      headStyle={{ background: "#E7F0FF", color: "#2246DC" }}
      style={styles}
      className={className}
    >
      {children}
    </CustomCard>
  );
};

export default Card;
