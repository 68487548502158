import React from "react";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";

import { Icon } from "assets/icons";
import "./index.scss";
import CustomTable from "components/CustomTable";
import useAgentList from "./useAgentList";

function AgentList({ id }) {
  const { columns, data, loading, onTableChange, paginationData, handleSearch } = useAgentList({
    id,
  });

  return (
    <div className="agent-list">
      <div className="search-input">
        <Input
          className="custom-input"
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={t("customer.searchSeller")}
          allowClear={true}
          onChange={(value) => handleSearch(value)}
        />
      </div>
      <CustomTable
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={onTableChange}
        pagination={{
          total: paginationData?.total,
          pageSize: paginationData?.limit,
          current: paginationData?.offset / paginationData?.limit + 1 || 1,
          showSizeChanger: false,
        }}
      />
    </div>
  );
}

export default AgentList;
