import { Col, Row, Typography } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";
import { SelectLoadMore } from "components/CustomSelect";

function WarehouseExport({ warehouseOptions, warehouseInfo, handleSelectWarehouse }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <SelectLoadMore
          formItemOptions={{
            name: "warehouseID",
            label: <span className="sub-title">{"Kho xuất"}</span>,
            rules: [
              {
                required: true,
                message: "Vui lòng chọn kho xuất hàng",
              },
            ],
          }}
          inputOptions={{
            placeholder: "Chọn kho xuất",
            options: warehouseOptions,
            onSelect: (value, option) => handleSelectWarehouse(value, option),
          }}
        />
      </Col>
      <>
        <Col span={4}>
          <Typography.Text className="sub-title">{"Địa chỉ"}</Typography.Text>
        </Col>
        <Col span={20}>
          <CustomInfoAdress value={warehouseInfo} />
        </Col>
        <Col span={4}>
          <Typography.Text className="sub-title">{"Chi nhánh"}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{warehouseInfo?.branch?.name || "- -"}</Typography.Text>
        </Col>
      </>
      {!warehouseInfo && (
        <Col span={24} style={{ textAlign: "center" }}>
          <Typography.Link strong>Vui lòng chọn kho xuất hàng!</Typography.Link>
        </Col>
      )}
    </Row>
  );
}

export default WarehouseExport;
