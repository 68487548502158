import { Checkbox, Col, Row, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import styles from "./index.module.scss";

function TableProduct({ dataTableProduct }) {
  let sumPrice = 0;
  for (let i = 0; i < dataTableProduct?.length; i++) {
    const { quantity, price } = dataTableProduct[i];
    sumPrice += quantity * price;
  }
  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: t("warehouse.recommendImport.PARAMETER"),
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link to={`/product/detail/${record?.product?.id}`} style={{ textAlign: "start", margin: 0 }}>
              <Typography.Link strong>{record?.product?.code}</Typography.Link>
            </Link>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ textAlign: "start", margin: 0 }} strong>
              {record?.product?.name || record?.productName}
            </Typography.Text>
          </div>
        );
      },
      isHidden: false,
      width: 250,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.quantity"),
      render: (_, record, index) => record?.quantity,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.UNITPRICE"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === "VND" ? formatCurrency(record?.price) : formatCurrencyDollar(record?.price)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.money"),
      render: (_, record, index) =>
        record?.currency === "VND"
          ? formatCurrency(record?.price * record?.quantity)
          : formatCurrencyDollar(record?.price * record?.quantity),
    },
    {
      align: "center",
      title: "VAT",
      render: (_, record, index) => <Checkbox checked={record?.isVat}></Checkbox>,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.timewarranty"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.warranty}&nbsp;{convertUnit(record?.warrantyPeriod)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.numberofdays"),
      render: (_, record, index) => <Typography.Text>{record?.creditDays}</Typography.Text>,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.EstimatedDelivery"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.expectedDeliveryTime ? formatDateTime(record?.expectedDeliveryTime) : "- -"}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.COUNTPAYMENT"),
      render: (_, record, index) => <Typography.Text>{record?.paymentNote || "- -"}</Typography.Text>,
    },
  ];

  return (
    <div className={styles["custom-table"]}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={dataTableProduct}
            rowKey={(record) => record?.id}
            scroll={{
              x: 900,
            }}
          />
          <div style={{ marginTop: "10px" }}>
            <Typography.Link strong>
              {t("warehouse.recommendImport.sumByMoney")}:{" "}
              {dataTableProduct && dataTableProduct[0]?.currency === "VND"
                ? formatCurrency(sumPrice)
                : formatCurrencyDollar(sumPrice)}
            </Typography.Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TableProduct;
