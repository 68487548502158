import TagFilterCustom from "components/_storybooks/TagFilterCustom";
import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import { IMPORT_TYPE } from "features/Serial/constant";
import { useGetOrderWithIds, useGetProductWithIds, useGetSellerWithIds } from "hooks/valueWithIds";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";

const valueTagsImportNoteType = [
  {
    label: t("serialIE.FIRST_TIME_IMPORT"),
    value: IMPORT_TYPE.FIRST_TIME_IMPORT,
  },
  {
    label: t("serialIE.RETURN"),
    value: IMPORT_TYPE.RETURN,
  },
  {
    label: t("serialIE.RE_IMPORT_WARRANTY"),
    value: IMPORT_TYPE.RE_IMPORT_WARRANTY,
  },
  {
    label: t("serialIE.transfer_serial"),
    value: IMPORT_TYPE.TRANSFER_SERIAL,
  },
  {
    label: t("serialIE.other"),
    value: IMPORT_TYPE.OTHER,
  },
  {
    label: t("serialIE.provider"),
    value: IMPORT_TYPE.OTHER,
  },
];

const useFiltersTag = ({ params, handleRemoveFilterTag, staffOptions, optionExportRadio, warehouseOptions }) => {
  const { data: dataSellerWithIds, loading: loadingSeller } = useGetSellerWithIds(params?.filters?.sellerIDs);
  const { data: dataProductWithIds, loading: loadingProduct } = useGetProductWithIds(params?.filters?.productIDs);
  const { data: dataOrderWithIds, loading: loadingOrder } = useGetOrderWithIds(params?.filters?.orderIDs);

  const tags = [
    {
      title: t("serialIE.staff"),
      options: staffOptions,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
    {
      title: t("serialIE.operation"),
      options: valueTagsImportNoteType,
      selected: params?.filters?.serialImportNoteType,
      key: "serialImportNoteType",
    },
    {
      title: t("serialIE.typeExport"),
      options: optionExportRadio,
      selected: params?.filters?.type,
      key: "type",
    },
    {
      title: t("serialIE.EXPORTWAREHOUSE"),
      options: warehouseOptions,
      selected: params?.filters?.exportWarehouseIDs,
      key: "exportWarehouseIDs",
    },
    {
      title: t("serialIE.ENTERPRISE_SERIAL"),
      options: warehouseOptions,
      selected: params?.filters?.warehouseIDs,
      key: "warehouseIDs",
    },
    {
      title: t("serialIE.SUPPLIER_AGENT"),
      options: dataSellerWithIds,
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
      loading: loadingSeller,
    },
    {
      title: "Thông số",
      options: dataProductWithIds,
      selected: params?.filters?.productIDs,
      key: "productIDs",
      loading: loadingProduct,
    },
    {
      title: "Mã đơn hàng",
      options: dataOrderWithIds,
      selected: params?.filters?.orderIDs,
      key: "orderIDs",
      loading: loadingOrder,
    },
  ];

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleRemoveFilterTag("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key, loading }) => {
      return (
        <TagFilterCustom
          arrValue={selected}
          onClose={() => handleRemoveFilterTag(key)}
          loading={loading}
          title={title}
          arrTags={options}
        />
      );
    });

  return {
    renderTags,
    renderDateTag,
  };
};

export default useFiltersTag;
