import { convertUnit } from "features/KeyManagement/constants";
import { Space, Typography, Tag, message, Popconfirm, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { Trans } from "react-i18next";
import { useGetShowPassword } from "hooks/keyManagement/query";
import { useState } from "react";

export function UnitTimeComponent(props) {
  return (
    <Space>
      <Typography.Text>{props.time}</Typography.Text>
      <Typography.Text>{convertUnit(props.unit)}</Typography.Text>
    </Space>
  );
}

export function TitleWithSubTitle(props) {
  return (
    <Space direction="vertical">
      <Typography.Text strong style={{ color: "rgba(91, 102, 115, 1)" }}>
        {props.title}
      </Typography.Text>
      <Typography.Text type={props.typeSub} style={{ fontSize: "13px", textTransform: "capitalize" }}>
        {props.titleSubtitle}
      </Typography.Text>
    </Space>
  );
}

export function TagKeyLicenseAccount(props) {
  const { t, orderItem, styles, serial, handleDeleteKey, isKeyLicense } = props;
  const { handleGetPassword } = useGetShowPassword();
  const [password, setPassword] = useState("**********");
  const fetchPassword = async (id) => {
    await handleGetPassword({ serialIDs: id }).then((res) => {
      setPassword(res?.data?.serial?.showPassword?.passwordBySerialID?.[0]?.password || "--");
    });
  };

  return isKeyLicense ? (
    <Tag className={styles["input-tag"]}>
      {serial?.serialCode}{" "}
      <CloseOutlined
        onClick={() => handleDeleteKey(orderItem?.productID, serial?.serialID)}
        className={styles["icon-close-outlined"]}
      />
    </Tag>
  ) : (
    <Tag className={styles["input-tag"]}>
      <Popconfirm
        icon={""}
        placement="topRight"
        title={() => (
          <div className={styles["pc-check-pass-account"]}>
            <span className={styles["title"]}>{t("v2.keyManagement.exportNoteCreate.modalSeePass.title")}</span>
            <span className={styles["description"]}>
              <Trans
                i18nKey="v2.keyManagement.exportNoteCreate.modalSeePass.description"
                values={{ serialCode: serial?.serialCode }}
                components={{ style: <span className={styles["blue"]} /> }}
              ></Trans>
            </span>
            <Input.Password
              className={styles["input"]}
              value={password}
              placeholder={t("v2.keyManagement.exportNoteCreate.modalSeePass.title")}
            />
          </div>
        )}
        cancelText="Đóng"
        okButtonProps={{
          className: styles["btn-popover-disabled"],
        }}
        cancelButtonProps={{
          className: styles["btn-popover-primary"],
        }}
      >
        <span style={{ cursor: "pointer" }} onClick={() => fetchPassword(serial?.serialID)}>
          {serial?.serialCode}
        </span>
      </Popconfirm>
      <CloseOutlined
        onClick={() => handleDeleteKey(orderItem?.productID, serial?.serialID)}
        className={styles["icon-close-outlined"]}
      />
    </Tag>
  );
}

export function handleCopy(t, serials) {
  if (serials && serials.length > 0) {
    let serialCodes = serials.map((item) => item.serialCode);
    const textToCopy = serialCodes.join(", ");
    navigator.clipboard.writeText(textToCopy);
    message.success(t("v2.keyManagement.exportNoteCreate.copySuccess"));
  } else {
    message.error(t("v2.keyManagement.exportNoteCreate.copyFail"));
  }
}
