import { Col, Descriptions, Row } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";

function WarehouseInfo({ dataDetail }) {
  const warehouseInfo =
    dataDetail?.items?.length > 0 && dataDetail?.items[0]?.exportItems?.length > 0
      ? dataDetail?.items[0]?.exportItems[0]?.exportedWarehouse
      : [];

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
          <Descriptions.Item label="Kho xuất">{warehouseInfo?.name || "- -"}</Descriptions.Item>
          <Descriptions.Item label="Địa chỉ">
            <CustomInfoAdress value={warehouseInfo}></CustomInfoAdress>
          </Descriptions.Item>
          <Descriptions.Item label="Chi nhánh">{warehouseInfo?.branch?.name || "- -"}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default WarehouseInfo;
