/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButton } from "components/PrimaryButton";
import { INPUT_TYPE, REGEX } from "config/constants";
import { useSendEmailKeyLicenseAccount } from "hooks/keyManagement/mutation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";

function ModalReSendEmail({ selectedRows }) {
  const [form] = Form.useForm();
  const formData = form.getFieldsValue();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    if (!selectedRows?.length) {
      setVisible(false);
      notify.warning({ message: t("v2.keyManagement.exportNotes.please_select_export") });
    } else {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const { handleSendEmailKeyLicenseAccount, loading } = useSendEmailKeyLicenseAccount();

  const handleReSendEmail = async () => {
    try {
      await form.validateFields().then(async (values) => {
        await handleSendEmailKeyLicenseAccount({
          serialExportNoteID: selectedRows[0]?.serialExportNoteLatest?.id,
          email: values?.email,
        })
          .then(() => {
            handleCancel();
            notify.success({ message: t("v2.keyManagement.exportNotes.resend_email_success") });
            form.resetFields();
            setVisible(false);
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
          });
      });
    } catch (error) {
      console.info(error);
    }
  };

  const initialValues = {
    email: selectedRows[0]?.email ?? "",
  };

  useEffect(() => {
    form.setFieldsValue({ ...formData, email: selectedRows[0]?.email ?? "" });
  }, [selectedRows, visible]);

  return (
    <CustomModal
      width="600px"
      footer={false}
      visible={visible}
      centered
      customComponent={<PrimaryButton title={t("v2.keyManagement.exportNotes.resend_email")} loading={loading} />}
      buttonLoading={loading}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onOke={handleReSendEmail}
      title={<Typography.Title level={4}>{t("v2.keyManagement.exportNotes.info_email")}</Typography.Title>}
      subTitle={
        <Typography.Text type="warning">{t("v2.keyManagement.exportNotes.warning_resend_email")}</Typography.Text>
      }
      children={
        <Form form={form} style={{ marginTop: "10px" }} initialValues={initialValues}>
          <FormInput
            type={INPUT_TYPE.INPUT}
            formItemOptions={{
              name: "email",
              style: { marginBottom: "12px" },
              label: <b>{"Email"}</b>,
              rules: [
                {
                  required: true,
                  message: t("v2.keyManagement.exportNotes.please_enter_email"),
                },
                {
                  pattern: REGEX.EMAIL,
                  message: t("v2.keyManagement.exportNotes.not_found_email"),
                },
              ],
            }}
            inputOptions={{
              size: "large",
              placeholder: t("v2.keyManagement.exportNotes.enter_email"),
            }}
          />
        </Form>
      }
    />
  );
}

export default ModalReSendEmail;
