import React from "react";
import CustomModal from "components/CustomModal";
import { Button, Typography } from "antd";
import { t } from "i18next";
import { WRequestSolutionMethod } from "features/WarrantyRequest/constant";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { useGetInfoPlatform } from "hooks/info-platform";

function PopupConfirm({ title, handleClick, loading, switchClose, solution }) {
  const { platformName } = useGetInfoPlatform();

  const titlePopupSolutionConfirm = {
    [WRequestSolutionMethod.PROVIDER]: t("warrantyRequest.popup.solutionConfirm1"),
    [WRequestSolutionMethod.FOREIGN_WARRANTY]: t("warrantyRequest.popup.foreignWarranty"),
    [WRequestSolutionMethod.THIRD_PARTY]: t("warrantyRequest.popup.solutionConfirm2"),
    [WRequestSolutionMethod.SELF]: t("warrantyRequest.popup.solutionConfirm3", {
      platformName,
    }),
  };
  return (
    <CustomModal
      {...{
        message: (
          <ModalWithIconQuestion
            title={
              <>
                {t(`notiConfirm.solution`)} <br />
                <Typography.Text strong> {titlePopupSolutionConfirm?.[solution]}</Typography.Text>
                {t(`notiConfirm.solution2`)}
              </>
            }
          />
        ),
        customComponent: title,
        styleButton: "notButton",
        footer: false,
        hideConfirmButton: true,
        switchClose: switchClose,
        addMoreButton: (
          <Button type="primary" onClick={handleClick} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
      }}
    />
  );
}

export default PopupConfirm;
