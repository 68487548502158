import { Form, Space, Table, Typography } from "antd";
import CustomInputGroup from "components/CustomInputGroup";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import { DATE_TIME_FORMAT2, INPUT_TYPE } from "config/constants";
import { CURRENCY_INPUT, DATE_PERIOD_UNIT, datePeriodOptions, optionCurrency } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";

function useSplitStepFirst({ form }) {
  const columns = [
    {
      title: t("purchase.table.stt"),
      fixed: true,
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("purchase.table.parameter"),
      fixed: true,
      width: 300,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Space align="center">
              <Typography.Link strong>
                <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
                  <Typography.Link strong>{record?.product?.code}</Typography.Link>
                </Link>
              </Typography.Link>
            </Space>
            <CustomTextHiddenLine line={2} text={record?.product?.name || "--"} />
          </div>
        );
      },
    },
    {
      title: "SỐ LƯỢNG",
      width: 120,
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "conditionQuantity"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.quantityToBuy") }),
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        record?.isRemainPurchaseOrder &&
                        value > record?.receivedQuantity - record?.notQualifiedQuantity
                      ) {
                        return Promise.reject(new Error("Số lượng ≤ số lượng thực giao"));
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                style: { width: 70 },
                maxLength: 4,
                min: 1,
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.unitPriceAfterTax"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "afterVatPrice"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.unitPriceAfterTax") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "currency"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 11,
              min: 0,
            }}
            optionsSelect={optionCurrency}
            maxWidthInput={120}
          />
        );
      },
    },
    {
      title: t("purchase.table.percentVat"),
      width: 80,
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.NUMBER,
              formItemOptions: {
                name: [index, "vatPercent"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.percentVat") }),
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                min: -1,
                maxLength: 2,
                style: { width: 50 },
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.unitPriceBeforeTax"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return record?.currency === CURRENCY_INPUT.USD
          ? formatCurrencyDollar(record?.beforeVatPrice)
          : formatCurrency(record?.beforeVatPrice);
      },
    },
    {
      title: t("purchase.table.intoMoney"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return record?.currency === CURRENCY_INPUT.USD
          ? formatCurrencyDollar(record?.intoMoney || 0)
          : formatCurrency(record?.intoMoney || 0);
      },
    },
    {
      title: t("purchase.table.vatMoney"),
      width: 200,
      align: "right",
      render: (_, record) => {
        return record?.currency === CURRENCY_INPUT.USD
          ? formatCurrencyDollar(record?.vatMoney || 0)
          : formatCurrency(record?.vatMoney || 0);
      },
    },
    {
      title: t("purchase.table.unitPriceAfterDiscount"),
      align: "center",
      width: 220,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "afterDiscountPrice"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.unitPriceAfterDiscount") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "currency"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 11,
              min: 0,
            }}
            optionsSelect={optionCurrency}
            maxWidthInput={120}
          />
        );
      },
    },
    {
      title: t("purchase.table.warrantyPeriod"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "warranty"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.warrantyPeriod") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "warrantyPeriod"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 3,
              min: 0,
            }}
            typeFirst={INPUT_TYPE.NUMBER}
            optionsSelect={datePeriodOptions}
            defaultValueSelect={DATE_PERIOD_UNIT?.MONTH}
            maxWidthSelect={90}
            maxWidthInput={70}
          />
        );
      },
    },
    {
      title: t("purchase.table.numberDaysDebt"),
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.NUMBER,
              formItemOptions: {
                name: [index, "creditDays"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.numberDaysDebt") }),
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                style: { width: 50 },
                maxLength: 3,
                min: 0,
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.expectedDelivery"),
      width: 200,
      align: "center",
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              name: [index, "expectedDeliveryTime"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.expectedDelivery") }),
                },
              ],
            },
            inputOptions: {
              format: DATE_TIME_FORMAT2,
              showTime: true,
              placeholder: t("warrantyRequest.create.placeHolder.date"),
            },
          }}
        />
      ),
    },
  ];

  const summaryTableSelected = (items) => {
    const currencyUSD = items?.[0]?.currency === CURRENCY_INPUT.USD;

    var quantityToBuy = items?.reduce(function (total, current) {
      return total + (current?.conditionQuantity || 0);
    }, 0);

    var totalIntoMoney = items?.reduce(function (total, current) {
      return total + (current?.intoMoney || 0);
    }, 0);

    var totalVatMoney = items?.reduce(function (total, current) {
      return total + (current?.vatMoney || 0);
    }, 0);

    const stylesSummary = {
      color: "#052987",
      textAlign: "center",
      fontWeight: "bold",
    };

    const colorTotals = { color: "#052987" };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.total")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            <Typography.Text style={colorTotals}>{quantityToBuy}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={4} colSpan={3}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD ? formatCurrencyDollar(totalIntoMoney) : formatCurrency(totalIntoMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD ? formatCurrencyDollar(totalVatMoney) : formatCurrency(totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.totalPayment")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD
                  ? formatCurrencyDollar(totalIntoMoney + totalVatMoney)
                  : formatCurrency(totalIntoMoney + totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const renderSummaryFullOrder = () => {
    const formValues = form.getFieldsValue();
    return summaryTableSelected(formValues?.remainPurchaseOrder.items);
  };
  const renderSummaryExtraOrder = () => {
    const formValues = form.getFieldsValue();
    return summaryTableSelected(formValues?.extraPurchaseOrder.items);
  };

  const renderTableFullOrderFirstStep = (dataSource) => {
    const formValues = form.getFieldsValue();
    return (
      <Form.List name="items">
        {(items, { remove }) => {
          return (
            <CustomTable
              scroll={{
                x: "max-content",
              }}
              columns={columns}
              dataSource={formValues?.remainPurchaseOrder?.items}
              summary={formValues?.remainPurchaseOrder?.items?.length > 0 ? renderSummaryFullOrder : null}
            />
          );
        }}
      </Form.List>
    );
  };
  const renderTableExtraOrderFirstStep = (dataSource) => {
    const formValues = form.getFieldsValue();
    return (
      <Form.List name="items">
        {(items, { remove }) => {
          return (
            <CustomTable
              scroll={{
                x: "max-content",
              }}
              columns={columns}
              dataSource={formValues?.extraPurchaseOrder?.items}
              summary={formValues?.extraPurchaseOrder?.items?.length > 0 ? renderSummaryExtraOrder : null}
            />
          );
        }}
      </Form.List>
    );
  };
  return { renderTableFullOrderFirstStep, renderTableExtraOrderFirstStep };
}

export default useSplitStepFirst;
