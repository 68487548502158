import { useGetProductsLoadMore } from "hooks/serial";
import { useState } from "react";

function useSelectProduct({ handleGetProduct }) {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData, loading } = useGetProductsLoadMore(params, "cache-first");

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    handleGetProduct && handleGetProduct({ ...option?.product });
  };

  return {
    onLoadMoreData,
    hasMoreData,
    loading,
    onSearch,
    handleChange,
    data,
  };
}

export default useSelectProduct;
