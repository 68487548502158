import { DIMENSION_UNIT } from "config/constants";
import { useState } from "react";

const useSizeInput = (value, onChange) => {
  const [groupValue, setGroupValue] = useState({
    length: null,
    width: null,
    height: null,
    unit: null,
  });

  const sizeOptions = [
    {
      value: DIMENSION_UNIT.MILLIMETER,
      label: "mm",
    },
    {
      value: DIMENSION_UNIT.CENTIMETER,
      label: "cm",
    },
    {
      value: DIMENSION_UNIT.METER,
      label: "m",
    },
  ];

  const triggerChange = (changedValue) => {
    onChange?.({
      ...groupValue,
      ...value,
      ...changedValue,
    });
  };

  const onNumberChange = (inputValue, key) => {
    const newNumber = inputValue >= 0 ? inputValue : null;
    if (Number.isNaN(groupValue[`${key}`])) {
      return;
    }
    if (!(key in value) || !newNumber) {
      setGroupValue({ ...groupValue, [`${key}`]: newNumber });
    }
    triggerChange({
      [`${key}`]: newNumber,
    });
  };

  const onUomChange = (newUom) => {
    if (!("unit" in value)) {
      setGroupValue({ ...groupValue, unit: newUom });
    }
    triggerChange({
      unit: newUom,
    });
  };

  return {
    onUomChange,
    onNumberChange,
    groupValue,
    sizeOptions,
  };
};

export default useSizeInput;
