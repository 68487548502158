import React, { useState } from "react";
import { Form } from "antd";
import moment from "moment";

import { useGetAllCategories, useGetProducts } from "hooks/product/product";

function useNotOwner({ record }) {
  const [form] = Form.useForm();
  const [params, setParams] = useState({
    filters: {
      query: undefined,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: dataAllCategories } = useGetAllCategories();
  const { data, onLoadMoreData, hasMoreData, loading } = useGetProducts(params, "cache-first");

  const initialValues = {
    serialImei: record?.serial,
    productID: record?.productID,
    productName: record?.productName,
    categoryID: record?.categoryID,
    itemAmount: record?.itemAmount,
    warrantyRequestType: record?.warrantyRequestType,
    accessoryAttachment: record?.accessoryAttachment,
    promisedReturnDate: moment(record?.promisedReturnDate),
    defectDescription: record?.defectDescription,
    customerRequest: record?.customerRequest,
    imageURLs: record?.imageURLs,
  };

  const options = data?.map((item) => {
    return {
      value: item.id,
      label: item.code,
      item: item,
    };
  });

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchTreeSelect = (search, option) => {
    return option.label.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  const handleChangeTreeSelect = (value, option) => {
    //    setCategory(option.at(0));
  };

  return {
    form,
    initialValues,
    dataAllCategories,
    options,
    onLoadMoreData,
    hasMoreData,
    loading,
    onSearch,
    handleChange,
    handleChangeTreeSelect,
    handleSearchTreeSelect,
  };
}

export default useNotOwner;
