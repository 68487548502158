import { Typography } from "antd";
import CustomModal from "components/CustomModal";
import { TabsMenu } from "components/CustomTabs";
import { PRODUCT_TYPE } from "config/constants";
import List from "features/Products/components/List";
import { t } from "i18next";
import React from "react";
import ToolBar from "./components/ToolBar";
import useModalProducts from "./useModalProducts";

const ModalProducts = ({ handleAddNewItems, idsProductsInCart, warehouseID, sellerID, customComponent }) => {
  const {
    tabs,
    handleChangeTab,
    params,
    handleTableChange,
    handleSelected,
    selectedRowKeys,
    selectedRows,
    handleChangeFilter,
    isFilterSelected,
    onSearch,
    onSubmit,
    handleFilter,
    handleRemoveFilterTag,
    countSelectedRowKeys,
  } = useModalProducts({ handleAddNewItems, warehouseID, sellerID });
  return (
    <CustomModal
      title={<Typography.Text strong>{t("cart.addProductAndServiceInCart")}</Typography.Text>}
      customComponent={
        customComponent || <Typography.Link style={{ marginBottom: "24px" }}>{t("cart.listProducts")}</Typography.Link>
      }
      footer={false}
      centered={true}
      okeButtonLabel={t("cart.addItemsToCart")}
      width="80%"
      onOke={onSubmit}
      zIndex={1001}
    >
      <div style={{ width: "100%" }}>
        <TabsMenu tabs={tabs} onChange={handleChangeTab} defaultActiveKey={PRODUCT_TYPE.MATERIAL} />
        <ToolBar onChange={handleChangeFilter} onSearch={onSearch} countSelectedRowKeys={countSelectedRowKeys} />
        <List
          selectedProduct={{ isFilterSelected, selectedRows }}
          params={params}
          onTableChange={handleTableChange}
          onSelected={handleSelected}
          selectedRowKeys={selectedRowKeys}
          idsProductsInCart={idsProductsInCart}
          handleFilter={handleFilter}
          handleRemoveFilterTag={handleRemoveFilterTag}
        />
      </div>
    </CustomModal>
  );
};

export default ModalProducts;
