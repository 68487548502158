import { gql } from "@apollo/client";

export const REJECT_ORDER_RECONCILATION = gql`
  mutation RejectOrderReconcilation($reconcilationID: ID!, $reasonInput: RejectReconcilationOrderInput!) {
    orderReconcilation {
      reject(reconcilationID: $reconcilationID, reasonInput: $reasonInput)
    }
  }
`;

export const COMPLETE_ORDER_RECONCILATION = gql`
  mutation CompleteOrderReconcilation($reconcilationID: [ID!]!) {
    orderReconcilation {
      updateStatus(reconcilationID: $reconcilationID, actionType: COMPLETE)
    }
  }
`;

export const START_ORDER_RECONCILATION = gql`
  mutation StartOrderReconcilation($reconcilationID: [ID!]!) {
    orderReconcilation {
      updateStatus(reconcilationID: $reconcilationID, actionType: START)
    }
  }
`;
