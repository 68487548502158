import React from "react";
import { Col, Progress, Row, Space, Tooltip, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { t } from "i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { formatCurrency } from "utils/helperFuncs";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { PROMOTION_CAMPAIGN_STATUS } from "features/Campaign/constants";
import ProgressBarSoldQuantity from "../ProgressBarSoldQuantity";

function ProductPromoDetail({ data }) {
  const dataSource = data.promotionCampaignTierGroups?.[0]?.promotionCampaignTiers;

  const priceChildrenFinal = (price, isDiscountPercentage, discountAmount) => {
    return (
      <span>
        <div style={{ fontSize: "12px", textDecorationLine: "line-through" }}>{formatCurrency(price)}</div>
        <div>
          {isDiscountPercentage
            ? formatCurrency(price * (1 - discountAmount / 100))
            : formatCurrency(price - discountAmount)}
        </div>
      </span>
    );
  };

  const renderChildrenInStock = () => {
    return [
      {
        title: t("campaign.productPromo.priceLevel1"),
        width: 150,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
          return priceChildrenFinal(
            product?.productPrices?.level1Price,
            record?.discountType === "DISCOUNT_PERCENTAGE",
            record?.discountAmount
          );
        },
      },
      {
        title: t("campaign.productPromo.priceLevel2"),
        width: 150,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
          return priceChildrenFinal(
            product?.productPrices?.level2Price,
            record?.discountType === "DISCOUNT_PERCENTAGE",
            record?.discountAmount
          );
        },
      },
      {
        title: t("campaign.productPromo.priceLevel3"),
        width: 150,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
          return priceChildrenFinal(
            product?.productPrices?.level3Price,
            record?.discountType === "DISCOUNT_PERCENTAGE",
            record?.discountAmount
          );
        },
      },
      {
        title: t("campaign.productPromo.priceLevel4"),
        width: 150,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
          return priceChildrenFinal(
            product?.productPrices?.level4Price,
            record?.discountType === "DISCOUNT_PERCENTAGE",
            record?.discountAmount
          );
        },
      },
    ];
  };

  const columnsDataSelected = [
    {
      title: <span>STT</span>,
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("campaign.productPromo.productTitle"),
      width: 150,
      render: (_, record, index) => {
        const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
        return (
          <Typography.Link strong href={`/product/detail/${product?.id}`} target="_blank">
            {product?.code}
          </Typography.Link>
        );
      },
    },
    {
      title: t("campaign.productPromo.nameProductTitle"),
      width: 200,
      render: (_, record) => {
        const product = record?.conditionProductGroups?.[0]?.conditionPromotionProducts?.[0]?.product || {};
        return <CustomTextHiddenLine line={2} text={product?.name} />;
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.discount")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.discountTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 150,
      align: "right",
      render: (_, record, index) => {
        return record?.discountType === "DISCOUNT_MONEY"
          ? formatCurrency(record?.discountAmount)
          : `${record?.discountAmount}%`;
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673", textJustify: "inter-word", textAlign: "left" }}>
            {t("campaign.productPromo.buyLimitProduct")}
          </Typography.Text>
          <Tooltip title={t("campaign.productPromo.buyLimitProductTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 200,
      align: "center",
      render: (_, record, index) => {
        return record?.maxQuantityPerSeller ?? "Không giới hạn";
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.budget")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.budgetTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 150,
      align: "right",
      render: (_, record, index) => {
        return formatCurrency(record?.budgetAmount);
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.tempoPromoPrice")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.tempoPromoPriceTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      children: renderChildrenInStock(),
    },

    ...(data?.status === PROMOTION_CAMPAIGN_STATUS.ON_GOING
      ? [
          {
            title: (
              <Space>
                <Typography.Text style={{ color: "#5B6673" }}>
                  {t("campaign.productPromo.conditionForReference")}
                </Typography.Text>
                <Tooltip title={t("campaign.productPromo.conditionForReferenceTooltip")}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            ),
            width: 170,
            align: "center",
            fixed: "right",
            render: (_, record, index) => {
              const soldQuantityOfTier = {
                soldQuantity: record?.usedQuantity || 0,
                totalQuantity: record?.totalQuantity || 0,
              };
              return <ProgressBarSoldQuantity soldQuantityOfTier={soldQuantityOfTier} />;
            },
          },
        ]
      : []),
  ];

  return (
    <div>
      <div style={{ padding: "0 0 12px 0" }}>
        <Typography.Text>
          {t("campaign.productPromo.totalRemainingAvailable")}:{" "}
          <span style={{ color: "#2246DC" }}>{formatCurrency(data?.totalRemainingBudget)}</span>/
          {formatCurrency(data?.totalBudget)}
        </Typography.Text>
      </div>

      <CustomTable columns={columnsDataSelected} dataSource={dataSource} scroll={{ x: "max-content" }} />
    </div>
  );
}

export default ProductPromoDetail;
