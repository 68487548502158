import { useMutation } from "@apollo/client";
import {
  COMPLETE_ORDER_RECONCILATION,
  REJECT_ORDER_RECONCILATION,
  START_ORDER_RECONCILATION,
} from "graphql/orderReconciliation/mutation";

export const useRejectOrderReconcilation = () => {
  const [reject, { loading }] = useMutation(REJECT_ORDER_RECONCILATION);

  const handleRejectOrderReconcilation = async (params) => {
    return await reject({
      variables: {
        ...params,
      },
      refetchQueries: ["GetOrderReconcilationQuery", "GetOrderReconciliationByID", "CountOrderReconciliation"],
    });
  };

  return {
    handleRejectOrderReconcilation,
    loading,
  };
};

export const useCompleteOrderReconcilation = () => {
  const [updateStatus, { loading }] = useMutation(COMPLETE_ORDER_RECONCILATION);

  const handleCompleteOrderReconcilation = async (id) => {
    return await updateStatus({
      variables: {
        reconcilationID: id,
      },
      refetchQueries: ["GetOrderReconcilationQuery", "GetOrderReconciliationByID", "CountOrderReconciliation"],
    });
  };

  return {
    handleCompleteOrderReconcilation,
    loading,
  };
};

export const useStartOrderReconcilation = () => {
  const [updateStatus, { loading }] = useMutation(START_ORDER_RECONCILATION);

  const handleStartOrderReconcilation = async (id) => {
    return await updateStatus({
      variables: {
        reconcilationID: id,
      },
      refetchQueries: ["GetOrderReconcilationQuery", "GetOrderReconciliationByID", "CountOrderReconciliation"],
    });
  };

  return {
    handleStartOrderReconcilation,
    loading,
  };
};
