import { Col, List, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import { useTranslation } from "react-i18next";
import "./index.scss";
import useTable from "./useTable";

function TableCampaign({
  data,
  onChangeParams,
  params,
  paginationData,
  loading,
  isStatusAll,
  statusTabs,
  onTableChange,
  handleSort,
  loadMoreDataSellerName,
  onSearchTextChange,
  sellerName,
  refetch,
}) {
  const { renderColumns, isModalCheckDuplicate, listPromotionDuplicateProducts, handleCancelModalCheckDuplicate } =
    useTable({
      onChangeParams,
      isStatusAll,
      data,
      paginationData,
      handleSort,
      loadMoreDataSellerName,
      onSearchTextChange,
      sellerName,
      refetch,
    });

  const { t } = useTranslation();

  return (
    <div className="custom-table-list-campaign">
      <CustomTable
        columns={renderColumns(statusTabs)}
        dataSource={data}
        rowKey={(record) => record?.id}
        loading={loading}
        onChange={onTableChange}
        scroll={{ x: "max-content" }}
        pagination={{
          total: paginationData?.total,
          pageSize: params?.pagination?.limit,
          current: params?.pagination?.offset / params?.pagination?.limit + 1 || 1,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        {...{
          visible: isModalCheckDuplicate,
          footer: false,
          title: <Typography.Title level={4}>{t("customer.activationFailed")}</Typography.Title>,
          hideCancelButton: true,
          okeButtonLabel: t("common.close"),
          onOke: handleCancelModalCheckDuplicate,
          onCancel: handleCancelModalCheckDuplicate,
          message: (
            <Row
              style={{
                textAlign: "left",
              }}
              gutter={[16, 16]}
            >
              <Col span={24}>
                <Typography.Text italic>{t("customer.labelActivationFailed")}</Typography.Text>
              </Col>
              <Col
                style={{
                  maxHeight: 500,
                  overflow: "auto",
                }}
                span={24}
              >
                {listPromotionDuplicateProducts?.map((item) => {
                  const productNumber = item?.overlappingProducts?.length;
                  const productName = item?.promotionCampaign?.name;
                  return (
                    <List
                      header={
                        <div
                          style={{
                            padding: "6px 12px",
                            background: "rgba(216, 223, 234, 1)",
                            borderRadius: "4px",
                          }}
                        >
                          {t("customer.titleMessageActivationFailed")}
                          <Typography.Text style={{ color: "red" }}>{productNumber}</Typography.Text>
                          {t("customer.titleMessageActivationFailed2")}
                          <br />[
                          <Typography.Link
                            style={{ color: "blue" }}
                            target="_blank"
                            href={`/campaign/detail/${item?.promotionCampaignID}`}
                          >{`${productName}`}</Typography.Link>
                          ]
                        </div>
                      }
                      size="small"
                      itemLayout="horizontal"
                      dataSource={item?.overlappingProducts}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Link target="_blank" href={`/product/detail/${item?.id}`}>
                            {item?.code}
                          </Typography.Link>
                        </List.Item>
                      )}
                    />
                  );
                })}
              </Col>
            </Row>
          ),
        }}
      />
    </div>
  );
}

export default TableCampaign;
