import React, { useRef } from "react";
import { Steps, Typography } from "antd";
import { t } from "i18next";
import { CheckCircleFilled, ClockCircleTwoTone } from "@ant-design/icons";
import { convertTimeDate, STEPS_STATE, STEPS_STATE_VALUE } from "features/WarrantyRequest/constant";
import { DATE_TIME_FORMAT } from "config/constants";
import { filter, isNull } from "lodash";
import { Icon } from "assets/icons";

import "./index.scss";

const { Step } = Steps;

function CustomStep({ dataSteps, rest, isCancel }) {
  const colorTitle = ({ name }) => {
    const title = {
      [`${name}`]: <Typography.Text style={{ color: "#052987" }}>{name}</Typography.Text>,
      [`Bảo hành thành công`]: <Typography.Text style={{ color: "#00AB78" }}>{name}</Typography.Text>,
      [`Từ chối bảo hành`]: <Typography.Text style={{ color: "#D2434D" }}>{name}</Typography.Text>,
    };
    return title?.[name];
  };

  const renderTitle = ({ state, name }) => {
    const title = {
      [`${STEPS_STATE.PAST}`]: colorTitle({ name }),
      [`${STEPS_STATE.CURRENT}`]: (
        <Typography>
          <Typography.Text style={{ color: "#2246DC" }}>{name}</Typography.Text>
          <br />
          {name === "Chờ trả khách" && isCancel && (
            <Typography.Text style={{ color: "#D2434D" }}>{`(${t("serial.warrantyDeny")}) `}</Typography.Text>
          )}
        </Typography>
      ),
      [`${STEPS_STATE.FUTURE}`]: <Typography.Text>{name}</Typography.Text>,
    };

    return title?.[state];
  };

  const renderTime = (time) => {
    return <Typography.Text className="time-zone">{convertTimeDate(time, DATE_TIME_FORMAT)}</Typography.Text>;
  };

  const subTitle = (title) => {
    return (
      <Typography>
        {title && (
          <Typography.Text>
            {t("common.by")}: {title}
          </Typography.Text>
        )}
      </Typography>
    );
  };

  const renderIcons = ({ name, state }) => {
    const icons = {
      [`${name}`]: state === "CURRENT" ? <ClockCircleTwoTone /> : <CheckCircleFilled />,

      [`Bảo hành thành công`]: (
        <div className={state === "PAST" && "success"}>
          <CheckCircleFilled />
        </div>
      ),
      [`Từ chối bảo hành`]: (
        <div className={state === "PAST" && "cancel"}>
          <CheckCircleFilled />
        </div>
      ),
    };

    return icons?.[name];
  };
  const stepsContainer = useRef(null);

  function scrollToLeft() {
    if (stepsContainer) {
      stepsContainer.current.scrollLeft -= 300;
    }
  }

  function scrollToRight() {
    if (stepsContainer) {
      stepsContainer.current.scrollLeft += 300;
    }
  }
  const newDataSteps = filter(dataSteps, (el) => !isNull(el));

  return (
    <div className="custom-steps">
      <div className="left-arrow" onClick={scrollToLeft}>
        <Icon.CustomIcon icon={Icon.faChevronLeft} />
      </div>

      <div className="right-arrow" onClick={scrollToRight}>
        <Icon.CustomIcon icon={Icon.faChevronRight} />
      </div>
      <div ref={stepsContainer} className="list-steps-container">
        <Steps size="small" labelPlacement="vertical" {...rest}>
          {newDataSteps?.map((data) => {
            return (
              <Step
                title={renderTitle(data)}
                status={STEPS_STATE_VALUE?.[data?.state]}
                subTitle={subTitle(data?.createdBy?.fullname)}
                description={renderTime(data?.createdAt)}
                icon={renderIcons(data)}
              />
            );
          })}
        </Steps>
      </div>
    </div>
  );
}

export default CustomStep;
