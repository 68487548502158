import { DownOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space, Typography } from "antd";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import { SHIPPING_TYPE } from "config/constants";
import PopupGetResult from "features/WarrantyRequest/components/PopupGetResult";
import PopupSelectShipper from "features/WarrantyRequest/components/PopupSelectShipper";
import PopupSolution from "features/WarrantyRequest/components/PopupSolution";
import { TOTAL_ITEMS_STATUS, WRequestSolutionMethod } from "features/WarrantyRequest/constant";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { useState } from "react";
import ModalEditSerial from "../ModalEditSerial";
import PopupConfirm from "../PopupConfirm";
import PopupConfirmDeliComplete from "../PopupConfirmDeliComplete";
import PopupShippingType from "../PopupShippingType";

export const ButtonChecking = ({ confirmCheck, loading, switchClose, handleOpen, handleCancel }) => {
  return (
    <CustomModal
      {...{
        customComponent: <Button type="primary">{t("warranty.warrantyRequestList.check")}</Button>,
        message: <ModalWithIconQuestion title={t(`notiConfirm.checking`)} />,
        styleButton: "notButton",
        footer: false,
        hideConfirmButton: true,
        visible: switchClose,
        onCancel: handleCancel,
        onOpen: handleOpen,
        addMoreButton: (
          <Button type="primary" onClick={confirmCheck} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
      }}
    />
  );
};

export const ButtonComplete = ({ complete, loading }) => (
  <PopupConfirmDeliComplete complete={complete} loading={loading} />
);

export const ButtonChooseProcess = ({ loading, confirmSolution, record }) => {
  const [switchClose, setSwitchClose] = useState(false);
  const [solution, setSolution] = useState();
  const { platformName } = useGetInfoPlatform();

  const handleClick = () => {
    confirmSolution({ solution: solution, solutionRequest: {} });
    setSwitchClose(false);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.PROVIDER}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.provider")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.FOREIGN_WARRANTY}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.foreignWarranty")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.THIRD_PARTY}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.thirdParty")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.SELF}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.self", { platformName })}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="primary" loading={loading}>
        {t("warrantyRequest.detail.btnGroup.chooseProcess")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export const ButtonTransfer = ({ solution, confirmDistribute, loading, record, selectedRows }) => {
  const [isOpen, setIsOpen] = useState(false);
  const renderButtonByStatus = {
    [`${WRequestSolutionMethod.PROVIDER}`]: (
      <Button loading={loading} type="primary">
        {t("warrantyRequest.detail.btnGroup.transferProvider")}
      </Button>
    ),
    [`${WRequestSolutionMethod.FOREIGN_WARRANTY}`]: (
      <Button loading={loading} type="primary">
        {t("warrantyRequest.detail.btnGroup.transferProvider")}
      </Button>
    ),
    [`${WRequestSolutionMethod.THIRD_PARTY}`]: (
      <Button loading={loading} type="primary">
        {t("warrantyRequest.detail.btnGroup.transferThird")}
      </Button>
    ),
    [`${WRequestSolutionMethod.SELF}`]: (
      <Button loading={loading} type="primary">
        {t("warrantyRequest.detail.btnGroup.technicalStaff")}
      </Button>
    ),
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <PopupSolution
      confirmDistribute={confirmDistribute}
      solution={solution}
      loading={loading}
      title={renderButtonByStatus?.[`${solution}`]}
      record={record}
      selfVisibleControlled={true}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleCancel={handleCancel}
      selectedRows={selectedRows || record}
    />
  );
};

// testing popup
export const ButtonGetResult = ({ record, solution, refetch, itemID, disabled }) => (
  <PopupGetResult refetch={refetch} solution={solution} record={record} itemID={itemID} disabled={disabled} />
);

export const ButtonCustomerDeli = ({ loading, confirmReturn, record }) => {
  const [shippingType, setShippingType] = useState();
  const props = {
    confirmReturn,
    shippingType,
    loading,
    record,
  };
  const { platformName } = useGetInfoPlatform();

  const handleClick = (shippingType) => {
    confirmReturn({
      warrantyRequestItemIDs: record?.id,
      shippingType: shippingType?.key,
      shippingConfig: {},
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(e) => setShippingType(e.key)} key={SHIPPING_TYPE.BUS}>
        <PopupSelectShipper title={t("warrantyRequest.detail.btnGroup.bus")} props={props} />
      </Menu.Item>
      <Menu.Item onClick={(e) => setShippingType(e.key)} key={SHIPPING_TYPE.DELIVER_3PARTY}>
        <PopupSelectShipper title={t("warrantyRequest.detail.btnGroup.deliver3Party")} props={props} />
      </Menu.Item>
      <Menu.Item onClick={handleClick} key={SHIPPING_TYPE.PICK_UP_COUNTER}>
        {t("warrantyRequest.detail.btnGroup.pickUpCounter")}
      </Menu.Item>
      <Menu.Item onClick={handleClick} key={SHIPPING_TYPE.URBAN_COD}>
        {t("warrantyRequest.detail.btnGroup.urbanCOD", { platformName })}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button loading={loading} type="primary">
        <Space>
          {t("warrantyRequest.detail.btnGroup.customerDeli")}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export const ButtonShippingType = ({ confirmReturn, loading }) => {
  return (
    <PopupShippingType
      title={t("warrantyRequest.detail.btnGroup.customerDeli")}
      confirmReturn={confirmReturn}
      loading={loading}
    />
  );
};

export const ButtonEdit = ({ loading, record, status, disabled }) => {
  return (
    <ModalEditSerial
      title={
        <CreateButton
          disabled={disabled}
          title={t("common.edit")}
          loading={loading}
          style={{ background: "#fff", color: "#4077e6" }}
        />
      }
      status={record.status}
      record={record}
    />
  );
};

const ButtonReprocess = ({ record, loading }) => {
  switch (record?.status) {
    case TOTAL_ITEMS_STATUS.COMPLETED:
    case TOTAL_ITEMS_STATUS.CANCELED:
      return <PrimaryButton loading={loading} title={t("warrantyRequest.popup.reprocess")} />;

    default:
      return <PrimaryButtonOutLine loading={loading} title={t("warrantyRequest.popup.reprocess")} />;
  }
};

export const ButtonReturnWaitingForCheck = ({ loading, returnWaitingForCheck, record }) => {
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    try {
      await returnWaitingForCheck({ warrantyRequestItemID: record?.id });
      handleCancel();
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <CustomModal
      {...{
        customComponent: <ButtonReprocess loading={loading} record={record} />,

        footer: false,
        message: (
          <Space direction="vertical">
            <div>
              <HistoryOutlined
                style={{
                  fontSize: 35,
                  color: "#2246DC",
                  background:
                    "radial-gradient(circle, rgba(170,203,255,1) 0%, rgba(208,226,255,1) 33%, rgba(255,255,255,1) 100%)",
                  padding: 20,
                  borderRadius: 50,
                }}
              />
            </div>
            <Typography.Text style={{ fontSize: 16 }}>
              {t("warrantyRequest.popup.reprocessQ")}
              <br />
              <Typography.Link>{record?.serial}</Typography.Link> {t("warrantyRequest.popup.no")}?
            </Typography.Text>
            <Typography.Text style={{ fontSize: 16, color: "#EF9351" }}>
              {t("warrantyRequest.popup.reprocessN")}
            </Typography.Text>
          </Space>
        ),
        onOke: handleOk,
        hideConfirmButton: true,
        addMoreButton: (
          <PrimaryButton loading={loading} onClick={handleOk} title={t("warrantyRequest.popup.reprocess")} />
        ),
        onCancel: handleCancel,
        onOpen: handleOpen,
        visible: visible,
      }}
    />
  );
};
