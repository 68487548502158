import { Form, Select } from "antd";
import styles from "./index.module.scss";
import useChartSelectUser from "./useChartSelectUser";
import { REPORT_TYPE } from "features/Report/constants";

const ChartSelectUser = ({ handleChangeReportType }) => {
  const { filteredTypeOptions, canCustomerReportStaffView, canCustomerReportManagerView } = useChartSelectUser();

  return (
    <div className={styles["container"]}>
      <Form.Item name="type">
        <Select
          options={filteredTypeOptions}
          defaultValue={
            (canCustomerReportStaffView && REPORT_TYPE.PERSONAL) ||
            (canCustomerReportManagerView && REPORT_TYPE.GENERAL)
          }
          onChange={handleChangeReportType}
        />
      </Form.Item>
    </div>
  );
};

export default ChartSelectUser;
