import { useMutation, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { useFlags } from "flagsmith/react";
import { GET_ME_QUERY } from "graphql/auth/query";
import {
  CLONE_CART,
  CREATE_CART_MUTATION,
  DELETE_CART_MUTATION,
  EXPORT_CART,
  UPDATE_CART,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEMS_MUTATION,
  UPDATE_CART_ITEMS_MUTATION_V2,
  UPDATE_CART_ITEM_V2,
  UPDATE_CART_SELLER,
  UPDATE_CART_V2,
} from "graphql/cart/mutation";
import {
  GET_ALL_CARTS,
  GET_CART,
  GET_SENDER,
  GET_SHIPPING_PROVIDER,
  GET_SHIPPING_PROVIDER_OLD,
} from "graphql/cart/query";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";
import { convertGetAllCarts, convertSender, convertShippingProvider } from "./converter";

export const useCreateCart = () => {
  const [createCart, { loading, error }] = useMutation(CREATE_CART_MUTATION);

  const handleCreateCart = async ({ sellerID, items, warehouseID }) => {
    return await createCart({
      variables: {
        sellerID,
        items,
        warehouseID,
      },
      refetchQueries: [GET_ME_QUERY, GET_ALL_CARTS],
    });
  };
  return {
    handleCreateCart,
    loading,
    error,
  };
};

export const useGetAllCarts = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_ALL_CARTS);
  return {
    loading,
    data: convertGetAllCarts(data?.cart?.all) || [],
    refetch,
    fetchMore,
  };
};

export const useGetCart = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_CART, {
    variables: params,
    skip: !params?.id,
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    data: data?.cart?.get,
    refetch,
    fetchMore,
  };
};

export const useDeleteCart = () => {
  const [deleteCart, { loading, error }] = useMutation(DELETE_CART_MUTATION);

  const handleDeleteCart = async (id) => {
    return await deleteCart({
      variables: {
        id,
      },
      refetchQueries: [GET_ME_QUERY, GET_ALL_CARTS],
    });
  };
  return {
    handleDeleteCart,
    loading,
    error,
  };
};

export const useUpdateCartItem = () => {
  const [updateCartItem, { loading, error }] = useMutation(UPDATE_CART_ITEMS_MUTATION);

  const handleUpdateCartItem = async (params) => {
    return await updateCartItem({
      variables: params,
      refetchQueries: [GET_SHIPPING_PROVIDER, GET_CART],
    });
  };

  return {
    handleUpdateCartItem,
    loading,
    error,
  };
};

export const useUpdateCartItemV2 = () => {
  const [updateCartItemV2, { loading, error }] = useMutation(UPDATE_CART_ITEMS_MUTATION_V2);

  const handleUpdateCartItemV2 = async (params) => {
    return await updateCartItemV2({
      variables: params,
      refetchQueries: [GET_SHIPPING_PROVIDER, GET_CART],
    });
  };

  return {
    handleUpdateCartItemV2,
    loading,
    error,
  };
};

export const useUpdateCart = () => {
  const [updateCart, { loading, error }] = useMutation(UPDATE_CART);

  const handleUpdateCart = async (params) => {
    return await updateCart({
      variables: params,
      refetchQueries: [GET_SHIPPING_PROVIDER, GET_CART],
    });
  };
  return {
    handleUpdateCart,
    loading,
    error,
  };
};

export const useUpdateCartV2 = () => {
  const [updateCartV2, { loading, error }] = useMutation(UPDATE_CART_V2);

  const handleUpdateCartV2 = async (params) => {
    return await updateCartV2({
      variables: params,
      refetchQueries: [GET_SHIPPING_PROVIDER, GET_CART],
    });
  };
  return {
    handleUpdateCartV2,
    loading,
    error,
  };
};

export const useUpdateCartSeller = () => {
  const [updateCartSeller, { loading, error }] = useMutation(UPDATE_CART_SELLER);

  const handleUpdateCartSeller = async (params) => {
    return await updateCartSeller({
      variables: params,
      refetchQueries: [
        { query: GET_CART, variables: { id: params?.cartID } },
        {
          query: GET_SHIPPING_PROVIDER,
          variables: {
            serviceFeeInput: {
              cartID: params?.cartID,
            },
          },
        },
      ],
    });
  };
  return {
    handleUpdateCartSeller,
    loading,
    error,
  };
};

export const useCartItems = () => {
  const [cartItems, { loading, error }] = useMutation(UPDATE_CART_ITEM);

  const handleCartItems = async (cartID, params) => {
    return await cartItems({
      variables: {
        cartID: cartID,
        request: {
          cartItems: {
            ...params,
          },
        },
      },
      refetchQueries: [
        { query: GET_CART, variables: { id: cartID } },
        { query: GET_SHIPPING_PROVIDER, variables: { id: cartID } },
      ],
    });
  };
  return {
    handleCartItems,
    loading,
    error,
  };
};

export const useCartItemsV2 = () => {
  const [updateCartItemV2, { loading, error }] = useMutation(UPDATE_CART_ITEM_V2);

  const handleCartItemsV2 = async (cartID, params) => {
    return await updateCartItemV2({
      variables: {
        cartID: cartID,
        request: {
          cartItems: {
            ...params,
          },
        },
      },
      refetchQueries: [
        { query: GET_CART, variables: { id: cartID } },
        { query: GET_SHIPPING_PROVIDER, variables: { id: cartID } },
      ],
    });
  };
  return {
    handleCartItemsV2,
    loading,
    error,
  };
};

export const useNewItems = () => {
  const [newItems, { loading, error }] = useMutation(UPDATE_CART_ITEM);

  const handleNewItems = async (params) => {
    return await newItems({
      variables: {
        cartItems: {
          ...params,
        },
      },
      refetchQueries: [GET_SHIPPING_PROVIDER],
    });
  };
  return {
    handleNewItems,
    loading,
    error,
  };
};

export const useRemovedItemIDs = () => {
  const [removedItemIDs, { loading, error }] = useMutation(UPDATE_CART_ITEM);

  const handleRemovedItemIDs = async (cartID, IDs) => {
    return await removedItemIDs({
      variables: {
        cartID: cartID,
        request: {
          removedItemIDs: [IDs],
        },
      },
      refetchQueries: [GET_SHIPPING_PROVIDER],
    });
  };
  return {
    handleRemovedItemIDs,
    loading,
    error,
  };
};

export const useGetSender = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SENDER);
  return {
    loading,
    data: convertSender(data?.user?.me?.senderContacts),
    refetch,
    fetchMore,
  };
};

export const useCloneCart = () => {
  const [clone, { loading, error }] = useMutation(CLONE_CART);
  const handleCloneCart = async (cartID) => {
    return await clone({
      variables: {
        cloneCartID: cartID,
      },
      refetchQueries: [GET_ALL_CARTS, GET_ME_QUERY],
    });
  };
  return {
    handleCloneCart,
    loading,
    error,
  };
};

export const useGetShippingProvider = (params) => {
  const id = params?.serviceFeeInput?.cartID || params?.serviceFeeInput?.orderID;

  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const { loading, data, refetch, fetchMore } = useQuery(GET_SHIPPING_PROVIDER, {
    variables: { serviceFeeInput: params?.serviceFeeInput },
    skip: !id || !idEnableNewShippingProvider,
    fetchPolicy: "cache-first",
  });
  return {
    loading,
    data: convertShippingProvider(data?.shippingProvider?.servicesFee),
    refetch,
    fetchMore,
  };
};

export const useGetShippingProviderNotConvert = (params) => {
  const id = params?.serviceFeeInput?.cartID || params?.serviceFeeInput?.orderID;

  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const { loading, data, refetch, fetchMore } = useQuery(GET_SHIPPING_PROVIDER, {
    variables: { serviceFeeInput: params?.serviceFeeInput },
    skip: !id || !idEnableNewShippingProvider,
    fetchPolicy: "cache-first",
  });
  return {
    loading,
    data: data?.shippingProvider?.servicesFee,
    refetch,
    fetchMore,
  };
};

export const useExportCart = () => {
  const [exportCart, { loading, error }] = useMutation(EXPORT_CART);

  const handleExportCart = async ({ cartID, fileType }) => {
    return await exportCart({
      variables: {
        cartID,
        fileType,
      },
    });
  };
  return {
    handleExportCart,
    loading,
    error,
  };
};

export const useCartPermissions = () => {
  const { data, loading } = useGetUserPermissions();

  const cartPermissions = (permissions) => ({
    canViewCart: hasPermission(permissions, [USER_PERMISSIONS.CART_VIEW]),
    canCreateCart: hasPermission(permissions, [USER_PERMISSIONS.CART_CREATE]),
  });

  if (loading === false) {
    return {
      loadingCartPermissions: false,
      cartPermissions: cartPermissions(data),
    };
  } else {
    return {
      loadingCartPermissions: true,
      cartPermissions: cartPermissions([]),
    };
  }
};

export const useGetShippingProviderOld = () => {
  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const { loading, data, refetch, fetchMore } = useQuery(GET_SHIPPING_PROVIDER_OLD, {
    skip: idEnableNewShippingProvider,
  });

  return {
    loading,
    data: data?.shippingProvider?.list,
    refetch,
    fetchMore,
  };
};
