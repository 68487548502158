import { Col } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE, SHIPPING_TYPE } from "config/constants";
import useAdditionalDeliver from "features/Cart/components/AdditionalDeliver/useAdditionalDeliver";
import { t } from "i18next";

function ShippingType({ shippingType }) {
  const { shippingProviderOptions } = useAdditionalDeliver();

  const delivers = {
    [`${SHIPPING_TYPE.BUS}`]: (
      <>
        <Col span={12}>
          <FormInput
            formItemOptions={{
              name: ["busConfig", "busName"],
              rules: [{ required: true, message: t("cart.blankBusName") }],
            }}
            inputOptions={{
              placeholder: t("cart.busNamePlaceholder"),
            }}
          />
        </Col>

        <Col span={12}>
          <FormInput
            formItemOptions={{ name: ["busConfig", "telephone"] }}
            inputOptions={{
              placeholder: t("cart.telephonePlaceholder"),
            }}
          />
        </Col>
      </>
    ),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: (
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            style: { width: "100%" },
            name: ["deliver3PartyConfig", "providerID"],
            rules: [{ required: true, message: t("cart.blankServiceCode") }],
          }}
          inputOptions={{
            placeholder: t("cart.serviceCodePlaceholder"),
            options: shippingProviderOptions,
          }}
        />
      </Col>
    ),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: null,
    [`${SHIPPING_TYPE.URBAN_COD}`]: null,
  };

  return delivers[`${shippingType}`];
}

export default ShippingType;
