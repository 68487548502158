import { Col, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import useCampaignInfo from "./useCampaignInfo";

import "./index.scss";

const CampaignInfo = ({ form, isApplyWithPromotion, handleCheckIsApplyWithPromo, isUpdate }) => {
  const { commonInfoFields } = useCampaignInfo({
    form,
    isUpdate,
  });
  return (
    <Card title={<Typography.Title level={5}>{t("campaign.form.commonInfo")}</Typography.Title>}>
      <Row className="custom-form-campaign" gutter={50}>
        {commonInfoFields.map((field, index) => {
          return (
            <Col span={8} key={index}>
              <FormInput key={index} {...field} />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default CampaignInfo;
