import CustomTable from "components/CustomTable";
import React from "react";
import FiltersTag from "../FiltersTag";
import useList from "./useList";

const List = ({
  params,
  onTableChange,
  onSelected,
  selectedRowKeys,
  selectedProduct,
  idsProductsInCart,
  handleFilter,
  handleRemoveFilterTag,
}) => {
  const { columns, products, paginationData, onChange, loading, onSelectedRows } = useList({
    params,
    onTableChange,
    onSelected,
    handleFilter,
    selectedProduct,
  });
  return (
    <>
      {!selectedProduct?.isFilterSelected && (
        <FiltersTag params={params} handleRemoveFilterTag={handleRemoveFilterTag} />
      )}
      <CustomTable
        columns={columns}
        dataSource={selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows : products}
        width="100%"
        rowKey={(record) => record?.id}
        rowSelection={{
          type: "checkbox",
          onChange: onSelectedRows,
          selectedRowKeys: selectedRowKeys,
          preserveSelectedRowKeys: true,
          getCheckboxProps: (record) => ({
            disabled: (idsProductsInCart || []).includes(record.id),
            name: record.name,
          }),
        }}
        pagination={
          !selectedProduct?.isFilterSelected && {
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1,
            showSizeChanger: false,
          }
        }
        onChange={onChange}
        loading={loading}
      />
    </>
  );
};

export default List;
