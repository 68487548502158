import React, { useState } from "react";
import { FilterLoadMore } from "components/CustomFilterMenu";
import { useGetStaff } from "hooks/warranty";

function FilterByUserStaffIDs({ onFilter, obj }) {
  const [params, setParams] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData } = useGetStaff(params);

  const handleSearch = (value) => {
    setParams({
      ...params,
      filters: { ...params?.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return (
    <FilterLoadMore
      params={params}
      data={data || []}
      onLoadMoreData={onLoadMoreData}
      onFilter={(value) => onFilter(value, obj)}
      onSearchTextChange={handleSearch}
      hasMore={hasMoreData}
    />
  );
}

export default FilterByUserStaffIDs;
