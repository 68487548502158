import { WARRANTY_STATUS } from "features/Warranty/constant";
import { useGetWarrantyLoadMore } from "hooks/warranty";
import { useEffect, useState } from "react";

function useSelectProductWarranty() {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });
  const { data, onLoadMoreData, hasMoreData, loading } = useGetWarrantyLoadMore(params, "cache-first");

  const onSearch = (value) => {
    setParams({
      filters: {
        ...params.filters,
        query: value || undefined,
        status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
      },
      pagination: { offset: 0, limit: 10 },
    });
  };

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        query: undefined,
        status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
      },
      pagination: { offset: 0, limit: 10 },
    });
  }, []);

  return { data, onLoadMoreData, hasMoreData, loading, onSearch };
}

export default useSelectProductWarranty;
