import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { useGetDataCreateExportKey, useGenerateKey } from "hooks/keyManagement/query";
import { useCreateSerialExport } from "hooks/serial";
import { useConfirmExportOrder } from "hooks/order";
import { notify } from "utils/helperFuncs";
import { useEffect, useState } from "react";

import { TABS_SUGGESTED_ENTRY } from "../../constants";

export default function useCreateExportKey(t) {
  const location = useLocation(),
    [form] = useForm(),
    history = useHistory(),
    orderID = location?.hash.slice(1),
    initialValues = {
      exportWarehouseType: "ORDER",
    },
    [totalInfo, setTotalInfo] = useState({
      totalProduct: 0,
      totalOrderQuantity: 0,
      totalCurrentQuantity: 0,
      stateQuantity: "",
      stateType: "",
    }),
    [productId2Serials, setProductId2Serials] = useState({});

  const { dataCreateExportKey, loadingDataCreateExportKey } = useGetDataCreateExportKey({ id: orderID });
  const { dataGenerateKey, loadingGenerateKey } = useGenerateKey({
    skip: loadingDataCreateExportKey,
    inputs: dataCreateExportKey?.items?.map((orderItem) => {
      return {
        productID: orderItem?.productID,
        quantity: orderItem?.quantity,
      };
    }),
  });

  const { handleCreateSerialExport, loadingCreate: loadingCreateSerialExport } = useCreateSerialExport();
  const { handleConfirmExportOrder, loading: loadingConfirmExportOrder } = useConfirmExportOrder();

  const handleCreate = async () => {
    let formData = form.getFieldValue();

    const exportNoteItem = dataCreateExportKey?.items?.map((item) => {
      let serialIds = productId2Serials?.[item?.productID]?.map((serial) => serial?.serialID);

      return {
        productID: item?.productID,
        warrantyOnSale: item?.productWarranty,
        warrantyUnit: item?.warrantyDateUnit,
        copyrightTerm: item?.product?.copyrightTerm,
        copyrightUnit: item?.product?.copyrightUnit,
        serialIDs: serialIds,
        warrantyItem: {
          warehouseID: dataCreateExportKey?.exportedWarehouse?.id,
        },
        expectedQuantity: item?.quantity,
        quantity: serialIds?.length,
      };
    });

    const exportNote = {
      type: formData?.exportWarehouseType,
      orderID: dataCreateExportKey?.id,
      sellerID: dataCreateExportKey?.sellerID,
      note: formData?.note,
      fileURLs: formData?.fileURLs,
      items: exportNoteItem,
    };

    try {
      let resultCreate = await handleCreateSerialExport({ request: exportNote });
      if (resultCreate?.data?.serialExportNotes?.create) {
        let resultConfirmExport = await handleConfirmExportOrder({ orderIDs: [dataCreateExportKey?.id] });
        if (resultConfirmExport?.data?.order?.export) {
          notify.success({
            message: t("order.orderList.exportingSuccess"),
          });
          history.push(
            `/suggested-entry?activeKey=${TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER}&digitalStatuses=${TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER}`
          );
        }
      }
    } catch (error) {
      notify.error({
        message: t("order.orderList.exportingFail"),
        description: error.message,
      });
    }
  };

  useEffect(() => {
    let productId2Serials = {};
    dataGenerateKey?.forEach((item) => {
      productId2Serials[item.productID] = item.serials;
    });
    setProductId2Serials({ ...productId2Serials });
  }, [dataGenerateKey]);

  useEffect(() => {
    let totalProduct = dataCreateExportKey?.items?.length || 0,
      totalOrderQuantity = 0,
      totalCurrentQuantity = 0,
      stateQuantity = t("v2.keyManagement.exportNoteCreate.lack"),
      stateType = "danger";

    dataCreateExportKey?.items?.forEach((orderItem) => {
      totalOrderQuantity += orderItem?.quantity || 0;
      totalCurrentQuantity += productId2Serials?.[orderItem?.productID]?.length || 0;
    });

    if (totalOrderQuantity > totalCurrentQuantity) {
      stateQuantity = t("v2.keyManagement.exportNoteCreate.lack");
      stateType = "danger";
    } else if (totalOrderQuantity < totalCurrentQuantity) {
      stateQuantity = t("v2.keyManagement.exportNoteCreate.redundant");
      stateType = "danger";
    } else {
      stateQuantity = t("v2.keyManagement.exportNoteCreate.enough");
      stateType = "success";
    }

    setTotalInfo({
      totalProduct,
      totalOrderQuantity,
      totalCurrentQuantity,
      stateQuantity,
      stateType,
    });
  }, [dataCreateExportKey, productId2Serials, t]);

  return {
    initialValues,
    dataCreateExportKey: dataCreateExportKey,
    loadingDataCreateExportKey: loadingDataCreateExportKey,
    dataGenerateKey: dataGenerateKey,
    loadingGenerateKey: loadingGenerateKey,
    form,
    productId2Serials,
    setProductId2Serials,
    totalInfo,
    handleCreate,
    loadingCreateSerialExport,
    loadingConfirmExportOrder,
  };
}
