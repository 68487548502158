import { Typography } from "antd";
import OrderLink from "components/CustomRedirectLink/OrderLink";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import FormInput from "components/FormInput";
import PopconfirmDelete from "components/PopconfirmDelete";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import CustomTitle from "features/Serial/components/CustomTitle";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { convertTimeDate } from "utils/dateTime";

export const useTable = ({ remove, handleRemoveSerial, warehouseOptions }) => {
  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.stt")} />,
      render: (_, record, index) => index + 1,
      width: "3%",
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.serial")} />,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Text ellipsis={{ expandable: true, tooltip: true }} style={{ textAlign: "start", margin: 0 }}>
            {record?.serialImei}
          </Typography.Text>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.productCode")} />,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography strong style={{ textAlign: "start", margin: 0 }}>
            <ProductLink product={record} />
          </Typography>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.productName")} />,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Text ellipsis={{ expandable: true, tooltip: true }} style={{ textAlign: "start", margin: 0 }}>
            {record?.product?.name || record?.productName}
          </Typography.Text>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.warrantyDate")} />,
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.warrantyOnSale || "--"} {convertUnit(record?.warrantyDateUnit)}
          </Typography.Text>
        );
      },
      width: 100,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.sellDate")} />,
      render: (_, record) => convertTimeDate(record?.soldDate, DATE_FORMAT),
      width: 110,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.warrantyCode")} />,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ textAlign: "start", margin: 0 }}>
            <WarrantyDetailLink warranty={record} />
          </div>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.orderCode")} />,
      render: (_, record) => <OrderLink order={record} />,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.reImportWarehouse")} />,
      render: (_, record, index) => (
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            name: [index, "warrantyItem", "warehouseID"],
            rules: [
              {
                required: true,
                message: t("serialIE.error.warehouseID"),
              },
            ],
          }}
          inputOptions={{
            options: warehouseOptions,
            placeholder: t("serialIE.placeholder.selectWarehouse"),
          }}
        />
      ),
      width: "10%",
    },
    {
      align: "center",
      render: (text, record, index) => {
        return (
          <PopconfirmDelete
            title={
              <Typography.Text strong>
                {t("cart.confirmDeleteItem")}
                <br />
                {<Typography.Link>{record?.product?.name || record?.productName}</Typography.Link>}?
              </Typography.Text>
            }
            onConfirm={() => {
              handleRemoveSerial(record?.serialImei);
              remove(index);
            }}
          />
        );
      },
      width: "3%",
    },
  ];
  return { columns };
};
