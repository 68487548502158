import React from "react";
import { Col, Row, Typography } from "antd";
import { RenderRemainingDeliverTime } from "features/Warranty/util";
import { solutionLabel } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import { WARRANTY_REQUEST_ITEM_SOLUTION } from "features/Warranty/constant";

const InfoAgent = ({ warrantyDetail, solution }) => {
  const {
    warrantyPeriodProviders,
    provider,
    providerDateUnit,
    importDayFromProvider,
    endDateFromProvider,
    remainingWarrantyDaysFromProvider,
  } = warrantyDetail;

  const warrantyPeriodProvider =
    warrantyPeriodProviders !== undefined ? `${warrantyPeriodProviders} ${providerDateUnit}` : provider?.warranty;
  const isMigrated = warrantyDetail?.isMigrated;

  const textIsMigrated = "Vui lòng Xem chi tiết dữ liệu";
  return (
    <div>
      {solution === WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY ? (
        <Row gutter={[16, 8]}>
          <Col xl={12} span={24}>
            <Row gutter={[16, 8]} style={{ borderRight: "1px dashed #9CADC4" }}>
              {solution && (
                <>
                  <Col span={24} xl={7}>
                    <Typography.Text strong>{t("serial.wayOfDistribute")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={17}>
                    <Typography.Text>{solutionLabel?.[solution]}</Typography.Text>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      ) : (
        <Row gutter={[16, 8]}>
          <Col xl={12} span={24}>
            <Row gutter={[16, 8]} style={{ borderRight: "1px dashed #9CADC4" }}>
              {solution && (
                <>
                  <Col span={24} xl={7}>
                    <Typography.Text strong>{t("serial.wayOfDistribute")}</Typography.Text>
                  </Col>
                  <Col span={24} xl={17}>
                    <Typography.Text>{solutionLabel?.[solution]}</Typography.Text>
                  </Col>
                </>
              )}
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.NCC")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : provider?.fullName || "--"}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.phoneNumber")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : provider?.telephone || "--"}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.email")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : provider?.email || "--"}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.address")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : provider?.address || "--"}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col xl={12} span={24}>
            <Row gutter={[16, 8]}>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.dateImport")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : importDayFromProvider}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.warrantyOfNCC")}</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text style={{ fontStyle: isMigrated && "italic" }}>
                  {isMigrated ? `<${textIsMigrated}>` : warrantyPeriodProvider || "--"}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{t("warranty.warrantyDetail.dateWarrantyOfNCC")}</Typography.Text>
              </Col>

              <Col span={17}>
                {isMigrated ? (
                  <Typography.Text
                    style={{ fontStyle: isMigrated && "italic" }}
                  >{`<${textIsMigrated}>`}</Typography.Text>
                ) : (
                  <>
                    <Typography.Text>{endDateFromProvider}</Typography.Text>
                    <br />
                    <RenderRemainingDeliverTime time={remainingWarrantyDaysFromProvider} />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InfoAgent;
