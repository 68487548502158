/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Select, Tag } from "antd";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import {
  useCreateCategory,
  useGetAllAttributes,
  useGetCategoriesForForm,
  useGetCategoriesLevel2,
  useUpdateCategory,
} from "hooks/category/category";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ACTION } from "../../const";
import styles from "./index.module.scss";
import { cloneDeep, includes } from "lodash";
import { getFile, notify } from "utils/helperFuncs";
import { useUpload } from "hooks/upload";
import { PlusOutlined } from "@ant-design/icons";
import LoadingComponent from "components/LoadingComponent";
import PageHeader from "components/PageHeader";
import { arrayUniqueByKey } from "utils/object";

const MAX_IMAGES = 1;

const TagCustom = ({ dataTag, tags, isActionCreate }) => {
  const tag = tags?.find((tag) => dataTag.value === tag.value);

  return (
    <Tag className={styles.tag} closable={!tag?.isDefault} onClose={dataTag.onClose}>
      {tag?.label} {`(${tag?.totalUsedCategory})`}
    </Tag>
  );
};

const FormProductCategory = ({ action, category, idCategory }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    data: categoriesOption,
    loading: loadingCategoriesOption,
    refetch: refetchAllCategory,
  } = useGetCategoriesForForm(category?.level);
  const { data: categoriesLevel2 } = useGetCategoriesLevel2();

  const { data: allAttributes } = useGetAllAttributes();
  const { handleUpload } = useUpload();
  const { handleCreateCategory, loading: loadingCreateCategory } = useCreateCategory();
  const { handleUpdateCategory, loading: loadingUpdateCategory } = useUpdateCategory();

  const [defaultAttributes, setDefaultAttributes] = useState([]);
  const [isDisableInputAttributes, setIsDisableInputAttributes] = useState(true);
  const [isDisableUpload, setIsDisableUpload] = useState(false);
  const [form] = Form.useForm();

  const attributesOptions = arrayUniqueByKey([...allAttributes, ...(category?.attributes || [])], "value");

  const initForm = {
    name: category?.name,
    logoURL: category?.logoURL || [],
    description: category?.description,
    attributeIDs: category?.attributes || [],
    parentID: category?.parentID,
  };

  const handleFilterAttributes = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const uploadButton = (
    <div className={styles.uploadButton}>
      <PlusOutlined />
    </div>
  );

  const isActionCreate = action === ACTION.CREATE;

  const onFinish = async (values) => {
    const formData = { ...values };
    let uploadUrl;

    if (formData?.logoURL) {
      if (formData?.logoURL[0]?.originFileObj) {
        uploadUrl = await handleUpload({ file: formData?.logoURL[0].originFileObj });
      } else {
        uploadUrl = formData?.logoURL[0]?.url;
      }
    } else {
      uploadUrl = "";
    }

    switch (action) {
      case ACTION.CREATE:
        try {
          await handleCreateCategory({
            ...formData,
            logoURL: uploadUrl,
          }).then((res) => {
            notify.success({ message: t("product.categoryForm.addSuccess") });
            history.push("/product-category-list");
          });
        } catch (err) {
          notify.error({ message: t("common.error") });
        }
        break;
      case ACTION.EDIT:
        try {
          await handleUpdateCategory(idCategory, { ...formData, logoURL: uploadUrl }).then((res) => {
            notify.success({ message: t("product.categoryForm.updateSuccess") });
            history.push("/product-category-list");
          });
        } catch (err) {
          notify.error({ message: t("common.error") });
        }
        break;
      default:
        break;
    }
  };

  const handleAddMore = async () => {
    await form.validateFields();

    const formData = { ...form.getFieldValue() };
    let uploadUrl;

    if (formData?.logoURL) {
      if (formData?.logoURL[0]?.originFileObj) {
        uploadUrl = await handleUpload({ file: formData?.logoURL[0].originFileObj });
      } else {
        uploadUrl = formData?.logoURL[0]?.url;
      }
    } else {
      uploadUrl = "";
    }

    try {
      await handleCreateCategory({ ...formData, logoURL: uploadUrl }).then((res) => {
        form.resetFields();
        setIsDisableUpload(false);
        refetchAllCategory();
        notify.success({ message: t("product.categoryForm.addSuccess") });
      });
    } catch (err) {
      notify.error({ message: t("common.error") });
    }
  };

  const isLoading = loadingCreateCategory || loadingUpdateCategory;

  const isChildrenOfCategoryLevel2 = (parentId) => includes(categoriesLevel2, parentId);

  const handleValuesChange = (changedValues) => {
    //category parent = level 2 user can change attributes
    if (changedValues.parentID) {
      //check category level 2 has contain parentID user select
      setIsDisableInputAttributes(!isChildrenOfCategoryLevel2(changedValues.parentID));
    }

    if (changedValues?.logoURL) {
      changedValues?.logoURL.length === MAX_IMAGES ? setIsDisableUpload(true) : setIsDisableUpload(false);
    }
  };

  const renderTreeSelect =
    category?.level === 1 ? null : (
      <FormInput
        type={INPUT_TYPE.TREE_SELECT}
        formItemOptions={{
          label: t("product.categoryForm.productCategoryLabel"),
          name: "parentID",
        }}
        inputOptions={{
          loading: loadingCategoriesOption,
          placeholder: t("product.categoryForm.productCategoryPlaceholder"),
          treeData: categoriesOption,
          treeDefaultExpandAll: true,
          showSearch: true,
          treeNodeFilterProp: "label",
        }}
      />
    );

  useEffect(() => {
    setDefaultAttributes(attributesOptions?.filter((item) => item?.isDefault)?.map((item) => item?.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attributesOptions)]);

  useEffect(() => {
    form.setFieldsValue(cloneDeep(initForm));
    setIsDisableUpload(initForm.logoURL.length === MAX_IMAGES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initForm)]);

  useEffect(() => {
    setIsDisableInputAttributes(!isChildrenOfCategoryLevel2(initForm?.parentID));
  }, [JSON.stringify(categoriesLevel2), JSON.stringify(initForm)]);

  useEffect(() => {
    !isDisableInputAttributes
      ? form.setFieldsValue({ attributeIDs: [...defaultAttributes] })
      : form.setFieldsValue({ attributeIDs: [] });
  }, [defaultAttributes, isDisableInputAttributes]);

  return (
    <LoadingComponent loading={isLoading}>
      <PageHeader
        routes={[
          {
            path: "/product",
            name: t("common.product"),
          },
          {
            path: "/product-category-list",
            name: t("product.productCategory"),
          },
          {
            path: "",
            name: isActionCreate ? t("product.createProductCategory") : t("product.editProductCategory"),
          },
        ]}
      />
      <Form className={styles.container} form={form} onValuesChange={handleValuesChange} onFinish={onFinish}>
        <div className={styles["button-group"]}>
          <CustomModal
            onOke={() => history.push("/product-category-list")}
            centered={true}
            footer={false}
            customComponent={<Button className="custom-button">{t("common.cancel")}</Button>}
            isBlockCloseOnOke={true}
          >
            <p style={{ marginTop: "24px" }}>{t("product.categoryForm.contentModal")}</p>
          </CustomModal>

          <Form.Item className={styles.buttons}>
            <CreateButton htmlType="submit" loading={loadingUpdateCategory} />
          </Form.Item>
          {isActionCreate && (
            <Form.Item className={styles.buttons}>
              <CreateButton title={t("common.saveAndAdd")} htmlType="button" onClick={handleAddMore} />
            </Form.Item>
          )}
        </div>
        <FormInput
          type={INPUT_TYPE.INPUT}
          formItemOptions={{
            label: t("product.categoryForm.nameLabel"),
            name: "name",
            rules: [
              {
                required: true,
                message: t("product.categoryForm.nameValidation"),
              },
              {
                pattern: REGEX.TEXT_TRIM_SPACE,
                message: t("supplier.placeholderInput.whiteSpace"),
              },
            ],
          }}
          inputOptions={{
            placeholder: t("product.categoryForm.namePlaceholder"),
          }}
        />

        <FormInput
          type={INPUT_TYPE.IMAGE_UPLOAD}
          formItemOptions={{
            getValueFromEvent: getFile,
            name: "logoURL",
            valuePropName: "fileList",
            label: t("product.categoryForm.logURLLabel"),
            extra: t("uploadDocument.uploadImagePlaceholder"),
          }}
          inputOptions={{
            showUploadList: {
              showPreviewIcon: false,
            },
          }}
          inputChildren={isDisableUpload ? null : uploadButton}
        />

        <FormInput
          type={INPUT_TYPE.TEXT_AREA}
          formItemOptions={{
            label: t("product.categoryForm.descriptionLabel"),
            name: "description",
          }}
          inputOptions={{
            placeholder: t("product.categoryForm.descriptionPlaceholder"),
          }}
        />

        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            label: t("product.categoryForm.attributesLabel"),
            name: "attributeIDs",
          }}
          inputOptions={{
            mode: "multiple",
            placeholder: t("product.categoryForm.attributesPlaceholder"),
            tagRender: (props) => (
              <TagCustom dataTag={props} tags={attributesOptions} isActionCreate={isActionCreate} />
            ),
            filterOption: handleFilterAttributes,
            disabled: isDisableInputAttributes,
          }}
          inputChildren={attributesOptions?.map((item) => (
            <Select.Option value={item.value} key={item.value} disabled={item?.isDefault}>
              {item.label}
            </Select.Option>
          ))}
        />

        {renderTreeSelect}
      </Form>
    </LoadingComponent>
  );
};

export default FormProductCategory;
