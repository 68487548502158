import React, { useEffect } from "react";
import { Form, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { t } from "i18next";
import { INPUT_TYPE } from "config/constants";
import { validator } from "utils/helperFuncs";

function ModalUpdateBankAccount({
  banksOptions,
  closeModal,
  openModal,
  visible,
  form,
  loadingBankAccount,
  data,
  updateBankAccountHandler,
}) {
  const formFieldsBankAccount = [
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.bankID"),
        name: "bankID",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.bankID"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankID"),
        options: banksOptions,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankAccountNumber"),
        name: "bankAccountNumber",
        rules: [
          validator({
            type: "number",
          }),
          {
            required: true,
            message: t("seller.validateMessage.bankAccountNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankAccountNumber"),
        maxLength: 30,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.accountHolder"),
        name: "name",
        rules: [
          {
            whitespace: true,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
          {
            required: true,
            message: t("seller.validateMessage.accountHolder"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.accountHolder"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankBranch"),
        name: "bankBranch",
        rules: [
          {
            whitespace: true,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
          {
            required: true,
            message: t("seller.validateMessage.bankBranch"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankBranch"),
        maxLength: 255,
      },
    },
  ];

  const initialValues = {
    bankID: data?.bankID,
    bankAccountNumber: data?.bankAccountNumber,
    name: data?.name || data?.ownerBankAccountName,
    bankBranch: data?.bankBranch,
  };

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [data]);

  return (
    <CustomModal
      {...{
        footer: false,
        title: (
          <Typography.Title level={3} style={{ color: "#052987" }}>
            {t("supplier.editPaymentAccount")}
          </Typography.Title>
        ),
        okeButtonLabel: t("common.save"),
        onOke: updateBankAccountHandler,
        onCancel: closeModal,
        onOpen: openModal,
        visible: visible,
        buttonLoading: loadingBankAccount,
      }}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        {formFieldsBankAccount?.map((field, index) => {
          return (
            <FormInput
              key={index}
              {...{
                ...field,
                formItemOptions: {
                  ...field.formItemOptions,
                  label: <Typography.Text strong>{field.formItemOptions.label}</Typography.Text>,
                },
              }}
            />
          );
        })}
      </Form>
    </CustomModal>
  );
}

export default ModalUpdateBankAccount;
