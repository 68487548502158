import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { orderStatusOptions, paymentMethodOptions, renderPaymentMethod } from "features/Agent/constants";
import { renderLabelStatus } from "features/Order/helper";
import { useGetCreatedBy } from "hooks/order";
import { useGetOrdersForActivityLogs, useGetSummaryOrder } from "hooks/seller";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getQuery } from "utils/helperFuncs";

const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

function useBuyHistory() {
  const GET_QUERY = getQuery();
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);

  const [paramCreatedBy, setParamCreatedBy] = useState({
    pagination: { offset: 0, limit: 10 },
    filters: { query: "" },
  });

  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || undefined,
      statuses: GET_QUERY.statuses || [],
      paymentMethods: GET_QUERY.paymentMethods || [],
      timeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      sellerIDs: [id],
      createdByIDs: GET_QUERY?.createdByIDs || [],
    },
    pagination: { offset: GET_QUERY?.offset || 0, limit: 10 },
    sort: [],
  });

  const { data, loading, paginationData } = useGetOrdersForActivityLogs(params);
  const { all, completed, processing, cancel } = useGetSummaryOrder({ id });

  const { data: createdByOptions, loadMoreDataCreatedBy } = useGetCreatedBy(paramCreatedBy);

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };
  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  const onSearchTextChange = (values) => {
    setParamCreatedBy({
      ...paramCreatedBy,
      pagination: { offset: 0, limit: 10 },
      filters: { query: values },
    });
  };

  const columns = [
    {
      title: <span className="title-column">{t("common.index")}</span>,
      align: "center",
      dataIndex: "id",
      render: (_, _record, index) => index + 1,
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.createdAt")}</span>,
      align: "center",
      width: "6%",
      sorter: true,
      dataIndex: "createdAt",
      render: (_, record) => record?.createdAt,
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.orderCode")}</span>,
      align: "center",
      dataIndex: "number",
      render: (_, record) => (
        <Link to={`/order/${record?.id}`} className="order-code">
          {record?.number}
        </Link>
      ),
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.valueOrder")}</span>,
      align: "center",
      dataIndex: "total",
      render: (_, record) => record?.total,
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.paymentMethod")}</span>,
      align: "center",
      dataIndex: "paymentMethod",
      render: (_, record) => {
        return renderPaymentMethod(record?.paymentMethod);
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("paymentMethods", value)}
          type="checkbox"
          options={paymentMethodOptions}
        />
      ),
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.createdBy")}</span>,
      align: "center",
      dataIndex: "createdBy",
      render: (_, record) => record?.createdBy?.fullname,
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilter("createdByIDs", value)}
          data={createdByOptions}
          onLoadMoreData={loadMoreDataCreatedBy}
          onSearchTextChange={onSearchTextChange}
        />
      ),
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.status")}</span>,
      align: "center",
      dataIndex: "status",
      render: (_, record) => {
        return renderLabelStatus?.[record?.status];
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("statuses", value)}
          type="checkbox"
          searchable
          options={orderStatusOptions}
        />
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function onTableChange(pagination, filters, sorter) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
      sort: sorter?.order ? [{ by: "CREATED_AT", orderType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };

    setParams(newParams);
  }

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  }, [debouncedValue]);

  return {
    columns,
    data,
    paginationData,
    loading,
    onTableChange,
    handleSearch,
    all,
    completed,
    processing,
    cancel,
    onChangeParams,
    params,
    createdByOptions,
  };
}

export default useBuyHistory;
