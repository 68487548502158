import { Button, Col, Row, Space, Spin, Typography } from "antd";
import CustomModal from "components/CustomModal";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import CustomTable from "components/CustomTable";
import { SERIAL_AVAILABILITY } from "config/constants";
import DrawerInfoSerial from "features/Warranty/Detail/components/DrawerInfoSerial";
import { useGetSerialDetail } from "hooks/serial/query";
import { t } from "i18next";
import { useState } from "react";
import useList from "./useList";

function PopupDetailSerial({ button, id }) {
  const { columns } = useList();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const { getSerialDetail, loading } = useGetSerialDetail({ id });

  const handleOpenVisible = async () => {
    setVisible(true);
    await getSerialDetail(id).then((res) => {
      setData(res?.data?.serial?.get);
    });
  };

  const handleCloseVisible = () => {
    setVisible(false);
    setData([]);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleOnClose = () => {
    setOpenDrawer(false);
  };

  const renderSerialStatus = () => {
    const output = data?.history
      ?.map((item) => {
        if (item.serialExportNoteItem) {
          return item.serialExportNoteItem;
        }
        if (item.serialImportNoteItem) {
          return item.serialImportNoteItem;
        }
        return null;
      })
      ?.filter((item) => item !== null)
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const nameWarehouseImport =
      output?.length > 0 && output[0]?.serialItems?.length > 0 && output[0]?.serialItems[0]?.warehouse?.name;
    const nameWarehouseExport =
      output?.length > 0 && output[0]?.exportItems?.length > 0 && output[0]?.exportItems[0]?.exportedWarehouse?.name;
    if (data?.status === SERIAL_AVAILABILITY.AVAILABLE) {
      return (
        <Typography.Text style={{ color: "#32BF46" }}>
          Có sẵn trong kho {nameWarehouseImport || nameWarehouseExport}
        </Typography.Text>
      );
    }
    if (data?.status === SERIAL_AVAILABILITY.UNAVAILABLE) {
      return (
        <Typography.Text style={{ color: "#FF9914" }}>
          Không có sẵn (lần cuối được xuất từ kho {nameWarehouseImport || nameWarehouseExport})
        </Typography.Text>
      );
    }
  };
  return (
    <CustomModal
      {...{
        customComponent: <Typography.Link strong>{button}</Typography.Link>,
        footer: false,
        title: (
          <div style={{ display: "flex", alignItems: "center", gap: "20px", fontSize: "20px" }}>
            <Typography.Text strong>{t("order.orderDetail.seeDetails")}</Typography.Text>
            <Typography.Link strong>{data?.code}</Typography.Link>
          </div>
        ),
        width: "80%",
        hideConfirmButton: true,
        cancelButtonLabel: t("common.close"),
        visible: visible,
        onOpen: handleOpenVisible,
        onCancel: handleCloseVisible,
      }}
    >
      <Spin spinning={loading}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <div
              style={{
                background: "#e9eef5",
                padding: "10px 20px",
                marginTop: "20px",
                borderRadius: "8px",
              }}
            >
              <Row gutter={[16, 8]}>
                <Col span={3}>
                  <Typography.Text strong>{t("product.detail.code")}</Typography.Text>
                </Col>
                <Col span={21}>
                  <ProductLink product={data} />
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ marginTop: "10px" }}>
                <Col span={3}>
                  <Typography.Text strong>{t("product.priceBid.nameProduct")}</Typography.Text>
                </Col>
                <Col span={21}>
                  <Typography.Text>{data?.product?.name}</Typography.Text>
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ marginTop: "10px" }}>
                <Col span={3}>
                  <Typography.Text strong>Trạng thái</Typography.Text>
                </Col>
                <Col span={21}>{renderSerialStatus()}</Col>
              </Row>
            </div>
          </Col>
          {data?.isMigrated && (
            <Col span={24}>
              <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                <Button type="link" onClick={handleOpenDrawer}>
                  <span style={{ textDecoration: "underline" }}> Xem chi tiết dữ liệu</span>
                </Button>
              </Space>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[16, 8]} style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <CustomTable
                  columns={columns}
                  dataSource={data?.history?.filter(
                    (item) => !(item.serialImportNoteItem === null && item.serialExportNoteItem === null)
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <DrawerInfoSerial openDrawer={openDrawer} handleOnClose={handleOnClose} data={data} />
      </Spin>
    </CustomModal>
  );
}

export default PopupDetailSerial;
