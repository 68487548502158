import { gql } from "@apollo/client";

export const GET_PURCHASE_STATUS_COUNT = gql`
  query PurchaseStatusCount {
    purchaseOrder {
      statusCount {
        all
        waitingDeposit
        waitingForReceiving
        waitingForImportStock
        waitingForHandling
        completed
        canceled
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_LIST = gql`
  query PurchaseOrderList(
    $filters: PurchaseOrderFilters
    $pagination: PaginationRequest!
    $sorts: [PurchaseOrderSort!]
  ) {
    purchaseOrder {
      pagination(filters: $filters, pagination: $pagination, sorts: $sorts) {
        paginationData {
          total
          offset
          limit
        }
        purchaseOrders {
          id
          code
          createdAt
          sellerID
          seller {
            id
            fullName
          }
          requestType
          orderType
          paymentMethods
          paymentTerms
          warehouse {
            id
            name
          }
          status
          createdBy {
            id
            fullname
          }
          updatedBy {
            id
            fullname
          }
          currency
          # rejectedReasons {
          #   reason
          # }
          productQuantity
          totalVatAmount
          subTotal
          total
          status
          deniedReason {
            id
            eventType
            reason
            files
            causes
          }
          items {
            product {
              id
              code
              name
            }
            quantity
            notQualifiedQuantity
            receivedQuantity
            afterVatPrice
            vatPercent
            vatAmount
            beforeVatPrice
            currency
            subTotal
            afterDiscountPrice
            warranty
            warrantyPeriod
            creditDays
            rejectedCauses
            expectedDeliveryTime
          }
        }
      }
    }
  }
`;

export const GET_SELLER_LOAD_MORE = gql`
  query GetSellerLoadMore($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        sellers {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_PROPOSED_PURCHASE_LIST = gql`
  query ProposedPurchaseList(
    $filters: ImportRequestsFilters
    $pagination: PaginationRequest = { offset: 0, limit: 10 }
    $sorts: [ImportRequestSort!]
  ) {
    importRequests {
      pagination(filters: $filters, pagination: $pagination, sorts: $sorts) {
        paginationData {
          total
          limit
          offset
        }
        importRequests {
          id
          createdAt
          code
          supplierID
          supplier {
            id
            fullName
            code
            telephone
            address
            wardID
            ward {
              id
              name
            }
            districtID
            district {
              name
              id
            }
            cityID
            city {
              id
              name
            }
            bankAccounts {
              id
              bankID
              bank {
                id
                name
                fullName
              }
              bankAccountNumber
              name
              bankBranch
            }
          }
          warehouseID
          warehouse {
            id
            name
            regionID
          }
          type
          status
          items {
            id
            product {
              id
              code
              name
              vatPercent
            }
            order {
              id
              number
              items {
                product {
                  id
                  name
                  code
                }
                quantity
              }
            }
            productName
            quantity
            price
            currency
            warranty
            isVat
            warrantyPeriod
            creditDays
            expectedDeliveryTime
            importReason
            paymentNote
          }
          paymentMethods
          paymentTerms
          paymentNotes
          note
          fileURLs
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_DETAIL = gql`
  query PurchaseOrderDetail($id: ID!) {
    purchaseOrder {
      get(id: $id) {
        id
        code
        status
        requestType
        orderType
        deniedReason {
          id
          eventType
          reason
          files
          causes
          createdBy {
            id
            fullname
          }
          createdAt
          updatedAt
        }
        warehouse {
          id
          name
        }
        parent {
          id
          code
        }
        seller {
          id
          code
          fullName
          telephone
          ward {
            id
            name
          }
          district {
            id
            name
          }
          city {
            id
            name
          }
          bankAccounts {
            bank {
              name
            }
            bankAccountNumber
            name
            bankBranch
          }
        }
        paymentTerms
        paymentMethods
        warehouse {
          id
          name
        }
        fileURLs
        createdBy {
          id
          fullname
        }
        updatedBy {
          id
          fullname
        }
        deniedReason {
          id
          reason
          causes
        }
        createdAt
        updatedAt
        transferNote
        internalNote
        total
        subTotal
        paymentDepositAmount
        paymentOnDeliveryAmount
        paymentDebtAmount
        fullEvents {
          state

          name
          createdAt
          createdByID
          createdBy {
            id
            fullname
          }
        }
        items {
          id
          quantity
          product {
            id
            name
            code
          }
          receivedQuantity
          notQualifiedQuantity
          afterVatPrice
          currency
          vatPercent
          beforeVatPrice
          rejectedCauses
          subTotal
          vatAmount
          afterDiscountPrice
          warranty
          warrantyPeriod
          creditDays
          expectedDeliveryTime
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_REFERENCES = gql`
  query PurchaseOrderReferences($id: ID!) {
    purchaseOrder {
      references(id: $id) {
        ... on ImportRequest {
          importRequestId: id
          importRequestCode: code
        }
        ... on SerialImportNotes {
          serialImportNotesId: id
          serialImportNotesCode: code
        }
      }
    }
  }
`;
