import { Button } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import React from "react";
import useModalCreateOrder from "./useModalCreateOrder";

const ModalCreateOrder = ({ data, form }) => {
  const { validateCreateOrder, onOpenCreateOrder, isVisible, onCancel, onCreateOrder, loading } = useModalCreateOrder({
    data,
    form,
  });
  const { title, hideConfirmButton } = validateCreateOrder();

  return (
    <CustomModal
      customComponent={<Button type="primary">{t("cart.createOrder")}</Button>}
      title={<b>{title}</b>}
      footer={false}
      centered={true}
      visible={isVisible}
      hideConfirmButton={hideConfirmButton}
      onOpen={onOpenCreateOrder}
      onCancel={onCancel}
      onOke={onCreateOrder}
      buttonLoading={loading}
    ></CustomModal>
  );
};

export default ModalCreateOrder;
