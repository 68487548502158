import { Col, Descriptions, Row, Tag, Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import CustomInfoAdress from "components/CustomInfoAdress";
import { COMPANY_TYPE, convertTypeAgent } from "features/Agent/constants";
import { t } from "i18next";
import { Link } from "react-router-dom";

export const GeneralInfoTab = ({ dataDetail, isSupplier }) => {
  return (
    <Col span={24} style={{ padding: "0 12px" }}>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <CustomDescriptions
            content={
              <>
                <Descriptions.Item
                  label={isSupplier ? t("seller.labelInput.typeSupplier") : t("seller.labelInput.typeAgent")}
                >
                  <Typography.Text>
                    {convertTypeAgent(dataDetail?.type)}&nbsp;
                    {dataDetail?.isSupplier && (
                      <Typography.Text type="warning" italic>
                        ({t("supplier.isCustomer")})
                      </Typography.Text>
                    )}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.labelInput.sellerGroup")}>
                  <Link to={`/seller-group/detail/${dataDetail?.sellerGroup?.id}`}>
                    {dataDetail?.sellerGroup?.name || "--"}
                  </Link>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.labelInput.address")}>
                  <CustomInfoAdress value={dataDetail}> </CustomInfoAdress>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.labelInput.contactPhone")}>
                  <Typography.Text>{dataDetail?.telephone || "--"}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.labelInput.warehouseIDs")}>
                  {dataDetail?.warehouses?.map((item) => (
                    <Tag color="geekblue">{`${item?.branch?.name} - ${item?.name}`}</Tag>
                  ))}
                </Descriptions.Item>
              </>
            }
          />
        </Col>
        <Col span={12}>
          <CustomDescriptions
            content={
              <>
                {dataDetail?.type === COMPANY_TYPE.PERSONAL && (
                  <Descriptions.Item label={t("seller.labelInput.numberPerson")}>
                    <Typography.Text>{dataDetail?.idNumber || "--"}</Typography.Text>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t("seller.labelInput.taxIDNumber")}>
                  <Typography.Text>{dataDetail?.vatInfo?.taxIDNumber || "--"}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.vat_agent_type")}>
                  {dataDetail?.type === COMPANY_TYPE.COMPANY && (
                    <span>
                      <span>
                        {dataDetail?.vatInfo?.defaultExportVat ? "Ưu tiên Xuất VAT" : "Ưu tiên Không xuất VAT"}
                      </span>
                      <span>{dataDetail?.vatInfo?.allowEditVat && " và được phép tùy chỉnh VAT"}</span>
                    </span>
                  )}
                </Descriptions.Item>
                {dataDetail?.type === COMPANY_TYPE.COMPANY && (
                  <Descriptions.Item label={t("seller.labelInput.companyAddress")}>
                    <Typography.Text>{dataDetail?.vatInfo?.address || "--"}</Typography.Text>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t("seller.labelInput.email")}>
                  <Typography.Text>{dataDetail?.email || "--"}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("seller.labelInput.paymentInfoBank")}>
                  {dataDetail?.bankAccounts?.map((item, index) => (
                    <div>
                      {index + 1}. {item?.bank?.name} - {item?.bankAccountNumber} - {item?.name} - {item?.bankBranch}
                    </div>
                  ))}
                </Descriptions.Item>
              </>
            }
          />
        </Col>
      </Row>
    </Col>
  );
};
