import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { t } from "i18next";
import PopupApproveBid from "../PopupApproveBid";
import PopupCreateNewBid from "../PopupCreateNewBid";
import PopupRejectBid from "../PopupRejectBid";

export const SelectActionsBid = ({ record, disabled }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <PopupApproveBid record={record} />
      </Menu.Item>
      <Menu.Item>
        <PopupRejectBid record={record} />
      </Menu.Item>
      <Menu.Item>
        <PopupCreateNewBid record={record} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      className={`dropdown-action-price-bid ${disabled === true ? "dropdown-action-price-bid-disabled" : ""}`}
      disabled={disabled}
    >
      <Button>
        {t("product.priceBid.priceApprove")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};
