import React, { useState } from "react";
import { Form } from "antd";
import { DATE_ISO_8601_FORMAT } from "config/constants";
import { WARRANTY_STATUS } from "features/Warranty/constant";
import { WARRANTY_PRODUCT_TYPE, WARRANTY_REQUEST_TYPE } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import { notify } from "utils/helperFuncs";
import { useUploadDocument } from "hooks/upload-document";

function useModalEditOwner({ warrantyRequestItem, onSubmit }) {
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(false);
  const { handleUpload } = useUploadDocument();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    warrantyProductType: WARRANTY_PRODUCT_TYPE.OWNER,
    warrantyRequestType:
      warrantyRequestItem?.status === WARRANTY_STATUS.STILL_VALIDATED
        ? WARRANTY_REQUEST_TYPE.SELF
        : WARRANTY_REQUEST_TYPE.BEHALF,
    promisedReturnDate: null,
    accessoryAttachment: null,
    customerRequest: null,
    defectDescription: null,
    imageURLs: [],
  };

  const handleOpen = () => {
    setSwitchClose(true);
  };

  const handleCancel = () => {
    setSwitchClose(false);
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t("uploadDocument.uploadImageError"),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const handleEdit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const imageURLs = await uploadImage(values?.imageURLs);

      const newObj = {
        ...warrantyRequestItem,
        ...values,
        warrantyProductType: WARRANTY_PRODUCT_TYPE.OWNER,
        warrantyRequestType: WARRANTY_REQUEST_TYPE.SELF,
        promisedReturnDate: formatDateTime(values?.promisedReturnDate, DATE_ISO_8601_FORMAT),
        imageURLs: imageURLs,
      };

      newObj && setSwitchClose(false);

      onSubmit(newObj);
    } catch (info) {
      console.info("Validate Failed:", info);
    } finally {
      setLoading(false);
    }
  };

  return {
    initialValues,
    form,
    handleEdit,
    switchClose,
    loading,
    handleOpen,
    handleCancel,
  };
}

export default useModalEditOwner;
