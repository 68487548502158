import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { Icon } from "assets/icons";
import { t } from "i18next";

function InputSearch({ onSearch }) {
  return (
    <Input
      onChange={(e) => onSearch(e)}
      prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
      placeholder={t("supplier.searchBySupplier")}
      allowClear={true}
    />
  );
}

export default InputSearch;
