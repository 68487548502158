import Card from "features/Cart/components/Card";
import { Typography, Col, Form } from "antd";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { getFile } from "utils/helperFuncs";
import { INPUT_TYPE } from "config/constants";

export default function AddInfo(props) {
  const { t } = props;

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "rgba(5, 41, 135, 1)" }}>
          {t("v2.keyManagement.exportNoteCreate.additionalInfo")}
        </Typography.Text>
      }
    >
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: <Typography.Text strong>{t("v2.keyManagement.note")}</Typography.Text>,
              name: "note",
              labelCol: { span: 24, offset: 0 },
            },
            inputOptions: {
              placeholder: t("v2.keyManagement.exportNoteCreate.inputNote"),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name={"fileURLs"}
          getValueFromEvent={getFile}
          valuePropName={"fileList"}
          label={
            <Typography.Text strong>{t("v2.keyManagement.exportNoteCreate.pictureAndAttachment")}</Typography.Text>
          }
          extra={t("v2.keyManagement.exportNoteCreate.fileSupport")}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
    </Card>
  );
}
