import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Space, Tooltip } from "antd";
import { Icon } from "assets/icons";
import FilterDate from "components/CustomFilterMenu/FilterDate";

import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import {
  TABS_SUGGESTED_ENTRY,
  digitalStatusOptions,
  priorityOptions,
  statusOptions,
} from "features/KeyManagement/constants";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useGetCreatedByWithIds } from "hooks/order";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import ModalReSendEmail from "../ModalReSendEmail";
import ModalSuggestedConfirm from "../ModalSuggedtedConfirm";
import ModalSuggestedCancel from "../ModalSuggestedCancel";

function Toolbar({
  onChangeParams,
  params,
  onSearch,
  activeKeyMain,
  selectedRows,
  propsContent,
  canSuggestKeyLicenseAccountCancel,
  canSuggestKeyLicenseAccountConfirm,
}) {
  const GET_QUERY = getQuery();

  const { resetSelected } = propsContent;

  const handleChangeDate = (value) => {
    const { from, to } = value;

    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
  };

  const handleOnClose = (key) => {
    switch (key) {
      case "timeRange":
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const { data: dataCreatedByWithIds } = useGetCreatedByWithIds({
    ids: GET_QUERY.createdByIDs ? GET_QUERY.createdByIDs : undefined,
  });

  const { data: dataSentDigitalByIDs } = useGetCreatedByWithIds({
    ids: GET_QUERY.sentDigitalByIDs ? GET_QUERY.sentDigitalByIDs : undefined,
  });

  const tagOptions = [
    ...(GET_QUERY.activeKey === TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            title: "Người gửi",
            options: dataSentDigitalByIDs,
            selected: params?.filters?.sentDigitalByIDs,
            key: "sentDigitalByIDs",
          },
        ]
      : []),
    {
      title: "Người tạo",
      options: dataCreatedByWithIds,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
    ...(GET_QUERY.activeKey === TABS_SUGGESTED_ENTRY.ALL
      ? [
          {
            title: "Trạng thái đề nghị",
            options: digitalStatusOptions,
            selected: params?.filters?.digitalStatuses,
            key: "digitalStatuses",
          },
        ]
      : []),
    {
      title: "Độ ưu tiên",
      options: priorityOptions,
      selected: params?.filters?.priorities,
      key: "priorities",
    },
    {
      title: "Trạng thái đơn hàng",
      options: statusOptions,
      selected: params?.filters?.statuses,
      key: "statuses",
    },
  ];

  const tagLabel = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    const label = result.map((item) => item?.label).join(", ");

    return (
      <div>
        <span className="label">{title}</span>:{" "}
        <Tooltip title={result?.length >= 5 && label}>
          {result?.length >= 5 ? truncateAddDots(label, 100) : label}
        </Tooltip>
      </div>
    );
  };

  const renderTags = tagOptions
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={tagLabel(options, selected, title)} />
      );
    });

  return (
    <Row gutter={[8, 8]} className="toolbar-suggested-entry-list">
      <Col span={24}>
        <Space style={{ width: "100%", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Row gutter={[16, 16]}>
            <Col>
              <FilterDate title={"Ngày tạo"} onChangeDate={handleChangeDate} />
            </Col>
            <Col span={12} style={{ width: "100%" }}>
              <Input
                prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
                placeholder={"Tìm kiếm theo mã đề nghị hoặc tên khách hàng"}
                onChange={onSearch}
                defaultValue={params?.filters?.query}
                allowClear
                style={{ width: "500px" }}
              />
            </Col>
          </Row>
          <div>
            {(activeKeyMain === TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER ||
              activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED) && (
              <Space>
                {activeKeyMain === TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER && (
                  <>
                    {canSuggestKeyLicenseAccountCancel && (
                      <ModalSuggestedCancel selectedRows={selectedRows} resetSelected={resetSelected} />
                    )}
                    {canSuggestKeyLicenseAccountConfirm && <ModalSuggestedConfirm selectedRows={selectedRows} />}
                  </>
                )}
                {activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED && <ModalReSendEmail selectedRows={selectedRows} />}
              </Space>
            )}
          </div>
        </Space>
      </Col>
      <Col span={24}>
        {renderDateTag()}
        {renderTags}
      </Col>
    </Row>
  );
}

export default Toolbar;
