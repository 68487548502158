import { useMutation } from "@apollo/client";
import { APPROVE, CREATE_NEW_BID, REJECT } from "graphql/priceBid/mutation";

export const useCreateNewBid = () => {
  const [createNewBid, { loading, error }] = useMutation(CREATE_NEW_BID);

  const handleCreateNewBid = async (params) => {
    return await createNewBid({
      variables: { request: params },
      refetchQueries: ["GetAllListPriceBid", "CountPriceBidSessionByStatus"],
    });
  };

  return {
    handleCreateNewBid,
    loading,
    error,
  };
};

export const useApproveBid = () => {
  const [approveBid, { loading, error }] = useMutation(APPROVE);

  const handleApprove = async ({ priceBidSessionID }) => {
    return await approveBid({
      variables: { priceBidSessionID: priceBidSessionID },
      refetchQueries: ["GetAllListPriceBid", "CountPriceBidSessionByStatus"],
    });
  };

  return {
    handleApprove,
    loading,
    error,
  };
};

export const useRejectBid = () => {
  const [rejectBid, { loading, error }] = useMutation(REJECT);

  const handleReject = async ({ priceBidSessionID }) => {
    return await rejectBid({
      variables: { priceBidSessionID: priceBidSessionID },
      refetchQueries: ["GetAllListPriceBid", "CountPriceBidSessionByStatus"],
    });
  };

  return {
    handleReject,
    loading,
    error,
  };
};
