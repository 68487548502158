import { MAX_SCREEN, MIN_SCREEN, PURCHASE_TABS, SORT_TYPES } from "features/Purchase/constant";
import { useGetProposedPurchaseList, useGetSellerLoadMore } from "hooks/purchase";
import useDebounce from "hooks/useDebounce";
import { useWarehouse } from "hooks/warehouse";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";

function useAccordingProposedPurchase() {
  const GET_QUERY = getQuery();

  const [selectedHighlight, setSelectedHighlight] = useState();
  const [isTableSelectedRow, setIsTableSelectedRow] = useState(true);
  const [sizeScrollPageAll, setSizeScrollPageAll] = useState({ x: 1600, y: MAX_SCREEN });
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const debouncedValue = useDebounce(searchTerm?.trim());

  const [params, setParams] = useState({
    activeKey: PURCHASE_TABS.PROPOSED_PURCHASE,
    key: GET_QUERY.key,
    filters: {
      query: GET_QUERY?.query || "",
      timeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      typesProposed: GET_QUERY?.typesProposed,
      warehouseIDs: GET_QUERY?.warehouseIDs || [],
      supplierIDs: GET_QUERY?.supplierIDs || [],
    },
    pagination: {
      limit: 10,
      offset: GET_QUERY?.offset || 0,
    },
    sorts: [],
  });

  const [paramsWareHouse, setParamsWareHouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: undefined, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    proposedPurchaseList,
    loading: loadingProposedPurchaseList,
    paginationData,
  } = useGetProposedPurchaseList(params);

  const {
    data: dataSeller,
    onLoadMoreData: onLoadMoreSeller,
    hasMoreData: hasMoreSeller,
    loading: loadingSeller,
  } = useGetSellerLoadMore(paramsSeller);

  const { warehouses } = useWarehouse(paramsWareHouse);

  const optionsSeller = dataSeller?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const handleChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    handleChangeParams && handleChangeParams({ [`${key}`]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSellerLoadMore = (value) => {
    setParamsSeller({
      filters: { ...setParamsSeller.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sorts: sorter?.order ? [{ by: "CREATED_AT", type: SORT_TYPES[sorter?.order] }] : [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        key: newParams.key,
        activeKey: newParams.activeKey,
      },
    });
  };

  const handleToggleRows = () => {
    setSizeScrollPageAll(isTableSelectedRow ? { x: 1600, y: MIN_SCREEN } : { x: 1600, y: MAX_SCREEN });
    setIsTableSelectedRow(!isTableSelectedRow);
  };

  const handleRowClick = (record, index) => {
    setSelectedHighlight(index);
    setSelectedRow(record);
    setIsTableSelectedRow(false);
    setSizeScrollPageAll({ x: 1600, y: MIN_SCREEN });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: { ...newParams?.filters, ...newParams?.pagination },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (proposedPurchaseList?.length > 0) {
      handleRowClick(proposedPurchaseList && proposedPurchaseList[0], 0);
    }
  }, [JSON.stringify(proposedPurchaseList)]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params?.filters,
        query: "",
      },
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
  }, []);

  return {
    selectedHighlight,
    isTableSelectedRow,
    sizeScrollPageAll,
    selectedRow,
    loadingProposedPurchaseList,
    paginationData,
    hasMoreSeller,
    loadingSeller,
    optionsSeller,
    warehouseOptions,
    proposedPurchaseList,
    params,
    onLoadMoreSeller,
    onFilter,
    handleSearch,
    handleSearchSellerLoadMore,
    handleChangeTable,
    handleChangeParams,
    handleRowClick,
    handleToggleRows,
  };
}

export default useAccordingProposedPurchase;
