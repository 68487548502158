export const BASE_URL = "https://honganh.dungdm93.me";
// eslint-disable-next-line no-undef
export const BASE_GRAPHQL_URL = process.env.REACT_APP_BASE_GRAPHQL_URL;
// export const BASE_GRAPHQL_URL = 'http://localhost:8080/graphql';

export const CC_URL = "https://app.papercups.io";
export const CC_TOKEN = "44748d57-14e9-443f-bea0-d31a0b9bc620";
export const CC_ACCOUNT_ID = "44748d57-14e9-443f-bea0-d31a0b9bc620";
export const CC_INBOX_ID = "1e282f92-3507-4993-b95b-0205d0b114f8";

const API_URL = {
  USER: "/api/v1/users",
  LOGIN: "/api/v1/token",
  REQUEST_EMAIL_CHANGE_PASSWORD: "/api/v1/password/request_forget_password",
  CHANGE_FORGET_PASSWORD: "/api/v1/password/change_forget_password",
  DEPARTMENT: "/api/v1/departments",
  ME: "/api/v1/me",
  PRODUCT: "/api/v1/products",
  PRODUCT_DETAIL: "/api/v1/product-detail",
  PRODUCT_GROUP: "/api/v1/categories",
};

export default API_URL;
