import { InputNumber, Space, Typography } from "antd";
import { MenuLayout } from "components/CustomFilterMenu";
import React, { useState } from "react";
import { isNil } from "lodash";

const FilterFromTo = ({ onFilter, defaultValue }) => {
  const [rangeFilter, setRangeFilter] = useState({ from: null, to: null, ...defaultValue });

  const handleChange = (value) => {
    setRangeFilter({ ...rangeFilter, ...value });
  };

  const handleResetFilter = () => setRangeFilter({ from: null, to: null });

  const handleApplyFilter = () => {
    const { from, to } = rangeFilter;

    if (isNil(from) && isNil(to)) {
      return onFilter && onFilter({ ...rangeFilter });
    }

    if (isNil(from)) {
      return onFilter && onFilter({ to });
    }
    if (isNil(to)) {
      return onFilter && onFilter({ from });
    }
    return onFilter && onFilter({ ...rangeFilter });
  };

  return (
    <MenuLayout
      filterContent={
        <Space>
          <Typography.Text>Từ</Typography.Text>
          <InputNumber
            value={rangeFilter.from}
            onChange={(value) => handleChange({ from: value })}
            min={0}
            max={rangeFilter.to}
          />
          <Typography.Text>-</Typography.Text>
          <InputNumber
            value={rangeFilter.to}
            onChange={(value) => handleChange({ to: value })}
            min={rangeFilter.from}
          />
        </Space>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
};

export default FilterFromTo;
