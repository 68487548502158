/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "antd/lib/form/Form";
import { CURRENCY_INPUT, IMPORT_REQUEST_TYPE } from "features/Purchase/constant";
import { useGetOrderLoadMore } from "hooks/order";
import { useGetDetailImportRequest } from "hooks/recommendimport";
import { useGetSellerCreateCart } from "hooks/seller";
import { useWarehouse } from "hooks/warehouse";
import { useUpdateImportRequest } from "hooks/warehouse/mutation";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const useEdit = () => {
  const [form] = useForm();
  const formData = form.getFieldsValue();
  const { id } = useParams();
  const { data, loading: loadingDetail } = useGetDetailImportRequest({ id: id });

  const images = data?.fileURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    ...data,
    type: data?.type,
    note: data?.note,
    fileURLs: images?.length > 0 && images[0].url?.length > 0 ? images : [],
    id: data?.id,
    processDeadline: data?.processDeadline ? moment(data?.processDeadline) : null,
    receiveMethod: data?.receiveMethod,
    receivingAddress: data?.receivingAddress,
    sellerID: data?.supplier?.id,
    orderID: data?.items && data?.items[0]?.order?.id,
    items: data?.items?.map((item) => ({
      product: {
        id: item?.product?.id,
        code: item?.product?.code,
        name: item?.product?.name,
      },
      quantityOrder: item?.order?.items?.find((i) => i?.product?.id === item?.product?.id)?.quantity,
      orderID: item?.order?.id,
      orderCode: item?.order?.number,
      productID: item?.product?.id,
      productName: item?.productName,
      quantity: item?.quantity,
      isVat: item?.isVat,
      importReason: item?.importReason,
      id: `${item?.product?.id}-${item?.order?.id}`,
      idItem: item?.id,
      currency: item?.currency,
      price: item?.price,
      serialCodes: item?.serials?.map((item) => item.code),
    })),
  };
  const [sellerInfo, setSellerInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [typeSalesReturn, setTypeSalesReturn] = useState();
  const [isDisabledSelectProduct, setIsDisabledProduct] = useState(true);

  const [visiblePopupChangeOrder, setVisiblePopupChangeOrder] = useState(false);
  const [visiblePopupChangeSeller, setVisiblePopupChangeSeller] = useState(false);

  const [paramsWarehouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { warehouses } = useWarehouse(paramsWarehouse);
  const valueImportWarehouse = warehouses?.map(({ id, name }) => ({ value: id, label: name }));
  const [dataTableProduct, setDataTableProduct] = useState([]);
  const history = useHistory();

  const [sumPrice, setSumPrice] = useState(0);
  const [currencyFirst, setCurrencyFirst] = useState(0);

  const [paramsOrder, setParamsOrder] = useState({
    filters: {
      query: "",
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });
  const [paramsOrderSeller, setParamsOrderSeller] = useState({
    filters: {
      query: "",
      sellerIDs: [data?.supplier?.id],
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: [],
  });

  const [deletedIDs, setdeletedIDs] = useState([]);
  const idItemsOld = data?.items?.map((item) => item?.id);
  const handleChangeTypeSalesReturn = (value) => {
    setTypeSalesReturn(value);
    setIsDisabledProduct(true);
    setDataTableProduct([]);
    setOrderInfo(null);
    setSellerInfo(null);
    form.setFieldsValue({
      sellerID: undefined,
      orderID: undefined,
      type: value,
      items: [],
    });
    setdeletedIDs(idItemsOld);
  };
  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isSeller: true },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    sellers,
    loadMoreData: loadMoreSeller,
    hasMoreData: hasMoreSeller,
    loading: loadingSeller,
  } = useGetSellerCreateCart(paramsSeller, "cache-first");

  const [paramsSellerEdit, setParamsSellerEdit] = useState({
    filters: {
      query: data?.supplier?.fullName,
      isSeller: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { sellers: sellerEdit } = useGetSellerCreateCart(paramsSellerEdit);

  const [paramsSellerMemory, setParamsSellerMemory] = useState({
    filters: {
      query: sellerInfo?.sellername || sellerInfo?.fullName,
      isSeller: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { sellers: sellerMemory } = useGetSellerCreateCart(paramsSellerMemory);

  const [paramsOrderEdit, setParamsOrderEdit] = useState({
    filters: {
      query: data?.items[0]?.order?.number,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const [paramsOrderMemory, setParamsOrderMemory] = useState({
    filters: {
      query: orderInfo?.orderCode || orderInfo?.number,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const onSearchSeller = (value) => {
    setParamsSeller({
      filters: {
        ...paramsSeller.filters,
        query: value || null,
        isSeller: true,
      },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSellerEdit({
      filters: {
        ...paramsSeller.filters,
        query: value || data?.supplier?.fullName,
        isSeller: true,
      },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsSellerMemory({
      filters: {
        ...paramsSeller.filters,
        query: value || sellerInfo?.sellername || sellerInfo?.fullName,
        isSeller: true,
      },
      pagination: { offset: 0, limit: 10 },
    });
  };
  const { orders: orderMemory } = useGetOrderLoadMore(paramsOrderMemory, "cache-first");

  const { orders: orderEdit } = useGetOrderLoadMore(paramsOrderEdit, "cache-first");

  const {
    orders,
    hasMoreData: hasMoreOrder,
    onLoadMoreData: loadMoreOrder,
    loading: loadingOrders,
  } = useGetOrderLoadMore(paramsOrder, "cache-first");

  const onSearchOrder = (value) => {
    setParamsOrder({
      filters: { ...paramsOrder.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsOrderEdit({
      filters: {
        ...paramsOrder.filters,
        query: value || data?.items[0]?.order?.number || undefined,
      },
      pagination: { offset: 0, limit: 10 },
    });
    setParamsOrderMemory({
      filters: {
        ...paramsOrder.filters,
        query: value || orderInfo?.orderCode || orderInfo?.number,
      },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const [valueSelectOrder, setValueSelectOrder] = useState();

  const [optionSelectProduct, setOptionSelectProduct] = useState([]);
  const handleGetOrder = (data) => {
    const listProduct = data.items?.map((item) => ({
      ...item?.product,
      orderID: data.id,
      orderCode: data.number,
    }));
    setOptionSelectProduct(listProduct);
    setIsDisabledProduct(false);
  };

  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      product: {
        id: data.id,
        code: data.code,
        name: data.name,
      },
      productID: data?.id,
      orderID: data?.orderID,
      productName: data?.name,
      quantity: null,
      price: null,
      currency: CURRENCY_INPUT?.VND,
      isVat: false,
      importReason: undefined,
      serialCodes: [],
      id: `${data?.id}-${data?.orderID}`,
    };

    const isDuplicateFormData = formData.items.some(
      (item) => item.productID === newData.productID && item.orderID === newData.orderID
    );
    if (!isDuplicateFormData) {
      formData.items.push(newData);
      form.setFieldsValue({
        ...formData,
      });
    }
    const newDataItemKey = `${data.id}-${data.orderID}`;
    const isDuplicate = dataTableProduct.some((item) => {
      const itemKey = `${item.productID}-${item.orderID}`;
      return itemKey === newDataItemKey;
    });
    const codeProduct = data?.code;
    const nameProduct = data?.name;
    if (!isDuplicate) {
      setDataTableProduct([
        ...dataTableProduct,
        {
          ...data,
          id: `${data?.id}-${data?.orderID}`,
          productID: data?.id,
          productName: data?.name,
          productCode: data?.code,
        },
      ]);
    } else {
      notify.error({
        message: t("warehouse.salesReturn.errorDuplicateProduct", { codeProduct, nameProduct }),
      });
    }
  };

  const handleDeleteProduct = (id, index, idItem) => {
    if (idItemsOld.includes(idItem)) {
      let ids = [...deletedIDs, idItem];
      setdeletedIDs(ids);
    }
    const dataFormDelete = formData?.items?.filter((item) => item.id !== id);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });
    const dataTableDelete = dataTableProduct?.filter((item) => item.id !== id);
    setDataTableProduct(dataTableDelete);
  };

  const { handleUpdateImportReques } = useUpdateImportRequest();
  const passwordInputRef = useRef(null);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handlePasswordKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      passwordInputRef.current.blur();
    }
  };

  function areAllElementsSame(arr) {
    if (arr.length <= 1) {
      return true;
    }
    const firstElement = arr[0];
    return arr.every((item) => item === firstElement);
  }

  const handleSubmitEdit = async () => {
    await form.validateFields().then(async (values) => {
      setLoadingEdit(true);
      const arrayWithUndefinedId = values?.items?.filter((item) => item.idItem === undefined);
      const arrayWithNonNullId = values?.items?.filter((item) => item.idItem !== undefined);
      const params = {
        type: values?.type,
        supplierID: values?.sellerID || orderInfo?.seller?.id,
        warehouseID: warehouses && warehouses[0]?.id,
        expandSalesReturnInput: {
          sellerID: values?.sellerID || orderInfo?.seller?.id,
          receivingAddress: values?.receivingAddress,
          processDeadline: values?.processDeadline,
          receiveMethod: values?.receiveMethod,
        },
        note: values?.note,
        fileURLs: values?.fileURLs,
        isResubmit: false,
        deletedIDs: deletedIDs,
        newItems: arrayWithUndefinedId?.map((item, i) => {
          return {
            id: null,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            isVat: item?.isVat,
            expandSalesReturnItemInput: {
              orderID: item?.orderID,
              importReason: item?.importReason,
              serialCodes: item?.serialCodes,
            },
          };
        }),
        updatedItems: arrayWithNonNullId?.map((item, i) => {
          return {
            id: item?.idItem,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            isVat: item?.isVat,
            expandSalesReturnItemInput: {
              orderID: item?.orderID,
              importReason: item?.importReason,
              serialCodes: item?.serialCodes,
            },
          };
        }),
      };
      const checkCurrency = values?.items?.map((item) => item?.currency);
      try {
        if (values?.items?.length > 0) {
          const resultCheckCurrency = areAllElementsSame(checkCurrency);
          if (resultCheckCurrency) {
            await handleUpdateImportReques({ id, request: params })
              .then(() => {
                notify.success({
                  message: t("warehouse.salesReturn.updateMessage"),
                });
                history.push(`/sales-return/detail/${id}`);
              })
              .catch((error) => {
                notify.error({
                  message: error.message,
                });
              });
          } else {
            notify.warning({
              message: t("warehouse.salesReturn.checkCurrencyMessage"),
            });
          }
        } else {
          notify.error({
            message: t("warehouse.create.PleaseChooseProduct"),
          });
        }
      } catch (error) {
        console.info(error);
      } finally {
        setLoadingEdit(false);
      }
    });
  };

  const handleSubmitEditAndSend = async () => {
    await form.validateFields().then(async (values) => {
      setLoadingEdit(true);
      const arrayWithUndefinedId = values?.items?.filter((item) => item.idItem === undefined);
      const arrayWithNonNullId = values?.items?.filter((item) => item.idItem !== undefined);
      const params = {
        type: values?.type,
        supplierID: values?.sellerID || orderInfo?.seller?.id,
        warehouseID: warehouses && warehouses[0]?.id,
        expandSalesReturnInput: {
          sellerID: values?.sellerID || orderInfo?.seller?.id,
          receivingAddress: values?.receivingAddress,
          processDeadline: values?.processDeadline,
          receiveMethod: values?.receiveMethod,
        },
        note: values?.note,
        fileURLs: values?.fileURLs,
        isResubmit: true,
        deletedIDs: deletedIDs,
        newItems: arrayWithUndefinedId?.map((item, i) => {
          return {
            id: null,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            isVat: item?.isVat,
            expandSalesReturnItemInput: {
              orderID: item?.orderID,
              importReason: item?.importReason,
              serialCodes: item?.serialCodes,
            },
          };
        }),
        updatedItems: arrayWithNonNullId?.map((item, i) => {
          return {
            id: item?.idItem,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            isVat: item?.isVat,
            expandSalesReturnItemInput: {
              orderID: item?.orderID,
              importReason: item?.importReason,
              serialCodes: item?.serialCodes,
            },
          };
        }),
      };
      const checkCurrency = values?.items?.map((item) => item?.currency);
      try {
        if (values?.items?.length > 0) {
          const resultCheckCurrency = areAllElementsSame(checkCurrency);
          if (resultCheckCurrency) {
            await handleUpdateImportReques({ id, request: params })
              .then(() => {
                notify.success({ message: t("warehouse.salesReturn.updateAndSendMessage") });
                history.push(`/sales-return/detail/${id}`);
              })
              .catch((error) => {
                notify.error({
                  message: error.message,
                });
              });
          } else {
            notify.warning({
              message: t("warehouse.salesReturn.checkCurrencyMessage"),
            });
          }
        } else {
          notify.error({
            message: t("warehouse.create.PleaseChooseProduct"),
          });
        }
      } catch (error) {
        console.info(error);
      } finally {
        setLoadingEdit(false);
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const [optionSellerMemory, setOptionSellerMemory] = useState(null);

  const handleOkPopupChangeSeller = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        items: [],
      });
    }
    setIsDisabledProduct(true);
    setVisiblePopupChangeSeller(false);
    setDataTableProduct([]);
    setdeletedIDs(idItemsOld);
    setParamsOrderSeller({
      ...paramsOrderSeller,
      filters: {
        ...paramsOrderSeller?.filters,
        sellerIDs: [optionSellerMemory?.id],
      },
    });
    setValueSelectOrder(undefined);
    setSellerInfo(optionSellerMemory);
  };

  const handleChangeInfoSeller = (value, option) => {
    if (formData?.items?.length === 0) {
      setSellerInfo(option?.seller);
      setParamsOrderSeller({
        ...paramsOrderSeller,
        filters: {
          ...paramsOrderSeller?.filters,
          sellerIDs: [option?.seller?.id],
        },
      });
      setValueSelectOrder(undefined);
      setIsDisabledProduct(true);
    }
    if (formData?.items?.length > 0) {
      setVisiblePopupChangeSeller(true);
    }
    setOptionSellerMemory(option?.seller);
  };

  const handleClosePopupChangeSeller = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        sellerID: sellerInfo?.id,
      });
    }
    setVisiblePopupChangeSeller(false);
  };

  const [temporary, setTemporary] = useState(null);
  const [optionOrder, setOptionOrder] = useState(null);

  const handleOkPopupChangeOrder = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        items: [],
      });
    }
    setdeletedIDs(idItemsOld);
    setDataTableProduct([]);
    setVisiblePopupChangeOrder(false);
    setOptionSelectProduct(temporary);
    setOrderInfo(optionOrder);
  };

  const handleChangeInfoOrder = (value, option) => {
    const listProduct = option?.order?.items.map((item) => ({
      code: item?.product?.code,
      id: item?.product?.id,
      name: item?.product?.name,
      quantityOrder: item?.quantity,
      orderCode: option?.order?.number,
      orderID: option?.order?.id,
      uom: {
        id: item?.product?.uom?.id,
        name: item?.product?.uom?.name,
      },
    }));

    if (formData?.items?.length === 0) {
      setOrderInfo(option?.order);
      setIsDisabledProduct(false);
      setOptionSelectProduct(listProduct);
    }

    if (formData?.items?.length > 0) {
      setVisiblePopupChangeOrder(true);
    }
    setTemporary(listProduct);
    setOptionOrder(option?.order);
  };

  const handleClosePopupChangeOrder = () => {
    if (formData?.items?.length > 0) {
      form.setFieldsValue({
        ...formData,
        orderID: orderInfo?.orderID || orderInfo?.id,
      });
    }
    setVisiblePopupChangeOrder(false);
  };

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
    setDataTableProduct(initialValues?.items);
    setOrderInfo({
      ...orderInfo,
      orderID: data?.items[0]?.order?.id,
      orderCode: data?.items[0]?.order?.number,
      seller: data?.supplier,
      exportedWarehouse: data?.items[0]?.order?.exportedWarehouse,
    });
    setIsDisabledProduct(data?.type === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY ? true : !data?.items);

    setSellerInfo({
      ...sellerInfo,
      ...data?.supplier,
    });

    const listProduct = data?.items[0]?.order?.items.map((item) => ({
      code: item?.product?.code,
      id: item?.product?.id,
      idItem: item?.id,
      name: item?.product?.name,
      order: item?.quantity,
      quantityOrder: item?.quantity,
      orderCode: data?.items[0]?.order?.number,
      orderID: data?.items[0]?.order?.id,
      uom: {
        id: item?.product?.uom?.id,
        name: item?.product?.uom?.name,
      },
    }));
    setOptionSelectProduct(listProduct);
    setTypeSalesReturn(data?.type);
  }, [data]);

  useEffect(() => {
    setParamsSellerEdit({
      filters: {
        query: data?.supplier?.fullName,
        isSeller: true,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
    setParamsOrderEdit({
      filters: {
        query: data?.items[0]?.order?.number,
      },
      pagination: {
        offset: 0,
        limit: 2,
      },
    });

    setParamsOrderSeller({
      ...paramsOrderSeller,
      filters: {
        ...paramsOrderSeller?.filters,
        sellerIDs: [data?.supplier?.id],
      },
    });
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setParamsSellerMemory({
      filters: {
        query: sellerInfo?.sellername || sellerInfo?.fullName,
        isSeller: true,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  }, [JSON.stringify(sellerInfo)]);

  useEffect(() => {
    setParamsOrderMemory({
      filters: {
        query: orderInfo?.orderCode || orderInfo?.number,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  }, [JSON.stringify(orderInfo)]);
  return {
    form,
    valueImportWarehouse,
    dataDetail: data,
    sellers,
    dataTableProduct,
    handleGetProduct,
    initialValues,
    handleChangeInfoSeller,
    sellerInfo,
    loadMoreSeller,
    hasMoreSeller,
    onSearchSeller,
    history,
    loadingEdit,
    handleDeleteProduct,
    formData,
    setSumPrice,
    setCurrencyFirst,
    currencyFirst,
    sumPrice,
    handlePasswordKeyPress,
    handleKeyPress,
    handleGetOrder,
    optionSelectProduct,
    orders,
    orderInfo,
    onSearchOrder,
    hasMoreOrder,
    loadMoreOrder,
    loadingOrders,
    handleChangeTypeSalesReturn,
    typeSalesReturn,
    isDisabledSelectProduct,
    handleChangeInfoOrder,
    visiblePopupChangeOrder,
    handleClosePopupChangeOrder,
    handleOkPopupChangeOrder,
    paramsOrderSeller,
    setParamsOrderSeller,
    handleSubmitEdit,
    loadingDetail,
    sellerEdit,
    visiblePopupChangeSeller,
    handleClosePopupChangeSeller,
    handleOkPopupChangeSeller,
    handleSubmitEditAndSend,
    orderEdit,
    valueSelectOrder,
    setValueSelectOrder,
    loadingSeller,
    sellerMemory,
    orderMemory,
  };
};

export default useEdit;
