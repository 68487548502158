import { Typography } from "antd";

function CustomUom({ uom, ...rest }) {
  return (
    <Typography.Text style={{ textAlign: "end", margin: 0, fontSize: "12px" }} italic>
      {uom?.name}
    </Typography.Text>
  );
}

export default CustomUom;
