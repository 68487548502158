import { Button, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useUpdateStatusImportRequest } from "hooks/recommendimport";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";
export const ButtonCancelRecommend = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const { handleUpdateStatusImportRequest, loading } = useUpdateStatusImportRequest();
  const onCancel = async () => {
    const params = {
      id: record?.id,
      actionType: "CANCEL",
    };
    await handleUpdateStatusImportRequest(params)
      .then(() => {
        notify.success({
          message: t("warehouse.recommendImport.messagecancelrequest"),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };
  return (
    <CustomModal
      {...{
        customComponent: (
          <Button className="button-action-recommend button-cancel-recommend">
            {t("warehouse.recommendImport.deleteRecommend")}
          </Button>
        ),
        footer: false,
        message: (
          <span>
            {t("warehouse.recommendImport.questioncancel")} <Typography.Link strong>{record?.code}</Typography.Link>{" "}
            {t("warehouse.recommendImport.no")}?
          </span>
        ),
        centered: true,
        styleButton: "notButton",
        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={onCancel} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    />
  );
};
