import React from "react";
import useFiltersTag from "./useFiltersTag";

const FiltersTag = ({ params, handleRemoveFilterTag }) => {
  const { renderTags, renderTagRealStocks, renderTagVat } = useFiltersTag({
    params,
    handleRemoveFilterTag,
  });
  return (
    <div style={{ marginBottom: "16px" }}>
      {renderTags}
      {renderTagRealStocks()}
      {renderTagVat()}
    </div>
  );
};

export default FiltersTag;
