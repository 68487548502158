import { PlusOutlined } from "@ant-design/icons";
import { Input, Tag, Typography } from "antd";
import { SERIAL_AVAILABILITY } from "config/constants";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { notify } from "utils/helperFuncs";
import "./index.scss";

const tagPlusStyle = {
  background: "transition",
  borderStyle: "dashed",
};

function InputTag({
  tags = [],
  onChange,
  searchResult = [],
  tagsValueResult,
  isHidden,
  idPopup,
  form,
  handleGetSerialExportNotesWarranty,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState(true);
  const [visibleSearchResult, setVisibleSearchResult] = useState(false);

  const formData = form?.getFieldsValue();

  const handleAdd = async () => {
    const newTags = inputValue
      ?.split(/[, ]+/)
      ?.filter((item) => item.trim() !== "")
      ?.map((tag) => tag?.trim());
    if (newTags?.length > 0) {
      const serialData = await handleGetSerialExportNotesWarranty({
        filter: {
          queries: newTags || [" "],
          // productIDs: record?.productID,
          statuses: SERIAL_AVAILABILITY.AVAILABLE,
          warehouseID: formData?.warehouseID,
          providerID: formData?.providerID,
        },
        pagination: {
          offset: 0,
          limit: 99,
          skipTotalCount: true,
        },
      });
      const serialGetData = serialData?.data?.serial?.pagination?.Serials?.map((item) => ({
        id: item?.id,
        code: item?.code,
      }));

      const serialCodeInValid = serialGetData?.map((item) => item.code);

      if (serialCodeInValid?.length > 0) {
        const newValues = [
          ...tags,
          ...serialGetData?.filter(
            (tag) => tag?.code !== "" && !tags?.some((existingTag) => existingTag?.code === tag?.code)
          ),
        ];
        onChange(newValues);
      }
      newTags
        ?.filter((item) => !serialCodeInValid?.includes(item))
        ?.map((item) => notify.warning({ message: `Serial/ IMEI ${item} không thỏa mãn điều kiện. Vui lòng thử lại` }));
    }
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleRemove = (removedValue) => {
    const newValues = tags.filter((val) => val !== removedValue);
    onChange(newValues);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputPressEnter = (event) => {
    event.preventDefault();
    handleAdd();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchResult?.length) {
      setVisibleSearchResult(true);
      setVisibleTags(false);
    } else {
      setVisibleSearchResult(false);
      setVisibleTags(true);
    }
  }, [JSON.stringify(searchResult)]);

  return (
    <div className={`input-tag-container-create`}>
      <div className={`${isHidden && "isHidden"}`}>
        <div className="input-tag-tags">
          {tags?.length > 8 && idPopup === false && (
            <Tag className="input-tag-tag custom-tags-by-serial">
              <Typography.Link italic>{`+${tags?.length - 8} serial...`}</Typography.Link>
            </Tag>
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === false &&
            tags?.slice(-8).map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val?.code}
              </Tag>
            ))
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === true &&
            tags.map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val?.code}
              </Tag>
            ))
          )}
          {visibleSearchResult &&
            searchResult?.map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val?.code}
              </Tag>
            ))}
        </div>
      </div>
      {idPopup ? (
        <></>
      ) : idPopup === false && inputVisible ? (
        <Input
          className="input-value input-enter-serial"
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 100,
          }}
          value={inputValue}
          onChange={handleChange}
          onPressEnter={handleInputPressEnter}
          placeholder={t("serialImportEdit.newSerial")}
        />
      ) : (
        <Tag className="input-value input-enter-serial" onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> {t("serialIEDetail.addNew")}
        </Tag>
      )}
    </div>
  );
}

export default InputTag;
