import React, { useState } from "react";
import CustomModal from "components/CustomModal";
import { Button, Col, Row, Space, Typography } from "antd";
import Card from "features/Cart/components/Card";
import { PlusCircleFilled } from "@ant-design/icons";

import { SvgIcon } from "assets/icons";
import { COMBO_CAMPAIGN } from "features/Campaign/constants";
import { t } from "i18next";
import { useFlags } from "flagsmith/react";

function ModalSelectComboCampaign({
  onGetValueComboButtons,
  selectedValueComboButtons,
  isDiscountMoney,
  isGift,
  isFixedPrice,
  form,
  initFormDiscountMoney,
  initFormFixedPrice,
  initFormGift,
}) {
  const [visible, setVisible] = useState(false);

  const flags = useFlags(["is_disable_promotion_fixed_price", "is_disable_promotion_gift"]);
  const isDisablePromotionFixedPrice = flags.is_disable_promotion_fixed_price.enabled;
  const isDisablePromotionGift = flags.is_disable_promotion_gift.enabled;

  const buttonData = [
    {
      key: COMBO_CAMPAIGN.DISCOUNT_MONEY,
      onClick: () => handleGetValueCombo(COMBO_CAMPAIGN.DISCOUNT_MONEY),
      disabled: isDiscountMoney,
      icon: <SvgIcon.DiscountIcon />,
      text: t("campaign.combo.comboDiscount"),
    },
    {
      key: COMBO_CAMPAIGN.GIFT,
      onClick: () => handleGetValueCombo(COMBO_CAMPAIGN.GIFT),
      disabled: isDisablePromotionGift || isGift,
      icon: <SvgIcon.GiftIcon />,
      text: t("campaign.combo.comboGift"),
    },
    {
      key: COMBO_CAMPAIGN.FIXED_PRICE,
      onClick: () => handleGetValueCombo(COMBO_CAMPAIGN.FIXED_PRICE),
      disabled: isDisablePromotionFixedPrice || isFixedPrice,
      icon: <SvgIcon.FixedPriceIcon />,
      text: t("campaign.combo.comboFixedPrice"),
    },
  ];

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleGetValueCombo = (value) => {
    onGetValueComboButtons && onGetValueComboButtons([...selectedValueComboButtons, value]);
    handleClose();
    value === COMBO_CAMPAIGN.DISCOUNT_MONEY &&
      form.setFieldsValue({
        promotionCampaignTierGroupsDiscountMoney: [initFormDiscountMoney],
      });
    value === COMBO_CAMPAIGN.FIXED_PRICE &&
      form.setFieldsValue({
        promotionCampaignTierGroupsFixedPrice: [initFormFixedPrice],
      });
    value === COMBO_CAMPAIGN.GIFT &&
      form.setFieldsValue({
        promotionCampaignTierGroupsGift: [initFormGift],
      });
  };

  return (
    <Card styles={{ marginBottom: "16px" }}>
      <CustomModal
        {...{
          customComponent: (
            <Typography.Link>
              <Space>
                <PlusCircleFilled />
                <Typography.Link>{t("campaign.combo.addNewCombo")}</Typography.Link>
              </Space>
            </Typography.Link>
          ),

          message: <Typography.Title level={4}>{t("campaign.combo.chooseTypeCombo")}</Typography.Title>,
          footer: false,
          hideConfirmButton: true,
          hideCancelButton: true,
          closable: false,
          width: "fit-content",
          onOpen: handleOpen,
          onCancel: handleClose,
          visible: visible,
          children: (
            <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
              {buttonData.map((button) => (
                <Button
                  key={button.key}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  style={{ width: "100%", height: "100%" }}
                >
                  <Row gutter={[16]} align="middle">
                    <Col span={8}>
                      <div>{button.icon}</div>
                    </Col>
                    <Col span={16}>
                      <Typography.Text strong style={{ textTransform: "uppercase" }}>
                        {button.text}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Button>
              ))}
            </Space>
          ),
        }}
      />
    </Card>
  );
}

export default ModalSelectComboCampaign;
