import React from "react";
import { Skeleton, Space, Typography } from "antd";

function ContentCardTab({ quantity, status, loading }) {
  return (
    <Space className="content-card" direction="vertical">
      <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
        <Typography.Text strong style={{ color: "#052987" }}>
          {quantity}
        </Typography.Text>
      </Skeleton>
      <Typography.Text>{status}</Typography.Text>
    </Space>
  );
}

export default ContentCardTab;
