import { Button, Col, Form, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import Card from "features/Cart/components/Card";
import { WRequestSolutionMethod, solutionLabel, titlePopupSolution } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import SelectStaffs from "../SelectStaffs";
import ShippingType from "./ShippingType";
import usePopupSolution from "./usePopupSolution";

function PopupSolution({
  solution,
  title,
  loading,
  confirmDistribute,
  itemIDs,
  selectedRows,
  selectedRowKeys,
  isOpen,
  handleCancel,
  setIsOpen,
  selfVisibleControlled,
  record,
}) {
  const {
    formProvider,
    formThird,
    formFieldsFormality,
    formSelf,
    form,
    initialValues,
    shippingType,
    handleClick,
    handleOpen,
    loadingBtn,
    staffs,
    onLoadMoreData,
    hasMoreData,
    onSearch,
    handleChange,
    handleValuesChange,
  } = usePopupSolution({
    confirmDistribute,
    solution,
    itemIDs,
    selectedRows,
    selectedRowKeys,
    isOpen,
    setIsOpen,
    record,
  });

  return (
    <CustomModal
      {...{
        customComponent: title,
        title: (
          <Typography.Text strong style={{ fontSize: "20px" }}>
            {titlePopupSolution?.[solution]}
          </Typography.Text>
        ),
        footer: false,
        hideConfirmButton: true,
        width: "50%",
        addMoreButton: (
          <Button type="primary" onClick={handleClick} loading={loadingBtn}>
            {t("common.confirm")}
          </Button>
        ),
        visible: isOpen,
        onCancel: handleCancel,
        onOpen: handleOpen,
        selfVisibleControlled: selfVisibleControlled,
      }}
    >
      <Card
        title={
          <Space>
            <Typography.Text strong>{t("warrantyRequest.popup.solution")}</Typography.Text>
            <Typography.Text strong style={{ color: "blue" }}>
              {solutionLabel?.[solution]}
            </Typography.Text>
          </Space>
        }
        children={
          <Form form={form} initialValues={initialValues} layout="vertical" onValuesChange={handleValuesChange}>
            <Row gutter={16}>
              {solution === WRequestSolutionMethod.PROVIDER &&
                formProvider.map((field, index) => {
                  return (
                    <Col span={24} xl={field.formItemOptions.name === "receivePartyName" ? 24 : 12} key={index}>
                      <FormInput
                        formItemOptions={field.formItemOptions}
                        type={field?.type}
                        inputOptions={field.inputOptions}
                      />
                    </Col>
                  );
                })}
              {solution === WRequestSolutionMethod.THIRD_PARTY &&
                formThird.map((field, index) => (
                  <Col span={24} xl={field.formItemOptions.name === "receivePartyName" ? 24 : 12} key={index}>
                    <FormInput
                      formItemOptions={field.formItemOptions}
                      inputOptions={field.inputOptions}
                      type={field?.type}
                    />
                  </Col>
                ))}
              {solution === WRequestSolutionMethod.SELF && (
                <Col span={24} xl={12}>
                  <SelectStaffs
                    staffs={staffs}
                    hasMoreData={hasMoreData}
                    onLoadMoreData={onLoadMoreData}
                    onSearch={onSearch}
                    handleChange={handleChange}
                  />
                </Col>
              )}
              {solution === WRequestSolutionMethod.SELF &&
                formSelf.map((field, index) => (
                  <Col span={24} xl={12} key={index}>
                    <FormInput
                      formItemOptions={field.formItemOptions}
                      inputOptions={field.inputOptions}
                      type={field?.type}
                    />
                  </Col>
                ))}

              {solution !== WRequestSolutionMethod.SELF &&
                formFieldsFormality.map((field, index) => (
                  <Col span={24} key={index}>
                    <FormInput
                      formItemOptions={field.formItemOptions}
                      inputOptions={field.inputOptions}
                      type={field?.type}
                    />
                  </Col>
                ))}

              {solution !== WRequestSolutionMethod.SELF && <ShippingType shippingType={shippingType} />}
            </Row>
          </Form>
        }
      />
    </CustomModal>
  );
}

export default PopupSolution;
