import { useGetPurchaseOrderDetail, useGetPurchaseOrderReferences } from "hooks/purchase";
import { useParams } from "react-router-dom";

const usePurchaseDetail = () => {
  const { id } = useParams();
  const { data: dataDetail, loading: loadingDetail } = useGetPurchaseOrderDetail({ id: id });

  const { data: dataReferences, loading: loadingReferences } = useGetPurchaseOrderReferences({ id: id });

  return {
    dataDetail,
    loadingDetail,
    dataReferences,
    loadingReferences,
  };
};

export default usePurchaseDetail;
