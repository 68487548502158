import { t } from "i18next";

export const PRODUCT_TYPE = {
  MATERIAL: "MATERIAL",
  SERVICE: "SERVICE",
  KEY_LICENSE: "KEY_LICENSE",
  ACCOUNT: "ACCOUNT",
};

export const OWNER_TYPE = {
  STAFF: "STAFF",
  SELLER: "SELLER",
};

export const STATUS_PRICE_BID = {
  WAITING_FOR_STAFF_CONFIRM: "WAITING_FOR_STAFF_CONFIRM",
  WAITING_FOR_SELLER_CONFIRM: "WAITING_FOR_SELLER_CONFIRM",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const convertActiveKey = (activeKey) => {
  switch (activeKey) {
    case "ALL":
      return [];
    case "WAITING_FOR_STAFF_CONFIRM":
      return [STATUS_PRICE_BID.WAITING_FOR_STAFF_CONFIRM];
    case "WAITING_FOR_SELLER_CONFIRM":
      return [STATUS_PRICE_BID.WAITING_FOR_SELLER_CONFIRM];
    case "APPROVED":
      return [STATUS_PRICE_BID.APPROVED];
    case "REJECTED":
      return [STATUS_PRICE_BID.REJECTED];
    case "COMPLETED":
      return [STATUS_PRICE_BID.COMPLETED];
    case "CANCELED":
      return [STATUS_PRICE_BID.CANCELED];
    default:
      return [];
  }
};

export const PROMOTION_PRICE_BID_STATUS = {
  ALL: "ALL",
  WAITING_FOR_STAFF_CONFIRM: "WAITING_FOR_STAFF_CONFIRM",
  WAITING_FOR_SELLER_CONFIRM: "WAITING_FOR_SELLER_CONFIRM",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const setColorStatusPriceBid = (status) => {
  switch (status) {
    case STATUS_PRICE_BID.WAITING_FOR_STAFF_CONFIRM:
      return "#052987";
    case STATUS_PRICE_BID.WAITING_FOR_SELLER_CONFIRM:
      return "#EF9351";
    case STATUS_PRICE_BID.APPROVED:
      return "#00AB78";
    case STATUS_PRICE_BID.REJECTED:
      return "#D2434D";
    case STATUS_PRICE_BID.CANCELED:
      return "#262F3B";
    default:
      break;
  }
};

export const setLabelStatusPriceBid = (status) => {
  switch (status) {
    case STATUS_PRICE_BID.WAITING_FOR_STAFF_CONFIRM:
      return t("product.priceBid.Pending");
    case STATUS_PRICE_BID.WAITING_FOR_SELLER_CONFIRM:
      return t("product.priceBid.WaitResponse");
    case STATUS_PRICE_BID.APPROVED:
      return t("product.priceBid.Accepted");
    case STATUS_PRICE_BID.REJECTED:
      return t("product.priceBid.Reject");
    case STATUS_PRICE_BID.CANCELED:
      return t("product.priceBid.Canceled");
    case STATUS_PRICE_BID.COMPLETED:
      return t("product.priceBid.completed");
    default:
      break;
  }
};

export const setLabelOwnerTypePriceBid = (ownerType, platformName) => {
  switch (ownerType) {
    case OWNER_TYPE?.STAFF:
      return t("product.priceBid.HongAnhOfferSeller", { platformName });
    case OWNER_TYPE?.SELLER:
      return t("product.priceBid.AgentsPay");
    default:
      break;
  }
};

export const ACTIONS = {
  EXPIRE: "EXPIRE",
  SELLER_ASSESS: "SELLER_ASSESS",
  SELLER_APPROVE: "SELLER_APPROVE",
  SELLER_REJECT: "SELLER_REJECT",
  STAFF_ASSESS: "STAFF_ASSESS",
  STAFF_APPROVE: "STAFF_APPROVE",
  STAFF_REJECT: "STAFF_REJECT",
  COMPLETE: "COMPLETE",
  CANCEL: "CANCEL",
};

export const actionsLabel = {
  EXPIRE: "Đề nghị bị hủy do quá hạn",
  SELLER_ASSESS: "Đại lý trả giá",
  SELLER_APPROVE: "Đại lý chấp nhận giá đề nghị",
  SELLER_REJECT: "Đề nghị bị hủy do sản phẩm bị xóa khỏi giỏ hàng",
  STAFF_ASSESS: "Hồng Anh chào bán giá mới",
  STAFF_APPROVE: "Hồng Anh chấp nhận đề nghị",
  STAFF_REJECT: "Hồng Anh từ chối đề nghị",
  COMPLETE: "Đề nghị hoàn thành do đại lý đã áp dụng giá đề nghị",
  CANCEL: "Đề nghị bị hủy do đại lý hủy phiên trả giá",
};

export const ACTIONS_OPTION = [
  {
    value: ACTIONS.EXPIRE,
    label: actionsLabel.EXPIRE,
  },
  {
    value: ACTIONS.SELLER_ASSESS,
    label: actionsLabel.SELLER_ASSESS,
  },
  {
    value: ACTIONS.SELLER_APPROVE,
    label: actionsLabel.SELLER_APPROVE,
  },
  {
    value: ACTIONS.SELLER_REJECT,
    label: actionsLabel.SELLER_REJECT,
  },
  {
    value: ACTIONS.STAFF_ASSESS,
    label: actionsLabel.STAFF_ASSESS,
  },
  {
    value: ACTIONS.STAFF_APPROVE,
    label: actionsLabel.STAFF_APPROVE,
  },
  {
    value: ACTIONS.STAFF_REJECT,
    label: actionsLabel.STAFF_REJECT,
  },
  {
    value: ACTIONS.COMPLETE,
    label: actionsLabel.COMPLETE,
  },
  {
    value: ACTIONS.CANCEL,
    label: actionsLabel.CANCEL,
  },
];
