import { Typography } from "antd";
import { DATE_FORMAT, INPUT_TYPE, REGEX } from "config/constants";
import { useFlags } from "flagsmith/react";
import { useLazySalesChannel } from "hooks/salesChannel";
import { t } from "i18next";
import moment from "moment";
import { useEffect } from "react";

const useCampaignInfo = ({ form, isUpdate }) => {
  const flags = useFlags(["is_disabled_saleschannel_web_van_hanh"]);
  const isDisabledSalesChannelWebVanHanh = flags.is_disabled_saleschannel_web_van_hanh.enabled;

  const { convertedData: dataSalesChannel, getSalesChannel } = useLazySalesChannel();

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const customDataSalesChannel = () => {
    return dataSalesChannel?.map((item) => {
      return {
        ...item,
        disabled: isDisabledSalesChannelWebVanHanh && item.label === "WEB VẬN HÀNH",
      };
    });
  };

  const handleGetDataSalesChannel = () => {
    !dataSalesChannel && getSalesChannel();
  };

  useEffect(() => {
    if (isUpdate) handleGetDataSalesChannel();
  }, []);

  const commonInfoFields = [
    {
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.campaignName")}</Typography.Text>,
        name: "promoName",
        rules: [
          {
            required: true,
            message: t("common.messageRequiredType", { message: t("campaign.form.campaignName") }),
          },
          {
            pattern: REGEX.TEXT_TRIM_SPACE,
            message: t("supplier.placeholderInput.whiteSpace"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("campaign.form.campaignNamePlaceholder"),
        maxLength: 100,
      },
    },
    {
      type: INPUT_TYPE.RANGE_PICKER,
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.campaignPeriod")}</Typography.Text>,
        name: "period",
        rules: [
          {
            required: true,
            message: t("common.messageRequiredSelect", {
              message: t("campaign.form.campaignPeriod"),
            }),
          },
        ],
      },
      inputOptions: {
        placeholder: [t("campaign.form.periodFromPlaceholder"), t("campaign.form.periodToPlaceholder")],
        separator: <>-</>,
        style: {
          width: "100%",
        },
        format: DATE_FORMAT,
        disabledDate: disabledDate,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.buyChannel")}</Typography.Text>,
        name: "buyChannels",
        rules: [
          {
            required: true,
            message: t("common.messageRequiredSelect", { message: t("campaign.form.buyChannel") }),
          },
        ],
      },
      inputOptions: {
        placeholder: t("campaign.form.buyChannelPlaceholder"),
        options: customDataSalesChannel(),
        mode: "multiple",
        filterOption: true,
        optionFilterProp: "label",
        onDropdownVisibleChange: () => handleGetDataSalesChannel(),
        allowClear: true,
        loading: !dataSalesChannel,
      },
    },
  ];

  const defaultBannerList = form?.getFieldsValue(["banner"])?.banner || [];

  return { commonInfoFields, defaultBannerList, form };
};
export default useCampaignInfo;
