import { Tag } from "antd";

function TagCustom({ color, children, ...props }) {
  return (
    <Tag color={color} {...props}>
      {children}
    </Tag>
  );
}

export default TagCustom;
