import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";

const useSelectLoadMore = ({ hasMoreData, onLoadMore, onSearch, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debounceValue = useDebounce(searchTerm);

  const handlePopupScroll = (e) => {
    const target = e.target;
    const scrollTop = target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const scrollHeight = target.scrollHeight;
    if (hasMoreData && scrollTop + offsetHeight === scrollHeight) {
      onLoadMore && onLoadMore();
    }
  };

  const handleSearch = (value) => setSearchTerm(value);

  const handleChange = (value, option) => {
    onChange && onChange(value, option);
    setSearchTerm("");
  };

  useEffect(() => {
    onSearch && onSearch(debounceValue);
  }, [debounceValue]);

  return {
    handlePopupScroll,
    handleSearch,
    debounceValue,
    handleChange,
  };
};

export default useSelectLoadMore;
