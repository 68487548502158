import TagFilterCustom from "components/_storybooks/TagFilterCustom";
import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import { useGetCreatedByWithIds, useGetSellerWithIds, useSalesChannelWithIds } from "hooks/valueWithIds";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import {
  COMPLETED_DATE_OPTIONS,
  EXPIRED_DATE_OPTIONS,
  formatBuildQueryString,
  ORDER_STATUS_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  RECONCILIATION_STATUS_OPTIONS,
} from "../../constants";

export function useToolbar({ params, onChangeParams }) {
  const GET_QUERY = getQuery();

  const { data: optionsCreatedBy, loading: loadingOptionsCreatedBy } = useGetCreatedByWithIds(
    GET_QUERY?.staffInChargeIDs
  );
  const { convertedData: dataSalesChannel, loading: loadingDataSalesChannel } = useSalesChannelWithIds(
    GET_QUERY?.saleChannelIDs
  );
  const { data: dataSellerWithIds, loading: loadingDataSellerWithIds } = useGetSellerWithIds(GET_QUERY.sellerIDs);

  const handleChangeDate = (value) => {
    const { from, to } = value;

    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
    formatBuildQueryString({
      ...params,
      activeKey: GET_QUERY.activeKey,
      from: from,
      to: to,
    });
  };

  const handleOnClose = (key) => {
    switch (key) {
      case "timeRange":
        onChangeParams &&
          onChangeParams({
            [`${key}`]: {
              from: undefined,
              to: undefined,
            },
          });
        formatBuildQueryString({
          ...params,
          activeKey: GET_QUERY.activeKey,
          from: undefined,
          to: undefined,
        });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const allTag = [
    {
      title: "Đại lý",
      options: dataSellerWithIds || [],
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
      loading: loadingDataSellerWithIds,
    },
    {
      title: "Hạn chót đốt soát",
      options: EXPIRED_DATE_OPTIONS || [],
      selected: params?.filters?.expiredDateStatuses,
      key: "expiredDateStatuses",
    },
    {
      title: "Kênh bán",
      options: dataSalesChannel || [],
      selected: params?.filters?.saleChannelIDs,
      key: "saleChannelIDs",
      loading: loadingDataSalesChannel,
    },
    {
      title: "Phương thức thanh toán",
      options: PAYMENT_METHOD_OPTIONS || [],
      selected: params?.filters?.paymentMethods,
      key: "paymentMethods",
    },
    {
      title: "Trạng thái đối soát",
      options: RECONCILIATION_STATUS_OPTIONS || [],
      selected: params?.filters?.reconciliationStatuses,
      key: "reconciliationStatuses",
      isHidden: GET_QUERY.activeKey !== "ALL",
    },
    {
      title: "Ngày hoàn thành đối soát",
      options: COMPLETED_DATE_OPTIONS || [],
      selected: params?.filters?.completedDateStatuses,
      key: "completedDateStatuses",
    },
    {
      title: "Trạng thái đơn hàng",
      options: ORDER_STATUS_OPTIONS || [],
      selected: params?.filters?.orderStatuses,
      key: "orderStatuses",
    },
    {
      title: "Nhân viên phụ trách",
      options: optionsCreatedBy || [],
      selected: params?.filters?.staffInChargeIDs,
      key: "staffInChargeIDs",
      loading: loadingOptionsCreatedBy,
    },
  ];

  const renderDateTag = () => {
    const from = formatDateTime(GET_QUERY?.from, DATE_FORMAT);
    const to = formatDateTime(GET_QUERY?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const renderTags = allTag
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key, isHidden, loading }) => {
      return (
        !isHidden && (
          <TagFilterCustom
            arrValue={selected}
            onClose={() => handleOnClose(key)}
            loading={loading}
            title={title}
            arrTags={options}
            isTimeRange={params?.filters?.timeRange?.from}
          />
        )
      );
    });

  return {
    renderTags,
    handleChangeDate,
    renderDateTag,
  };
}
