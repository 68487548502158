import { LeftOutlined, RightOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Image, Row, Skeleton, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PRODUCT_TYPE, UNIT_DATE, VAT_TYPE, convertUnit, keyOnlineTypeLabel } from "pages/App/Product/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { formatCurrency } from "utils/helperFuncs";
import ButtonSeeMore from "../ButtonSeeMore";
import ItemOdd from "../ItemOdd";
import "./index.scss";

const CommonInfo = ({ product, onChangeVariant }) => {
  const { t } = useTranslation();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [imgPreviewVisible, setImgPreviewVisible] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);

  const renderVAT = () => {
    const vatType = product?.vatType;
    const vatPercent = product?.vatPercent;

    if (vatPercent > -1 && vatType === VAT_TYPE.REQUIRED) {
      return <div style={{ color: "white" }}>(Giá đã bao gồm thuế VAT {vatPercent}%)</div>;
    }

    if (vatPercent > -1 && vatType === VAT_TYPE.OPTIONAL) {
      return <div style={{ color: "white" }}>(Tùy chỉnh xuất VAT)</div>;
    }

    if (vatPercent === -1) {
      return <div style={{ color: "white" }}>(Sản phẩm không chịu thuế VAT)</div>;
    }

    if (vatPercent > -1 && vatType === VAT_TYPE.NOT_REQUIRED) {
      return <div style={{ color: "white" }}>(Giá chưa bao gồm thuế VAT)</div>;
    }
  };
  return (
    <div className="common-info">
      <div className="card-container">
        <Row className="common-info-product-container">
          <Col xl={6} style={{ justifyContent: "center", display: "flex" }} span={24}>
            <Skeleton active loading={!product?.group}>
              <div className="image-carousels">
                <div>
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    arrows={false}
                    draggable={false}
                    fade={true}
                    initialSlide={currentImg}
                  >
                    {product?.group?.imageURLs?.map((item) => (
                      <div className="carousels-for" key={item}>
                        <Image
                          src={item}
                          preview={false}
                          className="image-carousel-for"
                          onClick={() => setImgPreviewVisible(true)}
                        />
                      </div>
                    )) || (
                      <div className="carousels-for">
                        <Skeleton.Image className="image-carousel-for" />
                      </div>
                    )}
                  </Slider>
                </div>
                <div className="slider-nav">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    swipeToSlide={true}
                    lazyLoad={true}
                    focusOnSelect={true}
                    centerMode={true}
                    arrows={true}
                    draggable={false}
                    variableWidth={true}
                    adaptiveHeight={true}
                    infinite={true}
                    prevArrow={<LeftOutlined />}
                    nextArrow={<RightOutlined />}
                    initialSlide={currentImg}
                    beforeChange={(_, next) => setCurrentImg(next)}
                  >
                    {product?.group?.imageURLs?.map((item) => (
                      <div className="carousels-nav" key={item}>
                        <img alt="" src={item} className="image-carousel-nav" />
                      </div>
                    )) || (
                      <div className="carousels-nav">
                        <Skeleton.Image style={{ display: "none" }} />
                      </div>
                    )}
                  </Slider>
                </div>
                <div style={{ display: "none" }}>
                  <Image.PreviewGroup
                    preview={{
                      visible: imgPreviewVisible,
                      onVisibleChange: (vis) => setImgPreviewVisible(vis),
                      current: currentImg,
                    }}
                  >
                    {product?.group?.imageURLs?.map((item) => (
                      <Image key={item} src={item} width="30px" height="30px" />
                    ))}
                  </Image.PreviewGroup>
                </div>
              </div>
            </Skeleton>
          </Col>
          <Col xl={10} style={{ justifyContent: "center", display: "flex" }} span={24}>
            <Skeleton active loading={!product?.group}>
              <div className="cental-info">
                <div>
                  <Space direction="vertical">
                    <Space className="prod-title">
                      <Typography.Text strong style={{ fontSize: "22px" }}>
                        {product?.name}&nbsp;&nbsp;
                      </Typography.Text>
                      <Button
                        type="text"
                        icon={<ShareAltOutlined style={{ color: "#2246DC" }} />}
                        size="small"
                        className="share-icon"
                      />
                    </Space>
                    <div className="prod-subtitle-1">
                      <Space>
                        <Typography.Text style={{ color: "#5B6673" }} className="subtitle-label">
                          {t("product.detail.code")}&nbsp;
                        </Typography.Text>
                        <Typography.Text strong className="subtitle-value">
                          {product?.code}
                        </Typography.Text>
                      </Space>
                    </div>
                    <div className="prod-subtitle-2">
                      <Space>
                        <Typography.Text style={{ color: "#5B6673" }} className="subtitle-label">
                          {t("product.detail.category")}&nbsp;
                        </Typography.Text>
                        <Typography.Text strong className="subtitle-value">
                          {product?.category?.name ? product?.category?.name : "- -"}
                        </Typography.Text>
                      </Space>
                    </div>
                    <div>
                      <Space
                        style={{
                          background: "#F3F3F7",
                          padding: "12px 16px",
                          borderRadius: "8px",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "#5B6673" }}>{t("product.brand")}</Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>{product?.group?.brand?.name}</Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "#5B6673" }}>
                              {t("product.productForm.warrantyPeriod")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {product?.group?.defaultProduct?.warranty}{" "}
                              {convertUnit(product?.group?.defaultProduct?.warrantyUnit)}
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "#5B6673" }}>
                              {t("v2.keyManagement.copyrightTerm")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {product?.group?.defaultProduct?.productType !== PRODUCT_TYPE.MATERIAL
                                ? product?.group?.defaultProduct?.copyrightUnit !== UNIT_DATE.FOREVER
                                  ? product?.group?.defaultProduct?.copyrightTerm +
                                    " " +
                                    convertUnit(product?.group?.defaultProduct?.copyrightUnit)
                                  : convertUnit(product?.group?.defaultProduct?.copyrightUnit)
                                : "--"}
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "#5B6673" }}>
                              {t("v2.keyManagement.keyOnlineType")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {product?.group?.defaultProduct?.productType !== PRODUCT_TYPE.MATERIAL &&
                              product?.group?.defaultProduct?.productType !== PRODUCT_TYPE.SERVICE
                                ? keyOnlineTypeLabel?.[product?.group?.defaultProduct?.productType]
                                : "--"}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Space>
                    </div>
                    <div className="prod-attributes">
                      {/* {attributeValuesRender?.map((att) => {
                        const whatAttValue = att?.attributeValue?.value
                          ? att?.attributeValue?.value
                          : att?.attributeValues;

                        const AttValueTag = (item) => {
                          return (
                            <span
                              className={`att-value-tag ${
                                product?.variantAttributeValues?.find(
                                  (variant) => variant.attributeValue.id === item.id
                                )
                                  ? "highlight"
                                  : ""
                              }`}
                              style={{
                                padding: "8px 12px",
                                border: "1px solid #E4E4EB",
                                borderRadius: "8px",
                                marginRight: "8px",
                                cursor: "pointer",
                              }}
                              key={item.id}
                              onClick={() => handleSelectAttValue(att.attributeID, item.id)}
                            >
                              {item.value}
                            </span>
                          );
                        };
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                            className="prod-att-item"
                            key={att.attributeID}
                          >
                            <Typography.Text
                              strong
                              style={{ marginBottom: "12px", marginTop: "8px" }}
                              className="prod-att-label"
                            >
                              {capitalizeFirstLetter(att.attribute.name)}
                            </Typography.Text>
                            <div className="tag-group">
                              {att?.attributeValue?.value
                                ? AttValueTag(att?.attributeValue)
                                : att?.attributeValues?.map((item) => {
                                    return AttValueTag(item);
                                  })}
                            </div>
                          </div>
                        );
                      })} */}
                    </div>
                    <div
                      style={{
                        borderRadius: "8px",
                        marginTop: "12px",
                        background: "rgba(36, 37, 51, 0.95)",
                        padding: "12px 16px",
                      }}
                    >
                      <Space
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: "6px",
                        }}
                      >
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white" }}>
                              {t("product.detail.priceLevel1")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white", fontSize: "16px" }} strong>
                              {formatCurrency(product?.productPrices?.level1Price)}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white" }}>
                              {t("product.detail.priceLevel2")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white", fontSize: "16px" }} strong>
                              {formatCurrency(product?.productPrices?.level2Price)}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white" }}>
                              {t("product.detail.priceLevel3")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white", fontSize: "16px" }} strong>
                              {formatCurrency(product?.productPrices?.level3Price)}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white" }}>
                              {t("product.detail.priceLevel4")}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text style={{ color: "white", fontSize: "16px" }} strong>
                              {formatCurrency(product?.productPrices?.level4Price)}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Space>
                      {renderVAT()}
                    </div>
                  </Space>
                </div>
              </div>
            </Skeleton>
          </Col>
          <Col xl={6} span={24}>
            <Skeleton active loading={!product?.group}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="parameter-info">
                  <div className="wrap-parameter-info">
                    <Typography.Text style={{ fontSize: "16px" }} strong>
                      {t("product.productForm.parameterInfo")}
                    </Typography.Text>
                    {product?.variantAttributeValues ? (
                      <>
                        <ItemOdd
                          //if hasVariable ? fullAtt : variantAtt
                          data={product?.variantAttributeValues?.map((item) => {
                            return {
                              label: item?.attribute?.name,
                              value: item?.attributeValue?.value,
                            };
                          })}
                          dimension={
                            product?.group?.defaultProduct?.productType === PRODUCT_TYPE.MATERIAL &&
                            product?.group?.dimension
                          }
                          weight={
                            product?.group?.defaultProduct?.productType === PRODUCT_TYPE.MATERIAL &&
                            product?.group?.weight
                          }
                        />
                        {product?.variantAttributeValues?.length > 7 && (
                          <CustomModal
                            footer={false}
                            hideCancelButton={true}
                            okeButtonLabel={t("common.close")}
                            customComponent={<ButtonSeeMore type={"link"} />}
                            title={
                              <Typography.Text strong style={{ fontSize: "16px" }}>
                                {t("product.productForm.parameterInfo")}
                              </Typography.Text>
                            }
                            children={
                              <Space direction="vertical" style={{ padding: "20px 0", width: "100%" }}>
                                <Typography.Link
                                  strong
                                  style={{
                                    borderRadius: "8px",
                                    padding: "12px",
                                    background: "rgba(231, 240, 255, 1)",
                                    display: "block",
                                  }}
                                >
                                  {product?.name}
                                </Typography.Link>
                                <ItemOdd
                                  //if hasVariable ? fullAtt : variantAtt
                                  fullCol={true}
                                  data={product?.variantAttributeValues?.map((item) => {
                                    return {
                                      label: item?.attribute?.name,
                                      value: item?.attributeValue?.value,
                                    };
                                  })}
                                />
                              </Space>
                            }
                          />
                        )}
                      </>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ padding: 20, margin: 0 }} />
                    )}
                  </div>
                </div>
              </div>
            </Skeleton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CommonInfo;
