import { useTranslation } from "react-i18next";
import { PAYMENT_METHOD, COMPANY_TYPE } from "./constants";
import { useGetCustomerLoadMore, useGetSellerLevels } from "hooks/seller";
import { useBanks, useCities, useDistricts, useWards } from "hooks/common";
import { useWarehouse } from "hooks/warehouse";
import { toLowerCaseNonAccentVietnamese } from "utils/helperFuncs";
import { useState } from "react";

const useAgent = (location) => {
  const { t } = useTranslation();
  const { sellerLevels } = useGetSellerLevels();
  const { cities } = useCities();
  const { districts } = useDistricts(location?.cityId);
  const { wards } = useWards(location?.districtId);
  const { warehouses, warehouseOptions } = useWarehouse();
  const { banks } = useBanks();

  const [params, setParams] = useState({
    filters: {
      query: undefined,
      isActive: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const {
    hasMoreData,
    loadMoreDataCustomers,
    options: customerOptions,
    refetch,
    customerList,
  } = useGetCustomerLoadMore(params);

  const onSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const paymentMethodOptions = [
    { value: PAYMENT_METHOD.DEBT, label: t("seller.debt") },
    { value: PAYMENT_METHOD.WALLET, label: t("seller.notDebt") },
  ];

  const companyOptions = [
    {
      label: t("seller.company"),
      value: COMPANY_TYPE.COMPANY,
    },
    {
      label: t("seller.personal"),
      value: COMPANY_TYPE.PERSONAL,
    },
  ];

  const sellerLevelsOptions = sellerLevels?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const citiesOptions = cities?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const districtsOptions = districts?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const wardsOptions = wards?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const banksOptions = banks?.map(({ id, name, fullName }) => ({
    label: `${name} - ${fullName}`,
    value: id,
  }));

  const handleSearchSelect = (search, option) =>
    toLowerCaseNonAccentVietnamese(option.label).includes(toLowerCaseNonAccentVietnamese(search));

  return {
    paymentMethodOptions,
    companyOptions,
    sellerLevelsOptions,
    citiesOptions,
    districtsOptions,
    wardsOptions,
    warehouseOptions,
    banksOptions,
    handleSearchSelect,
    hasMoreData,
    loadMoreDataCustomers,
    customerOptions,
    onSearch,
    refetch,
    customerList,
  };
};

export default useAgent;
