import { CloseOutlined } from "@ant-design/icons";
import { Col, Descriptions, Divider, Row, Skeleton, Space, Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import CustomDrawer from "components/CustomDrawer";
import { WARRANTY_TYPE, warrantyTypeLabel } from "features/Warranty/constant";
import { convertUnit } from "hooks/warranty/converter";
import { formatDate } from "utils/helperFuncs";

function CustomDivider({ title }) {
  return (
    <div className="custom-divider">
      <Divider orientation="left" plain style={{ margin: 0, borderTopColor: "rgba(5, 41, 135, 1)" }}>
        <Typography.Text style={{ color: "rgba(5, 41, 135, 1)", fontWeight: "bold" }}>{title}</Typography.Text>
      </Divider>
    </div>
  );
}

function DrawerInfoSerial({ openDrawer, handleOnClose, data }) {
  const dataDescriptions = data?.migratedSerialData?.map((item) => {
    return {
      serialName: item?.serial,
      productName: item?.name,
      providerName: item?.supplier,
      importDate: formatDate(item?.dateOfImport),
      warrantyType:
        item?.warrantyOnImportType === WARRANTY_TYPE.CUSTOM
          ? `${item?.warrantyOnImportPeriod || "--"} ${convertUnit(item?.warrantyOnImportPeriodUnit)}`
          : warrantyTypeLabel?.[item?.warrantyOnImportType],
      customerName: item?.customerName,
      customerPhone: item?.customerPhone,
      customerEmail: item?.customerEmail,
      saleDate: formatDate(item?.dateOfSale),
      warrantyOnSale:
        item?.warrantyOnSaleType === WARRANTY_TYPE.CUSTOM
          ? `${item?.warrantyOnSalePeriod || "--"} ${convertUnit(item?.warrantyOnSalePeriodUnit)}`
          : warrantyTypeLabel?.[item?.warrantyOnSaleType],
      branch: item?.branchName,
    };
  });

  return (
    <CustomDrawer
      visible={openDrawer}
      width={600}
      onClose={handleOnClose}
      customTitle={
        <Skeleton active loading={false}>
          <Row style={{ alignItems: "center" }}>
            <Col span={1}>
              <div onClick={handleOnClose} style={{ cursor: "pointer" }}>
                <CloseOutlined />
              </div>
            </Col>
            <Col span={23}>
              <Space
                className="content-header-sticky"
                style={{
                  backgroundColor: "rgba(231, 240, 255, 1)",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <Typography.Text style={{ fontSize: "14px" }}>Xem chi tiết</Typography.Text>{" "}
                  <Typography.Link style={{ fontSize: "14px" }} strong>
                    {dataDescriptions?.[0]?.serialName}
                  </Typography.Link>
                </Typography>
              </Space>
            </Col>
          </Row>
        </Skeleton>
      }
      content={
        <Row gutter={[16, 16]}>
          {dataDescriptions?.map((item, index) => (
            <Col span={24}>
              <CustomDescriptions
                title={<CustomDivider title={`Lần ${dataDescriptions?.length - index}`} />}
                content={
                  <>
                    <Descriptions.Item label="Serial">
                      {<Typography.Link strong>{item?.serialName}</Typography.Link> || "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tên sản phẩm">{item?.productName || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Nhà cung cấp">{item?.providerName || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Ngày nhập">{item?.importDate || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Bảo hành NCC">{item?.warrantyType || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Tên khách hàng">{item?.customerName || "--"}</Descriptions.Item>
                    <Descriptions.Item label="SĐT khách hàng">{item?.customerPhone || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Email khách hàng">{item?.customerEmail || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Ngày bán">{item?.saleDate || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Bảo hành khi bán">{item?.warrantyOnSale || "--"}</Descriptions.Item>
                    <Descriptions.Item label="Chi nhánh">{item?.branch || "--"}</Descriptions.Item>
                  </>
                }
              />
            </Col>
          ))}
        </Row>
      }
    />
  );
}

export default DrawerInfoSerial;
