import { CURRENCY_INPUT, DATE_PERIOD_UNIT } from "features/Purchase/constant";
import { uniqBy } from "lodash";
import { useEffect } from "react";

function useProductListLoadMore({ form, onGetProductList, productListSelected }) {
  const handleGetProduct = (value) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: value?.id,
      productName: value?.name,
      productCode: value?.code,
      currency: CURRENCY_INPUT?.VND,
      warranty: null,
      warrantyPeriod: DATE_PERIOD_UNIT?.MONTH,
      creditDays: null,
      quantity: null,
      afterVatPrice: null,
      vatPercent: value?.vatPercent,
      afterDiscountPrice: null,
      expectedDeliveryTime: null,
      beforeVatPrice: 0,
      intoMoney: 0,
      vatMoney: 0,
    };
    onGetProductList && onGetProductList(uniqBy([...productListSelected, newData], "productID"));
    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData?.items, newData], "productID"),
    });
  };

  const handleDeleteProduct = (id, index) => {
    const formData = { ...form.getFieldsValue() };
    const dataTableDelete = productListSelected?.filter((item) => {
      if (item.id) {
        return item.id !== id;
      } else {
        return item.productID !== id;
      }
    });
    form.setFieldsValue({
      ...formData,
      items: dataTableDelete,
    });
    onGetProductList && onGetProductList(dataTableDelete);
  };

  useEffect(() => {
    const formData = form.getFieldsValue();
    form.setFieldsValue({
      ...formData,
    });
  }, [form, productListSelected.length]);

  return { handleGetProduct, handleDeleteProduct };
}

export default useProductListLoadMore;
