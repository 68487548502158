import { useMutation, useQuery } from "@apollo/client";
import { convertGetAllOrder, convertGetCreatedBy, convertGetWarehouse, convertPagination } from "./converter";

import { USER_PERMISSIONS } from "config/constants";
import { GET_ME_QUERY } from "graphql/auth/query";
import {
  APPROVE_ORDER,
  APPROVE_PAYMENT,
  APPROVE_STOCK,
  CANCEL_ORDER,
  CANCEL_UPDATE_DETAIL,
  CLONE_CART_UPDATE_DETAIL,
  COMPLETED_ORDER,
  COMPLETE_ASSEMBLY,
  CONFIRM_CUSTOMER_PICKED,
  CONFIRM_D3P,
  CONFIRM_DELIVER,
  CONFIRM_EXPORT_ORDER,
  CONFIRM_PAYMENT,
  CONFIRM_PICKED_FOR_SHIPPING,
  CONFIRM_UPDATE_DETAIL,
  CREATE_ORDER,
  CREATE_ORDER_TO_SHIPPING_PROVIDER_INPUT,
  DENY_STOCK,
  EXPORT_ORDER,
  PACKAGE_ORDER,
  SET_DELIVERY_DEADLINE,
  SPLIT,
  UPDATE_SHIPPER,
  UPDATE_SHIPPING_PROVIDER,
  UPDATE_STATUS,
  WAITING_FOR_ENOUGH_STOCK,
} from "graphql/order/mutation";
import {
  COUNT_STATUS_ORDER,
  GET_CREATED_BY,
  GET_CREATED_BY_WITH_IDS,
  GET_ORDERS_QUERY,
  GET_ORDER_DETAIL_QUERY,
  GET_ORDER_FILTER_LOAD_MORE,
  GET_ORDER_LOAD_MORE,
  GET_SUMMARY_TABS,
  GET_WAREHOUSES,
  GET_WAREHOUSES_WITH_IDS,
} from "graphql/order/query";
import { useGetUserPermissions } from "hooks/user/user";
import { isEqual, omit, uniq } from "lodash";
import { useParams } from "react-router-dom";
import { hasPermission } from "utils/helperFuncs";

export const useGetOrders = (params) => {
  const { loading, data, fetchMore, refetch } = useQuery(GET_ORDERS_QUERY, {
    variables: omit(params, "activeKey"),
    skip: !params,
  });

  return {
    loading,
    data: convertGetAllOrder(data?.order?.pagination?.orders) || [],
    paginationData: data?.order?.pagination?.paginationData,
    fetchMore,
    refetchOrders: refetch,
  };
};

export const useGetWarehouses = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_WAREHOUSES);
  return {
    loading,
    data: convertGetWarehouse(data?.warehouse?.list?.warehouses),
    refetch,
    fetchMore,
  };
};

export const useGetCreatedBy = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_CREATED_BY, {
    variables: params,
    fetchPolicy: "cache-first",
  });

  const newData = data?.user?.pagination?.users;

  const loadMoreDataCreatedBy = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: newData.length, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv.user.pagination.paginationData,
          fetchMoreResult.user.pagination.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  return {
    loading,
    data: convertGetCreatedBy(newData) || [],
    paginationData: convertPagination(data?.user?.pagination?.paginationData),
    refetch,
    fetchMore,
    loadMoreDataCreatedBy,
  };
};

export const useGetSummaryTabs = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SUMMARY_TABS);
  return {
    loading,
    data: data?.order?.totalByStatus,
    refetch,
    fetchMore,
  };
};

export const useCreateOrder = () => {
  const [createOrder, { loading, error }] = useMutation(CREATE_ORDER);
  const handleCreateOrder = async (params) => {
    return await createOrder({
      variables: params,
      refetchQueries: [GET_ME_QUERY],
    });
  };
  return {
    handleCreateOrder,
    loading,
    error,
  };
};

export const useGetOrderDetail = ({ params }) => {
  const { loading, data, refetch } = useQuery(GET_ORDER_DETAIL_QUERY, {
    variables: params,
    skip: !params?.id,
  });
  return {
    loading,
    order: data?.order?.get,
    refetch,
  };
};

export const useUpdateStatus = () => {
  const [updateStatus, { loading, error }] = useMutation(UPDATE_STATUS);
  const handleUpdateStatus = async (params) => {
    return await updateStatus({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleUpdateStatus,
    loading,
    error,
  };
};

export const useApproveStock = () => {
  const [approveStock, { loading, error }] = useMutation(APPROVE_STOCK);
  const handleApproveStock = async (params) => {
    return await approveStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleApproveStock,
    loading,
    error,
  };
};

export const useCancelOrder = () => {
  const [cancelOrder, { loading, error }] = useMutation(CANCEL_ORDER);
  const handleCancelOrder = async (params) => {
    return await cancelOrder({
      variables: params,
      refetchQueries: [
        GET_SUMMARY_TABS,
        GET_ORDER_DETAIL_QUERY,
        GET_ORDERS_QUERY,
        COUNT_STATUS_ORDER,
        "GetOrderReconcilationQuery",
        "GetOrderReconciliationByID",
        "CountOrderReconciliation",
      ],
    });
  };
  return {
    handleCancelOrder,
    loading,
    error,
  };
};

export const useApprovePayment = () => {
  const [approvePayment, { loading, error }] = useMutation(APPROVE_PAYMENT);
  const handleApprovePayment = async (params) => {
    return await approvePayment({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleApprovePayment,
    loading,
    error,
  };
};

export const useConfirmPayment = () => {
  const [confirmPayment, { loading, error }] = useMutation(CONFIRM_PAYMENT);
  const handleConfirmPayment = async (params) => {
    return await confirmPayment({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmPayment,
    loading,
    error,
  };
};

export const useConfirmExportOrder = () => {
  const [confirmExportOrder, { loading, error }] = useMutation(CONFIRM_EXPORT_ORDER);
  const handleConfirmExportOrder = async (params) => {
    return await confirmExportOrder({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmExportOrder,
    loading,
    error,
  };
};

export const useCompletedOrder = () => {
  const [completedOrder, { loading, error }] = useMutation(COMPLETED_ORDER);
  const handleCompletedOrder = async (params) => {
    return await completedOrder({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleCompletedOrder,
    loading,
    error,
  };
};

export const useConfirmDeliver = () => {
  const [confirmDeliver, { loading, error }] = useMutation(CONFIRM_DELIVER);
  const handleConfirmDeliver = async (params) => {
    return await confirmDeliver({
      variables: params,
      skip: !params?.orderIDs || !params?.shipperID,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmDeliver,
    loading,
    error,
  };
};

export const useWaitingStock = () => {
  const [waitingStock, { loading, error }] = useMutation(WAITING_FOR_ENOUGH_STOCK);
  const handleWaitingStock = async (params) => {
    return await waitingStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleWaitingStock,
    loading,
    error,
  };
};

export const useDenyStock = () => {
  const [denyStock, { loading, error }] = useMutation(DENY_STOCK);
  const handleDenyStock = async (params) => {
    return await denyStock({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleDenyStock,
    loading,
    error,
  };
};

export const useCompleteAssembly = () => {
  const [completeAssembly, { loading, error }] = useMutation(COMPLETE_ASSEMBLY);
  const handleCompleteAssembly = async (params) => {
    return await completeAssembly({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleCompleteAssembly,
    loading,
    error,
  };
};

export const useGetWarehousesWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_WAREHOUSES_WITH_IDS, {
    variables: params,
    skip: params?.request?.ids === undefined,
  });

  return {
    loading,
    data: convertGetWarehouse(data?.warehouse?.list?.warehouses),
    refetch,
    fetchMore,
  };
};

export const useGetCreatedByWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_CREATED_BY_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });

  return {
    loading,
    data: convertGetCreatedBy(data?.user?.pagination?.users) || [],
    refetch,
    fetchMore,
  };
};

export const useOrderPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canCreateOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CREATE]),
    canViewOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_VIEW]),
    canApproveStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_APPROVE_STOCK]),
    canWaitStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_WAIT_STOCK]),
    canDenyStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_DENY_STOCK]),
    canConfirmPayment: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CONFIRM_PAYMENT]),
    canExportStock: hasPermission(permissions, [USER_PERMISSIONS.ORDER_EXPORT_STOCK]),
    canCompleteAssembly: hasPermission(permissions, [USER_PERMISSIONS.ORDER_COMPLETE_ASSEMBLY]),
    canAssignShipper: hasPermission(permissions, [USER_PERMISSIONS.ORDER_ASSIGN_SHIPPER]),
    canCompleteOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_COMPLETE]),
    canRecreateCart: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECREATE_CART]),
    canCancelOrder: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CANCEL]),
    canPromotionOrderView: hasPermission(permissions, [USER_PERMISSIONS.PROMOTION_ORDER_VIEW]),
    canOrderCancelBeforePayView: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CANCEL_BEFORE_PAY_CONFIRM]),
    canOrderCancelAfterPayView: hasPermission(permissions, [USER_PERMISSIONS.ORDER_CANCEL_AFTER_PAY_CONFIRM]),
    canApproveOrder: hasPermission(permissions, [USER_PERMISSIONS.APPROVE_ORDER]),
    loading,
  };
};

export const useExportOrder = () => {
  const [exportOrder, { loading, error }] = useMutation(EXPORT_ORDER);

  const handleExportOrder = async ({ orderID, fileType, orderIDs }) => {
    return await exportOrder({
      variables: {
        orderID,
        fileType,
        orderIDs,
      },
    });
  };
  return {
    handleExportOrder,
    loading,
    error,
  };
};

export const usePackageOrder = () => {
  const [packageOrder, { loading, error }] = useMutation(PACKAGE_ORDER);

  const handlePackageOrder = async ({ orderIDs }) => {
    return await packageOrder({
      variables: {
        orderIDs,
      },
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handlePackageOrder,
    loading,
    error,
  };
};

export const useConfirmD3P = () => {
  const [confirmD3P, { loading, error }] = useMutation(CONFIRM_D3P);
  const handleConfirmD3P = async (params) => {
    return await confirmD3P({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmD3P,
    loading,
    error,
  };
};

export const useSplit = () => {
  const [split, { loading, error }] = useMutation(SPLIT);
  const { handleCancelOrder, loading: loadingCancelOrder } = useCancelOrder();

  const handleSplit = async (params) => {
    return await split({
      variables: params,
    }).then(async () => {
      await handleCancelOrder({ orderIDs: [params?.orderID], reason: "Đơn hàng bị tách" });
    });
  };

  return {
    handleSplit,
    loadingSplit: loading || loadingCancelOrder,
    error,
  };
};

export const useConfirmCustomerPicked = () => {
  const [confirmCustomerPicked, { loading, error }] = useMutation(CONFIRM_CUSTOMER_PICKED);
  const handleConfirmCustomerPicked = async (params) => {
    return await confirmCustomerPicked({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmCustomerPicked,
    loading,
    error,
  };
};

export const useCloneCartUpdateDetail = () => {
  const [cloneCartUpdateDetail, { loading, error, data }] = useMutation(CLONE_CART_UPDATE_DETAIL);
  const handleCloneCartUpdateDetail = async (params) => {
    return await cloneCartUpdateDetail({
      variables: params,
    });
  };
  const { id } = useParams();
  const { order, loading: loadingOrder } = useGetOrderDetail({ id });

  return {
    handleCloneCartUpdateDetail,
    loading: loading || loadingOrder,
    error,
    data: { ...data, number: order?.number, status: order?.status },
  };
};

export const useConfirmUpdateDetail = () => {
  const [confirmUpdateDetail, { loading, error, data }] = useMutation(CONFIRM_UPDATE_DETAIL);

  const handleConfirmUpdateDetail = async (params) =>
    await confirmUpdateDetail({
      variables: params,
    });

  return {
    handleConfirmUpdateDetail,
    loading,
    error,
    data,
  };
};

export const useCancelUpdateDetail = () => {
  const [cancelUpdateDetail, { loading, error, data }] = useMutation(CANCEL_UPDATE_DETAIL);

  const handleCancelUpdateDetail = async (params) =>
    await cancelUpdateDetail({
      variables: params,
    });

  return {
    handleCancelUpdateDetail,
    loading,
    error,
    data,
  };
};

export const useGetOrderLoadMore = (params, fetchPolicy = "no-cache") => {
  const { data, loading, fetchMore } = useQuery(GET_ORDER_LOAD_MORE, {
    variables: params,
    fetchPolicy: fetchPolicy,
  });

  const orders = data?.order?.pagination?.orders || [];
  const currentTotal = orders?.length;
  const paginationData = data?.order?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotal, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.order?.pagination?.orders) return pv;
        const newData = {
          order: {
            pagination: {
              paginationData: { ...fetchMoreResult?.order?.pagination?.paginationData },
              orders: [...pv?.order?.pagination?.orders, ...fetchMoreResult?.order?.pagination?.orders],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);

  return {
    loading,
    paginationData,
    orders,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useChangeShipper = () => {
  const [updateShipper, { loading, error, data }] = useMutation(UPDATE_SHIPPER);

  const handleChangeShipper = async (params) =>
    await updateShipper({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });

  return {
    handleChangeShipper,
    loading,
    error,
    data,
  };
};

export const useApproveOrder = () => {
  const [approveOrder, { loading, error, data }] = useMutation(APPROVE_ORDER);

  const handleApproveOrder = async (params) =>
    await approveOrder({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });

  return {
    handleApproveOrder,
    loading,
    error,
    data,
  };
};

export const useCountStatusOrder = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(COUNT_STATUS_ORDER, {
    variables: params,
  });
  return {
    loading,
    data: data?.order?.count,
    refetch,
    fetchMore,
  };
};

export const useConfirmPickedForShipping = () => {
  const [confirmPickedForShipping, { loading, error }] = useMutation(CONFIRM_PICKED_FOR_SHIPPING);

  const handleConfirmPickedForShipping = async (params) => {
    return await confirmPickedForShipping({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });
  };
  return {
    handleConfirmPickedForShipping,
    loading,
    error,
  };
};

export const useCreateOrderToShippingProvider = () => {
  const [createOrderToShippingProvider, { loading, error, data }] = useMutation(
    CREATE_ORDER_TO_SHIPPING_PROVIDER_INPUT
  );

  const handleCreateOrderToShippingProvider = async (params) =>
    await createOrderToShippingProvider({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });

  return {
    handleCreateOrderToShippingProvider,
    loading,
    error,
    data,
  };
};

export const useUpdateShippingProvider = () => {
  const [updateShippingProvider, { loading, error, data }] = useMutation(UPDATE_SHIPPING_PROVIDER);

  const handleUpdateShippingProvider = async (params) =>
    await updateShippingProvider({
      variables: params,
      refetchQueries: [GET_SUMMARY_TABS, GET_ORDER_DETAIL_QUERY, GET_ORDERS_QUERY, COUNT_STATUS_ORDER],
    });

  return {
    handleUpdateShippingProvider,
    loading,
    error,
    data,
  };
};

export const useSetDeliveryDeadline = () => {
  const [setDeliveryDeadline, { loading, error }] = useMutation(SET_DELIVERY_DEADLINE);

  const handleSetDeliveryDeadline = async (params) =>
    await setDeliveryDeadline({
      variables: params,
      refetchQueries: [GET_ORDERS_QUERY, GET_ORDER_DETAIL_QUERY],
    });

  return {
    handleSetDeliveryDeadline,
    loading,
    error,
  };
};

export const useGetOrderFilterLoadMore = (params, fetchPolicy = "no-cache") => {
  const { data, loading, fetchMore } = useQuery(GET_ORDER_FILTER_LOAD_MORE, {
    variables: params,
    fetchPolicy: fetchPolicy,
  });

  const orders = uniq(data?.order?.pagination?.orders) || [];
  const currentTotal = orders?.length;
  const paginationData = data?.order?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotal || 0, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.order?.pagination?.orders) return pv;
        const newData = {
          order: {
            pagination: {
              paginationData: { ...fetchMoreResult?.order?.pagination?.paginationData },
              orders: [...pv?.order?.pagination?.orders, ...fetchMoreResult?.order?.pagination?.orders],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotal === paginationData?.total);

  return {
    loading,
    paginationData,
    orders,
    hasMoreData,
    onLoadMoreData,
  };
};
