import { useQuery } from "@apollo/client";
import { GET_ALL_LIST_PRICE_BID, GET_SUMMARY_TABS_PRICE_BID } from "graphql/priceBid/query";

export const useGetAllListPriceBid = (params) => {
  const { data, loading, error } = useQuery(GET_ALL_LIST_PRICE_BID, {
    variables: params,
  });

  return {
    data: data?.priceBidSession?.pagination?.priceBidSessions,
    loading,
    error,
  };
};

export const useGetSummaryTabsPriceBid = () => {
  const { data, refetch } = useQuery(GET_SUMMARY_TABS_PRICE_BID);
  return {
    data,
    refetch,
  };
};
