import React from "react";
import { Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useApproveBid } from "hooks/priceBid/mutation";
import { t } from "i18next";
import { formatCurrency, notify } from "utils/helperFuncs";

const PopupApproveBid = ({ record }) => {
  const { handleApprove, loading } = useApproveBid();

  const onApprove = async () => {
    try {
      await handleApprove({ priceBidSessionID: record?.id });
      notify.success({ message: t("product.priceBid.acceptRequestPriceBid") });
    } catch (error) {
      notify.error({ message: error?.message });
    }
  };

  return (
    <CustomModal
      footer={false}
      customComponent={<Typography.Text>{t("product.priceBid.Accept")}</Typography.Text>}
      message={
        <Typography.Text>
          {t("product.priceBid.QuestionAccept") +
            formatCurrency(record?.currentSuggestedPrice) +
            `/` +
            record?.product?.uom?.name +
            t("product.priceBid.give") +
            " " +
            record?.currentSuggestedQuantity +
            t("product.priceBid.Product") +
            " : " +
            record?.product?.name +
            t("product.priceBid.no") +
            "?"}
        </Typography.Text>
      }
      onOke={onApprove}
      buttonLoading={loading}
    />
  );
};

export default PopupApproveBid;
