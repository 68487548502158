import { Col, Descriptions, Typography } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";
import { DATE_FORMAT } from "config/constants";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { IMPORT_TYPE } from "../../../constant";
import "./index.scss";

export const InfoByType = ({ data }) => {
  const infoFirstTimeImport = (
    <Col span={24}>
      <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
        <Descriptions.Item label={t("serialIE.providerInfo.name")} span={1}>
          {data?.provider?.fullName || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.saleDate")} span={3}>
          {formatDateTime(data?.soldDateFromProvider, DATE_FORMAT) || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.phone")} span={1}>
          {data?.provider?.telephone || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.email")} span={1}>
          {data?.provider?.email || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.address")} span={1}>
          {data?.provider?.address || "- -"}
        </Descriptions.Item>
      </Descriptions>
    </Col>
  );

  const infoReturn = (
    <Col span={24}>
      <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
        <Descriptions.Item label={t("serialIE.providerInfo.orderCode")} span={1}>
          <Link to={`/order/${data?.order?.id}`}>
            <Typography.Link>{data?.order?.number || "- -"}</Typography.Link>
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.seller")} span={1}>
          {data?.seller?.fullName || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.phone")} span={1}>
          {data?.seller?.telephone || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.email")} span={1}>
          {data?.seller?.email || "- -"}
        </Descriptions.Item>
        <Descriptions.Item label={t("serialIE.providerInfo.warehouse")} span={1}>
          {data?.warehouse?.name || "- -"}
        </Descriptions.Item>
      </Descriptions>
    </Col>
  );

  const infoTransferSerial = (
    <Col span={24}>
      <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
        <Descriptions.Item label="Kho nhập">{data?.warehouse?.name || "- -"}</Descriptions.Item>
        <Descriptions.Item label="Địa chỉ">
          <CustomInfoAdress value={data?.warehouse}></CustomInfoAdress>
        </Descriptions.Item>
        <Descriptions.Item label="Chi nhánh">{data?.warehouse?.branch?.name || "- -"}</Descriptions.Item>
      </Descriptions>
    </Col>
  );
  const infoOther = (
    <Col span={24}>
      <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
        <Descriptions.Item label="Kho nhập">{data?.warehouse?.name || "- -"}</Descriptions.Item>
        <Descriptions.Item label="Địa chỉ">
          <CustomInfoAdress value={data?.warehouse}></CustomInfoAdress>
        </Descriptions.Item>
        <Descriptions.Item label="Chi nhánh">{data?.warehouse?.branch?.name || "- -"}</Descriptions.Item>
      </Descriptions>{" "}
    </Col>
  );

  const renderInfoByType = {
    [`${IMPORT_TYPE.FIRST_TIME_IMPORT}`]: infoFirstTimeImport,
    [`${IMPORT_TYPE.RETURN}`]: infoReturn,
    [`${IMPORT_TYPE.RE_IMPORT_WARRANTY}`]: null,
    [`${IMPORT_TYPE.TRANSFER_SERIAL}`]: infoTransferSerial,
    [`${IMPORT_TYPE.OTHER}`]: infoOther,
  };
  return { renderInfoByType };
};
