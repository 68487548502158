import { Form } from "antd";
import FormInput from "components/FormInput";
import { FORM_LAYOUT, INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import React from "react";
import useAddressForm from "./useAddressForm";

const AddressForm = ({ form, initialValues, handleValuesChange }) => {
  const { formFields } = useAddressForm({
    cityId: initialValues.cityID,
    districtId: initialValues.districtID,
    initialValues,
    form,
  });

  return (
    <Form form={form} initialValues={initialValues} {...FORM_LAYOUT} onValuesChange={handleValuesChange}>
      <div className="add-address-form">
        {formFields.map((field, index) => {
          return <FormInput key={index} {...field} />;
        })}

        <div className="address-input">
          <FormInput
            {...{
              formItemOptions: {
                label: t("seller.labelInput.address"),
                name: "address",
              },
              inputOptions: {
                placeholder: t("seller.placeholderInput.address"),
                maxLength: 255,
              },
            }}
          />

          <Form.Item>
            <FormInput
              {...{
                type: INPUT_TYPE.CHECK_BOX,
                formItemOptions: {
                  name: "isDefault",
                  valuePropName: "checked",
                },
                inputChildren: t("seller.componentAddressList.setupDefault"),
              }}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default AddressForm;
