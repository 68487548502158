import { MinusOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import BadgeStatusDot from "components/_storybooks/BadgeStatusDotCustom";
import { useProfitPermissions } from "hooks/orderReconciliation";
import ButtonCancelOrder from "../../components/ButtonCancelOrder";
import ButtonCompleteReconciliation from "../../components/ButtonCompleteReconciliation";
import ButtonRejectReconciliation from "../../components/ButtonRejectReconciliation";
import ButtonStartReconciliation from "../../components/ButtonStartReconciliation";
import { PROFIT_RECONCILIATION, reconciliationStatusConverter } from "../../constants";

function HeaderTitle({ data, handleMouseDown }) {
  const { canProfitComplete, canProfitReject, canProfitStart } = useProfitPermissions();

  const isShowViaStatus = (status, condition) => {
    switch (condition) {
      case "ButtonCancelOrder":
        return status === PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT || status === PROFIT_RECONCILIATION.RECONCILING;
      case "ButtonStartReconciliation":
        return status === PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT && canProfitStart;
      case "ButtonRejectReconciliation":
        return status === PROFIT_RECONCILIATION.RECONCILING && canProfitReject;
      case "ButtonCompleteReconciliation":
        return status === PROFIT_RECONCILIATION.RECONCILING && canProfitComplete;
      case "ReasonBlock":
        return status === PROFIT_RECONCILIATION.REJECTED || status === PROFIT_RECONCILIATION.CANCELED;
      default:
        return false;
    }
  };

  return (
    <>
      <MinusOutlined
        onMouseDown={handleMouseDown}
        style={{
          width: "100%",
          cursor: "ns-resize",
          position: "absolute",
          left: 0,
          zIndex: 100,
          padding: 0,
          top: "-5px",
          right: 0,
        }}
      />

      <Space style={{ paddingLeft: 30, width: "100%", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Space>
          <Space style={{ width: "100%" }}>
            <Typography.Text strong style={{ paddingRight: "12px" }}>
              Đơn hàng
            </Typography.Text>
            <Typography.Link strong>{data?.order?.number || "--"}</Typography.Link>
          </Space>
          <BadgeStatusDot
            status={reconciliationStatusConverter(data?.status)?.status}
            text={reconciliationStatusConverter(data?.status)?.text}
          />
        </Space>
        <Space>
          {isShowViaStatus(data?.status, "ButtonCancelOrder") && <ButtonCancelOrder data={data} />}
          {isShowViaStatus(data?.status, "ButtonStartReconciliation") && <ButtonStartReconciliation data={data} />}
          {isShowViaStatus(data?.status, "ButtonRejectReconciliation") && <ButtonRejectReconciliation data={data} />}
          {isShowViaStatus(data?.status, "ButtonCompleteReconciliation") && (
            <ButtonCompleteReconciliation data={data} />
          )}
        </Space>
      </Space>
    </>
  );
}

export default HeaderTitle;
