import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Space } from "antd";
import { Icon } from "assets/icons";
import CreateButton from "components/CreateButton";
import CustomTable from "components/CustomTable";
import PeriodSelect from "components/PeriodSelect";
import { t } from "i18next";
import InviteSellerButton from "pages/App/Agent/List/components/InviteSellerButton";
import SellerTagList from "pages/App/Agent/List/components/SellerTagList";
import { Link } from "react-router-dom";
import useAgentTab from "./useAgentTab";

export default function AgentTab() {
  const {
    loading,
    data,
    canCreate,
    canCreateSupplier,
    columns,
    params,
    selectedRowKeys,
    paginationData,
    countSellerInvited,
    handleTableChange,
    handleFilterSellerByTimeRange,
    handleInvitingSellers,
    handleCloseTag,
    handleSearch,
    setSelectedRowKeys,
    setSelectedRow,
  } = useAgentTab();

  return (
    <Row gutter={[16, 8]}>
      <Col span={8}>
        <Input
          prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
          placeholder={t("seller.searchInputPlaceholder")}
          allowClear={true}
          defaultValue={params?.filters?.query}
          onChange={handleSearch}
        />
      </Col>
      <Col span={4}>
        <PeriodSelect onChange={handleFilterSellerByTimeRange} params={params} />
      </Col>
      <Col span={12}>
        <Space style={{ width: "100%", justifyContent: "end" }}>
          <InviteSellerButton
            onConfirm={handleInvitingSellers}
            countSeller={countSellerInvited().countUninvited}
            isAllInvited={countSellerInvited().isAllInvited}
          />
          {(canCreate || canCreateSupplier) && (
            <Link to="/seller/create">
              <CreateButton title={t("seller.addSeller")} />
            </Link>
          )}
        </Space>
      </Col>
      <Col span={24}>
        <SellerTagList filters={params.filters} onClose={handleCloseTag} />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          rowKey={(record) => record?.id}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              setSelectedRow(selectedRows);
            },
            selectedRowKeys: selectedRowKeys,
            preserveSelectedRowKeys: true,
          }}
        />
      </Col>
    </Row>
  );
}
