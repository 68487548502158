import { useMutation } from "@apollo/client";
import { GET_ME_QUERY } from "graphql/auth/query";
import { MONEY_TRANSFER_MUTATION } from "graphql/money-transfer/mutation";
import { GET_SELLERS_WITH_WALLET } from "graphql/seller/query";

export const useTransferMoney = () => {
  const [transfer, { loading }] = useMutation(MONEY_TRANSFER_MUTATION);

  const handleTransferMoney = async ({ source, target, amount, description, fileUrls, transferType }) => {
    return await transfer({
      variables: {
        source,
        target,
        amount,
        description,
        fileUrls,
        transferType,
      },
      refetchQueries: [GET_ME_QUERY, GET_SELLERS_WITH_WALLET],
    });
  };

  return {
    loading,
    handleTransferMoney,
  };
};
