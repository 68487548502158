import { useEffect, useState } from "react";

const useUploadBannerButton = ({ value, onChange }) => {
  const [bannerFiles, setBannerFiles] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [bannerSize, setBannerSize] = useState("");

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  // get reader result img file and img resolution
  const getBase64ImgRez = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        let imgRez = (image.onload = () => {
          const { naturalHeight, naturalWidth } = image;
          return `${naturalWidth}px x ${naturalHeight}px`;
        });
        return resolve({ fileResult: reader.result, imgRez: imgRez });
      };
      reader.onerror = (error) => reject(error);
    });

  async function getUrlImgRez(url) {
    function getMeta(url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject();
        img.src = url;
      });
    }

    let image = await getMeta(url);

    const { naturalHeight, naturalWidth } = image;
    return `${naturalWidth}px x ${naturalHeight}px`;
  }

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const handleChange = async ({ file, fileList, event }) => {
    if (file.status !== "removed") {
      if (!file.url && !file.preview) {
        file.preview = await getBase64ImgRez(file.originFileObj);
        setBannerSize(file.preview.imgRez || "");
        triggerChange(fileList);
        setBannerFiles(fileList);
      }
      if (file.url) {
        await getUrlImgRez(file.url).then((res) => {
          setBannerSize(() => res);
        });
      }
      setPreviewImage(file.url || file.preview.fileResult || "");
    } else {
      setPreviewImage("");
      setBannerSize("");
      triggerChange([]);
      setBannerFiles([]);
    }
  };

  const handleTogglePreview = (vis) => setPreviewOpen(vis);

  const handleToggleButton = (condition, buttonUI) => (condition ? null : buttonUI);

  useEffect(() => {
    if (value?.length > 0) {
      setBannerFiles(value);
      handleChange({ file: value?.at(0), value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.length]);

  return {
    bannerFiles,
    previewOpen,
    previewImage,
    bannerSize,
    handlePreview,
    handleChange,
    handleTogglePreview,
    handleToggleButton,
  };
};

export default useUploadBannerButton;
