import { PlusCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { t } from "i18next";
import React from "react";
import "./index.scss";
const EmptyCart = () => {
  return (
    <Space direction="vertical" align="center" className="container-empty-cart">
      <SvgIcon.EmptyIcon />
      <Typography.Text style={{ color: "#5B6673" }}>{t("cart.youDoNotHaveACartYet")}</Typography.Text>
      <Typography.Link style={{}}>
        {t("cart.pleaseClickTheIcon")} <PlusCircleOutlined /> {t("cart.inTheLeftCornerScreen")}
      </Typography.Link>
    </Space>
  );
};

export default EmptyCart;
