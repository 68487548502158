import React from "react";
import { Button, Form, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import FormEditOwner from "features/WarrantyRequest/components/FormEditOwner";
import { t } from "i18next";
import useModalEditOwner from "./useModalEditOwner";

function ModalEditOwner({ warrantyRequestItem, onSubmit }) {
  const { initialValues, form, handleEdit, switchClose, loading, handleOpen, handleCancel } = useModalEditOwner({
    warrantyRequestItem,
    onSubmit,
  });

  return (
    <CustomModal
      {...{
        title: (
          <Typography.Text strong style={{ fontSize: "20px" }}>
            {t("warrantyRequest.detail.updateSerial")}
          </Typography.Text>
        ),
        customComponent: (
          <Tooltip title={t("warrantyRequest.detail.edit")}>
            <SvgIcon.EditIcon />
          </Tooltip>
        ),
        width: "50%",
        footer: false,
        centered: true,
        hideConfirmButton: true,
        visible: switchClose,
        onOpen: handleOpen,
        onCancel: handleCancel,
        addMoreButton: (
          <Button type="primary" onClick={handleEdit} loading={loading}>
            {t("common.save")}
          </Button>
        ),
      }}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" style={{ padding: "0 10px" }}>
        <FormEditOwner dataRow={warrantyRequestItem} />
      </Form>
    </CustomModal>
  );
}

export default ModalEditOwner;
