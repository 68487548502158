import React from "react";
import { Col, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import styles from "./index.module.scss";
import useConditionInfo from "./useConditionInfo";

const ConditionInfo = () => {
  const { conditionFields } = useConditionInfo();

  return (
    <Card title={<Typography.Title level={5}>{t("campaign.form.condition")}</Typography.Title>}>
      <Row className="custom-form-campaign" gutter={50}>
        {conditionFields.map((field, index) => {
          return (
            <Col span={8}>
              <FormInput key={index} {...field} />
            </Col>
          );
        })}
      </Row>
      <div className={`${styles["warning"]}`}>
        <span className={`${styles["warning-label"]}`}>{t("campaign.form.attention")}:</span>
        <i>{t("campaign.form.attentionMsg")}</i>
      </div>
    </Card>
  );
};

export default ConditionInfo;
