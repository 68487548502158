import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Pagination, Row } from "antd";
import "./index.scss";

export const CustomPaginationPrevNext = ({ handlePrevNextPage, paginationData }) => {
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return;
    }
    if (type === "next") {
      return;
    }

    return (
      <Row className="pagination-list-button">
        <Button
          onClick={() => handlePrevNextPage(paginationData?.offset - paginationData?.limit)}
          className="button-prev"
          disabled={!(paginationData?.offset && paginationData?.offset !== 0)}
        >
          <LeftOutlined />
        </Button>
        <Button
          onClick={() => handlePrevNextPage(paginationData?.offset + paginationData?.limit)}
          className="button-next"
          disabled={!(paginationData?.cursorHasNext === true)}
        >
          <RightOutlined />
        </Button>
      </Row>
    );
  };
  return (
    <div className="custom-pagination-prev-next">
      <Pagination itemRender={itemRender} />
    </div>
  );
};
