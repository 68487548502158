import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect/index";
import StateSwitch from "components/StateSwitch";
import { t } from "i18next";
import ModalProducts from "../ModalProducts";
import useSelectProduct from "./useSelectProduct";

const SelectProduct = ({
  onChange,
  warehouseID,
  sellerID,
  handleAddNewItems,
  idsProductsInCart,
  exportedWarehouseID,
}) => {
  const { options, onLoadMoreData, hasMoreData, onSearch, handleChange, handleFilterInStock, loading } =
    useSelectProduct({
      onChange,
      warehouseID,
      sellerID,
      exportedWarehouseID,
    });

  return (
    <SelectLoadMore
      inputOptions={{
        placeholder: t("cart.searchProductPlaceholder"),
        options: options,
        value: null,
        dropdownMatchSelectWidth: 1000,
        allowClear: false,
        dropdownRender: (originNode) => (
          <>
            <Row gutter={16} style={{ background: "#F7F8FB" }}>
              <Col span={7}>
                <Typography
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  {t("product.tableTitle.code")}
                </Typography>
              </Col>
              <Col span={12}>
                <Typography
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  {t("product.tableTitle.name")}
                </Typography>
              </Col>
              <Col span={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    padding: "12px 0px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  {t("product.tableTitle.inStock")}
                </Typography>
              </Col>
              <Col span={3}>
                <Typography
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  {t("product.tableTitle.price")}
                </Typography>
              </Col>
            </Row>
            <Spin spinning={loading}>{originNode}</Spin>
            <Row gutter={16} style={{ background: "#E7F0FF" }}>
              <Col span={7}>
                <ModalProducts
                  customComponent={
                    <Button type="link" icon={<SearchOutlined />}>
                      {t("cart.advancedSearch")}
                    </Button>
                  }
                  handleAddNewItems={handleAddNewItems}
                  idsProductsInCart={idsProductsInCart}
                  warehouseID={warehouseID}
                  sellerID={sellerID}
                />
              </Col>
              <Col
                span={16}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <StateSwitch onChange={handleFilterInStock} />
                <Typography.Text ellipsis={true}> {t("cart.showOnlyInventory")}</Typography.Text>
              </Col>
            </Row>
          </>
        ),
        dropdownStyle: { zIndex: "1000" },
        // style: { width: 391 },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
};

export default SelectProduct;
