import { useEffect, useState } from "react";
import { COMBO_CAMPAIGN, DISCOUNT_TYPE, TYPE_LIMIT } from "features/Campaign/constants";

function useOneOnProductForm({ form, dataTiersServer, isUpdate }) {
  const [dataSource, setDataSource] = useState([]);
  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);

  // Tính tổng ngân sách từ một mảng
  const calculateTotalBudget = (campaignGroups) => {
    return (
      campaignGroups?.reduce((total, group) => {
        return total + group?.promotionCampaignTiers?.reduce((sum, tier) => sum + (tier?.budgetAmount || 0), 0);
      }, 0) || 0
    );
  };

  const handleGetDataSource = (value) => {
    setDataSource(value);
  };

  const [idDeleted, setIdDeleted] = useState();

  const confirmDelete = (id) => {
    const { promotionCampaignTierGroupsProductPromo = [] } = form.getFieldsValue();
    const promotionCampaignTiers = promotionCampaignTierGroupsProductPromo[0]?.promotionCampaignTiers || [];
    const newDataSource = dataSource.filter((item) => item.id !== id);

    const updatedPromotionCampaignTiers = promotionCampaignTiers.filter((tier) =>
      tier.conditionProductGroups?.some((group) => {
        const idProduct =
          group.conditionPromotionProducts?.id ||
          group.conditionPromotionProducts?.[0]?.product?.id ||
          group?.conditionProductGroups?.id;
        return idProduct !== id;
      })
    );

    form.setFieldsValue({
      promotionCampaignTierGroupsProductPromo: [
        { type: COMBO_CAMPAIGN.DISCOUNT_PRODUCT, promotionCampaignTiers: updatedPromotionCampaignTiers },
      ],
    });

    setIdDeleted(id);
    setDataSource(newDataSource);
  };

  const copyPromotionTierGroupsProductPromo = (allProducts) => {
    const values = form.getFieldsValue();

    return [
      {
        type: COMBO_CAMPAIGN.DISCOUNT_PRODUCT,
        promotionCampaignTiers: allProducts?.map((itemData, index) => {
          const tier = values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index] || {};

          const newConditionProductGroups = {
            quantity: 1,
            conditionPromotionProducts: allProducts[index],
          };

          return {
            ...tier,
            type: COMBO_CAMPAIGN.DISCOUNT_PRODUCT,
            discountType: tier?.discountType || DISCOUNT_TYPE.DISCOUNT_MONEY,
            limitType: tier?.limitType || TYPE_LIMIT.LIMIT,
            conditionProductGroups: [newConditionProductGroups],
          };
        }),
      },
    ];
  };

  const convertPromotionTierGroupsProductPromo = (allProducts) => {
    return {
      type: allProducts?.[0]?.type,
      promotionCampaignTiers: allProducts?.[0]?.promotionCampaignTiers?.map((tiers, index) => {
        const newConditionProductGroups = {
          quantity: 1,
          conditionPromotionProducts: tiers?.conditionProductGroups?.[0]?.conditionPromotionProducts,
        };

        return {
          discountAmount: tiers?.discountAmount,
          budgetAmount: tiers?.budgetAmount,
          discountType: tiers?.discountType,
          limitType: tiers?.limitType,
          maxQuantityPerSeller: tiers?.maxQuantityPerSeller,
          type: tiers?.type,
          conditionProductGroups: [newConditionProductGroups],
        };
      }),
    };
  };

  const convertGetProducts = (data) => {
    return data
      ?.flatMap((item) => item?.conditionProductGroups)
      ?.flatMap((item) => item?.conditionPromotionProducts)
      ?.flatMap((item) => item?.product);
  };

  useEffect(() => {
    if (isUpdate) {
      const newData = convertPromotionTierGroupsProductPromo(dataTiersServer);
      form.setFieldsValue({
        promotionCampaignTierGroupsProductPromo: [newData],
      });

      setDataSource(convertGetProducts(newData?.promotionCampaignTiers));
    }
  }, [dataTiersServer]);

  useEffect(() => {
    if (!isUpdate) {
      const newPromotionCampaignTierGroupsProductPromo = copyPromotionTierGroupsProductPromo(dataSource);
      form.setFieldsValue({
        promotionCampaignTierGroupsProductPromo: newPromotionCampaignTierGroupsProductPromo,
      });
    }
  }, [dataSource, isUpdate]);

  useEffect(() => {
    const values = form.getFieldsValue();
    setTotalBudgetAmount(calculateTotalBudget(values?.promotionCampaignTierGroupsProductPromo));
  }, [JSON.stringify(form.getFieldsValue()), dataSource]);

  return {
    dataSource,
    handleGetDataSource,
    confirmDelete,
    convertGetProducts,
    totalBudgetAmount,
    idDeleted,
  };
}

export default useOneOnProductForm;
