import { Col, Row, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { styleHeaderCardChildren, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";

function AddInfo({ data }) {
  return (
    <CustomCard
      headStyle={styleHeaderCardChildren}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.addInfo")}
        </Typography.Text>
      }
      content={
        <Row gutter={[16, 8]} style={{ fontSize: "13px" }}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Typography.Text strong>{t("common.description")} </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text>{data?.internalNote || "---"}</Typography.Text>
              </Col>
            </Row>
            <Row style={{ marginTop: "24px" }}>
              <Col span={24}>
                <Typography.Text strong>{t("warehouse.create.ImageUpload")}</Typography.Text>
              </Col>
              <Col span={24}>
                <Space style={{ marginTop: "10px", flexWrap: "wrap" }}>
                  {data?.fileURLs?.map((fileURL, index) => {
                    return <CustomViewDocumentFiles file={fileURL} key={index} />;
                  }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
}

export default AddInfo;
