import { Form, Radio, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import { useCancelSuggestedExportCode } from "hooks/keyManagement/mutation";
import { has } from "lodash";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function ModalSuggestedCancel({ selectedRows, resetSelected }) {
  const [form] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [loading, setLoading] = useState(false);

  const { handleCancelSuggestedExportCode } = useCancelSuggestedExportCode();

  const hasMaterialProduct = selectedRows.some((order) =>
    order.items.some((item) => item.product.productType === "MATERIAL")
  );

  const handleOpen = () => {
    if (!selectedRows?.length) {
      setIsModal(false);
      notify.warning({ message: "Vui lòng chọn đề nghị xuất mã key license/ account!" });
    } else {
      setIsModal(true);
    }
  };

  const handleCancel = () => {
    setIsModal(false);
    resetSelected();
  };

  const cancelOptions = [
    {
      label: "Không đủ tồn",
      value: 1,
    },
    {
      label: "Không đủ hạn mức công nợ",
      value: 2,
    },
    {
      label: "Không nhập được hàng pre-order",
      value: 3,
    },
    {
      label: "Không thể bán hàng với đơn giá hiện tại",
      value: 4,
    },
    {
      label: "Khác",
      value: 5,
    },
  ];

  const convertReason = (value, otherReason) => {
    const selectedOption = cancelOptions.find((option) => option.value === value);
    if (selectedOption?.value === 5) {
      return otherReason;
    }
    return selectedOption.label;
  };

  const handlerCancelSuggestedExportCode = async () => {
    try {
      await form.validateFields().then(async (values) => {
        setLoading(true);
        await handleCancelSuggestedExportCode({
          orderIDs: selectedRows?.map((item) => item?.id),
          reason: convertReason(values?.reason, values?.otherReason),
        })
          .then(() => {
            handleCancel();
            notify.success({ message: "Xác nhận hủy đề nghị xuất mã key license/ account thành công" });
            form.resetFields();
            setLoading(false);
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
            setLoading(false);
            handleOpen();
          });
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "reason")) {
      if (changedValues.reason === 5) {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, otherReason: "" });
      }
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={isModal}
      customComponent={<PrimaryButtonOutLine title={"Huỷ đề nghị"} loading={loading} />}
      buttonLoading={loading}
      closable={false}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onOke={handlerCancelSuggestedExportCode}
      title={<Typography.Title level={4}>Huỷ đơn</Typography.Title>}
      subTitle={
        hasMaterialProduct ? (
          <Typography.Text type="warning">
            Lưu ý: Đơn hàng của bạn chứa 02 loại sản phẩm: <b>key license/ account</b> và <b>sản phẩm thông thường</b>.
            Nếu bạn Xác nhận hủy đơn, hệ thống sẽ hủy toàn bộ đơn hàng!
          </Typography.Text>
        ) : (
          <Typography.Text type="warning">
            Nếu bạn Xác nhận hủy đơn, trạng thái của đơn hàng sẽ chuyển sang Đã hủy!
          </Typography.Text>
        )
      }
      children={
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange} style={{ marginTop: "10px" }}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "reason",
              style: { marginBottom: "12px" },
              rules: [
                {
                  required: true,
                  message: "Vui lòng chọn lý do",
                },
              ],
            }}
            inputChildren={
              <Space direction="vertical">
                {cancelOptions.map((item, index) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            }
          />
          <FormInput
            type={INPUT_TYPE.TEXT_AREA}
            formItemOptions={{
              name: "otherReason",
              hidden,
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const reasonValue = getFieldValue("reason");
                    if (reasonValue !== 5) {
                      return Promise.resolve(); // Không cần validator nếu không phải là "Khác"
                    } else {
                      if (!value) {
                        return Promise.reject(new Error("Không được để trống trường này")); // Validator khi không có giá trị cho "Khác"
                      } else {
                        return Promise.resolve(); // Giá trị hợp lệ
                      }
                    }
                  },
                }),
              ],
            }}
            inputOptions={{ placeholder: "Vui lòng nhập lý do", maxLength: 255 }}
          />
        </Form>
      }
    />
  );
}

export default ModalSuggestedCancel;
