import { gql } from "@apollo/client";

export const GET_ME_QUERY = gql`
  {
    user {
      me {
        id
        fullname
        email
        avatarURL
        telephone
        address
        ward {
          code
          name
        }
        status
        branch {
          name
          id
        }
        cityID
        districtID
        wardID
        branchIDs
        departmentID
        roleIDs
        branches {
          id
          name
        }
        city {
          id
          name
        }
        department {
          name
          code
        }
        roles {
          id
          code
          name
        }
        wallets {
          id
          balance
          type
        }
        wallet {
          id
          balance
          type
        }
        totalCart
      }
    }
  }
`;

export const GET_USER_CURRENT = gql`
  query GetUserCurrent {
    user {
      me {
        id
        fullname
      }
    }
  }
`;
