import { t } from "i18next";

const useInfoAgent = ({ dataInfoAgent }) => {
  const { senderName, senderPhone, senderAddress, receiverContactName, receiverContactPhone, receiverContactAddress } =
    dataInfoAgent;

  const infoReceiverAndSender = [
    {
      title: t("cart.infoReceiverAndSender.sender"),
      name: senderName,
      phoneNumber: senderPhone,
      address: senderAddress,
    },
    {
      title: t("cart.infoReceiverAndSender.receiver"),
      name: receiverContactName,
      phoneNumber: receiverContactPhone,
      address: receiverContactAddress,
    },
  ];
  return { infoReceiverAndSender };
};

export default useInfoAgent;
