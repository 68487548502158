import { Input } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import CreateButton from "components/CreateButton";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import CustomRadioBtnGroup from "components/CustomRadioBtnGroup";
import { useToolbar } from "./useToolbar";

import "./index.scss";

function Toolbar({
  placeholder,
  titleFilterData,
  titleButton,
  showButton,
  countSelectedRowKeys,
  setIsFilterSelected,
  onSearch,
  onChangeParams,
  params,
  children,
  optionsSeller,
  optionsStaff,
  warehouseOptions,
  showButtonGroups,
  onClick,
  onChangeRadioGroup,
  isFilterSelected,
}) {
  const { handleChangeDate, filterItemsTypeOptions, renderDateTag, renderTags } = useToolbar({
    params,
    countSelectedRowKeys,
    setIsFilterSelected,
    onChangeParams,
    optionsSeller,
    optionsStaff,
    warehouseOptions,
  });

  return (
    <>
      <div className="filter-purchase-order-container">
        <div className="search-input">
          <FilterDate onChangeDate={handleChangeDate} title={titleFilterData} />
          <Input
            className="custom-input"
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={placeholder}
            allowClear={true}
            onChange={(value) => onSearch(value)}
          />
        </div>
        {showButton && <CreateButton title={titleButton} className="btn-create" onClick={onClick} />}
        {children}
      </div>
      <div className="tags-list">
        <div>
          {renderDateTag()}
          {renderTags}
        </div>
        <div></div>

        {showButtonGroups && (
          <CustomRadioBtnGroup
            filterItemsTypeOptions={filterItemsTypeOptions}
            handleChange={onChangeRadioGroup}
            defaultValue={isFilterSelected}
          />
        )}
      </div>
    </>
  );
}

export default Toolbar;
