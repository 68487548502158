import React from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import CustomTable from "components/CustomTable";
import "./index.scss";

function CustomTableSelectRow({
  columns,
  data = [],
  loading,
  onChangeTable,
  selectedHighlight,
  rowSelection,
  onClickRow,
  pagination,
  onToggleArrow,
  isTableSelectedRow,
  titleCardTableSelected,
  reasonComponent,
  dataTableSelected = [],
  columnTableSelected,
  summaryTableSelected = undefined,
  scroll,
}) {
  return (
    <Space className="custom-table-select-row" direction="vertical" style={{ width: "100%" }}>
      <CustomTable
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={onChangeTable}
        rowClassName={(record, index) => (index === selectedHighlight ? "highlight" : "")}
        rowSelection={rowSelection}
        scroll={scroll}
        onRow={(record, index) => ({
          onClick: () => onClickRow(record, index),
        })}
        pagination={pagination}
        rowKey={(record) => record?.id}
      />
      <div className="toggle-arrow" style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            padding: "0 20px",
            height: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          onClick={onToggleArrow}
          className="icon-arrow"
        >
          {isTableSelectedRow ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </Button>
      </div>
      {!isTableSelectedRow && data?.length ? (
        <div className="table-data-selected">
          <Card title={titleCardTableSelected} headStyle={{ backgroundColor: "#e7f0ff" }}>
            {reasonComponent}
            <CustomTable
              scroll={{
                x: "max-content",
              }}
              dataSource={dataTableSelected}
              columns={columnTableSelected}
              summary={summaryTableSelected ? (pageData) => summaryTableSelected(pageData) : undefined}
              loading={loading}
            />
          </Card>
        </div>
      ) : (
        <></>
      )}
    </Space>
  );
}

export default CustomTableSelectRow;
