import React from "react";
import { t } from "i18next";
import CustomTags from "components/CustomTags";

const useFiltersTag = ({ params, handleRemoveFilterTag, VALUE_OPTION_STATUS_BRAND }) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (typeof arrValue !== "undefined" && arrValue !== null && typeof arrValue[Symbol.iterator] === "function") {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const tagsIsActive = [
    {
      title: t("productBrand.ACTION"),
      options: VALUE_OPTION_STATUS_BRAND,
      selected: [params?.filters?.isActive],
      key: "isActive",
    },
  ];
  const renderTagsIsActive = tagsIsActive?.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });

  return {
    renderTagsIsActive,
  };
};

export default useFiltersTag;
