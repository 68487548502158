import { gql } from "@apollo/client";

export const APPROVE = gql`
  mutation Approve($customerID: ID!, $request: ApprovalInput!) {
    potentialCustomer {
      approve(customerID: $customerID, request: $request)
    }
  }
`;

export const CREATE_POTENTIAL_CUSTOMER = gql`
  mutation CreatePotentialCustomer($request: PotentialCustomerInput!) {
    potentialCustomer {
      create(request: $request) {
        id
      }
    }
  }
`;

export const UPLOAD_FILE_SAMPLE_CUSTOMER = gql`
  mutation UploadFileCustomer($file: Upload!) {
    potentialCustomer {
      batchCreate(file: $file) {
        errorMessage
        status
        errorType
        file {
          path
          url
        }
      }
    }
  }
`;

export const CREATE_COMMUNICATE_LOG = gql`
  mutation CreateCommunicateLog($request: CommunicationLogInput!) {
    potentialCustomer {
      createCommunicationLog(request: $request)
    }
  }
`;

export const DESIST_CUSTOMER = gql`
  mutation DesistCustomer($customerIDs: [ID!], $reason: DesistReasonInput!) {
    potentialCustomer {
      desist(customerIDs: $customerIDs, reason: $reason)
    }
  }
`;

export const CONFIRM_TAKING_CARE_CUSTOMER = gql`
  mutation ConfirmTakingCareCustomer($customerIDs: [ID!], $careStaffID: ID!) {
    potentialCustomer {
      confirmTakingCare(customerIDs: $customerIDs, careStaffID: $careStaffID)
    }
  }
`;

export const RECONTACT_CUSTOMER = gql`
  mutation RecontactCustomer($customerIDs: [ID!]) {
    potentialCustomer {
      recontact(customerIDs: $customerIDs)
    }
  }
`;

export const REJECT_CUSTOMER = gql`
  mutation RejectCustomer($customerIDs: [ID!], $reason: RejectReasonInput!) {
    potentialCustomer {
      reject(customerIDs: $customerIDs, reason: $reason)
    }
  }
`;

export const COMPLETE_CUSTOMER = gql`
  mutation CompleteCustomer($customerIDs: [ID!]) {
    potentialCustomer {
      complete(customerIDs: $customerIDs)
    }
  }
`;

export const LINK_CUSTOMER = gql`
  mutation LinkCustomer($customerID: ID!, $existedCustomerID: ID, $existedSellerID: ID) {
    potentialCustomer {
      link(customerID: $customerID, existedCustomerID: $existedCustomerID, existedSellerID: $existedSellerID) {
        id
        sellerID
        status
      }
    }
  }
`;

export const UPDATE_POTENTIAL_CUSTOMER = gql`
  mutation UpdatePotentialCustomer($id: ID!, $request: PotentialCustomerInput!) {
    potentialCustomer {
      update(id: $id, request: $request) {
        id
      }
    }
  }
`;
