import React from "react";
import { Select } from "antd";
import { t } from "i18next";
import "./index.scss";

function InputSearch({ onSearch, valueSearchSerial }) {
  const dropdownStyle = {
    display: "none",
  };
  return (
    <div className="form-input-search-by-serial">
      <Select
        mode="tags"
        allowClear={true}
        value={valueSearchSerial}
        placeholder={t("serialIE.placeholder.searchBySerial")}
        onChange={onSearch}
        className="select-tag-search"
        dropdownStyle={dropdownStyle}
        defaultOpen={false}
      />
    </div>
  );
}

export default InputSearch;
