import { Form, Select } from "antd";
import useSelectLoadMore from "./useSelectLoadMore";

export const SelectLoadMore = ({
  formItemOptions,
  inputOptions,
  inputChildren,
  options,
  onLoadMore,
  hasMoreData,
  onSearch,
  onChange,
}) => {
  const { handlePopupScroll, handleSearch, handleChange } = useSelectLoadMore({
    onLoadMore,
    hasMoreData,
    onSearch,
    onChange,
  });

  return (
    <Form.Item {...formItemOptions}>
      <Select
        showSearch
        style={{
          width: "100%",
        }}
        onChange={handleChange}
        allowClear
        onPopupScroll={handlePopupScroll}
        onSearch={handleSearch}
        filterOption={false}
        {...inputOptions}
      >
        {inputChildren}
      </Select>
    </Form.Item>
  );
};

export default SelectLoadMore;
