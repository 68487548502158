import { Tooltip, message, Upload } from "antd";
import CustomModal from "components/CustomModal";
import React, { useState } from "react";
import "./index.scss";
import { SvgIcon } from "assets/icons";
import { t } from "i18next";
import Text from "antd/lib/typography/Text";
import { useUploadFileProductPriceLogs, useGetImportFileProduct } from "hooks/product/product";
import { notify } from "../../../../../../../utils/helperFuncs";
import { useFlags } from "flagsmith/react";

const CustomModalImportPriceSeries = () => {
  const [dataFile, setDataFile] = useState();
  const [status, setStatus] = useState("SUCCEED");
  const [errorFileURL, setErrorFileURL] = useState(null);
  const [errorReason, setErrorReason] = useState("");
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const flags = useFlags(["is_disable_template_download_price_product"]);
  const isDisableTemplateDownloadPriceProduct = !flags.is_disable_template_download_price_product.enabled;

  const handleOpen = () => {
    setVisible(true);
  };

  const { handleExportFileProduct } = useGetImportFileProduct();
  const handleDownloadSample = async () => {
    const fileURLSample = await handleExportFileProduct({
      filters: { ids: [""], isExample: true },
    });
    window.open(fileURLSample?.data?.product?.getImportFile?.fileURL);
  };

  const { handleImportProductPrices, loading } = useUploadFileProductPriceLogs();
  const handleSubmit = async () => {
    if (fileList?.length > 0 && status === "SUCCEED") {
      const data = await handleImportProductPrices(dataFile);
      setStatus(data?.data?.product?.importProductPrices?.status);
      setErrorFileURL(data?.data?.product?.importProductPrices?.errorFileURL);
      setErrorReason(data?.data?.product?.importProductPrices?.errorReason);
      if (data?.data?.product?.importProductPrices?.errorFileURL) {
        setErrorReason(t("serialIE.productInfo.errorReason"));
      } else {
        setErrorReason(data?.data?.product?.importProductPrices?.errorReason);
      }
      setFileList([]);
      if (data.data.product.importProductPrices.status === "SUCCEED") {
        notify.success({ message: t("product.downFileSuccess") });
        setVisible(false);
        // window.location.reload();
      }
    } else {
      if (status === "SUCCEED") {
        notify.warning({ message: t("product.pleaseChooseProduct2") });
      }
    }
  };
  const downloadFileOther = async () => {
    setFileList([]);
    await setStatus("SUCCEED");
  };
  const handleCancel = () => {
    setFileList([]);
    setStatus("SUCCEED");
    setVisible(false);
  };

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    async onChange(info) {
      setFileList(info.fileList);
      setDataFile(info.file);
    },
  };

  return (
    <CustomModal
      className="modal-import-price-series"
      closable={false}
      centered={true}
      footer={false}
      buttonLoading={loading}
      visible={visible}
      onOpen={handleOpen}
      title={
        <span className="title">
          {status === "SUCCEED" ? t("product.downFileInfoProduct") : t("product.errorDownFileInfoProduct")}
        </span>
      }
      onOke={handleSubmit}
      onCancel={handleCancel}
      isBlockCloseOnOke
      // hideConfirmButton={status === 'SUCCEED' && errorFileURL === null ? false : true}
      cancelButtonLabel={status === "FAILED" && errorFileURL === null ? "Đóng" : "Hủy bỏ"}
      okeButtonLabel={
        status === "SUCCEED" ? (
          t("product.Confirm")
        ) : status === "FAILED" && errorFileURL === null ? (
          <span onClick={downloadFileOther}>Tải file khác</span>
        ) : (
          <a
            href={errorFileURL}
            target="_blank"
            rel="noopener noreferrer nofollow"
            download="template up file gia san pham Loi.xlsx"
          >
            {t("product.downFileError")}
          </a>
        )
      }
      customComponent={
        <Tooltip title={t("product.importPriceSeries")} color={"#5B6673"} placement="bottom">
          <SvgIcon.ImportPriceSeries style={{ width: "20px", cursor: "pointer" }} />
        </Tooltip>
      }
    >
      {status === "SUCCEED" ? (
        <Dragger {...props} beforeUpload={() => false} fileList={fileList} maxCount={1} accept=".xlsx">
          <p className="ant-upload-drag-icon">
            <SvgIcon.LogoFileWordIcon />
          </p>
          <p className="ant-upload-text">{t("product.drapDropFile")}</p>
        </Dragger>
      ) : (
        <p style={{ textAlign: "center" }}>
          <SvgIcon.WarningFileUpload />
        </p>
      )}
      {status === "SUCCEED" ? (
        <Text italic>
          <p className="text-info-file">{t("product.textInfoFile")}</p>
          {isDisableTemplateDownloadPriceProduct && (
            <p className="text-download-file">
              {t("product.textDownFile")}
              <a
                onClick={handleDownloadSample}
                // href={fileURLSample}
                target="_blank"
                rel="noopener noreferrer nofollow"
                download="Template_Up_Load_File_Gia_San_Pham.xlsx"
              >
                &nbsp;{t("product.here")}
              </a>
            </p>
          )}
        </Text>
      ) : (
        <p className="text-download-file">{errorReason}</p>
      )}
    </CustomModal>
  );
};

export default CustomModalImportPriceSeries;
