import { useTable } from "./useTable";

export const TypeOther = ({
  form,
  remove,
  handleRemoveProduct,
  tags,
  handleGetTags,
  sumSerialByLack,
  sumSerialByOrder,
  sumSerialByScanned,
  handleClearAllSerials,
  warehouseInfo,
}) => {
  const { columns } = useTable({
    form,
    remove,
    handleRemoveProduct,
    tags,
    handleGetTags,
    sumSerialByLack,
    sumSerialByOrder,
    sumSerialByScanned,
    handleClearAllSerials,
    warehouseInfo,
  });
  return { columns };
};
