import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Row, Space, Tooltip, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";

import Img from "assets/images";
import CustomModal from "components/CustomModal";
import { flatten, map, min } from "lodash";
import { validator } from "utils/helperFuncs";
import ModalSelectGift from "../ModalSelectGift";
import { PRODUCT_TYPE } from "features/Campaign/constants";

function calculateStockInfo(conditionGifts) {
  const newConditionGifts = conditionGifts?.filter((item) => item.type === PRODUCT_TYPE.GIFT_PRODUCT);
  return map(newConditionGifts, (item) => {
    const quantitiesArray = item?.conditionPromotionProducts?.map((product) => {
      const validRegionItemsClient = product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];
      const validRegionItemsServer =
        product?.product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];

      return [...validRegionItemsClient, ...validRegionItemsServer]?.reduce(
        (acc, stock) => acc + (stock?.quantity || 0),
        0
      );
    });
    const flattenedArray = flatten(quantitiesArray);
    const minValue = min(flattenedArray);

    return {
      quantity: item.quantity,
      smallestValueInStock: minValue,
      conditionPromotionProducts: item.conditionPromotionProducts,
    };
  });
}

function GiftCol({
  useInModal,
  remove,
  indexConditionProductGroups,
  indexPromotionCampaignTiers,
  form,
  promotionCampaignTiers,
  isUpdate,
  dataTiersServer,
  fieldName,
  indexComboGift,
  fields,
  valuesForm,
}) {
  const data = calculateStockInfo(
    dataTiersServer?.[indexComboGift]?.promotionCampaignTiers?.[indexPromotionCampaignTiers]?.conditionProductGroups
  );

  const extractedData = calculateStockInfo(
    valuesForm?.promotionCampaignTierGroupsGift?.[indexComboGift]?.promotionCampaignTiers?.[indexPromotionCampaignTiers]
      ?.conditionGifts
  );

  const dataProductsServer = data?.[indexConditionProductGroups]?.conditionPromotionProducts?.map((itemProduct) => {
    return {
      ...itemProduct.product,
      productWarranty: itemProduct?.productWarranty,
      budgetQuantity: itemProduct?.budgetQuantity,
    };
  });

  const isValidateHelp =
    extractedData?.[indexConditionProductGroups]?.quantity >
    extractedData?.[indexConditionProductGroups]?.smallestValueInStock;

  return (
    <Row gutter={[36]} style={{ alignItems: "center", justifyContent: "space-between", padding: "12px 0" }}>
      <Col span={15}>
        <Row style={{ alignItems: "center", flexWrap: "nowrap" }} gutter={16}>
          <Col span={4}>
            <Typography.Text strong>
              [{t("campaign.combo.gift")} {indexConditionProductGroups + 1}]
            </Typography.Text>
          </Col>
          <Col>
            <ModalSelectGift
              indexConditionProductGroups={indexConditionProductGroups}
              indexPromotionCampaignTiers={indexPromotionCampaignTiers}
              useInModal={useInModal}
              dataProductsServer={dataProductsServer}
              form={form}
              promotionCampaignTiers={promotionCampaignTiers}
              isUpdate={isUpdate}
              indexComboGift={indexComboGift}
              isGift={true}
              fieldName={fieldName}
              isCheckboxType
            />
          </Col>
        </Row>
      </Col>
      <Col span={7}>
        <Space>
          <Typography.Text
            ellipsis={{
              suffix: (
                <Tooltip title={t("campaign.detailPromo.quantityGift")}>
                  <QuestionCircleOutlined />
                </Tooltip>
              ),
            }}
          >
            {t("campaign.combo.quantity")}{" "}
          </Typography.Text>
          <FormInput
            type={INPUT_TYPE.NUMBER}
            {...{
              formItemOptions: {
                name: [indexConditionProductGroups, "quantity"],
                validateStatus: isValidateHelp ? "warning" : undefined,
                help: isValidateHelp ? t("cart.lackOfStock") : undefined,
                style: { margin: 0 },
                rules: [
                  { required: true, message: t("campaign.combo.errorMessage.qty") },
                  {
                    type: "number",
                    min: 1,
                    message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                  },
                  validator({
                    type: "number",
                  }),
                ],
              },
              inputOptions: {
                placeholder: t("campaign.combo.placeholder.type"),
                className: "input-number",
                maxLength: 10,
              },
            }}
          />
        </Space>
      </Col>
      {fields?.length !== 1 && (
        <Col span={2}>
          <CustomModal
            {...{
              message: t("campaign.combo.errorMessage.removeProductCol"),
              onOke: remove,
              customComponent: <DeleteOutlined />,
              footer: false,
              icon: Img.DeleteIcon,
            }}
          />
        </Col>
      )}
    </Row>
  );
}

export default GiftCol;
