import { useCallback, useEffect, useState } from "react";

const useResizeDrawer = ({ defaultHeight, minHeight, maxHeight, visible }) => {
  const [drawerHeight, setDrawerHeight] = useState(defaultHeight);

  const handleMouseDown = (e) => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      const newHeight = window.innerHeight - e.clientY;
      if (newHeight >= minHeight && newHeight <= maxHeight) {
        setDrawerHeight(newHeight);
      }
    },
    [minHeight, maxHeight]
  );

  useEffect(() => {
    if (visible) setDrawerHeight(defaultHeight);
  }, [visible]);

  return {
    drawerHeight,
    handleMouseDown,
  };
};

export default useResizeDrawer;
