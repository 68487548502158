import { Form, Skeleton, Space, Typography } from "antd";
import Img from "assets/images";
import CustomButton from "components/CustomButton";
import CustomDrawer from "components/CustomDrawer";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { compact } from "lodash";
import { useEffect } from "react";
import { dataSourceLabel, sellerTypeLabel } from "../constants";
import FormPotentialCustomer from "./FormPotentialCustomer";
import { CloseOutlined } from "@ant-design/icons";

import "./index.scss";

function PotentialCustomerDrawerForm({
  openDrawer,
  loadingForm,
  handleSubmitDrawer,
  initForm,
  form,
  handleValuesChange,
  optionsInChargeStaff,
  onLoadMoreInChargeStaff,
  hasMoreInChargeStaff,
  handleSearchInChargeStaffs,
  handleChangeInChargeStaff,
  formValues,
  valueEmail,
  loadingEmail,
  valueTelephone,
  loadingTelephone,
  isDuplicateEmail,
  isDuplicateTelephone,
  handleSearchEmail,
  handleSearchTelephone,
  valueIdNumber,
  valueTaxCode,
  loadingIdNumber,
  loadingTaxCode,
  isDuplicateIdNumber,
  isDuplicateTaxCode,
  handleSearchIdNumber,
  handleSearchTaxCode,
  typePersonal,
  dataDetail,
  notBordered,
  openDrawerMulti,
  handleOpenDrawerMulti,
  handleCloseDrawerMulti,
  loadingDetail,
  visibleModalCloseDrawer,
  handleOpenModalCloseDrawer,
  handleOkeModalCloseDrawer,
  handleCancelModalCloseDrawer,
}) {
  const [formMulti] = Form.useForm();
  const initFormMulti = {};

  useEffect(() => {
    formMulti.setFieldsValue({
      ...dataDetail,
      dataSource: dataSourceLabel?.[dataDetail?.dataSource] || "--",
      careStaffID: dataDetail?.careStaff?.fullname || "--",
      cityID: dataDetail?.city?.name || "--",
      districtID: dataDetail?.district?.name || "--",
      wardID: dataDetail?.ward?.name || "--",
      type: sellerTypeLabel?.[dataDetail?.type] || "--",
      vatNumber: dataDetail?.vatNumber || "--",
      note: dataDetail?.note || "--",
      legalRepresentativeName: dataDetail?.legalRepresentativeName || "--",
      legalRepresentativeTelephone: dataDetail?.legalRepresentativeTelephone || "--",
      legalRepresentativeEmail: dataDetail?.legalRepresentativeEmail || "--",
      registeredBusinessAddress: dataDetail?.registeredBusinessAddress || "--",
      representativeName: dataDetail?.representativeName || "--",
      representativeEmail: dataDetail?.representativeEmail || "--",
      representativeTelephone: dataDetail?.representativeTelephone || "--",
      expectedRevenue: dataDetail?.expectedRevenue || "--",
      priority: dataDetail?.priority || "--",
      telephone: dataDetail?.telephone || "--",
      email: dataDetail?.email || "--",
      idNumber: dataDetail?.idNumber || "--",
      address: dataDetail?.address || "--",
      interestedCategoryIDs: dataDetail?.interestedCategories?.length
        ? dataDetail?.interestedCategories?.map((item) => compact([item.name]).join(", "))
        : "--",
    });
  }, [dataDetail]);

  return (
    <CustomDrawer
      title={t("potentialCustomer.createCustomer")}
      visible={openDrawer}
      bodyStyle={{ background: "#F7F8FB" }}
      width={600}
      buttonCancel={
        <CustomModal
          customComponent={<CustomButton isSecondary title={t("common.cancel")} />}
          icon={Img.QuestionIcon}
          message={t("potentialCustomer.messageCloseDrawer")}
          footer={false}
          visible={visibleModalCloseDrawer}
          onOke={() => handleOkeModalCloseDrawer()}
          onOpen={() => handleOpenModalCloseDrawer()}
          onCancel={() => handleCancelModalCloseDrawer()}
        />
      }
      loading={loadingForm}
      onOk={handleSubmitDrawer}
      onClose={handleOpenModalCloseDrawer}
      multiLevel={openDrawerMulti}
      maskClosable={false}
      mask={false}
      className="custom-potential-drawer-form"
      widthMulti={600}
      titleMulti={
        <Skeleton active loading={loadingDetail} paragraph={{ rows: 0 }}>
          <Space style={{ justifyContent: "space-between", alignItems: "center" }}>
            <div onClick={handleCloseDrawerMulti} style={{ cursor: "pointer" }}>
              <CloseOutlined />
            </div>
            <Typography.Text strong>{dataDetail?.fullName || "--"}</Typography.Text>
          </Space>
        </Skeleton>
      }
      {...{
        propsDrawerChildren: {
          bodyStyle: { background: "#F7F8FB" },
          visible: openDrawerMulti,
          onClose: handleCloseDrawerMulti,
          closable: false,
          title: (
            <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
              <Typography.Text style={{ fontSize: "22px" }} strong>
                {dataDetail?.fullName || "--"}
              </Typography.Text>
            </Skeleton>
          ),
          placement: "right",
        },
      }}
      contentMultiDrawer={
        <Form initialValues={initFormMulti} className="custom-drawer-multi" form={formMulti} colon={false}>
          <FormPotentialCustomer
            loadingDetail={loadingDetail}
            notBordered={{ bordered: false, disabled: true }}
            dataMulti={dataDetail}
            typePersonal={dataDetail?.type}
            valueIdNumber={valueIdNumber}
            valueTaxCode={valueTaxCode}
            valueEmail={valueEmail}
            valueTelephone={valueTelephone}
            isDetail={true}
          />
        </Form>
      }
      content={
        <Form
          initialValues={initForm}
          className={"custom-drawer-add-potential-customer"}
          form={form}
          colon={false}
          layout="horizontal"
          onValuesChange={handleValuesChange}
        >
          <FormPotentialCustomer
            initForm={initForm}
            form={form}
            handleValuesChange={handleValuesChange}
            optionsInChargeStaff={optionsInChargeStaff}
            onLoadMoreInChargeStaff={onLoadMoreInChargeStaff}
            hasMoreInChargeStaff={hasMoreInChargeStaff}
            handleSearchInChargeStaffs={handleSearchInChargeStaffs}
            handleChangeInChargeStaff={handleChangeInChargeStaff}
            typePersonal={typePersonal}
            formValues={formValues}
            loadingIdNumber={loadingIdNumber}
            loadingTaxCode={loadingTaxCode}
            isDuplicateIdNumber={isDuplicateIdNumber}
            isDuplicateTaxCode={isDuplicateTaxCode}
            handleSearchIdNumber={handleSearchIdNumber}
            handleSearchTaxCode={handleSearchTaxCode}
            handleOpenDrawerMulti={handleOpenDrawerMulti}
            loadingEmail={loadingEmail}
            loadingTelephone={loadingTelephone}
            isDuplicateEmail={isDuplicateEmail}
            isDuplicateTelephone={isDuplicateTelephone}
            handleSearchEmail={handleSearchEmail}
            handleSearchTelephone={handleSearchTelephone}
            dataMulti={dataDetail}
            notBordered={notBordered}
            valueIdNumber={valueIdNumber}
            valueTaxCode={valueTaxCode}
            valueEmail={valueEmail}
            valueTelephone={valueTelephone}
          />
        </Form>
      }
    />
  );
}

export default PotentialCustomerDrawerForm;
