import { gql } from "@apollo/client";

export const CREATE_SERIAL_IMPORT = gql`
  mutation CreateSerialImport($request: SerialImportNotesInput) {
    serialImportNotes {
      create(request: $request) {
        id
        code
        type
        providerID
        provider {
          id
          fullName
          telephone
          email
          address
        }
        sellerID
        seller {
          id
          fullName
        }
        warehouseID
        warehouse {
          name
        }
        createdByID
        createdBy {
          fullname
        }
        quantityKindOfProduct
        quantitySerial
        items {
          id
          productID
          product {
            name
            code
            inStock
          }
          warrantyType
          warrantyOnSale
          warrantyUnit
          serialItems {
            serialID
          }
          quantity
          createdAt
          updatedAt
        }
        note
        fileURLs
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_SERIAL_IMPORT = gql`
  mutation UpdateSerialImport($serialImportNoteID: ID!, $request: UpdateSerialImportNoteInput!) {
    serialImportNotes {
      update(serialImportNoteID: $serialImportNoteID, request: $request)
    }
  }
`;

export const CREATE_SERIAL_EXPORT = gql`
  mutation CreateSerialExport($request: SerialExportNoteInput) {
    serialExportNotes {
      create(request: $request) {
        id
        code
        type
        providerID
        provider {
          id
          fullName
          telephone
          email
          address
        }
        sellerID
        seller {
          id
          fullName
        }
        createdByID
        createdBy {
          fullname
        }
        quantityKindOfProduct
        quantitySerial
        items {
          id
          productID
          product {
            name
            code
            inStock
          }
          serialExportNote {
            provider {
              id
              fullName
              code
            }
          }
          exportItems {
            replaceSerial {
              provider {
                id
                fullName
              }
            }
          }
          warrantyOnSale
          warrantyOnSaleUnit
          quantity
          createdAt
          updatedAt
        }
        note
        fileURLs
        createdAt
        updatedAt
      }
    }
  }
`;

export const PRINT_EXPORT_NOTES_SERIAL_IMPORT_NOTES = gql`
  mutation PrintExportNotesSerialImportNotes(
    $serialImportNoteID: ID!
    $serialImportNoteItemIDs: [ID!]
    $type: SerialNoteType!
    $fileType: ExportFileType!
  ) {
    serialImportNotes {
      exportFile(
        serialImportNoteID: $serialImportNoteID
        serialImportNoteItemIDs: $serialImportNoteItemIDs
        type: $type
        fileType: $fileType
      ) {
        path
        url
      }
    }
  }
`;

export const PRINT_EXPORT_NOTES_SERIAL_EXPORT_NOTES = gql`
  mutation PrintExportNotesSerialExportNotes(
    $serialExportNoteID: ID!
    $serialExportNoteItemIDs: [ID!]
    $type: SerialNoteType!
    $fileType: ExportFileType!
  ) {
    serialExportNotes {
      exportFile(
        serialExportNoteID: $serialExportNoteID
        serialExportNoteItemIDs: $serialExportNoteItemIDs
        type: $type
        fileType: $fileType
      ) {
        path
        url
      }
    }
  }
`;
