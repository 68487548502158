import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function WarrantyRequestDetailLink({ warrantyRequest }) {
  return (
    <Link to={`/warranty-request/detail/${warrantyRequest?.warrantyRequestID || warrantyRequest?.id}`}>
      <Typography.Link strong>{warrantyRequest?.warrantyRequest?.code || warrantyRequest?.code}</Typography.Link>
    </Link>
  );
}

export default WarrantyRequestDetailLink;
