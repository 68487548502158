import { Button, Col, Form, Radio, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useConfirmApproveCheckInfoPurchaseOrder } from "hooks/purchase";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import { ButtonRejectCheckInfo } from "./ButtonRejectCheckInfo";
import "./index.scss";

export const ButtonCheckInfoPurchaseOrder = ({ record }) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isAccept, setIsAccept] = useState(false);

  const listCause = [
    { label: t("purchase.detail.supplier_info_is_incorrect"), name: "supplier_info_is_incorrect", rules: true },
    { label: t("purchase.detail.wrong_delivered_product"), name: "wrong_delivered_product", rules: true },
    { label: t("purchase.detail.wrong_product_quantity"), name: "wrong_product_quantity", rules: true },
    {
      label: t("purchase.detail.delivery_note_and_order_info_different"),
      name: "delivery_note_and_order_info_different",
      rules: true,
    },
    { label: t("purchase.detail.not_qualified_quality"), name: "not_qualified_quality", rules: true },
    { label: t("purchase.detail.debt_delivery_note_compared"), name: "debt_delivery_note_compared", rules: false },
  ];

  const initialValues = {
    supplier_info_is_incorrect: null,
    wrong_delivered_product: null,
    wrong_product_quantity: null,
    delivery_note_and_order_info_different: null,
    not_qualified_quality: null,
    debt_delivery_note_compared: null,
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleValuesChange = (changedValue, allValues) => {
    const filteredListReject = listCause?.filter((item) => item.rules);
    const resultReject = filteredListReject?.some((item) => allValues[item.name] === "false");
    const resultAccept = filteredListReject?.every((item) => allValues[item.name] === "true");
    const filteredObj = Object.fromEntries(
      filteredListReject
        ?.filter((item) => allValues[item.name] !== undefined)
        ?.map((item) => [item.name, allValues[item.name]])
    );
    const allNotNull = Object.values(filteredObj).every((value) => value !== null);
    setIsReject(resultReject && allNotNull);
    setIsAccept(resultAccept && allNotNull);
  };

  const onReject = async () => {
    try {
      setVisibleReject(true);
    } catch (e) {
      console.error(e);
    }
  };

  const { handleConfirmApproveCheckInfoPurchaseOrder, loading } = useConfirmApproveCheckInfoPurchaseOrder();

  const onAccept = async () => {
    try {
      await handleConfirmApproveCheckInfoPurchaseOrder([record?.id])
        .then(() => {
          setVisible(false);
          form.resetFields();
          notify.success({
            message: "Xác nhận kiểm tra thông tin thành công.",
          });
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (e) {
      console.error(e);
    }
  };

  const renderHeader = () => {
    return (
      <Row className="form-header">
        <Col span={14}>{t("purchase.detail.CONFIRM_INFO")}</Col>
        <Col span={5}>{t("purchase.detail.ELIGIBLE")}</Col>
        <Col span={5}>{t("purchase.detail.UN_ELIGIBLE")}</Col>
      </Row>
    );
  };

  const renderContent = () => {
    return (
      <div className="form-content">
        {listCause?.map((item) => (
          <Row className="form-content-item" justify="space-between" align="middle">
            <Form.Item name={item.name} rules={[{ required: item.rules, message: "Please select your option!" }]}>
              <div style={{ display: "flex", fontSize: "13px" }}>
                <Col span={14}>
                  {item?.label}
                  {item?.rules && <span style={{ color: "#D2434D" }}> *</span>}
                </Col>
                <Col span={10} className="form-item-radio">
                  <Radio.Group>
                    <Row>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Radio value={true}></Radio>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Radio value={false}></Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
              </div>
            </Form.Item>
          </Row>
        ))}
      </div>
    );
  };

  useEffect(() => {
    setIsReject(false);
    setIsAccept(false);
  }, [visible]);

  return (
    <Form form={form} name="validate_other" initialValues={initialValues} onValuesChange={handleValuesChange}>
      <CustomModal
        {...{
          width: "50%",
          title: (
            <span style={{ fontSize: "18px" }}>
              <Typography.Text strong>{t("purchase.detail.check_purchase_order_info")}</Typography.Text>{" "}
              <Typography.Link strong>{record?.code}</Typography.Link>
            </span>
          ),
          customComponent: (
            <Button type="primary" loading={loading}>
              {t("purchase.detail.confirm_check")}
            </Button>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              <Button type="secondary" htmlType="submit" onClick={handleCancel} loading={loading}>
                {t("productBrand.cancel")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onReject} disabled={!isReject} loading={loading}>
                {t("warehouse.recommendImport.reject")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onAccept} disabled={!isAccept} loading={loading}>
                {t("warehouse.recommendImport.accept")}
              </Button>
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
        }}
      >
        <div className="form-check-info-purchase-order">
          <div>{renderHeader()}</div>
          <div>{renderContent()}</div>
          <span className="form-check-info-extra">(*) {t("purchase.detail.form_check_info_extra")}</span>
        </div>
      </CustomModal>
      <ButtonRejectCheckInfo
        record={record}
        visible={visible}
        setVisible={setVisible}
        visibleReject={visibleReject}
        setVisibleReject={setVisibleReject}
      />
    </Form>
  );
};
