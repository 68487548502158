import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModalDeliverySuccess from "../CustomModalDeliverySuccess";

const SuccessDeliveryButton = ({ orderID }) => {
  const { t } = useTranslation();
  const [openModalDeliverySuccess, setOpenModalDeliverySuccess] = useState(false);

  const onOpenModalDeliverySuccess = () => {
    setOpenModalDeliverySuccess(!openModalDeliverySuccess);
  };
  return (
    <>
      <Button type="link" onClick={() => onOpenModalDeliverySuccess()}>
        {t("order.orderList.linkShippingSuccess")} <RightOutlined />
      </Button>
      {openModalDeliverySuccess && (
        <CustomModalDeliverySuccess setOpenModalDeliverySuccess={setOpenModalDeliverySuccess} orderID={orderID} />
      )}
    </>
  );
};

export default SuccessDeliveryButton;
