import { Button, Dropdown, Menu } from "antd";
import { SvgIcon } from "assets/icons";
import { SALES_RETURN } from "features/Purchase/constant";
import { useSalesReturnPermissions } from "hooks/recommendimport";
import { t } from "i18next";
import ButtonManagerApproveSalesReturn from "../ButtonManagerApproveSalesReturn";
import ButtonManagerRejectSalesReturn from "../ButtonManagerRejectSalesReturn";
import ButtonTechnicalApproveSalesReturn from "../ButtonTechnicalApproveSalesReturn";
import ButtonTechnicalRejectSalesReturn from "../ButtonTechnicalRejectSalesReturn";

export const ButtonActionRequest = ({ record, disabled }) => {
  const {
    canImportSalesReturnManagerApprove,
    canImportSalesReturnTechnicalApprove,
    canImportSalesReturnManagerReject,
    canImportSalesReturnTechnicalReject,
  } = useSalesReturnPermissions();

  const menu = (
    <Menu>
      {canImportSalesReturnManagerApprove && record?.status === SALES_RETURN.WAITING_APPROVAL && (
        <Menu.Item>
          <ButtonManagerApproveSalesReturn record={record} />
        </Menu.Item>
      )}
      {canImportSalesReturnTechnicalApprove && record?.status === SALES_RETURN.WAITING_TECHNICAL_CONFIRM && (
        <Menu.Item>
          <ButtonTechnicalApproveSalesReturn record={record} />
        </Menu.Item>
      )}
      {canImportSalesReturnManagerReject && record?.status === SALES_RETURN.WAITING_APPROVAL && (
        <Menu.Item>
          <ButtonManagerRejectSalesReturn record={record} />
        </Menu.Item>
      )}
      {canImportSalesReturnTechnicalReject && record?.status === SALES_RETURN.WAITING_TECHNICAL_CONFIRM && (
        <Menu.Item>
          <ButtonTechnicalRejectSalesReturn record={record} />
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} className="button-action-recommend button-offers-recommend" disabled={disabled}>
      <Button>
        {t("warehouse.recommendImport.actionRecommend")}
        <div>
          <SvgIcon.ChevronDown />
        </div>
      </Button>
    </Dropdown>
  );
};
