import Setting from ".";

const router = [
  {
    path: "/setting",
    key: "setting",
    component: Setting,
    exact: true,
  },
];

export default router;
