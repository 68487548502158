import { t } from "i18next";
import React from "react";

function useSellerGroupOptions() {
  const convertedOptions = [
    { value: true, label: t("seller.cooperation") },
    { value: false, label: t("seller.stopCooperation") },
  ];

  const filterStatus = [
    { value: true, label: t("customer.on") },
    { value: false, label: t("customer.off") },
  ];

  return { convertedOptions, filterStatus };
}

export default useSellerGroupOptions;
