import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Space, Typography } from "antd";
import CustomTableSelectRow from "components/CustomTableSelectRow";
import { TABS_SUGGESTED_ENTRY } from "features/KeyManagement/constants";
import { useKeyManagementPermissions } from "hooks/keyManagement/query";

function TableSuggestedEntry({ propsContent, columns, columnTableSelected, activeKeyMain }) {
  const {
    dataSuggestedEntry,
    loadingDataSuggestedEntry,
    handleChangeTable,
    selectedHighlight,
    sizeScrollPageAll,
    handleRowClick,
    paginationData,
    handleToggleRows,
    isTableSelectedRow,
    selectedRow,
    rowSelection,
  } = propsContent;
  const { canSuggestKeyLicenseAccountView } = useKeyManagementPermissions();

  return (
    <CustomTableSelectRow
      columns={columns}
      columnTableSelected={columnTableSelected}
      data={canSuggestKeyLicenseAccountView ? dataSuggestedEntry : []}
      loading={loadingDataSuggestedEntry}
      onChangeTable={handleChangeTable}
      selectedHighlight={selectedHighlight}
      scroll={sizeScrollPageAll}
      rowSelection={
        (activeKeyMain === TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER ||
          activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED) &&
        rowSelection
      }
      onClickRow={handleRowClick}
      pagination={{
        total: canSuggestKeyLicenseAccountView ? paginationData?.total : 0,
        pageSize: paginationData?.limit,
        current: paginationData?.offset / paginationData?.limit + 1 || 1,
        showSizeChanger: false,
      }}
      onToggleArrow={handleToggleRows}
      isTableSelectedRow={isTableSelectedRow}
      dataTableSelected={dataSuggestedEntry ? selectedRow?.items : []}
      reasonComponent={
        activeKeyMain === TABS_SUGGESTED_ENTRY.CANCELED && (
          <Alert
            message={
              <span>
                <Typography.Text strong>{"Đề nghị bị hủy vì: "}:</Typography.Text>
                <Typography.Text style={{ marginLeft: "4px" }}>{selectedRow?.cancelReason || "--"}</Typography.Text>
              </span>
            }
            icon={<ExclamationCircleOutlined />}
            type="error"
            showIcon
            style={{ width: "100%", marginBottom: "12px" }}
          />
        )
      }
      titleCardTableSelected={
        <Space direction="horizontal">
          <Typography.Text strong>Danh sách sản phẩm key license/ account của </Typography.Text>
          <Typography.Link strong>{selectedRow?.number || "--"}</Typography.Link>
        </Space>
      }
    />
  );
}

export default TableSuggestedEntry;
