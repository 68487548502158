import { Typography } from "antd";
import { SHIPPING_TYPE } from "features/Cart/constants";
import { t } from "i18next";

export const WARRANTY_TYPE = {
  INTERNAL: "INTERNAL",
  MANUFACTURE: "MANUFACTURE",
  CUSTOM: "CUSTOM",
  STAMP: "STAMP",
};

export const WARRANTY_STATUS = {
  STILL_VALIDATED: "STILL_VALIDATED",
  INVALIDATED: "INVALIDATED",
  EXPIRED: "EXPIRED",
  NOT_EXIST: "NOT_EXIST",
};

export const WARRANTY_REQUEST_STATUS = {
  WAITING_FOR_PROCESS: "WAITING_FOR_PROCESS",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const WARRANTY_SERIAL_STATUS = {
  WAITING_FOR_CHECK: "WAITING_FOR_CHECK",
  CHECKING: "CHECKING",
  WAITING_FOR_DISTRIBUTE: "WAITING_FOR_DISTRIBUTE",
  REPAIRING: "REPAIRING",
  WAITING_FOR_RETURN: "WAITING_FOR_RETURN",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const WARRANTY_REQUEST_ITEM_RESULT = {
  EXCHANGE_SIMILAR_PRODUCT: "EXCHANGE_SIMILAR_PRODUCT",
  RENEW_SAME_PRODUCT: "RENEW_SAME_PRODUCT",
  DONE: "DONE",
  CANCEL: "CANCEL",
  REIMPORT: "REIMPORT",
};

export const WARRANTY_REQUEST_ITEM_RECEIVE_PARTY = {
  PROVIDER: "PROVIDER",
  THIRD_PARTY: "THIRD_PARTY",
  TECHNICAL_STAFF: " TECHNICAL_STAFF",
};

export const WARRANTY_REQUEST_ITEM_SOLUTION = {
  PROVIDER: "PROVIDER",
  THIRD_PARTY: "THIRD_PARTY",
  SELF: "SELF",
  FOREIGN_WARRANTY: "FOREIGN_WARRANTY",
};

export const WARRANTY_TABS = {
  INFO: "INFO",
  HISTORY: "HISTORY",
};

export const WARRANTY_REQUEST_TYPE = {
  SELF: "SELF",
  BEHALF: "BEHALF",
};

export const warrantySerialLabel = {
  [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK}`]: t(
    "warranty.warrantyRequestList.warrantySerialStatus.waitingForCheck"
  ),
  [`${WARRANTY_SERIAL_STATUS.CHECKING}`]: t("warranty.warrantyRequestList.warrantySerialStatus.checking"),
  [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE}`]: t(
    "warranty.warrantyRequestList.warrantySerialStatus.waitingForDistribute"
  ),
  [`${WARRANTY_SERIAL_STATUS.REPAIRING}`]: t("warranty.warrantyRequestList.warrantySerialStatus.repairing"),
  [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN}`]: t(
    "warranty.warrantyRequestList.warrantySerialStatus.waitingForReturn"
  ),
  [`${WARRANTY_SERIAL_STATUS.DELIVERING}`]: t("warranty.warrantyRequestList.warrantySerialStatus.delivering"),
  [`${WARRANTY_SERIAL_STATUS.COMPLETED}`]: t("warranty.warrantyRequestList.warrantySerialStatus.completed"),
  [`${WARRANTY_SERIAL_STATUS.CANCELED}`]: t("warranty.warrantyRequestList.warrantySerialStatus.canceled"),
};

export const warrantyRequestLabel = {
  [`${WARRANTY_REQUEST_STATUS.WAITING_FOR_PROCESS}`]: t("warranty.warrantyRequestList.waitingForProcess"),
  [`${WARRANTY_REQUEST_STATUS.PROCESSING}`]: t("warranty.warrantyRequestList.processing"),
  [`${WARRANTY_REQUEST_STATUS.COMPLETED}`]: t("warranty.warrantyRequestList.completed"),
  [`${WARRANTY_REQUEST_STATUS.CANCELED}`]: t("warranty.warrantyRequestList.canceled"),
};

export const warrantyRequestItemResultLabel = {
  [WARRANTY_REQUEST_ITEM_RESULT.EXCHANGE_SIMILAR_PRODUCT]: t("warrantyRequest.popup.changeSameType"),
  [WARRANTY_REQUEST_ITEM_RESULT.RENEW_SAME_PRODUCT]: t("warrantyRequest.popup.changeEquivalent"),
  [WARRANTY_REQUEST_ITEM_RESULT.DONE]: t("warrantyRequest.popup.warrantyDone"),
  [WARRANTY_REQUEST_ITEM_RESULT.CANCEL]: t("warrantyRequest.popup.warrantyCancel"),
  [WARRANTY_REQUEST_ITEM_RESULT.REIMPORT]: t("warrantyRequest.popup.retype"),
  null: <Typography.Text italic>{t("warrantyRequest.detail.notYet")}</Typography.Text>,
};

export const warrantyRequestItemReceivePartyLabel = {
  [`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.PROVIDER}`]: "Giao cho NCC| Hãng",
  [`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.THIRD_PARTY}`]: "Giao cho bên thứ ba",
  [`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.TECHNICAL_STAFF}`]: "Giao cho Nhân viên kỹ thuật (HA)",
};

export const warrantyRequestTypeLabel = {
  [`${WARRANTY_REQUEST_TYPE.SELF}`]: "Bảo hành",
  [`${WARRANTY_REQUEST_TYPE.BEHALF}`]: "Bảo hành hộ",
};

export const shippingTypeLabel = {
  [`${SHIPPING_TYPE.BUS}`]: "Nhà xe",
  [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: "Đối tác vận chuyển",
  [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: "Trực tiếp",
  [`${SHIPPING_TYPE.URBAN_COD}`]: "Shipper Hồng Anh",
};

export const warrantyTypeOptions = [
  { label: t("warranty.warrantyDetail.internal"), value: WARRANTY_TYPE.INTERNAL },
  { label: "Tem phiếu", value: WARRANTY_TYPE.STAMP },
  { label: t("warranty.warrantyDetail.manufacture"), value: WARRANTY_TYPE.MANUFACTURE },
  { label: t("warranty.warrantyDetail.custom"), value: WARRANTY_TYPE.CUSTOM },
];

export const warrantyStatusOptions = [
  { label: t("warranty.warrantyDetail.stillValidated"), value: WARRANTY_STATUS.STILL_VALIDATED },
  { label: t("warranty.warrantyDetail.invalidated"), value: WARRANTY_STATUS.INVALIDATED },
  { label: t("warranty.warrantyDetail.expire"), value: WARRANTY_STATUS.EXPIRED },
  { label: t("warrantyRequest.detail.asRequired"), value: WARRANTY_STATUS.NOT_EXIST },
];

export const warrantyRequestOptions = [
  {
    label: warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.WAITING_FOR_PROCESS}`],
    value: WARRANTY_REQUEST_STATUS.WAITING_FOR_PROCESS,
  },
  {
    label: warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.PROCESSING}`],
    value: WARRANTY_REQUEST_STATUS.PROCESSING,
  },
  {
    label: warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.COMPLETED}`],
    value: WARRANTY_REQUEST_STATUS.COMPLETED,
  },
  {
    label: warrantyRequestLabel[`${WARRANTY_REQUEST_STATUS.CANCELED}`],
    value: WARRANTY_REQUEST_STATUS.CANCELED,
  },
];

export const warrantySerialOptions = [
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK}`],
    value: WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.CHECKING}`],
    value: WARRANTY_SERIAL_STATUS.CHECKING,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE}`],
    value: WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.REPAIRING}`],
    value: WARRANTY_SERIAL_STATUS.REPAIRING,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN}`],
    value: WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.DELIVERING}`],
    value: WARRANTY_SERIAL_STATUS.DELIVERING,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.COMPLETED}`],
    value: WARRANTY_SERIAL_STATUS.COMPLETED,
  },
  {
    label: warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.CANCELED}`],
    value: WARRANTY_SERIAL_STATUS.CANCELED,
  },
];

export const warrantyRequestItemResultOptions = [
  {
    label: t("warrantyRequest.popup.changeSameType"),
    value: WARRANTY_REQUEST_ITEM_RESULT.EXCHANGE_SIMILAR_PRODUCT,
  },
  {
    label: t("warrantyRequest.popup.changeEquivalent"),
    value: WARRANTY_REQUEST_ITEM_RESULT.RENEW_SAME_PRODUCT,
  },
  {
    label: t("warrantyRequest.popup.warrantyDone"),
    value: WARRANTY_REQUEST_ITEM_RESULT.DONE,
  },
  {
    label: t("warrantyRequest.popup.warrantyCancel"),
    value: WARRANTY_REQUEST_ITEM_RESULT.CANCEL,
  },
  {
    label: t("warrantyRequest.popup.retype"),
    value: WARRANTY_REQUEST_ITEM_RESULT.REIMPORT,
  },
];

export const warrantyRequestItemReceivePartyOptions = [
  {
    value: WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.PROVIDER,
    label: warrantyRequestItemReceivePartyLabel[`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.PROVIDER}`],
  },
  {
    value: WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.THIRD_PARTY,
    label: warrantyRequestItemReceivePartyLabel[`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.THIRD_PARTY}`],
  },
  {
    value: WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.TECHNICAL_STAFF,
    label: warrantyRequestItemReceivePartyLabel[`${WARRANTY_REQUEST_ITEM_RECEIVE_PARTY.TECHNICAL_STAFF}`],
  },
];
export const WARRANTY_REQUEST_CATEGORY = {
  WARRANTY_REQUEST: "WARRANTY_REQUEST",
  WARRANTY_REQUEST_HELP: "WARRANTY_REQUEST_HELP",
};

export const warrantyRequestCategoryLabel = {
  [`${WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST}`]: t("warrantyRequest.detail.requestType.warranty"),
  [`${WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST_HELP}`]: t("warrantyRequest.detail.requestType.houseoldWarranty"),
};

export const warrantyRequestCategoryOptions = [
  {
    value: WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST,
    label: warrantyRequestCategoryLabel[`${WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST}`],
  },
  {
    value: WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST_HELP,
    label: warrantyRequestCategoryLabel[`${WARRANTY_REQUEST_CATEGORY.WARRANTY_REQUEST_HELP}`],
  },
];

export const warrantyRequestTypeOptions = [
  {
    value: WARRANTY_REQUEST_TYPE.SELF,
    label: warrantyRequestTypeLabel[`${WARRANTY_REQUEST_TYPE.SELF}`],
  },
  {
    value: WARRANTY_REQUEST_TYPE.BEHALF,
    label: warrantyRequestTypeLabel[`${WARRANTY_REQUEST_TYPE.BEHALF}`],
  },
];

export const shippingTypeOptions = [
  { value: SHIPPING_TYPE.URBAN_COD, label: shippingTypeLabel[`${SHIPPING_TYPE.URBAN_COD}`] },
  { value: SHIPPING_TYPE.BUS, label: shippingTypeLabel[`${SHIPPING_TYPE.BUS}`] },
  {
    value: SHIPPING_TYPE.DELIVER_3PARTY,
    label: shippingTypeLabel[`${SHIPPING_TYPE.DELIVER_3PARTY}`],
  },
  {
    value: SHIPPING_TYPE.PICK_UP_COUNTER,
    label: shippingTypeLabel[`${SHIPPING_TYPE.PICK_UP_COUNTER}`],
  },
];

export const warrantyTypeLabel = {
  [`${WARRANTY_TYPE.CUSTOM}`]: t("warranty.warrantyDetail.custom"),
  [`${WARRANTY_TYPE.INTERNAL}`]: t("warranty.warrantyDetail.internal"),
  [`${WARRANTY_TYPE.MANUFACTURE}`]: t("warranty.warrantyDetail.manufacture"),
  [`${WARRANTY_TYPE.STAMP}`]: "Tem phiếu",
};

export const warrantyStatusLabel = {
  [`${WARRANTY_STATUS.STILL_VALIDATED}`]: (
    <span style={{ color: "#00AB78" }}>{t("warranty.warrantyDetail.stillValidated")}</span>
  ),
  [`${WARRANTY_STATUS.INVALIDATED}`]: (
    <span style={{ color: "#D2434D" }}>{t("warranty.warrantyDetail.invalidated")}</span>
  ),
  [`${WARRANTY_STATUS.EXPIRED}`]: <span style={{ color: "" }}>{t("warranty.warrantyDetail.expire")}</span>,
};
