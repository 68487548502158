import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useOidc } from "@axa-fr/react-oidc";
import { Avatar, Badge, Dropdown, Layout, Menu } from "antd";
import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import { OAUTH2_ENABLED } from "config/auth";
import { PLATFORMS_CODE } from "config/constants";
import { firebaseConfig } from "config/firebase";
import { appRoutes, getAppRoutes } from "config/routes";
import flagsmith from "flagsmith";
import { useFlags } from "flagsmith/react";
import { useLogout } from "hooks/auth/auth";
import { useGetMe } from "hooks/auth/login";
import { useCartPermissions } from "hooks/cart";
import { useFirebase } from "hooks/firebase/firebase";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useGetUnreadNotifications } from "hooks/notification";
import { useUpdateUserNotification } from "hooks/user/user";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { getFilterRouters } from "utils/filteredRouter";
import { removeManyValueByKey } from "utils/object";
import { ChatwootWidget } from "../../components/Chatwoot";
import { CHATWOOT_TOKEN, CHATWOOT_URL } from "../../config/platform";
import NotificationMenu from "./Notification/component/NotificationMenu";
import "./index.scss";

const OldLogoutMenuItem = () => {
  const { logout } = useLogout();
  return (
    <Menu.Item
      key="/logout"
      className="menu-item log-out"
      onClick={() => {
        try {
          window.$chatwoot.toggleBubbleVisibility("hide");
          window.$chatwoot.reset();
        } catch (error) {
          console.info(error);
        }
        logout();
      }}
    >
      <FontAwesomeIcon icon={Icon.faSignOutAlt} />
      <span>Đăng xuất</span>
    </Menu.Item>
  );
};

const NewLogoutMenuItem = () => {
  const { logout } = useOidc();
  return (
    <Menu.Item key="/logout" className="menu-item log-out" onClick={() => logout("/")}>
      <FontAwesomeIcon icon={Icon.faSignOutAlt} />
      <span>Đăng xuất</span>
    </Menu.Item>
  );
};

const App = () => {
  // Setup firebase
  const { handleUpdateUserNotificationToken } = useUpdateUserNotification();

  const { getMessagingToken } = useFirebase(firebaseConfig);
  const [chatwootReady, setChatwootReady] = useState(false);

  const LogoutItem = OAUTH2_ENABLED ? NewLogoutMenuItem : OldLogoutMenuItem;

  //Ignore warning
  const backup = console.error;

  console.error = function filterWarnings(msg) {
    const suppressedWarnings = ["is deprecated in StrictMode"];

    if (!suppressedWarnings.some((entry) => msg.includes(entry))) {
      backup.apply(console, arguments);
    }
  };

  //Main code here
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const [menuItem, setMenuItems] = useState([]);
  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;
  const dispatch = useDispatch();
  const { platformURL, platformName, platformCode } = useGetInfoPlatform();

  const { me } = useGetMe();
  let customer = {
    name: me?.fullname,
    email: me?.email,
    external_id: `HAC-${me?.id}`,
  };

  const flags = useFlags(["is_hide_routes_by_key", "chat_support_enabled"]);
  const isHiddenRoutesByKey = flags.is_hide_routes_by_key.enabled;

  let chatSupportEnabled = flags.chat_support_enabled.enabled;
  useEffect(() => {
    if (me && chatwootReady) {
      window.$chatwoot.setUser(`staff:${me.id}`, {
        email: me.email,
        name: me.fullname,
        avatar_url: me.avatarURL,
        phone_number: me.telephone,
      });
      window.$chatwoot.toggleBubbleVisibility("show");
    }
    const envRegex = /https:\/\/gw\.(\w*)api\.honganh\.vn\/graphql/;
    const matches = process.env.REACT_APP_BASE_GRAPHQL_URL.match(envRegex);
    const getEnv = matches ? matches[1] || "" : "";

    if (me) {
      flagsmith.identify(me?.id, { name: me.fullname, userType: "STAFF", email: me.email, [`id${getEnv}`]: me.id });
    }
  }, [me?.id, chatwootReady]);

  useEffect(() => {
    getMessagingToken().then((token) => {
      if (token) {
        handleUpdateUserNotificationToken(token);
      }
    });
  }, []);

  const setChatwootReadyStatus = function () {
    setChatwootReady(true);
  };

  useEffect(() => {
    window.addEventListener("chatwoot:ready", setChatwootReadyStatus);
    return () => {
      window.removeEventListener("chatwoot:ready", setChatwootReadyStatus);
    };
  }, []);

  useEffect(() => {
    const arKey = ["salesReturn"];
    const filteredData =
      platformCode === PLATFORMS_CODE.HA
        ? appRoutes?.map((item) => {
            const filteredChildren = item?.children?.filter((child) => !arKey?.includes(child.key));
            return { ...item, children: filteredChildren };
          })
        : appRoutes;

    if (isHiddenRoutesByKey) {
      const removeList = [...JSON.parse(flags.is_hide_routes_by_key.value)?.removeList];

      const newMenuItem = removeManyValueByKey(filteredData, removeList);
      setMenuItems(newMenuItem);
    } else {
      setMenuItems(filteredData);
    }
  }, [isHiddenRoutesByKey, JSON.stringify(platformCode)]);

  const userInfo = useSelector((store) => store?.me?.get?.data);
  const { cartPermissions } = useCartPermissions();
  const { data: unreadNotify } = useGetUnreadNotifications();

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const currentPath = window.location.pathname;

  const headerMenu = (
    <Menu className="header-menu">
      <Menu.Item key="/profile" className="menu-item">
        <Link to="/profile?activeKey=listProfile">
          <FontAwesomeIcon icon={Icon.faUser} />
          <span>{t("account.infoUsers")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/change-password" className="menu-item">
        <Link to="/change-password">
          <FontAwesomeIcon icon={Icon.faLock} />
          <span>{t("account.changePassword")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item className="menu-item" key="setting" path="/setting">
        <Link to="/setting">
          <FontAwesomeIcon icon={Icon.faCog} />
          <span> {t("common.setting")}</span>
        </Link>
      </Menu.Item>
      <LogoutItem />
    </Menu>
  );

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  function closeSideBar() {
    if (window && window.innerWidth < 800) {
      setCollapsed(true);
    }
  }

  function getUserInfo() {
    dispatch({
      type: "GET_ME_REQUEST",
    });
  }

  useEffect(() => {
    for (let item of appRoutes) {
      if (!item?.children && currentPath === item?.path) {
        setSelectedKeys([item?.key]);
        break;
      }

      if (item?.children && item?.title) {
        for (let child of item.children) {
          if (currentPath === child?.path) {
            setSelectedKeys([child?.key]);
            if (!openKeys.includes(item?.key)) {
              const newOpenKeys = [...openKeys, item?.key];
              showMenuMobile && setOpenKeys(newOpenKeys);
            }
            break;
          }
        }
      }
    }
  }, [currentPath]);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    document.title = platformName || "";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = platformURL;
  }, [platformName, platformURL]);

  return (
    <Layout className="layout">
      <div className={`mask ${collapsed ? "mask-closed" : "mask-opened"}`} onClick={toggle}></div>

      {showMenuMobile && (
        <Sider
          width={225}
          trigger={null}
          className={`side-bar ${collapsed ? "side-bar-closed" : "side-bar-opened"}`}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo">
            <div className="img">
              <img src={platformURL} alt={``} />
            </div>
          </div>

          <Menu
            className="menu"
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={(e) => setOpenKeys(e)}
            onClick={(e) => setSelectedKeys(e.key)}
          >
            {getFilterRouters(menuItem)?.map((item) => {
              if (!item?.title) {
                return;
              }

              if (!item?.children) {
                return (
                  <Menu.Item onClick={closeSideBar} {...item} className="menu-item" key={item?.key}>
                    <Link to={item?.path}>{item?.title ? t(item.title) : ""}</Link>
                  </Menu.Item>
                );
              }

              return (
                <SubMenu className="sub-menu" {...item} key={item?.key} title={item?.title ? t(item.title) : ""}>
                  {item?.children?.map((child) => (
                    <Menu.Item onClick={closeSideBar} className="menu-item" key={child?.key}>
                      <Link to={child?.path}>{child?.title ? t(child.title) : ""}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            })}

            <Menu.Item className="setting" key="setting" path="/setting" onClick={closeSideBar}>
              <Link to="/setting">
                <div
                  className="setting"
                  style={{
                    justifyContent: collapsed ? "center" : "unset",
                  }}
                >
                  <FontAwesomeIcon icon={Icon.faCog} />
                  <span
                    style={{
                      display: collapsed ? "none" : "block",
                    }}
                  >
                    {t("common.setting")}
                  </span>
                </div>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
      )}

      <Layout className="site-layout">
        {showMenuMobile ? (
          <Header className={`header ${collapsed ? "header-closed" : "header-opened"}`}>
            {showMenuMobile &&
              React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: "trigger",
                onClick: toggle,
              })}

            {showMenuMobile && (
              <div className="mobile-menu" onClick={toggle}>
                <FontAwesomeIcon icon={Icon.faBars} />
              </div>
            )}
          </Header>
        ) : (
          <Header className={`header`}>
            <div className="logo-desktop">
              <div className="img">
                <img src={platformURL} alt={``} />
              </div>
            </div>
            <Menu
              className="menu menu-desktop"
              mode="horizontal"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={(e) => setOpenKeys(e)}
              onClick={(e) => setSelectedKeys(e.key)}
            >
              {getFilterRouters(menuItem)?.map((item) => {
                if (!item?.title) {
                  return;
                }

                if (!item?.children) {
                  return (
                    <Menu.Item onClick={closeSideBar} {...item} className="menu-item" key={item?.key}>
                      <Link to={item?.path}>{item?.title ? t(item.title) : ""}</Link>
                    </Menu.Item>
                  );
                }

                return (
                  <SubMenu className="sub-menu" {...item} key={item?.key} title={item?.title ? t(item.title) : ""}>
                    {item?.children?.map((child) => (
                      <Menu.Item onClick={closeSideBar} className="menu-item" key={child?.key}>
                        <Link to={child?.path}>{child?.title ? t(child.title) : ""}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              })}
            </Menu>
            <div className="right-part">
              {cartPermissions.canViewCart && (
                <Link to="/cart">
                  <Badge count={me?.totalCart}>
                    <Avatar size={40} className="icon-container" icon={<SvgIcon.CartIcon className="icon-style" />} />
                  </Badge>
                </Link>
              )}

              <Dropdown destroyPopupOnHide overlay={<NotificationMenu />}>
                <Badge count={unreadNotify} overflowCount={9}>
                  <Avatar
                    size={40}
                    className="icon-container"
                    icon={<FontAwesomeIcon icon={Icon.faBell} className="icon-style" />}
                  />
                </Badge>
              </Dropdown>

              <Dropdown trigger="click" overlay={headerMenu} placement="bottomRight">
                <div className="user-info">
                  <Avatar
                    size={40}
                    className="icon-container"
                    icon={<FontAwesomeIcon icon={Icon.faUser} className="icon-style" />}
                  />

                  <div className="info">
                    <p className="user-name">{userInfo?.user?.fullName}</p>
                    <p className="user-role">{userInfo?.department?.name}</p>
                  </div>

                  <FontAwesomeIcon className="down-arrow" icon={Icon.faChevronDown} />
                </div>
              </Dropdown>
            </div>
          </Header>
        )}

        <Content className={`site-layout-background content ${collapsed ? "content-closed" : "content-opened"}`}>
          <Switch>
            {getAppRoutes()?.map((item, index) => {
              return <Route key={index} {...item} />;
            })}

            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Content>
        {chatSupportEnabled && me && <ChatwootWidget url={CHATWOOT_URL} token={CHATWOOT_TOKEN} />}
      </Layout>
    </Layout>
  );
};

export default App;
