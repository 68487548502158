import { Col, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { Link } from "react-router-dom";

function ProviderInfo({ providerInfo }) {
  return (
    <Row gutter={[16, 8]}>
      {providerInfo && (
        <>
          <Col span={4}>
            <Typography.Text className="sub-title">{t("purchase.table.supplier")}</Typography.Text>
          </Col>
          <Col span={20}>
            <Link to={`/supplier/detail/${providerInfo?.id}`} target="_blank">
              <Typography.Link strong>{providerInfo?.code}</Typography.Link>
            </Link>
          </Col>
          <Col span={4}></Col>
          <Col span={20}>
            <Typography.Text>{providerInfo?.fullName}</Typography.Text>
          </Col>
        </>
      )}
      <Col span={24}>
        {
          <FormInput
            {...{
              type: INPUT_TYPE.DATE_PICKER,
              formItemOptions: {
                label: <Typography.Text strong>{t("serialIE.providerInfo.saleDate")}</Typography.Text>,
                name: "soldDateFromProvider",
                rules: [{ required: true, message: t("common.validateRequired") }],
              },
              inputOptions: {
                placeholder: t("warrantyRequest.create.placeHolder.date"),
                format: DATE_FORMAT,
              },
            }}
          />
        }
      </Col>
    </Row>
  );
}

export default ProviderInfo;
