import { CloseOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Alert, Col, Descriptions, Row, Skeleton, Space, Spin, Tag, Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import CustomDrawer from "components/CustomDrawer";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { PrimaryButton } from "components/PrimaryButton";
import { useGetKeyDetail, useKeyManagementPermissions } from "hooks/keyManagement/query";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate } from "utils/helperFuncs";
import {
  KEY_TYPE,
  STATUS_KEY_LIST,
  convertDateWithDateUnit,
  hideChars,
  statusKeyLabelColor,
  statusKeyListLabel,
  statusKeyTagColor,
} from "../constants";
import CustomDivider from "./CustomDivider";
import LockKey from "./LockKey";
import UnlockKey from "./UnlockKey";
import "./index.scss";

function KeyDetail({ visible, onClose, idDetail, showPassword, password, loadingShowPassword, onShowPassword }) {
  const { t } = useTranslation();
  const { dataKeyDetail, loadingKeyDetail } = useGetKeyDetail({ id: idDetail });
  const { canKeyLicenseAccountLock, canKeyLicenseAccountUnlock } = useKeyManagementPermissions();

  const handleOnClose = () => {
    onClose();
  };

  const convertWarrantyEndDate = ({ date, warranty, warrantyUnit }) => {
    if (!warranty) {
      return null;
    }

    let formatDate = moment(date);

    switch (warrantyUnit) {
      case "DAY":
        formatDate.add(warranty, "days");
        break;
      case "MONTH":
        formatDate.add(warranty, "months");
        break;
      case "YEAR":
        formatDate.add(warranty, "years");
        break;
      default:
        return null;
    }

    return formatDate.format("DD/MM/YYYY");
  };

  const checkWarrantyStatus = (warrantyEndDate) => {
    if (!warrantyEndDate) {
      return null;
    }

    const currentDate = moment();
    const endDate = moment(warrantyEndDate, "DD/MM/YYYY");

    if (endDate.isBefore(currentDate, "day")) {
      return <Typography.Text type="danger">({t("v2.keyManagement.exportNoteDetail.expired")})</Typography.Text>;
    } else {
      return <Typography.Text type="success">({t("v2.keyManagement.exportNoteDetail.stillValid")})</Typography.Text>;
    }
  };

  return (
    <div className="key-detail-custom">
      <CustomDrawer
        visible={visible}
        onClose={handleOnClose}
        width={600}
        customTitle={
          <Skeleton active loading={loadingKeyDetail}>
            <Row style={{ alignItems: "center" }}>
              <Col span={1}>
                <div onClick={handleOnClose} style={{ cursor: "pointer" }}>
                  <CloseOutlined />
                </div>
              </Col>
              <Col span={23}>
                <Space
                  className="content-header-sticky"
                  style={{
                    backgroundColor: "rgba(231, 240, 255, 1)",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <Typography.Text style={{ fontSize: "14px" }}>
                      {t("v2.keyManagement.exportNoteDetail.title")}
                    </Typography.Text>{" "}
                    <Typography.Link style={{ fontSize: "14px" }} strong>
                      {dataKeyDetail?.code || hideChars(dataKeyDetail?.codeDisplay)}
                    </Typography.Link>
                  </Typography>
                  <Tag color={statusKeyTagColor[dataKeyDetail?.status]} style={{ padding: "6px 16px" }}>
                    <Typography.Text style={{ color: statusKeyLabelColor[dataKeyDetail?.status] }}>
                      {statusKeyListLabel[dataKeyDetail?.status]}
                    </Typography.Text>
                  </Tag>
                </Space>
              </Col>
            </Row>
          </Skeleton>
        }
        headerSticky={
          <Skeleton active loading={loadingKeyDetail}>
            <Space style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              {dataKeyDetail?.status === STATUS_KEY_LIST.DISABLED
                ? canKeyLicenseAccountUnlock && (
                    <UnlockKey
                      id={dataKeyDetail?.id}
                      data={dataKeyDetail?.code || hideChars(dataKeyDetail?.codeDisplay)}
                    />
                  )
                : canKeyLicenseAccountLock && <LockKey id={dataKeyDetail?.id} />}
              <PrimaryButton title={t("v2.keyManagement.edit")} />
            </Space>
          </Skeleton>
        }
        content={
          <Row gutter={[16, 16]}>
            {/* Lý do khoá */}
            {dataKeyDetail?.status === STATUS_KEY_LIST.DISABLED && (
              <Skeleton active loading={loadingKeyDetail}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Alert
                        message={
                          <span>
                            <Typography.Text strong>
                              {t("v2.keyManagement.exportNoteDetail.reasonLock")}:
                            </Typography.Text>
                            <Typography.Text style={{ marginLeft: "4px" }}>
                              {dataKeyDetail?.lockedReason?.reason || "--"}
                            </Typography.Text>
                          </span>
                        }
                        icon={<ExclamationCircleOutlined />}
                        type="error"
                        showIcon
                        style={{ width: "100%", marginBottom: "10px" }}
                      />
                    </Col>
                    <Col span={24}>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {dataKeyDetail?.lockedReason?.files?.map((fileURL, index) => {
                          return <CustomViewDocumentFiles file={fileURL} key={index} />;
                        })}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Skeleton>
            )}
            <Col span={24}>
              {/* Thông tin chung */}
              <CustomDescriptions
                loading={loadingKeyDetail}
                title={<CustomDivider title={t("v2.keyManagement.exportNoteDetail.generalInformation")} />}
                content={
                  <>
                    {dataKeyDetail?.product?.productType === KEY_TYPE.account && (
                      <Descriptions.Item label="Password">
                        <Space style={{ width: "100%", justifyContent: "space-between" }}>
                          <Typography.Link strong>
                            <Spin spinning={loadingShowPassword}>
                              {showPassword ? password || "********" : "********"}
                            </Spin>
                          </Typography.Link>
                          <Typography.Link onClick={() => onShowPassword(dataKeyDetail?.id)}>
                            {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                          </Typography.Link>
                        </Space>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label={t("v2.keyManagement.productCode")}>
                      <Typography.Link strong>
                        <Link to={`/product/detail/${dataKeyDetail?.productID}`} target="_blank">
                          {dataKeyDetail?.product?.code || "--"}
                        </Link>
                      </Typography.Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.productName")}>
                      {dataKeyDetail?.product?.name || "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.note")}>--</Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.imageAndFile")}>
                      --
                    </Descriptions.Item>
                  </>
                }
              />
            </Col>
            <Col span={24}>
              {/* Thông tin nhập hàng */}
              <CustomDescriptions
                loading={loadingKeyDetail}
                title={<CustomDivider title={t("v2.keyManagement.exportNoteDetail.importInfo")} />}
                content={
                  <>
                    <Descriptions.Item label={t("v2.keyManagement.supplier")}>
                      <Space direction="vertical">
                        <Typography.Link strong>
                          <Link
                            to={`/supplier/detail/${dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.providerID}`}
                            target="_blank"
                          >
                            {dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.provider?.code}
                          </Link>
                        </Typography.Link>
                        <Typography.Text>
                          {" "}
                          {dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.provider?.fullName}
                        </Typography.Text>
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.purchaseOrder")}>
                      <Typography.Text>
                        {dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.purchaseOrderCode || "--"}
                      </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.dateAdded")}>
                      {formatDate(dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.createdAt) || "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.warranty")}>
                      {convertDateWithDateUnit(
                        dataKeyDetail?.serialImportNoteItemLatest?.warrantyPeriodProvider,
                        dataKeyDetail?.serialImportNoteItemLatest?.warrantyUnit
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.warrantyTerm")}>
                      <Space direction="vertical">
                        <Typography.Text>
                          {convertWarrantyEndDate({
                            date: dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.createdAt,
                            warranty: dataKeyDetail?.serialImportNoteItemLatest?.warrantyPeriodProvider,
                            warrantyUnit: dataKeyDetail?.serialImportNoteItemLatest?.warrantyUnit,
                          }) || "--"}
                        </Typography.Text>
                        {checkWarrantyStatus(
                          convertWarrantyEndDate({
                            date: dataKeyDetail?.serialImportNoteItemLatest?.serialImportNote?.createdAt,
                            warranty: dataKeyDetail?.serialImportNoteItemLatest?.warrantyPeriodProvider,
                            warrantyUnit: dataKeyDetail?.serialImportNoteItemLatest?.warrantyUnit,
                          })
                        )}
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.copyrightTerm")}>
                      {dataKeyDetail?.serialImportNoteItemLatest
                        ? convertDateWithDateUnit(
                            dataKeyDetail?.serialImportNoteItemLatest?.copyrightTerm,
                            dataKeyDetail?.serialImportNoteItemLatest?.copyrightUnit
                          )
                        : "--"}
                    </Descriptions.Item>
                  </>
                }
              />
            </Col>
            <Col span={24}>
              {/* Thông tin xuất hàng */}
              <CustomDescriptions
                loading={loadingKeyDetail}
                title={<CustomDivider title={t("v2.keyManagement.exportNoteDetail.exportInfo")} />}
                content={
                  <>
                    <Descriptions.Item label={t("v2.keyManagement.customer")}>
                      <Space direction="vertical">
                        {dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.seller ? (
                          <>
                            <Typography.Link strong>
                              <Link
                                to={`/supplier/detail/${dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.sellerID}`}
                                target="_blank"
                              >
                                {dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.seller?.code}
                              </Link>
                            </Typography.Link>
                            <Typography.Text>
                              {dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.seller?.fullName}
                            </Typography.Text>
                          </>
                        ) : (
                          "--"
                        )}
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.order")}>
                      <Typography.Link strong>
                        <Link
                          to={`/order/${dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.orderID}`}
                          target="_blank"
                        >
                          {dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.order?.number || "--"}
                        </Link>
                      </Typography.Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.dateExport")}>
                      {formatDate(dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.createdAt) || "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.warranty")}>
                      {convertDateWithDateUnit(
                        dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSale,
                        dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSaleUnit
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.exportNoteDetail.warrantyTerm")}>
                      <Space direction="vertical">
                        <Typography.Text>
                          {convertWarrantyEndDate({
                            date: dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.createdAt,
                            warranty: dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSale,
                            warrantyUnit: dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSaleUnit,
                          }) || "--"}
                        </Typography.Text>
                        {checkWarrantyStatus(
                          convertWarrantyEndDate({
                            date: dataKeyDetail?.serialExportNoteItemLatest?.serialExportNote?.createdAt,
                            warranty: dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSale,
                            warrantyUnit: dataKeyDetail?.serialExportNoteItemLatest?.warrantyOnSaleUnit,
                          })
                        )}
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("v2.keyManagement.copyrightTerm")}>
                      {dataKeyDetail?.serialExportNoteItemLatest
                        ? convertDateWithDateUnit(
                            dataKeyDetail?.product?.copyrightTerm,
                            dataKeyDetail?.product?.copyrightUnit
                          )
                        : "--"}
                    </Descriptions.Item>
                  </>
                }
              />
            </Col>
          </Row>
        }
      />
    </div>
  );
}

export default KeyDetail;
