import { UsergroupAddOutlined } from "@ant-design/icons";
import SupplierCreate from "features/Supplier/SupplierCreate";
import SupplierList from "features/Supplier/SupplierList";
import SupplierDetail from "features/Supplier/SupplierDetail";
import SupplierUpdate from "features/Supplier/SupplierUpdate";
import { USER_PERMISSIONS } from "config/constants";

const router = [
  {
    key: "supplier",
    title: "supplier.title",
    icon: <UsergroupAddOutlined />,
    permissions: [USER_PERMISSIONS.SUPPLIER_VIEW],
    children: [
      {
        key: "supplierList",
        title: "supplier.supplierList",
        path: "/supplier-list",
        exact: true,
        component: SupplierList,
      },
    ],
  },
  {
    key: "supplier-create",
    path: "/supplier/create",
    component: SupplierCreate,
    exact: true,
  },
  {
    key: "supplier-detail",
    path: "/supplier/detail/:id",
    component: SupplierDetail,
    exact: true,
  },
  {
    key: "supplier-update",
    path: "/supplier/update/:id",
    component: SupplierUpdate,
    exact: true,
  },
];

export default router;
