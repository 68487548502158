/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Radio, Row, Select, Space, Spin, Tag, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { SHIPPING_TYPE, USER_PERMISSIONS, USER_STATUS } from "config/constants";
import { SHIPPING_PROVIDER_TYPE } from "features/Cart/constants";
import { useGetShippingProvider } from "hooks/cart";
import { useConfirmDeliver, useCreateOrderToShippingProvider } from "hooks/order";
import useDebounce from "hooks/useDebounce";
import { useGetShipper } from "hooks/user/user";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { formatCurrency, notify } from "utils/helperFuncs";
// import { CustomModalConfirmDelivery } from "../ButtonAssignToDeliver";
import "./index.scss";

export const ButtonDivisionOrder = ({ order }) => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState();
  const [visible, setVisible] = useState(false);

  const [params, setParams] = useState({
    filters: {
      query: undefined,
      permission: USER_PERMISSIONS.ORDER_SHIPPER_DELIVER,
      status: USER_STATUS.ACTIVE,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
    sort: {
      userType: "ASC",
      by: "TOTAL_DELIVERY",
    },
  });

  const serviceFeeInput = {
    orderID: order?.id,
    carrierMethods: order?.shippingConfiguration?.deliver3PartyConfig?.carrierMethod,
  };

  const { data: dataShippingProvider } = useGetShippingProvider({
    shippingType: order?.shippingType,
    serviceFeeInput: serviceFeeInput,
  });

  const { data, loading: loadingGetShipper } = useGetShipper(params);

  const renderTag = ({ totalWaiting, totalDelivering }) => {
    if (totalWaiting && totalDelivering) {
      return (
        <Row>
          <Tag color="orange">
            {t("order.orderList.orderWaitAssigned")} {totalWaiting}
          </Tag>
          <Tag color="red">
            {t("order.orderList.orderAssigned")} {totalDelivering}
          </Tag>
        </Row>
      );
    }
    if (totalWaiting) {
      return (
        <Row>
          <Tag color="orange">
            {t("order.orderList.orderWaitAssigned")} {totalWaiting}
          </Tag>
        </Row>
      );
    }
    if (totalDelivering) {
      return (
        <Row>
          <Tag color="red">
            {t("order.orderList.orderAssigned")} {totalDelivering}
          </Tag>
        </Row>
      );
    }
    return (
      <Row>
        <Tag color="green">{t("order.orderList.orderNotAssigned")}</Tag>
      </Row>
    );
  };
  const debounceValue = useDebounce(searchTerm);

  const { handleCreateOrderToShippingProvider, loading } = useCreateOrderToShippingProvider();
  const { handleConfirmDeliver, loading: loadingConfirmShipper } = useConfirmDeliver();

  const addProduct = async () => {
    try {
      const values = await form.validateFields();
      const request = {
        method: dataShippingProvider && dataShippingProvider[0]?.method,
        shipperID: values?.shipperID,
        providerID: values?.providerID && values?.providerID?.split(" ")[0],
        orderID: order.id,
      };
      if (order.shippingType === SHIPPING_TYPE.BUS) {
        await handleConfirmDeliver({
          orderIDs: [order.id],
          shipperID: values?.shipperID,
        })
          .then(() => {
            notify.success({
              message: t("order.delivery.SuccessDeliver"),
            });
            setVisible(false);
          })
          .catch((error) => {
            notify.error({
              message: t("order.selectingShipper.confirmDeliverError"),
              description: error.message,
            });
          });
      }
      if (order.shippingType === SHIPPING_TYPE.DELIVER_3PARTY) {
        await handleCreateOrderToShippingProvider({ request })
          .then(() => {
            notify.success({ message: t("order.delivery.SuccessDeliver") });
            form.resetFields();
            setVisible(false);
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectedMethod = (e) => {
    setValue(e?.target?.value);
  };

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debounceValue,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sort: {
        userType: "ASC",
        by: "TOTAL_DELIVERY",
      },
    });
  }, [debounceValue]);
  return (
    <CustomModal
      title={
        <Typography.Text strong style={{ fontSize: "18px" }}>
          {order && order?.shippingType === SHIPPING_TYPE.DELIVER_3PARTY
            ? t("order.delivery.ListAvailableAarriers")
            : t("order.selectingShipper.title2")}
        </Typography.Text>
      }
      width="45%"
      footer={false}
      centered={true}
      htmlType="submit"
      customComponent={
        <Button style={{ width: "100%" }} type="primary" onClick={() => setVisible(true)}>
          {t("order.delivery.Deliver")}
        </Button>
      }
      buttonLoading={loading || loadingConfirmShipper}
      onOke={() => addProduct()}
      onCancel={() => setVisible(false)}
      visible={visible}
    >
      <Form form={form}>
        <div className="form-button-division-order">
          <Col span={24}>
            {order.shippingType === SHIPPING_TYPE.DELIVER_3PARTY && (
              <Row gutter={[8]}>
                <Typography.Text className="text-title-method">{t("order.delivery.DeliveryMethod")}:</Typography.Text>
                <Typography.Text className="text-highline">
                  {SHIPPING_PROVIDER_TYPE[dataShippingProvider && dataShippingProvider[0]?.method]}
                </Typography.Text>
              </Row>
            )}
          </Col>
          <Col span={24} className="form-content">
            {order.shippingType === SHIPPING_TYPE.BUS && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <div className="form-select-pick-shipper">
                  <Form.Item
                    name="shipperID"
                    label={<Typography.Text strong>Shipper</Typography.Text>}
                    rules={[
                      {
                        required: true,
                        message: t("order.selectingShipper.shipperValidateMessage"),
                      },
                    ]}
                  >
                    <Select
                      size="middle"
                      showSearch
                      placeholder={t("order.selectingShipper.shipperPlaceholder")}
                      filterOption={false}
                      optionLabelProp="label"
                      onSearch={(value) => setSearchTerm(value)}
                      dropdownRender={(originNode) => <Spin spinning={loadingGetShipper}>{originNode}</Spin>}
                    >
                      {data?.map(({ id, fullname, totalOrderDelivery, telephone }) => (
                        <Select.Option
                          key={id}
                          value={id}
                          label={
                            <Space style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography.Text>{fullname + (telephone && " - " + telephone)}</Typography.Text>
                              {renderTag(totalOrderDelivery)}
                            </Space>
                          }
                        >
                          <Space style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography.Text>{fullname + (telephone && " - " + telephone)}</Typography.Text>
                            {renderTag(totalOrderDelivery)}
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Space>
            )}
            {order.shippingType === SHIPPING_TYPE.DELIVER_3PARTY && (
              <Form.Item
                layout="vertical"
                className="form-radio"
                name="providerID"
                rules={[
                  {
                    required: true,
                    message: <div style={{ marginLeft: "20px" }}>{t("order.delivery.ChooseDeliveryMethod")}</div>,
                  },
                ]}
              >
                {visible && (
                  <Radio.Group value={value} onChange={(e) => onSelectedMethod(e)}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {dataShippingProvider &&
                        dataShippingProvider[0]?.providers?.map((item) => (
                          <>
                            <Radio value={item?.id}>
                              <Row className="method-item">
                                <div className="text-name-method">{item?.name}</div>
                                <div className="text-money">{formatCurrency(item?.fee)}</div>
                              </Row>
                            </Radio>

                            {item?.code === "HA_EXPRESS" && item.id === value && (
                              <div className="form-select-pick-shipper">
                                <Form.Item
                                  name="shipperID"
                                  rules={[
                                    {
                                      required: true,
                                      message: t("order.selectingShipper.shipperValidateMessage"),
                                    },
                                  ]}
                                >
                                  <Select
                                    size="middle"
                                    showSearch
                                    placeholder={t("order.selectingShipper.shipperPlaceholder")}
                                    filterOption={false}
                                    optionLabelProp="label"
                                    onSearch={(value) => setSearchTerm(value)}
                                    dropdownRender={(originNode) => (
                                      <Spin spinning={loadingGetShipper}>{originNode}</Spin>
                                    )}
                                  >
                                    {data?.map(({ id, fullname, totalOrderDelivery, telephone }) => (
                                      <Select.Option
                                        key={id}
                                        value={id}
                                        label={
                                          <Space style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography.Text>
                                              {fullname + (telephone && " - " + telephone)}
                                            </Typography.Text>
                                            {renderTag(totalOrderDelivery)}
                                          </Space>
                                        }
                                      >
                                        <Space style={{ display: "flex", justifyContent: "space-between" }}>
                                          <Typography.Text>
                                            {fullname + (telephone && " - " + telephone)}
                                          </Typography.Text>
                                          {renderTag(totalOrderDelivery)}
                                        </Space>
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            )}
                          </>
                        ))}
                    </Space>
                  </Radio.Group>
                )}
              </Form.Item>
            )}
          </Col>
        </div>
      </Form>
    </CustomModal>
  );
};
