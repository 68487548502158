/* eslint-disable react-hooks/exhaustive-deps */
import { SORT_TYPES } from "features/Campaign/constants";
import { useGetBranchQuery } from "hooks/branch";
import { useGetListImportSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useGetStaff } from "hooks/warranty";
import { isNil, omit } from "lodash";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";

const useList = () => {
  const GET_QUERY = getQuery();
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();

  const [branchesID, setBranchesId] = useState("");
  const onChangeBranchesId = (e) => {
    setBranchesId(e);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      branchesID: e,
    };
    setParams(newParams);
  };

  const [params, setParams] = useState();

  const { data, paginationData, loading } = useGetListImportSerial(omit(params, ["activeKey", "key"]));

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: staffs, onLoadMoreData, hasMoreData, loading: loadingStaff } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      branchesID: branchesID,
    };
    setParams(newParams);
  };

  const handleChangeDate = (value) => {
    const { from, to } = value;

    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        timeRange: {
          from: from,
          to: to,
        },
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      ...params,
      filters: omit({ ...params?.filters }, [`${key}`]),
      pagination: { offset: 0, limit: 10 },
      sort: params.sort || [],
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
      },
      pagination: {
        ...params.pagination,
      },
      sort: sorter?.order ? [{ by: "ID", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };

    setParams(newParams);
  };

  const handlePrevNextPage = (offset) => {
    const newParams = {
      ...params,
      pagination: {
        offset: offset,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
      sort: [...params?.sort],
    };
    setParams(newParams);
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue.trim() },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setParams(() => {
      return {
        // branchesID: branchIdUser,
        filters: {
          query: "",
          createdByIDs: [],
          serialImportNoteType: [],
        },
        pagination: {
          offset: 0,
          limit: 10,
        },
        sort: [
          {
            by: "ID",
            sortType: "DESC",
          },
        ],
      };
    });
    buildQueryString({
      params: { activeKey: "listByImportSerial" },
    });
  }, []);

  useEffect(() => {
    setParams({
      branchesID: branchIdByUser,
      filters: {
        query: "",
        createdByIDs: GET_QUERY.createdByIDs || [],
        serialImportNoteType: GET_QUERY.serialImportNoteType || [],
      },
      pagination: {
        offset: GET_QUERY.offset || 0,
        limit: GET_QUERY.limit || 10,
      },
      sort: [
        {
          by: "ID",
          sortType: "DESC",
        },
      ],
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchesId(branchIdByUser);
  }, [branchIdByUser]);

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: "listByImportSerial",
      },
    });
  }, [params]);

  return {
    valueFormSelectCity,
    loading,
    params,
    paramsStaff,
    paginationData,
    data,
    onSearch,
    handleChangeTable,
    onChangeBranchesId,
    branchesID,
    staffs,
    handleRemoveFilterTag,
    handlePrevNextPage,
    handleFilter,
    handleChangeDate,
    propsFilterCreated: {
      staffs,
      onLoadMoreData,
      handleSearchStaffs,
      hasMoreData,
      loading: loadingStaff,
    },
  };
};

export default useList;
