import { Col, Row, Typography } from "antd";
import CustomInfoAddress from "components/CustomInfoAddress";
import { IMPORT_REQUEST_TYPE } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";

function InfoProvider({ sellerInfo, data }) {
  const order = data?.items && data?.items[0]?.order;
  return (
    <Row gutter={[16, 8]}>
      <Col span={24} className="form-info-provider">
        <Row gutter={[16, 8]}>
          {data?.type === IMPORT_REQUEST_TYPE.REFUND && (
            <>
              <Col span={6}>
                <Typography.Text className="sub-title">{t("accountant.orderCode")}</Typography.Text>
              </Col>
              <Col span={18}>
                <Link to={`/order/${order?.id}`} target="_blank">
                  <Typography.Link strong>{order?.number || "---"}</Typography.Link>
                </Link>
              </Col>
            </>
          )}
          <Col span={6}>
            <Typography.Text className="sub-title">{t("warehouse.salesReturn.codeSeller")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Link to={`/seller/detail/${sellerInfo?.id}`} target="_blank">
              <Typography.Link strong>{sellerInfo?.code || "---"}</Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t("warehouse.salesReturn.nameSeller")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.fullName || "---"}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{"SĐT"}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>{sellerInfo?.telephone || "---"}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <Typography.Text className="sub-title">{t("common.address")}</Typography.Text>
          </Col>
          <Col span={18}>
            <Typography.Text>
              <CustomInfoAddress value={sellerInfo}></CustomInfoAddress>
            </Typography.Text>
          </Col>
        </Row>
        {data?.type === IMPORT_REQUEST_TYPE.REFUND && (
          <Row gutter={[16, 8]}>
            <Col span={6}>
              <Typography.Text className="sub-title">{t("accountant.warehouse")}</Typography.Text>
            </Col>
            <Col span={18}>
              <Typography.Text>{order?.exportedWarehouse?.name || "---"}</Typography.Text>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default InfoProvider;
