import { Button } from "antd";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

export const ButtonImportSerial = ({ record }) => {
  const history = useHistory();
  return (
    <Button
      type="primary"
      onClick={() => history.push({ pathname: "/import-serial-purchase-order/create", state: record })}
    >
      {t("purchase.buttonGroup.comfirmImportSerial")}
    </Button>
  );
};
