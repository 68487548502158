import { Button, Form } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { useSplit } from "hooks/order";
import { t } from "i18next";
import { get, last, slice } from "lodash";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import { arrayUniqueByKey } from "utils/object";
import CardOrder from "./components/CardOrder";
import "./index.scss";

const calcTotalPriceItem = (quantity, price) => quantity * price;

const modifierItem = (arr, value) =>
  arr.map((item) => {
    if (item?.id === value.id) {
      return { ...value };
    }
    return { ...item };
  });

const filterItemQuantityIsZero = (arr) => arr?.filter((item) => item?.quantitySpilt !== 0);

const CustomModalSpiltOrder = ({ items, isHidden, infoOrder, labelButton }) => {
  const [form] = Form.useForm();
  const [enoughItems, setEnoughItems] = useState([]);
  const [notEnoughItems, setNotEnoughItems] = useState([]);
  const [openSplitOrderModal, setOpenSplitOrderModal] = useState(false);
  const [openConfirmSplit, setOpenConfirmSplit] = useState(false);
  const getFieldsValue = (field) => form.getFieldValue(field);
  const { handleSplit, loading } = useSplit();

  const getEnoughItems = items?.map((item) => {
    // if (item?.product?.productType === TYPE_PRODUCT.SERVICE) {
    //   return {
    //     ...item,
    //     inStock: item?.quantity,
    //     quantitySpilt: item?.quantity,
    //     total: calcTotalPriceItem(item?.quantity, item?.price),
    //   };
    // } else {
    if (!item?.inStock) {
      return { ...item, quantitySpilt: 0, total: calcTotalPriceItem(0, item?.price) };
    } else {
      if (item?.inStock >= item?.quantity) {
        return {
          ...item,
          quantitySpilt: item?.quantity,
          total: calcTotalPriceItem(item?.quantity, item?.price),
        };
      } else {
        return {
          ...item,
          quantitySpilt: item?.inStock,
          total: calcTotalPriceItem(item?.inStock, item?.price),
        };
      }
    }
    // }
  });

  const getNotEnoughItems = items?.map((item) => {
    // if (item?.product?.productType === TYPE_PRODUCT.SERVICE) {
    //   return {
    //     ...item,
    //     inStock: item?.quantity,
    //     quantitySpilt: 0,
    //     total: calcTotalPriceItem(0, item?.price),
    //   };
    // } else {
    if (!item?.inStock) {
      return {
        ...item,
        inStock: 0,
        quantitySpilt: item?.quantity,
        total: calcTotalPriceItem(item?.quantity, item?.price),
      };
    } else {
      if (item?.inStock >= item?.quantity) {
        return { ...item, quantitySpilt: 0, total: calcTotalPriceItem(0, item?.price) };
      } else {
        return {
          ...item,
          quantitySpilt: item?.quantity - item?.inStock,
          total: calcTotalPriceItem(item?.quantity - item?.inStock, item?.price),
        };
      }
    }
    // }
  });

  const initForm = {
    request: [
      {
        index: 0,
        title: `${t("order.splitOrder.orderEnough")} #${infoOrder.orderNumber}.1`,
        items: filterItemQuantityIsZero(enoughItems),
        discount: infoOrder?.discount,
        shippingFee: infoOrder?.serviceFee || 0,
        splitOrderType: "EnoughStock",
        isHidden: isHidden,
        code: `${infoOrder?.orderNumber}1`,
      },
      {
        index: 1,
        title: `${t("order.splitOrder.orderNotEnough")} #${infoOrder.orderNumber}.2`,
        items: filterItemQuantityIsZero(notEnoughItems),
        discount: 0,
        shippingFee: infoOrder?.serviceFee || 0,
        splitOrderType: "NotEnoughStock",
        isHidden: isHidden,
        code: `${infoOrder?.orderNumber}2`,
      },
    ],
  };

  const onFieldsChange = (changedFields, allFields) => {
    //get all value form
    const formValue = { ...form.getFieldsValue() };
    //get value change
    const valueChange = get(formValue, changedFields[0]?.name);
    //get path order if order enough return ['request', 0] else return ['request', 1]
    const indexOrderSpilt = slice(changedFields[0]?.name, 0, 2);
    //get path item in order split
    const indexItemOrderSpilt = slice(changedFields[0]?.name, 0, 4);
    //get item in order split
    const itemOrderSpiltChange = get(formValue, indexItemOrderSpilt);
    //check change items
    const isChangeItems = last(slice(changedFields[0]?.name, 0, 3)) === "items";

    if (isChangeItems) {
      //change quantitySpilt
      if (last(changedFields[0]?.name) === "quantitySpilt") {
        if (last(indexOrderSpilt) === 0) {
          const enoughItemsChange = modifierItem(enoughItems, {
            ...itemOrderSpiltChange,
            total: valueChange * itemOrderSpiltChange?.price,
          });

          const notEnoughItemsChange = modifierItem(notEnoughItems, {
            ...itemOrderSpiltChange,
            quantitySpilt: itemOrderSpiltChange?.quantity - valueChange,
            total: (itemOrderSpiltChange?.quantity - valueChange) * itemOrderSpiltChange?.price,
          });
          setEnoughItems(enoughItemsChange);
          setNotEnoughItems(notEnoughItemsChange);
        } else {
          const enoughItemsChange = modifierItem(enoughItems, {
            ...itemOrderSpiltChange,
            quantitySpilt: itemOrderSpiltChange?.quantity - valueChange,
            total: (itemOrderSpiltChange?.quantity - valueChange) * itemOrderSpiltChange?.price,
          });
          const notEnoughItemsChange = modifierItem(notEnoughItems, {
            ...itemOrderSpiltChange,
            total: valueChange * itemOrderSpiltChange?.price,
          });
          setEnoughItems(enoughItemsChange);
          setNotEnoughItems(notEnoughItemsChange);
        }
      }

      //change price
      if (last(changedFields[0]?.name) === "price") {
        if (last(indexOrderSpilt) === 0) {
          const enoughItemsChange = modifierItem(enoughItems, {
            ...itemOrderSpiltChange,
            price: valueChange,
            total: calcTotalPriceItem(valueChange, itemOrderSpiltChange?.quantitySpilt),
          });
          setEnoughItems(enoughItemsChange);
        } else {
          const notEnoughItemsChange = modifierItem(notEnoughItems, {
            ...itemOrderSpiltChange,
            price: valueChange,
            total: calcTotalPriceItem(valueChange, itemOrderSpiltChange?.quantitySpilt),
          });
          setNotEnoughItems(notEnoughItemsChange);
        }
      }

      //change customerPrice
      if (last(changedFields[0]?.name) === "customerPrice") {
        if (last(indexOrderSpilt) === 0) {
          const enoughItemsChange = modifierItem(enoughItems, {
            ...itemOrderSpiltChange,
            customerPrice: valueChange,
            customerTotal: calcTotalPriceItem(valueChange, itemOrderSpiltChange?.quantitySpilt),
          });
          setEnoughItems(enoughItemsChange);
        } else {
          const notEnoughItemsChange = modifierItem(notEnoughItems, {
            ...itemOrderSpiltChange,
            customerPrice: valueChange,
            customerTotal: calcTotalPriceItem(valueChange, itemOrderSpiltChange?.quantitySpilt),
          });
          setNotEnoughItems(notEnoughItemsChange);
        }
      }
    }

    //change feeShipping
    if (last(changedFields[0]?.name) === "shippingFee") {
      if (last(indexOrderSpilt) === 0) {
        form.setFieldsValue({
          ...formValue,
          request: arrayUniqueByKey([...formValue.request, formValue.request[0]], "splitOrderType"),
        });
      } else {
        form.setFieldsValue({
          ...formValue,
          request: arrayUniqueByKey([...formValue.request, formValue.request[1]], "splitOrderType"),
        });
      }
    }

    //change discount
    if (last(changedFields[0]?.name) === "discount") {
      if (last(indexOrderSpilt) === 0) {
        form.setFieldsValue({
          ...formValue,
          request: arrayUniqueByKey([...formValue.request, formValue.request[0]], "splitOrderType"),
        });
      } else {
        form.setFieldsValue({
          ...formValue,
          request: arrayUniqueByKey([...formValue.request, formValue.request[1]], "splitOrderType"),
        });
      }
    }
  };

  const handleSubmitOrderSplit = async () => {
    const formSplit = { ...form.getFieldsValue() };

    const request = formSplit.request.map((order) => {
      const { items, discount, shippingFee, code } = order;

      const itemsOrder = items?.map((item) => ({
        productID: item?.productID,
        productCode: item?.productCode,
        productType: item?.product?.productType,
        quantity: item?.quantitySpilt,
        price: item?.price,
        customerPrice: item?.customerPrice,
      }));
      return {
        items: [...itemsOrder],
        discount,
        shippingFee,
        code,
      };
    });

    const dataSubmit = { orderID: infoOrder?.id, request };

    try {
      await handleSplit({ ...dataSubmit });
      notify.success({ message: t("order.splitOrder.successSplitOrder") });
      setOpenConfirmSplit(false);
      setOpenSplitOrderModal(false);
    } catch (err) {
      notify.error({ message: err?.message });
    }
  };

  const handleOpenConfirmSplit = async () => {
    await form.validateFields().then(async () => {
      setOpenConfirmSplit(true);
    });
  };

  useEffect(() => {
    setNotEnoughItems(getNotEnoughItems);
    setEnoughItems(getEnoughItems);
  }, [JSON.stringify(items)]);

  useEffect(() => {
    form.setFieldsValue({ ...initForm });
  }, [JSON.stringify(enoughItems), JSON.stringify(notEnoughItems)]);

  return (
    <CustomModal
      footer={false}
      isBlockCloseOnOke
      switchClose={openSplitOrderModal}
      customComponent={
        <Button type="primary" className="btn-main-action">
          {t("order.orderDetail.spiltBtn")}
        </Button>
      }
      title={
        <b style={{ fontSize: "16px", textTransform: "uppercase" }}>
          {t("order.splitOrder.splitOrderTitle")} #{infoOrder.orderNumber}
        </b>
      }
      width={1800}
      bodyStyle={{ backgroundColor: "#F7F8FB" }}
      buttonLoading={loading}
      hideConfirmButton={true}
      onCancel={() => {
        setOpenSplitOrderModal(false);
      }}
      addMoreButton={
        <CustomModal
          center={true}
          footer={false}
          isBlockCloseOnOke
          customComponent={<Button type="primary">{labelButton}</Button>}
          switchClose={openConfirmSplit}
          onOke={handleSubmitOrderSplit}
          buttonLoading={loading}
          onCancel={() => {
            setOpenConfirmSplit(false);
          }}
          onOpen={handleOpenConfirmSplit}
          visible={openConfirmSplit}
        >
          <div className="modal-split-confirm">
            <SvgIcon.WarningFilledIcon className="modal-warning-icon" />
            <b>{t("order.splitOrder.confirmSplitOrder")}</b>
            <i>{t("order.splitOrder.alertSplitOrder")}</i>
          </div>
        </CustomModal>
      }
    >
      <Form form={form} onFieldsChange={onFieldsChange}>
        <Form.List name="request">
          {(request) => (
            <>
              {request.map((item, index) => (
                <CardOrder
                  key={index}
                  getFieldsValue={getFieldsValue}
                  item={item}
                  orderSpilt={initForm?.request[index]}
                  infoOrder={infoOrder}
                  isHidden={isHidden}
                />
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </CustomModal>
  );
};

export default CustomModalSpiltOrder;
