import { Button, Popover, Space } from "antd";
import { SvgIcon } from "assets/icons";
import { EXPORT_CART_TYPE, MAXIMUM_CART } from "features/Cart/constants";
import { useCloneCart, useDeleteCart, useExportCart, useGetAllCarts } from "hooks/cart";
import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { buildQueryString, getQuery, notify } from "utils/helperFuncs";
import { isEmpty, findIndex, isNil } from "lodash";

const useTabsCart = ({ onChangeTab, hasCart, carts, loadingGetAllCarts }) => {
  const { handleDeleteCart, loading } = useDeleteCart();
  const { handleCloneCart } = useCloneCart();
  const { handleExportCart, loading: loadingExportCart } = useExportCart();
  const [isOpenRemoveCart, setIsOpenRemoveCart] = useState(false);
  const [isOpenCloneCart, setIsOpenCloneCart] = useState(false);
  const [targetKey, setTargetKey] = useState("");
  const [activeKey, setActiveKey] = useState();

  const onChange = (activeKey) => {
    onChangeTab && onChangeTab(activeKey);
    buildQueryString({ params: { id: activeKey } });
    setActiveKey(activeKey);
  };

  const onCloneCart = async () => {
    if (carts?.length === MAXIMUM_CART) {
      setIsOpenCloneCart(true);
    } else {
      try {
        await handleCloneCart(activeKey).then((res) => onChange(res.data.cart.clone.id));
        notify.success({ message: t("cart.cloneCartSuccess") });
      } catch (error) {
        notify.error({ message: t("common.error") });
      }
    }
  };

  const onExportFile = async (type) => {
    await handleExportCart({
      cartID: activeKey,
      fileType: type,
    })
      .then((response) => {
        const fileUrl = response.data.cart.exportFile.url;
        window.open(fileUrl, "_blank").focus();
      })
      .catch((error) => {
        notify.error({ message: t("common.error") });
      });
  };

  const isEmptyCart = isEmpty(carts);

  const tabBarExtraContent = {
    right: [
      <Button
        type="text"
        className="button"
        onClick={onCloneCart}
        style={{ display: `${isEmptyCart ? "none" : "block"}` }}
      >
        <SvgIcon.CopyCartIcon />
        <span>{t("cart.copy")}</span>
      </Button>,
      <Popover
        content={
          <Space direction="vertical">
            <Button type="text" onClick={() => onExportFile(EXPORT_CART_TYPE.JPEG)}>
              {t("common.image")}
            </Button>
            <Button type="text" onClick={() => onExportFile(EXPORT_CART_TYPE.XLSX)}>
              {t("common.excel")}
            </Button>
          </Space>
        }
      >
        <Button type="text" className="button" style={{ display: `${isEmptyCart ? "none" : "block"}` }}>
          <SvgIcon.ExportFileCartIcon />
          <span>{t("cart.export")}</span>
        </Button>
      </Popover>,
      <Button type="text" className="button">
        <SvgIcon.HelpCircleIcon />
        <span>{t("cart.help")}</span>
      </Button>,
    ],
  };

  const onEdit = (targetKey, action) => {
    if (action === "remove") {
      setTargetKey(targetKey);
      setIsOpenRemoveCart(true);
    }
  };

  const onCancelRemoveCart = () => {
    setIsOpenRemoveCart(false);
    setTargetKey("");
  };

  const onCancelCloneCart = () => {
    setIsOpenCloneCart(false);
    setTargetKey("");
  };

  const onSubmit = async () => {
    try {
      await handleDeleteCart(targetKey);
      notify.success({ message: t("cart.removeCartSuccess") });
      const indexActiveKey = findIndex(carts, function (item) {
        return item.key === activeKey;
      });
      if (activeKey === targetKey) {
        if (carts.length >= 2) {
          if (indexActiveKey === 0) {
            onChange(carts[1].key);
          } else {
            onChange(carts[indexActiveKey - 1].key);
          }
        }
      }
      setTargetKey("");
      setIsOpenRemoveCart(false);
    } catch (error) {
      notify.error({ message: t("common.error") });
      setTargetKey("");
      setIsOpenRemoveCart(false);
    }
  };

  useEffect(() => {
    const GET_QUERY = getQuery();
    const { id } = GET_QUERY;
    if (isNil(id)) {
      const getActiveKey = isEmpty(carts) ? null : carts[0].key;
      onChange(getActiveKey);
      buildQueryString({ params: { id: getActiveKey } });
    } else {
      onChange(id);
      buildQueryString({ params: { id } });
    }
  }, [loadingGetAllCarts]);

  useEffect(() => {
    hasCart && hasCart(isEmptyCart);
  }, [isEmptyCart]);

  return {
    tabs: carts,
    onChange,
    tabBarExtraContent,
    onEdit,
    isOpenRemoveCart,
    isOpenCloneCart,
    onCancelRemoveCart,
    onCancelCloneCart,
    onSubmit,
    loading,
    activeKey,
    isEmptyCart,
    loadingExportCart,
  };
};

export default useTabsCart;
