import { ACTIVE_WALLET } from "pages/App/Accountant/AccountantList/components/SellerList/constants";
import { formatCurrency, formatDatetime } from "utils/helperFuncs";
import { formatDateTime } from "utils/dateTime";

export const convertCreateCartSeller = (data) =>
  data?.map((record) => ({
    id: record?.id,
    value: record?.id,
    sellername: record?.fullName,
    label: record?.fullName,
    warehouses: record?.warehouses,
    address: record?.address,
    telephone: record?.telephone,
    email: record?.email,
    code: record?.code,
    bankAccounts: record?.bankAccounts,
    city: {
      name: record?.city?.name,
    },
    district: {
      name: record?.district?.name,
    },
    ward: {
      name: record?.ward?.name,
    },
  }));

export const convertActiveWallet = (activeWallets) => {
  switch (activeWallets) {
    case ACTIVE_WALLET.SELLER_COMPANY:
    case ACTIVE_WALLET.SELLER_PERSONAL: {
      return "accountant.activeWallet.sellerVirtualWallet";
    }
    case ACTIVE_WALLET.SELLER_DEBT: {
      return "accountant.activeWallet.sellerPayByDebt";
    }
    case ACTIVE_WALLET.WAREHOUSE_ACCOUNTANT: {
      return "accountant.activeWallet.sellerWarehouseWallet";
    }
    case ACTIVE_WALLET.BANK_ACCOUNT: {
      return "accountant.activeWallet.sellerBankAccount";
    }
    default:
      return;
  }
};

export const convertGetOrders = (data) =>
  data?.map((item) => ({
    id: item?.id,
    sellerIDs: item?.sellerIDs,
    createdAt: formatDateTime(item?.createdAt),
    number: item?.number,
    createdBy: item?.createdBy,
    total: formatCurrency(item?.total),
    paymentMethod: item?.paymentMethod,
    status: item?.status,
  }));

export const convertGetCategoriesSeller = (data) =>
  data?.map((item) => ({
    id: item?.categoryID,
    category: item?.category,
    level: item?.level,
    checked: item?.checked,
  }));
export const convertDataSeller = (data) =>
  data?.map((item) => ({
    id: item?.id,
    code: item?.code,
    idNumber: item?.idNumber,
    shortName: item?.shortName,
    fullName: item?.fullName,
    sellerLevel: item?.sellerLevel,
    debtLimit: item?.debtLimit,
    debtAge: item?.debtAge,
    isActive: item?.isActive,
    address: item?.address,
    paymentMethod: item?.paymentMethod,
    sellerGroupID: item?.sellerGroupID,
  }));

export const convertSellerCustomer = (data) =>
  data?.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    description: item?.description,
    sellerQuantity: item?.sellerQuantity,
    isActive: item?.isActive,
    manager: item?.manager,
    managerID: item?.managerID,
  }));

export const convertActivityLogs = (data) =>
  data?.map((item) => ({
    updatedAt: formatDatetime(item?.updatedAt),
    status: item?.status,
    action: item?.action,
    detail: item?.detail,
    updatedByID: item?.updatedByID,
    updatedBy: item?.updatedBy,
  }));
