import { Form } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import "./index.scss";

const CustomModalExportVat = ({
  visibleExportVat,
  handleSubmitEditProduct,
  loading,
  productCode,
  handleOpenExportVat,
  handleCancelExportVat,
}) => {
  return (
    <CustomModal
      className="modal-question-export-instock-vat"
      closable={false}
      centered={true}
      footer={false}
      onOke={handleSubmitEditProduct}
      visible={visibleExportVat}
      onCancel={handleCancelExportVat}
      onOpen={handleOpenExportVat}
      isBlockCloseOnOke
      buttonLoading={loading}
    >
      <Form layout="vertical" className="form-question-export-instock-vat">
        <SvgIcon.IconInfoQuestion />
        <div className="title">{t("product.productForm.confirm_export_vat")}</div>
        <div className="title-info">
          <span>{t("product.productForm.productCode")}</span>
          <span style={{ color: "#2246dc", fontWeight: "600" }}> {productCode}</span>
          <span> {t("product.productForm.yes")}</span>
          <span style={{ color: "#2246dc", fontWeight: "600" }}> {t("product.productForm.in_stock_vat_0")}</span>
        </div>
        <div className="title-info">{t("product.productForm.confirm_export_vat_question")}</div>
      </Form>
    </CustomModal>
  );
};

export default CustomModalExportVat;
