import { useGetOrderLoadMore } from "hooks/order";

function useSelectOrder({ handleGetOrder, setParamsOrderSeller, paramsOrderSeller, setValueSelectOrder }) {
  const { orders, hasMoreData, onLoadMoreData, loading } = useGetOrderLoadMore(paramsOrderSeller, "cache-first");
  const onSearch = (value) =>
    setParamsOrderSeller({
      filters: { ...paramsOrderSeller?.filters, query: value || "" },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    setValueSelectOrder(value);
    handleGetOrder && handleGetOrder({ ...option?.orders });
  };
  return {
    onLoadMoreData,
    hasMoreData,
    loading,
    onSearch,
    handleChange,
    data: orders,
  };
}

export default useSelectOrder;
