import { Col, Descriptions, Image, Row, Skeleton, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import CustomDescriptions from "components/CustomDescriptions";

import "./index.scss";

const InfoMarketing = ({ data }) => {
  const divRef = useRef(null);

  const [heightDescription, setHeightDescription] = useState(0);

  useEffect(() => {
    const divElement = divRef?.current;
    if (divElement && data?.description) {
      const contentHeight = divElement?.scrollHeight;
      setHeightDescription(contentHeight || 0);
    }
  }, [JSON.stringify(data)]);

  return (
    <Row className="info-marketing" gutter={[52, 16]}>
      <Col xl={12} span={24}>
        <CustomDescriptions
          colon={false}
          labelWidth="20%"
          content={
            <>
              <Descriptions.Item label="Link">
                <Typography.Link copyable href={data?.link} target="_blank">
                  {data?.link || "--"}
                </Typography.Link>
              </Descriptions.Item>
              <Descriptions.Item label="Highlight">
                <Typography.Text>{data?.highlight || "--"}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label={t("campaign.form.campaignDescription")}>
                <div className="box-gen-html">
                  <div
                    ref={divRef}
                    dangerouslySetInnerHTML={{ __html: data?.description || "--" }}
                    className="gen-html"
                  ></div>
                  {heightDescription > 405 && (
                    <div className="show-more">
                      <CustomModal
                        {...{
                          width: 1000,
                          customComponent: <span>{t("common.seemore")}</span>,
                          hideConfirmButton: true,
                          hideCancelButton: true,
                          title: (
                            <Typography.Title level={3} strong>
                              {t("campaign.form.campaignDescription")}
                            </Typography.Title>
                          ),
                          centered: true,
                          footer: false,
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: data?.description || "--" }}
                          className="gen-html-modal"
                          style={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll" }}
                        ></div>
                      </CustomModal>
                    </div>
                  )}
                </div>
              </Descriptions.Item>
            </>
          }
        />
      </Col>
      <Col xl={12} span={24}>
        <Row gutter={[16, 8]} className="custom-row">
          <Col span={24}>
            <Typography.Text strong>Banner Desktop (1800 * 540 px)</Typography.Text>
          </Col>
          <Col span={24}>
            {data?.banner?.imageURL === undefined ? (
              <Skeleton.Image width={"100%"} height={200} />
            ) : (
              <Image
                width={"100%"}
                src={data?.banner?.imageURL}
                style={{
                  borderRadius: "12px",
                  objectFit: "cover",
                  aspectRatio: "3 / 1",
                  maxHeight: "200px",
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={[16, 8]} className="custom-row">
          <Col span={24}>
            <Typography.Text strong>Banner Mobile (540 * 180 px)</Typography.Text>
          </Col>
          <Col span={24}>
            <Image
              width={"100%"}
              src={data?.banner?.mobileURL}
              style={{
                borderRadius: "12px",
                objectFit: "cover",
                aspectRatio: "3 / 1",
                maxHeight: "200px",
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InfoMarketing;
