import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import UploadFiles from "components/UploadFiles";
import { t } from "i18next";
import { useState } from "react";
import { formatCurrency, getFile, notify } from "utils/helperFuncs";
import "./index.scss";

import { PAYMENT_METHOD } from "config/constants";
import { useConfirmD3P } from "hooks/order";
import { useUploadDocument } from "hooks/upload-document";

export const ButtonAssignToDeliver = ({ orderDetail }) => {
  const [form] = Form.useForm();
  const { handleUpload } = useUploadDocument();

  const [visible, setVisible] = useState(false);
  const { handleConfirmD3P, loading } = useConfirmD3P();

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onSubmit = async () => {
    await form.validateFields().then(async () => {
      const formValues = form.getFieldsValue();
      const { imageURLs } = formValues;
      const originFiles = imageURLs?.map((item) => item.originFileObj);
      await handleUpload({ files: originFiles }).then(async (res) => {
        const imageURLs = res?.map((item) => item?.url);

        await handleConfirmD3P({
          orderIDs: [orderDetail?.id],
          imageURLs,
        })
          .then(() => {
            setVisible(false);
            form.resetFields();
            notify.success({
              message: t("order.orderList.confirm3PDeliverySuccess"),
            });
          })
          .catch((error) => {
            notify.error({
              message: t("order.orderList.confirm3PDeliveryError"),
              description: error.message,
            });
          });
      });
    });
  };

  return (
    <CustomModal
      footer={false}
      centered={true}
      onOke={onSubmit}
      width="35%"
      customComponent={
        <Button type="primary" loading={loading}>
          <Typography.Text strong style={{ color: "#fff" }}>
            {t("order.orderDetail.unitDelivery")}
          </Typography.Text>
        </Button>
      }
      buttonLoading={loading}
      onOpen={handleOpen}
      onCancel={handleClose}
      visible={visible}
    >
      <Form
        form={form}
        initialValues={{ imageURLs: [], checkbox: !(orderDetail?.paymentMethod === PAYMENT_METHOD.COD) }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <ExclamationCircleFilled style={{ fontSize: "48px", color: "#ff7f00" }} />
          </Col>
          <div className="render-content-modal-cash-approval-payment">
            <div style={{ textAlign: "center", fontSize: "16px" }}>
              <Typography.Text strong>{t("order.deliverySuccess.confirmDelivery")}</Typography.Text>
            </div>
            <div>
              <Typography.Text>{t("order.orderDetail.please-check-money")}</Typography.Text>
            </div>
            <div className="list-checkbox">
              <Form.Item
                name="checkbox"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error(t("order.orderDetail.reject-checkbox"))),
                  },
                ]}
              >
                <Checkbox disabled={!(orderDetail?.paymentMethod === PAYMENT_METHOD.COD)}>
                  <Typography.Text>
                    {t("order.orderDetail.order")}
                    <Typography.Link strong>&nbsp;{orderDetail?.number}&nbsp;</Typography.Link>
                    {t("order.orderDetail.value")}
                    <Typography.Link strong>&nbsp;{formatCurrency(orderDetail?.total)}&nbsp;</Typography.Link>
                    {t("order.orderDetail.seller-by")}
                    <Typography.Link strong>&nbsp;{orderDetail?.vatCompanyName}&nbsp;</Typography.Link>
                    {t("order.orderDetail.success-payment")}
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            </div>
            <div>
              <Typography.Text>{t("order.orderDetail.upload-file-delivery")}</Typography.Text>
            </div>
            <div>
              <Form.Item
                labelCol={{ span: 24, offset: 0 }}
                name={"imageURLs"}
                getValueFromEvent={getFile}
                valuePropName={"fileList"}
                extra={<Typography.Text italic>{t("common.noteAttack")}</Typography.Text>}
              >
                <UploadFiles />
              </Form.Item>
            </div>
          </div>
        </Row>
      </Form>
    </CustomModal>
  );
};
