import React from "react";
import Spinner from "components/Spinner";
import { useSellerPermissions } from "hooks/seller";
import { useGetUserPermissions } from "hooks/user/user";
import Page403 from "pages/PageError/403";
import { CreateSellerForm } from "../Create/components/CreateSellerForm";
import { useSupplierPermissions } from "hooks/supplier";

function SellerApprove() {
  const { canSellerAccountApproval } = useSellerPermissions();
  const { loading: loadUserPermissions } = useGetUserPermissions();
  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canSellerAccountApproval ? (
    <CreateSellerForm isApproveSeller={true} />
  ) : (
    <Page403 />
  );
}

export default SellerApprove;
