import { Space, Typography } from "antd";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import CustomUom from "components/CustomUom";
import CustomTitle from "features/Serial/components/CustomTitle";
import { WARRANTY_ON_SALE_UNIT } from "features/Serial/constant";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import ModalSelectSerial from "../ModalSelectSerial";
import styles from "./index.module.scss";

function TableOrder({ handleGetTableOrder, renderLack, form }) {
  const columnsByOrders = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
            <ProductLink product={record} />
          </Typography.Link>
          <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
            {record?.product?.name || record?.productName}
          </Typography.Text>
          <CustomUom uom={record?.product?.uom} />
        </div>
      ),
      isHidden: false,
      width: 250,
      fixed: "left",
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SALEWARRANTY")} />,
      render: (_, record, index) => {
        return (
          <div className={styles["custom-select"]}>
            <Typography.Text>
              {record?.productWarranty} {convertUnit(record?.warrantyDateUnit || WARRANTY_ON_SALE_UNIT.MONTH)}
            </Typography.Text>
          </div>
        );
      },
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.ACTUALINVENTORY")} subTitle="(HN)" />,
      render: (_, record, index) => <Typography.Text>{record?.inStock || 0}</Typography.Text>,
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.BYORDER")} />,
      render: (_, record, index) => <Typography.Text>{record?.quantity}</Typography.Text>,
      width: 120,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.SCANNED")} />,
      width: 120,
      render: (_, record) => {
        const scanned =
          record?.quantitySerialScanned >= 0 ? record?.quantitySerialScanned : record?.serialItems?.length;
        return (
          <Space direction="vertical">
            <Typography.Text>{scanned}</Typography.Text>
            {
              <Typography.Text className={styles["sub-title"]}>
                {renderLack({
                  quantity: record?.quantity,
                  scanned: scanned,
                }) < 0 ? (
                  <span style={{ color: "#D2434D" }}>
                    {t("serialIE.productInfo.residual")} :{" "}
                    {Math.abs(
                      renderLack({
                        quantity: record?.quantity,
                        scanned: scanned,
                      })
                    )}
                  </span>
                ) : renderLack({
                    quantity: record?.quantity,
                    scanned: scanned,
                  }) > 0 ? (
                  <span style={{ color: "#EF9351" }}>
                    {t("serialIE.productInfo.lack")} :{" "}
                    {renderLack({
                      quantity: record?.quantity,
                      scanned: scanned,
                    })}
                  </span>
                ) : (
                  <span style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</span>
                )}
              </Typography.Text>
            }
          </Space>
        );
      },
    },
    {
      align: "center",
      title: (
        <CustomTitle
          title={t("serialIE.productInfo.detailSerialB")}
          subTitle={
            <Typography.Text className={styles["sub-title"]} style={{ color: "#EF9351", fontSize: "12px" }} italic>
              {t("serialIE.productInfo.note")}
            </Typography.Text>
          }
        />
      ),
      render: (_, record, index) => {
        return (
          <ModalSelectSerial form={form} record={record} index={index} handleGetTableOrder={handleGetTableOrder} />
        );
      },
    },
  ];
  return { columnsByOrders };
}

export default TableOrder;
