import { parseIsoDateStringToMoment } from "utils/helperFuncs";

export const convertGetAllCarts = (data) =>
  data?.map((record) => ({
    tab: `Giỏ hàng #${record.name}`,
    closable: true,
    key: `${record.id}`,
  }));

const convertAddress = (address, ward, district, city) => {
  return [address, ward, district, city].filter((item) => !!item).join(", ");
};

const convertShippingConfig = (data) => {
  const dataShippingConfig = {
    busConfig: {
      busName: data?.busConfig?.busName ? data?.busConfig?.busName : "",
      telephone: data?.busConfig?.telephone ? data?.busConfig?.telephone : "",
      location: data?.busConfig?.location ? data?.busConfig?.location : "",
      busStation: data?.busConfig?.busStation ? data?.busConfig?.busStation : "",
      fee: 0,
    },
    deliver3PartyConfig: {
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      fee: 0,
      name: "",
      serviceCode: "",
      customFeeEnabled: false,
      customFee: 0,
      providerID: data?.deliver3PartyConfig?.providerID ? data?.deliver3PartyConfig?.providerID : null,
    },
    urbanCODConfig: {
      fee: data?.urbanConfig?.fee ? data?.urbanConfig?.fee : 0,
    },
  };

  return {
    ...dataShippingConfig,
  };
};

export const convertGetCart = (data) => ({
  sellerID: data?.sellerID,
  seller: {
    id: data?.seller?.id,
    fullName: data?.seller?.fullName,
    telephone: data?.seller?.telephone,
    code: data?.seller?.code,
    address: convertAddress(
      data?.seller?.address,
      data?.seller?.ward?.name,
      data?.seller?.district?.name,
      data?.seller?.city?.name
    ),
    debtLimit: data?.seller?.debtLimit,
    currentAvailable: data?.seller?.currentAvailable,
    availabilityLimit: data?.seller?.availabilityLimit,
    isActive: data?.seller?.isActive,
    currentDebt: data?.seller?.currentDebt,
  },
  exportedWarehouseID: data?.exportedWarehouse?.id,
  deliveryDeadline: data?.deliveryDeadline ? parseIsoDateStringToMoment(data?.deliveryDeadline) : null,
  promisedDeliverDate: data?.deliveryDeadline ? parseIsoDateStringToMoment(data?.deliveryDeadline) : null,
  listProducts: data?.items,
  totalProduct: data?.totalProduct,
  temporaryTotal: data?.subTotal,
  sender: {
    id: `${data?.senderName}${data?.senderPhone}`,
    fullName: data?.senderName,
    telephone: data?.senderPhone,
    address: data?.senderAddress,
  },
  shippingContact: {
    id: data?.shippingContact?.id,
    fullName: data?.shippingContact?.fullName,
    telephone: data?.shippingContact?.telephone,
    address: convertAddress(
      data?.shippingContact?.address,
      data?.shippingContact?.ward?.name,
      data?.shippingContact?.district?.name,
      data?.shippingContact?.city?.name
    ),
    wardID: data?.shippingContact?.wardID,
    districtID: data?.shippingContact?.districtID,
    cityID: data?.shippingContact?.cityID,
  },
  note: data?.note,
  internalNote: data?.internalNote,
  shippingType: data?.shippingType,
  shippingConfig: convertShippingConfig(data?.shippingConfiguration),
  orderPriority: data?.orderPriority,
  fullStockRequired: data?.fullStockRequired,
  paymentMethod: data?.paymentMethod,
  vat: data?.vat,
  vatAllOrder: data?.vatAllOrder,
  vatTaxIDNumber: data?.vatTaxIDNumber,
  vatCompanyName: data?.vatCompanyName,
  vatEmail: data?.vatEmail,
  vatAddress: data?.vatAddress,
  shippingFee: data?.shippingFee,
  serviceFee: data?.serviceFee,
  temporaryServiceFee: data?.temporaryServiceFee,
  total: data?.total,
  discountAmount: data?.discountAmount,
  paymentByCash: data?.cashPayment,
  paymentByDebit: data?.debtPayment,
  sendingAddress: {
    wardID: data?.sendingWard?.id,
    districtID: data?.sendingWard?.district?.id,
    cityID: data?.sendingWard?.district?.cityID,
  },
  allSenders: convertSender(data?.allSenders),
  customerSubTotal: data?.customerSubTotal,
  customerTotal: data?.customerTotal,
  orderType: data?.orderType,
  assemblyRequired: data?.assemblyRequired,
  customerPaymentMethod: data?.customerPaymentMethod,
  totalServiceProduct: data?.totalServiceProduct,
  quantityServiceProduct: data?.quantityServiceProduct,
});

export const convertSender = (data) =>
  data?.map((item) => ({
    id: `${item?.fullName}${item?.telephone}`,
    fullName: item?.fullName,
    address: convertAddress(item?.address, item?.ward?.name, item?.district?.name, item?.city?.name),
    telephone: item?.telephone,
    isDefault: item?.isDefault,
  }));

// export const convertGetShippingProvider = (data) =>
//   data
//     ?.map((item) =>
//       item.getServiceFee.length
//         ? item.getServiceFee.map((service) => ({
//             id: `${service.code}${item.id}`,
//             providerID: item.id,
//             name: item.name,
//             fee: service.totalFee,
//             serviceCode: service.code,
//             serviceName: service.name,
//           }))
//         : {
//             id: item.id,
//             name: item.name,
//           }
//     )
//     .flat();

export const convertShippingProvider = (data) => {
  return data?.map((item) => ({
    carrierMaxFee: item?.carrierMaxFee,
    carrierMinFee: item?.carrierMinFee,
    method: item?.method,
    providers: item?.providers?.map((i) => ({
      code: i?.code,
      fee: i?.fee,
      id: `${i?.id} - ${item?.method}`,
      logo_url: i?.logo_url,
      name: i?.name,
    })),
  }));
};
