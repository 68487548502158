import React from "react";
import { Button, Col, Form, Radio, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import CustomTable from "components/CustomTable";
import usePopupExportFile from "./usePopupExportFile";

function PopupExportFile({ title, handleExportFile, isPrint, loading }) {
  const {
    filterItemsTypeOptions,
    form,
    handleChange,
    handleOk,
    showModal,
    handleCancel,
    isModalOpen,
    selectedProduct,
    rowSelection,
    dataPopup,
    columns,
  } = usePopupExportFile({
    handleExportFile,
    isPrint,
  });

  return (
    <CustomModal
      {...{
        title: <Typography.Title level={3}>{t("serial.selectSerialExport")}</Typography.Title>,
        width: "fit-content",
        footer: false,
        customComponent: title,
        styleButton: "notButton",
        hideConfirmButton: true,
        destroyOnClose: true,
        styleBtnGrp: { marginTop: "20px" },
        addMoreButton: (
          <Button type="primary" onClick={handleOk} loading={loading}>
            {isPrint ? t("serial.printReturn") : t("serial.exportReturn")}
          </Button>
        ),
        visible: isModalOpen,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Space direction="vertical">
              <Radio.Group
                options={filterItemsTypeOptions}
                defaultValue={false}
                onChange={handleChange}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                optionType="button"
              />
              <CustomTable
                columns={columns}
                dataSource={selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows : dataPopup?.items}
                rowSelection={{
                  ...rowSelection,
                }}
                rowKey={(record) => record?.id}
                loading={loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default PopupExportFile;
