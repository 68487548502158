import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { GET_CHANGE_ACTIVE_STATUS } from "graphql/campaign/mutation";
import {
  CHECK_DUPLICATE_CODES,
  GET_DETAIL_PROMOTION_CAMPAIGN,
  GET_LIST_CAMPAIGN_QUERY,
  GET_ORDER_IN_PROMO_TABLE,
  GET_SELLER_NAME_QUERY,
  GET_SELLER_RANKING_LIST,
  GET_SUMMARY_TABS,
} from "graphql/campaign/query";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";
import { convertDataOrderInPromo, convertGetListCampaign, convertGetSellerName } from "./converter";

export const useGetListCampaign = (params) => {
  const { loading, data, error, refetch } = useQuery(GET_LIST_CAMPAIGN_QUERY, {
    variables: params,
  });
  return {
    refetch,
    loading,
    error,
    data: convertGetListCampaign(data?.promotionCampaign?.pagination?.promotionCampaigns) || [],
    paginationData: data?.promotionCampaign?.pagination?.paginationData,
  };
};

export const useGetSummaryTabs = () => {
  const { data } = useQuery(GET_SUMMARY_TABS);
  return {
    data,
  };
};

export const useGetChangeActiveStatus = () => {
  const [updateStatus, { loading, error }] = useMutation(GET_CHANGE_ACTIVE_STATUS);
  const handleUpdateStatus = async (params) => {
    return await updateStatus({
      variables: params,
    });
  };

  return {
    handleUpdateStatus,
    loading,
    error,
  };
};

export const useGetSellerRankingList = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_SELLER_RANKING_LIST, {
    variables: params,
    skip: params === undefined,
  });

  return {
    loading,
    error,
    refetch,
    data: data?.promotionCampaign?.sellerRankingList,
    paginationData: data?.promotionCampaign?.sellerRankingList?.paginationData,
  };
};

export const useGetOrderInPromo = (params) => {
  const { data, loading, error, refetch } = useQuery(GET_ORDER_IN_PROMO_TABLE, {
    variables: params,
  });

  return {
    loading,
    error,
    refetch,
    data: convertDataOrderInPromo(data?.promotionCampaign?.orderRankingList?.ordersRanking) || [],
    paginationData: data?.promotionCampaign?.orderRankingList?.paginationData,
  };
};

export const useGetSellerName = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SELLER_NAME_QUERY, {
    variables: params,
    fetchPolicy: "cache-first",
  });
  return {
    loading,
    data: convertGetSellerName(data?.seller?.pagination?.sellers) || [],
    paginationData: data?.seller?.pagination?.paginationData,
    refetch,
    fetchMore,
  };
};

export const useGetDetailPromotionCampaign = (params) => {
  const { data: initialData, loading: initialLoading } = useQuery(GET_DETAIL_PROMOTION_CAMPAIGN(false), {
    variables: params,
  });

  const campaignType = initialData?.promotionCampaign?.get?.campaignType;

  const { loading, data, refetch } = useQuery(GET_DETAIL_PROMOTION_CAMPAIGN(campaignType === "PRODUCT_PROMO"), {
    variables: params,
    skip: !campaignType,
  });

  return {
    loading: loading || initialLoading,
    data: data?.promotionCampaign?.get || [],
    refetch,
  };
};

export const useCheckDuplicateCodes = () => {
  const [pagination, { loading, data }] = useLazyQuery(CHECK_DUPLICATE_CODES);
  const handleCheckDuplicateCodes = async (params) => {
    return pagination({
      variables: params,
    });
  };
  return {
    loading,
    handleCheckDuplicateCodes,
    data: data?.promotionCampaign?.getConditionPromotionProducts,
  };
};

export const useCampaignPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    canViewListCampaign: hasPermission(permissions, [USER_PERMISSIONS.PROMOTION_VIEW]),
    canViewDetailCampaign: hasPermission(permissions, [USER_PERMISSIONS.PROMOTION_DETAIL_VIEW]),
    canCreateCampaign: hasPermission(permissions, [USER_PERMISSIONS.PROMOTION_CREATE]),
    canUpdateCampaign: hasPermission(permissions, [USER_PERMISSIONS.PROMOTION_UPDATE]),
    loading,
  };
};
