import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CLONE_CAMPAIGN, CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from "graphql/campaign/mutation";
import { GET_PROD_CATEGORIES } from "graphql/category/query";
import { converterCategoriesForForm } from "hooks/category/converter";
import { useUpload } from "hooks/upload";
import { t } from "i18next";
import { notify } from "utils/helperFuncs";

export const useGetCategoriesOptions = (level) => {
  const { loading, data } = useQuery(GET_PROD_CATEGORIES, {
    variables: { req: { levels: [1] } },
  });

  return {
    loading,
    data: converterCategoriesForForm(data?.category?.list, level),
  };
};

export const useCreateCampaign = () => {
  const [createCampaign, { loading, error, data }] = useMutation(CREATE_CAMPAIGN);

  const handleCreateCampaign = async (params, cloneID) => {
    return await createCampaign({
      variables: { promotionCampaignInput: params, cloneID },
    });
  };
  return {
    handleCreateCampaign,
    loading,
    error,
    data,
  };
};

export const useUpdateCampaign = () => {
  const [updateCampaign, { loading, error, data }] = useMutation(UPDATE_CAMPAIGN);

  const handleUpdateCampaign = async ({ id, params }) => {
    return await updateCampaign({
      variables: {
        id: id,
        promotionCampaignInput: params,
      },
      skip: !params || !params?.id,
    });
  };

  return {
    handleUpdateCampaign,
    loading,
    error,
    data,
  };
};

export const useCloneCampaign = () => {
  const [cloneCampaign, { loading, error, data }] = useMutation(CLONE_CAMPAIGN);

  const handleCloneCampaign = async (id) => {
    return await cloneCampaign({
      variables: { promotionCampaignID: id },
      skip: !id,
    });
  };
  return {
    handleCloneCampaign,
    loading,
    error,
    data,
  };
};

export const useLazyGetCategoriesOptions = (level) => {
  const [pagination, { loading, data }] = useLazyQuery(GET_PROD_CATEGORIES);

  const getDataCategories = async () => {
    pagination({
      variables: { req: { levels: [1] } },
    });
  };

  return {
    loading,
    data: converterCategoriesForForm(data?.category?.list, level),
    getDataCategories,
  };
};
