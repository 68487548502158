import { useGetSellerCreateCart } from "hooks/seller";
import { useState } from "react";
import { compact } from "lodash";
import { Col, Row, Typography } from "antd";
const useSelectSeller = ({ handleUpdateSeller, refetchCart, onGetWarehouses, warehouses }) => {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      isActive: true,
      isSeller: true,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { onLoadMoreData, hasMoreData, data, loading } = useGetSellerCreateCart({
    ...params,
  });

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const warehouseOptions = warehouses?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={16}>
        <Col span={3}>
          <Typography.Text>{item?.name}</Typography.Text>
        </Col>
        <Col span={18}>
          <Typography.Text ellipsis>
            {compact([item?.address, item?.ward?.name, item?.district?.name, item?.city?.name]).join(" ,")}
          </Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text ellipsis>{item?.branch?.name}</Typography.Text>
        </Col>
      </Row>
    ),
    warehouses: item?.warehouses,
    labelWarehouse: `${item?.branch?.name} - ${item?.name}`,
  }));

  const onChange = (value, options) => {
    onGetWarehouses && onGetWarehouses(options?.warehouses);
    handleUpdateSeller && handleUpdateSeller({ newSellerID: value });
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return { options: data, onLoadMoreData, hasMoreData, onSearch, onChange, warehouseOptions, loading };
};

export default useSelectSeller;
