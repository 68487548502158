import { Col, Form, Input, Row, Skeleton, Space, Tree, Typography } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import FormInput from "components/FormInput";
import { PrimaryButton } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import { useGetCategoriesLevel } from "hooks/category/category";
import { useGetSellerLevels } from "hooks/seller";
import { flatMapDeep } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

//flattened converted data
const flattenData = (data = []) => {
  return data?.reduce(function (flattened, { title, key, parentKey, titleString, children }) {
    return flattened?.concat([{ title, key, parentKey, titleString }])?.concat(children ? flattenData(children) : []);
  }, []);
};

const SellCategories = ({ onChangeCategory, seller, isUpdate }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const { sellerLevels } = useGetSellerLevels();
  const { categories, loading } = useGetCategoriesLevel({
    levels: [1],
    limit: 100,
  });
  const [levelSelected, setLevelSelected] = useState();

  const handleChangeLevelForAll = (value) => {
    setLevelSelected(value);
  };

  const renderLevelOptions = (sellerLevels) => {
    if (sellerLevels)
      return sellerLevels.map(({ level, name }) => ({
        label: name,
        value: level,
      }));
    else return [];
  };

  const getDefaultLevelById = (id) => {
    let foundItem = seller?.fullSaleCategories?.find((item) => item?.category?.id === id);
    let foundItemChecked = levelList?.find((item) => item?.id === id);
    return foundItemChecked?.level || foundItem?.level || 2;
  };

  const findItemIndex = (array, id) => {
    let foundIndex = -1;
    for (let i = 0; i < array?.length; i++) {
      if (array[i]?.id === id) {
        foundIndex = i;
        break;
      }
    }
    return foundIndex;
  };

  const handleSelectChange = ({ level, id }) => {
    const itemIndex = findItemIndex(levelList, id);

    if (itemIndex !== -1) {
      setLevelList((prevList) => prevList.map((item) => (item.id === id ? { ...item, level } : item)));
    } else {
      setLevelList((prevList) => [...prevList, { id, level }]);
    }
  };

  const findCheckedCategories = (id, parentID) => {
    if (checkedList.find((item) => item.id === id || item.id === parentID)) return true;
    else return false;
  };

  const renderTitleTree = (id, name, parentID, children) => {
    if (seller?.fullSaleCategories || !isUpdate || levelSelected) {
      return children?.length ? (
        name
      ) : (
        <div key={id} className="checkbox-item">
          <p className="checkbox-title">{name}</p>
          <Form form={form}>
            <FormInput
              type={INPUT_TYPE.SELECT}
              formItemOptions={{
                name: [id, "cateLevel"],
              }}
              inputOptions={{
                disabled: !findCheckedCategories(id, parentID),
                className: "seller-level",
                defaultValue: () => getDefaultLevelById(id),
                options: renderLevelOptions(sellerLevels),
                onChange: (level) =>
                  handleSelectChange({
                    level,
                    id,
                  }),
              }}
            />
          </Form>
        </div>
      );
    }
    return;
  };

  const convertTree = (data) => {
    return data?.map(({ id, name, parentID, children }) => {
      const strTitle = name;
      const strTitleLower = strTitle.toLocaleLowerCase();
      const index = strTitleLower.indexOf(searchValue.toLocaleLowerCase());
      const beforeStr = strTitle.substring(0, index);
      const afterStr = strTitle.slice(index + searchValue.length);
      const titleSearch =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: "#f50" }}>{strTitle.slice(index, index + searchValue.length)}</span>
            {afterStr}
          </span>
        ) : (
          <span>{strTitle}</span>
        );
      if (children) {
        return {
          title: () => renderTitleTree(id, titleSearch, parentID, children),
          key: id,
          titleString: strTitle,
          parentKey: parentID,
          children: convertTree(children),
        };
      }

      return {
        title: () => renderTitleTree(id, titleSearch, parentID, children),
        key: id,
        titleString: strTitle,
        parentKey: parentID,
      };
    });
  };

  const [memoTreeData, setMemoTreeData] = useState([]);

  useEffect(() => {
    setMemoTreeData(convertTree(categories));
  }, [searchValue, categories, checkedList, levelSelected]);

  const flattenedTree = flattenData(memoTreeData);

  const handleApplyCategories = () => {
    if (levelSelected) {
      const newCheckedList = flatMapDeep(flattenedTree, (node) => {
        const children = node.children || [];
        return [
          {
            id: node.key,
            level: levelSelected,
            checked: true,
          },
          ...children.map((child) => ({
            id: child.key,
            level: levelSelected,
            checked: true,
          })),
        ];
      });

      let foundItems = newCheckedList?.map(({ id, level }) => {
        form.setFieldsValue({ [id]: { cateLevel: levelSelected } });
        return {
          id: id,
          level: levelSelected,
        };
      });

      setLevelList(foundItems);
      setCheckedKeys(flattenedTree?.map((category, index) => category?.key));
      setCheckedList(newCheckedList);
    }
  };

  const findLevelById = (id) => {
    return levelList.find((item) => item.id === id)?.level || 2;
  };

  const initDefaultCheckedList = () => {
    let foundItems = seller?.fullSaleCategories
      ?.filter((item) => item?.checked)
      ?.map(({ category, level }) => ({
        id: category?.id,
        level,
        checked: true,
      }));

    setCheckedList(foundItems);
    onChangeCategory?.(foundItems);
  };

  const initDefaultLevelList = () => {
    let foundItems = seller?.fullSaleCategories?.map(({ category, level }) => ({
      id: category?.id,
      level,
    }));

    setLevelList(foundItems);
  };

  const initDefaultCheckedKeys = () => {
    let foundCheckedKeys =
      seller?.fullSaleCategories?.filter((item) => item?.checked)?.map((item) => item?.category?.id) || [];
    setCheckedKeys(foundCheckedKeys);
  };

  const onCheck = (_, event) => {
    const lastChildKeys = [];
    // check: if don't have children, push key on lastChildKey
    event.checkedNodes.forEach((element) => {
      if (element.children === undefined || element.children.length === 0) {
        lastChildKeys.push(element.key);
      }
    });
    setCheckedKeys(lastChildKeys);
    setExpandedKeys(lastChildKeys);
    setAutoExpandParent(true);
  };

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const newExpandedKeys = flattenedTree
      .filter((item) => item.titleString.toLowerCase().indexOf(value.toLowerCase()) > -1)
      .map((item) => {
        if (item.parentKey) return item.parentKey;
        else return item.key;
      });
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(true);
    setSearchValue(value);
  };

  useEffect(() => {
    initDefaultCheckedKeys();
    isUpdate && initDefaultCheckedList();
    isUpdate && initDefaultLevelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  useEffect(() => {
    onChangeCategory?.(checkedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  useEffect(() => {
    const newCheckedList = checkedKeys.map((key) => ({
      id: key,
      level: findLevelById(key),
      checked: true,
    }));
    setCheckedList(newCheckedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys, levelList]);

  return (
    <div className="sell-categories">
      <Row gutter={[16, 8]} className="sell-categories-content">
        <Col span={24}>
          <Input
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t("common.search")}
            allowClear={true}
            onChange={handleSearch}
            value={searchValue}
          />
        </Col>
        <Col span={24}>
          <Col span={24}>
            <Space style={{ width: "100%", justifyContent: "space-between", flexWrap: "wrap" }}>
              <div
                style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "8px" }}
              >
                <Typography.Text>Cấp giá đại lý</Typography.Text>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    style: { margin: 0 },
                    name: "changeAllCategory",
                  }}
                  inputOptions={{
                    placeholder: "Chọn cấp giá",
                    options: [
                      { label: "Cấp 1", value: 1 },
                      { label: "Cấp 2", value: 2 },
                      { label: "Cấp 3", value: 3 },
                      { label: "Cấp 4", value: 4 },
                    ],
                    onChange: handleChangeLevelForAll,
                  }}
                />
              </div>

              <PrimaryButton title={"Áp dụng tất cả ngành hàng"} onClick={handleApplyCategories} />
            </Space>
          </Col>
        </Col>
        <Col span={24} className="category-tree-frame">
          <Skeleton active loading={loading}>
            <Tree
              checkable
              selectable={true}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              treeData={memoTreeData}
              defaultExpandAll={true}
              showLine={{ showLeafIcon: false }}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
            />
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
};

export default SellCategories;
