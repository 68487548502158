import { SvgIcon } from "assets/icons";
import CustomTable from "components/CustomTable";
import StateSwitch from "components/StateSwitch";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Img from "assets/images";

const TableProductCategory = ({
  category,
  loadingCategories,
  onChange,
  idsCategories,
  expandedRowKeys,
  onExpand,
  loadingUpdateStatusCategory,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = [
    {
      title: t("product.categoryTableTitle.categoryList"),
      render: (_, record) => (
        <div className={"category-list"}>
          <img src={record.logoURL || Img.PlaceholderImageFailed} alt="" className={"logo"} />
          <p className={"title"}>
            {record.name} (<span className={"total-active-product"}>{record.totalActiveProduct}</span>/
            {record.totalProduct})
          </p>
        </div>
      ),
    },
    {
      title: t("product.categoryTableTitle.description"),
      dataIndex: "description",
      align: "center",
    },
    {
      title: t("product.categoryTableTitle.status"),
      dataIndex: "isActive",
      align: "center",
      render: (_, record) => (
        <StateSwitch
          // defaultChecked={record.isActive}
          loading={loadingUpdateStatusCategory}
          checked={record.isActive}
          onChange={(checked) => onChange(record?.id, record?.parentID, checked)}
        />
      ),
    },
    {
      title: t("product.categoryTableTitle.action"),
      align: "center",
      render: (_, record) => (
        <SvgIcon.EditIcon
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`/product-category-edit/${record.id}`)}
        />
      ),
    },
  ];

  return (
    <CustomTable
      className={"table-categories"}
      rowKey={(record) => record?.id}
      columns={columns}
      loading={loadingCategories}
      dataSource={category}
      rowClassName={(record, index) => (idsCategories.includes(record?.id) ? "highlights" : "")}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: onExpand,
      }}
    />
  );
};

export default memo(TableProductCategory);
