import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space, Typography } from "antd";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import ComboDiscountForm from "../components/ComboDiscountForm";

function DiscountMoneyForm({
  handleRemoveValueComboSelected,
  form,
  promotionCampaignTiers,
  indexErrorCombo,
  isUpdate,
  initFormDiscountMoney,
  dataTiersServer,
  onGetDataDiscountMoney,
}) {
  return (
    <Card
      title={
        <Typography.Title level={5} style={{ textTransform: "uppercase" }}>
          {t("campaign.combo.comboDiscount")}
        </Typography.Title>
      }
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Typography.Text italic className="title-main">
          {t("campaign.combo.noteComboDiscount")}
        </Typography.Text>
        <Form.List name={"promotionCampaignTierGroupsDiscountMoney"}>
          {(fields, { add, remove }) => {
            const handleAddComboCollapse = () => {
              add(initFormDiscountMoney);
            };

            return (
              <Row gutter={[16, 16]}>
                {fields.map((field, index) => (
                  <Col span={24} key={field.key}>
                    <ComboDiscountForm
                      onRemoveValueComboSelected={handleRemoveValueComboSelected}
                      form={form}
                      remove={() => remove(field.name)}
                      promotionCampaignTiers={promotionCampaignTiers}
                      indexErrorCombo={indexErrorCombo}
                      isUpdate={isUpdate}
                      indexComboDiscount={index}
                      dataTiersServer={dataTiersServer}
                      onGetDataDiscountMoney={onGetDataDiscountMoney}
                    />
                  </Col>
                ))}
                <Col span={24}>
                  <Typography.Link underline onClick={() => handleAddComboCollapse()}>
                    <PlusOutlined /> {t("campaign.combo.addComboDiscountMoney")}
                  </Typography.Link>
                </Col>
              </Row>
            );
          }}
        </Form.List>
      </Space>
    </Card>
  );
}

export default DiscountMoneyForm;
