import CreateButton from "components/CreateButton";
import { t } from "i18next";
import PotentialCustomerDrawerForm from "../PotentialCustomerDrawerForm";
import useAddPotentialCustomer from "./useAddPotentialCustomer";

function AddPotentialCustomer() {
  const {
    openDrawer,
    initForm,
    form,
    dataStaffOptions,
    hasMoreData,
    typePersonal,
    formValues,
    loadingCreate,
    handleOpenDrawer,
    handleSubmitDrawer,
    handleValuesChange,
    onLoadMoreData,
    onSearchStaff,
    handleChangeInChargeStaff,
    valueEmail,
    loadingEmail,
    valueTelephone,
    loadingTelephone,
    isDuplicateEmail,
    isDuplicateTelephone,
    handleSearchEmail,
    handleSearchTelephone,
    valueIdNumber,
    valueTaxCode,
    loadingIdNumber,
    loadingTaxCode,
    isDuplicateIdNumber,
    isDuplicateTaxCode,
    handleSearchIdNumber,
    handleSearchTaxCode,
    openDrawerMulti,
    handleOpenDrawerMulti,
    handleCloseDrawerMulti,
    dataDetail,
    loadingDetail,
    visibleModalCloseDrawer,
    handleOkeModalCloseDrawer,
    handleOpenModalCloseDrawer,
    handleCancelModalCloseDrawer,
  } = useAddPotentialCustomer();

  return (
    <>
      <CreateButton title={t("potentialCustomer.createCustomer")} onClick={handleOpenDrawer} />
      <PotentialCustomerDrawerForm
        openDrawer={openDrawer}
        loadingForm={loadingCreate}
        handleSubmitDrawer={handleSubmitDrawer}
        initForm={initForm}
        form={form}
        handleValuesChange={handleValuesChange}
        optionsInChargeStaff={dataStaffOptions}
        onLoadMoreInChargeStaff={onLoadMoreData}
        hasMoreInChargeStaff={hasMoreData}
        handleSearchInChargeStaffs={onSearchStaff}
        handleChangeInChargeStaff={handleChangeInChargeStaff}
        typePersonal={typePersonal}
        formValues={formValues}
        valueEmail={valueEmail}
        loadingEmail={loadingEmail}
        valueTelephone={valueTelephone}
        loadingTelephone={loadingTelephone}
        isDuplicateEmail={isDuplicateEmail}
        isDuplicateTelephone={isDuplicateTelephone}
        handleSearchEmail={handleSearchEmail}
        handleSearchTelephone={handleSearchTelephone}
        valueIdNumber={valueIdNumber}
        valueTaxCode={valueTaxCode}
        loadingIdNumber={loadingIdNumber}
        loadingTaxCode={loadingTaxCode}
        isDuplicateIdNumber={isDuplicateIdNumber}
        isDuplicateTaxCode={isDuplicateTaxCode}
        handleSearchIdNumber={handleSearchIdNumber}
        handleSearchTaxCode={handleSearchTaxCode}
        openDrawerMulti={openDrawerMulti}
        handleOpenDrawerMulti={handleOpenDrawerMulti}
        handleCloseDrawerMulti={handleCloseDrawerMulti}
        dataDetail={dataDetail}
        loadingDetail={loadingDetail}
        visibleModalCloseDrawer={visibleModalCloseDrawer}
        handleOpenModalCloseDrawer={handleOpenModalCloseDrawer}
        handleOkeModalCloseDrawer={handleOkeModalCloseDrawer}
        handleCancelModalCloseDrawer={handleCancelModalCloseDrawer}
      />
    </>
  );
}

export default AddPotentialCustomer;
