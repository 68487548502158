import { Col, Row, Typography } from "antd";

import { t } from "i18next";

import { convertTotalNumber, IMPORT_TYPE } from "features/Serial/constant";
import styles from "../../../index.module.scss";

function FooterTable({ importType, dataTableProduct, sumSerialByOrder, sumSerialByScanned, sumSerialByLack }) {
  const renderSubTotal = (sumSerialByOrder, sumSerialByScanned, sumSerialByLack) => {
    if (sumSerialByOrder < sumSerialByScanned) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "red" }}>
            : {sumSerialByScanned - sumSerialByOrder}
          </Col>
        </>
      );
    }
    if (sumSerialByScanned === sumSerialByOrder) {
      return (
        <>
          <Col span={12}>
            <Typography.Text strong className={styles["summary"]}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ color: "#00AB78" }}>: {t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (sumSerialByScanned !== sumSerialByOrder) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("common.lack")}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "#ef9351" }}>
            : {sumSerialByLack}
          </Col>
        </>
      );
    }
  };

  const renderUI = {
    [IMPORT_TYPE.FIRST_TIME_IMPORT]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
        <Col span={12}>: {convertTotalNumber(dataTableProduct?.length)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByOrder)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByScanned)}</Col>

        {renderSubTotal(sumSerialByOrder, sumSerialByScanned, sumSerialByLack)}
      </Row>
    ),
  };

  return renderUI?.[importType];
}

export default FooterTable;
