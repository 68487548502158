import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Form, Input, Row, Space, Tooltip, Typography } from "antd";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { DISCOUNT_TYPE, OPTIONS_TYPE_DISCOUNT, OPTIONS_TYPE_LIMIT, TYPE_LIMIT } from "features/Campaign/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";
import ProductCol from "../ProductCol";

const { Panel } = Collapse;

function ComboCollapse({
  remove,
  indexPromotionCampaignTiers,
  form,
  promotionCampaignTiers,
  isUpdate,
  dataTiersServer,
  indexComboDiscount,
  fields,
  valuesForm,
  onGetDataDataTiersServer,
}) {
  const dataPromotionCampaignTiersFromIndex =
    valuesForm?.promotionCampaignTierGroupsDiscountMoney?.[indexComboDiscount]?.promotionCampaignTiers[
      indexPromotionCampaignTiers
    ] || dataTiersServer[indexPromotionCampaignTiers];
  const discountType = dataPromotionCampaignTiersFromIndex?.discountType;
  const typeLimit = dataPromotionCampaignTiersFromIndex?.limitType;
  const discountAmount = dataPromotionCampaignTiersFromIndex?.discountAmount;
  const budgetAmount = dataPromotionCampaignTiersFromIndex?.budgetAmount;

  const isValidateStatusWarning =
    discountType === DISCOUNT_TYPE.DISCOUNT_MONEY && (budgetAmount ? discountAmount > budgetAmount : false);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse-nested" expandIconPosition="left">
      <Panel
        header={
          <Typography.Text strong style={{ textTransform: "uppercase" }}>
            {t("campaign.combo.preferentialLevel")} {indexPromotionCampaignTiers + 1}
          </Typography.Text>
        }
        extra={
          fields?.length !== 1 && (
            <CustomModal
              {...{
                message: t("campaign.combo.errorMessage.removeComboCollapse"),
                onOke: (event) => {
                  remove();
                },
                footer: false,
                icon: Img.DeleteIcon,
                customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
              }}
            />
          )
        }
        key="1"
      >
        <Row gutter={[36]} className="wrapper-collapse-nested">
          <Col xxl={12} span={24} style={{ borderRight: "1px dashed #9CADC4" }}>
            <Row gutter={[0, 20]} style={{ flexDirection: "column" }}>
              <Col span={24}>
                <Divider orientation="left">{t("campaign.combo.chooseProductAndQtyCombo")}</Divider>
              </Col>
              <Col span={24}>
                <Form.List name={[indexPromotionCampaignTiers, "conditionProductGroups"]}>
                  {(fields, { add, remove }) => {
                    const handleAddProductCol = () => {
                      add({
                        quantity: null,
                        conditionPromotionProducts: [],
                      });
                      onGetDataDataTiersServer &&
                        onGetDataDataTiersServer(valuesForm?.promotionCampaignTierGroupsDiscountMoney);
                    };
                    return (
                      <Row>
                        {fields.map((field, index) => (
                          <Col span={24} key={field?.key}>
                            <ProductCol
                              form={form}
                              indexPromotionCampaignTiers={indexPromotionCampaignTiers}
                              indexConditionProductGroups={index}
                              remove={() => {
                                remove(field?.name);
                                onGetDataDataTiersServer &&
                                  onGetDataDataTiersServer(valuesForm?.promotionCampaignTierGroupsDiscountMoney);
                              }}
                              promotionCampaignTiers={promotionCampaignTiers}
                              isUpdate={isUpdate}
                              dataTiersServer={dataTiersServer}
                              indexComboDiscountMoney={indexComboDiscount}
                              fields={fields}
                              valuesForm={valuesForm}
                            />
                          </Col>
                        ))}
                        {fields?.length < 10 && (
                          <Col span={24}>
                            <Typography.Link underline onClick={() => handleAddProductCol()}>
                              <PlusOutlined /> {t("campaign.combo.addProduct")}
                            </Typography.Link>
                          </Col>
                        )}
                      </Row>
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
          </Col>
          <Col xxl={12} span={24} className="condition-combo">
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Divider orientation="left">{t("campaign.combo.comboCondition")}</Divider>
              </Col>
              {/* minQuantity */}
              <Col span={24}>
                <FormInput
                  type={INPUT_TYPE.NUMBER}
                  {...{
                    formItemOptions: {
                      label: (
                        <Space>
                          <Typography.Text strong>{t("campaign.combo.qtyComboEstimate")}</Typography.Text>
                          <Tooltip title={t("campaign.combo.tooltipQtyComboEstimate")}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      ),
                      name: [indexPromotionCampaignTiers, "minQuantity"],
                      rules: [
                        {
                          required: true,
                          message: t("campaign.combo.errorMessage.qtyComboEstimate"),
                        },
                        {
                          type: "number",
                          min: 1,
                          message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                        },
                        validator({
                          type: "number",
                        }),
                      ],
                      labelCol: { span: 8 },
                      wrapperCol: { span: 8 },
                      style: { flexDirection: "row" },
                    },
                    inputOptions: {
                      placeholder: t("campaign.combo.placeholder.type"),
                    },
                  }}
                />
              </Col>
              {/* discountType */}
              <Col span={24} className={"form-flex"}>
                <Form.Item
                  label={
                    <Typography.Text
                      className="character-required"
                      strong
                      ellipsis={{
                        suffix: (
                          <Tooltip title={t("campaign.combo.tooltipTypeDiscount")}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        ),
                      }}
                    >
                      {t("campaign.combo.typeDiscount")}{" "}
                    </Typography.Text>
                  }
                  labelCol={{ span: 8 }}
                  wrapperCol={{ xl: 8, span: 16 }}
                  style={{ flexDirection: "row" }}
                >
                  <Input.Group compact>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.SELECT,
                        formItemOptions: {
                          noStyle: true,
                          name: [indexPromotionCampaignTiers, "discountType"],
                        },
                        inputOptions: {
                          options: OPTIONS_TYPE_DISCOUNT,
                        },
                      }}
                    />
                    <FormInput
                      {...{
                        type:
                          discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE
                            ? INPUT_TYPE.NUMBER
                            : INPUT_TYPE.CURRENCY_INPUT,
                        formItemOptions: {
                          noStyle: true,
                          name: [indexPromotionCampaignTiers, "discountAmount"],
                          rules: [
                            {
                              type: "number",
                              min: 1,
                              message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                            },
                            {
                              required: true,
                              message:
                                discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE
                                  ? t("campaign.combo.errorMessage.typeDiscountPercent")
                                  : t("campaign.combo.errorMessage.typeDiscount"),
                            },
                            validator({
                              type: "number",
                            }),
                          ],
                        },
                        inputOptions: {
                          placeholder: t("campaign.combo.placeholder.type"),
                          maxLength: discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE ? 2 : 15,
                          max: discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE && 99,
                        },
                      }}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              {/* typeLimit */}
              <Col span={24} className={"form-flex"}>
                <Form.Item
                  label={
                    <Typography.Text className="character-required" strong>
                      {t("campaign.combo.maxNumberOfComboPerDealer")}
                    </Typography.Text>
                  }
                  labelCol={{ span: 8 }}
                  wrapperCol={{ xl: 8, span: 16 }}
                  style={{ flexDirection: "row" }}
                >
                  <Input.Group compact>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.SELECT,
                        formItemOptions: {
                          noStyle: true,
                          name: [indexPromotionCampaignTiers, "limitType"],
                        },
                        inputOptions: {
                          options: OPTIONS_TYPE_LIMIT,
                        },
                      }}
                    />
                    <FormInput
                      type={INPUT_TYPE.NUMBER}
                      {...{
                        formItemOptions: {
                          noStyle: true,
                          name: [indexPromotionCampaignTiers, "maxQuantityPerSeller"],
                          rules: [
                            {
                              required: typeLimit !== TYPE_LIMIT.NO_LIMIT,
                              message: t("campaign.combo.errorMessage.maxNumberOfComboPerDealer"),
                            },
                            {
                              type: "number",
                              min: 1,
                              message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                            },
                            validator({
                              type: "number",
                            }),
                          ],
                        },
                        inputOptions: {
                          placeholder: t("campaign.combo.placeholder.type"),
                          disabled: typeLimit === TYPE_LIMIT.NO_LIMIT,
                        },
                      }}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <FormInput
                  type={INPUT_TYPE.CURRENCY_INPUT}
                  {...{
                    formItemOptions: {
                      label: <Typography.Text strong>{t("campaign.combo.totalPromotionAmount")}</Typography.Text>,
                      validateStatus: isValidateStatusWarning ? "warning" : undefined,
                      help: isValidateStatusWarning ? t("campaign.combo.warningMaxBudgetAmout") : undefined,
                      rules: [
                        {
                          required: true,
                          message: t("campaign.combo.errorMessage.totalPromotionAmount"),
                        },
                        {
                          type: "number",
                          min: 1,
                          message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                        },
                      ],
                      labelCol: { span: 8 },
                      wrapperCol: { span: 8 },
                      name: [indexPromotionCampaignTiers, "budgetAmount"],
                      style: { flexDirection: "row" },
                    },
                    inputOptions: {
                      placeholder: t("campaign.combo.placeholder.type"),
                      maxLength: 15,
                    },
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

export default ComboCollapse;
