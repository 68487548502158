import { t } from "i18next";

import CustomButton from "components/CustomButton";
import CustomModal from "components/CustomModal";

function AffixButton({
  onCancelCreate,
  importType,
  handleCreate,
  loadingCreateBtn,
  visible,
  showOffModalConfirmCreate,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        gap: "16px",
        background: "#fff",
        borderRadius: "8px",
        padding: "5px",
      }}
    >
      <CustomModal
        {...{
          customComponent: <CustomButton title={t("common.cancel")} />,
          footer: false,
          message: t("serialIE.notify.cancelCreate"),
          centered: true,
          onOke: onCancelCreate,
        }}
      />
      <CustomButton
        type={"primary"}
        title={t("serialIE.productInfo.warehouse")}
        htmlType={"submit"}
        onClick={handleCreate}
        loading={loadingCreateBtn}
      />
    </div>
  );
}

export default AffixButton;
