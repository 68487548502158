import { Col, Input, InputNumber, Row, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useWeightInput from "./useWeightInput";

const WeightInput = ({ value = {}, onChange, disabledInput }) => {
  const { t } = useTranslation();

  const { groupValue, onNumberChange, onUomChange, weightOptions } = useWeightInput(value, onChange);

  return (
    <Input.Group>
      <Row gutter={5}>
        <Col span={10}>
          <InputNumber
            placeholder={t("product.productForm.weightPlaceholder")}
            value={value.weight != null ? value.weight : groupValue.weight}
            onChange={(e) => onNumberChange(e, "weight")}
            max={9999}
            disabled={disabledInput}
          />
        </Col>
        <Col span={8}>
          <Select
            options={weightOptions}
            value={value.unit || groupValue.unit}
            onChange={onUomChange}
            disabled={disabledInput}
          />
        </Col>
      </Row>
    </Input.Group>
  );
};

export default WeightInput;
