/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { ACCEPT_IMG_TYPES } from "config/constants";
import { useGetMe } from "hooks/auth/login";
import { useGetBranchQuery } from "hooks/branch";
import { useCities, useDistricts, useWards } from "hooks/common";
import { useDepartments } from "hooks/department/department";
import { useGetRoles } from "hooks/role";
import { useUpload } from "hooks/upload";
import { useUpdateUser } from "hooks/user/user";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getQuery, notify, readFile } from "utils/helperFuncs";
import { omit } from "utils/object";

function useList() {
  const GET_QUERY = getQuery();
  const { me } = useGetMe();
  const [form] = Form.useForm();
  const { data: dataBranch } = useGetBranchQuery();
  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });
  const { cities } = useCities();
  const { districts, refetch: refetchDistricts } = useDistricts(location?.cityId);
  const { wards, refetch: refetchWards } = useWards(location?.districtId);
  const [avatar, setAvatar] = useState({
    base64String: "",
    src: "",
  });

  const { departments } = useDepartments({
    filters: {
      query: undefined,
    },
    pagination: {
      offset: 0,
      limit: 100,
    },
  });
  const { roles } = useGetRoles({
    offset: 0,
    query: undefined,
  });
  function initForm() {
    form.setFieldsValue({
      ...(me || {}),
      roleIDs: me?.roles?.map(({ id }) => id),
      branchIDs: me?.branches?.map(({ id }) => id),
    });
  }
  const convertOptions = (data) => {
    return data
      ? data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      : [];
  };

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }
  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  const [isEdit, setIsEdit] = useState(false);
  const handleCancel = () => {
    setIsEdit(false);
    initForm();
  };
  const changeUpdate = () => {
    setIsEdit(true);
  };

  const [uploading, setUploading] = useState(false);
  const { handleUpload } = useUpload();
  function onUploadChange(info) {
    if (info.file.status === "uploading") {
      setUploading(true);
    }
    if (info.file.status === "done") {
      if (!ACCEPT_IMG_TYPES.includes(info?.file?.type)) {
        notify.error({
          message: t("account.conditionImage"),
        });
        setUploading(false);
        return;
      }
      setUploading(false);
      readFile({
        getResult: async (result) => {
          const uploadUrl = await handleUpload({ file: info?.file?.originFileObj });
          setAvatar({
            base64String: result,
            src: uploadUrl,
          });
        },
        type: "Base64String",
        file: info?.file?.originFileObj,
      });
    } else if (info.file.status === "error") {
      return;
    }
  }

  const { handleUpdateUser, loading: loadingUpdate } = useUpdateUser();
  async function handleUpdate(values) {
    try {
      values = omit(values, "raw_password");
      await handleUpdateUser({
        request: {
          id: me?.id,
          avatarURL: avatar.src,
          ...values,
        },
      });
      setIsEdit(false);
      notify.success({
        message: t("account.updateAccountSuccess"),
      });
    } catch (err) {
      notify.error({
        message: t("account.updateAccountFaild"),
        description: err.message,
      });
    }
  }
  useEffect(() => {
    initForm();
    setLocation({
      cityId: me?.cityID,
      districtId: me?.districtID,
    });
    setAvatar({
      src: me?.avatarURL,
    });
  }, [me]);

  useEffect(() => {
    if (location?.cityId) {
      refetchDistricts(location?.cityId);
    }
    if (location?.districtId) {
      refetchWards(location?.districtId);
    }
  }, [location]);
  return {
    GET_QUERY,
    me,
    form,
    dataBranch,
    cities,
    districts,
    departments,
    roles,
    convertOptions,
    wards,
    changeUpdate,
    isEdit,
    handleCancel,
    handleUpdate,
    avatar,
    handleChangeCity,
    handleChangeDistrict,
    uploading,
    onUploadChange,
    loadingUpdate,
  };
}

export default useList;
