import { gql } from "@apollo/client";
import { GET_MORE_PRODUCT_FRAGMENT } from "./fragment";

export const GET_PRODUCTS_QUERY = gql`
  query GetProducts(
    $filters: ProductsFilters
    $pagination: PaginationRequest
    $warehouseIDs: [ID!]
    $sellerID: ID
    $withCart: Boolean!
  ) {
    product {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          limit
          offset
        }
        products {
          categoryID
          id
          name
          code
          inStock @skip(if: $withCart)
          # shortCode
          manufacturer {
            name
          }
          uom {
            name
          }
          vat
          vatValue
          vatPercent
          vatType
          note
          warranty @skip(if: $withCart)
          warrantyUnit
          productPrices {
            level1Price
            level2Price
            level3Price
            level4Price
          }
          lastPriceLog {
            oldLevel1Price
            newLevel1Price
            oldLevel2Price
            newLevel2Price
            oldLevel3Price
            newLevel3Price
            oldLevel4Price
            newLevel4Price
          }
          priceOfSeller(sellerID: $sellerID) {
            price
            level
            priceNoVat
            priceHasVat
            vatType
            defaultExportVat
          }
          stocks(warehouseIDs: $warehouseIDs) {
            productID
            quantity
            quantityRealStockHasVat
            warehouse {
              id
              code
              name
              regionID
            }
          }
          stocksSupplier {
            regionID
            region {
              name
              code
              isActive
            }
            totalQuantity
            vatQuantity
          }
          updatedAt @skip(if: $withCart)
          vatPercent @skip(if: $withCart)
          fullAttributeValues @skip(if: $withCart) {
            attributeID
            attributeValues {
              id
              value
            }
          }
          category {
            isActive
            name
            parent {
              isActive
              name
              parent {
                isActive
                name
              }
            }
          }
          sellingStatus
          isActive @skip(if: $withCart)
          productType
          vatType
          productDiscounts {
            productID
            minQuantity
            maxQuantity
            discountAmount
            isSelected
          }
          conditionPromotionProductsOnGoing {
            promotionCampaign {
              id
              name
              status
              startDate
              endDate
              # conditionPromotionProducts {
              #   product {
              #     name
              #     code
              #   }
              # }
            }
            promotionCampaignTier {
              id
              type
              maxQuantityPerSeller
              minQuantity
              discountAmount
              discountType
              budgetAmount
              fixedComboPrice
              conditionProductGroups {
                quantity
                conditionPromotionProducts {
                  product {
                    code
                  }
                }
              }
            }
          }
          productDiscounts {
            productID
            minQuantity
            maxQuantity
            discountAmount
            isSelected
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  query GetProduct($id: ID!) {
    product {
      get(id: $id) {
        variantAttributeValues {
          attributeID
          attribute {
            name
          }
          attributeValue {
            id
            value
          }
        }
        flatAttributeValues {
          attributeName
          attributeValue
        }
        fullAttributeValues {
          attributeID
          attribute {
            name
          }
          attributeValues {
            id
            value
          }
        }
        group {
          hasVariant
          id
          description
          imageURLs
          dimension {
            length
            width
            height
            unit
          }
          weight {
            weight
            unit
          }
          defaultProduct {
            productType
            copyrightTerm
            copyrightUnit
            warrantyUnit
            warranty
          }
          brand {
            name
          }
          variantAttributeValues {
            attributeID
            attribute {
              name
            }
            attributeValues {
              id
              value
            }
          }
        }
        category {
          name
        }
        isActive
        ...GetMoreProductFragment
      }
    }
  }
  ${GET_MORE_PRODUCT_FRAGMENT}
`;

export const GET_BY_VARIANT_ATTRIBUTE = gql`
  query GetByVariantAttribute($productID: ID!, $variantAttributeValueIDs: [ID!]!) {
    product {
      getByVariantAttribute(productID: $productID, variantAttributeValueIDs: $variantAttributeValueIDs) {
        variantAttributeValues {
          attributeID
          attribute {
            name
          }
          attributeValue {
            id
            value
          }
        }
        flatAttributeValues {
          attributeName
          attributeValue
        }
        fullAttributeValues {
          attributeID
          attributeValues {
            id
            value
          }
        }
        group {
          description
          imageURLs
          variantAttributeValues {
            attributeID
            attribute {
              name
            }
            attributeValues {
              id
              value
            }
          }
        }
        category {
          name
        }
        ...GetMoreProductFragment
      }
    }
  }
  ${GET_MORE_PRODUCT_FRAGMENT}
`;

export const GET_ALL_CATEGORIES_QUERY = gql`
  query GetAllCategories($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        level
        isActive
        children {
          id
          name
          level
          isActive
          children {
            id
            name
            level
            isActive
            attributes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ATTRIBUTE_OF_CATEGORY = gql`
  query GetAttributeOfCategory($req: ListAttributeRequest) {
    attribute {
      list(req: $req) {
        id
        name
        attributeType
        values {
          id
          value
        }
      }
    }
  }
`;

export const GET_RECOMMEND_PRODUCT_NAME = gql`
  query GetRecommendProductName($filters: ProductsFilters, $pagination: PaginationRequest) {
    product {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          limit
          offset
        }
        products {
          id
          name
        }
      }
    }
  }
`;

export const GET_MANUFACTURES_AND_WAREHOUSE = gql`
  query GetManufacturesAndWarehouse {
    manufacturer {
      list {
        id
        name
      }
    }
    warehouse {
      list {
        warehouses {
          id
          code
          name
          regionID
        }
      }
    }
  }
`;

export const GET_PRODUCT_INIT_FORM_QUERY = gql`
  query GetProductInitForm($id: ID!) {
    product {
      get(id: $id) {
        group {
          description
          imageURLs
          categoryID
          hasVariant
          products {
            id
            name
            code
            isActive
            imageURL
            productPrices {
              level1Price
              level2Price
              level3Price
              level4Price
            }
            stocks {
              warehouseID
              quantity
              quantityRealStock
            }
            variantAttributeValues {
              attributeID
              attributeValue {
                id
                value
              }
            }
            vat
            vatPercent
          }
          attributeValues {
            attributeID
            isVariant
            value
            attributeValues {
              id
              value
            }
          }
          productPrices {
            importPrice
            level1Price
            level2Price
            level3Price
            level4Price
          }
          warranty
        }
        isActive
        uom {
          id
          name
        }
        ...GetMoreProductFragment
      }
    }
  }
  ${GET_MORE_PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_GROUP_INIT_FORM_QUERY = gql`
  query GetProductGroupInitForm($id: ID!) {
    productGroup {
      get(id: $id) {
        products {
          id
          vatValue
          vatType
          vatPercent
          warranty
          warrantyUnit
        }
        id
        name
        code
        brandID
        categoryID
        warranty
        warrantyUnit
        uomID
        imageURLs
        description
        hasVariant
        vat
        vatPercent
        defaultProduct {
          productType
          copyrightTerm
          copyrightUnit
        }
        attributeValues {
          attributeID
          isVariant
          value
          attributeValues {
            id
            value
          }
        }
        productPrices {
          importPrice
          level1Price
          level2Price
          level3Price
          level4Price
        }
        defaultProductID
        products {
          id
          code
          name
          isActive
          imageURL
          vat
          vatPercent
          variantAttributeValues {
            attributeID
            attributeValue {
              id
              value
            }
          }
          stocks {
            quantityRealStockHasVat
          }
          productPrices {
            level1Price
            level2Price
            level3Price
            level4Price
          }
        }
        dimension {
          length
          width
          height
          unit
        }
        weight {
          weight
          unit
        }
      }
    }
  }
`;

export const GET_SUGGESTED_PROD_GROUP = gql`
  query GetSuggestedProdGroup($filters: ProductGroupFilters, $pagination: PaginationRequest) {
    productGroup {
      pagination(filters: $filters, pagination: $pagination) {
        products {
          id
          name
          code
          totalVariation
        }
        paginationData {
          offset
          limit
          total
        }
      }
    }
  }
`;

export const GET_PRODUCT_PRICELOG = gql`
  query getPriceLog($filters: PriceLogsFilter, $pagination: PaginationRequest! = { offset: 0, limit: 10 }) {
    product {
      priceLogs(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        priceLogs {
          createdAt
          productID
          product {
            id
            name
            code
          }
          oldLevel1Price
          newLevel1Price
          oldLevel2Price
          newLevel2Price
          oldLevel3Price
          newLevel3Price
          oldLevel4Price
          newLevel4Price
          oldVat
          oldVatType
          newVat
          newVatType
          oldVatPercent
          newVatPercent
          newVatValue
          oldVatValue
          oldLevel1Quantity
          newLevel1Quantity
          oldLevel1Discount
          newLevel1Discount
          oldLevel2Quantity
          newLevel2Quantity
          oldLevel2Discount
          newLevel2Discount
          oldLevel3Quantity
          newLevel3Quantity
          oldLevel3Discount
          newLevel3Discount
          oldLevel4Quantity
          newLevel4Quantity
          oldLevel4Discount
          newLevel4Discount
          createdBy {
            fullname
          }
          priceImportSessionID
          priceImportSession {
            fileName
            fileURL
          }
        }
      }
    }
  }
`;

export const GET_IMPORT_FILE_PRODUCT = gql`
  query GetImportFileProduct($filters: ProductsFilters) {
    product {
      getImportFile(filters: $filters) {
        fileURL
      }
    }
  }
`;

export const GET_LIST_PRICE_BID_WAIT_RESPONSE = gql`
  query GetAllListPriceBid(
    $filters: PriceBidSessionFilters
    $sort: [PriceBidSessionSort!] = [{ by: ID, sortType: DESC }]
    $pagination: PaginationRequest! = { offset: 0, limit: 100 }
  ) {
    priceBidSession {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        priceBidSessions {
          id
          createdAt
          cartItemID
          productID
          status
          product {
            name
            code
            uom {
              name
            }
          }
          sellerID
          seller {
            fullName
            id
          }
          ownerType
          originalPrice
          currentSuggestedPrice
          currentSuggestedQuantity
          events {
            eventType
            createdBy {
              fullname
              type
            }
            createdAt
            ownerType
            suggestedPrice
            suggestedQuantity
            reason
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BRAND = gql`
  query GetProductBrand(
    $filters: BrandFilters!
    $pagination: PaginationRequest!
    $sort: [BrandSort!] = [{ by: CREATED_AT, sortType: DESC }]
  ) {
    brand {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        paginationData {
          total
          limit
          offset
          __typename
        }
        brands {
          id
          name
          logoURL
          catalogURLs
          description
          isActive
          quantity
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_DETAIL_PRODUCT_BRAND = gql`
  query GetDetailBrand($id: ID!) {
    brand {
      get(id: $id) {
        id
        name
        logoURL
        catalogURLs
        description
        isActive
        quantity
      }
    }
  }
`;

export const BRAND = gql`
  query {
    brand {
      list {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_PRODUCT_WITH_IDS = gql`
  query GetProductWithIds($filters: ProductsFilters) {
    product {
      pagination(filters: $filters) {
        products {
          id
          name
          code
        }
      }
    }
  }
`;

export const CHECK_DUPLICATED_PRODUCT = gql`
  query CheckDuplicatedProduct($productCode: String!) {
    product {
      checkDuplicatedProduct(productCode: $productCode) {
        result
        product {
          id
          code
          name
        }
        respErr
      }
    }
  }
`;
