import { gql } from "@apollo/client";

export const CREATE_ATTRIBUTE_MUTATION = gql`
  mutation CreateAttribute(
    $name: String!
    $attributeType: AttributeType!
    $isCommon: Boolean!
    $values: [AttributeValueInput]
  ) {
    attribute {
      create(req: { name: $name, attributeType: $attributeType, isCommon: $isCommon, values: $values }) {
        id
      }
    }
  }
`;

export const UPDATE_ATTRIBUTE = gql`
  mutation UpdateAttribute(
    $id: ID!
    $name: String!
    $attributeType: AttributeType!
    $isCommon: Boolean!
    $values: [AttributeValueInput]
    $isActive: Boolean!
  ) {
    attribute {
      update(
        id: $id
        req: { name: $name, attributeType: $attributeType, isCommon: $isCommon, values: $values, isActive: $isActive }
      )
    }
  }
`;

export const UPDATE_ATTRIBUTE_STATUS = gql`
  mutation UpdateAttributeStatus($ids: [ID!], $isActive: Boolean!) {
    attribute {
      updateStatus(ids: $ids, isActive: $isActive)
    }
  }
`;
