import React from "react";
import { Col, Row } from "antd";
import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import Toolbar from "./Toolbar";
import useWarrantyList from "./useWarrantyList";

const List = () => {
  const {
    columns,
    rowSelection,
    handleChangeTable,
    paginationData,
    data,
    loading,
    countSelectedRowKeys,
    selectedProduct,
    handleSearch,
    setIsFilterSelected,
    params,
    setParams,
    onChangeParams,
  } = useWarrantyList();
  return (
    <Row gutter={[16, 12]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("warranty.warrantyList.title")}
          routes={[
            { path: "/warranty", name: t("common.warranty") },
            { path: "", name: t("warranty.warrantyList.title") },
          ]}
        />
      </Col>

      <Toolbar
        onSearch={handleSearch}
        countSelectedRowKeys={countSelectedRowKeys}
        selectedProduct={selectedProduct}
        setIsFilterSelected={setIsFilterSelected}
        params={params}
        onChangeParams={onChangeParams}
        setParams={setParams}
      />

      <Col span={24}>
        <CustomTable
          dataSource={selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows : data}
          columns={columns}
          onChange={handleChangeTable}
          rowSelection={rowSelection}
          rowKey={(record) => record?.id}
          loading={loading}
          pagination={{
            total: selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows?.length : paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          scroll={{
            y: 550,
            x: "max-content",
          }}
        />
      </Col>
    </Row>
  );
};

export default List;
