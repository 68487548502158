import { INPUT_TYPE, PRODUCT_INPUT_TYPE } from "config/constants";
import FormInput from "components/FormInput";
import { Input, Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "assets/icons";
import { useProductPermissions } from "hooks/product/product";

const { Option } = Select;

const EditableCell = ({ editing, dataIndex, inputType, children, ...restProps }) => {
  const { t } = useTranslation();
  const { canUpdateInfo, canUpdatePrice } = useProductPermissions();

  const formFields = {
    currencyField: {
      type: INPUT_TYPE.CURRENCY_INPUT,
      formItemOptions: {
        rules: [
          {
            required: true,
            message: t("product.validateMessage.currency.blank"),
          },
          {
            type: "number",
            max: 999999999,
            message: t("product.validateMessage.currency.overLimit"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("product.placeholder.currency"),
        min: 0,
        controls: false,
        maxLength: 11,
        disabled: !canUpdatePrice,
      },
    },
    noteField: {
      type: INPUT_TYPE.INPUT,
      formItemOptions: {},
    },
    vatField: {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        name: "vat",
      },
      inputOptions: {
        defaultValue: true,
        disabled: !canUpdateInfo,
      },
      inputChildren: (
        <>
          <Option value={true}>
            <SvgIcon.SuccessIcon />
          </Option>
          <Option value={false}>
            <SvgIcon.CloseIcon />
          </Option>
        </>
      ),
    },
    numberField: {
      type: INPUT_TYPE.NUMBER,
      formItemOptions: {
        rules: [
          {
            required: true,
            message: t("product.validateMessage.number"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("product.placeholder.number"),
        min: 0,
        controls: false,
        maxLength: 4,
        disabled: !canUpdateInfo,
      },
    },
    vatPercent: {
      type: INPUT_TYPE.NUMBER,
      formItemOptions: {
        rules: [
          {
            required: true,
            message: t("product.validateMessage.number"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("product.placeholder.number"),
        min: -1,
        max: 100,
        controls: false,
        disabled: !canUpdateInfo,
      },
    },
  };

  let inputNode = {};
  switch (inputType) {
    case PRODUCT_INPUT_TYPE.CURRENCY:
      inputNode = (
        <FormInput
          {...formFields.currencyField}
          formItemOptions={{
            ...formFields.currencyField.formItemOptions,
            name: dataIndex,
          }}
        />
      );
      break;
    case PRODUCT_INPUT_TYPE.NUMBER:
      inputNode = (
        <FormInput
          {...formFields.numberField}
          formItemOptions={{
            ...formFields.numberField.formItemOptions,
            name: dataIndex,
          }}
        />
      );
      break;
    case PRODUCT_INPUT_TYPE.VAT:
      inputNode = <FormInput {...formFields.vatField} />;
      break;
    case PRODUCT_INPUT_TYPE.VAT_PERCENT:
      inputNode = (
        <FormInput
          {...formFields.vatPercent}
          formItemOptions={{
            ...formFields.vatPercent.formItemOptions,
            name: dataIndex,
          }}
        />
      );
      break;
    case PRODUCT_INPUT_TYPE.VAT_VALUE:
      inputNode = (
        <FormInput
          {...formFields.currencyField}
          formItemOptions={{
            ...formFields.currencyField.formItemOptions,
            name: dataIndex,
          }}
        />
      );
      break;
    case PRODUCT_INPUT_TYPE.NOTE:
      inputNode = (
        <FormInput
          {...formFields.noteField}
          formItemOptions={{
            ...formFields.noteField.formItemOptions,
            name: dataIndex,
          }}
        />
      );
      break;
    default:
      inputNode = (
        <Form.Item>
          <Input />
        </Form.Item>
      );
  }
  return <td {...restProps}>{editing ? inputNode : children}</td>;
};

export default EditableCell;
