import { Col, Input, InputNumber, Row, Select, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useSizeInput from "./useSizeInput";

const SizeInput = ({ value = {}, onChange, disabledInput }) => {
  const { t } = useTranslation();

  const { groupValue, onNumberChange, onUomChange, sizeOptions } = useSizeInput(value, onChange);

  return (
    <Input.Group>
      <Row gutter={5}>
        <Col span={3}>
          <InputNumber
            placeholder={t("product.productForm.lengthPlaceholder")}
            value={value.length || groupValue.length}
            onChange={(e) => onNumberChange(e, "length")}
            max={9999}
            disabled={disabledInput}
          />
        </Col>
        <Col span={0.5}>
          <Typography.Text
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            X
          </Typography.Text>
        </Col>
        <Col span={3}>
          <InputNumber
            placeholder={t("product.productForm.widthPlaceholder")}
            value={value.width || groupValue.width}
            onChange={(e) => onNumberChange(e, "width")}
            disabled={disabledInput}
            max={9999}
          />
        </Col>
        <Col span={0.5}>
          <Typography.Text
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            X
          </Typography.Text>
        </Col>
        <Col span={3}>
          <InputNumber
            placeholder={t("product.productForm.heightPlaceholder")}
            value={value.height || groupValue.height}
            onChange={(e) => onNumberChange(e, "height")}
            disabled={disabledInput}
            max={9999}
          />
        </Col>
        <Col span={4}>
          <Select
            options={sizeOptions}
            value={value.unit || groupValue.unit}
            onChange={onUomChange}
            disabled={disabledInput}
          />
        </Col>
      </Row>
    </Input.Group>
  );
};

export default SizeInput;
