import React, { useEffect, useState } from "react";
import { Space, Typography } from "antd";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { warrantyRequestOptions } from "features/Warranty/constant";
import useDebounce from "hooks/useDebounce";
import { useGetStaff, useGetWarrantyRequest } from "hooks/warranty";
import { t } from "i18next";
import { isNil, omit } from "lodash";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import { FilterLoadMore } from "components/CustomFilterMenu";
import Percent from "components/Percent";
import { renderPercentBar, totalItemStatusMethod, warrantyRequestStatusLabel } from "features/WarrantyRequest/constant";
import { getPercentBar } from "features/WarrantyRequest/helperFuncs";
import WarrantyRequestDetailLink from "components/CustomRedirectLink/WarrantyRequestDetailLink";
import { formatDateTime } from "utils/dateTime";
import CustomTags from "components/CustomTags";
import TextAlignLeft from "components/TextAlignLeft";
import { SvgIcon } from "assets/icons";
import "./index.scss";
import { useGetBranchQuery } from "hooks/branch";

const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

const GET_QUERY = getQuery();

const useAccordingWarrantyRequest = () => {
  const [params, setParams] = useState({
    activeKey: "accordingWarrantyRequest",
    filters: {
      query: GET_QUERY.query || undefined,
      status: GET_QUERY.status || [],
      // ...((has(GET_QUERY, 'from') || has(GET_QUERY, 'to')) && {
      //   totalItem: { from: GET_QUERY?.from, to: GET_QUERY?.to },
      // }),
      createdByID: GET_QUERY.createdByID || undefined,
    },
    pagination: { offset: GET_QUERY.offset || 0, limit: GET_QUERY.limit || 10 },
    sort: [],
  });
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: valueFormSelectBrandId } = useGetBranchQuery();
  const [branchID, setbranchID] = useState(null);
  valueFormSelectBrandId?.unshift({ value: null, label: t("warehouse.recommendImport.allBranch") });

  const [searchTerm, setSearchTerm] = useState(undefined);
  const debounceValue = useDebounce(searchTerm);
  const { data, paginationData, loading: loadingWarrantyRequest } = useGetWarrantyRequest(omit(params, "activeKey"));
  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);
  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sort: sorter?.order ? [{ by: "CREATED_AT", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...omit(newParams?.filters, ["totalItem"]),
        ...newParams?.filters?.totalItem,
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
      },
    });
  };

  const handleChangeInput = (e) => setSearchTerm(e.target.value);

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...omit(newParams?.filters, ["totalItem"]),
        ...((!isNil(value?.totalItem?.from) || !isNil(value?.totalItem?.to)) && newParams?.filters?.totalItem),
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
      },
    });
  };

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSort = (by) => {
    if (params.sort[0]?.sortType === "ASC") {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "ASC" }],
      };
      setParams(newParams);
    }
  };

  const columns = [
    {
      title: (
        <Typography.Text strong>{t("warranty.warrantyRequestList.indexAccordingWarrantyRequest")}</Typography.Text>
      ),
      align: "center",
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("CREATED_AT")}>
          {t("warranty.warrantyRequestList.createdAtAccordingWarrantyRequest")}{" "}
          <SvgIcon.SortIcon className={params.sort[0]?.sortType} />
        </Typography.Text>
      ),
      align: "center",
      dataIndex: "createdAt",
      width: 150,
      render: (_, record) => formatDateTime(record?.createdAt),
    },
    {
      title: (
        <Typography.Text strong>
          {t("warranty.warrantyRequestList.warrantyRequestCodeAccordingWarrantyRequest")}
        </Typography.Text>
      ),
      align: "center",
      render: (text, record, index) => <TextAlignLeft value={<WarrantyRequestDetailLink warrantyRequest={record} />} />,
      width: 200,
    },
    {
      title: (
        <Typography.Text strong>
          {t("warranty.warrantyRequestList.customerRequestAccordingWarrantyRequest")}
        </Typography.Text>
      ),
      align: "center",
      render: (text, record, index) => <TextAlignLeft value={record?.warrantyCustomerName} />,
    },
    {
      title: (
        <Typography.Text strong>
          {t("warranty.warrantyRequestList.phoneNumberAccordingWarrantyRequest")}
        </Typography.Text>
      ),
      align: "center",
      render: (text, record, index) => <Typography.Text>{record?.warrantyCustomerPhone}</Typography.Text>,
      width: 200,
    },
    {
      title: (
        <Typography.Text strong>{t("warranty.warrantyRequestList.serialIMEIAccordingWarrantyRequest")}</Typography.Text>
      ),
      align: "center",
      // filterDropdown: <FilterFromTo onFilter={(value) => handleFilter({ totalItem: value })} />,
      render: (text, record, index) => <Typography.Text>{record?.totalItems}</Typography.Text>,
      width: 120,
    },
    {
      title: (
        <Typography.Text strong>{t("warranty.warrantyRequestList.staffAccordingWarrantyRequest")}</Typography.Text>
      ),
      align: "center",
      filterDropdown: (
        <FilterLoadMore
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => onFilter("createdByID", value)}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
      render: (text, record, index) => <Typography.Text>{record?.createdBy?.fullname}</Typography.Text>,
      width: 300,
    },
    {
      title: (
        <Typography.Text strong>{t("warranty.warrantyRequestList.statusAccordingWarrantyRequest")}</Typography.Text>
      ),
      align: "center",
      width: 200,
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ status: value })}
          type="checkbox"
          options={warrantyRequestOptions}
        />
      ),
      render: (text, record, index) => {
        const { canceled, completed, process, waitingForProcessing } = getPercentBar(record?.totalItemsByStatus);
        return (
          <Space style={{ background: "transparent", flexDirection: "column" }}>
            <Typography.Text>{warrantyRequestStatusLabel?.[record?.status]}</Typography.Text>
            <Percent
              percentBars={renderPercentBar(totalItemStatusMethod, canceled, completed, process, waitingForProcessing)}
            />
          </Space>
        );
      },
    },
  ];

  const handleCloseStatusTag = () => {
    const newParams = {
      ...params,
      pagination: {
        offset: 0,
        limit: 10,
      },
      filters: {
        ...params.filters,
        status: [],
      },
    };

    setParams(newParams);
    buildQueryString({
      params: {
        ...omit(newParams?.filters, ["totalItem"]),
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
      },
    });
  };

  const handleCloseRangerTag = () => {
    const newParams = {
      ...params,
      pagination: {
        offset: 0,
        limit: 10,
      },
      filters: omit(params.filters, "totalItem"),
    };

    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
      },
    });
  };

  const tags = [
    {
      title: (
        <Typography.Text strong>{t("warranty.warrantyRequestList.staffAccordingWarrantyRequest")}</Typography.Text>
      ),
      options: staffs,
      selected: params?.filters?.createdByID,
      key: "createdByID",
    },
  ];

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  const handleOnClose = (key) => {
    switch (key) {
      case "createdByID":
        onChangeParams && onChangeParams({ [`${key}`]: null });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
    }
  };
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result?.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  const onChangeBranchesId = (e) => {
    setbranchID(e);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        branchIDs: e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
        key: newParams?.key,
        branchIDs: newParams?.branchID,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debounceValue)) {
      const newParams = {
        ...params,
        filters: { ...params.filters, query: debounceValue },
        pagination: { offset: 0, limit: 10 },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...omit(newParams?.filters, ["totalItem"]),
          ...newParams?.filters?.totalItem,
          ...newParams?.pagination,
          activeKey: newParams.activeKey,
        },
      });
    }
  }, [debounceValue]);

  return {
    columns,
    data,
    handleChangeTable,
    paginationData,
    handleChangeInput,
    loadingWarrantyRequest,
    handleCloseStatusTag,
    handleCloseRangerTag,
    renderTags,
    valueFormSelectBrandId,
    branchID,
    onChangeBranchesId,
  };
};

export default useAccordingWarrantyRequest;
