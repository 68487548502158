import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import CustomTable from "components/CustomTable";
import { VALUE_TAGS_IMPORT_NOTE_TYPE } from "features/Serial/constant";
import { t } from "i18next";
import { FiltersTag } from "../../../components/FiltersTag";
import TablesList from "../Tables/TablesList";
import useList from "./useList";

function ListBySerial() {
  const {
    onChangeBranchIDs,
    valueFormSelectCity,
    loading,
    paginationData,
    data,
    onSearchBySerial,
    handleChangeTable,
    branchIDs,
    handleRemoveFilterTag,
    staffs,
    paramsListBySerial,
    warehouseOptions,
    handlePrevNextPage,
    handleFilter,
    propsFilterCreated,
    handleChangeDate,
  } = useList();

  const { BY_SERIAL_COLUMN } = TablesList({ params: paramsListBySerial, handleFilter, propsFilterCreated });

  return (
    <div className="list-by-serial">
      <Form name="basic" autoComplete="off" style={{ display: "flex", gap: 8 }}>
        <FilterDate title={"Ngày tạo"} onChangeDate={handleChangeDate} />
        <div style={{ width: "100%" }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Input
              placeholder={"Tìm kiếm theo số serial/ IMEI"}
              onChange={onSearchBySerial}
              prefix={<SearchOutlined />}
              style={{ maxWidth: 500 }}
              allowClear
            />
          </Form.Item>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Select options={valueFormSelectCity} onChange={(e) => onChangeBranchIDs(e)} value={branchIDs} />
          </Form.Item>
        </div>
      </Form>
      <FiltersTag
        staffOptions={staffs}
        params={paramsListBySerial}
        importNotTypeOptions={VALUE_TAGS_IMPORT_NOTE_TYPE}
        handleRemoveFilterTag={handleRemoveFilterTag}
        warehouseOptions={warehouseOptions}
      />
      <CustomTable
        loading={loading}
        columns={BY_SERIAL_COLUMN}
        dataSource={data}
        rowKey={(record) => record?.id}
        onChange={handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListBySerial;
