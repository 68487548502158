import { Form } from "antd";
import { PAYMENT_TERMS } from "features/Purchase/constant";
import { useSplitPurchaseOrder } from "hooks/purchase";
import moment from "moment";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";

export const calcVatMoney = (beforeVatPrice, vatPercent, quantity) => {
  return (beforeVatPrice / (1 + (vatPercent || 0) / 100)) * quantity * (vatPercent / 100);
};

export const calcIntoMoney = (beforeVatPrice, vatPercent, quantity) => {
  return (beforeVatPrice / (1 + (vatPercent || 0) / 100)) * quantity;
};

export const calcBeforeVatPrice = (afterVatPrice, vatPercent) => {
  return afterVatPrice / (1 + (vatPercent || 0) / 100);
};

export const conditionQuantity = (receivedQuantity, notQualifiedQuantity) => {
  return receivedQuantity - notQualifiedQuantity;
};

function useButtonSplitOrder({ record }) {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const [current, setCurrent] = useState(0);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = async () => {
    await form.resetFields();
    setVisible(false);
  };

  const handleCancelConfirm = () => {
    setVisibleConfirm(false);
  };
  const handleNextStep = async () => {
    await form.validateFields();
    setCurrent(current + 1);
  };
  const handlePrevStep = () => {
    setCurrent(current - 1);
  };

  const initialValues = {
    remainPurchaseOrder: {
      items: [],
    },
    extraPurchaseOrder: {
      items: [],
    },
  };

  const handleValuesChange = (changedValue, allValues) => {
    const formValues = form.getFieldsValue();

    var totalPayment = allValues?.remainPurchaseOrder?.items?.reduce(function (total, current) {
      return total + (current?.afterVatPrice * current?.conditionQuantity || 0);
    }, 0);

    const paymentDepositAmountRemain = record?.paymentDepositAmount;

    const isEditingRemainPurchaseOrder = changedValue?.["remainPurchaseOrder"] !== undefined;

    const updatedExtraItems = allValues?.extraPurchaseOrder?.items?.map((item) => {
      const correspondingRemainItem = allValues?.remainPurchaseOrder?.items?.find(
        (remainItem) => remainItem.id === item.id
      );
      const conditionQuantityExtra =
        isEditingRemainPurchaseOrder && correspondingRemainItem
          ? item.quantity - correspondingRemainItem.conditionQuantity > 0
            ? item.quantity - correspondingRemainItem.conditionQuantity
            : 0
          : item.conditionQuantity;
      return {
        ...item,
        conditionQuantity: conditionQuantityExtra,
        beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item?.vatPercent) || 0,
        intoMoney: calcIntoMoney(item?.afterVatPrice, item?.vatPercent, conditionQuantityExtra) || 0,
        vatMoney: calcVatMoney(item?.afterVatPrice, item?.vatPercent, conditionQuantityExtra) || 0,
      };
    });

    const updatedRemainItems = allValues?.remainPurchaseOrder?.items?.map((item) => {
      const correspondingExtraItem = allValues?.extraPurchaseOrder?.items?.find(
        (extraItem) => extraItem.id === item.id
      );
      const conditionQuantityRemain =
        !isEditingRemainPurchaseOrder && correspondingExtraItem
          ? item.quantity - correspondingExtraItem.conditionQuantity > 0
            ? item.quantity - correspondingExtraItem.conditionQuantity
            : 0
          : item.conditionQuantity;
      return {
        ...item,
        conditionQuantity: conditionQuantityRemain,
        beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item?.vatPercent) || 0,
        intoMoney: calcIntoMoney(item?.afterVatPrice, item?.vatPercent, conditionQuantityRemain) || 0,
        vatMoney: calcVatMoney(item?.afterVatPrice, item?.vatPercent, conditionQuantityRemain) || 0,
      };
    });

    form.setFieldsValue({
      ...formValues,
      remainPurchaseOrder: {
        ...allValues?.remainPurchaseOrder,
        paymentDepositAmount: totalPayment < paymentDepositAmountRemain ? totalPayment : paymentDepositAmountRemain,
        items: updatedRemainItems,
      },
      extraPurchaseOrder: {
        ...allValues?.extraPurchaseOrder,
        paymentDepositAmount: totalPayment < paymentDepositAmountRemain ? paymentDepositAmountRemain - totalPayment : 0,
        items: updatedExtraItems,
      },
    });
  };

  const { handleSplitPurchaseOrder, loading } = useSplitPurchaseOrder();

  const onSplitPurchaseOrder = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        id: record?.id,
        remainPurchaseOrder: {
          orderType: record?.orderType,
          paymentMethods: values?.remainPurchaseOrder?.paymentMethods,
          paymentTerms: values?.remainPurchaseOrder?.paymentTerms,
          transferNote: values?.remainPurchaseOrder?.transferNote,
          paymentDepositAmount: values?.remainPurchaseOrder?.paymentDepositAmount,
          paymentOnDeliveryAmount: values?.remainPurchaseOrder?.paymentOnDeliveryAmount,
          paymentDebtAmount: values?.remainPurchaseOrder?.paymentDebtAmount,
          items: values?.remainPurchaseOrder?.items?.map((item) => ({
            productID: item?.product?.id,
            quantity: item.conditionQuantity,
            afterDiscountPrice: item.afterDiscountPrice,
            afterVatPrice: item.afterVatPrice,
            vatPercent: item.vatPercent,
            currency: item.currency,
            warranty: item.warranty,
            warrantyPeriod: item.warrantyPeriod,
            creditDays: item.creditDays,
            expectedDeliveryTime: item.expectedDeliveryTime,
          })),
        },
        extraPurchaseOrder: {
          orderType: record?.orderType,
          paymentMethods: values?.extraPurchaseOrder?.paymentMethods,
          paymentTerms: values?.extraPurchaseOrder?.paymentTerms,
          transferNote: values?.extraPurchaseOrder?.transferNote,
          paymentDepositAmount: values?.extraPurchaseOrder?.paymentDepositAmount,
          paymentOnDeliveryAmount: values?.extraPurchaseOrder?.paymentOnDeliveryAmount,
          paymentDebtAmount: values?.extraPurchaseOrder?.paymentDebtAmount,
          items: values?.extraPurchaseOrder?.items?.map((item) => ({
            productID: item?.product?.id,
            quantity: item.conditionQuantity,
            afterDiscountPrice: item.afterDiscountPrice,
            afterVatPrice: item.afterVatPrice,
            vatPercent: item.vatPercent,
            currency: item.currency,
            warranty: item.warranty,
            warrantyPeriod: item.warrantyPeriod,
            creditDays: item.creditDays,
            expectedDeliveryTime: item.expectedDeliveryTime,
          })),
        },
      };
      await handleSplitPurchaseOrder(data)
        .then(() => {
          setVisible(false);
          setVisibleConfirm(false);
          notify.success({
            message: "Tách đơn mua hàng thành công",
          });
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const formData = form.getFieldsValue();

    var calcIntoMoneyRemain = (value) => {
      return (
        calcIntoMoney(
          value?.afterVatPrice,
          value?.vatPercent,
          conditionQuantity(value.receivedQuantity, value?.notQualifiedQuantity)
        ) || 0
      );
    };

    var calcVatMoneyRemain = (value) => {
      return (
        calcVatMoney(
          value?.afterVatPrice,
          value?.vatPercent,
          conditionQuantity(value.receivedQuantity, value?.notQualifiedQuantity)
        ) || 0
      );
    };

    var totalMoneyRemain = record?.items
      ?.filter((item) => conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity) > 0)
      ?.reduce(function (total, current) {
        return total + (calcIntoMoneyRemain(current) + calcVatMoneyRemain(current));
      }, 0);

    form.setFieldsValue({
      ...formData,
      remainPurchaseOrder: {
        ...formData?.remainPurchaseOrder,
        paymentMethods: record?.paymentMethods,
        transferNote: record?.transferNote,
        paymentTerms: record?.paymentTerms?.filter((item) => item === PAYMENT_TERMS.ADVANCE_PAYMENT),
        paymentDepositAmount:
          totalMoneyRemain < record?.paymentDepositAmount ? totalMoneyRemain : record?.paymentDepositAmount,
        paymentOnDeliveryAmount: 0,
        paymentDebtAmount: 0,
        items: record?.items
          ?.filter((item) => conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity) > 0)
          ?.map((item) => ({
            ...item,
            conditionQuantity: conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity),
            beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item?.vatPercent) || 0,
            intoMoney: calcIntoMoneyRemain(item),
            vatMoney: calcVatMoneyRemain(item),
            expectedDeliveryTime: item?.expectedDeliveryTime && moment(item?.expectedDeliveryTime),
            isRemainPurchaseOrder: true,
          })),
      },
      extraPurchaseOrder: {
        ...formData?.extraPurchaseOrder,
        paymentMethods: record?.paymentMethods,
        transferNote: record?.transferNote,
        paymentTerms: record?.paymentTerms?.filter((item) => item === PAYMENT_TERMS.ADVANCE_PAYMENT),
        paymentDepositAmount:
          totalMoneyRemain < record?.paymentDepositAmount ? record?.paymentDepositAmount - totalMoneyRemain : 0,
        paymentOnDeliveryAmount: 0,
        paymentDebtAmount: 0,
        items: record?.items
          ?.filter((item) => conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity) - item.quantity < 0)
          ?.map((item) => ({
            ...item,
            conditionQuantity: item?.quantity - conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity),
            beforeVatPrice: calcBeforeVatPrice(item?.afterVatPrice, item?.vatPercent) || 0,
            intoMoney:
              calcIntoMoney(
                item?.afterVatPrice,
                item?.vatPercent,
                item?.quantity - conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity)
              ) || 0,
            vatMoney:
              calcVatMoney(
                item?.afterVatPrice,
                item?.vatPercent,
                item?.quantity - conditionQuantity(item.receivedQuantity, item?.notQualifiedQuantity)
              ) || 0,
            expectedDeliveryTime: item?.expectedDeliveryTime && moment(item?.expectedDeliveryTime),
            isRemainPurchaseOrder: false,
          })),
      },
    });
  }, [form, record, visible]);

  return {
    form,
    visible,
    showModal,
    handleCancel,
    handleNextStep,
    handlePrevStep,
    current,
    initialValues,
    handleValuesChange,
    onSplitPurchaseOrder,
    loading,
    visibleConfirm,
    setVisibleConfirm,
    handleCancelConfirm,
  };
}

export default useButtonSplitOrder;
