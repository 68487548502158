import React, { useState } from "react";
import { Image } from "antd";
import "./index.scss";

function CustomListFileImage({ listImage, size = 100 }) {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      {listImage?.length > 0 && (
        <div className="custom-list-file-image">
          {listImage?.length > 1 ? (
            <div className="list-image">
              <div className="show-image">
                <Image
                  preview={{
                    visible: false,
                  }}
                  width={size}
                  height={size}
                  src={listImage?.[0]}
                  onClick={() => setVisible(true)}
                />
                <div className="show-more">+{listImage?.length - 1}</div>
              </div>

              <div
                style={{
                  display: "none",
                }}
              >
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {listImage?.map((item, index) => (
                    <span style={{ marginRight: "15px", opacity: "0.7" }}>
                      <Image key={index} width={size} height={size} src={item} className="image-item" />
                    </span>
                  ))}
                </Image.PreviewGroup>
              </div>
            </div>
          ) : (
            <Image width={size} height={size} src={listImage?.[0]} />
          )}
        </div>
      )}
    </div>
  );
}

export default CustomListFileImage;
