import { Dropdown, Menu, message, Popconfirm, Space, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import CustomSelectDate from "features/Serial/components/CustomSelectDate";
import CustomTitle from "features/Serial/components/CustomTitle";
import { onSaleUnitOptions } from "features/Serial/constant";
import { t } from "i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

import TransferSerialInputTag from "../AffixButton/TransferSerialInputTag";
import styles from "./index.module.scss";

export const useTable = ({
  form,
  remove,
  handleRemoveProduct,
  tags,
  handleGetTags,
  sumSerialByLack,
  sumSerialByOrder,
  sumSerialByScanned,
  handleClearAllSerials,
  warehouseInfo,
}) => {
  const [visible, setVisible] = useState(null);

  const renderLack = ({ quantity, scanned }) => {
    if (scanned?.length) {
      return quantity - scanned?.length;
    } else {
      return quantity || 0;
    }
  };

  const renderSubTotal = (record) => {
    if (record.quantity < record?.serialItems?.length) {
      return (
        <Typography.Text style={{ color: "red" }}>
          {t("serialIE.productInfo.residual")}: {record?.serialItems?.length - record?.quantity}
        </Typography.Text>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>;
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <Typography.Text className={styles["sub-title"]}>
          {t("common.lack")}: {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
        </Typography.Text>
      );
    }
  };

  const copyToClipboard = (record) => {
    if (record?.serialItems?.length > 0) {
      const textToCopy = record?.serialItems.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link to={`/product/detail/${record?.id}`} style={{ textAlign: "start", margin: 0 }}>
              <Typography.Link strong>{record?.code}</Typography.Link>
            </Link>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ textAlign: "start", margin: 0 }} strong>
              {record?.name}
            </Typography.Text>
            <Typography.Text style={{ textAlign: "end", margin: 0 }} italic>
              {record?.uom?.name}
            </Typography.Text>
          </div>
        );
      },
      isHidden: false,
      width: 250,
    },
    {
      align: "center",
      title: <CustomTitle title={"Thời hạn bảo hành"} />,
      render: (_, record, index) => <CustomSelectDate options={onSaleUnitOptions} index={index} />,
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.byOrder")} />,
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.CURRENCY_INPUT,
            formItemOptions: {
              name: [index, "quantity"],
              rules: [
                {
                  required: true,
                  message: t("serialIE.error.serialItems"),
                },
                {
                  pattern: REGEX.NUMBER,
                  message: t("serialIE.error.serialItems"),
                },
              ],
            },
            inputOptions: {
              maxLength: 6,
              min: 0,
            },
          }}
        />
      ),
      width: 120,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={"(Thực tế)"} />,
      width: 120,
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Typography.Text className={styles["title"]}>{record?.serialItems?.length || 0}</Typography.Text>
            {renderSubTotal(record)}
          </Space>
        );
      },
    },
    {
      align: "center",
      title: (
        <CustomTitle
          title={t("serialIE.productInfo.detailSerialB")}
          subTitle={
            <Typography.Text style={{ color: "#ef9351", fontSize: "13px" }}>
              {t("serialIE.productInfo.note")}
            </Typography.Text>
          }
        />
      ),
      render: (_, record, index) => {
        return (
          <div>
            <TransferSerialInputTag
              tagsIndex={record?.serialItems}
              tags={tags}
              handleGetTags={handleGetTags}
              index={index}
              sumSerialByOrder={sumSerialByOrder}
              sumSerialByScanned={sumSerialByScanned}
              sumSerialByLack={sumSerialByLack}
              record={record}
              form={form}
              setVisible={setVisible}
              visible={visible}
              handleClearAllSerials={handleClearAllSerials}
              warehouseInfo={warehouseInfo}
            />
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("campaign.actions")} />,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => setVisible(index)}
                  >
                    <SvgIcon.IconViewList />
                    <Typography.Text>{t("serialIE.viewAll")}</Typography.Text>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => copyToClipboard(record)}
                  >
                    <SvgIcon.IconCoppy />
                    <Typography.Text>{t("serialIE.copy")}</Typography.Text>
                  </div>
                </Menu.Item>
                <Popconfirm
                  title={
                    <Typography.Text>
                      {t("cart.confirmDeleteItem")}
                      <br />
                      <Typography.Link strong>{record?.product?.name}</Typography.Link> ?
                    </Typography.Text>
                  }
                  onConfirm={() => {
                    handleRemoveProduct(record?.id);
                    remove(index);
                  }}
                >
                  <Menu.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                      }}
                    >
                      <SvgIcon.IconDelete />
                      <Typography.Text>{t("common.Delete")}</Typography.Text>
                    </div>
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
            className="button-action-recommend button-offers-recommend"
          >
            <SvgIcon.IconMore style={{ fontSize: "16px" }} />
          </Dropdown>
        );
      },
      width: 70,
    },
  ];
  return { columns };
};
