import { Col, Row, Input, Tag, Tooltip, Typography } from "antd";
import {
  PlusCircleOutlined,
  CopyOutlined,
  SearchOutlined,
  EyeOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import CustomModal from "components/CustomModal";
import styles from "../../index.module.scss";
import { PRODUCT_TYPE } from "features/Products/constants";
import { SERIAL_AVAILABILITY } from "config/constants";
import { useEffect, useState } from "react";
import { useGetSerial } from "hooks/serial/query";
import { findDuplicateObjects } from "utils/helperFuncs";
import { Empty } from "antd";
import classnames from "classnames";
import { handleCopy } from "./utils";
import { useGetShowPassword } from "hooks/keyManagement/query";

export default function ModalCreateKey(props) {
  const { t, orderItem, productId2Serials, setProductId2Serials } = props;
  const productType = orderItem?.product?.productType;
  const lengthCurrentSerial = parseInt(productId2Serials?.[orderItem?.productID]?.length);
  const [currentQuantity, setCurrentQuantity] = useState(productId2Serials?.[orderItem?.productID]?.length ?? 0);
  const [serialsAdded, setSerialsAdded] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [params, setParams] = useState({
    pagination: {
      skipTotalCount: true,
    },
    filter: {
      queries: [""],
      productIDs: [orderItem?.productID],
      statuses: [SERIAL_AVAILABILITY.AVAILABLE],
      serialTypes: [productType],
      allowQueryAccurate: true,
    },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const { data: serialsAvailable, loading: loadingSerialsAvailable } = useGetSerial(params);

  const handleSearch = () => {
    let input = searchInput.split(/[, ]+/);
    setParams({
      ...params,
      filter: {
        ...params.filter,
        queries: input,
      },
      skip: false,
    });
    setSearchInput("");
  };

  const handleAddKey = () => {
    let currentSerials = productId2Serials?.[orderItem?.productID];

    if (currentSerials?.length > 0 && findDuplicateObjects(currentSerials, serialsAdded)?.length > 0) {
      return;
    }

    setProductId2Serials({
      ...productId2Serials,
      [orderItem?.productID]: currentSerials?.concat(...serialsAdded) || [...serialsAdded],
    });

    setSerialsAdded([]);
  };

  const handleDeleteKey = (serial) => {
    let filterSerial = serialsAdded?.filter((item) => item.serialID !== serial.serialID);
    setSerialsAdded(filterSerial);
    setCurrentQuantity(parseInt(productId2Serials?.[orderItem?.productID]?.length + filterSerial?.length));
  };

  const handleCancel = () => {
    setSerialsAdded([]);
    setSearchInput("");
    setCurrentQuantity(lengthCurrentSerial);
  };

  useEffect(() => {
    setCurrentQuantity(lengthCurrentSerial);
  }, [orderItem?.productID, productId2Serials]);

  useEffect(() => {
    if (loadingSerialsAvailable === false) {
      if (serialsAvailable?.length > 0) {
        let currentSerials = productId2Serials?.[orderItem?.productID]?.map((item) => ({
            serialID: item?.serialID,
            serialCode: item?.serialCode,
          })),
          convertSerialAvailable = serialsAvailable?.map((item) => ({
            serialID: item?.id,
            serialCode: item?.code,
          }));

        if (findDuplicateObjects(currentSerials, convertSerialAvailable).length > 0) {
          return;
        }

        if (findDuplicateObjects(serialsAdded, convertSerialAvailable).length > 0) {
          return;
        }

        let concatSerial = serialsAdded?.concat(convertSerialAvailable);
        setSerialsAdded(concatSerial);
        setCurrentQuantity(parseInt(productId2Serials?.[orderItem?.productID]?.length + concatSerial?.length));
      }
      setParams({
        ...params,
        filter: {
          ...params.filter,
          queries: [],
        },
        skip: true,
      });
    }
  }, [serialsAvailable]);

  return (
    <CustomModal
      {...{
        customComponent: (
          <>
            <PlusCircleOutlined className={styles["icon-plus-circle-outlined"]} />{" "}
            {productType === PRODUCT_TYPE.KEY_LICENSE
              ? t("v2.keyManagement.exportNoteCreate.inputKeyLicense")
              : t("v2.keyManagement.exportNoteCreate.inputAccount")}
          </>
        ),
        title: (
          <Typography.Title level={4} strong>
            {t("v2.keyManagement.exportNoteCreate.modalCreate.heading")}
          </Typography.Title>
        ),
        width: `50%`,
        footer: false,
        centered: true,
        onOke: () => handleAddKey(orderItem?.productID, serialsAdded),
        onCancel: handleCancel,
      }}
    >
      <Info t={t} productType={productType} orderItem={orderItem} currentQuantity={currentQuantity} />

      <Action
        t={t}
        handleSearch={handleSearch}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        serialsAdded={serialsAdded}
      />

      <List serialsAdded={serialsAdded} productType={productType} handleDeleteKey={handleDeleteKey} />
    </CustomModal>
  );
}

function Info(props) {
  const { t, productType, orderItem, currentQuantity } = props;
  const currentQuantityOrZero = currentQuantity ?? 0;

  return (
    <Row gutter={[16, 8]} className={styles["pop-up-info"]}>
      <Col span={8}>
        <Typography.Text strong>{t("v2.keyManagement.keyOnlineType")}</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>
          {productType === PRODUCT_TYPE.KEY_LICENSE ? t("v2.keyManagement.keyLicense") : t("v2.keyManagement.account")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>{t("v2.keyManagement.productCode")}</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text className={styles["blue"]}>{orderItem?.productCode}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>{t("v2.keyManagement.productName")}</Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{orderItem?.productName}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>
          {t("v2.keyManagement.exportNoteCreate.modalCreate.label.quantityOrder")}
        </Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>{orderItem?.quantity}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text strong>
          {t("v2.keyManagement.exportNoteCreate.modalCreate.label.quantityCurrent")}
        </Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text>
          {currentQuantityOrZero}{" "}
          {currentQuantityOrZero < orderItem?.quantity ? (
            <span className={styles["red"]}>
              ({t("v2.keyManagement.exportNoteCreate.lack")}: {orderItem?.quantity - currentQuantityOrZero})
            </span>
          ) : currentQuantityOrZero > orderItem?.quantity ? (
            <span className={styles["red"]}>
              ({t("v2.keyManagement.exportNoteCreate.redundant")}: {currentQuantityOrZero - orderItem?.quantity})
            </span>
          ) : (
            <span className={styles["green"]}>({t("v2.keyManagement.exportNoteCreate.enough")})</span>
          )}
        </Typography.Text>
      </Col>
    </Row>
  );
}

function Action(props) {
  const { t, handleSearch, searchInput, setSearchInput, serialsAdded } = props;

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <Row gutter={[16, 8]} className={styles["pop-up-action"]}>
      <Col span={23} style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Input
          className={styles["input-search"]}
          addonBefore={<SearchOutlined onClick={handleSearch} />}
          placeholder={t("v2.keyManagement.inputSearch")}
          onPressEnter={handleSearch}
          onChange={handleInputChange}
          value={searchInput}
        />
      </Col>
      <Col span={1}>
        <Tooltip title="Copy">
          <CopyOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={() => handleCopy(t, serialsAdded)} />
        </Tooltip>
      </Col>
    </Row>
  );
}

function List(props) {
  const { serialsAdded, productType, handleDeleteKey } = props;

  return (
    <Row
      gutter={[16, 8]}
      style={{ gap: "10px" }}
      className={classnames(styles["pop-up-key-license-account"], {
        [styles["pop-up-content-center"]]: serialsAdded.length === 0,
      })}
    >
      {serialsAdded.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        serialsAdded?.map((serial) => {
          return productType === PRODUCT_TYPE.ACCOUNT ? (
            <TagAccount key={serial?.serialID} handleDeleteKey={handleDeleteKey} serial={serial} />
          ) : (
            <Tag key={serial?.serialID} className={[styles["input-tag"], styles["tag-key-license"]]}>
              {serial.serialCode}{" "}
              <CloseOutlined onClick={() => handleDeleteKey(serial)} className={styles["icon-close-outlined"]} />
            </Tag>
          );
        })
      )}
    </Row>
  );
}

function TagAccount(props) {
  const { serial, handleDeleteKey } = props;

  const { handleGetPassword } = useGetShowPassword();
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("**********");

  const handleShowPass = async (id) => {
    await handleGetPassword({ serialIDs: id }).then((res) => {
      setPassword(res?.data?.serial?.showPassword?.passwordBySerialID?.[0]?.password || "--");
    });
    setShowPass(true);
  };

  const handleHidePass = () => {
    setPassword("**********");
    setShowPass(false);
  };

  return (
    <Col span={12} style={{ padding: 0, flex: 1 }} key={`pop-up-serial-${serial.serialID}`}>
      <Tag className={styles["tag-account"]}>
        <CloseOutlined onClick={() => handleDeleteKey(serial)} className={styles["icon-close-outlined"]} />
        <span className={styles["gmail"]}>{serial.serialCode}</span>
        <span className={styles["password"]}>{password}</span>
        <EyeOutlined
          style={{ display: !showPass ? "none" : "block" }}
          className={styles["show-password"]}
          onClick={handleHidePass}
        />
        <EyeInvisibleOutlined
          style={{ display: showPass ? "none" : "block" }}
          className={styles["show-password"]}
          onClick={() => handleShowPass(serial.serialID)}
        />
      </Tag>
    </Col>
  );
}
