import { Affix, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Img from "assets/images";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";

import "./index.scss";

const ButtonGroup = ({
  loading,
  isSeller,
  onSave,
  onSaveAndAdd,
  isUpdate,
  data,
  onFinish,
  visible,
  handleOpenModalSave,
  handleCloseModalSave,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const fullNameDetail = data?.fullName;
  return (
    <Affix offsetBottom={10}>
      <div className="button-group-seller-action">
        <CustomModal
          {...{
            customComponent: <Button className="cancel-btn custom-button">{t("common.cancel")}</Button>,
            message: <Typography.Text>{t("supplier.onCancel")}</Typography.Text>,
            centered: true,
            footer: false,
            onOke: () => history.push(isUpdate ? `/supplier/detail/${data?.id}` : "/supplier-list"),
            icon: Img.QuestionIcon,
          }}
        />
        {isUpdate && isSeller !== data?.isSeller ? (
          <CustomModal
            {...{
              customComponent: <CreateButton onClick={onFinish} loading={loading} />,
              message: (
                <Typography.Text>
                  {isSeller === true
                    ? t("supplier.questUpdateOnisSeller", { supplierName: fullNameDetail })
                    : t("supplier.questUpdateOffisSeller", { supplierName: fullNameDetail })}
                </Typography.Text>
              ),
              centered: true,
              footer: false,
              onOke: onSave,
              icon: Img.QuestionIcon,
              buttonLoading: loading,
              visible: visible,
              onCancel: handleCloseModalSave,
            }}
          />
        ) : (
          <CreateButton onClick={onSave} loading={loading} />
        )}

        {!isUpdate && <CreateButton onClick={onSaveAndAdd} title={t("common.saveAndAdd")} loading={loading} />}
      </div>
    </Affix>
  );
};

export default ButtonGroup;
