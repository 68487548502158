import { CheckCircleFilled, ClockCircleTwoTone, CloseCircleFilled } from "@ant-design/icons";
import { Steps, Typography } from "antd";
import { Icon } from "assets/icons";
import { DATE_TIME_FORMAT } from "config/constants";
import { filter, isNull } from "lodash";
import { useContext, useRef, useState } from "react";
import { formatDateTime } from "utils/dateTime";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./index.scss";
import { useGetUserCurrent } from "hooks/auth/auth";

const { Step } = Steps;

const STEPS_STATE = {
  PAST: "PAST",
  CURRENT: "CURRENT",
  FUTURE: "FUTURE",
};

const STEPS_STATE_VALUE = {
  [`${STEPS_STATE.PAST}`]: "finish",
  [`${STEPS_STATE.CURRENT}`]: "process",
  [`${STEPS_STATE.FUTURE}`]: "wait",
  null: undefined,
};

const convertTimeDate = (value, format) => {
  if (value !== null) {
    return formatDateTime(value, format);
  } else {
    return;
  }
};

function StepsCustom({ dataSteps, rest, isArrow = true }) {
  const stepsContainer = useRef(null);

  const newDataSteps = filter(dataSteps, (el) => !isNull(el));

  const colorTitle = (name) => {
    const colors = {
      "Hoàn thành": "#00AB78",
      "Đã hủy": "#D2434D",
      "Đã từ chối": "#D2434D",
      default: "#052987",
    };
    return <Typography.Text style={{ color: colors[name] || colors.default }}>{name}</Typography.Text>;
  };

  const renderTitle = ({ state, name }) => {
    const titles = {
      [STEPS_STATE.PAST]: colorTitle(name),
      [STEPS_STATE.CURRENT]: <Typography.Text style={{ color: "#2246DC" }}>{name}</Typography.Text>,
      [STEPS_STATE.FUTURE]: <Typography.Text>{name}</Typography.Text>,
    };
    return titles[state];
  };

  const renderTime = (time) => (
    <Typography.Text className="time-zone">{convertTimeDate(time, DATE_TIME_FORMAT)}</Typography.Text>
  );
  const { userCurrentData } = useGetUserCurrent();
  const subTitle = (fullname, id) =>
    fullname && (
      <Typography.Text>
        Bởi: {fullname}
        {userCurrentData?.id === id && ` (tôi)`}
      </Typography.Text>
    );

  const renderIcons = ({ name, state }) => {
    const iconMap = {
      "Hoàn thành": { className: "success", icon: <CheckCircleFilled /> },
      "Đã hủy": { className: "cancel", icon: <CloseCircleFilled /> },
      "Đã từ chối": { className: "cancel", icon: <CloseCircleFilled /> },
      default: { className: "", icon: <CheckCircleFilled /> },
    };

    const { className, icon } = iconMap[name] || iconMap.default;
    const iconComponent = state === "CURRENT" ? <ClockCircleTwoTone /> : icon;
    const iconClass = state === "PAST" ? className : "";

    return <div className={iconClass}>{iconComponent}</div>;
  };

  const scroll = (direction) => {
    if (stepsContainer.current) {
      stepsContainer.current.scrollLeft += direction === "left" ? -300 : 300;
    }
  };

  return (
    <div className="steps-custom">
      {isArrow && (
        <>
          <div className="left-arrow" onClick={() => scroll("left")}>
            <Icon.CustomIcon icon={Icon.faChevronLeft} />
          </div>
          <div className="right-arrow" onClick={() => scroll("right")}>
            <Icon.CustomIcon icon={Icon.faChevronRight} />
          </div>
        </>
      )}
      <div ref={stepsContainer} className="list-steps-container">
        <Steps size="small" labelPlacement="vertical" {...rest}>
          {newDataSteps.map((data, index) => (
            <Step
              key={index}
              title={renderTitle(data)}
              status={STEPS_STATE_VALUE[data.state]}
              subTitle={subTitle(data.createdBy?.fullname, data.createdBy?.id)}
              description={renderTime(data.createdAt)}
              icon={renderIcons(data)}
            />
          ))}
        </Steps>
      </div>
    </div>
  );
}

export default StepsCustom;
