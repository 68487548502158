import { Col, Descriptions, Form, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { PURCHASE_REQUEST_TYPE, PURCHASE_TYPE_OPTIONS } from "features/Purchase/constant";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import AddInfo from "./AddInfo";
import ButtonGroup from "./ButtonGroup";
import PaymentInfo from "./PaymentInfo";
import ProductsListLoadMore from "./ProductsListLoadMore";
import SupplierInfo from "./SupplierInfo";
import usePurchaseEdit from "./usePurchaseEdit";

function PurchaseEdit() {
  const {
    form,
    handleFinish,
    initialValues,
    warehouseOptions,
    handleValuesChange,
    paymentTerms,
    handleGetProductList,
    productListSelected,
    dataDetail,
    loadingUpdatePurchase,
    onUpdatePurchaseOrder,
    setDeletedIDs,
    deletedIDs,
    dataReferences,
  } = usePurchaseEdit();
  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={"Cập nhật đơn mua hàng"}
            routes={[
              { path: "#", name: t("purchase.titleMenu") },
              { path: "/purchase-order", name: t("purchase.purchaseOrder") },
              { path: "/", name: "Cập nhật đơn mua hàng" },
            ]}
          />
        </Col>

        <Col span={24}>
          <Descriptions colon={false}>
            <Descriptions.Item label={<Typography.Text strong>{t("purchase.reference")}</Typography.Text>}>
              <Typography.Link strong>
                {(dataReferences || [])?.map((item, index) => (
                  <React.Fragment key={index}>
                    <div>
                      {item.serialImportNotesCode ? (
                        <Link to={`/import-serial/detail/${item?.serialImportNotesId}`} target="_blank">
                          <Typography.Link strong>{item?.serialImportNotesCode || "??"}</Typography.Link>
                        </Link>
                      ) : (
                        <Link to={`/import-request/detail/${item?.importRequestId}`} target="_blank">
                          <Typography.Link strong>{item?.importRequestCode || "??"}</Typography.Link>
                        </Link>
                      )}
                      {index >= 0 && index < dataReferences?.length - 1 && `,`}
                    </div>
                  </React.Fragment>
                ))}
              </Typography.Link>
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={24}>
          <Row gutter={[16]}>
            <Col span={10}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("purchase.recommendedType")}</Typography.Text>,
                  name: "requestType",
                }}
                inputOptions={{
                  options: PURCHASE_TYPE_OPTIONS,
                  defaultValue: PURCHASE_REQUEST_TYPE.DOMESTIC,
                }}
              />
            </Col>
            <Col span={8}></Col>
            <Col span={6}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("purchase.importWarehouse")}</Typography.Text>,
                  name: "importWarehouseID",
                  rules: [{ required: true, message: t("purchase.rules.selectWarehouseImport") }],
                }}
                inputOptions={{
                  placeholder: t("purchase.placeHolder.selectWarehouseImport"),
                  options: warehouseOptions,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ProductsListLoadMore
            form={form}
            onGetProductList={handleGetProductList}
            productListSelected={productListSelected}
            setDeletedIDs={setDeletedIDs}
            deletedIDs={deletedIDs}
            dataDetail={dataDetail}
          />
        </Col>
        <Col span={24}>
          <PaymentInfo paymentTerms={paymentTerms} />
        </Col>
        <Col span={12}>
          <SupplierInfo dataDetail={dataDetail} />
        </Col>
        <Col span={12}>
          <AddInfo />
        </Col>
        <Col span={24}>
          <ButtonGroup loading={loadingUpdatePurchase} onUpdatePurchaseOrder={onUpdatePurchaseOrder} />
        </Col>
      </Row>
    </Form>
  );
}

export default PurchaseEdit;
