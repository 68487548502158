import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CreateButton = ({ title, loading, type = "primary", ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button className="custom-button" type={type} loading={loading} {...rest}>
      {title || t("common.save")}
    </Button>
  );
};

export default CreateButton;
