import { t } from "i18next";

export const IS_ACTIVES = {
  ACTIVE: true,
  DISABLED: false,
};

export const IS_ACTIVES_TAG = {
  ACTIVE: t("supplier.collaborating"),
  DISABLED: t("supplier.unCollab"),
};

export const IS_CUSTOMER_TAG = {
  ACTIVE: t("supplier.yes"),
  DISABLED: t("supplier.no"),
};

export const statusOptions = [
  { value: true, label: t("supplier.collaborating") },
  { value: false, label: t("supplier.unCollab") },
];

export const SUPPLIER_TYPE = {
  COMPANY: "COMPANY",
  PERSONAL: "PERSONAL",
};

export const supplierTypeOptions = [
  { value: SUPPLIER_TYPE.PERSONAL, label: t("supplier.personal") },
  { value: SUPPLIER_TYPE.COMPANY, label: t("supplier.company") },
];

export const supplierTypeLabel = {
  [SUPPLIER_TYPE.PERSONAL]: t("supplier.personal"),
  [SUPPLIER_TYPE.COMPANY]: t("supplier.company"),
  null: "--",
};

export const isCustomerOptions = [
  { value: true, label: t("supplier.yes") },
  { value: false, label: t("supplier.no") },
];

export const TOGGLE = {
  OVERDUE_DEBT: "OVERDUE_DEBT",
  BAD_DEBT: "BAD_DEBT",
  OTHER: null,
};

export const reasonLock = [
  {
    label: t("supplier.badQuality"),
    value: TOGGLE.BAD_DEBT,
  },
  {
    label: t("supplier.missingDeli"),
    value: TOGGLE.OVERDUE_DEBT,
  },
];

export const optionSupplierClassification = [
  { label: t("supplier.company"), value: SUPPLIER_TYPE.COMPANY },
  { label: t("supplier.personal"), value: SUPPLIER_TYPE.PERSONAL },
];

export const PAYMENT_METHOD = {
  DEBT: "DEBT",
  WALLET: "WALLET",
};
