import { Typography } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import React from "react";
import AddressForm from "../AddressForm/AddressForm";
import useModalUpdateAddress from "./useModalUpdateAddress";

const ModalUpdateAddress = ({ sellerID, refetch, contact }) => {
  const { updateContact, initialValues, visible, handleCancel, form, handleOpen, handleValuesChange } =
    useModalUpdateAddress({
      sellerID,
      refetch,
      contact,
    });

  return (
    <CustomModal
      {...{
        onOke: updateContact,
        okeButtonLabel: t("common.save"),
        customComponent: <Typography.Link>{t("common.edit")}</Typography.Link>,
        title: <span className="add-address-title">{t("seller.componentAddressList.titleEditAddress")}</span>,
        centered: true,
        footer: false,
        width: "fit-content",
        visible: visible,
        afterClose: refetch,
        onOpen: handleOpen,
        onCancel: handleCancel,
      }}
    >
      <AddressForm form={form} initialValues={initialValues} handleValuesChange={handleValuesChange} />
    </CustomModal>
  );
};

export default ModalUpdateAddress;
