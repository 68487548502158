import { Typography } from "antd";
import OrderLink from "components/CustomRedirectLink/OrderLink";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import { DATE_FORMAT } from "config/constants";
import CustomTitle from "features/Serial/components/CustomTitle";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { convertTimeDate } from "utils/dateTime";

export const useTableReImportWarranty = () => {
  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.stt")} />,
      render: (_, record, index) => index + 1,
      width: "3%",
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.serial")} />,
      render: (_, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Text ellipsis={{ expandable: true, tooltip: true }} style={{ textAlign: "start", margin: 0 }}>
              {record?.serialItems?.[0]?.serial?.code}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.productCode")} />,
      render: (_, record, index) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography strong style={{ textAlign: "start", margin: 0 }}>
            <ProductLink product={record} />
          </Typography>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.productName")} />,
      render: (_, record, index) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Text ellipsis={{ expandable: true, tooltip: true }} style={{ textAlign: "start", margin: 0 }}>
            {record?.product?.name}
          </Typography.Text>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.warrantyDate")} />,
      render: (_, record) => (
        <Typography.Text>
          {record?.warrantyOnSale} {convertUnit(record?.warrantyUnit)}
        </Typography.Text>
      ),
      width: 100,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.sellDate")} />,
      render: (_, record) => convertTimeDate(record?.soldDate, DATE_FORMAT),
      width: 110,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.warrantyCode")} />,
      render: (_, record, index) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ textAlign: "start", margin: 0 }}>
            <WarrantyDetailLink warranty={record?.serialItems?.[0]?.warrantyNote} />
          </div>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.orderCode")} />,
      render: (_, record, index) => <OrderLink order={record?.serialItems?.[0]?.warrantyNote} />,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.reImportWarehouse")} />,
      render: (_, record, index) => {
        return record?.serialItems?.[0]?.warehouse?.name;
      },
    },
  ];
  return { columns };
};
