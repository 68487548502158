import { Col, Descriptions, Row } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";
import { t } from "i18next";

function SellerInfo({ dataDetail }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
          <Descriptions.Item label={t("serialIE.nameSeller")}>
            {dataDetail?.seller?.fullName || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.phone")}>
            {dataDetail?.seller?.telephone || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.email")}>
            {dataDetail?.seller?.email || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.address")}>
            <CustomInfoAdress value={dataDetail?.seller}></CustomInfoAdress>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default SellerInfo;
