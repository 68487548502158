import { Col, Row, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomInfoAddress from "components/CustomInfoAddress";
import CustomSelectSellerLoadMore from "components/CustomSelectSellerLoadMore";
import { styleHeaderCard, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SupplierInfo({ dataDetail }) {
  const [sellerInfo, setSellerInfo] = useState();

  const handleGetSellerInfo = (value) => {
    setSellerInfo(value);
  };

  useEffect(() => {
    setSellerInfo(dataDetail?.seller);
  }, [dataDetail]);

  return (
    <CustomCard
      headStyle={styleHeaderCard}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.supplierInfo")}
        </Typography.Text>
      }
      content={
        <Space direction="vertical" style={{ width: "100%", minHeight: "332px" }}>
          <div>
            <CustomSelectSellerLoadMore
              formName={"sellerID"}
              onGetSellerInfo={handleGetSellerInfo}
              codeSellerEdit={dataDetail?.seller?.code}
            />
          </div>
          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row gutter={[16, 8]}>
                <Col span={6}>
                  <Typography.Text strong>{t("purchase.supplier")}</Typography.Text>
                </Col>
                <Col span={18}>
                  <Link to={`/supplier/detail/${sellerInfo?.value || sellerInfo?.id}`}>
                    <Typography.Link strong>{sellerInfo?.code || "---"}</Typography.Link>
                  </Link>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={6}></Col>
                <Col span={18}>
                  <Typography.Text>{sellerInfo?.label || sellerInfo?.fullName || "---"}</Typography.Text>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={6}>
                  <Typography.Text strong>{t("purchase.sdt")}</Typography.Text>
                </Col>
                <Col span={18}>
                  <Typography.Text>{sellerInfo?.telephone || "---"}</Typography.Text>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={6}>
                  <Typography.Text strong>{t("purchase.address")}</Typography.Text>
                </Col>
                <Col span={18}>
                  <Typography.Text>
                    <CustomInfoAddress value={sellerInfo} />
                  </Typography.Text>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={6}>
                  <Typography.Text strong>{t("purchase.bankAccountInfo")}</Typography.Text>
                </Col>
                <Col span={18}>
                  {sellerInfo?.bankAccounts?.map((item) => (
                    <div>
                      <Typography.Text>
                        {`${item?.bank?.name} - ${item?.bankAccountNumber} - ${item?.name} - chi nhánh ${item?.bankBranch}` ||
                          "---"}
                      </Typography.Text>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={24} style={{ textAlign: "center", width: "100%" }}>
                  {!sellerInfo && <Typography.Link strong>{t("purchase.pleaseSearchSupplier")}</Typography.Link>}
                </Col>
              </Row>
            </Space>
          </div>
        </Space>
      }
    />
  );
}

export default SupplierInfo;
