import { Col, Popconfirm, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { t } from "i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

function SupplierInfo({
  supplierOptions,
  onLoadMoreDataSupplier,
  hasMoreDataSupplier,
  onSearch,
  handleChangeInfo,
  loadingSupplier,
  supplierInfo,
  visiblePopconfirmProvider,
  handleCancelPopconfirmProvider,
  handleConfirmPopconfirmProvider,
}) {
  console.log(`Debug supplierInfo:${supplierInfo}`); //Debug supplierInfo
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Popconfirm
          title={t("serialExportCreate.confirmChangeProvider")}
          onConfirm={handleConfirmPopconfirmProvider}
          onCancel={handleCancelPopconfirmProvider}
          visible={visiblePopconfirmProvider}
          placement="bottomRight"
          okText={t("common.confirm")}
          cancelText={t("common.cancel")}
        >
          <SelectLoadMore
            formItemOptions={{
              name: "sellerID",
              rules: [
                {
                  required: true,
                  message: t("serialIE.error.provider"),
                },
              ],
              className: "form-seller-select",
            }}
            inputOptions={{
              placeholder: t("serialIE.placeholder.searchProvider"),
              options: supplierOptions,
              dropdownMatchSelectWidth: 1000,
              allowClear: false,
              dropdownRender: (originNode) => (
                <>
                  <Row gutter={16} className={styles["custom-dropdown"]}>
                    <Col span={5} className={styles["item"]} style={{ padding: "0 0 0 20px" }}>
                      <Typography.Text strong className={styles["sub-title"]}>
                        {t("supplier.table.supplierCode")}
                      </Typography.Text>
                    </Col>
                    <Col span={7} className={styles["item"]}>
                      <Typography.Text strong className={styles["sub-title"]}>
                        {t("supplier.table.supplierName")}
                      </Typography.Text>
                    </Col>
                    <Col span={3} className={styles["item"]}>
                      <Typography.Text strong className={styles["sub-title"]}>
                        {t("serial.sdt")}
                      </Typography.Text>
                    </Col>
                    <Col span={9} className={styles["item"]}>
                      <Typography.Text strong className={styles["sub-title"]}>
                        {t("supplier.table.address")}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Spin spinning={loadingSupplier}>{originNode}</Spin>
                </>
              ),
              optionLabelProp: "labelSupplier",
              onSelect: (value, options) => handleChangeInfo(value, options),
            }}
            onLoadMore={onLoadMoreDataSupplier}
            hasMoreData={hasMoreDataSupplier}
            onSearch={onSearch}
          />
        </Popconfirm>
      </Col>
      <>
        <Col span={4}>
          <Typography.Text className="sub-title">{t("supplier.supplierCode")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Link to={`/supplier/detail/${supplierInfo?.id}`} target="_blank">
            <Typography.Link strong>{supplierInfo?.code || "--------"}</Typography.Link>
          </Link>
        </Col>
        <Col span={4}>
          <Typography.Text className="sub-title">{t("supplier.supplierName")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{supplierInfo?.fullName || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="sub-title">SĐT</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{supplierInfo?.telephone || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="sub-title">{t("serialIE.providerInfo.address")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>
            <Typography.Text>
              {`${supplierInfo?.address || "---"} - ${supplierInfo?.ward?.name || "---"} - ${
                supplierInfo?.district?.name || "---"
              } - ${supplierInfo?.city?.name || "---"}`}
            </Typography.Text>
          </Typography.Text>
        </Col>
      </>
      <Col span={24} style={{ textAlign: "center", width: "100%", marginTop: "16px" }}>
        {!supplierInfo && <Typography.Link strong>{t("warehouse.create.PleasesearchProvider")}</Typography.Link>}
      </Col>
    </Row>
  );
}

export default SupplierInfo;
