import { Col, Row, Tag, Typography } from "antd";
import CustomCard from "components/CustomCard";
import ExpandableText from "components/CustomExpandableText";
import { SELLER_TYPE, dataSourceLabel, dataSourceTagColor } from "features/PotentialCustomer/constants";
import { t } from "i18next";
import { compact } from "lodash";

function GeneralInfoCard({ data }) {
  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t("potentialCustomer.form.generalInfo")}
        </Typography.Text>
      }
      content={
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.dataSource")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Tag color={dataSourceTagColor?.[data?.dataSource]}>{dataSourceLabel?.[data?.dataSource] || "--"}</Tag>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.taxCode")}</Typography.Text>
              </Col>
              <Col span={16}>{data?.vatInfo?.taxIDNumber || "--"} </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.customerGroup")}</Typography.Text>
              </Col>
              <Col span={16}>{data?.sellerGroup?.name || "--"}</Col>
            </Row>
          </Col>
          {data?.type === SELLER_TYPE.PERSONAL && (
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <div>
                    <Typography.Text style={{ textWrap: "pretty" }} strong>
                      {t("potentialCustomer.form.idNumber")}
                    </Typography.Text>
                    <br />
                    <Typography.Text style={{ textWrap: "pretty" }} strong>
                      {t("potentialCustomer.form.idNumber1")}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={16}>{data?.idNumber || "--"}</Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>
              </Col>
              <Col span={16}>{data?.email || "--"}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>
              </Col>
              <Col span={16}>{data?.telephone || "--"}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.address")}</Typography.Text>
              </Col>
              <Col span={16}>
                <ExpandableText
                  value={
                    compact([data?.address, data?.ward?.name, data?.district?.name, data?.city?.name]).join(", ") ||
                    "--"
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Typography.Text strong>{t("potentialCustomer.form.warehouses")}</Typography.Text>
              </Col>
              <Col span={16}>
                {data?.warehouses ? data?.warehouses?.map((item) => <Tag color={"blue"}>{item?.name}</Tag>) : "--"}
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
}

export default GeneralInfoCard;
