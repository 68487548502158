import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { Icon } from "assets/icons";
import { t } from "i18next";
import useToolbar from "./useToolbar";

import styles from "./index.module.scss";

function Toolbar({ params, handleSearch, onChangeParams }) {
  const { renderTags } = useToolbar({ onChangeParams, params });

  return (
    <div>
      <Input
        prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
        placeholder={t("warranty.warrantyList.placeholderInputSearch")}
        onChange={(value) => handleSearch(value)}
        allowClear={true}
      />
      <div className={styles["tags-list"]}>{renderTags}</div>
    </div>
  );
}

export default Toolbar;
