import { ExclamationCircleFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { MAXIMUM_LENGTH_CURRENCY, SHIPPING_PROVIDER_TYPE, SHIPPING_TYPE } from "features/Cart/constants";
import { useFlags } from "flagsmith/react";
import { t } from "i18next";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { formatCurrency, validator } from "utils/helperFuncs";
import useAdditionalDeliver from "./useAdditionalDeliver";

const AdditionalDeliver = ({
  dataOrder,
  shippingType,
  handleUpdate,
  refetchCart,
  useInOrder,
  dataShippingProvider,
  form,
  loadingShippingProvider,
}) => {
  const { shippingProviderOldOptions } = useAdditionalDeliver();

  const validFee = (_, value) => {
    if (isNil(value)) {
      return Promise.reject(new Error(t("cart.blankFee")));
    }
    if (value.toString().length > MAXIMUM_LENGTH_CURRENCY) {
      return Promise.reject(new Error(t("cart.maximumFee")));
    }
    return Promise.resolve();
  };

  const getShippingMethod = (name, money, imgUrl = null) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row style={{ alignItems: "center", gap: "5px" }}>
          {imgUrl && <img src={imgUrl} alt="" height={20} />}
          {name}
        </Row>
        <Typography.Link strong>{money}</Typography.Link>
      </div>
    );
  };

  const [textErrorNotSupport, setTextErrorNotSupport] = useState("");

  useEffect(() => {
    const carrierMethodShipping = dataOrder?.shippingConfiguration?.deliver3PartyConfig?.carrierMethod;
    const providerIDShipping = dataOrder?.shippingConfiguration?.deliver3PartyConfig?.providerID;

    const getShippingMethods = dataShippingProvider?.find(
      (item) => item?.method === carrierMethodShipping && item?.carrierMinFee !== null && item?.carrierMaxFee !== null
    );
    const getShippingProvider = getShippingMethods?.providers?.find(
      (item) => item.id === `${providerIDShipping} - ${carrierMethodShipping}`
    );
    const checkShipping = () => {
      if (carrierMethodShipping !== null && providerIDShipping !== null) {
        return getShippingProvider;
      } else if (carrierMethodShipping !== null && providerIDShipping === null) {
        return getShippingMethods;
      } else {
        return true;
      }
    };
    if (dataOrder && !loadingShippingProvider) {
      if (
        dataOrder?.items?.length > 0 &&
        (!dataShippingProvider || (dataShippingProvider && (!checkShipping() || checkShipping() === null)))
      ) {
        setTextErrorNotSupport(t("cart.textErrorNotSupport"));
      } else {
        setTextErrorNotSupport("");
      }
    }
  }, [dataShippingProvider, dataOrder]);

  const convertDataShippingProvider = dataShippingProvider?.map((item) => ({
    name: item.method,
    id: item.id,
    priceRange:
      item.carrierMinFee != null || item.carrierMaxFee != null
        ? item.carrierMinFee === item.carrierMaxFee
          ? formatCurrency(item.carrierMaxFee)
          : `${formatCurrency(item.carrierMinFee ?? 0)} - ${formatCurrency(item.carrierMaxFee ?? 0)}`
        : formatCurrency(0),
    minPrice: item.carrierMinFee,
    maxPrice: item.carrierMaxFee,
    isDisabled: (item.carrierMinFee == null || item.carrierMaxFee == null) && true,
    children: item?.providers?.map((i) => ({
      id: i.id,
      name: i.name,
      priceRange: formatCurrency(i.fee),
      fee: i.fee,
      imgUrl: i.logo_url,
    })),
  }));

  const treeData = convertDataShippingProvider?.map((method) => ({
    value: method?.name,
    disabled: method?.isDisabled,
    minPrice: method?.minPrice,
    maxPrice: method?.maxPrice,
    title: getShippingMethod(SHIPPING_PROVIDER_TYPE[method?.name], method?.priceRange),
    children: method?.children?.map((childMethod) => ({
      value: childMethod?.id,
      fee: childMethod.fee,
      title: getShippingMethod(childMethod?.name, childMethod?.priceRange, childMethod?.imgUrl),
    })),
  }));

  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const delivers = {
    [`${SHIPPING_TYPE.BUS}`]: idEnableNewShippingProvider ? (
      <>
        <Col span={8}>
          <FormInput
            formItemOptions={{
              name: ["shippingConfiguration", "busConfig", "busName"],
              rules: [{ required: true, message: t("cart.blankBusName") }],
            }}
            inputOptions={{
              placeholder: t("cart.busNamePlaceholder"),
              onBlur: () => handleUpdate(),
              maxLength: 255,
            }}
          />
        </Col>

        <Col span={8}>
          <FormInput
            formItemOptions={{
              rules: [
                validator({
                  type: "phone",
                }),
              ],
              name: ["shippingConfiguration", "busConfig", "telephone"],
            }}
            inputOptions={{
              placeholder: t("cart.telephonePlaceholder"),
              onBlur: () => handleUpdate(),
            }}
          />
        </Col>

        <Col span={8}>
          <FormInput
            formItemOptions={{ name: ["shippingConfiguration", "busConfig", "busStation"] }}
            inputOptions={{
              placeholder: t("cart.busStationPlaceholder"),
              onBlur: () => handleUpdate(),
              maxLength: 255,
            }}
          />
        </Col>
      </>
    ) : (
      <>
        <Col span={12}>
          <FormInput
            formItemOptions={{
              name: ["shippingConfiguration", "busConfig", "busName"],
              rules: [{ required: true, message: t("cart.blankBusName") }],
            }}
            inputOptions={{
              placeholder: t("cart.busNamePlaceholder"),
              onBlur: () => handleUpdate(),
            }}
          />
        </Col>

        <Col span={12}>
          <FormInput
            formItemOptions={{ name: ["shippingConfiguration", "busConfig", "location"] }}
            inputOptions={{
              placeholder: t("cart.locationPlaceholder"),
              onBlur: () => handleUpdate(),
            }}
          />
        </Col>

        <Col span={12}>
          <FormInput
            formItemOptions={{ name: ["shippingConfiguration", "busConfig", "telephone"] }}
            inputOptions={{
              placeholder: t("cart.telephonePlaceholder"),
              onBlur: () => handleUpdate(),
            }}
          />
        </Col>

        <Col span={12}>
          <FormInput
            formItemOptions={{ name: ["shippingConfiguration", "busConfig", "busStation"] }}
            inputOptions={{
              placeholder: t("cart.busStationPlaceholder"),
              onBlur: () => handleUpdate(),
            }}
          />
        </Col>
      </>
    ),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: idEnableNewShippingProvider ? (
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.TREE_SELECT}
          formItemOptions={{
            style: { width: "100%", marginBottom: "8px" },
            name: ["shippingConfiguration", "deliver3PartyConfig", "providerID"],
            rules: [{ required: true, message: t("cart.blankServiceCode") }],
          }}
          inputOptions={{
            placeholder: t("cart.serviceCodePlaceholder"),
            treeData: treeData ?? [],
            showSearch: true,
            treeNodeFilterProp: "title",
            filterTreeNode: (search, item) => {
              if (item?.title?.props?.children) {
                const titleHtml = item?.title?.props?.children[0]?.props?.children[1];
                return titleHtml?.toLowerCase()?.includes(search?.toLowerCase());
              }
            },
            onChange: (value) => {
              function findOptionById(tree, id) {
                if (!tree) return null;
                for (const option of tree) {
                  if (option.value === id) return option;
                  if (option.children) {
                    const result = findOptionById(option.children, id);
                    if (result) return result;
                  }
                }
                return null;
              }
              const selectedOption = findOptionById(treeData, value);
              const parentMethod = treeData?.find((method) => method?.children?.includes(selectedOption));
              handleUpdate(
                undefined,
                selectedOption?.minPrice,
                selectedOption?.maxPrice,
                selectedOption?.fee,
                parentMethod?.value
              );
            },
          }}
        />
        <span style={{ display: "flex", alignItems: "center", gap: "6px", margin: "-5px 0px 10px 0px" }}>
          <Typography.Text style={{ color: "#ff4d4f" }}>{textErrorNotSupport}</Typography.Text>
        </span>
        <span style={{ display: "flex", alignItems: "center", gap: "6px", marginLeft: "0px" }}>
          <ExclamationCircleFilled style={{ color: "#EF9351" }} />
          <Typography.Text style={{ color: "#EF9351" }}>{t("cart.infowarningchooseproduct")}</Typography.Text>
        </span>
      </Col>
    ) : (
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            style: { width: "100%" },
            name: ["shippingConfiguration", "deliver3PartyConfig", "providerID"],
            rules: [{ required: true, message: "Vui lòng chọn đơn vị vận chuyển" }],
          }}
          inputOptions={{
            placeholder: t("cart.serviceCodePlaceholder"),
            options: shippingProviderOldOptions,
            onChange: () => handleUpdate(),
          }}
        />
      </Col>
    ),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: null,
    [`${SHIPPING_TYPE.URBAN_COD}`]: (
      <>
        {isEmpty(useInOrder) || useInOrder?.changeShippingFee ? (
          <Col span={6}>
            <FormInput
              type={INPUT_TYPE.CURRENCY_INPUT}
              formItemOptions={{
                name: ["shippingConfiguration", "urbanConfig", "fee"],
                rules: [
                  {
                    validator: validFee,
                  },
                ],
              }}
              inputOptions={{
                placeholder: t("cart.urbanCODFeePlaceholder"),
                onBlur: () => handleUpdate().then(() => refetchCart()),
              }}
            />
          </Col>
        ) : (
          <>
            <Col span={6}>
              <Typography.Text strong>{t("order.orderDetail.shippingFee")}:</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {formatCurrency(10000)}
              </Typography.Text>
            </Col>
          </>
        )}
      </>
    ),
  };

  return shippingType ? delivers[`${shippingType}`] : delivers.BUS;
};

export default AdditionalDeliver;
