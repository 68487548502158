import App from "pages/App";
//AppRouters
import departmentRouter from "pages/App/Department/router";
import productRouter from "pages/App/Product/router";
import roleRouter from "pages/App/Role/router";
import settingRouter from "pages/App/Setting/router";
import accountantRouter from "pages/App/Accountant/router";
import accountRouter from "pages/App/Acount/router";
import agentRouter from "pages/App/Agent/router";
import cartRouter from "pages/App/Cart/router";
import orderRouter from "pages/App/Order/router";
import homeRouter from "pages/App/Home/router";
import notificationRouter from "pages/App/Notification/router";
import campaignRouter from "pages/App/Campaign/router";
import reportRouter from "pages/App/Report/router";
import warrantyRouter from "pages/App/Warranty/router";
import serialRouter from "pages/App/Serial/router";
import supplierRouter from "pages/App/Supplier/router";
import keyManagementRouter from "pages/App/KeyManagement/router";
import potentialCustomerRouter from "pages/App/PotentialCustomer/router";
import purchaseRouter from "pages/App/Purchase/router";

import Login from "pages/Auth/Login";

import ChangePassword from "pages/Auth/ChangePassword";
import ForgotPassword from "pages/Auth/ForgotPassword";
import NewPassword from "pages/Auth/NewPassword";
import profileRouter from "pages/Auth/Profile/router";

const routes = [
  {
    path: "/login",
    component: Login,
    checkAuth: false,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    checkAuth: false,
    exact: true,
  },
  {
    path: "/new-password",
    component: NewPassword,
    checkAuth: false,
    exact: true,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    checkAuth: false,
    exact: true,
  },
  {
    path: "/",
    component: App,
    checkAuth: true,
    exact: false,
  },
];

export const appRoutes = [
  ...orderRouter,
  ...productRouter,
  ...campaignRouter,
  ...potentialCustomerRouter,
  ...agentRouter,
  ...keyManagementRouter,
  ...reportRouter,
  ...accountRouter,
  ...settingRouter,
  ...departmentRouter,
  ...roleRouter,
  ...accountantRouter,
  ...cartRouter,
  ...homeRouter,
  ...notificationRouter,
  ...warrantyRouter,
  ...serialRouter,
  ...supplierRouter,
  ...profileRouter,
  ...purchaseRouter,
];

export function getAppRoutes() {
  let listRoutes = [];
  for (let item of appRoutes) {
    if (!item.children) {
      listRoutes.push(item);
    } else {
      item?.children?.forEach((child) => {
        listRoutes.push(child);
      });
    }
  }
  return listRoutes;
}

export default routes;
