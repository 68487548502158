import Home from ".";

const router = [
  {
    key: "home",
    path: "/",
    component: Home,
    exact: true,
  },
];

export default router;
