import React, { useState } from "react";
import { Button, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { useTechnicalApproveSalesReturn } from "hooks/recommendimport";
import { notify } from "utils/helperFuncs";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";

function ButtonTechnicalApproveSalesReturn({ record }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const { handleTechnicalApproveSalesReturn, loading: loadingTechnical } = useTechnicalApproveSalesReturn();

  const confirmApprove = async () => {
    await handleTechnicalApproveSalesReturn({ id: record?.id })
      .then(() => {
        notify.success({
          message: t("warehouse.salesReturn.messageApproveRequest"),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  return (
    <CustomModal
      {...{
        customComponent: t("warehouse.recommendImport.accept"),
        footer: false,
        message: (
          <ModalWithIconQuestion
            title={
              <>
                {t("warehouse.recommendImport.questionaccept")} <Typography.Link strong>{record?.code}</Typography.Link>{" "}
                {t("warehouse.recommendImport.no")}?
              </>
            }
          />
        ),
        centered: true,
        styleButton: "notButton",
        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={confirmApprove} loading={loadingTechnical}>
            {t("common.confirm")}
          </Button>
        ),
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    />
  );
}

export default ButtonTechnicalApproveSalesReturn;
