import { useQuery } from "@apollo/client";
import { GET_REGIONS_LIST } from "graphql/regions/query";

export const useGetRegionsList = () => {
  const { data, loading, error } = useQuery(GET_REGIONS_LIST);

  return {
    regions: data?.region?.list || [],
  };
};
