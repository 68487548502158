import FormPotentialCustomer from "../PotentialCustomerDrawerForm/FormPotentialCustomer";

function UpdatePotentialCustomer({
  dataDetail,
  form,
  handleChangeInChargeStaff,
  typePersonal,
  formValues,
  loadingIdNumber,
  loadingTaxCode,
  isDuplicateIdNumber,
  isDuplicateTaxCode,
  handleSearchIdNumber,
  handleSearchTaxCode,
  handleOpenDrawerMulti,
  loadingEmail,
  loadingTelephone,
  isDuplicateEmail,
  isDuplicateTelephone,
  handleSearchEmail,
  handleSearchTelephone,
  valueIdNumber,
  valueTaxCode,
  valueEmail,
  valueTelephone,
  handleSearchInChargeStaffs,
  handleChangeCareStaff,
  optionsCareStaffs,
  onLoadMoreCareStaff,
  hasMoreCareStaff,
  optionsInChargeStaff,
  onLoadMoreInChargeStaff,
  hasMoreInChargeStaff,
  handleSearchCareStaffs,
}) {
  return (
    <FormPotentialCustomer
      optionsCareStaffs={optionsCareStaffs}
      onLoadMoreCareStaff={onLoadMoreCareStaff}
      hasMoreCareStaff={hasMoreCareStaff}
      handleSearchInChargeStaffs={handleSearchInChargeStaffs}
      optionsInChargeStaff={optionsInChargeStaff}
      onLoadMoreInChargeStaff={onLoadMoreInChargeStaff}
      hasMoreInChargeStaff={hasMoreInChargeStaff}
      handleSearchCareStaffs={handleSearchCareStaffs}
      handleChangeInChargeStaff={handleChangeInChargeStaff}
      handleChangeCareStaff={handleChangeCareStaff}
      form={form}
      typePersonal={typePersonal}
      formValues={formValues}
      loadingIdNumber={loadingIdNumber}
      loadingTaxCode={loadingTaxCode}
      isDuplicateIdNumber={isDuplicateIdNumber}
      isDuplicateTaxCode={isDuplicateTaxCode}
      handleSearchIdNumber={handleSearchIdNumber}
      handleSearchTaxCode={handleSearchTaxCode}
      handleOpenDrawerMulti={handleOpenDrawerMulti}
      loadingEmail={loadingEmail}
      loadingTelephone={loadingTelephone}
      isDuplicateEmail={isDuplicateEmail}
      isDuplicateTelephone={isDuplicateTelephone}
      handleSearchEmail={handleSearchEmail}
      handleSearchTelephone={handleSearchTelephone}
      dataMulti={dataDetail}
      valueIdNumber={valueIdNumber}
      valueTaxCode={valueTaxCode}
      valueEmail={valueEmail}
      valueTelephone={valueTelephone}
      isUpdate={true}
    />
  );
}

export default UpdatePotentialCustomer;
