import React, { useState } from "react";
import { Form } from "antd";
import moment from "moment";

import { DATE_ISO_8601_FORMAT } from "config/constants";
import { WARRANTY_PRODUCT_TYPE, WARRANTY_REQUEST_TYPE } from "features/WarrantyRequest/constant";
import { formatDateTime } from "utils/dateTime";
import { notify } from "utils/helperFuncs";
import { useUploadDocument } from "hooks/upload-document";
import { t } from "i18next";

function useModalEditNotOwner({ record, onSubmit, product, category }) {
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleUpload } = useUploadDocument();

  const images = record?.imageURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    serialImei: record?.serialImei,
    productName: record?.productName,
    warrantyProductType: WARRANTY_PRODUCT_TYPE.NOT_OWNER,
    warrantyRequestType: WARRANTY_REQUEST_TYPE.BEHALF,
    productID: record?.productID,
    categoryID: record?.categoryID,
    promisedReturnDate: moment(record?.promisedReturnDate),
    accessoryAttachment: record?.accessoryAttachment,
    itemAmount: record?.itemAmount,
    customerRequest: record?.customerRequest,
    defectDescription: record?.defectDescription,
    imageURLs: images || [],
  };

  const handleOpen = () => {
    setSwitchClose(true);
  };

  const handleCancel = () => {
    setSwitchClose(false);
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t("uploadDocument.uploadImageError"),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const handleEdit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const imageURLs = await uploadImage(values?.imageURLs);

      const newValue = {
        ...record,
        ...values,
        productCode: product?.code,
        categoryName: category,
        warrantyProductType: WARRANTY_PRODUCT_TYPE.NOT_OWNER,
        promisedReturnDate: formatDateTime(values?.promisedReturnDate, DATE_ISO_8601_FORMAT, 0),
        imageURLs: imageURLs,
      };

      newValue && setSwitchClose(false);

      onSubmit(newValue);
    } catch (e) {
      console.info("Validate Failed:", e);
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    initialValues,
    handleEdit,
    switchClose,
    loading,
    handleOpen,
    handleCancel,
  };
}

export default useModalEditNotOwner;
