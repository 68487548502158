import { Typography } from "antd";
import { Link } from "react-router-dom";

function ProductLink({ product }) {
  const productID = product?.product?.id || product?.productID || product?.id;
  const productCode = product?.product?.code || product?.productCode || product?.code;

  if (productCode)
    return (
      <Link to={`/product/detail/${productID}`} target="_blank">
        <Typography.Link strong>{productCode}</Typography.Link>
      </Link>
    );

  return <Typography.Text>--</Typography.Text>;
}

export default ProductLink;
