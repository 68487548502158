import { Form } from "antd";
import { useCustomerPermissions, useGetPotentialCustomerDetail } from "hooks/potentialCustomer/query";
import { useState } from "react";

function useFormLinkButton({ onClose, customerId, setIsUpdate }) {
  const [form] = Form.useForm();
  const { data: dataDetail, loading: loadingDetail } = useGetPotentialCustomerDetail({ id: customerId });
  const { canCustomerUpdate } = useCustomerPermissions();

  const [isCustomerButton, setIsCustomerButton] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);

  const handleGetValueIsCustomerButton = (value) => {
    setIsCustomerButton(value);
  };

  const handleOnClose = () => {
    onClose();
    form.resetFields();
    setIsCustomerButton(false);
    setDataSelected(null);
    setIsUpdate && setIsUpdate(false);
  };

  const handleBack = () => {
    form.resetFields();
    setIsCustomerButton(false);
    setDataSelected(null);
  };

  const handleGetDataSelected = (value) => {
    setDataSelected(value);
  };

  return {
    dataDetail,
    loadingDetail,
    canCustomerUpdate,
    isCustomerButton,
    dataSelected,
    formLinkButton: form,
    handleGetValueIsCustomerButton,
    handleOnClose,
    handleGetDataSelected,
    handleBack,
  };
}

export default useFormLinkButton;
