import { Affix, Button, Col, Row, Space, Typography } from "antd";
import Img from "assets/images";
import CommonCard from "components/CommonCard";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import { DATE_FORMAT } from "config/constants";
import { CAMPAIGN_TYPE, COMBO_CAMPAIGN } from "features/Campaign/constants";
import { t } from "i18next";
import { groupBy, sumBy } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency } from "utils/helperFuncs";
import styles from "./index.module.scss";

const ButtonGroup = ({
  data,
  loadingCheckDuplicateCodes,
  visibleConfirmModal,
  handleOpenConfirmModal,
  handleCancelConfirmModal,
  handleActionCampaign,
  createLoading,
  isUpdate,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);
  const [totalBudgetQuantity, setTotalBudgetQuantity] = useState(0);

  const groupedData = groupBy(data?.promotionCampaignTierGroups, "type");
  const discountMoneyArray = groupedData[COMBO_CAMPAIGN.DISCOUNT_MONEY] || [];
  const fixedPriceArray = groupedData[COMBO_CAMPAIGN.FIXED_PRICE] || [];
  const giftArray = groupedData[COMBO_CAMPAIGN.GIFT] || [];

  const budgetProductQuantity = sumBy(fixedPriceArray, (item) => parseInt(item.budgetProductQuantity));

  const productPromoLen = () => {
    const num = data?.promotionCampaignTierGroups?.[0]?.promotionCampaignTiers?.length || 0;
    return num < 10 ? `0${num}` : num;
  };

  const totalBudgetAmountProductPromo = data?.promotionCampaignTierGroups?.[0]?.promotionCampaignTiers?.reduce(
    (total, item) => {
      return total + item.budgetAmount;
    },
    0
  );

  useEffect(() => {
    const totalAmount = sumBy(discountMoneyArray, (item) => sumBy(item.promotionCampaignTiers, "budgetAmount"));

    const flattenedGifts = giftArray.flatMap((item) =>
      item.promotionCampaignTiers?.flatMap((item) => item.conditionGifts)
    );

    const totalBudgetQuantity = sumBy(flattenedGifts, (gift) => sumBy(Object.values(gift), "budgetQuantity"));

    setTotalBudgetQuantity(totalBudgetQuantity);
    setTotalBudgetAmount(totalAmount);
  }, [data]);

  return (
    <Affix offsetBottom={10} style={{ marginTop: "8px" }}>
      <div className={`${styles["form-buttons"]}`}>
        <CommonCard className={`${styles["form-buttons-container"]}`}>
          <CustomModal
            message={<Typography.Text>{t("campaign.combo.cancel")}</Typography.Text>}
            customComponent={<Button className={`${styles["secondary-button"]}`}>{t("common.cancel")}</Button>}
            footer={false}
            icon={Img.QuestionIcon}
            onOke={() => history.push("/campaign")}
          />
          <CustomModal
            {...{
              customComponent: (
                <CreateButton
                  loading={loadingCheckDuplicateCodes}
                  title={isUpdate ? t("common.update") : t("common.create")}
                />
              ),
              footer: false,
              visible: visibleConfirmModal,
              onOpen: handleOpenConfirmModal,
              onOke: handleActionCampaign,
              onCancel: handleCancelConfirmModal,
              buttonLoading: createLoading,
              title: (
                <Typography.Title level={4}>
                  {isUpdate
                    ? t("campaign.combo.confirmUpdateCampaignLabel")
                    : t("campaign.combo.confirmCreateCampaignLabel")}
                </Typography.Title>
              ),
              message: (
                <div style={{ textAlign: "left" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row
                        gutter={[30, 30]}
                        style={{
                          backgroundColor: "#E7F0FF",
                          padding: "12px 0",
                          borderRadius: "12px",
                        }}
                      >
                        <Col span={12}>
                          <Typography.Text strong>{t("campaign.form.campaignName")}</Typography.Text>
                        </Col>
                        <Col span={12}>
                          <Typography.Text>{data?.name}</Typography.Text>
                        </Col>
                        <Col span={12}>
                          <Typography.Text strong>{t("campaign.form.campaignPeriod")}</Typography.Text>
                        </Col>
                        <Col span={12}>
                          <Space direction="vertical">
                            <Typography.Text>
                              {t("product.inputRange.from")}:{" "}
                              {data?.startDate ? formatDateTime(data?.startDate, DATE_FORMAT) : "- -"}
                            </Typography.Text>
                            <Typography.Text>
                              {t("campaign.detailPromo.to")}:{" "}
                              {data?.endDate ? formatDateTime(data?.endDate, DATE_FORMAT) : "- -"}
                            </Typography.Text>
                          </Space>
                        </Col>
                        {location.hash.replace(/#/g, "") === CAMPAIGN_TYPE.PRODUCT_PROMO ? (
                          <>
                            <Col span={12}>
                              <Typography.Text strong>{t("campaign.detailPromo.sumProductPromo")}</Typography.Text>
                            </Col>
                            <Col span={12}>{productPromoLen()}</Col>
                          </>
                        ) : null}
                        <Col span={12}>
                          <Typography.Text strong>{t("campaign.detailPromo.sumBudget")}</Typography.Text>
                        </Col>
                        <Col span={12}>
                          {location.hash.replace(/#/g, "") === CAMPAIGN_TYPE.COMBO_PROMO ? (
                            <Space direction="vertical">
                              {totalBudgetAmount ? (
                                <Typography.Text>
                                  {t("campaign.combo.totalPromotionAmount")}: {formatCurrency(totalBudgetAmount)}
                                </Typography.Text>
                              ) : null}
                              {totalBudgetQuantity !== 0 && (
                                <Typography.Text>
                                  {t("campaign.detailPromo.totalAmountToGifts")}: {totalBudgetQuantity}
                                </Typography.Text>
                              )}
                              {budgetProductQuantity !== 0 && (
                                <Typography.Text>
                                  {t("campaign.detailPromo.totalPriceFix")}: {budgetProductQuantity}
                                </Typography.Text>
                              )}
                            </Space>
                          ) : (
                            <Typography.Text>{formatCurrency(totalBudgetAmountProductPromo)}</Typography.Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Typography.Text>
                        {t(`campaign.combo.${isUpdate ? "editCampaign" : "createCampaign"}`)}
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>
              ),
            }}
          />
        </CommonCard>
      </div>
    </Affix>
  );
};

export default ButtonGroup;
