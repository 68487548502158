import CustomTable from "components/CustomTable";
import { IMPORT_TYPE } from "features/Serial/constant";

import "../../../../index.scss";
import { TypeFirstTimeImport } from "../TypeFirstTimeImport";
import { TypeOther } from "../TypeOther";
import { TypeReImportWarranty } from "../TypeReImportWarranty";
import { TypeReturn } from "../TypeReturn";
import { TypeTransferSerial } from "../TypeTransferSerial";
import styles from "./index.module.scss";

function TableProduct({
  importType,
  handleRemoveSerial,
  handleRemoveProduct,
  warehouseOptions,
  handleGetTags,
  tags,
  remove,
  pendingWarrantyNoteLoading,
  form,
  handleGetTableOrder,
  dataTableFirstTimeImport,
  sumSerialByLack,
  sumSerialByOrder,
  sumSerialByScanned,
  dataTableReturn,
  dataTableReImportWarranty,
  handleClearAllSerials,
  warehouseInfo,
}) {
  const { columns: columnReImportWarranty } = TypeReImportWarranty({
    remove,
    handleRemoveSerial,
    warehouseOptions,
  });

  const { columns: columnReturn } = TypeReturn({
    form,
    handleGetTableOrder,
  });

  const { columns: columnFirstTimeImport } = TypeFirstTimeImport({
    form,
    remove,
    handleRemoveProduct,
    tags,
    handleGetTags,
    sumSerialByLack,
    sumSerialByOrder,
    sumSerialByScanned,
    handleClearAllSerials,
  });

  const { columns: columnTransferSerial } = TypeTransferSerial({
    form,
    remove,
    handleRemoveProduct,
    tags,
    handleGetTags,
    sumSerialByLack,
    sumSerialByOrder,
    sumSerialByScanned,
    handleClearAllSerials,
    warehouseInfo,
  });

  const { columns: columnOther } = TypeOther({
    form,
    remove,
    handleRemoveProduct,
    tags,
    handleGetTags,
    sumSerialByLack,
    sumSerialByOrder,
    sumSerialByScanned,
    handleClearAllSerials,
    warehouseInfo,
  });

  const renderDataSource = {
    [IMPORT_TYPE.FIRST_TIME_IMPORT]: dataTableFirstTimeImport,
    [IMPORT_TYPE.RETURN]: dataTableReturn,
    [IMPORT_TYPE.RE_IMPORT_WARRANTY]: dataTableReImportWarranty,
    [IMPORT_TYPE.TRANSFER_SERIAL]: dataTableFirstTimeImport,
    [IMPORT_TYPE.OTHER]: dataTableFirstTimeImport,
  };

  const renderColumns = {
    [IMPORT_TYPE.FIRST_TIME_IMPORT]: columnFirstTimeImport,
    [IMPORT_TYPE.RETURN]: columnReturn,
    [IMPORT_TYPE.RE_IMPORT_WARRANTY]: columnReImportWarranty,
    [IMPORT_TYPE.TRANSFER_SERIAL]: columnTransferSerial,
    [IMPORT_TYPE.OTHER]: columnOther,
  };

  return (
    <div className={styles["custom-table"]}>
      <CustomTable
        columns={renderColumns?.[importType]}
        dataSource={renderDataSource?.[importType]}
        rowKey={(record) => record?.id}
        scroll={{
          x: 900,
        }}
        loading={pendingWarrantyNoteLoading}
      />
    </div>
  );
}

export default TableProduct;
