import { Button, Result } from "antd";
import PageHeader from "components/PageHeader";
import { CreateForm } from "features/Campaign/components/CampaignForm";
import { CAMPAIGN_TYPE } from "features/Campaign/constants";
import { t } from "i18next";
import { useHistory, useLocation } from "react-router-dom";

const CamPaignCreateForm = () => {
  const location = useLocation();
  const history = useHistory();

  const hashURL = [CAMPAIGN_TYPE.COMBO_PROMO, CAMPAIGN_TYPE.TOP_SALES, CAMPAIGN_TYPE.PRODUCT_PROMO];

  const pageTitle = () => {
    switch (location.hash.replace(/#/g, "")) {
      case hashURL[0]:
        return "Tạo mới khuyến mãi theo combo";
      case hashURL[1]:
        return "Tạo mới khuyến mãi đua top";
      case hashURL[2]:
        return "Tạo mới khuyến mãi trên 1 sản phẩm";
      default:
        return "#";
    }
  };

  return hashURL.includes(location.hash.replace(/#/g, "")) ? (
    <>
      <PageHeader
        pageTitle={pageTitle()}
        routes={[
          {
            path: "/campaign",
            name: t("campaign.form.list"),
          },
          {
            path: "",
            name: pageTitle(),
          },
        ]}
      />
      <CreateForm />
    </>
  ) : (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => history.push("/campaign")}>
          Back về Danh sách khuyến mãi
        </Button>
      }
    />
  );
};

export default CamPaignCreateForm;
