import { Button } from "antd";
import { useOrderPermissions } from "hooks/order";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import { CustomModalApprovePayment } from "../CustomModalApprovePayment";
import CustomModalCancelOrder from "../CustomModalCancelOrder";
import "../WaitingApprovalButtons/index.scss";

const WaitingPaymentButtons = ({ checkedKeyList, resetCheckedList, checkedList }) => {
  const { t } = useTranslation();
  const [openModalCancelOrder, setOpenModalCancelOrder] = useState(false);
  const { canCancelOrder, canOrderCancelBeforePayView, canConfirmPayment } = useOrderPermissions();
  const handleCancelOrder = () => {
    if (checkedKeyList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      setOpenModalCancelOrder(true);
    }
  };

  return (
    <div className={`button-group-of-${canOrderCancelBeforePayView ? 2 : 1}`}>
      {
        <>
          {(canOrderCancelBeforePayView || canCancelOrder) && (
            <Button onClick={handleCancelOrder}>{t("order.orderList.cancelOrder")}</Button>
          )}

          {openModalCancelOrder && (
            <CustomModalCancelOrder
              setOpenModalCancelOrder={setOpenModalCancelOrder}
              orderIDs={checkedKeyList}
              resetCheckedList={resetCheckedList}
            />
          )}

          {canConfirmPayment && (
            <CustomModalApprovePayment
              orderIDs={checkedKeyList}
              orderDetail={checkedList && checkedList[0]}
              resetCheckedList={resetCheckedList}
            />
          )}
        </>
      }
    </div>
  );
};

export default WaitingPaymentButtons;
