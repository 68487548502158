import { Checkbox, Col, Row, Tag, Tooltip, Typography } from "antd";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import PopupViewAllSerial from "features/Purchase/SalesReturn/components/PopupViewAllSerial";
import { IMPORT_REQUEST_TYPE } from "features/Purchase/constant";

import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import styles from "./index.module.scss";

function TableProduct({ dataTableProduct, type }) {
  let sumPrice = 0;
  for (let i = 0; i < dataTableProduct?.length; i++) {
    const { quantity, price } = dataTableProduct[i];
    sumPrice += quantity * price;
  }

  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      align: "center",
      key: "codeorder",
      title: <span>{t("serialImportEdit.codeOrder")}</span>,
      render: (_, record, index) => (
        <Link to={`/order/${record?.order?.id}`} target="_blank">
          <Typography.Link strong>{record?.order?.number || "- -"}</Typography.Link>
        </Link>
      ),
    },
    {
      title: t("warehouse.recommendImport.PARAMETER"),
      align: "start",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link
              to={`/product/detail/${record?.product?.id}`}
              style={{ textAlign: "start", margin: 0 }}
              target="_blank"
            >
              <Typography.Link strong>{record?.product?.code}</Typography.Link>
            </Link>
            <CustomTextHiddenLine
              text={
                <Tooltip title={record?.product?.name || record?.productName}>
                  <Typography.Text strong>{record?.product?.name || record?.productName}</Typography.Text>
                </Tooltip>
              }
            />
          </div>
        );
      },
      isHidden: false,
      width: 250,
    },
    {
      align: "center",
      key: "quantityOrder",
      title: <span>{t("warehouse.salesReturn.quantityOrder")}</span>,
      render: (_, record, index) => {
        const quantityOrder = record?.order?.items?.find((i) => i?.product?.id === record?.product?.id)?.quantity;
        return quantityOrder;
      },
    },
    {
      align: "center",
      title:
        type === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY
          ? t("warehouse.recommendImport.quantity")
          : t("warehouse.salesReturn.quantityReturn"),
      dataIndex: "quantity",
      render: (_, record, index) => record?.quantity,
    },
    {
      align: "center",
      title: <span>{t("warehouse.salesReturn.PRICERETURN")}</span>,
      dataIndex: "price",
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === "VND" ? formatCurrency(record?.price) : formatCurrencyDollar(record?.price)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: <span>{t("warehouse.recommendImport.money")}</span>,
      render: (_, record, index) =>
        record?.currency === "VND"
          ? formatCurrency(record?.price * record?.quantity)
          : formatCurrencyDollar(record?.price * record?.quantity),
    },
    {
      align: "center",
      title: <span>VAT</span>,
      render: (_, record, index) => <Checkbox checked={record?.isVat}></Checkbox>,
    },
    {
      title: <span>{t("warehouse.salesReturn.reasonReturn")}</span>,
      align: "left",
      render: (_, record, index) => <Typography.Text>{record?.importReason || "- -"}</Typography.Text>,
    },
    {
      align: "left",
      width: "20%",
      title: <span>{t("warehouse.salesReturn.serialImeiDetail")}</span>,
      render: (_, record, index) => (
        <>
          {record?.serials?.slice(0, 2)?.map((item, index) => {
            return <Tag key={index}>{item?.code}</Tag>;
          })}
          {record?.serials?.length > 2 && (
            <PopupViewAllSerial
              listSerial={record?.serials}
              components={
                <Tag className="input-tag-tag custom-tags-by-serial" key={index}>
                  <Typography.Link italic>+{record?.serials?.length - 2} serial...</Typography.Link>
                </Tag>
              }
            />
          )}
        </>
      ),
    },
  ];

  const filteredColumns =
    type === IMPORT_REQUEST_TYPE.REFUND
      ? columns.filter((column) => column.key !== "codeorder")
      : columns.filter((column) => column.key !== "quantityOrder");

  return (
    <div className={styles["custom-table"]}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <CustomTable
            columns={filteredColumns}
            dataSource={dataTableProduct}
            rowKey={(record) => record?.id}
            scroll={{
              x: 900,
            }}
          />
          <div style={{ marginTop: "10px" }}>
            <Typography.Link strong style={{ color: "#052987" }}>
              {t("warehouse.recommendImport.sumbymoney")}:{" "}
              {dataTableProduct && dataTableProduct[0]?.currency === "VND"
                ? formatCurrency(sumPrice)
                : formatCurrencyDollar(sumPrice)}
            </Typography.Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TableProduct;
