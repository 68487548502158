import React from "react";
import { Affix, Button, Space } from "antd";

import styles from "./index.module.scss";

function CustomAffix({ showButtonDefault = true, title, addButton, showButtonModal, type = "primary", ...rest }) {
  return (
    <Affix offsetBottom={10}>
      <Space className={styles["space"]}>
        {addButton}
        {showButtonDefault && (
          <Button type={type} {...rest}>
            {title}
          </Button>
        )}
        {showButtonModal}
      </Space>
    </Affix>
  );
}

export default CustomAffix;
