import React, { useState } from "react";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { useTranslation } from "react-i18next";
import { IS_USED_APP } from "pages/App/Agent/constants";
import { useGetCustomerLoadMore, useGetSellerLevels } from "hooks/seller";
import { useWarehouse } from "hooks/warehouse";
import { FilterLoadMore } from "components/CustomFilterMenu";

const FilterLevel = ({ onFilter, defaultValue }) => {
  const { sellerLevels = [] } = useGetSellerLevels();

  const handleFilter = (checkedList) => {
    onFilter && onFilter({ sellerLevelIDs: checkedList });
  };

  const convertedOptions = sellerLevels.map((item) => ({ label: item.name, value: item.id }));

  return <FilterMenu options={convertedOptions} type="checkbox" onFilter={handleFilter} defaultValue={defaultValue} />;
};

const FilterWarehouse = ({ onFilter, defaultValue }) => {
  const { warehouses = [] } = useWarehouse();

  const handleFilter = (checkedList) => {
    onFilter && onFilter({ exportedWarehouseIDs: checkedList });
  };

  const convertedOptions = warehouses.map((item) => ({ label: item.name, value: item.id }));

  return (
    <FilterMenu
      options={convertedOptions}
      type="checkbox"
      onFilter={handleFilter}
      defaultValue={defaultValue}
      searchable
    />
  );
};

const FilterStatus = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();
  const handleFilter = (checkedValue) => {
    onFilter && onFilter({ isActive: checkedValue });
  };

  const convertedOptions = [
    { value: true, label: t("seller.cooperation") },
    { value: false, label: t("seller.stopCooperation") },
  ];

  return <FilterMenu options={convertedOptions} type="radio" onFilter={handleFilter} defaultValue={defaultValue} />;
};

const FilterInvited = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();
  const handleFilter = (checkedValue) => {
    onFilter && onFilter({ inviteStatus: checkedValue });
  };

  const SELLER_INVITATION_STATUS = [
    { value: IS_USED_APP.USED, label: t("seller.usedApp") },
    { value: IS_USED_APP.INVITED, label: t("seller.invited") },
    { value: IS_USED_APP.UNINVITED, label: t("seller.uninvited") },
  ];

  return (
    <FilterMenu options={SELLER_INVITATION_STATUS} type="radio" onFilter={handleFilter} defaultValue={defaultValue} />
  );
};

const FilterSupplier = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();
  const handleFilter = (checkedValue) => {
    onFilter && onFilter({ isSupplier: checkedValue });
  };

  const convertedOptions = [
    { value: true, label: t("supplier.yes") },
    { value: false, label: t("supplier.no") },
  ];

  return <FilterMenu options={convertedOptions} type="radio" onFilter={handleFilter} defaultValue={defaultValue} />;
};

const FilterGroupCustomer = ({ onFilter, defaultValue }) => {
  const [params, setParams] = useState({
    filters: { query: undefined },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { hasMoreData, loadMoreDataCustomers, options = [] } = useGetCustomerLoadMore(params);

  const handleSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilter = (checkedList) => {
    onFilter && onFilter({ sellerGroupIDs: checkedList });
  };

  return (
    <FilterLoadMore
      onFilter={handleFilter}
      data={options}
      onLoadMoreData={loadMoreDataCustomers}
      onSearchTextChange={handleSearch}
      hasMore={hasMoreData}
    />
  );
};

export { FilterLevel, FilterWarehouse, FilterStatus, FilterInvited, FilterSupplier, FilterGroupCustomer };
