import { Col, Descriptions, Form, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { PURCHASE_REQUEST_TYPE, PURCHASE_TYPE_OPTIONS } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import AddInfo from "./AddInfo";
import ButtonGroup from "./ButtonGroup";
import PaymentInfo from "./PaymentInfo";
import ProductsListLoadMore from "./ProductsListLoadMore";
import SupplierInfo from "./SupplierInfo";
import usePurchaseCreate from "./usePurchaseCreate";

function PurchaseCreate() {
  const {
    form,
    handleFinish,
    initialValues,
    warehouseOptions,
    handleValuesChange,
    paymentTerms,
    handleCreate,
    handleGetProductList,
    productListSelected,
    dataState,
    loadingCreatePurchase,
    isShowFully,
    isShowPartially,
    handleOpenFully,
    handleCancelFully,
    handleOpenPartially,
    handleCancelPartially,
    handleAddCodeProduct,
  } = usePurchaseCreate();

  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("purchase.create")}
            routes={[
              { path: "#", name: t("purchase.titleMenu") },
              { path: "/purchase-order", name: t("purchase.purchaseOrder") },
              { path: "/", name: t("purchase.create") },
            ]}
          />
        </Col>
        {dataState?.code && (
          <Col span={24}>
            <Descriptions colon={false}>
              <Descriptions.Item label={<Typography.Text strong>{t("purchase.reference")}</Typography.Text>}>
                <Link to={`/import-request/detail/${dataState?.id}`} target="_blank">
                  <Typography.Link strong>{dataState?.code}</Typography.Link>
                </Link>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
        <Col span={24}>
          <Row gutter={[16]}>
            <Col span={10}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("purchase.recommendedType")}</Typography.Text>,
                  name: "requestType",
                }}
                inputOptions={{
                  options: PURCHASE_TYPE_OPTIONS,
                  defaultValue: PURCHASE_REQUEST_TYPE.DOMESTIC,
                }}
              />
            </Col>
            <Col span={8}></Col>
            <Col span={6}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("purchase.importWarehouse")}</Typography.Text>,
                  name: "importWarehouseID",
                  rules: [{ required: true, message: t("purchase.rules.selectWarehouseImport") }],
                }}
                inputOptions={{
                  placeholder: t("purchase.placeHolder.selectWarehouseImport"),
                  options: warehouseOptions,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ProductsListLoadMore
            form={form}
            onGetProductList={handleGetProductList}
            productListSelected={productListSelected}
            handleAddCodeProduct={handleAddCodeProduct}
          />
        </Col>
        <Col span={24}>
          <PaymentInfo paymentTerms={paymentTerms} />
        </Col>
        <Col span={12}>
          <SupplierInfo dataState={dataState} />
        </Col>
        <Col span={12}>
          <AddInfo />
        </Col>
        <Col span={24}>
          <ButtonGroup
            onCreate={handleCreate}
            dataState={dataState}
            form={form}
            loadingCreatePurchase={loadingCreatePurchase}
            isShowFully={isShowFully}
            isShowPartially={isShowPartially}
            handleOpenFully={handleOpenFully}
            handleCancelFully={handleCancelFully}
            handleOpenPartially={handleOpenPartially}
            handleCancelPartially={handleCancelPartially}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default PurchaseCreate;
