import { Switch } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

import { SvgIcon } from "assets/icons";
import { CAMPAIGN_ACTIONS, PROMOTION_CAMPAIGN_STATUS } from "features/Campaign/constants";
import { useCampaign } from "features/Campaign/hooks";
import { useCampaignPermissions, useGetChangeActiveStatus } from "hooks/campaign";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import { ORDER_STATUS } from "pages/App/Order/conts";
import { joinArray, notify } from "utils/helperFuncs";

import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomModal from "components/CustomModal";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import LockComponent from "components/LockComponent";
import TextAlignLeft from "components/TextAlignLeft";
import { useGetCategoriesOptions } from "hooks/campaign/campaign";
import { useSalesChannelInputOptions } from "hooks/salesChannel";
import { useGetUserCurrent } from "hooks/auth/auth";
import { useGetStaff } from "hooks/warranty";

const useTable = ({
  onChangeParams,
  isStatusAll,
  handleSort,
  loadMoreDataSellerName,
  onSearchTextChange,
  sellerName,
  refetch,
}) => {
  const { dataManufactures } = useGetManufacturers();
  const { data: dataAllCategories } = useGetCategoriesOptions();
  const { convertedData: dataSalesChannel } = useSalesChannelInputOptions();
  const { userCurrentData } = useGetUserCurrent();

  const {
    ACTIONS_CAMPAIGN,
    STATUS_CAMPAIGN,
    TEXT_STATUS_CAMPAIGN,
    TYPE_CAMPAIGN,
    TEXT_TYPES_CAMPAIGN,
    ORDER_STATUS_FILTER,
  } = useCampaign();

  const { handleUpdateStatus, loading } = useGetChangeActiveStatus();
  const { canUpdateCampaign } = useCampaignPermissions();
  const [isModalCheckDuplicate, setIsModalCheckDuplicate] = useState(false);
  const [listPromotionDuplicateProducts, setListPromotionDuplicateProducts] = useState([]);

  const handleOpenModalCheckDuplicate = () => {
    setIsModalCheckDuplicate(true);
  };

  const handleCancelModalCheckDuplicate = () => {
    setIsModalCheckDuplicate(false);
  };

  const onOke = async (record) => {
    try {
      await handleUpdateStatus({
        promotionID: record?.id,
        isActive: !record?.isActive,
      }).then((res) => {
        const { result, listPromotionDuplicateProducts } = res?.data?.promotionCampaign?.changeActiveStatus;
        if (!result) {
          setListPromotionDuplicateProducts(listPromotionDuplicateProducts);
          handleOpenModalCheckDuplicate();
        } else {
          notify.success({
            message: t("customer.updateStatusSuccess"),
          });
        }
      });

      refetch();
    } catch (err) {
      notify.error({
        message: err?.message,
      });
    }
  };

  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  const convertOrderStatus = (status) => {
    switch (status) {
      case ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK:
        return t("order.orderStatus.waitingForApprovalStock");
      case ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK:
        return t("order.orderStatus.waitingForEnoughStock");
      case ORDER_STATUS.WAITING_FOR_PAYMENT:
        return t("order.orderStatus.waitingForPayment");
      case ORDER_STATUS.WAITING_FOR_PACKAGING:
        return t("order.orderStatus.waitingForPackaging");
      case ORDER_STATUS.WAITING_FOR_EXPORTING:
        return t("order.orderStatus.waitingForExporting");
      case ORDER_STATUS.WAITING_FOR_SHIPPING:
        return t("order.orderStatus.waitingForDelivery");
      case ORDER_STATUS.DELIVERING:
        return t("order.orderStatus.delivering");
      case ORDER_STATUS.COMPLETED:
        return <span className="order-completed">{t("order.orderStatus.completed")}</span>;
      case ORDER_STATUS.CANCELED:
        return <span className="order-cancel">{t("order.orderStatus.cancel")}</span>;
      default:
        return "";
    }
  };

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: undefined, status: "ACTIVE" },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: dataStaffOptions, hasMoreData, onLoadMoreData } = useGetStaff(paramsStaff);

  const onSearchStaff = (value) =>
    setParamsStaff({
      filters: { ...paramsStaff.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const all = {
    index: {
      title: <span>{t("campaign.stt")}</span>,
      dataIndex: "id",
      align: "center",
      width: 50,
      render: (_, _record, index) => index + 1,
    },
    nameCampaign: {
      title: t("campaign.nameCampaign"),
      dataIndex: "name",
      render: (_, record) => (
        <CustomTextHiddenLine text={record?.name} line={2} isLink={true} link={`campaign/detail/${record?.id}`} />
      ),
    },
    timeUpcoming: {
      title: t("campaign.timeUpcoming"),
      dataIndex: "timeRange",
      width: 220,
      render: (_, record) => (
        <>
          {record?.startDate} - {record?.endDate}
        </>
      ),
    },
    typeCampaign: {
      title: t("campaign.typeCampaign"),
      align: "center",
      dataIndex: "campaignType",
      render: (_, record) => {
        return <TextAlignLeft value={TEXT_TYPES_CAMPAIGN[`${record?.campaignType}`]} />;
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("types", value)} type="checkbox" options={TYPE_CAMPAIGN} />
      ),
    },
    channelBuy: {
      title: t("campaign.channelBuy"),
      dataIndex: "saleChannels",
      align: "center",
      render: (_, record) => {
        const channels = record?.saleChannels?.map((item) => item?.name);
        return <TextAlignLeft value={joinArray(channels)} />;
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("saleChannelIDs", value)}
          type="checkbox"
          options={dataSalesChannel}
        />
      ),
    },
    categories: {
      title: t("campaign.industry"),
      dataIndex: "appliedCategories",
      align: "center",
      render: (_, record) => {
        const categories = record?.appliedCategories?.map((item) => item?.name);
        return <TextAlignLeft value={joinArray(categories)} />;
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("categoryIDs", value)}
          type="checkbox"
          searchable
          options={dataAllCategories}
        />
      ),
    },
    brands: {
      title: t("campaign.brand"),
      align: "center",
      dataIndex: "appliedBrands",
      render: (_, record) => {
        const nameBrands = record?.appliedBrands?.map((item) => item?.name);
        return <TextAlignLeft value={joinArray(nameBrands)} />;
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("brandIDs", value)}
          type="checkbox"
          searchable
          options={dataManufactures}
        />
      ),
    },
    status: {
      title: t("campaign.status"),
      dataIndex: "status",
      render: (_, record) => {
        return TEXT_STATUS_CAMPAIGN[`${record?.status}`];
      },
      filterDropdown: isStatusAll && (
        <FilterMenu onFilter={(value) => onFilter("statuses", value)} type="checkbox" options={STATUS_CAMPAIGN} />
      ),
    },
    createdBy: {
      title: t("campaign.createdBy"),
      dataIndex: "createdBy",
      render: (_, record) => {
        return userCurrentData?.id === record?.createdByID
          ? `${userCurrentData?.fullname} (tôi)`
          : record?.createdBy?.fullname;
      },
      filterDropdown: (
        <FilterLoadMore
          onFilter={(values) => onFilter("createdByIDs", values)}
          data={dataStaffOptions}
          onLoadMoreData={onLoadMoreData}
          onSearchTextChange={onSearchStaff}
        />
      ),
    },
    action: {
      title: t("campaign.actions"),
      dataIndex: "isActive",
      fixed: "right",
      width: "50px",
      render: (_, record) => {
        return canUpdateCampaign ? (
          <CustomModal
            message={
              <p className="user-name">
                {record.isActive === CAMPAIGN_ACTIONS?.ACTIVE ? t("campaign.confirmOff") : t("campaign.confirmOn")}
              </p>
            }
            centered={true}
            footer={false}
            onOke={() => onOke(record)}
            customIcon={<LockComponent locked={record?.isActive} />}
            buttonLoading={loading}
            customComponent={
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={record?.isActive === CAMPAIGN_ACTIONS.ACTIVE}
              />
            }
          />
        ) : (
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={record?.isActive === CAMPAIGN_ACTIONS.ACTIVE}
            disabled={!canUpdateCampaign}
          />
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("isActive", value)} type="radio" options={ACTIONS_CAMPAIGN} />
      ),
    },
    createdAt: {
      align: "center",
      title: (
        <div className="title-table">
          {t("campaign.ranking.columns.createdAt")}
          <SvgIcon.SortIcon onClick={() => handleSort("CREATED_AT")} />
        </div>
      ),
      dataIndex: "createdAt",
      render: (_, record) => record?.createdAt,
    },
    orderCode: {
      align: "center",
      title: t("campaign.ranking.columns.orderCode"),
      dataIndex: "orderCode",
      render: (_, record) => (
        <Link to={`/order/${record?.orderID}`} className="code-dealer">
          {record?.orderCode}
        </Link>
      ),
    },
    orderValue: {
      align: "center",
      title: (
        <div className="title-table">
          {t("campaign.ranking.columns.orderValue")}
          <SvgIcon.SortIcon onClick={() => handleSort("AMOUNT")} />
        </div>
      ),
      dataIndex: "total",
      render: (_, record) => record?.total,
    },
    orderStatus: {
      width: "15%",
      align: "center",
      title: t("campaign.ranking.columns.orderStatus"),
      dataIndex: "status",
      render: (_, record) => convertOrderStatus(record?.status),
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("status", value)} type="checkbox" options={ORDER_STATUS_FILTER} />
      ),
    },
    purchasingAgent: {
      align: "center",
      title: t("campaign.ranking.columns.purchasingAgent"),
      dataIndex: "sellerName",
      render: (_, record) => record?.sellerName,
      filterDropdown: (
        <FilterLoadMore
          onFilter={(values) => onFilter("sellerIDs", values)}
          data={sellerName}
          onLoadMoreData={loadMoreDataSellerName}
          onSearchTextChange={onSearchTextChange}
        />
      ),
    },
    saleChannel: {
      title: t("campaign.channelBuy"),
      dataIndex: "saleChannel",
      align: "center",
      width: "8%",
      render: (_, record) => record?.saleChannel,
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("saleChannelIDs", value)}
          type="checkbox"
          options={dataSalesChannel}
        />
      ),
    },
  };

  const {
    index,
    nameCampaign,
    timeUpcoming,
    typeCampaign,
    brands,
    categories,
    channelBuy,
    status,
    createdBy,
    action,
    createdAt,
    orderCode,
    orderValue,
    orderStatus,
    purchasingAgent,
    saleChannel,
  } = all;

  const LIST_COLUMNS_TABLE = [
    index,
    nameCampaign,
    timeUpcoming,
    typeCampaign,
    brands,
    categories,
    channelBuy,
    status,
    createdBy,
    action,
  ];

  const RANKING_COLUMNS_TABLE = [index, createdAt, orderCode, orderValue, orderStatus, purchasingAgent, saleChannel];

  const renderColumns = (type) => {
    switch (type) {
      case PROMOTION_CAMPAIGN_STATUS.ALL:
      case PROMOTION_CAMPAIGN_STATUS.UPCOMING:
      case PROMOTION_CAMPAIGN_STATUS.ON_GOING:
      case PROMOTION_CAMPAIGN_STATUS.RECONCILING:
      case PROMOTION_CAMPAIGN_STATUS.ENDED:
        return LIST_COLUMNS_TABLE;
      default:
        return RANKING_COLUMNS_TABLE;
    }
  };

  return {
    renderColumns,
    isModalCheckDuplicate,
    listPromotionDuplicateProducts,
    handleCancelModalCheckDuplicate,
  };
};

export default useTable;
