import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { useGetCategoriesOptions } from "hooks/campaign/campaign";
import { useGetCategoriesSeller } from "hooks/seller";
import { getQuery } from "utils/helperFuncs";

function useCategoriesList() {
  const { id } = useParams();
  const GET_QUERY = getQuery();

  const { data, loading } = useGetCategoriesSeller({ id });
  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY?.query || "",
      attributeIDs: GET_QUERY?.attributeIDs || undefined,
      levels: GET_QUERY?.levels || undefined,
    },
  });

  const { data: dataAllCategories } = useGetCategoriesOptions();

  const optionsPriceLv = [
    {
      value: 1,
      label: t("common.levelFilter", { value: 1 }),
    },
    {
      value: 2,
      label: t("common.levelFilter", { value: 2 }),
    },
    {
      value: 3,
      label: t("common.levelFilter", { value: 3 }),
    },
    {
      value: 4,
      label: t("common.levelFilter", { value: 4 }),
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [searchSelect, setSearchSelect] = useState([]);
  const [searchLevel, setSearchLevel] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const convertTreeCate = (data) => {
    return data?.map(({ category, categoryID, level }) => {
      if (category?.parent?.parent) {
        return {
          children3: category?.name,
          children2: category?.parent?.name,
          level: level,
          parent: category?.parent?.parent?.name,
          id: categoryID,
        };
      }
      if (category?.parent === null) {
        return {
          parent: category?.name,
          level: level,
          id: categoryID,
        };
      } else {
        return {
          children2: category?.name,
          level: level,
          parent: category?.parent?.name,
          id: categoryID,
        };
      }
    });
  };

  const newData = convertTreeCate(data)?.filter(
    (item) =>
      String(item?.parent).toLowerCase().includes(searchTerm?.toLowerCase()) ||
      String(item?.children2).toLowerCase().includes(searchTerm?.toLowerCase()) ||
      String(item?.children3).toLowerCase().includes(searchTerm?.toLowerCase()) ||
      String(item?.level).toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const columns = [
    {
      title: t("common.index"),
      dataIndex: "id",
      align: "center",
      render: (_, _record, index) => index + 1,
    },
    {
      title: t("seller.categoriesList.cateParent"),
      dataIndex: "parent",
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => {
            const newSearchSelect = dataAllCategories
              ?.filter((item) => value.some((item2) => item2 === item?.value))
              ?.map((item) => item?.label);
            setSearchSelect(newSearchSelect);
          }}
          type="checkbox"
          searchable
          options={dataAllCategories}
        />
      ),
      filteredValue: searchSelect,
      onFilter: (value, record) => {
        return String(record?.parent).toLowerCase().includes(value?.toLowerCase());
      },
    },
    {
      title: t("seller.categoriesList.cateLv2"),
      align: "center",
      dataIndex: "children2",
    },
    {
      title: t("seller.categoriesList.cateLv3"),
      align: "center",
      dataIndex: "children3",
    },
    {
      align: "center",
      title: t("seller.categoriesList.priceLv"),
      dataIndex: "level",
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => {
            const newSearchSelect = newData
              ?.filter((item) => value.some((item2) => item2 === item?.level))
              ?.map((item) => item?.level);
            setSearchLevel(newSearchSelect);
          }}
          type="checkbox"
          searchable
          options={optionsPriceLv}
        />
      ),
      filteredValue: searchLevel,
      onFilter: (value, record) => {
        return String(record?.level).includes(value);
      },
    },
  ];

  return {
    columns,
    newData,
    loading,
    handleSearch,
    params,
    onChangeParams,
  };
}

export default useCategoriesList;
