import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

function SellerLink({ seller }) {
  if (!seller) {
    return "--";
  }
  return (
    <Link to={`/seller/detail/${seller?.sellerID || seller?.id}`}>
      <Typography.Link strong>{seller?.seller?.fullName}</Typography.Link>
    </Link>
  );
}

export default SellerLink;
