import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useEffect } from "react";

const useReportChart = (elId, chartData, fetchChartToken) => {
  useEffect(() => {
    if (!!chartData?.dashboardID) {
      embedDashboard({
        id: chartData?.dashboardID,
        supersetDomain: chartData?.domainUrl,
        mountPoint: document.getElementById(elId),
        fetchGuestToken: fetchChartToken,
        dashboardUiConfig: {
          hideTitle: true,
          hideTab: true,
          hideChartControls: true,
        },
      });
    }
  }, [chartData]);
};

export default useReportChart;
