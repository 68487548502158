import { useQuery } from "@apollo/client";
import { GET_BRANCH_QUERY } from "graphql/branch/query";
import { convertBranchQuery } from "./converter";

export const useGetBranchQuery = () => {
  const { data, loading, error, refetch } = useQuery(GET_BRANCH_QUERY);

  return {
    data: convertBranchQuery(data?.branch?.list),
    loading,
    error,
    refetch,
  };
};
