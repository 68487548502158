import React from "react";
import { List, Space, Typography } from "antd";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { Link } from "react-router-dom";
import PopupShowAllProduct from "../PopupShowAllProduct";

function ModalListProduct({ listProduct, itemPro, indexPro }) {
  return (
    <Space direction="vertical" style={{ display: "flex" }} size={12}>
      <List
        dataSource={listProduct?.slice(0, 3)}
        renderItem={(item, index) => (
          <List.Item
            style={{
              padding: "5px",
            }}
            key={index}
          >
            <Space direction="vertical">
              <CustomTextHiddenLine
                text={
                  <Link key={index} to={`/product/detail/${item?.id}`} target="_blank">
                    <Typography.Link strong>{item.code}</Typography.Link>
                  </Link>
                }
                line={1}
              />
              <CustomTextHiddenLine text={item?.name} line={1} />
            </Space>
          </List.Item>
        )}
      />
      <div
        style={{
          textAlign: "right",
          marginTop: "-7px",
        }}
      >
        <PopupShowAllProduct data={itemPro} index={indexPro} />
      </div>
    </Space>
  );
}

export default ModalListProduct;
