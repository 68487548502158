import { Button } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

export const SelectWarehouseToAll = ({ exportedWarehouseOptions, form }) => {
  const [warehouse, setWarehouse] = useState(null);
  const formData = form.getFieldsValue();
  const handleApplyToAll = () => {
    if (warehouse != null) {
      formData.items = formData.items.map((item) => {
        if (item.warrantyItem) {
          item.warrantyItem.warehouseID = warehouse;
        }
        return item;
      });
      form.setFieldsValue(formData);
    } else {
      notify.warning({
        message: t("Mời bạn chọn kho nhập lại!"),
      });
    }
  };
  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "end" }}>
      <FormInput
        type={INPUT_TYPE.SELECT}
        formItemOptions={{
          style: { width: "240px" },
        }}
        inputOptions={{
          options: exportedWarehouseOptions,
          placeholder: "Chọn kho nhập lại",
          onChange: (value) => {
            setWarehouse(value);
          },
        }}
      />
      <Button type="primary" onClick={() => handleApplyToAll()}>
        Áp dụng tất cả serial
      </Button>
    </div>
  );
};
