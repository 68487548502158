import { gql } from "@apollo/client";

export const GET_ALL_CARTS = gql`
  query GetAllCarts {
    cart {
      all {
        id
        name
      }
    }
  }
`;

export const GET_CART = gql`
  query GetCart($id: ID!) {
    cart {
      get(id: $id) {
        id
        sellerID
        seller {
          id
          fullName
          telephone
          code
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          debtLimit
          availabilityLimit
          isActive
          currentAvailable
          currentDebt
          warehouses {
            id
            code
            name
            address
            ward {
              name
            }
            district {
              name
            }
            city {
              name
            }
            branch {
              id
              name
            }
          }
          vatInfo {
            defaultExportVat
          }
        }
        exportedWarehouse {
          id
          name
        }

        items {
          id
          product {
            id
            code
            name
            warranty
            vat
            vatValue
            vatType
            isActive
            productType
            # shortCode
            uom {
              name
            }
            priceOfSeller {
              price
              level
            }
            stocks {
              productID
              quantity
              warehouse {
                id
                code
                name
                regionID
              }
            }
          }
          vat
          price
          priceLevel
          quantity
          total
          maxDaysOfDebt
          inStock
          customerPrice
          customerTotal
          productWarranty
        }
        subTotal
        totalProduct
        cashPayment
        debtPayment

        senderName
        senderPhone
        senderAddress

        allSenders {
          id
          fullName
          telephone
          address
          wardID
          districtID
          cityID
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          isDefault
        }

        shippingContact {
          id
          fullName
          telephone
          address
          ward {
            name
            id
          }
          district {
            name
          }
          city {
            name
          }
          wardID
          districtID
          cityID
        }

        note
        internalNote
        orderType
        shippingType
        orderPriority
        shippingConfiguration {
          busConfig {
            busName
            busStation
            telephone
            location
            fee
          }
          deliver3PartyConfig {
            name
            weight
            length
            width
            height
            fee
            serviceCode
            customFeeEnabled
            customFee
            providerID
            carrierMethod
            carrierMaxFee
            carrierMinFee
          }
          urbanConfig {
            fee
          }
        }
        deliveryDeadline
        fullStockRequired

        paymentMethod
        customerPaymentMethod
        vat
        vatAllOrder
        vatTaxIDNumber
        vatCompanyName
        vatEmail
        vatAddress

        shippingFee
        serviceFee
        temporaryServiceFee
        total
        discountAmount
        sendingWard {
          id
          district {
            id
            cityID
          }
        }
        customerSubTotal
        customerTotal
        assemblyRequired
        totalServiceProduct
        quantityServiceProduct
      }
    }
  }
`;

export const GET_SENDER = gql`
  query GetSender {
    user {
      me {
        senderContacts {
          id
          fullName
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          telephone
          isDefault
        }
      }
    }
  }
`;

export const GET_SHIPPING_PROVIDER = gql`
  query GetShippingProvider($serviceFeeInput: GetShippingServiceFeeInput!) {
    shippingProvider {
      servicesFee(serviceFeeInput: $serviceFeeInput) {
        method
        carrierMinFee
        carrierMaxFee
        providers {
          id
          code
          name
          fee
          logo_url
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_SHIPPING_PROVIDER_OLD = gql`
  query GetShippingProvider {
    shippingProvider {
      list {
        id
        name
        # getServiceFee {
        #   totalFee
        #   code
        #   name
        # }
      }
    }
  }
`;
