import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query GetContacts($sellerID: ID) {
    contact {
      list(sellerID: $sellerID) {
        id
        sellerID
        fullName
        telephone
        address
        ward {
          name
        }
        district {
          name
        }
        city {
          name
        }
        isDefault
      }
    }
  }
`;

export const GET_CONTACTS_ALL = gql`
  query GetContactsAll($filters: ContactFilters, $pagination: PaginationRequest! = { offset: 0, limit: 10 }) {
    contact {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        contacts {
          id
          sellerID
          fullName
          telephone
          address
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          isDefault
        }
      }
    }
  }
`;
