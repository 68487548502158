import { Col, Divider, Form, Row, Typography } from "antd";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { SHIPPING_TYPE } from "features/Cart/constants";
import { useGetShippingProviderNotConvert } from "hooks/cart";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { Fragment, useRef } from "react";
import { formatCurrency } from "utils/helperFuncs";
import Card from "../Card";
import ModalProducts from "../ModalProducts";
import PaymentMethods from "../PaymentMethods";
import SelectProduct from "../SelectProduct/Index";
import "./index.scss";
import useInfoProductAndBill from "./useInfoProductAndBill";

const InfoProductAndBill = ({
  data,
  handleUpdate,
  refetchCart,
  handleUpdateItem,
  handleAddNewItems,
  handleRemoveCartItem,
  handleVatAllItems,
  useInOrder,
}) => {
  const { listProducts, paymentByDebit, paymentByCash, exportedWarehouseID, sellerID } = data || {};
  const { columnsProductsInCart, paymentMethodsOptions, bill, isPaymentMethodByCash, idsProductsInCart } =
    useInfoProductAndBill({
      data,
      handleUpdate,
      refetchCart,
      handleUpdateItem,
      handleRemoveCartItem,
      handleVatAllItems,
      useInOrder,
    });

  const rightRef = useRef();
  const height = rightRef?.current?.clientHeight;

  const serviceFeeInput = { cartID: data?.id };
  const { data: dataShippingProvider } = useGetShippingProviderNotConvert({
    shippingType: data?.shippingType,
    serviceFeeInput: serviceFeeInput,
  });

  const renderShippingFee = () => {
    const deliver3PartyConfig = data.shippingConfiguration.deliver3PartyConfig;
    const getShippingMethod = dataShippingProvider?.find((item) => item.method === deliver3PartyConfig?.carrierMethod);

    switch (data.shippingType) {
      case SHIPPING_TYPE.URBAN_COD:
        return formatCurrency(data?.shippingConfiguration?.urbanConfig?.fee);
      case SHIPPING_TYPE.BUS:
        return formatCurrency(data?.shippingConfiguration?.busConfig?.fee);
      case SHIPPING_TYPE.PICK_UP_COUNTER:
        return formatCurrency(0);
      case SHIPPING_TYPE.DELIVER_3PARTY:
        if (deliver3PartyConfig.providerID == null) {
          return getShippingMethod?.carrierMinFee === getShippingMethod?.carrierMaxFee
            ? formatCurrency(getShippingMethod?.carrierMinFee)
            : `${formatCurrency(getShippingMethod?.carrierMinFee)} - ${formatCurrency(
                getShippingMethod?.carrierMaxFee
              )}`;
        } else {
          const getShippingProvider = getShippingMethod?.providers?.find(
            (item) => item.id === deliver3PartyConfig?.providerID
          );
          return formatCurrency(getShippingProvider?.fee);
        }
    }
  };
  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "#2246DC" }}>
          {t("cart.infoProductAndBill")}
        </Typography.Text>
      }
    >
      <Row gutter={16}>
        <Col span={15}>
          {(isEmpty(useInOrder) || useInOrder?.changeProductInCart) && (
            <Row gutter={16}>
              <Col span={9}>
                <SelectProduct
                  onChange={(value) => handleAddNewItems([value])}
                  warehouseID={data?.exportedWarehouseID}
                  sellerID={data?.sellerID}
                  handleAddNewItems={handleAddNewItems}
                  idsProductsInCart={idsProductsInCart}
                  exportedWarehouseID={data?.exportedWarehouseID}
                />
              </Col>
              <Col span={6}>
                <ModalProducts
                  handleAddNewItems={handleAddNewItems}
                  idsProductsInCart={idsProductsInCart}
                  warehouseID={exportedWarehouseID}
                  sellerID={sellerID}
                />
              </Col>
            </Row>
          )}

          <Form.List name="items">
            {(item, { remove }) => {
              return (
                <CustomTable
                  columns={columnsProductsInCart}
                  dataSource={listProducts}
                  rowKey={(record) => record.id}
                  scroll={{
                    x: 900,
                    y: +height,
                  }}
                />
              );
            }}
          </Form.List>
        </Col>
        <Col span={9}>
          <Row gutter={[16, 8]} ref={rightRef}>
            {/* <Col span={24}> */}
            <PaymentMethods paymentMethodsOptions={paymentMethodsOptions} data={data} handleUpdate={handleUpdate} />
            {/* </Col> */}
            <Col span={24} style={{ background: "#f7f8fb", padding: "12px", borderRadius: "8px" }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Typography.Text style={{ color: "#2246DC" }} strong>
                    {t("cart.bill")}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Row gutter={16}>
                    {bill
                      .filter(({ isHidden }) => !isHidden)
                      .map(({ title, value, hasAction }, index) => (
                        <Fragment key={index}>
                          <Col style={{ textAlign: "end" }} span={12}>
                            <Typography.Text>{title}</Typography.Text>
                          </Col>
                          <Col style={{ textAlign: "end" }} span={12}>
                            {value}
                          </Col>
                        </Fragment>
                      ))}
                    <Divider />

                    {isPaymentMethodByCash ? (
                      <>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong> {t("cart.debtPaymentAgent")}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong>{formatCurrency(paymentByCash)}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong> {t("cart.shippingFeeLabel")}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong>{formatCurrency(0)}</Typography.Text>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong> {t("cart.debtPaymentAgent")}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong>{formatCurrency(paymentByDebit)}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          <Typography.Text strong> {t("cart.shippingFeeLabel")}</Typography.Text>
                        </Col>
                        <Col style={{ textAlign: "end" }} span={12}>
                          {/* <Typography.Text strong>{formatCurrency(data?.shippingFee)}</Typography.Text> */}
                          <Typography.Text strong>{renderShippingFee()}</Typography.Text>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              {/* </Space> */}
            </Col>
            <Col span={24}></Col>
          </Row>

          <Row gutter={16} style={{ background: "#f7f8fb", borderRadius: "8px" }}>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{
                  name: "internalNote",
                  label: <b>{t("cart.internalNoteLabel")}</b>,
                  labelCol: { span: 24, offset: 0 },
                }}
                inputOptions={{
                  rows: 3,
                  placeholder: t("cart.internalNotePlaceholder"),
                  onBlur: () => handleUpdate(),
                }}
              />
            </Col>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{
                  name: "note",
                  label: <b>{t("cart.noteLabel")}</b>,
                  labelCol: { span: 24, offset: 0 },
                }}
                inputOptions={{
                  rows: 3,
                  placeholder: t("cart.notePlaceholder"),
                  onBlur: () => handleUpdate(),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoProductAndBill;
