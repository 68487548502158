import { gql } from "@apollo/client";

export const GET_LIST_IMPORT_RECOMMMEND = gql`
  query GetListImportRequests(
    $filters: ImportRequestsFilters
    $pagination: PaginationRequest = { offset: 0, limit: 10 }
    $sorts: [ImportRequestSort!]
  ) {
    importRequests {
      pagination(filters: $filters, pagination: $pagination, sorts: $sorts) {
        paginationData {
          total
          limit
          offset
        }
        importRequests {
          id
          code
          createdAt
          supplier {
            id
            fullName
          }
          waitingApprovalCount
          paymentMethods
          paymentTerms
          warehouse {
            id
            name
          }
          type
          status
          createdBy {
            id
            fullname
          }
          updatedBy {
            id
            fullname
          }
          rejectedReason
          items {
            product {
              id
              code
              name
            }
            order {
              id
              number
              items {
                product {
                  id
                  name
                  code
                }
                quantity
              }
            }
            productName
            quantity
            price
            currency
            warranty
            isVat
            warrantyPeriod
            creditDays
            expectedDeliveryTime
            importReason
            paymentNote
            serials {
              id
              code
            }
          }
        }
      }
    }
  }
`;

export const COUNT_STATUS_REQUET_IMPORT = gql`
  query CountStatusRequestImport($filters: ImportRequestsFilters) {
    importRequests {
      count(filters: $filters)
    }
  }
`;

export const GET_DETAIL_IMPORT_REQUEST = gql`
  query GetDetailImportRequest($id: ID!) {
    importRequests {
      get(id: $id) {
        id
        code
        type
        warehouse {
          id
          name
          code
        }
        createdBy {
          fullname
        }
        updatedBy {
          fullname
        }
        status
        rejectedReason
        waitingApprovalCount
        createdAt
        updatedAt
        supplier {
          id
          code
          fullName
          telephone
          address
          district {
            name
          }
          ward {
            name
          }
          city {
            name
          }
          bankAccounts {
            bank {
              name
            }
            bankAccountNumber
            name
            bankBranch
          }
        }
        paymentMethods
        paymentTerms
        paymentNotes
        processDeadline
        receiveMethod
        receivingAddress
        note
        fileURLs
        items {
          id
          product {
            id
            code
            name
          }
          order {
            id
            number
            exportedWarehouse {
              id
              name
            }
            items {
              product {
                id
                code
                name
              }
              quantity
            }
          }
          id
          productName
          quantity
          price
          currency
          isVat
          warranty
          warrantyPeriod
          creditDays
          expectedDeliveryTime
          paymentNote
          importReason
          serials {
            code
          }
        }
      }
    }
  }
`;
