import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Row, Space, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButton } from "components/PrimaryButton";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import IconWarning from "../IconWarning";
import { useCompleteOrderReconcilation } from "hooks/orderReconciliation/mutation";

function ButtonCompleteReconciliation({ data }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(false);

  const handleOpenModal = () => {
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const { handleCompleteOrderReconcilation, loading } = useCompleteOrderReconcilation();

  const handleConfirm = async () => {
    await form.validateFields().then(() => {
      handleCompleteOrderReconcilation(data?.id)
        .then(() => {
          notify.success({ message: t("v2.orderReconcilation.change_status.reconciliation_success") });
          setIsModal(false);
        })
        .catch((error) => {
          notify.error({ message: error.message });
        });
    });
  };

  return (
    <CustomModal
      footer={false}
      closable={false}
      visible={isModal}
      onOke={handleConfirm}
      onCancel={handleCloseModal}
      customComponent={
        <PrimaryButton
          title={t("v2.orderReconcilation.change_status.reconciliation_complete")}
          onClick={handleOpenModal}
        />
      }
      message={
        <IconWarning
          title={
            <Typography.Text strong>
              {t("v2.orderReconcilation.change_status.confirm_reconciliation_complete")}
            </Typography.Text>
          }
        />
      }
      buttonLoading={loading}
      children={
        <Row>
          <Col span={24}>
            <Typography.Text>{t("v2.orderReconcilation.change_status.tick_confirmation")}</Typography.Text>
            <div style={{ paddingLeft: 16, paddingTop: 8 }}>
              <Form form={form}>
                <FormInput
                  type="CHECK_BOX_GROUP"
                  formItemOptions={{
                    name: "options",
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("options") && value?.length === 2) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("v2.orderReconcilation.change_status.mandatory_check")));
                        },
                      }),
                    ],
                  }}
                  inputChildren={
                    <Space direction="vertical">
                      <Checkbox value="1">
                        <Trans
                          i18nKey="v2.orderReconcilation.change_status.order_eligibility"
                          values={{
                            order_number: data?.order?.number ?? "--",
                            seller_name: data?.order?.seller?.fullName ?? "--",
                          }}
                          components={{ link1: <Typography.Link strong />, link2: <Typography.Link strong /> }}
                        />
                      </Checkbox>
                      <Checkbox value="2">
                        <Trans
                          i18nKey="v2.orderReconcilation.change_status.no_return_within_7_days"
                          values={{
                            boldString: t("v2.orderReconcilation.change_status.no") ?? "--",
                          }}
                          components={{ bold: <b /> }}
                        />
                      </Checkbox>
                    </Space>
                  }
                />
              </Form>
            </div>
          </Col>
          <Col span={24}>
            <Space style={{ alignItems: "flex-start", color: "#5B6673", fontSize: 12 }}>
              <QuestionCircleOutlined />
              <span>
                {t("v2.orderReconcilation.change_status.sync_and_save")}
                <br />
                <div>
                  <SvgIcon.TagIcon /> {t("v2.orderReconcilation.change_status.debit_record")}
                </div>
                <div>
                  <SvgIcon.TagIcon /> {t("v2.orderReconcilation.change_status.credit_record")}
                </div>
              </span>
            </Space>
          </Col>
        </Row>
      }
    />
  );
}

export default ButtonCompleteReconciliation;
