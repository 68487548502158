import { gql } from "@apollo/client";

export const GET_WAREHOUSES = gql`
  {
    warehouse {
      list {
        warehouses {
          name
          code
          id
          branchID
          regionID
          branch {
            id
            name
          }
          district {
            name
          }
          ward {
            name
          }
          city {
            name
          }
          address
        }
      }
    }
  }
`;
