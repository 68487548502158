/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Select, Space } from "antd";
import { Icon } from "assets/icons";
import CustomTable from "components/CustomTable";
import { t } from "i18next";
import React, { useCallback, useMemo } from "react";
import { getQuery } from "utils/helperFuncs";
import { omit, has, isNil } from "lodash";
import useAccordingWarrantyRequest from "./useAccordingWarrantyRequest";
import CustomTags from "components/CustomTags";
import { warrantyRequestLabel } from "features/Warranty/constant";

const Tags = ({ handleCloseRangerTag, handleCloseStatusTag }) => {
  const GET_QUERY = getQuery();

  const tagFilter = omit(GET_QUERY, ["activeKey", "limit", "offset"]);

  const isRenderTagFilters = useMemo(
    () => has(tagFilter, "status") || has(tagFilter, "from") || has(tagFilter, "to"),
    [JSON.stringify(tagFilter.status), tagFilter.from, tagFilter.to]
  );

  const renderLabelRange = useCallback(() => {
    const { from, to } = tagFilter;

    if (isNil(from)) {
      return `${t("warranty.warrantyRequestList.serialIMEIAccordingWarrantyRequest")} : <=${to}`;
    }

    if (isNil(to)) {
      return `${t("warranty.warrantyRequestList.serialIMEIAccordingWarrantyRequest")} : >=${from}`;
    }

    return `${t("warranty.warrantyRequestList.serialIMEIAccordingWarrantyRequest")} : ${from} - ${to}`;
  }, [tagFilter.from, tagFilter.to]);

  const renderLabelStatus = useCallback(() => {
    const { status } = GET_QUERY;
    const convertStatus = status?.map((item) => warrantyRequestLabel[`${item}`]).join(", ");

    return `${t("warranty.warrantyRequestList.statusAccordingWarrantyRequest")} : ${convertStatus}`;
  }, [JSON.stringify(tagFilter.status)]);

  const rangerTag = <CustomTags label={renderLabelRange()} handleOnClose={handleCloseRangerTag} />;

  const statusTag = <CustomTags label={renderLabelStatus()} handleOnClose={handleCloseStatusTag} />;

  return (
    isRenderTagFilters && (
      <Col span={24}>
        <Space>
          {has(tagFilter, "from") && has(tagFilter, "to") && rangerTag}
          {has(tagFilter, "status") && statusTag}
        </Space>
      </Col>
    )
  );
};

const AccordingWarrantyRequest = () => {
  const {
    columns,
    data,
    handleChangeTable,
    paginationData,
    handleChangeInput,
    loadingWarrantyRequest,
    handleCloseStatusTag,
    handleCloseRangerTag,
    renderTags,
    valueFormSelectBrandId,
    branchID,
    onChangeBranchesId,
  } = useAccordingWarrantyRequest();

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Row style={{ justifyContent: "space-between" }}>
          <Col span={12}>
            <Input
              onChange={handleChangeInput}
              placeholder={t("warranty.warrantyRequestList.placeHolderAccordingWarrantyRequest")}
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            />
          </Col>
          <Col>
            <Select
              options={valueFormSelectBrandId}
              onChange={(e) => onChangeBranchesId(e)}
              value={branchID}
              style={{ minWidth: "170px" }}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}></Col>
      <Tags staffs={data} handleCloseStatusTag={handleCloseStatusTag} handleCloseRangerTag={handleCloseRangerTag} />
      <Col span={24}>
        <Space>{renderTags}</Space>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={columns}
          dataSource={data}
          onChange={handleChangeTable}
          rowKey={(record) => record?.id}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          loading={loadingWarrantyRequest}
        />
      </Col>
    </Row>
  );
};

export default AccordingWarrantyRequest;
