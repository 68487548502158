import { gql } from "@apollo/client";

export const CREATE_PURCHASE = gql`
  mutation CreatePurchase($purchaseOrder: CreatePurchaseOrderInput!) {
    purchaseOrder {
      create(purchaseOrder: $purchaseOrder) {
        id
      }
    }
  }
`;

export const CONFIRM_DEPOSIT_PURCHASE_ORDER = gql`
  mutation ConfirmDepositPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmDeposit(ids: $ids)
    }
  }
`;

export const CONFIRM_RECEIVED_PURCHASE_ORDER = gql`
  mutation ConfirmReceivedPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmReceived(ids: $ids)
    }
  }
`;

export const CONFIRM_APPROVE_CHECK_QUALITY_PURCHASE_ORDER = gql`
  mutation ConfirmApproveCheckQualityPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmApprovedCheckQuality(ids: $ids)
    }
  }
`;

export const CONFIRM_REJECT_CHECK_QUALITY_PURCHASE_ORDER = gql`
  mutation ConfirmRejectCheckQualityPurchaseOrder(
    $id: ID!
    $reasonInput: RejectOrderPurchaseInput!
    $items: [RejectPurchaseOrderItemInput!]
  ) {
    purchaseOrder {
      confirmRejectedCheckQuality(id: $id, reasonInput: $reasonInput, items: $items)
    }
  }
`;

export const CONFIRM_APPROVE_CHECK_INFO_PURCHASE_ORDER = gql`
  mutation ConfirmApproveCheckInfoPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmApprovedCheckInfo(ids: $ids)
    }
  }
`;

export const CONFIRM_REJECT_CHECK_INFO_PURCHASE_ORDER = gql`
  mutation ConfirmRejectCheckInfoPurchaseOrder(
    $id: ID!
    $reasonInput: RejectOrderPurchaseInput!
    $items: [RejectPurchaseOrderItemInput!]
  ) {
    purchaseOrder {
      confirmRejectedCheckInfo(id: $id, reasonInput: $reasonInput, items: $items)
    }
  }
`;

export const CONFIRM_PAYMENT_PURCHASE_ORDER = gql`
  mutation ConfirmPaymentPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmPayment(ids: $ids)
    }
  }
`;

export const CONFIRM_CANCEL_PURCHASE_ORDER = gql`
  mutation ConfirmCancelPurchaseOrder($ids: [ID!], $reasonInput: RejectOrderPurchaseInput!) {
    purchaseOrder {
      confirmCancel(ids: $ids, reasonInput: $reasonInput)
    }
  }
`;

export const CONFIRM_SAVED_DOCUMENT_PURCHASE_ORDER = gql`
  mutation ConfirmSavedDocumentPurchaseOrder($ids: [ID!]) {
    purchaseOrder {
      confirmSavedDocument(ids: $ids)
    }
  }
`;

export const SPLIT_PURCHASE_ORDER = gql`
  mutation SplitPurchaseOrder(
    $id: ID!
    $remainPurchaseOrder: SplitPurchaseOrderInput!
    $extraPurchaseOrder: SplitPurchaseOrderInput!
  ) {
    purchaseOrder {
      split(id: $id, remainPurchaseOrder: $remainPurchaseOrder, extraPurchaseOrder: $extraPurchaseOrder)
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation UpdatePurchase($id: ID!, $purchaseOrder: UpdatePurchaseOrderInput!, $isResubmit: Boolean = false) {
    purchaseOrder {
      update(id: $id, purchaseOrder: $purchaseOrder, isResubmit: $isResubmit)
    }
  }
`;

export const CONFIRM_IMPORT_SERIAL_PURCHASE = gql`
  mutation ConfirmImportedSerialPurchase($ids: [ID!]) {
    purchaseOrder {
      confirmImportedSerial(ids: $ids)
    }
  }
`;
