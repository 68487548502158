import { Button, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { useConfirmPaymentPurchaseOrder } from "hooks/purchase";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";
import "./index.scss";

export const ButtonApprovePayment = ({ record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const { handleConfirmPaymentPurchaseOrder, loading } = useConfirmPaymentPurchaseOrder();

  const onConfirm = async () => {
    try {
      await handleConfirmPaymentPurchaseOrder([record?.id])
        .then(() => {
          setVisible(false);
          notify.success({
            message: t("purchase.buttonGroup.confirmAcceptMessageSuccess"),
          });
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <CustomModal
        {...{
          width: "35%",
          customComponent: (
            <Button type="primary" loading={loading}>
              {t("order.orderDetail.approvedPayment")}
            </Button>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          className: "form-approve-payment-purchase-order",
          addMoreButton: (
            <div className="button-group-action-approve-payment-purchase-order">
              <Button type="secondary" htmlType="submit" onClick={handleCancel} loading={loading}>
                {t("productBrand.cancel")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onConfirm} loading={loading}>
                {t("common.confirm")}
              </Button>
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
          hideCancelButton: true,
        }}
      >
        <div className="form-approve-payment-content">
          <SvgIcon.IconInfoConfirm />
          <div>
            <Typography.Text>{t("purchase.buttonGroup.confirmApprovePaymentMessage")} </Typography.Text>
            <Typography.Link strong>{record?.code}</Typography.Link>?
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
