import { Checkbox, Col, Row, Tag, Typography } from "antd";
import CustomCard from "components/CustomCard";
import { PAYMENT_TERMS, paymentRequest, styleHeaderCardChildren, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";

function PaymentInfo({ data }) {
  return (
    <CustomCard
      headStyle={styleHeaderCardChildren}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.paymentInfo")}
        </Typography.Text>
      }
      content={
        <Row gutter={[16, 8]} style={{ fontSize: "13px" }}>
          <Col span={24}>
            <Typography.Text strong>{t("purchase.paymentMethods")}</Typography.Text>
            <div>
              <Typography.Text>
                {data?.paymentMethods?.map((item) => (
                  <Tag>{paymentRequest[item]}</Tag>
                ))}
              </Typography.Text>
            </div>
          </Col>
          <Col span={24}>
            <Typography.Text strong>{t("purchase.transferContent")}</Typography.Text>
            <div>
              <Typography.Text>{data?.transferNote}</Typography.Text>
            </div>
          </Col>
          <Col span={24}>
            <Typography.Text strong>{t("purchase.paymentPolicy")}</Typography.Text>
            <Row gutter={[16, 16]} style={{ padding: "5px 0" }}>
              <Col span={12}>
                <Checkbox
                  disabled
                  style={{ marginRight: "10px" }}
                  checked={data?.paymentTerms?.includes(PAYMENT_TERMS.ADVANCE_PAYMENT)}
                />
                <Typography.Text>{t("purchase.deposit")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{formatCurrency(data?.paymentDepositAmount)}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ padding: "5px 0" }}>
              <Col span={12}>
                <Checkbox
                  disabled
                  style={{ marginRight: "10px" }}
                  checked={data?.paymentTerms?.includes(PAYMENT_TERMS.CASH_ON_DELIVERY)}
                />
                <Typography.Text>{t("purchase.paymentOnDelivery")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{formatCurrency(data?.paymentOnDeliveryAmount)}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ padding: "5px 0" }}>
              <Col span={12}>
                <Checkbox
                  disabled
                  style={{ marginRight: "10px" }}
                  checked={data?.paymentTerms?.includes(PAYMENT_TERMS.DEFERRED_PAYMENT)}
                />
                <Typography.Text>{t("purchase.payLater")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>{formatCurrency(data?.paymentDebtAmount)}</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
}

export default PaymentInfo;
