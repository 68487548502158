import { Form, Radio, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import UploadFiles from "components/UploadFiles";
import { INPUT_TYPE } from "config/constants";
import { useLockKey } from "hooks/keyManagement/mutation";
import { useUploadDocument } from "hooks/upload-document";
import { t } from "i18next";
import { has } from "lodash";
import { useState } from "react";
import { getFile, notify } from "utils/helperFuncs";

function LockKey({ id }) {
  const [form] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [loading, setLoading] = useState(false);

  const { handleLockKey } = useLockKey();
  const { handleUpload } = useUploadDocument();

  const handleShowModal = () => {
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const desistOptions = [
    {
      label: "Key lỗi. Không đủ điều kiện bán",
      value: 1,
    },
    {
      label: "Key lỗi. Khách hàng yêu cầu trả lại",
      value: 2,
    },
    {
      label: "Khác",
      value: 3,
    },
  ];

  const handleCancel = () => {
    setIsModal(false);
  };

  const handleOpen = () => {
    setIsModal(true);
  };

  const convertReason = (value, otherReason) => {
    switch (value) {
      case 1:
        return "Key lỗi. Không đủ điều kiện bán";
      case 2:
        return "Key lỗi. Khách hàng yêu cầu trả lại";
      case 3:
        return otherReason;
      default:
        break;
    }
  };

  const handlerLockKey = async () => {
    try {
      setLoading(true);

      await form.validateFields().then(async (values) => {
        const resultFileURLs = await handleUpload({
          files: values?.fileURLs?.map((item) => item?.originFileObj),
        });

        await handleLockKey({
          id: id,
          lockReason: {
            reason: convertReason(values?.reason, values?.otherReason),
            fileURLs: resultFileURLs?.map((file) => file.url),
          },
        })
          .then(() => {
            handleCancel();
            notify.success({ message: "Khóa key license/ account thành công!" });
            form.resetFields();
            setLoading(false);
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
            setLoading(false);
            handleOpen();
          });
      });
    } catch (error) {
      console.info(error);
    } finally {
      setLoading(false);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "reason")) {
      if (changedValues.reason === 3) {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, otherReason: "" });
      }
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={isModal}
      closable={false}
      buttonLoading={loading}
      onOpen={handleShowModal}
      onCancel={handleCloseModal}
      onOke={handlerLockKey}
      customComponent={
        <PrimaryButtonOutLine
          title={t("v2.keyManagement.block")}
          primaryColor={"rgba(210, 67, 77, 1)"}
          restStyle={{ backgroundColor: "white" }}
        />
      }
      title={
        <Typography.Text strong style={{ fontSize: "20px" }}>
          Lý do khóa key license/ account
        </Typography.Text>
      }
      subTitle={
        <Typography.Text type="warning" style={{ fontSize: "13px", margin: "12px 0" }}>
          Lưu ý: Sau khi khóa thành công, hệ thống sẽ chuyển trạng thái của key license/ account sang “Đã vô hiệu” và
          nhân viên kinh doanh không thể tiếp tục bán mã này!
        </Typography.Text>
      }
      children={
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              label: <Typography.Text strong>Lý do khóa</Typography.Text>,
              name: "reason",
              style: { marginBottom: "12px" },
              rules: [
                {
                  required: true,
                  message: "Vui lòng chọn lý do khoá",
                },
              ],
            }}
            inputChildren={
              <Space direction="vertical">
                {desistOptions.map((item, index) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            }
          />
          {!hidden && (
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              formItemOptions={{
                name: "otherReason",
                rules: [
                  {
                    required: true,
                    message: t("order.cancelOrder.required_field"),
                  },
                ],
              }}
              inputOptions={{ placeholder: t("order.cancelOrder.otherPlaceholder"), maxLength: 255 }}
            />
          )}

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name={"fileURLs"}
            getValueFromEvent={getFile}
            valuePropName={"fileList"}
            label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
            extra={t("warrantyRequest.create.noteAttack")}
          >
            <UploadFiles />
          </Form.Item>
        </Form>
      }
    />
  );
}

export default LockKey;
