import { gql } from "@apollo/client";

export const WARRANTY_REQUEST_CREATE = gql`
  mutation CreateWarrantyRequest($request: WarrantyRequestInput) {
    warrantyRequest {
      create(request: $request) {
        id
        code
        warrantyCustomerName
        warrantyCustomerPhone
        warrantyCustomerAddress
        warrantyReceiveMethod
        branchID
        note
        totalItems
        createdByID
        createdBy {
          id
          fullname
        }
        status
      }
    }
  }
`;

export const WARRANTY_REQUEST_UPDATE = gql`
  mutation WarrantyRequestUpdate($id: ID!, $request: WarrantyRequestInput) {
    warrantyRequest {
      update(id: $id, request: $request)
    }
  }
`;

export const CONFIRM_CHECK = gql`
  mutation ConfirmCheck($warrantyRequestItemIDs: [ID!]) {
    warrantyRequest {
      confirmCheck(warrantyRequestItemIDs: $warrantyRequestItemIDs)
    }
  }
`;

export const CONFIRM_SOLUTION = gql`
  mutation ConfirmSolution(
    $warrantyRequestItemIDs: [ID!]
    $solution: WarrantyRequestItemSolution!
    $solutionRequest: SolutionRequestInput
  ) {
    warrantyRequest {
      confirmSolution(
        warrantyRequestItemIDs: $warrantyRequestItemIDs
        solution: $solution
        solutionRequest: $solutionRequest
      )
    }
  }
`;

export const CONFIRM_DISTRIBUTE = gql`
  mutation ConfirmDistribute(
    $warrantyRequestItemIDs: [ID!]
    $assignee: WarrantyRequestItemReceiveParty!
    $distributeRequest: DistributeRequestInput
  ) {
    warrantyRequest {
      confirmDistribute(
        warrantyRequestItemIDs: $warrantyRequestItemIDs
        assignee: $assignee
        distributeRequest: $distributeRequest
      )
    }
  }
`;

export const RECEIVE_RESULT = gql`
  mutation ReceiveResult($warrantyRequestItemID: ID!, $confirmResultRequest: ConfirmResultInput!) {
    warrantyRequest {
      receiveResult(warrantyRequestItemID: $warrantyRequestItemID, confirmResultRequest: $confirmResultRequest)
    }
  }
`;

export const CONFIRM_RETURN = gql`
  mutation ConfirmReturn(
    $warrantyRequestItemIDs: [ID!]
    $shippingType: ShippingType!
    $shippingConfig: ShippingConfigInput
  ) {
    warrantyRequest {
      confirmReturn(
        warrantyRequestItemIDs: $warrantyRequestItemIDs
        shippingType: $shippingType
        shippingConfig: $shippingConfig
      )
    }
  }
`;

export const COMPLETE = gql`
  mutation COMPLETE($warrantyRequestItemID: ID!, $deliverFileURLs: [String!]) {
    warrantyRequest {
      complete(warrantyRequestItemID: $warrantyRequestItemID, deliverFileURLs: $deliverFileURLs)
    }
  }
`;

export const EXPORT_FILE = gql`
  mutation exportFile(
    $warrantyRequestID: ID!
    $warrantyRequestItemIDs: [ID!]
    $noteType: WarrantyRequestNoteType!
    $fileType: ExportFileType!
  ) {
    warrantyRequest {
      exportFile(
        warrantyRequestID: $warrantyRequestID
        warrantyRequestItemIDs: $warrantyRequestItemIDs
        noteType: $noteType
        fileType: $fileType
      ) {
        url
      }
    }
  }
`;

export const UPDATE_SERIAL = gql`
  mutation UpdateSerial($id: ID!, $request: WarrantyRequestItemInput) {
    warrantyRequest {
      updateSerial(id: $id, request: $request)
    }
  }
`;

export const RETURN_WAITING_FOR_CHECK = gql`
  mutation ReturnWaitingForCheck($warrantyRequestItemID: ID!) {
    warrantyRequest {
      returnWaitingForCheck(warrantyRequestItemID: $warrantyRequestItemID)
    }
  }
`;
