import { QuestionCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import CustomDescriptions from "components/CustomDescriptions";
import { PAYMENT_METHOD } from "config/constants";
import { ORDER_STATUS, renderStringPaymentMethod, renderStringPaymentStatus } from "features/Order/constants";
import { t } from "i18next";
import { renderColorTagByPaymentStatus } from "pages/App/Order/conts";
import { formatCurrency } from "utils/helperFuncs";

const PaymentMethod = ({ dataInfoProductAndBill }) => {
  const { paymentMethod, customerPaymentMethod, orderType, paymentStatus, paymentInfo, seller, debtPayment, status } =
    dataInfoProductAndBill;

  return (
    <div style={{ background: "#F7F8FB", borderRadius: "8px", padding: "12px" }}>
      <CustomDescriptions
        loading={false}
        bordered={false}
        colon={false}
        labelWidth={"60%"}
        contentWidth={"40%"}
        labelStyle={{
          fontWeight: "normal",
        }}
        contentStyle={{
          justifyContent: "flex-end",
        }}
        paddingView="0 10px 0 10px"
        title={<DividerCustom fontWeight="bold" children={t("order.orderDetail.paymentMethod")} margin={0} />}
        contentMapping={[
          {
            label: (
              <Typography.Text>
                {renderStringPaymentMethod(paymentMethod)} <QuestionCircleOutlined />
              </Typography.Text>
            ),
            value: (
              <>
                {paymentMethod === PAYMENT_METHOD.Cash && (
                  <Typography.Text style={{ color: `${renderColorTagByPaymentStatus(paymentStatus)}` }}>
                    {renderStringPaymentStatus(paymentStatus)}
                  </Typography.Text>
                )}
                {paymentMethod !== PAYMENT_METHOD.Cash && (
                  <Typography.Text style={{ color: `${paymentInfo?.sellerConfirmPaid ? "green" : "orange"}` }}>
                    {paymentInfo?.sellerConfirmPaid ? t("bank_transfer.order.paid") : t("bank_transfer.order.pending")}
                  </Typography.Text>
                )}
              </>
            ),
          },
        ]}
      />

      <div style={{ width: "100%", padding: "0 8px" }}>
        {debtPayment > Math.abs(seller?.availabilityLimit) &&
          paymentMethod === PAYMENT_METHOD.Debt &&
          (status === ORDER_STATUS.WAITING_FOR_APPROVAL_ORDER ||
            status === ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK ||
            status === ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK ||
            status === ORDER_STATUS.WAITING_FOR_PAYMENT) && (
            <Typography.Text style={{ color: "orange" }}>
              {t("bank_transfer.order.debt_limits_not_enough")}
              {formatCurrency(seller?.availabilityLimit)}
            </Typography.Text>
          )}
      </div>
    </div>
  );
};

export default PaymentMethod;
