/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { filter, isNull, isUndefined, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { WARRANTY_STATUS } from "features/Warranty/constant";
import { useConfirmImportedSerialPurchaseOrder, useGetPurchaseOrderReferences } from "hooks/purchase";
import { useCreateSerialImport } from "hooks/serial";
import { useWarehouse } from "hooks/warehouse";
import { useGetWarrantyTablePending } from "hooks/warranty";
import { notify } from "utils/helperFuncs";
import { IMPORT_TYPE } from "../../constant";

function useCreateSerialPurchaseOrder() {
  const [form] = useForm();
  const history = useHistory();
  const location = useLocation();
  const dataState = { ...location.state };
  const [importType, setImportType] = useState(IMPORT_TYPE.FIRST_TIME_IMPORT);
  const [providerInfo, setProviderInfo] = useState(dataState?.seller);
  const [dataTableProduct, setDataTableProduct] = useState([]);
  const [dataTableOrder, setDataTableOrder] = useState([]);
  const [tags, setTags] = useState([]);
  const { data: dataReferences } = useGetPurchaseOrderReferences({ id: dataState?.id });

  const initialValues = {
    importType: importType,
    providerID: null,
    sellerID: null,
    warehouseID: null,
    orderID: null,
    note: null,
    fileURLs: null,
    items: [],
  };

  const [serials, setSerials] = useState([]);
  const [warrantyRequestItems, setWarrantyRequestItems] = useState([]);

  const serialInfo = warrantyRequestItems[0];
  //
  const { data: warrantyNotes, loading: pendingWarrantyNoteLoading } = useGetWarrantyTablePending({
    filters: {
      query: null,
      status: [WARRANTY_STATUS.STILL_VALIDATED, WARRANTY_STATUS.INVALIDATED],
      serials: serials,
    },
    pagination: {
      offset: 0,
      limit: serials?.length,
    },
    sort: [],
  });

  const serverSerialToWarrantyNote = useMemo(() => {
    return warrantyNotes.reduce((agg, curr) => {
      agg[curr?.serialImei] = curr;
      return agg;
    }, {});
  }, [warrantyNotes]);

  useEffect(() => {
    // merge fields

    const newItems = serials?.map((serialItem) => {
      return {
        ...(serverSerialToWarrantyNote[serialItem] || {}),
      };
    });

    setWarrantyRequestItems(newItems);
    const formData = form.getFieldsValue();
    const serialToItem = formData.items?.reduce((agg, curr) => {
      agg[curr.serialItems] = curr;
      return agg;
    }, {});

    const formItems = newItems.map((item) => {
      const currentItem = serialToItem[item?.serialImei] || {};
      const newValues = {
        productID: item?.product?.id,
        serialItems: item?.serialImei,
        warrantyOnSale: item?.warrantyOnSale,
        warrantyUnit: item?.warrantyDateUnit,
        // soldDateFromProvider: item?.soldDate,
        warrantyItem: {
          warrantyNoteID: item?.id,
          ...currentItem?.warrantyItem,
        },
        quantity: 1,
      };

      return newValues;
    });

    const newArr = uniqBy([...formItems], "serialItems");

    const filteredArr = filter(newArr, (obj) => !isNull(obj.serialItems) && !isUndefined(obj.serialItems));

    form.setFieldsValue({
      ...formData,
      items: filteredArr,
    });
  }, [JSON.stringify(serials), JSON.stringify(warrantyNotes)]);

  const handleGetTags = (values) => {
    setTags(values);
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.importType) {
      setImportType(changedValues?.importType);
      setVisible(false);
    }
    if (changedValues?.items) {
      const newData = dataTableProduct?.map((item, index) => {
        return {
          ...item,
          quantity: allValues.items[index].quantity,
          serialItems: allValues.items[index].serialItems,
          lack: allValues.items[index].quantity - allValues.items[index].serialItems.length,
          scanned:
            allValues.items[index].quantity -
            (allValues.items[index].quantity - allValues.items[index].serialItems.length),
        };
      });
      setDataTableProduct(newData);
    }
  };

  const handleGetTableOrder = (data) => {
    setDataTableOrder(data);
  };

  const { warehouses } = useWarehouse();

  const { handleCreateSerialImport, loadingCreate } = useCreateSerialImport();

  const [loadingCreateBtn, setLoadingCreateBtn] = useState(loadingCreate);

  const exportedWarehouseOptions = warehouses?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const handleRemoveSerial = (serial) => {
    const filteredSerials = serials?.filter((item) => item !== serial);
    setSerials(filteredSerials);
  };

  const handleRemoveProduct = (id) => {
    const newArr = dataTableProduct?.filter((item) => item?.id !== id);
    return setDataTableProduct(newArr);
  };

  const onCancelCreate = () => {
    history.push(`/purchase/detail/${dataState?.id}`);
  };

  const [visible, setVisible] = useState(false);

  const showOnModalConfirmCreate = () => {
    setVisible(true);
  };
  const showOffModalConfirmCreate = () => {
    setVisible(false);
  };

  const handleFinish = async () => {
    await form.validateFields().then((values) => {
      showOffModalConfirmCreate();
      values?.items?.length
        ? showOnModalConfirmCreate()
        : notify.warning({ message: t("serialIE.notify.warningCreate") });
    });
  };

  const { handleConfirmImportedSerialPurchaseOrder } = useConfirmImportedSerialPurchaseOrder();

  const handleCreate = async () => {
    const values = await form.getFieldsValue();
    setLoadingCreateBtn(true);
    try {
      const params = {
        ...values,
        sellerID: serialInfo?.seller?.id,
        fileURLs: values?.fileURLs,
        items: values?.items,
      };
      if (values?.items?.length) {
        await form.validateFields();
        try {
          const res = await handleCreateSerialImport({ request: params });
          const idParams = res?.data?.serialImportNotes?.create?.id;
          if (idParams) {
            handleConfirmImportedSerialPurchaseOrder({ ids: dataState?.id });
            notify.success({ message: t("serialIE.notify.successCreate") });
            history.push(`/purchase/detail/${dataState?.id}`);
          }
        } catch (error) {
          notify.error({ message: error?.message });
        }
      }
    } catch (error) {
      console.info(error);
    } finally {
      setLoadingCreateBtn(false);
      showOffModalConfirmCreate();
    }
  };

  const listFileURL = dataState?.fileURLs?.map((url) => {
    return {
      url: url,
    };
  });
  useEffect(() => {
    form.setFieldsValue({
      orderID: null,
      note: dataState?.internalNote,
      fileURLs: listFileURL,
      importType: IMPORT_TYPE.FIRST_TIME_IMPORT,
      items: dataState?.items?.map((item) => ({
        product: item?.product,
        quantity: item?.quantity,
        warranty: item?.warranty,
        warrantyPeriod: item?.warrantyPeriod,
        serialItems: [],
      })),
    });
    setDataTableProduct(
      dataState?.items?.map((item) => ({
        product: item?.product,
        quantity: item?.quantity,
        warranty: item?.warranty,
        warrantyPeriod: item?.warrantyPeriod,
        serialItems: [],
      }))
    );
    setProviderInfo(dataState?.seller);
  }, [JSON.stringify(dataState)]);

  return {
    form,
    exportedWarehouseOptions,
    initialValues,
    providerInfo,
    importType,
    dataTableProduct,
    dataTableOrder,
    loadingCreateBtn,
    loadingCreate,
    tags,
    visible,
    warrantyRequestItems,
    pendingWarrantyNoteLoading,
    handleRemoveSerial,
    handleRemoveProduct,
    handleValuesChange,
    handleFinish,
    handleGetTags,
    onCancelCreate,
    handleCreate,
    showOffModalConfirmCreate,
    handleGetTableOrder,
    dataState,
    dataReferences,
  };
}

export default useCreateSerialPurchaseOrder;
