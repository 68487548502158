import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButton } from "components/PrimaryButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency, notify } from "utils/helperFuncs";
import IconQuestion from "../IconQuestion";
import SpaceCustom from "../SpaceVertical";
import { useStartOrderReconcilation } from "hooks/orderReconciliation/mutation";

function ButtonStartReconciliation({ data }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const { handleStartOrderReconcilation, loading } = useStartOrderReconcilation();

  const handleConfirm = async () => {
    handleStartOrderReconcilation([data?.id])
      .then(() => {
        notify.success({ message: t("v2.orderReconcilation.change_status.message_success_start") });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({ message: error.message });
      });
  };

  return (
    <CustomModal
      footer={false}
      visible={visible}
      onOke={handleConfirm}
      onCancel={closeModal}
      closable={false}
      customComponent={
        <PrimaryButton title={t("v2.orderReconcilation.change_status.start_reconcilation")} onClick={handleOpenModal} />
      }
      message={
        <IconQuestion
          title={
            <SpaceCustom style={{ justifyContent: "center" }}>
              <Typography.Text>{t("v2.orderReconcilation.change_status.question_start")}</Typography.Text>
              <span>
                <Typography.Link strong>{`${data?.order?.number || "--"}`}</Typography.Link> ?
              </span>
            </SpaceCustom>
          }
        />
      }
      buttonLoading={loading}
      children={
        <Space style={{ alignItems: "flex-start", color: "#5B6673", fontSize: 12 }}>
          <QuestionCircleOutlined />
          <span>
            {t("v2.orderReconcilation.change_status.warning_start", {
              total: formatCurrency(data?.order?.customerTotal),
            })}
          </span>
        </Space>
      }
    />
  );
}

export default ButtonStartReconciliation;
