import { Col, Row, Spin, Tooltip, Typography } from "antd";
import CustomInfoAddress from "components/CustomInfoAddress";
import SelectLoadMore from "components/CustomSelect/SelectLoadMore";
import { useGetSellerCreateCart } from "hooks/seller";
import { t } from "i18next";
import { useState } from "react";

import { checkOptionDuplicate } from "utils/helperFuncs";
import "./index.scss";

function CustomSelectSellerLoadMore({ formName, onGetSellerInfo, codeSellerEdit = null }) {
  const [paramsSeller, setParamsSeller] = useState({
    filters: {
      query: null,
      isSupplier: true,
      isActiveSupplier: true,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { sellers, onLoadMoreData, hasMoreData, loading } = useGetSellerCreateCart(paramsSeller);

  const { sellers: sellerEdit } = useGetSellerCreateCart({
    filters: {
      query: codeSellerEdit,
      isSupplier: true,
    },
  });

  const handleChangeInfo = (value, option) => {
    onGetSellerInfo && onGetSellerInfo(option?.seller);
  };

  const onSearchSeller = (value) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: value || null, isSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const combinedArray = (codeSellerEdit !== null ? sellerEdit : []).concat(sellers || []);

  const sellerOptions = checkOptionDuplicate(combinedArray)?.map((item) => ({
    value: item?.value,
    label: (
      <Row gutter={16}>
        <Col span={5}>
          <Typography.Text style={{ color: "#2246DC" }} strong>
            {item?.code}
          </Typography.Text>
        </Col>
        <Col span={7}>
          <Tooltip title={item?.sellername}>
            <Typography.Text ellipsis>{item?.sellername}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={4}>
          <Typography.Text>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={8}>
          <Tooltip title={<CustomInfoAddress value={item} />}>
            <Typography.Text ellipsis>{<CustomInfoAddress value={item} />}</Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    seller: item,
    labelSeller: item?.sellername,
  }));

  return (
    <SelectLoadMore
      formItemOptions={{
        name: formName,
        rules: [{ required: true, message: t("purchase.rules.selectSupplier") }],
      }}
      inputOptions={{
        placeholder: t("purchase.placeHolder.searchByCodeOrNameSupplier"),
        options: sellerOptions,
        optionLabelProp: "labelSeller",
        dropdownMatchSelectWidth: 1000,
        onSelect: (value, options) => handleChangeInfo(value, options),
        onClear: () => onGetSellerInfo && onGetSellerInfo(null),
        dropdownRender: (originNode) => (
          <>
            <Row gutter={16} className="custom-select-seller">
              <Col className="header-select" span={5}>
                <Typography.Text className="text-header">{t("supplier.table.supplierCode")}</Typography.Text>
              </Col>
              <Col className="header-select" span={7}>
                <Typography.Text className="text-header">{t("supplier.table.supplierName")}</Typography.Text>
              </Col>
              <Col className="header-select" span={4}>
                <Typography.Text className="text-header">{t("supplier.table.phoneNumber")}</Typography.Text>
              </Col>
              <Col className="header-select" span={8}>
                <Typography.Text className="text-header">{t("supplier.table.address")}</Typography.Text>
              </Col>
            </Row>
            <Spin spinning={loading}>{originNode}</Spin>
          </>
        ),
        dropdownStyle: { zIndex: "1000" },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearchSeller}
    />
  );
}

export default CustomSelectSellerLoadMore;
