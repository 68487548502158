import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import CommonCard from "components/CommonCard";
import CreateButton from "components/CreateButton";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency, getFile } from "utils/helperFuncs";

const FORM_ITEM_STYLE = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
  labelAlign: "left",
  colon: false,
};

const initValues = {
  accountNumber: "123456789",
  bank: "Vietcombank",
  receiveMethod: "transfer",
  actualAmount: 150000000,
  transactionImg: [
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-2",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-3",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-4",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ],
  reason: "Nhận tiền thu hộ của đơn hàng BH20221123#1",
};

const Edit = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const handleSave = async () => {
    await form.validateFields();
  };

  useEffect(() => {
    form.setFieldsValue({ ...initValues });
  }, []);

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          // pageTitle={t('common.codManagement')}
          routes={[
            {
              path: "",
              name: t("common.accountant"),
            },
            {
              path: "/cod-management",
              name: t("common.codManagement"),
            },
            {
              path: "",
              name: t("accountant.codManagement.edit"),
            },
          ]}
        />
      </Col>

      <Col span={6} offset={12}>
        <Typography style={{ fontSize: "12px" }}>
          <Typography.Text style={{ color: "rgba(120, 120, 120, 1)" }}>
            {t("accountant.createBy")}: Phan Thành&nbsp;
          </Typography.Text>
          <Typography.Text italic style={{ color: "rgba(38, 47, 59, 0.44)" }}>
            3 ngày trước
          </Typography.Text>
        </Typography>
      </Col>

      <Col span={6}>
        <Typography style={{ fontSize: "12px" }}>
          <Typography.Text style={{ color: "rgba(120, 120, 120, 1)" }}>
            {t("accountant.updateBy")}: Phan Thành&nbsp;
          </Typography.Text>
          <Typography.Text italic style={{ color: "rgba(38, 47, 59, 0.44)" }}>
            19 giờ trước
          </Typography.Text>
        </Typography>
      </Col>

      <Col span={24}>
        <Card
          title={
            <Typography.Text strong style={{ color: "#2246DC" }}>
              {t("accountant.infoBill")}
            </Typography.Text>
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={8} style={{ borderRight: "0.5px dashed #9cadc4" }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t("accountant.paymentDate")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>25/11/2022</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t("accountant.receiptsCode")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>PTT1234567</Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[16, 16]} style={{ borderRight: "0.5px dashed #9cadc4" }}>
                <Col span={12}>
                  <Typography.Text>{t("accountant.orderCode")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong style={{ color: "#2246DC" }}>
                    BH20221123
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t("accountant.statusOrder")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>Hoàn thành</Typography.Text>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Typography.Text>{t("accountant.agentCode")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>MST11111111</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>{t("accountant.agentName")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>Đại lý thanh thảo</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      <Form form={form}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Card
              title={
                <Typography.Text strong style={{ color: "#2246DC" }}>
                  {t("accountant.collectionAmountInformation")}
                </Typography.Text>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={12} style={{ borderRight: "0.5px dashed #9cadc4" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <FormInput
                        type={INPUT_TYPE.SELECT}
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: t("accountant.accountReceivingMoney"),
                          name: "accountNumber",
                          rules: [
                            {
                              required: true,
                              message: t("common.validateRequired"),
                            },
                          ],
                        }}
                        inputOptions={{
                          options: [{ label: "123456789", value: "123456789" }],
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <FormInput
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: t("accountant.bank"),
                          name: "bank",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <FormInput
                        type={INPUT_TYPE.SELECT}
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: t("accountant.receiveMoneyMethod"),
                          name: "receiveMethod",
                          rules: [
                            {
                              required: true,
                              message: t("common.validateRequired"),
                            },
                          ],
                        }}
                        inputOptions={{
                          options: [
                            { label: "Tiền mặt", value: "cash" },
                            { label: "Chuyển khoản", value: "transfer" },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item {...FORM_ITEM_STYLE} label={t("accountant.amountToBeCollected")}>
                        <Typography.Text strong>{formatCurrency(150000000)}</Typography.Text>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <FormInput
                        type={INPUT_TYPE.CURRENCY_INPUT}
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: t("accountant.actualAmount"),
                          name: "actualAmount",
                          rules: [
                            {
                              required: true,
                              message: t("common.validateRequired"),
                            },
                          ],
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <FormInput
                        type={INPUT_TYPE.IMAGE_UPLOAD}
                        formItemOptions={{
                          ...FORM_ITEM_STYLE,
                          label: t("accountant.transactionDetails"),
                          name: "transactionImg",
                          valuePropName: "fileList",
                          getValueFromEvent: getFile,
                        }}
                        inputOptions={{
                          multiple: true,
                          showUploadList: {
                            showPreviewIcon: false,
                          },
                        }}
                        inputChildren={
                          <Space>
                            <PlusOutlined />
                          </Space>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <CommonCard>
              <Row gutter={16} style={{ padding: "12px" }}>
                <Col span={24}>
                  <FormInput
                    type={INPUT_TYPE.TEXT_AREA}
                    formItemOptions={{
                      ...FORM_ITEM_STYLE,
                      labelCol: { span: 6 },
                      wrapperCol: { span: 18 },
                      label: t("accountant.reasonForCollectingMoney"),
                      name: "reason",
                    }}
                    inputOptions={{}}
                  />
                </Col>
              </Row>
            </CommonCard>
          </Col>
        </Row>
      </Form>

      <Col
        span={24}
        style={{
          padding: 0,
        }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "end",
            background: "#ffffff",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <Button onClick={handleSave}>{t("common.cancel")}</Button>
          <CreateButton />
        </Space>
      </Col>
    </Row>
  );
};

export default Edit;
