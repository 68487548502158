import { useQuery } from "@apollo/client";
import { GET_CONTACTS, GET_CONTACTS_ALL } from "graphql/contact/query";
import { convertGetContact } from "./converter";

export const useContacts = (params) => {
  const { data, loading, refetch } = useQuery(GET_CONTACTS, {
    variables: params,
    skip: params?.sellerID === undefined,
  });
  return {
    data: convertGetContact(data?.contact?.list),
    loading,
    refetch,
  };
};

export const useGetContactsAll = (params, { sellerID }) => {
  const { data, loading, refetch } = useQuery(GET_CONTACTS_ALL, {
    variables: {
      ...params,
      filters: {
        ...params?.filters,
        sellerID: sellerID,
      },
    },
  });

  return {
    data: convertGetContact(data?.contact?.pagination?.contacts),
    loading,
    refetch,
    paginationData: data?.contact?.pagination?.paginationData,
  };
};
