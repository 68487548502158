import { Button, Col, Form, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { INPUT_TYPE, REGEX } from "config/constants";
import { CAUSE_REJECT } from "features/Purchase/constant";
import { useConfirmCancelPurchaseOrder } from "hooks/purchase";
import { t } from "i18next";
import { useState } from "react";
import { getFile, notify } from "utils/helperFuncs";
import "./index.scss";

export const ButtonCancel = ({ record }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isRulesOther, setIsRulesOther] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
    setIsRulesOther(false);
  };
  const { handleConfirmCancelPurchaseOrder } = useConfirmCancelPurchaseOrder();

  const handleValuesChange = (changedValue, allValues) => {
    const isOther = allValues?.causes?.some((item) => item === "OTHER");
    setIsRulesOther(isOther);
  };

  const onCancel = async () => {
    try {
      const values = await form.validateFields(null, { force: true });
      setLoading(true);
      await handleConfirmCancelPurchaseOrder({
        ids: record?.id,
        reasonInput: {
          reason: values?.reason,
          fileURLs: values?.fileURLs,
          causes: values?.causes?.filter((item) => item !== "OTHER"),
        },
        items: values?.items,
      })
        .then(() => {
          setVisible(false);
          setLoading(false);
          notify.success({
            message: "Hủy đơn mua hàng thành công",
          });
        })
        .catch((error) => {
          setLoading(false);

          notify.error({
            message: error.message,
          });
        });
    } catch (e) {
      console.error(e);
    }
  };

  const causesOptions = [
    {
      value: CAUSE_REJECT.CAUSE_SUPPLIER_INFO_IS_INCORRECT,
      label: "Thông tin nhà cung cấp không đúng",
    },
    {
      value: CAUSE_REJECT.CAUSE_WRONG_DELIVERED_PRODUCT,
      label: "Giao sai mã hàng hóa",
    },
    {
      value: CAUSE_REJECT.CAUSE_WRONG_PRODUCT_QUANTITY,
      label: "Số lượng giao sai",
    },
    {
      value: CAUSE_REJECT.CAUSE_DELIVERY_NOTE_AND_ORDER_INFO_DIFFERENT,
      label: "Thông tin phiếu giao hàng và đơn mua hàng khác nhau",
    },
    {
      value: CAUSE_REJECT.CAUSE_NOT_QUALIFIED_QUALITY,
      label: "Chất lượng hàng hóa không đạt",
    },
    {
      value: "OTHER",
      label: (
        <Row align="center" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span>Khác</span>
          {isRulesOther && (
            <FormInput
              {...{
                type: INPUT_TYPE.INPUT,
                formItemOptions: {
                  name: "reason",
                  rules: [
                    { required: isRulesOther, message: "Không được để trống trường này" },
                    {
                      pattern: REGEX.TEXT_TRIM_SPACE,
                      message: t("supplier.placeholderInput.whiteSpace"),
                    },
                  ],
                },
                inputOptions: {
                  maxLength: 255,
                  min: 0,
                  placeholder: "Vui lòng nhập lý do",
                  style: { marginBottom: "0px" },
                },
              }}
            />
          )}
        </Row>
      ),
    },
  ];

  return (
    <Form form={form} name="validate_other" onValuesChange={handleValuesChange}>
      <CustomModal
        {...{
          width: "50%",
          title: (
            <span style={{ fontSize: "18px" }}>
              <Typography.Text strong>{"Lý do huỷ đơn mua hàng"}</Typography.Text>{" "}
            </span>
          ),
          customComponent: (
            <Button type="secondary" loading={loading}>
              {"Huỷ đơn mua"}
            </Button>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              <Button type="secondary" htmlType="submit" onClick={handleCancel} loading={loading}>
                {"Huỷ bỏ"}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onCancel} loading={loading}>
                {"Xác nhận"}
              </Button>
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
          className: "form-cancel-check-info-purchase-order",
        }}
      >
        <div className="form-check-info-purchase-order">
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.CHECK_BOX_GROUP,
                formItemOptions: {
                  label: <b>{"Lý do hủy đơn mua hàng"}</b>,
                  labelCol: { span: 24, offset: 0 },
                  name: "causes",
                  rules: [
                    {
                      required: true,
                      message: "Vui lòng chọn lý do huỷ đơn mua hàng",
                    },
                  ],
                },
                inputOptions: {
                  options: causesOptions,
                  style: {
                    display: "inline-flex",
                    flexDirection: "column",
                    gap: 15,
                    marginTop: 12,
                  },
                },
              }}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              name={"fileURLs"}
              getValueFromEvent={getFile}
              valuePropName={"fileList"}
              label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
              extra={t("warrantyRequest.create.noteAttack")}
            >
              <UploadFiles />
            </Form.Item>
          </Col>
        </div>
      </CustomModal>
    </Form>
  );
};
