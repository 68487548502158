import { STATUS_ORDER } from "config/constants";
import i18n from "locales/i18n";
import { omit } from "lodash";
import { buildQueryString } from "utils/helperFuncs";

export const PROFIT_RECONCILIATION = {
  ALL: "ALL",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  RECONCILING: "RECONCILING",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
};

export const formatBuildQueryString = (values) => {
  buildQueryString({
    params: {
      ...omit(values?.filters, "timeRange"),
      ...values?.pagination,
      from: values?.from,
      to: values?.to,
      activeKey: values.activeKey === "ALL" ? "ALL" : values.activeKey,
    },
  });
};

export const reconciliationStatusConverter = (value) => {
  switch (value) {
    case PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT:
      return {
        status: "warning",
        text: "Chờ thanh toán",
      };
    case PROFIT_RECONCILIATION.RECONCILING:
      return {
        status: "processing",
        text: "Đang đối soát",
      };
    case PROFIT_RECONCILIATION.COMPLETED:
      return {
        status: "success",
        text: "Hoàn thành",
      };
    case PROFIT_RECONCILIATION.REJECTED:
      return {
        status: "error",
        text: "Đã từ chối",
      };
    case PROFIT_RECONCILIATION.CANCELED:
      return {
        status: "error",
        text: "Đã hủy",
      };
    default:
      break;
  }
};

export const PAYMENT_METHOD = {
  DEBT: "DEBT",
  WALLET: "WALLET",
  CASH: "CASH",
  CashAndDebt: "CASH_AND_DEBT",
  COD: "COD",
};

export const PAYMENT_METHOD_OPTIONS = [
  { value: PAYMENT_METHOD.CASH, label: i18n.t("order.orderList.cash") },
  { value: PAYMENT_METHOD.COD, label: i18n.t("order.orderList.COD") },
];

export const RECONCILIATION_STATUS_OPTIONS = [
  { value: PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT, label: "Chờ thanh toán" },
  { value: PROFIT_RECONCILIATION.RECONCILING, label: "Đang đối soát" },
  { value: PROFIT_RECONCILIATION.COMPLETED, label: "Hoàn thành" },
  { value: PROFIT_RECONCILIATION.REJECTED, label: "Đã từ chối" },
  { value: PROFIT_RECONCILIATION.CANCELED, label: "Đã hủy" },
];

export const ORDER_STATUS_OPTIONS = [
  {
    label: i18n.t("order.orderStatus.waitingForApprovalOrder"),
    value: STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER,
  },
  {
    label: i18n.t("order.orderStatus.waitingForApprovalStock"),
    value: STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK,
  },
  {
    label: i18n.t("order.orderStatus.waitingForEnoughStock"),
    value: STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK,
  },
  { label: i18n.t("order.orderStatus.waitingForPayment"), value: STATUS_ORDER.WAITING_FOR_PAYMENT },
  {
    label: i18n.t("order.orderStatus.waitingForExporting"),
    value: STATUS_ORDER.WAITING_FOR_EXPORTING,
  },
  {
    label: i18n.t("order.orderStatus.waitingForAssembly"),
    value: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
  },
  {
    label: i18n.t("order.orderStatus.waitingForDelivery"),
    value: STATUS_ORDER.WAITING_FOR_SHIPPING,
  },
  { label: i18n.t("order.orderStatus.delivering"), value: STATUS_ORDER.DELIVERING },
  { label: i18n.t("order.orderStatus.completed"), value: STATUS_ORDER.COMPLETED },
  { label: i18n.t("order.orderStatus.cancel"), value: STATUS_ORDER.CANCELED },
  {
    label: i18n.t("order.orderStatus.waitingForPackaging"),
    value: STATUS_ORDER.WAITING_FOR_PACKAGING,
  },
];

export const EXPIRED_DATE = {
  REMAINING: "REMAINING",
  OVERDUE: "OVERDUE",
  NOT_COMPLETED: "NOT_COMPLETED",
};

export const COMPLETED_DATE = {
  ON_TIME: "ON_TIME",
  OVERDUE: "OVERDUE",
};

export const EXPIRED_DATE_OPTIONS = [
  {
    label: "Còn hạn",
    value: EXPIRED_DATE.REMAINING,
  },
  {
    label: "Quá hạn",
    value: EXPIRED_DATE.OVERDUE,
  },
  {
    label: "Đơn chưa hoàn thành",
    value: EXPIRED_DATE.NOT_COMPLETED,
  },
];

export const COMPLETED_DATE_OPTIONS = [
  {
    label: "Đúng hạn",
    value: COMPLETED_DATE.ON_TIME,
  },
  {
    label: "Quá hạn",
    value: COMPLETED_DATE.OVERDUE,
  },
];

export const REJECTED_REASON_CAUSE = {
  CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS: "CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS",
  PROBLEM_POLICY_COLLABORATOR_SELLER: "PROBLEM_POLICY_COLLABORATOR_SELLER",
};

export const REJECTED_REASON_CAUSE_LABEL = {
  [REJECTED_REASON_CAUSE.CUSTOMER_REQUEST_RETURN_WITHIN_X_DAYS]: "Khách yêu cầu hoàn hàng trong vòng 07 ngày đối soát",
  [REJECTED_REASON_CAUSE.PROBLEM_POLICY_COLLABORATOR_SELLER]: "Vấn đề về chính sách cộng tác viên với đại lý",
};
