import React from "react";
import { t } from "i18next";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import ActivityTabs from "features/Agent/Detail/components/ActivityTabs";
import Info from "features/Agent/Detail/components/Info";
import useSupplierDetail from "./useSupplierDetail";

function SupplierDetail() {
  const { supplierDetail, loading, refetch } = useSupplierDetail();
  return (
    <div className="wrapper">
      <Spinner loading={loading} />
      <PageHeader
        pageTitle={t("supplier.header.detailSupplier")}
        routes={[
          { path: "/supplier-list", name: t("supplier.title") },
          { path: "/supplier-list", name: t("supplier.supplierManagement") },
          {
            path: "#",
            name: t("supplier.header.detailSupplier"),
          },
        ]}
      />

      <Info isSupplier={true} dataDetail={supplierDetail} refetch={refetch} />
      <ActivityTabs isSupplier={true} supplierDetail={supplierDetail} />
    </div>
  );
}

export default SupplierDetail;
