import { Card, Col, Row } from "antd";
import { IMPORT_TYPE } from "features/Serial/constant";
import AddInfo from "../AddInfo";
import ProviderInfo from "../ProviderInfo";

import WarehouseImport from "features/Serial/SerialImport/CreateSerial/components/WarehouseImport";
import { t } from "i18next";
import styles from "../../index.module.scss";
import InfoRetype from "../InfoRetype";
import { TitleCard } from "../TitleCard";

function UiByImportType({
  providerOptions,
  orderOptions,
  loadMoreOrder,
  loadMoreProvider,
  hasMoreOrder,
  hasMoreProvider,
  onSearch,
  loading,
  orders,
  importType,
  handleChangeInfo,
  providerInfo,
  orderInfo,
  serialInfo,
  handleChange,
  loadingWarehouse,
  warehouseOptions,
  handleSelectWarehouse,
  warehouseInfo,
}) {
  switch (importType) {
    case IMPORT_TYPE.FIRST_TIME_IMPORT:
      return (
        <Row gutter={[16, 8]}>
          <Col span={14} style={{ display: "flex", flexDirection: "column" }} className="card-container-info">
            <Card title={<TitleCard title={"Thông tin nhà cung cấp"} />} style={{ flexGrow: 1 }}>
              <ProviderInfo
                options={providerOptions}
                loadMoreData={loadMoreProvider}
                hasMoreData={hasMoreProvider}
                onSearch={onSearch}
                loading={loading}
                importType={importType}
                handleChangeInfo={handleChangeInfo}
                providerInfo={providerInfo}
              />
            </Card>
          </Col>
          <Col span={10} className="card-container-info">
            <Card title={<TitleCard title={t("serialIE.addInfo.title")} />} style={{ flexGrow: 1 }}>
              <AddInfo />
            </Card>
          </Col>
        </Row>
      );
    case IMPORT_TYPE.RETURN:
      return (
        <Row gutter={[16, 8]}>
          <Col span={14} style={{ display: "flex", flexDirection: "column" }} className="card-container-info">
            <Card title={<TitleCard title={"Thông tin đơn hàng"} />} style={{ flexGrow: 1 }}>
              <ProviderInfo
                options={orderOptions}
                loadMoreData={loadMoreOrder}
                hasMoreData={hasMoreOrder}
                onSearch={onSearch}
                loading={loading}
                orders={orders}
                importType={importType}
                handleChangeInfo={handleChangeInfo}
                orderInfo={orderInfo}
                handleChange={handleChange}
              />
            </Card>
          </Col>
          <Col span={10} className="card-container-info">
            <Card title={<TitleCard title={t("serialIE.addInfo.title")} />} style={{ flexGrow: 1 }}>
              <AddInfo />
            </Card>
          </Col>
        </Row>
      );
    case IMPORT_TYPE.RE_IMPORT_WARRANTY:
      return (
        <Row gutter={[16, 8]}>
          <Col span={12} className={styles["border-right"]}>
            <InfoRetype serialInfo={serialInfo} />
          </Col>
          <Col span={12}>
            <AddInfo />
          </Col>
        </Row>
      );
    case IMPORT_TYPE.TRANSFER_SERIAL:
      return (
        <Row gutter={[16, 8]}>
          <Col span={14} style={{ display: "flex", flexDirection: "column" }} className="card-container-info">
            <Card title={<TitleCard title={"Thông tin kho nhập"} />} style={{ flexGrow: 1 }}>
              <WarehouseImport
                loadingWarehouse={loadingWarehouse}
                warehouseOptions={warehouseOptions}
                handleSelectWarehouse={handleSelectWarehouse}
                warehouseInfo={warehouseInfo}
              />
            </Card>
          </Col>
          <Col span={10} className="card-container-info">
            <Card title={<TitleCard title={t("serialIE.addInfo.title")} />} style={{ flexGrow: 1 }}>
              <AddInfo />
            </Card>
          </Col>
        </Row>
      );
    case IMPORT_TYPE.OTHER:
      return (
        <Row gutter={[16, 8]}>
          <Col span={14} style={{ display: "flex", flexDirection: "column" }} className="card-container-info">
            <Card title={<TitleCard title={"Thông tin kho nhập"} />} style={{ flexGrow: 1 }}>
              <WarehouseImport
                loadingWarehouse={loadingWarehouse}
                warehouseOptions={warehouseOptions}
                handleSelectWarehouse={handleSelectWarehouse}
                warehouseInfo={warehouseInfo}
              />
            </Card>
          </Col>
          <Col span={10} className="card-container-info">
            <Card title={<TitleCard title={t("serialIE.addInfo.title")} />} style={{ flexGrow: 1 }}>
              <AddInfo />
            </Card>
          </Col>
        </Row>
      );
    default:
      break;
  }
}

export default UiByImportType;
