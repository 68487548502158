import { EXPORT_ORDER_TYPE } from "features/Order/constants";
import { EXPORT_NOTES_TYPE } from "features/Serial/constant";
import { useGetExportFileSerial } from "hooks/keyManagement/mutation";
import {
  useCountDigitalStatusOrder,
  useGetSuggestedEntryList,
  useKeyManagementPermissions,
} from "hooks/keyManagement/query";
import useDebounce from "hooks/useDebounce";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery, notify } from "utils/helperFuncs";
import { SORT_TYPES, TABS_SUGGESTED_ENTRY, formatBuildQueryString } from "../constants";

const MAX_SCREEN = "calc(80vh - 280px)";
const MIN_SCREEN = "calc(60vh - 280px)";

const useSuggestedEntry = (t) => {
  const GET_QUERY = getQuery();
  const { canSuggestKeyLicenseAccountView, canSuggestKeyLicenseAccountCancel, canSuggestKeyLicenseAccountConfirm } =
    useKeyManagementPermissions();

  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.activeKey || TABS_SUGGESTED_ENTRY.ALL);

  const [selectedHighlight, setSelectedHighlight] = useState();
  const [isTableSelectedRow, setIsTableSelectedRow] = useState(true);
  const [sizeScrollPageAll, setSizeScrollPageAll] = useState({ x: 1600, y: MAX_SCREEN });
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const debouncedValue = useDebounce(searchTerm?.trim());
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    activeKey: activeKeyMain,
    filters: {
      query: GET_QUERY?.query || undefined,
      timeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      digitalStatuses: GET_QUERY.digitalStatuses || undefined,
      priorities: GET_QUERY.priorities || undefined,
      createdByIDs: GET_QUERY.createdByIDs || undefined,
      statuses: GET_QUERY.statuses || undefined,
    },
    pagination: {
      limit: 10,
      offset: GET_QUERY?.offset || 0,
    },
    sorts: [],
  });

  const { dataSuggestedEntry, loadingDataSuggestedEntry, paginationData } = useGetSuggestedEntryList(params);

  const { COUNT_ALL, COUNT_WAITING_SEND_CUSTOMER, COUNT_COMPLETED, COUNT_CANCELED } = useCountDigitalStatusOrder();

  const tabList = [
    {
      key: TABS_SUGGESTED_ENTRY.ALL,
      tab: `${t("v2.keyManagement.exportNotes.tabAll")} (${canSuggestKeyLicenseAccountView ? COUNT_ALL ?? 0 : 0})`,
    },
    {
      key: TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER,
      tab: `${t("v2.keyManagement.exportNotes.tabWaitingSendCustomer")} (${
        canSuggestKeyLicenseAccountView ? COUNT_WAITING_SEND_CUSTOMER ?? 0 : 0
      })`,
    },
    {
      key: TABS_SUGGESTED_ENTRY.COMPLETED,
      tab: `${t("v2.keyManagement.exportNotes.tabSentCustomer")} (${
        canSuggestKeyLicenseAccountView ? COUNT_COMPLETED ?? 0 : 0
      })`,
    },
    {
      key: TABS_SUGGESTED_ENTRY.CANCELED,
      tab: `${t("v2.keyManagement.exportNotes.tabCancel")} (${
        canSuggestKeyLicenseAccountView ? COUNT_CANCELED ?? 0 : 0
      })`,
    },
  ];

  const resetSelected = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    fixed: true,
    hideSelectAll: true,
    getCheckboxProps: (record) => {
      return {
        disabled:
          (GET_QUERY?.activeKey === TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER ||
            GET_QUERY?.activeKey === TABS_SUGGESTED_ENTRY.COMPLETED) &&
          selectedRowKeys?.length === 1 &&
          !selectedRowKeys?.includes(record?.id),
        name: record?.id,
      };
    },
  };

  const handleFilter = (value, objKey) => {
    const newParams = {
      ...params,
      filters: { ...params?.filters, [`${objKey}`]: value },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const handleChangeParams = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeTab = (activeKey) => {
    const newParams = {
      activeKey: activeKey,
      filters: {
        digitalStatuses: activeKey === TABS_SUGGESTED_ENTRY.ALL ? [] : activeKey,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      sorts: [],
    };

    setParams(newParams);
    resetSelected();
    setActiveKeyMain(activeKey);
    formatBuildQueryString(newParams);
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sort: sorter?.order
        ? [{ by: sorter.field === "createdAt" ? "CREATED_AT" : "GRAND_TOTAL", orderType: SORT_TYPES[sorter?.order] }]
        : [],
    };

    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const { handleGetExportFileSerial } = useGetExportFileSerial();

  const handleDownloadFileURL = async ({ serialExportNoteLatest }) => {
    try {
      setLoading(true);
      await handleGetExportFileSerial({
        serialExportNoteID: serialExportNoteLatest?.id,
        serialExportNoteItemIDs: serialExportNoteLatest?.items?.map((item) => item?.id),
        type: EXPORT_NOTES_TYPE.SERIAL_EXPORT_NOTE,
        fileType: EXPORT_ORDER_TYPE.XLSX,
      })
        .then((data) => {
          const exportFile = data?.data?.serialExportNotes?.exportFile;
          const pathFile = exportFile?.path;
          const urlFile = exportFile?.url;
          setLoading(false);

          var xhr = new XMLHttpRequest();
          xhr.open("GET", urlFile, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement("a");
            tag.href = imageUrl;
            tag.download = pathFile;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
          };
          xhr.send();
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      notify.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleRows = () => {
    setSizeScrollPageAll(isTableSelectedRow ? { x: 1600, y: MIN_SCREEN } : { x: 1600, y: MAX_SCREEN });
    setIsTableSelectedRow(!isTableSelectedRow);
  };

  const handleRowClick = (record, index) => {
    setSelectedHighlight(index);
    setSelectedRow(record);
    setIsTableSelectedRow(false);
    setSizeScrollPageAll({ x: 1600, y: MIN_SCREEN });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: { ...newParams?.filters, ...newParams?.pagination },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (dataSuggestedEntry?.length > 0) {
      handleRowClick(dataSuggestedEntry?.[0] || [], 0);
    }
  }, [dataSuggestedEntry]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params?.filters,
        digitalStatuses: params.activeKey === "ALL" ? [] : params.filters.digitalStatuses,
      },
    };

    setParams(newParams);
    formatBuildQueryString(newParams);
  }, []);

  useEffect(() => {
    formatBuildQueryString(params);
  }, [GET_QUERY.activeKey, params]);

  const propsContent = {
    tabList,
    dataSuggestedEntry,
    loadingDataSuggestedEntry,
    handleChangeTable,
    selectedHighlight,
    sizeScrollPageAll,
    handleRowClick,
    paginationData,
    handleToggleRows,
    isTableSelectedRow,
    selectedRow,
    rowSelection,
    resetSelected,
  };

  return {
    activeKeyMain,
    propsContent,
    params,
    selectedRowKeys,
    selectedRows,
    handleSearch,
    handleFilter,
    handleChangeTab,
    handleChangeParams,
    handleDownloadFileURL,
    loading,
    canSuggestKeyLicenseAccountCancel,
    canSuggestKeyLicenseAccountConfirm,
  };
};

export default useSuggestedEntry;
