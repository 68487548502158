import { Button } from "antd";
import LoadingComponent from "components/LoadingComponent";
import { PRODUCT_TYPE } from "config/constants";
import { useFlags } from "flagsmith/react";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useConfirmExportOrder, useOrderPermissions } from "hooks/order";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const WaitingExportingButtons = ({ checkedKeyList, resetCheckedList, checkedList }) => {
  const { t } = useTranslation();
  const { handleConfirmExportOrder, loading } = useConfirmExportOrder();
  const { canExportStock } = useOrderPermissions();
  const { platformCode } = useGetInfoPlatform();
  const flags = useFlags(["is_redirect_export_serial"]);
  const listPlatformCode = JSON.parse(flags.is_redirect_export_serial?.value)?.platformCode;

  const isMaterialService =
    checkedList?.length > 0 &&
    checkedList[0]?.items?.every(
      (item) => item.product.productType === PRODUCT_TYPE.MATERIAL || item.product.productType === PRODUCT_TYPE.SERVICE
    );

  const handleCheckMessage = async () => {
    if (checkedKeyList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      resetCheckedList && resetCheckedList();
    }
  };

  const handleExportConfirm = async () => {
    await handleConfirmExportOrder({
      orderIDs: checkedKeyList,
    })
      .then(() => {
        notify.success({
          message: t("order.orderList.exportingSuccess"),
        });
      })
      .catch((err) => {
        notify.success({
          message: err.message,
        });
      });
  };

  const queryParams = new URLSearchParams();
  queryParams.set("orderId", JSON.stringify(checkedKeyList && checkedKeyList[0]));

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "checkReload") {
        window.location.reload();
        localStorage.removeItem("checkReload");
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    var retrievedUser = JSON.parse(localStorage.getItem("checkReload"));
    if (retrievedUser) {
      localStorage.removeItem("checkReload");
      window.location.reload();
    }
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <div className="button-group-of-2">
        {canExportStock &&
          (checkedKeyList?.length > 0 ? (
            listPlatformCode?.includes(platformCode) ? (
              <Link
                to={
                  isMaterialService
                    ? { pathname: "/export-serial/create", search: queryParams.toString() }
                    : { pathname: `create-export-key#${checkedList?.length > 0 && checkedList[0]?.id}` }
                }
                target="_blank"
              >
                <Button
                  className="btn-main-action"
                  onClick={() => {
                    resetCheckedList && resetCheckedList();
                  }}
                >
                  {t("order.orderDetail.exportConfirmation")}
                </Button>
              </Link>
            ) : (
              <Button className="btn-main-action" onClick={handleExportConfirm}>
                {t("order.orderDetail.exportConfirmation")}
              </Button>
            )
          ) : (
            <Button className="btn-main-action" onClick={handleCheckMessage}>
              {t("order.orderDetail.exportConfirmation")}
            </Button>
          ))}
      </div>
    </LoadingComponent>
  );
};

export default WaitingExportingButtons;
