import { Col, Descriptions, Row, Typography } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";
import { t } from "i18next";
import { Link } from "react-router-dom";

function SupplierInfo({ dataDetail }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Descriptions bordered column={1} size="small" className="info-by-type-serial-import-notes">
          <Descriptions.Item label={t("supplier.supplierCode")}>
            <Link to={`/supplier/detail/${dataDetail?.provider?.id}`} target="_blank">
              <Typography.Link strong>
                {dataDetail?.provider?.code || dataDetail?.seller?.code || "- -"}
              </Typography.Link>
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={t("supplier.supplierName")}>
            {dataDetail?.provider?.fullName || dataDetail?.seller?.fullName || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={"SĐT"}>
            {dataDetail?.provider?.telephone || dataDetail?.seller?.telephone || "- -"}
          </Descriptions.Item>
          <Descriptions.Item label={t("serialIE.providerInfo.address")}>
            <CustomInfoAdress value={dataDetail?.provider || dataDetail?.seller}></CustomInfoAdress>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default SupplierInfo;
