import { Form } from "antd";
import { useGetSellerCreateCart } from "hooks/seller";
import { useUpdateSerialImport } from "hooks/serial";
import { useGetDetailSerialImport } from "hooks/serial/query";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { compact, isEqual, omit, pick, sumBy, uniqBy, uniqWith } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { WARRANTY_ON_SALE_UNIT } from "../../constant";

const convertItems = (items) => {
  return items?.map((item) => {
    return {
      ...item,
      warrantyUnit: item?.warrantyUnit,
      warrantyPeriodSupplier: item?.warrantyPeriodProvider,
      serialItems: item?.serialItems?.map((item) => ({
        code: item?.serial?.code,
        status: item?.serial?.status,
      })),
    };
  });
};

function useEdit() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  const { data, loading } = useGetDetailSerialImport({ id: id });
  const { handleUpdateSerialImport, loading: loadingUp } = useUpdateSerialImport();

  const [loadingUpdate, setLoadingUpdate] = useState(loadingUp);

  const dataItemsConvert = data?.items?.map((item) => ({
    ...item,
    scanned: item?.quantity - (item?.quantity - item?.serialItems?.length),
    lack: item?.quantity - item?.serialItems?.length,
    serialItems: item?.serialItems?.map((item) => ({
      code: item?.serial?.code,
      status: item?.serial?.status,
    })),
  }));

  const [itemsCopy, setItemsCopy] = useState([]);
  const [providerInfo, setProviderInfo] = useState(null);
  const [removeItemIDs, setRemoveItemIDs] = useState([]);
  const [updateItems, setUpdateItems] = useState([]);

  const sumSerialByOrder = sumBy(itemsCopy, "quantity");
  const sumSerialByScanned = sumBy(itemsCopy, "scanned");
  const sumSerialByLack = sumBy(itemsCopy, "lack");

  const images = data?.fileURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    ...data,
    note: data?.note,
    soldDateFromProvider: moment(data?.soldDateFromProvider),
    items: convertItems(data?.items),
    fileURLs: images,
  };

  // wareHouse
  const { warehouses } = useWarehouse();
  const exportedWarehouseOptions = warehouses?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  // providerInfo
  const [params, setParams] = useState({
    filters: {
      query: null,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const {
    sellers,
    loadMoreData: loadMoreProvider,
    hasMoreData: hasMoreProvider,
  } = useGetSellerCreateCart(params, "cache-first");
  const { sellers: providerEdit } = useGetSellerCreateCart(
    {
      filters: {
        query: data?.provider?.fullName,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    },
    "cache-first"
  );

  const providerOptions = (sellers || [])?.concat(providerEdit || [])?.map((item) => {
    return {
      value: item?.value,
      label: item?.label,
      provider: {
        address: item?.address,
        telephone: item?.telephone,
        email: item?.email,
      },
    };
  });
  const onSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value || null },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleChangeInfo = (value, data) => {
    setProviderInfo(data?.provider);
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.items) {
      const newData = itemsCopy?.map((item, index) => {
        return {
          ...item,
          serialImportNoteItemID: allValues?.items[index].id,
          warrantyPeriodSupplier: allValues?.items[index].warrantyPeriodSupplier,
          warrantyUnit: allValues?.items[index].warrantyUnit,
          quantity: allValues.items[index].quantity,
          serialItems: allValues.items[index].serialItems,
          lack: allValues.items[index].quantity - allValues.items[index].serialItems.length,
          scanned:
            allValues.items[index].quantity -
            (allValues.items[index].quantity - allValues.items[index].serialItems.length),
        };
      });
      const dataPicked = newData
        ?.filter((item) => item.serialItems.every((serialItem) => serialItem.status !== "UNAVAILABLE"))
        ?.filter((item) => item?.id)
        ?.map((item) =>
          pick({ ...item }, [
            "serialImportNoteItemID",
            "productID",
            "warrantyPeriodSupplier",
            "warrantyUnit",
            "quantity",
            "serialItems",
          ])
        );
      setUpdateItems(
        dataPicked?.map((item) => ({
          ...item,
          serialItems: item?.serialItems?.map((i) => i?.code),
        }))
      );
      setItemsCopy(newData);
    }
  };

  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: data?.id,
      warrantyPeriodSupplier: 0,
      warrantyUnit: WARRANTY_ON_SALE_UNIT.MONTH,
      quantity: 0,
      serialItems: [],
      product: data,
    };
    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData.items, newData], "productID"),
    });

    let newRemoveItemIDs = [...removeItemIDs];
    if (newRemoveItemIDs.includes(data?.id)) {
      let selectIndex = newRemoveItemIDs.indexOf(data?.id);
      newRemoveItemIDs.splice(selectIndex, 1);
      setRemoveItemIDs(newRemoveItemIDs);
    }
    setItemsCopy(uniqBy([...itemsCopy, newData], "productID"));
  };

  const handleEdit = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    const newItems = itemsCopy
      ?.filter((item) => !item.id)
      .map((item) => omit(item, ["product", "lack", "scanned"]))
      ?.map((item) => ({
        ...item,
        serialItems: item?.serialItems?.map((i) => i?.code),
      }));
    setLoadingUpdate(true);
    try {
      const params = {
        serialImportNoteID: id,
        request: {
          providerID: values?.providerID,
          soldDateFromProvider: values?.soldDateFromProvider,
          warehouseID: values?.warehouseID,
          note: values?.note,
          removeItemIDs: compact(removeItemIDs),
          newItems: newItems,
          updateItems: updateItems,
          fileURLs: values?.fileURLs || [],
        },
      };
      await handleUpdateSerialImport(params)
        .then(() => {
          notify.success({ message: t("serialIE.notify.successUpdate") });
          history.push(`/import-serial/detail/${id}`);
          setLoadingUpdate(false);
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.info(error);
    }
  };

  const handleRemoveProduct = (id, productID) => {
    setRemoveItemIDs((prev) => setRemoveItemIDs([...prev, id]));
    const newArr = itemsCopy?.filter((item) => item?.productID !== productID);
    return setItemsCopy(newArr);
  };

  const handleCancelEdit = () => {
    history.push(`/import-serial/detail/${id}`);
  };

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
    setItemsCopy(dataItemsConvert);
  }, [data?.id]);

  return {
    loading,
    form,
    initialValues,
    handleValuesChange,
    data,
    exportedWarehouseOptions,
    providerOptions: uniqWith(providerOptions, isEqual),
    handleChangeInfo,
    loadMoreProvider,
    hasMoreProvider,
    onSearch,
    providerInfo,
    handleGetProduct,
    itemsCopy,
    sumSerialByOrder,
    sumSerialByScanned,
    sumSerialByLack,
    handleEdit,
    handleCancelEdit,
    handleRemoveProduct,
    loadingUpdate,
  };
}

export default useEdit;
