import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationOldVI from "locales/languages/vi.json";
import { initReactI18next } from "react-i18next";

const resources = {
  vi: {
    translation: {
      ...translationOldVI,
      v2: {
        account: require("locales/languages/vi/account.json"),
        campaign: require("locales/languages/vi/campaign.json"),
        cart: require("locales/languages/vi/cart.json"),
        common: require("locales/languages/vi/common.json"),
        customer: require("locales/languages/vi/customer.json"),
        keyManagement: require("locales/languages/vi/keyManagement.json"),
        notification: require("locales/languages/vi/notification.json"),
        order: require("locales/languages/vi/order.json"),
        product: require("locales/languages/vi/product.json"),
        provider: require("locales/languages/vi/provider.json"),
        purchaseOrder: require("locales/languages/vi/purchaseOrder.json"),
        report: require("locales/languages/vi/report.json"),
        seller: require("locales/languages/vi/seller.json"),
        serial: require("locales/languages/vi/serial.json"),
        warranty: require("locales/languages/vi/warranty.json"),
        orderReconcilation: require("locales/languages/vi/order-reconcilation.json"),
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: process.env.REACT_APP_DEFAULT_LANG,
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
