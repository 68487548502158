import { useMutation, useQuery } from "@apollo/client";
import { CREATE_CATEGORY, UPDATE_CATEGORY, UPDATE_STATUS_CATEGORY } from "graphql/category/mutation";
import {
  GET_ALL_ATTRIBUTES_QUERY,
  GET_ALL_CATEGORIES_QUERY,
  GET_CATEGORIES_FOR_FORM_QUERY,
  GET_CATEGORIES_LEVEL_2,
  GET_CATEGORIES_QUERY,
  GET_CATEGORY_QUERY,
  GET_CATEGORY_QUERY_LEVEL,
  GET_CATEGORIES_BY_ATTRIBUTE_ID,
  GET_PROD_CATEGORIES,
  GET_CATEGORY_DETAIL,
  GET_CATEGORIES_LEVEL_1,
  GET_CATEGORIES_LEVEL_COUNT,
} from "graphql/category/query";
import {
  converGetFilterCat,
  convertCategoriesByAtt,
  converterCategories,
  converterCategoriesForForm,
  converterGetCategory,
  convertGetAllAttributes,
} from "./converter";

export const useGetCategories = (params) => {
  const { loading, data, refetch } = useQuery(GET_CATEGORIES_QUERY, {
    variables: params,
  });
  return {
    loading,
    data: data?.category?.pagination,
    refetch,
  };
};

export const useGetCategoriesLevel = ({ levels, limit }) => {
  const { loading, data, refetch } = useQuery(GET_CATEGORY_QUERY_LEVEL, {
    variables: {
      levels,
      limit,
    },
  });

  return {
    loading,
    categories: data?.category?.pagination?.categories,
    refetch,
  };
};

export const useGetCategory = (params) => {
  const { loading, data } = useQuery(GET_CATEGORY_QUERY, {
    variables: params,
  });
  return {
    loading,
    data: converterGetCategory(data?.category?.get),
  };
};

export const useGetAllCategory = (params) => {
  const { loading, data, refetch } = useQuery(GET_ALL_CATEGORIES_QUERY, {
    variables: { req: params },
  });

  return {
    loading,
    data: converterCategories(data?.category?.list),
    refetch,
  };
};

export const useCreateCategory = () => {
  const [createCategory, { loading, error }] = useMutation(CREATE_CATEGORY);

  const handleCreateCategory = async (params) => {
    return await createCategory({
      variables: {
        req: {
          ...params,
        },
      },
    });
  };

  return {
    handleCreateCategory,
    loading,
    error,
  };
};

export const useUpdateCategory = () => {
  const [updateCategory, { loading, error }] = useMutation(UPDATE_CATEGORY);

  const handleUpdateCategory = async (id, params) => {
    return await updateCategory({
      variables: {
        id: id,
        req: {
          ...params,
        },
      },
    });
  };

  return {
    handleUpdateCategory,
    loading,
    error,
  };
};

export const useUpdateStatusCategory = () => {
  const [updateStatusCategory, { loading, error }] = useMutation(UPDATE_STATUS_CATEGORY);

  const handleUpdateStatusCategory = async (params) => {
    return await updateStatusCategory({
      variables: {
        ...params,
      },
      refetchQueries: [GET_ALL_CATEGORIES_QUERY],
    });
  };

  return {
    handleUpdateStatusCategory,
    loading,
    error,
  };
};

export const useGetCategoriesForForm = (level) => {
  const { loading, data, refetch } = useQuery(GET_CATEGORIES_FOR_FORM_QUERY, {
    variables: { req: { levels: [1] } },
  });

  return {
    loading,
    data: converterCategoriesForForm(data?.category?.list, level),
    refetch,
  };
};

export const useGetAllAttributes = () => {
  const { loading, data, refetch } = useQuery(GET_ALL_ATTRIBUTES_QUERY, {
    variables: { req: { isActive: true } },
  });

  return {
    loading,
    data: convertGetAllAttributes(data?.attribute?.list) || [],
    refetch,
  };
};

export const useGetCategoriesLevel2 = () => {
  const { loading, data } = useQuery(GET_CATEGORIES_LEVEL_2, {
    variables: { req: { levels: [2] } },
  });

  return {
    loading,
    data: data?.category?.list.map((item) => item?.id),
  };
};

export const useGetCategoriesByAtt = (params) => {
  const { loading, data, refetch } = useQuery(GET_CATEGORIES_BY_ATTRIBUTE_ID, {
    variables: params,
    skip: !params.attributeIDs,
  });
  return {
    loading,
    data: convertCategoriesByAtt(data?.category?.list),
    refetch,
  };
};

export const useGetProductCategories = () => {
  const { loading, data } = useQuery(GET_PROD_CATEGORIES, {
    variables: { req: { levels: [1] } },
  });
  return {
    loading,
    data: data?.category?.list,
  };
};

export const useGetCategoriesLevel1 = () => {
  const { loading, data } = useQuery(GET_CATEGORIES_LEVEL_1, {
    variables: { req: { levels: [1] } },
  });

  return {
    loading,
    data: data?.category?.list,
  };
};

export const useGetCategoriesLevelCount = (level) => {
  const { loading, data } = useQuery(GET_CATEGORIES_LEVEL_COUNT, {
    variables: { req: { levels: [level] } },
  });

  return {
    loading,
    data: data?.category?.list,
  };
};
