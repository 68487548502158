import { useMutation } from "@apollo/client";
import {
  CANCEL_SUGGESTED_EXPORT_CODE,
  CONFIRM_SUGGESTED_EXPORT_CODE,
  GET_EXPORT_FILE_SERIAL,
  LOCK_KEY,
  SEND_EMAIL_KEY_LICENSE_ACCOUNT,
  UNLOCK_KEY,
  UPLOAD_FILE_DIGITAL_PRODUCT,
} from "graphql/keyManagement/mutation";
import { COUNT_STATUS_ORDER } from "graphql/order/query";
import { GET_KEY_DETAIL, GET_KEY_MANAGEMENT, GET_SUGGESTED_ENTRY_LIST } from "graphql/keyManagement/query";

const refetchQueries = [GET_KEY_MANAGEMENT, GET_KEY_DETAIL];

export const useLockKey = () => {
  const [lockKey, { loading }] = useMutation(LOCK_KEY);

  const handleLockKey = async (params) => {
    return await lockKey({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleLockKey,
    loadingLockKey: loading,
  };
};

export const useUnlockKey = () => {
  const [unlockKey, { loading }] = useMutation(UNLOCK_KEY);

  const handleUnlockKey = async (params) => {
    return await unlockKey({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleUnlockKey,
    loadingUnlockKey: loading,
  };
};

export const useCancelSuggestedExportCode = () => {
  const [cancelSuggestedExportCode, { loading }] = useMutation(CANCEL_SUGGESTED_EXPORT_CODE);

  const handleCancelSuggestedExportCode = async (params) => {
    return await cancelSuggestedExportCode({
      variables: params,
      refetchQueries: [GET_SUGGESTED_ENTRY_LIST, COUNT_STATUS_ORDER],
    });
  };

  return {
    handleCancelSuggestedExportCode,
    loadingCancelSuggestedExportCode: loading,
  };
};

export const useConfirmSuggestedExportCode = () => {
  const [confirmSuggestedExportCode, { loading }] = useMutation(CONFIRM_SUGGESTED_EXPORT_CODE);

  const handleConfirmSuggestedExportCode = async (params) => {
    return await confirmSuggestedExportCode({
      variables: params,
      refetchQueries: [GET_SUGGESTED_ENTRY_LIST],
    });
  };

  return {
    handleConfirmSuggestedExportCode,
    loadingCancelSuggestedExportCode: loading,
  };
};

export const useUploadFileDigitalProduct = () => {
  const [createByExcel, { loading }] = useMutation(UPLOAD_FILE_DIGITAL_PRODUCT);

  const handleUploadFileDigitalProduct = async (params) => {
    return await createByExcel({
      variables: params,
      refetchQueries: refetchQueries,
    });
  };

  return {
    handleUploadFileDigitalProduct,
    loading,
  };
};

export const useSendEmailKeyLicenseAccount = () => {
  const [sendEmail, { loading }] = useMutation(SEND_EMAIL_KEY_LICENSE_ACCOUNT);

  const handleSendEmailKeyLicenseAccount = async (params) => {
    return await sendEmail({
      variables: params,
      refetchQueries: [GET_SUGGESTED_ENTRY_LIST, COUNT_STATUS_ORDER],
    });
  };

  return {
    handleSendEmailKeyLicenseAccount,
    loading,
  };
};

export const useGetExportFileSerial = () => {
  const [exportFile, { loading }] = useMutation(GET_EXPORT_FILE_SERIAL);

  const handleGetExportFileSerial = async (params) => {
    return await exportFile({
      variables: params,
    });
  };

  return {
    handleGetExportFileSerial,
    loading,
  };
};
