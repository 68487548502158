/* eslint-disable react/jsx-no-useless-fragment */
import { FontAwesomeIcon, Icon } from "assets/icons";
import CreateButton from "components/CreateButton";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import FilterMenuDate from "../FilterMenuDate";
import { FiltersTag } from "../FiltersTag";
import "./index.scss";

function Toolbar({
  params,
  handleSearch,
  sellersOptions,
  handleRemoveFilterTag,
  filterTags,
  setParams,
  setFilterTags,
  warehouseOptions,
  optionImportRequestType,
  staffs,
  statusTabs,
  setSelectedHightline,
  setSizeScrollPageAll,
  setSelectedRow,
  canImportRequestCreate,
  canImportRequestViewCreatedByMe,
  canImportRequestView,
}) {
  const history = useHistory();

  return (
    <div className="toolbar-recommend-import">
      <div className="toolbar-box">
        <div style={{ display: "flex", gap: "20px" }}>
          <FilterMenuDate
            params={params}
            setParams={setParams}
            staffs={sellersOptions}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            setSelectedHightline={setSelectedHightline}
            setSizeScrollPageAll={setSizeScrollPageAll}
            setSelectedRow={setSelectedRow}
          />
          <div className="search-input">
            <FormInput
              {...{
                type: INPUT_TYPE,
                inputOptions: {
                  onChange: handleSearch,
                  placeholder: t("warehouse.recommendImport.search"),
                  prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
                },
              }}
            ></FormInput>
          </div>
        </div>

        {canImportRequestCreate ? (
          <CreateButton
            title={t("warehouse.recommendImport.createRecommend")}
            className="btn-create"
            onClick={() => {
              history.push({
                pathname: "/import-request/create",
              });
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <FiltersTag
          rangeDateTimeFilter={params?.filters?.timeRange || []}
          params={params}
          handleRemoveFilterTag={handleRemoveFilterTag}
          warehouseOptions={warehouseOptions}
          optionImportRequestType={optionImportRequestType}
          staffs={staffs}
          sellersOptions={sellersOptions}
          statusTabs={statusTabs}
          canImportRequestViewCreatedByMe={canImportRequestViewCreatedByMe}
          canImportRequestView={canImportRequestView}
        />
      </div>
    </div>
  );
}

export default Toolbar;
