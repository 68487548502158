import React, { useState } from "react";
import { Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import { useGetProducts } from "hooks/product/product";
import { t } from "i18next";

function SelectProduct() {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
    },
    pagination: { offset: 0, limit: 10 },
  });
  const { data, onLoadMoreData, hasMoreData } = useGetProducts(params, "cache-first");

  const options = data?.map((item) => {
    return {
      value: item.code,
      label: item.code,
      item: item,
    };
  });

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    // setProduct(option?.item);
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };
  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.popup.productCode")}</Typography.Text>,
          name: "alternativeProductCode",
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.productCode"),
          options: options,
          allowClear: false,
          disabled: true,
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectProduct;
