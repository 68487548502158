import { gql } from "@apollo/client";

export const GET_KEY_MANAGEMENT = gql`
  query GetKeyManagementList(
    $filters: SerialFilters
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sorts: [SerialSort!]
  ) {
    serial {
      pagination(filters: $filters, pagination: $pagination, sorts: $sorts) {
        paginationData {
          total
          offset
          limit
        }
        Serials {
          id
          createdAt
          code
          codeDisplay
          productID
          product {
            code
            name
            warranty
            warrantyUnit
            productType
            copyrightTerm
            copyrightUnit
          }
          status
          createdByID
          serialImportNoteItemLatest {
            serialImportNote {
              id
              providerID
              provider {
                code
                fullName
                __typename
              }
              purchaseOrderCode
              soldDateFromProvider
              createdAt
              createdBy {
                fullname
              }
            }
          }
          serialExportNoteItemLatest {
            serialExportNote {
              id
              sellerID
              seller {
                code
                fullName
                __typename
              }
              orderID
              order {
                number
                __typename
              }
              createdAt
            }
            copyrightTerm
            copyrightUnit
          }
        }
      }
    }
  }
`;

export const GET_SAMPLE_FILE_KEY = gql`
  query GetSampleFileKey($productType: ProductType!) {
    product {
      getSampleFile(productType: $productType) {
        path
        url
      }
    }
  }
`;

export const GET_KEY_DETAIL = gql`
  query GetKeyDetail($id: ID!) {
    serial {
      get(id: $id) {
        id
        code
        codeDisplay
        status
        lockedReason {
          reason
          files
        }
        productID
        product {
          code
          name
          warranty
          warrantyUnit
          productType
          copyrightTerm
          copyrightUnit
        }
        serialImportNoteItemLatest {
          serialImportNote {
            id
            providerID
            provider {
              code
              fullName
              __typename
            }
            purchaseOrderCode
            soldDateFromProvider
            createdAt
            createdBy {
              fullname
            }
          }
          warrantyPeriodProvider
          warrantyUnit
          copyrightTerm
          copyrightUnit
        }
        serialExportNoteItemLatest {
          serialExportNote {
            id
            sellerID
            seller {
              code
              fullName
              __typename
            }
            orderID
            order {
              number
              __typename
            }
            createdAt
          }
          warrantyOnSale
          warrantyOnSaleUnit
          copyrightTerm
          copyrightUnit
        }
      }
    }
  }
`;

export const GET_SUGGESTED_ENTRY_LIST = gql`
  query GetSuggestedEntryList(
    $filters: OrdersFilters!
    $pagination: PaginationRequest! = { offset: 0, limit: 10 }
    $sort: [OrderSort!] = [{ by: CREATED_AT, orderType: DESC }]
  ) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          id
          number
          orderType
          createdAt
          email
          status
          digitalStatus
          completedType
          seller {
            fullName
          }
          total
          orderPriority
          createdBy {
            fullname
          }
          items {
            productID
            productName
            productCode
            productWarranty
            product {
              productType
            }
            warrantyDateUnit
            vat
            quantity
            price
            total
            inStock
            maxDaysOfDebt
          }
          sentDigitalBy {
            fullname
          }
          cancelReason
          fileURLs
          serialExportNoteLatest {
            id
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_SHOW_PASSWORD = gql`
  query GetShowPassword($serialIDs: [ID!]) {
    serial {
      showPassword(serialIDs: $serialIDs) {
        passwordBySerialID {
          password
        }
      }
    }
  }
`;

export const GET_DATA_CREATE_EXPORT_KEY = gql`
  query GetDataCreateExportKey($id: ID!) {
    order {
      get(id: $id) {
        id
        number
        email
        items {
          id
          productID
          productName
          productCode
          product {
            productType
            copyrightTerm
            copyrightUnit
          }
          productWarranty
          warrantyDateUnit
          quantity
        }
        exportedWarehouse {
          id
        }
        sellerID
        seller {
          idNumber
          code
          vatInfo {
            taxIDNumber
          }
          fullName
          telephone
          email
        }
      }
    }
  }
`;

export const GENERATE_KEY = gql`
  query GenerateKey($inputs: [SuggestSerialInput]) {
    serial {
      generateSerials(inputs: $inputs) {
        productID
        serials {
          serialID
          serialCode
        }
      }
    }
  }
`;
