import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "components/CustomCard";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { SELLER_TYPE } from "features/PotentialCustomer/constants";
import { useCities, useDistricts, useWards } from "hooks/common";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { handleSearchSelect, validator } from "utils/helperFuncs";
import DuplicateValuesWarning from "../DuplicateValuesWarning";
import ExpandableText from "components/CustomExpandableText";

function ContactInfoCard({
  typePersonal,
  form,
  formValues,
  loadingEmail,
  loadingTelephone,
  isDuplicateEmail,
  isDuplicateTelephone,
  handleSearchEmail,
  handleSearchTelephone,
  handleOpenDrawerMulti,
  notBordered,
  dataMulti,
  loadingDetail,
  valueEmail,
  valueTelephone,
}) {
  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });

  const { cities } = useCities();
  const { districts, refetch: refetchDistricts } = useDistricts(location?.cityId || form?.getFieldsValue()?.cityID);
  const { wards, refetch: refetchWards } = useWards(location?.districtId || form?.getFieldsValue()?.districtID);

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }

  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  useEffect(() => {
    if (location?.cityId) {
      refetchDistricts(location?.cityId);
    }
    if (location?.districtId) {
      refetchWards(location?.districtId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t(`potentialCustomer.form.${typePersonal === SELLER_TYPE.PERSONAL ? "contactInfo" : "companyInfo"}`)}
        </Typography.Text>
      }
      content={
        <Skeleton active loading={loadingDetail}>
          <Row className="content-contact-info">
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.INPUT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>,
                  labelAlign: "left",
                  name: "email",
                  labelCol: { span: 8 },
                  ...(formValues?.email && { hasFeedback: true }),
                  ...(isDuplicateEmail && { style: { marginBottom: 0 } }),
                  validateStatus: loadingEmail ? "validating" : isDuplicateEmail ? "error" : undefined,
                  rules: [
                    validator({
                      type: "email",
                    }),
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.email"),
                  onChange: handleSearchEmail,
                }}
              />
              {isDuplicateEmail && (
                <DuplicateValuesWarning
                  message={t("potentialCustomer.messageDuplicate.email")}
                  fullName={valueEmail?.fullName}
                  onClick={handleOpenDrawerMulti}
                  seller={valueEmail}
                />
              )}
            </Col>
            <Col span={24}>
              <FormInput
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>,
                  labelAlign: "left",
                  name: "telephone",
                  labelCol: { span: 8 },
                  ...(formValues?.telephone && { hasFeedback: true }),
                  ...(isDuplicateTelephone && { style: { marginBottom: 0 } }),
                  validateStatus: loadingTelephone ? "validating" : isDuplicateTelephone ? "error" : undefined,
                  rules: [
                    validator({
                      type: "phone",
                    }),
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.telephone"),
                  onChange: handleSearchTelephone,
                }}
              />
              {isDuplicateTelephone && (
                <DuplicateValuesWarning
                  message={t("potentialCustomer.messageDuplicate.telephone")}
                  fullName={valueTelephone?.fullName}
                  onClick={handleOpenDrawerMulti}
                  seller={valueTelephone}
                />
              )}
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.city")}</Typography.Text>,
                  labelAlign: "left",
                  name: "cityID",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.city"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.city"),
                  options: cities?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                  showSearch: true,
                  allowClear: true,
                  onChange: (value) => handleChangeCity(value),
                  filterOption: (search, option) => handleSearchSelect(search, option),
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.district")}</Typography.Text>,
                  labelAlign: "left",
                  name: "districtID",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.district"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.district"),
                  options: districts?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                  showSearch: true,
                  allowClear: true,
                  onChange: handleChangeDistrict,
                  filterOption: (search, option) => handleSearchSelect(search, option),
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.ward")}</Typography.Text>,
                  labelAlign: "left",
                  name: "wardID",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.ward"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.ward"),
                  options: wards?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                  showSearch: true,
                  allowClear: true,
                  filterOption: (search, option) => handleSearchSelect(search, option),
                }}
              />
            </Col>
            <Col span={24}>
              {notBordered ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.address")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <ExpandableText value={dataMulti?.address || "--"} />
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.address")}</Typography.Text>,
                    labelAlign: "left",
                    name: "address",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: false,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.address"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.address"),
                    maxLength: 255,
                  }}
                />
              )}
            </Col>
          </Row>
        </Skeleton>
      }
    />
  );
}

export default ContactInfoCard;
