import React, { useState } from "react";
import { Typography } from "antd";

const ExpandableText = ({ value }) => {
  const [expanded, setExpanded] = useState(false);
  const MAX_CHARACTERS = 100; // Số ký tự tối đa trước khi rút gọn

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderContent = () => {
    const shouldTruncate = value?.length > MAX_CHARACTERS && !expanded;
    const truncatedValue = shouldTruncate ? `${value?.slice(0, MAX_CHARACTERS)}...` : value;

    return (
      <>
        <Typography.Text>{truncatedValue}</Typography.Text>{" "}
        {value?.length > MAX_CHARACTERS && (
          <span style={{ cursor: "pointer", color: "blue", textDecorationLine: "underline" }} onClick={toggleExpand}>
            {expanded ? (
              <>
                <br />
                Rút gọn
              </>
            ) : (
              "Xem thêm"
            )}
          </span>
        )}
      </>
    );
  };

  return <div>{renderContent()}</div>;
};

export default ExpandableText;
