import { Form } from "antd";
import { useCreateCart } from "hooks/cart";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

const useModalSelectSelect = ({ onChange }) => {
  const [form] = Form.useForm();

  const [switchClose, setSwitchClose] = useState(false);

  const { handleCreateCart, loading } = useCreateCart();

  const onSubmit = async () => {
    const formData = await form.validateFields();

    try {
      await handleCreateCart(formData).then((res) => {
        const createdCartId = res.data.cart.create.id;
        onChange(createdCartId);
        // window.location.reload();
      });
      notify.success({ message: t("cart.createCartSuccess") });
      setSwitchClose(!switchClose);
    } catch (error) {
      notify.error({ message: t("common.error") });
    }
  };

  return { form, onSubmit, loading, switchClose };
};

export default useModalSelectSelect;
