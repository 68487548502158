import CustomTable from "components/CustomTable";
import Card from "features/Cart/components/Card";
import CustomDescriptions from "components/CustomDescriptions";
import { Typography, Table } from "antd";
import { fillColumnsData } from "./columns";

export default function TableOrderItem(props) {
  const { t, dataCreateExportKey, productId2Serials, setProductId2Serials, totalInfo } = props;

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "rgba(5, 41, 135, 1)" }}>
          {t("v2.keyManagement.exportNoteCreate.table.title")}
        </Typography.Text>
      }
    >
      <CustomTable
        columns={fillColumnsData(t, productId2Serials, setProductId2Serials, dataCreateExportKey)}
        dataSource={dataCreateExportKey?.items}
        summary={(pageData) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={24} align="right">
                <CustomDescriptions
                  bordered={false}
                  colon={false}
                  contentStyle={{
                    color: "rgba(5, 41, 135, 1)",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    width: "10%",
                  }}
                  labelStyle={{
                    color: "rgba(5, 41, 135, 1)",
                    display: "flex",
                    width: "90%",
                    paddingLeft: "80%",
                  }}
                  contentMapping={[
                    {
                      label: t("v2.keyManagement.exportNoteCreate.table.totalProductType"),
                      value: `: ${totalInfo?.totalProduct}`,
                    },
                    {
                      label: t("v2.keyManagement.exportNoteCreate.table.totalQuantityOrder"),
                      value: `: ${totalInfo?.totalOrderQuantity}`,
                    },
                    {
                      label: t("v2.keyManagement.exportNoteCreate.table.totalQuantityCurrent"),
                      value: `: ${totalInfo?.totalCurrentQuantity}`,
                    },
                    {
                      label: <Typography.Text type={totalInfo.stateType}>{totalInfo.stateQuantity}</Typography.Text>,
                      value: <div></div>,
                    },
                  ]}
                />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
}
