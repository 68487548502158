import { Col, Row, Skeleton, Space, Tag, Typography } from "antd";
import Progress from "antd/es/progress";
import CustomCard from "components/CustomCard";
import { SelectLoadMore } from "components/CustomSelect";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import {
  CUSTOMER_STATUS,
  DATA_SOURCE,
  POTENTIAL_TABS,
  SELLER_TYPE,
  potentialStatusLabel,
  sellerTypeLabel,
  sellerTypeOptions,
} from "features/PotentialCustomer/constants";
import { t } from "i18next";
import CareInfoCard from "./CareInfoCard";
import ContactInfoCard from "./ContactInfoCard";
import ContactInfoCompanyCard from "./ContactInfoCompanyCard";
import GeneraInfoCard from "./GeneraInfoCard";
import VatInfoCard from "./VatInfoCard";

function FormPotentialCustomer({
  form,
  typePersonal,
  formValues,
  loadingIdNumber,
  loadingTaxCode,
  isDuplicateIdNumber,
  isDuplicateTaxCode,
  handleSearchIdNumber,
  handleSearchTaxCode,
  handleOpenDrawerMulti,
  loadingEmail,
  loadingTelephone,
  isDuplicateEmail,
  isDuplicateTelephone,
  handleSearchEmail,
  handleSearchTelephone,
  notBordered,
  dataMulti,
  loadingDetail,
  isDetail,
  valueIdNumber,
  valueTaxCode,
  valueEmail,
  valueTelephone,
  isCustomerButton,
  isUpdate,
  handleChangeInChargeStaff,
  handleChangeCareStaff,
  optionsCareStaffs,
  onLoadMoreCareStaff,
  hasMoreCareStaff,
  optionsInChargeStaff,
  onLoadMoreInChargeStaff,
  hasMoreInChargeStaff,
  handleSearchCareStaffs,
  handleSearchInChargeStaffs,
}) {
  const calcPercent = (value) => {
    switch (value) {
      case POTENTIAL_TABS.NEW:
        return 25;
      case POTENTIAL_TABS.TAKING_CARE:
        return 50;
      case POTENTIAL_TABS.COMPLETED:
        return 100;
      case POTENTIAL_TABS.STOP_CARING:
        return 100;
      default:
        return 0;
    }
  };

  const ON_BOARDING = [DATA_SOURCE.SELLER_APP, DATA_SOURCE.SELLER_WEB];

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        {notBordered ? (
          <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
            <Col span={8}>
              <Typography.Text strong>{t("potentialCustomer.form.staffInCharge")}</Typography.Text>
            </Col>
            <Col span={9}>
              <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
                <Typography.Text>{dataMulti?.inChargeStaff?.fullname || "--"}</Typography.Text>
              </Skeleton>
            </Col>
            {!isCustomerButton && (
              <Col span={7} className="custom-progress-steps">
                <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
                  <Space className="space-progress" direction="vertical" style={{ background: "transparent" }}>
                    <Progress
                      className={dataMulti?.status === POTENTIAL_TABS.STOP_CARING ? "progress-item" : ""}
                      percent={calcPercent(dataMulti?.status)}
                      steps={3}
                      showInfo={false}
                      strokeColor={["#0099FF", "#0099FF", "#FC5555"]}
                    />
                    <Typography.Text className="text-status">
                      {potentialStatusLabel?.[dataMulti?.status]}
                    </Typography.Text>
                  </Space>
                </Skeleton>
              </Col>
            )}
          </Row>
        ) : (
          <SelectLoadMore
            type={INPUT_TYPE.SELECT_LOAD_MORE}
            {...{
              formItemOptions: {
                label: <Typography.Text strong>{t("potentialCustomer.form.staffInCharge")}</Typography.Text>,
                labelAlign: "left",
                name: "inChargeStaffID",
                labelCol: { span: 8 },
                rules: [
                  {
                    required: false,
                    message: t("common.messageRequiredSelect", {
                      message: t("potentialCustomer.form.staffInCharge"),
                    }),
                  },
                ],
                style: { marginBottom: 0 },
              },
              inputOptions: {
                ...notBordered,
                placeholder: t("potentialCustomer.placeHolder.selectStaff"),
                options: optionsInChargeStaff,
                value: null,
                allowClear: true,
                optionLabelProp: "labelStaff",
              },
            }}
            onLoadMore={onLoadMoreInChargeStaff}
            hasMoreData={hasMoreInChargeStaff}
            onSearch={handleSearchInChargeStaffs}
            onChange={handleChangeInChargeStaff}
          />
        )}
      </Col>
      {isUpdate && dataMulti?.status === CUSTOMER_STATUS.TAKING_CARE && (
        <Col span={24}>
          <SelectLoadMore
            type={INPUT_TYPE.SELECT_LOAD_MORE}
            {...{
              formItemOptions: {
                label: <Typography.Text strong>{t("potentialCustomer.table.caringStaff")}</Typography.Text>,
                labelAlign: "left",
                name: "careStaffID",
                labelCol: { span: 8 },
                rules: [
                  {
                    required: true,
                    message: t("potentialCustomer.selectStaffError"),
                  },
                ],
                style: { marginBottom: 0 },
              },
              inputOptions: {
                ...notBordered,
                placeholder: t("potentialCustomer.selectStaff"),
                options: optionsCareStaffs,
                value: null,
                allowClear: true,
                optionLabelProp: "labelStaff",
              },
            }}
            onLoadMore={onLoadMoreCareStaff}
            hasMoreData={hasMoreCareStaff}
            onSearch={handleSearchCareStaffs}
            onChange={handleChangeCareStaff}
          />
        </Col>
      )}
      {isDetail && [POTENTIAL_TABS.TAKING_CARE, POTENTIAL_TABS.COMPLETED]?.includes(dataMulti?.status) ? (
        <Col span={24}>
          <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
            <Col span={8}>
              <Typography.Text strong>{t("potentialCustomer.table.caringStaff")}</Typography.Text>
            </Col>
            <Col span={16}>
              <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
                <Typography.Text>{dataMulti?.careStaff?.fullname || "--"}</Typography.Text>
              </Skeleton>
            </Col>
          </Row>
        </Col>
      ) : (
        <></>
      )}
      <Col span={24}>
        {notBordered ? (
          <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
            <Col span={8}>
              <Typography.Text strong>{t("potentialCustomer.form.customerType")}</Typography.Text>
            </Col>
            <Col span={10}>
              <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
                <Tag color={dataMulti?.type === SELLER_TYPE.COMPANY ? "geekblue" : "magenta"}>
                  {sellerTypeLabel?.[dataMulti?.type] || "--"}
                </Tag>
              </Skeleton>
            </Col>
          </Row>
        ) : (
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              label: <Typography.Text strong>{t("potentialCustomer.form.customerType")}</Typography.Text>,
              labelAlign: "left",
              name: "type",
              labelCol: { span: 8 },
              rules: [
                {
                  required: false,
                  message: t("common.messageRequiredSelect", {
                    message: t("potentialCustomer.form.customerType"),
                  }),
                },
              ],
            }}
            inputOptions={{
              ...notBordered,
              options: sellerTypeOptions,
            }}
          />
        )}
      </Col>
      <Col span={24}>
        <GeneraInfoCard
          typePersonal={typePersonal}
          formValues={formValues}
          dataMulti={dataMulti}
          loadingIdNumber={loadingIdNumber}
          loadingTaxCode={loadingTaxCode}
          isDuplicateIdNumber={isDuplicateIdNumber}
          isDuplicateTaxCode={isDuplicateTaxCode}
          handleSearchIdNumber={handleSearchIdNumber}
          handleSearchTaxCode={handleSearchTaxCode}
          handleOpenDrawerMulti={handleOpenDrawerMulti}
          notBordered={notBordered}
          loadingDetail={loadingDetail}
          isDetail={isDetail}
          valueIdNumber={valueIdNumber}
          valueTaxCod={valueTaxCode}
        />
      </Col>
      <Col span={24}>
        <ContactInfoCard
          typePersonal={typePersonal}
          form={form}
          formValues={formValues}
          loadingEmail={loadingEmail}
          dataMulti={dataMulti}
          loadingTelephone={loadingTelephone}
          isDuplicateEmail={isDuplicateEmail}
          isDuplicateTelephone={isDuplicateTelephone}
          handleSearchEmail={handleSearchEmail}
          handleSearchTelephone={handleSearchTelephone}
          handleOpenDrawerMulti={handleOpenDrawerMulti}
          notBordered={notBordered}
          loadingDetail={loadingDetail}
          valueEmail={valueEmail}
          valueTelephone={valueTelephone}
        />
      </Col>
      {typePersonal === SELLER_TYPE.COMPANY && (
        <Col span={24}>
          <ContactInfoCompanyCard
            loadingDetail={loadingDetail}
            form={form}
            formValues={formValues}
            notBordered={notBordered}
            dataMulti={dataMulti}
          />
        </Col>
      )}
      <Col span={24}>
        <CareInfoCard notBordered={notBordered} dataMulti={dataMulti} loadingDetail={loadingDetail} />
      </Col>
      {typePersonal === SELLER_TYPE.COMPANY && (
        <Col span={24}>
          <VatInfoCard
            form={form}
            formValues={formValues}
            notBordered={notBordered}
            dataMulti={dataMulti}
            loadingDetail={loadingDetail}
          />
        </Col>
      )}
      {(isDetail || isUpdate) && (
        <Col span={24}>
          <CustomCard
            title={
              <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                {t(
                  `potentialCustomer.form.${
                    !ON_BOARDING.includes(dataMulti?.dataSource) ? "linkLoginAccount" : "loginAccount"
                  }`
                )}
              </Typography.Text>
            }
            content={
              <Space direction="vertical">
                {dataMulti?.seller?.users?.length ? (
                  dataMulti?.seller?.users?.map((item, index) => {
                    return (
                      <Row gutter={[0, 8]} style={{ background: "#F7F8FB", padding: "8px", borderRadius: "8px" }}>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.loginAccount")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.email || "--"}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.username")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.fullname || "--"}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.telephone || "--"}</Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography.Text>{t("potentialCustomer.form.notLinked")}</Typography.Text>
                )}
              </Space>
            }
          />
        </Col>
      )}
    </Row>
  );
}

export default FormPotentialCustomer;
