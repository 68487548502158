import React, { useState } from "react";
import { Col, Form, Row, Space, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import UploadBannerButton from "features/Campaign/components/UploadBannerButton";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import QuillEditor from "components/Editor";
import "./index.scss";

function MarketingInfo({ form }) {
  const [files, setFiles] = useState([]);

  const onEditorChange = (value) => {
    const descriptionString = value;
    form.setFieldsValue({ description: descriptionString });
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const marketingInfoFields = {
    banner: {
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.bannerDesktop")}</Typography.Text>,
        name: "bannerDesktop",
        getValueFromEvent: normFile,
        rules: [
          {
            required: true,
            message: t("common.messageRequiredType", {
              message: t("campaign.form.bannerDesktop"),
            }),
          },
        ],
      },
    },
    bannerMobile: {
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.bannerMobile")}</Typography.Text>,
        name: "bannerMobile",
        getValueFromEvent: normFile,
        rules: [
          {
            required: true,
            message: t("common.messageRequiredType", {
              message: t("campaign.form.campaignBannerRez"),
            }),
          },
        ],
      },
    },
    link: {
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.campaignLink")}</Typography.Text>,
        name: "link",
        rules: [{ type: "url", message: t("campaign.form.incorrectFormat") }],
      },
      inputOptions: {
        placeholder: t("campaign.form.campaignLinkPlaceholder"),
      },
    },
    highlight: {
      type: INPUT_TYPE.TEXT_AREA,
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.highlight")}</Typography.Text>,
        name: "highlight",
        rules: [
          {
            required: true,
            message: t("common.messageRequiredType", { message: t("campaign.form.highlight") }),
          },
          { max: 300, message: t("campaign.form.descriptionOutOfLimit") },
        ],
      },
      inputOptions: {
        placeholder: t("campaign.form.highlightPlaceholder"),
        rows: 3,
      },
    },
    description: {
      formItemOptions: {
        label: <Typography.Text strong>{t("campaign.form.campaignDescription")}</Typography.Text>,
        name: "description",
      },
      inputOptions: {
        onEditorChange: onEditorChange,
        onFilesChange: onFilesChange,
      },
    },
  };

  const { banner, description, link, highlight, bannerMobile } = marketingInfoFields;

  return (
    <Card title={<Typography.Title level={5}>{t("campaign.form.marketingInfo")}</Typography.Title>}>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput {...link} />
          <FormInput {...highlight} />
          <Form.Item className="custom-markdown" {...description.formItemOptions}>
            <QuillEditor {...description.inputOptions} canUpdateInfo={false} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item {...banner.formItemOptions}>
              <UploadBannerButton />
            </Form.Item>
            <Form.Item {...bannerMobile.formItemOptions}>
              <UploadBannerButton />
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Card>
  );
}

export default MarketingInfo;
