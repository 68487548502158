import { DATE_FORMAT, DATE_TIME_FORMAT } from "config/constants";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";

export const convertTimeDate = (value, format) => {
  if (value === null) {
    return "--";
  } else {
    return formatDateTime(value, format);
  }
};

const DATE_UNIT = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
};

export const convertUnit = (unit) => {
  switch (unit) {
    case DATE_UNIT.DAY:
      return t("common.DAY");
    case DATE_UNIT.MONTH:
      return t("common.month");
    case DATE_UNIT.YEAR:
      return t("common.YEAR");
    default:
      return;
  }
};

export const convertWarrantyDetail = (data) => ({
  ...data,
  id: data?.id,
  code: data?.code,
  serialImei: data?.serialImei,
  productID: data?.productID,
  product: {
    id: data?.product?.id,
    code: data?.product?.code,
    name: data?.product?.name,
  },
  categoryID: data?.categoryID,
  category: {
    id: data?.category?.id,
    name: data?.category?.name,
  },
  orderID: data?.orderID,
  order: {
    id: data?.order?.id,
    number: data?.order?.number,
  },
  sellerID: data?.sellerID,
  seller: {
    id: data?.seller?.id,
    fullName: data?.seller?.fullName,
    address: data?.seller?.address,
    telephone: data?.seller?.telephone,
    email: data?.seller?.email,
  },
  providerID: data?.providerID,
  provider: {
    id: data?.provider?.id,
    fullName: data?.provider?.fullName,
    telephone: data?.provider?.telephone,
    email: data?.provider?.email,
    address: data?.provider?.address,
    warranty: data?.provider?.warranty,
    importDate: formatDateTime(data?.provider?.importDate, DATE_FORMAT),
    endDate: formatDateTime(data?.provider?.endDate, DATE_FORMAT),
  },
  branchID: data?.branchID,
  branch: {
    id: data?.branch?.id,
    name: data?.branch?.name,
  },
  warrantyType: data?.warrantyType,
  status: data?.status,
  soldDate: formatDateTime(data?.soldDate, DATE_FORMAT),
  warrantyEndDate: formatDateTime(data?.warrantyEndDate, DATE_FORMAT),
  warrantyOnSale: data?.warrantyOnSale,
  productName: data?.productName,
  customerName: data?.customerName,
  supplierName: data?.supplierName,
  remainingWarrantyDays: data?.remainingWarrantyDays,
  createdAt: formatDateTime(data?.createdAt, DATE_TIME_FORMAT),
  updatedAt: formatDateTime(data?.updatedAt, DATE_TIME_FORMAT),
  importDayFromProvider: formatDateTime(data?.importDayFromProvider, DATE_FORMAT),
  warrantyPeriodProviders: data?.warrantyPeriodProviders,
  endDateFromProvider: formatDateTime(data?.endDateFromProvider, DATE_FORMAT),
  warrantyDateUnit: convertUnit(data?.warrantyDateUnit),
  providerDateUnit: convertUnit(data?.providerDateUnit),
  remainingWarrantyDaysFromProvider: data?.remainingWarrantyDaysFromProvider,
});

export const convertWarrantyRequestDetail = (data) => ({
  id: data?.id,
  code: data?.code,
  warrantyCustomerName: data?.warrantyCustomerName,
  warrantyCustomerPhone: data?.warrantyCustomerPhone,
  warrantyCustomerAddress: data?.warrantyCustomerAddress,
  warrantyReceiveMethod: data?.warrantyReceiveMethod,
  totalItemsByStatus: data?.totalItemsByStatus,
  branchID: data?.branchID,
  branch: data?.branch,
  note: data?.note,
  totalItems: data?.totalItems,
  status: data?.status,
  createdAt: convertTimeDate(data?.createdAt, DATE_TIME_FORMAT),
  updatedAt: convertTimeDate(data?.updatedAt, DATE_TIME_FORMAT),
  updatedBy: data?.updatedBy,
  items: data?.items,
});

export const converSerialDetailWarrantyNote = (data) => ({});
