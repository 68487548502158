import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Select, Space, Tooltip } from "antd";
import { Icon } from "assets/icons";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import { PrimaryButton } from "components/PrimaryButton";

import CustomTags from "components/CustomTags";
import { DATE_FORMAT } from "config/constants";
import { KEY_MANAGEMENT, statusKeyListOptions } from "features/KeyManagement/constants";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useGetCreatedByWithIds } from "hooks/order";
import { useGetProductWithIds } from "hooks/product/product";
import { useGetSellerWithIds } from "hooks/seller";
import { useGetSupplierWithIds } from "hooks/supplier";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import { CustomDownloadFile } from "../components/CustomDownloadFile";
import { CustomUploadFile } from "../components/CustomUploadFile";
import "./index.scss";

const { Option } = Select;

function Toolbar({ onChangeParams, params, onSearch, canKeyLicenseAccountCreate }) {
  const { t } = useTranslation();
  const GET_QUERY = getQuery();
  const selectAfter = (
    <Select
      onChange={(value) => onChangeParams({ serialTypes: value === "ALL" ? KEY_MANAGEMENT : value })}
      defaultValue={Array.isArray(params.filters.serialTypes) ? "ALL" : params.filters.serialTypes}
      className="select-after"
    >
      <Option value="ALL">{t("v2.keyManagement.inItsEntirety")}</Option>
      <Option value={KEY_MANAGEMENT[0]}>{t("v2.keyManagement.inTheKeyLicenseType")}</Option>
      <Option value={KEY_MANAGEMENT[1]}>{t("v2.keyManagement.inTheAccountType")}</Option>
    </Select>
  );

  const handleChangeDate = (value) => {
    const { from, to } = value;

    onChangeParams &&
      onChangeParams({
        timeRange: {
          from: from,
          to: to,
        },
      });
  };

  const handleOnClose = (key) => {
    switch (key) {
      case "timeRange":
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
    }
  };

  const renderDateTag = () => {
    const from = formatDateTime(params?.filters?.timeRange?.from, DATE_FORMAT);
    const to = formatDateTime(params?.filters?.timeRange?.to, DATE_FORMAT);

    return (
      params?.filters?.timeRange?.from && (
        <CustomTags
          handleOnClose={() => handleOnClose("timeRange")}
          label={
            <>
              <span className="label">{t("campaign.tags.startDate")}:</span>&nbsp;
              {from} - {to}
            </>
          }
        />
      )
    );
  };

  const { data: dataCreatedByWithIds } = useGetCreatedByWithIds({
    ids: GET_QUERY.createdByIDs ? GET_QUERY.createdByIDs : undefined,
  });
  const { data: dataProviderWithIds } = useGetSupplierWithIds({
    ids: GET_QUERY.providerIDs ? GET_QUERY.providerIDs : undefined,
  });
  const { data: dataSellerWithIds } = useGetSellerWithIds({
    ids: GET_QUERY.sellerIDs ? GET_QUERY.sellerIDs : undefined,
  });
  const { data: productListOptions } = useGetProductWithIds({
    ids: GET_QUERY.productIDs ? GET_QUERY.productIDs : undefined,
  });

  const tagOptions = [
    {
      title: t("v2.keyManagement.supplier"),
      options: dataProviderWithIds,
      selected: params?.filters?.providerIDs,
      key: "providerIDs",
    },
    {
      title: t("v2.keyManagement.importer"),
      options: dataCreatedByWithIds,
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
    {
      title: t("v2.keyManagement.customer"),
      options: dataSellerWithIds,
      selected: params?.filters?.sellerIDs,
      key: "sellerIDs",
    },
    {
      title: t("v2.keyManagement.status"),
      options: statusKeyListOptions,
      selected: params?.filters?.statuses,
      key: "statuses",
    },
    {
      title: t("v2.keyManagement.parameter"),
      options: productListOptions,
      selected: params?.filters?.productIDs,
      key: "productIDs",
    },
  ];

  const tagLabel = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    const label = result.map((item) => item?.label).join(", ");

    return (
      <div>
        <span className="label">{title}</span>:{" "}
        <Tooltip title={result?.length >= 5 && label}>
          {result?.length >= 5 ? truncateAddDots(label, 100) : label}
        </Tooltip>
      </div>
    );
  };

  const renderTags = tagOptions
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={tagLabel(options, selected, title)} />
      );
    });

  return (
    <Row gutter={[16]} className="toolbar-key-list">
      <Col span={24}>
        <Space style={{ width: "100%", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Space>
            <FilterDate title={t("v2.keyManagement.dateAdded")} onChangeDate={handleChangeDate} />
            <div className="toolbar-key-list-input">
              <Input
                prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
                placeholder={t("v2.keyManagement.inputSearch")}
                addonAfter={selectAfter}
                onChange={onSearch}
                defaultValue={params?.filters?.queries}
                allowClear
              />
            </div>
          </Space>
          <div className="toolbar-key-list-actions">
            <Space
              className="toolbar-key-list-actions-space"
              style={{ display: "flex", gap: "12px !important", alignItems: "center" }}
            >
              <div style={{ display: "flex" }}>
                <CustomUploadFile />
              </div>
              <div style={{ display: "flex" }}>
                <CustomDownloadFile />
              </div>
              {canKeyLicenseAccountCreate && <PrimaryButton title={t("v2.keyManagement.addNew")} />}
            </Space>
          </div>
        </Space>
      </Col>
      <Col span={24}>
        {renderDateTag()}
        {renderTags}
      </Col>
    </Row>
  );
}

export default Toolbar;
