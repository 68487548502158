import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Row, Typography } from "antd";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import { COMBO_CAMPAIGN, CONDITION_PRODUCT_TYPE, TYPE_LIMIT } from "features/Campaign/constants";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ComboCollapse from "./ComboCollapse";

const { Panel } = Collapse;

const initPromotionCampaignTiersForm = {
  type: COMBO_CAMPAIGN.GIFT,
  maxQuantityPerSeller: null,
  limitType: TYPE_LIMIT.LIMIT,
  conditionProducts: [
    {
      type: CONDITION_PRODUCT_TYPE.CONDITION_PRODUCT,
      quantity: null,
      conditionPromotionProducts: [],
    },
  ],
  conditionGifts: [
    {
      type: CONDITION_PRODUCT_TYPE.GIFT_PRODUCT,
      quantity: null,
      conditionPromotionProducts: [],
    },
  ],
};

function ComboGiftForm({
  form,
  indexComboGift,
  remove,
  onRemoveValueComboSelected,
  isUpdate,
  promotionCampaignTiers,
  dataTiersServer,
  onGetDataGift,
}) {
  const [valuesForm, setValuesForm] = useState();
  const [newDataTiersServer, setNewDataTiersServer] = useState(dataTiersServer);

  const handleGetDataTiersServer = (value) => {
    setNewDataTiersServer(value);
  };

  const handleRemoveCollapse = () => {
    if (valuesForm.promotionCampaignTierGroupsGift?.length === 1) {
      onRemoveValueComboSelected(COMBO_CAMPAIGN.GIFT);
    }
    onGetDataGift && onGetDataGift([]);
    remove();
  };

  useEffect(() => {
    !isUpdate &&
      form.setFieldsValue({
        promotionCampaignTiers: [initPromotionCampaignTiersForm],
      });
  }, []);

  useEffect(() => {
    const values = form.getFieldsValue();
    setValuesForm(values);
  }, [JSON.stringify(form.getFieldsValue())]);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapses" expandIconPosition="left">
      <Panel
        key="1"
        header={
          <Typography.Text strong style={{ textTransform: "uppercase" }}>
            {t("campaign.combo.comboGift")} {indexComboGift + 1}
          </Typography.Text>
        }
        extra={
          <CustomModal
            {...{
              message: t("campaign.combo.errorMessage.removeComboCollapse"),
              onOke: () => handleRemoveCollapse(),
              footer: false,
              icon: Img.DeleteIcon,
              customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
            }}
          />
        }
      >
        <Row className="panel-main">
          <Col span={24}>
            <Form.List name={[indexComboGift, "promotionCampaignTiers"]}>
              {(fields, { add, remove }) => {
                return (
                  <Row>
                    {fields.map((field, index) => (
                      <Col span={24} key={field.key}>
                        <ComboCollapse
                          indexTierComboGift={index}
                          indexComboGift={indexComboGift}
                          remove={() => remove(field.name)}
                          form={form}
                          promotionCampaignTiers={promotionCampaignTiers}
                          isUpdate={isUpdate}
                          fields={fields}
                          dataTiersServer={newDataTiersServer}
                          onGetDataTiersServer={handleGetDataTiersServer}
                          valuesForm={valuesForm}
                        />
                      </Col>
                    ))}
                    {fields?.length < 10 && (
                      <Col span={24}>
                        <Typography.Link
                          underline
                          onClick={() => {
                            add(initPromotionCampaignTiersForm);
                            setNewDataTiersServer(valuesForm?.promotionCampaignTierGroupsGift);
                          }}
                        >
                          <PlusOutlined /> {t("campaign.combo.addIncentives")}
                        </Typography.Link>
                      </Col>
                    )}
                  </Row>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

export default ComboGiftForm;
