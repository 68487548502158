import React from "react";
import { Col, Row, Space, Typography } from "antd";
import { t } from "i18next";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";

function AddInfo({ data }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text strong>{t("common.description")} </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>{data?.note || "---"}</Typography.Text>
          </Col>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Col span={24}>
            <Typography.Text strong>{t("warehouse.create.ImageUpload")}</Typography.Text>
          </Col>
          <Col span={24}>
            <Space style={{ marginTop: "10px" }}>
              {data?.fileURLs?.map((fileURL, index) => {
                return <CustomViewDocumentFiles file={fileURL} key={index} />;
              }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AddInfo;
