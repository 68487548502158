import React, { useState } from "react";
import CustomModal from "components/CustomModal";
import {
  useGetSellerContact,
  useUpdateSellerContact,
  useDeleteSellerContact,
  useSellerPermissions,
} from "hooks/seller";
import { useParams } from "react-router-dom";
import Img from "assets/images";
import { notify } from "utils/helperFuncs";
import { debounce } from "utils/helperFuncs";
import { useTranslation } from "react-i18next";
import ModalUpdateAddress from "features/Agent/components/ModalAddress/ModalUpdateAddress/ModalUpdateAddress";
import { Tooltip } from "antd";
import TextAlignLeft from "components/TextAlignLeft";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useSupplierPermissions } from "hooks/supplier";

function useAddressBook({ isSupplier }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const [switchClose, setSwitchClose] = useState(true);
  const [query, setQuery] = useState(null);

  const { sellerContacts, refetch, loading: fetching } = useGetSellerContact({ sellerID: id, query });
  const { handleUpdateContact } = useUpdateSellerContact();
  const { handleDeleteContact } = useDeleteSellerContact();
  const { canUpdateContact, canDeleteContact } = useSellerPermissions();
  const { canSupplierContactUpdate, canSupplierContactDelete } = useSupplierPermissions();

  const canUpdateContactAll = isSupplier ? canSupplierContactUpdate : canUpdateContact;

  const columns = [
    {
      title: t("common.index"),
      dataIndex: "no",
    },
    {
      title: t("seller.componentAddressList.fullName"),
      dataIndex: "fullName",
    },
    {
      title: t("seller.componentAddressList.address"),
      dataIndex: "address",
      render: (_, record) => {
        const fullAddress = `${record?.address ? record?.address + "," : ""} ${record?.ward?.name}, ${
          record?.district?.name
        }, ${record?.city?.name}`;
        return (
          <Tooltip title={fullAddress}>
            <TextAlignLeft value={truncateAddDots(fullAddress, 80)} />
          </Tooltip>
        );
      },
    },
    {
      title: t("seller.componentAddressList.telephone"),
      dataIndex: "telephone",
    },
    {
      title: t("seller.componentAddressList.actions"),
      dataIndex: "actions",
      align: "center",
      render: (_, record) => {
        return (
          <div className="actions">
            {!record?.isDefault && (
              <CustomModal
                {...{
                  onOke: () => setAsDefaultContact(record),
                  okeButtonLabel: t("common.confirm"),
                  customComponent: <a>{t("seller.componentAddressList.setupDefault")}</a>,
                  title: (
                    <span className="add-address-title">{t("seller.componentAddressList.setupAddressDefault")}</span>
                  ),
                  centered: true,
                  footer: false,
                  switchClose,
                  afterClose: refetch,
                  message: t("seller.componentAddressList.messageSetupAddressDefault"),
                }}
              />
            )}

            {(isSupplier ? canSupplierContactUpdate : canUpdateContact) && (
              <ModalUpdateAddress sellerID={id} refetch={refetch} contact={record} />
            )}

            {!record?.isDefault && (isSupplier ? canSupplierContactDelete : canDeleteContact) && (
              <CustomModal
                {...{
                  message: t("seller.componentAddressList.messageDeleteAddress"),
                  onOke: () => deleteContact(record?.id),
                  okeButtonLabel: t("common.confirm"),
                  customComponent: <a className="delete-action">{t("common.delete")}</a>,
                  centered: true,
                  footer: false,
                  width: "fit-content",
                  switchClose,
                  afterClose: refetch,
                  icon: Img.DeleteIcon,
                  closeOnOke: true,
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  async function deleteContact(addressId) {
    try {
      await handleDeleteContact({
        id: addressId,
      });
      notify.success({
        message: t("seller.componentAddressList.messageDeleteAddressSuccess"),
      });
    } catch (err) {
      notify.error({
        message: t("seller.componentAddressList.messageDeleteAddressError"),
        description: err?.message,
      });
    }
  }

  //Filter role by text
  const filterAddressByTextDebounced = debounce(filterAddressByText, 400);

  function filterAddressByText([text]) {
    if (text[0] === " ") {
      return;
    }
    setQuery(text);
  }

  async function setAsDefaultContact(record) {
    const { address, cityID, districtID, fullName, telephone, wardID } = record;
    try {
      await handleUpdateContact({
        id: record?.id,
        contact: {
          address,
          cityID,
          districtID,
          fullName,
          telephone,
          wardID,
          isDefault: true,
          sellerID: id,
        },
      });
      notify.success({
        message: t("seller.componentAddressList.messageSetupAddressDefaultSuccess"),
      });
      refetch();
    } catch (err) {
      notify.error({
        message: t("seller.componentAddressList.messageSetupAddressDefaultError"),
      });
    }
  }
  return {
    filterAddressByTextDebounced,
    id,
    columns,
    sellerContacts,
    fetching,
    refetch,
    canUpdateContactAll,
  };
}

export default useAddressBook;
