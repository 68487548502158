import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { t } from "i18next";
import { buildQueryString } from "utils/helperFuncs";

export const POTENTIAL_TABS = {
  NEW: "NEW",
  TAKING_CARE: "TAKING_CARE",
  STOP_CARING: "STOP_CARING",
  COMPLETED: "COMPLETE_CARING",
};

export const CUSTOMER_STATUS = {
  NEW: "NEW",
  TAKING_CARE: "TAKING_CARE",
  STOP_CARING: "STOP_CARING",
  COMPLETED: "COMPLETE_CARING",
  WAIT_APPROVE: "WAIT_APPROVE",
  APPROVED_SELLER: "APPROVED_SELLER",
};

export const potentialStatusLabel = {
  [CUSTOMER_STATUS.NEW]: t("potentialCustomer.status.new"),
  [CUSTOMER_STATUS.TAKING_CARE]: t("potentialCustomer.status.takingCare"),
  [CUSTOMER_STATUS.STOP_CARING]: t("potentialCustomer.status.stopTaking"),
  [CUSTOMER_STATUS.COMPLETED]: t("potentialCustomer.status.completed"),
  [CUSTOMER_STATUS.APPROVED_SELLER]: t("potentialCustomer.status.seller"),
};

export const PRIORITY_CUSTOMER = {
  HIGH: "HIGH",
  LOW: "LOW",
  UNCATEGORIZED: "UNCATEGORIZED",
};

export const priorityCustomerLabel = {
  [PRIORITY_CUSTOMER.HIGH]: t("potentialCustomer.priorityCustomerHigh"),
  [PRIORITY_CUSTOMER.LOW]: t("potentialCustomer.priorityCustomerLow"),
  [PRIORITY_CUSTOMER.UNCATEGORIZED]: t("potentialCustomer.unclassified"),
};

export const priorityCustomerOptions = [
  { value: PRIORITY_CUSTOMER.HIGH, label: t("potentialCustomer.priorityCustomerHigh") },
  { value: PRIORITY_CUSTOMER.LOW, label: t("potentialCustomer.priorityCustomerLow") },
  { value: PRIORITY_CUSTOMER.UNCATEGORIZED, label: t("potentialCustomer.unclassified") },
];

export const SELLER_TYPE = {
  COMPANY: "COMPANY",
  PERSONAL: "PERSONAL",
};

export const sellerTypeLabel = {
  [SELLER_TYPE.COMPANY]: t("potentialCustomer.sellerTypeCompany"),
  [SELLER_TYPE.PERSONAL]: t("potentialCustomer.sellerTypePersonal"),
};

export const sellerTypeOptions = [
  { value: SELLER_TYPE.PERSONAL, label: t("potentialCustomer.sellerTypePersonal") },
  { value: SELLER_TYPE.COMPANY, label: t("potentialCustomer.sellerTypeCompany") },
];

export const DATA_SOURCE = {
  FACEBOOK: "FACEBOOK",
  GOOGLE: "GOOGLE",
  ZALO: "ZALO",
  MASOTHUE: "MASOTHUE",
  SHOPEE: "SHOPEE",
  SELLER_APP: "SELLER_APP",
  SELLER_WEB: "SELLER_WEB",
};

export const dataSourceLabel = {
  [DATA_SOURCE.FACEBOOK]: t("potentialCustomer.facebook"),
  [DATA_SOURCE.GOOGLE]: t("potentialCustomer.google"),
  [DATA_SOURCE.ZALO]: t("potentialCustomer.zalo"),
  [DATA_SOURCE.MASOTHUE]: truncateAddDots(t("potentialCustomer.mst"), 13),
  [DATA_SOURCE.SHOPEE]: t("potentialCustomer.shoppe"),
  [DATA_SOURCE.SELLER_APP]: t("potentialCustomer.app"),
  [DATA_SOURCE.SELLER_WEB]: t("potentialCustomer.web"),
};

export const dataSourceTagColor = {
  [DATA_SOURCE.FACEBOOK]: "blue",
  [DATA_SOURCE.GOOGLE]: "red",
  [DATA_SOURCE.ZALO]: "geekblue",
  [DATA_SOURCE.MASOTHUE]: "purple",
  [DATA_SOURCE.SHOPEE]: "orange",
  [DATA_SOURCE.SELLER_APP]: "green",
  [DATA_SOURCE.SELLER_WEB]: "cyan",
};

export const dataSourceOptions = [
  { value: DATA_SOURCE.FACEBOOK, label: t("potentialCustomer.facebook") },
  { value: DATA_SOURCE.GOOGLE, label: t("potentialCustomer.google") },
  { value: DATA_SOURCE.ZALO, label: t("potentialCustomer.zalo") },
  { value: DATA_SOURCE.SHOPEE, label: t("potentialCustomer.shoppe") },
  { value: DATA_SOURCE.MASOTHUE, label: t("potentialCustomer.mst") },
];
export const dataSourceFilterOptions = [
  { value: DATA_SOURCE.SELLER_APP, label: t("potentialCustomer.app") },
  { value: DATA_SOURCE.SELLER_WEB, label: t("potentialCustomer.web") },
  { value: DATA_SOURCE.FACEBOOK, label: t("potentialCustomer.facebook") },
  { value: DATA_SOURCE.GOOGLE, label: t("potentialCustomer.google") },
  { value: DATA_SOURCE.ZALO, label: t("potentialCustomer.zalo") },
  { value: DATA_SOURCE.SHOPEE, label: t("potentialCustomer.shoppe") },
  { value: DATA_SOURCE.MASOTHUE, label: t("potentialCustomer.mst") },
];

export const COMMUNICATION_TYPE = {
  CALL: "CALL",
  MESSAGE: "MESSAGE",
  EMAIL: "EMAIL",
  MEET: "MEET",
};

export const communicationTypeLabel = {
  [COMMUNICATION_TYPE.CALL]: t("potentialCustomer.activityLogs.call"),
  [COMMUNICATION_TYPE.MESSAGE]: t("potentialCustomer.activityLogs.mess"),
  [COMMUNICATION_TYPE.EMAIL]: t("potentialCustomer.activityLogs.email"),
  [COMMUNICATION_TYPE.MEET]: t("potentialCustomer.activityLogs.meet"),
};

export const communicationTypeOptions = [
  { value: COMMUNICATION_TYPE.CALL, label: t("potentialCustomer.activityLogs.call") },
  { value: COMMUNICATION_TYPE.MESSAGE, label: t("potentialCustomer.activityLogs.mess") },
  { value: COMMUNICATION_TYPE.EMAIL, label: t("potentialCustomer.activityLogs.email") },
  { value: COMMUNICATION_TYPE.MEET, label: t("potentialCustomer.activityLogs.meet") },
];

export const ACTIVITY_LOG_TYPE = {
  UPDATE_CUSTOMER_STATUS: "UPDATE_CUSTOMER_STATUS",
  COMMUNICATE: "COMMUNICATE",
};

export const activityLogTypeLabel = {
  [ACTIVITY_LOG_TYPE.UPDATE_CUSTOMER_STATUS]: t("potentialCustomer.activityLogs.infoUpdated"),
  [ACTIVITY_LOG_TYPE.COMMUNICATE]: t("potentialCustomer.activityLogs.exchangeWithCustomer"),
};

export const EVENT_TYPE = {
  REJECT_SELLER: "REJECT_SELLER",
  MERGE: "MERGE",
};

export const eventTypeLabel = {
  [EVENT_TYPE.REJECT_SELLER]: t("potentialCustomer.activityLogs.call"),
  [EVENT_TYPE.MERGE]: t("potentialCustomer.activityLogs.mess"),
};

export const ERROR_TYPE_UPLOAD = {
  WRONG_FORMAT: "WRONG_FORMAT",
  LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
};

export const FILTER_TYPE = {
  ALL: "ALL",
  INCLUDE: "INCLUDE",
  INCLUDE_NULL: "INCLUDE_NULL",
};

export const DESIST_CUSTOMER = {
  NO_DEMAND: "NO_DEMAND",
  NO_INTERESTED_CATEGORY: "NO_INTERESTED_CATEGORY",
  HAD_ACCOUNT: "HAD_ACCOUNT",
  OTHER: "OTHER",
};

export const REJECT_CUSTOMER_REASON = {
  MISSING_INFO: "MISSING_INFO",
  INVALID_INFO: "INVALID_INFO",
  OTHER: "OTHER",
};

export const formatBuildQueryString = (values) => {
  buildQueryString({
    params: {
      ...values?.filters,
      offset: values?.pagination?.offset,
      limit: values?.pagination?.limit,
      priorityFilter: values?.filters?.priorityFilter?.priorities,
      inChargeStaffIDFilter: values?.filters?.inChargeStaffIDFilter?.inChargeStaffIDs,
      careStaffIDFilter: values?.filters?.careStaffIDFilter?.careStaffIDs,
      filterTypePriorityFilter: values?.filters?.priorityFilter?.filterType,
      filterTypeInChargeStaffIDFilter: values?.filters?.inChargeStaffIDFilter?.filterType,
      filterTypeCareStaffIDFilter: values?.filters?.careStaffIDFilter?.filterType,
    },
  });
};
