import { Typography } from "antd";
import EllipsisText from "components/_storybooks/EllipsisText";
import { FilterCreatedByIDs, FilterSellerIDs } from "components/_storybooks/FilterLoadMore";
import FilterMenu from "components/_storybooks/FilterMenu";
import { DATE_TIME_FORMAT4 } from "config/constants";
import { useGetUserCurrent } from "hooks/auth/auth";
import { useSalesChannelInputOptions } from "hooks/salesChannel";
import { renderPaymentMethod, renderStatus, tagColorSalesChannel } from "pages/App/Order/conts";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency, getQuery } from "utils/helperFuncs";
import BadgeStatusDot from "../../components/BadgeStatusDot";
import SpaceCustom from "../../components/SpaceVertical";
import TagCustom from "../../components/TagCustom";
import {
  COMPLETED_DATE_OPTIONS,
  EXPIRED_DATE_OPTIONS,
  ORDER_STATUS_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  PROFIT_RECONCILIATION,
  RECONCILIATION_STATUS_OPTIONS,
  REJECTED_REASON_CAUSE_LABEL,
  reconciliationStatusConverter,
} from "../../constants";

const getTimeConvert = (value) => {
  const now = new Date();
  const newDate = new Date(value);
  newDate.setDate(newDate.getDate());

  const timeLeft = newDate - now;
  const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
  const daysLeft = Math.floor(hoursLeft / 24);

  return {
    hoursLeft,
    daysLeft,
  };
};

const convertExpiredDateNote = (record) => {
  const { expiredDate } = record;

  const { daysLeft, hoursLeft } = getTimeConvert(expiredDate);

  if (!expiredDate) {
    return null;
  }

  if (hoursLeft < -24) {
    return <Typography.Text type="danger">Quá hạn {Math.abs(daysLeft)} ngày</Typography.Text>;
  } else if (hoursLeft < 0) {
    return <Typography.Text type="danger">Quá hạn {Math.abs(hoursLeft)} giờ</Typography.Text>;
  } else if (hoursLeft < 24) {
    return (
      <Typography.Text type="warning">
        Còn lại {hoursLeft < 1 && "~"}
        {hoursLeft === 0 ? 1 : hoursLeft} giờ
      </Typography.Text>
    );
  } else {
    return <Typography.Text type="success">Còn lại {daysLeft} ngày</Typography.Text>;
  }
};

const convertCompletedDateNote = (record) => {
  const { completedDate, expiredDate } = record;

  const { daysLeft: completedDateDayLeft, hoursLeft: completedDateHoursLeft } = getTimeConvert(completedDate);
  const { daysLeft: expiredDateDaysLeft, hoursLeft: expiredDateHoursLeft } = getTimeConvert(expiredDate);
  const daysLeft = completedDateDayLeft - expiredDateDaysLeft;
  const hoursLeft = completedDateHoursLeft - expiredDateHoursLeft;

  if (!completedDate) {
    return null;
  }

  if (daysLeft <= 0) {
    return <Typography.Text type="success">Đúng hạn</Typography.Text>;
  }

  // // TH2: Quá hạn nếu thời gian còn lại > 0 giờ và < 24 giờ
  // if (hoursLeft > 24 && completedDateDayLeft === expiredDateDaysLeft) {
  //   return <Typography.Text type="danger">Quá hạn {Math.abs(hoursLeft)} giờ</Typography.Text>;
  // }

  if (daysLeft >= 0) {
    return <Typography.Text type="danger">Quá hạn {Math.abs(daysLeft)} ngày</Typography.Text>;
  }
};

function TablesList({ handleVisibleDrawerDetail, onFilter }) {
  const GET_QUERY = getQuery();
  const { userCurrentData } = useGetUserCurrent();
  const { convertedData: dataSalesChannel } = useSalesChannelInputOptions();

  const columnsProfit = {
    stt: {
      title: "STT",
      align: "center",
      width: 50,
      fixed: "left",
      render: (_, record, index) => index + 1,
    },
    burningDeadline: {
      width: 200,
      align: "center",
      fixed: "left",
      title: "Hạn chót đốt soát",
      render: (_, record) => {
        if (!record?.expiredDate) {
          return <Typography.Link>Đơn chưa hoàn thành</Typography.Link>;
        }
        return (
          <SpaceCustom>
            {formatDateTime(record.expiredDate, DATE_TIME_FORMAT4)}
            {convertExpiredDateNote(record)}
          </SpaceCustom>
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter({ expiredDateStatuses: value })} options={EXPIRED_DATE_OPTIONS} />
      ),
    },
    createdAt: {
      title: "Ngày tạo",
      align: "center",
      width: 100,
      sorter: true,
      fixed: GET_QUERY.reconciliationStatuses !== PROFIT_RECONCILIATION.RECONCILING && "left",
      render: (_, record) => formatDateTime(record?.order?.createdAt),
    },
    orderCode: {
      width: 180,
      fixed: "left",
      title: "Mã đơn hàng",
      render: (_, record) => {
        return (
          <Typography.Link strong onClick={() => handleVisibleDrawerDetail(record?.id)}>
            {record?.order?.number}
          </Typography.Link>
        );
      },
    },
    agentInfo: {
      width: 250,
      title: "Đại lý",
      render: (_, record) => {
        const order = record?.order;
        return (
          <SpaceCustom>
            <Typography.Link target="_blank" strong href={`/seller/detail/${order?.sellerID}`}>
              {order?.seller?.idNumber || order?.seller?.code}
            </Typography.Link>
            <EllipsisText title={order?.seller?.fullName} />
          </SpaceCustom>
        );
      },
      filterDropdown: <FilterSellerIDs onFilter={(value, option) => onFilter({ sellerIDs: value })} />,
    },
    createdBy: {
      width: 220,
      title: "Tài khoản tạo đơn",
      render: (_, record) => {
        const order = record?.order;
        return (
          <SpaceCustom>
            <Typography.Text>{order?.createdBy?.fullname}</Typography.Text>
            <Typography.Text style={{ color: "#5B6673" }}>{order?.createdBy?.email}</Typography.Text>
          </SpaceCustom>
        );
      },
    },
    orderValue: {
      width: 150,
      align: "right",
      title: "Giá trị đơn",
      render: (_, record) => {
        return <EllipsisText title={formatCurrency(record?.order?.customerTotal)} />;
      },
    },
    agentProfit: {
      width: 180,
      align: "right",
      title: "Lợi nhuận của đại lý",
      render: (_, record) => (
        <TagCustom color="#E9F9E9" style={{ color: "#0EAB10" }}>
          Lãi {formatCurrency(record?.order?.sellerProfitAmount)}
        </TagCustom>
      ),
    },
    channel: {
      width: 120,
      title: "Kênh bán",
      align: "center",
      render: (_, record) => {
        const order = record?.order;
        return (
          <TagCustom color={tagColorSalesChannel?.[order?.saleChannel?.type]}>{order?.saleChannel?.name}</TagCustom>
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter({ saleChannelIDs: value })} options={dataSalesChannel} />
      ),
    },
    paymentMethod: {
      width: 250,
      title: "Phương thức thanh toán",
      render: (_, record) => {
        return <Typography.Text>{renderPaymentMethod(record?.order?.paymentMethod)}</Typography.Text>;
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter({ paymentMethods: value })} options={PAYMENT_METHOD_OPTIONS} />
      ),
    },
    reconciliationStatus: {
      width: 170,
      title: "Trạng thái đối soát",
      render: (_, record) => {
        return (
          <BadgeStatusDot
            status={reconciliationStatusConverter(record?.status)?.status}
            text={reconciliationStatusConverter(record?.status)?.text}
          />
        );
      },
      fixed: "right",
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter({ reconciliationStatuses: value })}
          options={RECONCILIATION_STATUS_OPTIONS}
        />
      ),
    },
    reasonForCancelingReconciliation: {
      width: 250,
      title: "Lý do hủy đối soát",
      render: (_, record) => {
        return <EllipsisText title={record?.order?.cancelReason} />;
      },
    },
    reasonForRefusingReconciliation: {
      width: 250,
      title: "Lý do từ chối đối soát",
      render: (_, record) => {
        const rejectedReason = record?.rejectedReason;
        const title = rejectedReason?.reason
          ? rejectedReason?.reason
          : REJECTED_REASON_CAUSE_LABEL[rejectedReason?.causes];
        return <EllipsisText title={title} />;
      },
    },
    reconciliationCompletionDate: {
      width: 250,
      align: "center",
      title: "Ngày hoàn thành đối soát",
      render: (_, record) => {
        return (
          <SpaceCustom>
            {formatDateTime(record.completedDate, DATE_TIME_FORMAT4)}
            {convertCompletedDateNote(record)}
          </SpaceCustom>
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter({ completedDateStatuses: value })} options={COMPLETED_DATE_OPTIONS} />
      ),
    },
    orderStatus: {
      width: 220,
      fixed: GET_QUERY.reconciliationStatuses !== PROFIT_RECONCILIATION.RECONCILING && "right",
      title: "Trạng thái đơn hàng",
      render: (_, record) => {
        return <EllipsisText title={renderStatus(record?.order?.status)} />;
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter({ orderStatuses: value })} options={ORDER_STATUS_OPTIONS} />
      ),
    },
    staffInCharge: {
      width: 200,
      fixed: "right",
      title: "Nhân viên phụ trách",
      render: (_, record) => {
        const updatedBy = record?.updatedBy;
        const me = userCurrentData?.id === updatedBy?.id ? `(tôi)` : "";
        const lastFullEvents = [...record?.order?.fullEvents].pop();

        if (lastFullEvents?.createdByID === record?.order?.createdByID) {
          return "Hủy bởi đại lý";
        }

        return <EllipsisText title={updatedBy?.fullname ? `${updatedBy?.fullname} ${me}` : "--"} />;
      },
      filterDropdown: <FilterCreatedByIDs onFilter={(value, option) => onFilter({ staffInChargeIDs: value })} />,
    },
  };

  const {
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    channel,
    reconciliationStatus,
    paymentMethod,
    orderStatus,
    staffInCharge,
    reconciliationCompletionDate,
    reasonForRefusingReconciliation,
    reasonForCancelingReconciliation,
    burningDeadline,
  } = columnsProfit;

  const COLUMN_ALL = [
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    channel,
    reconciliationStatus,
  ];

  const COLUMN_WAITING_FOR_PAYMENT = [
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    paymentMethod,
    channel,
    orderStatus,
  ];

  const COLUMN_RECONCILING = [
    stt,
    burningDeadline,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    paymentMethod,
    channel,
    createdAt,
    orderStatus,
    staffInCharge,
  ];

  const COLUMN_COMPLETED = [
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    paymentMethod,
    channel,
    reconciliationCompletionDate,
    staffInCharge,
  ];

  const COLUMN_REJECTED = [
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    reasonForRefusingReconciliation,
    channel,
    staffInCharge,
  ];

  const COLUMN_CANCELED = [
    stt,
    createdAt,
    orderCode,
    agentInfo,
    createdBy,
    orderValue,
    agentProfit,
    reasonForCancelingReconciliation,
    channel,
    staffInCharge,
  ];

  return {
    COLUMN_ALL,
    COLUMN_WAITING_FOR_PAYMENT,
    COLUMN_RECONCILING,
    COLUMN_COMPLETED,
    COLUMN_REJECTED,
    COLUMN_CANCELED,
  };
}

export default TablesList;
