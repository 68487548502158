import React from "react";

import { useToolbar } from "./useToolbar";
import FilterDate from "components/CustomFilterMenu/FilterDate";

import "./index.scss";
import { t } from "i18next";

function Toolbar({ params, onChangeParams, statusOptions, actionActivityLogsOptions }) {
  const { handleChangeDate, renderDateTag, renderTags } = useToolbar({
    params,
    onChangeParams,
    statusOptions,
    actionActivityLogsOptions,
  });

  return (
    <>
      <div className="filter-logs-container">
        <div className="search-input">
          {<FilterDate onChangeDate={handleChangeDate} title={t("seller.activitylogtabs.table.dateUpdate")} />}
        </div>
      </div>
      <div className="tags-list">
        {renderDateTag()}
        {renderTags}
      </div>
    </>
  );
}

export default Toolbar;
