import { Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { t } from "i18next";
import useSelectSerial from "./useSelectSerial";

import { warrantyStatusLabel } from "features/Warranty/constant";
import styles from "./index.module.scss";

function SelectSerial({ handleGetSerial, handleChangeInfo, onSerialSelected }) {
  const { data, onLoadMoreData, hasMoreData, loading, onSearch, handleChange, handleKeyDown } = useSelectSerial({
    handleGetSerial,
    onSerialSelected,
  });

  const ellipsis = { expandable: true, tooltip: true };

  const options = data?.map((item) => ({
    value: item.id,
    serialImei: item?.serialImei,
    label: (
      <Row gutter={16}>
        <Col span={5}>
          <Typography.Link ellipsis={ellipsis}>{item?.code}</Typography.Link>
        </Col>
        <Col span={4}>
          <Typography.Text ellipsis={ellipsis}>{item?.serialImei}</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text ellipsis={ellipsis}>{item?.product?.code}</Typography.Text>
        </Col>
        <Col span={7}>
          <Typography.Text ellipsis={ellipsis}>{item?.product?.name}</Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text style={{ float: "right" }}>{warrantyStatusLabel?.[item?.status]}</Typography.Text>
        </Col>
      </Row>
    ),
    serial: item,
  }));

  return (
    <SelectLoadMore
      inputOptions={{
        placeholder: t("serialIE.placeholder.searchBySerial"),
        options: options,
        value: null,
        dropdownMatchSelectWidth: 1500,
        allowClear: false,
        dropdownRender: (originNode) => (
          <>
            <Row gutter={16} className={styles["custom-select"]}>
              <Col span={5}>
                <Typography className={styles["header-select"]}>{t("warranty.warrantyList.warrantyCode")}</Typography>
              </Col>
              <Col span={4}>
                <Typography className={styles["header-select"]}>
                  {t("warranty.warrantyList.SERIAL/IMEITitle")}
                </Typography>
              </Col>
              <Col span={5}>
                <Typography className={styles["header-select"]}>{t("warranty.warrantyList.productTitle")}</Typography>
              </Col>
              <Col span={7}>
                <Typography className={styles["header-select"]}>
                  {t("warranty.warrantyList.nameProductTitle")}
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className={styles["header-select"]}>{t("warranty.warrantyList.statusTitle")}</Typography>
              </Col>
            </Row>
            <Spin spinning={loading}>{originNode}</Spin>
          </>
        ),
        dropdownStyle: { zIndex: "1000" },
        onSelect: (value, options) => handleChangeInfo(value, options),
        defaultActiveFirstOption: false,
        onKeyDown: (value) => handleKeyDown(value, options),
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectSerial;
