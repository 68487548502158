import { Form } from "antd";
import LoadingComponent from "components/LoadingComponent";
import { generateGreetings } from "utils/helperFuncs";
import CustomRangePicker from "../CustomRangePicker";
import ChartSelectUser from "./components/ChartSelectUser";
import ReportChart from "./components/ReportChart";
import styles from "./index.module.scss";
import usePotentialCustomerChartUI from "./usePotentialCustomerChartUI";

const PotentialCustomerChartUI = () => {
  const {
    GREETING,
    me,
    chartData,
    form,
    handleChangeReportType,
    handleChangeTimeRange,
    loadingChart,
    fetchChartToken,
    renderSubtitle,
    initialValues,
  } = usePotentialCustomerChartUI();

  return (
    <LoadingComponent loading={loadingChart}>
      <div className={styles["container"]}>
        <div className={styles["header"]}>
          <div className={styles["welcome"]}>
            <div className={styles["title"]}>{`${GREETING[`${generateGreetings()}`]}, ${me?.fullname || "HAC"}`}</div>
            <div className={styles["subTitle"]}>{renderSubtitle()}</div>
          </div>
          <Form form={form} initialValues={initialValues}>
            <div className={styles["selection-group"]}>
              <ChartSelectUser handleChangeReportType={handleChangeReportType} />
            </div>
          </Form>
        </div>
        <div className={styles["charts"]}>
          <ReportChart chartData={chartData} loadingChart={loadingChart} fetchChartToken={fetchChartToken} />
        </div>
      </div>
    </LoadingComponent>
  );
};

export default PotentialCustomerChartUI;
