import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSellerDetail } from "../../../hooks/seller";

const useDetail = () => {
  const { id } = useParams();

  const { data: sellerDetail, loading, refetch } = useGetSellerDetail({ id });

  return {
    sellerDetail,
    loading,
    refetch,
  };
};

export default useDetail;
