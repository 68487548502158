import {
  EXPORT_VAT,
  FULL_STOCK_REQUIRED,
  PAYMENT_METHOD,
  PRIORITY_LEVEL,
  SHIPPING_TYPE,
  STATUS_ORDER,
  STOCK_STATUS,
} from "config/constants";
import { useTranslation } from "react-i18next";
import { CUSTOMER_PAYMENT_METHOD, ORDER_STATUS, ORDER_TYPE, PAYMENT_METHOD_AGENT, PAYMENT_STATUS } from "./conts";

export const useOrder = () => {
  const { t } = useTranslation();

  const convertOrderStatus = (status) => {
    switch (status) {
      case ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK:
        return t("order.orderStatus.waitingForApprovalStock");
      case ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK:
        return t("order.orderStatus.waitingForEnoughStock");
      case ORDER_STATUS.WAITING_FOR_PAYMENT:
        return t("order.orderStatus.waitingForPayment");
      case ORDER_STATUS.WAITING_FOR_PACKAGING:
        return t("order.orderStatus.waitingForPackaging");
      case ORDER_STATUS.WAITING_FOR_EXPORTING:
        return t("order.orderStatus.waitingForExporting");
      case ORDER_STATUS.WAITING_FOR_SHIPPING:
        return t("order.orderStatus.waitingForDelivery");
      case ORDER_STATUS.DELIVERING:
        return t("order.orderStatus.delivering");
      case ORDER_STATUS.COMPLETED:
        return t("order.orderStatus.completed");
      case ORDER_STATUS.CANCELED:
        return t("order.orderStatus.cancel");
      default:
        return "";
    }
  };

  const orderPriorityOptions = [
    { value: PRIORITY_LEVEL.LOW, label: t("order.orderList.low") },
    { value: PRIORITY_LEVEL.MEDIUM, label: t("order.orderList.medium") },
    { value: PRIORITY_LEVEL.HIGH, label: t("order.orderList.high") },
  ];

  const shippingOptions = [
    { value: SHIPPING_TYPE.BUS, label: t("order.orderList.bus") },
    { value: SHIPPING_TYPE.DELIVER_3PARTY, label: t("order.orderList.deliver3Party") },
    { value: SHIPPING_TYPE.PICK_UP_COUNTER, label: t("order.orderList.pickUpCounter") },
    { value: SHIPPING_TYPE.URBAN_COD, label: t("order.orderList.urbanCOD") },
  ];

  const paymentMethodOptions = [
    { value: PAYMENT_METHOD.Cash, label: t("order.orderList.cash") },
    // { value: PAYMENT_METHOD.CashAndDebt, label: t("order.orderList.cashAndDebit") },
    // { value: PAYMENT_METHOD.COD, label: t("order.orderList.COD") },
    { value: PAYMENT_METHOD.Debt, label: t("order.orderList.debt") },
    { value: PAYMENT_METHOD.COD, label: t("order.orderList.cod") },
  ];

  const statusOptions = [
    { value: STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER, label: "Chờ duyệt đơn" },
    { value: STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK, label: t("order.orderStatus.waitingForApprovalStock") },
    {
      value: STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK,
      label: t("order.orderStatus.waitingForEnoughStock"),
    },
    { value: STATUS_ORDER.WAITING_FOR_PAYMENT, label: t("order.orderStatus.waitingForPayment") },
    {
      value: STATUS_ORDER.WAITING_FOR_PACKAGING,
      label: t("order.orderStatus.waitingForPackaging"),
    },
    {
      value: STATUS_ORDER.WAITING_FOR_EXPORTING,
      label: t("order.orderStatus.waitingForExporting"),
    },
    {
      value: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
      label: t("order.orderStatus.waitingForAssembly"),
    },
    { value: STATUS_ORDER.WAITING_FOR_SHIPPING, label: t("order.orderStatus.waitingForDelivery") },
    { value: STATUS_ORDER.DELIVERING, label: t("order.orderStatus.delivering") },
    { value: STATUS_ORDER.COMPLETED, label: t("order.orderStatus.completed") },
    { value: STATUS_ORDER.CANCELED, label: t("order.orderStatus.cancel") },
  ];

  const fullStockRequiredOption = [
    { value: FULL_STOCK_REQUIRED.YES, label: t("common.yes") },
    { value: FULL_STOCK_REQUIRED.NO, label: t("common.no") },
  ];

  const vatOption = [
    { value: EXPORT_VAT.YES, label: t("common.yes") },
    { value: EXPORT_VAT.NO, label: t("common.no") },
  ];

  const stockStatusOption = [
    { value: STOCK_STATUS.FULL, label: t("common.full") },
    { value: STOCK_STATUS.LACK, label: t("common.lack") },
    { value: STOCK_STATUS.OUT_OF_STOCK, label: t("common.outOfStock") },
  ];

  const orderForOption = [
    { value: ORDER_TYPE.SELLER, label: t("order.orderType.seller") },
    { value: ORDER_TYPE.CUSTOMER, label: t("order.orderType.customer") },
  ];

  const preOrderOption = [
    { value: true, label: t("order.approvalOrder.preOrder") },
    { value: false, label: t("order.approvalOrder.regularOrder") },
  ];

  const paymentStatusOptions = [
    { value: PAYMENT_STATUS.PENDING, label: t("bank_transfer.order.pending") },
    { value: PAYMENT_STATUS.WAITING_APPROVAL, label: t("bank_transfer.order.waiting_approval") },
    { value: PAYMENT_STATUS.PAID, label: t("bank_transfer.order.paid") },
  ];

  const isSeller = (orderType) => (orderType === ORDER_TYPE.SELLER ? true : false);

  const renderCustomerPaymentMethod = (method) => {
    switch (method) {
      case CUSTOMER_PAYMENT_METHOD.CASH:
        return t("order.customPaymentMethods.cash");
      case CUSTOMER_PAYMENT_METHOD.COD:
        return t("order.customPaymentMethods.cod");
      default:
        return "";
    }
  };

  const renderPaymentMethod = (method) => {
    switch (method) {
      case PAYMENT_METHOD_AGENT.CASH:
        return t("order.paymentMethods.cash");
      case PAYMENT_METHOD_AGENT.DEBT:
        return t("order.paymentMethods.debt");
      default:
        return "";
    }
  };

  return {
    convertOrderStatus,
    orderPriorityOptions,
    shippingOptions,
    paymentMethodOptions,
    statusOptions,
    fullStockRequiredOption,
    vatOption,
    stockStatusOption,
    orderForOption,
    isSeller,
    renderCustomerPaymentMethod,
    renderPaymentMethod,
    preOrderOption,
    paymentStatusOptions,
  };
};
