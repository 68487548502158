import { Col, Form, Row, Spin, Typography } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { sumBy } from "lodash";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { SubTitleCard, TitleCard } from "../../components/TitleCard";
import { IMPORT_TYPE } from "../../constant";
import styles from "../../index.module.scss";
import AddInfo from "./AddInfo";
import AffixButton from "./AffixButton";
import FooterTable from "./FooterTable";
import ProviderInfo from "./ProviderInfo";
import TableProduct from "./Table";
import "./index.scss";
import useCreateSerialPurchaseOrder from "./useCreateSerialPurchaserOrder";

function CreateSerialPurchaseOrder() {
  const {
    form,
    exportedWarehouseOptions,
    initialValues,
    providerInfo,
    importType,
    dataTableProduct,
    dataTableOrder,
    loadingCreateBtn,
    loadingCreate,
    tags,
    visible,
    warrantyRequestItems,
    pendingWarrantyNoteLoading,
    handleRemoveSerial,
    handleRemoveProduct,
    handleValuesChange,
    handleFinish,
    handleGetTags,
    onCancelCreate,
    handleCreate,
    showOffModalConfirmCreate,
    handleGetTableOrder,
    dataState,
    dataReferences,
  } = useCreateSerialPurchaseOrder();

  const sumSerialByOrder = useMemo(() => sumBy(dataTableProduct, "quantity") || 0, [dataTableProduct]);
  const sumSerialByScanned = useMemo(() => sumBy(dataTableProduct, "scanned") || 0, [dataTableProduct]);
  const sumSerialByLack = useMemo(() => sumBy(dataTableProduct, "lack") || 0, [dataTableProduct]);

  const sumSerialByOrderTypeReimport = useMemo(() => sumBy(dataTableOrder, "quantity") || 0, [dataTableOrder]);
  const sumReturnByOrderTypeReimport = useMemo(
    () => sumBy(dataTableOrder, "serialItems.length") || 0,
    [dataTableOrder]
  );
  const sumLackByOrderTypeReimport = useMemo(
    () => sumSerialByOrderTypeReimport - sumReturnByOrderTypeReimport,
    [sumReturnByOrderTypeReimport, sumSerialByOrderTypeReimport]
  );

  const { platformName } = useGetInfoPlatform();

  const optionRadio = [
    { label: t("serialIE.firstTimeImport"), value: IMPORT_TYPE.FIRST_TIME_IMPORT },
    { label: t("serialIE.return", { platformName }), value: IMPORT_TYPE.RETURN },
    { label: t("serialIE.reImportWarranty"), value: IMPORT_TYPE.RE_IMPORT_WARRANTY },
  ];

  return (
    <Spin spinning={loadingCreate}>
      <div className="serial-import-create-purchase-order-page">
        <Row gutter={[16, 8]} className={styles["create-serial"]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("serialIE.createTitle")}
              routes={[
                { path: "#", name: t("purchase.titleMenu") },
                { path: "/purchase-order", name: t("purchase.purchaseOrder") },
                { path: "#", name: t("serialIE.createTitle") },
              ]}
            />
          </Col>
          <Col span={24}>
            <Form
              layout="horizontal"
              initialValues={initialValues}
              form={form}
              onValuesChange={handleValuesChange}
              onFinish={handleFinish}
            >
              <Row gutter={[16, 8]}>
                <Col span={24} className="col-export-serial-header">
                  <Card title={<TitleCard title={t("serialIE.ticketImportSerial")} />} style={{ flexGrow: 1 }}>
                    <Row>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "start", gap: "30px" }}
                        className="form-control-radio-group"
                      >
                        <SubTitleCard subTitle={t("purchase.reference") + " :"} />
                        <Typography.Link strong>
                          {(dataReferences || [])?.map((item, index) => (
                            <React.Fragment key={index}>
                              <div>
                                {item.serialImportNotesCode ? (
                                  <Link to={`/import-serial/detail/${item?.serialImportNotesId}`} target="_blank">
                                    <Typography.Link strong>{item?.serialImportNotesCode || "??"}</Typography.Link>
                                  </Link>
                                ) : (
                                  <Link to={`/import-request/detail/${item?.importRequestId}`} target="_blank">
                                    <Typography.Link strong>{item?.importRequestCode || "??"}</Typography.Link>
                                  </Link>
                                )}
                                {index >= 0 && index < dataReferences?.length - 1 && `,`}
                              </div>
                            </React.Fragment>
                          ))}
                        </Typography.Link>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                      <Col span={20} className="form-control-radio-group">
                        <FormInput
                          type={INPUT_TYPE.RADIO_GROUP}
                          formItemOptions={{
                            name: "importType",
                            label: <SubTitleCard subTitle={t("serialIE.TypeExport")} />,
                          }}
                          inputOptions={{
                            disabled: true,
                            options: optionRadio,
                          }}
                        />
                      </Col>
                      <Col span={4} style={{ textAlign: "end" }}>
                        <Typography.Text strong> Kho Nhập : {dataState?.warehouse?.name}</Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={14} className="card-container-info">
                        <Card
                          className="card-children"
                          title={<TitleCard title={t("serialIE.providerInfo.title")} />}
                          style={{ flexGrow: 1 }}
                        >
                          <ProviderInfo providerInfo={providerInfo} />
                        </Card>
                      </Col>
                      <Col span={10} className="card-container-info">
                        <Card
                          title={<TitleCard title={t("serialIE.addInfo.title")} />}
                          className="card-children"
                          style={{ flexGrow: 1 }}
                        >
                          <AddInfo />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col style={{ marginTop: "16px" }} span={24}>
                  <Card
                    title={
                      <div>
                        <TitleCard title={t("common.productList")} />
                      </div>
                    }
                  >
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.List name="items">
                          {(items, { remove }) => {
                            return (
                              <TableProduct
                                importType={importType}
                                handleRemoveSerial={handleRemoveSerial}
                                handleRemoveProduct={handleRemoveProduct}
                                exportedWarehouseOptions={exportedWarehouseOptions}
                                handleGetTags={handleGetTags}
                                tags={tags}
                                remove={remove}
                                pendingWarrantyNoteLoading={pendingWarrantyNoteLoading}
                                form={form}
                                handleGetTableOrder={handleGetTableOrder}
                                dataTableProduct={dataTableProduct}
                                sumSerialByLack={sumSerialByLack}
                                sumSerialByOrder={sumSerialByOrder}
                                sumSerialByScanned={sumSerialByScanned}
                                dataTableOrder={dataTableOrder}
                                warrantyRequestItems={warrantyRequestItems}
                              />
                            );
                          }}
                        </Form.List>
                      </Col>
                      <Col span={24} xl={18}></Col>
                      <Col span={24} xl={6}>
                        <FooterTable
                          importType={importType}
                          dataTableProduct={dataTableProduct}
                          sumSerialByLack={sumSerialByLack}
                          sumSerialByOrder={sumSerialByOrder}
                          sumSerialByScanned={sumSerialByScanned}
                          dataTableOrder={dataTableOrder}
                          sumLackByOrderTypeReimport={sumLackByOrderTypeReimport}
                          sumReturnByOrderTypeReimport={sumReturnByOrderTypeReimport}
                          sumSerialByOrderTypeReimport={sumSerialByOrderTypeReimport}
                          warrantyRequestItems={warrantyRequestItems}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>
                  <AffixButton
                    handleCreate={handleCreate}
                    importType={importType}
                    loadingCreateBtn={loadingCreateBtn}
                    onCancelCreate={onCancelCreate}
                    showOffModalConfirmCreate={showOffModalConfirmCreate}
                    visible={visible}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default CreateSerialPurchaseOrder;
