import { Col, Row, Typography } from "antd";
import { t } from "i18next";
import { SubTitleCard } from "../TitleCard";

function InfoRetype({ serialInfo }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <SubTitleCard subTitle={t("serialIE.providerInfo.infoRetype")} />
      </Col>
      <Col span={12}>
        <Typography.Text className="sub-title">{t("serialIE.providerInfo.providerName")}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>{serialInfo?.seller?.fullName}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text className="sub-title">{t("serialIE.providerInfo.phone")}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>{serialInfo?.seller?.telephone}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text className="sub-title">{t("serialIE.providerInfo.email")}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>{serialInfo?.seller?.email || "- -"}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text className="sub-title">{t("serialIE.providerInfo.address")}</Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>{serialInfo?.seller?.address}</Typography.Text>
      </Col>
    </Row>
  );
}

export default InfoRetype;
