import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Space, Tooltip, Typography } from "antd";
import { t } from "i18next";
import { Trans } from "react-i18next";

import CustomInputGroup from "components/CustomInputGroup";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import PopconfirmDelete from "components/PopconfirmDelete";
import { INPUT_TYPE } from "config/constants";
import { DISCOUNT_TYPE, OPTIONS_TYPE_DISCOUNT, OPTIONS_TYPE_LIMIT, TYPE_LIMIT } from "features/Campaign/constants";
import Card from "features/Cart/components/Card";
import { formatCurrency, validator } from "utils/helperFuncs";

import ModalSelectProduct from "./ModalSelectProduct";
import useFormList from "./useFormList";
import useOneOnProductForm from "./useOneOnProductForm";

import "./index.scss";

function OneOnProductForm({ form, dataTiersServer, isUpdate }) {
  const { dataSource, handleGetDataSource, confirmDelete, convertGetProducts, totalBudgetAmount, idDeleted } =
    useOneOnProductForm({
      form,
      dataTiersServer,
      isUpdate,
    });

  const getValueForm = (value, index) => {
    return ["promotionCampaignTierGroupsProductPromo", 0, "promotionCampaignTiers", index, value];
  };

  const shouldUpdateItem = (prevValues, currentValues, index) => {
    const getDiscountType = (values, index) => {
      return values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index]?.discountType;
    };
    const getDiscountAmount = (values, index) => {
      return values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index]?.discountAmount;
    };
    const getMaxQuantityPerSeller = (values, index) => {
      return values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index]
        ?.maxQuantityPerSeller;
    };
    const getLimitType = (values, index) => {
      return values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index]?.limitType;
    };
    const getBudgetAmount = (values, index) => {
      return values?.promotionCampaignTierGroupsProductPromo?.[0]?.promotionCampaignTiers?.[index]?.budgetAmount;
    };
    return (
      getDiscountType(prevValues, index) !== getDiscountType(currentValues, index) ||
      getDiscountAmount(prevValues, index) !== getDiscountAmount(currentValues, index) ||
      getMaxQuantityPerSeller(prevValues, index) !== getMaxQuantityPerSeller(currentValues, index) ||
      getLimitType(prevValues, index) !== getLimitType(currentValues, index) ||
      getBudgetAmount(prevValues, index) !== getBudgetAmount(currentValues, index)
    );
  };

  const priceChildrenFinal = (price, index) => {
    return (
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => {
          return shouldUpdateItem(prevValues, currentValues, index);
        }}
        style={{
          marginBottom: 0,
        }}
      >
        {({ getFieldValue }) => {
          const discountType = getFieldValue(getValueForm("discountType", index));
          const isDiscountPercentage = discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE;
          const discountAmount = getFieldValue(getValueForm("discountAmount", index));

          return discountAmount ? (
            <span>
              <div style={{ fontSize: "12px", textDecorationLine: "line-through" }}>{formatCurrency(price)}</div>
              <div>
                {isDiscountPercentage
                  ? formatCurrency(price * (1 - discountAmount / 100))
                  : formatCurrency(price - discountAmount)}
              </div>
            </span>
          ) : (
            <div>{formatCurrency(price)}</div>
          );
        }}
      </Form.Item>
    );
  };

  const renderChildrenInStock = (prices) => {
    return [
      {
        title: t("campaign.productPromo.priceLevel1"),
        width: 100,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          return priceChildrenFinal(record?.productPrices?.level1Price, index);
        },
      },
      {
        title: t("campaign.productPromo.priceLevel2"),
        width: 100,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          return priceChildrenFinal(record?.productPrices?.level2Price, index);
        },
      },
      {
        title: t("campaign.productPromo.priceLevel3"),
        width: 100,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          return priceChildrenFinal(record?.productPrices?.level3Price, index);
        },
      },
      {
        title: t("campaign.productPromo.priceLevel4"),
        width: 100,
        align: "center",
        editable: "false",
        render: (_, record, index) => {
          return priceChildrenFinal(record?.productPrices?.level4Price, index);
        },
      },
    ];
  };

  const columnsDataSelected = [
    {
      title: <span>STT</span>,
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("campaign.productPromo.productTitle"),
      width: 150,
      render: (_, record, index) => {
        return (
          <Typography.Link strong href={`/product/detail/${record?.id}`} target="_blank">
            {record?.code}
          </Typography.Link>
        );
      },
    },
    {
      title: t("campaign.productPromo.nameProductTitle"),
      width: 200,
      render: (_, record) => {
        return <CustomTextHiddenLine line={2} text={record?.name} />;
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.discount")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.discountTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 250,
      render: (_, record, index) => {
        return (
          <Form.Item
            shouldUpdate={(prevValues, currentValues) => {
              return shouldUpdateItem(prevValues, currentValues, index);
            }}
            style={{
              marginBottom: 0,
            }}
          >
            {({ getFieldValue }) => {
              const discountType = getFieldValue(getValueForm("discountType", index));
              const isDiscountPercentage = discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE;
              const discountAmount = getFieldValue(getValueForm("discountAmount", index));

              return (
                <CustomInputGroup
                  typeFirst={INPUT_TYPE.SELECT}
                  typeSecond={isDiscountPercentage ? INPUT_TYPE.NUMBER : INPUT_TYPE.CURRENCY_INPUT}
                  formItemOptionsFirst={{
                    name: [index, "discountType"],
                  }}
                  formItemOptionsSecond={{
                    name: [index, "discountAmount"],
                    rules: [
                      {
                        required: true,
                        message: t("campaign.error.enterValue"),
                      },
                      {
                        type: "number",
                        min: 1,
                        message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                      },
                      // Check: value > đơn giá cấp 1
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (value >= record?.productPrices?.level1Price) {
                            return Promise.reject(new Error(t("campaign.error.wrongPrice")));
                          }
                          if (isDiscountPercentage && discountAmount > 100) {
                            return Promise.reject(new Error("Giảm % phải < 100"));
                          }
                          return Promise.resolve();
                        },
                      }),
                      validator({
                        type: "number",
                      }),
                    ],
                  }}
                  inputOptionsFirst={{
                    options: OPTIONS_TYPE_DISCOUNT,
                    defaultValue: DISCOUNT_TYPE.DISCOUNT_MONEY,
                  }}
                  inputOptionsSecond={{
                    placeholder: t("campaign.combo.placeholder.type"),
                    max: isDiscountPercentage && 99,
                    maxLength: isDiscountPercentage ? 2 : 15,
                  }}
                  maxWidthSelect={"100%"}
                />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673", textJustify: "inter-word", textAlign: "left" }}>
            {t("campaign.productPromo.buyLimitProduct")}
          </Typography.Text>
          <Tooltip title={t("campaign.productPromo.buyLimitProductTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 230,
      render: (_, record, index) => {
        return (
          <Form.Item
            shouldUpdate={(prevValues, currentValues) => {
              return shouldUpdateItem(prevValues, currentValues, index);
            }}
            style={{
              marginBottom: 0,
            }}
          >
            {({ getFieldValue }) => {
              const limitType = getFieldValue(getValueForm("limitType", index));
              const isNO_LIMIT = limitType === TYPE_LIMIT.NO_LIMIT;

              return (
                <CustomInputGroup
                  typeFirst={INPUT_TYPE.SELECT}
                  typeSecond={INPUT_TYPE.NUMBER}
                  formItemOptionsFirst={{
                    name: [index, "limitType"],
                  }}
                  formItemOptionsSecond={{
                    name: [index, "maxQuantityPerSeller"],
                    rules: [
                      {
                        required: !isNO_LIMIT,
                        message: t("campaign.error.enterValue"),
                      },
                      {
                        type: "number",
                        min: 1,
                        message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                      },
                      validator({ type: "number" }),
                    ],
                  }}
                  inputOptionsFirst={{
                    options: OPTIONS_TYPE_LIMIT,
                    defaultValue: TYPE_LIMIT.LIMIT,
                  }}
                  inputOptionsSecond={{
                    placeholder: t("campaign.combo.placeholder.type"),
                    disabled: isNO_LIMIT,
                  }}
                  maxWidthSelect={"100%"}
                />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.budget")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.budgetTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      width: 150,
      render: (_, record, index) => {
        return (
          <Form.Item
            shouldUpdate={(prevValues, currentValues) => {
              return shouldUpdateItem(prevValues, currentValues, index);
            }}
            style={{
              marginBottom: 0,
            }}
          >
            {({ getFieldValue }) => {
              return (
                <FormInput
                  {...{
                    type: INPUT_TYPE.CURRENCY_INPUT,
                    formItemOptions: {
                      name: [index, "budgetAmount"],
                      rules: [
                        {
                          required: true,
                          message: t("campaign.error.enterValue"),
                        },
                        {
                          type: "number",
                          min: 1,
                          message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                        },
                        validator({ type: "number" }),
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }

                            const discountType = getFieldValue(getValueForm("discountType", index));
                            const discountAmount = getFieldValue(getValueForm("discountAmount", index));
                            const level4Price = record?.productPrices?.level4Price;

                            if (discountType === DISCOUNT_TYPE.DISCOUNT_MONEY && value < discountAmount) {
                              return Promise.reject(new Error(t("campaign.error.wrongValueBudget")));
                            }
                            if (
                              discountType === DISCOUNT_TYPE.DISCOUNT_PERCENTAGE &&
                              value < (discountAmount * level4Price) / 100
                            ) {
                              return Promise.reject(new Error(t("campaign.error.wrongValueBudget")));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ],
                      style: {
                        margin: 0,
                      },
                    },
                    inputOptions: {
                      placeholder: t("campaign.combo.placeholder.type"),
                    },
                  }}
                />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Typography.Text style={{ color: "#5B6673" }}>{t("campaign.productPromo.tempoPromoPrice")}</Typography.Text>
          <Tooltip title={t("campaign.productPromo.tempoPromoPriceTooltip")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      children: renderChildrenInStock(),
    },
    {
      width: 50,
      align: "center",
      fixed: "right",
      render: (_, record, index) => {
        return (
          <div className="icon-trash">
            <PopconfirmDelete
              title={"Bạn muốn xóa sản phẩm này ??? 👀 ???"}
              onConfirm={() => confirmDelete(record?.id)}
            />
          </div>
        );
      },
    },
  ];

  const customTableFormList = useFormList({ dataSource, columnsDataSelected });

  return (
    <Card
      title={
        <Typography.Title level={5} style={{ textTransform: "uppercase" }}>
          {t("campaign.productPromo.productPromo")}
        </Typography.Title>
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <Space
            direction={dataSource?.length ? "horizontal" : "vertical"}
            style={
              dataSource?.length && {
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }
            }
          >
            {dataSource?.length ? (
              <div>
                <Space direction="vertical">
                  <Typography.Text>
                    <Trans
                      i18nKey="campaign.productPromo.sumProductPromo"
                      values={{ sumProduct: dataSource?.length }}
                      components={{ style: <Typography.Link /> }}
                    />
                  </Typography.Text>
                  <Typography.Text>
                    <Trans
                      i18nKey="campaign.productPromo.sumBudget"
                      values={{ sumBudget: formatCurrency(totalBudgetAmount || 0) }}
                      components={{ style: <Typography.Link /> }}
                    />
                  </Typography.Text>
                </Space>
              </div>
            ) : (
              <Typography.Text italic style={{ color: "#262F3B" }}>
                {t("campaign.productPromo.labelProductPromo")}
              </Typography.Text>
            )}
            <ModalSelectProduct
              handleGetDataSource={handleGetDataSource}
              form={form}
              dataProductsServer={convertGetProducts(dataTiersServer?.[0]?.promotionCampaignTiers)}
              isUpdate={isUpdate}
              dataSourceSelected={dataSource}
              idDeleted={idDeleted}
            />
          </Space>
        </div>
        {dataSource?.length ? customTableFormList : null}
      </Space>
    </Card>
  );
}

export default OneOnProductForm;
