import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { Icon } from "assets/icons";
import { t } from "i18next";

function InputSearch({ onSearch, params }) {
  return (
    <Input
      onChange={(e) => onSearch(e)}
      prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
      placeholder={t("warranty.warrantyList.placeholderInputSearch")}
      allowClear={true}
      defaultValue={params?.filters?.query}
    />
  );
}

export default InputSearch;
