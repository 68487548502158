import { Dropdown, Menu, Popconfirm, Space, Typography, message } from "antd";
import { SvgIcon } from "assets/icons";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import CustomUom from "components/CustomUom";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import CustomTitle from "features/Serial/components/CustomTitle";
import { t } from "i18next";
import { useState } from "react";
import SerialsInputTag from "../SerialsInputTag";
import styles from "./index.module.scss";

function TableReturnSupplier({
  form,
  renderLack,
  tags,
  handleGetTags,
  handleRemoveItemReturnSupplier,
  handleDeleteAllSerial,
}) {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const [visible, setVisible] = useState(null);
  const copyToClipboard = (record) => {
    if (record?.serialItems?.length > 0) {
      const textToCopy = record?.serialItems?.map((item) => item?.code).join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  const columnsBySupplier = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
            <ProductLink product={record} />
          </Typography.Link>
          <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
            <CustomTextHiddenLine text={record?.product?.name || record?.productName} line={3} />
          </Typography.Text>
          <CustomUom uom={record?.productUom} />
        </div>
      ),
      isHidden: false,
      width: 300,
      fixed: "left",
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.BYORDER")} />,
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "quantity"],
                rules: [
                  {
                    required: true,
                    message: t("warehouse.create.relueschosseQuantity"),
                  },
                  {
                    pattern: REGEX.NUMBER,
                  },
                ],
              },
              inputOptions: {
                style: { width: "70px" },
                maxLength: 5,
                min: 0,
                className: "form-text-center",
                // onChange: (e) => handleQuantity(index, e),
                onKeyPress: handleKeyPress,
                placeholder: "0",
              },
            }}
          />
        );
      },
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.SCANNED")} />,
      width: 200,
      render: (_, record) => {
        const scanned =
          record?.quantitySerialScanned > 0 ? record?.quantitySerialScanned : record?.serialItems?.length || 0;
        return (
          <Space direction="vertical">
            <Typography.Text>{scanned}</Typography.Text>
            {
              <Typography.Text className={styles["sub-title"]}>
                {renderLack({
                  quantity: record?.quantity,
                  scanned: scanned,
                }) < 0 ? (
                  <span style={{ color: "#D2434D" }}>
                    {t("serialIE.productInfo.residual")} :{" "}
                    {Math.abs(
                      renderLack({
                        quantity: record?.quantity,
                        scanned: scanned,
                      })
                    )}
                  </span>
                ) : renderLack({
                    quantity: record?.quantity,
                    scanned: scanned,
                  }) > 0 ? (
                  <span style={{ color: "#EF9351" }}>
                    {t("serialIE.productInfo.lack")} :{" "}
                    {renderLack({
                      quantity: record?.quantity,
                      scanned: scanned,
                    })}
                  </span>
                ) : (
                  <span style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</span>
                )}
              </Typography.Text>
            }
          </Space>
        );
      },
    },
    {
      align: "center",
      title: (
        <CustomTitle
          title={t("serialIE.productInfo.detailSerialB")}
          subTitle={
            <Typography.Text className={styles["sub-title"]} style={{ color: "#EF9351", fontSize: "12px" }} italic>
              {t("serialIE.productInfo.note")}
            </Typography.Text>
          }
        />
      ),
      render: (_, record, index) => {
        return (
          <div>
            <SerialsInputTag
              tagsIndex={record?.serialItems}
              tags={tags}
              handleGetTags={handleGetTags}
              index={index}
              record={record}
              form={form}
              setVisible={setVisible}
              visible={visible}
              handleDeleteAllSerial={handleDeleteAllSerial}
            />
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("campaign.actions")} />,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => setVisible(index)}
                  >
                    <SvgIcon.IconViewList />
                    <Typography.Text>{t("serialIE.viewAll")}</Typography.Text>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => copyToClipboard(record)}
                  >
                    <SvgIcon.IconCoppy />
                    <Typography.Text>{t("serialIE.copy")}</Typography.Text>
                  </div>
                </Menu.Item>
                <Popconfirm
                  title={
                    <Typography.Text>
                      {t("cart.confirmDeleteItem")}
                      <br />
                      <Typography.Link strong>{record?.productName}</Typography.Link> ?
                    </Typography.Text>
                  }
                  onConfirm={() => {
                    handleRemoveItemReturnSupplier(record?.productID);
                  }}
                >
                  <Menu.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                      }}
                    >
                      <SvgIcon.IconDelete />
                      <Typography.Text>{t("common.Delete")}</Typography.Text>
                    </div>
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
            className="button-action-recommend button-offers-recommend"
          >
            <SvgIcon.IconMore style={{ height: "28px" }} />
          </Dropdown>
        );
      },
      width: 70,
    },
  ];
  return { columnsBySupplier };
}

export default TableReturnSupplier;
