import React from "react";
import { Affix, Col, Row, Spin } from "antd";
import useDetail from "./useSerialDetail";
import FullEvents from "./FullEvents";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import SerialInfo from "./SerialInfo";
import CommonCard from "components/CommonCard";
import ButtonGroup from "./ButtonGroup";
import Tabs from "./TabsMenu";

function Detail() {
  const { loading, refetch, data, warrantyNote } = useDetail();
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("warranty.warrantyRequestList.title")}
            routes={[
              { path: "", name: t("common.warranty") },
              { path: "/warranty-request", name: t("warranty.warrantyRequestList.title") },
              { path: "/", name: t("serial.serialDetail") },
            ]}
          />
        </Col>
        <Col span={24}>
          <FullEvents data={data} />
        </Col>
        <Col span={24}>
          <SerialInfo data={data} />
        </Col>
        <Col span={24}>
          <Tabs data={data} warrantyNote={warrantyNote} />
        </Col>
        <Col span={24}>
          <Affix offsetBottom={10}>
            <CommonCard>
              <ButtonGroup record={data} refetch={refetch} />
            </CommonCard>
          </Affix>
        </Col>
      </Row>
    </Spin>
  );
}

export default Detail;
