import React from "react";
import { Button } from "antd";
import { useMarkAllRead } from "hooks/notification";
import { t } from "i18next";
import { notify } from "utils/helperFuncs";
import styles from "./index.module.scss";

const MarkAllReadButton = () => {
  const { handleMarkAllRead, loading: loadingAllRead } = useMarkAllRead();

  const handleClickAllRead = async () => {
    await handleMarkAllRead()
      .then(() => {
        notify.success({
          message: t("notification.markAllReadSuccess"),
          placement: "top",
        });
      })
      .catch((error) => {
        notify.error({
          message: t("notification.markAllReadError"),
          placement: "top",
          description: error.message,
        });
      });
  };

  return (
    <Button loading={loadingAllRead} className={`${styles["mark-all-btn"]}`} type="text" onClick={handleClickAllRead}>
      {t("notification.markAllRead")}
    </Button>
  );
};

export default MarkAllReadButton;
