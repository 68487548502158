import { Form, Popconfirm, Tag, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import FormInput from "components/FormInput";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import CustomTitle from "features/Serial/components/CustomTitle";
import { WARRANTY_STATUS } from "features/Warranty/constant";
import { useGetSerialExportNotesWarranty } from "hooks/serial/query";
import { useGetWarrantyExportSerial } from "hooks/warranty";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { includes } from "lodash";
import { useState } from "react";
import { convertTimeDate } from "utils/dateTime";
import { notify } from "utils/helperFuncs";
import InputTag from "../InputTag";
import InputTagNewSerial from "../InputTagNewSerial";

function TableWarranty({ form, warehouseOptions, setDataSourceWarranty, dataSourceWarranty, serials, setSerials }) {
  const formData = form.getFieldsValue();
  const [dataWarrantyExportSerial, setDataWarrantyExportSerial] = useState([]);
  const { handleGetWarrantyExportSerial, loading } = useGetWarrantyExportSerial();
  const { handleGetSerialExportNotesWarranty } = useGetSerialExportNotesWarranty();
  const [loadingNewSerial, setLoadingNewSerial] = useState(false);

  const handleInputChangeSerial = async (value, index) => {
    const paramsWarranty = {
      filters: {
        query: null,
        status: [WARRANTY_STATUS.EXPIRED],
        serials: [...serials, value],
      },
      pagination: {
        offset: 0,
        limit: 100,
        skipTotalCount: true,
      },
    };
    const data = await handleGetWarrantyExportSerial(paramsWarranty);
    const warrantyNotes = data?.data?.warranty?.pagination?.noteWarranty;
    setDataWarrantyExportSerial(data?.data?.warranty?.pagination?.noteWarranty);
    const newArray = warrantyNotes?.filter((item) => item.id !== "");
    newArray?.sort((a, b) => serials?.indexOf(a.serialImei) - serials.indexOf(b.serialImei));

    let mergedArr = newArray?.reduce((acc, curr) => {
      const matchedItem = acc?.find((item) => item.serialImei === curr.serialImei);
      if (matchedItem) {
        matchedItem.warehouseID = matchedItem?.warehouseID || acc?.warehouseID;
      } else {
        acc.push(curr);
      }
      return acc;
    }, dataSourceWarranty);

    mergedArr = mergedArr?.filter((item) => Object.keys(item)?.length !== 0);

    if (warrantyNotes?.length > 0 && warrantyNotes !== null) {
      setDataSourceWarranty(mergedArr);
    } else {
      setDataSourceWarranty(dataSourceWarranty?.filter((item) => Object.keys(item)?.length !== 0));
    }
    const filteredArr = [...serials, value]?.filter((item) =>
      dataSourceWarranty?.some((obj) => obj.serialImei === item)
    );
    setSerials([...filteredArr]);
    form.setFieldsValue({
      ...formData,
      items:
        warrantyNotes?.length > 0 && warrantyNotes !== null
          ? mergedArr
          : dataSourceWarranty?.filter((item) => Object.keys(item)?.length !== 0),
    });
    if (!mergedArr?.some((item) => item?.serialImei === value)) {
      notify.warning({
        message: t("serialExportCreate.warningBy"),
      });
    }
    if (includes(serials, value)) {
      notify.warning({ message: t("serialExportCreate.serialAlive") });
    }
  };

  const handleInputChangeNewSerial = async (value, index) => {
    const formData = form.getFieldsValue();
    const arrCodeNewSerial = formData?.items?.flatMap((item) => item?.replaceSerialID?.map((subItem) => subItem?.code));

    if (arrCodeNewSerial.indexOf(value) !== -1) {
      notify.warning({
        message: t("serialExportCreate.warningByCheckNewSerial"),
      });
    } else {
      if (value?.length > 0) {
        setLoadingNewSerial(true);
        handleGetSerialExportNotesWarranty({
          filter: {
            queries: value,
            statuses: ["AVAILABLE"],
          },
        })
          .then((res) => {
            const serials = res?.data?.serial?.pagination?.Serials;
            const productIDNewSerial = serials?.length > 0 && serials[0]?.product?.id;
            if (serials === null || !serials) {
              notify.warning({
                message: t("serialExportCreate.warningBy"),
              });
            }
            const newArray = dataSourceWarranty?.filter((item) => item.id !== "");
            const reverseNewArray = newArray;
            reverseNewArray[index].replaceSerialID = serials?.filter((item) => item.code === value);
            reverseNewArray[index].productIDNewSerial = productIDNewSerial;
            form.setFieldsValue({
              ...formData,
              items: reverseNewArray,
            });
          })
          .catch((err) => {
            notify.error({
              message: err.message,
            });
          })
          .finally(() => {
            setLoadingNewSerial(false);
          });
      }
    }
  };

  const handleDelete = (id, serialImei, index) => {
    setDataSourceWarranty((prevData) => {
      const newData = prevData?.filter((item) => item.id !== id);
      return newData;
    });
    setSerials((prevSerials) => {
      const newSerials = prevSerials?.filter((item) => item !== serialImei);
      return newSerials;
    });

    // Xoa row trong items cua form
    const newFormDataDelete = [...formData.items];
    newFormDataDelete.splice(index, 1);
    form.setFieldsValue({
      ...formData,
      items: newFormDataDelete,
    });
  };

  const handleSelectWarehouse = (value, index) => {
    const newArray = dataSourceWarranty?.filter((item) => item.id !== "");
    const reverseNewArray = newArray;
    reverseNewArray[index].warehouseID = value;
    form.setFieldsValue({
      ...formData,
      items: reverseNewArray,
    });
    setDataWarrantyExportSerial([...dataWarrantyExportSerial, reverseNewArray]);
  };

  const handleCloseNewSerial = (index) => {
    if (formData?.items?.length > 0) {
      const newItems = [...formData?.items];
      if (newItems[index]) {
        newItems[index].replaceSerialID = undefined;
        form.setFieldsValue({
          ...formData,
          items: newItems,
        });
      }
    }
  };

  const columnsBySeller = [
    {
      align: "center",
      fixed: "center",
      title: <CustomTitle title={t("serialIE.SERIALIMEIOLD")} />,
      render: (text, record, index) => {
        return (
          <div style={{ textAlign: "left" }}>
            <Form.Item name={[index, "serialImei"]}>
              {!record?.serialImei ? (
                <InputTag
                  title={t("serialIE.importSerial")}
                  onOke={(inputValue) => handleInputChangeSerial(inputValue, index)}
                />
              ) : (
                <Tag>{record?.serialImei}</Tag>
              )}
            </Form.Item>
            <div style={{ display: "none" }}>
              <Form.Item name={[index, "serialId"]}>{record?.serialID}</Form.Item>
            </div>
          </div>
        );
      },
    },
    {
      align: "left",
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      render: (text, record, index) => {
        const ellipsis = { expandable: true, tooltip: true };
        return (
          <div>
            <ProductLink product={record} />
            <div>
              <Typography.Text ellipsis={ellipsis} style={{ textAlign: "start", margin: 0 }}>
                {record?.product?.name || record?.productName}
              </Typography.Text>
            </div>
            <Typography.Text style={{ textAlign: "end", margin: 0, width: "100%", display: "block" }} italic>
              {record?.product?.uom?.name}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SALEWARRANTY")} />,
      render: (record) => (
        <Typography.Text>
          {record?.warrantyOnSale} {convertUnit(record?.warrantyDateUnit)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.DAYFORSELL")} />,
      render: (record) => record?.soldDate && convertTimeDate(record?.soldDate, DATE_FORMAT),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.WARRANTY")} />,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ textAlign: "start", margin: 0 }}>
            <WarrantyDetailLink warranty={record} />
          </div>
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SERIALIMEINEW")} />,
      render: (text, record, index) => {
        return (
          record?.serialImei && (
            <Form.Item>
              {formData?.items?.length > 0 && formData?.items[index]?.replaceSerialID?.length > 0 ? (
                <Tag
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleCloseNewSerial(index);
                  }}
                >
                  {formData?.items[index]?.replaceSerialID[0]?.code}
                </Tag>
              ) : (
                <InputTagNewSerial
                  title={t("serialIE.importNewSerial")}
                  onOke={(inputValue) => handleInputChangeNewSerial(inputValue, index)}
                />
              )}
            </Form.Item>
          )
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SUPPLIER")} />,
      width: 150,
      render: (_, record, index) => {
        const serialImportNote =
          formData?.items?.length > 0 &&
          formData?.items?.[index]?.replaceSerialID?.[0]?.history?.find((item) => item?.serialImportNoteItem !== null)
            ?.serialImportNoteItem?.serialImportNote;
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {serialImportNote?.provider?.fullName || serialImportNote?.seller?.fullName || "- -"}
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.EXPORTWAREHOUSE")} />,
      render: (_, record, index) => {
        return (
          record?.serialImei && (
            <FormInput
              type={INPUT_TYPE.SELECT}
              formItemOptions={{
                name: [index, "warrantyItem", "warehouseID"],
                rules: [
                  {
                    required: true,
                    message: t("serialIE.error.warehouseID"),
                  },
                ],
              }}
              inputOptions={{
                onChange: (e) => handleSelectWarehouse(e, index),
                options: warehouseOptions,
                placeholder: t("serialIE.placeholder.selectExportWarehouse"),
              }}
            />
          )
        );
      },
    },
    {
      dataIndex: "operation",
      align: "center",
      width: 50,
      render: (_, record, index) =>
        dataSourceWarranty?.length >= 1 ? (
          <Popconfirm
            title={t("serialIE.comfirmDeleteSerial")}
            cancelText={t("common.cancel")}
            okText={t("serialIE.Comfirm")}
            onConfirm={() => handleDelete(record.id, record.serialImei, index)}
          >
            <SvgIcon.TrashFullCartIcon style={{ fontSize: "16px", color: "#5B6673" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  return { columnsBySeller, loadingTableWarranty: loading || loadingNewSerial };
}

export default TableWarranty;
