/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleFilled, CloseCircleFilled, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import SearchableSelect from "components/CustomSelect/SearchableSelect";
import FormInput from "components/FormInput";
import { DIMENSION_UNIT, INPUT_TYPE, REGEX, WEIGHT_UNIT } from "config/constants";
import SizeInput from "features/Products/Form/components/SizeInput";
import WeightInput from "features/Products/Form/components/WeightInput";
import {
  useCheckDuplicatedProduct,
  useGetAllCategories,
  useGetAttributeOfCategory,
  useGetBrand,
  useGetUom,
} from "hooks/product/product";
import { t } from "i18next";
import { has } from "lodash";
import { ATTRIBUTE_TYPE } from "pages/App/Product/ProductGroup/conts";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const PopupAddProduct = ({ handleAddCodeProduct, record, isAdd }) => {
  const [form] = useForm();
  const inputRef = useRef(null);

  const [visible, setVisible] = useState(false);

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [isCheckDuplicateProductCode, setIsCheckDuplicateProductCode] = useState(true);
  const [productCode, setProductCode] = useState(null);

  const [showSuffix, setShowSuffix] = useState(false);
  const [categoryID, setCategoryID] = useState(record?.categoryID);
  const [categoryIDTempore, setCategoryIDTempore] = useState();
  const { dataBrand } = useGetBrand();
  const { data: dataUom } = useGetUom();
  const { data: dataAllCategories } = useGetAllCategories();
  const { data: dataAttributeOfCategory } = useGetAttributeOfCategory(categoryID);

  const openModal = () => {
    setVisible(true);
  };

  const openModalConfirm = () => {
    setVisibleConfirm(true);
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
    setShowSuffix(false);
  };

  const handleChangeCategory = (e) => {
    const formData = form.getFieldsValue();
    if (categoryID) {
      setVisibleConfirm(true);
      setCategoryIDTempore(e);
    } else {
      setCategoryID(e);
      form.setFieldsValue({
        ...formData,
        attributeValues: [],
      });
    }
  };

  const handleSubmitChangeCate = () => {
    const formData = form.getFieldsValue();
    setCategoryID(categoryIDTempore);
    setVisibleConfirm(false);
    form.setFieldsValue({
      ...formData,
      attributeValues: [],
    });
  };

  const handleCancelConfirm = () => {
    const formData = form.getFieldsValue();
    setVisibleConfirm(false);
    form.setFieldsValue({
      ...formData,
      categoryID: categoryID,
    });
  };

  const onSearchTreeSelect = (search, option) => {
    return option?.label?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0;
  };

  const onSearchSelect = (search, option) => {
    return option?.label?.toLowerCase()?.includes(search?.toLowerCase());
  };

  const validateWeight = (_, value) => {
    if (value?.weight && value?.unit) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("product.productForm.weightNotBlank")));
  };

  const checkSize = (_, value) => {
    if (value?.length && value?.width && value?.height) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("product.productForm.sizeNotBlank")));
  };

  const [attributes, setAttributes] = useState([]);

  const isDisable = (hasVariant) => {
    if (hasVariant) {
      return false;
    }
  };

  const handleChangeName = (isVariant, index) => {
    return isVariant ? [index, "attributeValueIDs"] : [index, "attributeValueID"];
  };
  const handleValuesChange = (changedValues, allValues) => {
    if (has(changedValues, "attributeValues")) {
      setAttributes([...allValues?.attributeValues]);
    }
  };
  const renderInput = (object, index) => {
    switch (object?.attributeType) {
      case ATTRIBUTE_TYPE.FREE_TEXT:
        return (
          <div className="input-free-text">
            <FormInput
              formItemOptions={{
                name: [index, "value"],
                label: <span className="title">{object?.name}</span>,
                rules: [
                  {
                    required: true,
                    message: t("purchase.purchaseOrderCreate.pleaseEnterAttribute"),
                  },
                ],
              }}
              inputOptions={{
                placeholder: `Nhập ${object?.name}`,
              }}
            />
          </div>
        );
      case ATTRIBUTE_TYPE.PREDEFINED:
        return (
          <div className="input-predefined">
            <SearchableSelect
              formItemOptions={{
                name: handleChangeName(attributes?.at(index)?.isVariant, index),
                rules: [
                  {
                    required: true,
                    message: t("purchase.purchaseOrderCreate.pleaseEnterAttribute"),
                  },
                ],
                label: (
                  <div className="label-input-predefined">
                    <span className="title">{object?.name}</span>
                    {false && (
                      <FormInput
                        type="CHECK_BOX"
                        style={{
                          margin: 0,
                        }}
                        formItemOptions={{
                          name: [index, "isVariant"],
                          valuePropName: "checked",
                        }}
                        inputOptions={{
                          disabled: isDisable(attributes?.at(index)?.isVariant),
                        }}
                        inputChildren={t("product.productForm.hasAttribute")}
                      />
                    )}
                  </div>
                ),
              }}
              selectOptions={{
                mode: attributes?.at(index)?.isVariant && "multiple",
                options: object?.values?.map((item) => {
                  return { value: item?.id, label: item?.value };
                }),
                placeholder: `Nhập ${object?.name}`,
              }}
            />
          </div>
        );
      default:
        return;
    }
  };

  const initialValues = {
    id: record?.id,
    productCode: record?.productCode,
    productName: record?.productName,
    brandID: record?.brandID,
    categoryID: record?.categoryID,
    productWarranty: record?.productWarranty,
    uomID: record?.uomID,
    productDescription: record?.productDescription,
    dimension: {
      length: record?.dimension?.length,
      width: record?.dimension?.width,
      height: record?.dimension?.height,
      unit: record?.dimension?.unit || DIMENSION_UNIT.MILLIMETER,
    },
    weight: {
      weight: record?.weight?.weight,
      unit: record?.weight?.unit || WEIGHT_UNIT.KILOGRAM,
    },
    attributeValues: [{ attributeValueID: "4a15K9kWzrdv_589" }, { attributeValueID: "nj0YP4WwzG5l_577" }],
  };

  useEffect(() => {
    const formData = form.getFieldsValue();
    form.setFieldsValue({
      ...formData,
      attributeValues: record?.attributeValues?.map((item) => ({ attributeValueID: item?.attributeValueIDs })),
    });
  }, [record, visible]);

  const convertAttributesProductGroupDetail = (dataAttributeOfCategory = [], attributeValues = []) => {
    return dataAttributeOfCategory?.map((item) => {
      const findIndex = attributeValues?.findIndex((attr) => item?.id === attr?.attributeID);
      const currentAttributeValue = attributeValues?.at(findIndex);

      return {
        attributeID: item?.id,
        attributeValueIDs: item?.values?.map((item) => item?.id) || [],
        isVariant: currentAttributeValue?.isVariant || false,
      };
    });
  };

  const { handleCheckDuplicatedProduct, loading } = useCheckDuplicatedProduct();
  const checkDuplicateProductCodeValid = async (_, value) => {
    setShowSuffix(true);
    setProductCode(value);
    const data = await handleCheckDuplicatedProduct({ productCode: value });
    const checkDuplicateProductCode = data?.data?.product?.checkDuplicatedProduct;
    if (value !== null || value?.length === 0) {
      setIsCheckDuplicateProductCode(!checkDuplicateProductCode?.result);
      if (checkDuplicateProductCode?.result) {
        inputRef.current.focus();

        return Promise.reject(
          <span style={{ fontSize: "12px" }}>
            {t("purchase.purchaseOrderCreate.duplicatedProductCode")}{" "}
            <Link to={`/product/detail/${checkDuplicateProductCode?.product?.id}`} target="_blank">
              <Typography.Link strong>{checkDuplicateProductCode?.product?.name}</Typography.Link>
            </Link>
          </span>
        );
      }
    } else {
      setIsCheckDuplicateProductCode(false);
    }
    inputRef.current.focus();
    return Promise.resolve();
  };

  return (
    <div>
      <Space>
        <CustomModal
          footer={false}
          width="60%"
          title={
            <Typography.Text strong style={{ fontSize: "20px" }}>
              {isAdd ? t("purchase.purchaseOrderCreate.editProduct") : t("purchase.purchaseOrderCreate.addNewProduct")}
            </Typography.Text>
          }
          okeButtonLabel={isAdd ? t("common.save") : t("warehouse.create.create")}
          customComponent={
            isAdd ? (
              <EditOutlined style={{ fontSize: "30px", paddingTop: "5px" }} />
            ) : (
              <Row>
                <Col>
                  <PlusCircleFilled style={{ fontSize: "30px", color: "#2246DC" }} />
                </Col>
                <Col>
                  <Typography.Link>{t("purchase.purchaseOrderCreate.addNewProduct")}</Typography.Link>
                </Col>
              </Row>
            )
          }
          onOke={() => {
            form.validateFields().then((values) => {
              // form.resetFields();
              handleAddCodeProduct(values, record?.id, convertAttributesProductGroupDetail(dataAttributeOfCategory));
              setVisible(false);
            });
          }}
          onOpen={openModal}
          onCancel={closeModal}
          visible={visible}
        >
          <Form
            form={form}
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
            layout="vertical"
            name="form_in_modal"
          >
            <Row gutter={[16, 8]} className="form-add-code-product">
              <Col span={24}>
                <Card title={t("product.productForm.title")} className="card-info-product">
                  <div className="info-product">
                    <FormInput
                      formItemOptions={{
                        label: t("product.productForm.productCode"),
                        className: "product-code",
                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankProductCode"),
                          },
                          {
                            pattern: REGEX.CODE,
                            message: t("product.productForm.formatProductCode"),
                          },
                          {
                            max: 50,
                            message: t("product.productForm.maximumProductCode"),
                          },
                          {
                            validator: checkDuplicateProductCodeValid,
                          },
                        ],
                        name: "productCode",
                      }}
                      inputOptions={{
                        placeholder: t("product.productForm.placeholderProductCode"),
                        ref: inputRef,
                        suffix:
                          showSuffix && !loading ? (
                            isCheckDuplicateProductCode && productCode?.length > 0 ? (
                              <CheckCircleFilled style={{ color: "green", fontSize: "13px" }} />
                            ) : (
                              <CloseCircleFilled style={{ color: "red", fontSize: "13px" }} />
                            )
                          ) : undefined,
                      }}
                    />

                    <FormInput
                      formItemOptions={{
                        label: t("product.productForm.productName"),
                        className: "product-name",
                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankProductName"),
                          },
                          {
                            pattern: REGEX.TEXT_TRIM_SPACE,
                            message: t("supplier.placeholderInput.whiteSpace"),
                          },
                          {
                            max: 255,
                            message: t("product.productForm.maximumProductName"),
                          },
                        ],
                        name: "productName",
                      }}
                      inputOptions={{
                        placeholder: t("product.productForm.placeholderProductName"),
                      }}
                    />

                    <FormInput
                      type={INPUT_TYPE.SELECT}
                      formItemOptions={{
                        label: t("product.productForm.brand"),
                        className: "brand",
                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankBrand"),
                          },
                        ],
                        name: "brandID",
                      }}
                      inputOptions={{
                        showSearch: true,
                        placeholder: t("product.productForm.placeholderBrand"),
                        options: dataBrand,
                        filterOption: (search, option) => onSearchSelect(search, option),
                      }}
                    />
                    <FormInput
                      type={INPUT_TYPE.TREE_SELECT}
                      formItemOptions={{
                        placeholder: t("product.productForm.placeholderCategory"),
                        label: t("product.productForm.category"),
                        className: "category",
                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankCategory"),
                          },
                        ],
                        name: "categoryID",
                        extra: <i>{t("product.productForm.extraCategory")}</i>,
                      }}
                      inputOptions={{
                        placeholder: t("product.productForm.placeholderCategory"),
                        treeData: dataAllCategories,
                        showSearch: true,
                        filterTreeNode: (search, item) => onSearchTreeSelect(search, item),
                        onChange: handleChangeCategory,
                      }}
                    />

                    <FormInput
                      type={INPUT_TYPE.NUMBER}
                      formItemOptions={{
                        label: t("product.productForm.warrantyPeriod"),
                        className: "warranty",

                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankWarrantyPeriod"),
                          },
                          {
                            type: "number",
                            max: 999,
                            message: t("product.productForm.maximumWarrantyPeriod"),
                          },
                          {
                            type: "number",
                            transform: (value) => +value,
                            message: t("product.productForm.typeWarrantyPeriod"),
                          },
                        ],
                        name: "productWarranty",
                      }}
                      inputOptions={{
                        placeholder: t("product.productForm.warrantyPlaceholder"),
                      }}
                    />

                    <FormInput
                      type={INPUT_TYPE.SELECT}
                      formItemOptions={{
                        label: t("product.productForm.unit"),
                        name: "uomID",
                        className: "uom",
                        rules: [
                          {
                            required: true,
                            message: t("product.productForm.blankUnit"),
                          },
                        ],
                      }}
                      inputOptions={{
                        showSearch: true,
                        placeholder: t("product.productForm.placeholderUnit"),
                        options: dataUom,
                        filterOption: (search, option) => onSearchSelect(search, option),
                      }}
                    />

                    <Form.Item
                      className={["product-size"]}
                      label={t("product.productForm.sizeLabel")}
                      name="dimension"
                      required
                      rules={[
                        {
                          validator: checkSize,
                        },
                      ]}
                    >
                      <SizeInput />
                    </Form.Item>

                    <Form.Item
                      className={"product-weight"}
                      label={t("product.productForm.weightLabel")}
                      name="weight"
                      required
                      rules={[
                        {
                          validator: validateWeight,
                        },
                      ]}
                    >
                      <WeightInput />
                    </Form.Item>

                    <div className="editor">
                      <FormInput
                        type={INPUT_TYPE.TEXT_AREA}
                        formItemOptions={{
                          label: t("product.productForm.description"),
                          name: "productDescription",
                        }}
                        inputOptions={{}}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                {dataAttributeOfCategory && (
                  <Card title={t("purchase.purchaseOrderCreate.attributeProduct")} className="card-info-product">
                    <div className="product-attributes">
                      <Form.List name="attributeValues">
                        {() =>
                          dataAttributeOfCategory?.map((item, index) => {
                            return <div key={item?.id}>{renderInput(item, index)}</div>;
                          })
                        }
                      </Form.List>
                    </div>
                  </Card>
                )}
              </Col>
            </Row>
          </Form>
        </CustomModal>
        <CustomModal
          {...{
            width: "30%",
            footer: false,
            centered: true,
            styleButton: "notButton",
            hideConfirmButton: true,
            className: "form-accept-purchase-order",
            addMoreButton: (
              <div className="button-group-action-check-info-purchase-order">
                <Button type="secondary" htmlType="submit" onClick={handleCancelConfirm}>
                  {t("productBrand.cancel")}
                </Button>
                <Button type="primary" htmlType="submit" onClick={handleSubmitChangeCate}>
                  {t("common.confirm")}
                </Button>
              </div>
            ),
            visible: visibleConfirm,
            onOpen: openModalConfirm,
            onCancel: handleCancelConfirm,
            hideCancelButton: true,
          }}
        >
          <div className="form-accept-content">
            <SvgIcon.IconInfoConfirm />
            <div>
              <Typography.Text>
                Nếu bạn thay đổi ngành hàng, thông tin về thuộc tính sản phẩm và biến thể bạn vừa nhập sẽ không được lưu
                lại. Bạn có chắc chắn muốn thay đổi không?
              </Typography.Text>
            </div>
          </div>
        </CustomModal>
      </Space>
    </div>
  );
};
export default PopupAddProduct;
