import { Modal } from "antd";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./index.scss";

const CustomModal = ({
  message,
  buttonLabel,
  onOke,
  onCancel,
  cancelButtonLabel,
  okeButtonLabel,
  customComponent,
  icon,
  title,
  children,
  isBlockCloseOnOke,
  htmlType,
  buttonLoading,
  switchClose,
  afterClose,
  onOpen,
  selfVisibleControlled = true,
  hideConfirmButton = false,
  hideCancelButton = false,
  addMoreButton,
  styleButton = "button",
  styleBtnGrp,
  disabled,
  subTitle,
  customIcon,
  className,
  loadingBtnCancel,
  ...rest
}) => {
  const [localVisible, setLocalVisible] = useState(!selfVisibleControlled);

  function openModal() {
    if (selfVisibleControlled) {
      setLocalVisible(true);
    }
    onOpen && onOpen();
  }

  function closeModal() {
    if (selfVisibleControlled) {
      setLocalVisible(false);
    }
  }

  function handleOk() {
    !isBlockCloseOnOke && closeModal();
    onOke && onOke();
  }

  function handleCancel() {
    closeModal();
    onCancel && onCancel();
  }

  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchClose]);

  return (
    <>
      <Modal
        afterClose={afterClose}
        visible={localVisible}
        onCancel={handleCancel}
        className={className}
        confirmLoading
        {...rest}
      >
        <div className="modal-content">
          {icon && (
            <div
              className="delete-icon"
              style={{
                backgroundImage: `url(${icon})`,
              }}
            ></div>
          )}
          {customIcon && <span className="custom-icon">{customIcon}</span>}
          {title && title}
          {subTitle && subTitle}
          {message && <span className="message">{message}</span>}
          {children && <div className="modal-children">{children}</div>}
          <div className="btn-group" style={styleBtnGrp}>
            {!hideCancelButton && (
              <PrimaryButtonOutLine
                onClick={handleCancel}
                title={cancelButtonLabel || t("common.cancel")}
                loading={loadingBtnCancel}
              />
            )}
            {!hideConfirmButton && (
              <PrimaryButton
                title={okeButtonLabel || t("common.confirm")}
                loading={buttonLoading}
                htmlType={htmlType}
                onClick={handleOk}
                disabled={disabled}
              />
            )}
            {addMoreButton}
          </div>
        </div>
      </Modal>

      {selfVisibleControlled && (
        <div onClick={openModal} className={styleButton}>
          {customComponent}
          {buttonLabel && <span>{buttonLabel}</span>}
        </div>
      )}
    </>
  );
};

export default CustomModal;
