import { Checkbox, Col, Row, Space, Spin, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomCard from "components/CustomCard";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import PageHeader from "components/PageHeader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency } from "utils/helperFuncs";
import {
  TABS_SUGGESTED_ENTRY,
  convertUnit,
  digitalStatusColor,
  digitalStatusLabel,
  digitalStatusOptions,
  priorityColor,
  priorityLabel,
  priorityOptions,
  statusLabel,
  statusOptions,
} from "../constants";
import FilterByUserStaffIDs from "./FilterByUserStaffIDs";
import TableSuggestedEntry from "./TableSuggestedEntry";
import Toolbar from "./Toolbar";
import useSuggestedEntry from "./useSuggestedEntry";

function SuggestedEntry() {
  const { t } = useTranslation();

  const {
    activeKeyMain,
    propsContent,
    params,
    selectedRowKeys,
    selectedRows,
    handleSearch,
    handleFilter,
    handleChangeTab,
    handleChangeParams,
    handleDownloadFileURL,
    loading,
    canSuggestKeyLicenseAccountCancel,
    canSuggestKeyLicenseAccountConfirm,
  } = useSuggestedEntry(t);

  const columns = [
    ...(activeKeyMain === TABS_SUGGESTED_ENTRY.ALL || activeKeyMain === TABS_SUGGESTED_ENTRY.CANCELED
      ? [
          {
            title: <Typography.Text style={{ color: "#5b6673" }}>{t("v2.keyManagement.index")}</Typography.Text>,
            render: (text, record, index) => index + 1,
            width: 60,
            align: "center",
          },
        ]
      : []),
    {
      title: t("v2.keyManagement.dateCreated"),
      render: (_, record) => formatDateTime(record?.createdAt),
      width: 120,
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
    },
    {
      title: t("v2.keyManagement.exportNotes.code"),
      width: 200,
      render: (_, record) => (
        <Typography.Link strong>
          <Link target="_blank" to={`/order/${record?.id}`}>
            {record?.number || "--"}
          </Link>
        </Typography.Link>
      ),
    },
    {
      title: t("v2.keyManagement.exportNotes.seller"),
      render: (_, record) => <CustomTextHiddenLine line={2} text={record?.seller?.fullName} />,
      width: 450,
    },
    {
      title: t("v2.keyManagement.exportNotes.email"),
      width: 250,
      render: (_, record) => <CustomTextHiddenLine line={2} text={record?.email} />,
    },
    {
      title: t("v2.keyManagement.exportNotes.total"),
      width: 150,
      render: (_, record) => (
        <Typography.Text className={!record?.total && "text-center"}>{formatCurrency(record?.total)}</Typography.Text>
      ),
      dataIndex: "orderTotal",
      sorter: true,
      align: "right",
    },
    ...(activeKeyMain !== TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            title: t("v2.keyManagement.exportNotes.priority"),
            width: 150,
            render: (_, record) => (
              <Typography.Text
                style={{ color: priorityColor?.[record?.orderPriority] }}
                className={!record?.orderPriority && "text-center"}
              >
                {priorityLabel?.[record?.orderPriority]}
              </Typography.Text>
            ),
            filterDropdown: (
              <FilterMenu
                onFilter={(value) => handleFilter(value, "priorities")}
                type="checkbox"
                options={priorityOptions}
              />
            ),
          },
        ]
      : []),
    ...(activeKeyMain === TABS_SUGGESTED_ENTRY.ALL
      ? [
          {
            title: t("v2.keyManagement.exportNotes.digitalStatus"),
            width: 200,
            render: (_, record) => {
              return (
                <Typography.Text
                  style={{ color: digitalStatusColor?.[record?.digitalStatus] }}
                  className={!record?.digitalStatus && "text-center"}
                >
                  {digitalStatusLabel?.[record?.digitalStatus] || "--"}
                </Typography.Text>
              );
            },
            filterDropdown: (
              <FilterMenu
                onFilter={(value) => handleFilter(value, "digitalStatuses")}
                type="checkbox"
                options={digitalStatusOptions}
              />
            ),
          },
        ]
      : []),
    ...(activeKeyMain === TABS_SUGGESTED_ENTRY.WAITING_SEND_CUSTOMER || activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            title: t("v2.keyManagement.exportNotes.orderStatus"),
            width: 220,
            render: (_, record) => (
              <Typography.Text className={!record?.status && "text-center"}>
                {statusLabel?.[record?.status] || "--"}
              </Typography.Text>
            ),
            filterDropdown: (
              <FilterMenu
                searchable={true}
                onFilter={(value) => handleFilter(value, "statuses")}
                type="checkbox"
                options={statusOptions}
              />
            ),
          },
        ]
      : []),
    ...(activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            width: 150,
            align: "center",
            title: t("v2.keyManagement.exportNotes.file"),
            render: (_, record) => {
              return (
                <Tooltip title={t("v2.keyManagement.exportNotes.download_file_export_notes")}>
                  <div
                    onClick={() =>
                      handleDownloadFileURL({
                        serialExportNoteLatest: record?.serialExportNoteLatest,
                      })
                    }
                  >
                    <SvgIcon.Xlsx />
                  </div>
                </Tooltip>
              );
            },
          },
        ]
      : []),

    ...(activeKeyMain !== TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            title: t("v2.keyManagement.exportNotes.createdBy"),
            width: 200,
            render: (_, record) => <CustomTextHiddenLine text={record?.createdBy?.fullname} line={1} />,
            filterDropdown: <FilterByUserStaffIDs onFilter={handleFilter} obj="createdByIDs" />,
          },
        ]
      : []),
    ...(activeKeyMain === TABS_SUGGESTED_ENTRY.COMPLETED
      ? [
          {
            width: 200,
            title: t("v2.keyManagement.exportNotes.sentBy"),
            render: (_, record) => <CustomTextHiddenLine text={record?.sentDigitalBy?.fullname} line={1} />,
            filterDropdown: <FilterByUserStaffIDs onFilter={handleFilter} obj="sentDigitalByIDs" />,
          },
        ]
      : []),
  ];

  const columnTableSelected = [
    {
      title: <Typography.Text style={{ color: "#5b6673" }}>{t("v2.keyManagement.index")}</Typography.Text>,
      render: (_, record, index) => index + 1,
      width: 20,
      fixed: true,
      align: "center",
    },
    {
      title: t("v2.keyManagement.parameter"),
      render: (_, record, index) => (
        <Space direction="vertical">
          <Typography.Link strong>
            <Link target="_blank" to={`/product/detail/${record?.productID}`}>
              {record?.productCode}
            </Link>
          </Typography.Link>
          <CustomTextHiddenLine line={2} text={record?.productName} />
        </Space>
      ),
      width: 400,
    },
    {
      title: t("v2.keyManagement.exportNotes.vat"),
      width: 40,
      align: "center",
      render: (_, record) => <Checkbox checked={record?.vat} disabled />,
    },
    {
      title: t("v2.keyManagement.exportNotes.quantity"),
      width: 100,
      align: "center",
      render: (_, record) => record?.quantity || "--",
    },
    {
      title: (
        <>
          {t("v2.keyManagement.exportNotes.sellerPrice")} <br />
          <Typography.Link>({t("v2.keyManagement.exportNotes.level1")})</Typography.Link>
        </>
      ),
      render: (_, record) => formatCurrency(record?.price) || "--",
      width: 150,
      align: "right",
    },
    {
      title: t("v2.keyManagement.exportNotes.sellerTotalPrice"),
      render: (_, record) => <Typography.Link>{formatCurrency(record?.total) || "--"}</Typography.Link>,
      width: 180,
      align: "right",
    },
    {
      title: t("v2.keyManagement.exportNotes.warranty"),
      render: (_, record) => record?.productWarranty + " " + convertUnit(record?.warrantyDateUnit) || "--",
      width: 120,
      align: "center",
    },
    {
      title: t("v2.keyManagement.exportNotes.debtDays"),
      render: (_, record) => record?.maxDaysOfDebt || "--",
      width: 120,
      align: "center",
    },
    {
      title: t("v2.keyManagement.exportNotes.inStock"),
      render: (_, record) => record?.inStock || "--",
      width: 120,
      align: "center",
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <PageHeader
            pageTitle={t("v2.keyManagement.exportNotes.title")}
            routes={[
              { path: "#", name: t("v2.keyManagement.title") },
              { path: "#", name: t("v2.keyManagement.exportNotes.title") },
            ]}
          />
        </Col>
        <Col span={24}>
          <CustomCard
            tabList={propsContent.tabList}
            onTabChange={handleChangeTab}
            defaultActiveTabKey={activeKeyMain}
            content={
              <>
                <Toolbar
                  i18n={t}
                  onChangeParams={handleChangeParams}
                  onSearch={handleSearch}
                  params={params}
                  activeKeyMain={activeKeyMain}
                  selectedRowKeys={selectedRowKeys}
                  selectedRows={selectedRows}
                  propsContent={propsContent}
                  canSuggestKeyLicenseAccountCancel={canSuggestKeyLicenseAccountCancel}
                  canSuggestKeyLicenseAccountConfirm={canSuggestKeyLicenseAccountConfirm}
                />
                <TableSuggestedEntry
                  propsContent={propsContent}
                  columns={columns}
                  columnTableSelected={columnTableSelected}
                  activeKeyMain={activeKeyMain}
                />
              </>
            }
            activeTabKey={activeKeyMain}
          />
        </Col>
      </Row>
    </Spin>
  );
}

export default SuggestedEntry;
