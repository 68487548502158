/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Tag, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu";
import {
  useGetCountStatusRequestImport,
  useGetListRecommendImport,
  useImportRequestPermissions,
} from "hooks/recommendimport";
import { useGetSupplierOnLoadMore } from "hooks/supplier";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, formatCurrencyDollar, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";
import {
  IMPORT_REQUEST_TYPE,
  RECOMMEND_IMPORT,
  convertActiveKey,
  importRequestStatus,
  importRequestType,
  optionImportRequestStatus,
  optionImportRequestType,
  setColorStatusImportRequest,
} from "../../constant";
import { ButtonActionRecommend } from "./components/ButtonActionRecommend";
import { ButtonCancelRecommend } from "./components/ButtonCancelRecommend";
import { ButtonCompleteInportRequest } from "./components/ButtonCompleteInportRequest";
import { ButtonReRequest } from "./components/ButtonReRequest";
import FilterMenu from "./components/FilterMenu";

export default function useList() {
  const GET_QUERY = getQuery();
  const MAX_SCREEN = "calc(80vh - 280px)";
  const MIN_SCREEN = "calc(60vh - 280px)";

  const {
    canImportRequestView,
    canImportRequestCreate,
    canImportRequestUpdate,
    canImportRequestApprove,
    canImportRequestReject,
    canImportRequestCancel,
    canImportRequestViewCreatedByMe,
    canImportRequestComplete,
  } = useImportRequestPermissions();
  const { me } = useGetUserBranch();
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: GET_QUERY?.query || "",
        statuses: GET_QUERY.statuses || [],
        timeRange: {
          from: GET_QUERY?.from || undefined,
          to: GET_QUERY?.to || undefined,
        },
        types: GET_QUERY?.types || [IMPORT_REQUEST_TYPE.DOMESTIC, IMPORT_REQUEST_TYPE.OVERSEAS],
        warehouseIDs: GET_QUERY?.warehouseIDs || [],
        supplierIDs: GET_QUERY?.supplierIDs || [],
        createdByIDs: GET_QUERY?.createdByIDs || [],
        updatedByIDs: GET_QUERY?.updatedByIDs || [],
        codes: GET_QUERY?.codes || [],
      },
      pagination: {
        limit: 10,
        offset: GET_QUERY?.offset || 0,
      },
      sorts: [],
    };
  });
  useEffect(() => {
    if (canImportRequestViewCreatedByMe === true && canImportRequestView === false) {
      setParams({
        ...params,
        filters: {
          ...params.filters,
          createdByIDs: [me?.id],
        },
      });
    } else if (canImportRequestViewCreatedByMe === false && canImportRequestView === false) {
      setParams({
        ...params,
        filters: { ...params.filters, createdByIDs: [""] },
      });
    } else {
      setParams({
        ...params,
        filters: { ...params.filters, createdByIDs: [] },
      });
    }
  }, [JSON.stringify(me), JSON.stringify(canImportRequestViewCreatedByMe), JSON.stringify(canImportRequestView)]);

  const { data, paginationData, loading } = useGetListRecommendImport(params);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAllRows, setShowAllRows] = useState(true);
  const [sizeScrollPageAll, setSizeScrollPageAll] = useState({ x: 1800, y: MAX_SCREEN });
  const [selectedHightline, setSelectedHightline] = useState(null);
  const handleRowClick = (record, index) => {
    setSelectedHightline(index);
    setSelectedRow(record);
    setShowAllRows(false);
    setSizeScrollPageAll({ x: 1800, y: MIN_SCREEN });
  };

  const handleToggleRows = () => {
    setSizeScrollPageAll(showAllRows ? { x: 1800, y: MIN_SCREEN } : { x: 1800, y: MAX_SCREEN });
    setShowAllRows(!showAllRows);
  };

  const [statusTabs, setStatusTabs] = useState(
    GET_QUERY?.statuses && GET_QUERY?.statuses?.length === 1 ? GET_QUERY?.statuses[0] : RECOMMEND_IMPORT.ALL
  );
  const handleChange = (activeKey) => {
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1800, y: MAX_SCREEN });
    setStatusTabs(activeKey);
    setSelectedRow(null);
    setParams({
      filters: {
        timeRange: {
          from: undefined,
          to: undefined,
        },
        query: "",
        statuses: convertActiveKey(activeKey),
        types: [IMPORT_REQUEST_TYPE.DOMESTIC, IMPORT_REQUEST_TYPE.OVERSEAS],
        warehouseIDs: [],
        supplierIDs: [],
        createdByIDs: canImportRequestView === true ? [] : params?.filters?.createdByIDs,
        updatedByIDs: [],
        codes: [],
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sorts: [],
    });
  };

  const isStatusAll = statusTabs === RECOMMEND_IMPORT?.ALL;
  const [filterTags, setFilterTags] = useState([]);

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { warehouses } = useWarehouse(paramsStaff);
  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));
  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);
  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: sellers,
    onLoadMoreData: onLoadMoreDataSeller,
    hasMoreData: hasMoreDataSeller,
  } = useGetSupplierOnLoadMore(paramsSeller);

  const sellersOptions = sellers?.map(({ id, fullName }) => ({ value: id, label: fullName }));

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };
  const handleSearchSellers = (value) => {
    setParamsSeller({
      ...paramsSeller,
      pagination: { offset: 0, limit: 10 },
      filters: { query: value === "" ? null : value },
    });
  };

  const handleFilter = (value) => {
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1800, y: MAX_SCREEN });
    setSelectedRow(null);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
      sorts: params?.sorts,
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams?.activeKey,
      },
    });
  };

  const handleSort = (by) => {
    if (params.sorts[0]?.type === "ASC") {
      const newParams = {
        ...params,
        sorts: [{ by: by, type: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sorts: [{ by: by, type: "ASC" }],
      };
      setParams(newParams);
    }
  };
  const columns = {
    stt: {
      title: "STT",
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    actionEdit: {
      render: (_, record) => (
        <Link to={`/import-request/edit/${record?.id}`}>
          <SvgIcon.EditIcon style={{ cursor: "pointer" }} />{" "}
        </Link>
      ),
      width: 60,
      align: "center",
    },
    createdAt: {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("CREATED_AT")} style={{ color: "#5B6673" }}>
          {t("warehouse.recommendImport.CREATED_AT")}
          <SvgIcon.SortIcon className={params?.sorts[0]?.type} />
        </Typography.Text>
      ),
      width: 165,
      render: (_, record) => formatDateTime(record?.createdAt),
      align: "center",
    },
    codeRecommend: {
      title: t("warehouse.recommendImport.CODERECOMMEND"),
      render: (_, record) => (
        <Link to={`/import-request/detail/${record.id}`}>
          <Typography.Link strong>{record?.code}</Typography.Link>
        </Link>
      ),
    },
    provider: {
      title: t("warehouse.recommendImport.PROVIDER"),
      width: 350,
      render: (_, record) => (
        <Tooltip placement="top" title={record?.supplier?.fullName}>
          <Typography.Text className="text-hidden-two">{record?.supplier?.fullName}</Typography.Text>
        </Tooltip>
      ),
      filterDropdown: (
        <FilterLoadMore
          params={paramsSeller}
          data={sellersOptions}
          onLoadMoreData={onLoadMoreDataSeller}
          hasMore={hasMoreDataSeller}
          onFilter={(value) => handleFilter({ supplierIDs: value })}
          onSearchTextChange={handleSearchSellers}
        />
      ),
    },

    sumMoney: {
      title: t("warehouse.recommendImport.SUMMONEY"),
      render: (_, record) => {
        let sumPrice = 0;
        for (let i = 0; i < record?.items?.length; i++) {
          const { quantity, price } = record?.items[i];
          sumPrice += quantity * price;
        }
        return record?.items && record?.items[0]?.currency === "VND"
          ? formatCurrency(sumPrice)
          : formatCurrencyDollar(sumPrice);
      },
    },
    warehouseImport: {
      title: t("warehouse.recommendImport.WAREHOUSE"),
      render: (_, record) => record?.warehouse?.name,
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ warehouseIDs: value })}
          type="checkbox"
          options={warehouseOptions}
        />
      ),
    },
    typeRecommend: {
      title: t("warehouse.recommendImport.TYPERECOMMEND"),
      render: (_, record) => importRequestType[record?.type],
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ types: [value] })}
          type="radio"
          options={optionImportRequestType}
        />
      ),
    },
    status: {
      title: t("warehouse.recommendImport.STATUS"),
      render: (_, record) => (
        <Tag color={setColorStatusImportRequest(record?.status)}>{importRequestStatus[record?.status]}</Tag>
      ),
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ statuses: value })}
          type="checkbox"
          options={optionImportRequestStatus}
        />
      ),
    },
    actionAll: {
      title: t("warehouse.recommendImport.ACTIONALL"),
      render: (_, record) => {
        return (
          <div>
            {canImportRequestApprove && canImportRequestReject ? (
              <ButtonActionRecommend
                record={record}
                canImportRequestApprove={canImportRequestApprove}
                canImportRequestReject={canImportRequestReject}
              />
            ) : (
              <></>
            )}
            {canImportRequestCancel ? <ButtonCancelRecommend record={record} /> : <></>}
          </div>
        );
      },
    },
    actionTabReject: {
      title: t("warehouse.recommendImport.ACTIONALL"),
      render: (_, record) => {
        return (
          <div>
            {canImportRequestUpdate ? <ButtonReRequest record={record} /> : <></>}
            {canImportRequestCancel ? <ButtonCancelRecommend record={record} /> : <></>}
          </div>
        );
      },
    },
    actionComplete: {
      title: t("warehouse.recommendImport.ACTIONALL"),
      render: (_, record) => {
        return (
          <div>
            {canImportRequestComplete && record?.status === RECOMMEND_IMPORT.WAITING_FOR_BUYING && (
              <ButtonCompleteInportRequest record={record} />
            )}
          </div>
        );
      },
    },
    approvedBy: {
      title: t("warehouse.recommendImport.APPROVEBY"),
      render: (_, record) => record?.updatedBy?.fullname,
      filterDropdown: (
        <FilterLoadMore
          params={paramsStaff}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          onFilter={(value) => handleFilter({ updatedByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
    },
    createdBy:
      canImportRequestView === true
        ? {
            title: t("warehouse.recommendImport.CREATEDBY"),
            render: (_, record) => record?.createdBy?.fullname,
            filterDropdown: (
              <FilterLoadMore
                params={paramsStaff}
                data={staffs}
                onLoadMoreData={onLoadMoreData}
                hasMore={hasMoreData}
                onFilter={(value) => handleFilter({ createdByIDs: value })}
                onSearchTextChange={handleSearchStaffs}
              />
            ),
          }
        : {
            title: t("warehouse.recommendImport.CREATEDBY"),
            render: (_, record) => record?.createdBy?.fullname,
          },
  };

  const {
    stt,
    actionEdit,
    createdAt,
    codeRecommend,
    provider,
    sumMoney,
    warehouseImport,
    typeRecommend,
    actionAll,
    actionTabReject,
    status,
    createdBy,
    approvedBy,
    actionComplete,
  } = columns;
  const ALL_COLUMNS_TABLE = [
    stt,
    createdAt,
    codeRecommend,
    provider,
    sumMoney,
    warehouseImport,
    typeRecommend,
    status,
    ...((canImportRequestViewCreatedByMe === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    createdBy
      ? [createdBy]
      : []),
  ];
  const PENDING_COLUMNS_TABLE = [
    actionEdit,
    createdAt,
    codeRecommend,
    provider,
    sumMoney,
    warehouseImport,
    typeRecommend,
    ...((canImportRequestViewCreatedByMe === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    actionAll,
  ];
  const REJECTED_COLUMNS_TABLE =
    canImportRequestUpdate === false && canImportRequestCancel === false
      ? [
          stt,
          createdAt,
          codeRecommend,
          provider,
          sumMoney,
          warehouseImport,
          typeRecommend,
          ...((canImportRequestViewCreatedByMe === false ||
            (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
          createdBy
            ? [createdBy]
            : []),
          ...((canImportRequestView === false ||
            (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
          approvedBy
            ? [approvedBy]
            : []),
        ]
      : [
          stt,
          createdAt,
          codeRecommend,
          provider,

          sumMoney,
          warehouseImport,
          typeRecommend,
          ...((canImportRequestViewCreatedByMe === false ||
            (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
          createdBy
            ? [createdBy]
            : []),
          ...((canImportRequestView === false ||
            (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
          approvedBy
            ? [approvedBy]
            : []),
          actionTabReject,
        ];
  const WAITING_BUY_COLUMNS_TABLE = [
    stt,
    createdAt,
    codeRecommend,
    provider,

    sumMoney,
    warehouseImport,
    typeRecommend,
    ...((canImportRequestViewCreatedByMe === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...((canImportRequestView === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
    ...(canImportRequestComplete && actionComplete ? [actionComplete] : []),
  ];
  const COMPLETE_COLUMNS_TABLE = [
    stt,
    createdAt,
    codeRecommend,
    provider,

    sumMoney,
    warehouseImport,
    typeRecommend,
    ...((canImportRequestViewCreatedByMe === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...((canImportRequestView === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
  ];
  const CANCELED_COLUMNS_TABLE = [
    stt,
    createdAt,
    codeRecommend,
    provider,
    sumMoney,
    warehouseImport,
    typeRecommend,
    ...((canImportRequestViewCreatedByMe === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    createdBy
      ? [createdBy]
      : []),
    ...((canImportRequestView === false ||
      (canImportRequestViewCreatedByMe === true && canImportRequestView === true)) &&
    approvedBy
      ? [approvedBy]
      : []),
  ];

  const renderColumns = (type) => {
    switch (type) {
      case RECOMMEND_IMPORT.ALL:
        return ALL_COLUMNS_TABLE;
      case RECOMMEND_IMPORT.WAITING_APPROVAL:
        return PENDING_COLUMNS_TABLE;
      case RECOMMEND_IMPORT.REJECTED:
        return REJECTED_COLUMNS_TABLE;
      case RECOMMEND_IMPORT.WAITING_FOR_BUYING:
        return WAITING_BUY_COLUMNS_TABLE;
      case RECOMMEND_IMPORT.COMPLETED:
        return COMPLETE_COLUMNS_TABLE;
      case RECOMMEND_IMPORT.CANCELED:
        return CANCELED_COLUMNS_TABLE;
      default:
        return;
    }
  };

  const handleChangeTable = (pagination) => {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params?.pagination, offset: (current - 1) * pageSize },
      sorts: params?.sorts,
    };
    setParams(newParams);
    const queryString = {
      ...newParams.filters,
      ...newParams.pagination,
      ...newParams.filters.timeRange,
    };
    buildQueryString({ params: { ...queryString } });
    setSelectedHightline(null);
    setSizeScrollPageAll({ x: 1800, y: MAX_SCREEN });
    setSelectedRow(null);
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const debouncedValue = useDebounce(searchTerm, 500);
  const paramsStatus = (status) => {
    return {
      filters: {
        statuses: status,
        query: "",
        createdByIDs:
          canImportRequestViewCreatedByMe === true && canImportRequestView === false
            ? params?.filters?.createdByIDs
            : canImportRequestViewCreatedByMe === false && canImportRequestView === false
            ? [""]
            : [],
        types: [IMPORT_REQUEST_TYPE.DOMESTIC, IMPORT_REQUEST_TYPE.OVERSEAS],
      },
    };
  };

  const { data: COUNT_ALL } = useGetCountStatusRequestImport(paramsStatus(undefined));
  const { data: COUNT_WAITING_APPROVAL } = useGetCountStatusRequestImport(
    paramsStatus(RECOMMEND_IMPORT.WAITING_APPROVAL)
  );
  const { data: COUNT_REJECTED } = useGetCountStatusRequestImport(paramsStatus(RECOMMEND_IMPORT.REJECTED));
  const { data: COUNT_WAITING_FOR_BUYING } = useGetCountStatusRequestImport(
    paramsStatus(RECOMMEND_IMPORT.WAITING_FOR_BUYING)
  );
  const { data: COUNT_COMPLETED } = useGetCountStatusRequestImport(paramsStatus(RECOMMEND_IMPORT.COMPLETED));
  const { data: COUNT_CANCELED } = useGetCountStatusRequestImport(paramsStatus(RECOMMEND_IMPORT.CANCELED));
  const dataCount = {
    ALL: COUNT_ALL,
    WAITING_APPROVAL: COUNT_WAITING_APPROVAL,
    REJECTED: COUNT_REJECTED,
    WAITING_FOR_BUYING: COUNT_WAITING_FOR_BUYING,
    COMPLETED: COUNT_COMPLETED,
    CANCELED: COUNT_CANCELED,
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...(key === "types"
          ? {
              ...params.filters,
              types: [IMPORT_REQUEST_TYPE.DOMESTIC, IMPORT_REQUEST_TYPE.OVERSEAS],
            }
          : { ...omit({ ...params.filters }, [key]) }),
      },
      sorts: params?.sorts,
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };
  useEffect(() => {
    buildQueryString({
      params: {
        offset: 0,
        limit: 10,
        statuses: params?.filters?.statuses,
        from: params?.filters?.timeRange?.from,
        to: params?.filters?.timeRange?.to,
        supplierIDs: params?.filters?.supplierIDs,
        updatedByIDs: params?.filters?.updatedByIDs,
        codes: params?.filters?.codes,
        warehouseIDs: params?.filters?.warehouseIDs,
        types: params?.filters?.types?.length === 2 ? [] : params?.filters?.types,
        createdByIDs: params?.filters?.createdByIDs,
      },
    });
  }, [params]);

  useEffect(() => {
    if ((data || [])?.length > 0) {
      handleRowClick(data && data[0], 0);
    } else {
      handleRowClick(null, 0);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue?.trim(),
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sorts: [],
    });
    setSelectedRow(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  useEffect(() => {
    setParams(() => {
      return {
        filters: {
          query: "",
          statuses: GET_QUERY?.statuses || [],
          timeRange: {
            from: undefined,
            to: undefined,
          },
          types: [IMPORT_REQUEST_TYPE.DOMESTIC, IMPORT_REQUEST_TYPE.OVERSEAS],
          warehouseIDs: [],
          supplierIDs: [],
          createdByIDs: [],
          updatedByIDs: [],
          codes: [],
        },
        pagination: {
          limit: 10,
          offset: GET_QUERY?.offset || 0,
        },
        sorts: [],
      };
    });
    buildQueryString({
      params: {},
    });
  }, []);
  return {
    renderColumns,
    statusTabs,
    isStatusAll,
    columns,
    setFilterTags,
    filterTags,
    GET_QUERY,
    data,
    params,
    handleChange,
    handleRowClick,
    selectedRow,
    showAllRows,
    handleToggleRows,
    selectedHightline,
    sizeScrollPageAll,
    paginationData,
    handleChangeTable,
    loading,
    handleSearch,
    dataCount,
    handleRemoveFilterTag,
    warehouseOptions,
    optionImportRequestType,
    staffs,
    sellersOptions,
    setParams,
    setSelectedHightline,
    setSizeScrollPageAll,
    setSelectedRow,
    canImportRequestCreate,
    canImportRequestViewCreatedByMe,
    canImportRequestView,
  };
}
