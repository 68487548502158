// client id, the client should not specify secret because of SPA app cannot keep the client secret securely.
export const OAUTH2_CLIENT_ID = process.env.REACT_APP_OAUTH2_CLIENT_ID;
// route handle by oidc library
export const OAUTH2_REDIRECT_URI =
  process.env.REACT_APP_OAUTH2_REDIRECT_URI || `http://localhost:3000/authentication/callback`;

// must contain openid for logout function working properly
export const OAUTH2_SCOPE = process.env.REACT_APP_OAUTH2_SCOPE || `openid offline_access`;

// public url of hydra/ auth server
export const OAUTH2_SERVER_URL = process.env.REACT_APP_OAUTH2_SERVER_URL || `https://auth.dev.honganh.vn`;

export const OAUTH2_ENABLED = process.env.REACT_APP_NEW_AUTH_FLOW === "true";
