import { CloseOutlined, EditFilled, QuestionCircleFilled } from "@ant-design/icons";
import { Col, Form, Row, Skeleton, Space, Typography } from "antd";
import CustomDrawer from "components/CustomDrawer";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { useGetPotentialCustomerDetail } from "hooks/potentialCustomer/query";
import { t } from "i18next";
import { useState } from "react";
import UpdatePotentialCustomer from "../UpdatePotentialCustomer";
import { CUSTOMER_STATUS } from "../constants";
import ActivityLogs from "./ActivityLogs";
import ButtonGroupCustomer from "./ButtonGroupCustomer";
import LinkButton from "./ButtonGroupCustomer/LinkButton";
import DetailMulti from "./DetailMulti";
import PotentialCustomerDetail from "./PotentialCustomerDetail";
import SelectCustomer from "./SelectCustomer";
import useFormLinkButton from "./useFormLinkButton";
import useFormUpdate from "./useFormUpdate";
import CustomModal from "components/CustomModal";

function PotentialCustomerDetailBlock({ visible, onClose, customerId, isAgent }) {
  const [isUpdate, setIsUpdate] = useState(false);
  const [openDrawerMulti, setOpenDrawerMulti] = useState(false);

  const { data: dataDetail, loading: loadingDetail } = useGetPotentialCustomerDetail({ id: customerId });

  const {
    canCustomerUpdate,
    isCustomerButton,
    dataSelected,
    formLinkButton,
    handleGetValueIsCustomerButton,
    handleOnClose,
    handleGetDataSelected,
    handleBack,
  } = useFormLinkButton({ onClose, setIsUpdate, dataDetail });

  const {
    formUpdate,
    handleValuesChange,
    typePersonal,
    formValues,
    loadingIdNumber,
    loadingTaxCode,
    isDuplicateIdNumber,
    isDuplicateTaxCode,
    handleSearchIdNumber,
    handleSearchTaxCode,
    loadingEmail,
    loadingTelephone,
    isDuplicateEmail,
    isDuplicateTelephone,
    handleSearchEmail,
    handleSearchTelephone,
    valueIdNumber,
    valueTaxCode,
    valueEmail,
    valueTelephone,
    handleSubmitDrawer,
    loadingUpdate,
    funcInitForm,
    handleGetType,
    visibleModalCloseDrawer,
    handleOkeModalCloseDrawer,
    handleOpenModalCloseDrawer,
    handleCancelModalCloseDrawer,
    handleResetAll,
    handleChangeCareStaff,
    handleChangeInChargeStaff,
    optionsCareStaffs,
    onLoadMoreCareStaff,
    hasMoreCareStaff,
    optionsInChargeStaff,
    onLoadMoreInChargeStaff,
    hasMoreInChargeStaff,
    handleSearchCareStaffs,
    handleSearchInChargeStaffs,
  } = useFormUpdate({ dataDetail, setIsUpdate, isUpdate });

  const handleSwitchUpdate = (value) => {
    setIsUpdate(value);
    handleResetAll();
    funcInitForm();
    handleGetType && handleGetType(dataDetail?.type);
  };

  const [customerIDDuplicate, setCustomerIDDuplicate] = useState();

  const { data: dataDetailMulti, loading: loadingDetailMulti } = useGetPotentialCustomerDetail({
    id: customerIDDuplicate,
  });

  const handleOpenDrawerMulti = (id) => {
    setCustomerIDDuplicate(id);
    setOpenDrawerMulti(true);
  };

  const handleCloseDrawerMulti = () => {
    setOpenDrawerMulti(false);
  };

  const handleCloseAll = () => {
    isUpdate ? handleOpenModalCloseDrawer() : handleOnClose();
  };

  return (
    <>
      <CustomDrawer
        title={t("potentialCustomer.createCustomer")}
        visible={visible}
        bodyStyle={{ background: "#F7F8FB" }}
        onClose={handleCloseAll}
        width={1100}
        maskClosable={false}
        mask={false}
        customTitle={
          isCustomerButton ? (
            <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
              <Col>
                <Typography.Text strong>{t("potentialCustomer.activityLogs.linkAccount")}</Typography.Text>
              </Col>
              <Col>
                <Space>
                  <PrimaryButtonOutLine onClick={handleBack} title={t("potentialCustomer.buttonGroup.back")} />
                  <LinkButton
                    form={formLinkButton}
                    customerId={dataDetail?.id}
                    dataSelected={dataSelected}
                    onCloseDrawer={handleCloseAll}
                  />
                </Space>
              </Col>
            </Row>
          ) : (
            <Skeleton active loading={loadingDetail} paragraph={{ rows: 0 }}>
              <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                <Col span={12} style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                  {!isUpdate && (
                    <div onClick={onClose} style={{ cursor: "pointer" }}>
                      <CloseOutlined />
                    </div>
                  )}
                  <div>
                    <Typography.Text strong>{dataDetail?.fullName}</Typography.Text>
                  </div>
                  {[CUSTOMER_STATUS.NEW, CUSTOMER_STATUS.TAKING_CARE].includes(dataDetail?.status) &&
                    canCustomerUpdate &&
                    !isUpdate && (
                      <div style={{ cursor: "pointer" }} onClick={() => handleSwitchUpdate(true)}>
                        <Space style={{ gap: "4px" }} direction="horizontal">
                          <EditFilled style={{ color: "#5B6673" }} />
                          <Typography.Text style={{ color: "#5B6673", textWrap: "nowrap" }}>
                            {t("potentialCustomer.edit")}
                          </Typography.Text>
                        </Space>
                      </div>
                    )}
                </Col>
                {canCustomerUpdate && (
                  <Col span={8} style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <ButtonGroupCustomer
                      data={dataDetail}
                      isAgent={isAgent}
                      onGetValueIsCustomerButton={handleGetValueIsCustomerButton}
                      isUpdate={isUpdate}
                      onSwitchUpdate={handleSwitchUpdate}
                      onSubmitUpdate={handleSubmitDrawer}
                      loadingUpdate={loadingUpdate}
                      handleOkeModalCloseDrawer={handleOkeModalCloseDrawer}
                    />
                  </Col>
                )}
              </Row>
            </Skeleton>
          )
        }
        multiLevel={openDrawerMulti}
        widthMulti={600}
        titleMulti={
          <Skeleton active loading={loadingDetail} paragraph={{ rows: 0 }}>
            <Space style={{ justifyContent: "space-between", alignItems: "center" }}>
              <div onClick={handleCloseDrawerMulti} style={{ cursor: "pointer" }}>
                <CloseOutlined />
              </div>
              <Typography.Text strong>
                {openDrawerMulti ? dataDetailMulti?.fullName : dataDetail?.fullName || "--"}
              </Typography.Text>
            </Space>
          </Skeleton>
        }
        {...{
          propsDrawerChildren: {
            bodyStyle: { background: "#F7F8FB" },
            visible: openDrawerMulti,
            onClose: handleCloseDrawerMulti,
            closable: false,
            title: (
              <Skeleton className="skeleton-detail" active loading={loadingDetail} paragraph={{ rows: 0 }}>
                <Typography.Text style={{ fontSize: "22px" }} strong>
                  {dataDetail?.fullName || "--"}
                </Typography.Text>
              </Skeleton>
            ),
            placement: "right",
          },
        }}
        contentMultiDrawer={
          <DetailMulti
            visible={openDrawerMulti}
            onClose={onClose}
            handleOpenDrawerMulti={handleOpenDrawerMulti}
            loading={loadingDetailMulti}
            data={dataDetailMulti}
          />
        }
        content={
          <Row style={{ justifyContent: "space-between" }}>
            <Col span={12}>
              <Space direction="vertical">
                {isCustomerButton && (
                  <Space direction="vertical" style={{ marginTop: "6px" }}>
                    <div>
                      <Typography.Text>{t("potentialCustomer.textLinkAccount")}</Typography.Text>
                      <br />
                      <Typography.Text type="danger">{t("potentialCustomer.textLinkAccount2")}</Typography.Text>
                    </div>
                    <Typography.Text style={{ fontSize: "16px" }} strong>
                      {dataDetail?.fullName}
                    </Typography.Text>
                  </Space>
                )}
                {isUpdate ? (
                  <Form
                    className={"custom-drawer-add-potential-customer"}
                    form={formUpdate}
                    colon={false}
                    layout="horizontal"
                    onValuesChange={handleValuesChange}
                  >
                    <UpdatePotentialCustomer
                      optionsCareStaffs={optionsCareStaffs}
                      onLoadMoreCareStaff={onLoadMoreCareStaff}
                      hasMoreCareStaff={hasMoreCareStaff}
                      optionsInChargeStaff={optionsInChargeStaff}
                      onLoadMoreInChargeStaff={onLoadMoreInChargeStaff}
                      hasMoreInChargeStaff={hasMoreInChargeStaff}
                      handleSearchCareStaffs={handleSearchCareStaffs}
                      handleSearchInChargeStaffs={handleSearchInChargeStaffs}
                      handleChangeCareStaff={handleChangeCareStaff}
                      handleChangeInChargeStaff={handleChangeInChargeStaff}
                      dataDetail={dataDetail}
                      form={formUpdate}
                      typePersonal={typePersonal}
                      formValues={formValues}
                      loadingIdNumber={loadingIdNumber}
                      loadingTaxCode={loadingTaxCode}
                      isDuplicateIdNumber={isDuplicateIdNumber}
                      isDuplicateTaxCode={isDuplicateTaxCode}
                      handleSearchIdNumber={handleSearchIdNumber}
                      handleSearchTaxCode={handleSearchTaxCode}
                      loadingEmail={loadingEmail}
                      loadingTelephone={loadingTelephone}
                      isDuplicateEmail={isDuplicateEmail}
                      isDuplicateTelephone={isDuplicateTelephone}
                      handleSearchEmail={handleSearchEmail}
                      handleSearchTelephone={handleSearchTelephone}
                      dataMulti={dataDetail}
                      valueIdNumber={valueIdNumber}
                      valueTaxCode={valueTaxCode}
                      valueEmail={valueEmail}
                      valueTelephone={valueTelephone}
                      handleOpenDrawerMulti={handleOpenDrawerMulti}
                    />
                  </Form>
                ) : (
                  <PotentialCustomerDetail
                    visible={visible}
                    onClose={onClose}
                    loadingDetail={loadingDetail}
                    dataDetail={dataDetail}
                    isCustomerButton={isCustomerButton}
                  />
                )}
              </Space>
            </Col>
            <Col span={12}>
              {isCustomerButton ? (
                <Form form={formLinkButton}>
                  <SelectCustomer
                    isCustomerButton={isCustomerButton}
                    onGetData={handleGetDataSelected}
                    dataSelected={dataSelected}
                    dataDetail={dataDetail}
                  />
                </Form>
              ) : (
                <ActivityLogs customerID={customerId} dataDetail={dataDetail} />
              )}
            </Col>
          </Row>
        }
      />
      <CustomModal
        footer={false}
        customIcon={
          <QuestionCircleFilled
            style={{
              fontSize: "50px",
              color: "#2246DC",
              padding: "20px",
              borderRadius: 50,
              background:
                "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
            }}
          />
        }
        message={t("potentialCustomer.update.cancelMessage")}
        visible={visibleModalCloseDrawer}
        onOke={() => handleOkeModalCloseDrawer({ resetDataDup: true })}
        onOpen={() => handleOpenModalCloseDrawer()}
        onCancel={() => handleCancelModalCloseDrawer({ resetDataDup: true })}
      />
    </>
  );
}

export default PotentialCustomerDetailBlock;
