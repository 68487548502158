import { Col, Row, Tooltip, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import { CUSTOMER_STATUS, potentialStatusLabel } from "features/PotentialCustomer/constants";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useGetPotentialCustomerLinkAble } from "hooks/potentialCustomer/query";
import { t } from "i18next";
import { compact } from "lodash";
import { useState } from "react";
import PotentialCustomerDetail from "../PotentialCustomerDetail";
import SellerDetail from "../SellerDetail";

const styleTitleTable = {
  textTransform: "uppercase",
  color: "#5B6673",
  fontWeight: "bold",
};

function SelectCustomer({ isCustomerButton, onGetData, dataSelected, dataDetail }) {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      statuses: ["NEW", "TAKING_CARE", "COMPLETE_CARING", "APPROVED_SELLER"],
      isValidate: false,
      IDFilter: {
        filterType: "EXCLUDE",
        IDs: dataDetail?.id,
      },
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const { data, onLoadMoreData, hasMoreData } = useGetPotentialCustomerLinkAble(params);
  const options = data?.map((item, index) => {
    const address = compact([
      item?.address,
      item?.potentialCustomer?.ward?.name || item?.seller?.ward?.name,
      item?.potentialCustomer?.district?.name || item?.seller?.district?.name,
      item?.potentialCustomer?.city?.name || item?.seller?.city?.name,
    ]).join(", ");
    return {
      value: item?.status === CUSTOMER_STATUS.APPROVED_SELLER ? item?.sellerID : item?.potentialCustomerID,
      label: (
        <Row key={item?.id}>
          <Col span={3}>
            <Typography.Text>{potentialStatusLabel?.[item.status]}</Typography.Text>
          </Col>
          <Col span={7}>
            <Tooltip title={item?.fullName?.length > 30 && item?.fullName}>
              <Typography.Link>
                {truncateAddDots(
                  item?.fullName || item?.potentialCustomer?.fullName || item?.seller?.fullName || "--",
                  30
                )}
              </Typography.Link>
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip title={item?.email?.length > 30 && item?.email}>
              <Typography.Text>
                {truncateAddDots(item?.email || item?.potentialCustomer?.email || item?.seller?.email || "--", 30)}
              </Typography.Text>
            </Tooltip>
          </Col>
          <Col span={3}>
            <Typography.Text>
              {item?.telephone || item?.potentialCustomer?.telephone || item?.seller?.telephone || "--"}
            </Typography.Text>
          </Col>
          <Col span={5}>
            <Tooltip title={address.length > 30 && address}>
              <Typography.Text>{truncateAddDots(address || "--", 30)}</Typography.Text>
            </Tooltip>
          </Col>
        </Row>
      ),
      labelProp: item?.potentialCustomer?.fullName || item.seller?.fullName,
      item: item,
    };
  });

  const handleSearch = (value) => {
    setParams({
      filters: { ...params.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSelect = (value, options) => {
    onGetData && onGetData(options?.item);
  };

  const SwitchSelectedUI = ({ status }) => {
    if (status === "APPROVED_SELLER") {
      return <SellerDetail dataDetail={dataSelected?.seller} />;
    } else {
      return (
        <PotentialCustomerDetail isCustomerButton={isCustomerButton} dataDetail={dataSelected?.potentialCustomer} />
      );
    }
  };

  return (
    <div style={{ paddingLeft: "8px" }}>
      <SelectLoadMore
        type={INPUT_TYPE.SELECT_LOAD_MORE}
        {...{
          formItemOptions: {
            name: "idSelected",
            required: false,
            label: <Typography.Text strong>{t("potentialCustomer.form.existedCustomer")}</Typography.Text>,
            rules: [
              {
                required: true,
                message: "Vui lòng không để trống trường thông tin này!",
              },
            ],
            labelCol: { span: 24 },
          },
          inputOptions: {
            placeholder: t("potentialCustomer.placeHolder.selectCustomer"),
            options: options,
            value: null,
            optionLabelProp: "labelProp",
            dropdownStyle: { padding: 0 },
            dropdownMatchSelectWidth: 1000,
            dropdownRender: (originNode) => {
              return (
                <>
                  <Row gutter={16} style={{ margin: 0, padding: "8px 0", background: "#F7F8FB" }}>
                    <Col span={3}>
                      <Typography.Text style={styleTitleTable}>{t("potentialCustomer.table.status")}</Typography.Text>
                    </Col>
                    <Col span={7}>
                      <Typography.Text style={styleTitleTable}>
                        {t("potentialCustomer.table.customerName")}
                      </Typography.Text>
                    </Col>
                    <Col span={6}>
                      <Typography.Text style={styleTitleTable}>{t("potentialCustomer.table.email")}</Typography.Text>
                    </Col>
                    <Col span={3}>
                      <Typography.Text style={styleTitleTable}>
                        {t("potentialCustomer.table.telephone")}
                      </Typography.Text>
                    </Col>
                    <Col span={5}>
                      <Typography.Text style={styleTitleTable}>{t("potentialCustomer.table.address")}</Typography.Text>
                    </Col>
                  </Row>
                  {originNode}
                </>
              );
            },
          },
        }}
        onLoadMore={onLoadMoreData}
        hasMoreData={hasMoreData}
        onSearch={handleSearch}
        onChange={handleSelect}
      />
      {dataSelected && <SwitchSelectedUI status={dataSelected?.status} />}
    </div>
  );
}

export default SelectCustomer;
