import { Col, Space, Typography } from "antd";

import { importRequestStatus, setColorStatusImportRequest } from "features/Purchase/constant";
import styles from "../../../index.module.scss";

function TitleCard({ title, value, status }) {
  return (
    <Col
      span={24}
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        width: "100%",
      }}
    >
      <Space>
        <Typography.Text className={styles["title-card"]}>{title}</Typography.Text>
        <Typography.Text style={{ color: "#2246DC" }} className={styles["title-card"]}>
          {value && `#${value || "---"}`}
        </Typography.Text>
      </Space>
      <span
        style={{
          color: setColorStatusImportRequest(status),
        }}
      >
        {importRequestStatus[status]}
      </span>
    </Col>
  );
}

const SubTitleCard = ({ subTitle }) => {
  return <Typography.Text className="title-sub-title-card">{subTitle}</Typography.Text>;
};

export { SubTitleCard, TitleCard };
