import { Col, Space, Typography } from "antd";

import { purchaseStatusLabel, setColorStatusPurchaseOrder } from "features/Purchase/constant";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import ModalTrackingPurchaseOrder from "../ModalTrackingPurchaseOrder";
import styles from "./index.module.scss";

function TitleCard({ value, dataReferences, status, dataDetail }) {
  const renderReferences = (dataReferences || [])?.map((item, index) => (
    <React.Fragment key={index}>
      <div>
        {item.serialImportNotesCode ? (
          <Link to={`/import-serial/detail/${item?.serialImportNotesId}`} target="_blank">
            <Typography.Link strong>{item?.serialImportNotesCode || "??"}</Typography.Link>
          </Link>
        ) : (
          <Link to={`/import-request/detail/${item?.importRequestId}`} target="_blank">
            <Typography.Link strong>{item?.importRequestCode || "??"}</Typography.Link>
          </Link>
        )}
        {index >= 0 && index < dataReferences?.length - 1 && `,`}
      </div>
    </React.Fragment>
  ));

  return (
    <Col
      span={24}
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Space>
        <Typography.Text className={styles["title-card"]}>{t("purchase.purchaseOrder")}</Typography.Text>
        <Typography.Link strong>{value && `${value || "---"}`}</Typography.Link>
        {dataReferences?.length > 0 && (
          <Typography.Text className={styles["title-card"]}>{t("purchase.Reference")}</Typography.Text>
        )}
        {renderReferences}
      </Space>
      <div style={{ textAlign: "right", lineHeight: "16px" }}>
        <span
          style={{
            color: setColorStatusPurchaseOrder(status),
            fontSize: "13px",
          }}
        >
          {purchaseStatusLabel[status]}
        </span>
        <div>
          <ModalTrackingPurchaseOrder data={dataDetail} />
        </div>
      </div>
    </Col>
  );
}

const SubTitleCard = ({ subTitle }) => {
  return <Typography.Text className="title-sub-title-card">{subTitle}</Typography.Text>;
};

export { SubTitleCard, TitleCard };
