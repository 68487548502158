import { Col, Row, Space } from "antd";
import CustomInputGroup from "components/CustomInputGroup";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { CURRENCY_INPUT, PAYMENT_TERMS, optionCurrency, optionPaymentRequest } from "features/Purchase/constant";
import { t } from "i18next";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";

function useSplitStepSecond({ form }) {
  const paymentTermsOptions = [
    {
      value: PAYMENT_TERMS.ADVANCE_PAYMENT,
      label: t("purchase.deposit"),
      disabled: true,
    },
    {
      value: PAYMENT_TERMS.CASH_ON_DELIVERY,
      label: <span style={{ textWrap: "nowrap" }}>{t("purchase.paymentOnDelivery")}</span>,
    },
    {
      value: PAYMENT_TERMS.DEFERRED_PAYMENT,
      label: t("purchase.payLater"),
    },
  ];

  const inputOptionsFirst = {
    placeholder: 0,
    maxLength: 11,
    min: 0,
  };

  const renderSummaryTotal = (items) => {
    var intoMoney = items?.reduce(function (total, current) {
      return total + (current?.intoMoney || 0);
    }, 0);

    var vatMoney = items?.reduce(function (total, current) {
      return total + (current?.vatMoney || 0);
    }, 0);

    return (
      <Col span={7} className="summary-total">
        <Row className="summary-total-items">
          <Col span={12}>Tổng số lượng</Col>
          <Col span={12} className="count-money">
            {items?.reduce(function (total, current) {
              return total + (current?.conditionQuantity || 0);
            }, 0)}
          </Col>
        </Row>
        <Row className="summary-total-items">
          <Col span={12}>Tổng tiền hàng</Col>
          <Col span={12} className="count-money">
            {items && items[0]?.currency === CURRENCY_INPUT.USD
              ? formatCurrencyDollar(intoMoney)
              : formatCurrency(intoMoney)}
          </Col>
        </Row>
        <Row className="summary-total-items">
          <Col span={12}>Tiền thuế GTGT</Col>
          <Col span={12} className="count-money">
            {items && items[0]?.currency === CURRENCY_INPUT.USD
              ? formatCurrencyDollar(vatMoney)
              : formatCurrency(vatMoney)}
          </Col>
        </Row>
        <Row className="summary-total-items">
          <Col span={12}>Tổng tiền thanh toán</Col>
          <Col span={12} className="count-money">
            {items && items[0]?.currency === CURRENCY_INPUT.USD
              ? formatCurrencyDollar(intoMoney + vatMoney)
              : formatCurrency(intoMoney + vatMoney)}
          </Col>
        </Row>
      </Col>
    );
  };

  const renderPaymentInfo = (data) => {
    return (
      <Col span={17} className="payment-info">
        <Row gutter={[16, 8]} style={{ display: "flex", justifyContent: "space-between" }}>
          <Col span={24} xl={12} style={{ maxWidth: 500 }}>
            <Space direction="vertical" style={{ width: "100%", justifyContent: "space-between" }}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                {...{
                  formItemOptions: {
                    label: <b>{t("purchase.paymentMethods")}</b>,
                    labelCol: { span: 24, offset: 0 },
                    name: "paymentMethods",
                    rules: [
                      {
                        required: true,
                        message: t("purchase.rules.selectPaymentMethod"),
                      },
                    ],
                  },
                  inputOptions: {
                    placeholder: t("purchase.placeHolder.selectPaymentMethod"),
                    mode: "multiple",
                    allowClear: true,
                    showSearch: true,
                    filterOption: false,
                    optionFilterProp: "label",
                    placement: "topLeft",
                    options: optionPaymentRequest,
                  },
                }}
              />
              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{
                  label: <b>{t("purchase.transferContent")}</b>,
                  labelCol: { span: 24, offset: 0 },
                  name: "transferNote",
                }}
                inputOptions={{
                  rows: 3,
                  placeholder: t("purchase.placeHolder.typeTransferContent"),
                }}
              />
            </Space>
          </Col>
          <Col span={24} xl={12} style={{ paddingLeft: "18px" }} className="paymentTerms">
            <Row
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                gap: 16,
                justifyContent: "space-between",
              }}
            >
              <Col>
                <FormInput
                  {...{
                    type: INPUT_TYPE.CHECK_BOX_GROUP,
                    formItemOptions: {
                      label: <b>{t("purchase.paymentPolicy")}</b>,
                      labelCol: { span: 24, offset: 0 },
                      name: "paymentTerms",
                      rules: [
                        {
                          required: true,
                          message: t("purchase.rules.paymentPolicy"),
                        },
                      ],
                    },
                    inputOptions: {
                      options: paymentTermsOptions,
                      style: {
                        display: "inline-flex",
                        flexDirection: "column",
                        gap: 40,
                        marginTop: 12,
                      },
                    },
                  }}
                />
              </Col>
              <Col
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  gap: 30,
                  marginTop: 20,
                }}
              >
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={true}
                  formItemOptionsFirst={{
                    name: "paymentDepositAmount",
                    rules: [
                      {
                        required: true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={!data?.paymentTerms?.includes(PAYMENT_TERMS.CASH_ON_DELIVERY)}
                  formItemOptionsFirst={{
                    name: "paymentOnDeliveryAmount",
                    rules: [
                      {
                        required: true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
                <CustomInputGroup
                  optionsSelect={optionCurrency}
                  inputOptionsFirst={inputOptionsFirst}
                  disabled={!data?.paymentTerms?.includes(PAYMENT_TERMS.DEFERRED_PAYMENT)}
                  formItemOptionsFirst={{
                    name: "paymentDebtAmount",
                    rules: [
                      {
                        required: true,
                        message: t("purchase.rules.pleaseType"),
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderFullOrder = () => {
    const formValues = form.getFieldsValue();
    return (
      <>
        {renderSummaryTotal(formValues?.remainPurchaseOrder?.items)}
        {renderPaymentInfo(formValues?.remainPurchaseOrder)}
      </>
    );
  };
  const renderExtraOrder = () => {
    const formValues = form.getFieldsValue();
    return (
      <>
        {renderSummaryTotal(formValues?.extraPurchaseOrder?.items)}
        {renderPaymentInfo(formValues?.extraPurchaseOrder)}
      </>
    );
  };
  return { renderFullOrder, renderExtraOrder };
}

export default useSplitStepSecond;
