import { gql } from "@apollo/client";

export const CONFIRM_REJECT_IMPORT_REQUEST = gql`
  mutation ComfirmReject($id: ID!, $request: ConfirmRejectImportRequestInput!) {
    importRequests {
      confirmReject(id: $id, request: $request)
    }
  }
`;

export const UPDATE_STATUS_IMPORT_REQUEST = gql`
  mutation UpdateStatusImportRequest($id: ID!, $actionType: ImportRequestChangeStatusActionType!) {
    importRequests {
      updateStatus(id: $id, actionType: $actionType)
    }
  }
`;

export const CREATE_IMPORT_REQUEST = gql`
  mutation createImportRequest($importRequest: CreateImportRequestInput) {
    importRequests {
      create(request: $importRequest) {
        id
        supplierID
        warehouseID
        rejectedReason
        code
        type
        status
        paymentTerms
        paymentMethods
        paymentNotes
        createdAt
        updatedAt
        updatedBy {
          id
          fullname
        }
        createdBy {
          id
          fullname
        }
        items {
          productID
          productName
          price
          currency
          isVat
          quantity
          warranty
          warrantyPeriod
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_IMPORT_REQUEST = gql`
  mutation updateImportRequest($id: ID!, $request: UpdateImportRequestInput!) {
    importRequests {
      update(id: $id, request: $request)
    }
  }
`;

export const CONFIRM_CANCEL_SALES_RETURN = gql`
  mutation ConfirmCancelSalesReturn($id: ID!) {
    importRequests {
      confirmCancel(id: $id)
    }
  }
`;

export const MANAGER_APPROVE_SALES_RETURN = gql`
  mutation ManagerApproveSalesReturn($id: ID!) {
    importRequests {
      managerApprove(id: $id)
    }
  }
`;

export const TECHNICAL_APPROVE_SALES_RETURN = gql`
  mutation TechnicalApproveSalesReturn($id: ID!) {
    importRequests {
      technicalApprove(id: $id)
    }
  }
`;

export const MANAGER_REJECT_SALES_RETURN = gql`
  mutation ManagerRejectSalesReturn($id: ID!, $request: ConfirmRejectImportRequestInput) {
    importRequests {
      managerReject(id: $id, request: $request)
    }
  }
`;

export const TECHNICAL_REJECT_SALES_RETURN = gql`
  mutation TechnicalRejectSalesReturn($id: ID!, $request: ConfirmRejectImportRequestInput!) {
    importRequests {
      technicalReject(id: $id, request: $request)
    }
  }
`;

export const CONFIRM_COMPLETE_SALES_RETURN = gql`
  mutation ConfirmCompleteSalesReturn($id: ID!) {
    importRequests {
      confirmComplete(id: $id)
    }
  }
`;
