import { Space } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import { t } from "i18next";

import "../../index.scss";

function CustomSelectDate({ options, index, isHiddenEnterSerial }) {
  return (
    <Space className={"custom-select-date"}>
      <div className="warrantyPeriodSupplier">
        <FormInput
          {...{
            type: INPUT_TYPE.NUMBER,
            formItemOptions: {
              name: [index, "warrantyPeriodSupplier"],
              rules: [
                {
                  required: true,
                  message: "Vui lòng nhập thời gian bảo hành",
                },
                {
                  pattern: REGEX.NUMBER,
                  message: t("serialIE.error.warrantyPer"),
                },
              ],
            },
            inputOptions: {
              maxLength: 3,
              min: 0,
              disabled: isHiddenEnterSerial,
            },
          }}
        />
      </div>
      <div className="warrantyUnit">
        <FormInput
          {...{
            type: INPUT_TYPE.SELECT,
            formItemOptions: {
              name: [index, "warrantyUnit"],
            },
            inputOptions: {
              options: options,
              disabled: isHiddenEnterSerial,
            },
          }}
        />
      </div>
    </Space>
  );
}

export default CustomSelectDate;
