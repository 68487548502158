/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleFilled } from "@ant-design/icons";
import { Col, Form, Row, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import { CURRENCY_INPUT, optionCurrency, optionwarrantyPeriod } from "features/Purchase/constant";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";

const PopupAddProduct = ({ handleAddProduct, record }) => {
  const [form] = useForm();
  const lableCol = { span: 24, offset: 0 };

  const [visible, setVisible] = useState(false);
  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("VND");

  const handleQuantity = (value) => {
    setQuantity(parseFloat(value));
  };

  const handlePrice = (value) => {
    setPrice(parseFloat(value));
  };

  const handleCurrency = (value) => {
    setCurrency(value);
  };

  const initialValues = {
    productID: null,
    productName: null,
    price: null,
    currency: "VND",
    warranty: null,
    warrantyPeriod: "MONTH",
    isVat: false,
    creditDays: null,
    paymentNote: null,
  };

  useEffect(() => {
    setQuantity(0);
    setPrice(0);
    setCurrency("VND");
    form.setFieldsValue({ ...initialValues });
  }, [JSON.stringify(visible)]);
  const currentQuantity = quantity || 0;
  const currentPrice = price || 0;
  const currentCurrency = currency || CURRENCY_INPUT?.VND;
  const tongthanhtien = currentQuantity && currentPrice ? currentQuantity * currentPrice : 0;
  return (
    <div>
      <Space>
        <CustomModal
          footer={false}
          width="55%"
          title={
            <Typography.Text strong style={{ fontSize: "20px" }}>
              {t("warehouse.create.RecommendAddProduct")}
            </Typography.Text>
          }
          okeButtonLabel={t("warehouse.create.create")}
          customComponent={
            <Row gutter={[8]}>
              <Col>
                <PlusCircleFilled style={{ fontSize: "30px" }} />
              </Col>
              <Col>{t("warehouse.create.createProduct")}</Col>
            </Row>
          }
          onOke={() => {
            form.validateFields().then((values) => {
              form.resetFields();
              handleAddProduct(values);
              setVisible(false);
            });
          }}
          onOpen={openModal}
          onCancel={closeModal}
          visible={visible}
        >
          <Form form={form} initialValues={initialValues} layout="vertical" name="form_in_modal">
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <FormInput
                  {...{
                    type: INPUT_TYPE.INPUT,
                    formItemOptions: {
                      label: <Typography.Text strong>{t("warehouse.create.nameProduct")}</Typography.Text>,
                      name: "name",
                      labelCol: lableCol,
                      rules: [
                        { required: true, message: t("warehouse.create.relueschosseNameProduct") },
                        {
                          pattern: REGEX.TEXT_TRIM_SPACE,
                          message: t("supplier.placeholderInput.whiteSpace"),
                        },
                      ],
                    },
                    inputOptions: {
                      maxLength: 255,
                      min: 0,
                      placeholder: t("product.productForm.placeholderProductName"),
                    },
                  }}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={8}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.CURRENCY_INPUT,
                        formItemOptions: {
                          label: <Typography.Text strong>{t("warehouse.create.Quatity")}</Typography.Text>,
                          name: "quantity",
                          labelCol: lableCol,
                          rules: [
                            { required: true, message: t("warehouse.create.relueschosseQuantity") },
                            {
                              pattern: REGEX.NUMBER,
                            },
                          ],
                        },
                        inputOptions: {
                          maxLength: 5,
                          min: 0,
                          placeholder: "0",
                          onChange: (e) => handleQuantity(e),
                        },
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={12}>
                        <Typography.Text strong>{t("warehouse.create.unitPrice")}</Typography.Text>
                      </Col>
                      <Col span={12} className="form-input-checkbox-add-product" style={{ textAlign: "right" }}>
                        <FormInput
                          type={INPUT_TYPE.CHECK_BOX}
                          formItemOptions={{
                            name: "isVat",
                            valuePropName: "checked",
                          }}
                          inputChildren={t("warehouse.create.exportVAT")}
                        />
                      </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="warrantyPeriodSupplier" style={{ width: "-webkit-fill-available" }}>
                        <FormInput
                          {...{
                            type: INPUT_TYPE.CURRENCY_INPUT,
                            formItemOptions: {
                              name: ["price"],
                              rules: [
                                {
                                  pattern: REGEX.NUMBER,
                                  message: t("serialIE.error.serialItems"),
                                },
                              ],
                            },
                            inputOptions: {
                              maxLength: 11,
                              min: 0,
                              className: "form-text-center form-border-radius-right",
                              onChange: (e) => handlePrice(e),
                              placeholder: "0",
                            },
                          }}
                        />
                      </div>
                      <div className="warrantyUnit">
                        <FormInput
                          {...{
                            type: INPUT_TYPE.SELECT,
                            formItemOptions: {
                              name: ["currency"],
                              rules: [{ required: true, message: t("warehouse.create.pleaseEnter") }],
                            },
                            inputOptions: {
                              options: optionCurrency,
                              style: { width: "100px" },
                              onChange: (e) => handleCurrency(e),
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <Row gutter={[16, 8]}>
                      <Col span={14}>
                        <Typography.Text strong>{t("warehouse.create.money")}</Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]} style={{ lineHeight: "35px" }}>
                      <Col span={14}>
                        <Typography.Link strong>
                          {currentCurrency === CURRENCY_INPUT?.VND
                            ? formatCurrency(tongthanhtien)
                            : formatCurrencyDollar(tongthanhtien)}
                        </Typography.Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={8}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.NUMBER,
                        formItemOptions: {
                          label: <Typography.Text strong>{t("warehouse.create.countdayDebt")}</Typography.Text>,
                          name: "creditDays",
                          labelCol: lableCol,
                          rules: [
                            {
                              pattern: REGEX.NUMBER,
                            },
                          ],
                        },
                        inputOptions: {
                          maxLength: 3,
                          min: 0,
                          className: "form-text-center",
                          placeholder: "0",
                        },
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={24}>
                        <Typography.Text strong>{t("warehouse.create.timeWarranty")}</Typography.Text>
                        <div style={{ display: "flex" }}>
                          <div className="warrantyPeriodSupplier" style={{ width: "-webkit-fill-available" }}>
                            <FormInput
                              {...{
                                type: INPUT_TYPE.NUMBER,
                                formItemOptions: {
                                  name: ["warranty"],
                                },
                                inputOptions: {
                                  maxLength: 3,
                                  min: 0,
                                  placeholder: "0",
                                },
                              }}
                            />
                          </div>
                          <div className="warrantyUnit">
                            <FormInput
                              {...{
                                type: INPUT_TYPE.SELECT,
                                formItemOptions: {
                                  name: ["warrantyPeriod"],
                                  rules: [{ required: true, message: t("warehouse.create.pleaseEnter") }],
                                },
                                inputOptions: {
                                  options: optionwarrantyPeriod,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row gutter={[16, 8]}>
                      <Col span={24} className="date-picker-form-add-product">
                        <FormInput
                          {...{
                            type: INPUT_TYPE.DATE_PICKER,
                            formItemOptions: {
                              label: (
                                <Typography.Text strong>{t("warehouse.create.EstimatedDelivery")}</Typography.Text>
                              ),
                              name: "expectedDeliveryTime",
                              labelCol: lableCol,
                            },
                            inputOptions: {
                              showTime: true,
                              placeholder: t("warrantyRequest.create.placeHolder.date"),
                              format: "YYYY-MM-DD HH:mm:ss",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <FormInput
                  {...{
                    type: INPUT_TYPE.TEXT_AREA,
                    formItemOptions: {
                      label: <Typography.Text strong>{t("warehouse.create.countPayment")}</Typography.Text>,
                      name: "paymentNote",
                      labelCol: { span: 48, offset: 0 },
                    },
                    inputOptions: {
                      placeholder: t("warehouse.create.detail"),
                    },
                  }}
                />
              </Col>
            </Row>
          </Form>
        </CustomModal>
      </Space>
    </div>
  );
};
export default PopupAddProduct;
