import React from "react";
import { Form, Select } from "antd";
import styles from "./index.module.scss";
import useChartSelectUser from "./useChartSelectUser";

const ChartSelectUser = ({ reportPermission }) => {
  const { filteredTypeOptions } = useChartSelectUser(reportPermission);

  return (
    <div className={styles["container"]}>
      <Form.Item name="type">
        <Select options={filteredTypeOptions} />
      </Form.Item>
    </div>
  );
};

export default ChartSelectUser;
