import React from "react";
import Spinner from "components/Spinner";
import { useSellerPermissions } from "hooks/seller";
import { useGetUserPermissions } from "hooks/user/user";
import Page403 from "pages/PageError/403";
import { CreateSellerForm } from "../Create/components/CreateSellerForm";
import { useSupplierPermissions } from "hooks/supplier";

function SellerUpdate() {
  const { canUpdate } = useSellerPermissions();
  const { canUpdateSupplier } = useSupplierPermissions();
  const { loading: loadUserPermissions } = useGetUserPermissions();

  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canUpdate || canUpdateSupplier ? (
    <CreateSellerForm isUpdate={true} />
  ) : (
    <Page403 />
  );
}

export default SellerUpdate;
