import { Tooltip, Typography } from "antd";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import OrderLink from "components/CustomRedirectLink/OrderLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import TextAlignLeft from "components/TextAlignLeft";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import useDebounce from "hooks/useDebounce";
import { useGetWarranty } from "hooks/warranty";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import {
  WARRANTY_STATUS,
  WARRANTY_TYPE,
  warrantyStatusOptions,
  warrantyTypeLabel,
  warrantyTypeOptions,
} from "../constant";
import { renderWarrantyStatusLabel } from "../utlis";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";

const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

const GET_QUERY = getQuery();

const useWarrantyList = () => {
  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);

  const debouncedValue = useDebounce(searchTerm);

  const countSelectedRowKeys = selectedRowKeys?.length ? selectedRowKeys?.length : 0;

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || null,
      status: GET_QUERY.status || [],
      types: GET_QUERY.types || [],
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: GET_QUERY.limit || 10,
    },
    sort: [],
  });

  const { data, paginationData, loading } = useGetWarranty(params);

  const all = {
    createdAt: {
      title: <span>{t("warranty.warrantyList.createAtTitle")}</span>,
      sorter: true,
      showSorterTooltip: false,
      width: "5%",
      align: "center",
      render: (text, record, index) => formatDateTime(record?.createdAt),
    },
    detailLink: {
      title: t("warranty.warrantyList.warrantyCode"),
      render: (text, record, index) => <TextAlignLeft value={<WarrantyDetailLink warranty={record} />} />,
      width: "12%",
    },
    serial: {
      title: t("warranty.warrantyList.SERIAL/IMEITitle"),
      render: (text, record, index) => <TextAlignLeft value={truncateAddDots(record?.serialImei)} />,
    },
    productLink: {
      title: t("warranty.warrantyList.productTitle"),
      width: "10%",
      render: (text, record, index) => {
        const product = record?.product;
        return product?.id || product?.code ? (
          <Link to={`/product/detail/${product?.id}`} target="_blank">
            <Typography.Link strong>{product?.code}</Typography.Link>
          </Link>
        ) : (
          "--"
        );
      },
    },
    productName: {
      title: t("warranty.warrantyList.nameProductTitle"),
      width: "10%",
      render: (text, record, index) => {
        const productName = record?.product?.name || record?.productName;
        return <CustomTextHiddenLine text={productName} />;
      },
    },

    orderLink: {
      title: t("warranty.warrantyList.orderCodeTitle"),
      width: "10%",
      render: (text, record, index) => <OrderLink order={record} />,
    },
    sellerName: {
      title: t("warranty.warrantyList.nameAgentTitle"),
      render: (text, record, index) => {
        const sellerName = record?.seller?.fullName || record?.customerName;
        return <CustomTextHiddenLine text={sellerName} />;
      },
    },
    providerName: {
      title: t("warranty.warrantyList.provider"),
      render: (text, record, index) => {
        return <CustomTextHiddenLine text={record?.provider?.fullName || record?.supplierName} />;
      },
    },
    warrantyType: {
      title: t("warranty.warrantyList.saleWarrantyTitle"),
      width: "7%",
      render: (text, record, index) => {
        const CUSTOM = record?.warrantyType === WARRANTY_TYPE.CUSTOM;
        return !CUSTOM ? (
          warrantyTypeLabel?.[record?.warrantyType] || "--"
        ) : (
          <Typography.Text>
            {record?.warrantyOnSale || "--"} {convertUnit(record?.warrantyDateUnit)}
          </Typography.Text>
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => handleFilter(value, "types")} type="checkbox" options={warrantyTypeOptions} />
      ),
    },
    status: {
      title: t("warranty.warrantyList.statusTitle"),
      render: (text, record, index) => renderWarrantyStatusLabel[`${record?.status}`],
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter(value, "status")}
          type="checkbox"
          options={warrantyStatusOptions}
        />
      ),
    },
  };

  const {
    createdAt,
    detailLink,
    serial,
    productLink,
    productName,
    orderLink,
    sellerName,
    warrantyType,
    status,
    providerName,
  } = all;

  const columns = [
    createdAt,
    detailLink,
    serial,
    productLink,
    productName,
    orderLink,
    sellerName,
    providerName,
    warrantyType,
    status,
  ];

  const columnsPopup = [detailLink, serial, productLink, productName, sellerName, providerName, status];

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sort: sorter?.order ? [{ by: "CREATED_AT", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (value, objKey) => {
    const newParams = {
      ...params,
      filters: { ...params?.filters, [`${objKey}`]: value },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowKeys,
    getCheckboxProps: (record) => {
      return {
        disabled: record?.status === WARRANTY_STATUS.EXPIRED,
        name: record?.name,
      };
    },
  };

  const handleChange = (e) => {
    setIsFilterSelected && setIsFilterSelected(e.target.value);
  };

  const handleSelectedData = () => {
    history.push({
      pathname: "/warranty-request/create",
      state: {
        ownedSerials: selectedRows,
      },
    });
  };

  const onChangeParams = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: { ...newParams?.filters, ...newParams?.pagination },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    const newParams = {
      ...params,
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
  }, []);

  return {
    columns,
    rowSelection,
    countSelectedRowKeys: selectedRowKeys?.length ? selectedRowKeys?.length : 0,
    handleChangeTable,
    handleSearch,
    paginationData,
    data,
    loading,
    selectedProduct: { isFilterSelected, selectedRows, selectedRowKeys },
    setIsFilterSelected,
    filterItemsTypeOptions,
    handleChange,
    handleSelectedData,
    columnsPopup,
    params,
    setParams,
    onChangeParams,
  };
};

export default useWarrantyList;
