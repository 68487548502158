import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Divider, Form, Input, Row, Space, Typography } from "antd";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { CONDITION_PRODUCT_TYPE, OPTIONS_TYPE_LIMIT, TYPE_LIMIT } from "features/Campaign/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";
import GiftCol from "../GiftCol";
import ProductCol from "../ProductCol";

const { Panel } = Collapse;

function ComboCollapse({
  indexTierComboGift,
  remove,
  form,
  promotionCampaignTiers,
  isUpdate,
  fields,
  indexComboGift,
  dataTiersServer,
  valuesForm,
  onGetDataTiersServer,
}) {
  const dataPromotionCampaignTiersFromIndex =
    valuesForm?.promotionCampaignTierGroupsGift?.[indexComboGift]?.promotionCampaignTiers[indexTierComboGift];

  const typeLimit = dataPromotionCampaignTiersFromIndex?.limitType;

  return (
    <Col span={24}>
      <Collapse defaultActiveKey={["1"]} className="collapse-nested" expandIconPosition="left">
        <Panel
          header={
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {t("campaign.combo.preferentialLevel")} {indexTierComboGift + 1}
            </Typography.Text>
          }
          extra={
            fields?.length !== 1 && (
              <CustomModal
                {...{
                  message: t("campaign.combo.errorMessage.removeComboCollapse"),
                  onOke: (event) => {
                    remove();
                  },
                  footer: false,
                  icon: Img.DeleteIcon,
                  customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
                }}
              />
            )
          }
          key="1"
        >
          <Row style={{ alignItems: "center" }}>
            <Col style={{ margin: "14px" }} span={24}>
              <Divider orientation="left" plain>
                {t("campaign.combo.comboCondition")}
              </Divider>
              <Space>
                <Typography.Text className="character-required">
                  {t("campaign.combo.maxNumberOfComboPerDealer")}
                </Typography.Text>
                <Form.Item style={{ margin: 0 }}>
                  <Input.Group compact style={{ display: "flex" }}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.SELECT,
                        formItemOptions: {
                          noStyle: true,
                          name: [indexTierComboGift, "limitType"],
                        },
                        inputOptions: {
                          options: OPTIONS_TYPE_LIMIT,
                        },
                      }}
                    />
                    <FormInput
                      type={INPUT_TYPE.NUMBER}
                      {...{
                        formItemOptions: {
                          noStyle: true,
                          name: [indexTierComboGift, "maxQuantityPerSeller"],
                          rules: [
                            {
                              required: typeLimit !== TYPE_LIMIT.NO_LIMIT,
                              message: t("campaign.combo.errorMessage.maxNumberOfComboPerDealer"),
                            },
                            {
                              type: "number",
                              min: 1,
                              message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                            },
                            validator({
                              type: "number",
                            }),
                          ],
                        },
                        inputOptions: {
                          placeholder: t("campaign.combo.placeholder.type"),
                          disabled: typeLimit === TYPE_LIMIT.NO_LIMIT,
                        },
                      }}
                    />
                  </Input.Group>
                </Form.Item>
              </Space>
            </Col>
            <Col span={24}>
              <Card style={{ margin: "14px" }}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Divider
                      orientation="left"
                      plain
                      style={{ margin: 0 }}
                      children={<Typography.Text>{t("campaign.combo.chooseProductAndQtyCombo")}</Typography.Text>}
                    />
                  </Col>
                  <Col span={24}>
                    <Form.List name={[indexTierComboGift, "conditionProducts"]}>
                      {(fields, { add, remove }) => {
                        return (
                          <Row>
                            {fields.map((field, index) => (
                              <Col span={24} key={field?.key}>
                                <ProductCol
                                  form={form}
                                  indexPromotionCampaignTiers={indexTierComboGift}
                                  indexConditionProductGroups={index}
                                  remove={() => {
                                    remove(field?.name);
                                    onGetDataTiersServer &&
                                      onGetDataTiersServer(valuesForm?.promotionCampaignTierGroupsGift);
                                  }}
                                  promotionCampaignTiers={promotionCampaignTiers}
                                  isUpdate={isUpdate}
                                  fields={fields}
                                  indexComboGift={indexComboGift}
                                  dataTiersServer={dataTiersServer}
                                  valuesForm={valuesForm}
                                />
                              </Col>
                            ))}
                            {fields?.length < 10 && (
                              <Col span={24}>
                                <Typography.Link
                                  underline
                                  onClick={() => {
                                    add({
                                      type: CONDITION_PRODUCT_TYPE.CONDITION_PRODUCT,
                                      quantity: null,
                                      conditionPromotionProducts: [],
                                    });
                                    onGetDataTiersServer &&
                                      onGetDataTiersServer(valuesForm?.promotionCampaignTierGroupsGift);
                                  }}
                                >
                                  <PlusOutlined /> {t("campaign.combo.addProduct")}
                                </Typography.Link>
                              </Col>
                            )}
                          </Row>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{ margin: "14px" }}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Divider
                      orientation="left"
                      plain
                      style={{ margin: 0 }}
                      children={<Typography.Text>{t("campaign.combo.chooseGiftAndQtyGift")}</Typography.Text>}
                    />
                  </Col>
                  <Col span={24}>
                    <Form.List name={[indexTierComboGift, "conditionGifts"]}>
                      {(fields, { add, remove }) => {
                        return (
                          <Row>
                            {fields.map((field, index) => (
                              <Col span={24} key={field?.key}>
                                <GiftCol
                                  form={form}
                                  indexPromotionCampaignTiers={indexTierComboGift}
                                  indexConditionProductGroups={index}
                                  remove={() => {
                                    remove(field?.name);
                                    onGetDataTiersServer &&
                                      onGetDataTiersServer(valuesForm?.promotionCampaignTierGroupsGift);
                                  }}
                                  promotionCampaignTiers={promotionCampaignTiers}
                                  isUpdate={isUpdate}
                                  fieldName={field.name}
                                  fields={fields}
                                  indexComboGift={indexComboGift}
                                  dataTiersServer={dataTiersServer}
                                  valuesForm={valuesForm}
                                />
                              </Col>
                            ))}
                            {fields?.length < 2 && (
                              <Col span={24}>
                                <Typography.Link
                                  underline
                                  onClick={() =>
                                    add({
                                      type: CONDITION_PRODUCT_TYPE.GIFT_PRODUCT,
                                      quantity: null,
                                      conditionPromotionProducts: [],
                                    })
                                  }
                                >
                                  <PlusOutlined /> {t("campaign.combo.addProductGift")}
                                </Typography.Link>
                              </Col>
                            )}
                          </Row>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Col>
  );
}

export default ComboCollapse;
