import { Button, Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import ListByExportSerial from "./ListByExportSerial";
import ListBySerial from "./ListBySerial";
import useList from "./useList";

function ExportSerial() {
  const { handleChangeTab, activeKeyMain } = useList();
  const history = useHistory();

  const tabList = [
    {
      key: "listBySerial",
      tab: t("serialIE.bySerial"),
    },
    {
      key: "listByExportSerial",
      tab: t("serialIE.ListByExportSerial"),
    },
  ];

  const contentList = {
    [`listBySerial`]: <ListBySerial />,
    [`listByExportSerial`]: <ListByExportSerial />,
  };

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("serialIE.listExportSerial")}
          routes={[
            { path: "/import-serial", name: t("serialIE.serial") },
            { path: "/", name: t("serialIE.exportBySerial") },
          ]}
        />
      </Col>
      <Col span={24}>
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
          tabProps={{
            tabBarExtraContent: (
              <Button
                onClick={() => {
                  history.push({
                    pathname: "/export-serial/create",
                  });
                }}
                type="primary"
              >
                {t("serialIE.create")}
              </Button>
            ),
          }}
        />
      </Col>
    </Row>
  );
}

export default ExportSerial;
