import { Col, Form, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { t } from "i18next";
import { CANCEL_ORDER_REASON } from "pages/App/Order/conts";
import { useState } from "react";
import RadioGroupFormCustom from "../RadioGroupFormCustom";
import { notify } from "utils/helperFuncs";
import { useCancelOrder } from "hooks/order";

const ButtonCancelOrder = ({ data }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { handleCancelOrder } = useCancelOrder();

  const cancelOptions = [
    { label: t("order.cancelOrder.lackOfStock"), value: CANCEL_ORDER_REASON.LACK_OF_STOCK },
    { label: t("order.cancelOrder.outOfDebt"), value: CANCEL_ORDER_REASON.OUT_OF_DEBT },
    { label: "Không nhập được hàng", value: CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER },
    { label: t("order.cancelOrder.cannotBeSoldAtThisPrice"), value: CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE },
    { label: t("bank_transfer.order.overdue_order_payment"), value: CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT },
    {
      label: "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
      value: CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION,
    },
    {
      label: "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng, ...)",
      value: CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER,
    },
    {
      label: "Khách hàng không có nhu cầu mua nữa",
      value: CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY,
    },
    { label: t("order.cancelOrder.other"), value: CANCEL_ORDER_REASON.OTHER },
  ];

  const cancelReason = {
    [`${CANCEL_ORDER_REASON.LACK_OF_STOCK}`]: t("order.cancelOrder.lackOfStock"),
    [`${CANCEL_ORDER_REASON.OUT_OF_DEBT}`]: t("order.cancelOrder.outOfDebt"),
    [`${CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER}`]: "Không nhập được hàng",
    [`${CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE}`]: t("order.cancelOrder.cannotBeSoldAtThisPrice"),
    [`${CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT}`]: t("bank_transfer.order.overdue_order_payment"),
    [`${CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION}`]:
      "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
    [`${CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER}`]:
      "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng,...)",
    [`${CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY}`]: "Khách hàng không có nhu cầu mua nữa",
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const onSubmit = async () =>
    await form.validateFields().then(async (values) => {
      const params = {
        orderIDs: data?.orderID,
        reason: values.reason === CANCEL_ORDER_REASON.OTHER ? values.freeText : cancelReason[`${values.reason}`],
      };
      handleCancelOrder(params)
        .then(() => {
          notify.success({
            message: "Huỷ đơn thành công",
          });
          setVisible(false);
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    });

  return (
    <CustomModal
      footer={false}
      centered={true}
      closable={false}
      title={<Typography.Title level={4}>{t("order.cancelOrder.tittle")}</Typography.Title>}
      customComponent={
        <PrimaryButtonOutLine
          primaryColor="#D2434D"
          title={t("order.orderDetail.cancelBtn")}
          onClick={handleOpenModal}
        />
      }
      onOke={onSubmit}
      visible={visible}
      onCancel={closeModal}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space style={{ width: "100%", justifyContent: "left" }}>
            <Typography.Text type="warning">{t("order.cancelOrder.warningMessage")}</Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <RadioGroupFormCustom form={form} radioGroupOptions={cancelOptions} />
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ButtonCancelOrder;
