import { Image, Typography } from "antd";
import Card from "features/Cart/components/Card";
import { t } from "i18next";

const ImageLibrary = ({ dataImageLibrary }) => {
  const { fileURLs } = dataImageLibrary;

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "#052987" }}>
          {t("order.orderDetail.photoDeliver")}
        </Typography.Text>
      }
    >
      <Image.PreviewGroup>
        {fileURLs?.map((item, index) => (
          <span style={{ marginRight: "15px" }}>
            <Image key={index} width={100} height={100} src={item} />
          </span>
        ))}
      </Image.PreviewGroup>
    </Card>
  );
};

export default ImageLibrary;
