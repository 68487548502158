import CustomTags from "components/CustomTags";
import {
  FILTER_TYPE,
  PRIORITY_CUSTOMER,
  dataSourceFilterOptions,
  priorityCustomerOptions,
  sellerTypeOptions,
} from "features/PotentialCustomer/constants";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { useGetCreatedByWithIds } from "hooks/order";
import { useGetStaffWithIds } from "hooks/potentialCustomer/query";
import { t } from "i18next";
import { getQuery } from "utils/helperFuncs";

export function useToolbar({ params, onChangeParams }) {
  const GET_QUERY = getQuery();

  const handleOnClose = (key) => {
    switch (key) {
      case "priorityFilter":
      case "inChargeStaffIDFilter":
      case "careStaffIDFilter":
        return (
          onChangeParams &&
          onChangeParams({
            [`${key}`]: {
              filterType: FILTER_TYPE.ALL,
            },
          })
        );
      default:
        return onChangeParams && onChangeParams({ [`${key}`]: [] });
    }
  };

  const { data: optionsCreatedBy } = useGetCreatedByWithIds({
    ids: GET_QUERY.createdByIDs ? GET_QUERY.createdByIDs : undefined,
  });
  const { data: optionsInChargeStaff } = useGetStaffWithIds({
    ids: GET_QUERY.inChargeStaffIDFilter ? GET_QUERY.inChargeStaffIDFilter : undefined,
  });
  const { data: optionsCareStaffs } = useGetStaffWithIds({
    ids: GET_QUERY.careStaffIDFilter ? GET_QUERY.careStaffIDFilter : undefined,
  });

  const convertOptions = (value) => {
    return value?.map((item) => ({ label: item.fullname, value: item.id }));
  };

  const allTag = [
    {
      title: t("potentialCustomer.table.customerType"),
      options: sellerTypeOptions,
      selected: params?.filters?.types,
      key: "types",
    },
    {
      title: t("potentialCustomer.table.priority"),
      options: priorityCustomerOptions,
      selected: params?.filters?.priorityFilter?.priorities,
      key: "priorityFilter",
    },
    {
      title: t("potentialCustomer.table.caringStaff"),
      options:
        convertOptions(optionsCareStaffs)?.concat([
          { label: "Không có nhân viên chăm sóc", value: PRIORITY_CUSTOMER.UNCATEGORIZED },
        ]) || [],
      selected: params?.filters?.careStaffIDFilter?.careStaffIDs,
      key: "careStaffIDFilter",
    },
    {
      title: t("potentialCustomer.table.staffInCharge"),
      options:
        convertOptions(optionsInChargeStaff)?.concat([
          { label: "Không có nhân viên phụ trách", value: PRIORITY_CUSTOMER.UNCATEGORIZED },
        ]) || [],
      selected: params?.filters?.inChargeStaffIDFilter?.inChargeStaffIDs,
      key: "inChargeStaffIDFilter",
    },
    {
      title: t("potentialCustomer.table.createdBy"),
      options: optionsCreatedBy || [],
      selected: params?.filters?.createdByIDs,
      key: "createdByIDs",
    },
    {
      title: t("potentialCustomer.table.dataSource"),
      options: dataSourceFilterOptions || [],
      selected: params?.filters?.dataSources,
      key: "dataSources",
    },
  ];

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => truncateAddDots(item?.label, 50)).join(", ")}
      </div>
    );
  };

  const renderTags = allTag
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return {
    renderTags,
  };
}
