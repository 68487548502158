import CustomModal from "components/CustomModal";
import React from "react";

const CustomModalUpdateStatusCategory = ({ modalAlert }) => {
  const { switchClose, title, onOk, onCancel, hideConfirmButton } = modalAlert;

  return (
    switchClose && (
      <CustomModal
        selfVisibleControlled={false}
        hideConfirmButton={hideConfirmButton}
        closable={false}
        centered={true}
        footer={false}
        title={title}
        onCancel={onCancel}
        onOke={onOk}
        isBlockCloseOnOke
        switchClose={switchClose}
      ></CustomModal>
    )
  );
};

export default CustomModalUpdateStatusCategory;
