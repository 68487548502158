import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Col, Typography } from "antd";
import { PURCHASE_STATUS, renderCauseReject } from "features/Purchase/constant";

function AlertReason({ data }) {
  const renderAlert = (title, reason) => {
    return (
      <Alert
        message={
          <span>
            <Typography.Text strong>{title}:</Typography.Text>
            <Typography.Text style={{ marginLeft: "4px" }}>{reason}</Typography.Text>
          </span>
        }
        icon={<ExclamationCircleOutlined />}
        type="error"
        showIcon
        style={{ width: "100%", marginBottom: "10px" }}
      />
    );
  };
  return (
    <Col span={24}>
      {data?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING &&
        renderAlert(
          "Đơn mua hàng bị từ chối nhập kho vì",
          data?.deniedReason?.causes && renderCauseReject[data?.deniedReason?.causes[0]]
        )}
      {data?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING &&
        renderAlert("Kỹ thuật từ chối với lý do", data?.deniedReason?.reason)}
      {data?.status === PURCHASE_STATUS.CANCELED &&
        renderAlert(
          "Đơn mua hàng đã bị hủy với lý do",
          data?.deniedReason?.causes?.map((item) => renderCauseReject[item])?.join(", ") +
            (data?.deniedReason?.reason != null ? ", " + data?.deniedReason?.reason : "")
        )}
    </Col>
  );
}

export default AlertReason;
