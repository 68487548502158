import { Radio } from "antd";

function RadioGroup({ filterItemsTypeOptions, handleChange, value }) {
  return (
    <Radio.Group
      value={value}
      options={filterItemsTypeOptions}
      defaultValue={false}
      onChange={handleChange}
      style={{
        display: "flex",
        justifyContent: "end",
      }}
      optionType="button"
    />
  );
}

export default RadioGroup;
