import { gql } from "@apollo/client";

export const CREATE_NEW_BID = gql`
  mutation CreateNewBid($request: CreateNewBidInput) {
    priceBidSession {
      createNewBid(request: $request)
    }
  }
`;

export const APPROVE = gql`
  mutation Approve($priceBidSessionID: ID!) {
    priceBidSession {
      approve(priceBidSessionID: $priceBidSessionID)
    }
  }
`;

export const REJECT = gql`
  mutation Reject($priceBidSessionID: ID!) {
    priceBidSession {
      reject(priceBidSessionID: $priceBidSessionID)
    }
  }
`;
