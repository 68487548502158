import { Button, Dropdown, Menu, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { IMPORT_REQUEST_STATUS } from "features/Purchase/constant";
import { useUpdateStatusImportRequest } from "hooks/recommendimport";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";
import ModalRejectRequest from "../ModalRejectRequest";
export const ButtonActionRecommend = ({ record, disabled, canImportRequestReject, canImportRequestApprove }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const { handleUpdateStatusImportRequest, loading } = useUpdateStatusImportRequest();
  const onAccept = async () => {
    const params = {
      id: record?.id,
      actionType: IMPORT_REQUEST_STATUS.CONFIRM_APPROVE,
    };
    await handleUpdateStatusImportRequest(params)
      .then(() => {
        notify.success({
          message: t("warehouse.recommendImport.messageacceptrequest"),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };
  const menu = (
    <Menu>
      {canImportRequestApprove ? (
        <Menu.Item>
          <CustomModal
            {...{
              customComponent: t("warehouse.recommendImport.accept"),
              footer: false,
              message: (
                <ModalWithIconQuestion
                  title={
                    <>
                      {t("warehouse.recommendImport.questionaccept")}{" "}
                      <Typography.Link strong>{record?.code}</Typography.Link> {t("warehouse.recommendImport.no")}?
                    </>
                  }
                />
              ),
              centered: true,
              styleButton: "notButton",
              hideConfirmButton: true,
              addMoreButton: (
                <Button type="primary" onClick={onAccept} loading={loading}>
                  {t("common.confirm")}
                </Button>
              ),
              visible: visible,
              onOpen: showModal,
              onCancel: handleCancel,
            }}
          />
        </Menu.Item>
      ) : (
        <span></span>
      )}

      {canImportRequestReject ? (
        <Menu.Item>
          <ModalRejectRequest record={record} />
        </Menu.Item>
      ) : (
        <span></span>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} className="button-action-recommend button-offers-recommend" disabled={disabled}>
      <Button>
        {t("warehouse.recommendImport.actionRecommend")}
        <div>
          <SvgIcon.ChevronDown />
        </div>
      </Button>
    </Dropdown>
  );
};
