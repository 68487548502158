import { PrimaryButton } from "components/PrimaryButton";
import { t } from "i18next";
import { Link } from "react-router-dom";

function ApproveButton({ data }) {
  return (
    <Link to={`/seller/${data?.sellerID}/approve/potential-customer/${data?.id}`}>
      <PrimaryButton title={t("potentialCustomer.buttonGroup.accept")} />
    </Link>
  );
}

export default ApproveButton;
