import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";

function useList() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY.activeKey || "listBySerial");
  const [activeKeyStatus, setActiveKeyStatus] = useState(GET_QUERY.key);

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    setActiveKeyStatus("listBySerial");
    buildQueryString({
      params: activeKey === "listBySerial" ? { activeKey: activeKey } : { activeKey: activeKey, key: "listBySerial" },
    });
  };

  const handleChangeTabStatus = (value) => {
    setActiveKeyStatus(value);
  };

  useEffect(() => {
    buildQueryString({
      params: { activeKey: GET_QUERY.activeKey },
    });
  }, [GET_QUERY.activeKey]);

  return { handleChangeTab, handleChangeTabStatus, activeKeyMain, activeKeyStatus };
}

export default useList;
