import React from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router-dom";

const { TabPane } = Tabs;

export const TabsBasic = ({ listTabPanel, defaultActiveKey, children, ...rest }) => {
  const location = useLocation();

  const renderListTabPanel = listTabPanel?.map(({ permission, name, id, tabPaneProps, component }) => {
    if (permission)
      return (
        <TabPane tab={name} key={id} {...tabPaneProps}>
          {component}
        </TabPane>
      );
    else return <></>;
  });

  return (
    <Tabs
      tabBarGutter={40}
      defaultActiveKey={location?.state?.tab || defaultActiveKey}
      tabBarStyle={{
        backgroundColor: "#e7f0ff",
        padding: "0px 24px",
        marginBottom: "0px",
        borderRadius: "16px 16px 0 0 ",
      }}
      {...rest}
    >
      {renderListTabPanel}
      {children}
    </Tabs>
  );
};

export default TabsBasic;
