import { EyeTwoTone } from "@ant-design/icons";
import { Col, Row, Space, Tag, Typography } from "antd";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomModal from "components/CustomModal";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import {
  useGetCareStaffFilter,
  useGetCreatedByFilter,
  useGetInChargeStaffFilter,
  useGetPotentialCustomerList,
} from "hooks/potentialCustomer/query";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { compact, isNil } from "lodash";
import { useEffect, useState } from "react";
import { formatDateTime } from "utils/dateTime";
import { getQuery } from "utils/helperFuncs";
import {
  FILTER_TYPE,
  POTENTIAL_TABS,
  PRIORITY_CUSTOMER,
  SELLER_TYPE,
  dataSourceFilterOptions,
  dataSourceLabel,
  dataSourceTagColor,
  formatBuildQueryString,
  priorityCustomerLabel,
  priorityCustomerOptions,
  sellerTypeLabel,
  sellerTypeOptions,
} from "../constants";

function usePotentialCustomerList({ isAgent }) {
  const GET_QUERY = getQuery();
  const [searchTerm, setSearchTerm] = useState(null);

  const debouncedValue = useDebounce(searchTerm?.trim()?.toString());

  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || undefined,
      statuses: GET_QUERY.statuses || "NEW",
      types: GET_QUERY.types || undefined,
      priorityFilter: GET_QUERY?.priorityFilter
        ? {
            filterType: GET_QUERY?.priorityFilter?.filterType,
            priorities: GET_QUERY?.priorityFilter?.priorities,
          }
        : undefined,
      inChargeStaffIDFilter: GET_QUERY?.inChargeStaffIDFilter
        ? {
            filterType: GET_QUERY?.inChargeStaffIDFilter?.filterType,
            inChargeStaffIDs: GET_QUERY?.inChargeStaffIDFilter?.inChargeStaffIDs,
          }
        : undefined,
      careStaffIDFilter: GET_QUERY?.careStaffIDFilter
        ? {
            filterType: GET_QUERY?.careStaffIDFilter?.filterType,
            careStaffIDs: GET_QUERY?.careStaffIDFilter?.careStaffIDs,
          }
        : undefined,
      createdByIDs: GET_QUERY.createdByIDs || undefined,
      dataSources: GET_QUERY.dataSources || undefined,
    },
    pagination: {
      limit: GET_QUERY?.limit || 10,
      offset: GET_QUERY?.offset || 0,
    },
  });

  const [paramsCareStaff, setParamsCareStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: optionsCareStaffs,
    onLoadMoreData: onLoadMoreCareStaff,
    hasMoreData: hasMoreCareStaff,
  } = useGetCareStaffFilter(paramsCareStaff);

  const [paramsCreatedBy, setParamsCreatedBy] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: optionsCreatedBy,
    onLoadMoreData: onLoadMoreCreatedBy,
    hasMoreData: hasMoreCreatedBy,
  } = useGetCreatedByFilter(paramsCreatedBy);

  const [paramsInChargeStaff, setParamsInChargeStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: optionsInChargeStaff,
    onLoadMoreData: onLoadMoreInChargeStaff,
    hasMoreData: hasMoreInChargeStaff,
  } = useGetInChargeStaffFilter(paramsInChargeStaff);

  const handleSearchCareStaffs = (value) => {
    setParamsCareStaff({
      ...paramsCareStaff,
      filters: { ...paramsCareStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchInChargeStaffs = (value) => {
    setParamsInChargeStaff({
      ...paramsInChargeStaff,
      filters: { ...paramsInChargeStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleSearchCreatedBy = (value) => {
    setParamsCreatedBy({
      ...paramsCreatedBy,
      filters: { ...paramsCreatedBy?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const { potentialCustomer, loading, paginationData } = useGetPotentialCustomerList({ ...params, isValidate: false });

  const handleChangeParams = (value) => {
    const newParams = {
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const onFilter = (key, value) => {
    handleChangeParams && handleChangeParams({ [`${key}`]: value });
  };

  const checkFilterType = (value) => {
    return value?.length
      ? value.includes(PRIORITY_CUSTOMER.UNCATEGORIZED)
        ? FILTER_TYPE.INCLUDE_NULL
        : FILTER_TYPE.INCLUDE
      : FILTER_TYPE.ALL;
  };

  const onFilterPriorityFilter = (key, value) => {
    handleChangeParams &&
      handleChangeParams({
        priorityFilter: { priorities: value, filterType: checkFilterType(value) },
      });
  };

  const onFilterCareStaffFilter = (key, value) => {
    handleChangeParams &&
      handleChangeParams({
        careStaffIDFilter: {
          careStaffIDs: value,
          filterType: checkFilterType(value),
        },
      });
  };

  const onFilterInChargeStaffFilter = (key, value) => {
    handleChangeParams &&
      handleChangeParams({
        inChargeStaffIDFilter: {
          inChargeStaffIDs: value,
          filterType: checkFilterType(value),
        },
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTableChange = (pagination) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
    };
    setParams(newParams);
    formatBuildQueryString(newParams);
  };

  const [isShowDrawerDetail, setIsShowDrawerDetail] = useState(false);
  const [customerId, setCustomerId] = useState(undefined);

  const handleOpenDrawerDetail = ({ customerId }) => {
    setCustomerId(customerId);
    setIsShowDrawerDetail(true);
  };

  const handleCloseDrawerDetail = () => {
    setCustomerId(null);
    setIsShowDrawerDetail(false);
  };

  const columns = [
    {
      title: <Typography.Text style={{ color: "#5b6673" }}>{t("potentialCustomer.table.stt")}</Typography.Text>,
      width: 60,
      fixed: "left",
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("potentialCustomer.table.customerName"),
      width: 300,
      fixed: "left",
      render: (_, record) => (
        <Space direction="vertical" style={{ gap: "0px" }}>
          <CustomTextHiddenLine
            onClick={() => handleOpenDrawerDetail({ customerId: record?.id })}
            line={2}
            text={record?.fullName}
            style={{ color: "#2246DC", cursor: "pointer" }}
            limit={40}
          />
          {record?.rejectedReason && GET_QUERY.statuses === POTENTIAL_TABS.TAKING_CARE && (
            <Typography.Text>(Bị từ chối)</Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: t("potentialCustomer.table.address"),
      width: 250,
      render: (_, record) => (
        <CustomTextHiddenLine
          line={2}
          text={compact([record?.address, record?.ward?.name, record?.district?.name, record?.city?.name]).join(", ")}
        />
      ),
    },
    {
      title: t("potentialCustomer.table.email"),
      width: 250,
      render: (_, record) => <CustomTextHiddenLine line={1} text={record?.email} limit={27} />,
    },
    {
      title: t("potentialCustomer.table.telephone"),
      width: 150,
      align: "center",
      render: (_, record) => record?.telephone || "--",
    },
    //loai khach hang
    {
      title: t("potentialCustomer.table.customerType"),
      align: "center",
      width: 180,
      render: (_, record) => (
        <Tag color={record?.type === SELLER_TYPE.COMPANY ? "geekblue" : "magenta"}>
          {sellerTypeLabel?.[record?.type]}
        </Tag>
      ),
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("types", value)} type="checkbox" options={sellerTypeOptions} />
      ),
    },
    //phan loai
    {
      title: t("potentialCustomer.table.priority"),
      width: 200,
      render: (_, record) => <CustomTextHiddenLine text={priorityCustomerLabel?.[record?.priority]} />,
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilterPriorityFilter("priorityFilter", value)}
          type="checkbox"
          options={priorityCustomerOptions}
        />
      ),
    },
    //ly do dung cham soc
    ...(GET_QUERY?.statuses === POTENTIAL_TABS.STOP_CARING
      ? [
          {
            title: t("potentialCustomer.table.desistReason"),
            width: 220,
            render: (_, record) => (
              <Row style={{ alignItems: "center" }}>
                <Col span={20}>
                  <Typography.Text>{truncateAddDots(record?.desistReason || "--")}</Typography.Text>
                </Col>
                {record?.desistReason?.length > 55 && (
                  <Col span={4}>
                    <CustomModal
                      cancelButtonLabel={t("common.close")}
                      hideConfirmButton={true}
                      closable={false}
                      styleBtnGrp={{ marginTop: "20px" }}
                      message={
                        <Typography.Text strong style={{ textTransform: "uppercase", fontSize: "16px" }}>
                          {t("potentialCustomer.table.desistReason")}
                        </Typography.Text>
                      }
                      children={
                        <Space direction="vertical">
                          <Typography.Text type="warning">
                            {" "}
                            {t("potentialCustomer.table.titleDesitReason")}
                          </Typography.Text>
                          <Typography.Text>{record?.desistReason}</Typography.Text>
                        </Space>
                      }
                      customComponent={<EyeTwoTone style={{ cursor: "pointer" }} />}
                      footer={false}
                    />
                  </Col>
                )}
              </Row>
            ),
          },
        ]
      : []),
    //nhan vien cham soc
    ...(GET_QUERY?.statuses !== POTENTIAL_TABS.NEW
      ? [
          {
            title: t("potentialCustomer.table.caringStaff"),
            width: 220,
            render: (_, record) => <CustomTextHiddenLine text={record?.careStaff?.fullname} />,
            filterDropdown: (
              <FilterLoadMore
                onFilter={(value) => onFilterCareStaffFilter("careStaffIDFilter", value)}
                data={[{ label: "Không có nhân viên chăm sóc", value: PRIORITY_CUSTOMER.UNCATEGORIZED }]?.concat(
                  optionsCareStaffs
                )}
                onLoadMoreData={onLoadMoreCareStaff}
                hasMore={hasMoreCareStaff}
                onSearchTextChange={handleSearchCareStaffs}
              />
            ),
          },
        ]
      : []),
    //nhan vien phu trach
    {
      title: t("potentialCustomer.table.staffInCharge"),
      width: 220,
      render: (_, record) => <CustomTextHiddenLine text={record?.inChargeStaff?.fullname} />,
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilterInChargeStaffFilter("inChargeStaffIDFilter", value)}
          data={[{ label: "Không có nhân viên phụ trách", value: PRIORITY_CUSTOMER.UNCATEGORIZED }]?.concat(
            optionsInChargeStaff
          )}
          onLoadMoreData={onLoadMoreInChargeStaff}
          hasMore={hasMoreInChargeStaff}
          onSearchTextChange={handleSearchInChargeStaffs}
        />
      ),
    },
    //nguon
    {
      title: t("potentialCustomer.table.dataSource"),
      width: 150,
      render: (_, record) => (
        <Tag color={dataSourceTagColor?.[record?.dataSource]}>{dataSourceLabel?.[record?.dataSource]}</Tag>
      ),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("dataSources", value)}
          type="checkbox"
          options={dataSourceFilterOptions}
        />
      ),
    },
    {
      title: t("potentialCustomer.table.createdAt"),
      width: 120,
      align: "center",
      render: (_, record) => formatDateTime(record?.createdAt),
    },
    {
      title: t("potentialCustomer.table.createdBy"),
      width: 150,
      render: (_, record) => <CustomTextHiddenLine text={record?.createdBy?.fullname} />,
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilter("createdByIDs", value)}
          data={optionsCreatedBy}
          onLoadMoreData={onLoadMoreCreatedBy}
          hasMore={hasMoreCreatedBy}
          onSearchTextChange={handleSearchCreatedBy}
        />
      ),
    },
  ];

  const restTable = {
    columns,
    loading,
    dataSource: potentialCustomer,
    rowKey: (record) => record?.id,
    pagination: {
      total: paginationData?.total,
      pageSize: paginationData?.limit,
      current: paginationData?.offset / paginationData?.limit + 1 || 1,
      showSizeChanger: false,
    },
    onChange: handleTableChange,
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue.trim() || undefined },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      formatBuildQueryString(newParams);
    }
  }, [debouncedValue]);

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
      },
    };

    if (GET_QUERY?.priorityFilter) {
      newParams.filters.priorityFilter = {
        filterType: GET_QUERY.filterTypePriorityFilter,
        priorities: GET_QUERY.priorityFilter,
      };
    }

    if (GET_QUERY?.inChargeStaffIDFilter) {
      newParams.filters.inChargeStaffIDFilter = {
        filterType: GET_QUERY.filterTypeInChargeStaffIDFilter,
        inChargeStaffIDs: GET_QUERY.inChargeStaffIDFilter,
      };
    }

    if (GET_QUERY?.careStaffIDFilter) {
      newParams.filters.careStaffIDFilter = {
        filterType: GET_QUERY.filterTypeCareStaffIDFilter,
        careStaffIDs: GET_QUERY.careStaffIDFilter,
      };
    }

    setParams(newParams);
    formatBuildQueryString(newParams);
  }, []);

  return {
    loadingPotentialCustomer: loading,
    columns,
    potentialCustomer,
    restTable,
    handleSearch,
    params,
    handleChangeParams,
    isShowDrawerDetail,
    handleCloseDrawerDetail,
    customerId,
  };
}

export default usePotentialCustomerList;
