import { useLazyQuery, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import {
  COUNT_ORDER_RECONCILIATION,
  ORDER_RECONCILIATION_DETAIL,
  ORDER_RECONCILIATION_LIST_QUERY,
} from "graphql/orderReconciliation/query";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";

const formatToUTC7 = () => {
  const date = new Date();

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  const hours = String(date.getUTCHours() + 7).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const useOrderReconciliationListQuery = (params) => {
  const filters = params?.filters;

  const { data, loading, refetch } = useQuery(ORDER_RECONCILIATION_LIST_QUERY, {
    variables: {
      ...params,
      filters: {
        ...filters,
        currentTime: filters?.expiredDateStatuses?.length
          ? filters?.currentTime
          : undefined || filters?.completedDateStatuses?.length
          ? filters?.currentTime
          : undefined,
      },
    },
  });

  return {
    orderReconciliationList: data?.orderReconciliation?.pagination?.orderReconcilations || [],
    loading,
    paginationData: data?.orderReconciliation?.pagination?.paginationData,
    refetch,
  };
};

export const useOrderReconciliationDetail = (params) => {
  const [getDetail, { loading, data, refetch }] = useLazyQuery(ORDER_RECONCILIATION_DETAIL);

  const handleGetOrderReconciliationDetail = async (params) => {
    return getDetail({
      variables: {
        id: params,
      },
    });
  };
  return {
    loadingOrderReconciliationDetail: loading,
    dataOrderReconciliationDetail: data?.orderReconciliation?.get,
    handleGetOrderReconciliationDetail,
    refetch,
  };
};

export const useGetCountOrderReconciliation = () => {
  const { data, refetch } = useQuery(COUNT_ORDER_RECONCILIATION);

  return {
    all: data?.all?.count || 0,
    waiting_for_payment: data?.waiting_for_payment?.count || 0,
    reconciling: data?.reconciling?.count || 0,
    completed: data?.completed?.count || 0,
    rejected: data?.rejected?.count || 0,
    canceled: data?.canceled?.count || 0,
    refetch,
  };
};

export const useProfitPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();

  return {
    canViewList: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECONCILIATION_VIEW]),
    canViewDetail: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECONCILIATION_DETAIL]),
    canProfitStart: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECONCILIATION_START]),
    canProfitComplete: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECONCILIATION_COMPLETE]),
    canProfitReject: hasPermission(permissions, [USER_PERMISSIONS.ORDER_RECONCILIATION_REJECT]),
    loading,
  };
};
