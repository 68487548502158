import { Radio } from "antd";

function CustomRadioBtnGroup({ filterItemsTypeOptions, handleChange, isUpdate, defaultValue = false }) {
  return (
    <Radio.Group
      options={filterItemsTypeOptions}
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={handleChange}
      style={{
        display: "flex",
        justifyContent: "end",
      }}
      optionType="button"
    />
  );
}

export default CustomRadioBtnGroup;
