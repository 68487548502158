import { Card, Col, Form, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import InfoProvider from "./components/InfoProvider";
import { TitleCard } from "./components/TitleCard";
import PaymentInfo from "./components/PaymentInfo";
import SelectProduct from "./components/SelectProduct";
import TableProduct from "./components/Table";
import AddInfo from "./components/AddInfo";
import CustomButton from "components/CustomButton";
import "./index.scss";
import PopupAddProduct from "./components/PopupAddProduct";
import { IMPORT_REQUEST_TYPE, optionImportRequestType } from "../../constant";
import useCreate from "./useCreate";
import CustomModal from "components/CustomModal";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";

function CreateImportRequest() {
  const {
    form,
    valueImportWarehouse,
    sellers,
    dataTableProduct,
    handleGetProduct,
    handleFinish,
    initialValues,
    handleAddProduct,
    handleChangeInfo,
    sellerInfo,
    loadMoreSeller,
    hasMoreSeller,
    onSearchSeller,
    history,
    loadingCreate,
    handleDeleteProduct,
    setSumPrice,
    setCurrencyFirst,
    currencyFirst,
    sumPrice,
    handleCreateClick,
    handlePasswordKeyPress,
    formData,
    handleKeyPress,
  } = useCreate();
  return (
    <div className="create-recommend-import">
      <Form layout="horizontal" form={form} onFinish={handleFinish} initialValues={initialValues}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("warehouse.create.createRecommendImport")}
              routes={[
                { path: "#", name: t("warehouse.recommendImport.buy") },
                { path: "/import-request", name: t("warehouse.recommendImport.recommendImport") },
                { path: "/", name: t("warehouse.create.createRecommendImport") },
              ]}
            />
          </Col>

          <Col span={24} className="form-head">
            <Row gutter={[16, 8]}>
              <Col span={10}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("warehouse.create.typeRecommend")}</Typography.Text>,
                    name: "type",
                  }}
                  inputOptions={{
                    options: optionImportRequestType,
                    defaultValue: IMPORT_REQUEST_TYPE.DOMESTIC,
                  }}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={6}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("warehouse.create.warehouseImport")}</Typography.Text>,
                    name: "warehouseID",
                    rules: [{ required: true, message: t("warehouse.create.relueschosseWarrenty") }],
                  }}
                  inputOptions={{
                    placeholder: t("warehouse.create.chosseWarrenty"),
                    options: valueImportWarehouse,
                    onKeyPress: handlePasswordKeyPress,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={10} className="card-container-info">
                <Card title={<TitleCard title={t("warehouse.create.infoProvider")} />}>
                  <InfoProvider
                    sellers={sellers}
                    handleChangeInfo={handleChangeInfo}
                    sellerInfo={sellerInfo}
                    loadMoreSeller={loadMoreSeller}
                    hasMoreSeller={hasMoreSeller}
                    onSearchSeller={onSearchSeller}
                  />
                </Card>
              </Col>
              <Col span={7} className="card-container-info">
                <Card title={<TitleCard title={t("warehouse.create.infoPayment")} />}>
                  <PaymentInfo />
                </Card>
              </Col>
              <Col span={7} className="card-container-info">
                <Card title={<TitleCard title={t("warehouse.create.addInfo")} />}>
                  <AddInfo />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card title={<TitleCard title={t("warehouse.create.listProduct")} />}>
                  <Row gutter={[16, 8]}>
                    <Col span={10}>
                      <SelectProduct handleGetProduct={handleGetProduct} />
                    </Col>
                    <Row
                      gutter={10}
                      style={{
                        color: "#2246DC",
                        cursor: "pointer",
                        marginTop: "7px",
                        marginLeft: "10px",
                      }}
                    >
                      <PopupAddProduct form={form} handleAddProduct={handleAddProduct} />
                    </Row>
                  </Row>
                  <Col span={24}>
                    <Form.List name="items">
                      {(items, { remove }) => {
                        return (
                          <div>
                            <TableProduct
                              remove={remove}
                              form={form}
                              dataTableProduct={dataTableProduct}
                              handleDeleteProduct={handleDeleteProduct}
                              setCurrencyFirst={setCurrencyFirst}
                              setSumPrice={setSumPrice}
                              handleKeyPress={handleKeyPress}
                            />
                            {formData?.items?.length > 0 ? (
                              <div style={{ marginTop: "10px" }}>
                                <Typography.Link strong>
                                  {t("warehouse.recommendImport.sumByMoney")}:{" "}
                                  {currencyFirst === "USD"
                                    ? formatCurrencyDollar(sumPrice || 0)
                                    : formatCurrency(sumPrice || 0)}
                                </Typography.Link>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    </Form.List>
                  </Col>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card className="form-footer">
                  <CustomModal
                    {...{
                      customComponent: <CustomButton title={t("common.cancel")} />,
                      footer: false,
                      message: t("warehouse.create.cancelCreateImportRequest"),
                      centered: true,
                      onOke: () => {
                        history.push("/import-request");
                      },
                    }}
                  />
                  <CustomButton
                    type={"primary"}
                    title={t("warehouse.create.create")}
                    htmlType={"submit"}
                    loading={loadingCreate}
                    onClick={handleCreateClick}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateImportRequest;
