import { Button, DatePicker, Menu, Space } from "antd";
import React from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "assets/icons";
import useFilterDate from "./useFilterDate";
import { t } from "i18next";

const FilterDate = ({ title, onChangeDate }) => {
  const {
    thisDay,
    yesterday,
    thisWeek,
    lastWeek,
    thisMonth,
    lastMonth,
    value,
    isOpen,
    renderRangerPicker,
    onCancel,
    onConfirm,
    onChange,
    onOpen,
    onOpenChange,
    onClickMenu,
  } = useFilterDate({ onChangeDate });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button onClick={onOpen}>
        <FontAwesomeIcon icon={Icon.faFilter} style={{ marginRight: "16px" }} className="icon" />
        <span className="hide">{title}</span>
      </Button>
      <DatePicker.RangePicker
        style={{ visibility: "hidden", width: 0, padding: 0, margin: 0, height: 0, border: 0 }}
        open={isOpen}
        value={value}
        onOpenChange={() => onOpenChange(isOpen)}
        dropdownClassName="menu-select-range-picker"
        onChange={onChange}
        panelRender={(originalPanel) => {
          return (
            <>
              <Menu mode="inline" defaultSelectedKeys="option">
                <Menu.Item key="today" onClick={() => onClickMenu(thisDay)}>
                  {t("common.today")}
                </Menu.Item>
                <Menu.Item key="yesterday" onClick={() => onClickMenu(yesterday)}>
                  {t("common.yesterday")}
                </Menu.Item>
                <Menu.Item key="this-week" onClick={() => onClickMenu(thisWeek)}>
                  {t("common.thisWeek")}
                </Menu.Item>
                <Menu.Item key="last-week" onClick={() => onClickMenu(lastWeek)}>
                  {t("common.lastWeek")}
                </Menu.Item>
                <Menu.Item key="this-month" onClick={() => onClickMenu(thisMonth)}>
                  {t("common.thisMonth")}
                </Menu.Item>
                <Menu.Item key="last-month" onClick={() => onClickMenu(lastMonth)}>
                  {t("common.lastMonth")}
                </Menu.Item>
                <Menu.Item key="option">{t("common.option")}</Menu.Item>
              </Menu>
              <Space>
                {originalPanel}
                <div className="buttons">
                  {renderRangerPicker()}
                  <Button onClick={onCancel}>{t("common.cancel")}</Button>
                  <Button onClick={onConfirm} type="primary">
                    {t("common.confirm")}
                  </Button>
                </div>
              </Space>
            </>
          );
        }}
      />
    </div>
  );
};

export default FilterDate;
