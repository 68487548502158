import { Button, Col, Result, Row } from "antd";
import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import { useGetCountOrderReconciliation, useProfitPermissions } from "hooks/orderReconciliation";
import ProfitReconciliationDetail from "../Detail";
import { PROFIT_RECONCILIATION } from "../constants";
import TablesList from "./Tables/TablesList";
import Toolbar from "./Toolbar";
import useProfitReconciliationList from "./useProfitReconciliationList";
import CustomPanelCard from "components/_storybooks/CustomPanelCard";
import LoadingComponent from "components/LoadingComponent";
import Page403 from "pages/PageError/403";

function ProfitReconciliationList() {
  const { canViewDetail, canViewList, loading: loadUserPermissions } = useProfitPermissions();
  const { all, canceled, completed, reconciling, rejected, waiting_for_payment, refetch } =
    useGetCountOrderReconciliation();

  const {
    params,
    restTableList,
    activeKeyMain,
    visibleDrawerDetail,
    dataOrderReconciliationDetail,
    loadingOrderReconciliationDetail,
    handleChangeTab,
    handleVisibleDrawerDetail,
    handleCloseVisibleDrawerDetail,
    handleSearch,
    handleChangeParams,
    onFilter,
  } = useProfitReconciliationList({ refetchCount: refetch });

  const {
    COLUMN_ALL,
    COLUMN_WAITING_FOR_PAYMENT,
    COLUMN_RECONCILING,
    COLUMN_COMPLETED,
    COLUMN_REJECTED,
    COLUMN_CANCELED,
  } = TablesList({ handleVisibleDrawerDetail, onFilter });

  const tabList = [
    {
      key: PROFIT_RECONCILIATION.ALL,
      tab: `Tất cả (${all})`,
    },
    {
      key: PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT,
      tab: `Chờ thanh toán (${waiting_for_payment})`,
    },
    {
      key: PROFIT_RECONCILIATION.RECONCILING,
      tab: `Đang đối soát (${reconciling})`,
    },
    {
      key: PROFIT_RECONCILIATION.COMPLETED,
      tab: `Hoàn thành (${completed})`,
    },
    {
      key: PROFIT_RECONCILIATION.REJECTED,
      tab: `Đã từ chối (${rejected})`,
    },
    {
      key: PROFIT_RECONCILIATION.CANCELED,
      tab: `Đã hủy (${canceled})`,
    },
  ];

  const contentList = {
    [PROFIT_RECONCILIATION.ALL]: <CustomTable columns={COLUMN_ALL} {...restTableList} />,
    [PROFIT_RECONCILIATION.WAITING_FOR_PAYMENT]: (
      <CustomTable columns={COLUMN_WAITING_FOR_PAYMENT} {...restTableList} />
    ),
    [PROFIT_RECONCILIATION.RECONCILING]: <CustomTable columns={COLUMN_RECONCILING} {...restTableList} />,
    [PROFIT_RECONCILIATION.COMPLETED]: <CustomTable columns={COLUMN_COMPLETED} {...restTableList} />,
    [PROFIT_RECONCILIATION.REJECTED]: <CustomTable columns={COLUMN_REJECTED} {...restTableList} />,
    [PROFIT_RECONCILIATION.CANCELED]: <CustomTable columns={COLUMN_CANCELED} {...restTableList} />,
  };

  return (
    <LoadingComponent loading={loadUserPermissions}>
      {canViewList ? (
        <Row>
          <Col span={24}>
            <PageHeader
              pageTitle={"Danh sách đơn hàng đặt cho khách của đại lý"}
              routes={[
                {
                  path: "/profit-reconciliation",
                  name: "Kế toán",
                },
                {
                  path: "",
                  name: "Danh sách đơn hàng đặt cho khách của đại lý",
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <CustomPanelCard
              tabList={tabList}
              onTabChange={handleChangeTab}
              defaultActiveTabKey={activeKeyMain}
              contentList={contentList}
              activeTabKey={activeKeyMain}
              content={<Toolbar onSearch={handleSearch} params={params} onChangeParams={handleChangeParams} />}
            />
            {canViewDetail && (
              <ProfitReconciliationDetail
                visible={visibleDrawerDetail}
                onClose={handleCloseVisibleDrawerDetail}
                dataOrderReconciliationDetail={dataOrderReconciliationDetail}
                loadingOrderReconciliationDetail={loadingOrderReconciliationDetail}
              />
            )}
          </Col>
        </Row>
      ) : (
        <Page403 />
      )}
    </LoadingComponent>
  );
}

export default ProfitReconciliationList;
