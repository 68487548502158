import { pick } from "lodash";

const converterCategory = (item) => {
  return {
    level: item.level,
    label: item.name,
    value: item.id,
    parentID: item.parentID,
  };
};

export const converterCategories = (data) =>
  //remove children hollow
  data?.flatMap((item) =>
    item?.children?.length
      ? { ...item, children: converterCategories(item.children) }
      : pick(item, [
          "id",
          "name",
          "logoURL",
          "totalProduct",
          "totalActiveProduct",
          "description",
          "isActive",
          "parentID",
        ])
  );

export const convertAttributes = (data) =>
  data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });

const isLevelDisable = (levelItem, levelSelect) => levelItem !== levelSelect - 1;

export const converterCategoriesForForm = (data, level) => {
  if (level) {
    return data?.map((item) => {
      const isDisable = isLevelDisable(item?.level, level);
      const category = converterCategory(item);
      return item?.children?.length
        ? {
            ...category,
            disabled: isDisable,
            children: converterCategoriesForForm(item.children, level),
          }
        : {
            ...category,
            disabled: isDisable,
          };
    });
  } else {
    return data?.map((item) => {
      const category = converterCategory(item);
      return item?.children?.length
        ? {
            ...category,
            children: converterCategoriesForForm(item.children),
          }
        : { ...category };
    });
  }
};
//remove children hollow

export const convertGetAllAttributes = (data) =>
  data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
      isCommon: item?.isCommon,
      isActive: item?.isActive,
      totalUsedCategory: item?.totalUsedCategory,
      isDefault: item?.isCommon,
    };
  });

export const convertCategoriesByAtt = (data) =>
  data?.map((item) => {
    return {
      id: item?.id,
      categoryName: item?.name,
      status: item?.isActive,
    };
  });

export const converterGetCategory = (data) => {
  const attributes = data?.attributes?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
      isCommon: item?.isCommon,
      isActive: item?.isActive,
      totalUsedCategory: item?.totalUsedCategory,
      isDefault: true,
    };
  });
  return {
    ...data,
    attributes: attributes || [],
    logoURL: data?.logoURL ? [{ url: data?.logoURL }] : [],
  };
};
