import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomTable from "components/CustomTable";
import { EXPORT_TYPE } from "features/Serial/constant";
import { t } from "i18next";
import { sumBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import "../../index.scss";
import TableOrder from "../TypeOrder/TableOrder";
import SelectProduct from "../TypeReturnSupplier/SelectProduct";
import TableReturnSupplier from "../TypeReturnSupplier/TableReturnSupplier";

import { TableOther } from "../TypeOther/TableOther";
import SelectProductTransferSerial from "../TypeTransferSerial/SelectProductTransferSerial";
import { TableTransferSerial } from "../TypeTransferSerial/TableTransferSerial";
import { EditableCell } from "../TypeWarranty/EditableCell";
import { EditTableRow } from "../TypeWarranty/EditableRow";
import TableWarranty from "../TypeWarranty/TableWarranty";
import styles from "./index.module.scss";
import useList from "./useList";

function TableSerialExport({
  dataTableOrder,
  warehouseOptions,
  tags,
  exportType,
  form,
  handleGetTableOrder,
  sumProductByOrder,
  sumSerialByOrder,
  sumSerialScanned,
  dataTableSupplier,
  handleSetFieldSupplier,
  handleGetTags,
  handleRemoveItemReturnSupplier,
  formData,
  handleSetFieldTransferSerial,
  dataTableTransferSerial,
  handleRemoveItemTransferSerial,
  handleDeleteAllSerial,
  warehouseInfo,
}) {
  const { renderLack } = useList();
  const [dataSourceWarranty, setDataSourceWarranty] = useState([]);
  const [serials, setSerials] = useState([]);
  const isDisabledSelectProduct = !formData?.sellerID || !formData?.warehouseID;
  const { columnsBySupplier } = TableReturnSupplier({
    form,
    renderLack,
    tags,
    handleGetTags,
    sumSerialByOrder,
    sumProductByOrder,
    sumSerialScanned,
    handleRemoveItemReturnSupplier,
    handleDeleteAllSerial,
  });

  const { columnsByOrders } = TableOrder({ form, handleGetTableOrder, renderLack });

  const { columnsBySeller, loadingTableWarranty } = TableWarranty({
    form,
    handleGetTableOrder,
    renderLack,
    setDataSourceWarranty,
    dataSourceWarranty,
    warehouseOptions,
    serials,
    setSerials,
  });

  const { columns: columnsTransferSerial } = TableTransferSerial({
    warehouseOptions,
    form,
    renderLack,
    tags,
    handleGetTags,
    sumSerialByOrder,
    sumProductByOrder,
    sumSerialScanned,
    handleRemoveItemTransferSerial,
    handleDeleteAllSerial,
    warehouseInfo,
  });

  const { columns: columnsOther } = TableOther({
    warehouseOptions,
    form,
    renderLack,
    tags,
    handleGetTags,
    sumSerialByOrder,
    sumProductByOrder,
    sumSerialScanned,
    handleRemoveItemTransferSerial,
    handleDeleteAllSerial,
    warehouseInfo,
  });

  const handleAdd = () => {
    const newData = {};
    setDataSourceWarranty([...dataSourceWarranty, newData]);
  };

  const handleSave = (row) => {
    const newData = [...dataSourceWarranty];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSourceWarranty(newData);
  };

  const columns = columnsBySeller.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
      }),
    };
  });
  const rightRef = useRef();
  const height = rightRef?.current?.clientHeight;

  const renderSubTotal = (sumSerialByOrder, sumSerialScanned) => {
    if (sumSerialByOrder < sumSerialScanned) {
      return (
        <>
          <Col span={20}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={4} style={{ color: "red" }}>
            : {(sumSerialScanned - sumSerialByOrder)?.toString().padStart(2, "0")}
          </Col>
        </>
      );
    }
    if (sumSerialScanned === sumSerialByOrder) {
      return (
        <>
          <Col span={20}>
            <Typography.Text strong className={styles["summary"]} style={{ color: "#00AB78" }}>
              {t("serialIE.productInfo.enough")}
            </Typography.Text>
          </Col>
          <Col span={4}>
            <Typography.Text style={{ color: "#00AB78" }}>
              : {sumSerialScanned?.toString().padStart(2, "0")}
            </Typography.Text>
          </Col>
        </>
      );
    }
    if (sumSerialScanned < sumSerialByOrder) {
      return (
        <>
          <Col span={20}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}:
            </Typography.Text>
          </Col>
          <Col span={4} style={{ color: "#ef9351" }}>
            : {(sumSerialByOrder - sumSerialScanned)?.toString().padStart(2, "0")}
          </Col>
        </>
      );
    }
  };

  useEffect(() => {
    setDataSourceWarranty([]);
    setSerials([]);
  }, [exportType]);

  const summaryTotalOrder = () => {
    return (
      <Row gutter={[]} className="sumary-total">
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(sumProductByOrder || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumSerialByOrder || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumSerialScanned || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>{sumProductByOrder > 0 && renderSubTotal(sumSerialByOrder || 0, sumSerialScanned || 0)}</Row>
        </Col>
      </Row>
    );
  };

  const summaryTotalReturnSupplier = () => {
    return (
      <Row gutter={[]} className="sumary-total">
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(dataTableSupplier?.length || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "quantity") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "serialItems.length") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            {(dataTableSupplier?.length || 0) > 0 &&
              renderSubTotal(
                sumBy(formData?.items, "quantity") || 0,
                sumBy(formData?.items, "serialItems.length") || 0
              )}
          </Row>
        </Col>
      </Row>
    );
  };

  const summaryTotalTransferSerial = () => {
    return (
      <Row gutter={[]} className="sumary-total">
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(dataTableSupplier?.length || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "quantity") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "serialItems.length") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            {(dataTableTransferSerial?.length || 0) > 0 &&
              renderSubTotal(
                sumBy(formData?.items, "quantity") || 0,
                sumBy(formData?.items, "serialItems.length") || 0
              )}
          </Row>
        </Col>
      </Row>
    );
  };

  const summaryTotalOther = () => {
    return (
      <Row gutter={[]} className="sumary-total">
        <Col span={19}></Col>
        <Col span={5}>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumProduct")}</Col>
            <Col span={4}>: {(dataTableSupplier?.length || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "quantity") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            <Col span={20}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
            <Col span={4}>: {(sumBy(formData?.items, "serialItems.length") || 0)?.toString().padStart(2, "0")}</Col>
          </Row>
          <Row>
            {(dataTableTransferSerial?.length || 0) > 0 &&
              renderSubTotal(
                sumBy(formData?.items, "quantity") || 0,
                sumBy(formData?.items, "serialItems.length") || 0
              )}
          </Row>
        </Col>
      </Row>
    );
  };

  const renderTableExportSerial = () => {
    switch (exportType) {
      case EXPORT_TYPE.ORDER:
        return (
          <div>
            <CustomTable
              columns={columnsByOrders}
              dataSource={dataTableOrder}
              rowKey={(record) => record.id}
              scroll={{
                x: 1200,
                y: +height,
              }}
            />
            {summaryTotalOrder()}
          </div>
        );
      case EXPORT_TYPE.WARRANTY:
        return (
          <div className="custom-table-seller">
            <CustomTable
              components={{
                body: {
                  row: EditTableRow,
                  cell: EditableCell,
                },
              }}
              scroll={{
                x: 1200,
                y: +height,
              }}
              loading={loadingTableWarranty}
              columns={columns}
              dataSource={dataSourceWarranty}
              rowClassName={() => "editable-row"}
            />
            <div className="footer-table-seller">
              <div className="button-add-row" onClick={handleAdd}>
                <PlusCircleOutlined className={styles.icon} />
                <span className={styles.text}>{t("serialIE.createSerial")}</span>
              </div>
              <div>
                {t("serialIE.productInfo.sumSerial")} : {dataSourceWarranty?.length?.toString().padStart(2, "0")}
              </div>
            </div>
          </div>
        );
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return (
          <>
            <Row gutter={[16, 8]}>
              <Col span={9}>
                <SelectProduct
                  handleSetFieldSupplier={handleSetFieldSupplier}
                  isDisabledSelectProduct={isDisabledSelectProduct}
                />
              </Col>
            </Row>
            <CustomTable
              columns={columnsBySupplier}
              dataSource={dataTableSupplier}
              rowKey={(record) => record.id}
              scroll={{
                x: 1200,
                y: +height,
              }}
              locale={{
                emptyText: (
                  <div style={{ textAlign: "center", fontSize: "12px" }}>
                    <SvgIcon.EmptyData />
                    <p>{t("serialExportCreate.notifySelectWarehouseAndProvider")}</p>
                  </div>
                ),
              }}
            />
            {summaryTotalReturnSupplier()}
          </>
        );
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return (
          <>
            <Row gutter={[16, 8]}>
              <Col span={9}>
                <SelectProductTransferSerial handleSetFieldTransferSerial={handleSetFieldTransferSerial} />
              </Col>
            </Row>
            <CustomTable
              columns={columnsTransferSerial}
              dataSource={dataTableTransferSerial}
              rowKey={(record) => record.id}
              scroll={{
                x: 1200,
                y: +height,
              }}
            />
            {summaryTotalTransferSerial()}
          </>
        );
      case EXPORT_TYPE.OTHER:
        return (
          <>
            <Row gutter={[16, 8]}>
              <Col span={9}>
                <SelectProductTransferSerial handleSetFieldTransferSerial={handleSetFieldTransferSerial} />
              </Col>
            </Row>
            <CustomTable
              columns={columnsOther}
              dataSource={dataTableTransferSerial}
              rowKey={(record) => record.id}
              scroll={{
                x: 1200,
                y: +height,
              }}
            />
            {summaryTotalOther()}
          </>
        );
      default:
        return "";
    }
  };

  return renderTableExportSerial();
}

export default TableSerialExport;
