import React, { useState, useEffect } from "react";
import { message, Modal, Spin, Upload } from "antd";
import { dummyRequest, notify, validateFile } from "utils/helperFuncs";
import { useUploadDocument } from "hooks/upload-document";
import { PictureOutlined } from "@ant-design/icons";
import { t } from "i18next";
import "./index.scss";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function UploadLogo({ maxCount, onUploadSuccess, switchClear, multiple, accept, form, listType, ...rest }) {
  const { handleUpload, loading } = useUploadDocument();
  const [files, setFiles] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  function handleChange(info) {
    const { status } = info.file;
    handleRemoveFile(info.file);
    if (status === "done") {
      setFiles((prevState) => setFiles([...prevState, info.file]));
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  async function hanldeUploadFiles() {
    try {
      const uploadUrls = await handleUpload({
        files: files?.map((item) => item?.originFileObj),
      });
      onUploadSuccess && onUploadSuccess(uploadUrls?.map((item) => item?.url));
    } catch (err) {
      notify.error({
        message: t("uploadDocument.uploadFail"),
      });
    }
  }

  const handleRemoveFile = (file) => {
    const newFileList = files?.filter((item) => item.uid !== file.uid);
    setFiles(newFileList);
  };

  useEffect(() => {
    if (files.length) {
      hanldeUploadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    setFiles([]);
  }, [switchClear]);

  const formData = form?.getFieldValue();
  const uploadButton = (
    <div>
      <PictureOutlined />
    </div>
  );
  return (
    <div className="upload-logo-product-brand">
      <Spin spinning={loading}>
        <Upload
          accept="image/*"
          maxCount={maxCount}
          onChange={handleChange}
          listType={listType || "picture-card"}
          onPreview={handlePreview}
          customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
          beforeUpload={(file) => {
            if (!validateFile(file)) {
              notify.error({
                message: t("uploadDocument.limitErrorMessage"),
                description: file?.name,
              });
              return Upload.LIST_IGNORE;
            }
            return validateFile(file);
          }}
          onRemove={handleRemoveFile}
          {...rest}
        >
          {formData?.logo?.length >= 1 ? null : uploadButton}
        </Upload>
        <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img
            alt={previewTitle}
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
      </Spin>
    </div>
  );
}

export default React.memo(UploadLogo);
