import { Col, Row, Select, Typography } from "antd";
import { useUpdateProductStatus } from "hooks/product/product";
import { SELLING_STATUS, SELLING_STATUS_OPTIONS } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import "./index.scss";
import CustomModal from "components/CustomModal";
import Img from "assets/images";

const notifySuccess = () => {
  return notify.success({
    message: "Thay đổi trạng thái sản phẩm thành công",
  });
};

const notifyError = (err) => {
  notify.error({
    message: "Thay đổi trạng thái sản phẩm thất bại",
    description: err?.message,
  });
};

const switchCss = (value) => {
  switch (value) {
    case SELLING_STATUS.AVAILABLE:
      return "available";
    case SELLING_STATUS.CONTACT:
      return "contact";
    case SELLING_STATUS.UNAVAILABLE:
      return "unavailable";
    default:
      return "";
  }
};

function SelectStatus({ sellingStatus, id, record, handleOpenDrawerEditProduct }) {
  const [status, setStatus] = useState(sellingStatus);
  const [isModalContact, setIsModalContact] = useState(false);
  const [isModalAvailable, setIsModalAvailable] = useState(false);

  function checkAllZero(obj) {
    if (obj === null) {
      return true;
    }
    for (let key in obj) {
      return obj[key] === 0;
    }
  }

  const { handleUpdateProductStatus, loading } = useUpdateProductStatus();

  const handleOpenModalContact = () => {
    setIsModalContact(true);
  };

  const handleCancelModalContact = () => {
    setIsModalContact(false);
  };

  const handleOpenModalAvailable = () => {
    setIsModalAvailable(true);
  };

  const handleCancelModalAvailable = () => {
    setIsModalAvailable(false);
  };

  const handleChangeProductStatus = async (value) => {
    try {
      switch (value) {
        case SELLING_STATUS.UNAVAILABLE:
          await handleUpdateProductStatus({ ids: [id], sellingStatus: value })
            .then(() => {
              notifySuccess();
              setStatus(value);
            })
            .catch((err) => {
              notifyError(err);
              setStatus(sellingStatus);
            });
          break;
        case SELLING_STATUS.CONTACT:
          handleOpenModalContact();
          break;
        case SELLING_STATUS.AVAILABLE:
          handleOpenModalAvailable();
          break;
        default:
          break;
      }
    } catch (error) {
      console.info(error);
    }
  };

  const handleOkModalContact = async () => {
    try {
      return handleUpdateProductStatus({ ids: [id], sellingStatus: SELLING_STATUS.CONTACT })
        .then(() => {
          setStatus(SELLING_STATUS.CONTACT);
          notifySuccess();
          handleCancelModalContact();
        })
        .catch((err) => {
          notifyError(err);
          setStatus(sellingStatus);
        });
    } catch (error) {
      console.info(error);
    }
  };

  const handleOkModalAvailable = async () => {
    try {
      if (checkAllZero(record?.productPrices)) {
        handleOpenDrawerEditProduct && handleOpenDrawerEditProduct(record, (sellingStatus = SELLING_STATUS.AVAILABLE));
        handleCancelModalAvailable();
      }
      if (!checkAllZero(record?.productPrices)) {
        handleUpdateProductStatus({ ids: [id], sellingStatus: SELLING_STATUS.AVAILABLE })
          .then(() => {
            setStatus(SELLING_STATUS.AVAILABLE);
            notifySuccess();
            handleCancelModalAvailable();
          })
          .catch((err) => {
            notifyError(err);
            setStatus(sellingStatus);
          });
      }
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    setStatus(sellingStatus);
  }, [record]);

  return (
    <div className="css-select-status">
      <Select
        loading={loading}
        className={switchCss(status)}
        defaultValue={status}
        value={status}
        style={{
          width: 120,
        }}
        onChange={handleChangeProductStatus}
        options={SELLING_STATUS_OPTIONS}
      />
      {/* Contact */}
      <CustomModal
        visible={isModalContact}
        icon={Img.QuestionIcon}
        closable={false}
        centered
        footer={false}
        onOpen={handleOpenModalContact}
        onCancel={handleCancelModalContact}
        onOke={handleOkModalContact}
        buttonLoading={loading}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text>Bạn có chắc chắn cập nhật trạng thái của sản phẩm không?</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography>
              <Typography.Text strong>Nếu xác nhận:</Typography.Text>
              <Typography.Text type="warning">
                <ul style={{ margin: 0 }}>
                  {!record?.category?.isActive && (
                    <li>
                      <span style={{ color: "black" }}>
                        Ngành hàng của sản phẩm này hiện tại đang không được kích hoạt.
                      </span>{" "}
                      Việc kích hoạt sản phẩm này đồng nghĩa với việc kích hoạt ngành hàng{" "}
                      {!record?.category?.parent?.parent?.isActive && record?.category?.parent?.parent?.name + "/"}{" "}
                      {!record?.category?.parent?.isActive && record?.category?.parent?.name + "/"}{" "}
                      {!record?.category?.isActive && record?.category?.name}.
                    </li>
                  )}
                  <li>
                    Giá của sản phẩm sẽ bằng 0đ <span style={{ color: "black" }}>và</span> trạng thái của sản phẩm trên
                    app/web đại lý sẽ là Liên hệ.
                  </li>
                </ul>
              </Typography.Text>
            </Typography>
          </Col>
        </Row>
      </CustomModal>
      {/* Available */}
      <CustomModal
        visible={isModalAvailable}
        icon={Img.QuestionIcon}
        closable={false}
        centered
        footer={false}
        onOpen={handleOpenModalAvailable}
        onCancel={handleCancelModalAvailable}
        buttonLoading={loading}
        onOke={handleOkModalAvailable}
        okeButtonLabel={checkAllZero(record?.productPrices) ? "Đi tới cập nhật giá" : "Xác nhận"}
      >
        <Row gutter={[16, 8]}>
          {record?.category?.isActive && checkAllZero(record?.productPrices) && (
            <Col span={24}>
              <Typography.Text>
                Để cập nhật trạng thái của sản phẩm, vui lòng cập nhật đủ 4 cấp giá cho sản phẩm.
              </Typography.Text>
            </Col>
          )}
          {record?.category?.isActive && !checkAllZero(record?.productPrices) && (
            <Col span={24}>
              <Typography.Text>Bạn có chắc chắn muốn kinh doanh sản phẩm này không ?</Typography.Text>
            </Col>
          )}
          {!record?.category?.isActive && checkAllZero(record?.productPrices) && (
            <>
              <Col span={24}>
                <Typography.Text>Bạn có chắc chắn cập nhật trạng thái của sản phẩm không?</Typography.Text>
              </Col>
              <Col span={24}>
                <Typography>
                  <Typography.Text strong>Nếu xác nhận:</Typography.Text>
                  <Typography.Text type="warning">
                    <ul style={{ margin: 0 }}>
                      <li>
                        <span style={{ color: "black" }}>
                          Ngành hàng của sản phẩm này hiện tại đang không được kích hoạt.
                        </span>{" "}
                        Việc kích hoạt sản phẩm này đồng nghĩa với việc kích hoạt ngành hàng{" "}
                        {!record?.category?.parent?.parent?.isActive && record?.category?.parent?.parent?.name + "/"}{" "}
                        {!record?.category?.parent?.isActive && record?.category?.parent?.name + "/"}{" "}
                        {!record?.category?.isActive && record?.category?.name}.
                      </li>
                      <li>
                        <span style={{ color: "black" }}>Vui lòng cập nhật đủ 4 cấp giá cho sản phẩm.</span>
                      </li>
                    </ul>
                  </Typography.Text>
                </Typography>
              </Col>
            </>
          )}
          {!record?.category?.isActive && !checkAllZero(record?.productPrices) && (
            <>
              <Col span={24}>
                <Typography.Text>Ngành hàng của sản phẩm này hiện tại đang không được kích hoạt.</Typography.Text>
              </Col>
              <Col span={24}>
                <Typography>
                  <Typography.Text strong>Nếu xác nhận:</Typography.Text>
                  <Typography.Text type="warning">
                    <ul style={{ margin: 0 }}>
                      <li>
                        Việc kích hoạt sản phẩm này đồng nghĩa với việc kích hoạt ngành hàng{" "}
                        {!record?.category?.parent?.parent?.isActive && record?.category?.parent?.parent?.name + "/"}{" "}
                        {!record?.category?.parent?.isActive && record?.category?.parent?.name + "/"}{" "}
                        {!record?.category?.isActive && record?.category?.name}.
                      </li>
                    </ul>
                  </Typography.Text>
                </Typography>
              </Col>
            </>
          )}
        </Row>
      </CustomModal>
    </div>
  );
}

export default SelectStatus;
