import { Col, Row, Table } from "antd";
import PageHeader from "components/PageHeader";
import TabsMenu from "components/CustomTabs/TabsMenu/index";

import React from "react";
// import Toolbar from '../../CollectManagement/component/Toolbar';
// import Table from '../../CollectManagement/component/Table';
import { t } from "i18next";
import Toolbar from "../component/Toolbar";

const List = () => {
  const tabs = [
    {
      title: t("accountant.waitingForCheck"),
    },
    {
      title: t("accountant.checked"),
    },
  ];

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <PageHeader
          pageTitle={t("common.collectManagement")}
          routes={[
            {
              path: "",
              name: t("common.accountant"),
            },
            {
              path: "",
              name: t("common.collectManagement"),
            },
          ]}
        />
      </Col>

      <Col span={24}>
        <TabsMenu tabs={tabs} />
      </Col>

      <Toolbar />

      <Col span={24}>
        <Table />
      </Col>
    </Row>
  );
};

export default List;
