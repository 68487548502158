import { Button, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useConfirmCompleteSalesReturn } from "hooks/recommendimport";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

export const ButtonCompleteInportRequest = ({ record, type }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const { handleConfirmCompleteSalesReturn, loading } = useConfirmCompleteSalesReturn();
  const handleComplete = async () => {
    await handleConfirmCompleteSalesReturn({ id: record?.id })
      .then(() => {
        notify.success({
          message: t("warehouse.salesReturn.messagecompleterequest"),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  return (
    <CustomModal
      {...{
        customComponent: (
          <Button type={type} className="button-action-recommend button-action-complete button-offers-recommend">
            {t("warehouse.salesReturn.confirmComplete")}
          </Button>
        ),
        footer: false,
        message: (
          <span>
            {t("warehouse.salesReturn.questionconfirmComplete")}{" "}
            <Typography.Link strong>{record?.code}</Typography.Link> {t("warehouse.recommendImport.no")}
          </span>
        ),
        centered: true,
        styleButton: "notButton",
        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={handleComplete} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    />
  );
};
