import { gql } from "@apollo/client";

export const SALES_CHANNEL_LIST = gql`
  query SalesChannelList($filters: SaleChannelFilters) {
    saleChannel {
      listSalesChannel(filters: $filters) {
        id
        name
        type
      }
    }
  }
`;

export const SALES_CHANNEL_INPUT_OPTIONS = gql`
  query SalesChannelInputOptions($filters: SaleChannelFilters) {
    saleChannel {
      listSalesChannel(filters: $filters) {
        id
        name
      }
    }
  }
`;

export const SALES_CHANNEL_WITH_IDS = gql`
  query SalesChannelWithIds($filters: SaleChannelFilters) {
    saleChannel {
      listSalesChannel(filters: $filters) {
        id
        name
      }
    }
  }
`;
