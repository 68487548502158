import { Col, Row, Space, Tag, Typography } from "antd";
import CustomCard from "components/CustomCard";
import { SELLER_TYPE, sellerTypeLabel } from "features/PotentialCustomer/constants";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";
import GeneralInfoCard from "./GeneralInfoCard";
import { convertPaymentMethod } from "features/Agent/constants";

function SellerDetail({ dataDetail }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <Typography.Text strong>{t("potentialCustomer.sellerType")}</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>
              <Tag color={dataDetail?.type === SELLER_TYPE.COMPANY ? "geekblue" : "magenta"}>
                {sellerTypeLabel?.[dataDetail?.type] || "--"}
              </Tag>
              {dataDetail?.isSupplier && (
                <Typography.Text type="warning" italic>
                  {" "}
                  ({t("potentialCustomer.isSeller")})
                </Typography.Text>
              )}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <GeneralInfoCard data={dataDetail} />
      </Col>
      {dataDetail?.type === SELLER_TYPE.COMPANY && (
        <Col span={24}>
          <CustomCard
            title={
              <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                {t(`potentialCustomer.form.contactInfo`)}
              </Typography.Text>
            }
            content={
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Typography.Text strong>{t("potentialCustomer.form.contact")}</Typography.Text>
                    </Col>
                    <Col span={16}>{dataDetail?.representName || "--"}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>
                    </Col>
                    <Col span={16}>{dataDetail?.representPhone || "--"}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>
                    </Col>
                    <Col span={16}>{dataDetail?.representEmail || "--"}</Col>
                  </Row>
                </Col>
              </Row>
            }
          />
        </Col>
      )}
      <Col span={24}>
        <CustomCard
          title={
            <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
              {t(`potentialCustomer.form.paymentMethod`)}
            </Typography.Text>
          }
          content={
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.payments")}</Typography.Text>
                  </Col>
                  <Col span={16}>{convertPaymentMethod(dataDetail?.paymentMethod) || "--"}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.debtLimit")}</Typography.Text>
                  </Col>
                  <Col span={16}>{formatCurrency(dataDetail?.debtLimit) || "--"}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.debtAge")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    {dataDetail?.debtAge || "--"} {t("common.DAY")}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.avaiLimit")}</Typography.Text>
                  </Col>
                  <Col span={16}>{formatCurrency(dataDetail?.availabilityLimit) || "--"}</Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </Col>
      {!(dataDetail?.type === SELLER_TYPE.PERSONAL && dataDetail?.isSupplier) && (
        <Col span={24}>
          <CustomCard
            title={
              <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                {t(`potentialCustomer.form.vatInfo`)}
              </Typography.Text>
            }
            content={
              dataDetail?.type === SELLER_TYPE.PERSONAL ? (
                <Space direction="vertical">
                  {dataDetail?.bankAccounts?.length ? (
                    dataDetail?.bankAccounts?.map((item, index) => {
                      return (
                        <Row gutter={[0, 8]} style={{ background: "#F7F8FB", padding: "8px", borderRadius: "8px" }}>
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Typography.Text strong>{t("potentialCustomer.form.bankName")}</Typography.Text>
                              </Col>
                              <Col span={16}>{item?.name || "--"}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Typography.Text strong>{t("potentialCustomer.form.bankNumber")}</Typography.Text>
                              </Col>
                              <Col span={16}>{item?.bankAccountNumber || "--"}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Typography.Text strong>{t("potentialCustomer.form.branch")}</Typography.Text>
                              </Col>
                              <Col span={16}>{item?.bankBranch || "--"}</Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <Typography.Text>{t("potentialCustomer.noBankAccount")}</Typography.Text>
                  )}
                </Space>
              ) : (
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <div>
                          <Typography.Text style={{ textWrap: "pretty" }} strong>
                            {t("potentialCustomer.form.legalRepresentative")}
                          </Typography.Text>
                          <br />
                          <Typography.Text style={{ textWrap: "pretty" }} strong>
                            {t("potentialCustomer.form.legalRepresentative1")}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={16}>{dataDetail?.vatInfo?.representative || "--"}</Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>
                      </Col>
                      <Col span={16}>{dataDetail?.vatInfo?.telephone || "--"}</Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <div>
                          <Typography.Text style={{ textWrap: "pretty" }} strong>
                            {t("potentialCustomer.form.registeredBusinessAddress")}
                          </Typography.Text>
                          <br />
                          <Typography.Text style={{ textWrap: "pretty" }} strong>
                            {t("potentialCustomer.form.registeredBusinessAddress1")}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={16}>{dataDetail?.vatInfo?.address || "--"}</Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>
                      </Col>
                      <Col span={16}>{dataDetail?.vatInfo?.email || "--"}</Col>
                    </Row>
                  </Col>
                </Row>
              )
            }
          />
        </Col>
      )}
      {dataDetail?.isSupplier && (
        <Col span={24}>
          <CustomCard
            title={
              <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                {t(`potentialCustomer.form.infoBankTitle`)}
              </Typography.Text>
            }
            content={
              <Space direction="vertical">
                {dataDetail?.bankAccounts?.length ? (
                  dataDetail?.bankAccounts?.map((item, index) => {
                    return (
                      <Row gutter={[0, 8]} style={{ background: "#F7F8FB", padding: "8px", borderRadius: "8px" }}>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.bankName")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.name || "--"}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.bankNumber")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.bankAccountNumber || "--"}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <Typography.Text strong>{t("potentialCustomer.form.branch")}</Typography.Text>
                            </Col>
                            <Col span={16}>{item?.bankBranch || "--"}</Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography.Text>{t("potentialCustomer.noBankAccount")}</Typography.Text>
                )}
              </Space>
            }
          />
        </Col>
      )}
      <Col span={24}>
        <CustomCard
          title={
            <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
              {t(`potentialCustomer.form.loginAccount`)}
            </Typography.Text>
          }
          content={
            <Space direction="vertical">
              {dataDetail?.users?.length ? (
                dataDetail?.users?.map((item, index) => {
                  return (
                    <Row gutter={[0, 8]} style={{ background: "#F7F8FB", padding: "8px", borderRadius: "8px" }}>
                      <Col span={24}>
                        <Row>
                          <Col span={8}>
                            <Typography.Text strong>{t("potentialCustomer.form.loginAccount")}</Typography.Text>
                          </Col>
                          <Col span={16}>{item?.email || "--"}</Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={8}>
                            <Typography.Text strong>{t("potentialCustomer.form.username")}</Typography.Text>
                          </Col>
                          <Col span={16}>{item?.fullname || "--"}</Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={8}>
                            <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>
                          </Col>
                          <Col span={16}>{item?.telephone || "--"}</Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Typography.Text>{t("potentialCustomer.noAccount")}</Typography.Text>
              )}
            </Space>
          }
        />
      </Col>
    </Row>
  );
}

export default SellerDetail;
