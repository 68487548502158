import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Space } from "antd";
import "./index.scss";

const FilterLayout = ({ filterContent, resetFilter, applyFilter }) => {
  const { t } = useTranslation();

  const handleResetFilter = (e) => {
    resetFilter && resetFilter(e);
  };

  const handleApplyFilter = (e) => {
    applyFilter && applyFilter(e);
  };

  return (
    <menu className="filter-layout">
      <div className="filter-header">
        <p className="title">{t("common.filter")}</p>
        <div className="setting">
          <span onClick={handleResetFilter}>{t("common.reset")}</span>&nbsp;&nbsp;
          <span className="apply" onClick={handleApplyFilter}>
            {t("common.apply")}
          </span>
        </div>
      </div>
      <div className="filter-content">{filterContent}</div>
    </menu>
  );
};

const FilterBooleanRadio = ({ onFilter, labelOfTrue, labelOfFalse, defaultParams }) => {
  const [value, setValue] = useState(defaultParams);

  function resetFilter() {
    setValue(undefined);
  }

  function applyFilter() {
    onFilter(value);
  }

  function handleSelect(e) {
    setValue(e.target.value);
  }

  return (
    <FilterLayout
      resetFilter={resetFilter}
      applyFilter={applyFilter}
      filterContent={
        <Radio.Group onChange={handleSelect} value={value}>
          <Space direction="vertical">
            <Radio value={true}>{labelOfTrue}</Radio>
            <Radio value={false}>{labelOfFalse}</Radio>
          </Space>
        </Radio.Group>
      }
    />
  );
};

export { FilterBooleanRadio };
