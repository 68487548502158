import React from "react";
import "./index.scss";
import Img from "assets/images";
import { t } from "i18next";
import { INPUT_TYPE } from "config/constants";
import FormInput from "components/FormInput";
import { Button, Col, Form, Row, Typography, Upload } from "antd";
import useList from "./useList";
import { dummyRequest, notify, validateFile, validator } from "utils/helperFuncs";
import { SvgIcon } from "assets/icons";
import CustomButton from "components/CustomButton";
import CustomModal from "components/CustomModal";
const ListProfile = () => {
  const {
    form,
    dataBranch,
    cities,
    districts,
    departments,
    roles,
    convertOptions,
    wards,
    changeUpdate,
    isEdit,
    handleUpdate,
    handleCancel,
    avatar,
    handleChangeCity,
    handleChangeDistrict,
    uploading,
    onUploadChange,
    loadingUpdate,
  } = useList();
  return (
    <div className="list-profile-container">
      <Form form={form} onFinish={handleUpdate}>
        <Row style={{ justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <div className="page-title">{t("account.infoAccount")}</div>
          {isEdit ? (
            <Row style={{ gap: "10px" }}>
              <CustomModal
                {...{
                  customComponent: <CustomButton title={t("common.cancel")} size="large" className="button-edit" />,
                  footer: false,
                  message: t("account.messageSaveInfo"),
                  centered: true,
                  onOke: handleCancel,
                }}
              />

              <CustomButton
                title={t("account.saveinfo")}
                type="primary"
                size="large"
                htmlType="submit"
                className="button-edit"
                loading={loadingUpdate}
              />
            </Row>
          ) : (
            <Button type="primary" size="large" className="button-edit" onClick={changeUpdate}>
              {t("account.editInfo")}
            </Button>
          )}
        </Row>
        <div className="card-content">
          <div className="card-info-person">
            <div className="card-avatar">
              <div className="avatar">
                <img alt="avatar" src={avatar?.base64String || avatar?.src || Img.AvatarPlaceHolder} />
                <div className="upload" style={{ background: isEdit ? "#ffffffcc" : "transparent" }}>
                  <Upload
                    loading={uploading}
                    showUploadList={false}
                    onChange={onUploadChange}
                    customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
                    beforeUpload={(file) => {
                      if (!validateFile(file)) {
                        notify.error({
                          message: t("uploadDocument.limitErrorMessage"),
                          description: file?.name,
                        });
                        return Upload.LIST_IGNORE;
                      }
                      return validateFile(file);
                    }}
                  >
                    {isEdit ? (
                      <div className="icon_upload">
                        <SvgIcon.IconUploadImage />
                        <span> {t("account.uploadImage")}</span>
                      </div>
                    ) : (
                      <div className="icon_upload"></div>
                    )}
                  </Upload>
                </div>
              </div>
              <p className="title-rules">
                <span>{t("account.conditionAvatar")}</span>
                <div>{t("account.maxFiveMB")}</div>
              </p>
            </div>
            <div className="card-avatar">
              <div>
                <p className="title">{t("account.infoPerson")}</p>
                <div className="card-info">
                  <div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.code")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.INPUT,
                          formItemOptions: {
                            readOnly: true,
                            rules: [{ required: true, message: t("account.pleaseEnterCode") }],
                          },
                          inputOptions: {
                            placeholder: t("account.pleaseEnterCode"),
                            optionFilterProp: "label",
                            size: "large",
                            readOnly: !isEdit,
                            disabled: true,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.phoneNumber")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.INPUT,
                          formItemOptions: {
                            name: "telephone",
                            rules: [
                              { required: false, message: t("account.pleaseEnterPhoneNumber") },
                              validator({
                                type: "phone",
                              }),
                            ],
                          },
                          inputOptions: {
                            placeholder: t("account.enterMax"),
                            mode: "multiple",
                            allowClear: true,
                            showSearch: true,
                            filterOption: false,
                            optionFilterProp: "label",
                            placement: "topLeft",
                            size: "large",
                            readOnly: !isEdit,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.province")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.SELECT,
                          formItemOptions: {
                            name: "cityID",
                          },
                          inputOptions: {
                            placeholder: t("account.enterProvince"),
                            size: "large",
                            readOnly: true,
                            options: cities?.map(({ id, name }) => ({
                              label: name,
                              value: id,
                              disabled: !isEdit,
                            })),
                            onChange: handleChangeCity,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.wards")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.SELECT,
                          formItemOptions: {
                            name: "wardID",
                          },
                          inputOptions: {
                            placeholder: t("account.pleaseSelectWards"),
                            size: "large",
                            options: wards?.map(({ id, name }) => ({
                              label: name,
                              value: id,
                              disabled: !isEdit,
                            })),
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.name")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.INPUT,
                          formItemOptions: {
                            name: "fullname",
                            rules: [{ required: true, message: t("account.pleaseEnterName") }],
                          },
                          inputOptions: {
                            size: "large",
                            placeholder: t("account.enterName"),
                            maxLength: 255,
                            readOnly: !isEdit,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{"Email"}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.INPUT,
                          formItemOptions: {
                            name: "email",
                            rules: [
                              { required: true, message: t("account.pleaseWenterEmail") },
                              validator({
                                type: "email",
                              }),
                            ],
                          },
                          inputOptions: {
                            placeholder: t("account.enterEmailForLogin"),
                            mode: "multiple",
                            allowClear: true,
                            showSearch: true,
                            filterOption: false,
                            optionFilterProp: "label",
                            placement: "topLeft",
                            size: "large",
                            readOnly: !isEdit,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.district")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.SELECT,
                          formItemOptions: {
                            name: "districtID",
                          },
                          inputOptions: {
                            size: "large",
                            placeholder: t("account.pleaseSelectDistrict"),
                            options: districts?.map(({ id, name }) => ({
                              label: name,
                              value: id,
                              disabled: !isEdit,
                            })),
                            onChange: handleChangeDistrict,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <Col span={24}>
                        <Typography.Text className="sub-title">
                          <b>{t("account.address")}</b>
                        </Typography.Text>
                      </Col>
                      <FormInput
                        {...{
                          type: INPUT_TYPE.INPUT,
                          formItemOptions: {
                            name: "address",
                          },
                          inputOptions: {
                            placeholder: t("account.enterAdress"),
                            showCount: true,
                            size: "large",
                            readOnly: !isEdit,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-info-majob">
            <p className="title">{t("warehouse.create.addInfo")}</p>
            <div className="card-info">
              <div>
                <Col span={24}>
                  <Typography.Text className="sub-title">
                    <b>{t("account.branch")}</b>
                  </Typography.Text>
                </Col>
                <FormInput
                  {...{
                    type: INPUT_TYPE.SELECT,
                    formItemOptions: {
                      name: "branchIDs",
                      rules: [{ required: true, message: t("account.messageSlectBranch") }],
                    },
                    inputOptions: {
                      placeholder: t("account.selectBranch"),
                      mode: "multiple",
                      allowClear: true,
                      showSearch: true,
                      filterOption: false,
                      optionFilterProp: "label",
                      placement: "topLeft",
                      size: "large",
                      disabled: true,
                      options: dataBranch,
                    },
                  }}
                />
              </div>
              <div>
                <Col span={24}>
                  <Typography.Text className="sub-title">
                    <b>{t("account.department")}</b>
                  </Typography.Text>
                </Col>
                <FormInput
                  {...{
                    type: INPUT_TYPE.SELECT,
                    formItemOptions: {
                      name: "departmentID",
                    },
                    inputOptions: {
                      placeholder: t("account.selectDepartment"),
                      size: "large",
                      disabled: true,
                      options: convertOptions(departments),
                    },
                  }}
                />
              </div>
              <div>
                <Col span={24}>
                  <Typography.Text className="sub-title">
                    <b>{t("account.roles")}</b>
                  </Typography.Text>
                </Col>
                <FormInput
                  {...{
                    type: INPUT_TYPE.SELECT,
                    formItemOptions: {
                      name: "roleIDs",
                    },
                    inputOptions: {
                      placeholder: t("account.selectRoles"),
                      mode: "multiple",
                      allowClear: true,
                      showSearch: true,
                      filterOption: false,
                      optionFilterProp: "label",
                      placement: "topLeft",
                      size: "large",
                      disabled: true,
                      options: convertOptions(roles),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ListProfile;
