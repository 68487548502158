import AccountantList from "./AccountantList";
import { Icon } from "assets/icons";
import TransactionHistory from "./TransactionHistory";
import { USER_PERMISSIONS } from "config/constants";
import CodManagementEdit from "./CodManagement/CodManagementEdit";
import CodManagementDetail from "./CodManagement/CodManagementDetail";
import CodManagementList from "./CodManagement/CodManagementList";
import ProfitReconciliationList from "features/Accountant/ProfitReconciliation/List";

const profitPermission = [
  USER_PERMISSIONS.ORDER_RECONCILIATION_VIEW,
  USER_PERMISSIONS.ORDER_RECONCILIATION_DETAIL,
  USER_PERMISSIONS.ORDER_RECONCILIATION_START,
  USER_PERMISSIONS.ORDER_RECONCILIATION_COMPLETE,
  USER_PERMISSIONS.ORDER_RECONCILIATION_REJECT,
];

const router = [
  {
    key: "accountant",
    title: "common.accountant",
    icon: <Icon.CustomIcon icon={Icon.faUsers} />,
    permissions: [
      USER_PERMISSIONS.ACCOUNTANT_VIEW_SELLER_LIST,
      USER_PERMISSIONS.ACCOUNTANT_VIEW_ACCOUNTANT_LIST,
      USER_PERMISSIONS.ACCOUNTANT_VIEW_SELLER_TX_HISTORY,
      USER_PERMISSIONS.ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY,
    ],
    children: [
      {
        key: "accountant-list",
        title: "common.accountantList",
        path: "/accountant-list",
        component: AccountantList,
        exact: true,
        permissions: [USER_PERMISSIONS.ACCOUNTANT_VIEW_SELLER_LIST, USER_PERMISSIONS.ACCOUNTANT_VIEW_ACCOUNTANT_LIST],
      },
      {
        key: "transaction-history",
        title: "common.transactionHistory",
        path: "/transaction-history",
        component: TransactionHistory,
        exact: true,
        permissions: [
          USER_PERMISSIONS.ACCOUNTANT_VIEW_SELLER_TX_HISTORY,
          USER_PERMISSIONS.ACCOUNTANT_VIEW_ACCOUNTANT_TX_HISTORY,
        ],
      },
      {
        key: "cod-management",
        title: "common.collectManagement",
        path: "/cod-management",
        component: CodManagementList,
        exact: true,
      },
      {
        key: "profit-reconciliation",
        title: "Đối soát lợi nhuận",
        path: "/profit-reconciliation",
        component: ProfitReconciliationList,
        exact: true,
        permissions: profitPermission,
      },
    ],
  },
  {
    key: "cod-management/edit",
    path: "/cod-management/edit/:id",
    component: CodManagementEdit,
    exact: true,
  },
  {
    key: "cod-management/detail",
    path: "/cod-management/detail/:id",
    component: CodManagementDetail,
    exact: true,
  },
];

export default router;
