import React from "react";
import CustomTable from "components/CustomTable";

function TableSellerGroup({ columns, data, loading, paginationData, onTableChange }) {
  return (
    <CustomTable
      rowKey={(record) => record?.id}
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={onTableChange}
      pagination={{
        total: paginationData?.total,
        pageSize: paginationData?.limit,
        current: paginationData?.offset / paginationData?.limit + 1 || 1,
        showSizeChanger: false,
      }}
    />
  );
}

export default TableSellerGroup;
