import { Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import PageHeader from "components/PageHeader";
import { useGetCountPotentialCustomer } from "hooks/potentialCustomer/query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import CustomerCompletedTab from "./PotentialCustomerList/CustomerCompletedTab/customerCompletedTab";
import CustomerNewTab from "./PotentialCustomerList/CustomerNewTab/customerNewTab";
import StopCaringTab from "./PotentialCustomerList/StopCaringTab/stopCaringTab";
import TakingCareTab from "./PotentialCustomerList/TakingCareTab/takingCareTab";
import { POTENTIAL_TABS } from "./constants";

function PotentialCustomerTabs() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.statuses || POTENTIAL_TABS.NEW);

  const { newCount, takingCareCount, stopCount, completeCaringCount } = useGetCountPotentialCustomer();

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    buildQueryString({
      params: { statuses: activeKey },
    });
  };

  const tabList = [
    {
      key: POTENTIAL_TABS.NEW,
      tab: t("potentialCustomer.tabs.new", { count: newCount || 0 }),
    },
    {
      key: POTENTIAL_TABS.TAKING_CARE,
      tab: t("potentialCustomer.tabs.takingCare", { count: takingCareCount || 0 }),
    },
    {
      key: POTENTIAL_TABS.STOP_CARING,
      tab: t("potentialCustomer.tabs.stopTaking", { count: stopCount || 0 }),
    },
    {
      key: POTENTIAL_TABS.COMPLETED,
      tab: t("potentialCustomer.tabs.completed", { count: completeCaringCount || 0 }),
    },
  ];
  const contentList = {
    [POTENTIAL_TABS.NEW]: <CustomerNewTab />,
    [POTENTIAL_TABS.TAKING_CARE]: <TakingCareTab />,
    [POTENTIAL_TABS.STOP_CARING]: <StopCaringTab />,
    [POTENTIAL_TABS.COMPLETED]: <CustomerCompletedTab />,
  };

  useEffect(() => {
    buildQueryString({
      params: {
        statuses: GET_QUERY.statuses || POTENTIAL_TABS.NEW,
        ...GET_QUERY,
      },
    });
  }, [GET_QUERY.statuses]);

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          pageTitle={t("potentialCustomer.customerList")}
          routes={[
            {
              path: "/setting",
              name: t("seller.systemSetting"),
            },
            {
              path: "#",
              name: t("potentialCustomer.customerManagement"),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
        />
      </Col>
    </Row>
  );
}

export default PotentialCustomerTabs;
