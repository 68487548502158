import { Col, Space, Typography } from "antd";

import { SALES_RETURN, SaleReturnStatus, setColorStatusImportRequest } from "features/Purchase/constant";
import { t } from "i18next";
import styles from "../../../index.module.scss";

function TitleCard({ title, value, status, isDetail, waitingApprovalCount = 0 }) {
  return (
    <Col
      span={24}
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        width: "100%",
      }}
    >
      <Space>
        <Typography.Text className={styles["title-card"]}>{title}</Typography.Text>
        <Typography.Text style={{ color: "#2246DC" }} className={styles["title-card"]}>
          {value && `${value || "---"}`}
        </Typography.Text>
      </Space>
      <div style={{ textAlign: "right", lineHeight: "20px" }}>
        <span
          style={{
            color: setColorStatusImportRequest(status),
          }}
        >
          {status === SALES_RETURN.WAITING_APPROVAL
            ? `${SaleReturnStatus[status]} ${waitingApprovalCount > 1 ? t("seller.times") + waitingApprovalCount : ""}`
            : SaleReturnStatus[status]}
        </span>
        {isDetail && (
          <div>
            <Typography.Link italic style={{ fontSize: "14px", color: "#2246DC", fontWeight: "500" }}>
              {t("seller.labelInput.seeDetail")}
            </Typography.Link>
          </div>
        )}
      </div>
    </Col>
  );
}

const SubTitleCard = ({ subTitle }) => {
  return <Typography.Text className="title-sub-title-card">{subTitle}</Typography.Text>;
};

export { SubTitleCard, TitleCard };
