import { Spin } from "antd";
import CampaignForm from "../Form";
import useCreateForm from "./useCreateForm";

const CreateForm = () => {
  const {
    form,
    handleValuesChange,
    isApplyWithPromotion,
    promoType,
    handleCheckIsApplyWithPromo,
    loadingCheckDuplicateCodes,
    dataConfirmModal,
    visibleConfirmModal,
    handleOpenConfirmModal,
    handleCreateCampaign,
    handleCancelConfirmModal,
    createLoading,
    indexErrorCombo,
  } = useCreateForm();

  return (
    <Spin spinning={loadingCheckDuplicateCodes || createLoading}>
      <CampaignForm
        form={form}
        handleValuesChange={handleValuesChange}
        isApplyWithPromotion={isApplyWithPromotion}
        promoType={promoType}
        handleCheckIsApplyWithPromo={handleCheckIsApplyWithPromo}
        loadingCheckDuplicateCodes={loadingCheckDuplicateCodes}
        dataConfirmModal={dataConfirmModal}
        visibleConfirmModal={visibleConfirmModal}
        handleOpenConfirmModal={handleOpenConfirmModal}
        handleCancelConfirmModal={handleCancelConfirmModal}
        handleActionCampaign={handleCreateCampaign}
        createLoading={createLoading}
        indexErrorCombo={indexErrorCombo}
      />
    </Spin>
  );
};

export default CreateForm;
