import { Space, Tooltip, Typography } from "antd";
import CustomTitle from "features/Serial/components/CustomTitle";
import { ModalSelectSerial } from "features/Serial/SerialImport/CreateSerial/components/TypeReturn/components/ModalSelectSerial";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export const useTable = ({ form, handleGetTableOrder }) => {
  const renderLack = ({ quantity, scanned }) => {
    if (scanned?.length) {
      return quantity - scanned?.length;
    } else {
      return quantity || 0;
    }
  };

  const renderSubTotalTable = (record) => {
    if (record.quantity < record?.serialItems?.length) {
      return (
        <Typography.Text style={{ color: "red" }}>
          {t("serialIE.productInfo.residual")}: {record?.serialItems?.length - record?.quantity}
        </Typography.Text>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>;
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <Typography.Text style={{ color: "#ef9351" }}>
          {t("common.lack")}: {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
        </Typography.Text>
      );
    }
  };
  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link strong style={{ textAlign: "start", margin: 0 }}>
            <Link to={`/product/detail/${record?.productID}`}>{record?.product?.code}</Link>
          </Typography.Link>
          <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
            <Tooltip title={record?.product?.name}>
              <span>{truncateAddDots(record?.product?.name, 60)}</span>
            </Tooltip>
          </Typography.Text>
          <Typography.Text style={{ textAlign: "end", margin: 0 }} italic>
            {record?.product?.uom?.name}
          </Typography.Text>
        </div>
      ),
      isHidden: false,
      width: 250,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.selectSerial.warrantyDate")} />,
      render: (_, record, index) => (
        <Typography.Text>
          {record?.productWarranty} {convertUnit("MONTH")}
        </Typography.Text>
      ),
      width: 200,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.byOrder")} />,
      render: (_, record, index) => <Typography.Text>{record?.quantity}</Typography.Text>,
      width: 120,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.productInfo.return")} />,
      width: 120,
      render: (_, record, index) => {
        return (
          <Space direction="vertical">
            <Typography.Text className={styles["title"]}>{record?.serialItems?.length || 0}</Typography.Text>
            {renderSubTotalTable(record)}
          </Space>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.detailSerialC")} />,
      render: (_, record, index) => {
        return (
          <ModalSelectSerial
            form={form}
            infoCard={record}
            record={record?.warrantyNotes}
            index={index}
            handleGetTableOrder={handleGetTableOrder}
          />
        );
      },
    },
  ];
  return { columns };
};
