import { Button, Card, Col, Row, Skeleton, Space, Typography } from "antd";
import CustomCard from "components/CustomCard";
import { useFlags } from "flagsmith/react";
import { useCampaignPermissions } from "hooks/campaign";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import InfoDetail from "./components/InfoDetail";
import InfoMarketing from "./components/InfoMarketing";
import InfoOverView from "./components/InfoOverView";
import ProductPromoDetail from "./components/ProductPromoDetail";

import "./index.scss";

const ProductPromo = ({ data, refetch, loadingDetail }) => {
  const { canUpdateCampaign } = useCampaignPermissions();
  const history = useHistory();
  const { id } = useParams();

  const handleRedirectUpdate = () => {
    refetch();
    history.push({ pathname: `/campaign/edit/${id}`, state: data });
  };

  const flags = useFlags(["is_hide_button_edit_campaign"]);

  const isHideButtonEditCampaign = !flags.is_hide_button_edit_campaign.enabled;

  const isProductPromo = data?.campaignType === "PRODUCT_PROMO";

  return (
    <section className="campaign-product-promo">
      <Space direction="vertical" size={24} style={{ display: "flex", marginBottom: "40px" }}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <CustomCard
              title={
                <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                  {t("supplier.generalInformation")}
                </Typography.Text>
              }
              content={
                <Skeleton active loading={loadingDetail}>
                  <Col span={24}>
                    <InfoOverView data={data} />
                  </Col>
                </Skeleton>
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <CustomCard
              title={
                <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                  {t("campaign.form.marketingInfo")}
                </Typography.Text>
              }
              content={
                <Skeleton active loading={loadingDetail}>
                  <Col span={24}>
                    <InfoMarketing data={data} />
                  </Col>
                </Skeleton>
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <CustomCard
              title={
                loadingDetail ? (
                  <Skeleton.Button
                    active
                    size={"small"}
                    style={{
                      width: "200px",
                      height: "20px",
                    }}
                  />
                ) : (
                  <Typography.Text strong style={{ color: "rgb(5, 41, 135)", textTransform: "uppercase" }}>
                    {isProductPromo ? t("campaign.detailPromo.productPromo") : t("campaign.detailPromo.infoDetail")}
                  </Typography.Text>
                )
              }
              content={
                <Skeleton active loading={loadingDetail}>
                  {isProductPromo ? <ProductPromoDetail data={data} /> : <InfoDetail data={data} />}
                </Skeleton>
              }
            />
          </Col>
        </Row>
      </Space>
      {canUpdateCampaign && data?.status === "UPCOMING" && (
        <Col span={24}>
          <Card className="form-footer">
            <Button onClick={handleRedirectUpdate} type="primary">
              {t("common.EDIT")}
            </Button>
          </Card>
        </Col>
      )}
    </section>
  );
};

export default ProductPromo;
