import React, { useEffect, useState } from "react";
import { SERIAL_DETAIL_TABS, TOTAL_ITEMS_STATUS } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import TabInfo from "./TabInfo";
import TabRequest from "./TabRequest";
import TabResults from "./TabResults";
import TabSolution from "./TabSolution";

function useTabsMenu({ data, warrantyNote }) {
  const selectTab = {
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE}`]: SERIAL_DETAIL_TABS.SOLUTION,
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN}`]: SERIAL_DETAIL_TABS.RESULTS,
  };

  const [defaultActiveKey, setdefaultActiveKey] = useState();

  const showTabResult = (status) => {
    switch (status) {
      case TOTAL_ITEMS_STATUS.WAITING_FOR_CHECK:
      case TOTAL_ITEMS_STATUS.CHECKING:
      case TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE:
      case TOTAL_ITEMS_STATUS.REPAIRING:
        return false;
      default:
        return true;
    }
  };

  const tabs = [
    {
      title: <span className="title">{t("serial.warrantyRequestInfo")}</span>,
      key: SERIAL_DETAIL_TABS.REQUEST_INFO,
      children: <TabInfo data={data} />,
    },
    {
      title: <span className="title">{t("serial.clientWarrantyDemand")}</span>,
      key: SERIAL_DETAIL_TABS.DEMAND,
      children: <TabRequest data={data} />,
    },
    {
      title: data?.solution && <span className="title">{t("serial.distributeInfo")}</span>,
      key: SERIAL_DETAIL_TABS.SOLUTION,
      children: data?.solution && <TabSolution data={data} warrantyNote={warrantyNote} />,
    },
    {
      title: showTabResult(data?.status) && <span className="title">{t("serial.warrantyResults")}</span>,
      key: SERIAL_DETAIL_TABS.RESULTS,
      children: showTabResult(data?.status) && <TabResults data={data} />,
    },
  ];

  const handleChange = (activeKey) => {
    setdefaultActiveKey(activeKey);
  };

  useEffect(() => {
    setdefaultActiveKey(selectTab?.[data?.status]);
  }, [JSON.stringify(data)]);

  return {
    tabs,
    selectTab,
    defaultActiveKey,
    handleChange,
  };
}

export default useTabsMenu;
