import { useGetDetailPromotionCampaign } from "hooks/campaign";
import { useState } from "react";
import { useParams } from "react-router-dom";

const useCampaignDetail = () => {
  const { id } = useParams();

  const [params] = useState({
    id: id,
    withCart: false,
  });

  const { data, loading, refetch } = useGetDetailPromotionCampaign(params);

  return { data, loading, refetch };
};

export default useCampaignDetail;
