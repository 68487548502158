import { Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import TagCustom from "components/_storybooks/TagCustom";
import CustomCard from "components/CustomCard";
import CustomDescriptions from "components/CustomDescriptions";
import { renderLabelStatus } from "features/Order/helper";
import { compact } from "lodash";
import {
  renderColorTagByPaymentStatus,
  renderPaymentMethod,
  renderStringTagByPaymentStatus,
  tagColorSalesChannel,
} from "pages/App/Order/conts";

function OrderInfo({ loading, order }) {
  return (
    <CustomCard
      content={
        <SpaceCustom gap={12}>
          <DividerCustom fontWeight="bold" children={"Thông tin đơn hàng"} margin={0} />
          <CustomDescriptions
            loading={loading}
            contentMapping={[
              {
                label: "Trạng thái đơn hàng",
                value: renderLabelStatus?.[order?.status],
              },
              {
                label: "Trạng thái thanh toán",
                value: (
                  <TagCustom color={renderColorTagByPaymentStatus(order?.paymentStatus)}>
                    {renderStringTagByPaymentStatus(order?.paymentStatus)}
                  </TagCustom>
                ),
              },
              {
                label: "Người nhận",
                value: (
                  <SpaceCustom>
                    <div>
                      <Typography.Text>
                        {order?.receiverContact?.fullName}
                        <DividerCustom type="vertical" />
                        {order?.receiverContact?.telephone}
                      </Typography.Text>
                    </div>
                    <EllipsisText
                      style={{ color: "#5B6673" }}
                      title={compact([
                        order?.receiverContact?.address,
                        order?.receiverContact?.ward?.name,
                        order?.receiverContact?.district?.name,
                        order?.receiverContact?.city?.name,
                      ]).join(", ")}
                    />
                  </SpaceCustom>
                ),
              },
              {
                label: "Đại lý",
                value: (
                  <SpaceCustom>
                    <Typography.Link target="_blank" strong>
                      {order?.seller?.code}
                    </Typography.Link>
                    <EllipsisText title={order?.seller?.fullName} />
                  </SpaceCustom>
                ),
              },
              {
                label: "Tài khoản tạo đơn",
                value: (
                  <Typography.Text>
                    {order?.createdBy?.fullname} <DividerCustom type="vertical" />
                    <span style={{ color: "#5B6673" }}> {order?.createdBy?.email}</span>
                  </Typography.Text>
                ),
              },
              {
                label: "Kho xuất hàng",
                value: order?.exportedWarehouse?.name,
              },
              {
                label: "Phương thức thanh toán",
                value: renderPaymentMethod(order?.paymentMethod),
              },
              {
                label: "Kênh bán",
                value: (
                  <TagCustom color={tagColorSalesChannel?.[order?.saleChannel?.type]}>
                    {order?.saleChannel?.name}
                  </TagCustom>
                ),
              },
            ]}
          />
        </SpaceCustom>
      }
    />
  );
}

export default OrderInfo;
