import { useCustomerPermissions } from "hooks/potentialCustomer/query";
import { useTranslation } from "react-i18next";
import { REPORT_TYPE } from "../../../constants";

const useChartSelectUser = () => {
  const { t } = useTranslation();
  const { canCustomerReportStaffView, canCustomerReportManagerView } = useCustomerPermissions();

  const chartTypeOptions = [
    {
      value: REPORT_TYPE.PERSONAL,
      label: t("report.personalReport"),
      permission: canCustomerReportStaffView,
    },
    {
      value: REPORT_TYPE.GENERAL,
      label: t("report.generalReport"),
      permission: canCustomerReportManagerView,
    },
  ];

  const filteredTypeOptions = chartTypeOptions
    .filter((item) => item.permission)
    .map(({ label, value }) => ({
      label,
      value,
    }));

  return { filteredTypeOptions, canCustomerReportStaffView, canCustomerReportManagerView };
};

export default useChartSelectUser;
