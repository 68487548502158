import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "components/CustomCard";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";
import "./index.scss";
import ExpandableText from "components/CustomExpandableText";

function VatInfoCard({ notBordered, loadingDetail, dataMulti }) {
  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t("potentialCustomer.form.vatInfo")}
        </Typography.Text>
      }
      content={
        <Skeleton active loading={loadingDetail}>
          <Row className="content-vat-info">
            <Col span={24}>
              {notBordered ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <div>
                      <Typography.Text style={{ textWrap: "pretty" }} strong>
                        {t("potentialCustomer.form.legalRepresentative")}
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ textWrap: "pretty" }} strong>
                        {t("potentialCustomer.form.legalRepresentative1")}
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{dataMulti?.legalRepresentativeName || "--"}</Typography.Text>
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: (
                      <div>
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.legalRepresentative")}
                        </Typography.Text>
                        <br />
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.legalRepresentative1")}
                        </Typography.Text>
                      </div>
                    ),
                    labelAlign: "left",
                    name: "legalRepresentativeName",
                    className: "labelLegalRepresentative",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: false,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.legalRepresentative"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.legalRepresentative"),
                    maxLength: 255,
                  }}
                />
              )}
            </Col>
            <Col span={24}>
              <FormInput
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.telephone")}</Typography.Text>,
                  labelAlign: "left",
                  name: "legalRepresentativeTelephone",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.telephone"),
                      }),
                    },
                    validator({
                      type: "phone",
                    }),
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.telephone"),
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.INPUT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.email")}</Typography.Text>,
                  labelAlign: "left",
                  name: "legalRepresentativeEmail",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.email"),
                      }),
                    },
                    validator({
                      type: "email",
                    }),
                  ],
                }}
                inputOptions={{ ...notBordered, placeholder: t("potentialCustomer.placeHolder.email") }}
              />
            </Col>
            <Col span={24}>
              {notBordered ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <div>
                      <Typography.Text style={{ textWrap: "pretty" }} strong>
                        {t("potentialCustomer.form.registeredBusinessAddress")}
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ textWrap: "pretty" }} strong>
                        {t("potentialCustomer.form.registeredBusinessAddress1")}
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col span={16}>
                    <ExpandableText value={dataMulti?.registeredBusinessAddress || "--"} />
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: (
                      <div>
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.registeredBusinessAddress")}
                        </Typography.Text>
                        <br />
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.registeredBusinessAddress1")}
                        </Typography.Text>
                      </div>
                    ),
                    labelAlign: "left",
                    name: "registeredBusinessAddress",
                    labelCol: { span: 8 },
                    className: "labelRegisteredBusinessAddress",
                    rules: [
                      {
                        required: false,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.registeredBusinessAddress"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.registeredBusinessAddress"),
                    maxLength: 255,
                  }}
                />
              )}
            </Col>
          </Row>
        </Skeleton>
      }
    />
  );
}

export default VatInfoCard;
