import { Divider } from "antd";
import "./index.scss";

function DividerCustom({
  type,
  orientation = "left",
  children,
  colorText = "#052987",
  borderTopColor = "#052987",
  orientationMargin,
  margin,
  style,
  fontWeight,
  ...rest
}) {
  return (
    <Divider
      className="divider-custom"
      type={type}
      orientation={orientation}
      plain
      style={{
        color: colorText,
        fontWeight: fontWeight,
        borderTopColor: borderTopColor,
        margin: margin,
        "--width": type === "vertical" ? "none" : "100%",
        ...style,
      }}
      orientationMargin={orientationMargin}
      {...rest}
    >
      {children}
    </Divider>
  );
}

export default DividerCustom;
