import { Card, Col, Form, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { useCreateNewBid } from "hooks/priceBid/mutation";
import { t } from "i18next";
import React, { useState } from "react";
import { formatCurrency, notify } from "utils/helperFuncs";

const PopupCreateNewBid = ({ record }) => {
  const [form] = Form.useForm();
  const [valuesPrice, setValuesPrice] = useState(record?.currentSuggestedPrice);
  const handleValues = (e) => {
    setValuesPrice(parseInt(e.target.value.replace(/\./g, "")) || 0);
  };
  const currentDeviantPrice = valuesPrice - record?.originalPrice;

  const initialValues = {
    priceBidSessionID: record?.id,
    suggestQuantity: record?.currentSuggestedQuantity,
    suggestPrice: valuesPrice,
  };

  const { handleCreateNewBid, loading } = useCreateNewBid();

  const onCreateNewBid = async () => {
    try {
      const values = await form.validateFields();

      const params = {
        priceBidSessionID: record?.id,
        suggestQuantity: values?.suggestQuantity,
        suggestPrice: values?.suggestPrice,
      };

      handleCreateNewBid(params);
    } catch (error) {
      notify.error({ message: error?.message });
    }
  };
  const handleBlurResset = async () => {
    try {
      const values = await form.validateFields();
      if (values?.suggestQuantity === 0) {
        form.setFieldsValue({
          ...values,
          suggestQuantity: 1,
        });
      }
    } catch (error) {
      form.setFieldsValue({
        suggestQuantity: 1,
      });
    }
  };
  return (
    <CustomModal
      footer={false}
      customComponent={<Typography.Text>{t("product.priceBid.NewRequest")}</Typography.Text>}
      title={<Typography.Title level={4}>{t("product.priceBid.newRequestPriceBid")}</Typography.Title>}
      onOke={onCreateNewBid}
      buttonLoading={loading}
      disabled={valuesPrice === 0 && true}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              style={{ background: "#F7F8FB" }}
              children={
                <Row gutter={[16, 8]}>
                  <Col span={8}>
                    <Typography.Text strong>{t("product.priceBid.nameSeller")}</Typography.Text>
                  </Col>
                  <Col span={16}>{record?.seller?.fullName}</Col>

                  <Col span={8}>
                    <Typography.Text strong>{t("product.priceBid.idProduct")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <ProductLink product={record} />
                  </Col>

                  <Col span={8}>
                    <Typography.Text strong>{t("product.priceBid.nameProduct")}</Typography.Text>
                  </Col>
                  <Col span={16}>{record?.product?.name}</Col>

                  <Col span={8}>
                    <Typography.Text strong>{t("product.priceBid.priceBidDefault")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    {formatCurrency(record?.originalPrice)}/{record?.product?.uom?.name}
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.CURRENCY_INPUT}
              {...{
                formItemOptions: {
                  label: t("product.priceBid.numberOffer"),
                  name: "suggestQuantity",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                  onBlur: handleBlurResset,
                },

                inputOptions: {
                  min: 0,
                  maxLength: 4,
                },
              }}
            />
          </Col>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.CURRENCY_INPUT}
              {...{
                formItemOptions: {
                  label: t("product.priceBid.priceOffer"),
                  name: "suggestPrice",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                  onChange: handleValues,
                  allowClear: true,
                },

                inputOptions: {
                  maxLength: 11,
                  placeholder: "đ/ [đơn vị tính]",
                },
              }}
            />
          </Col>

          <Col span={24}>
            {valuesPrice !== 0 && (
              <Row gutter={[16, 16]}>
                <Col span={8}>{t("product.priceBid.difference")}</Col>
                <Col span={16}>
                  <Typography.Text
                    style={{
                      color: currentDeviantPrice >= 0 ? "#32BF46" : "#D2434D",
                    }}
                    strong
                  >
                    {formatCurrency(currentDeviantPrice)}/ {record?.product?.uom?.name}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24} style={{ marginBottom: "16px" }}>
            <Typography.Text style={{ color: "#EF9351" }} italic>
              {t("product.priceBid.note")}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default PopupCreateNewBid;
