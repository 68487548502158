import { Col, Form, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { getFile } from "utils/helperFuncs";

const lableCol = { span: 24, offset: 0 };

function AddInfo() {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.note")}</Typography.Text>,
              name: "note",
              labelCol: lableCol,
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.note"),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={lableCol}
          name={"fileURLs"}
          getValueFromEvent={getFile}
          valuePropName={"fileList"}
          label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
          extra={t("warrantyRequest.create.noteAttack")}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default AddInfo;
