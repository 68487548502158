import React from "react";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import CustomTags from "components/CustomTags";
import { warrantyStatusOptions, warrantyTypeOptions } from "features/Warranty/constant";
import { buildQueryString } from "utils/helperFuncs";

const useToolbar = ({
  countSelectedRowKeys,
  selectedProduct,
  setIsFilterSelected,
  onChangeParams,
  params,
  setParams,
}) => {
  const history = useHistory();

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const handleChange = (e) => {
    const newParams = {
      ...params,
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: { ...newParams?.filters, ...newParams?.pagination },
    });
    setIsFilterSelected && setIsFilterSelected(e.target.value);
  };

  const handleSelectedData = () => {
    history.push({
      pathname: "/warranty-request/create",
      state: {
        ownedSerials: selectedProduct?.selectedRowKeys,
        ownedSelected: selectedProduct?.selectedRows,
      },
    });
  };

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];

    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const tags = [
    {
      title: t("warranty.warrantyList.saleWarrantyTitle"),
      options: warrantyTypeOptions,
      selected: params?.filters?.types,
      key: "types",
    },
    {
      title: t("warranty.warrantyList.statusTitle"),
      options: warrantyStatusOptions,
      selected: params?.filters?.status,
      key: "status",
    },
  ];

  const handleOnClose = (key) => {
    onChangeParams && onChangeParams({ [`${key}`]: [] });
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return { filterItemsTypeOptions, handleChange, handleSelectedData, renderTags };
};

export default useToolbar;
