import { useLazyQuery, useQuery } from "@apollo/client";
import { MANUFACTURES } from "graphql/manufacturer/query";

export const useGetManufacturers = () => {
  const { loading, data, refetch } = useQuery(MANUFACTURES);
  const convertData = data?.manufacturer?.list.map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });

  return {
    loadingManufactures: loading,
    dataManufactures: convertData,
    refetch,
  };
};

export const useLazyGetManufacturers = () => {
  const [pagination, { loading, data, refetch }] = useLazyQuery(MANUFACTURES);
  const convertData = data?.manufacturer?.list.map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });

  const getDataManufacturers = () => {
    pagination();
  };

  return {
    loadingManufactures: loading,
    dataManufactures: convertData,
    refetch,
    getDataManufacturers,
  };
};
