import { Button, Col, Form, Row, Spin, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import React, { useState } from "react";
import "./index.scss";
import { useGetSuggestedProdGroup } from "hooks/product/product";
import { useHistory } from "react-router-dom";
import { has } from "lodash";
import CustomSelectLoadMore from "components/CustomSelectLoadMore";

const CustomModalRecommendProductName = () => {
  const history = useHistory();
  const [params, setParams] = useState({
    pagination: { offset: 0, limit: 10 },
    filters: { query: "" },
  });
  const [value, setValue] = useState({});

  const {
    data,
    onLoadMoreData,
    hasMoreData,
    paginationData,
    loading: loadingSuggest,
  } = useGetSuggestedProdGroup(params);

  const renderLabel = (item) => (
    <Row gutter={16}>
      <Col span={6}>
        <Typography.Text ellipsis={true} style={{ textAlign: "center" }}>
          {item?.code}
        </Typography.Text>
      </Col>
      <Col span={10}>
        <Typography.Text ellipsis style={{ color: "#2246DC", textAlign: "center" }}>
          {item?.name}
        </Typography.Text>
      </Col>
      <Col span={4}>
        <Typography style={{ textAlign: "center" }}>{item?.totalVariation || 0}</Typography>
      </Col>
    </Row>
  );

  const options = data?.map((item) => ({
    key: item?.id,
    value: item?.name,
    label: renderLabel(item),
  }));

  const onCancel = () => {
    return;
  };

  const handleSubmit = () => {
    if (has(value, "valueSelect")) {
      history.push(`product-group/edit/${value.valueSelect}`);
    } else {
      history.push(`product-group/create`, { productName: value?.valueSearch });
    }
  };

  const onSearch = (value) => {
    setValue({ valueSearch: value });
    setParams({ ...params, filters: { query: value } });
  };

  const handleChange = (value) => {
    setValue({ valueSelect: value });
  };

  const renderMessage = (valueSelected, dataTotal) => {
    if (dataTotal === 0) return t("product.modalAddProduct.weHaveNotFound");
    if (dataTotal > 0)
      return (
        <>
          {t("product.modalAddProduct.weHaveFound")} {dataTotal} {t("product.modalAddProduct.descriptionWeHaveFound")}
        </>
      );
    if (!valueSelected) return t("product.modalAddProduct.pleaseEnter");
    return;
  };

  const renderTableMenu = (originNode) => (
    <>
      <Row gutter={16} style={{ background: "#F7F8FB" }}>
        <Col span={6}>
          <Typography
            style={{
              textAlign: "center",
              padding: "12px 8px",
              color: "#5B6673",
              fontWeight: "bold",
            }}
          >
            {t("product.modalAddProduct.productCode")}
          </Typography>
        </Col>
        <Col span={10}>
          <Typography
            style={{
              textAlign: "center",
              padding: "12px 8px",
              color: "#5B6673",
              fontWeight: "bold",
            }}
          >
            {t("product.modalAddProduct.productName")}
          </Typography>
        </Col>
        <Col span={4}>
          <Typography
            style={{
              textAlign: "center",
              padding: "12px 8px",
              color: "#5B6673",
              fontWeight: "bold",
            }}
          >
            {t("product.modalAddProduct.variantQuantity")}
          </Typography>
        </Col>
      </Row>
      <Spin spinning={loadingSuggest}>{originNode}</Spin>
    </>
  );

  return (
    <CustomModal
      className="modal-recommend-product-name"
      closable={false}
      centered={true}
      footer={false}
      title={<span className="title">{t("product.modalAddProduct.title")}</span>}
      onCancel={onCancel}
      onOke={handleSubmit}
      isBlockCloseOnOke
      customComponent={<Button className="button-add-product">{t("product.addProduct")}</Button>}
      okeButtonLabel={t("common.continue")}
    >
      <p>{renderMessage(value?.valueSelect, paginationData?.total)}</p>
      <Form layout="vertical">
        <Form.Item label={<b>{t("product.modalAddProduct.productName")}</b>} name="productGroup">
          <CustomSelectLoadMore
            options={options}
            onSearch={onSearch}
            onSelect={handleChange}
            placeholder={t("product.modalAddProduct.placeholderProductName")}
            optionClassName="label-custom"
            onLoadMore={onLoadMoreData}
            hasMoreData={hasMoreData}
            dropdownRender={renderTableMenu}
            dropdownMatchSelectWidth={1000}
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default CustomModalRecommendProductName;
