import { Button, Popconfirm, Typography, Form } from "antd";
import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import { v4 as uuidv4 } from "uuid";
import CustomTable from "components/CustomTable";
import StateSwitch from "components/StateSwitch";
import { useWarehouse } from "hooks/warehouse";
import { PRODUCT_INPUT_TYPE } from "pages/App/Product/ProductGroup/conts";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditableCell from "../EditableCell";
import styles from "./index.module.scss";
import { PlusCircleOutlined } from "@ant-design/icons";

const VariantTable = ({
  form,
  attributes,
  products,
  dataSource,
  setDataSource,
  disabledInputInfo,
  disabledInputPrice,
}) => {
  const { warehouses } = useWarehouse();
  const [editingKey, setEditingKey] = useState("");
  const { t } = useTranslation();

  //get option attribute for variant table when use select isVariant true
  const attributesOption = attributes
    ?.filter((attribute) => attribute?.isVariant)
    ?.map((attribute) =>
      attribute?.attributeValues?.filter((attributeValue) => attribute?.attributeValueIDs?.includes(attributeValue?.id))
    )
    ?.filter((attribute) => attribute && attribute.length);

  const addColumnAttributes =
    attributes
      ?.filter((item) => item?.isVariant)
      ?.map((item, indexCol) => {
        const options =
          attributesOption?.at(indexCol)?.map((item) => ({
            label: item?.value,
            value: item?.id,
          })) || [];

        return {
          title: <span className={styles.title}>{item?.name}</span>,
          align: "center",
          editable: true,
          width: "100",
          inputType: PRODUCT_INPUT_TYPE.SELECT,
          nameInput: ["attributeValues", indexCol],
          options: options,
          render: (text, record, indexRow) => {
            const attributes = form.getFieldsValue()?.products?.at(indexRow)?.attributeValues;
            const colOpt = options?.find((item) => attributes?.some((att) => att === item?.value));
            return colOpt?.label;
          },
        };
      }) || [];

  const renderColumnsWarehouse = warehouses?.map((warehouse) => {
    return {
      title: (
        <span className={`${styles.title} ${styles.filter}`}>
          {warehouse?.name}
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </span>
      ),
      width: 335,
      align: "center",
      editable: true,
    };
  });

  const columns = [
    //sticky
    {
      title: (
        <span className={`${styles.title} ${styles.filter}`}>
          {t("product.productForm.status")}
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </span>
      ),
      width: 130,
      fixed: "left",
      align: "center",
      editable: false,
      render: (text, record, index) => <StateSwitch checked={record?.isActive} />, //chuyen form
    },
    {
      title: (
        <p className={styles.title} style={{ textAlign: "center", margin: 0 }}>
          {t("product.productForm.image")}
        </p>
      ),
      width: 200,
      fixed: "left",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.UPLOAD_IMAGE,
      nameInput: ["imageURL"],

      render: (text, record, index) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{
              margin: "auto",
              width: "102px",
              height: "103px",
              borderRadius: "8px",
              objectFit: "none",
            }}
            src={record?.image}
            alt=""
          />
        </div>
      ),
    },
    {
      title: <span className={styles.title}>{t("product.productForm.productCode")}</span>,
      width: 120,
      align: "center",
      fixed: "left",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_PRODUCT_CODE,
      nameInput: ["code"],
      render: (text, record, index) => record?.productCode,
    },
    ...addColumnAttributes,
    //scroll
    {
      title: (
        <div className={`${styles.title} ${styles.filter} ${styles["label-price"]}`}>
          <span>{t("product.productForm.priceLevel1")}</span>
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </div>
      ),
      width: 200,
      align: "center",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_CURRENCY,
      nameInput: ["productPrices", "level1Price"],
      render: (text, record, index) => record?.level1Price,
    },
    {
      title: (
        <div className={`${styles.title} ${styles.filter} ${styles["label-price"]}`}>
          <span>{t("product.productForm.priceLevel2")}</span>
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </div>
      ),
      width: 200,
      align: "center",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_CURRENCY,
      nameInput: ["productPrices", "level2Price"],
      render: (text, record, index) => record?.level2Price,
    },
    {
      title: (
        <div
          className={`${styles.title} ${styles.filter} ${styles["label-price"]}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span>{t("product.productForm.priceLevel3")}</span>
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </div>
      ),
      width: 200,
      align: "center",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_CURRENCY,
      nameInput: ["productPrices", "level3Price"],
      render: (text, record, index) => record?.level3Price,
    },
    {
      title: (
        <div className={`${styles.title} ${styles.filter} ${styles["label-price"]}`}>
          <span>{t("product.productForm.priceLevel4")}</span>
          <Button type="text" size="small" icon={<FontAwesomeIcon icon={Icon.faFilter} />} />
        </div>
      ),
      width: 200,
      align: "center",
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_CURRENCY,
      nameInput: ["productPrices", "level4Price"],

      render: (text, record, index) => record?.level4Price,
    },
    //unused for now
    // {
    //   title: <span className={styles.title}>{t('product.productForm.inStock')}</span>,
    //   children: renderColumnsWarehouse,
    // },
    {
      title: <span className={styles.title}>{t("product.productForm.exportVAT")}</span>,
      align: "center",
      width: 85,
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.SELECT,
      nameInput: ["vat"],
      render: (text, record, index) => (record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.ErrorIcon />),
      options: [
        { value: true, label: <SvgIcon.SuccessIcon /> },
        { value: false, label: <SvgIcon.ErrorIcon /> },
      ],
      initialValue: true,
    },
    {
      title: <span className={styles.title}>{t("product.productForm.percentVAT")}</span>,
      align: "center",
      width: 100,
      editable: true,
      inputType: PRODUCT_INPUT_TYPE.INPUT_VAT,
      nameInput: ["vatPercent"],
      render: (text, record, index) => `${record?.vatPercent}%`,
    },
    {
      title: <span className={styles.title}>{t("product.productForm.action")}</span>,
      align: "center",
      width: 120,
      fixed: "right",
      render: (text, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{
                marginRight: 8,
              }}
              onClick={() => handleSave(record?.key)}
            >
              {t("common.save")}
            </Typography.Link>
            <Popconfirm
              title={t("common.isCancel")}
              cancelText={t("common.cancel")}
              okText={t("common.confirm")}
              onConfirm={handleCancel}
            >
              <a>{t("common.cancel")}</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            {disabledInputPrice && (
              <Typography.Link
                style={{
                  marginRight: 8,
                }}
                onClick={() => handleEdit(record)}
              >
                {t("common.edit")}
              </Typography.Link>
            )}
            {disabledInputInfo && (
              <Popconfirm
                title={t("product.productForm.isDeleteVariable")}
                cancelText={t("common.cancel")}
                okText={t("common.confirm")}
                onConfirm={() => handleDelete(record?.key)}
              >
                <a>{t("common.delete")}</a>
              </Popconfirm>
            )}
          </span>
        );
      },
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const handleEdit = (record) => {
    setEditingKey(record.key);
  };

  const handleCancel = () => {
    setEditingKey("");
  };

  const handleDelete = (key) => {
    const values = form.getFieldsValue();

    const newData = dataSource.filter((item) => item.key !== key);
    const newProducts = values?.products.filter((item) => item?.id !== key).filter((item) => item?.key !== key);

    form.setFieldsValue({ ...values, products: [...newProducts] });
    setDataSource(newData);
  };

  const handleSave = (key) => {
    const newDataSource = [...dataSource];
    const index = newDataSource.findIndex((item) => item.key === key);
    if (index > -1) {
      const item = newDataSource[index];
      const itemEdit = {
        isActive: true,
        image: products[index]?.imageURL ? products[index]?.imageURL[0]?.thumbUrl : "",
        productCode: products[index]?.code,
        level1Price: products[index]?.productPrices?.level1Price,
        level2Price: products[index]?.productPrices?.level2Price,
        level3Price: products[index]?.productPrices?.level3Price,
        level4Price: products[index]?.productPrices?.level4Price,
        vat: products[index]?.vat || true,
        vatPercent: products[index]?.vatPercent || 0,
      };
      newDataSource.splice(index, 1, { ...item, ...itemEdit });
      setDataSource(newDataSource);
      setEditingKey("");
    }
  };

  const isDisableUpload = (index) => {
    return form?.getFieldsValue()?.products?.at(index)?.imageURL?.length || products?.at(index)?.imageURL?.length
      ? true
      : false;
  };
  const mergedColumns = columns.map((col, index) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: col.inputType,
        nameInput: col.nameInput,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
        options: col?.options,
        rowIndex: rowIndex,
        initialValue: col.initialValue,
        isDisableUpload: isDisableUpload(rowIndex),
      }),
    };
  });

  const handleAdd = () => {
    const values = form.getFieldsValue();

    const newData = {
      key: uuidv4(),
      isActive: true,
      image: "",
      productCode: values?.code,
      level1Price: 0,
      level2Price: 0,
      level3Price: 0,
      level4Price: 0,
      vat: true,
      vatPercent: 0,
    };

    const newProducts = {
      key: newData?.key,
      name: values?.name,
      code: values?.code,
      inStock: 0,
      vat: true,
      vatPercent: 0,
      productPrices: { level1Price: 0, level2Price: 0, level3Price: 0, level4Price: 0 },
      attributeValues: [],
      imageURL: [],
      isActive: true,
    };

    form.setFieldsValue({ ...values, products: [...values.products, newProducts] });
    setDataSource([...dataSource, newData]);
    handleEdit(newData);
  };

  return (
    <>
      <Form.List name="products">
        {(products) => {
          return (
            <CustomTable
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              scroll={{ x: 1500, y: null }}
              rowSelection={{
                type: "checkbox",
              }}
              dataSource={dataSource}
            />
          );
        }}
      </Form.List>
      {disabledInputPrice && (
        <div className={styles["add-variant"]} onClick={handleAdd}>
          <PlusCircleOutlined className={styles.icon} />
          <span className={styles.text}>{t("product.productForm.addVariant")}</span>
        </div>
      )}
    </>
  );
};

export default VariantTable;
