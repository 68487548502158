import CustomCard from "components/CustomCard";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import usePurchaseOrder from "./usePurchaseOrder";

function PurchaseOrder() {
  const { handleChangeTab, activeKeyMain, tabList, contentList } = usePurchaseOrder();

  return (
    <div>
      <PageHeader
        pageTitle={t("purchase.purchaseOrderList")}
        routes={[
          { path: "#", name: t("purchase.titleMenu") },
          { path: "/purchase-order", name: t("purchase.purchaseOrder") },
          { path: "/", name: t("purchase.purchaseOrderList") },
        ]}
      />
      <CustomCard
        tabList={tabList}
        onTabChange={handleChangeTab}
        defaultActiveTabKey={activeKeyMain}
        contentList={contentList}
        activeTabKey={activeKeyMain}
      />
    </div>
  );
}

export default PurchaseOrder;
