import { CameraOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Radio, Row, Space, Tooltip, Typography, message } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { PrimaryButton } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import copy from "copy-to-clipboard";
import { useGenerateLink } from "hooks/generateLink/query";
import * as htmlToImage from "html-to-image";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, notify } from "utils/helperFuncs";

const QUOTATION_FORM = {
  LINK: "LINK",
  INFO: "INFO",
};

function ModalCopyProductPrice({ loading, data, handleSetSelectedRows, selectedRowKeys, handleSetSelectedRowKeys }) {
  const [form] = Form.useForm();

  const initialValues = data.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    level1Price: item?.productPrices?.level1Price,
    level2Price: item?.productPrices?.level2Price,
    level3Price: item?.productPrices?.level3Price,
    level4Price: item?.productPrices?.level4Price,
    oldLevel1Price: item?.lastPriceLog?.oldLevel1Price,
    newLevel1Price: item?.lastPriceLog?.newLevel1Price,
    oldLevel2Price: item?.lastPriceLog?.oldLevel2Price,
    newLevel2Price: item?.lastPriceLog?.newLevel2Price,
    oldLevel3Price: item?.lastPriceLog?.oldLevel3Price,
    newLevel3Price: item?.lastPriceLog?.newLevel3Price,
    oldLevel4Price: item?.lastPriceLog?.oldLevel4Price,
    newLevel4Price: item?.lastPriceLog?.newLevel4Price,
    lastPriceLog: item?.lastPriceLog,
  }));
  const [itemsCopy, setItemsCopy] = useState();
  const [quotationForm, setQuotationForm] = useState(QUOTATION_FORM.LINK);
  const [visible, setVisible] = useState(false);

  const { handleGenerateLink, loading: loadingGenLink } = useGenerateLink();

  const openModal = () => {
    if (data?.length) {
      setVisible(true);
    } else {
      notify.warning({ message: t("product.errorMessage") });
      setVisible(false);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const [showColumns, setShowColumns] = useState({
    showColumn1: false,
    showColumn2: true,
    showColumn3: false,
    showColumn4: false,
  });

  const handleColumnChange = (column) => (e) => {
    setShowColumns((prevColumns) => ({
      ...prevColumns,
      [column]: e.target.checked,
    }));
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const checkValueDuplicate = (price, lastPriceLog, oldValue, newValue) => {
    if (lastPriceLog) {
      if (oldValue.toString() === newValue.toString()) {
        return oldValue;
      } else
        return (
          <span>
            <div>{checkNullValue(oldValue)}</div>
            <div style={{ color: "#32BF46" }}>&nbsp;&#10142;&nbsp;{checkNullValue(newValue)}</div>
          </span>
        );
    } else {
      return price;
    }
  };

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "rgba(91, 102, 115, 1)" }} strong>
          STT
        </Typography.Text>
      ),
      align: "center",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    ...(quotationForm === QUOTATION_FORM.LINK
      ? [
          {
            title: "Mã sản phẩm",
            width: 200,
            render: (_, record) => (
              <Link to={`/product/detail/${record?.id}`} target="_blank">
                <Typography.Link>{record?.code}</Typography.Link>
              </Link>
            ),
          },
        ]
      : []),
    {
      title: t("product.productName"),
      width: 350,
      render: (_, record) => <Typography.Text>{record?.name}</Typography.Text>,
    },
    ...(quotationForm !== QUOTATION_FORM.LINK
      ? Object.entries(showColumns).map(([column, isVisible]) =>
          isVisible
            ? {
                title: t(`product.PriceLevel${column.slice(-1)}`),
                dataIndex: ["productPrices", `level${column.slice(-1)}Price`],
                width: 150,
                render: (_, record, index) => (
                  <FormInput
                    {...{
                      type: INPUT_TYPE.CURRENCY_INPUT,
                      formItemOptions: {
                        name: [index, `level${column.slice(-1)}Price`],
                        rules: [
                          {
                            required: true,
                            message: t("product.validateMessage.currency.blank"),
                          },
                        ],
                      },
                      inputOptions: {
                        maxLength: 11,
                      },
                    }}
                  />
                ),
              }
            : undefined
        )
      : [
          {
            title: "Giá cấp 1",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.level1Price),
                record?.lastPriceLog,
                formatCurrency(record?.oldLevel1Price),
                formatCurrency(record?.newLevel1Price)
              ),
          },
          {
            title: "Giá cấp 2",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.level2Price),
                record?.lastPriceLog,
                formatCurrency(record?.oldLevel2Price),
                formatCurrency(record?.newLevel2Price)
              ),
          },
          {
            title: "Giá cấp 3",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.level3Price),
                record?.lastPriceLog,
                formatCurrency(record?.oldLevel3Price),
                formatCurrency(record?.newLevel3Price)
              ),
          },
          {
            title: "Giá cấp 4",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.level4Price),
                record?.lastPriceLog,
                formatCurrency(record?.oldLevel4Price),
                formatCurrency(record?.newLevel4Price)
              ),
          },
        ]),
    {
      align: "center",
      width: "10px",
      render: (_, record) => {
        const handleDelete = () => {
          const cloneItemsCopy = [...itemsCopy];
          const cloneData = [...data];
          const cloneDataRowKeys = [...selectedRowKeys];

          const newData = cloneData?.filter((item) => item?.id !== record?.id);
          const newItemsCopy = cloneItemsCopy?.filter((item) => item?.id !== record?.id);
          const newDataRowKeys = cloneDataRowKeys?.filter((item) => item !== record?.id);

          setItemsCopy(newItemsCopy);
          handleSetSelectedRows(newData);
          handleSetSelectedRowKeys(newDataRowKeys);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleDelete}>
            <DeleteOutlined />
          </div>
        );
      },
    },
  ].filter(Boolean);

  const formattedData = itemsCopy?.map(({ name, level1Price, level2Price, level3Price, level4Price }) => {
    const { showColumn1, showColumn2, showColumn3, showColumn4 } = showColumns;
    const rowData = [name];

    if (showColumn1) rowData.push(formatCurrency(level1Price));

    if (showColumn2) rowData.push(formatCurrency(level2Price));

    if (showColumn3) rowData.push(formatCurrency(level3Price));

    if (showColumn4) rowData.push(formatCurrency(level4Price));

    return rowData;
  });

  const textToCopy = formattedData?.map((row) => row.join("\t")).join("\n");

  const handleCopyTable = () => {
    copy(textToCopy);
    message.success(t("product.copyTypeTextSuccess"));
  };

  const tableRef = useRef(null);
  const [loadingCopy, setLoadingCopy] = useState(false);

  const copyTableToClipboard = async () => {
    setLoadingCopy(true);
    try {
      const table = tableRef.current;
      await htmlToImage
        .toPng(table)
        .then((dataUrl) => {
          const blob = dataURLToBlob(dataUrl);
          // eslint-disable-next-line no-undef
          const item = new ClipboardItem({ [blob.type]: blob });
          navigator.clipboard
            .write([item])
            .then(() => {
              setLoadingCopy(false);
              message.success(t("product.copyTypeImageSuccess"));
            })
            .catch((error) => {
              console.error("Failed to copy table as image to clipboard:", error);
            });
        })
        .catch((error) => {
          console.error("Conversion failed:", error);
          setLoadingCopy(false);
        });
    } catch (error) {
      console.info(error);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues) {
      const newData = itemsCopy.map((item, index) => {
        return {
          ...item,
          level1Price: allValues?.[index]?.level1Price || item?.level1Price,
          level2Price: allValues?.[index]?.level2Price || item?.level2Price,
          level3Price: allValues?.[index]?.level3Price || item?.level3Price,
          level4Price: allValues?.[index]?.level4Price || item?.level4Price,
        };
      });
      setItemsCopy(newData);
    }
  };

  const handleCopyLink = async () => {
    let resultIDs = selectedRowKeys.join(",");
    try {
      const shopURL = process.env.REACT_APP_SHOP;
      const link = `${shopURL}/products?product_ids=${resultIDs}&from_home=false&leading_text`;
      await handleGenerateLink({ url: link }).then((res) => {
        const dynamicLayout = `CHI TIẾT BÁO GIÁ
Xem tại: ${res?.data?.linkQuery?.generateDynamicLinkFromUrl}
${itemsCopy?.map((item, index) => `- ${item?.name}`).join("\n")}`;

        copy(dynamicLayout);

        message.success("Đã sao chép link vào bộ nhớ tạm");
      });
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    setItemsCopy([...initialValues]);
    form.setFieldsValue({ ...initialValues });
  }, [data]);

  return (
    <CustomModal
      customComponent={<Typography.Text>Sao chép báo giá</Typography.Text>}
      title={<Typography.Title level={4}>{t("product.infoProducPrice")}</Typography.Title>}
      closable={false}
      centered
      footer={false}
      buttonLoading={loading}
      width={quotationForm === QUOTATION_FORM.LINK ? "70%" : "55%"}
      hideConfirmButton
      onOpen={openModal}
      onCancel={closeModal}
      visible={visible}
      addMoreButton={
        quotationForm === QUOTATION_FORM.LINK ? (
          <PrimaryButton title={"Sao chép"} onClick={handleCopyLink} loading={loadingGenLink} />
        ) : (
          <Space>
            <Button onClick={copyTableToClipboard} loading={loadingCopy} icon={<CameraOutlined />} type="primary">
              {t("product.copyTypeImage")}
            </Button>
            <Button onClick={handleCopyTable} icon={<CopyOutlined />} type="primary">
              {t("product.copyTypeText")}
            </Button>
          </Space>
        )
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <Typography>
                <Typography.Text strong>Hình thức báo giá </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Col>
            <Col>
              <Radio.Group
                defaultValue={quotationForm}
                value={quotationForm}
                onChange={(e) => setQuotationForm(e.target.value)}
              >
                <Radio value={QUOTATION_FORM.LINK}>Sao chép link báo giá</Radio>
                <Radio value={QUOTATION_FORM.INFO}>Sao chép thông tin báo giá</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {quotationForm === QUOTATION_FORM.LINK && (
          <Col span={24}>
            <Typography.Text type="warning">
              Lưu ý:
              <ul>
                <li>
                  Link sao chép sẽ điều hướng đại lý mở app và xem được sản phẩm theo cấp giá hiện tại của đại lý.
                </li>
                <li>Đại lý sẽ chỉ nhìn thấy những sản phẩm được cập nhật giá trong cấp giá hiện tại của mình</li>
              </ul>
            </Typography.Text>
          </Col>
        )}
        {quotationForm !== QUOTATION_FORM.LINK && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Typography>
                  <Typography.Text strong>{t("product.choosePriceLv")} </Typography.Text>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col>
                {Object.entries(showColumns).map(([column, isVisible]) => (
                  <Checkbox key={column} checked={isVisible} onChange={handleColumnChange(column)}>
                    {t(`product.PriceLevel${column.slice(-1)}`)}
                  </Checkbox>
                ))}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Form
            form={form}
            initialValues={initialValues}
            style={{ paddingBottom: "16px" }}
            onValuesChange={handleValuesChange}
          >
            <div ref={tableRef}>
              {quotationForm === QUOTATION_FORM.LINK ? (
                <CustomTable
                  rowKey={(record) => record?.id}
                  bordered
                  columns={columns}
                  dataSource={itemsCopy}
                  {...(itemsCopy?.length > 10 && {
                    scroll: {
                      x: "max-content",
                      y: "500px",
                    },
                  })}
                />
              ) : (
                <CustomTable rowKey={(record) => record?.id} bordered columns={columns} dataSource={itemsCopy} />
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
}

export default ModalCopyProductPrice;
