import CustomAffix from "components/CustomAffix";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

function ButtonGroup({ loading, onUpdatePurchaseOrder }) {
  const history = useHistory();

  return (
    <CustomAffix
      addButton={
        <>
          <CustomModal
            {...{
              customComponent: <PrimaryButtonOutLine title={t("common.cancel")} />,
              footer: false,
              message: <ModalWithIconQuestion title={t("purchase.modalMessage.cancel")} />,
              onOke: () => history.goBack(),
            }}
          />
          <PrimaryButton
            title={"Lưu lại"}
            onClick={() => onUpdatePurchaseOrder({ isResubmit: false })}
            loading={loading}
          />
          <PrimaryButton
            title={"Lưu & Gửi đề nghị"}
            onClick={() => onUpdatePurchaseOrder({ isResubmit: true })}
            loading={loading}
          />
        </>
      }
      showButtonDefault={false}
    />
  );
}

export default ButtonGroup;
