import { useState } from "react";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useTranslation } from "react-i18next";
import { useGetSellerCreateCart } from "hooks/seller";
import { useCreateCart, useUpdateCartSeller } from "hooks/cart";
import { notify } from "utils/helperFuncs";
import { debounce } from "utils/helperFuncs";
import { useHistory } from "react-router-dom";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { SelectLoadMore } from "components/CustomSelect";
import { useGetInfoPlatform } from "hooks/info-platform";

const CustomModalChangeSeller = ({
  action,
  onSubmitSuccess,
  cartID,
  defaultSeller,
  onCancel,
  selectedProducts,
  selectedRows,
}) => {
  const [form] = Form.useForm();
  const [selectedValue, setSelectedValue] = useState(defaultSeller || null);
  const [switchClose, setSwitchClose] = useState(false);
  const { handleCreateCart, loading } = useCreateCart();
  const { handleUpdateCartSeller } = useUpdateCartSeller();
  const { t } = useTranslation();
  const history = useHistory();
  const [warehouses, setWarehouses] = useState([]);

  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    sellerCartSearch: null,
    ids: selectedValue ? [selectedValue] : [],
    isActive: true,
  });

  const getParams = () => ({
    filters: {
      query: params.sellerCartSearch || null,
      ids: params.ids,
      isActive: params.isActive,
      isSeller: true,
    },
    pagination: {
      offset: params.offset,
      limit: params.limit,
    },
  });

  const warehouseOptions = warehouses?.map(({ id, name, branch }) => ({
    value: id,
    label: `${branch?.name} - ${name}`,
  }));

  const { sellers = [], hasMoreData, onLoadMoreData } = useGetSellerCreateCart(getParams());

  const handleValuesChange = (valueChange, allValues) => {
    if (valueChange?.sellerID) {
      form.setFieldsValue({
        sellerID: valueChange?.sellerID,
        warehouseID: null,
      });
    }
  };

  const handleSelectSeller = (value, option) => {
    setWarehouses(option?.warehouses);
    setSelectedValue(value);
  };

  const changeParamsOnSearch = ([text]) => {
    if (text[0] === " ") {
      return;
    }
    const inputText = text.length > 0 ? text : null;
    setParams((prev) => ({ ...prev, sellerCartSearch: inputText, ids: [] }));
  };
  const handleDebouncedSearchSeller = debounce(changeParamsOnSearch, 500);

  const handleSubmitSuccess = () => {
    clearField();
    setSwitchClose(!switchClose);
    onSubmitSuccess();
  };

  const clearField = () => {
    form.resetFields(["seller"]);
    setParams(() => ({ offset: 0, limit: 10 }));
    setSelectedValue(null);
  };

  const { platformCode } = useGetInfoPlatform();

  const platformCodes = {
    HA: "HA",
    C_GROUP: "C_GROUP",
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const cartItems =
      (await selectedProducts?.map((item, index) => ({
        productId: item,
        quantity: 1,
        vat: platformCode === platformCodes.HA ? false : true, // selectedRows.map((item) => item.vat)?.[index],
      }))) || null;
    switch (action) {
      case "addCart":
        await form.validateFields().then(() => {
          handleCreateCart({
            sellerID: selectedValue,
            warehouseID: values?.warehouseID,
            items: null,
          })
            .then(() => {
              notify.success({
                message: t("cart.create.addSuccess"),
              });
              handleSubmitSuccess();
            })
            .catch((error) => {
              notify.error({
                message: t("cart.create.addError"),
                description: error?.message,
              });
            });
        });
        break;
      case "addCartFromProduct":
        await form.validateFields().then(() => {
          handleCreateCart({
            sellerID: selectedValue,
            warehouseID: values?.warehouseID,
            items: cartItems,
          })
            .then((response) => {
              const id = response?.data?.cart?.create?.id;
              notify.success({
                message: t("cart.create.addSuccess"),
              });
              history.push({
                pathname: "/cart",
                search: `?id=${id}`,
              });
            })
            .catch((error) => {
              notify.error({
                message: t("cart.create.addError"),
                description: error?.message,
              });
            });
        });
        break;
      case "changeSeller":
        await form.validateFields().then(() => {
          handleUpdateCartSeller(cartID, selectedValue)
            .then(() => {
              handleSubmitSuccess();
            })
            .catch((error) => {
              notify.error({
                message: t("cart.changeSellerError"),
                description: error?.message,
              });
            });
        });
        break;
      default:
        break;
    }
  };

  return (
    <CustomModal
      width={580}
      // selfVisibleControlled={false}
      closable={false}
      centered={true}
      footer={false}
      title={
        <Typography.Text strong className="create-cart-title">
          {t("cart.create.selectSeller")}
        </Typography.Text>
      }
      onCancel={onCancel}
      onOke={() => handleSubmit()}
      // isBlockCloseOnOke
      switchClose={switchClose}
      buttonLoading={loading}
      customComponent={
        <Button className="create-btn" type="primary">
          {t("cart.create.createNewCart")}
        </Button>
      }
    >
      <Form form={form} name="create-cart-from" onValuesChange={handleValuesChange}>
        <SelectLoadMore
          formItemOptions={{
            label: <Typography.Text strong>{t("cart.create.supplier")}</Typography.Text>,
            name: "sellerID",
            rules: [
              {
                required: true,
                message: t("cart.create.sellerValidate"),
              },
            ],
            initialValue: selectedValue,
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
          inputOptions={{
            placeholder: t("cart.create.sellerPlaceholder"),
            options: sellers,
            onSelect: (value, option) => handleSelectSeller(value, option),
            onClick: () => setParams({ ...params, ids: [] }),
          }}
          onLoadMore={onLoadMoreData}
          hasMoreData={hasMoreData}
          onSearch={handleDebouncedSearchSeller}
        />
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            label: <b>{t("cart.exportWarehouseLabel")}</b>,
            style: { width: "100%" },
            name: "warehouseID",
            rules: [{ required: true, message: t("cart.requiredWarehouse") }],
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
          inputOptions={{
            options: warehouseOptions,
            placeholder: t("cart.warehousePlaceholder"),
          }}
        />
      </Form>
    </CustomModal>
  );
};

export default CustomModalChangeSeller;
