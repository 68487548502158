import { Typography } from "antd";

import styles from "../../../../index.module.scss";

function TitleCard({ title }) {
  return <Typography.Text className={styles["title-card"]}>{title}</Typography.Text>;
}

const SubTitleCard = ({ subTitle }) => {
  return <Typography.Text className="title-sub-title-card">{subTitle}</Typography.Text>;
};

export { SubTitleCard, TitleCard };
