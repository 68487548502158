import { Form } from "antd";
import { checkIcon } from "features/WarrantyRequest/constant";
import { useGetBranchQuery } from "hooks/branch";
import { useGetWarrantyRequestUpdate } from "hooks/warranty";
import { t } from "i18next";
import { some } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";

function useFormEditAddInfo({ data }) {
  const [form] = Form.useForm();
  const { data: branchQuery } = useGetBranchQuery();
  const { id } = useParams();
  const { handleUpdateWarrantyRequest, loading } = useGetWarrantyRequestUpdate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCompleted = some(data?.items, { status: "COMPLETED" });
  const isCanceled = some(data?.items, { status: "CANCELED" });
  const isDelivering = some(data?.items, { status: "DELIVERING" });

  const initialValues = {
    branchID: data?.branchID,
    note: data?.note,
  };

  const showModal = () => {
    setIsModalOpen(checkIcon(isCompleted || isCanceled || isDelivering));
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
    form.setFieldsValue({ ...initialValues });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdateWarrantyRequest({ id: id, request: { ...values } }).then((res) => {
          if (res.data.warrantyRequest.update === true) {
            setIsModalOpen(!isModalOpen);
            notify.success({ message: t("warrantyRequest.detail.updateInfo") });
          } else {
            notify.error({ message: t("common.updateError") });
          }
        });
      })
      .catch((err) => {
        notify.error({ message: t("common.updateError") });

        console.info(err);
      });
  };

  return {
    form,
    branchQuery,
    initialValues,
    loading,
    isModalOpen,
    showModal,
    handleCancel,
    handleOk,
    isCompleted,
    isCanceled,
    isDelivering,
  };
}

export default useFormEditAddInfo;
