import { Col, Form, Row, Typography } from "antd";
import TableOrderItem from "./components/TableOrderItem";
import styles from "./index.module.scss";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import useCreateExportKey from "./hooks";
import { useTranslation } from "react-i18next";
import SellerInfo from "./components/SellerInfo";
import AddInfo from "./components/AddInfo";

import Footer from "./components/Footer";

export default function CreateExportKey() {
  const { t } = useTranslation();
  const {
    dataCreateExportKey,
    loadingDataCreateExportKey,
    initialValues,
    form,
    handleCreate,
    loadingCreateSerialExport,
    loadingConfirmExportOrder,
    totalInfo,
    productId2Serials,
    setProductId2Serials,
  } = useCreateExportKey(t);

  return (
    <div className={styles["create-export-key"]}>
      <PageHeader
        pageTitle={t("v2.keyManagement.exportNoteCreate.title")}
        routes={[
          { path: "/", name: t("v2.keyManagement.title") },
          { path: "/key-list", name: t("v2.keyManagement.codeList") },
          { path: "#", name: t("v2.keyManagement.exportNoteCreate.title") },
        ]}
      />
      <Form form={form} layout="horizontal" className={styles["form"]} initialValues={initialValues} colon={false}>
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            name: "exportWarehouseType",
            label: (
              <Typography.Text strong>{t("v2.keyManagement.exportNoteCreate.exportWarehouseType")}</Typography.Text>
            ),
            style: { maxWidth: 300, margin: 0 },
          }}
          inputOptions={{
            options: [
              { label: t("v2.keyManagement.exportNoteCreate.byOrder"), value: initialValues.exportWarehouseType },
            ],
          }}
        />
        <Row gutter={[16, 8]}>
          <Col span={12} style={{ display: dataCreateExportKey && "flex" }}>
            <SellerInfo
              t={t}
              dataCreateExportKey={dataCreateExportKey}
              loadingDataCreateExportKey={loadingDataCreateExportKey}
            />
          </Col>
          <Col span={12}>
            <AddInfo t={t} />
          </Col>
        </Row>

        <TableOrderItem
          t={t}
          dataCreateExportKey={dataCreateExportKey}
          totalInfo={totalInfo}
          productId2Serials={productId2Serials}
          setProductId2Serials={setProductId2Serials}
        />

        <Footer
          t={t}
          loadingCreateSerialExport={loadingCreateSerialExport}
          loadingConfirmExportOrder={loadingConfirmExportOrder}
          handleCreate={handleCreate}
          totalInfo={totalInfo}
          email={dataCreateExportKey?.email}
        />
      </Form>
    </div>
  );
}
