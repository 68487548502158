import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($req: CategoryInput!) {
    category {
      create(req: $req)
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $req: CategoryInput!) {
    category {
      update(id: $id, req: $req)
    }
  }
`;

export const UPDATE_STATUS_CATEGORY = gql`
  mutation UpdateStatusCategory($ids: [ID!], $isActive: Boolean!) {
    category {
      updateStatus(ids: $ids, isActive: $isActive)
    }
  }
`;
