import React from "react";
import { Col, Row, Select } from "antd";
import InputSearch from "features/Warranty/WarrantyList/component/InputSearch";
import useToolbar from "./useToolbar";

function Toolbar({ onSearch, staffs, onChangeParams, params, valueFormSelectBrandId, onChangeBranchesId, branchID }) {
  const { renderTags } = useToolbar({ staffs, onChangeParams, params });

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row style={{ justifyContent: "space-between" }}>
          <Col span={12}>
            <InputSearch onSearch={onSearch} />
          </Col>
          <div>
            <Select
              options={valueFormSelectBrandId}
              onChange={(e) => onChangeBranchesId(e)}
              value={branchID}
              style={{ minWidth: "170px" }}
            />
          </div>
        </Row>
      </Col>

      <Col span={24} style={{ marginTop: "16px" }}>
        <div>{renderTags}</div>
      </Col>
    </Row>
  );
}

export default Toolbar;
