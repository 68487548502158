/* eslint-disable no-prototype-builtins */
import { Tag } from "antd";
import { useGetSellerGroupWithIds, useGetSellerLevels } from "hooks/seller";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { find } from "lodash";
import { IS_USED_APP } from "pages/App/Agent/constants";
import { getQuery } from "utils/helperFuncs";
import "../../../../Product/Product/List/components/TagsList/index.scss";

const TagCustom = ({ label, filterText, handleOnClose }) => {
  return (
    <Tag closable onClose={handleOnClose}>
      <span className="label">{label}:</span>
      <span>&nbsp;{filterText}</span>
    </Tag>
  );
};

const SellerTagList = ({ filters, onClose }) => {
  const GET_QUERY = getQuery();

  const { sellerLevels = [] } = useGetSellerLevels();
  const { warehouses = [] } = useWarehouse();

  const { data: customerList } = useGetSellerGroupWithIds({
    ids: GET_QUERY.sellerGroupIDs ? GET_QUERY.sellerGroupIDs : undefined,
  });

  const sellerLevelOptions = sellerLevels.map((item) => ({ label: item.name, value: item.id }));
  const warehousesOptions = warehouses.map((item) => ({ label: item.name, value: item.id }));
  const customerListOptions = customerList?.map((item) => ({ label: item.name, value: item.id }));

  const renderTagLevel = (key) => {
    if (filters?.hasOwnProperty(key) && filters[`${key}`]) {
      const levelList = filters[`${key}`]
        .map((item) => find(sellerLevelOptions, { value: item }))
        .map((item) => item?.label)
        .join(", ");

      return (
        levelList && <TagCustom label={t("common.level")} filterText={levelList} handleOnClose={() => onClose(key)} />
      );
    } else {
      return null;
    }
  };

  const renderTagWarehouse = (key) => {
    if (filters?.hasOwnProperty(key) && filters[`${key}`]) {
      const warehouseList = filters[`${key}`]
        .map((item) => find(warehousesOptions, { value: item }))
        .map((item) => item?.label)
        .join(", ");

      return (
        warehouseList && (
          <TagCustom label={t("seller.warehouse")} filterText={warehouseList} handleOnClose={() => onClose(key)} />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagStatus = (key) => {
    const renderStatusText = (value) => {
      if (value === true) {
        return t("seller.cooperation");
      } else if (value === false) {
        return t("seller.stopCooperation");
      } else {
        return "";
      }
    };

    if (filters?.hasOwnProperty([`${key}`]) && filters[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("common.status")}
          filterText={renderStatusText(filters[`${key}`])}
          handleOnClose={() => onClose(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagVatInvited = (key) => {
    const renderStatusText = (value) => {
      switch (value) {
        case IS_USED_APP.USED:
          return t("seller.usedApp");
        case IS_USED_APP.INVITED:
          return t("seller.invited");
        case IS_USED_APP.UNINVITED:
          return t("seller.uninvited");
        default:
          return "";
      }
    };

    if (filters?.hasOwnProperty([`${key}`]) && filters[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("seller.usedApp")}
          filterText={renderStatusText(filters[`${key}`])}
          handleOnClose={() => onClose(key)}
        />
      );
    } else {
      return null;
    }
  };

  const renderTagSellerGroup = (key) => {
    if (filters.hasOwnProperty(key) && filters[`${key}`]) {
      const sellerGroupList = filters[`${key}`]
        .map((item) => find(customerListOptions, { value: item }))
        .map((item) => item?.label)
        .join(", ");

      return (
        sellerGroupList && (
          <TagCustom
            label={t("seller.groupCustomer")}
            filterText={sellerGroupList}
            handleOnClose={() => onClose(key)}
          />
        )
      );
    } else {
      return null;
    }
  };

  const renderTagIsSupplier = (key) => {
    const renderSupplierText = (value) => {
      if (value === true) {
        return t("supplier.yes");
      } else if (value === false) {
        return t("supplier.no");
      } else {
        return "";
      }
    };

    if (filters?.hasOwnProperty([`${key}`]) && filters[`${key}`] !== undefined) {
      return (
        <TagCustom
          label={t("seller.isSupplier")}
          filterText={renderSupplierText(filters[`${key}`])}
          handleOnClose={() => onClose(key)}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="tags-list">
      {/* Cấp độ */}
      {renderTagLevel("sellerLevelIDs")}
      {/* Kho xuất hàng */}
      {renderTagWarehouse("exportedWarehouseIDs")}
      {/* Trạng thái */}
      {renderTagStatus("isActive")}
      {/* Đã dùng app */}
      {renderTagVatInvited("inviteStatus")}
      {/* Seller Group */}
      {renderTagSellerGroup("sellerGroupIDs")}
      {/* Supplier */}
      {renderTagIsSupplier("isSupplier")}
    </div>
  );
};

export default SellerTagList;
