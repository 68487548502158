import { Button, Typography } from "antd";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { t } from "i18next";
import { useState } from "react";
import { useHistory } from "react-router-dom";
export const ButtonReRequest = ({ record, disabled }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const onReRequest = async () => {
    history.push(`/import-request/edit/${record?.id}`);
  };
  return (
    <CustomModal
      {...{
        customComponent: (
          <Button className="button-action-recommend button-offers-recommend">
            {t("warehouse.recommendImport.rerequest")}
          </Button>
        ),
        footer: false,
        message: (
          <ModalWithIconQuestion
            title={
              <>
                {t("warehouse.recommendImport.questionrerequest")}{" "}
                <Typography.Link strong>{record?.code}</Typography.Link> {t("warehouse.recommendImport.no")}?
              </>
            }
          />
        ),
        styleButton: "notButton",
        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={onReRequest}>
            {t("common.confirm")}
          </Button>
        ),
        centered: true,
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    />
  );
};
