import { gql } from "@apollo/client";

export const GET_BRANCH_QUERY = gql`
  query BranchQuery {
    branch {
      list {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
