import React from "react";
import CustomTable from "components/CustomTable";
import usePotentialCustomerList from "../usePotentialCustomerList";
import Toolbar from "../Toolbar";
import AddPotentialCustomer from "../../AddPotentialCustomer";
import PotentialCustomerDetailBlock from "features/PotentialCustomer/PotentialCustomerDetailBlock";

function CustomerNewTab({ isAgent }) {
  const {
    restTable,
    optionsFilter,
    handleSearch,
    params,
    handleChangeParams,
    isShowDrawerDetail,
    handleCloseDrawerDetail,
    customerId,
  } = usePotentialCustomerList({ isAgent });

  return (
    <>
      <Toolbar
        optionsFilter={optionsFilter}
        onSearch={handleSearch}
        params={params}
        onChangeParams={handleChangeParams}
        customButton={<AddPotentialCustomer />}
      />
      <CustomTable {...restTable} />
      <PotentialCustomerDetailBlock
        visible={isShowDrawerDetail}
        onClose={handleCloseDrawerDetail}
        customerId={customerId}
      />
    </>
  );
}

export default CustomerNewTab;
