import { useQuery } from "@apollo/client";
import { GET_WAREHOUSES } from "graphql/ware-house/query";

export const useWarehouse = () => {
  const { data, loading } = useQuery(GET_WAREHOUSES);

  const warehouseOptions = data?.warehouse?.list?.warehouses?.map(({ id, name, branch }) => ({
    value: id,
    label: `${branch?.name} - ${name}`,
  }));

  return {
    warehouses: data?.warehouse?.list?.warehouses,
    loading,
    warehouseOptions,
  };
};
