import { Form, Input } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import "./index.scss";

function CustomInputGroup({
  typeFirst,
  typeSecond,
  formItemOptionsFirst,
  inputOptionsFirst,
  formItemOptionsSecond,
  inputOptionsSecond,
  optionsSelect,
  maxWidthSelect = 55,
  defaultValueSelect = "VND",
  maxWidthInput = 140,
  disabled,
}) {
  const defaultValue = typeSecond === INPUT_TYPE.SELECT && defaultValueSelect;
  return (
    <Form.Item className="custom-input-group-compact">
      <Input.Group compact>
        <FormInput
          {...{
            type: typeFirst || INPUT_TYPE.CURRENCY_INPUT,
            formItemOptions: {
              noStyle: true,
              ...formItemOptionsFirst,
            },
            inputOptions: {
              ...inputOptionsFirst,
              // disabled: disabled,
              style: {
                maxWidth: maxWidthInput,
              },
            },
          }}
        />
        <FormInput
          {...{
            type: typeSecond || INPUT_TYPE.SELECT,
            formItemOptions: {
              noStyle: true,
              ...formItemOptionsSecond,
            },
            inputOptions: {
              ...inputOptionsSecond,
              // disabled: disabled,
              defaultValue: defaultValue,
              style: {
                maxWidth: maxWidthSelect,
              },
              options: optionsSelect,
            },
          }}
        />
      </Input.Group>
    </Form.Item>
  );
}

export default CustomInputGroup;
