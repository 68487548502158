import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Row, Space, Spin, Typography } from "antd";
import CustomButton from "components/CustomButton";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { RECOMMEND_IMPORT, importRequestType } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { ButtonCompleteInportRequest } from "../List/components/ButtonCompleteInportRequest";
import AddInfo from "./components/AddInfo";
import InfoProvider from "./components/InfoProvider";
import PaymentInfo from "./components/PaymentInfo";
import TableProduct from "./components/Table";
import { TitleCard } from "./components/TitleCard";
import "./index.scss";
import useList from "./useList";

function DetailImportRequest() {
  const {
    data,
    loading,
    form,
    canImportRequestUpdate,
    canImportRequestApprove,
    canImportRequestReject,
    canImportRequestCancel,
    canImportRequestComplete,
    visibleReject,
    showModalReject,
    confirmReject,
    handleCancelReject,
    visibleAccept,
    showModalAccept,
    handleCancelAccept,
    loadingAccept,
    onAccept,
    visibleCancel,
    showModalCancel,
    onCancel,
    handleCancel,
  } = useList();

  return (
    <Spin spinning={loading}>
      <div className="detail-recommend-import">
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("warehouse.create.detailrecommendimport")}
              routes={[
                { path: "#", name: t("warehouse.recommendImport.buy") },
                { path: "/import-request", name: t("warehouse.recommendImport.recommendImport") },
                { path: "/", name: t("warehouse.create.detailrecommendimport") },
              ]}
            />
          </Col>
          <Col span={24} className="form-head">
            <Row gutter={[16, 8]} style={{ justifyContent: "space-between", padding: "8px" }}>
              <Col span={10}>
                <Row gutter={[16, 8]}>
                  <Col span={5}>
                    <Typography.Text strong>{t("warehouse.create.typeRecommend")} : </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text>{importRequestType[data?.type] || "---"}</Typography.Text>{" "}
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={5}>
                    <Typography.Text strong>{t("warehouse.create.warehouseImport")} : </Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Text> {data?.warehouse?.name || "---"}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}></Row>
              </Col>
              <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space style={{ background: "transparent", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("warranty.warrantyDetail.dateCreated")}: {formatDateTime(data?.createdAt) || "--"}
                        &nbsp;{t("common.by")} {data?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("common.updatedAt")}: {formatDateTime(data?.updatedAt) || "--"} &nbsp;
                        {t("common.by")}{" "}
                        {data?.updatedBy ? data?.updatedBy?.fullname : data?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card
              title={
                <TitleCard
                  title={t("warehouse.recommendImport.recommendImport")}
                  value={data?.code}
                  status={data?.status}
                />
              }
              style={{ flexGrow: 1 }}
              className="card-parent"
            >
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={10} className="card-container-info">
                    <Card
                      title={<TitleCard title={t("warehouse.create.infoProvider")} />}
                      style={{ flexGrow: 1 }}
                      className="card-children"
                    >
                      <InfoProvider sellerInfo={data?.supplier} />
                    </Card>
                  </Col>
                  <Col span={7} className="card-container-info">
                    <Card title={<TitleCard title={t("warehouse.create.infoPayment")} />} className="card-children">
                      <PaymentInfo data={data} />
                    </Card>
                  </Col>
                  <Col span={7} className="card-container-info">
                    <Card title={<TitleCard title={t("warehouse.create.addInfo")} />} className="card-children">
                      <AddInfo data={data} />
                    </Card>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Card title={<TitleCard title={t("warehouse.create.listProduct")} />} className="card-children">
                      <Col span={24}>
                        {data?.status === RECOMMEND_IMPORT?.REJECTED && (
                          <Alert
                            message={
                              <Row>
                                <Typography.Text strong>
                                  <Col>{t("warehouse.recommendImport.reasonImportReques")}: </Col>
                                </Typography.Text>
                                <Col>
                                  <Typography.Text style={{ marginLeft: "4px" }}>
                                    {data?.rejectedReason}
                                  </Typography.Text>
                                </Col>
                              </Row>
                            }
                            icon={<ExclamationCircleOutlined />}
                            type="error"
                            showIcon
                            style={{ width: "100%", marginBottom: "10px" }}
                          />
                        )}
                        <Form.List name="items">
                          {(items, { remove }) => {
                            return <TableProduct remove={remove} form={form} dataTableProduct={data?.items} />;
                          }}
                        </Form.List>
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
          <Col span={24}>
            <Card className="form-footer">
              {(data?.status === "WAITING_APPROVAL" && canImportRequestCancel) ||
              (data?.status === "REJECTED" && canImportRequestCancel) ? (
                <CustomModal
                  {...{
                    customComponent: (
                      <Button className="button-action-recommend button-cancel-recommend">
                        {t("warehouse.recommendImport.deleteRecommend")}
                      </Button>
                    ),
                    footer: false,
                    message: (
                      <span>
                        {t("warehouse.recommendImport.questioncancel")}{" "}
                        <Typography.Link strong>{data?.code}</Typography.Link> {t("warehouse.recommendImport.no")}?
                      </span>
                    ),
                    centered: true,
                    styleButton: "notButton",
                    hideConfirmButton: true,
                    addMoreButton: (
                      <Button type="primary" onClick={onCancel} loading={loadingAccept}>
                        {t("common.confirm")}
                      </Button>
                    ),
                    visible: visibleCancel,
                    onOpen: showModalCancel,
                    onCancel: handleCancel,
                  }}
                />
              ) : (
                <></>
              )}

              {(data?.status === RECOMMEND_IMPORT.WAITING_APPROVAL && canImportRequestUpdate) ||
              (data?.status === RECOMMEND_IMPORT.REJECTED && canImportRequestUpdate) ? (
                <Link to={`/import-request/edit/${data?.id}`}>
                  <CustomButton type={"primary"} title={t("common.EDIT")} htmlType={"submit"} />
                </Link>
              ) : (
                <></>
              )}
              {data?.status === RECOMMEND_IMPORT.WAITING_APPROVAL && canImportRequestApprove ? (
                <CustomModal
                  {...{
                    customComponent: (
                      <CustomButton
                        type={"primary"}
                        title={t("warehouse.recommendImport.accept")}
                        htmlType={"submit"}
                      />
                    ),
                    footer: false,
                    message: (
                      <ModalWithIconQuestion
                        title={
                          <>
                            {t("warehouse.recommendImport.questionaccept")}{" "}
                            <Typography.Link strong>{data?.code}</Typography.Link> {t("warehouse.recommendImport.no")}?
                          </>
                        }
                      />
                    ),
                    centered: true,
                    styleButton: "notButton",
                    hideConfirmButton: true,
                    addMoreButton: (
                      <Button type="primary" onClick={onAccept} loading={loadingAccept}>
                        {t("common.confirm")}
                      </Button>
                    ),
                    visible: visibleAccept,
                    onOpen: showModalAccept,
                    onCancel: handleCancelAccept,
                  }}
                />
              ) : (
                <></>
              )}
              {canImportRequestComplete && data?.status === RECOMMEND_IMPORT.WAITING_FOR_BUYING && (
                <ButtonCompleteInportRequest record={data} type="primary" />
              )}
              {data?.status === RECOMMEND_IMPORT.WAITING_APPROVAL && canImportRequestReject ? (
                <CustomModal
                  {...{
                    customComponent: (
                      <CustomButton
                        type={"primary"}
                        title={t("warehouse.recommendImport.reject")}
                        htmlType={"submit"}
                      />
                    ),
                    footer: false,
                    centered: true,
                    styleButton: "notButton",
                    hideConfirmButton: true,
                    title: (
                      <Typography.Text strong style={{ fontSize: "20px" }}>
                        {t("warehouse.recommendImport.rejectrequestimport")}
                      </Typography.Text>
                    ),
                    addMoreButton: (
                      <Button type="primary" onClick={confirmReject} loading={loading}>
                        {t("common.confirm")}
                      </Button>
                    ),
                    visible: visibleReject,
                    onOpen: showModalReject,
                    onCancel: handleCancelReject,
                  }}
                >
                  <Form form={form} layout="vertical">
                    <FormInput
                      {...{
                        type: INPUT_TYPE.TEXT_AREA,
                        formItemOptions: {
                          label: (
                            <Typography.Text strong>{t("warehouse.recommendImport.reasonReject")}</Typography.Text>
                          ),
                          name: "rejectedReason",
                          rules: [
                            {
                              required: true,
                              message: t("warehouse.recommendImport.pleaseEnterReject"),
                            },
                          ],
                        },
                        inputOptions: {
                          placeholder: t("warehouse.recommendImport.enterreasonReject"),
                        },
                      }}
                    />
                  </Form>
                </CustomModal>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default DetailImportRequest;
