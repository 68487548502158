import React from "react";
import CustomTags from "components/CustomTags";
import { t } from "i18next";
import { IS_ACTIVES_TAG, IS_CUSTOMER_TAG, SUPPLIER_TYPE } from "features/Supplier/constant";
import { useGetSellerGroupWithIds } from "hooks/seller";

function useToolbar({ onChangeParams, params, customerGroupOptions, supplierTypeOptions }) {
  const { data: customerList } = useGetSellerGroupWithIds({
    ids: params?.filters?.sellerGroupIDs ? params?.filters?.sellerGroupIDs : undefined,
  });
  const customerListOptions = customerList?.map((item) => ({ label: item.name, value: item.id }));

  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];

    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
        {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const tags = [
    {
      title: t("supplier.table.customerGroup"),
      options: customerListOptions,
      selected: params?.filters?.sellerGroupIDs,
      key: "sellerGroupIDs",
    },
  ];

  const handleOnClose = (key) => {
    onChangeParams && onChangeParams({ [`${key}`]: undefined });
  };

  const renderSupplierTypeTag = () => {
    const types = params?.filters?.types;
    return (
      types && (
        <CustomTags
          handleOnClose={() => handleOnClose("types")}
          label={
            <div>
              <span className="label">{t("supplier.table.supplierType")}</span>:{" "}
              {types === SUPPLIER_TYPE.COMPANY ? t("supplier.company") : t("supplier.personal")}
            </div>
          }
        />
      )
    );
  };

  const renderIsSellerTag = () => {
    const isSeller = params?.filters?.isSeller;

    return (
      isSeller !== undefined && (
        <CustomTags
          handleOnClose={() => handleOnClose("isSeller")}
          label={
            <div>
              <span className="label">{t("supplier.table.isCustomer")}</span>:{" "}
              {isSeller ? IS_CUSTOMER_TAG.ACTIVE : IS_CUSTOMER_TAG.DISABLED}
            </div>
          }
        />
      )
    );
  };

  const renderActionsTag = () => {
    const isActiveSupplier = params?.filters?.isActiveSupplier;
    return (
      isActiveSupplier !== undefined && (
        <CustomTags
          handleOnClose={() => handleOnClose("isActiveSupplier")}
          label={
            <div>
              <span className="label">{t("supplier.table.status")}</span>:{" "}
              {isActiveSupplier ? IS_ACTIVES_TAG.ACTIVE : IS_ACTIVES_TAG.DISABLED}
            </div>
          }
        />
      )
    );
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return { renderTags, renderSupplierTypeTag, renderIsSellerTag, renderActionsTag };
}

export default useToolbar;
