import { Col, Row, Timeline, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { isCurrent, isPast } from "features/Order/helper";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import useModalTrackingOrder from "./useModalTrackingOrder";

const ModalTrackingOrder = ({ data }) => {
  const { number, seller, fullEvents, status } = data.order;
  const { timeLine } = useModalTrackingOrder({ fullEvents, status });
  return (
    <CustomModal
      title={<Typography.Text strong>{t("order.orderStatus.orderStatus")}</Typography.Text>}
      customComponent={
        <Typography.Link italic style={{ fontSize: "12px" }}>
          {t("order.orderDetail.seeDetails")}
        </Typography.Link>
      }
      centered={true}
      footer={false}
      hideConfirmButton={true}
      cancelButtonLabel={t("common.close")}
      width="50%"
      closable={false}
      className="modal-status-order-by-export-serial"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={16} style={{ background: "#E7F0FF", padding: "8px", borderRadius: "8px" }}>
            <Col span={4}>
              <Typography.Text strong>{t("order.orderDetail.orderCode")}</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong style={{ color: "#052987" }}>
                #{number}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong style={{ color: "#052987" }}>
                {seller?.fullName}
              </Typography.Text>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Timeline mode="left">
            {timeLine?.map(({ createdAt, name, state, createdBy, dot }, index) => (
              <Timeline.Item
                key={index}
                label={
                  isPast(state) && (
                    <Row gutter={16}>
                      <Col span={12} offset={11}>
                        <Typography.Text style={{ color: "#052987" }}>{formatDateTime(createdAt)}</Typography.Text>
                      </Col>
                    </Row>
                  )
                }
                dot={dot}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text style={{ color: `${isCurrent(state) ? "#2246DC" : "#052987"}` }}>
                      {name}
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    {isPast(state) && <Typography.Text italic>Bởi: {createdBy?.fullname}</Typography.Text>}
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default ModalTrackingOrder;
