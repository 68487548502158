import React from "react";
import CustomTable from "components/CustomTable";
import { OrderSummary } from "features/Agent/constants";
import useBuyHistory from "./useBuyHistory";
import Toolbar from "./Toolbar";

function BuyHistory() {
  const {
    data,
    columns,
    paginationData,
    loading,
    onTableChange,
    handleSearch,
    all,
    completed,
    processing,
    cancel,
    onChangeParams,
    params,
    createdByOptions,
  } = useBuyHistory();

  return (
    <>
      <Toolbar
        showButton={false}
        children={<OrderSummary all={all} completed={completed} processing={processing} cancel={cancel} />}
        handleSearch={handleSearch}
        onChangeParams={onChangeParams}
        params={params}
        isSellerIDs={false}
        createdByOptions={createdByOptions}
      />
      <CustomTable
        columns={columns}
        loading={loading}
        dataSource={data}
        onChange={onTableChange}
        rowKey={(record) => record?.id}
        pagination={{
          total: paginationData?.total,
          pageSize: paginationData?.limit,
          current: paginationData?.offset / paginationData?.limit + 1 || 1,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default BuyHistory;
