import { Dropdown, Menu, Tooltip, message } from "antd";
import { SvgIcon } from "assets/icons";
import { useGetSampleFileKey } from "hooks/keyManagement/query";
import { useTranslation } from "react-i18next";

export const CustomDownloadFile = () => {
  const { handleGetFileSampleKey } = useGetSampleFileKey();
  const { t } = useTranslation();

  const handleDownloadSampleKeyLicense = async () => {
    try {
      message.loading({
        content: t("v2.keyManagement.downloading"),
      });

      const res = await handleGetFileSampleKey({ productType: "KEY_LICENSE" });

      const url = res?.data?.product?.getSampleFile?.url;

      if (url) {
        const blob = await fetch(url).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          return response.blob();
        });

        const link = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "[Key license] [Template] File mẫu.xlsx";
        link.click();

        window.URL.revokeObjectURL(objectUrl);
        setTimeout(() => {
          message.success({
            content: t("v2.keyManagement.fileKeyLicense"),
          });
        }, 1000);
      } else {
        throw new Error("URL for file download is not available.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadSampleAccount = async () => {
    try {
      message.loading({
        content: t("v2.keyManagement.downloading"),
      });

      const res = await handleGetFileSampleKey({ productType: "ACCOUNT" });

      const url = res?.data?.product?.getSampleFile?.url;
      if (url) {
        const blob = await fetch(url).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          return response.blob();
        });

        const link = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "[Account] [Template] File mẫu.xlsx";
        link.click();

        window.URL.revokeObjectURL(objectUrl);
        setTimeout(() => {
          message.success({
            content: t("v2.keyManagement.fileAccount"),
          });
        }, 1000);
      } else {
        throw new Error("URL for file download is not available.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const menuDownload = (
    <Menu>
      <Menu.Item onClick={handleDownloadSampleKeyLicense}>{t("v2.keyManagement.fileSampleKeyLicense")}</Menu.Item>
      <Menu.Item onClick={handleDownloadSampleAccount}>{t("v2.keyManagement.fileSampleAccount")}</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menuDownload} trigger="click">
      <Tooltip title={t("v2.keyManagement.tooltipDownload")}>
        <SvgIcon.Xlsx style={{ width: "24px", cursor: "pointer" }} />
      </Tooltip>
    </Dropdown>
  );
};
