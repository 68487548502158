import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import { t } from "i18next";

const CancellationReason = ({ dataCancellationReason }) => {
  const { cancelReason, fullEvents } = dataCancellationReason;

  return (
    <Space
      style={{
        height: "48px",
        background: "#ffffff",
        width: "100%",
        paddingLeft: "16px",
        borderRadius: "8px",
      }}
    >
      <ExclamationCircleOutlined style={{ marginRight: "8px", color: "#D2434D" }} />
      <Typography.Text strong>
        {t("order.cancelReasons.cancelBy")}{" "}
        {fullEvents?.[fullEvents?.length - 1]?.createdBy?.type === "SELLER"
          ? t("order.cancelReasons.seller")
          : t("order.cancelReasons.staff")}{" "}
        {t("order.cancelReasons.by")}: {cancelReason}
      </Typography.Text>
    </Space>
  );
};

export default CancellationReason;
