import { usePrintExportNotesSerialImportNotes } from "hooks/serial";
import { useGetDetailSerialImport } from "hooks/serial/query";
import { uniq } from "lodash";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EXPORT_NOTES_TYPE, FILETYPE } from "../../constant";

function useDetail() {
  const { id } = useParams();
  const { data, loading, refetch } = useGetDetailSerialImport({ id: id });

  const [visibleTags, setVisibleTags] = useState(true);
  const [visibleSearchResult, setVisibleSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [tagsValueResult, setTagsValueResult] = useState([]);

  const handleSearchSerials = (values, tags) => {
    const sortedArray1 = tags;
    const sortedArray2 = uniq(values);
    setTagsValueResult(sortedArray2);
    const searchValues = sortedArray1.filter((item) => sortedArray2.includes(item.code));
    if (searchValues.length > 0) {
      setSearchResult(searchValues);
    } else {
      setSearchResult([]);
    }
  };

  const { handlePrintExportNotesSerialImportNotes, loadingExport } = usePrintExportNotesSerialImportNotes();
  const dataSerialImportNoteItemID = data?.items?.map((item) => item?.id);

  const handleExport = async () => {
    try {
      const params = {
        serialImportNoteID: data?.id,
        serialImportNoteItemIDs: dataSerialImportNoteItemID,
        type: EXPORT_NOTES_TYPE.SERIAL_IMPORT_NOTE,
        fileType: FILETYPE.XLSX,
      };
      const dataExport = await handlePrintExportNotesSerialImportNotes(params);
      const link = document.createElement("a");
      link.href = dataExport?.data?.serialImportNotes?.exportFile?.url;
      link.download = dataExport?.data?.serialImportNotes?.exportFile?.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.info(error);
    }
  };
  const handlePrint = async () => {
    try {
      const params = {
        serialImportNoteID: data?.id,
        serialImportNoteItemIDs: dataSerialImportNoteItemID,
        type: EXPORT_NOTES_TYPE.SERIAL_EXPORT_NOTE,
        fileType: FILETYPE.PDF,
      };
      const dataPrint = await handlePrintExportNotesSerialImportNotes(params);
      printJS(dataPrint?.data?.serialImportNotes?.exportFile?.url);
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    if (searchResult?.length) {
      setVisibleSearchResult(true);
      setVisibleTags(false);
    } else {
      setVisibleSearchResult(false);
      setVisibleTags(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchResult)]);

  return {
    data,
    loading,
    refetch,
    handleSearchSerials,
    tagsValueResult,
    visibleTags,
    visibleSearchResult,
    searchResult,
    handleExport,
    handlePrint,
    loadingExport,
  };
}

export default useDetail;
