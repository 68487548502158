import { PrimaryButton } from "components/PrimaryButton";
import { useRecontactCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { notify } from "utils/helperFuncs";

function RecontactButton({ customerId }) {
  const { handleRecontactCustomer, loadingRecontact } = useRecontactCustomer();

  const onSubmit = async () => {
    try {
      await handleRecontactCustomer({ customerIDs: customerId })
        .then(() => {
          notify.success({ message: t("potentialCustomer.updateSuccess") });
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <PrimaryButton onClick={onSubmit} loading={loadingRecontact} title={t("potentialCustomer.buttonGroup.reContact")} />
  );
}

export default RecontactButton;
