import React from "react";
import { useSellerPermissions } from "hooks/seller";
import { useGetUserPermissions } from "hooks/user/user";
import Spinner from "components/Spinner";
import Page403 from "pages/PageError/403";
import SupplierCreate from "../SupplierCreate";
import { useParams } from "react-router-dom";
import { useGetSupplierDetail, useSupplierPermissions } from "hooks/supplier";

function SupplierUpdate() {
  const { canUpdate } = useSellerPermissions();
  const { canUpdateSupplier } = useSupplierPermissions();

  const { loading: loadUserPermissions } = useGetUserPermissions();
  const { id } = useParams();
  const { data: supplierDetail, loading, refetch } = useGetSupplierDetail({ id });

  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canUpdate || canUpdateSupplier ? (
    <SupplierCreate isUpdate={true} supplierDetail={supplierDetail} refetch={refetch} loadingDetail={loading} />
  ) : (
    <Page403 />
  );
}

export default SupplierUpdate;
