import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Row, Typography } from "antd";
import ComboCollapse from "./ComboCollapse";

import Img from "assets/images";
import CustomModal from "components/CustomModal";
import { COMBO_CAMPAIGN, DISCOUNT_TYPE, TYPE_LIMIT } from "features/Campaign/constants";
import { t } from "i18next";
import { useEffect, useState } from "react";

const { Panel } = Collapse;

const initPromotionCampaignTiersForm = {
  type: COMBO_CAMPAIGN.DISCOUNT_MONEY,
  minQuantity: null,
  maxQuantityPerSeller: null,
  discountType: DISCOUNT_TYPE.DISCOUNT_MONEY,
  discountAmount: null,
  budgetAmount: null,
  conditionProductGroups: [{ quantity: null, conditionPromotionProducts: [] }],
  limitType: TYPE_LIMIT.LIMIT,
};

function ComboDiscountForm({
  form,
  promotionCampaignTiers,
  onRemoveValueComboSelected,
  indexErrorCombo,
  isUpdate,
  dataTiersServer,
  indexComboDiscount,
  remove,
  onGetDataDiscountMoney,
}) {
  const [valuesForm, setValuesForm] = useState();
  const [newDataTiersServer, setNewDataTiersServer] = useState(dataTiersServer);

  const handleGetDataDataTiersServer = (value) => {
    setNewDataTiersServer(value);
  };

  const handleRemoveComboCollapse = () => {
    const formValues = form.getFieldsValue();
    if (formValues?.promotionCampaignTierGroupsDiscountMoney?.length === 1) {
      onRemoveValueComboSelected(COMBO_CAMPAIGN.DISCOUNT_MONEY);
    }
    onGetDataDiscountMoney && onGetDataDiscountMoney([]);
    remove();
  };
  useEffect(() => {
    const values = form.getFieldsValue();
    setValuesForm(values);
  }, [JSON.stringify(form.getFieldsValue())]);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapses" expandIconPosition="left">
      <Panel
        key="1"
        header={
          <Typography.Text strong style={{ textTransform: "uppercase" }}>
            {t("campaign.combo.comboDiscount")} {indexComboDiscount + 1}
          </Typography.Text>
        }
        extra={
          <CustomModal
            {...{
              message: t("campaign.combo.errorMessage.removeComboCollapse"),
              onOke: () => handleRemoveComboCollapse(),
              footer: false,
              icon: Img.DeleteIcon,
              customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
            }}
          />
        }
      >
        <Row className="panel-main">
          <Col span={24}>
            <Form.List name={[indexComboDiscount, "promotionCampaignTiers"]}>
              {(fields, { add, remove }) => {
                const handleAddComboCollapse = () => {
                  add(initPromotionCampaignTiersForm);
                  setNewDataTiersServer(valuesForm?.promotionCampaignTierGroupsDiscountMoney);
                };
                return (
                  <Row>
                    {fields.map((field, index) => (
                      <Col span={24} key={field.key}>
                        <ComboCollapse
                          form={form}
                          indexPromotionCampaignTiers={index}
                          remove={() => remove(field.name)}
                          promotionCampaignTiers={promotionCampaignTiers}
                          indexErrorCombo={indexErrorCombo}
                          isUpdate={isUpdate}
                          dataTiersServer={newDataTiersServer}
                          indexComboDiscount={indexComboDiscount}
                          fields={fields}
                          valuesForm={valuesForm}
                          onGetDataDataTiersServer={handleGetDataDataTiersServer}
                        />
                      </Col>
                    ))}
                    <Col span={24}>
                      <Typography.Link underline onClick={() => handleAddComboCollapse()}>
                        <PlusOutlined /> {t("campaign.combo.addIncentives")}
                      </Typography.Link>
                    </Col>
                  </Row>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

export default ComboDiscountForm;
