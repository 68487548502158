import { Tooltip, Typography } from "antd";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import TextAlignLeft from "components/TextAlignLeft";
import { DATE_FORMAT } from "config/constants";
import { warrantyRequestCategoryOptions } from "features/Warranty/constant";
import { RenderDelayTime, RenderRemainingDeliverTime } from "features/Warranty/util";
import { useGetInfoPlatform } from "hooks/info-platform";
import useDebounce from "hooks/useDebounce";
import { useConfirmReturn, useExportFile, useGetWarrantyRequestDetail } from "hooks/warranty";
import { t } from "i18next";
import { isNil } from "lodash";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { convertTimeDate } from "utils/dateTime";
import { getQuery, notify } from "utils/helperFuncs";
import {
  EXPORT_WARRANTY_TYPE,
  ResultMethod,
  TOTAL_ITEMS_STATUS,
  optionsItemStatus,
  optionsSolution,
  renderItemsStatus,
  renderSolutionLabel,
  renderWarrantyRequestType,
  resultLabel,
} from "../constant";
import { getPercentBar, truncateAddDots } from "../helperFuncs";
import ButtonGroup from "./ButtonGroup";

const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

function useDetail() {
  const GET_QUERY = getQuery();
  const { id } = useParams();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);

  const [params, setParams] = useState({
    id: id || undefined,
    filters: {
      query: GET_QUERY?.query || undefined,
      itemSolutions: GET_QUERY?.itemSolutions || [],
      warrantyRequestTypes: GET_QUERY?.warrantyRequestTypes || [],
      itemStatuses: GET_QUERY?.itemStatuses || [],
    },
    sort: [],
  });

  const { data, loading, refetch, totalItemStatus } = useGetWarrantyRequestDetail(params);
  const { canceled, completed, process, waitingForProcessing } = getPercentBar(totalItemStatus);
  const { handleConfirmReturn, loading: loadingConfirmReturn } = useConfirmReturn();
  const { handleExportFile, loading: loadingExport } = useExportFile();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setselectedRowKeys] = useState();
  const { platformName } = useGetInfoPlatform();

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      setselectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRowKeys,
  };

  const isCheckingForReturn = data?.items
    ?.map((data) => data?.status)
    ?.filter((status) => status === TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN);

  const handleClick = (record) => {
    history.push({ pathname: `/warranty/serial/detail/${record?.id}`, state: { id: id } });
  };

  const columns = [
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.serial")}</Typography.Text>,
      fixed: "left",
      width: 200,
      render: (_, record) => {
        return (
          <Link to={`/warranty/serial/detail/${record?.id}`} onClick={() => handleClick(record)}>
            <Typography.Link strong>{record?.serial}</Typography.Link>
          </Link>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.parameter")}</Typography.Text>,
      dataIndex: "parameter",
      fixed: "left",
      width: 200,
      align: "center",
      render: (_, record) => {
        const productName = record?.product?.name || record?.productName || record?.warrantyNote?.productName;
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
              <Link to={`/product/detail/${record?.productID}`}>{record?.product?.code}</Link>
            </Typography.Text>
            <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
              <Tooltip title={productName}>
                <span>{truncateAddDots(productName)}</span>
              </Tooltip>
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.warrantyCode")}</Typography.Text>,
      dataIndex: "warrantyNote",
      width: 250,
      render: (_, record) => <TextAlignLeft value={<WarrantyDetailLink warranty={record?.warrantyNote} />} />,
      align: "center",
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.errorDetail")}</Typography.Text>,
      dataIndex: "defectDescription",
      width: 250,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Text style={{ textAlign: "start", margin: 0 }}>
            <Tooltip title={record?.defectDescription}>
              <span>{truncateAddDots(record?.defectDescription)}</span>
            </Tooltip>
          </Typography.Text>
        </div>
      ),
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.wResolve")}</Typography.Text>,
      dataIndex: "solution", //Hướng xử lý
      width: 250,
      align: "center",
      render: (_, record) => {
        const technicalStaffName = record?.technicalStaff && `NVKT: ${record?.technicalStaff?.fullname}`;
        return (
          <TextAlignLeft
            value={
              <Typography>
                <Typography.Text>{renderSolutionLabel(record?.solution, platformName)}</Typography.Text>
                <br />
                <Typography.Link italic>{record?.receivePartyName || technicalStaffName}</Typography.Link>
              </Typography>
            }
          />
        );
      },
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("itemSolutions", value)} type="checkbox" options={optionsSolution} />
      ),
    },
    {
      title: (
        <Typography.Text strong style={{ textTransform: "uppercase" }}>
          {t("warrantyRequest.detail.resultOfHA", { platformName })}
        </Typography.Text>
      ),
      dataIndex: "result",
      width: 250,
      align: "center",
      render: (_, record) => {
        const reImportSerial = `Serial: ${record?.alternativeProductSerial}`;

        const renderReImportSerial = {
          [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: reImportSerial,
          [ResultMethod.RENEW_SAME_PRODUCT]: reImportSerial,
        };
        return (
          <TextAlignLeft
            value={
              <Typography>
                <Typography.Text>{resultLabel?.[record?.result]}</Typography.Text>
                <br />
                <Typography.Link italic>{renderReImportSerial?.[record?.result]}</Typography.Link>
              </Typography>
            }
          />
        );
      },
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.requestType")}</Typography.Text>,
      dataIndex: "warrantyRequestType",
      width: 150,
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.warrantyNote?.remainingWarrantyDays <= 0
              ? t("warrantyRequest.detail.requestType.houseoldWarranty")
              : renderWarrantyRequestType(record?.warrantyRequestType)}
          </Typography.Text>
        );
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("warrantyRequestCategoryFilter", value)}
          type="radio"
          options={warrantyRequestCategoryOptions}
        />
      ),
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.dateReCustomer")}</Typography.Text>,
      dataIndex: "promisedReturnDate",
      width: 200,
      align: "center",
      sorter: true,
      render: (_, record) => {
        return (
          <div>
            <Typography.Text>{convertTimeDate(record?.promisedReturnDate, DATE_FORMAT)}</Typography.Text>
            <br />
            <Typography.Text strong style={{ color: "#00AB78" }}>
              <RenderDelayTime time={record?.delayReturnTimeRange} />
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.remainingDate")}</Typography.Text>,
      width: 200,
      align: "center",
      dataIndex: "resultReceivedDate",
      render: (_, record) => (
        <div>
          <Typography.Text>
            {convertTimeDate(record?.warrantyNote?.warrantyDate || record?.warrantyNote?.warrantyEndDate, DATE_FORMAT)}
          </Typography.Text>
          <br />
          <RenderRemainingDeliverTime time={record?.warrantyNote?.remainingWarrantyDays} />
        </div>
      ),
    },
    {
      title: <Typography.Text strong>{t("warrantyRequest.detail.table.status")}</Typography.Text>,
      dataIndex: "status",
      fixed: "right",
      width: 120,
      align: "center",
      render: (_, record) => (
        <div>
          <Typography.Text>{renderItemsStatus(record?.status)}</Typography.Text>
          <br />
          {record?.status === TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN && record?.isCancel && (
            <Typography.Text strong style={{ color: "#D2434D" }}>
              {`(${t("serial.warrantyDeny")}) `}
            </Typography.Text>
          )}
        </div>
      ),
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("itemStatuses", value)} type="checkbox" options={optionsItemStatus} />
      ),
    },
    {
      dataIndex: "actions",
      fixed: "right",
      width: 200,
      align: "center",
      render: (_, record, index) => {
        return (
          <ButtonGroup
            refetch={refetch}
            record={record}
            data={data}
            selectedRows={selectedRows.length > 0 ? selectedRows : data?.items[index]}
            selectedRowKeys={selectedRowKeys}
          />
        );
      },
    },
  ];

  const confirmReturn = async ({ warrantyRequestItemIDs, shippingType, shippingConfig }) => {
    await handleConfirmReturn({
      warrantyRequestItemIDs: warrantyRequestItemIDs,
      shippingType: shippingType,
      shippingConfig: shippingConfig,
    })
      .then(() => {
        notify.success({ message: t("notiActions.return") });
        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sort: sorter?.order ? [{ by: "PROMISE_RETURN_DATE", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParams(newParams);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  const onPrint = async ({ noteType }) => {
    await handleExportFile({
      warrantyRequestID: id,
      noteType: noteType,
      fileType: EXPORT_WARRANTY_TYPE.PDF,
    })
      .then((res) => {
        const fileUrl = res.data.warrantyRequest.exportFile.url;
        printJS({
          printable: fileUrl,
          type: EXPORT_WARRANTY_TYPE.PDF.toLowerCase(),
          showModal: true,
          modalMessage: t("common.loadingData"),
        });
      })
      .catch((err) => {
        notify.error({ message: t("common.error") });
      });
  };

  const onExport = async ({ noteType }) => {
    await handleExportFile({
      warrantyRequestID: id,
      noteType: noteType,
      fileType: EXPORT_WARRANTY_TYPE.XLSX,
    })
      .then((response) => {
        const fileUrl = response.data.warrantyRequest.exportFile.url;
        var win = window.open(fileUrl, "_blank");
        win.opener = null;
        win.blur();
        window.focus();
        window.close();
      })
      .catch(() => {
        notify.error({ message: t("common.error") });
      });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: {
          ...params?.filters,
          query: debouncedValue,
        },
        sort: [],
      };
      setParams(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return {
    data,
    columns,
    dataTable: data?.items,
    loading,
    params,
    canceled,
    completed,
    process,
    waitingForProcessing,
    isCheckingForReturn,
    loadingConfirmReturn,
    loadingExport,
    rowSelection,
    handleSearch,
    onChangeParams,
    refetch,
    handleChangeTable,
    confirmReturn,
    onPrint,
    onExport,
    handleExportFile,
  };
}

export default useDetail;
