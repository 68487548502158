import React, { useState } from "react";
import { Form } from "antd";
import { useUpload } from "hooks/upload";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";

function usePopupConfirmDeliComplete({ complete, itemID }) {
  const [form] = Form.useForm();
  const { handleUpload } = useUpload();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClick = async () => {
    const values = await form.validateFields();
    setLoadingBtn(true);
    const uploadImage = (values) =>
      values?.map(
        async (item) =>
          await handleUpload({ file: item?.originFileObj }).catch((error) => {
            notify.error({
              message: t("uploadDocument.uploadImageError"),
              description: error?.message,
            });
          })
      );

    const deliverFileURLs = !!values?.deliverFileURLs?.at(0)?.url
      ? [values?.deliverFileURLs.at(0).url]
      : await Promise.all(uploadImage(values?.deliverFileURLs)).then((res) => res);

    form
      .validateFields()
      .then(() => {
        complete({ itemID: itemID, deliverFileURLs: deliverFileURLs });
        setIsModalOpen(!isModalOpen);
        setLoadingBtn(false);
        form.resetFields();
      })
      .catch((info) => {
        console.info("Validate Failed:", info);
      });
  };

  return {
    form,
    handleClick,
    isModalOpen,
    showModal,
    handleCancel,
    loadingBtn,
  };
}

export default usePopupConfirmDeliComplete;
