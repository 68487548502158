import { Space, Tooltip, Typography, message } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { ERROR_TYPE_UPLOAD } from "features/PotentialCustomer/constants";
import { useUploadFileCustomer } from "hooks/potentialCustomer/mutation";
import { useGetSampleFileCustomer } from "hooks/potentialCustomer/query";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function AddNewBatchModal() {
  const [fileList, setFileList] = useState([]);
  const [dataFile, setDataFile] = useState();
  const [isModal, setIsModal] = useState(false);
  const [errorReason, setErrorReason] = useState();
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [errorType, setErrorType] = useState(undefined);
  const [errorFile, setErrorFile] = useState();

  const props = {
    name: "file",
    multiple: true,
    async onChange(info) {
      setFileList(info.fileList);
      setDataFile(info.file);
    },
  };

  const { handleUploadFileCustomer } = useUploadFileCustomer();
  const { handleGetFileSampleCustomer } = useGetSampleFileCustomer();

  const handleDownloadSample = async () => {
    try {
      message.loading({
        content: t("potentialCustomer.loadingDownSample"),
      });

      const res = await handleGetFileSampleCustomer();

      const url = res?.data?.potentialCustomer?.getBatchCreateExampleFile?.url;
      if (url) {
        const blob = await fetch(url).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          return response.blob();
        });

        const link = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "Danh-sach-khach-hang-mau.xlsx";
        link.click();

        window.URL.revokeObjectURL(objectUrl);
        setTimeout(() => {
          message.success({
            content: t("potentialCustomer.loadedDownSample"),
          });
        }, 1000);
      } else {
        throw new Error("URL for file download is not available.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setIsModal(true);
  };

  const handleCancel = () => {
    setIsModal(false);
    setDataFile([]);
    setFileList();
    setErrorType(undefined);
    setLoadingUploadFile(false);
  };

  const handleSubmit = async () => {
    try {
      if (dataFile) setLoadingUploadFile(true);

      if (errorType === ERROR_TYPE_UPLOAD.LIMIT_EXCEEDED) {
        setDataFile([]);
        setFileList();
        setErrorType(undefined);
        setLoadingUploadFile(false);
        return;
      }

      if (errorType === ERROR_TYPE_UPLOAD.WRONG_FORMAT && errorFile) {
        try {
          message.loading({
            content: t("potentialCustomer.loadingDownSample"),
          });

          setTimeout(() => {
            fetch(errorFile)
              .then((res) => {
                if (res.ok) {
                  return res.blob();
                } else {
                  throw new Error("Network response was not ok.");
                }
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "Danh-sach-khach-hang-loi.xlsx";
                link.click();
                window.URL.revokeObjectURL(url);
                handleCancel();
                message.success({
                  content: t("potentialCustomer.loadedDownSample"),
                });
              })
              .catch((error) => {
                console.error("There has been a problem with your fetch operation:", error);
              });
          }, 2000);
        } catch (error) {
          console.info(error);
        }
        return;
      }

      if (!fileList?.length) {
        notify.error({ message: t("potentialCustomer.errorFileLength") });
      } else {
        await handleUploadFileCustomer(dataFile)
          .then((res) => {
            const data = res?.data?.potentialCustomer?.batchCreate;
            setErrorFile(data?.file?.url);
            setErrorType(data?.errorType);
            setErrorReason(data?.errorMessage);
            setLoadingUploadFile(false);
            if (data?.status) {
              handleCancel();
              notify.success({ message: t("potentialCustomer.downFileSuccess") });
            }
          })
          .catch((error) => {
            setLoadingUploadFile(false);
          });
      }
    } catch (error) {
      console.info(error);
    }
  };

  const labelButton = (value) => {
    switch (value) {
      case ERROR_TYPE_UPLOAD?.WRONG_FORMAT:
        return t("potentialCustomer.downFileError");
      case ERROR_TYPE_UPLOAD?.LIMIT_EXCEEDED:
        return t("potentialCustomer.downFileDifferent");
      default:
        return t("common.confirm");
    }
  };

  return (
    <CustomModal
      visible={isModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      onOke={handleSubmit}
      closable={false}
      footer={false}
      title={
        <Typography.Text style={{ fontSize: "20px" }} strong>
          {t("potentialCustomer.uploadDocument")}
        </Typography.Text>
      }
      customComponent={
        <Tooltip title={t("potentialCustomer.addNewBatch")}>
          <SvgIcon.IconUpload style={{ width: "20px", cursor: "pointer" }} />
        </Tooltip>
      }
      width={500}
      buttonLoading={loadingUploadFile}
      okeButtonLabel={labelButton(errorType)}
      message={
        <Space direction="vertical" style={{ width: "100%" }}>
          {!errorType ? (
            <>
              <Dragger
                style={{ borderColor: "#2246DC" }}
                {...props}
                beforeUpload={() => false}
                fileList={fileList}
                maxCount={1}
                accept=".xlsx"
              >
                <p className="ant-upload-drag-icon">
                  <SvgIcon.LogoFileWordIcon />
                </p>
                <Typography.Link>{t("product.drapDropFile")}</Typography.Link>
              </Dragger>
              <Typography.Text style={{ fontSize: "13px", color: "#99A8BA" }} italic className="text-info-file">
                {t("potentialCustomer.notingUpload")}
              </Typography.Text>
              <Typography.Text style={{ float: "left" }}>
                {t("potentialCustomer.uploadFileSampleText")}
                <Typography.Link onClick={handleDownloadSample}>{t("potentialCustomer.here")}</Typography.Link>
              </Typography.Text>
              <Typography.Text strong type="danger" style={{ float: "left" }}>
                {t("potentialCustomer.notingSample")}
              </Typography.Text>
            </>
          ) : (
            <>
              <SvgIcon.WarningFileUpload />
              <Typography.Text>{errorReason || "--"}</Typography.Text>
            </>
          )}
        </Space>
      }
    />
  );
}

export default AddNewBatchModal;
