import { Card, Col, Form, Row, Space, Tooltip, Typography, message } from "antd";
import CustomModal from "components/CustomModal";
import { convertTotalNumber } from "features/Serial/constant";
import { t } from "i18next";
import { useState } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { SvgIcon } from "assets/icons";

import { uniq } from "lodash";
import InputSearch from "../../../components/InputSearch";
import styles from "../../../index.module.scss";
import "../../../index.scss";
import InputTag from "../InputTag";
import useTable from "../Table/useTable";

function SerialsInputTag({
  index,
  sumSerialByOrder,
  sumSerialByScanned,
  record,
  tagsIndex,
  form,
  handleGetTags,
  setVisible,
  visible,
}) {
  const { renderLack } = useTable();
  const tags = record?.serialItems?.map((item) => item);
  const [tagsCopy, setTagsCopy] = useState(tags); //onModal
  const [tagsCopy2, setTagsCopy2] = useState(tags); //offModal

  const handleChange = (e) => {
    visible ? setTagsCopy(e) : setTagsCopy2(e);
  };
  const [searchResult, setSearchResult] = useState([]);
  const [tagsValueResult, setTagsValueResult] = useState([]);

  const moreThanNumber = tagsIndex?.length > 2;
  const handleSearchSerials = (values) => {
    const sortedArray1 = tagsCopy2;
    const sortedArray2 = uniq(values);
    setTagsValueResult(sortedArray2);
    const searchValues = sortedArray1?.filter((value) => sortedArray2?.includes(value));
    if (searchValues?.length > 0) {
      setSearchResult(searchValues);
    } else {
      setSearchResult([]);
    }
  };

  const renderSubTotal = (record) => {
    if (record?.quantity < record?.serialItems?.length) {
      return (
        <>
          <Col span={5}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={19} style={{ color: "red" }}>
            {record?.serialItems?.length - record?.quantity}
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return (
        <>
          <Col span={5}>
            <Typography.Text strong style={{ color: "" }}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <>
          <Col span={5}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}
            </Typography.Text>
          </Col>
          <Col span={19} style={{ color: "#ef9351" }}>
            {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
          </Col>
        </>
      );
    }
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    if (tagsCopy2?.length > 0) {
      setTagsCopy([...tagsCopy2]);
    }
    setVisible(false);
  };

  const handleOke = () => {
    setTagsCopy2([...tagsCopy]);
    setVisible(false);
  };

  const copyToClipboard = () => {
    if (tags?.length > 0) {
      const textToCopy = tags.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCoppy"));
    }
  };

  return (
    <Space className="custom-input-tag">
      <Form.Item name={[index, "serialItems"]}>
        <InputTag
          isHidden={true}
          // value={tags}
          tags={tags}
          onChange={handleChange}
          moreThanNumber={moreThanNumber}
          idPopup={false}
          productId={record?.product?.id}
          form={form}
        />
      </Form.Item>
      {/* {moreThanNumber && ( */}
      <CustomModal
        {...{
          // buttonLabel: <Typography.Link>{t('common.seemore')}</Typography.Link>,
          footer: false,
          title: (
            <Typography.Title level={4} strong>
              {t("serialIE.popupAddSerial")}
            </Typography.Title>
          ),
          width: "60%",
          visible: visible === index,
          onCancel: handleCancel,
          onOpen: handleOpen,
          onOke: handleOke,
          cancelButtonLabel: t("common.close"),
          hideConfirmButton: true,
        }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Card
              style={{ background: "#F7F8FB", marginBottom: "16px" }}
              children={
                <Row gutter={[16, 8]} className={styles["summary"]}>
                  <Col span={5}>
                    <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                  </Col>
                  <Col span={19}>
                    <Typography.Link strong href={`/product/detail/${record?.product?.id}`} target="_blank">
                      {record?.product?.code}
                    </Typography.Link>
                  </Col>
                  <Col span={5}>
                    <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                  </Col>
                  <Col span={19}> {record?.product?.name}</Col>
                  <Col span={5}>
                    <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                  </Col>
                  <Col span={19}> {convertTotalNumber(record?.quantity)}</Col>
                  <Col span={5}>
                    <Typography.Text strong>{t("serialIE.productInfo.serialScanned")}</Typography.Text>
                  </Col>
                  <Col span={19}> {convertTotalNumber(record?.serialItems?.length)}</Col>

                  {renderSubTotal(record)}
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <InputSearch onSearch={handleSearchSerials} />
          </Col>
          <Col span={24}>
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <div>
                <ExclamationCircleFilled style={{ color: "#EF9351" }} />
                <Typography.Text type="warning" style={{ fontStyle: "italic" }}>
                  {t("serialIEDetail.warning")}
                </Typography.Text>
              </div>
              <Tooltip title={t("serialIE.coppy")}>
                <SvgIcon.CopyIcon
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  width="24px"
                  height="24px"
                  onClick={() => copyToClipboard()}
                />
              </Tooltip>
            </Space>
          </Col>
          <Col span={24}>
            <Form.Item name={[index, "serialItems"]}>
              <InputTag
                // value={tags}
                tags={tags}
                searchResult={searchResult}
                tagsValueResult={tagsValueResult}
                onChange={handleChange}
                form={form}
                idPopup={true}
                productId={record?.product?.id}
              />
            </Form.Item>
          </Col>
        </Row>
      </CustomModal>
      {/* )} */}
    </Space>
  );
}

export default SerialsInputTag;
