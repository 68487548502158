import { Form } from "antd";
import { useGetMe } from "hooks/auth/login";
import { useCustomerPermissions } from "hooks/potentialCustomer/query";
import { useGetPotentialCustomerReport } from "hooks/report";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getQuery } from "utils/helperFuncs";
import { REPORT_TYPE } from "../constants";

const usePotentialCustomerChartUI = () => {
  const { t } = useTranslation();
  const GREETING = {
    GOOD_MORNING: t("report.greeting.morning"),
    GOOD_AFTERNOON: t("report.greeting.afternoon"),
    GOOD_EVENING: t("report.greeting.evening"),
    GOOD_NIGHT: t("report.greeting.night"),
    HELLO: t("report.greeting.hello"),
  };
  const [form] = Form.useForm();
  const GET_QUERY = getQuery();
  const { me } = useGetMe();

  const [reportType, setChartType] = useState();
  const [chartData, setChartData] = useState();
  const [params, setParams] = useState({
    filters: {
      reportType: GET_QUERY.reportType,
      timeRange: GET_QUERY.timeRanges || {},
    },
  });

  const initialValues = {
    type: undefined,
  };
  const {
    canCustomerReportStaffView,
    canCustomerReportManagerView,
    loading: loadingPermission,
  } = useCustomerPermissions();

  const { data, loading } = useGetPotentialCustomerReport(params);

  const handleChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    handleChangeParams && handleChangeParams({ [`${key}`]: value });
  };
  const handleChangeReportType = (reportType) => {
    setChartType(reportType);
    onFilter("reportType", reportType);
  };

  const handleChangeTimeRange = (timeRange) => {
    onFilter("timeRange", {
      from: timeRange?.at(0),
      to: timeRange?.at(1),
    });
  };

  const renderSubtitle = () => {
    if (reportType === REPORT_TYPE.PERSONAL) return t("report.subTitleSale");
    if (reportType === REPORT_TYPE.GENERAL) return t("report.subTitleAdmin");
    return "";
  };

  const fetchChartToken = async () => {
    return data?.token;
  };

  useEffect(() => {
    setChartData(data);
  }, [data]);

  useEffect(() => {
    const type =
      (canCustomerReportStaffView && REPORT_TYPE.PERSONAL) ||
      (canCustomerReportManagerView && REPORT_TYPE.GENERAL) ||
      null;
    setParams({
      filters: {
        reportType: type,
      },
    });
    form.setFieldsValue({
      type: type,
    });
    setChartType(type);
  }, [loadingPermission]);

  return {
    GREETING,
    me,
    chartData,
    form,
    handleChangeReportType,
    handleChangeTimeRange,
    loadingChart: loading,
    fetchChartToken,
    renderSubtitle,
    initialValues,
  };
};

export default usePotentialCustomerChartUI;
