import { QuestionCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButton } from "components/PrimaryButton";
import { useCompleteCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function CompleteButton({ customerId }) {
  const [showModal, setShowModal] = useState(false);

  const { handleCompleteCustomer, loadingComplete } = useCompleteCustomer();

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const onSubmit = async () => {
    try {
      handleCompleteCustomer({
        customerIDs: customerId,
      })
        .then(() => {
          handleCancel();
          notify.success({ message: t("potentialCustomer.updateSuccess") });
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
          handleOpen();
        });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={showModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      customComponent={<PrimaryButton title={t("potentialCustomer.buttonGroup.complete")} />}
      onOke={onSubmit}
      buttonLoading={loadingComplete}
      children={<Typography.Text>{t("potentialCustomer.confirmCustomer")}</Typography.Text>}
      customIcon={
        <QuestionCircleFilled
          style={{
            fontSize: "50px",
            color: "#2246DC",
            padding: "20px",
            borderRadius: 50,
            background:
              "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
          }}
        />
      }
    />
  );
}

export default CompleteButton;
