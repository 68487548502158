import { Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import PageHeader from "components/PageHeader";
import CustomerCompletedTab from "features/PotentialCustomer/PotentialCustomerList/CustomerCompletedTab/customerCompletedTab";
import { useGetCountPotentialCustomer } from "hooks/potentialCustomer/query";
import { useTotalByStatus } from "hooks/seller";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import AgentTab from "./AgentTab";

export default function AgentTabs() {
  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.statuses || "AGENT");
  const { completeCaringCount } = useGetCountPotentialCustomer();
  const { data: dataTotalByStatus } = useTotalByStatus();

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    buildQueryString({
      params: { statuses: activeKey },
    });
  };

  const tabList = [
    {
      key: "COMPLETE_CARING",
      tab: `${t("seller.sellerWaitApprove")} (${completeCaringCount || 0})`,
    },
    {
      key: "AGENT",
      tab: `${t("seller.seller")} (${dataTotalByStatus?.approval || 0})`,
    },
  ];

  const contentList = {
    COMPLETE_CARING: <CustomerCompletedTab isAgent />,
    AGENT: <AgentTab />,
  };

  useEffect(() => {
    buildQueryString({
      params: {
        statuses: GET_QUERY.statuses || "AGENT",
        ...GET_QUERY,
      },
    });
  }, [GET_QUERY.statuses]);

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          pageTitle={t("seller.sellerAccList")}
          routes={[
            {
              path: "/setting",
              name: t("seller.systemSetting"),
            },
            {
              path: "",
              name: t("seller.sellerAccManage"),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
        />
      </Col>
    </Row>
  );
}
