import { Typography } from "antd";
import DividerCustom from "components/_storybooks/DividerCustom";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import TagCustom from "components/_storybooks/TagCustom";
import CustomDescriptions from "components/CustomDescriptions";
import { SHIPPING_TYPE } from "config/constants";
import { isOrderTypeCustomer } from "features/Order/helper";
import { useOrderPermissions } from "hooks/order";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";

const Bill = ({ dataInfoProductAndBill }) => {
  const {
    shippingFee,
    totalServiceProduct,
    discount,
    subTotal,
    customerSubTotal,
    customerTotal,
    total,
    orderType,
    totalPromotionDiscountMoney,
    shippingType,
    shippingConfiguration,
    shippingStatus,
    customerShippingFee,
    customerTotalServiceProduct,
    sellerProfitAmount,
  } = dataInfoProductAndBill;

  const { canPromotionOrderView } = useOrderPermissions();

  const renderShippingFee = () => {
    if (
      shippingType === SHIPPING_TYPE.DELIVER_3PARTY &&
      shippingConfiguration?.deliver3PartyConfig?.provider === null &&
      shippingFee === 0 &&
      shippingStatus !== "WAITING" &&
      shippingStatus !== "CONFIRM_PICKED"
    ) {
      if (
        shippingConfiguration?.deliver3PartyConfig?.carrierMinFee ===
        shippingConfiguration?.deliver3PartyConfig?.carrierMaxFee
      ) {
        return `${formatCurrency(shippingConfiguration?.deliver3PartyConfig?.carrierMinFee)}`;
      } else {
        return `${formatCurrency(shippingConfiguration?.deliver3PartyConfig?.carrierMinFee)} - ${formatCurrency(
          shippingConfiguration?.deliver3PartyConfig?.carrierMaxFee
        )}`;
      }
    } else {
      return formatCurrency(shippingFee);
    }
  };

  const promotionDiscountMoney = (canPromotionOrderView ? 0 : totalPromotionDiscountMoney) || 0;

  const isCustomerOrder = isOrderTypeCustomer(orderType);
  const contentMappingBill = [
    {
      label: t("order.orderDetail.totalAmountAgent"),
      value: formatCurrency(subTotal + promotionDiscountMoney),
    },
    {
      label: t("order.orderDetail.totalAmountClient"),
      value: formatCurrency(customerSubTotal),
      isHidden: !isCustomerOrder,
    },
    {
      label: t("order.orderDetail.serviceFee"),
      value: formatCurrency(totalServiceProduct),
    },
    {
      label: t("order.orderDetail.saleOff"),
      value: formatCurrency(discount),
    },
    {
      label: t("common.campaign"),
      value: formatCurrency(promotionDiscountMoney),
      isHidden: !canPromotionOrderView,
    },
    {
      label: t("order.orderDetail.totalPaymentAgent"),
      value: formatCurrency(total),
    },
    {
      label: t("order.orderDetail.totalAmountClient"),
      value: formatCurrency(customerTotal),
      isHidden: !isCustomerOrder,
    },
  ]?.filter((item) => !item?.isHidden);

  return (
    <SpaceCustom gap={12} style={{ background: "#F7F8FB", borderRadius: "8px", padding: "12px" }}>
      {orderType !== "CUSTOMER" ? (
        <>
          <CustomDescriptions
            bordered={false}
            colon={false}
            labelWidth={"60%"}
            contentWidth={"40%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            title={<DividerCustom fontWeight="bold" children={t("order.orderDetail.bill")} margin={0} />}
            contentMapping={contentMappingBill}
          />
          <DividerCustom dashed margin={0} borderTopColor="#D8DFEA" />
          <CustomDescriptions
            bordered={false}
            colon={false}
            labelWidth={"50%"}
            contentWidth={"50%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            contentMapping={[
              {
                label: t("cart.debtPaymentAgent"),
                value: formatCurrency(total),
              },
              {
                label: t("order.orderDetail.shippingFee"),
                value: renderShippingFee(),
              },
            ]}
          />
        </>
      ) : (
        <>
          <DividerCustom fontWeight="bold" children={"Hóa đơn"} margin={0} />
          <CustomDescriptions
            loading={false}
            bordered={false}
            colon={false}
            labelWidth={"50%"}
            contentWidth={"50%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho khách của đại lý</Typography.Text>}
            contentMapping={[
              {
                label: "Tổng tạm tính khách hàng",
                value: formatCurrency(customerSubTotal),
              },
              {
                label: "Phí vận chuyển",
                value: formatCurrency(customerShippingFee),
              },
              {
                label: "Phí dịch vụ",
                value: formatCurrency(customerTotalServiceProduct),
              },
              {
                label: <Typography.Link strong>Tổng thanh toán của khách</Typography.Link>,
                value: <Typography.Link strong>{formatCurrency(customerTotal)}</Typography.Link>,
              },
            ]}
          />
          <DividerCustom dashed margin={0} borderTopColor="#D8DFEA" />
          <CustomDescriptions
            loading={false}
            bordered={false}
            colon={false}
            labelWidth={"50%"}
            contentWidth={"50%"}
            labelStyle={{
              fontWeight: "normal",
            }}
            contentStyle={{
              justifyContent: "flex-end",
            }}
            paddingView="0 10px 0 10px"
            title={<Typography.Text style={{ fontSize: 14 }}>Hóa đơn cho đại lý</Typography.Text>}
            contentMapping={[
              {
                label: "Doanh thu bán hàng",
                value: formatCurrency(customerSubTotal),
              },
              {
                label: "Tổng thành tiền đại lý",
                value: formatCurrency(subTotal),
              },
              {
                label: "Giảm giá đơn hàng",
                value: formatCurrency(discount),
              },
              {
                label: "Lợi nhuận trước chi phí",
                value: (
                  <Typography.Text strong style={{ color: "#0099FF" }}>
                    {formatCurrency(customerSubTotal - (subTotal - discount))}
                  </Typography.Text>
                ),
              },
              {
                label: "Phí vận chuyển",
                value: formatCurrency(shippingFee),
              },
              {
                label: "Phí dịch vụ",
                value: formatCurrency(totalServiceProduct),
              },
              {
                label: <Typography.Link strong>Lợi nhuận của đại lý</Typography.Link>,
                value: (
                  <TagCustom color="#E9F9E9" style={{ color: "#0EAB10", margin: 0 }}>
                    {formatCurrency(sellerProfitAmount)}
                  </TagCustom>
                ),
              },
            ]}
          />
        </>
      )}
    </SpaceCustom>
  );
};

export default Bill;
