import { Button, Dropdown, Input, Tooltip } from "antd";
import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import CustomTable from "components/CustomTable";
import StateSwitch from "components/StateSwitch";
import { useGetSellerWithWallet } from "hooks/seller/index";
import useSearchTextDebounce from "hooks/useSearchTextDebounce";
import { useAccountantPermissions } from "hooks/user/user";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { buildQueryString, formatCurrency, getQuery } from "utils/helperFuncs";
import FilterMenu from "./components/FilterMenu/index";
import SellerRechargeModal from "./components/SellerRechargeModal";
import "./index.scss";

const WALLET_STATUS = (number) => {
  switch (number < 0) {
    case true:
      return <div className="wallet-status">{formatCurrency(number)}</div>;
    case false:
      return <div>{formatCurrency(number)}</div>;
    default:
      return <div>0 đ</div>;
  }
};

function isSellerActive(record) {
  return record?.isActive;
}

const SellerList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [params, setParams] = useState({
    offset: getQuery().offset || 0,
    limit: 10,
    fullTextSearch: getQuery().fullTextSearch || null,
  });
  const search = useSearchTextDebounce(params, setParams);
  const { canRechargeFromBank, canRechargeFromVirtualWallet, displaySellerTxHistory } = useAccountantPermissions();

  const getParams = () => ({
    filters: {
      query: params.fullTextSearch,
      exportedWarehouseID: params.exportedWarehouseID,
      exportedWarehouseIDs: params.exportedWarehouseIDs,
      isActive: params.isActive,
      sellerLevelID: params.sellerLevelID,
    },
    pagination: {
      limit: params.limit,
      offset: params.offset,
    },
  });

  const { loading, data, total, refetch } = useGetSellerWithWallet(getParams());

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      offset: (current - 1) * pageSize,
    };
    setParams(newParams);
    buildQueryString({
      params: newParams,
    });
  }

  const fetchSellerList = async () => {
    await refetch(getParams);
  };

  const columns = [
    {
      title: "MÃ ĐẠI LÝ",
      dataIndex: "code",
      render: (_, record) => (
        <Link to={`/seller/detail/${record?.id}`} className="agent-code">
          {record?.code}
        </Link>
      ),
    },
    {
      title: "TÊN ĐẠI LÝ",
      dataIndex: "shortName",
    },
    {
      align: "center",
      title: "CẤP ĐỘ",
      dataIndex: ["sellerLevel", "level"],
    },
    {
      align: "center",
      title: "SỐ DƯ VÍ",
      dataIndex: ["companyWallet", "balance"],
      render: (_, record) => WALLET_STATUS(record?.companyWallet?.balance),
    },
    {
      align: "center",
      title: "SỐ DƯ NỢ",
      dataIndex: "currentDebt",
      render: (_, record) => formatCurrency(record?.currentDebt),
    },
    {
      align: "center",
      title: "HẠN MỨC CÔNG NỢ",
      dataIndex: "debtLimit",
      render: (_, record) => formatCurrency(record?.debtLimit),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "isActive",
      render: (_, record) => {
        return <StateSwitch disabled checked={record?.isActive} />;
      },
    },
    {
      align: "center",
      title: "THAO TÁC",
      dataIndex: "actions",
      render: (_, record) => {
        const sellerName = `${record?.shortName} - ${record?.fullName}`;

        return (
          <div className="actions">
            {(canRechargeFromBank || canRechargeFromVirtualWallet) && isSellerActive(record) && (
              <SellerRechargeModal sellerName={sellerName} record={record} />
            )}

            {displaySellerTxHistory && (
              <Tooltip title="Xem lịch sử">
                <SvgIcon.TransactionHistory
                  onClick={() => {
                    history.push({
                      pathname: "transaction-history",
                      state: { tab: "1", query: record?.code.toString() },
                    });
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  function filterSellerExtra(values) {
    setParams({
      ...params,
      ...values,
      offset: 0,
    });
  }

  useEffect(() => {
    fetchSellerList();
  }, [params]);

  return (
    <div className="seller-list">
      <div className="filter-box">
        <div className="search-input">
          <Input
            className="custom-input"
            allowClear={true}
            placeholder="Tìm kiếm mã hoặc tên đại lý"
            defaultValue={getQuery().fullTextSearch || ""}
            onChange={search.onChange}
          />
        </div>

        <Dropdown
          overlayClassName="dropdown-overlay"
          trigger="click"
          overlay={<FilterMenu onFilter={filterSellerExtra} />}
          placement="bottomRight"
        >
          <Button icon={<FontAwesomeIcon icon={Icon.faFilter} className="filter-icon" />} className="filter-btn">
            {t("common.filter")}
          </Button>
        </Dropdown>
      </div>

      <CustomTable
        loading={loading}
        pagination={{
          total: total,
          pageSize: params.limit,
          current: params.offset / params.limit + 1,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        columns={columns}
        dataSource={data}
        scroll={{ x: 800, y: null }}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default SellerList;
