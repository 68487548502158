import { Col, Form, Row, Typography } from "antd";
import CustomCard from "components/CustomCard";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { INPUT_TYPE } from "config/constants";
import { styleHeaderCard, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";
import { getFile } from "utils/helperFuncs";

function AddInfo() {
  return (
    <CustomCard
      headStyle={styleHeaderCard}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.addInfo")}
        </Typography.Text>
      }
      content={
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              formItemOptions={{
                label: <b>{t("common.description")}</b>,
                labelCol: { span: 24, offset: 0 },
                name: "internalNote",
              }}
              inputOptions={{
                rows: 3,
                placeholder: t("warrantyRequest.create.note"),
              }}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              name={"fileURLs"}
              getValueFromEvent={getFile}
              valuePropName={"fileList"}
              label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
              extra={t("warrantyRequest.create.noteAttack")}
            >
              <UploadFiles />
            </Form.Item>
          </Col>
        </Row>
      }
    />
  );
}

export default AddInfo;
