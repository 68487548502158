import { Form } from "antd";
import FormPotentialCustomer from "features/PotentialCustomer/PotentialCustomerDrawerForm/FormPotentialCustomer";
import { dataSourceLabel, sellerTypeLabel } from "features/PotentialCustomer/constants";
import { useEffect } from "react";
import { compact } from "lodash";

function PotentialCustomerDetail({
  visible,
  onClose,
  loadingDetail,
  dataDetail,
  isCustomerButton,
  handleOpenDrawerMulti,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...dataDetail,
      status: dataDetail?.status,
      dataSource: dataSourceLabel?.[dataDetail?.dataSource] || "--",
      careStaffID: dataDetail?.careStaff?.fullname || "--",
      inChargeStaffID: dataDetail?.inChargeStaff?.fullname || "--",
      cityID: dataDetail?.city?.name || "--",
      districtID: dataDetail?.district?.name || "--",
      wardID: dataDetail?.ward?.name || "--",
      type: sellerTypeLabel?.[dataDetail?.type] || "--",
      vatNumber: dataDetail?.vatNumber || "--",
      note: dataDetail?.note || "--",
      legalRepresentativeName: dataDetail?.legalRepresentativeName || "--",
      legalRepresentativeTelephone: dataDetail?.legalRepresentativeTelephone || "--",
      legalRepresentativeEmail: dataDetail?.legalRepresentativeEmail || "--",
      registeredBusinessAddress: dataDetail?.registeredBusinessAddress || "--",
      representativeName: dataDetail?.representativeName || "--",
      representativeEmail: dataDetail?.representativeEmail || "--",
      representativeTelephone: dataDetail?.representativeTelephone || "--",
      expectedRevenue: dataDetail?.expectedRevenue || "--",
      priority: dataDetail?.priority || "--",
      telephone: dataDetail?.telephone || "--",
      email: dataDetail?.email || "--",
      idNumber: dataDetail?.idNumber || "--",
      address: dataDetail?.address || "--",
      interestedCategoryIDs: dataDetail?.interestedCategories?.length
        ? dataDetail?.interestedCategories?.map((item) => compact([item.name]).join(", "))
        : "--",
    });
  }, [dataDetail]);

  return (
    <Form className={"custom-drawer-multi"} form={form} colon={false} layout="horizontal">
      <FormPotentialCustomer
        openDrawer={visible}
        handleCloseDrawer={onClose}
        loadingDetail={loadingDetail}
        form={form}
        dataMulti={dataDetail}
        notBordered={{ bordered: false, disabled: true }}
        isDetail={true}
        typePersonal={dataDetail?.type}
        isCustomerButton={isCustomerButton}
        handleOpenDrawerMulti={handleOpenDrawerMulti}
      />
    </Form>
  );
}

export default PotentialCustomerDetail;
