import React, { useEffect, useState } from "react";
import { Form, Typography } from "antd";
import { INPUT_TYPE, REGEX } from "config/constants";
import { checkIcon, WarrantyReceiveMethod } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import { useGetWarrantyRequestUpdate } from "hooks/warranty";
import { useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { some } from "lodash";
import { useGetInfoPlatform } from "hooks/info-platform";

function useFormEditCustomerRequest({ data }) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { handleUpdateaWarrantyRequest, loading } = useGetWarrantyRequestUpdate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { platformName } = useGetInfoPlatform();

  const initialValues = {
    warrantyCustomerAddress: data?.warrantyCustomerAddress,
    warrantyCustomerName: data?.warrantyCustomerName,
    warrantyCustomerPhone: data?.warrantyCustomerPhone,
    warrantyReceiveMethod: data?.warrantyReceiveMethod,
  };

  const isCompleted = some(data?.items, { status: "COMPLETED" });
  const isCanceled = some(data?.items, { status: "CANCELED" });
  const isDelivering = some(data?.items, { status: "DELIVERING" });

  const showModal = () => {
    setIsModalOpen(checkIcon(isCompleted || isCanceled || isDelivering));
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
    form.setFieldsValue({ ...initialValues });
  };

  const optionsReception = [
    {
      value: WarrantyReceiveMethod.BUS,
      label: t("warrantyRequest.create.shippingType.bus"),
    },
    {
      value: WarrantyReceiveMethod.SHIPPING_PROVIDER,
      label: t("warrantyRequest.create.shippingType.shippingProvider"),
    },
    {
      value: WarrantyReceiveMethod.CUSTOMER_BRING,
      label: t("warrantyRequest.create.shippingType.pickUp"),
    },
    {
      value: WarrantyReceiveMethod.SHIPPER,
      label: t("warrantyRequest.create.shippingType.shipper", { platformName }),
    },
  ];

  const formFields = [
    {
      ...{
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.fullName")}</Typography.Text>,
          name: "warrantyCustomerName",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.fullName"),
          maxLength: 255,
        },
      },
    },
    {
      ...{
        type: INPUT_TYPE.PHONE_NUMBER_INPUT,
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.phoneNumber")}</Typography.Text>,
          name: "warrantyCustomerPhone",
          rules: [
            {
              required: true,
              message: t("seller.validateMessage.telephone"),
            },
            {
              pattern: REGEX.PHONE,
              message: t("common.wrongPhone"),
            },
          ],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.phoneNumber"),
        },
      },
    },
    {
      ...{
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.address")}</Typography.Text>,
          name: "warrantyCustomerAddress",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.address"),
          maxLength: 255,
        },
      },
    },
    {
      ...{
        type: INPUT_TYPE.SELECT,
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.receptionType")}</Typography.Text>,
          name: "warrantyReceiveMethod",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.receptionType"),
          options: optionsReception,
        },
      },
    },
  ];

  const handleOk = async () => {
    await form.validateFields().then((values) => {
      handleUpdateaWarrantyRequest({ id: id, request: { ...values } })
        .then((res) => {
          if (res.data.warrantyRequest.update) {
            setIsModalOpen(!isModalOpen);
            notify.success({ message: t("warrantyRequest.detail.updateInfo") });
          } else {
            notify.error({ message: t("common.updateError") });
          }
        })
        .catch((err) => {
          console.info(err);
        });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    });
  }, [data]);

  return {
    formFields,
    form,
    handleOk,
    loading,
    isModalOpen,
    showModal,
    handleCancel,
    initialValues,
    isCompleted,
    isCanceled,
    isDelivering,
  };
}

export default useFormEditCustomerRequest;
