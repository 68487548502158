import { gql } from "@apollo/client";

export const GET_POTENTIAL_CUSTOMER_REPORT = gql`
  query GetPotentialCustomerReport($filters: PotentialCustomerReportFilter!) {
    report {
      getPotentialCustomerReport(filters: $filters) {
        dashboardID
        domainUrl
        token
      }
    }
  }
`;

export const GET_REPORT_SALE_INFO = gql`
  query GetReportSalesInfo($filter: ReportFilters) {
    report {
      getSalesReport(filter: $filter) {
        dashboardID
        domainUrl
      }
    }
  }
`;

export const GET_REPORT_MANAGER_INFO = gql`
  query GetReportSalesManagerInfo($filter: ReportFilters) {
    report {
      getSalesManagerReport(filter: $filter) {
        dashboardID
        domainUrl
      }
    }
  }
`;

export const GET_REPORT_SALE_TOKEN = gql`
  query GetReportSalesToken($filter: ReportFilters) {
    report {
      getSalesReport(filter: $filter) {
        dashboardID
        token
      }
    }
  }
`;

export const GET_REPORT_MANAGER_TOKEN = gql`
  query GetReportSalesManagerToken($filter: ReportFilters) {
    report {
      getSalesManagerReport(filter: $filter) {
        dashboardID
        token
      }
    }
  }
`;
