import React from "react";
import { Affix, Button, Form } from "antd";
import CreateButton from "components/CreateButton";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { useHistory } from "react-router-dom";

const ButtonGroup = ({ loadingBtn, onCancel, onSave, onSaveAndAdd, isUpdate, isApproveSeller }) => {
  const { t } = useTranslation();
  const history = useHistory();

  //affix can detect antd form
  return (
    <Affix offsetBottom={10}>
      <div className="button-group-seller-action">
        <Form.Item>
          <Button className="cancel-btn custom-button" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
        </Form.Item>

        <Form.Item>
          <CreateButton onClick={onSave} loading={loadingBtn} />
        </Form.Item>

        {isUpdate || isApproveSeller ? (
          <></>
        ) : (
          <Form.Item>
            <CreateButton onClick={onSaveAndAdd} title={t("common.saveAndAdd")} loading={loadingBtn} />
          </Form.Item>
        )}
      </div>
    </Affix>
  );
};

export default ButtonGroup;
