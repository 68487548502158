import { Col, Input, Row, Space } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import CreateButton from "components/CreateButton";
import { useToolbar } from "./useToolbar";

import AddNewBatchModal from "features/PotentialCustomer/AddPotentialCustomer/AddNewBatchModal";
import { t } from "i18next";
import "./index.scss";
import { useCustomerPermissions } from "hooks/potentialCustomer/query";

function Toolbar({ onSearch, onChangeParams, params, onClick, customButton, isAgent }) {
  const { renderTags } = useToolbar({
    params,
    onChangeParams,
  });

  const { canCustomerCreate } = useCustomerPermissions();

  return (
    <>
      <div className="filter-potential-customer-container" style={{ padding: "12px 0" }}>
        <Row style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Col xl={8} span={12}>
            <Input
              className="custom-input"
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={t("potentialCustomer.searchInputPlaceholder")}
              allowClear={true}
              defaultValue={params?.filters?.query}
              onChange={(value) => onSearch(value)}
            />
          </Col>
          <Col xl={8} span={4}></Col>
          <Col span={8}>
            {!isAgent && canCustomerCreate && (
              <Space style={{ float: "right", gap: "28px" }}>
                <div style={{ display: "flex", paddingRight: "12px" }}>
                  <AddNewBatchModal />
                </div>
                {customButton || (
                  <CreateButton
                    title={t("potentialCustomer.createCustomer")}
                    className="btn-create"
                    onClick={onClick}
                  />
                )}
              </Space>
            )}
          </Col>
        </Row>
      </div>
      <div className="tags-list">
        <div>{renderTags}</div>
      </div>
    </>
  );
}

export default Toolbar;
