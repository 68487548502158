import { Tabs } from "antd";
import "./index.scss";

export function TabsMenu({ tabs, onChange, defaultActiveKey, ...rest }) {
  const handleChange = (activeKey) => {
    onChange && onChange(activeKey);
  };

  return (
    <Tabs className="custom-tabs" defaultActiveKey={defaultActiveKey} onChange={handleChange} {...rest}>
      {tabs?.map((tab) => (
        <Tabs.TabPane active tab={<span className="title">{tab.title}</span>} key={tab.key}>
          {tab.children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}

export default TabsMenu;
