import { unzip } from "lodash";

export const converterCategoriesForForm = (data, level) => {
  if (level) {
    return data?.flatMap((item) =>
      item?.children?.length
        ? item?.level === level
          ? {
              level: item.level,
              label: item.name,
              value: item.id,
              disabled: false,
              children: converterCategoriesForForm(item.children, level),
            }
          : {
              level: item.level,
              label: item.name,
              value: item.id,
              disabled: true,
              children: converterCategoriesForForm(item.children, level),
            }
        : item?.level === level && item?.isActive
        ? { level: item.level, label: item.name, value: item.id, disabled: false }
        : { level: item.level, label: item.name, value: item.id, disabled: true }
    );
  } else {
    return data?.flatMap((item) =>
      item?.children?.length
        ? {
            level: item.level,
            label: item.name,
            value: item.id,
            children: converterCategoriesForForm(item.children),
          }
        : { level: item.level, label: item.name, value: item.id }
    );
  }
};

export const converterUom = (data) =>
  data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });

export const converterRecommendProductName = (data) =>
  data?.map(({ id, name }) => ({ value: id, label: { nameProduct: name, quantityVariant: 10 } }));

export const convertVariantTable = (data) => {
  return data?.map((item) => ({
    image: item?.imageURL,
    isActive: item?.isActive,
    key: item?.id,
    level1Price: item?.productPrices?.level1Price,
    level2Price: item?.productPrices?.level2Price,
    level3Price: item?.productPrices?.level3Price,
    level4Price: item?.productPrices?.level4Price,
    productCode: item?.code,
    vat: item?.vat,
    vatPercent: item?.vatPercent,
  }));
};

//sort products attributeValues by attribute of category
const sortByDataAttributeOfCategory = (variantAttributeValues, dataAttributeOfCategory) => {
  const filteredData = dataAttributeOfCategory?.filter((item) => item?.values?.length > 0);

  const sortedVariantAttribute =
    filteredData
      ?.map((item) => {
        return variantAttributeValues.find((variant) => item?.id === variant?.attributeID);
      })
      ?.filter((item) => item) || [];

  const attributeValue = [...sortedVariantAttribute];

  return attributeValue?.map((item) => item?.attributeValue?.id);
};

export const convertInitFormProducts = (products = [], dataAttributeOfCategory = []) => {
  return products.map((product) => ({
    ...product,
    imageURL: product?.imageURL ? [product.imageURL] : [],
    attributeValues: product?.variantAttributeValues
      ? sortByDataAttributeOfCategory(product.variantAttributeValues, dataAttributeOfCategory)
      : [],
  }));
};

//convert product group attributes to it (attributes) state format
export const convertAttributesProductGroupDetail = (
  dataAttributeOfCategory = [],
  attributeValues = [],
  isNewCategory = false
) => {
  return dataAttributeOfCategory?.map((item) => {
    const findIndex = attributeValues?.findIndex((attr) => item?.id === attr?.attributeID);
    const currentAttributeValue = attributeValues?.at(findIndex);

    return {
      attributeID: item?.id,
      name: item?.name,
      attributeValues: item?.values || false,
      attributeValueID: isNewCategory ? currentAttributeValue?.attributeValues?.at(0)?.id : undefined,
      attributeValueIDs: isNewCategory ? currentAttributeValue?.attributeValues?.map((item) => item?.id) : [],
      isVariant: currentAttributeValue?.isVariant || false,
      value: currentAttributeValue?.value,
    };
  });
};

//convert input before send to the server
export const convertProductsInput = (products = []) =>
  products.map(
    ({
      id,
      name,
      code,
      inStock = 0,
      vat,
      vatPercent,
      warranty,
      productPrices,
      attributeValues = [],
      imageURL,
      isActive,
    }) => ({
      id,
      name,
      code,
      inStock,
      vat,
      vatPercent,
      warranty,
      productPrices: {
        level1Price: productPrices?.level1Price,
        level2Price: productPrices?.level2Price,
        level3Price: productPrices?.level3Price,
        level4Price: productPrices?.level4Price,
      },
      attributeValues,
      imageURL,
      isActive,
    })
  );

//convert products after attributeValues options change
export const convertProdsWithNewAttValues = (products = [], attributeValues = []) => {
  const variantAttributeValues = attributeValues?.filter((att) => att?.isVariant);

  const variantAttributeValuesOptions = variantAttributeValues?.map((att) => att?.attributeValueIDs)?.flat();

  const productsAttributeValues = products?.map((prod) => prod?.attributeValues);

  const matrixProdsAttValues =
    productsAttributeValues?.map((attValues) => {
      const newAttValues = attValues?.map((value) => {
        if (variantAttributeValuesOptions?.includes(value)) return value;
        return undefined;
      });
      return newAttValues;
    }) || [];

  const newProdsAttValuesCol = unzip(matrixProdsAttValues);

  const filteredProdsAttValuesCol = () => {
    return newProdsAttValuesCol?.filter((arr) => arr.some((item) => item));
  };

  const finalProdsAttValues = unzip(filteredProdsAttValuesCol());

  const newProducts = [
    ...products.map((item, index) => ({
      ...item,
      attributeValues: finalProdsAttValues?.at(index),
    })),
  ];

  return newProducts;
};

export const converterSuggestedProdGroup = (data) =>
  data?.map(({ id, name, totalVariation }) => ({
    value: id,
    label: { nameProduct: name, quantityVariant: totalVariation },
  }));
