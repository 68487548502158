import { t } from "i18next";

export const useAccountLoginList = () => {
  const columns = [
    {
      title: t("common.index"),
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("seller.loginAccount"),
      align: "center",
      render: (_, record, index) => {
        return record?.email || "- -";
      },
    },
    {
      title: t("seller.name_account"),
      align: "center",
      render: (_, record, index) => {
        return record?.fullname || "- -";
      },
    },
    {
      align: "center",
      title: t("cart.telephone"),
      render: (_, record, index) => {
        return record?.telephone || "- -";
      },
    },
    {
      align: "center",
      title: t("cart.count_order"),
      dataIndex: "level",
      render: (_, record, index) => {
        return record?.totalOrderDelivery?.totalWaiting + record?.totalOrderDelivery?.totalDelivering || 0;
      },
    },
  ];

  return {
    columns,
  };
};
