import { useTable } from "./useTable";

export const TypeReImportWarranty = ({ remove, handleRemoveSerial, warehouseOptions }) => {
  const { columns } = useTable({
    remove,
    handleRemoveSerial,
    warehouseOptions,
  });
  return { columns };
};
