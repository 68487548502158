import { CaretDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./index.scss";

function ButtonSeeMore({ type = "link", ...rest }) {
  return (
    <div className="custom-btn-see-more">
      <div className="button-see-more">
        <Button type={type} block icon={<CaretDownOutlined />} {...rest}>
          Xem thêm
        </Button>
      </div>
    </div>
  );
}

export default ButtonSeeMore;
