/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import { CustomPaginationPrevNext } from "components/CustomPaginationPrevNext";
import CustomTable from "components/CustomTable";
import { FiltersTag } from "../../../components/FiltersTag";
import { VALUE_TAGS_IMPORT_NOTE_TYPE } from "../../../constant";
import TablesList from "../Tables/TablesList";
import useList from "./useList";

function ListByImportSerial() {
  const {
    onChangeBranchesId,
    valueFormSelectCity,
    loading,
    paginationData,
    data,
    onSearch,
    handleChangeTable,
    branchesID,
    handleRemoveFilterTag,
    staffs,
    params,
    handlePrevNextPage,
    handleFilter,
    propsFilterCreated,
    handleChangeDate,
  } = useList();

  const { BY_IMPORT_SERIAL_COLUMN } = TablesList({ params, handleFilter, propsFilterCreated });
  return (
    <div className="list-by-import-serial">
      <Form name="basic" autoComplete="off" style={{ display: "flex", gap: 8 }}>
        <FilterDate title={"Ngày tạo"} onChangeDate={handleChangeDate} />
        <div style={{ width: "100%" }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Input
              placeholder={"Tìm kiếm theo mã phiếu nhập serial"}
              onChange={onSearch}
              prefix={<SearchOutlined />}
              style={{ maxWidth: 500 }}
              allowClear
            />
          </Form.Item>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Select options={valueFormSelectCity} onChange={(e) => onChangeBranchesId(e)} value={branchesID} />
          </Form.Item>
        </div>
      </Form>
      <FiltersTag
        staffOptions={staffs}
        params={params}
        importNotTypeOptions={VALUE_TAGS_IMPORT_NOTE_TYPE}
        handleRemoveFilterTag={handleRemoveFilterTag}
      />
      <CustomTable
        loading={loading}
        columns={BY_IMPORT_SERIAL_COLUMN}
        dataSource={data}
        rowKey={(record) => record?.id}
        onChange={handleChangeTable}
      />
      {paginationData && (
        <CustomPaginationPrevNext handlePrevNextPage={handlePrevNextPage} paginationData={paginationData} />
      )}
    </div>
  );
}

export default ListByImportSerial;
