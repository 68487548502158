import CustomAffix from "components/CustomAffix";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import { ORDER_TYPE } from "features/Purchase/constant";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function ButtonGroup({
  onCreate,
  form,
  dataState,
  loadingCreatePurchase,
  isShowFully,
  isShowPartially,
  handleOpenFully,
  handleCancelFully,
  handleOpenPartially,
  handleCancelPartially,
}) {
  const history = useHistory();

  const [formValues, setFormValues] = useState();

  const messageFullyFn = () => {
    let countConditions = 0;
    const messages = [];

    const hasId = dataState?.id;
    const productIdsDataState = (dataState?.items || []).map((item) => item?.product?.id).filter(Boolean);
    const productIdsFormValues = (formValues?.items || [])
      .map((item) => item?.product?.id ?? item?.productID)
      .filter(Boolean);

    // Loại đề nghị khác nhau
    const isDifferentRequestType = hasId && dataState?.type !== formValues?.requestType;
    if (isDifferentRequestType) {
      messages.push(t("purchase.modalMessage.differenceOrderType"));
      countConditions++;
    }

    // Kho nhập khác nhau
    const isDifferentWarehouse = hasId && dataState?.warehouseID !== formValues?.importWarehouseID;
    if (isDifferentWarehouse) {
      messages.push(t("purchase.modalMessage.differenceWarehouse"));
      countConditions++;
    }

    // Thông tin nhà cung cấp khác nhau
    const isDifferentSupplier =
      hasId &&
      dataState?.supplier?.id !==
        (dataState?.supplier?.fullName === formValues?.sellerID ? dataState?.supplier?.id : formValues?.sellerID);
    if (isDifferentSupplier) {
      messages.push(t("purchase.modalMessage.differenceSupplierInfo"));
      countConditions++;
    }

    // Mã sản phẩm khác nhau(mã của sản phẩm đã tồn tại trên hệ thống)
    const differentProductIds = productIdsFormValues?.filter((id) => !productIdsDataState.includes(id));
    const isDifferentProductIds = hasId && differentProductIds?.length;
    if (isDifferentProductIds && differentProductIds?.length > 0) {
      messages.push(
        t("purchase.modalMessage.differenceProduct", { quantityProductDifference: differentProductIds?.length })
      );
      countConditions++;
    }

    // Số lượng của từng mã sản phẩm khác nhau
    const mapArray1 = formValues?.items?.reduce((acc, item) => {
      acc[item.productID] = item.quantity;
      return acc;
    }, {});
    const resultArray = dataState?.items?.filter((item) => {
      const quantityInMap = mapArray1 && mapArray1[item?.product?.id];
      return quantityInMap !== undefined && quantityInMap !== item?.quantity;
    });

    if (hasId && resultArray?.length > 0) {
      messages.push(t("purchase.modalMessage.quantityChange", { quantityChange: resultArray?.length }));
      countConditions++;
    }

    // Nếu đơn mua hàng và đề nghị nhập hàng có ≥ 2 loại thông tin khác nhau
    if (countConditions >= 2) {
      const combinedMessage = [...new Set(messages)].join(", ");
      return (
        `Đơn mua hàng và đề nghị nhập hàng có ${countConditions} loại thông tin khác nhau, bao gồm: ` +
        combinedMessage +
        ". " +
        t("purchase.modalMessage.create")
      );
    } else if (countConditions === 1) {
      return [...new Set(messages)] + ". " + t("purchase.modalMessage.create");
    } else {
      return t("purchase.modalMessage.create");
    }
  };

  const messagePartiallyFn = () => {
    let countConditions = 0;
    const messages = [];

    const hasId = dataState?.id;
    const productIdsDataState = (dataState?.items || []).map((item) => item?.product?.id).filter(Boolean);
    const productIdsFormValues = (formValues?.items || [])
      .map((item) => item?.product?.id ?? item?.productID)
      .filter(Boolean);

    // Loại đề nghị khác nhau
    const isDifferentRequestType = hasId && dataState?.type !== formValues?.requestType;
    if (isDifferentRequestType) {
      messages.push(t("purchase.modalMessage.differenceOrderType"));
      countConditions++;
    }

    // Kho nhập khác nhau
    const isDifferentWarehouse = hasId && dataState?.warehouseID !== formValues?.importWarehouseID;
    if (isDifferentWarehouse) {
      messages.push(t("purchase.modalMessage.differenceWarehouse"));
      countConditions++;
    }

    // Thông tin nhà cung cấp khác nhau
    const isDifferentSupplier =
      hasId &&
      dataState?.supplier?.id !==
        (dataState?.supplier?.fullName === formValues?.sellerID ? dataState?.supplier?.id : formValues?.sellerID);
    if (isDifferentSupplier) {
      messages.push(t("purchase.modalMessage.differenceSupplierInfo"));
      countConditions++;
    }

    // Mã sản phẩm khác nhau(mã của sản phẩm đã tồn tại trên hệ thống)
    const differentProductIds = productIdsFormValues?.filter((id) => !productIdsDataState.includes(id));
    const isDifferentProductIds = hasId && differentProductIds?.length;
    if (isDifferentProductIds && differentProductIds?.length > 0) {
      messages.push(
        t("purchase.modalMessage.differenceProduct", { quantityProductDifference: differentProductIds?.length })
      );
      countConditions++;
    }

    // Số lượng của từng mã sản phẩm khác nhau
    const mapArray1 = formValues?.items?.reduce((acc, item) => {
      acc[item.productID] = item.quantity;
      return acc;
    }, {});
    const resultArray = dataState?.items?.filter((item) => {
      const quantityInMap = mapArray1 && mapArray1[item?.product?.id];
      return quantityInMap !== undefined && quantityInMap !== item?.quantity;
    });

    if (hasId && resultArray?.length > 0) {
      messages.push(t("purchase.modalMessage.quantityChange", { quantityChange: resultArray?.length }));
      countConditions++;
    }

    // Nếu đơn mua hàng và đề nghị nhập hàng có ≥ 2 loại thông tin khác nhau
    if (countConditions >= 2) {
      const combinedMessage = [...new Set(messages)].join(", ");
      return (
        `Đơn mua hàng và đề nghị nhập hàng có ${countConditions} loại thông tin khác nhau, bao gồm: ` +
        combinedMessage +
        ". " +
        t("purchase.modalMessage.create")
      );
    } else if (countConditions === 1) {
      return [...new Set(messages)] + ". " + t("purchase.modalMessage.create");
    } else {
      return t("purchase.modalMessage.create");
    }
  };

  useEffect(() => {
    const formData = form.getFieldsValue();
    setFormValues(formData);
  }, [JSON.stringify(form.getFieldsValue())]);

  return (
    <CustomAffix
      addButton={
        <>
          <CustomModal
            {...{
              customComponent: <PrimaryButtonOutLine title={t("common.cancel")} />,
              footer: false,
              message: <ModalWithIconQuestion title={t("purchase.modalMessage.cancel")} />,
              onOke: () => history.goBack(),
            }}
          />
          <CustomModal
            {...{
              customComponent: <PrimaryButtonOutLine title={t("purchase.createPartialPurchaseOrder")} />,
              footer: false,
              message: <ModalWithIconQuestion title={messagePartiallyFn()} />,
              visible: isShowPartially,
              onOpen: () => handleOpenPartially(),
              onCancel: () => handleCancelPartially(),
              onOke: () => onCreate({ orderType: ORDER_TYPE.PARTIALLY }),
              buttonLoading: loadingCreatePurchase,
            }}
          />
          <CustomModal
            {...{
              customComponent: <PrimaryButton title={t("purchase.createPurchaseOrder")} />,
              footer: false,
              message: <ModalWithIconQuestion title={messageFullyFn()} />,
              visible: isShowFully,
              onOpen: () => handleOpenFully(),
              onCancel: () => handleCancelFully(),
              onOke: () => onCreate({ orderType: ORDER_TYPE.FULLY }),
              buttonLoading: loadingCreatePurchase,
            }}
          />
        </>
      }
      showButtonDefault={false}
    />
  );
}

export default ButtonGroup;
