import React, { useState } from "react";
import {
  useComplete,
  useConfirmCheck,
  useConfirmDistribute,
  useConfirmReturn,
  useConfirmSolution,
  useReturnWaitingForCheck,
  useUpdateSerial,
} from "hooks/warranty";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";
import { omit } from "lodash";

function useButtonGroup({ refetch, itemID }) {
  const { handleConfirmCheck, loading: loadingConfirmCheck } = useConfirmCheck();
  const { handleConfirmSolution, loading: loadingConfirmSolution } = useConfirmSolution();
  const { handleConfirmDistribute, loading: loadingConfirmDistribute } = useConfirmDistribute();
  const { handleComplete, loading: loadingComplete } = useComplete();
  const { handleConfirmReturn, loading: loadingConfirmReturn } = useConfirmReturn();
  const { handleUpdateSerial, loading: loadingUpdateSerial } = useUpdateSerial();
  const { handleReturnWaitingForCheck, loading: loadingReturnWaitingForCheck } = useReturnWaitingForCheck();

  const [switchClose, setSwitchClose] = useState(false);

  const handleOpenModal = () => {
    setSwitchClose(true);
  };

  const handleCancelModal = () => {
    setSwitchClose(false);
  };

  const confirmCheck = async () => {
    await handleConfirmCheck({ warrantyRequestItemIDs: itemID })
      .then(() => {
        notify.success({ message: t("notiActions.success") });
        setSwitchClose(false);
        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const confirmSolution = async ({ solution, solutionRequest }) => {
    await handleConfirmSolution({
      warrantyRequestItemIDs: itemID,
      solution: solution,
      solutionRequest: solutionRequest,
    })
      .then(() => {
        notify.success({ message: t("notiActions.chooseProcess") });
        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const confirmDistribute = async ({ assignee, distributeRequest }) => {
    const newDistribute = omit(distributeRequest, "technicalStaffPhone");
    await handleConfirmDistribute({
      warrantyRequestItemIDs: itemID,
      assignee: assignee,
      distributeRequest: newDistribute,
    })
      .then(() => {
        notify.success({ message: t("notiActions.distribute") });
        setSwitchClose(false);

        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const complete = async ({ deliverFileURLs }) => {
    await handleComplete({
      warrantyRequestItemID: itemID,
      deliverFileURLs: deliverFileURLs,
    })
      .then(() => {
        notify.success({ message: t("notiActions.completed") });
        setSwitchClose(false);

        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const confirmReturn = async (params) => {
    await handleConfirmReturn(params)
      .then(() => {
        notify.success({ message: t("notiActions.return") });
        setSwitchClose(false);

        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const updateSerial = async (params) => {
    await handleUpdateSerial(params)
      .then(() => {
        notify.success({ message: t("updatge serial") });
        setSwitchClose(false);

        refetch();
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  const returnWaitingForCheck = async (params) => {
    await handleReturnWaitingForCheck(params)
      .then(() => {
        notify.success({ message: t("notiActions.returnWaitingForCheck") });
        setSwitchClose(false);
      })
      .catch((error) => {
        notify.error({
          message: t("error"),
          description: error?.message,
        });
      });
  };

  return {
    loadingConfirmCheck,
    loadingConfirmSolution,
    loadingConfirmDistribute,
    loadingComplete,
    loadingConfirmReturn,
    loadingUpdateSerial,
    loadingReturnWaitingForCheck,
    switchClose,
    confirmCheck,
    confirmSolution,
    confirmDistribute,
    complete,
    confirmReturn,
    updateSerial,
    handleOpenModal,
    handleCancelModal,
    returnWaitingForCheck,
  };
}

export default useButtonGroup;
