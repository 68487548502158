import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import i18n from "./locales/i18n";
import { I18nextProvider } from "react-i18next";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { ConfigProvider } from "antd";
import viVN from "antd/es/locale/vi_VN";

flagsmith
  .init({
    api: process.env.REACT_APP_FLAGSMITH_URL,
    environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
    cacheFlags: true,
  })
  .then(() => {
    flagsmith.startListening(60000);
  });

ReactDOM.render(
  <React.StrictMode>
    <FlagsmithProvider flagsmith={flagsmith}>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider locale={viVN}>
          <Provider store={store}>
            <App />
          </Provider>
        </ConfigProvider>
      </I18nextProvider>
    </FlagsmithProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
