import { Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import LockComponent from "components/LockComponent";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { useUnlockKey } from "hooks/keyManagement/mutation";
import { t } from "i18next";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function UnlockKey({ data, id }) {
  const [isModal, setIsModal] = useState(false);
  const { handleUnlockKey, loadingUnlockKey } = useUnlockKey();

  const handleShowModal = () => {
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const handlerUnlockKey = () => {
    handleUnlockKey({ id: id })
      .then(() => {
        notify.success({ message: "Mở khóa key license/ account thành công!" });
        handleCloseModal();
      })
      .catch((error) => {
        notify.error({ message: error?.message });
        handleShowModal();
      });
  };

  return (
    <CustomModal
      footer={false}
      visible={isModal}
      closable={false}
      customComponent={
        <PrimaryButtonOutLine title={t("v2.keyManagement.unlock")} restStyle={{ backgroundColor: "white" }} />
      }
      customIcon={<LockComponent locked={false} />}
      buttonLoading={loadingUnlockKey}
      onOpen={handleShowModal}
      onCancel={handleCloseModal}
      onOke={handlerUnlockKey}
      message={
        <Space direction="vertical" style={{ justifyContent: "center" }}>
          <div style={{ fontSize: "16px" }}>
            <Typography.Text>Bạn có chắc chắn muốn mở khóa key license/ account </Typography.Text>
            <Typography.Link>{data} </Typography.Link>
            <Typography.Text>không?</Typography.Text>
          </div>
          <Typography.Text type="warning">
            Lưu ý: Sau khi mở khóa thành công, hệ thống sẽ chuyển trạng thái của key license/ account sang “
            <b>Có sẵn</b>” và nhân viên kinh doanh có thể tiếp tục bán mã này!
          </Typography.Text>
        </Space>
      }
    />
  );
}

export default UnlockKey;
