import { useMutation, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { ACTIONS_BANK_ACCOUNT, CREATE_SUPPLIER, UPDATE_SUPPLIER } from "graphql/supplier/mutation";
import {
  GET_DATA_BANK_ACCOUNTS,
  GET_SUPPLIER_DETAIL,
  GET_SUPPLIER_LIST,
  GET_SUPPLIER_WITH_IDS,
} from "graphql/supplier/query";
import { useGetUserPermissions } from "hooks/user/user";
import join from "lodash";
import { hasPermission } from "utils/helperFuncs";

export const useGetSupplierList = (params) => {
  const { data, loading } = useQuery(GET_SUPPLIER_LIST, {
    variables: params,
  });

  return {
    data: data?.seller?.pagination?.sellers,
    paginationData: data?.seller?.pagination?.paginationData,
    loading,
  };
};

export const useCreateSupplier = () => {
  const [createSupplier, { loading }] = useMutation(CREATE_SUPPLIER);

  const handleCreateSupplier = async (params) => {
    return await createSupplier({
      variables: params,
    });
  };

  return {
    handleCreateSupplier,
    loadingCreate: loading,
  };
};

export const useGetSupplierDetail = (params) => {
  const { data, loading, refetch } = useQuery(GET_SUPPLIER_DETAIL, {
    variables: params,
  });

  return {
    data: data?.seller?.get,
    loading,
    refetch,
  };
};

export const useUpdateSupplier = () => {
  const [updateSupplier, { loading }] = useMutation(UPDATE_SUPPLIER);

  const handleUpdateSupplier = async (params) => {
    return await updateSupplier({
      variables: params,
      refetchQueries: ["GET_SUPPLIER_DETAIL", "GET_SUPPLIER_LIST", "GET_SELLER_DETAIL", "GET_SELLERS"],
    });
  };

  return {
    handleUpdateSupplier,
    loadingUpdateSupplier: loading,
  };
};

export const useActionsBankAccount = () => {
  const [actionsBankAccount, { loading }] = useMutation(ACTIONS_BANK_ACCOUNT);

  const actionsBankAccountHandler = async (params) => {
    return await actionsBankAccount({
      variables: params,
      refetchQueries: ["GET_SUPPLIER_DETAIL", "GET_DATA_BANK_ACCOUNTS"],
    });
  };

  return {
    actionsBankAccountHandler,
    loadingBankAccount: loading,
  };
};

export const useDataBankAccounts = (params) => {
  const { data, loading, refetch } = useQuery(GET_DATA_BANK_ACCOUNTS, {
    variables: params,
  });

  return {
    dataBankAccounts: data?.seller?.get?.bankAccounts,
    loading,
    refetch,
  };
};
const convertOptionSupplier = (data) =>
  data?.map((record) => ({
    value: record?.id,
    sellername: record?.fullName,
    label: join([record?.code, record?.fullName, record?.telephone], " - "),
  }));

export const useGetSupplierOnLoadMore = ({ filters, pagination }) => {
  const { loading, data, fetchMore } = useQuery(GET_SUPPLIER_LIST, {
    variables: {
      filters,
      pagination,
    },
    fetchPolicy: "cache-first",
  });

  const sellers = convertOptionSupplier(data?.seller?.pagination?.sellers) || [];
  const currentTotalSellers = sellers.length;
  const paginationData = data?.seller?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalSellers || 0, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.seller?.pagination?.sellers) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.seller?.pagination?.paginationData },
              sellers: [...pv?.seller?.pagination?.sellers, ...fetchMoreResult?.seller?.pagination?.sellers],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalSellers === paginationData?.total);

  return {
    loading,
    sellers,
    onLoadMoreData,
    hasMoreData,
    data: data?.seller?.pagination?.sellers,
  };
};

export const useSupplierPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();

  return {
    canViewListSupplier: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_VIEW]),
    canDeactivateSupplier: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_DEACTIVATE]),
    canCreateSupplier: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_CREATE]),
    canUpdateSupplier: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_UPDATE]),
    canSupplierContactView: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_CONTACT_VIEW]),
    canSupplierContactUpdate: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_CONTACT_UPDATE]),
    canSupplierContactDelete: hasPermission(permissions, [USER_PERMISSIONS.SUPPLIER_CONTACT_DELETE]),
    loading,
  };
};

export const useGetSupplierWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_SUPPLIER_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });
  const options = data?.seller?.pagination?.sellers?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));
  return {
    loading,
    data: options || [],
    refetch,
    fetchMore,
  };
};
