import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const PriceInfo = ({ disabledInput, productGroupData }) => {
  const { t } = useTranslation();
  const defaultVatValue =
    productGroupData?.products[0]?.vatValue !== undefined ? productGroupData?.products[0]?.vatValue : 0;
  const defaultVatPercent =
    productGroupData?.products[0]?.vatPercent !== undefined ? productGroupData?.products[0]?.vatPercent : 0;

  const [isTaxableGapVAT, setIsTaxableGapVAT] = useState(defaultVatValue >= 0);
  const [isTaxablePercentVAT, setIsTaxablePercentVAT] = useState(defaultVatPercent >= 0);

  const inputOptionsPrices = {
    min: 0,
    maxLength: 11,
    style: { width: "100%" },
    addonAfter: <div className="add-on-after">đ</div>,
    disabled: disabledInput,
  };

  const optionVatType = [
    {
      value: VAT_TYPE.REQUIRED,
      label: t("v2.product.drawer_edit_product.export_vat"),
    },
    {
      value: VAT_TYPE.NOT_REQUIRED,
      label: t("v2.product.drawer_edit_product.not_export_vat"),
    },
    {
      value: VAT_TYPE.OPTIONAL,
      label: t("v2.product.drawer_edit_product.optional"),
    },
  ];

  const optionTaxable = [
    { value: false, label: t("v2.product.drawer_edit_product.not_taxable") },
    { value: true, label: t("v2.product.drawer_edit_product.taxable") },
  ];

  return (
    <div className="price-info-container">
      <Row justify="start" gutter={64} className="price-info-row">
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "level1Price"],
              label: <span className="title">{t("product.productForm.priceLevel1")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.priceIncludeVATPlaceholder"),
            }}
          />
        </Col>
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "level2Price"],
              label: <span className="title">{t("product.productForm.priceLevel2")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.priceIncludeVATPlaceholder"),
            }}
          />
        </Col>
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "level3Price"],
              label: <span className="title">{t("product.productForm.priceLevel3")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.priceIncludeVATPlaceholder"),
            }}
          />
        </Col>
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "level4Price"],
              label: <span className="title">{t("product.productForm.priceLevel4")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.priceIncludeVATPlaceholder"),
            }}
          />
        </Col>
      </Row>
      <Row justify="start" gutter={64} className="price-info-row">
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "importPrice"],
              label: <span className="title">{t("product.productForm.costPrice")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.costPrice"),
            }}
          />
        </Col>
        <Col span={6}>
          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{
              name: "vatType",
              label: <span className="title">{t("v2.product.drawer_edit_product.product_export_vat")}</span>,
              rules: [
                { required: true, message: t("v2.product.drawer_edit_product.please_select_product_vat_exported") },
              ],
            }}
            inputOptions={{
              options: optionVatType,
              defaultValue: VAT_TYPE.REQUIRED,
            }}
          />
        </Col>
        <Col span={6}>
          <Row className="vat-value-group" gutter={12}>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  rules: [
                    {
                      required: true,
                      message: t("common.messageRequiredType", {
                        message: t("product.tableTitle.vatValue"),
                      }),
                    },
                  ],
                  name: "taxablePercentVAT",
                  label: (
                    <div>
                      <Typography.Text strong>{t("product.productForm.percentVAT")}</Typography.Text>{" "}
                      <Tooltip title={t("product.productForm.percentVATTooltip")}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  ),
                }}
                inputOptions={{
                  options: optionTaxable,
                  defaultValue: true,
                  onChange: (value) => {
                    setIsTaxablePercentVAT(value);
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.NUMBER}
                formItemOptions={{
                  rules: [
                    {
                      required: isTaxablePercentVAT,
                      message: t("common.messageRequiredType", {
                        message: t("product.productForm.percentVAT"),
                      }),
                    },
                  ],
                  name: "vatPercent",
                  className: "vat",
                }}
                inputOptions={{
                  min: -1,
                  max: 100,
                  addonAfter: <div className="add-on-after">%</div>,
                  placeholder: t("product.productForm.percentVATPlaceholder"),
                  disabled: !isTaxablePercentVAT,
                  maxLength: 3,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row className="vat-value-group" gutter={12}>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  rules: [
                    {
                      required: true,
                      message: t("common.messageRequiredType", {
                        message: t("product.tableTitle.vatValue"),
                      }),
                    },
                  ],
                  name: "taxableGapVAT",
                  label: (
                    <div>
                      <Typography.Text strong>{t("product.tableTitle.vatValue")}</Typography.Text>{" "}
                      <Tooltip title={t("product.productForm.VATDifferenceTooltip")}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  ),
                }}
                inputOptions={{
                  options: optionTaxable,
                  defaultValue: true,
                  onChange: (value) => {
                    setIsTaxableGapVAT(value);
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.CURRENCY_INPUT}
                formItemOptions={{
                  rules: [
                    {
                      required: isTaxableGapVAT,
                      message: t("common.messageRequiredType", {
                        message: t("product.productForm.percentVAT"),
                      }),
                    },
                  ],
                  name: "vatValue",
                  className: "vat",
                }}
                inputOptions={{
                  ...inputOptionsPrices,
                  placeholder: t("product.productForm.VATDifferencePlaceholder"),
                  disabled: !isTaxableGapVAT,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PriceInfo;
