import { Grid, notification } from "antd";
import {
  ANTD_STEPS_STATUS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EVENT_STATUS,
  GREETING,
  PRODUCT_TYPE,
  REGEX,
  SHIPPING_TYPE,
} from "config/constants";
import { t } from "i18next";
import intersection from "lodash/intersection";
import moment from "moment";
import "moment/locale/vi";
import queryString from "query-string";

const { useBreakpoint } = Grid;

//Open notify toast
export const notify = {
  error: ({ message, ...options }) =>
    notification.error({
      message,
      ...options,
    }),

  info: ({ message, ...options }) =>
    notification.info({
      message,
      ...options,
    }),

  success: ({ message, ...options }) =>
    notification.success({
      message,
      ...options,
    }),

  warning: ({ message, ...options }) =>
    notification.warning({
      message,
      ...options,
    }),
};

//Generate password
export const passwordGenerator = (len) => {
  var length = len ? len : 8;
  var string = "abcdefghijklmnopqrstuvwxyz";
  var numeric = "0123456789";
  var punctuation = "#?!@$%^&*-"; // "!@#$%^&*()_+~`|}{[]:;?><,./-="
  var password = "";
  var character = "";
  while (password.length < length) {
    let entity1 = Math.ceil(string.length * Math.random() * Math.random());
    let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
  return password.substr(0, len);
};

//Validator input data
export const validator = ({ regex, message, type }) => {
  let reg = "";
  let mess = "";
  switch (type) {
    case "email": {
      reg = REGEX.EMAIL;
      mess = "Email không đúng định dạng";
      break;
    }
    case "phone": {
      reg = REGEX.PHONE_NUMBER;
      mess = "Số điện thoại không đúng định dạng";
      break;
    }
    case "number": {
      reg = REGEX.NUMBER;
      mess = "Vui lòng nhập số nguyên dương";
      break;
    }
    case "password": {
      reg = REGEX.PASSWORD;
      mess = "Mật khẩu phải tối thiếu 8 ký tự bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt";
      break;
    }
    case "space": {
      reg = REGEX.TEXT_TRIM_SPACE;
      mess = t("common.whiteSpace");
      break;
    }
    case "debt": {
      reg = REGEX.CURRENCY_DEBT;
      mess = "Nhập tối đa 11 số";
      break;
    }
  }
  return () => ({
    validator(_, value) {
      if (!value || (reg || regex).test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(mess || message));
    },
  });
};

//Build query string by params
export const buildQueryString = ({ params }) => {
  let rootUrl = window.location.origin + window.location.pathname;
  let queryRoot = `${rootUrl}?`;

  const query = queryString.stringify(params, {
    arrayFormat: "index",
    parseBooleans: true,
    parseNumbers: true,
  });

  window.history.pushState(null, null, queryRoot + query);
};

//Get query string by params
export const getQuery = (parseNumber = true) => {
  return queryString.parse(window.location.search, {
    arrayFormat: "index",
    parseBooleans: true,
    parseNumbers: parseNumber,
  });
};

//Debounce function
export const debounce = (func, delay = 400) => {
  let timmerId = "";
  return (...args) => {
    if (timmerId) {
      clearTimeout(timmerId);
    }
    timmerId = setTimeout(() => func(args), delay);
  };
};

//Formart date by time
export const formatDatetime = (date) => {
  return moment(date).format(DATE_TIME_FORMAT);
};

//Read file function
export const readFile = ({ getResult, type, file }) => {
  const reader = new FileReader();
  reader.onload = function () {
    getResult(reader.result);
  };
  if (type === "Base64String") {
    reader.readAsDataURL(file);
  }
};

//Custom request upload fuction
export const dummyRequest = (file, onSuccess, onError) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

//Get the timestamp by date
export const getTimeStamp = (value) => {
  let timestamp = null;

  var thisYear = moment().year();
  const firstDayOfMonth = moment().startOf("month");
  const firstDayOfYear = moment().startOf("year");
  const lastDayOfYear = moment().endOf("year");

  const firstDayOfQuarter = moment({
    year: thisYear,
    month: getTheFirstMonthOfQuarter() - 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
  });

  const now = moment();
  const midDateOfYear = moment({
    year: thisYear,
    month: 5,
    day: 30,
    hour: 23,
    minute: 59,
    second: 59,
  });

  function getTheFirstMonthOfQuarter() {
    let thisMonth = moment().month() + 1;
    if (thisMonth % 3 === 0) {
      return thisMonth - 2;
    } else {
      return thisMonth - ((thisMonth % 3) - 1);
    }
  }

  function getTimeStampByDate(date) {
    return moment(date).toISOString();
  }

  switch (value) {
    case "this_month": {
      timestamp = {
        from: getTimeStampByDate(firstDayOfMonth),
        to: getTimeStampByDate(now),
      };
      break;
    }
    case "this_quarter": {
      timestamp = {
        from: getTimeStampByDate(firstDayOfQuarter),
        to: getTimeStampByDate(now),
      };
      break;
    }
    case "this_year": {
      timestamp = {
        from: getTimeStampByDate(firstDayOfYear),
        to: getTimeStampByDate(now),
      };
      break;
    }

    case "first_six_months": {
      timestamp = {
        from: getTimeStampByDate(firstDayOfYear),
        to: getTimeStampByDate(midDateOfYear),
      };
      break;
    }

    case "six_months_late": {
      timestamp = {
        from: getTimeStampByDate(midDateOfYear),
        to: getTimeStampByDate(lastDayOfYear),
      };
      break;
    }

    default:
      return timestamp;
  }

  return timestamp;
};

//Format vni currency
export const formatCurrency = (number, defaultValue = "--") => {
  return number !== undefined
    ? new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(number)
    : defaultValue;
};

export const formatCurrencyDollar = (number, defaultValue = "--") => {
  return number !== undefined
    ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number)
    : defaultValue;
};

//check permissions of each action
export const hasPermission = (allPermissions, checkedPermissions) => {
  return intersection(allPermissions, checkedPermissions).length > 0;
};

//Parse date to moment
export const parseIsoDateStringToMoment = (time) => {
  return time ? moment.utc(time).local() : moment(new Date());
};

//Format display in stock
export const formatInStock = (number, isPercentStock, realInStock, isActutalStock) => {
  if (isPercentStock) return `${number} / ${realInStock}`;

  if (isActutalStock) return realInStock;

  if (number) {
    return <div>{number}</div>;
  } else {
    return <>0</>;
  }
};

export const diffBetweenTwoNumber = (firstValue, finalValue) => {
  if (firstValue != 0) {
    return Number.parseFloat(((finalValue - firstValue) / firstValue) * 100).toFixed(2);
  } else {
    return undefined;
  }
};

export const toLowerCaseNonAccentVietnamese = (str) => {
  str = str?.toLowerCase();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str?.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str?.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str?.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

//use form item upload antd
export const getFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

//validate file size
export const validateFile = (file, limitSize = 5) => file.size / 1024 / 1024 <= limitSize;

//capitalize first letter
export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

//render deliver 3rd party string
export const renderD3PString = (d3pInfo) => {
  if (d3pInfo?.name && d3pInfo?.serviceName) {
    return `${d3pInfo.name} - ${d3pInfo.serviceName}`;
  } else {
    if (d3pInfo?.name) return d3pInfo.name;
  }
  return "- -";
};

//check this product is material type
export function isMaterialProduct(product) {
  if (product === PRODUCT_TYPE.MATERIAL) return true;
  return false;
}

// renderCreateAt
export const renderCreateAt = (timestamp, isMobileMode) => {
  if (!timestamp) return;
  const timeString = parseIsoDateStringToMoment(timestamp).format("hh:mm DD/MM/YYYY").toString();

  const timestampMobile = timeString.split(" ");
  if (isMobileMode) {
    return timeString;
  } else {
    return timestampMobile.map((item, index) => <div key={index}>{item}</div>);
  }
};

//check if all values of object are undefined
export const checkObjectUndefined = (object = {}) => {
  if (Object.values(object).every((value) => value === undefined)) {
    return undefined;
  }
  return object;
};

//remove string extra whitespace
export const removeWhiteSpace = (string) => {
  if (typeof string === "string") return string.trim().replace(/\s+/g, " ");
  return;
};

//validate delivery shipping type
export const deliverShippingType = (selectedList = []) => {
  return {
    isContainBus: selectedList.some((item) => item?.shippingType === SHIPPING_TYPE.BUS),
    isContainCod: selectedList.some((item) => item?.shippingType === SHIPPING_TYPE.URBAN_COD),
    isContainD3p: selectedList.some((item) => item?.shippingType === SHIPPING_TYPE.DELIVER_3PARTY),
    isContainPuc: selectedList.some((item) => item?.shippingType === SHIPPING_TYPE.PICK_UP_COUNTER),
  };
};

// join array
export const joinArray = (array, character = ", ") => array?.length && array.join(`${character}`);

//parse our event status to ant event status
export const parseEventStatus = (event) => {
  switch (event) {
    case EVENT_STATUS.PAST:
      return ANTD_STEPS_STATUS.FINISH;

    case EVENT_STATUS.CURRENT:
      return ANTD_STEPS_STATUS.PROCESS;

    case EVENT_STATUS.FUTURE:
      return ANTD_STEPS_STATUS.WAIT;

    default:
      return;
  }
};

// time Ago
export const timeAgo = (previousTime) => {
  moment.locale("vi-en");
  return `${moment(previousTime).fromNow(true)} trước`;
};

//convert ant Upload files input
export const parseAntUploadFiles = (urls) => {
  return !!urls
    ? urls?.map((url, index) => ({
        uid: `${index}`,
        name: url,
        status: "done",
        url: url,
      }))
    : [];
};

//generate greetings
export function generateGreetings() {
  const currentHour = moment().format("HH");

  if (currentHour >= 3 && currentHour < 12) {
    return GREETING.GOOD_MORNING;
  } else if (currentHour >= 12 && currentHour < 18) {
    return GREETING.GOOD_AFTERNOON;
  } else if (currentHour >= 18 && currentHour < 21) {
    return GREETING.GOOD_EVENING;
  } else if (currentHour >= 21 && currentHour < 3) {
    return GREETING.GOOD_NIGHT;
  } else {
    return GREETING.HELLO;
  }
}

//handleSearchSelect
const removeDiacritics = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
export const handleSearchSelect = (search, option) => {
  const optionLabelWithoutDiacritics = removeDiacritics(option?.label?.toLowerCase() || "");
  const searchQueryWithoutDiacritics = removeDiacritics(search?.toLowerCase() || "");

  return optionLabelWithoutDiacritics.includes(searchQueryWithoutDiacritics);
};

//handle format DateTime filter tag one day back
export const formatDateTimeFilterTag = (date) => {
  const getDate = new Date(date);
  return `${String(getDate.getUTCDate()).padStart(2, "0")}/${String(getDate.getUTCMonth() + 1).padStart(
    2,
    "0"
  )}/${getDate.getUTCFullYear()}`;
};

//Formart date
export const formatDate = (date) => {
  if (date?.endsWith("Z")) {
    return moment(date).format(DATE_FORMAT);
  } else {
    return date;
  }
};

//Formart padstart two number
export const formatPadStart = (value) => {
  return value?.toString()?.padStart(2, "0");
};

//Check validation date
export const validateDate = (rule, value, callback) => {
  const currentDate = moment(); // Lấy ngày hiện tại dưới dạng Moment
  if (moment(value).isBefore(currentDate, "day")) {
    // So sánh theo ngày
    callback(t("common.validateDate"));
  } else {
    callback();
  }
};

export const checkOptionDuplicate = (arrCheck) => {
  return [...new Set(arrCheck.map((item) => item.id))].map((id) => arrCheck.find((item) => item.id === id));
};

export const findDuplicateObjects = (arr1, arr2) => {
  const set1 = new Set(arr1?.map((obj) => JSON.stringify(obj)));
  const duplicates = arr2?.filter((obj) => set1.delete(JSON.stringify(obj)));
  return duplicates;
};

export const convertPhoneNumber = (phoneNumber = "") => {
  // Loại bỏ dấu và khoảng trắng từ số điện thoại
  const cleanedPhoneNumber = phoneNumber?.replace(/[^\d]/g, "");

  // Nếu số điện thoại bắt đầu bằng "84" hoặc "+84", thì thay thế bằng "0"
  if (cleanedPhoneNumber?.startsWith("84") || cleanedPhoneNumber?.startsWith("+84")) {
    return 0 + cleanedPhoneNumber?.slice(2);
  }

  // Nếu không, trả về số điện thoại không thay đổi
  return cleanedPhoneNumber || null;
};

export const checkMaxLengthPhoneNumber = (phoneNumber = "") => {
  const cleanPhoneNumber = phoneNumber?.replace(/\D/g, "");
  if (cleanPhoneNumber?.startsWith("0")) {
    return 11;
  } else if (cleanPhoneNumber?.startsWith("84")) {
    return 12;
  } else if (cleanPhoneNumber?.startsWith("+84")) {
    return 13;
  }
  return 0;
};

export const renderLack = ({ quantity, scanned }) => {
  if (scanned?.length) {
    return quantity - scanned?.length;
  } else {
    return quantity || 0;
  }
};

export const useBreakpointPage = () => {
  const screens = useBreakpoint();

  const currentPoint = () => {
    return Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0])
      .join("-");
  };

  return {
    currentPoint,
    xs: screens?.xs,
    sm: screens?.sm,
    md: screens?.md,
    lg: screens?.lg,
    xl: screens?.xl,
    xxl: screens?.xxl,
  };
};
