import { Affix, Col, Row } from "antd";
import LoadingComponent from "components/LoadingComponent";
import { isRenderCancellationReason, isRenderImageLibrary } from "../helper";
import ButtonGroup from "./components/ButtonGroup";
import CancellationReason from "./components/CancellationReason";
import ImageLibrary from "./components/ImageLibrary";
import InfoAgent from "./components/InfoAgent";
import InfoProductAndBill from "./components/InfoProductAndBill";
import OrderCodeAndOrderStatus from "./components/OrderCodeAndOrderStatus";
import Toolbar from "./components/ToolBar";
import useDetail from "./useDetail";

const Detail = () => {
  const {
    onExportOrder,
    onPrintOrder,
    dataToolBar,
    loading,
    dataOrderCodeAndOrderStatus,
    dataInfoAgent,
    dataInfoProductAndBill,
    dataButtonGroup,
    order,
    dataImageLibrary,
    dataCancellationReason,
    actions,
    canViewOrder,
    loadingPermission,
  } = useDetail();
  const { status } = dataOrderCodeAndOrderStatus;

  return (
    <LoadingComponent loading={loading}>
      {!loadingPermission && canViewOrder && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Toolbar onExportOrder={onExportOrder} onPrintOrder={onPrintOrder} dataToolBar={dataToolBar} />
          </Col>

          <Col span={24}>
            <OrderCodeAndOrderStatus dataOrderCodeAndOrderStatus={dataOrderCodeAndOrderStatus} />
          </Col>

          {isRenderImageLibrary(status) && (
            <Col span={24}>
              <ImageLibrary dataImageLibrary={dataImageLibrary} />
            </Col>
          )}

          {isRenderCancellationReason(status) && (
            <Col span={24}>
              <CancellationReason dataCancellationReason={dataCancellationReason} />
            </Col>
          )}

          <Col span={24}>
            <InfoAgent dataInfoAgent={dataInfoAgent} order={order} />
          </Col>

          <Col span={24}>
            <InfoProductAndBill dataInfoProductAndBill={dataInfoProductAndBill} />
          </Col>

          <Col span={24}>
            <Affix offsetBottom={10}>
              <ButtonGroup dataButtonGroup={dataButtonGroup} actions={actions} order={order} />
            </Affix>
          </Col>
        </Row>
      )}
    </LoadingComponent>
  );
};

export default Detail;
