import React from "react";
import { Col, Row } from "antd";
import CustomTable from "components/CustomTable";
import { getQuery } from "utils/helperFuncs";
import useAccordingSerial from "./useAccordingSerial";
import Toolbar from "../Toolbar";
import TabsCard from "components/CustomTabs/TabsCard";

const AccordingSerial = ({ onChangeTabStatus, selectedRowKeys, setSelectedRowKeys, setSelectedRows, selectedRows }) => {
  const {
    columns,
    tabs,
    handleChangeTab,
    handleChangeInput,
    data,
    loading,
    paginationData,
    rowSelection,
    handleChangeTable,
    staffs,
    onChangeParams,
    params,
    valueFormSelectBrandId,
    onChangeBranchesId,
    branchID,
  } = useAccordingSerial({
    onChangeTabStatus,
    selectedRowKeys,
    setSelectedRowKeys,
    setSelectedRows,
    selectedRows,
  });

  const GET_QUERY = getQuery();

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <TabsCard tabs={tabs} onChange={handleChangeTab} activeKey={GET_QUERY.key} />
      </Col>
      <Col span={24}>
        <Toolbar
          onChangeParams={onChangeParams}
          params={params}
          onSearch={handleChangeInput}
          staffs={staffs}
          valueFormSelectBrandId={valueFormSelectBrandId}
          onChangeBranchesId={onChangeBranchesId}
          branchID={branchID}
        />
      </Col>
      <Col span={12}></Col>
      <Col span={24}>
        <CustomTable
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data}
          onChange={handleChangeTable}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          loading={loading}
          rowSelection={rowSelection}
        />
      </Col>
    </Row>
  );
};

export default AccordingSerial;
