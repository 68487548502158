import React from "react";
import useFiltersTag from "./useFiltersTag";

export const FiltersTag = ({ params, handleRemoveFilterTag, staffOptions }) => {
  const { renderTagsCreatedByID } = useFiltersTag({
    params,
    handleRemoveFilterTag,
    staffOptions,
  });
  return (
    <div style={{ marginBottom: "16px" }}>{params?.filters?.createdByIDs?.length > 0 ? renderTagsCreatedByID : ""}</div>
  );
};
