import { gql } from "@apollo/client";

export const UPDATE_SELLER_STATUS = gql`
  mutation UpdateSellerStatus(
    $id: ID!
    $isActive: Boolean
    $reason: DeactivateSellerReasonInput
    $isActiveSupplier: Boolean
  ) {
    seller {
      updateStatus(id: $id, isActive: $isActive, reason: $reason, isActiveSupplier: $isActiveSupplier)
    }
  }
`;

export const CREATE_SELLER_MUTATION = gql`
  mutation CreateSeller(
    $sellerInfo: CreateSellerInfoRequest!
    $vatInfo: CreateVatInfoRequest
    $warehouseIDs: [ID!]!
    $saleCategories: [SaleCategoryInput!]!
  ) {
    seller {
      create(sellerInfo: $sellerInfo, vatInfo: $vatInfo, warehouseIDs: $warehouseIDs, saleCategories: $saleCategories) {
        id
      }
    }
  }
`;

export const UPDATE_SELLER_MUTATION = gql`
  mutation UpdateSeller(
    $sellerInfo: CreateSellerInfoRequest!
    $vatInfo: CreateVatInfoRequest
    $warehouseIDs: [ID!]!
    $saleCategories: [SaleCategoryInput!]!
    $sellerID: ID!
  ) {
    seller {
      update(
        sellerInfo: $sellerInfo
        vatInfo: $vatInfo
        warehouseIDs: $warehouseIDs
        saleCategories: $saleCategories
        sellerID: $sellerID
      ) {
        id
      }
    }
  }
`;

export const UPDATE_SELLER_SALE_CATEGORIES = gql`
  mutation UpdateSellerSaleCategories($sellerID: ID!, $saleCategories: [SaleCategoryInput!]) {
    seller {
      updateSellerSaleCategories(sellerID: $sellerID, saleCategories: $saleCategories) {
        categoryID
      }
    }
  }
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($contact: ContactInput) {
    contact {
      create(contact: $contact) {
        id
      }
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($id: ID!, $contact: ContactInput) {
    contact {
      update(contact: $contact, id: $id) {
        id
      }
    }
  }
`;

export const DELETE_CONTACT_MUTATION = gql`
  mutation DeleteContact($id: ID!) {
    contact {
      delete(id: $id)
    }
  }
`;

export const INVITE_SELLERS = gql`
  mutation inviteSellers($sellerIDs: [ID!]) {
    seller {
      inviteSeller(sellerID: $sellerIDs)
    }
  }
`;

export const CREATE_SELLER_GROUP = gql`
  mutation createSellerGroup($sellerGroupInfo: SellerGroupInput!) {
    sellerGroup {
      create(sellerGroupInfo: $sellerGroupInfo) {
        id
        code
        name
        description
        managerID
      }
    }
  }
`;

export const UPDATE_SELLER_GROUP = gql`
  mutation updateSellerGroup($sellerGroupInfo: SellerGroupInput!, $id: ID!) {
    sellerGroup {
      update(sellerGroupInfo: $sellerGroupInfo, id: $id) {
        code
        name
        description
        managerID
      }
    }
  }
`;

export const GET_CHANGE_ACTIVE_STATUS = gql`
  mutation ChangeActiveStatus($id: [ID!], $isActive: Boolean!) {
    promotionCampaign {
      changeActiveStatus(id: $id, isActive: $isActive)
    }
  }
`;

export const RECEIVE = gql`
  mutation Receive($sellerIDs: [ID!]) {
    seller {
      receive(sellerIDs: $sellerIDs)
    }
  }
`;

export const APPROVE = gql`
  mutation Approve($sellerID: ID!, $request: ApprovalInput!) {
    seller {
      approve(sellerID: $sellerID, request: $request)
    }
  }
`;

export const REJECT = gql`
  mutation Reject($sellerIDs: [ID!], $reason: RejectInput!) {
    seller {
      reject(sellerIDs: $sellerIDs, reason: $reason)
    }
  }
`;

export const REPROCESS = gql`
  mutation reprocess($sellerIDs: [ID!]) {
    seller {
      reprocess(sellerIDs: $sellerIDs)
    }
  }
`;

export const UPDATE_IDENTITY_SELLER = gql`
  mutation updateIdentitySeller($sellerID: ID!, $sellerRequest: IdentitySellerRequest!) {
    seller {
      updateIdentitySeller(sellerID: $sellerID, sellerRequest: $sellerRequest) {
        id
      }
    }
  }
`;

export const MERGE_SELLER = gql`
  mutation mergeSeller($sellerID: ID!, $existedSellerID: ID!) {
    seller {
      mergeSeller(sellerID: $sellerID, existedSellerID: $existedSellerID)
    }
  }
`;
