import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SERIAL_EXPORT,
  CREATE_SERIAL_IMPORT,
  PRINT_EXPORT_NOTES_SERIAL_EXPORT_NOTES,
  PRINT_EXPORT_NOTES_SERIAL_IMPORT_NOTES,
  UPDATE_SERIAL_IMPORT,
} from "graphql/serial/mutation";
import { GET_PRODUCTS_LOAD_MORE, GET_SERIAL } from "graphql/serial/query";
import { useUploadDocument } from "hooks/upload-document";
import { uniq } from "lodash";

export const useGetProductsLoadMore = (params, fetchPolicy = "no-cache") => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_PRODUCTS_LOAD_MORE, {
    variables: params,
    fetchPolicy: fetchPolicy,
  });

  const products = data?.product?.pagination?.products || [];
  const currentTotalProducts = products?.length;
  const paginationData = data?.product?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalProducts || 0, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.product?.pagination?.products) return pv;
        const newData = {
          product: {
            pagination: {
              paginationData: { ...fetchMoreResult?.product?.pagination?.paginationData },
              products: [...pv?.product?.pagination?.products, ...fetchMoreResult?.product?.pagination?.products],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalProducts === paginationData?.total);

  return {
    loading,
    paginationData,
    data: uniq(products),
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export const useCreateSerialImport = () => {
  const [createSerialImport, { loading, error }] = useMutation(CREATE_SERIAL_IMPORT);
  const { handleUpload } = useUploadDocument();

  const handleCreateSerialImport = async (params) => {
    let newImageURLs = [];
    if (params?.request?.fileURLs?.length > 0) {
      newImageURLs = await handleUpload({
        files: params?.request?.fileURLs?.map((item) => item?.originFileObj),
      });
    }

    return await createSerialImport({
      variables: {
        ...params,
        request: {
          ...params?.request,
          fileURLs: newImageURLs?.map((item) => item?.url),
        },
      },
    });
  };

  return {
    handleCreateSerialImport,
    loadingCreate: loading,
    error,
  };
};

export const useUpdateSerialImport = () => {
  const [updateSerialImport, { loading, error }] = useMutation(UPDATE_SERIAL_IMPORT);
  const { handleUpload } = useUploadDocument();

  const handleUpdateSerialImport = async (params) => {
    const newImages = params?.request?.fileURLs?.filter((item) => !item?.url);
    const oldImageURLs = params?.request?.fileURLs?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj) || [],
    });

    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];
    return await updateSerialImport({
      variables: {
        ...params,
        request: {
          ...params?.request,
          fileURLs: allImageURLs,
        },
      },
    });
  };

  return {
    handleUpdateSerialImport,
    loading,
    error,
  };
};

export const useCreateSerialExport = () => {
  const [createSerialExport, { loading, error }] = useMutation(CREATE_SERIAL_EXPORT);
  const { handleUpload } = useUploadDocument();

  const handleCreateSerialExport = async (params) => {
    let newImageURLs;
    if (params?.request?.fileURLs?.length > 0) {
      newImageURLs = await handleUpload({
        files: params?.request?.fileURLs?.map((item) => item?.originFileObj),
      });
    }
    return await createSerialExport({
      variables: {
        ...params,
        request: {
          ...params?.request,
          fileURLs: newImageURLs?.map(({ url }) => url),
        },
      },
    });
  };

  return {
    handleCreateSerialExport,
    createSerialExport,
    loadingCreate: loading,
    error,
  };
};

export const usePrintExportNotesSerialImportNotes = () => {
  const [exportFile, { loading, error }] = useMutation(PRINT_EXPORT_NOTES_SERIAL_IMPORT_NOTES);
  const handlePrintExportNotesSerialImportNotes = async (params) => {
    return await exportFile({
      variables: params,
    });
  };
  return {
    handlePrintExportNotesSerialImportNotes,
    loadingExport: loading,
    error,
  };
};

export const usePrintExportNotesSerialExportNotes = () => {
  const [exportFile, { loading, error }] = useMutation(PRINT_EXPORT_NOTES_SERIAL_EXPORT_NOTES);
  const handlePrintExportNotesSerialExportNotes = async (params) => {
    return await exportFile({
      variables: params,
    });
  };
  return {
    handlePrintExportNotesSerialExportNotes,
    loadingExport: loading,
    error,
  };
};

export const useGetSerialLoadMore = ({ filter, pagination }) => {
  const { loading, data, fetchMore } = useQuery(GET_SERIAL, {
    variables: {
      filter,
      pagination,
    },
    fetchPolicy: "cache-first",
  });

  const serials = data?.serial?.pagination?.Serials || [];
  const currentTotalSellers = serials.length;
  const paginationData = data?.seller?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalSellers, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.serial?.pagination?.Serials) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.serial?.pagination?.paginationData },
              serials: [...pv?.serial?.pagination?.Serials, ...fetchMoreResult?.serial?.pagination?.Serials],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalSellers === paginationData?.total);

  return {
    loading,
    serials,
    onLoadMoreData,
    hasMoreData,
    data: data?.serial?.pagination?.Serials,
  };
};
