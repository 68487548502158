import { Tooltip, Typography } from "antd";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import CustomUom from "components/CustomUom";
import { DATE_FORMAT } from "config/constants";
import CustomTitle from "features/Serial/components/CustomTitle";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { convertTimeDate } from "utils/dateTime";

function TableWarranty() {
  const columnsBySeller = [
    {
      align: "center",
      fixed: "left",
      title: <CustomTitle title={t("serialIE.SERIALIMEIOLD")} />,
      render: (text, record, index) => {
        return <Typography.Text>{record?.exportItems[0]?.replaceSerial?.code}</Typography.Text>; //replace replaceSerialID
      },
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
              <ProductLink product={record} />
            </Typography.Link>
            <Tooltip title={record?.product?.name}>
              <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
                {truncateAddDots(record?.product?.name, 80)}
              </Typography.Text>
            </Tooltip>
            <CustomUom uom={record?.product?.uom} />
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SALEWARRANTY")} />,
      render: (record) => (
        <Typography.Text>
          {record?.warrantyOnSale} {convertUnit(record?.warrantyOnSaleUnit)}
        </Typography.Text>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.DAYFORSELL")} />,
      render: (record) => (
        <Typography.Text>{record?.soldDate && convertTimeDate(record?.soldDate, DATE_FORMAT)}</Typography.Text>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.WARRANTY")} />,
      render: (_, record) => (
        <div style={{ textAlign: "start", margin: 0 }}>
          <WarrantyDetailLink warranty={record?.exportItems[0]?.warrantyNote} />
        </div>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SERIALIMEINEW")} />,
      render: (text, record, index) => {
        return <Typography.Text>{record?.exportItems[0]?.serial?.code}</Typography.Text>; //replace serialID
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.SUPPLIER")} />,
      render: (_, record) => (
        <Typography.Text>{record?.exportItems[0]?.serial?.provider?.fullName || "- -"}</Typography.Text>
      ),
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.EXPORTWAREHOUSE")} />,
      render: (_, record, index) => {
        return <Typography.Text>{record?.exportItems[0].exportedWarehouse?.name}</Typography.Text>;
      },
    },
  ];
  return { columnsBySeller };
}

export default TableWarranty;
