import { Col, Row, Typography } from "antd";
import { useGetProducts } from "hooks/product/product";
import { isEmpty, isNil } from "lodash";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { formatCurrency } from "utils/helperFuncs";
const useSelectProduct = ({ onChange, warehouseID, sellerID, exportedWarehouseID }) => {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      isActive: true,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData, loading } = useGetProducts(params, "cache-first");

  const resolvePrice = (priceOfSeller) => {
    if (
      priceOfSeller?.vatType === VAT_TYPE.REQUIRED ||
      (priceOfSeller?.vatType === VAT_TYPE.OPTIONAL && priceOfSeller?.defaultExportVat === true)
    ) {
      return priceOfSeller?.priceHasVat;
    } else {
      return priceOfSeller?.priceNoVat;
    }
  };

  const options = data?.map((item) => ({
    value: item.id,
    label: (
      <Row gutter={16}>
        <Col span={7}>
          <Typography.Text ellipsis={true}>{item.code}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text ellipsis style={{ color: "#2246DC" }}>
            {item.name}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography style={{ textAlign: "center" }}>
            {isNil(item?.stocks) || isEmpty(item?.stocks)
              ? 0
              : item?.stocks?.find((item) => item.warehouse.id === exportedWarehouseID)?.quantity}
          </Typography>
        </Col>
        <Col span={3}>
          <Typography style={{ textAlign: "end" }}>{formatCurrency(resolvePrice(item?.priceOfSeller))}</Typography>
        </Col>
      </Row>
    ),
    item: item,
  }));

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined, isActive: true },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    onChange && onChange(option?.item);
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined, isActive: true },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilterInStock = (value) => {
    value
      ? setParams({
          filters: {
            query: params?.filters?.query,
            realStocks: [{ warehouseID: warehouseID, range: { from: 1 } }],
            isActive: true,
          },
          warehouseIDs: warehouseID,
          sellerID: sellerID,
          pagination: { offset: 0, limit: 10 },
        })
      : setParams({
          ...params,
          filters: {
            query: params?.filters?.query,
            isActive: true,
          },
          warehouseIDs: warehouseID,
          sellerID: sellerID,
          pagination: { offset: 0, limit: 10 },
        });
  };

  useEffect(() => {
    if (warehouseID && sellerID) {
      setParams({
        ...params,
        filters: {
          query: undefined,
          isActive: true,
        },
        warehouseIDs: warehouseID,
        sellerID: sellerID,
        pagination: { offset: 0, limit: 10 },
      });
    }
  }, [warehouseID, sellerID]);

  return {
    options,
    onLoadMoreData,
    hasMoreData,
    onSearch,
    handleChange,
    handleFilterInStock,
    loading,
  };
};

export default useSelectProduct;
