import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row, Space, Tooltip, Typography } from "antd";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";

import { map } from "lodash";
import ModalSelectProduct from "../../ComboDiscountForm/ModalSelectProduct";

const { Panel } = Collapse;

function ComboCollapse({
  remove,
  indexPromotionCampaignTiers,
  indexComboFixedPrice,
  useInModal,
  dataTiersServer,
  form,
  promotionCampaignTiers,
  isUpdate,
  arrayValidateFixedComboPrice,
  arrayValidateFixedComboPriceQuantity,
  fields,
  valuesForm,
  setNewDataTiersServer,
}) {
  const dataConditionProductGroups =
    dataTiersServer?.[indexComboFixedPrice]?.promotionCampaignTiers?.[indexPromotionCampaignTiers]
      ?.conditionProductGroups || [];

  const dataProductsServer =
    dataConditionProductGroups[0]?.conditionPromotionProducts?.map((item) => item?.product) || [];

  const extractedData =
    valuesForm?.promotionCampaignTierGroupsFixedPrice?.[indexComboFixedPrice]?.promotionCampaignTiers?.[
      indexPromotionCampaignTiers
    ]?.conditionProductGroups?.map((item) => {
      const quantitiesArray = item?.conditionPromotionProducts?.map((product) => {
        const validRegionItemsClient = product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];
        const validRegionItemsServer =
          product?.product?.stocks?.filter((item) => item?.warehouse?.regionID !== null) || [];

        return [...validRegionItemsClient, ...validRegionItemsServer]?.reduce(
          (acc, stock) => acc + (stock?.quantity || 0),
          0
        );
      });
      const minValue = Math.min(...quantitiesArray?.filter((val) => !isNaN(val)));

      const promotionCampaignTierQuantity =
        valuesForm?.promotionCampaignTierGroupsFixedPrice?.[indexComboFixedPrice]?.promotionCampaignTiers?.[
          indexPromotionCampaignTiers
        ]?.quantity || 0;

      return {
        quantity: promotionCampaignTierQuantity || item.quantity,
        smallestValueInStock: minValue,
        conditionPromotionProducts: item.conditionPromotionProducts,
      };
    }) || [];

  const isValidateHelp = extractedData[0]?.quantity > extractedData[0]?.smallestValueInStock;

  const validateStatus = arrayValidateFixedComboPriceQuantity[indexPromotionCampaignTiers]
    ? "error"
    : undefined || (isValidateHelp ? "warning" : undefined);

  const help = arrayValidateFixedComboPriceQuantity[indexPromotionCampaignTiers]
    ? t("campaign.combo.errorFixedComboPriceQty")
    : undefined || (isValidateHelp ? t("cart.lackOfStock") : undefined);

  return (
    <Col span={24}>
      <Collapse defaultActiveKey={["1"]} className="collapse-nested" expandIconPosition="left">
        <Panel
          header={
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {t("campaign.combo.preferentialLevel")} {indexPromotionCampaignTiers + 1}
            </Typography.Text>
          }
          extra={
            fields?.length !== 1 && (
              <CustomModal
                {...{
                  message: t("campaign.combo.errorMessage.removeComboCollapse"),
                  onOke: (event) => {
                    remove();
                    setNewDataTiersServer(valuesForm?.promotionCampaignTierGroupsFixedPrice);
                  },
                  footer: false,
                  icon: Img.DeleteIcon,
                  customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
                }}
              />
            )
          }
          key="1"
        >
          <Row gutter={[16, 16]} style={{ alignItems: "center", padding: "16px 32px" }}>
            <Col span={24} xl={8}>
              <Space>
                <Typography.Text strong>{t("campaign.combo.applyProduct")}</Typography.Text>
                <ModalSelectProduct
                  indexPromotionCampaignTiers={indexPromotionCampaignTiers}
                  useInModal={useInModal}
                  dataProductsServer={dataProductsServer}
                  form={form}
                  promotionCampaignTiers={promotionCampaignTiers}
                  isUpdate={isUpdate}
                  indexComboFixedPrice={indexComboFixedPrice}
                />
              </Space>
            </Col>
            <Col span={24} xl={8} className={"col-left"}>
              <FormInput
                type={INPUT_TYPE.NUMBER}
                {...{
                  formItemOptions: {
                    label: (
                      <Space>
                        <Typography.Text strong>{t("campaign.combo.quantity")}</Typography.Text>
                        <Tooltip title={t("campaign.combo.tooltipQtyFixed")}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Space>
                    ),
                    name: [indexPromotionCampaignTiers, "quantity"],
                    validateStatus: validateStatus,
                    help: help,
                    rules: [
                      {
                        required: true,
                        message: t("common.pleaseEnter", {
                          label: t("campaign.combo.tooltipQtyFixed"),
                        }),
                      },
                      {
                        type: "number",
                        min: 1,
                        message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                      },
                      validator({
                        type: "number",
                      }),
                    ],
                  },
                  inputOptions: {
                    placeholder: t("campaign.combo.placeholder.type"),
                    maxLength: 10,
                  },
                }}
              />
            </Col>
            <Col span={24} xl={8} className={"col-left"}>
              <FormInput
                type={INPUT_TYPE.CURRENCY_INPUT}
                {...{
                  formItemOptions: {
                    label: <Typography.Text strong>{t("campaign.combo.comboOfPrice")}</Typography.Text>,
                    name: [indexPromotionCampaignTiers, "fixedComboPrice"],
                    validateStatus: arrayValidateFixedComboPrice[indexPromotionCampaignTiers] ? "error" : undefined,
                    help: arrayValidateFixedComboPrice[indexPromotionCampaignTiers]
                      ? t("campaign.combo.errorFixedComboPrice")
                      : undefined,
                    rules: [
                      {
                        required: true,
                        message: t("common.pleaseEnter", {
                          label: t("campaign.combo.comboOfPrice"),
                        }),
                      },
                      {
                        type: "number",
                        min: 1,
                        message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                      },
                      validator({
                        type: "number",
                      }),
                    ],
                  },
                  inputOptions: {
                    placeholder: t("campaign.combo.placeholder.type"),
                    maxLength: 15,
                  },
                }}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Col>
  );
}

export default ComboCollapse;
