import { FontAwesomeIcon, Icon } from "assets/icons";
import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import "styles/custom_component.scss";
import "./index.scss";
import CategoryTreeFilter from "./components/CategoryTreeFilter";
import FormInput from "components/FormInput";
import useList from "./useList";
import FilterMenu from "./components/FilterMenu";
import { FiltersTag } from "./components/FiltersTag";

const ProductPriceLogsList = ({ useInModal }) => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    staffs,
    paginationData,
    onSearchPriceLog,
    loading,
    handleChangeTablePriceLogs,
    filterTags,
    setFilterTags,
    filterProductPriceLogsByCategoryId,
    params,
    setParams,
    handleRemoveFilterTag,
    GET_QUERY,
  } = useList();

  return (
    <div className="product-container">
      {useInModal || <PageHeader pageTitle={t("common.historyUpdatePriceProduct")} />}
      <div className="filter-box">
        <CategoryTreeFilter
          onFilter={filterProductPriceLogsByCategoryId}
          defaultValue={params?.filters?.categoryIDs || undefined}
        />
        <FilterMenu
          params={params}
          setParams={setParams}
          staffs={staffs}
          filterTags={filterTags}
          setFilterTags={setFilterTags}
        />
        <div className="search-input">
          <FormInput
            {...{
              type: INPUT_TYPE,
              inputOptions: {
                placeholder: t("product.searchCodeProductPriceLog"),
                onChange: onSearchPriceLog,
                defaultValue: GET_QUERY?.query,
                prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
              },
            }}
          ></FormInput>
        </div>
      </div>
      <div>
        <FiltersTag
          staffOptions={staffs}
          rangeDateTimeFilter={params?.filters?.createdAtFilter}
          params={params}
          handleRemoveFilterTag={handleRemoveFilterTag}
        />
        <div className="list-watch-history-price">
          <CustomTable
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record?.id}
            scroll={{ x: 1900 }}
            onChange={handleChangeTablePriceLogs}
            pagination={{
              total: paginationData?.total,
              pageSize: paginationData?.limit,
              current: paginationData?.offset / paginationData?.limit + 1 || 1,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductPriceLogsList;
