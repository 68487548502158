import { useCancelUpdateDetail, useConfirmUpdateDetail } from "hooks/order";
import { useHistory } from "react-router-dom";
import { getQuery } from "utils/helperFuncs";

const useEdit = () => {
  const { orderID } = getQuery();
  const history = useHistory();

  const { handleConfirmUpdateDetail } = useConfirmUpdateDetail();
  const { handleCancelUpdateDetail } = useCancelUpdateDetail();

  const onConfirmUpdateDetail = async () => {
    await handleConfirmUpdateDetail({ orderID }).then(() => {
      history.push(`/order/${orderID}`);
    });
  };

  const onCancelUpdateDetail = async () => {
    await handleCancelUpdateDetail({ orderID }).then(() => {
      history.push(`/order/${orderID}`);
    });
  };

  return { onCancelUpdateDetail, onConfirmUpdateDetail };
};

export default useEdit;
