import { Form } from "antd";
import { USER_PERMISSIONS } from "config/constants";
import { SELLER_TYPE } from "features/PotentialCustomer/constants";
import { useCreatePotentialCustomer } from "hooks/potentialCustomer/mutation";
import {
  useGetCountPotentialCustomer,
  useGetCustomerDuplicate,
  useGetPotentialCustomerDetail,
} from "hooks/potentialCustomer/query";
import useDebounce from "hooks/useDebounce";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { convertPhoneNumber, getQuery, notify } from "utils/helperFuncs";

const undefinedValue = "undefinedundefined";

function useAddPotentialCustomer() {
  const GET_QUERY = getQuery();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [typePersonal, setTypeCustomer] = useState(SELLER_TYPE.PERSONAL);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [inChargeStaff, setInChargeStaff] = useState();

  const [paramsStaff, setParamsStaff] = useState({
    filters: {
      query: null,
      status: "ACTIVE",
      permission: USER_PERMISSIONS.CUSTOMER_CREATE,
    },
    pagination: { offset: 0, limit: 10 },
  });
  const [openDrawerMulti, setOpenDrawerMulti] = useState(false);
  const [customerId, setCustomerId] = useState();
  const handleOpenDrawerMulti = (id) => {
    setCustomerId(id);
    setOpenDrawerMulti(true);
  };

  const handleCloseDrawerMulti = () => {
    setOpenDrawerMulti(false);
  };

  const { data: dataDetail, loading: loadingDetail } = useGetPotentialCustomerDetail({ id: customerId });
  const { data: dataStaffOptions, hasMoreData, onLoadMoreData } = useGetStaff(paramsStaff);
  const { refetch } = useGetCountPotentialCustomer();
  const { handleCreatePotentialCustomer } = useCreatePotentialCustomer();

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleChangeInChargeStaff = (value, option) => {
    setInChargeStaff(option);
  };

  const initForm = {
    type: SELLER_TYPE.PERSONAL,
    cityID: undefined,
    districtID: undefined,
    wardID: undefined,
    telephone: undefined,
    idNumber: undefined,
    vatNumber: undefined,
    email: undefined,
  };

  const [filters, setFilters] = useState({
    emails: GET_QUERY.emails,
    telephones: GET_QUERY.telephones,
    idNumbers: GET_QUERY.idNumbers,
    vatNumbers: GET_QUERY.vatNumbers,
  });

  const debouncedValueEmail = useDebounce(filters.emails);
  const debouncedValueTelephone = useDebounce(filters.telephones);
  const debouncedValueIdNumber = useDebounce(filters.idNumbers);
  const debouncedValueVatNumber = useDebounce(filters.vatNumbers);

  const {
    data: valueIdNumber,
    loading: loadingIdNumber,
    handleGetDataDuplicate: handleGetIdNumberDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueTaxCode,
    loading: loadingTaxCode,
    handleGetDataDuplicate: handleGetVatNumberDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueEmail,
    loading: loadingEmail,
    handleGetDataDuplicate: handleGetEmailDuplicate,
  } = useGetCustomerDuplicate();
  const {
    data: valueTelephone,
    loading: loadingTelephone,
    handleGetDataDuplicate: handleGetTelephoneDuplicate,
  } = useGetCustomerDuplicate();

  const checkDuplicateCustomer = (formValue, apiData) => {
    if (formValue && apiData) {
      return true;
    }
    return false;
  };

  let isDuplicateIdNumber = checkDuplicateCustomer(
    formValues?.idNumber,
    valueIdNumber?.seller?.idNumber || valueIdNumber?.potentialCustomer?.idNumber
  );
  let isDuplicateTaxCode = checkDuplicateCustomer(
    formValues?.vatNumber,
    valueTaxCode?.seller?.vatInfo?.taxIDNumber || valueTaxCode?.potentialCustomer?.vatNumber
  );
  let isDuplicateEmail = checkDuplicateCustomer(
    formValues?.email,
    valueEmail?.seller?.email || valueEmail?.potentialCustomer?.email
  );
  let isDuplicateTelephone = checkDuplicateCustomer(
    convertPhoneNumber(formValues?.telephone),
    valueTelephone?.seller?.telephone || valueTelephone?.potentialCustomer?.telephone
  );

  const handleSearchIdNumber = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, idNumbers: e.target.value.trim() }));
  };
  const handleSearchTaxCode = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, vatNumbers: e.target.value.trim() }));
  };
  const handleSearchEmail = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, emails: e.target.value.trim() }));
  };
  const handleSearchTelephone = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, telephones: e.target.value.trim() }));
  };

  const handlerGetEmailDup = async (value) => {
    if (openDrawer)
      await handleGetEmailDuplicate({
        filters: {
          emails: value || undefinedValue,
        },
      });
  };
  const handlerGetIdNumberDup = async (value) => {
    if (openDrawer)
      await handleGetIdNumberDuplicate({
        filters: {
          idNumbers: value || undefinedValue,
        },
      });
  };
  const handlerGetVatNumberDup = async (value) => {
    if (openDrawer)
      await handleGetVatNumberDuplicate({
        filters: {
          vatNumbers: value || undefinedValue,
        },
      });
  };
  const handlerGetTelephoneDup = async (value) => {
    if (openDrawer)
      await handleGetTelephoneDuplicate({
        filters: {
          telephones: value || undefinedValue,
        },
      });
  };

  useEffect(() => {
    handlerGetEmailDup(debouncedValueEmail);
    handlerGetIdNumberDup(debouncedValueIdNumber);
    handlerGetVatNumberDup(debouncedValueVatNumber);
    handlerGetTelephoneDup(convertPhoneNumber(debouncedValueTelephone));
  }, [debouncedValueEmail, debouncedValueIdNumber, debouncedValueTelephone, debouncedValueVatNumber]);

  const [visibleModalCloseDrawer, setVisibleModalCloseDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleResetAll = () => {
    const newFilter = {
      emails: undefined,
      telephones: undefined,
      idNumbers: undefined,
      vatNumbers: undefined,
    };
    setTypeCustomer(SELLER_TYPE.PERSONAL);
    setFilters(newFilter);
    setInChargeStaff(undefined);
    form.resetFields();
  };

  const resetDataDuplicate = () => {
    handlerGetEmailDup(undefinedValue);
    handlerGetIdNumberDup(undefinedValue);
    handlerGetVatNumberDup(undefinedValue);
    handlerGetTelephoneDup(undefinedValue);
  };

  const handleOkeModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(false);
    setOpenDrawer(false);
    handleResetAll();
    resetDataDuplicate();
  };

  const handleCancelModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(false);
    resetDataDuplicate();
  };

  const handleOpenModalCloseDrawer = () => {
    setVisibleModalCloseDrawer(true);
  };

  const handleValuesChange = async (changeValue, values) => {
    if (changeValue.type) {
      setTypeCustomer(changeValue.type);
    }
    setFormValues(values);
  };

  const handleSubmitDrawer = async () => {
    try {
      const values = await form.validateFields();
      if (isDuplicateIdNumber || isDuplicateTaxCode || isDuplicateEmail || isDuplicateTelephone) {
        console.info("Validated");
      } else {
        setLoadingCreate(true);
        const newParams = {
          ...values,
          inChargeStaffID: inChargeStaff?.value,
          email: values?.email?.length ? values.email : undefined,
          idNumber: values?.idNumber?.length ? values.idNumber : undefined,
          vatNumber: values?.vatNumber?.length ? values.vatNumber : undefined,
          telephone: convertPhoneNumber(values.telephone) || undefined,
          legalRepresentativeTelephone: convertPhoneNumber(values.legalRepresentativeTelephone) || undefined,
          representativeTelephone: convertPhoneNumber(values.representativeTelephone) || undefined,
        };
        await handleCreatePotentialCustomer(newParams)
          .then(() => {
            setLoadingCreate(false);
            handleResetAll();
            setOpenDrawer(false);
            setInChargeStaff(undefined);
            refetch();
            notify.success({
              message: t("potentialCustomer.createCustomerSuccess"),
            });
          })
          .catch((error) => {
            setLoadingCreate(false);
            notify.error({
              message: t("common.createError"),
              description: error.message,
            });
          });
      }
    } catch (error) {
      console.info(error);
    }
  };

  return {
    openDrawer,
    initForm,
    form,
    dataStaffOptions,
    hasMoreData,
    typePersonal,
    formValues,
    loadingCreate,
    handleOpenDrawer,
    handleSubmitDrawer,
    handleValuesChange,
    onLoadMoreData,
    onSearchStaff: handleSearchStaffs,
    handleChangeInChargeStaff,
    valueEmail,
    valueTelephone,
    loadingEmail,
    loadingTelephone,
    isDuplicateEmail,
    isDuplicateTelephone,
    handleSearchEmail,
    handleSearchTelephone,
    valueIdNumber,
    valueTaxCode,
    loadingIdNumber,
    loadingTaxCode,
    isDuplicateIdNumber,
    isDuplicateTaxCode,
    handleSearchIdNumber,
    handleSearchTaxCode,
    openDrawerMulti,
    handleOpenDrawerMulti,
    handleCloseDrawerMulti,
    dataDetail,
    loadingDetail,
    visibleModalCloseDrawer,
    handleOkeModalCloseDrawer,
    handleOpenModalCloseDrawer,
    handleCancelModalCloseDrawer,
  };
}

export default useAddPotentialCustomer;
