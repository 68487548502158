import React from "react";
import { Col, Row, Spin, Typography } from "antd";
import { t } from "i18next";
import { isNil, isEmpty } from "lodash";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import useSelectProduct from "./useSelectProduct";

import styles from "./index.module.scss";

function SelectProduct({ handleGetProduct }) {
  const { onLoadMoreData, hasMoreData, loading, onSearch, handleChange, data } = useSelectProduct({
    handleGetProduct,
  });

  const options = data?.map((item) => ({
    value: item.id,
    label: (
      <Row gutter={16}>
        <Col span={7}>
          <Typography.Text ellipsis={true}>{item.code}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text ellipsis style={{ color: "#2246DC" }}>
            {item.name}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography style={{ textAlign: "center" }}>
            {isNil(item?.stocks) || isEmpty(item?.stocks) ? 0 : item?.stocks[0]?.quantity}
          </Typography>
        </Col>
      </Row>
    ),
    product: item,
  }));

  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        inputOptions: {
          placeholder: t("serialIE.placeholder.searchByProduct"),
          options: options,
          value: null,
          dropdownMatchSelectWidth: 1000,
          allowClear: false,
          dropdownRender: (originNode) => (
            <>
              <Row gutter={16} className={styles["custom-select"]}>
                <Col span={7}>
                  <Typography className={styles["header-select"]} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.code")}
                  </Typography>
                </Col>
                <Col span={12}>
                  <Typography className={styles["header-select"]} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.name")}
                  </Typography>
                </Col>
                <Col span={2}>
                  <Typography className={styles["header-select"]} style={{ padding: "12px 0px" }}>
                    {t("product.tableTitle.inStock")}
                  </Typography>
                </Col>
              </Row>
              <Spin spinning={loading}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectProduct;
