import { useMutation } from "@apollo/client";
import {
  APPROVE,
  COMPLETE_CUSTOMER,
  CONFIRM_TAKING_CARE_CUSTOMER,
  CREATE_COMMUNICATE_LOG,
  CREATE_POTENTIAL_CUSTOMER,
  DESIST_CUSTOMER,
  LINK_CUSTOMER,
  RECONTACT_CUSTOMER,
  REJECT_CUSTOMER,
  UPDATE_POTENTIAL_CUSTOMER,
  UPLOAD_FILE_SAMPLE_CUSTOMER,
} from "graphql/potentialCustomer/mutation";
import {
  COUNT_POTENTIAL_CUSTOMER,
  POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
  POTENTIAL_CUSTOMER_DETAIL,
  POTENTIAL_CUSTOMER_LIST,
} from "graphql/potentialCustomer/query";
import { useUploadDocument } from "hooks/upload-document";

export const useApprovePotentialCustomer = () => {
  const [approvePotentialCustomer, { loading }] = useMutation(APPROVE);

  const handleApprovePotentialCustomer = async (params) => {
    return await approvePotentialCustomer({
      variables: params,
      refetchQueries: [POTENTIAL_CUSTOMER_LIST, COUNT_POTENTIAL_CUSTOMER],
    });
  };

  return {
    handleApprovePotentialCustomer,
    loading,
  };
};

export const useCreatePotentialCustomer = () => {
  const [createPotentialCustomer, { loading }] = useMutation(CREATE_POTENTIAL_CUSTOMER);

  const handleCreatePotentialCustomer = async (params) => {
    return await createPotentialCustomer({
      variables: {
        request: params,
      },
      refetchQueries: [POTENTIAL_CUSTOMER_LIST, COUNT_POTENTIAL_CUSTOMER],
    });
  };

  return {
    handleCreatePotentialCustomer,
    loading,
  };
};

export const useUploadFileCustomer = () => {
  const [uploadFileCustomer, { loading, data }] = useMutation(UPLOAD_FILE_SAMPLE_CUSTOMER);

  const handleUploadFileCustomer = async (params) => {
    return await uploadFileCustomer({
      variables: { file: params },
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleUploadFileCustomer,
    loadingUploadFileL: loading,
    dataUploadFileCustomer: data?.potentialCustomer?.batchCreate,
  };
};

export const useCreateCommunicateLog = () => {
  const [createCommunicateLog, { loading }] = useMutation(CREATE_COMMUNICATE_LOG);
  const { handleUpload } = useUploadDocument();

  const handleCreateCommunicateLog = async (params) => {
    const newImageURLs = await handleUpload({
      files: params?.request?.fileUrls?.map((item) => item?.originFileObj),
    });
    return await createCommunicateLog({
      variables: {
        request: {
          ...params?.request,
          fileUrls: newImageURLs?.map(({ url }) => url),
        },
      },
      refetchQueries: [POTENTIAL_CUSTOMER_ACTIVITY_LOGS],
    });
  };

  return {
    handleCreateCommunicateLog,
    loadingCreateCommunicate: loading,
  };
};

export const useDesistCustomer = () => {
  const [desistCustomer, { loading }] = useMutation(DESIST_CUSTOMER);
  const handleDesistCustomer = async (params) => {
    return await desistCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleDesistCustomer,
    loadingDesistCustomer: loading,
  };
};

export const useConfirmTakingCareCustomer = () => {
  const [confirmTakingCareCustomer, { loading }] = useMutation(CONFIRM_TAKING_CARE_CUSTOMER);

  const handleConfirmTakingCareCustomer = async (params) => {
    return await confirmTakingCareCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleConfirmTakingCareCustomer,
    loadingConfirmTakingCare: loading,
  };
};

export const useRecontactCustomer = () => {
  const [recontactCustomer, { loading }] = useMutation(RECONTACT_CUSTOMER);

  const handleRecontactCustomer = async (params) => {
    await recontactCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleRecontactCustomer,
    loadingRecontact: loading,
  };
};

export const useRejectCustomer = () => {
  const [rejectCustomer, { loading }] = useMutation(REJECT_CUSTOMER);

  const handleRejectCustomer = async (params) => {
    await rejectCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleRejectCustomer,
    loadingRecontact: loading,
  };
};

export const useCompleteCustomer = () => {
  const [completeCustomer, { loading }] = useMutation(COMPLETE_CUSTOMER);

  const handleCompleteCustomer = async (params) => {
    await completeCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleCompleteCustomer,
    loadingComplete: loading,
  };
};

export const useLinkCustomer = () => {
  const [linkCustomer, { loading, data }] = useMutation(LINK_CUSTOMER);

  const handleLinkCustomer = async (params) => {
    await linkCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleLinkCustomer,
    loading,
    data,
  };
};

export const useUpdatePotentialCustomer = () => {
  const [updatePotentialCustomer, { data, loading }] = useMutation(UPDATE_POTENTIAL_CUSTOMER);

  const handleUpdatePotentialCustomer = async (params) => {
    await updatePotentialCustomer({
      variables: params,
      refetchQueries: [
        POTENTIAL_CUSTOMER_ACTIVITY_LOGS,
        POTENTIAL_CUSTOMER_LIST,
        POTENTIAL_CUSTOMER_DETAIL,
        COUNT_POTENTIAL_CUSTOMER,
      ],
    });
  };

  return {
    handleUpdatePotentialCustomer,
    loadingUpdatePotentialCustomer: loading,
  };
};
