import React from "react";
import { Button } from "antd";

function ButtonAdd({ selectedProduct, handleSelectedData, title }) {
  return (
    <Button disabled={selectedProduct?.selectedRows?.length ? false : true} type="primary" onClick={handleSelectedData}>
      {title}
    </Button>
  );
}

export default ButtonAdd;
