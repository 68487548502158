import { useMutation, useQuery } from "@apollo/client";
import { PROPOSED_PURCHASE_STATUS, PURCHASE_REQUEST_TYPE } from "features/Purchase/constant";
import {
  CONFIRM_APPROVE_CHECK_INFO_PURCHASE_ORDER,
  CONFIRM_APPROVE_CHECK_QUALITY_PURCHASE_ORDER,
  CONFIRM_CANCEL_PURCHASE_ORDER,
  CONFIRM_DEPOSIT_PURCHASE_ORDER,
  CONFIRM_IMPORT_SERIAL_PURCHASE,
  CONFIRM_PAYMENT_PURCHASE_ORDER,
  CONFIRM_RECEIVED_PURCHASE_ORDER,
  CONFIRM_REJECT_CHECK_INFO_PURCHASE_ORDER,
  CONFIRM_REJECT_CHECK_QUALITY_PURCHASE_ORDER,
  CONFIRM_SAVED_DOCUMENT_PURCHASE_ORDER,
  CREATE_PURCHASE,
  SPLIT_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from "graphql/purchase/mutation";
import {
  GET_PROPOSED_PURCHASE_LIST,
  GET_PURCHASE_ORDER_DETAIL,
  GET_PURCHASE_ORDER_LIST,
  GET_PURCHASE_ORDER_REFERENCES,
  GET_PURCHASE_STATUS_COUNT,
  GET_SELLER_LOAD_MORE,
} from "graphql/purchase/query";
import { useUploadDocument } from "hooks/upload-document";
import { omit } from "lodash";

export const useGetPurchaseStatusCount = () => {
  const { data, loading, refetch } = useQuery(GET_PURCHASE_STATUS_COUNT);
  return {
    statusCount: data?.purchaseOrder?.statusCount,
    loading,
    refetch,
  };
};

export const useGetPurchaseOrderList = (params) => {
  const { data, loading, refetch } = useQuery(GET_PURCHASE_ORDER_LIST, {
    variables: { filters: params.filters, pagination: params.pagination, sorts: params.sorts },
  });

  return {
    loading,
    refetch,
    paginationData: data?.purchaseOrder?.pagination?.paginationData,
    purchaseOrderList: data?.purchaseOrder?.pagination?.purchaseOrders,
  };
};

export const useGetSellerLoadMore = (params) => {
  const { loading, data, fetchMore } = useQuery(GET_SELLER_LOAD_MORE, {
    variables: params,
    fetchPolicy: "cache-first",
  });

  const sellers = data?.seller?.pagination?.sellers || [];
  const currentTotalSellers = sellers.length;
  const paginationData = data?.seller?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalSellers, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.seller?.pagination?.sellers) return pv;
        const newData = {
          seller: {
            pagination: {
              paginationData: { ...fetchMoreResult?.seller?.pagination?.paginationData },
              sellers: [...pv?.seller?.pagination?.sellers, ...fetchMoreResult?.seller?.pagination?.sellers],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalSellers === paginationData?.total);

  return {
    loading,
    sellers,
    onLoadMoreData,
    hasMoreData,
    data: data?.seller?.pagination?.sellers,
  };
};

export const useGetProposedPurchaseList = (params) => {
  const { data, loading, refetch } = useQuery(GET_PROPOSED_PURCHASE_LIST, {
    variables: {
      filters: {
        ...omit(params.filters, ["typesProposed"]),
        statuses: PROPOSED_PURCHASE_STATUS.WAITING_FOR_BUYING,
        types:
          !params.filters.typesProposed || params.filters.typesProposed?.length === 0
            ? [PURCHASE_REQUEST_TYPE.DOMESTIC, PURCHASE_REQUEST_TYPE.OVERSEAS]
            : params.filters.typesProposed,
      },
      pagination: params.pagination,
      sorts: params.sorts,
    },
  });
  return {
    loading,
    refetch,
    paginationData: data?.importRequests?.pagination?.paginationData,
    proposedPurchaseList: data?.importRequests?.pagination?.importRequests,
  };
};

export const useCreatePurchase = () => {
  const [createPurchase, { loading, error }] = useMutation(CREATE_PURCHASE);

  const handleCreatePurchase = async (params) => {
    return await createPurchase({
      variables: { purchaseOrder: params },
      refetchQueries: [],
    });
  };

  return {
    handleCreatePurchase,
    loadingCreatePurchase: loading,
    errorCreatePurchase: error,
  };
};

export const useGetPurchaseOrderDetail = (params) => {
  const { data, loading, refetch } = useQuery(GET_PURCHASE_ORDER_DETAIL, {
    variables: params,
  });

  return {
    data: data?.purchaseOrder?.get,
    loading,
    refetch,
  };
};

export const useGetPurchaseOrderReferences = (params) => {
  const { data, loading, refetch } = useQuery(GET_PURCHASE_ORDER_REFERENCES, {
    variables: params,
  });

  return {
    data: data?.purchaseOrder?.references,
    loading,
    refetch,
  };
};

export const useConfirmDepositPurchaseOrder = () => {
  const [confirmDeposit, { loading, error }] = useMutation(CONFIRM_DEPOSIT_PURCHASE_ORDER);

  const handleConfirmDepositPurchaseOrder = async (ids) => {
    return await confirmDeposit({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmDepositPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmReceivedPurchaseOrder = () => {
  const [confirmReceived, { loading, error }] = useMutation(CONFIRM_RECEIVED_PURCHASE_ORDER);

  const handleConfirmReceivedPurchaseOrder = async (ids) => {
    return await confirmReceived({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmReceivedPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmApproveCheckQualityPurchaseOrder = () => {
  const [confirmReceived, { loading, error }] = useMutation(CONFIRM_APPROVE_CHECK_QUALITY_PURCHASE_ORDER);

  const handleConfirmApproveCheckQualityPurchaseOrder = async (ids) => {
    return await confirmReceived({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmApproveCheckQualityPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmRejectCheckQualityPurchaseOrder = () => {
  const [confirmRejectedCheckQuality, { loading, error }] = useMutation(CONFIRM_REJECT_CHECK_QUALITY_PURCHASE_ORDER);
  const { handleUpload } = useUploadDocument();

  const handleConfirmRejectCheckQualityPurchaseOrder = async (params) => {
    const newImageURLs = await handleUpload({
      files: params?.reasonInput?.fileURLs?.map((item) => item?.originFileObj),
    });
    return await confirmRejectedCheckQuality({
      variables: {
        ...params,
        reasonInput: { ...params?.reasonInput, fileURLs: newImageURLs?.map(({ url }) => url) },
      },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmRejectCheckQualityPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmApproveCheckInfoPurchaseOrder = () => {
  const [confirmApprovedCheckInfo, { loading, error }] = useMutation(CONFIRM_APPROVE_CHECK_INFO_PURCHASE_ORDER);

  const handleConfirmApproveCheckInfoPurchaseOrder = async (ids) => {
    return await confirmApprovedCheckInfo({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmApproveCheckInfoPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmRejectCheckInfoPurchaseOrder = () => {
  const [confirmRejectedCheckInfo, { loading, error }] = useMutation(CONFIRM_REJECT_CHECK_INFO_PURCHASE_ORDER);
  const { handleUpload } = useUploadDocument();

  const handleConfirmRejectCheckInfoPurchaseOrder = async (params) => {
    const newImageURLs = await handleUpload({
      files: params?.reasonInput?.fileURLs?.map((item) => item?.originFileObj),
    });
    return await confirmRejectedCheckInfo({
      variables: {
        ...params,
        reasonInput: { ...params?.reasonInput, fileURLs: newImageURLs?.map(({ url }) => url) },
      },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmRejectCheckInfoPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmPaymentPurchaseOrder = () => {
  const [confirmPayment, { loading, error }] = useMutation(CONFIRM_PAYMENT_PURCHASE_ORDER);

  const handleConfirmPaymentPurchaseOrder = async (ids) => {
    return await confirmPayment({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmPaymentPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmCancelPurchaseOrder = () => {
  const [confirmCancel, { loading, error }] = useMutation(CONFIRM_CANCEL_PURCHASE_ORDER);
  const { handleUpload } = useUploadDocument();

  const handleConfirmCancelPurchaseOrder = async (params) => {
    const newImageURLs = await handleUpload({
      files: params?.reasonInput?.fileURLs?.map((item) => item?.originFileObj),
    });
    return await confirmCancel({
      variables: {
        ...params,
        reasonInput: { ...params?.reasonInput, fileURLs: newImageURLs?.map(({ url }) => url) },
      },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmCancelPurchaseOrder,
    loading,
    error,
  };
};

export const useConfirmSavedDocumentPurchaseOrder = () => {
  const [confirmSavedDocument, { loading, error }] = useMutation(CONFIRM_SAVED_DOCUMENT_PURCHASE_ORDER);

  const handleConfirmSavedDocumentPurchaseOrder = async (ids) => {
    return await confirmSavedDocument({
      variables: { ids },
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmSavedDocumentPurchaseOrder,
    loading,
    error,
  };
};

export const useSplitPurchaseOrder = () => {
  const [split, { loading, error }] = useMutation(SPLIT_PURCHASE_ORDER);

  const handleSplitPurchaseOrder = async (params) => {
    return await split({
      variables: params,
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleSplitPurchaseOrder,
    loading,
    error,
  };
};

export const useUpdatePurchaseOrder = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PURCHASE_ORDER);

  const handleUpdatePurchaseOrder = async (params) => {
    return await update({
      variables: params,
      refetchQueries: [],
    });
  };

  return {
    handleUpdatePurchaseOrder,
    loadingUpdatePurchase: loading,
    errorUpdatePurchase: error,
  };
};

export const useConfirmImportedSerialPurchaseOrder = () => {
  const [confirmImportedSerial, { loading, error }] = useMutation(CONFIRM_IMPORT_SERIAL_PURCHASE);

  const handleConfirmImportedSerialPurchaseOrder = async (params) => {
    return await confirmImportedSerial({
      variables: params,
      refetchQueries: ["PurchaseOrderDetail", "PurchaseOrderList", "PurchaseStatusCount"],
    });
  };

  return {
    handleConfirmImportedSerialPurchaseOrder,
    loading,
    error,
  };
};
