import Profile from ".";

const router = [
  {
    path: "/profile",
    key: "profile",
    component: Profile,
    exact: true,
  },
];

export default router;
