import React from "react";
import { Col, Form, Row, Typography } from "antd";
import FormInput from "components/FormInput";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { getFile } from "utils/helperFuncs";
import { optionsRequestType } from "features/WarrantyRequest/constant";
import styles from "./index.module.scss";
import { SelectLoadMore } from "components/CustomSelect";
import UploadFiles from "components/UploadFiles";

const lableCol = { span: 24, offset: 0 };

function FormEditNotOwner({
  options,
  onLoadMoreData,
  hasMoreData,
  dataAllCategories,
  handleSearchTreeSelect,
  handleChangeTreeSelect,
  onSearch,
  handleChange,
}) {
  return (
    <Row gutter={16}>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.serialIMEI")}</Typography.Text>,
              name: "serialImei",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.serialIMEI"),
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <SelectLoadMore
          type={INPUT_TYPE.SELECT_LOAD_MORE}
          {...{
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.productCode")}</Typography.Text>,
              name: "productID",
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.productCode"),
              options: options,
              allowClear: false,
            },
          }}
          onLoadMore={onLoadMoreData}
          hasMoreData={hasMoreData}
          onSearch={onSearch}
          onChange={handleChange}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.productName")}</Typography.Text>,
              name: "productName",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.productName"),
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          type={INPUT_TYPE.TREE_SELECT}
          formItemOptions={{
            placeholder: t("warrantyRequest.create.placeHolder.attributeLV3"),
            label: <Typography.Text strong>{t("warrantyRequest.create.attributeLV3")}</Typography.Text>,
            className: styles["category"],

            name: "categoryID",
          }}
          inputOptions={{
            placeholder: t("warrantyRequest.create.placeHolder.attributeLV3"),
            treeData: dataAllCategories,
            showSearch: true,
            onChange: handleChangeTreeSelect,
            filterTreeNode: (search, item) => handleSearchTreeSelect(search, item),
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.SELECT,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.warrantyType")}</Typography.Text>,
              name: "warrantyRequestType",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.warrantyType"),
              options: optionsRequestType,
              disabled: "disabled",
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.appointmentDate")}</Typography.Text>,
              name: "promisedReturnDate",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.date"),
              format: DATE_FORMAT,
            },
          }}
        />
      </Col>

      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.errorDetail")}</Typography.Text>,
              name: "defectDescription",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.errorDetail"),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.clientRequest")}</Typography.Text>,
              name: "customerRequest",
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.clientRequest"),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.create.accessoriesNote")}</Typography.Text>,
              name: "accessoryAttachment",
            },
            inputOptions: {
              placeholder: t("warrantyRequest.create.placeHolder.accessoriesNote"),
            },
          }}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={lableCol}
          name={"imageURLs"}
          getValueFromEvent={getFile}
          valuePropName={"fileList"}
          label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
          extra={t("warrantyRequest.create.noteAttack")}
        >
          <UploadFiles />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormEditNotOwner;
