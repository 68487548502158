import CreateSeller from "./Create";
import { Icon, FontAwesomeIcon } from "assets/icons";
import { USER_PERMISSIONS } from "config/constants";
import SellerUpdate from "./Update";
import SellerGroupList from "./SellerGroup/List";
import SellerGroupDetail from "./SellerGroup/Detail";
import SellerDetail from "./Detail";
import AgentTabs from "features/Agent/List";
import SellerApprove from "./Approve";

const router = [
  {
    key: "seller",
    title: "common.seller",
    icon: <FontAwesomeIcon icon={Icon.faUserFriends} />,
    permissions: [USER_PERMISSIONS.SELLER_VIEW],
    children: [
      {
        key: "seller-list",
        title: "common.sellerList",
        path: "/seller",
        component: AgentTabs,
        exact: true,
      },
      {
        key: "seller-group",
        title: "common.groupCustomer",
        path: "/seller-group",
        component: SellerGroupList,
        exact: true,
      },
    ],
  },
  {
    key: "seller-create",
    path: "/seller/create",
    component: CreateSeller,
    exact: true,
  },
  {
    key: "seller-detail",
    path: "/seller/detail/:id",
    component: SellerDetail,
    exact: true,
  },
  {
    key: "seller-update",
    path: "/seller/update/:id",
    component: SellerUpdate,
    exact: true,
  },
  {
    key: "seller-approve",
    path: "/seller/:id?/approve/potential-customer/:potentialCustomerId?",
    component: SellerApprove,
    exact: true,
  },
  {
    key: "seller-group-detail",
    path: "/seller-group/detail/:id",
    component: SellerGroupDetail,
    exact: true,
  },
];

export default router;
