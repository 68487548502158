import { Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { renderLabelStatus } from "features/Order/helper";
import { t } from "i18next";
import ModalTrackingOrder from "../ModalTrackingOrder";
import styles from "./index.module.scss";

function OrderInfo({
  orderOptions,
  loadMoreData,
  hasMoreData,
  onSearch,
  loading,
  orderInfo,
  handleChangeInfo,
  handleChange,
  isStateLocation,
}) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <SelectLoadMore
          formItemOptions={{
            name: "orderID",
            rules: [
              {
                required: true,
                message: t("serialIE.error.product"),
              },
            ],
            className: "form-order-select",
          }}
          inputOptions={{
            placeholder: t("serialIE.placeholder.selectProductcode"),
            options: orderOptions,
            dropdownMatchSelectWidth: 1000,
            allowClear: false,
            dropdownRender: (originNode) => (
              <>
                <Row gutter={16} className={styles["custom-dropdown"]}>
                  <Col span={6} className={styles["item"]} style={{ padding: "10px 0 0 20px" }}>
                    <Typography.Text strong className={styles["sub-title"]}>
                      {t("serialIE.providerInfo.orderCodeB")}
                    </Typography.Text>
                  </Col>
                  <Col span={10} className={styles["item"]} style={{ paddingTop: "10px" }}>
                    <Typography.Text strong className={styles["sub-title"]}>
                      {t("serialIE.providerInfo.customer")}
                    </Typography.Text>
                  </Col>
                  <Col span={5} className={styles["item"]} style={{ paddingTop: "10px" }}>
                    <Typography.Text strong className={styles["sub-title"]}>
                      {t("serialIE.providerInfo.warehouseB")}
                    </Typography.Text>
                  </Col>
                  <Col span={3} className={styles["item"]}>
                    <Typography.Text strong className={styles["sub-title"]}>
                      {t("serialIE.providerInfo.quantity")}
                    </Typography.Text>
                  </Col>
                </Row>
                <Spin spinning={loading}>{originNode}</Spin>
              </>
            ),
            optionLabelProp: "labelOrder",
            onSelect: (value, options) => handleChangeInfo(value, options),
            disabled: isStateLocation,
          }}
          onLoadMore={loadMoreData}
          hasMoreData={hasMoreData}
          onSearch={onSearch}
          onChange={handleChange}
        />
      </Col>
      <>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.status")}</Typography.Text>
        </Col>
        <Col span={20} className="modal-status-order">
          <Typography.Text>{renderLabelStatus[orderInfo?.status] || "--------"}</Typography.Text>
          {orderInfo && <ModalTrackingOrder data={orderInfo} />}
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.seller")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{orderInfo?.seller?.fullName || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.phone")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{orderInfo?.seller?.telephone || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.email")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{orderInfo?.seller?.email || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.warehouse")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{orderInfo?.exportedWarehouse?.name || "--------"}</Typography.Text>
        </Col>
      </>
      <Col span={24} style={{ textAlign: "center", width: "100%", marginTop: "16px" }}>
        {!orderInfo && <Typography.Link strong>{t("warehouse.salesReturn.pleaseSearchOrder")}</Typography.Link>}
      </Col>
    </Row>
  );
}

export default OrderInfo;
