import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";

function DuplicateValuesWarning({ message, fullName, span = 8, styleRow, onClick, seller }) {
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Col span={span}></Col>
      <Col>
        <Typography.Text type="danger">{message}</Typography.Text>
        {seller?.status === "APPROVED_SELLER" ? (
          <Link target={"_blank"} to={`seller/detail/${seller?.sellerID}`}>
            {fullName || seller?.seller?.fullName || seller?.potentialCustomer?.fullName}
          </Link>
        ) : (
          <Typography.Link onClick={() => onClick(seller?.potentialCustomerID)}>{fullName}</Typography.Link>
        )}
      </Col>
    </Row>
  );
}

export default DuplicateValuesWarning;
