import { Button, Popover, Space, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { EXPORT_ORDER_TYPE } from "features/Order/constants";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { t } from "i18next";
import React from "react";
import "./index.scss";

const Toolbar = ({ onExportOrder, onPrintOrder, dataToolBar }) => {
  const [_, copy] = useCopyToClipboard();
  const { shareLink } = dataToolBar;

  return (
    <Space className="order-toolbar">
      <Space>
        <Popover
          content={
            <Space direction="vertical">
              <Button type="text" onClick={() => onExportOrder(EXPORT_ORDER_TYPE.JPEG)}>
                {t("common.image")}
              </Button>
              <Button type="text" onClick={() => onExportOrder("XLSX")}>
                {t("common.excel")}
              </Button>
            </Space>
          }
        >
          <Button type="text" className="button">
            <SvgIcon.ExportFileCartIcon />
            <span>{t("cart.export")}</span>
          </Button>
        </Popover>

        <Button type="text" className="button" onClick={onPrintOrder}>
          <SvgIcon.PrinterIcon />
          <span>{t("order.orderDetail.printOrder")}</span>
        </Button>

        <Tooltip title={t("order.shareOrder")} trigger={"click"} placement="bottom">
          <Button type="text" className="button" onClick={() => copy(shareLink)}>
            <SvgIcon.ShareIcon />
            <span>{t("common.share")}</span>
          </Button>
        </Tooltip>
      </Space>
      <Space>
        <Button type="text" className="button">
          <SvgIcon.HelpCircleIcon />
          <span>{t("cart.help")}</span>
        </Button>
      </Space>
    </Space>
  );
};

export default Toolbar;
