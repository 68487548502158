import { Affix, Col, Form, Row, Space, Spin } from "antd";
import { isEmpty } from "lodash";
import Page403 from "pages/PageError/403";
import { useState } from "react";
import EmptyCart from "../EmptyCart";
import InfoAgent from "../InfoAgent";
import InfoProductAndBill from "../InfoProductAndBill";
import ModalCreateOrder from "../ModalCreateOrder";
import TabsCart from "../TabsCart";
import "./index.scss";
import useList from "./useList";

const List = ({ useInOrder = {} }) => {
  const {
    cartPermissions,
    onChangeTab,
    data,
    loading,
    form,
    dataInfoAgent,
    dataInfoProductAndBill,
    handleUpdate,
    handleUpdateSeller,
    refetchCart,
    handleUpdateItem,
    handleAddNewItems,
    handleRemoveCartItem,
    handleVatAllItems,
    hasCart,
    isEmptyCart,
    carts,
    dataShippingProvider,
    loadingShippingProvider,
  } = useList({ useInOrder });
  const [warehouses, setWarehouses] = useState();

  const onGetWarehouses = (value) => {
    setWarehouses(value);
  };
  const { canCreateCart, canViewCart } = cartPermissions;

  if (!loading && !canViewCart) {
    return <Page403 />;
  } else {
    return (
      <Spin spinning={loading}>
        <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
          {isEmpty(useInOrder) && (
            <TabsCart
              canCreateCart={canCreateCart}
              onChangeTab={onChangeTab}
              hasCart={hasCart}
              carts={carts}
              loadingGetAllCarts={loading}
              warehouses={warehouses}
              onGetWarehouses={onGetWarehouses}
            />
          )}
          {isEmptyCart && isEmpty(useInOrder) ? (
            <EmptyCart />
          ) : (
            <>
              <Form size="middle" colon={false} form={form}>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <InfoAgent
                      data={dataInfoAgent}
                      dataOrder={data}
                      handleUpdate={handleUpdate}
                      handleUpdateSeller={handleUpdateSeller}
                      loadingUpdateCart={loading}
                      refetchCart={refetchCart}
                      useInOrder={useInOrder}
                      dataShippingProvider={dataShippingProvider}
                      form={form}
                      loadingShippingProvider={loadingShippingProvider}
                    />
                  </Col>

                  <Col span={24}>
                    <InfoProductAndBill
                      data={dataInfoProductAndBill}
                      handleUpdate={handleUpdate}
                      refetchCart={refetchCart}
                      handleUpdateItem={handleUpdateItem}
                      handleAddNewItems={handleAddNewItems}
                      handleRemoveCartItem={handleRemoveCartItem}
                      handleVatAllItems={handleVatAllItems}
                      useInOrder={useInOrder}
                    />
                  </Col>
                </Row>
              </Form>
              {isEmpty(useInOrder) && (
                <Affix offsetBottom={10}>
                  <Space
                    style={{
                      background: "#FFFFFF",
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <ModalCreateOrder data={data} form={form} />
                  </Space>
                </Affix>
              )}
            </>
          )}
        </Space>
      </Spin>
    );
  }
};

export default List;
