import React from "react";
import { Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import useSelectOrder from "./useSelectOrder";

import styles from "./index.module.scss";
import { t } from "i18next";

function SelectOrder({
  handleGetOrder,
  sellerInfo,
  paramsOrderSeller,
  setParamsOrderSeller,
  valueSelectOrder,
  setValueSelectOrder,
}) {
  const { onLoadMoreData, hasMoreData, loading, onSearch, handleChange, data } = useSelectOrder({
    handleGetOrder,
    setParamsOrderSeller,
    paramsOrderSeller,
    setValueSelectOrder,
  });
  const options = data?.map((item) => ({
    value: item.id,
    label: (
      <Row gutter={24}>
        <Col span={24}>
          <Typography.Link ellipsis strong style={{ padding: "5px 18px" }}>
            {item.number}
          </Typography.Link>
        </Col>
      </Row>
    ),
    orders: item,
    labelOrder: (
      <Typography.Link ellipsis strong>
        {item?.number}
      </Typography.Link>
    ),
  }));

  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        inputOptions: {
          placeholder: t("warehouse.salesReturn.searchOrder"),
          options: options,
          allowClear: false,
          disabled: !sellerInfo,
          optionLabelProp: "labelOrder",
          value: valueSelectOrder,
          dropdownRender: (originNode) => (
            <>
              <Row gutter={24} className={styles["custom-select"]}>
                <Col span={24}>
                  <Typography strong className={styles["header-select"]} style={{ padding: "12px 30px" }}>
                    {t("warehouse.salesReturn.CODEORDER")}
                  </Typography>
                </Col>
              </Row>
              <Spin spinning={loading}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectOrder;
