import React from "react";
import { Button } from "antd";

function CustomButton({ title, type, isSecondary, ...rest }) {
  return (
    <Button style={isSecondary && { color: "#2246dc", borderColor: "#2246dc" }} type={type} {...rest}>
      {title}
    </Button>
  );
}

export default CustomButton;
