import { t } from "i18next";

import { omit } from "lodash";
import { buildQueryString } from "utils/helperFuncs";

export const UNIT_DATE = {
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
  FOREVER: "FOREVER",
};

export const KEY_TYPE = {
  keyLicense: "KEY_LICENSE",
  account: "ACCOUNT",
};

export const stringKeyType = {
  [KEY_TYPE.keyLicense]: "Key License",
  [KEY_TYPE.account]: "Account",
};

export const KEY_MANAGEMENT = ["KEY_LICENSE", "ACCOUNT"];

export const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

export const convertUnit = (unit) => {
  switch (unit) {
    case UNIT_DATE.DAY:
      return t("v2.keyManagement.day");
    case UNIT_DATE.MONTH:
      return t("v2.keyManagement.month");
    case UNIT_DATE.YEAR:
      return t("v2.keyManagement.year");
    case UNIT_DATE.FOREVER:
      return t("v2.keyManagement.forever");
    default:
      return "--";
  }
};

export const convertDateWithDateUnit = (date, dateUnit) => {
  let dateUnitTran = convertUnit(dateUnit);
  return dateUnit === UNIT_DATE.FOREVER ? dateUnitTran : date ? `${date} ${dateUnitTran}` : "--";
};

export const STATUS_KEY_LIST = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
  DISABLED: "DISABLED",
};

export const statusKeyListLabel = {
  [STATUS_KEY_LIST.AVAILABLE]: t("v2.keyManagement.available"),
  [STATUS_KEY_LIST.UNAVAILABLE]: t("v2.keyManagement.unavailable"),
  [STATUS_KEY_LIST.DISABLED]: t("v2.keyManagement.disable"),
  [undefined || null]: "--",
};

export const statusKeyListColor = {
  [STATUS_KEY_LIST.AVAILABLE]: "green",
  [STATUS_KEY_LIST.UNAVAILABLE]: "orange",
  [STATUS_KEY_LIST.DISABLED]: "red",
};

export const statusKeyTagColor = {
  [STATUS_KEY_LIST.AVAILABLE]: "#EDF9F0",
  [STATUS_KEY_LIST.UNAVAILABLE]: "#FFF4EC",
  [STATUS_KEY_LIST.DISABLED]: "#FEEFEF",
};

export const statusKeyLabelColor = {
  [STATUS_KEY_LIST.AVAILABLE]: "#00AB78",
  [STATUS_KEY_LIST.UNAVAILABLE]: "#EF9351",
  [STATUS_KEY_LIST.DISABLED]: "#D2434D",
};

export const statusKeyListOptions = [
  {
    label: t("v2.keyManagement.available"),
    value: STATUS_KEY_LIST.AVAILABLE,
  },
  {
    label: t("v2.keyManagement.unavailable"),
    value: STATUS_KEY_LIST.UNAVAILABLE,
  },
  {
    label: t("v2.keyManagement.disable"),
    value: STATUS_KEY_LIST.DISABLED,
  },
];

export const formatBuildQueryString = (values) => {
  buildQueryString({
    params: {
      ...omit(values?.filters, "timeRange"),
      ...values?.pagination,
      from: values?.filters?.timeRange?.from,
      to: values?.filters?.timeRange?.to,
    },
  });
};

export function hideChars(str) {
  if (str?.length < 3) return str;
  let lastThreeChars = str?.slice(-3);
  return "****************" + lastThreeChars;
}

export const TABS_SUGGESTED_ENTRY = {
  ALL: "ALL",
  WAITING_SEND_CUSTOMER: "WAITING_SEND_CUSTOMER",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const PRIORITY = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const priorityLabel = {
  [PRIORITY.LOW]: "Thấp",
  [PRIORITY.MEDIUM]: "Bình thường",
  [PRIORITY.HIGH]: "Cao",
  undefined: "--",
};

export const priorityOptions = [
  {
    label: "Thấp",
    value: PRIORITY.LOW,
  },
  {
    label: "Bình thường",
    value: PRIORITY.MEDIUM,
  },
  {
    label: "Cao",
    value: PRIORITY.HIGH,
  },
];

export const priorityColor = {
  [PRIORITY.MEDIUM]: "#00ab78",
  [PRIORITY.HIGH]: "#df3030",
};

export const DIGITAL_STATUS = {
  WAITING_SEND_CUSTOMER: "WAITING_SEND_CUSTOMER",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const digitalStatusLabel = {
  [DIGITAL_STATUS.WAITING_SEND_CUSTOMER]: "Chờ gửi khách",
  [DIGITAL_STATUS.COMPLETED]: "Đã gửi khách",
  [DIGITAL_STATUS.CANCELED]: "Đã huỷ",
  undefined: "--",
};

export const digitalStatusOptions = [
  {
    label: "Chờ gửi khách",
    value: DIGITAL_STATUS.WAITING_SEND_CUSTOMER,
  },
  {
    label: "Đã gửi khách",
    value: DIGITAL_STATUS.COMPLETED,
  },
  {
    label: "Đã huỷ",
    value: DIGITAL_STATUS.CANCELED,
  },
];

export const digitalStatusColor = {
  [DIGITAL_STATUS.WAITING_SEND_CUSTOMER]: "rgba(239, 147, 81, 1)",
  [DIGITAL_STATUS.COMPLETED]: "rgba(0, 171, 120, 1)",
  [DIGITAL_STATUS.CANCELED]: "rgba(210, 67, 77, 1)",
};

export const STATUS = {
  WAITING_FOR_EXPORTING: "WAITING_FOR_EXPORTING",
  WAITING_FOR_ASSEMBLY: "WAITING_FOR_ASSEMBLY",
  WAITING_FOR_SHIPPING: "WAITING_FOR_SHIPPING",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const statusLabel = {
  [STATUS.WAITING_FOR_EXPORTING]: "Chờ xuất kho",
  [STATUS.WAITING_FOR_ASSEMBLY]: "Chờ lắp đặt",
  [STATUS.WAITING_FOR_SHIPPING]: "Chờ giao hàng",
  [STATUS.DELIVERING]: "Đang giao",
  [STATUS.COMPLETED]: "Hoàn thành",
  [STATUS.CANCELED]: "Đã huỷ",
};

export const statusOptions = [
  {
    label: "Chờ xuất kho",
    value: STATUS.WAITING_FOR_EXPORTING,
  },
  {
    label: "Chờ lắp đặt",
    value: STATUS.WAITING_FOR_ASSEMBLY,
  },
  {
    label: "Chờ giao hàng",
    value: STATUS.WAITING_FOR_SHIPPING,
  },
  {
    label: "Đang giao",
    value: STATUS.DELIVERING,
  },
  {
    label: "Hoàn thành",
    value: STATUS.COMPLETED,
  },
  {
    label: "Đã huỷ",
    value: STATUS.CANCELED,
  },
];

export const ERROR_TYPE = {
  NO_ERROR: "NO_ERROR",
  WRONG_FORMAT: "WRONG_FORMAT",
  LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
};
