import React from "react";
import Info from "./components/Info";
import ActivityTabs from "./components/ActivityTabs";
import useDetail from "./useDetail";
import "./index.scss";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import Spinner from "components/Spinner";

function AgentDetail() {
  const { sellerDetail, loading, refetch } = useDetail();

  return (
    <div className="wrapper">
      <Spinner loading={loading} />
      <PageHeader
        pageTitle={t("seller.activitylogtabs.detailInfo")}
        routes={[
          {
            path: "/setting",
            name: t("seller.systemSetting"),
          },
          {
            path: "/seller",
            name: t("seller.sellerAccManage"),
          },
          {
            path: "",
            name: t("seller.activitylogtabs.detailInfo"),
          },
        ]}
      />

      <Info dataDetail={sellerDetail} refetch={refetch} />
      <ActivityTabs supplierDetail={sellerDetail} />
    </div>
  );
}

export default AgentDetail;
