import { Col, Row, Spin, Tooltip, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import useSelectProduct from "./useSelectProduct";

import styles from "./index.module.scss";

function SelectProduct({ handleGetProduct, record }) {
  const { onLoadMoreData, hasMoreData, loading, onSearch, handleChange, data } = useSelectProduct({
    handleGetProduct,
  });

  const defaultEditProduct = { id: record?.productID, code: record?.product?.code, name: record?.product?.name };
  const uniqueArray = Array.from(new Map([defaultEditProduct, ...data]?.map((item) => [item.id, item])).values());
  const options = uniqueArray?.map((item) => ({
    value: item.id,
    label: (
      <Row gutter={24}>
        <Col span={8}>
          <Typography.Text ellipsis={true}>{item.code}</Typography.Text>
        </Col>
        <Col span={16}>
          <Tooltip title={item.name}>
            <Typography.Text ellipsis style={{ color: "#2246DC" }}>
              {item.name}
            </Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    product: item,
    labelProduct: item?.code,
  }));

  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        formItemOptions: {
          name: "productID",
          rules: [
            {
              required: true,
              message: t("serialIE.error.product"),
            },
          ],
          label: "Mã sản phẩm",
          className: "form-order-select",
        },
        inputOptions: {
          placeholder: t("serialIE.placeholder.searchByProduct"),
          options: options,
          value: null,
          allowClear: false,
          optionLabelProp: "labelProduct",
          dropdownMatchSelectWidth: 500,
          dropdownRender: (originNode) => (
            <>
              <Row gutter={24} className={styles["custom-select"]}>
                <Col span={8}>
                  <Typography className={styles["header-select"]} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.code")}
                  </Typography>
                </Col>
                <Col span={16}>
                  <Typography className={styles["header-select"]} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.name")}
                  </Typography>
                </Col>
              </Row>
              <Spin spinning={loading}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default SelectProduct;
