import { Checkbox, Space, Table, Typography } from "antd";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomTableSelectRow from "components/CustomTableSelectRow";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import {
  CURRENCY_INPUT,
  PURCHASE_TYPE_OPTIONS,
  dataPeriodUnitLabel,
  purchaseRequestTypeLabel,
} from "features/Purchase/constant";
import { t } from "i18next";
import { Link, useHistory } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import Toolbar from "../Toolbar";
import useAccordingProposedPurchase from "./useAccordingProposedPurchase";

function AccordingProposedPurchase() {
  const history = useHistory();

  const {
    selectedHighlight,
    isTableSelectedRow,
    sizeScrollPageAll,
    selectedRow,
    loadingProposedPurchaseList,
    paginationData,
    hasMoreSeller,
    loadingSeller,
    optionsSeller,
    warehouseOptions,
    proposedPurchaseList,
    params,
    onLoadMoreSeller,
    onFilter,
    handleSearch,
    handleSearchSellerLoadMore,
    handleChangeTable,
    handleChangeParams,
    handleRowClick,
    handleToggleRows,
  } = useAccordingProposedPurchase();

  const columns = [
    {
      title: t("purchase.table.stt"),
      render: (_, record, index) => index + 1,
      align: "center",
      width: 60,
    },
    {
      title: t("purchase.table.createdAt"),
      render: (_, record) => formatDateTime(record.createdAt),
      align: "center",
      width: 100,
      sorter: true,
    },
    {
      title: t("purchase.table.purchaseOfferCode"),
      width: 200,
      render: (_, record) => (
        <Link to={`/import-request/detail/${record.id}`}>
          <Typography.Link strong>{record?.code}</Typography.Link>
        </Link>
      ),
    },
    {
      title: t("purchase.table.recommendedSupplier"),
      width: 350,
      render: (_, record) => <CustomTextHiddenLine text={record?.supplier?.fullName} line={2} />,
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilter("supplierIDs", value)}
          data={optionsSeller}
          onLoadMoreData={onLoadMoreSeller}
          hasMore={hasMoreSeller}
          onSearchTextChange={handleSearchSellerLoadMore}
          loading={loadingSeller}
        />
      ),
    },
    {
      title: t("purchase.table.totalAmount"),
      align: "right",
      width: 200,
      render: (_, record) => {
        let sumPrice = 0;
        for (let i = 0; i < record?.items?.length; i++) {
          const { quantity, price } = record?.items[i];
          sumPrice += quantity * price;
        }
        return record?.items && record?.items[0]?.currency === CURRENCY_INPUT.VND
          ? formatCurrency(sumPrice)
          : formatCurrencyDollar(sumPrice);
      },
    },
    {
      title: t("purchase.table.wareHouseImport"),
      width: 100,
      render: (_, record) => record?.warehouse?.name,
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("warehouseIDs", value)}
          options={warehouseOptions}
          type="checkbox"
          searchable
        />
      ),
    },
    {
      title: t("purchase.table.recommendedType"),
      width: 120,
      render: (_, record) => purchaseRequestTypeLabel[record?.type],
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => onFilter("typesProposed", value)}
          options={PURCHASE_TYPE_OPTIONS}
          type="checkbox"
          searchable
        />
      ),
    },
    {
      title: t("purchase.table.actions"),
      width: 150,
      align: "center",
      render: (_, record) => {
        return (
          <PrimaryButtonOutLine
            title={t("purchase.createPurchaseOrder")}
            onClick={() => history.push({ pathname: "/purchase/create", state: record })}
          />
        );
      },
    },
  ];

  const columnTableSelected = [
    {
      title: t("purchase.table.stt"),
      render: (_, record, index) => index + 1,
      align: "center",
      width: 60,
      fixed: true,
    },
    {
      title: t("purchase.table.parameter"),
      fixed: true,
      render: (_, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Link strong>
              <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
                <Typography.Link strong>{record?.product?.code}</Typography.Link>
              </Link>
            </Typography.Link>
            <CustomTextHiddenLine line={2} text={record?.product?.name || record?.productName || "--"} />
          </div>
        );
      },
      width: 300,
    },
    {
      title: t("purchase.table.quantity"),
      align: "center",
      width: 100,
      render: (_, record) => record?.quantity,
    },
    {
      title: t("purchase.table.unitPrice"),
      width: 150,
      align: "right",
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.price)
            : formatCurrencyDollar(record?.price)}
        </Typography.Text>
      ),
    },
    {
      title: t("purchase.table.intoMoney"),
      width: 150,
      align: "right",
      render: (_, record, index) =>
        record?.currency === CURRENCY_INPUT.VND
          ? formatCurrency(record?.price * record?.quantity)
          : formatCurrencyDollar(record?.price * record?.quantity),
    },
    {
      title: t("purchase.table.vat"),
      width: 60,
      align: "center",
      render: (_, record) => <Checkbox disabled checked={record?.isVat} />,
    },
    {
      title: t("purchase.table.warrantyPeriod"),
      align: "center",
      width: 150,
      render: (_, record, index) => (
        <Space direction="horizontal">
          <Typography.Text>{record?.warranty}</Typography.Text>
          <Typography.Text>{dataPeriodUnitLabel?.[record?.warrantyPeriod]}</Typography.Text>
        </Space>
      ),
    },
    {
      title: t("purchase.table.numberDaysDebt"),
      align: "center",
      width: 150,
      render: (_, record, index) => record?.creditDays || "--",
    },
    {
      title: t("purchase.table.expectedDelivery"),
      align: "center",
      width: 200,
      render: (_, record, index) =>
        (record?.expectedDeliveryTime && formatDateTime(record?.expectedDeliveryTime)) || "--",
    },
    {
      title: t("purchase.table.numberOfPayments"),
      align: "center",
      render: (_, record, index) => record?.paymentNote || "--",
      width: 200,
    },
  ];

  const renderSummaryTableSelected = (pageData) => {
    var sumPrice = pageData?.reduce(function (total, current) {
      return total + current?.price * current?.quantity;
    }, 0);

    const stylesSummary = {
      color: "#052987",
      textAlign: "center",
      fontWeight: "bold",
    };

    const colorTotals = { color: "#052987" };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.totalMoney")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={3}>
            <Typography.Text style={colorTotals}>
              {" "}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography.Text style={colorTotals}>
                  {selectedRow?.items[0]?.currency === CURRENCY_INPUT.VND
                    ? formatCurrency(sumPrice)
                    : formatCurrencyDollar(sumPrice)}
                </Typography.Text>
              </div>
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={5}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div className={"wrapperProposedPurchase"}>
      <Toolbar
        placeholder={t("purchase.placeHolder.searchByCodeProposedPurchase")}
        titleFilterData={t("purchase.table.createdAt")}
        titleButton={t("purchase.createPurchaseOrder")}
        showButton={true}
        onSearch={handleSearch}
        onChangeParams={handleChangeParams}
        params={params}
        optionsSeller={optionsSeller}
        warehouseOptions={warehouseOptions}
        onClick={() => history.push("/purchase/create")}
      />
      <CustomTableSelectRow
        columns={columns}
        data={proposedPurchaseList}
        loading={loadingProposedPurchaseList}
        onChangeTable={handleChangeTable}
        selectedHighlight={selectedHighlight}
        scroll={sizeScrollPageAll}
        onClickRow={handleRowClick}
        pagination={{
          total: paginationData?.total,
          pageSize: paginationData?.limit,
          current: paginationData?.offset / paginationData?.limit + 1 || 1,
          showSizeChanger: false,
        }}
        onToggleArrow={handleToggleRows}
        isTableSelectedRow={isTableSelectedRow}
        summaryTableSelected={renderSummaryTableSelected}
        columnTableSelected={columnTableSelected}
        dataTableSelected={selectedRow?.items}
        titleCardTableSelected={
          <Space direction="horizontal">
            <Typography.Text strong>{t("purchase.table.listOfProductBy")}</Typography.Text>
            <Typography.Link strong>{selectedRow?.code}</Typography.Link>
          </Space>
        }
      />
    </div>
  );
}

export default AccordingProposedPurchase;
