import { SORT_TYPES } from "features/Serial/constant";
import { useGetBranchQuery } from "hooks/branch";
import { useGetExportNotesSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { isNil } from "lodash";
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";

const useList = () => {
  const GET_QUERY = getQuery();
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branchesIDParam = searchParams.get("branchesID");
  const [branchIdUser, setBranchIdUser] = useState(branchIdByUser || "");
  const [branchesID, setBranchesId] = useState(branchesIDParam || branchIdUser);

  const [params, setParams] = useState();

  const { data, paginationData, loading } = useGetExportNotesSerial(params);
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData, loading: loadingStaff } = useGetStaff(paramsStaff);
  const { warehouses } = useWarehouse(paramsStaff);

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const updateParams = useCallback(
    (newParams) => {
      setParams((prevParams) => ({ ...prevParams, ...newParams }));
      // buildQueryString({
      //   params: {
      //     ...newParams?.filters,
      //     ...newParams?.pagination,
      //     activeKey: newParams?.activeKey || GET_QUERY.activeKey,
      //     key: newParams?.key || GET_QUERY.key,
      //   },
      // });
    },
    [GET_QUERY]
  );

  const onChangeBranchesId = (e) => {
    setBranchesId(e);
    updateParams({ ...params, filters: { ...params?.filters, branchesID: e }, pagination: { offset: 0, limit: 10 } });
  };

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleFilter = (value) => {
    updateParams({
      ...params,
      filters: { ...params?.filters, ...value, branchesID },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleRemoveFilterTag = (key) => {
    updateParams({ ...params, filters: omit(params?.filters, [key]), pagination: { offset: 0, limit: 10 } });
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeDate = (value) => {
    const { from, to } = value;
    updateParams({
      ...params,
      filters: { ...params?.filters, timeRange: { from, to } },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    updateParams({
      ...params,
      sort: sorter?.order ? [{ by: "ID", sortType: SORT_TYPES[sorter?.order] }] : [],
    });
  };

  const handlePrevNextPage = (offset) => {
    updateParams({ ...params, pagination: { offset, limit: 10 } });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      updateParams({
        ...params,
        filters: { ...params?.filters, query: debouncedValue?.trim() },
        pagination: { offset: 0, limit: 10 },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    setBranchIdUser(branchIdByUser);
    updateParams({
      ...params,
      filters: { ...params?.filters, branchesID: branchIdByUser || "" },
      sort: [{ by: "ID", sortType: "DESC" }],
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchesId(branchesIDParam || branchIdByUser);
  }, [branchesIDParam, branchIdByUser]);

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: "listByExportSerial",
      },
    });
  }, [params]);

  return {
    valueFormSelectCity,
    loading,
    data,
    branchesID,
    params,
    staffs,
    paginationData,
    warehouseOptions,
    onChangeBranchesId,
    onSearch,
    handleChangeTable,
    handleRemoveFilterTag,
    handleFilter,
    handlePrevNextPage,
    propsFilterCreated: {
      staffs,
      hasMoreData,
      onLoadMoreData,
      handleSearchStaffs,
      loading: loadingStaff,
    },
    handleChangeDate,
  };
};

export default useList;
