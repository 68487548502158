import React, { useState } from "react";
import {
  useComplete,
  useConfirmCheck,
  useConfirmDistribute,
  useConfirmReturn,
  useConfirmSolution,
} from "hooks/warranty";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";

function useButtonGroup({ itemID, itemIDs, setSelectedRowKeys, selectedRows }) {
  const { handleConfirmCheck, loading: loadingConfirmCheck } = useConfirmCheck();
  const { handleConfirmSolution, loading: loadingConfirmSolution } = useConfirmSolution();
  const { handleConfirmDistribute, loading: loadingConfirmDistribute } = useConfirmDistribute();
  const { handleComplete, loading: loadingComplete } = useComplete();
  const { handleConfirmReturn, loading: loadingConfirmReturn } = useConfirmReturn();

  const [switchClose, setSwitchClose] = useState(false);

  const handleOpenModal = () => {
    setSwitchClose(true);
  };

  const handleCancelModal = () => {
    setSwitchClose(false);
  };

  const confirmCheck = async () => {
    await handleConfirmCheck({ warrantyRequestItemIDs: itemIDs })
      .then(() => {
        notify.success({ message: t("notiActions.success") });
        setSelectedRowKeys([]);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const confirmSolution = async ({ solution, solutionRequest }) => {
    await handleConfirmSolution({
      warrantyRequestItemIDs: itemIDs,
      solution: solution,
      solutionRequest: solutionRequest,
    })
      .then(() => {
        notify.success({ message: t("notiActions.chooseProcess") });
        setSelectedRowKeys([]);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const confirmDistribute = async ({ warrantyRequestItemIDs, assignee, distributeRequest }) => {
    await handleConfirmDistribute({
      warrantyRequestItemIDs: warrantyRequestItemIDs,
      assignee: assignee,
      distributeRequest: distributeRequest,
    })
      .then(() => {
        notify.success({ message: t("notiActions.distribute") });
        setSelectedRowKeys([]);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const complete = async ({ itemID, deliverFileURLs }) => {
    await handleComplete({
      warrantyRequestItemID: itemID,
      deliverFileURLs: deliverFileURLs,
    })
      .then(() => {
        notify.success({ message: t("notiActions.completed") });
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const confirmReturn = async (params) => {
    await handleConfirmReturn(params)
      .then(() => {
        notify.success({ message: t("notiActions.return") });
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  return {
    loadingConfirmCheck,
    loadingConfirmSolution,
    loadingConfirmDistribute,
    loadingComplete,
    loadingConfirmReturn,
    confirmCheck,
    confirmSolution,
    confirmDistribute,
    complete,
    confirmReturn,
    switchClose,
    handleOpenModal,
    handleCancelModal,
  };
}

export default useButtonGroup;
