/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Card, Col, Row, Space, Spin, Typography } from "antd";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import PageHeader from "components/PageHeader";
import { PURCHASE_STATUS, purchaseRequestTypeLabel, renderCauseReject } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { ButtonAccept } from "../ButtonGroupStatus/ButtonAccept";
import { ButtonApprovePayment } from "../ButtonGroupStatus/ButtonApprovePayment";
import { ButtonCancel } from "../ButtonGroupStatus/ButtonCancel";
import { ButtonCheckInfoPurchaseOrder } from "../ButtonGroupStatus/ButtonCheckInfoPurchaseOrder";
import { ButtonConfirmDelivery } from "../ButtonGroupStatus/ButtonConfirmDelivery";
import { ButtonConfirmDeposit } from "../ButtonGroupStatus/ButtonConfirmDeposit";
import { ButtonImportSerial } from "../ButtonGroupStatus/ButtonImportSerial";
import { ButtonReject } from "../ButtonGroupStatus/ButtonReject";
import { ButtonSplitOrder } from "../ButtonGroupStatus/ButtonSplitOrder";
import { ButtonUpVoucher } from "../ButtonGroupStatus/ButtonUpVoucher";
import { ButtonUpVoucherAndPrint } from "../ButtonGroupStatus/ButtonUpVoucherAndPrint";
import AddInfo from "./components/AddInfo";
import AlertReason from "./components/AlertReason";
import PaymentInfo from "./components/PaymentInfo";
import { PopupDetailReason } from "./components/PopupDetailReason";
import ProductsListLoadMore from "./components/ProductsListLoadMore";
import SupplierInfo from "./components/SupplierInfo";
import { TitleCard } from "./components/TitleCard";
import "./index.scss";
import usePurchaseDetail from "./usePurchaseDetail";

function PurchaseDetail() {
  const { dataDetail, loadingDetail, dataReferences, loadingReferences } = usePurchaseDetail();
  return (
    <Spin spinning={loadingDetail || loadingReferences}>
      <div className="purchase-order-detail-page">
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("purchase.detail.detailPurchase")}
              routes={[
                { path: "#", name: t("purchase.titleMenu") },
                { path: "/purchase-order", name: t("purchase.purchaseOrder") },
                { path: "/", name: t("purchase.detail.detailPurchase") },
              ]}
            />
          </Col>

          <Col span={24} style={{ fontSize: "13px" }}>
            <Row gutter={[16]}>
              <Col span={6}>
                <Row>
                  <Col span={6}>
                    <Typography.Text strong>{t("purchase.recommendedType")}</Typography.Text>
                  </Col>
                  <Col span={18}>{purchaseRequestTypeLabel[dataDetail?.requestType]}</Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Typography.Text strong>{t("purchase.importWarehouse")}</Typography.Text>
                  </Col>
                  <Col span={18}>{dataDetail?.warehouse?.name}</Col>
                </Row>
              </Col>
              <Col span={11}></Col>
              <Col span={7} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space style={{ background: "transparent", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("warehouse.salesReturn.dayCreate")}: {formatDateTime(dataDetail?.createdAt) || "--"}
                        &nbsp;{t("common.by")} {dataDetail?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("warehouse.salesReturn.dayUpdate")}: {formatDateTime(dataDetail?.updatedAt) || "--"}&nbsp;
                        {t("common.by")}{" "}
                        {dataDetail?.updatedBy
                          ? dataDetail?.updatedBy?.fullname
                          : dataDetail?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card
              title={
                <TitleCard
                  value={dataDetail?.code}
                  dataReferences={dataReferences}
                  status={dataDetail?.status}
                  dataDetail={dataDetail}
                />
              }
              className="card-parent"
            >
              <Col span={24}>
                {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING && (
                  <>
                    <AlertReason
                      title="Đơn mua hàng bị từ chối nhập kho vì"
                      reason={dataDetail?.deniedReason?.causes?.map((value) => renderCauseReject[value])?.join(", ")}
                    />
                    <AlertReason title="Kỹ thuật từ chối với lý do" reason={dataDetail?.deniedReason?.reason} />
                  </>
                )}
                {dataDetail?.status === PURCHASE_STATUS.CANCELED && (
                  <AlertReason
                    title="Đơn mua hàng đã bị hủy với lý do"
                    reason={
                      dataDetail?.deniedReason?.causes?.map((item) => renderCauseReject[item])?.join(", ") +
                      (dataDetail?.deniedReason?.reason != null ? ", " + dataDetail?.deniedReason?.reason : "")
                    }
                  />
                )}
              </Col>
              <Col span={24}>
                <Row style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                  <Col style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "16px" }}>
                    {dataDetail?.deniedReason?.files?.length > 0 &&
                      dataDetail?.deniedReason?.files?.map((fileURL, index) => {
                        return <CustomViewDocumentFiles file={fileURL} key={index} />;
                      })}
                  </Col>
                  <Col>
                    {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING && (
                      <PopupDetailReason data={dataDetail} />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 8]} style={{ flexGrow: 1 }}>
                  <Col span={10} className="card-container-info">
                    <SupplierInfo dataSupplier={dataDetail?.seller} />
                  </Col>
                  <Col span={8} className="card-container-info">
                    <PaymentInfo data={dataDetail} />
                  </Col>
                  <Col span={6} className="card-container-info">
                    <AddInfo data={dataDetail} />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <ProductsListLoadMore data={dataDetail} />
              </Col>
            </Card>
          </Col>
          <Col span={24}>
            <Card className="form-footer">
              <ButtonCancel record={dataDetail} />
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_CHECKING_QUALITY && (
                <ButtonReject record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_SAVE_DOCUMENT && <ButtonUpVoucher record={dataDetail} />}
              <ButtonUpVoucherAndPrint record={dataDetail} />
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_PAYMENT && (
                <ButtonApprovePayment record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_CHECKING_QUALITY && (
                <ButtonAccept record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_DEPOSIT && (
                <ButtonConfirmDeposit record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_RECEIVING && (
                <ButtonConfirmDelivery record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_CHECKING_INFO && (
                <ButtonCheckInfoPurchaseOrder record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING && <ButtonSplitOrder record={dataDetail} />}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_IMPORT_SERIAL && (
                <ButtonImportSerial record={dataDetail} />
              )}
              {dataDetail?.status === PURCHASE_STATUS.WAITING_FOR_HANDLING && (
                <Link to={`/purchase-order/edit/${dataDetail?.id}`}>
                  <Button type="primary">Chỉnh sửa</Button>
                </Link>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default PurchaseDetail;
