import { Typography } from "antd";
import BadgeStatusDot from "components/_storybooks/BadgeStatusDotCustom";
import EllipsisText from "components/_storybooks/EllipsisText";
import { FilterLoadMore, FilterProductIDs, FilterSellerIDs } from "components/_storybooks/FilterLoadMore";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import PopupDetailSerial from "features/Serial/components/PopupDetailSerial";
import { importTypeSerialConverter, VALUE_TAGS_IMPORT_NOTE_TYPE } from "features/Serial/constant";
import { useGetUserCurrent } from "hooks/auth/auth";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { getQuery, useBreakpointPage } from "utils/helperFuncs";

function TablesList({ params, handleFilter, propsFilterCreated }) {
  const GET_QUERY = getQuery();
  const { userCurrentData } = useGetUserCurrent();
  const { lg, xl, xxl } = useBreakpointPage();
  const { warehouses } = useWarehouse();
  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const isListBySerial = GET_QUERY.activeKey === "listBySerial";

  const columns = {
    stt: {
      title: "STT",
      align: "center",
      width: 50,
      fixed: xxl || xl || lg ? "left" : undefined,
      render: (_, record, index) => index + 1,
    },
    //Ngay nhap kho
    importWarehouseDate: {
      fixed: xxl || xl || lg ? "left" : undefined,
      title: t("serialIE.dateImportSerial"),
      width: GET_QUERY.activeKey === "listBySerial" ? 150 : 100,
      align: "center",
      sorter: true,
      render: (_, record) =>
        formatDateTime(isListBySerial ? record?.serialImportNoteItem?.serialImportNote?.createdAt : record?.createdAt),
    },
    // MÃ PHIẾU NHẬP SERIAL
    serialImportCode: {
      fixed: xxl || xl || lg ? "left" : undefined,
      width: 150,
      title: t("serialIE.codeImportSerial"),
      render: (_, record) => (
        <Link to={`/import-serial/detail/${record?.id}`}>
          <b>{record?.code}</b>
        </Link>
      ),
    },
    // NHÀ CUNG CẤP/ ĐẠI LÝ
    providerAgent: {
      title: t("serialIE.provider"),
      width: 300,
      render: (_, record) => {
        const providerFromByImportSerial = record?.provider || record?.seller;
        const providerFromBySerial =
          record?.serialImportNoteItem?.serialImportNote?.provider ||
          record?.serialImportNoteItem?.serialImportNote?.seller;

        const provider = isListBySerial ? providerFromBySerial : providerFromByImportSerial;
        return (
          <SpaceCustom>
            {provider?.id && (
              <Typography.Link target="_blank" strong href={`/seller/detail/${provider?.id}`}>
                {provider?.idNumber || provider?.code}
              </Typography.Link>
            )}
            <EllipsisText title={provider?.fullName} />
          </SpaceCustom>
        );
      },
      filterDropdown: <FilterSellerIDs onFilter={(value, option) => handleFilter({ sellerIDs: value })} />,
    },
    // Tổng số mã sản phẩm
    categoriesTotal: {
      title: "Tổng số mã sản phẩm",
      width: 80,
      align: "center",
      render: (_, record) => record?.quantityKindOfProduct,
    },
    // Tổng số serial/imei
    serialImeiQty: {
      title: "Tổng số serial/imei",
      width: 100,
      align: "center",
      render: (_, record) => record?.quantitySerial,
    },
    // NHÂN VIÊN PHỤ TRÁCH
    staffInCharge: {
      title: t("serialIE.staffInCharge"),
      align: "left",
      width: 220,
      render: (_, record) => {
        const staffInCharge = isListBySerial
          ? record?.serialImportNoteItem?.serialImportNote?.createdBy
          : record?.createdBy;
        const me = userCurrentData?.id === staffInCharge?.id ? `(tôi)` : "";
        return <EllipsisText title={staffInCharge?.fullname ? `${staffInCharge?.fullname} ${me}` : "--"} />;
      },
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={propsFilterCreated?.staffs}
          onLoadMoreData={propsFilterCreated?.onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearch={propsFilterCreated?.handleSearchStaffs}
          hasMore={propsFilterCreated?.hasMoreData}
          loading={propsFilterCreated?.loading}
        />
      ),
    },
    // Hình thức nhập
    inputType: {
      title: t("serialIE.operation"),
      fixed: xxl || xl || lg ? "right" : undefined,
      width: 200,
      render: (_, record, index) => {
        const type = isListBySerial ? record?.serialImportNoteItem?.serialImportNote?.type : record?.type;
        return (
          <BadgeStatusDot
            status={importTypeSerialConverter(type)?.status}
            text={importTypeSerialConverter(type)?.text}
          />
        );
      },
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ serialImportNoteType: value })}
          type="checkbox"
          options={VALUE_TAGS_IMPORT_NOTE_TYPE}
        />
      ),
    },
    // SỐ SERIAL/IMEI
    serialImeiCode: {
      fixed: xxl || xl || lg ? "left" : undefined,
      title: t("serialIE.NUMBER_SERIAL_IMEI"),
      render: (_, record) => <PopupDetailSerial button={record?.serial?.code} id={record?.serialID} />,
    },
    // Thông số
    parameter: {
      width: 250,
      title: "Thông số",
      render: (_, record) => (
        <SpaceCustom>
          <Link target="_blank" to={`/product/detail/${record?.serial?.product?.id}`}>
            <b>{record?.serial?.product?.code}</b>
          </Link>
          <EllipsisText line={2} title={record?.serial?.product?.name} />
        </SpaceCustom>
      ),
      filterDropdown: (
        <FilterProductIDs params={params} onFilter={(value, option) => handleFilter({ productIDs: value })} />
      ),
    },
    // Mã phiếu nhập serial
    fromImportSerialCode: {
      title: "Mã phiếu nhập serial",
      render: (_, record) => (
        <Link target="_blank" to={`/import-serial/detail/${record?.serialImportNoteItem?.serialImportNote?.id}`}>
          <Typography.Link strong>{record?.serialImportNoteItem?.serialImportNote?.code}</Typography.Link>
        </Link>
      ),
    },
    // KHO NHẬP
    serialImportWarehouse: {
      title: "Kho nhập",
      width: 200,
      render: (_, record) => record?.warehouse?.name,
      filterDropdown: (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ warehouseIDs: value })}
          type="checkbox"
          options={warehouseOptions}
        />
      ),
    },
  };

  const {
    stt,
    importWarehouseDate,
    serialImportCode,
    providerAgent,
    categoriesTotal,
    serialImeiQty,
    staffInCharge,
    inputType,
    serialImeiCode,
    parameter,
    fromImportSerialCode,
    serialImportWarehouse,
  } = columns;

  const BY_SERIAL_COLUMN = [
    stt,
    importWarehouseDate,
    serialImeiCode,
    parameter,
    providerAgent,
    fromImportSerialCode,
    serialImportWarehouse,
    staffInCharge,
    inputType,
  ];
  const BY_IMPORT_SERIAL_COLUMN = [
    stt,
    importWarehouseDate,
    serialImportCode,
    providerAgent,
    categoriesTotal,
    serialImeiQty,
    staffInCharge,
    inputType,
  ];

  return {
    BY_IMPORT_SERIAL_COLUMN,
    BY_SERIAL_COLUMN,
  };
}

export default TablesList;
