import { Dropdown, Menu, Space, Tooltip, Typography, message } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { PrimaryButton } from "components/PrimaryButton";
import { ERROR_TYPE, KEY_TYPE, stringKeyType } from "features/KeyManagement/constants";
import { useUploadFileDigitalProduct } from "hooks/keyManagement/mutation";
import { useGetSampleFileKey } from "hooks/keyManagement/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";

export const CustomUploadFile = () => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [dataFile, setDataFile] = useState(null);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [typeUpload, setTypeUpload] = useState();
  const [errorType, setErrorType] = useState(ERROR_TYPE.NO_ERROR);
  const [fileErr, setFileErr] = useState();
  const [loading, setLoading] = useState();

  const props = {
    name: "file",
    multiple: true,
    async onChange(info) {
      if (info.file.size > 512000) {
        message.warning({
          content: t("v2.keyManagement.upload_file.download_file_size_large"),
        });
      } else {
        setFileList(info.fileList);
        setDataFile(info.file);
      }
    },
  };

  const handleOpenUpload = (keyType) => {
    setVisibleUpload(true);
    setTypeUpload(keyType);
  };

  const handleCloseUpload = () => {
    setVisibleUpload(false);
    setTypeUpload(null);
    setErrorType(ERROR_TYPE.NO_ERROR);
    setFileList([]);
    setDataFile(null);
    setFileErr(null);
  };

  const handleDownloadAnotherFile = () => {
    setErrorType(ERROR_TYPE.NO_ERROR);
    setFileList([]);
    setDataFile(null);
    setFileErr(null);
  };

  const { handleUploadFileDigitalProduct, loading: loadingUpload } = useUploadFileDigitalProduct();

  const handleUploadFile = async () => {
    try {
      const params = {
        file: dataFile,
        productType: typeUpload,
      };

      if (dataFile != null) {
        await handleUploadFileDigitalProduct(params)
          .then((res) => {
            var dataCreateByExcel = res?.data.product.createByExcel;
            if (dataCreateByExcel.status === true && dataCreateByExcel.errorType === ERROR_TYPE.NO_ERROR) {
              notify.success({
                message: t("v2.keyManagement.upload_file.upload_file_excel_success"),
              });
              setTypeUpload(null);
              setVisibleUpload(false);
            } else {
              setErrorType(dataCreateByExcel.errorType);
              setFileErr(dataCreateByExcel.file);
            }
          })
          .catch((err) => {
            notify.error({
              message: err.message,
            });
          });
      } else {
        notify.warning({
          message: t("v2.keyManagement.upload_file.download_file_failed"),
          description: t("v2.keyManagement.upload_file.select_file_before_confirming"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { handleGetFileSampleKey, loading: loadingDownloadSimple } = useGetSampleFileKey();

  const handleDownloadSample = async () => {
    try {
      message.loading({
        content: t("v2.keyManagement.downloading"),
      });

      await handleGetFileSampleKey({ productType: typeUpload })
        .then(async (res) => {
          const url = res?.data?.product?.getSampleFile?.url;
          if (url) {
            const blob = await fetch(url).then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok.");
              }
              return response.blob();
            });

            const link = document.createElement("a");
            const objectUrl = window.URL.createObjectURL(blob);
            link.href = objectUrl;
            link.download = `[${stringKeyType[typeUpload]}] [Template] File mẫu.xlsx`;
            link.click();
            window.URL.revokeObjectURL(objectUrl);
          } else {
            throw new Error("URL for file download is not available.");
          }
        })
        .catch((err) => {
          notify.error({
            message: err.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadFileErr = async () => {
    try {
      setLoading(true);

      if (fileErr?.url) {
        const blob = await fetch(fileErr?.url).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          return response.blob();
        });

        const link = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = fileErr?.path;
        link.click();
        window.URL.revokeObjectURL(objectUrl);
        handleCloseUpload();
        setLoading(false);
      }
    } catch (error) {
      notify.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const menuUpload = (
    <Menu>
      <Menu.Item onClick={() => handleOpenUpload(KEY_TYPE.keyLicense)}>
        {t("v2.keyManagement.upload_file.download_file_key_license")}
      </Menu.Item>
      <Menu.Item onClick={() => handleOpenUpload(KEY_TYPE.account)}>
        {t("v2.keyManagement.upload_file.download_file_account")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuUpload} trigger="click">
        <Tooltip title={t("v2.keyManagement.upload_file.import_batch_list")}>
          <SvgIcon.ImportPriceSeries style={{ width: "20px", cursor: "pointer" }} />
        </Tooltip>
      </Dropdown>
      <CustomModal
        width="32%"
        hideConfirmButton={true}
        onCancel={handleCloseUpload}
        visible={visibleUpload}
        footer={false}
        closable={false}
        buttonLoading={loadingUpload}
        loadingBtnCancel={loading}
        addMoreButton={
          errorType === ERROR_TYPE.NO_ERROR ? (
            <div className="btn-group-custom-upload-file-digital-product">
              <PrimaryButton
                title={t("v2.keyManagement.upload_file.confirm")}
                loading={loadingUpload || loadingDownloadSimple || loading}
                onClick={handleUploadFile}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <PrimaryButton
                title={t("v2.keyManagement.upload_file.download_file_another")}
                loading={loadingUpload || loadingDownloadSimple || loading}
                onClick={handleDownloadAnotherFile}
              />
              <PrimaryButton
                title={t("v2.keyManagement.upload_file.download_file_error")}
                loading={loadingUpload || loadingDownloadSimple || loading}
                onClick={handleDownloadFileErr}
              />
            </div>
          )
        }
        title={
          errorType === ERROR_TYPE.NO_ERROR && (
            <Typography.Title level={4}>
              {t("v2.keyManagement.upload_file.download_file_key_license_account")}
            </Typography.Title>
          )
        }
        subTitle={
          errorType === ERROR_TYPE.NO_ERROR ? (
            <Typography style={{ display: "flex", gap: "4px", color: "rgba(239, 147, 81, 1)", marginBottom: "10px" }}>
              <ExclamationCircleFilled style={{ marginTop: "3px" }} />
              <Typography.Text italic style={{ color: "rgba(239, 147, 81, 1)", fontSize: "13px" }}>
                {t("v2.keyManagement.upload_file.warning_upload_file")}
              </Typography.Text>
            </Typography>
          ) : (
            <SvgIcon.ErrorFilledIcon />
          )
        }
        children={
          <Space direction="vertical" style={{ width: "100%", padding: "0 12px 0px 12px", justifyContent: "center" }}>
            {errorType === ERROR_TYPE.NO_ERROR && (
              <>
                <Dragger
                  style={{ borderColor: "#2246DC" }}
                  {...props}
                  beforeUpload={() => false}
                  fileList={fileList}
                  maxCount={1}
                  accept=".xlsx"
                >
                  <p className="ant-upload-drag-icon">
                    <SvgIcon.Xlsx />
                  </p>
                  <Typography.Link> {t("v2.keyManagement.upload_file.drag_drop_file")}</Typography.Link>
                </Dragger>

                <Typography.Text style={{ textAlign: "center" }}>
                  {t("v2.keyManagement.upload_file.download_sample_file")}{" "}
                  <Typography.Link underline strong onClick={handleDownloadSample}>
                    {t("v2.keyManagement.upload_file.this")}
                  </Typography.Link>
                </Typography.Text>
              </>
            )}
            {errorType !== ERROR_TYPE.NO_ERROR && (
              <div>
                <div style={{ textAlign: "center", fontSize: "18px" }}>
                  <Typography.Text strong>{t("v2.keyManagement.upload_file.download_file_failed")}</Typography.Text>
                </div>

                <div style={{ textAlign: "center", marginTop: "12px" }}>
                  <Typography.Text>{t("v2.keyManagement.upload_file.warning_file_not_found")}</Typography.Text>
                </div>
              </div>
            )}
          </Space>
        }
      />
    </>
  );
};
