import { Checkbox, Empty, Input, Space } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import MenuLayout from "components/_storybooks/MenuLayout";
import SkeletonCustom from "components/_storybooks/Skeleton";

const SPACE_LAYOUT = {
  display: "flex",
  flexDirection: "column",
  maxHeight: "300px",
  width: "100%",
  maxWidth: "300px",
};

export const FilterLoadMore = ({ onFilter, data = [], onLoadMoreData, onSearch, hasMore, loading }) => {
  const [searchValue, setSearchValue] = useState(undefined);
  const [checkedList, setCheckedList] = useState([]);
  const valueDebounce = useDebounce(searchValue);
  const handleFilter = () => {
    onFilter && onFilter(checkedList?.length ? checkedList : undefined);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChecked = (e) => {
    if (e.target.checked) {
      setCheckedList((prev) => [...prev, e.target.value]);
    } else {
      setCheckedList((prev) => prev.filter((item) => item !== e.target.value));
    }
  };
  const handleResetFilter = () => {
    setCheckedList([]);
    setSearchValue(undefined);
  };

  useEffect(() => {
    onSearch && onSearch(valueDebounce?.trim());
  }, [valueDebounce]);

  useEffect(() => {
    onLoadMoreData();
  }, []);

  return (
    <MenuLayout
      applyFilter={handleFilter}
      resetFilter={handleResetFilter}
      filterContent={
        <>
          <div className="input-search">
            <Input
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={t("common.search")}
              allowClear
              value={searchValue}
              onChange={handleChange}
            />
          </div>
          {loading && (
            <span style={{ padding: "10px" }}>
              <SkeletonCustom />
            </span>
          )}
          {!loading && !data?.length && <Empty />}
          {data?.length > 0 && (
            <Checkbox.Group value={checkedList}>
              <InfiniteScroll
                dataLength={data?.length}
                next={onLoadMoreData}
                hasMore={hasMore}
                style={SPACE_LAYOUT}
                height={data?.length > 10 ? 300 : 200}
                loader={
                  <span style={{ paddingBottom: "20px" }}>
                    <SkeletonCustom />
                  </span>
                }
              >
                {data.map((item, index) => (
                  <Space direction="vertical" key={index}>
                    <Checkbox value={item.value} onChange={handleChecked}>
                      {item.label}
                    </Checkbox>
                  </Space>
                ))}
              </InfiniteScroll>
            </Checkbox.Group>
          )}
        </>
      }
    />
  );
};
