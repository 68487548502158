import React from "react";
import { Col, Row, Typography, Space } from "antd";
import { DATE_FORMAT } from "config/constants";
import { renderWarrantyRequestType } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { WARRANTY_REQUEST_TYPE } from "features/Warranty/constant";

function TabInfo({ data }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24} xl={12}>
        <Row gutter={[16, 8]} style={{ borderRight: "1px dashed #9CADC4" }}>
          <Col span={7}>
            <Typography.Text strong>{t("serial.requestType")}</Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text>
              {data?.warrantyNote?.remainingWarrantyDays > 0
                ? renderWarrantyRequestType(WARRANTY_REQUEST_TYPE?.SELF)
                : renderWarrantyRequestType(WARRANTY_REQUEST_TYPE?.BEHALF)}
            </Typography.Text>
          </Col>

          <Col span={7}>
            <Typography.Text strong>{t("serial.returnDate")}</Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text>{formatDateTime(data?.promisedReturnDate, DATE_FORMAT)}</Typography.Text>
          </Col>

          <Col span={7}>
            <Typography.Text strong>{t("serial.attachItem")}</Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text>{data?.accessoryAttachment || "--"}</Typography.Text>
          </Col>

          <Col span={7}>
            <Typography.Text strong>{t("serial.failureDescribe")}</Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text>{data?.defectDescription || "--"}</Typography.Text>
          </Col>

          <Col span={7}>
            <Typography.Text strong>{t("serial.clientDemand")}</Typography.Text>
          </Col>
          <Col span={17}>
            <Typography.Text>{data?.customerRequest || "--"}</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24} xl={12}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>
          </Col>
          <Col span={24}>
            <Space style={{ flexWrap: "wrap" }}>
              {data?.imageURLs?.map((fileURL, index) => {
                return <CustomViewDocumentFiles file={fileURL} key={index} />;
              }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TabInfo;
