import { Col, Descriptions, Row, Tag, Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import { convertPaymentMethod } from "features/Agent/constants";
import CustomDivider from "features/KeyManagement/KeyDetail/CustomDivider";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";

export const PaymentInfoTab = ({ dataDetail }) => {
  return (
    <Col span={24} style={{ padding: "0 12px" }}>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <CustomDescriptions
            title={<CustomDivider title={t("warehouse.create.Rules")} />}
            content={
              <>
                <Descriptions.Item label={t("order.orderDetail.paymentMethod")}>
                  {dataDetail?.paymentMethod ? (
                    <Tag color="geekblue">{convertPaymentMethod(dataDetail?.paymentMethod)}</Tag>
                  ) : (
                    "- -"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.debt")}>
                  <Typography.Text>{formatCurrency(dataDetail?.debtLimit) || "--"}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.outstanding_balance")}>
                  <Typography.Text>
                    {formatCurrency(dataDetail?.debtLimit - dataDetail?.availabilityLimit) || "--"}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.debtLimit")}>
                  <Typography.Text>{formatCurrency(dataDetail?.availabilityLimit) || "--"}</Typography.Text>
                </Descriptions.Item>
              </>
            }
          />
        </Col>
        <Col span={12}>
          <CustomDescriptions
            title={<CustomDivider title={t("cart.account_balance")} />}
            content={
              <>
                <Descriptions.Item label={t("cart.account_balance")}>
                  <Typography.Text>{formatCurrency(dataDetail?.payableTotalBalance)}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.personal_income_tax")}>
                  <Typography.Text>{formatCurrency(dataDetail?.personalIncomeTax)}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.availableBalances")}>
                  <Typography.Link strong>{formatCurrency(dataDetail?.payableAvailabilityBalance)}</Typography.Link>
                </Descriptions.Item>
              </>
            }
          />
        </Col>
      </Row>
    </Col>
  );
};
