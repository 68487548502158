import React, { useState } from "react";
import { Input, Pagination } from "antd";
import { t } from "i18next";
import NotiList from "./component/NotiList";
import { FontAwesomeIcon, Icon } from "assets/icons";
import styles from "./index.module.scss";
import { useGetNotifyPaging, useUpdateNotifyStatus } from "hooks/notification";
import { convertParams } from "hooks/notification/converter";
import "./index.scss";
import { notify, debounce } from "utils/helperFuncs";
import { NOTIFICATION_STATUS } from "config/constants";
import MarkAllReadButton from "./component/MarkAllReadButton";

const Notification = () => {
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    query: undefined,
  });

  const { convertData, loading, paginationData } = useGetNotifyPaging(convertParams(params));
  const { handleUpdateNotifyStatus } = useUpdateNotifyStatus();

  const handleChangePage = (page) => {
    //change params base on previous params
    setParams((prev) => ({ ...prev, offset: (page - 1) * prev.limit }));
  };

  const handleUpdateStatus = async (ids, isUnread) => {
    if (isUnread) {
      const convertId = ids ? [ids] : [];

      await handleUpdateNotifyStatus({
        ids: convertId,
        status: NOTIFICATION_STATUS.READ,
      })
        .then(() => {
          notify.success({
            message: t("notification.changeStatusSuccess"),
            placement: "top",
          });
        })
        .catch((error) => {
          notify.error({
            message: t("notification.changeStatusError"),
            placement: "top",
            description: error.message,
          });
        });
    }
    return;
  };

  function searchQuery([text]) {
    if (text[0] === " ") {
      return;
    }
    setParams((prev) => ({ ...prev, query: text.length > 0 ? text : undefined }));
  }
  // debounce search query
  const handleSearchDebounced = debounce(searchQuery, 500);

  return (
    <div className={`${styles["notify-container"]}`}>
      <div className={`${styles["header"]}`}>
        <MarkAllReadButton />
      </div>
      <div className={`${styles["content"]}`}>
        <div className={`${styles["search-input"]}`}>
          <Input
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t("notification.searchNotification")}
            allowClear={true}
            onChange={(e) => {
              handleSearchDebounced(e.target.value);
            }}
          />
        </div>

        <div className="notification-list">
          <NotiList
            dataSource={convertData}
            loading={loading}
            defaultDataLength={5}
            onClickCard={(id, notifyObj) => handleUpdateStatus(id, notifyObj.status === NOTIFICATION_STATUS.UNREAD)}
          />
        </div>
        <div className={`${styles["pagination"]}`}>
          <Pagination
            size="small"
            showSizeChanger={false}
            pageSize={params?.limit}
            current={params?.offset / params?.limit + 1}
            total={paginationData?.total}
            onChange={handleChangePage}
            hideOnSinglePage={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
