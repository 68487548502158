import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Input, Radio, Space } from "antd";
import { Icon } from "assets/icons";
import MenuLayout from "components/CustomFilterMenu/MenuLayout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toLowerCaseNonAccentVietnamese } from "utils/helperFuncs";

const MENU_LAYOUT = {
  width: "300px",
};

const SPACE_LAYOUT = {
  width: "100%",
  maxHeight: "200px",
  overflow: "auto",
  paddingBottom: "2px",
};

const FilterMenu = ({ onFilter, options, defaultValue, type, searchable, params, onSearchTextChange }) => {
  //options must convert to [{label: 'label', value: value},...]
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [checkedList, setCheckedList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleResetFilter = () => {
    setCheckedList([]);
    setSearchValue("");
    setSelectedValue(undefined);
  };

  useEffect(() => {
    setCheckedList(params?.filters?.ownerType || []);
  }, [params?.filters?.ownerType]);

  const handleFilter = () => {
    switch (type) {
      case "checkbox": {
        onFilter && onFilter(checkedList);
        break;
      }
      case "radio": {
        onFilter && onFilter(selectedValue);
        break;
      }
      default:
        return;
    }
  };

  const handleChecked = (e) => {
    if (e?.target.checked) {
      setCheckedList((prev) => [...prev, e.target.value]);
    } else {
      setCheckedList((prev) => prev.filter((item) => item !== e?.target.value));
    }
  };

  const handleSelected = (e) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    setSearchValue("");
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const renderGroup = () => {
    switch (type) {
      case "checkbox":
        return (
          <Checkbox.Group value={checkedList}>
            <Space direction="vertical" style={SPACE_LAYOUT}>
              {options
                ?.filter((item) =>
                  toLowerCaseNonAccentVietnamese(item.label)?.includes(toLowerCaseNonAccentVietnamese(searchValue))
                )
                ?.map((item) => (
                  <Checkbox key={item.value} value={item.value} onChange={handleChecked}>
                    {item.label}
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        );
      case "radio":
        return (
          <Radio.Group value={selectedValue} onChange={handleSelected}>
            <Space direction="vertical" style={SPACE_LAYOUT}>
              {options
                ?.filter((item) => item.label.includes(searchValue))
                ?.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        );
      default:
        return <span></span>;
    }
  };

  return (
    <MenuLayout
      style={MENU_LAYOUT}
      applyFilter={handleFilter}
      resetFilter={handleResetFilter}
      filterContent={
        <>
          {searchable && (
            <Input
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={t("common.search")}
              allowClear={true}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          )}
          {renderGroup()}
        </>
      }
    />
  );
};

export default FilterMenu;
