import React, { useEffect } from "react";
import { useGetSupplierDetail } from "hooks/supplier";
import { useParams } from "react-router-dom";

function useSupplierDetail() {
  const { id } = useParams();

  const { data: supplierDetail, loading, refetch } = useGetSupplierDetail({ id });

  useEffect(() => {
    refetch();
  }, [id]);

  return {
    supplierDetail,
    loading,
    refetch,
  };
}

export default useSupplierDetail;
