import Card from "features/Cart/components/Card";
import { Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import { Link } from "react-router-dom";

export default function SellerInfo(props) {
  const { t, dataCreateExportKey, loadingDataCreateExportKey } = props;

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "rgba(5, 41, 135, 1)" }}>
          {t("v2.keyManagement.exportNoteCreate.sellerInfo")}
        </Typography.Text>
      }
    >
      <CustomDescriptions
        loading={loadingDataCreateExportKey}
        bordered={false}
        colon={false}
        contentStyle={{ paddingBottom: "8px" }}
        labelStyle={{ paddingBottom: "8px" }}
        contentMapping={[
          {
            label: t("v2.keyManagement.exportNoteCreate.orderCode"),
            value: (
              <Link target="_blank" to={`/order/${dataCreateExportKey?.id}`}>
                <Typography.Link strong>{dataCreateExportKey?.number}</Typography.Link>
              </Link>
            ),
          },
          {
            label: t("v2.keyManagement.exportNoteCreate.sellerCode"),
            value: (
              <Link target="_blank" to={`/seller/detail/${dataCreateExportKey?.sellerID}`}>
                <Typography.Link strong>
                  {dataCreateExportKey?.seller?.idNumber || dataCreateExportKey?.seller?.vatInfo?.taxIDNumber}
                </Typography.Link>
              </Link>
            ),
          },
          {
            label: t("v2.keyManagement.exportNoteCreate.sellerName"),
            value: <Typography.Text>{dataCreateExportKey?.seller?.fullName}</Typography.Text>,
          },
          {
            label: t("v2.keyManagement.exportNoteCreate.phone"),
            value: <Typography.Text>{dataCreateExportKey?.seller?.telephone}</Typography.Text>,
          },
          {
            label: t("v2.keyManagement.exportNoteCreate.email2Send"),
            value: <Typography.Text>{dataCreateExportKey?.email}</Typography.Text>,
          },
        ]}
      />
    </Card>
  );
}
