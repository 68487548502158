import React from "react";
import { Form, Select } from "antd";
import { toLowerCaseNonAccentVietnamese } from "utils/helperFuncs";

function SearchableSelect({ formItemOptions, selectOptions }) {
  const handleSearchSelect = (search, option) => {
    return toLowerCaseNonAccentVietnamese(option?.label).includes(toLowerCaseNonAccentVietnamese(search).trim());
  };

  return (
    <Form.Item {...formItemOptions}>
      <Select showSearch={true} filterOption={handleSearchSelect} {...selectOptions} />
    </Form.Item>
  );
}

export default SearchableSelect;
