import { SvgIcon } from "assets/icons";
import { isCanceledStatus, isCompleteStatus, isCurrent, isFuture, isPast } from "features/Order/helper";

const OrderState = ({ state }) => {
  return (
    <>
      {isPast(state) && <SvgIcon.PastOrderIcon />}
      {isFuture(state) && <SvgIcon.FutureOrderIcon />}
      {isCurrent(state) && <SvgIcon.CurrentOrderIcon />}
    </>
  );
};

const useModalTrackingPurchaseOrder = ({ fullEvents, status }) => {
  const timeLine =
    isCanceledStatus(status) || isCompleteStatus(status)
      ? fullEvents?.map((item, index) => {
          if (fullEvents?.length - 1 === index) {
            return {
              ...item,
              dot: (
                <>
                  {isCanceledStatus(status) && <SvgIcon.CancelOrderIcon />}
                  {isCompleteStatus(status) && <SvgIcon.SuccessOrderIcon />}
                </>
              ),
            };
          }
          return {
            ...item,
            dot: <OrderState state={item?.state} />,
          };
        })
      : fullEvents?.map((item, index) => {
          return {
            ...item,
            dot: <OrderState state={item?.state} />,
          };
        });
  return {
    timeLine,
  };
};

export default useModalTrackingPurchaseOrder;
