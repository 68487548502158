import { Col, Row, Spin, Typography } from "antd";
import SelectLoadMore from "components/CustomSelect/SelectLoadMore";
import { INPUT_TYPE } from "config/constants";
import { useGetProductsLoadMore } from "hooks/serial";
import { t } from "i18next";
import { useState } from "react";

import "./index.scss";

function CustomSelectProductLoadMore({ onGetProduct, formName }) {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      isActive: true,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data, onLoadMoreData, hasMoreData, loading } = useGetProductsLoadMore(params, "cache-first");

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    onGetProduct && onGetProduct(option?.product);
  };

  const options = data?.map((item) => ({
    value: item.id,
    label: (
      <Row gutter={16}>
        <Col span={10}>
          <Typography.Text strong ellipsis={true} style={{ color: "#2246DC" }}>
            {item.code}
          </Typography.Text>
        </Col>
        <Col span={14}>
          <Typography.Text ellipsis>{item.name}</Typography.Text>
        </Col>
      </Row>
    ),
    product: item,
    // labelProduct: item?.name,
  }));
  return (
    <SelectLoadMore
      type={INPUT_TYPE.SELECT_LOAD_MORE}
      {...{
        formItemOptions: {
          name: formName,
          rules: [{ required: true, message: t("purchase.rules.selectProduct") }],
        },
        inputOptions: {
          placeholder: t("purchase.placeHolder.searchByCodeOrNameProduct"),
          options: options,
          value: null,
          dropdownMatchSelectWidth: 1000,
          // optionLabelProp: "labelProduct",
          allowClear: false,
          // onClear: () => onGetProduct && onGetProduct(null),
          dropdownRender: (originNode) => (
            <>
              <Row gutter={16} className={"custom-select-product-load-more"}>
                <Col span={10}>
                  <Typography className={"header-select"} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.code")}
                  </Typography>
                </Col>
                <Col span={14}>
                  <Typography className={"header-select"} style={{ padding: "12px 8px" }}>
                    {t("product.tableTitle.name")}
                  </Typography>
                </Col>
              </Row>
              <Spin spinning={loading}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      }}
      onLoadMore={onLoadMoreData}
      hasMoreData={hasMoreData}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default CustomSelectProductLoadMore;
