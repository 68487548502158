import { Button, Col, Form, Radio, Row, Space, Spin, Tooltip, Typography } from "antd";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { REASON_CANCEL, ResultMethod, ResultOptions, solutionLabel } from "features/WarrantyRequest/constant";
import { useGetInfoPlatform } from "hooks/info-platform";
import useDebounce from "hooks/useDebounce";
import { useGetSerialReplaceLoadMore } from "hooks/warranty";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getFile } from "utils/helperFuncs";
import SelectCategory from "../SelectCategory";
import SelectProduct from "../SelectProduct";
import styles from "./index.module.scss";
import usePopupGetResult from "./usePopupGetResult";

const lableCol = { span: 24, offset: 0 };

const RenderUIbyResult = ({ resultOfHAValue, reason, form }) => {
  const { platformName } = useGetInfoPlatform();
  const [paramsSerial, setParamsSerial] = useState({
    filter: {
      queries: [""],
      statuses: "AVAILABLE",
    },
    pagination: { offset: 0, limit: 10, skipTotalCount: true },
  });
  const [valueSearch, setValueSearch] = useState("");

  const { serials, loading } = useGetSerialReplaceLoadMore(paramsSerial, "cache-first");
  const onSearchSerial = (value) => {
    setValueSearch(value);
  };

  const valueDebounce = useDebounce(valueSearch);

  useEffect(() => {
    setParamsSerial({
      filter: {
        ...paramsSerial.filter,
        queries: [valueSearch] || [""],
        statuses: "AVAILABLE",
      },
      pagination: { offset: 0, limit: 10, skipTotalCount: true },
    });
  }, [valueDebounce]);

  const serialsOptions = serials?.map((item) => ({
    value: item?.code,
    label: (
      <Row gutter={[16]}>
        <Col
          span={6}
          className={styles["item"]}
          style={{ padding: "5px 0 0 15px", textAlign: "left", fontWeight: 600 }}
        >
          <Tooltip title={item?.code}>
            <CustomTextHiddenLine text={item?.code} line={1} isLink={true} />
          </Tooltip>
        </Col>
        <Col span={6} className={styles["item"]} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.product?.code}>
            <Typography.Text ellipsis>{item?.product?.code}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={12} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.product?.name}>
            <Typography.Text ellipsis>{item?.product?.name}</Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    serial: item,
    labelSerial: item?.code,
  }));

  const handleChangeSerial = (value, option) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      alternativeProductName: option?.serial?.product?.name,
      alternativeProductCode: option?.serial?.product?.code,
      alternativeProductCategoryID: option?.serial?.product?.category?.id,
    });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const fieldShared = (
    <>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            type: INPUT_TYPE.SELECT_LOAD_MORE,
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.popup.serial")}</Typography.Text>,
              name: "alternativeProductSerial",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.popup.serialPlh"),
              allowClear: true,
              options: serialsOptions,
              dropdownMatchSelectWidth: 1000,
              optionLabelProp: "labelSeller",
              onSearch: onSearchSerial,
              onChange: (value, option) => handleChangeSerial(value, option),
              onInputKeyDown: handleInputKeyDown,
              dropdownRender: (originNode) => (
                <>
                  <Row gutter={16} className={styles["custom-dropdown"]}>
                    <Col span={6} className={styles["item"]} style={{ padding: "10px 0 0 20px", textAlign: "center" }}>
                      <Typography.Text strong>{"SERIAL/IMEI"}</Typography.Text>
                    </Col>
                    <Col span={6} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                      <Typography.Text strong>{t("product.tableTitle.code")}</Typography.Text>
                    </Col>
                    <Col span={12} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                      <Typography.Text strong>{t("product.tableTitle.name")}</Typography.Text>
                    </Col>
                  </Row>
                  {loading ? (
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spin spinning={loading}></Spin>
                    </div>
                  ) : (
                    originNode
                  )}
                </>
              ),
              dropdownStyle: { zIndex: "1000" },
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <SelectProduct />
      </Col>
      <Col span={24} xl={12}>
        <FormInput
          {...{
            formItemOptions: {
              label: <Typography.Text strong>{t("warrantyRequest.popup.productName")}</Typography.Text>,
              name: "alternativeProductName",
              rules: [{ required: true, message: t("common.validateRequired") }],
            },
            inputOptions: {
              placeholder: t("warrantyRequest.popup.productNamePlh"),
              maxLength: 255,
              disabled: true,
            },
          }}
        />
      </Col>
      <Col span={24} xl={12}>
        <SelectCategory />
      </Col>
    </>
  );
  const renderUI = {
    [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: fieldShared,
    [ResultMethod.RENEW_SAME_PRODUCT]: fieldShared,
    [ResultMethod.DONE]: <></>,
    [ResultMethod.CANCEL]: (
      <Col span={24}>
        <FormInput
          type={INPUT_TYPE.RADIO_GROUP}
          formItemOptions={{
            label: <Typography.Text strong>{t("warrantyRequest.popup.cancel")}</Typography.Text>,

            name: ["declineReason", "defaultReason"],
          }}
          inputChildren={
            <Space direction="vertical">
              <Radio value={REASON_CANCEL.EXPIRED}>{t("warrantyRequest.reasonLock1")}</Radio>
              <Radio value={REASON_CANCEL.NOT_BELONG_HA}>{t("warrantyRequest.reasonLock2", { platformName })}</Radio>
              <Radio value={REASON_CANCEL.USER_FAULT}>{t("warrantyRequest.reasonLock3")}</Radio>
              <Radio value={REASON_CANCEL.OTHER}>
                <Space>
                  <Typography.Text>{t("common.other")}</Typography.Text>
                  <FormInput
                    formItemOptions={{
                      name: ["declineReason", "freeText"],
                      style: { margin: 0 },
                      rules: [{ required: !reason && true, message: t("common.validateRequired") }],
                    }}
                    inputOptions={{
                      placeholder: t("seller.reasonLockPlace"),
                      disabled: reason && true,
                    }}
                  />
                </Space>
              </Radio>
            </Space>
          }
        />
      </Col>
    ),
    [ResultMethod.REIMPORT]: (
      <>
        <Col span={24} xl={12}>
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                label: (
                  <Typography.Text strong>{t("warrantyRequest.popup.ownerGetMoney", { platformName })}</Typography.Text>
                ),
                name: "receiveAmount",
              },
              inputOptions: {
                placeholder: t("warrantyRequest.popup.ownerGetMoneyplc", { platformName }),
                maxLength: 11,
              },
            }}
          />
        </Col>
        <Col span={24} xl={12}>
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                label: (
                  <Typography.Text strong>{t("warrantyRequest.popup.ownerPayMoney", { platformName })}</Typography.Text>
                ),
                name: "returnAmount",
              },
              inputOptions: {
                maxLength: 11,
                placeholder: t("warrantyRequest.popup.ownerPayMoneyplc", { platformName }),
              },
            }}
          />
        </Col>
      </>
    ),
  };

  return renderUI?.[resultOfHAValue];
};

function PopupGetResult({ record, refetch, itemID, disabled }) {
  const {
    loadingBtn,
    initialValues,
    form,
    switchClose,
    reason,
    resultOfHAValue,
    setResultValue,
    handleOk,
    setResultOfHAValue,
    handleValuesChange,
  } = usePopupGetResult({ record, refetch, itemID });
  const { platformName } = useGetInfoPlatform();

  return (
    <CustomModal
      {...{
        customComponent: (
          <CreateButton
            disabled={disabled}
            loading={loadingBtn}
            title={t("warrantyRequest.detail.btnGroup.getResult")}
          />
        ),
        title: (
          <Typography.Text style={{ fontSize: "20px" }} strong>
            {t("warrantyRequest.popup.title")}
          </Typography.Text>
        ),
        width: "40%",
        footer: false,
        centered: true,
        switchClose,
        hideConfirmButton: true,
        addMoreButton: (
          <Button loading={loadingBtn} type="primary" onClick={handleOk}>
            {t("common.confirm")}
          </Button>
        ),
      }}
    >
      <Card
        title={
          <Space>
            <Typography.Text strong>{t("warrantyRequest.popup.processingBy")}</Typography.Text>
            <Typography.Link strong>{record?.receivePartyName || solutionLabel?.[record?.solution]}</Typography.Link>
          </Space>
        }
        children={
          <Form form={form} layout="vertical" initialValues={initialValues} onValuesChange={handleValuesChange}>
            <Row gutter={16}>
              <Col span={24} xl={12}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  {...{
                    formItemOptions: {
                      label: <Typography.Text strong>{t("warrantyRequest.popup.getResult")}</Typography.Text>,
                      name: "brandResult",
                      rules: [{ required: true, message: t("common.validateRequired") }],
                    },
                    inputOptions: {
                      options: ResultOptions,
                      allowClear: false,
                      onChange: (value) => setResultValue(value),
                    },
                  }}
                />
              </Col>
              <Col span={24} xl={12}>
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  {...{
                    formItemOptions: {
                      label: (
                        <Typography.Text strong>
                          {t("warrantyRequest.popup.resultOfHA", { platformName })}
                        </Typography.Text>
                      ),
                      name: "result",
                      rules: [{ required: true, message: t("common.validateRequired") }],
                    },
                    inputOptions: {
                      options: ResultOptions,
                      allowClear: false,
                      onChange: (value) => setResultOfHAValue(value),
                    },
                  }}
                />
              </Col>
              <RenderUIbyResult reason={reason} resultOfHAValue={resultOfHAValue} form={form} />

              <Col span={24} xl={12}>
                <FormInput
                  {...{
                    type: INPUT_TYPE.TEXT_AREA,
                    formItemOptions: {
                      label: <Typography.Text strong>{t("warrantyRequest.popup.note")}</Typography.Text>,
                      name: "resultNote",
                    },
                    inputOptions: {
                      placeholder: t("warrantyRequest.popup.notePlh"),
                      maxLength: 255,
                    },
                  }}
                />
              </Col>
              <Col span={24} xl={12}>
                <FormInput
                  {...{
                    type: INPUT_TYPE.DATE_PICKER,
                    formItemOptions: {
                      label: <Typography.Text strong>{t("warrantyRequest.popup.getDate")}</Typography.Text>,
                      name: "resultReceivedDate",
                      rules: [{ required: true, message: t("common.validateRequired") }],
                    },
                    inputOptions: {
                      placeholder: t("warrantyRequest.create.placeHolder.date"),
                      format: DATE_FORMAT,
                    },
                  }}
                />
              </Col>

              <Col span={24}>
                <Form.Item
                  labelCol={lableCol}
                  name={"resultFileURLs"}
                  getValueFromEvent={getFile}
                  valuePropName={"fileList"}
                  label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
                  extra={t("warrantyRequest.create.noteAttack")}
                >
                  <UploadFiles />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
    </CustomModal>
  );
}

export default PopupGetResult;
