import { gql } from "@apollo/client";

export const GET_CATEGORIES_QUERY = gql`
  query GetCategories($filters: CategoriesFilters, $pagination: PaginationRequest) {
    category {
      pagination(filters: $filters, pagination: $pagination) {
        categories {
          id
          code
          name
          level
          isActive
          parent {
            id
            name
            level
          }
        }
        paginationData {
          offset
          total
        }
      }
    }
  }
`;

export const GET_CATEGORY_QUERY = gql`
  query GetCategory($id: ID!) {
    category {
      get(id: $id) {
        id
        code
        name
        isActive
        level
        logoURL
        description
        parentID
        attributes {
          id
          name
          isActive
          isCommon
          totalUsedCategory
        }
        parent {
          id
          code
          name
        }
      }
    }
  }
`;

export const GET_CATEGORY_QUERY_LEVEL = gql`
  query GetCategory($levels: [Int64!], $limit: Int64) {
    category {
      pagination(filters: { levels: $levels }, pagination: { limit: $limit }) {
        categories {
          name
          id
          code
          parentID
          children {
            name
            id
            code
            parentID
            children {
              name
              id
              code
              parentID
              children {
                name
                id
                code
                parentID
                children {
                  name
                  id
                  code
                  parentID
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_CATEGORIES_QUERY = gql`
  query GetAllCategories($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        logoURL
        totalProduct
        totalActiveProduct
        description
        isActive
        parentID
        attributes {
          id
          name
        }
        children {
          id
          name
          logoURL
          totalProduct
          totalActiveProduct
          description
          isActive
          parentID
          attributes {
            id
            name
          }
          children {
            id
            name
            logoURL
            totalProduct
            totalActiveProduct
            description
            isActive
            parentID
            attributes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_ATTRIBUTES_CATEGORIES_QUERY = gql`
  query GetAllAttributesCategories {
    category {
      list {
        attributes {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES_FOR_FORM_QUERY = gql`
  query GetAllCategories($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        level
        parentID
        children {
          id
          name
          level
          parentID
          # children {
          #   id
          #   name
          #   level
          #   parentID
          # }
        }
      }
    }
  }
`;

export const GET_CATEGORIES_LEVEL_2 = gql`
  query GetAllCategoriesLevel2($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
      }
    }
  }
`;

export const GET_CATEGORIES_BY_ATTRIBUTE_ID = gql`
  query CategoriesByAtt($levels: [Int64!], $attributeIDs: [ID!], $isActive: Boolean) {
    category {
      list(req: { levels: $levels, attributeIDs: $attributeIDs, isActive: $isActive }) {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_PROD_CATEGORIES = gql`
  query GetProdCate($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        level
        parentID
        children {
          id
          name
          level
          parentID
          children {
            id
            name
            level
            parentID
          }
        }
      }
    }
  }
`;

//attribute query
export const GET_ALL_ATTRIBUTES_QUERY = gql`
  query GetAllAttributes($req: ListAttributeRequest) {
    attribute {
      list(req: $req) {
        id
        name
        isActive
        isCommon
        totalUsedCategory
      }
    }
  }
`;

export const GET_CATEGORIES_LEVEL_1 = gql`
  query GetProdCate($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        level
        parentID
      }
    }
  }
`;

export const GET_CATEGORIES_LEVEL_COUNT = gql`
  query GetProdCate($req: CategoriesFilters) {
    category {
      list(req: $req) {
        id
        name
        level
        parentID
      }
    }
  }
`;
