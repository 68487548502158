import { Col, Row, Typography } from "antd";
import { IMPORT_TYPE, convertTotalNumber } from "features/Serial/constant";
import { t } from "i18next";

import styles from "../../../../index.module.scss";

function FooterTable({
  importType,
  dataTableFirstTimeImport,
  sumSerialByOrder,
  sumSerialByScanned,
  sumSerialByLack,
  dataTableReturn,
  sumSerialByOrderTypeReimport,
  sumReturnByOrderTypeReimport,
  sumLackByOrderTypeReimport,
  dataTableReImportWarranty,
}) {
  const renderSubTotal = (sumSerialByOrder, sumSerialByScanned, sumSerialByLack) => {
    if (sumSerialByOrder < sumSerialByScanned) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "red" }}>
            : {sumSerialByScanned - sumSerialByOrder}
          </Col>
        </>
      );
    }
    if (sumSerialByScanned === sumSerialByOrder) {
      return (
        <>
          <Col span={12}>
            <Typography.Text strong className={styles["summary"]}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ color: "#00AB78" }}>: {t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (sumSerialByScanned !== sumSerialByOrder) {
      return (
        <>
          <Col span={12}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("common.lack")}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ color: "#ef9351" }}>
            : {sumSerialByLack}
          </Col>
        </>
      );
    }
  };

  const renderUI = {
    [IMPORT_TYPE.FIRST_TIME_IMPORT]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
        <Col span={12}>: {convertTotalNumber(dataTableFirstTimeImport?.length)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByOrder)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByScanned)}</Col>

        {renderSubTotal(sumSerialByOrder, sumSerialByScanned, sumSerialByLack)}
      </Row>
    ),
    [IMPORT_TYPE.RETURN]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
        <Col span={12}>: {convertTotalNumber(dataTableReturn?.length)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByOrderTypeReimport)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialReturn")}</Col>
        <Col span={12}>: {convertTotalNumber(sumReturnByOrderTypeReimport)}</Col>
        {renderSubTotal(sumSerialByOrderTypeReimport, sumReturnByOrderTypeReimport, sumLackByOrderTypeReimport)}
      </Row>
    ),
    [IMPORT_TYPE.RE_IMPORT_WARRANTY]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumSerial")}</Col>
        <Col span={12}>: {dataTableReImportWarranty?.length}</Col>
      </Row>
    ),
    [IMPORT_TYPE.TRANSFER_SERIAL]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
        <Col span={12}>: {convertTotalNumber(dataTableFirstTimeImport?.length)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByOrder)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByScanned)}</Col>

        {renderSubTotal(sumSerialByOrder, sumSerialByScanned, sumSerialByLack)}
      </Row>
    ),
    [IMPORT_TYPE.OTHER]: (
      <Row gutter={[]} className={styles["summary"]}>
        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
        <Col span={12}>: {convertTotalNumber(dataTableFirstTimeImport?.length)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByOrder)}</Col>
        <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
        <Col span={12}>: {convertTotalNumber(sumSerialByScanned)}</Col>

        {renderSubTotal(sumSerialByOrder, sumSerialByScanned, sumSerialByLack)}
      </Row>
    ),
  };

  return renderUI?.[importType];
}

export default FooterTable;
