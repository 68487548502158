import { useState } from "react";
import { Form, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButton } from "components/PrimaryButton";
import { useConfirmTakingCareCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { SelectLoadMore } from "components/CustomSelect";
import { INPUT_TYPE, USER_PERMISSIONS } from "config/constants";
import { useGetStaff } from "hooks/warranty";
import { notify } from "utils/helperFuncs";

function ConfirmTakingCareButton({ customerId }) {
  const [form] = Form.useForm();

  const [showModal, setShowModal] = useState(false);
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: undefined, status: "ACTIVE", permission: USER_PERMISSIONS.CUSTOMER_CREATE },
    pagination: { offset: 0, limit: 10 },
  });

  const { handleConfirmTakingCareCustomer, loadingConfirmTakingCare } = useConfirmTakingCareCustomer();
  const { data: dataStaffOptions, hasMoreData, onLoadMoreData } = useGetStaff(paramsStaff);

  const onSearchStaff = (value) =>
    setParamsStaff({
      filters: { ...paramsStaff.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        await handleConfirmTakingCareCustomer({
          customerIDs: customerId,
          careStaffID: values?.careStaffID,
        })
          .then((res) => {
            handleCancel();
            notify.success({ message: t("potentialCustomer.updateSuccess") });
            form.resetFields();
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
            handleOpen();
          });
      });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={showModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      customComponent={<PrimaryButton title={t("potentialCustomer.buttonGroup.pushInStaff")} />}
      title={
        <Typography.Text strong style={{ textTransform: "uppercase", fontSize: "18px" }}>
          {t("potentialCustomer.selectStaff")}
        </Typography.Text>
      }
      onOke={onSubmit}
      buttonLoading={loadingConfirmTakingCare}
      children={
        <Form form={form}>
          <SelectLoadMore
            type={INPUT_TYPE.SELECT_LOAD_MORE}
            {...{
              formItemOptions: {
                name: "careStaffID",
                rules: [
                  {
                    required: true,
                    message: t("potentialCustomer.selectStaffError"),
                  },
                ],
              },
              inputOptions: {
                placeholder: t("potentialCustomer.selectStaff"),
                options: dataStaffOptions,
                value: null,
                allowClear: false,
                optionLabelProp: "labelStaff",
              },
            }}
            onLoadMore={onLoadMoreData}
            hasMoreData={hasMoreData}
            onSearch={onSearchStaff}
          />
        </Form>
      }
    />
  );
}

export default ConfirmTakingCareButton;
