import React from "react";
import { Typography } from "antd";

function TextAlignLeft({ value, strong, color, ...rest }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }} {...rest}>
      <Typography.Text
        strong={strong}
        style={{ textAlign: value || value?.length > 5 ? "start" : "center", margin: 0, color: `${color}` }}
        {...rest}
      >
        {value ?? "--"}
      </Typography.Text>
    </div>
  );
}

export default TextAlignLeft;
