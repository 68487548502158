import { Col, Form, Popconfirm, Row, Space, Table, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomCard from "components/CustomCard";
import CustomInputGroup from "components/CustomInputGroup";
import CustomSelectProductLoadMore from "components/CustomSelectProductLoadMore";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import { DATE_TIME_FORMAT2, INPUT_TYPE } from "config/constants";
import {
  CURRENCY_INPUT,
  DATE_PERIOD_UNIT,
  datePeriodOptions,
  optionCurrency,
  styleHeaderCard,
  styleTitleCard,
} from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import styles from "./index.module.scss";
import useProductListLoadMore from "./useProductListLoadMore";

function ProductsListLoadMore({ form, onGetProductList, productListSelected, setDeletedIDs, deletedIDs, dataDetail }) {
  const { handleGetProduct, handleDeleteProduct } = useProductListLoadMore({
    form,
    onGetProductList,
    productListSelected,
    setDeletedIDs,
    deletedIDs,
    dataDetail,
  });
  const columns = [
    {
      title: t("purchase.table.stt"),
      fixed: true,
      width: 60,
      align: "center",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("purchase.table.parameter"),
      fixed: true,
      width: 300,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Space align="center">
              <Typography.Link strong>
                <Link to={`/product/detail/${record?.productID}`} target="_blank">
                  <Typography.Link strong>{record?.productCode}</Typography.Link>
                </Link>
              </Typography.Link>
            </Space>
            <CustomTextHiddenLine line={2} text={record?.productName || "--"} />
          </div>
        );
      },
    },
    {
      title: t("purchase.table.recommendedQuantity"),
      width: 100,
      align: "center",
      render: (_, record) => {
        return record?.recommendedQuantity || 0;
      },
    },
    {
      title: t("purchase.table.quantityToBuy"),
      width: 120,
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "quantity"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.quantityToBuy") }),
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                style: { width: 70 },
                maxLength: 4,
                min: 0,
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.unitPriceAfterTax"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "afterVatPrice"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.unitPriceAfterTax") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "currency"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 11,
              min: 0,
            }}
            optionsSelect={optionCurrency}
            maxWidthInput={120}
          />
        );
      },
    },
    {
      title: t("purchase.table.percentVat"),
      width: 80,
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.NUMBER,
              formItemOptions: {
                name: [index, "vatPercent"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.percentVat") }),
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                min: -1,
                maxLength: 2,
                style: { width: 50 },
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.unitPriceBeforeTax"),
      width: 200,
      align: "right",
      render: (_, record) => {
        const beforeVatPrice = record?.beforeVatPrice || 0;
        return record?.currency === CURRENCY_INPUT.USD
          ? formatCurrencyDollar(beforeVatPrice)
          : formatCurrency(beforeVatPrice);
      },
    },
    {
      title: t("purchase.table.intoMoney"),
      width: 200,

      align: "right",
      render: (_, record) => {
        const intoMoney = record?.intoMoney || 0;
        return record?.currency === CURRENCY_INPUT.USD ? formatCurrencyDollar(intoMoney) : formatCurrency(intoMoney);
      },
    },
    {
      title: t("purchase.table.vatMoney"),
      width: 200,
      align: "right",
      render: (_, record) => {
        const vatMoney = record?.vatMoney || 0;
        return record?.currency === CURRENCY_INPUT.USD ? formatCurrencyDollar(vatMoney) : formatCurrency(vatMoney);
      },
    },
    {
      title: t("purchase.table.unitPriceAfterDiscount"),
      align: "center",
      width: 220,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "afterDiscountPrice"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.unitPriceAfterDiscount") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "currency"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 11,
              min: 0,
            }}
            optionsSelect={optionCurrency}
            maxWidthInput={120}
          />
        );
      },
    },
    {
      title: t("purchase.table.warrantyPeriod"),
      align: "center",
      width: 200,
      render: (_, record, index) => {
        return (
          <CustomInputGroup
            formItemOptionsFirst={{
              name: [index, "warranty"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.warrantyPeriod") }),
                },
              ],
            }}
            formItemOptionsSecond={{
              name: [index, "warrantyPeriod"],
            }}
            inputOptionsFirst={{
              placeHolder: 0,
              maxLength: 3,
              min: 0,
            }}
            typeFirst={INPUT_TYPE.NUMBER}
            optionsSelect={datePeriodOptions}
            defaultValueSelect={DATE_PERIOD_UNIT?.MONTH}
            maxWidthSelect={90}
            maxWidthInput={70}
          />
        );
      },
    },
    {
      title: t("purchase.table.numberDaysDebt"),
      width: 100,
      align: "center",
      render: (_, record, index) => {
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.NUMBER,
              formItemOptions: {
                name: [index, "creditDays"],
                rules: [
                  {
                    required: true,
                    message: t("common.messageRequiredType", { message: t("purchase.table.numberDaysDebt") }),
                  },
                ],
              },
              inputOptions: {
                placeHolder: 0,
                style: { width: 50 },
                maxLength: 3,
                min: 0,
              },
            }}
          />
        );
      },
    },
    {
      title: t("purchase.table.expectedDelivery"),
      width: 200,
      align: "center",
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              name: [index, "expectedDeliveryTime"],
              rules: [
                {
                  required: true,
                  message: t("common.messageRequiredType", { message: t("purchase.table.expectedDelivery") }),
                },
              ],
            },
            inputOptions: {
              format: DATE_TIME_FORMAT2,
              showTime: true,
              placeholder: t("warrantyRequest.create.placeHolder.date"),
            },
          }}
        />
      ),
    },
    {
      width: 50,
      fixed: "right",
      align: "center",
      render: (_, record, index) =>
        productListSelected.length >= 1 ? (
          <Popconfirm
            title={t("warehouse.create.messageConfirmDelete")}
            cancelText={t("common.cancel")}
            okText={t("serialIE.Comfirm")}
            onConfirm={() => handleDeleteProduct(record.id || record?.productID, index)}
          >
            <SvgIcon.TrashFullCartIcon
              className={styles["icon-trash"]}
              style={{ fontSize: "16px", color: "#5B6673", cursor: "pointer" }}
            />
          </Popconfirm>
        ) : null,
    },
  ];

  const renderSummaryTableSelected = (pageData) => {
    const formValues = form.getFieldsValue();

    const currencyUSD = pageData?.[0]?.currency === CURRENCY_INPUT.USD;

    var recommendedQuantity = formValues?.items?.reduce(function (total, current) {
      return total + (current?.recommendedQuantity || 0);
    }, 0);

    var quantityToBuy = formValues?.items?.reduce(function (total, current) {
      return total + (current?.quantity || 0);
    }, 0);

    var totalIntoMoney = formValues?.items?.reduce(function (total, current) {
      return total + (current?.intoMoney || 0);
    }, 0);

    var totalVatMoney = formValues?.items?.reduce(function (total, current) {
      return total + (current?.vatMoney || 0);
    }, 0);

    const stylesSummary = {
      color: "#052987",
      textAlign: "center",
      fontWeight: "bold",
    };

    const colorTotals = { color: "#052987" };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.total")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            <Typography.Text style={colorTotals}>{recommendedQuantity || 0}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            <Typography.Text style={colorTotals}>{quantityToBuy}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} colSpan={4}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD ? formatCurrencyDollar(totalIntoMoney) : formatCurrency(totalIntoMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD ? formatCurrencyDollar(totalVatMoney) : formatCurrency(totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.totalPayment")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={7}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {currencyUSD
                  ? formatCurrencyDollar(totalIntoMoney + totalVatMoney)
                  : formatCurrency(totalIntoMoney + totalVatMoney)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <CustomCard
      headStyle={styleHeaderCard}
      title={
        <Typography.Text style={styleTitleCard} strong>
          {t("purchase.titleCard.productList")}
        </Typography.Text>
      }
      content={
        <Row>
          <Col span={8}>
            <CustomSelectProductLoadMore onGetProduct={handleGetProduct} />
          </Col>
          <Col span={24}>
            <Form.List name={"items"}>
              {(fields, { add, remove }) => {
                return (
                  <CustomTable
                    scroll={{
                      x: "max-content",
                    }}
                    columns={columns}
                    dataSource={productListSelected}
                    summary={productListSelected?.length > 0 ? renderSummaryTableSelected : null}
                  />
                );
              }}
            </Form.List>
          </Col>
        </Row>
      }
    />
  );
}

export default ProductsListLoadMore;
