import React from "react";

import { useSellerPermissions } from "hooks/seller";
import Spinner from "components/Spinner";
import Page403 from "pages/PageError/403";
import { useGetUserPermissions } from "hooks/user/user";
import { CreateSellerForm } from "./components/CreateSellerForm";
import { useSupplierPermissions } from "hooks/supplier";

const CreateSeller = () => {
  const { canCreate: canCreateSeller } = useSellerPermissions();
  const { canCreateSupplier } = useSupplierPermissions();
  const { loading: loadUserPermissions } = useGetUserPermissions();

  return loadUserPermissions ? (
    <Spinner loading={loadUserPermissions} />
  ) : canCreateSeller || canCreateSupplier ? (
    <CreateSellerForm />
  ) : (
    <Page403 />
  );
};

export default CreateSeller;
