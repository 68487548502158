import { Form } from "antd";
import { useCreateCommunicateLog } from "hooks/potentialCustomer/mutation";
import { useGetPotentialCustomerActivityLogs } from "hooks/potentialCustomer/query";
import { useGetStaff } from "hooks/warranty";
import { useEffect, useState } from "react";
import "./index.scss";
import { notify } from "utils/helperFuncs";
import { useGetUserCurrent } from "hooks/auth/auth";
import moment from "moment";

function useActivityLogs({ customerID }) {
  const [form] = Form.useForm();
  const { activityLogsData, activityLogsLoading } = useGetPotentialCustomerActivityLogs({
    customerID: customerID,
  });
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: undefined, status: "ACTIVE" },
    pagination: { offset: 0, limit: 10 },
  });
  const [valueSelected, setValueSelected] = useState();
  const [loadingCreateLog, setLoadingCreateLog] = useState(false);
  const [createdBy, setCreatedBy] = useState();

  const { data: dataStaffOptions, hasMoreData, onLoadMoreData } = useGetStaff(paramsStaff);
  const { handleCreateCommunicateLog } = useCreateCommunicateLog();
  const { userCurrentData } = useGetUserCurrent();

  const initialValues = {
    type: undefined,
    note: undefined,
    createdAt: moment(),
    createdByID: `${userCurrentData?.fullname} (tôi)`,
  };

  const onSearchStaff = (value) =>
    setParamsStaff({
      filters: { ...paramsStaff.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChangeCreatedBy = (value, option) => {
    setCreatedBy(option);
  };

  const handleChangeSelect = (value) => {
    setValueSelected(value);
  };

  const handleCancel = () => {
    setValueSelected(undefined);
    form.resetFields();
  };

  const handleSave = async () => {
    const formValues = await form.validateFields();

    try {
      if (formValues?.note || formValues?.fileUrls) {
        const newParams = {
          request: {
            ...formValues,
            customerID: customerID,
            createdByID: createdBy?.value || userCurrentData?.id,
          },
        };
        setLoadingCreateLog(true);
        await handleCreateCommunicateLog(newParams)
          .then((res) => {
            handleCancel();
            setLoadingCreateLog(false);
          })
          .catch((err) => {
            notify.error({ message: err });
            setLoadingCreateLog(false);
          });
      }
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [userCurrentData]);

  useEffect(() => {
    form.resetFields();
    setValueSelected(undefined);
  }, [customerID]);

  return {
    loadingCreateCommunicate: loadingCreateLog,
    activityLogsData,
    activityLogsLoading,
    dataStaffOptions,
    hasMoreData,
    valueSelected,
    form,
    initialValues,
    onLoadMoreData,
    onSearchStaff,
    handleChangeSelect,
    handleCancel,
    handleSave,
    handleChangeCreatedBy,
  };
}

export default useActivityLogs;
