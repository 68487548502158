import { Card, Col, Form, Row, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { convertTotalNumber } from "features/Serial/constant";
import { t } from "i18next";
import { useState } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";

import InputSearch from "features/Serial/components/InputSearch";
import { uniq } from "lodash";
import styles from "../../../index.module.scss";
import "../../../index.scss";

import { renderLack } from "utils/helperFuncs";
import InputTag from "../InputTag";

function SerialsInputTag({
  index,
  sumSerialByOrder,
  sumSerialByScanned,
  record,
  tagsIndex,
  form,
  setVisible,
  visible,
}) {
  const tags = record?.serialItems?.map((item) => item);

  const [tagsCopy, setTagsCopy] = useState(tags);
  const [tagsCopy2, setTagsCopy2] = useState(tags);

  const handleChange = (e) => {
    visible ? setTagsCopy(e) : setTagsCopy2(e);
  };

  const [searchResult, setSearchResult] = useState([]);
  const [tagsValueResult, setTagsValueResult] = useState([]);

  const moreThanNumber = tagsIndex?.length > 7;
  const handleSearchSerials = (values) => {
    const sortedArray1 = tagsCopy2;
    const sortedArray2 = uniq(values);
    setTagsValueResult(sortedArray2);
    const searchValues = sortedArray1.filter((value) => sortedArray2.includes(value.code));
    if (searchValues.length > 0) {
      setSearchResult(searchValues);
    } else {
      setSearchResult([]);
    }
  };

  const renderSubTotal = (record) => {
    if (record?.quantity < record?.serialItems?.length) {
      return (
        <>
          <Col span={4}>
            <Typography.Text style={{ color: "red" }} strong>
              {t("serialIE.productInfo.residual")}
            </Typography.Text>
          </Col>
          <Col span={20} style={{ color: "red" }}>
            {record?.serialItems?.length - record?.quantity}
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length === record?.quantity) {
      return (
        <>
          <Col span={4}>
            <Typography.Text strong style={{ color: "" }}>
              {t("serialIE.productInfo.quantitySerial")}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</Typography.Text>
          </Col>
        </>
      );
    }
    if (record?.serialItems?.length !== record?.quantity) {
      return (
        <>
          <Col span={4}>
            <Typography.Text style={{ color: "#ef9351" }} strong>
              {t("serialIE.productInfo.lack")}
            </Typography.Text>
          </Col>
          <Col span={20} style={{ color: "#ef9351" }}>
            {renderLack({ quantity: record?.quantity, scanned: record?.serialItems })}
          </Col>
        </>
      );
    }
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setTagsCopy([...tagsCopy2]);
    setVisible(false);
  };

  const handleOke = () => {
    setTagsCopy2([...tagsCopy]);
    setVisible(false);
  };

  return (
    <Space className="custom-input-tag" style={{ width: "100%" }}>
      <Form.Item
        name={[index, "serialItems"]}
        rules={[
          {
            required: true,
            message: t("serialIE.error.serialItems"),
          },
        ]}
      >
        <InputTag isHidden={true} tags={tags} onChange={handleChange} moreThanNumber={moreThanNumber} idPopup={false} />
      </Form.Item>
      <CustomModal
        {...{
          footer: false,
          title: (
            <Typography.Title level={4} strong>
              {t("serialIE.popupAddSerial")}
            </Typography.Title>
          ),
          width: "50%",
          visible: visible === index,
          onCancel: handleCancel,
          onOpen: handleOpen,
          onOke: handleOke,
          cancelButtonLabel: t("common.close"),
          hideConfirmButton: true,
        }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Card
              style={{ background: "#F7F8FB", marginBottom: "16px" }}
              children={
                <Row gutter={[16, 8]} className={styles["summary"]}>
                  <Col span={4}>
                    <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                  </Col>
                  <Col span={20}>
                    <Typography.Link strong href={`/product/detail/${record?.product?.id}`}>
                      {record?.product?.code}
                    </Typography.Link>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                  </Col>
                  <Col span={20}> {record?.product?.name}</Col>
                  <Col span={4}>
                    <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                  </Col>
                  <Col span={20}> {convertTotalNumber(record?.quantity)}</Col>
                  <Col span={4}>
                    <Typography.Text strong>{t("serialIE.productInfo.serialScanned")}</Typography.Text>
                  </Col>
                  <Col span={20}> {convertTotalNumber(record?.serialItems?.length)}</Col>

                  {renderSubTotal(record)}
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <InputSearch onSearch={handleSearchSerials} />
          </Col>
          <Col span={24}>
            <Space>
              <ExclamationCircleFilled style={{ color: "#EF9351" }} />
              <Typography.Text type="warning">{t("serialIEDetail.warning")}</Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[index, "serialItems"]}
              rules={[
                {
                  required: true,
                  message: t("serialIE.error.serialItems"),
                },
              ]}
            >
              <InputTag
                tags={tags}
                searchResult={searchResult}
                tagsValueResult={tagsValueResult}
                onChange={handleChange}
                form={form}
                idPopup={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </CustomModal>
    </Space>
  );
}

export default SerialsInputTag;
