import React from "react";
import { Button, Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import StateSwitch from "components/StateSwitch";
import "./index.scss";

const CategoryListModal = ({
  totalUsedCategory,
  toggleCategoryList,
  setToggleCategoryList,
  dataSource,
  attributeName,
}) => {
  const { t } = useTranslation();

  const handleOpenList = () => {
    setToggleCategoryList(true);
  };

  const handleClose = () => {
    setToggleCategoryList(false);
  };

  const columns = [
    {
      title: "STT",
      key: "No",
      width: "10%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("product.categoryAttribute.level3CategoryName"),
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: t("product.categoryAttribute.attributeType"),
      key: "attributeName",
      render: () => <span>{attributeName}</span>,
    },
    {
      title: t("common.status"),
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (_, record) => {
        return <StateSwitch defaultChecked={record?.status} disabled={true} />;
      },
    },
  ];

  return (
    <>
      <Button type="link" onClick={handleOpenList}>
        <span className="view-category-list">{t("product.categoryAttribute.viewCategoryList")}</span>
      </Button>
      <Modal
        width={700}
        closable={true}
        centered={true}
        footer={false}
        visible={toggleCategoryList}
        onCancel={handleClose}
      >
        <p>
          {t("product.categoryAttribute.level3CategoryInUsed")} ({totalUsedCategory})
        </p>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 400 }}
          rowKey={(obj) => obj?.id}
        />
      </Modal>
    </>
  );
};

export default CategoryListModal;
