import { LockTwoTone, UnlockTwoTone } from "@ant-design/icons";

const LockComponent = ({ locked }) => {
  return locked ? (
    <LockTwoTone
      twoToneColor="#2246DC"
      style={{
        fontSize: 40,
        padding: "20px",
        borderRadius: 50,
        background:
          "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
      }}
    />
  ) : (
    <UnlockTwoTone
      twoToneColor="#2246DC"
      style={{
        fontSize: 40,
        padding: "20px",
        borderRadius: 50,
        background:
          "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
      }}
    />
  );
};

export default LockComponent;
