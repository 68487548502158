import { Space } from "antd";
import "./index.scss";

function SpaceCustom({ children, direction = "vertical", gap = 0, style, ...props }) {
  return (
    <Space className="space-custom" direction={direction} style={{ "--gap": `${gap}px`, ...style }} {...props}>
      {children}
    </Space>
  );
}

export default SpaceCustom;
