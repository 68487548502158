import { Card, Col, Form, Row, Typography } from "antd";
import PageHeader from "components/PageHeader";
import { t } from "i18next";
import React from "react";
import "./index.scss";
import CustomButton from "components/CustomButton";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import UploadBannerButton from "./components/UploadBannerButton";
import UploadLogo from "components/UploadLogo";
import useList from "./useList";
import { getFile } from "utils/helperFuncs";
import CustomModal from "components/CustomModal";

function EditProductBrand() {
  const { form, initialValues, onCancelCreate, handleSubmitCreate, loadingUpdate } = useList();
  return (
    <div className="form-edit-product-brand">
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("productBrand.editProductBrand")}
            routes={[
              { path: "/", name: t("productBrand.product") },
              { path: "/product-brand", name: t("productBrand.listBrand") },
              { path: "#", name: t("productBrand.editProductBrand") },
            ]}
          />
        </Col>
        <Col span={24}>
          <Form layout="horizontal" initialValues={initialValues} form={form}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card>
                  <Row gutter={[16, 100]}>
                    <Col span={12} style={{ paddingRight: "30px" }}>
                      <Form.Item
                        labelCol={{ span: 24, offset: 0 }}
                        name={"logo"}
                        getValueFromEvent={getFile}
                        valuePropName={"fileList"}
                        label={<Typography.Text strong>{t("productBrand.logoBrand")}</Typography.Text>}
                        extra={t("common.noteAttack")}
                      >
                        <UploadLogo maxCount={1} form={form} />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24, offset: 0 }}
                        name={"catalogs"}
                        getValueFromEvent={getFile}
                        valuePropName={"fileList"}
                        label={<Typography.Text strong>{t("productBrand.catalog")}</Typography.Text>}
                        extra={t("warrantyRequest.create.noteAttack")}
                      >
                        <UploadBannerButton />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft: "30px" }}>
                      <FormInput
                        type={INPUT_TYPE.INPUT_TYPE}
                        formItemOptions={{
                          label: <b>{t("productBrand.nameBrand")}</b>,
                          labelCol: { span: 24, offset: 0 },
                          name: "name",
                          rules: [
                            {
                              required: true,
                              message: t("productBrand.enterNameBrand"),
                            },
                            {
                              whitespace: true,
                              message: t("supplier.placeholderInput.whiteSpace"),
                            },
                          ],
                        }}
                        inputOptions={{
                          maxLength: 255,
                          placeholder: t("productBrand.enterNameBrand"),
                        }}
                      />
                      <span className="text-warning-edit-name">{t("productBrand.warningEditNameProductBrand")}</span>
                      <FormInput
                        type={INPUT_TYPE.TEXT_AREA}
                        formItemOptions={{
                          label: <b>{t("productBrand.description")}</b>,
                          labelCol: { span: 24, offset: 0 },
                          name: "description",
                        }}
                        inputOptions={{
                          rows: 5,
                          placeholder: t("productBrand.enterDescription"),
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card className="form-footer-product-brand">
                  <CustomModal
                    {...{
                      customComponent: <CustomButton title={t("productBrand.cancel")} />,
                      footer: false,
                      message: t("productBrand.messageCancel"),
                      centered: true,
                      onOke: onCancelCreate,
                    }}
                  />
                  <CustomButton
                    title={t("productBrand.save")}
                    htmlType={"submit"}
                    onClick={handleSubmitCreate}
                    loading={loadingUpdate}
                    type={"primary"}
                  />
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
export default EditProductBrand;
