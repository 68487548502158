/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Input, Tag, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { notify } from "utils/helperFuncs";
import "./index.scss";

const tagPlusStyle = {
  background: "transition",
  borderStyle: "dashed",
};

function InputTag({
  tags = [],
  onChange,
  moreThanNumber,
  searchResult = [],
  tagsValueResult,
  isHidden,
  idPopup,
  form,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState(true);
  const [visibleSearchResult, setVisibleSearchResult] = useState(false);

  const handleAdd = () => {
    const newTags = inputValue
      ?.split(/[, ]+/)
      ?.filter((item) => item.trim() !== "")
      ?.map((tag) => tag?.trim());
    if (newTags && !tags.includes(newTags)) {
      const newValues = [...tags, ...newTags.filter((tag) => tag !== "" && tags.indexOf(tag) === -1)];
      onChange(newValues);
      const commonElements = newTags?.filter((element) => tags?.includes(element));
      if (commonElements.length > 0) {
        commonElements.map((item) =>
          notify.error({
            message: t("serialIEDetail.not_recorded_duplicate_serial", {
              item: item,
            }),
          })
        );
      }
    }
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleRemove = (removedValue) => {
    const newValues = tags.filter((val) => val !== removedValue);
    onChange(newValues);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputPressEnter = () => {
    handleAdd();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // handleAdd();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchResult?.length) {
      setVisibleSearchResult(true);
      setVisibleTags(false);
    } else {
      setVisibleSearchResult(false);
      setVisibleTags(true);
    }
  }, [JSON.stringify(searchResult)]);

  return (
    <div className={`input-tag-container-create`}>
      <div className={`${isHidden && "isHidden"}`}>
        <div className="input-tag-tags">
          {tags?.length > 8 && idPopup === false && (
            <Tag className="input-tag-tag custom-tags-by-serial">
              <Typography.Link italic>{`+${tags?.length - 8} serial...`}</Typography.Link>
            </Tag>
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === false &&
            tags.slice(-8).map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val}
              </Tag>
            ))
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === true &&
            tags.map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val}
              </Tag>
            ))
          )}
          {visibleSearchResult &&
            searchResult?.map((val) => (
              <Tag className="input-tag-tag custom-tags-by-serial" key={val} closable onClose={() => handleRemove(val)}>
                {val}
              </Tag>
            ))}
        </div>
      </div>
      {idPopup ? (
        <></>
      ) : idPopup === false && inputVisible ? (
        <Input
          className="input-value input-enter-serial"
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 100,
          }}
          value={inputValue}
          onChange={handleChange}
          onPressEnter={handleInputPressEnter}
          onKeyDown={handleKeyDown}
          placeholder={t("serialImportEdit.newSerial")}
        />
      ) : (
        <Tag className="input-value input-enter-serial" onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> {t("serialIEDetail.addNew")}
        </Tag>
      )}
    </div>
  );
}

export default InputTag;
