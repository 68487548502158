import { Typography } from "antd";
import { t } from "i18next";

export const warrantyStatus = {
  STILL_VALIDATED: {
    text: t("warranty.warrantyDetail.active"),
    color: "#00ab78",
    backgroundColor: "#edf9f0",
    borderRadius: "8px",
    padding: "8px 24px",
  },
  EXPIRED: {
    text: t("warranty.warrantyDetail.unactive"),
    color: "#d2434d",
    backgroundColor: "#feefef",
    borderRadius: "8px",
    padding: "8px 24px",
  },
  INVALIDATED: {
    text: t("warranty.warrantyDetail.invalidated"),
    color: "#EF9351",
    backgroundColor: "rgb(255 220 194)",
    borderRadius: "8px",
    padding: "8px 24px",
  },
};

export const renderRemainingDeliverTime = (time) => {
  if (time >= 0) {
    return (
      <Typography.Text style={{ color: "#00AB78" }}>
        ({`${t("common.expired")}: ${time} ${t("common.day")}`})
      </Typography.Text>
    );
  }
  if (time < 0) {
    return (
      <Typography.Text style={{ color: "#D2434D" }}>({t("warrantyRequest.itemsStatus.soldDate")})</Typography.Text>
    );
  }
  return;
};

export const RenderRemainingDeliverTime = ({ time }) => {
  return (
    <Typography.Text strong style={{ color: time >= 0 ? "#00AB78" : "#D2434D" }}>
      ({time >= 0 ? `${t("common.expired")}: ${time} ${t("common.day")}` : t("warrantyRequest.itemsStatus.soldDate")})
    </Typography.Text>
  );
};

export const warrantyHistoryStatus = {
  CANCELED: {
    text: t("warrantyRequest.itemsStatus.canceled"),
    color: "#D2434D",
  },
  COMPLETED: {
    text: t("warrantyRequest.itemsStatus.completed"),
    color: "#00AB78",
  },
  CHECKING: {
    text: t("warrantyRequest.itemsStatus.checking"),
  },
  WAITING_FOR_CHECK: {
    text: t("warrantyRequest.itemsStatus.waitingForCheck"),
  },
  WAITING_FOR_DISTRIBUTE: {
    text: t("warrantyRequest.itemsStatus.waitinForDistribute"),
  },
  REPAIRING: {
    text: t("warrantyRequest.itemsStatus.repairing"),
  },
  WAITING_FOR_RETURN: {
    text: t("warrantyRequest.itemsStatus.waitingForReturn"),
  },
  DELIVERING: {
    text: t("warrantyRequest.itemsStatus.delivering"),
  },
};

export const RenderDelayTime = ({ time }) => {
  const text = `(${t("common.delay")}: ${time} ${t("common.day")})`; //namtc07 todo check time backend

  return <Typography.Text style={{ color: "#EF9351" }}>{time > 0 && text}</Typography.Text>;
};
