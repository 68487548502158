import { useGetWarrantyDetail } from "hooks/warranty";
import { useHistory, useParams } from "react-router-dom";

function useDetail() {
  const history = useHistory();

  const warrantyDetailID = useParams();

  const { data: warrantyDetail, loading } = useGetWarrantyDetail(warrantyDetailID);

  const handleClick = () => {
    history.push({
      pathname: "/warranty-request/create",
      state: {
        ownedSerials: [warrantyDetail?.serialImei],
        ownedSelected: [warrantyDetail],
      },
    });
  };

  return { warrantyDetail, handleClick, loading };
}

export default useDetail;
