import { convertShippingProviderOld } from "features/Cart/utils";
import { useGetShippingProviderOld } from "hooks/cart";

const useAdditionalDeliver = () => {
  const { data } = useGetShippingProviderOld();
  const shippingProviderOldOptions = convertShippingProviderOld(data);

  return { shippingProviderOldOptions };
};

export default useAdditionalDeliver;
