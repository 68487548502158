import { Form } from "antd";
import { EXPORT_WARRANTY_TYPE, NOTE_TYPE } from "features/WarrantyRequest/constant";
import { useExportFile } from "hooks/warranty";
import { t } from "i18next";
import printJS from "print-js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";

function usePopupSelectShipper({
  confirmReturn,
  countSelectedRowKeys,
  setIsFilterSelected,
  record,
  shippingType,
  itemIDs,
}) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleExportFile, loading: loadingExport } = useExportFile();
  const { id } = useParams();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const handleChange = (e) => {
    setIsFilterSelected && setIsFilterSelected(e.target.value);
  };

  const handleOk = async ({ isPrint }) => {
    const values = await form.validateFields();
    const warrantyRequestItemID = Array.isArray(record) ? record?.map((item) => item?.id) : record?.id;
    const params = {
      warrantyRequestItemIDs: warrantyRequestItemID || itemIDs,
      shippingType: shippingType,
      shippingConfig: values,
    };
    const handleConfirmReturn = () => {
      form
        .validateFields()
        .then(() => {
          confirmReturn(params);
          setIsModalOpen(false);
          form.resetFields();
        })
        .catch((info) => {
          console.info("Validate Failed:", info);
        });
    };
    if (!isPrint) {
      handleConfirmReturn();
    } else {
      handleExportFile({
        warrantyRequestID: Array.isArray(record) ? record && record[0]?.warrantyRequestID : record?.warrantyRequestID,
        warrantyRequestItemIDs: warrantyRequestItemID || itemIDs,
        noteType: NOTE_TYPE.RETURN_NOTE,
        fileType: EXPORT_WARRANTY_TYPE.PDF,
      })
        .then((response) => {
          const fileUrl = response.data.warrantyRequest.exportFile.url;
          printJS({
            printable: fileUrl,
            type: EXPORT_WARRANTY_TYPE.PDF.toLowerCase(),
            showModal: true,
            modalMessage: t("common.loadingData"),
          });
          handleConfirmReturn();
          form.resetFields();
          setIsModalOpen(false);
        })
        .catch((err) => {
          notify.error({ message: err.message });
        });
    }
  };

  return {
    form,
    handleOk,
    filterItemsTypeOptions,
    handleChange,
    isModalOpen,
    showModal,
    handleCancel,
    loadingExport,
  };
}

export default usePopupSelectShipper;
