import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

export const MenuLayout = ({ filterContent, resetFilter, applyFilter, style }) => {
  const { t } = useTranslation();

  const handleResetFilter = (e) => {
    resetFilter && resetFilter(e);
  };

  const handleApplyFilter = (e) => {
    applyFilter && applyFilter(e);
  };

  return (
    <div className="menu-layout-custom" style={style}>
      <div className="menu-header">
        <p className="title">{t("common.filter")}</p>
        <div className="setting">
          <span onClick={handleResetFilter}>{t("common.reset")}</span>&nbsp;&nbsp;
          <span className="apply" onClick={handleApplyFilter}>
            {t("common.apply")}
          </span>
        </div>
      </div>
      <div className="menu-content">{filterContent}</div>
    </div>
  );
};

export default MenuLayout;
