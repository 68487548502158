import { FontAwesomeIcon, Icon } from "assets/icons";
import CreateImportRequest from "features/Purchase/ImportRequest/Create";
import DetailImportRequest from "features/Purchase/ImportRequest/Detail";
import EditImportRequest from "features/Purchase/ImportRequest/Edit";
import ImportRequest from "features/Purchase/ImportRequest/List";
import PurchaseOrder from "features/Purchase/PurchaseOrder";
import PurchaseCreate from "features/Purchase/PurchaseOrder/PurchaseCreate";
import PurchaseDetail from "features/Purchase/PurchaseOrder/PurchaseDetail";
import PurchaseEdit from "features/Purchase/PurchaseOrder/PurchaseEdit";
import CreateSalesReturn from "features/Purchase/SalesReturn/Create";
import DetailSalesReturn from "features/Purchase/SalesReturn/Detail";
import EditSalesReturn from "features/Purchase/SalesReturn/Edit";
import SalesReturn from "features/Purchase/SalesReturn/List";

const router = [
  {
    key: "purchase",
    title: "purchase.titleMenu",
    icon: <FontAwesomeIcon icon={Icon.faShoppingBasket} />,
    children: [
      {
        key: "importRequest",
        title: "purchase.proposedPurchase",
        path: "/import-request",
        exact: true,
        component: ImportRequest,
      },
      {
        key: "salesReturn",
        title: "warehouse.salesReturn.salesReturn",
        path: "/sales-return",
        exact: true,
        component: SalesReturn,
      },
      {
        key: "purchaseOrder",
        title: "purchase.purchaseOrder",
        path: "/purchase-order",
        exact: true,
        component: PurchaseOrder,
      },
    ],
  },
  {
    key: "purchase-create",
    path: "/purchase/create",
    component: PurchaseCreate,
    exact: true,
  },
  {
    key: "purchase-detail",
    path: "/purchase/detail/:id",
    component: PurchaseDetail,
    exact: true,
  },
  {
    key: "purchase-edit",
    path: "/purchase-order/edit/:id",
    component: PurchaseEdit,
    exact: true,
  },
  {
    key: "importRequest-create",
    path: "/import-request/create",
    component: CreateImportRequest,
    exact: true,
  },
  {
    key: "importRequest-detail",
    path: "/import-request/detail/:id",
    component: DetailImportRequest,
    exact: true,
  },
  {
    key: "importRequest-edit",
    path: "/import-request/edit/:id",
    component: EditImportRequest,
    exact: true,
  },
  {
    key: "salesReturn-create",
    path: "/sales-return/create",
    component: CreateSalesReturn,
    exact: true,
  },
  {
    key: "salesReturn-detail",
    path: "/sales-return/detail/:id",
    component: DetailSalesReturn,
    exact: true,
  },
  {
    key: "salesReturn-edit",
    path: "/sales-return/edit/:id",
    component: EditSalesReturn,
    exact: true,
  },
];

export default router;
