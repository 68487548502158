import { Space, Tag, Tooltip, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import styles from "../../index.module.scss";
import { PRODUCT_TYPE } from "features/Products/constants";
import ModalCreateKey from "./ModalCreateKey";
import { UnitTimeComponent, TitleWithSubTitle, handleCopy, TagKeyLicenseAccount } from "./utils";

export function fillColumnsData(t, productId2Serials, setProductId2Serials, dataCreateExportKey) {
  const handleDeleteKey = (productId, serialId) => {
    let serials = productId2Serials?.[productId];
    serials = serials?.filter((item) => item.serialID !== serialId);
    let result = { ...productId2Serials, [productId]: serials };
    setProductId2Serials(result);
  };

  const indexCol = {
    title: <Typography.Text style={{ color: "rgba(91, 102, 115, 1)" }}>{t("v2.keyManagement.index")}</Typography.Text>,
    fixed: true,
    width: 40,
    align: "center",
    render: (_, record, index) => index + 1,
  };

  const parameterProductCol = {
    title: t("v2.keyManagement.parameter"),
    fixed: true,
    width: 300,
    render: (_, record) => {
      return (
        <Space style={{ width: "100%" }} direction="vertical">
          <Link target="_blank" to={`/product/detail/${record?.productID}`}>
            <Typography.Link strong>{record?.productCode}</Typography.Link>
          </Link>
          <CustomTextHiddenLine line={2} text={record?.productName} />
        </Space>
      );
    },
  };

  const warrantyCol = {
    title: t("v2.keyManagement.warranty"),
    align: "center",
    width: 100,
    render: (_, record) => <UnitTimeComponent time={record?.productWarranty} unit={record?.warrantyDateUnit} />,
  };

  const copyrightCol = {
    title: t("v2.keyManagement.copyrightTerm"),
    align: "center",
    width: 110,
    render: (_, record) => (
      <UnitTimeComponent time={record?.product?.copyrightTerm} unit={record?.product?.copyrightUnit} />
    ),
  };

  const orderQuantityCol = {
    title: (
      <TitleWithSubTitle
        title={t("v2.keyManagement.exportNoteCreate.table.header.quantity")}
        titleSubtitle={t("v2.keyManagement.exportNoteCreate.table.header.byOrder")}
        typeSub={"secondary"}
      />
    ),
    align: "center",
    width: 100,
    render: (_, record) => record?.quantity,
  };

  const currentQuantityCol = {
    title: (
      <TitleWithSubTitle
        title={t("v2.keyManagement.exportNoteCreate.table.header.quantity")}
        titleSubtitle={t("v2.keyManagement.exportNoteCreate.table.header.byCurrent")}
        typeSub={"secondary"}
      />
    ),
    align: "center",
    width: 100,
    render: (_, record) => {
      let currentQuantity = productId2Serials?.[record?.productID]?.length ?? 0;
      return (
        <Space direction="vertical">
          <Typography.Text>{currentQuantity}</Typography.Text>
          {currentQuantity < record?.quantity ? (
            <Typography.Text type="danger">{t("v2.keyManagement.exportNoteCreate.lack")}</Typography.Text>
          ) : currentQuantity > record?.quantity ? (
            <Typography.Text type="danger">{t("v2.keyManagement.exportNoteCreate.redundant")}</Typography.Text>
          ) : (
            <Typography.Text type="success">{t("v2.keyManagement.exportNoteCreate.enough")}</Typography.Text>
          )}
        </Space>
      );
    },
  };

  const keysCol = {
    title: (
      <TitleWithSubTitle
        title={t("v2.keyManagement.exportNoteCreate.table.header.keyDetail")}
        titleSubtitle={t("v2.keyManagement.exportNoteCreate.table.header.pleasePressEnter")}
        typeSub={"warning"}
      />
    ),
    align: "left",
    width: 500,
    render: (_, record) => {
      let serials = productId2Serials?.[record?.productID];
      let isKeyLicense = record?.product?.productType === PRODUCT_TYPE.KEY_LICENSE;

      return (
        <>
          {serials?.map((serial) => (
            <TagKeyLicenseAccount
              t={t}
              styles={styles}
              isKeyLicense={isKeyLicense}
              serial={serial}
              orderItem={record}
              handleDeleteKey={handleDeleteKey}
            />
          ))}
          <Tag className={[styles["input-tag"], styles["input-tag-popup"]]}>
            <ModalCreateKey
              t={t}
              orderItem={record}
              productId2Serials={productId2Serials}
              setProductId2Serials={setProductId2Serials}
              dataCreateExportKey={dataCreateExportKey}
            />
          </Tag>
        </>
      );
    },
  };

  const actionsCol = {
    fixed: "right",
    width: 30,
    align: "center",
    render: (_, record) => {
      let serials = productId2Serials?.[record?.productID];
      return (
        <Tooltip title="Copy">
          <CopyOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={() => handleCopy(t, serials)} />
        </Tooltip>
      );
    },
  };

  return [
    indexCol,
    parameterProductCol,
    warrantyCol,
    copyrightCol,
    orderQuantityCol,
    currentQuantityCol,
    keysCol,
    actionsCol,
  ];
}
