import { Dropdown, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon, Icon } from "assets/icons";
import { MenuLayout } from "components/CustomFilterMenu";
import {
  SELLER_CANCEL_REASON,
  SELLER_CANCEL_REASON_ARRAY,
  SELLER_CANCEL_REASON_LABEL,
  STAFF_CANCEL_REASON,
  STAFF_CANCEL_REASON_ARRAY,
  STAFF_CANCEL_REASON_LABEL,
} from "pages/App/Order/conts";
import { t } from "i18next";

const treeData = [
  {
    title: t(STAFF_CANCEL_REASON_LABEL.BY_STAFF),
    key: STAFF_CANCEL_REASON_ARRAY,
    children: [
      {
        title: t(STAFF_CANCEL_REASON_LABEL.NOT_ENOUGH),
        key: STAFF_CANCEL_REASON.NOT_ENOUGH,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.NOT_ENOUGH_DEBT_LIMIT),
        key: STAFF_CANCEL_REASON.NOT_ENOUGH_DEBT_LIMIT,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.CANNOT_IMPORT_GOODS),
        key: STAFF_CANCEL_REASON.CANNOT_IMPORT_GOODS,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE),
        key: STAFF_CANCEL_REASON.SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.OVERDUE_ORDER_PAYMENT),
        key: STAFF_CANCEL_REASON.OVERDUE_ORDER_PAYMENT,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.CHANGE_ORDER_INFORMATION_STAFF),
        key: STAFF_CANCEL_REASON.CHANGE_ORDER_INFORMATION_STAFF,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.CHANGE_PRODUCTS_IN_ORDER_STAFF),
        key: STAFF_CANCEL_REASON.CHANGE_PRODUCTS_IN_ORDER_STAFF,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE),
        key: STAFF_CANCEL_REASON.CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE,
      },
      {
        title: t(STAFF_CANCEL_REASON_LABEL.OTHER_STAFF),
        key: STAFF_CANCEL_REASON.OTHER_STAFF,
      },
    ],
  },
  {
    title: t(SELLER_CANCEL_REASON_LABEL.BY_SELLER),
    key: SELLER_CANCEL_REASON_ARRAY,
    children: [
      {
        title: t(SELLER_CANCEL_REASON_LABEL.CHANGE_ORDER_INFORMATION),
        key: SELLER_CANCEL_REASON.CHANGE_ORDER_INFORMATION,
      },
      {
        title: t(SELLER_CANCEL_REASON_LABEL.CHANGE_PRODUCTS_IN_ORDER),
        key: SELLER_CANCEL_REASON.CHANGE_PRODUCTS_IN_ORDER,
      },
      {
        title: t(SELLER_CANCEL_REASON_LABEL.FIND_A_BETTER_PLACE_TO_BUY),
        key: SELLER_CANCEL_REASON.FIND_A_BETTER_PLACE_TO_BUY,
      },
      {
        title: t(SELLER_CANCEL_REASON_LABEL.DELIVERY_TIME_IS_TOO_LONG),
        key: SELLER_CANCEL_REASON.DELIVERY_TIME_IS_TOO_LONG,
      },
      {
        title: t(SELLER_CANCEL_REASON_LABEL.NO_NEED_TO_BUY_ANY_MORE),
        key: SELLER_CANCEL_REASON.NO_NEED_TO_BUY_ANY_MORE,
      },
      {
        title: t(SELLER_CANCEL_REASON_LABEL.OTHER_SELLER),
        key: SELLER_CANCEL_REASON.OTHER_SELLER,
      },
    ],
  },
];

function FilterCancelReason({ keyObj, onFilter }) {
  const [expandedKeys, setExpandedKeys] = useState([...STAFF_CANCEL_REASON_ARRAY]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChecked = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
    setExpandedKeys(checkedKeys);
    setAutoExpandParent(true);
  };

  const handleFilter = () => {
    onFilter &&
      onFilter({
        [`${keyObj}`]: {
          staff: selectedKeys?.staff || [],
          seller: selectedKeys?.seller || [],
        },
      });
  };

  const handleResetFilter = () => {
    setCheckedKeys([]);
  };

  const getSelectedCategories = () => {
    const selectedCategories = {
      seller: [],
      staff: [],
    };

    checkedKeys.forEach((key) => {
      if (STAFF_CANCEL_REASON_ARRAY.includes(key)) {
        selectedCategories.staff.push(key);
      } else if (SELLER_CANCEL_REASON_ARRAY.includes(key)) {
        selectedCategories.seller.push(key);
      }
    });

    return setSelectedKeys(selectedCategories);
  };

  useEffect(() => {
    getSelectedCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(checkedKeys)]);

  return (
    <Dropdown
      overlayClassName="dropdown-overlay"
      trigger="click"
      overlay={
        <MenuLayout
          applyFilter={handleFilter}
          resetFilter={handleResetFilter}
          filterContent={
            <Tree
              height={400}
              checkable={true}
              selectable={false}
              showLine={{ showLeafIcon: false }}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              checkedKeys={checkedKeys}
              treeData={treeData}
              onCheck={onChecked}
            />
          }
        />
      }
      placement="bottomRight"
    >
      <FontAwesomeIcon icon={Icon.faFilter} />
    </Dropdown>
  );
}

export default FilterCancelReason;
