function useList({ dataDetail }) {
  const renderLack = ({ quantity, scanned }) => {
    if (scanned) {
      return quantity - scanned;
    } else {
      return quantity || 0;
    }
  };

  function getQuantityById(id) {
    const productObj = dataDetail?.items?.find((item) => item?.product?.id === id);
    if (productObj) {
      const quantityObj = dataDetail?.order?.items?.find((item) => item.productID === productObj?.product?.id);
      if (quantityObj) {
        return quantityObj.quantity;
      }
    }
    return null;
  }

  return { renderLack, getQuantityById };
}

export default useList;
