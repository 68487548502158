import { Space } from "antd";
import { TOTAL_ITEMS_STATUS } from "features/WarrantyRequest/constant";
import ModalEditSerial from "../../components/ModalEditSerial";
import useButtonGroup from "./useButtonGroup";

import {
  ButtonChecking,
  ButtonChooseProcess,
  ButtonComplete,
  ButtonCustomerDeli,
  ButtonGetResult,
  ButtonReturnWaitingForCheck,
  ButtonTransfer,
} from "features/WarrantyRequest/components/ButtonActions";

const ButtonGroup = ({ record, refetch, data, selectedRows, selectedRowKeys }) => {
  const {
    loadingConfirmSolution,
    loadingConfirmCheck,
    loadingConfirmDistribute,
    loadingComplete,
    loadingConfirmReturn,
    switchClose,
    loadingReturnWaitingForCheck,
    returnWaitingForCheck,
    confirmSolution,
    confirmCheck,
    confirmDistribute,
    complete,
    confirmReturn,
    handleOpenModal,
    handleCancelModal,
  } = useButtonGroup({
    refetch,
    itemID: record?.id,
  });

  const renderButtonByStatus = {
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_CHECK}`]: (
      <>
        <ButtonChecking
          confirmCheck={confirmCheck}
          loading={loadingConfirmCheck}
          switchClose={switchClose}
          handleOpen={handleOpenModal}
          handleCancel={handleCancelModal}
        />
        <ModalEditSerial status={record.status} record={record} />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.CHECKING}`]: (
      <>
        <ButtonChooseProcess record={record} loading={loadingConfirmSolution} confirmSolution={confirmSolution} />
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          returnWaitingForCheck={returnWaitingForCheck}
          record={record}
        />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE}`]: (
      <>
        <ButtonTransfer
          confirmDistribute={confirmDistribute}
          loading={loadingConfirmDistribute}
          solution={record?.solution}
          selectedRows={selectedRows}
        />
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          returnWaitingForCheck={returnWaitingForCheck}
          record={record}
        />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.REPAIRING}`]: (
      <>
        <ButtonGetResult refetch={refetch} record={record} solution={record?.solution} />
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          returnWaitingForCheck={returnWaitingForCheck}
          record={record}
        />
      </>
    ),
    [`${TOTAL_ITEMS_STATUS.WAITING_FOR_RETURN}`]: (
      <>
        <ButtonCustomerDeli record={record} loading={loadingConfirmReturn} confirmReturn={confirmReturn} data={data} />
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          returnWaitingForCheck={returnWaitingForCheck}
          record={record}
        />
      </>
    ),

    [`${TOTAL_ITEMS_STATUS.DELIVERING}`]: (
      <>
        <ButtonComplete complete={complete} loading={loadingComplete} />
        <ButtonReturnWaitingForCheck
          loading={loadingReturnWaitingForCheck}
          returnWaitingForCheck={returnWaitingForCheck}
          record={record}
        />
      </>
    ),

    [`${TOTAL_ITEMS_STATUS.COMPLETED}`]: (
      <ButtonReturnWaitingForCheck
        loading={loadingReturnWaitingForCheck}
        returnWaitingForCheck={returnWaitingForCheck}
        record={record}
      />
    ),
    [`${TOTAL_ITEMS_STATUS.CANCELED}`]: (
      <ButtonReturnWaitingForCheck
        loading={loadingReturnWaitingForCheck}
        returnWaitingForCheck={returnWaitingForCheck}
        record={record}
      />
    ),
  };

  return (
    <Space direction="vertical" style={{ alignItems: "center" }}>
      {renderButtonByStatus?.[`${record?.status}`]}
    </Space>
  );
};

export default ButtonGroup;
