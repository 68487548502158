import CustomTable from "components/CustomTable";
import { t } from "i18next";
import Toolbar from "./Toolbar";
import useCategoriesList from "./useCategoriesList";

function CategoriesList() {
  const { columns, newData, loading, handleSearch, onChangeParams, params } = useCategoriesList();

  return (
    <>
      <div className="class-custom">
        <Toolbar
          handleSearch={handleSearch}
          showButton={false}
          showFilterDate={false}
          placeholder={t("seller.categoriesList.placeHolder")}
          params={params}
          onChangeParams={onChangeParams}
        />
      </div>
      <CustomTable
        loading={loading}
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={newData}
        isLeftFooter={false}
        pagination
      />
    </>
  );
}

export default CategoriesList;
