import { Form } from "antd";
import { DATE_ISO_8601_FORMAT } from "config/constants";
import { isEditIcon } from "features/WarrantyRequest/constant";
import { useUploadDocument } from "hooks/upload-document";
import { useConfirmSolution, useGetStaff, useUpdateSerial } from "hooks/warranty";
import { t } from "i18next";
import { omit } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatDateTime } from "utils/dateTime";
import { notify } from "utils/helperFuncs";

function useModelEditSerial({ record, loadingDataSerial, refetch }) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProductCode, setNewProductCode] = useState();

  const { handleConfirmSolution, loading: loadingConfirmSolution } = useConfirmSolution();

  const confirmSolution = async ({ solution, solutionRequest }) => {
    await handleConfirmSolution({
      warrantyRequestItemIDs: record?.id,
      solution: solution,
      solutionRequest: solutionRequest,
    })
      .then(() => {
        notify.success({ message: t("notiActions.chooseProcess") });
      })
      .catch((error) => {
        notify.error({
          description: error?.message,
        });
      });
  };

  const [params, setParams] = useState({
    filters: {
      query: undefined,
      // permission: USER_PERMISSIONS.STAFF_ALL,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(params);

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const handleChange = (value, option) => {
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const { handleUpdateSerial, loading: loadingUpdate } = useUpdateSerial();
  const { handleUpload, loading: loadingImg } = useUploadDocument();

  const loading = loadingUpdate || loadingImg;

  const images = record?.imageURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const imagesResultFileURLs = record?.resultFileURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    serialImei: record?.serial,
    productID: record?.productID,
    productCode: record?.product?.code,
    sendNoteNumber: record?.sendNoteNumber,
    productName: record?.product?.name || record?.productName,
    categoryID: record?.categoryID,
    itemAmount: record?.itemAmount,
    warrantyRequestType: record?.warrantyRequestType,
    accessoryAttachment: record?.accessoryAttachment,
    promisedReturnDate: moment(record?.promisedReturnDate) || moment(record?.resultReceivedDate),
    defectDescription: record?.defectDescription,
    customerRequest: record?.customerRequest,
    imageURLs: images || [],
    solution: record?.solution,
    receivePartyPhone: record?.receivePartyPhone,
    warrantySendDate: moment(record?.warrantySendDate),
    technicalStaffID: record?.technicalStaff?.fullname,
    technicalStaffPhone: record?.technicalStaff?.telephone,
    receivePartyName: record?.receivePartyName,
    receivePartyAddress: record?.receivePartyAddress,
    shippingType: record?.shippingType,
    shippingConfiguration: {
      busConfig: {
        busName: record?.shippingConfig?.busConfig?.busName,
        telephone: record?.shippingConfig?.busConfig?.telephone,
      },
      deliver3PartyConfig: {
        name: record?.shippingConfig?.deliver3PartyConfig?.name,
      },
      urbanConfig: {
        fee: record?.shippingConfig?.urbanConfig?.fee,
      },
    },
    result: record?.result,
    alternativeProductCategoryID: record?.alternativeProductCategoryID,
    alternativeProductCode: record?.alternativeProductCode,
    alternativeProductName: record?.alternativeProductName,
    alternativeProductSerial: record?.alternativeProductSerial,
    resultFileURLs: imagesResultFileURLs || [],
    receiveAmount: record?.receiveAmount,
    returnAmount: record?.returnAmount,
    repairAccessoryName: record?.repairAccessoryName,
    alternativeAccessoryName: record?.alternativeAccessoryName,
    alternativeAccessoryStatus: record?.alternativeAccessoryStatus,
    repairCost: record?.repairCost,
  };

  const handleGetProduct = (values) => {
    const formData = form?.getFieldsValue();
    form.setFieldsValue({
      ...formData,
      productName: values?.name,
      productCode: values?.code,
    });
    setNewProductCode(values?.code);
  };

  const showModal = (status) => {
    setIsModalOpen(isEditIcon(status));
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
    form.setFieldsValue({ ...initialValues });
  };

  const uploadImage = async (values) => {
    const newImages = values?.filter((item) => !item?.url);
    const oldImageURLs = values?.filter((item) => item?.url)?.map((item) => item?.url);
    const newImageURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    }).catch((error) => {
      notify.error({
        message: t("uploadDocument.uploadImageError"),
        description: error?.message,
      });
    });
    const allImageURLs = [...oldImageURLs, ...newImageURLs?.map(({ url }) => url)];

    return allImageURLs;
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const newValues = omit(values, ["technicalStaffPhone"]);
      const imageURLs = await uploadImage(values?.imageURLs);
      // const resultFileURLs = await uploadImage(values?.resultFileURLs);

      const params = {
        id: record?.id,
        request: {
          ...newValues,
          //caching fieldname ( vì lúc này  init của technicalStaffID đang là label => record?.technicalStaff?.fullname === values?.technicalStaffID  )
          technicalStaffID:
            record?.technicalStaff?.fullname === values?.technicalStaffID
              ? record?.technicalStaff?.id
              : values?.technicalStaffID,
          promisedReturnDate: formatDateTime(values?.promisedReturnDate, DATE_ISO_8601_FORMAT),
          warrantySendDate: formatDateTime(values?.warrantySendDate, DATE_ISO_8601_FORMAT),
          imageURLs: imageURLs,
          // resultFileURLs: resultFileURLs,
        },
      };
      await handleUpdateSerial(params)
        .then((res) => {
          setIsModalOpen(!isModalOpen);
          notify.success({
            message: `Serial/IMEI đã được cập nhật lại thông tin sản phẩm. Serial/IMEI ${
              record?.serial
            } thuộc sản phẩm ${newProductCode ?? record?.product?.code} ${values?.productName}`,
          });
        })
        .catch((err) => {
          console.info(err);
          notify.error({ message: err.message });
        });
    } catch (error) {
      notify.error({
        message: error?.message,
      });
    }
  };

  useEffect(() => {
    setNewProductCode(record?.product?.code);
  }, [JSON.stringify(record)]);

  return {
    isModalOpen,
    form,
    showModal,
    handleCancel,
    initialValues,
    handleOk,
    loading,
    confirmSolution,
    loadingConfirmSolution,
    staffs,
    onLoadMoreData,
    hasMoreData,
    onSearch,
    handleChange,
    handleGetProduct,
  };
}

export default useModelEditSerial;
