import { Button } from "antd";
import LoadingComponent from "components/LoadingComponent";
import { useCompleteAssembly, useOrderPermissions } from "hooks/order";
import React from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";

const WaitingAssemblyButtons = ({ checkedKeyList, resetCheckedList }) => {
  const { t } = useTranslation();

  const { handleCompleteAssembly, loading } = useCompleteAssembly();
  const { canCompleteAssembly } = useOrderPermissions();

  const handleWaitingForAssembly = async () => {
    if (checkedKeyList?.length === 0) {
      notify.error({
        message: t("order.orderList.selectOrderValidate"),
      });
    } else {
      await handleCompleteAssembly({
        orderIDs: checkedKeyList,
      })
        .then(() => {
          notify.success({
            message: t("order.orderList.confirmAssembleSuccess"),
          });
          resetCheckedList && resetCheckedList();
        })
        .catch((error) => {
          notify.error({
            message: t("order.orderList.confirmAssembleError"),
            description: error.message,
          });
        });
    }
  };

  return (
    <LoadingComponent loading={loading}>
      <div className="button-group-of-2">
        {canCompleteAssembly && (
          <>
            <Button className="btn-main-action" onClick={handleWaitingForAssembly}>
              {t("order.orderDetail.assembleConfirmation")}
            </Button>
          </>
        )}
      </div>
    </LoadingComponent>
  );
};

export default WaitingAssemblyButtons;
