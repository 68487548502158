import React, { useState } from "react";
import { t } from "i18next";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { actionActivityLogsOptions, convertActionActivityLogs } from "features/Agent/constants";
import { getQuery } from "utils/helperFuncs";
import { useParams } from "react-router-dom";
import { useGetActivityLogs } from "hooks/seller";
import { useGetCreatedBy } from "hooks/order";
import { FilterLoadMore } from "components/CustomFilterMenu";
import TextAlignLeft from "components/TextAlignLeft";

const SORT_TYPES = {
  ascend: "UPDATED_AT_ASC",
  descend: "UPDATED_AT_DESC",
};

const typesActivityLogs = {
  SUPPLIER: "SUPPLIER",
  SELLER: "SELLER",
};

function useActivityLogs({ isSupplier }) {
  const { id } = useParams();
  const GET_QUERY = getQuery();
  const [paramCreatedBy, setParamCreatedBy] = useState({
    pagination: { offset: 0, limit: 10 },
    filters: { query: "" },
  });
  const [params, setParams] = useState({
    sellerIDs: id,
    filters: {
      updatedAt: GET_QUERY?.updatedAt || undefined,
      status: GET_QUERY?.status || undefined,
      action: GET_QUERY?.action || undefined,
      updatedByIDs: GET_QUERY?.updatedByIDs || [],
      updatedAtTimeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      orderBy: GET_QUERY?.orderBy || undefined,
      types: isSupplier ? typesActivityLogs.SUPPLIER : typesActivityLogs.SELLER,
    },
    pagination: {
      offset: GET_QUERY?.offset || 0,
      limit: 10,
    },
  });
  const { data: activityLogs, loading, paginationData } = useGetActivityLogs(params);
  const { data: createdByOptions, loadMoreDataCreatedBy } = useGetCreatedBy(paramCreatedBy);

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    });
  };
  const onSearchTextChange = (values) => {
    setParamCreatedBy({
      ...paramCreatedBy,
      pagination: { offset: 0, limit: 10 },
      filters: { query: values },
    });
  };
  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  function onTableChange(pagination, filters, sorter) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
      filters: {
        ...params.filters,
        orderBy: sorter?.order ? SORT_TYPES[`${sorter?.order}`] : undefined,
      },
    };

    setParams(newParams);
  }

  const columns = [
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.dateUpdate")}</span>,
      align: "center",
      width: "5%",
      dataIndex: "updatedAt",
      render: (_, record) => record?.updatedAt,
      sorter: true,
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.action")}</span>,
      align: "center",
      dataIndex: "action",
      width: "15%",
      render: (_, record) => t(convertActionActivityLogs(record?.action)),
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("action", value)} type="radio" options={actionActivityLogsOptions} />
      ),
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.detail")}</span>,
      align: "center",
      dataIndex: "detail",
      render: (_, record) => {
        return <TextAlignLeft value={record?.detail} />;
      },
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.updateBy")}</span>,
      align: "center",
      dataIndex: "updatedBy",
      render: (_, record) => record?.updatedBy?.fullname || "--",
      filterDropdown: (
        <FilterLoadMore
          onFilter={(value) => onFilter("updatedByIDs", value)}
          data={createdByOptions}
          onLoadMoreData={loadMoreDataCreatedBy}
          onSearchTextChange={onSearchTextChange}
          searchable
        />
      ),
    },
  ];

  return {
    createdByOptions,
    columns,
    onChangeParams,
    onTableChange,
    activityLogs,
    params,
    loading,
    paginationData,
  };
}

export default useActivityLogs;
