import { Typography } from "antd";
import { ORDER_PRIORITY, ORDER_TYPE, PAYMENT_METHOD, SHIPPING_TYPE } from "features/Cart/constants";
import i18n from "locales/i18n";
import { includes } from "lodash";
import { CUSTOMER_PAYMENT_METHOD, SHIPPING_STATUS } from "pages/App/Order/conts";
import {
  ORDER_STATUS,
  RENDER_ACTUAL,
  RENDER_ACTUAL_AND_RENDER_AVAILABLE,
  RENDER_AVAILABLE,
  STOCK_STATUS_TYPE,
} from "./constants";

export const renderLabelStatus = {
  [`${ORDER_STATUS.WAITING_FOR_APPROVAL_ORDER}`]: (
    <Typography.Text style={{ color: "#052987" }}>
      {i18n.t("order.orderStatus.waitingForApprovalOrder")}
    </Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK}`]: (
    <Typography.Text style={{ color: "#052987" }}>
      {i18n.t("order.orderStatus.waitingForApprovalStock")}
    </Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForEnoughStock")}</Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_PAYMENT}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForPayment")}</Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_PACKAGING}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForPackaging")}</Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_EXPORTING}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForExporting")}</Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_ASSEMBLY}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForAssembly")}</Typography.Text>
  ),
  [`${ORDER_STATUS.WAITING_FOR_SHIPPING}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.waitingForDelivery")}</Typography.Text>
  ),
  [`${ORDER_STATUS.DELIVERING}`]: (
    <Typography.Text style={{ color: "#052987" }}>{i18n.t("order.orderStatus.delivering")}</Typography.Text>
  ),
  [`${ORDER_STATUS.COMPLETED}`]: (
    <Typography.Text style={{ color: "#00AB78" }}>{i18n.t("order.orderStatus.completed")}</Typography.Text>
  ),
  [`${ORDER_STATUS.CANCELED}`]: (
    <Typography.Text style={{ color: "#D2434D" }}>{i18n.t("order.orderStatus.cancel")}</Typography.Text>
  ),
};

export const isCanceledStatus = (status) => status === ORDER_STATUS.CANCELED;

export const isCompleteStatus = (status) => status === ORDER_STATUS.COMPLETED;

export const isDeliveringStatus = (status) => status === ORDER_STATUS.DELIVERING;

export const isWaitingForShippingStatus = (status) => status === ORDER_STATUS.WAITING_FOR_SHIPPING;

// export const isDeliveringStatus = (status) => status === ORDER_STATUS.;

export const isPast = (state) => state === "PAST";

export const isFuture = (state) => state === "FUTURE";

export const isCurrent = (state) => state === "CURRENT";

export const renderOrderType = {
  [`${ORDER_TYPE.CUSTOMER}`]: i18n.t("order.orderType.customer"),
  [`${ORDER_TYPE.SELLER}`]: i18n.t("order.orderType.seller"),
};

export const renderShippingType = {
  [`${SHIPPING_TYPE.BUS}`]: i18n.t("cart.shippingType.bus"),
  [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: i18n.t("cart.shippingType.deliver3Party"),
  [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: i18n.t("cart.shippingType.pickUpCounter"),
  [`${SHIPPING_TYPE.URBAN_COD}`]: i18n.t("cart.shippingType.urbanCOD"),
};

export const renderOrderPriority = {
  [`${ORDER_PRIORITY.LOW}`]: i18n.t("cart.orderPriority.low"),
  [`${ORDER_PRIORITY.MEDIUM}`]: i18n.t("cart.orderPriority.medium"),
  [`${ORDER_PRIORITY.HIGH}`]: i18n.t("cart.orderPriority.high"),
};

export const renderPaymentMethod = {
  [`${PAYMENT_METHOD.CASH}`]: i18n.t("cart.paymentMethods.cash"),
  [`${PAYMENT_METHOD.DEBT}`]: i18n.t("cart.paymentMethods.debt"),
};

export const renderCustomerPaymentMethod = {
  [`${CUSTOMER_PAYMENT_METHOD.CASH}`]: i18n.t("cart.customPaymentMethods.cash"),
  [`${CUSTOMER_PAYMENT_METHOD.COD}`]: i18n.t("cart.customPaymentMethods.cod"),
};

export const isRenderAvailableColumn = (status) => includes(RENDER_AVAILABLE, status);

export const isRenderActualColumn = (status) => includes(RENDER_ACTUAL, status);

export const isRenderActualAndAvailableColumn = (status) => includes(RENDER_ACTUAL_AND_RENDER_AVAILABLE, status);

export const isOrderTypeCustomer = (orderType) => orderType === ORDER_TYPE.CUSTOMER;

export const isPaymentMethodCash = (paymentMethod) => paymentMethod === PAYMENT_METHOD.CASH;

export const isCustomerPaymentMethod = (customerPaymentMethod) =>
  customerPaymentMethod === CUSTOMER_PAYMENT_METHOD.CASH;

export const isStockStatusFull = (stockStatus) => stockStatus === STOCK_STATUS_TYPE.FULL;

export const isShippingTypeUrbanCOD = (shippingType) => shippingType === SHIPPING_TYPE.URBAN_COD;

export const isShippingTypeBus = (shippingType) => shippingType === SHIPPING_TYPE.BUS;

export const isShippingTypePickUpCounter = (shippingType) => shippingType === SHIPPING_TYPE.PICK_UP_COUNTER;

export const isShippingTypeDeliver3Party = (shippingType) => shippingType === SHIPPING_TYPE.DELIVER_3PARTY;

export const isRenderImageLibrary = (status) => ORDER_STATUS.COMPLETED === status;

export const isRenderCancellationReason = (status) => ORDER_STATUS.CANCELED === status;

export const isShippingStatusWaitingForShippingWaiting = (shipping) => shipping === SHIPPING_STATUS.WAITING;

export const isTabOrderWaitingForShippingNotSet = (status, shipping) =>
  shipping === SHIPPING_STATUS.NOT_SET && status === ORDER_STATUS.WAITING_FOR_SHIPPING;
export const isTabOrderWaitingForShippingWaiting = (status, shipping) =>
  shipping === SHIPPING_STATUS.WAITING && status === ORDER_STATUS.WAITING_FOR_SHIPPING;
export const isTabOrderDelivering = (status) => status === ORDER_STATUS.DELIVERING;
export const isTabOrderComplete = (status) => status === ORDER_STATUS.COMPLETED;
export const isTabOrderCancel = (status) => status === ORDER_STATUS.CANCELED;
