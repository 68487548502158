import React from "react";
import { Form } from "antd";
import { generateGreetings } from "utils/helperFuncs";
import ChartSelectUser from "./components/ChartSelectUser";
import ReportChart from "./components/ReportChart";
import styles from "./index.module.scss";
import useSaleChartUi from "./useSaleChartUi";
import LoadingComponent from "components/LoadingComponent";
import Page403 from "pages/PageError/403";

const SaleChartUI = () => {
  const {
    GREETING,
    me,
    form,
    chartData,
    handleFormChange,
    loadingChart,
    fetchChartToken,
    renderSubtitle,
    reportPermission,
    canViewSaleChart,
  } = useSaleChartUi();

  return (
    <LoadingComponent loading={loadingChart}>
      {canViewSaleChart ? (
        <div className={styles["container"]}>
          <div className={styles["header"]}>
            <div className={styles["welcome"]}>
              <div className={styles["title"]}>{`${GREETING[`${generateGreetings()}`]}, ${me?.fullname || "HAC"}`}</div>
              <div className={styles["subTitle"]}>{renderSubtitle()}</div>
            </div>
            <Form form={form} onValuesChange={handleFormChange}>
              <div className={styles["selection-group"]}>
                <ChartSelectUser reportPermission={reportPermission} />
                {/* <Form.Item name="datePicker">
              <CustomRangePicker
                defaultOptionKey="yesterday"
                buttonLabel={t('report.dateFilter')}
                dropdownAlign={{ offset: [-165, 0] }}
                options={['yesterday', 'lastWeek', 'lastMonth', 'lastYear', 'custom']}
              />
            </Form.Item> */}
              </div>
            </Form>
          </div>
          <div className={styles["charts"]}>
            <ReportChart chartData={chartData} loadingChart={loadingChart} fetchChartToken={fetchChartToken} />
          </div>
        </div>
      ) : (
        <Page403 />
      )}
    </LoadingComponent>
  );
};

export default SaleChartUI;
