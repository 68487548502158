import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "components/CustomCard";
import ExpandableText from "components/CustomExpandableText";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { PRIORITY_CUSTOMER } from "features/PotentialCustomer/constants";
import { useGetCategoriesLevelCount } from "hooks/category/category";
import { t } from "i18next";
import { handleSearchSelect } from "utils/helperFuncs";

const convertOptions = (data) => {
  return data
    ? data.map(({ id, name, permissions }) => ({
        label: name,
        value: id,
        permissions: permissions,
      }))
    : [];
};

function CareInfoCard({ notBordered, dataMulti, loadingDetail }) {
  const { data: dataAllCategories } = useGetCategoriesLevelCount(3);

  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t("potentialCustomer.form.careInfo")}
        </Typography.Text>
      }
      content={
        <Skeleton active loading={loadingDetail}>
          <Row className="content-care-info">
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.classify")}</Typography.Text>,
                  labelAlign: "left",
                  name: "priority",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.classify"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  options: [
                    { value: PRIORITY_CUSTOMER.HIGH, label: t("potentialCustomer.priorityCustomerHigh") },
                    { value: PRIORITY_CUSTOMER.LOW, label: t("potentialCustomer.priorityCustomerLow") },
                  ],
                  allowClear: true,
                  placeholder: t("potentialCustomer.placeHolder.classify"),
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.CURRENCY_INPUT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.expectedRevenue")}</Typography.Text>,
                  labelAlign: "left",
                  name: "expectedRevenue",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.expectedRevenue"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.expectedRevenue"),
                  maxLength: 13,
                }}
              />
            </Col>
            <Col span={24}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.industryOfInterested")}</Typography.Text>,
                  labelAlign: "left",
                  name: "interestedCategoryIDs",
                  labelCol: { span: 8 },
                  rules: [
                    {
                      required: false,
                      message: t("common.messageRequiredSelect", {
                        message: t("potentialCustomer.form.industryOfInterested"),
                      }),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  mode: "multiple",
                  allowClear: true,
                  showSearch: true,
                  optionFilterProp: "label",
                  placeholder: t("potentialCustomer.placeHolder.industryOfInterested"),
                  filterOption: (search, option) => handleSearchSelect(search, option),
                  options: convertOptions(dataAllCategories),
                }}
              />
            </Col>
            <Col span={24}>
              {notBordered ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.note")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <ExpandableText value={dataMulti?.note || "--"} />
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.TEXT_AREA}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.note")}</Typography.Text>,
                    labelAlign: "left",
                    name: "note",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: false,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.note"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{ ...notBordered, placeholder: t("potentialCustomer.placeHolder.note") }}
                />
              )}
            </Col>
          </Row>
        </Skeleton>
      }
    />
  );
}

export default CareInfoCard;
