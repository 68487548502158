import { useEffect, useState } from "react";

const useModalChangeAddress = ({ id }) => {
  const [selected, setSelected] = useState(id);

  useEffect(() => {
    setSelected(id);
  }, [id]);

  const handleClick = (id) => {
    setSelected(id);
  };

  const handleCancel = () => {
    setSelected(id);
  };

  return {
    selected,
    handleClick,
    handleCancel,
  };
};

export default useModalChangeAddress;
