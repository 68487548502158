import React from "react";
import { Col, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import InputSearch from "features/Warranty/WarrantyList/component/InputSearch";
import RadioGroup from "features/Warranty/WarrantyList/component/RadioGroup";
import useWarrantyList from "features/Warranty/WarrantyList/useWarrantyList";
import { t } from "i18next";
import CustomTable from "components/CustomTable";
import ButtonAdd from "features/Warranty/WarrantyList/component/ButtonAdd";
import ModalAddProduct from "../ModalAddProduct";
import { useHistory } from "react-router-dom";

function PopupCreateSelect({ button, selectedRows }) {
  const {
    rowSelection,
    handleChangeTable,
    paginationData,
    data,
    loading,
    selectedProduct,
    handleSearch,
    filterItemsTypeOptions,
    handleChange,
    columnsPopup,
    handleSelectedData,
  } = useWarrantyList();
  const history = useHistory();

  function handleSubmitNewProduct(values) {
    history.push({
      pathname: "/warranty-request/create",
      state: {
        notOwnedSerials: [values],
      },
    });
  }

  return (
    <CustomModal
      {...{
        customComponent: button,
        footer: false,
        title: <Typography.Title level={4}>{t("warrantyRequest.create.pleaseSelectSerial")}</Typography.Title>,
        width: "80%",
        hideConfirmButton: true,
        addMoreButton: (
          <ButtonAdd
            handleSelectedData={handleSelectedData}
            selectedProduct={selectedProduct}
            title={t("warrantyRequest.create.addProduct")}
          />
        ),
      }}
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <InputSearch onSearch={handleSearch} />
        </Col>
        <Col span={24}>
          <RadioGroup filterItemsTypeOptions={filterItemsTypeOptions} handleChange={handleChange} />
        </Col>
        <Col span={24}>
          <CustomTable
            columns={columnsPopup}
            dataSource={selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows : data}
            onChange={handleChangeTable}
            rowSelection={{
              ...rowSelection,
            }}
            rowKey={(record) => record?.id}
            loading={loading}
            pagination={{
              total: selectedProduct?.isFilterSelected ? selectedProduct?.selectedRows?.length : paginationData?.total,
              pageSize: paginationData?.limit,
              current: paginationData?.offset / paginationData?.limit + 1 || 1,
              showSizeChanger: false,
            }}
            footer={() => {
              return (
                <ModalAddProduct supportAddMore={false} selectedRows={selectedRows} onSubmit={handleSubmitNewProduct} />
              );
            }}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}

export default PopupCreateSelect;
