import { useQuery } from "@apollo/client";
import { GET_PERMISSIONS } from "graphql/permission/query";

export const useGetPermissions = () => {
  const { loading, data, refetch } = useQuery(GET_PERMISSIONS);

  return {
    permissions: data?.permission?.list,
    loading,
    refetch,
  };
};
