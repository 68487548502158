import { parseIsoDateStringToMoment } from "utils/helperFuncs";

const convertAddress = (address, ward, district, city) => {
  return [address, ward, district, city].filter((item) => !!item).join(", ");
};
const convertShippingConfig = (data) => {
  const dataShippingConfig = {
    busConfig: {
      busName: data?.busConfig?.busName ? data?.busConfig?.busName : "",
      telephone: data?.busConfig?.telephone ? data?.busConfig?.telephone : "",
      location: data?.busConfig?.location ? data?.busConfig?.location : "",
      busStation: data?.busConfig?.busStation ? data?.busConfig?.busStation : "",
      fee: 0,
    },
    deliver3PartyConfig: {
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      fee: data?.deliver3PartyConfig?.fee,
      name: data?.deliver3PartyConfig?.name,
      serviceCode: data?.deliver3PartyConfig?.serviceCode,
      customFeeEnabled: false,
      customFee: data?.deliver3PartyConfig?.customFee,
      carrierMaxFee: data?.deliver3PartyConfig?.carrierMaxFee,
      carrierMinFee: data?.deliver3PartyConfig?.carrierMinFee,
      providerID: data?.deliver3PartyConfig?.providerID ? data?.deliver3PartyConfig?.providerID : null,
      carrierMethod: data?.deliver3PartyConfig?.carrierMethod,
    },
    urbanCODConfig: {
      fee: data?.urbanConfig?.fee ? data?.urbanConfig?.fee : 0,
    },
  };

  return {
    ...dataShippingConfig,
  };
};

const convertSender = (data) =>
  data?.map((item) => ({
    id: `${item?.fullName}${item?.telephone}`,
    fullName: item?.fullName,
    address: convertAddress(item?.address, item?.ward?.name, item?.district?.name, item?.city?.name),
    telephone: item?.telephone,
    isDefault: item?.isDefault,
  }));

export const convertGetCart = (data) => ({
  sellerID: data?.sellerID,
  seller: {
    id: data?.seller?.id,
    fullName: data?.seller?.fullName,
    telephone: data?.seller?.telephone,
    code: data?.seller?.code,
    address: convertAddress(
      data?.seller?.address,
      data?.seller?.ward?.name,
      data?.seller?.district?.name,
      data?.seller?.city?.name
    ),
    debtLimit: data?.seller?.debtLimit,
    currentAvailable: data?.seller?.currentAvailable,
    availabilityLimit: data?.seller?.availabilityLimit,
    isActive: data?.seller?.isActive,
    currentDebt: data?.seller?.currentDebt,
    warehouses: data?.seller?.warehouses,
    vatInfo: data?.seller?.vatInfo,
  },
  exportedWarehouse: { ...data?.exportedWarehouse },
  exportedWarehouseID: data?.exportedWarehouse?.id,
  deliveryDeadline: data?.deliveryDeadline ? parseIsoDateStringToMoment(data?.deliveryDeadline) : null,
  promisedDeliverDate: data?.deliveryDeadline ? parseIsoDateStringToMoment(data?.deliveryDeadline) : null,
  listProducts: data?.items,
  totalProduct: data?.totalProduct,
  temporaryTotal: data?.subTotal,
  sender: {
    id: `${data?.senderName}${data?.senderPhone}`,
    fullName: data?.senderName,
    telephone: data?.senderPhone,
    address: data?.senderAddress,
  },
  shippingContact: {
    id: data?.shippingContact?.id,
    fullName: data?.shippingContact?.fullName,
    telephone: data?.shippingContact?.telephone,
    address: convertAddress(
      data?.shippingContact?.address,
      data?.shippingContact?.ward?.name,
      data?.shippingContact?.district?.name,
      data?.shippingContact?.city?.name
    ),
    wardID: data?.shippingContact?.wardID,
    districtID: data?.shippingContact?.districtID,
    cityID: data?.shippingContact?.cityID,
  },
  note: data?.note,
  internalNote: data?.internalNote,
  shippingType: data?.shippingType,
  shippingConfiguration: convertShippingConfig(data?.shippingConfiguration),
  orderPriority: data?.orderPriority,
  fullStockRequired: data?.fullStockRequired,
  paymentMethod: data?.paymentMethod,
  vat: data?.vat,
  vatAllOrder: data?.vatAllOrder,
  vatTaxIDNumber: data?.vatTaxIDNumber,
  vatCompanyName: data?.vatCompanyName,
  vatEmail: data?.vatEmail,
  vatAddress: data?.vatAddress,
  shippingFee: data?.shippingFee,
  serviceFee: data?.serviceFee,
  temporaryServiceFee: data?.temporaryServiceFee,
  total: data?.total,
  discountAmount: data?.discountAmount,
  paymentByCash: data?.cashPayment,
  paymentByDebit: data?.debtPayment,
  sendingAddress: {
    wardID: data?.sendingWard?.id,
    districtID: data?.sendingWard?.district?.id,
    cityID: data?.sendingWard?.district?.cityID,
  },
  allSenders: convertSender(data?.allSenders),
  customerSubTotal: data?.customerSubTotal,
  customerTotal: data?.customerTotal,
  orderType: data?.orderType,
  assemblyRequired: data?.assemblyRequired,
  customerPaymentMethod: data?.customerPaymentMethod,
  totalServiceProduct: data?.totalServiceProduct,
  quantityServiceProduct: data?.quantityServiceProduct,
});

export const convertShippingProviderOld = (data) => data?.map(({ id, name }) => ({ value: id, label: name }));
