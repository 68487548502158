import { QuestionCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PrimaryButton } from "components/PrimaryButton";
import { useLinkCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "utils/helperFuncs";

function LinkButton({ customerId, form, dataSelected, onCloseDrawer }) {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const { handleLinkCustomer, loading } = useLinkCustomer();

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpen = async () => {
    await form.validateFields();
    setShowModal(true);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const IS_APPROVED_SELLER = dataSelected?.status === "APPROVED_SELLER";
      await handleLinkCustomer({
        customerID: customerId,
        existedCustomerID: !IS_APPROVED_SELLER ? dataSelected?.potentialCustomerID : undefined,
        existedSellerID: IS_APPROVED_SELLER ? dataSelected?.sellerID : undefined,
      })
        .then((res) => {
          handleCancel();
          notify.success({ message: t("potentialCustomer.linkSuccess") });
        })
        .then(() => {
          if (IS_APPROVED_SELLER) {
            history.push(`/seller/detail/${dataSelected?.sellerID}`);
            onCloseDrawer();
          } else {
            onCloseDrawer();
          }
        })
        .catch((error) => {
          notify.error({
            message: t("potentialCustomer.linkFail"),
          });
          handleOpen();
        });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={showModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      onOke={onSubmit}
      buttonLoading={loading}
      customComponent={
        <PrimaryButton
          title={
            <Typography.Text style={{ color: "white" }}>{t("potentialCustomer.buttonGroup.link")}</Typography.Text>
          }
        />
      }
      children={<Typography.Text>{t("potentialCustomer.linkQuestion")}</Typography.Text>}
      customIcon={
        <QuestionCircleFilled
          style={{
            fontSize: "50px",
            color: "#2246DC",
            padding: "20px",
            borderRadius: 50,
            background:
              "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
          }}
        />
      }
    />
  );
}

export default LinkButton;
