import React from "react";
import { t } from "i18next";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import useCreate from "./useCreate";
import FormSellerGroup from "../Form";

import "./index.scss";

function CreateSellerGroup({ data, refetch, customComponent, formCreateSeller }) {
  const { handleOpen, form, createSellerGroup, switchClose, loading, initialValues } = useCreate({
    formCreateSeller,
    refetch,
  });

  return (
    <CustomModal
      {...{
        onOke: createSellerGroup,
        okeButtonLabel: t("customer.modal.addNew"),
        customComponent: customComponent || <CreateButton title={t("customer.addCustomer")} className="btn-create" />,

        title: <span className="add-customer-title">{t("customer.modal.title")}</span>,
        centered: true,
        footer: false,
        width: "fit-content",
        switchClose,
        afterClose: refetch,
        onOpen: handleOpen,
        buttonLoading: loading,
        isBlockCloseOnOke: true,
      }}
    >
      <FormSellerGroup form={form} initialValues={initialValues} data={data} />
    </CustomModal>
  );
}

export default CreateSellerGroup;
