import { INPUT_TYPE } from "config/constants";
import { useCities, useDistricts, useWards } from "hooks/common";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { handleSearchSelect, validator } from "utils/helperFuncs";

const useAddressForm = ({ cityId, districtId, form }) => {
  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });

  const { cities } = useCities();
  const { districts, refetch: refetchDistricts } = useDistricts(location?.cityId || cityId);
  const { wards, refetch: refetchWards } = useWards(location?.districtId || districtId);

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }

  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  useEffect(() => {
    if (location?.cityId) {
      refetchDistricts(location?.cityId);
    }
    if (location?.districtId) {
      refetchWards(location?.districtId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const formFields = [
    {
      formItemOptions: {
        label: t("seller.labelInput.fullName"),
        name: "fullName",
        rules: [
          {
            whitespace: true,
            required: true,
            message: t("seller.validateMessage.fullName"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.fullName"),
        maxLength: 50,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.email"),
        name: "email",
        rules: [
          {
            whitespace: true,
            required: false,
            message: t("seller.validateMessage.email"),
          },
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.email"),
        maxLength: 50,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.telephone"),
        name: "telephone",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.telephone"),
          },
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.telephone"),
        maxLength: 10,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.cityID"),
        name: "cityID",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.cityID"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.cityID"),
        maxLength: 50,
        options: cities?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        onChange: (value) => handleChangeCity(value),
        showSearch: true,
        allowClear: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.districtID"),
        name: "districtID",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.districtID"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.districtID"),
        maxLength: 50,
        options: districts?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        allowClear: true,
        onChange: handleChangeDistrict,
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.wardID"),
        name: "wardID",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.wardID"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.wardID"),
        maxLength: 50,
        options: wards?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        allowClear: true,
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
      },
    },
  ];

  return { formFields };
};

export default useAddressForm;
