import { Tag } from "antd";
import { typeExport, typeImport } from "features/Serial/constant";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";

const useList = (id) => {
  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };
  const columns = [
    {
      title: t("serialIE.stt"),
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: t("serialDetail.DATE"),
      width: 150,
      render: (_, record) =>
        checkNullValue(
          formatDateTime(record?.serialExportNoteItem?.createdAt || record?.serialImportNoteItem?.createdAt)
        ),
    },
    {
      title: t("account.STATUS"),
      render: (_, record) =>
        record?.serialImportNoteItem ? <Tag color="green">Nhập serial</Tag> : <Tag color="orange">Xuất serial</Tag>,
    },
    {
      title: t("serialDetail.TYPEEXPORTIMPORT"),
      render: (_, record) =>
        checkNullValue(
          typeImport?.[record?.serialImportNoteItem?.serialItems[0]?.serialImportNoteItem?.serialImportNote?.type] ||
            typeExport?.[record?.serialExportNoteItem?.serialExportNote?.type]
        ),
    },
    {
      title: t("serialDetail.SALE/PROVIDER"),
      render: (_, record) =>
        checkNullValue(
          record?.serialExportNoteItem?.serialExportNote?.seller?.fullName ||
            record?.serialImportNoteItem?.serialItems[0]?.serialImportNoteItem?.serialImportNote?.seller?.fullName ||
            record?.serialImportNoteItem?.serialItems[0]?.serialImportNoteItem?.serialImportNote?.provider?.fullName ||
            record?.serialImportNoteItem?.serialItems[0]?.serial?.provider?.name
        ),
    },
    {
      title: t("serialDetail.TIMEWARRANTY"),
      render: (_, record) => (
        <div>
          {record?.serialExportNoteItem?.warrantyOnSale ||
            record?.serialImportNoteItem?.serialItems[0]?.serialImportNoteItem?.warrantyPeriodProvider}{" "}
          {convertUnit(
            record?.serialExportNoteItem?.warrantyOnSaleUnit ||
              record?.serialImportNoteItem?.serialItems[0]?.serialImportNoteItem?.warrantyUnit
          )}
        </div>
      ),
    },
    {
      title: t("serialDetail.WAREHOUSEEXPRTIMPORT"),
      render: (_, record) =>
        checkNullValue(
          record?.serialImportNoteItem?.serialItems[0]?.warehouse?.name ||
            record?.serialExportNoteItem?.exportItems[0]?.exportedWarehouse?.name
        ),
    },
  ];

  return {
    columns,
  };
};

export default useList;
