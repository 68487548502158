import React from "react";
import CustomTags from "components/CustomTags";
import { t } from "i18next";
import {
  shippingTypeOptions,
  warrantyRequestItemResultOptions,
  warrantyRequestTypeOptions,
  warrantyStatusOptions,
  WARRANTY_REQUEST_ITEM_SOLUTION,
} from "features/Warranty/constant";
import { useGetInfoPlatform } from "hooks/info-platform";

function useToolbar({ onChangeParams, params, staffs }) {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];

    for (const element of arrValue) {
      const item = arrTags?.find(({ value }) => element === value);
      result.push(item);
    }

    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(", ")}
      </div>
    );
  };

  const { platformName } = useGetInfoPlatform();

  const warrantyRequestItemSolutionLabel = {
    [WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER]: t("warrantyRequest.popup.solutionConfirm1"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY]: t("warrantyRequest.popup.foreignWarranty"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY]: t("warrantyRequest.popup.solutionConfirm2"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.SELF]: t("warrantyRequest.popup.solutionConfirm3", {
      platformName,
    }),
  };

  const warrantyRequestItemSolutionOptions = [
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.SELF,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.SELF}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY}`],
    },
  ];

  const tags = [
    {
      title: t("warranty.warrantyRequestList.staffAccordingSerial"),
      options: staffs,
      selected: params?.filters?.createdByID,
      key: "createdByID",
    },
    {
      title: t("warranty.warrantyRequestList.warrantyStatusAccordingSerial"),
      options: warrantyStatusOptions,
      selected: params?.filters?.warrantyNoteStatuses,
      key: "warrantyNoteStatuses",
    },
    {
      title: t("warranty.warrantyRequestList.typeRequestAccordingSerial"),
      options: warrantyRequestTypeOptions,
      selected: params?.filters?.requestType,
      key: "requestType",
    },
    {
      title: t("warranty.warrantyRequestList.solutionAccordingSerial"),
      options: warrantyRequestItemSolutionOptions,
      selected: params?.filters?.solutions,
      key: "solutions",
    },
    {
      title: t("warranty.warrantyRequestList.warrantyResultAccordingSerial"),
      options: warrantyRequestItemResultOptions,
      selected: params?.filters?.warrantyResults,
      key: "warrantyResults",
    },
    {
      title: t("warranty.warrantyRequestList.methodSendCustomerAccordingSerial"),
      options: shippingTypeOptions,
      selected: params?.filters?.shippingType,
      key: "shippingType",
    },
  ];

  const handleOnClose = (key) => {
    switch (key) {
      case "warrantyNoteStatuses":
        onChangeParams && onChangeParams({ [`${key}`]: [] });
        break;
      default:
        onChangeParams && onChangeParams({ [`${key}`]: undefined });
        break;
    }
  };

  const renderTags = tags
    ?.filter(({ selected }) => selected?.length > 0)
    ?.map(({ options, selected, title, key }) => {
      return (
        <CustomTags handleOnClose={() => handleOnClose(key)} key={key} label={renderTag(options, selected, title)} />
      );
    });

  return { renderTags };
}

export default useToolbar;
