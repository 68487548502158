import ModalChangeAddress from "../ModalChangeAddress";
import "./index.scss";

const InfoReceiverAndSender = ({ infoUser, loadingUpdateCart, refetch }) => {
  const {
    title,
    id,
    name,
    phoneNumber,
    address,
    titleModal,
    list,
    onSubmit,
    addMoreButton,
    paginationData,
    params,
    loadingContactList,
    onTableChange,
    isReceiver,
    handleChangeInput,
  } = infoUser;

  return (
    <div className="info-receiver-and-sender">
      <b>{title}</b>
      <div className="info-address">
        <div>
          <span>
            <b>{name}</b>
            {phoneNumber ? ` | ${phoneNumber}` : null}
          </span>
          <ModalChangeAddress
            title={titleModal}
            list={list}
            id={id}
            onSubmit={onSubmit}
            loadingButton={loadingUpdateCart}
            addMoreButton={addMoreButton}
            paginationData={paginationData}
            params={params}
            loadingContactList={loadingContactList}
            onTableChange={onTableChange}
            isReceiver={isReceiver}
            handleChangeInput={handleChangeInput}
          />
        </div>
        <span>{address}</span>
      </div>
    </div>
  );
};

export default InfoReceiverAndSender;
