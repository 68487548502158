import { ExclamationCircleFilled } from "@ant-design/icons";
import { Space, Typography } from "antd";

export default function IconWarning({ title }) {
  return (
    <Space direction="vertical">
      <ExclamationCircleFilled
        style={{
          fontSize: "50px",
          color: "#EF9351",
          padding: "20px",
          borderRadius: 50,
          background:
            "radial-gradient(circle, rgb(255, 247, 231) 0%, rgb(255, 250, 244) 50%, rgba(255,255,255,1) 100%)",
        }}
      />
      <Typography.Text style={{ fontSize: "16px" }}>{title}</Typography.Text>
    </Space>
  );
}
