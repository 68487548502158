import { usePrintExportNotesSerialExportNotes } from "hooks/serial";
import { useGetDetailSerialExport } from "hooks/serial/query";
import { t } from "i18next";
import { sumBy } from "lodash";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import { EXPORT_NOTES_TYPE, EXPORT_TYPE, FILETYPE } from "../../constant";
import OrderInfo from "./components/TypeOrder/OrderInfo";
import SupplierInfo from "./components/TypeReturnSupplier/SupplierInfo";
import SellerInfo from "./components/TypeWarranty/SellerInfo";
import WarehouseInfo from "./components/WarehouseInfo";

function useList() {
  const { id } = useParams();
  const { data: dataDetail, loading } = useGetDetailSerialExport({ id: id });
  const sumProductByOrder = dataDetail?.items?.length;
  const sumSerialByOrder = sumBy(dataDetail?.order?.items, "quantity");
  const sumSerialScanned = sumBy(dataDetail?.items, "exportItems.length");
  const [isReady, setIsReady] = useState(false);
  const { handlePrintExportNotesSerialExportNotes, loadingExport } = usePrintExportNotesSerialExportNotes();
  const dataSerialImportNoteItemID = dataDetail?.items?.map((item) => item?.id);
  const handleExport = async () => {
    try {
      const params = {
        serialExportNoteID: dataDetail?.id,
        serialExportNoteItemIDs: dataSerialImportNoteItemID,
        type: EXPORT_NOTES_TYPE.SERIAL_EXPORT_NOTE,
        fileType: FILETYPE.XLSX,
      };
      await handlePrintExportNotesSerialExportNotes(params)
        .then((res) => {
          const link = document.createElement("a");
          link.href = res?.data?.serialExportNotes?.exportFile?.url;
          link.download = res?.data?.serialExportNotes?.exportFile?.path;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.info(error);
    }
  };

  const handlePrint = async () => {
    try {
      const params = {
        serialExportNoteID: dataDetail?.id,
        serialExportNoteItemIDs: dataSerialImportNoteItemID,
        type: EXPORT_NOTES_TYPE.SERIAL_EXPORT_NOTE,
        fileType: FILETYPE.PDF,
      };
      await handlePrintExportNotesSerialExportNotes(params)
        .then((res) => {
          printJS(res?.data?.serialExportNotes?.exportFile?.url);
        })
        .catch((error) => {
          notify.error({
            message: error.message,
          });
        });
    } catch (error) {
      console.info(error);
    }
  };

  const checkTypeTitleInfo = () => {
    switch (dataDetail?.type) {
      case EXPORT_TYPE.ORDER:
        return t("serialIE.infoOrder");
      case EXPORT_TYPE.WARRANTY:
        return t("serialIE.infoSeller");
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return t("warranty.warrantyDetail.infoNCC");
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return "Thông tin kho xuất";
      case EXPORT_TYPE.OTHER:
        return "Thông tin kho xuất";
      default:
        return [];
    }
  };
  const checkTypeInfo = () => {
    switch (dataDetail?.type) {
      case EXPORT_TYPE.ORDER:
        return <OrderInfo dataDetail={dataDetail} />;
      case EXPORT_TYPE.WARRANTY:
        return <SellerInfo dataDetail={dataDetail} />;
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return <SupplierInfo dataDetail={dataDetail} />;
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return <WarehouseInfo dataDetail={dataDetail} />;
      case EXPORT_TYPE.OTHER:
        return <WarehouseInfo dataDetail={dataDetail} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (dataDetail?.type) {
      setIsReady(true);
    }
  }, [dataDetail]);

  return {
    dataDetail,
    isReady,
    handleExport,
    handlePrint,
    loadingExport,
    sumProductByOrder,
    sumSerialByOrder,
    sumSerialScanned,
    checkTypeInfo,
    checkTypeTitleInfo,
    loading,
  };
}

export default useList;
