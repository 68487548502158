import CustomModal from "components/CustomModal";
import LoadingComponent from "components/LoadingComponent";
import { usePackageOrder } from "hooks/order";
import React from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";

const CustomModalWaitingPackaging = ({
  setOpenModalWaitingPackaging,
  orderIDs,
  isScreenListWaitingPackaging,
  resetCheckedList,
}) => {
  const { t } = useTranslation();
  const { handlePackageOrder, loading: loadingPackage } = usePackageOrder();

  const handleConfirm = async () => {
    try {
      await handlePackageOrder({
        orderIDs: orderIDs,
      }).then(() => {
        notify.success({
          message: t("order.orderList.packageSuccess"),
        });
        setOpenModalWaitingPackaging(false);
        isScreenListWaitingPackaging && resetCheckedList();
      });
    } catch (err) {
      notify.error({
        message: t("order.orderList.packageError"),
      });
    }
  };

  const handleCancel = () => {
    setOpenModalWaitingPackaging(false);
  };

  return (
    <LoadingComponent loading={loadingPackage}>
      <CustomModal
        title={<div className="cancel-order-title">{t("order.packagingOrder.title")}</div>}
        closable={false}
        centered={true}
        footer={false}
        isBlockCloseOnOke
        selfVisibleControlled={false}
        onOke={handleConfirm}
        onCancel={handleCancel}
      />
    </LoadingComponent>
  );
};

export default CustomModalWaitingPackaging;
