import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Form, Input, Row, Space, Tooltip, Typography } from "antd";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { COMBO_CAMPAIGN, OPTIONS_TYPE_LIMIT, TYPE_LIMIT } from "features/Campaign/constants";
import { t } from "i18next";
import { flatten } from "lodash";
import { validator } from "utils/helperFuncs";
import ComboCollapse from "./ComboCollapse";
import { useEffect, useState } from "react";

const { Panel } = Collapse;

const initPromotionCampaignTiersForm = {
  conditionProductGroups: [{ quantity: null, conditionPromotionProducts: [] }],
  fixedComboPrice: null,
  type: COMBO_CAMPAIGN.FIXED_PRICE,
};

const checkFixedComboQuantity = (fixedComboPrices) => {
  if (!fixedComboPrices || fixedComboPrices?.length === 0) {
    return [];
  }

  let previousQuantity = fixedComboPrices[0]?.quantity;
  const result = [];

  for (let i = 0; i < fixedComboPrices?.length; i++) {
    const combo = fixedComboPrices[i];
    if (i === 0) {
      result.push(false);
    } else {
      const currentQuantity = combo?.quantity;
      const isQuantityLessThanPrevious = currentQuantity <= previousQuantity;
      result.push(isQuantityLessThanPrevious);
      previousQuantity = currentQuantity;
    }
  }

  // Set the last item to true if its quantity is less than the previous quantity
  if (
    fixedComboPrices?.length > 1 &&
    fixedComboPrices[fixedComboPrices?.length - 1].quantity < fixedComboPrices[fixedComboPrices?.length - 2].quantity
  ) {
    result[fixedComboPrices?.length - 1] = true;
  }

  return result;
};

const calculateTotalQuantity = (fixedComboPrices) => {
  if (!fixedComboPrices || fixedComboPrices.length === 0) {
    return 0;
  }

  let totalQuantity = 0;

  for (let i = 0; i < fixedComboPrices.length; i++) {
    const combo = fixedComboPrices[i];
    if (combo.quantity) {
      totalQuantity += combo.quantity;
    }
  }

  return totalQuantity;
};

const checkFixedComboPrices = (fixedComboPrices) => {
  if (!fixedComboPrices || fixedComboPrices.length === 0) {
    return [];
  }

  let previousPrice = fixedComboPrices[0].fixedComboPrice;
  const result = [];

  for (let i = 0; i < fixedComboPrices.length; i++) {
    const combo = fixedComboPrices[i];
    if (i === 0) {
      result.push(false);
    } else {
      const currentPrice = combo.fixedComboPrice;
      const isPriceLessThanPrevious = currentPrice && currentPrice <= previousPrice;
      result.push(isPriceLessThanPrevious);
      previousPrice = currentPrice;
    }
  }

  return flatten(result);
};

const ComboFixedPriceForm = ({
  remove,
  indexComboFixedPrice,
  form,
  promotionCampaignTiers,
  isUpdate,
  dataTiersServer,
  useInModal,
  onRemoveValueComboSelected,
  onGetDataFixedPrice,
}) => {
  const [valuesForm, setValuesForm] = useState();
  const [newDataTiersServer, setNewDataTiersServer] = useState(dataTiersServer);

  const dataPromotionCampaignTiersFromIndex = valuesForm?.promotionCampaignTierGroupsFixedPrice?.[indexComboFixedPrice];
  const typeLimit = dataPromotionCampaignTiersFromIndex?.limitType;
  const promotionCampaignTiersFromForm = dataPromotionCampaignTiersFromIndex?.promotionCampaignTiers;
  const arrayValidateFixedComboPrice = checkFixedComboPrices(promotionCampaignTiersFromForm);
  const arrayValidateFixedComboPriceQuantity = checkFixedComboQuantity(promotionCampaignTiersFromForm);
  const totalQuantity = calculateTotalQuantity(promotionCampaignTiersFromForm);
  const isBudgetExceededForPromotion =
    dataPromotionCampaignTiersFromIndex?.budgetProductQuantity < totalQuantity &&
    dataPromotionCampaignTiersFromIndex?.budgetProductQuantity;

  const handleRemoveComboCollapse = () => {
    const formValues = form.getFieldsValue();
    if (formValues?.promotionCampaignTierGroupsFixedPrice?.length === 1) {
      onRemoveValueComboSelected(COMBO_CAMPAIGN.FIXED_PRICE);
    }
    onGetDataFixedPrice && onGetDataFixedPrice([]);
    remove();
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    setValuesForm(values);
  }, [JSON.stringify(form.getFieldsValue())]);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapses" expandIconPosition="left">
      <Panel
        key="1"
        header={
          <Typography.Text strong style={{ textTransform: "uppercase" }}>
            {t("campaign.combo.comboFixedPrice")} {indexComboFixedPrice + 1}
          </Typography.Text>
        }
        extra={
          <CustomModal
            {...{
              message: t("campaign.combo.errorMessage.removeComboCollapse"),
              onOke: () => handleRemoveComboCollapse(),
              footer: false,
              icon: Img.DeleteIcon,
              customComponent: <Button className="btn-delete">{t("common.delete")}</Button>,
            }}
          />
        }
      >
        <Row className="panel-main">
          <Col span={24}>
            <Card title={t("campaign.combo.comboCondition")}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Typography.Text className="character-required" strong>
                        {t("campaign.combo.maxNumberOfComboPerDealer")}
                      </Typography.Text>
                    }
                    className="col-left"
                  >
                    <Input.Group compact className="form-flex">
                      <FormInput
                        {...{
                          type: INPUT_TYPE.SELECT,
                          formItemOptions: {
                            noStyle: true,
                            name: [indexComboFixedPrice, "limitType"],
                          },
                          inputOptions: {
                            options: OPTIONS_TYPE_LIMIT,
                          },
                        }}
                        labelCol={{ span: 8 }}
                      />

                      <FormInput
                        type={INPUT_TYPE.NUMBER}
                        {...{
                          formItemOptions: {
                            noStyle: true,
                            name: [indexComboFixedPrice, "maxQuantityPerSeller"],
                            rules: [
                              {
                                required: typeLimit !== TYPE_LIMIT.NO_LIMIT,
                                message: t("campaign.combo.errorMessage.maxNumberOfComboPerDealer"),
                              },
                              {
                                type: "number",
                                min: 1,
                                message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                              },
                              validator({
                                type: "number",
                              }),
                            ],
                          },
                          inputOptions: {
                            placeholder: t("campaign.combo.placeholder.type"),
                            disabled: typeLimit === TYPE_LIMIT.NO_LIMIT,
                          },
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className={"col-left"}>
                  <FormInput
                    type={INPUT_TYPE.NUMBER}
                    {...{
                      formItemOptions: {
                        label: (
                          <Space>
                            <Typography.Text strong>{t("campaign.combo.fixedTotalProductQty")}</Typography.Text>
                            <Tooltip title={t("campaign.combo.tooltipFixedTotalProductQty")}>
                              <QuestionCircleOutlined />.
                            </Tooltip>
                          </Space>
                        ),
                        name: [indexComboFixedPrice, "budgetProductQuantity"],
                        rules: [
                          {
                            required: true,
                            message: t("common.pleaseEnter", {
                              label: t("campaign.combo.fixedTotalProductQty"),
                            }),
                          },
                          {
                            type: "number",
                            min: 1,
                            message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                          },
                          validator({
                            type: "number",
                          }),
                        ],
                        validateStatus: isBudgetExceededForPromotion ? "warning" : undefined,
                        help: isBudgetExceededForPromotion
                          ? t("campaign.combo.warningBudgetProductQuantity")
                          : undefined,
                      },
                      inputOptions: {
                        placeholder: t("campaign.combo.placeholder.type"),
                      },
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Form.List name={[indexComboFixedPrice, "promotionCampaignTiers"]}>
              {(fields, { add, remove }) => {
                return (
                  <Row>
                    {fields.map((field, index) => (
                      <Col span={24} key={field.key}>
                        <ComboCollapse
                          indexPromotionCampaignTiers={index}
                          remove={() => remove(field.name)}
                          dataTiersServer={newDataTiersServer}
                          indexComboFixedPrice={indexComboFixedPrice}
                          form={form}
                          useInModal={useInModal}
                          promotionCampaignTiers={promotionCampaignTiers}
                          isUpdate={isUpdate}
                          arrayValidateFixedComboPrice={arrayValidateFixedComboPrice}
                          arrayValidateFixedComboPriceQuantity={arrayValidateFixedComboPriceQuantity}
                          fields={fields}
                          valuesForm={valuesForm}
                        />
                      </Col>
                    ))}
                    {fields?.length < 10 && (
                      <Col span={24}>
                        <Typography.Link
                          underline
                          onClick={() => {
                            add(initPromotionCampaignTiersForm);
                            setNewDataTiersServer(valuesForm?.promotionCampaignTierGroupsFixedPrice);
                          }}
                        >
                          <PlusOutlined /> {t("campaign.combo.addIncentives")}
                        </Typography.Link>
                      </Col>
                    )}
                  </Row>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default ComboFixedPriceForm;
