import { Col, Row, Typography } from "antd";
import CustomTable from "components/CustomTable";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import Bill from "../Bill";
import PaymentMethod from "../PaymentMethod";
import useInfoProductAndBill from "./useInfoProductAndBill";
import CustomDescriptions from "components/CustomDescriptions";

const InfoProductAndBill = ({ dataInfoProductAndBill }) => {
  const { columns } = useInfoProductAndBill({ dataInfoProductAndBill });
  const { items, note, internalNote } = dataInfoProductAndBill;

  const convertItems =
    items?.map((item, index) => ({
      ...item,
      key: item?.id,
      children: item?.promoTier?.type !== "DISCOUNT_PRODUCT" && item?.orderItems,
    })) || [];

  const convertFilterItems = convertItems.reduce((acc, item) => {
    const existingItem = acc.find(
      (entry) =>
        entry.productID === item.productID &&
        entry.price === item.price &&
        entry.productWarranty === item.productWarranty
    );
    if (existingItem) {
      existingItem.quantity += item.quantity;
      existingItem.total += item.total;
    } else {
      acc.push({ ...item });
    }
    return acc;
  }, []);

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "#052987" }}>
          {t("cart.infoProductAndBill")}
        </Typography.Text>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={17}>
          {convertFilterItems?.length && (
            <CustomTable
              rowKey={(record) => record?.id}
              expandable={{
                expandIcon: () => null,
                defaultExpandAllRows: true,
              }}
              columns={columns}
              dataSource={convertFilterItems || []}
              pagination={false}
            />
          )}
        </Col>
        <Col span={7}>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <PaymentMethod dataInfoProductAndBill={dataInfoProductAndBill} />
            </Col>
            <Col span={24}>
              <Bill dataInfoProductAndBill={dataInfoProductAndBill} />
            </Col>
            <Col span={24}>
              <div style={{ background: "#F7F8FB", borderRadius: "8px", padding: "12px" }}>
                <CustomDescriptions
                  bordered={false}
                  colon={false}
                  labelStyle={{
                    fontWeight: "normal",
                  }}
                  labelWidth={"40%"}
                  contentWidth={"60%"}
                  paddingView="0 10px 0 10px"
                  contentMapping={[
                    {
                      label: t("order.orderDetail.internalNote"),
                      value: (
                        <Typography.Text strong style={{ color: "#2246DC" }}>
                          {internalNote || "--"}
                        </Typography.Text>
                      ),
                    },
                    {
                      label: t("order.orderDetail.orderNote"),
                      value: (
                        <Typography.Text strong style={{ color: "#2246DC" }}>
                          {note || "--"}
                        </Typography.Text>
                      ),
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoProductAndBill;
