import { gql } from "@apollo/client";

export const GET_SUPPLIER_LIST = gql`
  query GetSupplierList($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        sellers {
          id
          code
          fullName
          telephone
          address
          city {
            name
          }
          district {
            name
          }
          ward {
            name
          }
          sellerGroupID
          sellerGroup {
            id
            name
          }
          type
          isSeller
          isActiveSupplier
        }
      }
    }
  }
`;

export const GET_SUPPLIER_DETAIL = gql`
  query GET_SUPPLIER_DETAIL($id: ID!) {
    seller {
      get(id: $id) {
        id
        code
        logoUrl
        type
        idNumber
        shortName
        sellerLevelID
        debtLimit
        debtAge
        isActiveSupplier
        fullName
        email
        telephone
        address
        cityID
        districtID
        wardID
        city {
          name
        }
        district {
          name
        }
        ward {
          name
        }
        paymentMethod
        representName
        representEmail
        representPhone
        isSeller
        isSupplier
        vatInfo {
          id
          taxIDNumber
          businessName
          representative
          telephone
          address
          email
          bankID
          bankAccountNumber
          bankBranch
          defaultExportVat
          allowEditVat
        }
        warehouses {
          id
          name
          branch {
            id
            name
          }
        }
        fullSaleCategories {
          checked
          level
          category {
            name
            code
            id
          }
        }
        sellerGroupID
        sellerGroup {
          name
          id
          code
        }
        availabilityLimit
        bankAccounts {
          id
          bankID
          bank {
            id
            name
            fullName
            icon
          }
          bankAccountNumber
          name
          bankBranch
        }
      }
    }
  }
`;

export const GET_DATA_BANK_ACCOUNTS = gql`
  query GET_DATA_BANK_ACCOUNTS($id: ID!) {
    seller {
      get(id: $id) {
        bankAccounts {
          id
          bankID
          bank {
            id
            name
            fullName
            icon
          }
          bankAccountNumber
          name
          bankBranch
        }
      }
    }
  }
`;

export const GET_SUPPLIER_WITH_IDS = gql`
  query GetSupplierWithIds($filters: SellersFilters) {
    seller {
      pagination(filters: $filters) {
        sellers {
          id
          fullName
        }
      }
    }
  }
`;
