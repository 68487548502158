import { useLazyQuery, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import {
  GET_POTENTIAL_CUSTOMER_REPORT,
  GET_REPORT_MANAGER_INFO,
  GET_REPORT_MANAGER_TOKEN,
  GET_REPORT_SALE_INFO,
  GET_REPORT_SALE_TOKEN,
} from "graphql/report/query";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";

export const useGetPotentialCustomerReport = (params) => {
  const { data, loading } = useQuery(GET_POTENTIAL_CUSTOMER_REPORT, {
    variables: {
      filters: {
        ...params?.filters,
      },
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    data: data?.report?.getPotentialCustomerReport,
  };
};

export const useLazyGetPotentialCustomerReport = () => {
  const [handleGetPotentialCustomerReport, { loading, data }] = useLazyQuery(GET_POTENTIAL_CUSTOMER_REPORT);

  return {
    loading,
    data: data?.report?.getPotentialCustomerReport,
    handleGetPotentialCustomerReport,
  };
};

export const useGetReportSaleInfo = (params, skip) => {
  const { loading, data, refetch } = useQuery(GET_REPORT_SALE_INFO, {
    variables: params,
    skip: skip,
  });

  return {
    loading,
    data: data?.report?.getSalesReport,
    refetch,
  };
};

export const useGetReportSalesManagerInfo = (params, skip) => {
  const { loading, data, refetch } = useQuery(GET_REPORT_MANAGER_INFO, {
    variables: params,
    skip: skip,
  });

  return {
    loading,
    data: data?.report?.getSalesManagerReport,
    refetch,
  };
};

export const useLazyGetReportSaleToken = () => {
  const [handleGetSaleReport, { loading, data }] = useLazyQuery(GET_REPORT_SALE_TOKEN);

  return {
    loading,
    data: data?.report?.getReportSales,
    handleGetSaleReport,
  };
};

export const useLazyGetReportSalesManagerToken = () => {
  const [handleGetManagerReport, { loading, data }] = useLazyQuery(GET_REPORT_MANAGER_TOKEN);

  return {
    loading,
    data: data?.report?.getReportSalesManager,
    handleGetManagerReport,
  };
};

export const useReportPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    loading,
    canViewSale: hasPermission(permissions, [USER_PERMISSIONS.REPORT_SALE_VIEW]),
    canViewManager: hasPermission(permissions, [USER_PERMISSIONS.REPORT_SALE_MANAGER_VIEW]),
    canViewSaleChart: hasPermission(permissions, [
      USER_PERMISSIONS.REPORT_SALE_VIEW,
      USER_PERMISSIONS.REPORT_SALE_MANAGER_VIEW,
    ]),
  };
};

export const usePotentialCustomerReportPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    loading,
    canViewPersonal: hasPermission(permissions, [USER_PERMISSIONS.REPORT_POTENTIAL_CUSTOMER_PERSONAL_VIEW]),
    canViewGeneral: hasPermission(permissions, [USER_PERMISSIONS.REPORT_POTENTIAL_CUSTOMER_GENERAL_VIEW]),
    canViewPersonalChart: hasPermission(permissions, [
      USER_PERMISSIONS.REPORT_POTENTIAL_CUSTOMER_PERSONAL_VIEW,
      USER_PERMISSIONS.REPORT_POTENTIAL_CUSTOMER_GENERAL_VIEW,
    ]),
  };
};
