/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import InputSearch from "features/Serial/components/InputSearch";
import { useGetSerialExportNotesWarranty } from "hooks/serial/query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import RadioGroup from "../../../../../../components/RadioGroup";
import "./index.scss";

export const ModalSelectSerial = ({ record, infoCard, index, form, handleGetTableOrder }) => {
  const plainOptions = record?.map(({ serialImei }) => serialImei);
  const [defaultListSerialAll] = useState(plainOptions);
  const [valueSearchInputSerial, setValueSearchInputSerial] = useState([]);
  const [valueSearchInputSerialFetch, setValueSearchInputSerialFetch] = useState([]);

  const [isTypeChecked, setIsTypeChecked] = useState(true);
  const [listCheckedSerial, setListCheckedSerial] = useState([]);

  const [listCheckedTable, setListCheckedTable] = useState([]);
  const handleAddSerialModel = async () => {
    setValueSearchInputSerial([]);
    setListCheckedTable(listCheckedSerial);
    const newItems = formData.items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          serialItems: listCheckedSerial,
          listSerialCheckedItems: listCheckedSerial,
          quantitySerialScanned: listCheckedSerial?.length,
        };
      } else {
        return item;
      }
    });
    form.setFieldsValue({
      ...formData,
      items: newItems,
    });
    handleGetTableOrder && handleGetTableOrder(newItems);
    setVisible(false);
  };

  const formData = form.getFieldsValue();
  const [visible, setVisible] = useState(false);
  const filterItemsTypeOptions = [{ label: `${t("cart.selected")} (${listCheckedSerial?.length || 0})`, value: true }];
  const { handleGetSerialExportNotesWarranty, loading } = useGetSerialExportNotesWarranty();
  const handleChangeRadioTypeFilterSerial = (e) => {
    setIsTypeChecked(e?.target?.value);
    setValueSearchInputSerial([]);
  };

  const handleSearchSerials = async (values) => {
    const newValues = values[0]?.split(/[, ]+/)?.map((value) => value?.trim());

    const exists = newValues?.some((item) => listCheckedSerial?.includes(item));
    if (!exists) {
      setValueSearchInputSerial([]);
      setValueSearchInputSerialFetch(newValues);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOpen = () => {
    setVisible(true);
  };
  useEffect(() => {
    setListCheckedSerial(formData?.items[index]?.listSerialCheckedItems);
  }, [JSON.stringify(visible)]);

  useEffect(async () => {
    if (valueSearchInputSerialFetch.length > 0) {
      const dataNewSerial = await handleGetSerialExportNotesWarranty({
        filter: {
          queries: valueSearchInputSerialFetch,
        },
        pagination: {
          offset: 0,
          limit: 99,
          skipTotalCount: true,
        },
      });
      const serials = dataNewSerial?.data?.serial?.pagination?.Serials;
      const serialCodeInValid = serials?.map((item) => item.code);

      if (serialCodeInValid?.length > 0) {
        setListCheckedSerial((listCheckedSerial || [])?.concat(serialCodeInValid));
      }

      valueSearchInputSerialFetch
        ?.filter((item) => !serialCodeInValid?.includes(item))
        ?.map((item) => notify.warning({ message: `Serial/ IMEI ${item} không thỏa mãn điều kiện. Vui lòng thử lại` }));
    }
  }, [valueSearchInputSerialFetch, defaultListSerialAll]);

  const onRemoveSelected = (value) => {
    const values = listCheckedSerial.filter((v) => v !== value);
    setListCheckedSerial(values);
  };
  const renderSelectedOption = (option) => (
    <div key={option}>
      <Checkbox
        checked={listCheckedSerial?.includes(option)}
        onChange={() => onRemoveSelected(option)}
        className="serial-checkbox-custom"
      >
        <div style={{ display: "flex", justifyContent: "space-around", gap: "25px" }}>
          <div>{option}</div>
          <div>
            <CloseOutlined />
          </div>
        </div>
      </Checkbox>
    </div>
  );

  const handleDeleteAllSerial = () => {
    setListCheckedSerial([]);
  };

  const copyToClipboard = (listCheckedTable) => {
    if (listCheckedTable?.length > 0) {
      const textToCopy = listCheckedTable.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  return (
    <div className="input-tag-container-modal-select" style={{ justifyContent: "space-between" }}>
      <div className="input-tag-tags">
        {listCheckedTable?.length ? (
          listCheckedTable?.map((tag, index) => {
            return (
              <Tag className="input-tag-tag custom-tags-by-serial" key={index}>
                {tag}
              </Tag>
            );
          })
        ) : (
          <Typography.Text>{t("serialIEDetail.noselected")}</Typography.Text>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
        }}
      >
        <CustomModal
          {...{
            footer: false,
            width: "50%",
            customComponent: <Typography.Link underline>{t("serialIE.select")}</Typography.Link>,
            title: (
              <Typography.Text strong style={{ fontSize: "20px" }}>
                {t("serialIE.select2")}
              </Typography.Text>
            ),
          }}
          visible={visible}
          onOke={handleAddSerialModel}
          onOpen={handleOpen}
          onCancel={handleCancel}
          className="form-model-list-tags-export-serial"
        >
          <Spin spinning={loading}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card
                  style={{ background: "#F7F8FB", marginBottom: "16px" }}
                  children={
                    <Row gutter={[16, 8]}>
                      <Col span={6}>
                        <Typography.Text strong>{t("serial.productCode")}</Typography.Text>
                      </Col>
                      <Col span={18}>
                        <Typography.Link strong href={`/product/detail/${infoCard?.product?.id}`}>
                          {infoCard?.product?.code}
                        </Typography.Link>
                      </Col>
                      <Col span={6}>
                        <Typography.Text strong>{t("serial.productName")}</Typography.Text>
                      </Col>
                      <Col span={18}> {infoCard?.product?.name}</Col>
                      <Col span={6}>
                        <Typography.Text strong>{t("serialIE.productInfo.serialByOrder")}</Typography.Text>
                      </Col>
                      <Col span={18}>{infoCard?.quantity}</Col>
                    </Row>
                  }
                />
                <InputSearch onSearch={handleSearchSerials} valueSearchSerial={valueSearchInputSerial} />
              </Col>
              <Col span={24}>
                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                  <Space>
                    <ExclamationCircleFilled style={{ color: "#EF9351" }} />
                    <Typography.Text type="warning">{t("serialIEDetail.warning")}</Typography.Text>
                  </Space>{" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Popconfirm
                      title="Bạn có chắc chắn muốn xóa tất cả serial/IMEI không?"
                      onConfirm={() => handleDeleteAllSerial(index)}
                      okText="Xác nhận"
                      cancelText="Đóng"
                    >
                      <SvgIcon.IconDelete
                        style={{ fontSize: "20px", cursor: "pointer", marginRight: "8px" }}
                        width="21px"
                        height="21px"
                      />
                    </Popconfirm>
                    <RadioGroup
                      filterItemsTypeOptions={filterItemsTypeOptions}
                      handleChange={handleChangeRadioTypeFilterSerial}
                      value={isTypeChecked}
                    />
                  </div>
                </Space>
                <Divider />
                <Form.Item
                  name={[index, "serialItems"]}
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: t("serialIE.error.serialItems"),
                    },
                  ]}
                >
                  {listCheckedSerial?.length > 0 ? (
                    <div className="ant-checkbox-group">{listCheckedSerial?.map(renderSelectedOption)}</div>
                  ) : (
                    <Typography.Text className="message-no-data">{t("serialIEDetail.noselected")}</Typography.Text>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </CustomModal>
        <Tooltip title={t("serialIE.copy")}>
          <SvgIcon.CopyIcon
            style={{ fontSize: "30px", cursor: "pointer" }}
            width="24px"
            height="24px"
            onClick={() => copyToClipboard(listCheckedTable)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
