import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import CustomModal from "components/CustomModal";
import ModalWithIconQuestion from "components/CustomModalWithIcon/Question";
import { SHIPPING_TYPE } from "config/constants";
import { WARRANTY_SERIAL_STATUS } from "features/Warranty/constant";
import { ButtonGetResult } from "features/WarrantyRequest/components/ButtonActions";
import PopupConfirm from "features/WarrantyRequest/components/PopupConfirm";
import PopupConfirmDeliComplete from "features/WarrantyRequest/components/PopupConfirmDeliComplete";
import PopupCreateSelect from "features/WarrantyRequest/components/PopupCreateSelect";
import PopupSelectShipper from "features/WarrantyRequest/components/PopupSelectShipper";
import PopupSolution from "features/WarrantyRequest/components/PopupSolution";
import { TOTAL_ITEMS_STATUS, WRequestSolutionMethod } from "features/WarrantyRequest/constant";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { uniqBy } from "lodash";
import { useState } from "react";
import { notify } from "utils/helperFuncs";
import useButtonGroup from "./useButtonGroup";

const ButtonCreateRequest = ({ selectedRows }) => (
  <PopupCreateSelect
    selectedRows={selectedRows}
    button={<Button type="primary">{t("warranty.warrantyRequestList.createRequest")}</Button>}
  />
);

function ButtonTest({ confirmCheck, loading, itemIDs }) {
  const [switchClose, setSwitchClose] = useState(true);

  const handleClick = () => {
    confirmCheck();
    setSwitchClose(!switchClose);
  };

  return (
    <CustomModal
      {...{
        customComponent: (
          <Button type="primary" disabled={itemIDs?.length ? false : true} loading={loading}>
            {t("warranty.warrantyRequestList.check")}
          </Button>
        ),
        message: <ModalWithIconQuestion title={t(`notiConfirm.checking`)} />,
        styleButton: "notButton",
        footer: false,
        hideConfirmButton: true,
        switchClose: switchClose,
        addMoreButton: (
          <Button type="primary" onClick={handleClick} loading={loading}>
            {t("common.confirm")}
          </Button>
        ),
      }}
    />
  );
}

const ButtonChooseProcessingDirection = ({ loading, confirmSolution, record, itemIDs }) => {
  const [switchClose, setSwitchClose] = useState(true);
  const [solution, setSolution] = useState();
  const { platformName } = useGetInfoPlatform();

  const handleClick = () => {
    confirmSolution({ solution: solution, solutionRequest: {} });
    setSwitchClose(!switchClose);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.PROVIDER}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.provider")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.FOREIGN_WARRANTY}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.foreignWarranty")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.THIRD_PARTY}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.thirdParty")}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
      <Menu.Item onClick={(solution) => setSolution(solution?.key)} key={WRequestSolutionMethod.SELF}>
        <PopupConfirm
          title={t("warrantyRequest.detail.btnGroup.self", { platformName })}
          handleClick={handleClick}
          loading={loading}
          switchClose={switchClose}
          solution={solution}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} disabled={itemIDs?.length ? false : true}>
      <Button loading={loading} type="primary">
        <Space>
          {t("warranty.warrantyRequestList.chooseProcessingDirection")}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
const ButtonTransferFor = ({ itemIDs, confirmDistribute, loading, selectedRows }) => {
  const [menuSelected, setMenuSelected] = useState({});
  const { platformName } = useGetInfoPlatform();
  const [isOpen, setIsOpen] = useState(false);

  const menuItem = [
    {
      title: t("warrantyRequest.detail.provider"),
      key: WRequestSolutionMethod.PROVIDER,
    },
    {
      title: t("warrantyRequest.detail.thirdParty"),
      key: WRequestSolutionMethod.THIRD_PARTY,
    },
    {
      title: t("warrantyRequest.detail.assigned_to_technology", { platformName }),
      key: WRequestSolutionMethod.SELF,
    },
  ];

  const handleOpen = (selected) => {
    const newData = uniqBy(selectedRows, "solution");
    if (newData?.length === 1 && newData[0].solution === selected.key) {
      setMenuSelected(selected);
      setIsOpen(true);
    } else {
      notify.warning({ message: t("notiConfirm.solution3") });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const menu = (
    <Menu>
      {menuItem?.map(({ title, key }) => {
        return (
          <Menu.Item key={key} onClick={() => handleOpen({ title, key })}>
            {title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      <Dropdown disabled={itemIDs?.length ? false : true} overlay={menu}>
        <Button loading={loading} type="primary">
          <Space>
            {t("warranty.warrantyRequestList.distributeFor")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <PopupSolution
        title={menuSelected.title}
        itemIDs={itemIDs}
        confirmDistribute={confirmDistribute}
        loading={loading}
        solution={menuSelected.key}
        isOpen={isOpen}
        handleCancel={handleCancel}
        setIsOpen={setIsOpen}
        selfVisibleControlled={false}
        selectedRows={selectedRows}
      />
    </>
  );
};

const ButtonCustomerDelivery = ({ loading, confirmReturn, record, itemIDs }) => {
  const [shippingType, setShippingType] = useState();
  const props = {
    confirmReturn,
    shippingType,
    loading,
    record,
  };
  const { platformName } = useGetInfoPlatform();

  const handleClick = (shippingType) => {
    confirmReturn({
      warrantyRequestItemIDs: itemIDs,
      shippingType: shippingType?.key,
      shippingConfig: {},
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(e) => setShippingType(e.key)} key={SHIPPING_TYPE.BUS}>
        <PopupSelectShipper title={t("warrantyRequest.detail.btnGroup.bus")} props={props} itemIDs={itemIDs} />
      </Menu.Item>
      <Menu.Item onClick={(e) => setShippingType(e.key)} key={SHIPPING_TYPE.DELIVER_3PARTY}>
        <PopupSelectShipper
          title={t("warrantyRequest.detail.btnGroup.deliver3Party")}
          props={props}
          itemIDs={itemIDs}
        />
      </Menu.Item>
      <Menu.Item onClick={handleClick} key={SHIPPING_TYPE.PICK_UP_COUNTER}>
        {t("warrantyRequest.detail.btnGroup.pickUpCounter")}
      </Menu.Item>
      <Menu.Item onClick={handleClick} key={SHIPPING_TYPE.URBAN_COD}>
        {t("warrantyRequest.detail.btnGroup.urbanCOD", { platformName })}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} disabled={itemIDs?.length ? false : true}>
      <Button loading={loading} type="primary">
        <Space>
          {t("warranty.warrantyRequestList.customerDelivery")}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const ButtonDeliverySuccessful = ({ complete, loading, itemIDs, itemID }) => (
  <PopupConfirmDeliComplete
    complete={complete}
    loading={loading}
    disabled={itemIDs?.length === 1 ? false : true}
    itemID={itemID}
  />
);

const ButtonGroup = ({
  activeKeyMain,
  activeKeyStatus,
  itemIDs,
  itemID,
  selectedRows,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const {
    loadingConfirmReturn,
    loadingConfirmCheck,
    loadingConfirmSolution,
    loadingComplete,
    loadingConfirmDistribute,
    confirmSolution,
    confirmDistribute,
    confirmCheck,
    complete,
    confirmReturn,
  } = useButtonGroup({
    itemIDs,
    itemID,
    setSelectedRowKeys,
    selectedRows,
  });

  const renderButtonByStatus = {
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK}`]: (
      <ButtonTest confirmCheck={confirmCheck} loading={loadingConfirmCheck} itemIDs={itemIDs} />
    ),
    [`${WARRANTY_SERIAL_STATUS.CHECKING}`]: (
      <ButtonChooseProcessingDirection
        record={selectedRows?.at(0)}
        itemIDs={itemIDs}
        loading={loadingConfirmSolution}
        confirmSolution={confirmSolution}
      />
    ),
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE}`]: (
      <ButtonTransferFor
        confirmDistribute={confirmDistribute}
        loading={loadingConfirmDistribute}
        itemIDs={itemIDs}
        selectedRows={selectedRows}
        selectedRowKeys={selectedRowKeys}
      />
    ),
    [WARRANTY_SERIAL_STATUS.REPAIRING]: <ButtonGetResult disabled={itemIDs?.length ? false : true} itemID={itemID} />,
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN}`]: (
      <ButtonCustomerDelivery
        itemIDs={itemIDs}
        confirmReturn={confirmReturn}
        loading={loadingConfirmReturn}
        record={selectedRows}
      />
    ),
    [`${WARRANTY_SERIAL_STATUS.DELIVERING}`]: (
      <ButtonDeliverySuccessful itemIDs={itemIDs} complete={complete} loading={loadingComplete} itemID={itemID} />
    ),
  };
  if (activeKeyMain === "accordingSerial" && activeKeyStatus !== TOTAL_ITEMS_STATUS?.ALL) {
    return <Space>{renderButtonByStatus[`${activeKeyStatus}`]}</Space>;
  } else {
    return (
      <Space>
        <ButtonCreateRequest selectedRows={selectedRows} />
      </Space>
    );
  }
};

export default ButtonGroup;
