import React from "react";
import "./index.scss";

const CommonCard = (props) => {
  const { className = "", children, ...restProps } = props;
  return (
    <div className={`common-card ${className}`} {...restProps}>
      {children}
    </div>
  );
};

export default CommonCard;
