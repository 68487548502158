import { t } from "i18next";
import { TabsMenu } from "components/CustomTabs";
import PageHeader from "components/PageHeader";
import useListPriceBid from "./useListPriceBid";
import "./index.scss";
import Toolbar from "./components/Toolbar";
import CustomTable from "components/CustomTable";
import { getQuery } from "utils/helperFuncs";

function ListPriceBid() {
  const {
    params,
    handleChange,
    onChangeParams,
    data,
    paginationData,
    loading,
    isStatusAll,
    handleSearch,
    expandedRowRender,
    sellersOptions,
    handleRemoveFilterTag,
    filterTags,
    setFilterTags,
    setParams,
    handleChangeTable,
    OPTION_OWNER_TYPE,
    renderColumns,
    dataSummaryTabs,
    PROMOTION_PRICE_BID_STATUS,
    OPTION_PRICE_BID_STATUS,
  } = useListPriceBid();
  const GET_QUERY = getQuery();

  const getTotalStatus = dataSummaryTabs?.priceBidSession?.getTotalStatus;

  const tabs = [
    {
      title: (
        <div className="title">
          {t("product.priceBid.All")} ({`${getTotalStatus?.all || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.ALL,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.Pending")} ({`${getTotalStatus?.waitingForStaffConfirm || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.WAITING_FOR_STAFF_CONFIRM,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.WaitResponse")} ({`${getTotalStatus?.waitingForSellerConfirm || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.WAITING_FOR_SELLER_CONFIRM,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.Accepted")} ({`${getTotalStatus?.approved || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.APPROVED,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.Reject")} ({`${getTotalStatus?.rejected || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.REJECTED,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.orderCreated")} ({`${getTotalStatus?.completed || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.COMPLETED,
    },
    {
      title: (
        <div className="title">
          {t("product.priceBid.Canceled")} ({`${getTotalStatus?.cancelled || 0}`})
        </div>
      ),
      key: PROMOTION_PRICE_BID_STATUS.CANCELED,
    },
  ];
  return (
    <div className="list-by-price-bid">
      <PageHeader
        pageTitle={t("product.priceBid.listByRequest")}
        routes={[
          { path: "/product", name: t("product.priceBid.product") },
          { path: "/", name: t("product.priceBid.priceBid") },
        ]}
      />
      <div className="campaign-container">
        <TabsMenu defaultActiveKey={GET_QUERY.activeKey} tabs={tabs} onChange={handleChange} />
        <Toolbar
          handleSearch={handleSearch}
          OPTION_OWNER_TYPE={OPTION_OWNER_TYPE}
          handleRemoveFilterTag={handleRemoveFilterTag}
          setFilterTags={setFilterTags}
          filterTags={filterTags}
          setParams={setParams}
          sellersOptions={sellersOptions}
          isStatusAll={isStatusAll}
          params={params}
          onChangeParams={onChangeParams}
          statusTabs={GET_QUERY.activeKey}
          OPTION_PRICE_BID_STATUS={OPTION_PRICE_BID_STATUS}
        />
        <CustomTable
          loading={loading}
          columns={renderColumns(GET_QUERY.activeKey)}
          dataSource={data}
          rowKey={(record, index) => record.id}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record?.events,
          }}
          scroll={{
            x: "max-content",
          }}
          onChange={handleChangeTable}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
}

export default ListPriceBid;
