import React, { useState } from "react";
import { Button, Popover, Space, Typography } from "antd";
import { t } from "i18next";
import { NOTE_TYPE } from "features/WarrantyRequest/constant";
import PopupExportFile from "features/WarrantyRequest/components/PopupExportFile";
import { SvgIcon } from "assets/icons";
import styles from "../../index.module.scss";

function PopoverExport({ onExport, handleExportFile, data, loading, disabled }) {
  const [openPopover, setOpenPopover] = useState(false);

  const hidePopover = () => {
    setOpenPopover(false);
  };

  const handleVisiblePopover = (value) => {
    setOpenPopover(value);
  };

  return (
    <Popover
      trigger={"click"}
      placement="bottom"
      visible={openPopover}
      onVisibleChange={(value) => handleVisiblePopover(value)}
      content={
        <Space direction="vertical">
          <Button
            type="text"
            disabled={disabled}
            onClick={() => {
              onExport({ noteType: NOTE_TYPE.RECEIVE_NOTE });
              hidePopover();
            }}
          >
            {t("common.receiveNote")}
          </Button>
          <PopupExportFile
            title={
              <Button onClick={hidePopover} type="text" disabled={disabled}>
                {t("common.returnNote")}
              </Button>
            }
            handleExportFile={handleExportFile}
            data={data}
            loading={loading}
          />
        </Space>
      }
    >
      <Button type="text" className="button">
        <SvgIcon.ArrowCircleDownIcon />
        <Typography.Text className={styles["color-content"]}>{t("warrantyRequest.detail.export")}</Typography.Text>
      </Button>
    </Popover>
  );
}

export default PopoverExport;
