import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PROMOTION_CAMPAIGN_STATUS } from "features/Campaign/constants";
import { useGetListCampaign, useGetSummaryTabs } from "hooks/campaign";
import useDebounce from "hooks/useDebounce";
import { useGetUserPermissions } from "hooks/user/user";
import { buildQueryString, getQuery } from "utils/helperFuncs";

export default function useListCampaign() {
  const GET_QUERY = getQuery();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm, 500);

  const [statusTabs, setStatusTabs] = useState(
    GET_QUERY?.statuses && GET_QUERY?.statuses?.length === 1 ? GET_QUERY?.statuses[0] : PROMOTION_CAMPAIGN_STATUS.ALL
  );
  const isStatusAll = statusTabs === PROMOTION_CAMPAIGN_STATUS?.ALL;

  const [params, setParams] = useState({
    filters: {
      timeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      query: GET_QUERY?.query || undefined,
      types: GET_QUERY?.types || [],
      saleChannelIDs: GET_QUERY?.saleChannelIDs || [],
      categoryIDs: GET_QUERY?.categoryIDs || [],
      brandIDs: GET_QUERY?.brandIDs || [],
      statuses: GET_QUERY?.statuses || [],
      isActive: GET_QUERY?.isActive || undefined,
      createdByIDs: GET_QUERY?.createdByIDs || undefined,
    },
    pagination: {
      limit: 10,
      offset: GET_QUERY?.offset || 0,
    },
    sort: [
      {
        by: "ID",
        sortType: "DESC",
      },
    ],
  });
  const { data: dataSummaryTabs } = useGetSummaryTabs();
  const { data, paginationData, loading, refetch } = useGetListCampaign(params);
  const { loading: loadUserPermissions } = useGetUserPermissions();

  const renderCount = (value) => {
    return value ? value : "0";
  };

  const tabs = [
    {
      title: (
        <div className="title">
          {t("campaign.all")} ({`${renderCount(dataSummaryTabs?.all?.count)}`})
        </div>
      ),
      key: PROMOTION_CAMPAIGN_STATUS.ALL,
    },
    {
      title: (
        <div className="title">
          {t("campaign.onGoing")} ({`${renderCount(dataSummaryTabs?.onGoing?.count)}`})
        </div>
      ),
      key: PROMOTION_CAMPAIGN_STATUS.ON_GOING,
    },
    {
      title: (
        <div className="title">
          {t("campaign.upcoming")} ({`${renderCount(dataSummaryTabs?.upcoming?.count)}`})
        </div>
      ),
      key: PROMOTION_CAMPAIGN_STATUS.UPCOMING,
    },
    {
      title: (
        <div className="title">
          {t("campaign.reconciling")} ({`${renderCount(dataSummaryTabs?.reconciling?.count)}`})
        </div>
      ),
      key: PROMOTION_CAMPAIGN_STATUS.RECONCILING,
    },
    {
      title: (
        <div className="title">
          {t("campaign.ended")} ({`${renderCount(dataSummaryTabs?.ended?.count)}`})
        </div>
      ),
      key: PROMOTION_CAMPAIGN_STATUS.ENDED,
    },
  ];

  const convertActiveKey = (activeKey) => {
    switch (activeKey) {
      case "ALL":
        return [];
      case "ON_GOING":
      case "UPCOMING":
      case "RECONCILING":
      case "ENDED":
        return [activeKey];
      default:
        return [];
    }
  };

  const handleChange = (activeKey) => {
    setStatusTabs(activeKey);
    setParams({
      filters: {
        timeRange: {
          from: undefined,
          to: undefined,
        },
        query: undefined,
        types: [],
        saleChannelIDs: [],
        categoryIDs: [],
        brandIDs: [],
        statuses: convertActiveKey(activeKey),
        isActive: undefined,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sort: [
        {
          by: "ID",
          sortType: "DESC",
        },
      ],
    });
  };

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const isLoading = loading || loadUserPermissions;

  useEffect(() => {
    buildQueryString({
      params: {
        offset: params?.pagination?.offset,
        limit: params?.pagination?.limit,
        types: params?.filters?.types,
        statuses: params?.filters?.statuses,
        from: params?.filters?.timeRange?.from,
        to: params?.filters?.timeRange?.to,
        saleChannelIDs: params?.filters?.saleChannelIDs,
        categoryIDs: params?.filters?.categoryIDs,
        brandIDs: params?.filters?.brandIDs,
        isActive: params?.filters?.isActive,
        query: params?.filters?.query,
        createdByIDs: params?.filters?.createdByIDs,
      },
    });
  }, [params]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sort: [
        {
          by: "ID",
          sortType: "DESC",
        },
      ],
    });
  }, [debouncedValue]);

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };

    setParams(newParams);
    const queryString = {
      ...newParams.filters,
      ...newParams.pagination,
      ...newParams.filters.timeRange,
    };
    buildQueryString({ params: { ...queryString } });
  }

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        isActive: GET_QUERY.isActive,
      },
    };
    setParams(newParams);
    buildQueryString({ params: { ...newParams } });
  }, []);

  return {
    params,
    statusTabs,
    data,
    tabs,
    handleChange,
    onChangeParams,
    isLoading,
    loading,
    paginationData,
    isStatusAll,
    handleSearch,
    onTableChange,
    refetch,
  };
}
