import React from "react";
import { Card as AntCard, Col, List, Row, Space, Tooltip, Typography } from "antd";
import { t } from "i18next";
import ModalAddBankAccount from "../ModalAddBankAccount";
import { SvgIcon } from "assets/icons";
import useBankAccount from "./useBankAccount";
import PopconfirmDelete from "components/PopconfirmDelete";
import Card from "features/Cart/components/Card";
import ModalUpdateBankAccount from "../ModalUpdateBankAccount";

const titleCard = {
  level: 5,
  style: {
    color: "#052987",
  },
};

function CardInfoBankAccount({ banksOptions, widthNotFull, sellerID, bankAccounts, handleGetBankAccounts }) {
  const {
    updateBankAccountHandler,
    submitHandler,
    closeModal,
    openModal,
    closeModalUpdate,
    openModalUpdate,
    confirmDelete,
    visibleModalUpdate,
    loadingBankAccount,
    form,
    visible,
    currentModalData,
  } = useBankAccount({ sellerID, bankAccounts, handleGetBankAccounts });

  return (
    <Card title={<Typography.Title {...titleCard}>{t("supplier.infoBankAccount")}</Typography.Title>}>
      <Space style={{ width: "100%" }} direction="vertical">
        {bankAccounts?.length ? (
          <div>
            {bankAccounts?.map((item, index) => {
              return (
                <AntCard.Grid key={index} hoverable={true} style={{ width: !widthNotFull && "100%" }}>
                  <Row style={{ justifyContent: "space-between" }}>
                    <Col style={{ maxWidth: "90%" }}>
                      <Row>
                        <Col span={24} style={{ paddingBottom: "6px" }}>
                          <Typography.Text strong style={{ paddingBottom: "4px" }}>
                            {banksOptions?.map((itemBankOptions) => {
                              return itemBankOptions?.value === item?.bankID && itemBankOptions?.label;
                            })}
                          </Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text>{t("seller.labelInput.bankAccountNumber")}: </Typography.Text>
                        </Col>
                        <Col span={18}>
                          <Typography.Text>{item?.bankAccountNumber}</Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text>{t("seller.labelInput.accountHolder")}: </Typography.Text>
                        </Col>
                        <Col span={18}>
                          <Typography.Text>{item?.ownerBankAccountName || item?.name}</Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text>{t("seller.labelInput.bankBranch")}: </Typography.Text>
                        </Col>
                        <Col span={18}>
                          <Typography.Text>{item?.bankBranch}</Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col style={{ maxWidth: "10%" }}>
                      <Space
                        direction="vertical"
                        style={{
                          height: "100%",
                          justifyContent: "space-evenly",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ cursor: "pointer" }} onClick={() => openModalUpdate(item)}>
                          <Tooltip title={t("common.edit")}>
                            <SvgIcon.EditIcon2 />
                          </Tooltip>
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          <PopconfirmDelete
                            title={t("supplier.notiMessage.onDeleteBankAccount")}
                            onConfirm={() => confirmDelete(item?.id)}
                          />
                        </div>
                      </Space>
                    </Col>
                  </Row>
                </AntCard.Grid>
              );
            })}
          </div>
        ) : null}
        {visibleModalUpdate && (
          <ModalUpdateBankAccount
            banksOptions={banksOptions}
            submitHandler={submitHandler}
            visible={visibleModalUpdate}
            closeModal={closeModalUpdate}
            data={currentModalData}
            form={form}
            loadingBankAccount={loadingBankAccount}
            updateBankAccountHandler={updateBankAccountHandler}
          />
        )}
        <ModalAddBankAccount
          banksOptions={banksOptions}
          submitHandler={submitHandler}
          closeModal={closeModal}
          openModal={openModal}
          visible={visible}
          form={form}
          loadingBankAccount={loadingBankAccount}
          bankAccounts={bankAccounts}
          handleGetBankAccounts={handleGetBankAccounts}
        />
      </Space>
    </Card>
  );
}

export default CardInfoBankAccount;
