import { Col, Space, Typography } from "antd";

import styles from "./index.module.scss";

function TitleCard({ title, value }) {
  return (
    <Col span={12} style={{ display: "flex" }}>
      <Space>
        <Typography.Text className={styles["title-card"]}>{title}</Typography.Text>
        <Typography.Text style={{ color: "#1A1B1D" }} className={styles["title-card"]}>
          {value && `#${value}`}
        </Typography.Text>
      </Space>
    </Col>
  );
}

const SubTitleCard = ({ subTitle }) => {
  return (
    <Typography.Text className={`${styles["title-card"]} ${styles["sub-title-card"]}`}>{subTitle}</Typography.Text>
  );
};

export { SubTitleCard, TitleCard };
