import React, { useEffect, useRef, useState } from "react";
import { Input, Tag, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./index.scss";
import { t } from "i18next";
import { SERIAL_AVAILABILITY } from "config/constants";

const tagPlusStyle = {
  background: "transition",
  borderStyle: "dashed",
};

function InputTag({
  tags = [],
  onChange,
  moreThanNumber,
  searchResult = [],
  tagsValueResult,
  isHidden,
  form,
  idPopup,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState(true);
  const [visibleSearchResult, setVisibleSearchResult] = useState(false);

  const handleAdd = async () => {
    const newVal = inputValue
      ?.split(/[, ]+/)
      ?.filter((item) => item.trim() !== "")
      ?.map((tag) => tag?.trim());
    if (newVal) {
      const newValues = newVal.filter((code) => !tags.some((item) => item.code === code));
      if (newValues.length > 0) {
        const updatedTags = [...tags, ...newValues.map((code) => ({ code, status: SERIAL_AVAILABILITY.AVAILABLE }))];
        onChange(updatedTags);
      }
    }
    setInputValue("");
  };

  const showInput = () => {
    inputRef.current?.focus();
    setInputVisible(true);
  };

  const handleRemove = (removedValue) => {
    const newValues = tags.filter((val) => val.code !== removedValue);
    onChange(newValues);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputPressEnter = () => {
    handleAdd();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAdd();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchResult?.length) {
      setVisibleSearchResult(true);
      setVisibleTags(false);
    } else {
      setVisibleSearchResult(false);
      setVisibleTags(true);
    }
  }, [JSON.stringify(searchResult)]);
  const isHiddenEnterSerial = tags.some((item) => item.status === SERIAL_AVAILABILITY.UNAVAILABLE);
  return (
    <div className={`input-tag-container-edit`}>
      <div className={`${isHidden && "isHidden"}`}>
        <div className="input-tag-tags">
          {tags?.length > 8 && idPopup === false && (
            <Tag className="input-tag-tag custom-tags-by-serial">
              <Typography.Link italic>{`+${tags?.length - 8} serial...`}</Typography.Link>
            </Tag>
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === false &&
            tags
              .sort((a, b) => {
                if (a.status === SERIAL_AVAILABILITY.UNAVAILABLE && b.status !== SERIAL_AVAILABILITY.UNAVAILABLE) {
                  return 1;
                } else if (
                  a.status !== SERIAL_AVAILABILITY.UNAVAILABLE &&
                  b.status === SERIAL_AVAILABILITY.UNAVAILABLE
                ) {
                  return -1;
                } else {
                  return 0;
                }
              })
              ?.slice(-8)
              .map((val, index) => (
                <Tag
                  className={`input-tag-tag  ${
                    val?.status === SERIAL_AVAILABILITY.AVAILABLE ? "custom-tags-by-serial" : "custom-tags-unvailable"
                  }`}
                  key={index}
                  closable={!isHiddenEnterSerial}
                  onClose={() => handleRemove(val?.code)}
                >
                  {val?.code}
                </Tag>
              ))
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t("serialIEDetail.notFound")}</div>
          ) : (
            visibleTags &&
            idPopup === true &&
            tags?.map((val) => (
              <Tag
                className={`input-tag-tag  ${
                  val?.status === SERIAL_AVAILABILITY.AVAILABLE ? "custom-tags-by-serial" : "custom-tags-unvailable"
                }`}
                key={val?.code}
                closable={!isHiddenEnterSerial}
                onClose={() => handleRemove(val?.code)}
              >
                {val?.code}
              </Tag>
            ))
          )}
          {visibleSearchResult &&
            searchResult?.map((val) => (
              <Tag
                className={`input-tag-tag  ${
                  val?.status === SERIAL_AVAILABILITY.AVAILABLE ? "custom-tags-by-serial" : "custom-tags-unvailable"
                }`}
                key={val?.code}
                closable
                onClose={() => handleRemove(val?.code)}
              >
                {val?.code}
              </Tag>
            ))}
        </div>
      </div>
      {idPopup ? (
        <></>
      ) : idPopup === false && inputVisible ? (
        <Input
          className="input-value input-enter-serial"
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 100,
          }}
          value={inputValue}
          onChange={handleChange}
          onPressEnter={handleInputPressEnter}
          onKeyDown={handleKeyDown}
          placeholder={t("serialImportEdit.newSerial")}
        />
      ) : (
        !isHiddenEnterSerial && (
          <Tag className="input-value input-enter-serial" onClick={showInput} style={tagPlusStyle}>
            <PlusOutlined /> {t("serialIEDetail.addNew")}
          </Tag>
        )
      )}
    </div>
  );
}

export default InputTag;
