import CustomModal from "components/CustomModal";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const InviteSellerButton = ({ onConfirm, countSeller = 0, isAllInvited }) => {
  const { t } = useTranslation();
  const { platformName } = useGetInfoPlatform();

  return (
    <CustomModal
      title={<span className={`${styles.modalTitle}`}>{t("seller.confirmInvited")}</span>}
      closable={false}
      centered={true}
      footer={false}
      onOke={onConfirm}
      customComponent={<PrimaryButtonOutLine title={t("seller.invitingSeller")} />}
    >
      {isAllInvited ? (
        <p className={`${styles.modalMessage}`}>
          <span>{t("seller.invitedMsg1of2")}</span>&nbsp;
          <span className={`${styles.countSeller}`}>({countSeller})</span>&nbsp;
          <span>{t("seller.invitedMsg2of2")}</span>&nbsp;
          <span className={`${styles.countSeller}`}>({countSeller})</span>&nbsp;
          <span>{t("seller.invitedMsg3of2")}</span>
        </p>
      ) : (
        <p className={`${styles.modalMessage}`}>
          <span>{t("seller.invitedMsg1of1", { platformName })}</span>&nbsp;
          <span className={`${styles.countSeller}`}>({countSeller})</span>&nbsp;
          <span>{t("seller.invitedMsg2of1")}</span>
        </p>
      )}
    </CustomModal>
  );
};

export default InviteSellerButton;
