import { SvgIcon } from "assets/icons";
import { USER_PERMISSIONS } from "config/constants";
import PotentialCustomerTabs from "features/PotentialCustomer";

const router = [
  {
    key: "potential-customer",
    title: "potentialCustomer.customer",
    icon: (
      <span className="icon-potential-customer">
        <SvgIcon.IconPotentialCustomer />
      </span>
    ),
    children: [
      {
        key: "potential-customer-list",
        title: "potentialCustomer.customerList",
        path: "/potential-customer-list",
        component: PotentialCustomerTabs,
        exact: true,
        permissions: [USER_PERMISSIONS.CUSTOMERS_VIEW],
      },
    ],
  },
];

export default router;
