import React from "react";
import { Button } from "antd";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import AddressForm from "../AddressForm/AddressForm";
import useModalAdd from "./useModalAddAddress";

const ModalAddAddress = ({ sellerID, refetch }) => {
  const { createContact, creating, switchClose, form, initialValues } = useModalAdd({
    sellerID,
  });

  return (
    <CustomModal
      {...{
        onOke: createContact,
        okeButtonLabel: t("common.save"),
        customComponent: (
          <Button className="create-btn" type="primary">
            {t("seller.componentAddressList.createNewAddress")}
          </Button>
        ),
        title: <span className="add-address-title">{t("seller.componentAddressList.createNewAddressTitle")}</span>,
        centered: true,
        footer: false,
        width: "fit-content",
        buttonLoading: creating,
        switchClose,
        afterClose: refetch,
        isBlockCloseOnOke: true,
      }}
    >
      <AddressForm form={form} initialValues={initialValues} />
    </CustomModal>
  );
};

export default ModalAddAddress;
