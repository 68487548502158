import React, { useState } from "react";
import { useGetSeller } from "hooks/seller";
import { getQuery } from "utils/helperFuncs";
const convertParams = (params) => ({
  filters: {
    timeRange:
      !params?.timeRangeFrom && !params?.timeRangeTo
        ? undefined
        : { from: params?.timeRangeFrom, to: params?.timeRangeTo },
    query: params.query,
    exportedWarehouseID: params.exportedWarehouseID,
    isActive: params.isActive,
    sellerLevelID: params.sellerLevelID,
    exportedWarehouseIDs: params.exportedWarehouseIDs,
    sellerLevelIDs: params.sellerLevelIDs,
    inviteStatus: params.inviteStatus,
    sellerGroupIDs: params.sellerGroupIDs,
    isSeller: params.isSeller,
    isSupplier: params.isSupplier,
  },
  pagination: {
    offset: params.offset,
    limit: params.limit,
  },
});

function useList() {
  const GET_QUERY = getQuery();

  const getDefaultParams = () => ({
    offset: GET_QUERY.offset || 0,
    limit: GET_QUERY.limit || 10,
    query: GET_QUERY.query || undefined,
    timeRangeFrom: GET_QUERY.timeRangeFrom || undefined,
    timeRangeTo: GET_QUERY.timeRangeTo || undefined,
    exportedWarehouseID: GET_QUERY.exportedWarehouseID || undefined,
    isActive: GET_QUERY.isActive,
    sellerLevelID: GET_QUERY.sellerLevelID || undefined,
    exportedWarehouseIDs: GET_QUERY.exportedWarehouseIDs || undefined,
    sellerLevelIDs: GET_QUERY.sellerLevelIDs || undefined,
    inviteStatus: GET_QUERY.inviteStatus || undefined,
    sellerGroupIDs: GET_QUERY.sellerGroupIDs || undefined,
    isSeller: true,
    isSupplier: GET_QUERY.isSupplier || undefined,
  });

  const [params, setParams] = useState(getDefaultParams());

  const { loading, data, total, refetch } = useGetSeller(convertParams(params));

  return {
    loading,
    data,
    total,
    refetch,
    setParams,
    params,
  };
}

export default useList;
