import { t } from "i18next";

export const PURCHASE_TABS = {
  PROPOSED_PURCHASE: "PROPOSED_PURCHASE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
};

export const PURCHASE_STATUS = {
  ALL: "ALL",
  WAITING_DEPOSIT: "WAITING_DEPOSIT",
  WAITING_FOR_DEPOSIT: "WAITING_FOR_DEPOSIT",
  WAITING_FOR_RECEIVING: "WAITING_FOR_RECEIVING",
  WAITING_FOR_CHECKING_INFO: "WAITING_FOR_CHECKING_INFO",
  WAITING_FOR_CHECKING_QUALITY: "WAITING_FOR_CHECKING_QUALITY",
  WAITING_FOR_IMPORT_STOCK: "WAITING_FOR_IMPORT_STOCK",
  WAITING_SAVE_DOCUMENT: "WAITING_SAVE_DOCUMENT",
  WAITING_FOR_HANDLING: "WAITING_FOR_HANDLING",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  WAITING_FOR_IMPORT_SERIAL: "WAITING_FOR_IMPORT_SERIAL",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const setColorStatusPurchaseOrder = (status) => {
  switch (status) {
    case PURCHASE_STATUS.WAITING_FOR_DEPOSIT:
      return "geekblue";
    case PURCHASE_STATUS.COMPLETED:
      return "green";
    case PURCHASE_STATUS.CANCELED:
      return "red";
    default:
      return "orange";
  }
};

export const purchaseStatusLabel = {
  [`${PURCHASE_STATUS.ALL}`]: t("purchase.purchaseStatusCount.all"),
  [`${PURCHASE_STATUS.WAITING_DEPOSIT}`]: t("purchase.purchaseStatusCount.waitingDeposit"),
  [`${PURCHASE_STATUS.WAITING_FOR_DEPOSIT}`]: t("purchase.purchaseStatusCount.waitingDeposit"),
  [`${PURCHASE_STATUS.WAITING_FOR_RECEIVING}`]: t("purchase.purchaseStatusCount.waitingForReceiving"),
  [`${PURCHASE_STATUS.WAITING_FOR_CHECKING_INFO}`]: t("purchase.purchaseStatusCount.waitingForCheckingInfo"),
  [`${PURCHASE_STATUS.WAITING_FOR_CHECKING_QUALITY}`]: t("purchase.purchaseStatusCount.waitingForCheckingQuality"),
  [`${PURCHASE_STATUS.WAITING_FOR_IMPORT_STOCK}`]: t("purchase.purchaseStatusCount.waitingForImportStock"),
  [`${PURCHASE_STATUS.WAITING_SAVE_DOCUMENT}`]: t("purchase.purchaseStatusCount.waitingForSaveDocument"),
  [`${PURCHASE_STATUS.WAITING_FOR_HANDLING}`]: t("purchase.purchaseStatusCount.waitingForHandling"),
  [`${PURCHASE_STATUS.WAITING_FOR_PAYMENT}`]: t("purchase.purchaseStatusCount.waitingForPayment"),
  [`${PURCHASE_STATUS.WAITING_FOR_IMPORT_SERIAL}`]: t("purchase.purchaseStatusCount.waitingForImportSerial"),
  [`${PURCHASE_STATUS.COMPLETED}`]: t("purchase.purchaseStatusCount.completed"),
  [`${PURCHASE_STATUS.CANCELED}`]: t("purchase.purchaseStatusCount.canceled"),
};

export const purchaseStatusOptions = [
  {
    value: PURCHASE_STATUS.WAITING_FOR_DEPOSIT,
    label: t("purchase.purchaseStatusCount.waitingDeposit"),
  },
  {
    value: PURCHASE_STATUS.WAITING_FOR_RECEIVING,
    label: t("purchase.purchaseStatusCount.waitingForReceiving"),
  },
  {
    value: PURCHASE_STATUS.WAITING_FOR_HANDLING,
    label: t("purchase.purchaseStatusCount.waitingForHandling"),
  },
  {
    value: PURCHASE_STATUS.WAITING_FOR_IMPORT_STOCK,
    label: t("purchase.purchaseStatusCount.waitingForImportStock"),
  },
  {
    value: PURCHASE_STATUS.COMPLETED,
    label: t("purchase.purchaseStatusCount.completed"),
  },
  {
    value: PURCHASE_STATUS.CANCELED,
    label: t("purchase.purchaseStatusCount.canceled"),
  },
];

export const PURCHASE_REQUEST_TYPE = {
  DOMESTIC: "DOMESTIC",
  OVERSEAS: "OVERSEAS",
};

export const purchaseRequestTypeLabel = {
  [`${PURCHASE_REQUEST_TYPE.DOMESTIC}`]: t("purchase.filters.domestic"),
  [`${PURCHASE_REQUEST_TYPE.OVERSEAS}`]: t("purchase.filters.overseas"),
};

export const PURCHASE_TYPE_OPTIONS = [
  {
    value: PURCHASE_REQUEST_TYPE.DOMESTIC,
    label: t("purchase.filters.domestic"),
  },
  {
    value: PURCHASE_REQUEST_TYPE.OVERSEAS,
    label: t("purchase.filters.overseas"),
  },
];

export const DATE_PERIOD_UNIT = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
};

export const datePeriodOptions = [
  {
    label: t("common.DAY"),
    value: DATE_PERIOD_UNIT.DAY,
  },
  {
    label: t("common.month"),
    value: DATE_PERIOD_UNIT.MONTH,
  },
  {
    label: t("common.YEAR"),
    value: DATE_PERIOD_UNIT.YEAR,
  },
];

export const dataPeriodUnitLabel = {
  [DATE_PERIOD_UNIT.DAY]: t("common.DAY"),
  [DATE_PERIOD_UNIT.MONTH]: t("common.month"),
  [DATE_PERIOD_UNIT.YEAR]: t("common.YEAR"),
};

export const PURCHASE_ORDER_EVENT = {
  CONFIRM_REJECTED_CHECKING_INFO: "CONFIRM_REJECTED_CHECKING_INFO",
  CONFIRM_REJECTED_CHECKING_QUALITY: "CONFIRM_REJECTED_CHECKING_QUALITY",
  CONFIRM_CANCELED: "CONFIRM_CANCELED",
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: "BANK_TRANSFER",
  DEBT: "DEBT",
  CASH: "CASH",
};

export const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

export const MAX_SCREEN = "calc(80vh - 280px)";
export const MIN_SCREEN = "calc(60vh - 280px)";

export const PROPOSED_PURCHASE_STATUS = {
  WAITING_APPROVAL: "WAITING_APPROVAL",
  WAITING_FOR_BUYING: "WAITING_FOR_BUYING",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
  WAITING_TECHNICAL_CONFIRM: "WAITING_TECHNICAL_CONFIRM",
  WAITING_FOR_STOCK: "WAITING_FOR_STOCK",
};

export const proposedPurchaseStatusLabel = {
  [PROPOSED_PURCHASE_STATUS.WAITING_APPROVAL]: t("purchase.status.waitingForApprove"),
  [PROPOSED_PURCHASE_STATUS.WAITING_FOR_BUYING]: t("purchase.status.waitingForBuying"),
  [PROPOSED_PURCHASE_STATUS.REJECTED]: t("purchase.status.rejected"),
  [PROPOSED_PURCHASE_STATUS.COMPLETED]: t("purchase.status.completed"),
  [PROPOSED_PURCHASE_STATUS.CANCELED]: t("purchase.status.canceled"),
};

export const proposedPurchaseStatusOptions = [
  {
    value: PROPOSED_PURCHASE_STATUS.WAITING_APPROVAL,
    label: t("purchase.status.waitingForApprove"),
  },
  {
    value: PROPOSED_PURCHASE_STATUS.WAITING_FOR_BUYING,
    label: t("purchase.status.waitingForBuying"),
  },
  {
    value: PROPOSED_PURCHASE_STATUS.REJECTED,
    label: t("purchase.status.rejected"),
  },
  {
    value: PROPOSED_PURCHASE_STATUS.COMPLETED,
    label: t("purchase.status.completed"),
  },
  {
    value: PROPOSED_PURCHASE_STATUS.CANCELED,
    label: t("purchase.status.canceled"),
  },
];

export const validTabValues = [
  PURCHASE_STATUS.WAITING_FOR_DEPOSIT,
  PURCHASE_STATUS.WAITING_FOR_HANDLING,
  PURCHASE_STATUS.WAITING_FOR_RECEIVING,
];

export const styleHeaderCard = {
  backgroundColor: "#e7f0ff",
};

export const styleHeaderCardChildren = {
  backgroundColor: "#fff",
  flexGrow: 1,
};

export const styleTitleCard = {
  color: "#052987",
};

export const PAYMENT_TERMS = {
  ADVANCE_PAYMENT: "ADVANCE_PAYMENT",
  CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
  DEFERRED_PAYMENT: "DEFERRED_PAYMENT",
};

export const paymentTermsLabel = {
  [PAYMENT_TERMS.ADVANCE_PAYMENT]: t("purchase.deposit"),
  [PAYMENT_TERMS.CASH_ON_DELIVERY]: t("purchase.paymentOnDelivery"),
  [PAYMENT_TERMS.DEFERRED_PAYMENT]: t("purchase.payLater"),
};

export const CURRENCY_INPUT = {
  VND: "VND",
  USD: "USD",
};

export const currencyFormat = {
  [`${CURRENCY_INPUT.VND}`]: "₫",
  [`${CURRENCY_INPUT.USD}`]: "$",
};

export const optionCurrency = [
  {
    label: "₫",
    value: CURRENCY_INPUT.VND,
  },
  {
    label: "$",
    value: CURRENCY_INPUT.USD,
  },
];

export const ORDER_TYPE = {
  FULLY: "FULLY",
  PARTIALLY: "PARTIALLY",
};

export const paymentRequest = {
  [`${PAYMENT_METHODS.BANK_TRANSFER}`]: t("warehouse.recommendImport.BANK_TRANSFER"),
  [`${PAYMENT_METHODS.DEBT}`]: t("warehouse.recommendImport.DEBT"),
  [`${PAYMENT_METHODS.CASH}`]: t("warehouse.recommendImport.CASH"),
};

export const paymentTerms = {
  [`${PAYMENT_TERMS.ADVANCE_PAYMENT}`]: t("warehouse.recommendImport.ADVANCE_PAYMENT"),
  [`${PAYMENT_TERMS.CASH_ON_DELIVERY}`]: t("warehouse.recommendImport.CASH_ON_DELIVERY"),
  [`${PAYMENT_TERMS.DEFERRED_PAYMENT}`]: t("warehouse.recommendImport.DEFERRED_PAYMENT"),
};

export const RECOMMEND_IMPORT = {
  ALL: "ALL",
  WAITING_APPROVAL: "WAITING_APPROVAL",
  REJECTED: "REJECTED",
  WAITING_FOR_BUYING: "WAITING_FOR_BUYING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};
export const IMPORT_REQUEST_STATUS = {
  CONFIRM_COMPLETE: "CONFIRM_COMPLETE",
  CONFIRM_APPROVE: "CONFIRM_APPROVE",
  CANCEL: "CANCEL",
};

export const convertActiveKey = (activeKey) => {
  switch (activeKey) {
    case "ALL":
      return [];
    case "WAITING_APPROVAL":
      return [RECOMMEND_IMPORT.WAITING_APPROVAL];
    case "REJECTED":
      return [RECOMMEND_IMPORT.REJECTED];
    case "WAITING_FOR_BUYING":
      return [RECOMMEND_IMPORT.WAITING_FOR_BUYING];
    case "COMPLETED":
      return [RECOMMEND_IMPORT.COMPLETED];
    case "CANCELED":
      return [RECOMMEND_IMPORT.CANCELED];
    case "WAITING_TECHNICAL_CONFIRM":
      return [SALES_RETURN.WAITING_TECHNICAL_CONFIRM];
    case "WAITING_FOR_STOCK":
      return [SALES_RETURN.WAITING_FOR_STOCK];
    default:
      return [];
  }
};

export const IMPORT_REQUEST_TYPE = {
  DOMESTIC: "DOMESTIC",
  OVERSEAS: "OVERSEAS",
  REFUND: "REFUND",
  RE_IMPORT_WARRANTY: "RE_IMPORT_WARRANTY",
};

export const importRequestType = {
  [`${IMPORT_REQUEST_TYPE.DOMESTIC}`]: t("warehouse.recommendImport.DOMESTIC"),
  [`${IMPORT_REQUEST_TYPE.OVERSEAS}`]: t("warehouse.recommendImport.OVERSEAS"),
  [`${IMPORT_REQUEST_TYPE.REFUND}`]: t("warehouse.salesReturn.REFUND"),
  [`${IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY}`]: t("warehouse.salesReturn.RE_IMPORT_WARRANTY"),
};

export const importRequestStatus = {
  [`${RECOMMEND_IMPORT.WAITING_APPROVAL}`]: t("warehouse.recommendImport.pending"),
  [`${RECOMMEND_IMPORT.REJECTED}`]: t("warehouse.recommendImport.rejected"),
  [`${RECOMMEND_IMPORT.WAITING_FOR_BUYING}`]: t("warehouse.recommendImport.waitingBuy"),
  [`${RECOMMEND_IMPORT.COMPLETED}`]: t("warehouse.recommendImport.complete"),
  [`${RECOMMEND_IMPORT.CANCELED}`]: t("warehouse.recommendImport.canceled"),
};

export const setColorStatusImportRequest = (status) => {
  switch (status) {
    case RECOMMEND_IMPORT.WAITING_APPROVAL:
      return "orange";
    case RECOMMEND_IMPORT.WAITING_FOR_BUYING:
      return "geekblue";
    case RECOMMEND_IMPORT.COMPLETED:
      return "green";
    case RECOMMEND_IMPORT.REJECTED:
      return "red";
    case RECOMMEND_IMPORT.CANCELED:
      return "purple";
    default:
      break;
  }
};

export const optionImportRequestStatus = [
  {
    label: t("warehouse.recommendImport.pending"),
    value: RECOMMEND_IMPORT.WAITING_APPROVAL,
  },
  {
    label: t("warehouse.recommendImport.rejected"),
    value: RECOMMEND_IMPORT.REJECTED,
  },
  {
    label: t("warehouse.recommendImport.waitingBuy"),
    value: RECOMMEND_IMPORT.WAITING_FOR_BUYING,
  },
  {
    label: t("warehouse.recommendImport.complete"),
    value: RECOMMEND_IMPORT.COMPLETED,
  },
  {
    label: t("warehouse.recommendImport.canceled"),
    value: RECOMMEND_IMPORT.CANCELED,
  },
];

export const optionImportRequestType = [
  {
    label: t("warehouse.recommendImport.DOMESTIC"),
    value: IMPORT_REQUEST_TYPE.DOMESTIC,
  },
  {
    label: t("warehouse.recommendImport.OVERSEAS"),
    value: IMPORT_REQUEST_TYPE.OVERSEAS,
  },
];

export const optionPaymentRequest = [
  {
    label: t("warehouse.recommendImport.BANK_TRANSFER"),
    value: PAYMENT_METHODS.BANK_TRANSFER,
  },
  {
    label: t("warehouse.recommendImport.DEBT"),
    value: PAYMENT_METHODS.DEBT,
  },
  {
    label: t("warehouse.recommendImport.CASH"),
    value: PAYMENT_METHODS.CASH,
  },
];

export const optionPaymentTerms = [
  {
    label: t("warehouse.recommendImport.ADVANCE_PAYMENT"),
    value: PAYMENT_TERMS.ADVANCE_PAYMENT,
  },
  {
    label: t("warehouse.recommendImport.CASH_ON_DELIVERY"),
    value: PAYMENT_TERMS.CASH_ON_DELIVERY,
  },
  {
    label: t("warehouse.recommendImport.DEFERRED_PAYMENT"),
    value: PAYMENT_TERMS.DEFERRED_PAYMENT,
  },
];

export const WARRENTY_PERIOD = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
};

export const optionwarrantyPeriod = [
  {
    label: t("common.day"),
    value: "DAY",
  },
  {
    label: t("common.monthB"),
    value: "MONTH",
  },
  {
    label: t("common.year"),
    value: "YEAR",
  },
];

export const SALES_RETURN = {
  ALL: "ALL",
  WAITING_APPROVAL: "WAITING_APPROVAL",
  REJECTED: "REJECTED",
  WAITING_TECHNICAL_CONFIRM: "WAITING_TECHNICAL_CONFIRM",
  WAITING_FOR_STOCK: "WAITING_FOR_STOCK",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const SaleReturnStatus = {
  [`${SALES_RETURN.WAITING_APPROVAL}`]: t("warehouse.recommendImport.pending"),
  [`${SALES_RETURN.REJECTED}`]: t("warehouse.recommendImport.rejected"),
  [`${SALES_RETURN.WAITING_TECHNICAL_CONFIRM}`]: t("warehouse.salesReturn.quantityChecking"),
  [`${SALES_RETURN.WAITING_FOR_STOCK}`]: t("warehouse.salesReturn.waitingForStock"),
  [`${SALES_RETURN.COMPLETED}`]: t("warehouse.recommendImport.complete"),
  [`${SALES_RETURN.CANCELED}`]: t("warehouse.recommendImport.canceled"),
};

export const optionSalesReturnType = [
  {
    label: t("warehouse.salesReturn.REFUND"),
    value: IMPORT_REQUEST_TYPE.REFUND,
  },
  {
    label: t("warehouse.salesReturn.RE_IMPORT_WARRANTY"),
    value: IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY,
  },
];

export const setColorStatusSalesReturn = (status) => {
  switch (status) {
    case SALES_RETURN.WAITING_APPROVAL:
      return "orange";
    case SALES_RETURN.WAITING_TECHNICAL_CONFIRM:
      return "geekblue";
    case SALES_RETURN.WAITING_FOR_STOCK:
      return "orange";
    case SALES_RETURN.COMPLETED:
      return "green";
    case SALES_RETURN.REJECTED:
      return "red";
    case SALES_RETURN.CANCELED:
      return "red";
    default:
      break;
  }
};

export const optionSalesReturnStatus = [
  {
    label: t("warehouse.recommendImport.pending"),
    value: SALES_RETURN.WAITING_APPROVAL,
  },
  {
    label: t("warehouse.recommendImport.rejected"),
    value: SALES_RETURN.REJECTED,
  },
  {
    label: t("warehouse.salesReturn.quantityChecking"),
    value: SALES_RETURN.WAITING_TECHNICAL_CONFIRM,
  },
  {
    label: t("warehouse.salesReturn.waitingForStock"),
    value: SALES_RETURN.WAITING_FOR_STOCK,
  },
  {
    label: t("warehouse.recommendImport.complete"),
    value: SALES_RETURN.COMPLETED,
  },

  {
    label: t("warehouse.recommendImport.canceled"),
    value: SALES_RETURN.CANCELED,
  },
];

export const SHIPPING_TYPE = {
  BUS: "BUS",
  URBAN_COD: "URBAN_COD",
  DELIVER_3PARTY: "DELIVER_3PARTY",
  PICK_UP_COUNTER: "PICK_UP_COUNTER",
  URBAN_NOT_COD: "URBAN_NOT_COD",
};

export const CAUSE_REJECT = {
  CAUSE_SUPPLIER_INFO_IS_INCORRECT: "CAUSE_SUPPLIER_INFO_IS_INCORRECT",
  CAUSE_WRONG_DELIVERED_PRODUCT: "CAUSE_WRONG_DELIVERED_PRODUCT",
  CAUSE_WRONG_PRODUCT_QUANTITY: "CAUSE_WRONG_PRODUCT_QUANTITY",
  CAUSE_DELIVERY_NOTE_AND_ORDER_INFO_DIFFERENT: "CAUSE_DELIVERY_NOTE_AND_ORDER_INFO_DIFFERENT",
  CAUSE_NOT_QUALIFIED_QUALITY: "CAUSE_NOT_QUALIFIED_QUALITY",
};

export const optionsCauseReject = [
  { label: "Thông tin nhà cung cấp không đúng", value: "CAUSE_SUPPLIER_INFO_IS_INCORRECT" },
  { label: "Giao sai mã hàng hóa", value: "CAUSE_WRONG_DELIVERED_PRODUCT" },
  { label: "Số lượng giao sai", value: "CAUSE_WRONG_PRODUCT_QUANTITY" },
  {
    label: "Thông tin phiếu giao hàng và đơn mua hàng khác nhau",
    value: "CAUSE_DELIVERY_NOTE_AND_ORDER_INFO_DIFFERENT",
  },
  { label: "Chất lượng hàng hóa không đạt", value: "CAUSE_NOT_QUALIFIED_QUALITY" },
];

export const renderCauseReject = {
  [`${CAUSE_REJECT.CAUSE_SUPPLIER_INFO_IS_INCORRECT}`]: "Thông tin nhà cung cấp không đúng",
  [`${CAUSE_REJECT.CAUSE_WRONG_DELIVERED_PRODUCT}`]: "Giao sai mã hàng hóa",
  [`${CAUSE_REJECT.CAUSE_WRONG_PRODUCT_QUANTITY}`]: "Số lượng giao sai",
  [`${CAUSE_REJECT.CAUSE_DELIVERY_NOTE_AND_ORDER_INFO_DIFFERENT}`]:
    "Thông tin phiếu giao hàng và đơn mua hàng khác nhau",
  [`${CAUSE_REJECT.CAUSE_NOT_QUALIFIED_QUALITY}`]: "Chất lượng hàng hóa không đạt",
};
