import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import CustomTable from "components/CustomTable";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import "styles/custom_component.scss";
import "./index.scss";
import FormInput from "components/FormInput";
import useList from "./useList";
import { Button, Row, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import StateSwitch from "components/StateSwitch";
import Img from "assets/images";
import { FiltersTag } from "./components/FiltersTag";

const ProductBrand = ({ useInModal }) => {
  const { t } = useTranslation();
  const {
    data,
    onSearchProductBrand,
    loading,
    GET_QUERY,
    paginationData,
    handleChangeTable,
    checkNullValue,
    statusBrand,
    VALUE_OPTION_STATUS_BRAND,
    handleFilter,
    params,
    handleRemoveFilterTag,
    handleChangeStatus,
  } = useList();
  const history = useHistory();

  const columns = [
    {
      title: "STT",
      width: 100,
      fixed: "left",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("productBrand.NAMEBRAND"),
      fixed: "left",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "start", gap: "15px", alignItems: "center" }}>
            <img alt="" src={record?.logoURL || Img.PlaceholderImageFailed} style={{ width: "50px", height: "50px" }} />
            <span className="text-two-line-hidden">
              {checkNullValue(<Link to={`/product-brand-edit/${record?.id}`}>{record?.name}</Link>)}
            </span>
          </div>
        );
      },
    },
    {
      title: t("productBrand.DESCRIPTION"),
      render: (_, record) => <span className="text-three-line-hidden">{checkNullValue(record.description)}</span>,
    },
    {
      title: t("productBrand.QUANTITY"),
      render: (_, record) => <div>{checkNullValue(record.quantity)}</div>,
    },
    {
      title: t("productBrand.CATALOG"),
      render: (_, record) => (
        <div>
          <Tooltip
            title={record?.catalogURLs?.length > 1 ? `[${record?.catalogURLs[0]}...]` : record?.catalogURLs}
            color="#5B6673"
            placement="bottom"
          >
            {checkNullValue(<SvgIcon.Xlsx style={{ width: "40px", cursor: "pointer" }} />)}
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("productBrand.STATUS"),
      render: (_, record) => (
        <div style={{ color: record?.isActive === true ? "green" : "gray" }}>
          {checkNullValue(statusBrand?.[record?.isActive])}
        </div>
      ),
      filterDropdown: (
        <FilterMenu
          type="radio"
          options={VALUE_OPTION_STATUS_BRAND}
          onFilter={(value) => handleFilter({ isActive: value })}
        />
      ),
    },
    {
      title: t("productBrand.ACTION"),
      render: (_, record) => (
        <StateSwitch checked={record?.isActive} onChange={(checked) => handleChangeStatus(record?.id, checked)} />
      ),
    },
  ];
  return (
    <div className="product-brand">
      {useInModal || (
        <PageHeader
          pageTitle={t("productBrand.listBrand")}
          routes={[
            { path: "/product", name: t("product.priceBid.product") },
            { path: "/", name: t("productBrand.listBrand") },
          ]}
        />
      )}
      <div className="filter-box">
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <div className="search-input">
            <FormInput
              {...{
                type: INPUT_TYPE,
                inputOptions: {
                  placeholder: t("productBrand.searchNameBrand"),
                  onChange: onSearchProductBrand,
                  defaultValue: GET_QUERY?.query,
                  prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
                },
              }}
            ></FormInput>
          </div>
          <Button
            onClick={() => {
              history.push({
                pathname: "/product-brand-create",
              });
            }}
            type="primary"
          >
            {t("serialIE.create")}
          </Button>
        </Row>
      </div>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <FiltersTag
          VALUE_OPTION_STATUS_BRAND={VALUE_OPTION_STATUS_BRAND}
          params={params}
          handleRemoveFilterTag={handleRemoveFilterTag}
        />
      </div>
      <div className="list-watch-history-price">
        <CustomTable
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record?.id}
          onChange={handleChangeTable}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default ProductBrand;
