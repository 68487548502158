import { Affix, Drawer, Space, Typography } from "antd";
import { t } from "i18next";
import CustomButton from "components/CustomButton";

import "./index.scss";

function CustomDrawer(props) {
  const {
    visible,
    onClose,
    onOk,
    placement,
    title,
    labelCancel,
    labelOk,
    content,
    loading,
    width,
    height,
    multiLevel,
    propsDrawer,
    propsDrawerChildren,
    contentMultiDrawer,
    closable = false,
    bodyStyle,
    headerSticky,
    buttonCancel,
    buttonOk,
    styleHeaderSticky,
    widthMulti = 320,
    customTitle,
    titleMulti,
  } = props;

  const placementDrawerClose = {
    bottom: "unset",
  };

  return (
    <Drawer
      className="custom-drawer"
      title={
        customTitle || (
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Text style={{ fontSize: "22px" }} strong>
              {title}
            </Typography.Text>
            <Space>
              {buttonCancel || <CustomButton isSecondary onClick={onClose} title={labelCancel || t("common.cancel")} />}
              {buttonOk || (
                <CustomButton type="primary" onClick={onOk} loading={loading} title={labelOk || t("common.save")} />
              )}
            </Space>
          </Space>
        )
      }
      style={{
        "--placement-drawer-close": placementDrawerClose[placement],
      }}
      placement={placement}
      width={width}
      height={height}
      onClose={onClose}
      visible={visible}
      closable={closable}
      bodyStyle={bodyStyle}
      {...propsDrawer}
    >
      <div className={"header-sticky"} style={{ ...styleHeaderSticky }}>
        <Affix>{headerSticky}</Affix>
      </div>
      {content}
      {multiLevel && (
        <Drawer
          className="custom-drawer"
          {...propsDrawerChildren}
          width={widthMulti}
          title={<div className="header-drawer">{titleMulti}</div>}
        >
          {contentMultiDrawer}
        </Drawer>
      )}
    </Drawer>
  );
}

export default CustomDrawer;
