import { Card, Col, Form, Row, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import OrderLink from "components/CustomRedirectLink/OrderLink";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import WarrantyDetailLink from "components/CustomRedirectLink/WarrantyDetailLink";
import FormInput from "components/FormInput";
import UploadFiles from "components/UploadFiles";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import { RenderRemainingDeliverTime, warrantyStatus } from "features/Warranty/util";
import { TOTAL_ITEMS_STATUS, optionsRequestType, optionsSolution } from "features/WarrantyRequest/constant";
import { t } from "i18next";
import { formatDateTime } from "utils/dateTime";
import { getFile } from "utils/helperFuncs";
import SelectProduct from "./SelectProduct";

const lableCol = { span: 24, offset: 0 };

function FormUpdateOwner({ record, status, handleGetProduct }) {
  const isMigrated = record?.product?.code === "MIGRATED-SERIAL" && record?.warrantyNote?.isMigrated;
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card style={{ background: "#F7F8FB" }}>
          <Row gutter={[16, 16]}>
            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.serialIMEI")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              {record?.serial}
            </Col>

            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.productName")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              {record?.product?.name || record?.productName}
            </Col>
            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.productCode")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              <ProductLink product={record} />
            </Col>
            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.orderCode")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              <OrderLink order={record?.warrantyNote} />
            </Col>
            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.warranty")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              <Row gutter={[16]}>
                <Col>
                  <WarrantyDetailLink warranty={record?.warrantyNote} />
                </Col>
                <Col>
                  <Typography.Text style={warrantyStatus[record?.warrantyNote?.status]}>
                    {warrantyStatus[record?.warrantyNote?.status]?.text}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24} xl={8}>
              <Typography.Text strong>{t("warrantyRequest.create.expiredDate")}</Typography.Text>
            </Col>
            <Col span={24} xl={16}>
              <Row gutter={[16]}>
                <Col>{formatDateTime(record?.warrantyNote?.warrantyEndDate, DATE_FORMAT)}</Col>
                <RenderRemainingDeliverTime time={record?.warrantyNote?.remainingWarrantyDays} />
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        {isMigrated && (
          <Row style={{ display: "flex", alignItems: "center", gap: "12px", flexWrap: "nowrap" }}>
            <div>
              <SvgIcon.HelpIcon />
            </div>
            <div>
              <Typography.Text style={{ color: "#EF9351" }}>
                Lưu ý: Serial/IMEI này được đồng bộ từ hệ thống cũ. Vui lòng chuẩn hóa dữ liệu bằng cách chọn mã sản
                phẩm hiện tại của serial/IMEI. Sau khi xác nhận, hệ thống sẽ cập nhật toàn bộ thông tin liên quan và
                kiểm tra điều kiện xuất nhập của serial/IMEI theo mã sản phẩm mới. Hãy đảm bảo việc cập nhật thật chính
                xác.
              </Typography.Text>
            </div>
          </Row>
        )}
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {isMigrated && (
            <Col span={24} xl={12}>
              <SelectProduct record={record} handleGetProduct={handleGetProduct} />
            </Col>
          )}
          {isMigrated && (
            <Col span={24} xl={12}>
              <FormInput
                {...{
                  type: INPUT_TYPE.INPUT,
                  formItemOptions: {
                    label: <Typography.Text strong>Tên sản phẩm</Typography.Text>,
                    name: "productName",
                    rules: [{ required: true, message: t("common.validateRequired") }],
                  },
                  inputOptions: {
                    placeholder: "Nhập tên sản phẩm",
                    disabled: true,
                  },
                }}
              />
            </Col>
          )}

          <Col span={24} xl={12}>
            <FormInput
              {...{
                type: INPUT_TYPE.SELECT,
                formItemOptions: {
                  label: <Typography.Text strong>{t("warrantyRequest.create.warrantyType")}</Typography.Text>,
                  name: "warrantyRequestType",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.warrantyType"),
                  maxLength: 50,
                  options: optionsRequestType,
                  disabled: true,
                },
              }}
            />
          </Col>
          <Col span={24} xl={12}>
            <FormInput
              {...{
                type: INPUT_TYPE.DATE_PICKER,
                formItemOptions: {
                  label: <Typography.Text strong>{t("warrantyRequest.create.appointmentDate")}</Typography.Text>,
                  name: "promisedReturnDate",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.date"),
                  format: DATE_FORMAT,
                },
              }}
            />
          </Col>
          {status === TOTAL_ITEMS_STATUS.WAITING_FOR_DISTRIBUTE && (
            <Col span={12}>
              <FormInput
                {...{
                  type: INPUT_TYPE.SELECT,
                  formItemOptions: {
                    label: <Typography.Text strong>{t("warrantyRequest.popup.solution")}</Typography.Text>,
                    name: "solution",
                    rules: [{ required: true, message: t("common.validateRequired") }],
                  },
                  inputOptions: {
                    options: optionsSolution,
                    // disabled: true,
                  },
                }}
              />
            </Col>
          )}
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.TEXT_AREA,
                formItemOptions: {
                  label: <Typography.Text strong>{t("warrantyRequest.create.errorDetail")}</Typography.Text>,
                  name: "defectDescription",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.errorDetail"),
                  maxLength: 255,
                },
              }}
            />
          </Col>
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.TEXT_AREA,
                formItemOptions: {
                  label: <Typography.Text strong>{t("warrantyRequest.create.clientRequest")}</Typography.Text>,
                  name: "customerRequest",
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.clientRequest"),
                },
              }}
            />
          </Col>
          <Col span={24}>
            <FormInput
              {...{
                type: INPUT_TYPE.TEXT_AREA,
                formItemOptions: {
                  label: <Typography.Text strong>{t("warrantyRequest.create.accessoriesNote")}</Typography.Text>,
                  name: "accessoryAttachment",
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.accessoriesNote"),
                  maxLength: 255,
                },
              }}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={lableCol}
              name={"imageURLs"}
              getValueFromEvent={getFile}
              valuePropName={"fileList"}
              label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
              extra={t("warrantyRequest.create.noteAttack")}
            >
              <UploadFiles />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FormUpdateOwner;
