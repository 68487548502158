import { Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";

function CustomTextHiddenLine({ text, line = 2, isLink = false, link, limit = 60, ...rest }) {
  const style = {
    display: "-webkit-box",
    height: "16px*1.3*3",
    "-webkit-line-clamp": line?.toString(),
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textAlign: !text && "center",
  };

  return (
    <div style={style}>
      {isLink ? (
        <Tooltip title={text?.length > limit ? text : undefined}>
          {link ? (
            <Link to={link}>
              <Typography.Link {...rest}>{text || "--"}</Typography.Link>
            </Link>
          ) : (
            <Typography.Link {...rest}>{text || "--"}</Typography.Link>
          )}
        </Tooltip>
      ) : (
        <Tooltip title={text?.length > limit ? text : undefined}>
          <Typography.Text {...rest}>{text || "--"}</Typography.Text>
        </Tooltip>
      )}
    </div>
  );
}

export default CustomTextHiddenLine;
