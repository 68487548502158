import { Button, Col, Form, Row, Select, Typography } from "antd";
import CustomCard from "components/CustomCard";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { optionsCauseReject, styleHeaderCard, styleTitleCard } from "features/Purchase/constant";
import { t } from "i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

export const PopupDetailReason = ({ data }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const columns = [
    {
      title: <span className="title-column">{t("common.index")}</span>,
      align: "center",
      width: "5%",
      render: (_, _record, index) => index + 1,
    },
    {
      title: <span>THÔNG SỐ</span>,
      align: "start",
      width: "25%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Link strong>
              <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
                <Typography.Link strong>{record?.product?.code}</Typography.Link>
              </Link>
            </Typography.Link>
            <CustomTextHiddenLine line={3} text={record?.product?.name || "--"} />
          </div>
        );
      },
    },
    {
      title: <span>SỐ LƯỢNG ĐẶT HÀNG</span>,
      align: "center",
      width: "10%",
      render: (_, record) => {
        return <span>{record?.quantity}</span>;
      },
    },
    {
      title: <span>SỐ LƯỢNG THỰC GIAO</span>,
      align: "center",
      width: "10%",
      render: (_, record, index) => {
        return <span>{record?.receivedQuantity}</span>;
      },
    },
    {
      title: <span>SỐ LƯỢNG KHÔNG ĐỦ ĐIỀU KIỆN</span>,
      align: "center",
      width: "15%",
      render: (_, record, index) => {
        return <span>{record?.notQualifiedQuantity}</span>;
      },
    },
    {
      title: <span>SỐ LƯỢNG ĐỦ ĐIỀU KIỆN</span>,
      align: "center",
      width: "15%",
      render: (_, record, index) => {
        return <Typography.Text>{record?.receivedQuantity - record?.notQualifiedQuantity}</Typography.Text>;
      },
    },
    {
      title: <span>LÝ DO TỪ CHỐI</span>,
      align: "start",
      width: "20%",
      maxWidth: "30%",
      render: (_, record, index) => {
        return (
          <div>
            <Select
              mode="tags"
              value={record?.rejectedCauses}
              options={optionsCauseReject}
              readOnly={false}
              placeholder="Chọn lý do từ chối"
              maxTagCount={1}
              dropdownMatchSelectWidth={430}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Form form={form} name="validate_other">
      <CustomModal
        {...{
          width: "65%",
          title: (
            <span style={{ fontSize: "18px" }}>
              <Typography.Text strong>{"Từ chối đơn mua hàng"}</Typography.Text>
            </span>
          ),
          customComponent: <Typography.Link strong>{"Xem chi tiết lý do từng sản phẩm"}</Typography.Link>,
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          addMoreButton: (
            <div className="button-group-action-check-info-purchase-order">
              <Button type="primary" htmlType="submit" onClick={handleCancel}>
                {"Đóng"}
              </Button>
            </div>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
          className: "button-reject-check-info-purchase-order",
        }}
      >
        <div className="form-check-info-purchase-order">
          <CustomCard
            headStyle={styleHeaderCard}
            title={
              <Typography.Text style={styleTitleCard} strong>
                {"DANH SÁCH SẢN PHẨM"}
              </Typography.Text>
            }
            content={
              <Form.List name="items">
                {(items, { remove }) => {
                  return <CustomTable columns={columns} dataSource={data?.items} />;
                }}
              </Form.List>
            }
          />
          <Col span={24} style={{ marginTop: "10px" }}>
            <Row>
              <Typography.Text strong>Lý do từ chối: </Typography.Text>
              <Typography.Text>&nbsp;{data?.deniedReason?.reason}</Typography.Text>
            </Row>
            <div style={{ marginTop: "10px" }}>
              <Typography.Text strong>Hình ảnh/ File đính kèm:</Typography.Text>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "16px" }}>
              {data?.deniedReason?.files?.map((fileURL, index) => {
                return <CustomViewDocumentFiles file={fileURL} key={index} />;
              })}
            </div>
          </Col>
        </div>
      </CustomModal>
    </Form>
  );
};
