import { ModalAddAddress } from "features/Agent/components";
import { ORDER_PRIORITY, ORDER_TYPE, SHIPPING_TYPE } from "features/Cart/constants";
import { useFlags } from "flagsmith/react";
import { useGetContactsAll } from "hooks/contact";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getQuery } from "utils/helperFuncs";

const useInfoAgent = ({ data, handleUpdate, refetchCart }) => {
  const GET_QUERY = getQuery();
  const { sender, sellerID, allSenders, shippingContact } = data || {};
  const [searchTerm, setSearchTerm] = useState("");
  const debounceValue = useDebounce(searchTerm);

  const [paramsContactsAll, setParamsContactsAll] = useState({
    filters: {
      query: GET_QUERY.query || undefined,
      sellerID: sellerID || null,
    },
    pagination: {
      offset: GET_QUERY?.offset || 0,
      limit: 10,
    },
  });

  const {
    data: allContacts,
    loading: loadingContactList,
    paginationData,
    refetch,
  } = useGetContactsAll(paramsContactsAll, { sellerID: sellerID });

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...paramsContactsAll,
      pagination: { ...paramsContactsAll?.pagination, offset: (current - 1) * pageSize },
    };

    setParamsContactsAll(newParams);
  }

  const handleChangeInput = (e) => {
    setSearchTerm(e);
  };

  const orderTypeOptions = [
    {
      value: ORDER_TYPE.SELLER,
      label: t("cart.orderType.seller"),
    },
    {
      value: ORDER_TYPE.CUSTOMER,
      label: t("cart.orderType.customer"),
    },
  ];

  const onSubmitSenderAddress = (selected) => {
    const newSender = allSenders?.find((item) => item.id === selected);
    const senderUpload = {
      senderName: newSender?.fullName,
      senderPhone: newSender?.telephone,
      senderAddress: newSender?.address,
    };
    handleUpdate({ ...senderUpload, shippingConfig: data?.shippingConfiguration }).then(() => refetchCart());
  };

  const onSubmitReceiverAddress = (selected) => {
    const newReceiver = allContacts?.find((item) => item.id === selected);
    handleUpdate({ shippingContactID: newReceiver?.id, shippingConfig: data?.shippingConfiguration }).then(() =>
      refetchCart()
    );
  };

  const infoReceiverAndSender = [
    {
      title: t("cart.infoReceiverAndSender.sender"),
      id: sender?.id,
      name: sender?.fullName,
      phoneNumber: sender?.telephone,
      address: sender?.address,
      titleModal: t("cart.senderAddress"),
      list: allSenders,
      onSubmit: onSubmitSenderAddress,
      addMoreButton: null,
    },
    {
      title: t("cart.infoReceiverAndSender.receiver"),
      id: shippingContact?.id,
      name: shippingContact?.fullName,
      phoneNumber: shippingContact?.telephone,
      address: shippingContact?.address,
      titleModal: t("cart.receiverAddress"),
      list: allContacts,
      onSubmit: onSubmitReceiverAddress,
      addMoreButton: <ModalAddAddress refetch={refetch} sellerID={sellerID} />,
      paginationData: paginationData,
      params: paramsContactsAll,
      loadingContactList: loadingContactList,
      onTableChange: onTableChange,
      isReceiver: true,
      handleChangeInput: handleChangeInput,
    },
  ];

  const flags = useFlags(["enable_new_shipping_provider"]);
  const idEnableNewShippingProvider = flags.enable_new_shipping_provider.enabled;

  const shippingTypeOptions = idEnableNewShippingProvider
    ? [
        {
          value: SHIPPING_TYPE.DELIVER_3PARTY,
          label: t("cart.shippingType.deliver3Party"),
        },
        {
          value: SHIPPING_TYPE.BUS,
          label: t("cart.shippingType.bus"),
        },
        {
          value: SHIPPING_TYPE.PICK_UP_COUNTER,
          label: t("cart.shippingType.pickUpCounter"),
        },
      ]
    : [
        {
          value: SHIPPING_TYPE.BUS,
          label: t("cart.shippingType.bus"),
        },
        {
          value: SHIPPING_TYPE.DELIVER_3PARTY,
          label: t("cart.shippingType.deliver3Party"),
        },
        {
          value: SHIPPING_TYPE.PICK_UP_COUNTER,
          label: t("cart.shippingType.pickUpCounter"),
        },
        {
          value: SHIPPING_TYPE.URBAN_COD,
          label: t("cart.shippingType.urbanCOD"),
        },
      ];

  const orderPriorityOptions = [
    {
      value: ORDER_PRIORITY.LOW,
      label: t("cart.orderPriority.low"),
    },
    {
      value: ORDER_PRIORITY.MEDIUM,
      label: t("cart.orderPriority.medium"),
    },

    {
      value: ORDER_PRIORITY.HIGH,
      label: t("cart.orderPriority.high"),
    },
  ];

  const onChange = async (value) => {
    await handleUpdate(value).then(() => refetchCart());
  };

  const onChangeDate = (date, dateString) => {
    const formatDate = date?.toISOString(true);
    handleUpdate({ deliveryDeadline: formatDate }).then(() => refetchCart());
  };

  useEffect(() => {
    const newParams = {
      ...paramsContactsAll,
      filters: { ...paramsContactsAll.filters, query: debounceValue },
      pagination: { offset: 0, limit: 10 },
    };
    setParamsContactsAll(newParams);
  }, [debounceValue]);

  return {
    orderTypeOptions,
    infoReceiverAndSender,
    shippingTypeOptions,
    orderPriorityOptions,
    onChange,
    onChangeDate,
    handleChangeInput,
  };
};

export default useInfoAgent;
