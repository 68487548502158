import { Form, Radio, Space, Typography } from "antd";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import { DESIST_CUSTOMER } from "features/PotentialCustomer/constants";
import { useDesistCustomer } from "hooks/potentialCustomer/mutation";
import { t } from "i18next";
import { has } from "lodash";
import { useState } from "react";
import { notify } from "utils/helperFuncs";

function DesistButton({ customerId }) {
  const [form] = Form.useForm();

  const [hidden, setHidden] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { loadingDesistCustomer, handleDesistCustomer } = useDesistCustomer();

  const desistOptions = [
    {
      label: t("potentialCustomer.noDemand"),
      value: DESIST_CUSTOMER.NO_DEMAND,
    },
    {
      label: t("potentialCustomer.noInterestedCategory"),
      value: DESIST_CUSTOMER.NO_INTERESTED_CATEGORY,
    },
    {
      label: t("potentialCustomer.hadAccount"),
      value: DESIST_CUSTOMER.HAD_ACCOUNT,
    },
    {
      label: t("potentialCustomer.other"),
      value: DESIST_CUSTOMER.OTHER,
    },
  ];

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        await handleDesistCustomer({
          customerIDs: customerId,
          reason: values,
        })
          .then(() => {
            handleCancel();
            notify.success({ message: t("potentialCustomer.updateSuccess") });
            form.resetFields();
          })
          .catch((error) => {
            notify.error({
              message: error.message,
            });
            handleOpen();
          });
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "defaultReason")) {
      if (changedValues.defaultReason === "OTHER") {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, otherReason: "" });
      }
    }
  };

  return (
    <CustomModal
      footer={false}
      visible={showModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      customComponent={<PrimaryButtonOutLine title={t("potentialCustomer.buttonGroup.stopCaring")} />}
      title={
        <Typography.Text strong style={{ textTransform: "uppercase", fontSize: "18px" }}>
          {t("potentialCustomer.status.stopTaking")}
        </Typography.Text>
      }
      message={<Typography.Text type="warning">{t("potentialCustomer.messageModalStopTaking")}</Typography.Text>}
      onOke={onSubmit}
      buttonLoading={loadingDesistCustomer}
      children={
        <Form form={form} onValuesChange={handleValuesChange}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "defaultReason",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("defaultReason")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(notify.warning({ message: t("potentialCustomer.pleaseSelect") }));
                  },
                }),
              ],
            }}
            inputChildren={
              <Space direction="vertical">
                {desistOptions.map((item, index) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            }
          />
          <FormInput
            type={INPUT_TYPE.TEXT_AREA}
            formItemOptions={{
              name: "otherReason",
              hidden,
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("defaultReason") !== "OTHER") {
                      return Promise.resolve();
                    }
                    if (getFieldValue("defaultReason") === "OTHER" && value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("potentialCustomer.errorOrderReason")));
                  },
                }),
              ],
            }}
            inputOptions={{ placeholder: t("order.cancelOrder.otherPlaceholder") }}
          />
        </Form>
      }
    />
  );
}

export default DesistButton;
