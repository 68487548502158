import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import SearchableSelect from "components/CustomSelect/SearchableSelect";
import FormInput from "components/FormInput";
import { ATTRIBUTE_TYPE } from "pages/App/Product/ProductGroup/conts";
import { useTranslation } from "react-i18next";
import "./index.scss";

const ProductAttributes = ({
  isHasVariant,
  dataAttributeOfCategory,
  attributes,
  disableCheckboxHasVariant,
  form,
  disabledInput,
}) => {
  const isDisable = (hasVariant) => {
    if (hasVariant) {
      return false;
    }
    if (disableCheckboxHasVariant) {
      return true;
    }
    if (disabledInput) {
      return true;
    }
  };
  const handleChangeName = (isVariant, index) =>
    isVariant ? [index, "attributeValueIDs"] : [index, "attributeValueID"];

  const renderInput = (object, index) => {
    switch (object?.attributeType) {
      case ATTRIBUTE_TYPE.FREE_TEXT:
        return (
          <div className="input-free-text">
            <FormInput
              formItemOptions={{
                name: [index, "value"],
                label: <span className="title">{object?.name}</span>,
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập biến thể",
                  },
                ],
              }}
              inputOptions={{
                placeholder: `Nhập ${object?.name}`,
                disabled: disabledInput,
              }}
            />
          </div>
        );
      case ATTRIBUTE_TYPE.PREDEFINED:
        return (
          <div className="input-predefined">
            <SearchableSelect
              formItemOptions={{
                name: handleChangeName(attributes?.at(index)?.isVariant, index),
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập biến thể",
                  },
                ],
                label: (
                  <div className="label-input-predefined">
                    <span className="title">{object?.name}</span>
                    {isHasVariant && (
                      <FormInput
                        type="CHECK_BOX"
                        style={{
                          margin: 0,
                        }}
                        formItemOptions={{
                          name: [index, "isVariant"],
                          valuePropName: "checked",
                        }}
                        inputOptions={{
                          disabled: isDisable(attributes?.at(index)?.isVariant),
                        }}
                        inputChildren={t("product.productForm.hasAttribute")}
                      />
                    )}
                  </div>
                ),
              }}
              selectOptions={{
                mode: attributes?.at(index)?.isVariant && "multiple",
                options: object?.values?.map((item) => {
                  return { value: item?.id, label: item?.value };
                }),
                placeholder: `Nhập ${object?.name}`,
                disabled: disabledInput,
              }}
            />
          </div>
        );
      default:
        return;
    }
  };

  const { t } = useTranslation();
  return (
    <>
      {!isHasVariant || (
        <span style={{ color: "#FF7F00" }}>
          <ExclamationCircleOutlined />
          &nbsp;&nbsp;
          <span style={{ fontStyle: "italic" }}>{t("product.productForm.warningProductAttributes")}</span>
        </span>
      )}
      <div className="product-attributes">
        <Form.List name="attributeValues">
          {() =>
            dataAttributeOfCategory?.map((item, index) => {
              return <div key={item?.id}>{renderInput(item, index)}</div>;
            })
          }
        </Form.List>
      </div>
    </>
  );
};

export default ProductAttributes;
