import { ClockCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { Col, Divider, Form, Row, Skeleton, Space, Timeline, Typography } from "antd";
import CustomCard from "components/CustomCard";
import { SelectLoadMore } from "components/CustomSelect";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import { PrimaryButton, PrimaryButtonOutLine } from "components/PrimaryButton";
import UploadFiles from "components/UploadFiles";
import { DATE_TIME_FORMAT, INPUT_TYPE } from "config/constants";
import {
  ACTIVITY_LOG_TYPE,
  EVENT_TYPE,
  POTENTIAL_TABS,
  communicationTypeLabel,
  communicationTypeOptions,
  potentialStatusLabel,
} from "features/PotentialCustomer/constants";
import { t } from "i18next";
import { formatDateTime, formatTime } from "utils/dateTime";
import { formatDate, getFile, getQuery } from "utils/helperFuncs";
import useActivityLogs from "./useActivityLogs";
import "./index.scss";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import ExpandableText from "components/CustomExpandableText";
import { useCustomerPermissions } from "hooks/potentialCustomer/query";

const styleIcon = { color: "#0099FF", fontSize: "28px" };

const BlockContentLog = ({ value, label }) => {
  return (
    <Col span={24}>
      <Row>
        <Col span={10}>
          <Typography.Text strong>{label}</Typography.Text>
        </Col>
        <Col>
          <ExpandableText value={value || "--"} />
        </Col>
      </Row>
    </Col>
  );
};

const TitleLog = ({ dataLog }) => {
  if (![EVENT_TYPE.REJECT_SELLER]?.includes(dataLog?.eventType) && dataLog?.type !== ACTIVITY_LOG_TYPE.COMMUNICATE) {
    return (
      <Typography.Text style={{ color: "rgb(5, 41, 135)" }} strong>
        {t("potentialCustomer.activityLogs.infoUpdated")}
      </Typography.Text>
    );
  }
  if (dataLog?.eventType === EVENT_TYPE.REJECT_SELLER) {
    return (
      <Typography.Text style={{ color: "rgb(5, 41, 135)" }} strong>
        {t("potentialCustomer.activityLogs.rejectCustomer")}
      </Typography.Text>
    );
  }
  if (dataLog?.type === ACTIVITY_LOG_TYPE.COMMUNICATE) {
    return (
      <Typography.Text style={{ color: "rgb(5, 41, 135)" }} strong>
        {t("potentialCustomer.activityLogs.exchangeWithCustomer")}
      </Typography.Text>
    );
  }
  return "--";
};

const ContentLog = ({ dataLog }) => {
  if (dataLog?.eventType === EVENT_TYPE.REJECT_SELLER) {
    return <BlockContentLog label={t("potentialCustomer.activityLogs.rejectReason")} value={dataLog?.rejectReason} />;
  }
  if (dataLog?.type === ACTIVITY_LOG_TYPE.UPDATE_CUSTOMER_STATUS) {
    if ([EVENT_TYPE.MERGE]?.includes(dataLog?.eventType)) {
      return (
        <BlockContentLog
          label={t("potentialCustomer.activityLogs.linkAccountLogin")}
          value={dataLog?.linkAccountEmail}
        />
      );
    } else {
      const valueStatus = `${potentialStatusLabel?.[dataLog?.previousStatus]} → 
              ${potentialStatusLabel?.[dataLog?.nextStatus]}`;
      return (
        <>
          <BlockContentLog label={t("potentialCustomer.activityLogs.status")} value={valueStatus} />
          {dataLog?.nextStatus === "STOP_CARING" && (
            <BlockContentLog
              label={t("potentialCustomer.activityLogs.stopCaringReason")}
              value={dataLog?.desistReason}
            />
          )}
        </>
      );
    }
  }
  if (dataLog?.type === ACTIVITY_LOG_TYPE.COMMUNICATE) {
    return (
      <>
        <BlockContentLog
          label={t("potentialCustomer.activityLogs.exchangeMethod")}
          value={communicationTypeLabel?.[dataLog?.communicationType]}
        />
        <BlockContentLog label={t("potentialCustomer.activityLogs.content")} value={dataLog?.note} />
        <Col span={24}>
          <Space style={{ flexWrap: "wrap" }}>
            {dataLog?.fileUrls?.map((fileURL, index) => {
              return <CustomViewDocumentFiles file={fileURL} key={index} />;
            })}
          </Space>
        </Col>
      </>
    );
  }
  return "--";
};

function ActivityLogs({ customerID, dataDetail }) {
  const {
    loadingCreateCommunicate,
    activityLogsData,
    activityLogsLoading,
    dataStaffOptions,
    hasMoreData,
    valueSelected,
    form,
    onLoadMoreData,
    onSearchStaff,
    handleChangeSelect,
    handleCancel,
    handleSave,
    handleChangeCreatedBy,
    initialValues,
  } = useActivityLogs({ customerID });
  const { canCustomerCreate } = useCustomerPermissions();

  return (
    <div className="custom-activity-log-potential" style={{ marginLeft: "16px" }}>
      <Timeline>
        <Timeline.Item className="item-first" dot={<InfoCircleFilled style={styleIcon} />}>
          {[POTENTIAL_TABS.NEW, POTENTIAL_TABS.TAKING_CARE].includes(dataDetail?.status) && canCustomerCreate && (
            <CustomCard
              title={
                <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
                  {t("potentialCustomer.activityLogs.addActiveLog")}
                </Typography.Text>
              }
              content={
                <Form colon={false} form={form} initialValues={initialValues}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <FormInput
                      type={INPUT_TYPE.SELECT}
                      formItemOptions={{
                        label: (
                          <Typography.Text strong>{t("potentialCustomer.activityLogs.typeActive")}</Typography.Text>
                        ),
                        labelAlign: "left",
                        name: "type",
                        labelCol: { span: 8 },
                      }}
                      inputOptions={{
                        options: communicationTypeOptions,
                        placeholder: t("potentialCustomer.activityLogs.selectTypeActive"),
                        onChange: handleChangeSelect,
                      }}
                    />
                    {valueSelected && (
                      <>
                        <FormInput
                          type={INPUT_TYPE.TEXT_AREA}
                          formItemOptions={{
                            name: "note",
                          }}
                          inputOptions={{
                            placeholder: t("potentialCustomer.activityLogs.typeNote"),
                            autoSize: { minRows: 3, maxRows: 10 },
                          }}
                        />
                        <Form.Item
                          labelCol={{ span: 24 }}
                          style={{ display: "flex", flexDirection: "column" }}
                          name={"fileUrls"}
                          getValueFromEvent={getFile}
                          valuePropName={"fileList"}
                          label={<Typography.Text strong>{t("potentialCustomer.activityLogs.upload")}</Typography.Text>}
                          extra={t("potentialCustomer.activityLogs.attack")}
                        >
                          <UploadFiles />
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <FormInput
                              type={INPUT_TYPE.DATE_PICKER}
                              {...{
                                formItemOptions: {
                                  name: "createdAt",
                                  style: { marginRight: 0 },
                                },
                                inputOptions: {
                                  placeholder: t("potentialCustomer.activityLogs.date"),
                                  format: DATE_TIME_FORMAT,
                                  showTime: true,
                                  allowClear: false,
                                },
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <SelectLoadMore
                              type={INPUT_TYPE.SELECT_LOAD_MORE}
                              {...{
                                formItemOptions: {
                                  name: "createdByID",
                                  rules: [
                                    {
                                      required: false,
                                      message: t("common.messageRequiredSelect", {
                                        message: t("potentialCustomer.form.staffInCharge"),
                                      }),
                                    },
                                  ],
                                  style: { marginBottom: 0, marginRight: 0 },
                                },
                                inputOptions: {
                                  placeholder: t("potentialCustomer.activityLogs.assignee"),
                                  options: dataStaffOptions,
                                  value: null,
                                  allowClear: false,
                                  optionLabelProp: "labelStaff",
                                },
                              }}
                              onLoadMore={onLoadMoreData}
                              hasMoreData={hasMoreData}
                              onSearch={onSearchStaff}
                              onChange={handleChangeCreatedBy}
                            />
                          </Col>
                        </Row>
                        <div>
                          <Space>
                            <PrimaryButtonOutLine onClick={handleCancel} title={t("common.cancel")} />
                            <PrimaryButton
                              onClick={handleSave}
                              title={t("common.save")}
                              loading={loadingCreateCommunicate}
                            />
                          </Space>
                        </div>
                      </>
                    )}
                  </Space>
                </Form>
              }
            />
          )}
          {/* <Skeleton active loading={activityLogsLoading} paragraph={{ rows: 0 }}>
            <Divider style={{ color: "#5B6673" }}>{formatDate(activityLogsData?.[0]?.createdAt)}</Divider>
          </Skeleton> */}
        </Timeline.Item>
        <Skeleton active loading={activityLogsLoading} title={false}>
          {activityLogsData?.map((dataLog, index) => {
            return (
              <Timeline.Item key={index} dot={<ClockCircleFilled style={styleIcon} />}>
                {/* <Skeleton active loading={activityLogsLoading} paragraph={{ rows: 0 }}>
                  <Divider style={{ color: "#5B6673" }}>{formatDate(activityLogsData?.[0]?.createdAt)}</Divider>
                </Skeleton> */}
                <CustomCard
                  title={
                    <Row gutter={16}>
                      <Col span={12}>
                        <TitleLog dataLog={dataLog} />
                        {/* <Typography.Text style={{ color: "#5B6673" }}>{formatTime(dataLog?.createdAt)}</Typography.Text> */}
                      </Col>
                      <Col span={12}>
                        <div
                          direction="vertical"
                          style={{ display: "flex", flexDirection: "column", float: "right", gap: "0px !important" }}
                        >
                          <CustomTextHiddenLine
                            limit={25}
                            line={1}
                            text={`${t("potentialCustomer.activityLogs.by")}: ${dataLog?.createdBy?.fullname}`}
                          />
                          <Typography.Text style={{ color: "#5B6673" }}>
                            {formatDateTime(dataLog?.createdAt)}
                          </Typography.Text>
                        </div>
                      </Col>
                    </Row>
                  }
                  content={
                    <Row gutter={[8, 8]}>
                      <ContentLog dataLog={dataLog} />
                    </Row>
                  }
                />
              </Timeline.Item>
            );
          })}
        </Skeleton>
      </Timeline>
    </div>
  );
}

export default ActivityLogs;
