import { Col, Row, Space, Tag, Typography } from "antd";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import PageHeader from "components/PageHeader";
import Card from "features/Cart/components/Card";
import { useGetShowPassword } from "hooks/keyManagement/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import KeyDetail from "../KeyDetail";
import {
  convertDateWithDateUnit,
  hideChars,
  statusKeyListColor,
  statusKeyListLabel,
  statusKeyListOptions,
} from "../constants";
import Toolbar from "./Toolbar";
import FilterCreatedByIDs from "./components/FilterCreatedByIDs";
import FilterProductIDs from "./components/FilterProductIDs";
import FilterProviderIDs from "./components/FilterProviderIDs";
import FilterSellerIDs from "./components/FilterSellerIDs";
import useKeyList from "./useKeyList";

export default function KeyList() {
  const { t } = useTranslation();

  const {
    params,
    dataKeyList,
    loadingKeyList,
    paginationData,
    handleFilter,
    handleSearch,
    handleChangeParams,
    handleChangeTable,
    canKeyLicenseAccountCreate,
    canKeyLicenseAccountDetail,
    canKeyLicenseAccountViewAll,
  } = useKeyList();

  const [isKeyDetail, setIsKeyDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(undefined);

  const { handleGetPassword, loadingShowPassword } = useGetShowPassword();

  const fetchPassword = async (id) => {
    await handleGetPassword({ serialIDs: id }).then((res) => {
      setPassword(res?.data?.serial?.showPassword?.passwordBySerialID?.[0]?.password || "--");
    });
  };

  const handleShowPassword = async (id) => {
    setShowPassword(!showPassword);
    if (!password) await fetchPassword(id);
  };

  const handleOpenDrawer = ({ id }) => {
    setIsKeyDetail(true);
    setSelectedId(id);
  };

  const handleCloseDrawer = () => {
    setShowPassword(false);
    setIsKeyDetail(false);
    setPassword(undefined);
  };

  const columns = [
    {
      title: <Typography.Text style={{ color: "#5b6673" }}>{t("v2.keyManagement.index")}</Typography.Text>,
      width: 50,
      align: "center",
      render: (_, record, index) => index + 1,
      fixed: "left",
    },
    {
      title: t("v2.keyManagement.dateAdded"),
      sorter: true,
      width: 120,
      align: "center",
      fixed: "left",
      render: (_, record, index) => {
        if (record?.serialImportNoteItemLatest?.serialImportNote?.createdAt === undefined) {
          return "--";
        } else {
          return formatDateTime(record?.serialImportNoteItemLatest?.serialImportNote?.createdAt);
        }
      },
    },
    {
      title: t("v2.keyManagement.codeKeyAndAccount"),
      width: 250,
      fixed: "left",
      render: (_, record, index) => {
        return canKeyLicenseAccountDetail ? (
          <Typography.Link onClick={() => handleOpenDrawer({ id: record?.id })} strong>
            {record?.codeDisplay && !canKeyLicenseAccountViewAll ? hideChars(record.codeDisplay) : record?.code}
          </Typography.Link>
        ) : (
          <Typography.Link strong>
            {record?.codeDisplay && !canKeyLicenseAccountViewAll ? hideChars(record.codeDisplay) : record?.code}
          </Typography.Link>
        );
      },
    },
    {
      title: t("v2.keyManagement.parameter"),
      width: 350,
      render: (_, record, index) => {
        return (
          <Space direction="vertical">
            <Typography.Text strong>
              <CustomTextHiddenLine
                line={1}
                isLink
                text={
                  <Link target="_blank" rel="noreferrer" to={`/product/detail/${record?.productID}`}>
                    {record?.product?.code}
                  </Link>
                }
              />
            </Typography.Text>
            <CustomTextHiddenLine line={2} text={record?.product?.name} />
          </Space>
        );
      },
      filterDropdown: <FilterProductIDs params={params} onFilter={handleFilter} />,
    },
    {
      title: t("v2.keyManagement.supplier"),
      width: 300,
      render: (_, record, index) => {
        return (
          <CustomTextHiddenLine
            line={2}
            text={record?.serialImportNoteItemLatest?.serialImportNote?.provider?.fullName}
          />
        );
      },
      filterDropdown: <FilterProviderIDs params={params} onFilter={handleFilter} />,
    },
    {
      title: t("v2.keyManagement.customer"),
      width: 300,
      render: (_, record, index) => {
        return (
          <CustomTextHiddenLine
            line={2}
            text={record?.serialExportNoteItemLatest?.serialExportNote?.seller?.fullName}
          />
        );
      },
      filterDropdown: <FilterSellerIDs params={params} onFilter={handleFilter} />,
    },
    {
      title: t("v2.keyManagement.copyrightTerm"),
      width: 180,
      render: (_, record, index) => {
        return convertDateWithDateUnit(record?.product?.copyrightTerm, record?.product?.copyrightUnit);
      },
    },
    {
      title: t("v2.keyManagement.status"),
      width: 130,
      render: (_, record, index) => {
        return <Tag color={statusKeyListColor[record?.status]}>{statusKeyListLabel[record?.status]}</Tag>;
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter(value, "statuses")}
          type="checkbox"
          options={statusKeyListOptions}
        />
      ),
    },
    {
      title: t("v2.keyManagement.importer"),
      width: 200,
      render: (_, record, index) => {
        return (
          <CustomTextHiddenLine
            line={2}
            text={record?.serialImportNoteItemLatest?.serialImportNote?.createdBy?.fullname}
          />
        );
      },
      filterDropdown: <FilterCreatedByIDs params={params} onFilter={handleFilter} />,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          pageTitle={t("v2.keyManagement.codeList")}
          routes={[
            {
              path: "#",
              name: t("v2.keyManagement.title"),
            },
            {
              path: "",
              name: t("v2.keyManagement.codeList"),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Card>
          <div style={{ paddingBottom: 12 }}>
            <Toolbar
              onSearch={handleSearch}
              onChangeParams={handleChangeParams}
              params={params}
              canKeyLicenseAccountCreate={canKeyLicenseAccountCreate}
            />
          </div>
          <div>
            <CustomTable
              columns={columns}
              dataSource={dataKeyList}
              rowKey={(record) => record?.id}
              loading={loadingKeyList}
              scroll={{
                x: 1300,
              }}
              onChange={handleChangeTable}
              pagination={{
                total: paginationData?.total,
                pageSize: params?.pagination?.limit,
                current: params?.pagination?.offset / params?.pagination?.limit + 1 || 1,
                showSizeChanger: false,
              }}
            />
          </div>
        </Card>
      </Col>
      <KeyDetail
        visible={isKeyDetail}
        onClose={handleCloseDrawer}
        idDetail={selectedId}
        showPassword={showPassword}
        password={password}
        loadingShowPassword={loadingShowPassword}
        onShowPassword={handleShowPassword}
      />
    </Row>
  );
}
