import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Typography } from "antd";

function AlertReason({ title, reason = "" }) {
  return (
    <Alert
      message={
        <span>
          <Typography.Text strong>{title}:</Typography.Text>
          <Typography.Text style={{ marginLeft: "4px" }}>{reason}</Typography.Text>
        </span>
      }
      icon={<ExclamationCircleOutlined />}
      type="error"
      showIcon
      style={{ width: "100%", marginTop: "10px" }}
    />
  );
}

export default AlertReason;
