import { Form, Tabs } from "antd";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import { useApprovePotentialCustomer } from "hooks/potentialCustomer/mutation";
import { useCreateSeller, useGetSellerDetail, useUpdateSeller } from "hooks/seller";
import useList from "pages/App/Agent/List/useList";
import { COMPANY_TYPE, PAYMENT_METHOD } from "pages/App/Agent/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { joinArray, notify } from "utils/helperFuncs";
import CommonInfo from "../CommonInfo";
import "./index.scss";

const { TabPane } = Tabs;

export const CreateSellerForm = ({ isUpdate, isApproveSeller }) => {
  const [logoUrl, setLogoUrl] = useState("");
  const { id, potentialCustomerId } = useParams();
  const [saleCategories, setSaleCategories] = useState([]);
  const [isCompany, setIsCompany] = useState(true);
  const [form] = Form.useForm();
  const { handleCreateSeller, loading: loadingCreate } = useCreateSeller();
  const { handleApprovePotentialCustomer, loading: loadingApprove } = useApprovePotentialCustomer();
  const { refetch } = useList();
  const { handleUpdateSeller, loading: loadingUpdate } = useUpdateSeller();
  const [activeTab, setActiveTab] = useState("common-info");
  const { data, loading } = useGetSellerDetail({ id });
  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });
  const history = useHistory();
  const { t } = useTranslation();

  function getLogoUrl(url) {
    setLogoUrl(url);
  }

  function changeTab(tabKey) {
    setActiveTab(tabKey);
  }

  function handleChangeSaleCategories(checkedList) {
    setSaleCategories(checkedList?.filter((item) => item.checked));
  }

  function initForm() {
    if (isUpdate || isApproveSeller) {
      const formValues = {
        ...data?.vatInfo,
        ...data,
        companyAddress: data?.vatInfo?.address,
        company_email: data?.vatInfo?.email,
        legal_representative_phone: data?.vatInfo?.telephone,
        warehouseIDs: data?.warehouses?.map(({ id }) => id),
        contactName: data?.representName,
        contactPhone: data?.representPhone,
        contactEmail: data?.representEmail,
        paymentMethod: data?.paymentMethod || PAYMENT_METHOD.DEBT,
      };
      form.setFieldsValue({
        ...formValues,
      });
      setLocation({
        ...location,
        cityId: data?.cityID,
        districtId: data?.districtID,
      });
    } else {
      const formValues = {
        type: COMPANY_TYPE.COMPANY,
        paymentMethod: PAYMENT_METHOD.DEBT,
        ...history.location.state,
      };

      form.setFieldsValue({
        ...formValues,
      });
    }
  }

  const clearFields = () => {
    form.resetFields();
    setIsCompany(true);
    setSaleCategories([]);
  };

  const handleSubmit = async (isDetail, isRedirect) => {
    const values = await form.validateFields();

    if (!saleCategories?.length) {
      notify.warning({
        message: t("seller.selectSaleCategoriesWarning"),
      });
      return;
    }

    if (saleCategories.some((category) => category.level === undefined)) {
      notify.warning({
        message: t("seller.selectSaleLevelCategoriesWarning"),
      });
      return;
    }

    const {
      fullName,
      email,
      address,
      bankAccountNumber,
      bankBranch,
      bankID,
      cityID,
      companyAddress,
      company_email,
      debtAge,
      debtLimit,
      districtID,
      idNumber,
      legal_representative_phone,
      representative,
      sellerLevelID,
      shortName,
      taxIDNumber,
      telephone,
      wardID,
      warehouseIDs,
      type,
      paymentMethod,
      contactName,
      contactEmail,
      contactPhone,
      sellerGroupID,
      defaultExportVat,
      allowEditVat,
    } = values;

    const payload = {
      sellerInfo: {
        idNumber,
        type: type || COMPANY_TYPE.COMPANY,
        logoUrl: logoUrl || data?.logoUrl,
        shortName,
        debtAge: paymentMethod === PAYMENT_METHOD.DEBT ? debtAge : undefined,
        debtLimit: paymentMethod === PAYMENT_METHOD.DEBT ? debtLimit : undefined,
        sellerLevelID,
        fullName,
        email: email || undefined,
        address,
        cityID,
        districtID,
        wardID,
        telephone,
        paymentMethod,
        representName: contactName,
        representEmail: contactEmail,
        representPhone: contactPhone,
        sellerGroupID:
          [data?.sellerGroup?.code, data?.sellerGroup?.name].join(" - ") === values?.sellerGroupID
            ? data?.sellerGroupID
            : values?.sellerGroupID,
        isSupplier: false,
      },
      vatInfo: {
        address: companyAddress,
        bankAccountNumber,
        bankBranch,
        bankID,
        businessName: fullName,
        email: company_email,
        representative,
        taxIDNumber,
        telephone: legal_representative_phone,
        defaultExportVat,
        allowEditVat,
      },
      warehouseIDs,
      saleCategories: saleCategories?.map(({ level, id }) => ({
        categoryID: id,
        level: level || 4,
      })),
    };
    if (isDetail) {
      try {
        await handleUpdateSeller({
          sellerID: id,
          ...payload,
        });
        notify.success({
          message: t("seller.updateSellerSuccess"),
        });
        history.goBack();
      } catch (err) {
        notify.error({
          message: t("seller.updateSellerFail"),
          description: err.message,
        });
      }
    } else {
      if (isApproveSeller) {
        try {
          await handleApprovePotentialCustomer({ customerID: potentialCustomerId, request: payload });
          notify.success({
            message: t("seller.approveSellerSuccess"),
          });
          clearFields();
          refetch();
          history.push(`/seller/detail/${id}`);
        } catch (err) {
          notify.error({
            message: t("seller.createSellerFail"),
            description: err.message,
          });
        }
      } else {
        try {
          await handleCreateSeller(payload);
          notify.success({
            message: t("seller.createSellerSuccess"),
          });
          clearFields();
          refetch();
          isRedirect && history.push("/seller");
        } catch (err) {
          notify.error({
            message: t("seller.createSellerFail"),
            description: err.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    initForm();
    form.setFieldsValue({
      ...data,
      sellerGroupID: data?.sellerGroupID
        ? joinArray([data?.sellerGroup?.code, data?.sellerGroup?.name], " - ")
        : undefined,
    });
    setIsCompany(form.getFieldValue("type") === COMPANY_TYPE.COMPANY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <div className="create-seller-container">
      <Spinner loading={loading} />

      <PageHeader
        pageTitle={
          isUpdate ? (
            <p>
              {t("seller.seller")} {data?.shortName} <span className="seller-code">#{data?.code}</span>
            </p>
          ) : (
            t("seller.addSeller")
          )
        }
        routes={[
          {
            path: "/setting",
            name: t("common.systemSetting"),
          },
          {
            path: "/seller",
            name: t("seller.sellerManage"),
          },
          {
            path: "",
            name: isUpdate ? t("seller.updateSeller") : t("seller.addSeller"),
          },
        ]}
      />

      <div className="tabs-frame">
        <Tabs
          tabBarGutter={40}
          defaultActiveKey="common-info"
          tabBarStyle={{
            backgroundColor: "#e7f0ff",
            padding: "12px 24px",
            marginBottom: "20px",
            borderRadius: "8px",
          }}
          activeKey={activeTab}
          onChange={changeTab}
        >
          <TabPane tab={t("seller.commonInfo")} key="common-info">
            <CommonInfo
              defaultLogoUrl={data?.logoUrl}
              getLogoUrl={getLogoUrl}
              form={form}
              isUpdate={isUpdate}
              isApproveSeller={isApproveSeller}
              location={location}
              setLocation={setLocation}
              isCompany={isCompany}
              setIsCompany={setIsCompany}
              sellerData={data}
              onChangeCategory={handleChangeSaleCategories}
              saveSeller={() => handleSubmit(isUpdate, true)}
              saveAndAddSeller={() => handleSubmit(false, false)}
              loadingBtn={loadingCreate || loadingUpdate || loadingApprove}
            />
          </TabPane>
          {/* {isUpdate && canViewContact && (
            <TabPane tab={t('seller.addressList')} key="contact">
              <AddressList />
            </TabPane>
          )} */}
        </Tabs>
      </div>
    </div>
  );
};
