import { useQuery } from "@apollo/client";
import { GET_INFO_PLATFORM } from "graphql/info-platform/query";
import { PLATFORM_BANNER_URL, PLATFORM_CODE, PLATFORM_LOGO, PLATFORM_NAME } from "../../config/platform";

export const useGetInfoPlatform = () => {
  const { data } = useQuery(GET_INFO_PLATFORM);
  const platformGet = data?.platform?.get;

  return {
    platformURL: platformGet?.logoURL || PLATFORM_LOGO,
    platformBgURL: platformGet?.bannerURL || PLATFORM_BANNER_URL,
    platformName: platformGet?.name || PLATFORM_NAME,
    platformCode: platformGet?.code || PLATFORM_CODE,
  };
};
