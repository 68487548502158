import { Col, Form, Radio, Row, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import Img from "assets/images";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import StateSwitch from "components/StateSwitch";
import { INPUT_TYPE } from "config/constants";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { has } from "lodash";
import { TOGGLE_OFF_AGENT } from "pages/App/Order/conts";
import { useState } from "react";

const ActiveSellerModal = ({ isActive, onOke, loading }) => {
  const [form] = useForm();
  const { platformName } = useGetInfoPlatform();
  const [hidden, setHidden] = useState(true);

  const reasonsList = [
    {
      label: t("seller.reasonLock1"),
      value: TOGGLE_OFF_AGENT.OVERDUE_DEBT,
    },
    {
      label: t("seller.reasonLock2", { platformName }),
      value: TOGGLE_OFF_AGENT.BAD_DEBT,
    },
    {
      label: t("seller.other"),
      value: "OTHER",
    },
  ];

  const handleOke = () => {
    const formValues = form.getFieldsValue();
    onOke({
      defaultReason: formValues?.defaultReason !== "OTHER" ? formValues?.defaultReason : null,
      freeText: formValues?.freeText || null,
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();

    if (has(changedValues, "defaultReason")) {
      if (changedValues.defaultReason === "OTHER") {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, freeText: "" });
      }
    }
  };

  return (
    <CustomModal
      customComponent={<StateSwitch checked={isActive} loading={loading} />}
      icon={isActive ? Img.LockedIcon : Img.UnlockedIcon}
      centered={true}
      footer={false}
      onOke={handleOke}
    >
      {isActive ? (
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Space style={{ width: "100%", justifyContent: "center" }}>
              <Typography.Title level={3}>{t("seller.lockAcc")}</Typography.Title>
            </Space>
          </Col>
          <Col span={24}>
            <Form form={form} onValuesChange={handleValuesChange}>
              <FormInput
                type={INPUT_TYPE.RADIO_GROUP}
                formItemOptions={{ name: "defaultReason" }}
                inputChildren={
                  <Space direction="vertical">
                    {reasonsList.map((item, index) => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Space>
                }
              />

              <FormInput
                type={INPUT_TYPE.TEXT_AREA}
                formItemOptions={{ name: "freeText", hidden }}
                inputOptions={{ placeholder: t("seller.freeTextPlaceholder") }}
              />
            </Form>
          </Col>
        </Row>
      ) : (
        t("seller.unlockAcc")
      )}
    </CustomModal>
  );
};

export default ActiveSellerModal;
