import React from "react";
import styles from "./index.module.scss";

const PercentBar = ({ percent, color }) => {
  return <div style={{ width: percent, background: color, height: "100%", float: "left" }}></div>;
};

const Percent = ({ percentBars }) => {
  return (
    <div className={styles.percent}>
      {percentBars?.map((item, index) => (
        <PercentBar {...item} key={index} />
      ))}
    </div>
  );
};

export default Percent;
