import React from "react";
import SaleReport from "./SaleReport";
import { Icon, FontAwesomeIcon } from "assets/icons";
import { USER_PERMISSIONS } from "config/constants";
import PotentialCustomerReport from "./PotentialCustomerReport";

const router = [
  {
    key: "report",
    title: "common.report",
    icon: <FontAwesomeIcon icon={Icon.faChartBar} />,
    children: [
      {
        key: "sale-report",
        title: "report.saleReport",
        path: "/sale-report",
        component: SaleReport,
        exact: true,
        permissions: [USER_PERMISSIONS.REPORT_SALE_VIEW, USER_PERMISSIONS.REPORT_SALE_MANAGER_VIEW],
      },
      {
        key: "potential-customer-report",
        title: "report.potentialCustomerReport",
        path: "/potential-customer-report",
        component: PotentialCustomerReport,
        exact: true,
        permissions: [USER_PERMISSIONS.CUSTOMER_REPORT_STAFF_VIEW, USER_PERMISSIONS.CUSTOMER_REPORT_MANAGER_VIEW],
      },
    ],
  },
];

export default router;
