import { useLazyQuery, useQuery } from "@apollo/client";
import { USER_PERMISSIONS } from "config/constants";
import { DIGITAL_STATUS } from "features/KeyManagement/constants";
import {
  GENERATE_KEY,
  GET_DATA_CREATE_EXPORT_KEY,
  GET_KEY_DETAIL,
  GET_KEY_MANAGEMENT,
  GET_SAMPLE_FILE_KEY,
  GET_SHOW_PASSWORD,
  GET_SUGGESTED_ENTRY_LIST,
} from "graphql/keyManagement/query";
import { COUNT_STATUS_ORDER } from "graphql/order/query";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";

export const useGetKeyManagement = (params) => {
  const { data, loading, fetchMore } = useQuery(GET_KEY_MANAGEMENT, {
    variables: {
      ...params,
      filters: {
        ...params.filters,
      },
    },
  });

  return {
    dataKeyList: data?.serial?.pagination?.Serials || [],
    paginationData: data?.serial?.pagination?.paginationData,
    loadingKeyList: loading,
  };
};

export const useGetSampleFileKey = (params) => {
  const [getFileSampleKey, { data, loading }] = useLazyQuery(GET_SAMPLE_FILE_KEY);

  const handleGetFileSampleKey = async (params) => {
    return await getFileSampleKey({
      variables: params,
    });
  };

  return {
    fileSampleCustomer: data?.product?.getSampleFile?.url || undefined,
    loadingFileSample: loading,
    handleGetFileSampleKey,
  };
};

export const useGetKeyDetail = (params) => {
  const { data, loading } = useQuery(GET_KEY_DETAIL, { variables: params, skip: !params.id });
  return {
    dataKeyDetail: data?.serial?.get,
    loadingKeyDetail: loading,
  };
};

export const useGetSuggestedEntryList = (params) => {
  const { data, loading } = useQuery(GET_SUGGESTED_ENTRY_LIST, {
    variables: {
      ...params,
      filters: {
        ...params?.filters,
        digitalStatuses:
          params.activeKey === "ALL" &&
          (params.filters.digitalStatuses === undefined || params.filters.digitalStatuses.length === 0)
            ? [DIGITAL_STATUS.WAITING_SEND_CUSTOMER, DIGITAL_STATUS.COMPLETED, DIGITAL_STATUS.CANCELED]
            : params.filters.digitalStatuses,
      },
    },
  });

  return {
    dataSuggestedEntry: data?.order?.pagination?.orders || [],
    loadingDataSuggestedEntry: loading,
    paginationData: data?.order?.pagination?.paginationData,
  };
};

export const useGetShowPassword = () => {
  const [getPassword, { data, loading }] = useLazyQuery(GET_SHOW_PASSWORD);

  const handleGetPassword = async (params) => {
    return await getPassword({
      variables: params,
    });
  };

  return {
    handleGetPassword,
    loadingShowPassword: loading,
  };
};

export const useGetDataCreateExportKey = (params) => {
  const { data, loading, error } = useQuery(GET_DATA_CREATE_EXPORT_KEY, {
    variables: params,
  });

  return {
    dataCreateExportKey: data?.order?.get,
    loadingDataCreateExportKey: loading,
    error,
  };
};

export const useGenerateKey = (params) => {
  const { data, loading, error } = useQuery(GENERATE_KEY, {
    skip: params.skip,
    variables: {
      inputs: params.inputs,
    },
  });

  return {
    dataGenerateKey: data?.serial?.generateSerials,
    loadingGenerateKey: loading,
    error,
  };
};

export const useCountDigitalStatusOrder = () => {
  const params = (digitalStatus) => {
    return {
      filters: {
        digitalStatuses: digitalStatus,
        query: "",
      },
    };
  };

  const { data: dataAll } = useQuery(COUNT_STATUS_ORDER, {
    variables: params([DIGITAL_STATUS.WAITING_SEND_CUSTOMER, DIGITAL_STATUS.COMPLETED, DIGITAL_STATUS.CANCELED]),
  });
  const { data: dataWaitingSendCustomer } = useQuery(COUNT_STATUS_ORDER, {
    variables: params([DIGITAL_STATUS.WAITING_SEND_CUSTOMER]),
  });
  const { data: dataCompleted } = useQuery(COUNT_STATUS_ORDER, {
    variables: params([DIGITAL_STATUS.COMPLETED]),
  });
  const { data: dataCancelled } = useQuery(COUNT_STATUS_ORDER, {
    variables: params([DIGITAL_STATUS.CANCELED]),
  });

  return {
    COUNT_ALL: dataAll?.order?.count,
    COUNT_WAITING_SEND_CUSTOMER: dataWaitingSendCustomer?.order?.count,
    COUNT_COMPLETED: dataCompleted?.order?.count,
    COUNT_CANCELED: dataCancelled?.order?.count,
  };
};

export const useKeyManagementPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();

  return {
    canKeyLicenseAccountAll: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_ALL]),
    canKeyLicenseAccountViewAll: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_VIEW_ALL]),
    canKeyLicenseAccountViewPart: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_VIEW_PART]),
    canKeyLicenseAccountCreate: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_CREATE]),
    canKeyLicenseAccountDetail: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_DETAIL]),
    canKeyLicenseAccountLock: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_LOCK]),
    canKeyLicenseAccountUnlock: hasPermission(permissions, [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_UNLOCK]),
    canSuggestKeyLicenseAccountAll: hasPermission(permissions, [USER_PERMISSIONS.SUGGEST_KEY_LICENSE_ACCOUNT_ALL]),
    canSuggestKeyLicenseAccountView: hasPermission(permissions, [USER_PERMISSIONS.SUGGEST_KEY_LICENSE_ACCOUNT_VIEW]),
    canSuggestKeyLicenseAccountConfirm: hasPermission(permissions, [
      USER_PERMISSIONS.SUGGEST_KEY_LICENSE_ACCOUNT_CONFIRM,
    ]),
    canSuggestKeyLicenseAccountCancel: hasPermission(permissions, [
      USER_PERMISSIONS.SUGGEST_KEY_LICENSE_ACCOUNT_CANCEL,
    ]),
    loading,
  };
};
