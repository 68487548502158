export const ACTION = { CREATE: "CREATE", EDIT: "EDIT" };

export const ATTRIBUTE_TYPE = { PREDEFINED: "PREDEFINED", FREE_TEXT: "FREE_TEXT" };

export const PRODUCT_INPUT_TYPE = {
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  INPUT_NUMBER: "INPUT_NUMBER",
  SELECT: "SELECT",
  INPUT_CURRENCY: "INPUT_CURRENCY",
  INPUT_SHORT_CODE: "INPUT_SHORT_CODE",
  INPUT_PRODUCT_CODE: "INPUT_PRODUCT_CODE",
  INPUT_VAT: "INPUT_VAT",
};

export const PRODUCT_TYPE = {
  MATERIAL: "MATERIAL",
  KEY_LICENSE: "KEY_LICENSE",
};
