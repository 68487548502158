import { Col, Row, Spin, Typography } from "antd";
import { SelectLoadMore } from "components/CustomSelect";
import { IMPORT_TYPE } from "features/Serial/constant";
import { t } from "i18next";

import FormInput from "components/FormInput";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import styles from "./index.module.scss";

function ProviderInfo({
  options,
  loadMoreData,
  hasMoreData,
  onSearch,
  loading,
  importType,
  providerInfo,
  orderInfo,
  handleChangeInfo,
  handleChange,
}) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        {importType === IMPORT_TYPE.FIRST_TIME_IMPORT ? (
          <>
            <SelectLoadMore
              formItemOptions={{
                name: "providerID",
                label: <span className="sub-title">{t("serialIE.providerInfo.name")}</span>,
                rules: [
                  {
                    required: true,
                    message: t("serialIE.error.provider"),
                  },
                ],
              }}
              inputOptions={{
                placeholder: t("serialIE.placeholder.selectProvider"),
                options: options,
                onSelect: (value, options) => handleChangeInfo(value, options),
              }}
              onLoadMore={loadMoreData}
              hasMoreData={hasMoreData}
              onSearch={onSearch}
            />
            <FormInput
              {...{
                type: INPUT_TYPE.DATE_PICKER,
                formItemOptions: {
                  label: <Typography.Text strong>{t("serialIE.providerInfo.saleDate")}</Typography.Text>,
                  name: "soldDateFromProvider",
                  rules: [{ required: true, message: t("common.validateRequired") }],
                },
                inputOptions: {
                  placeholder: t("warrantyRequest.create.placeHolder.date"),
                  format: DATE_FORMAT,
                },
              }}
            />
          </>
        ) : (
          <div>
            <SelectLoadMore
              formItemOptions={{
                name: "orderID",
                label: <span className="sub-title">{t("serialIE.providerInfo.orderCode")}</span>,
                rules: [
                  {
                    required: true,
                    message: t("serialIE.error.product"),
                  },
                ],
              }}
              inputOptions={{
                placeholder: t("serialIE.placeholder.selectProductcode"),
                options: options,
                dropdownMatchSelectWidth: 1000,
                allowClear: false,
                dropdownRender: (originNode) => (
                  <>
                    <Row gutter={16} className={styles["custom-dropdown"]}>
                      <Col span={6} className={styles["item"]} style={{ padding: "10px 0 0 20px" }}>
                        <Typography.Text>{t("serialIE.providerInfo.orderCodeB")}</Typography.Text>
                      </Col>
                      <Col span={10} className={styles["item"]} style={{ paddingTop: "10px" }}>
                        <Typography.Text>{t("serialIE.providerInfo.customer")}</Typography.Text>
                      </Col>
                      <Col span={5} className={styles["item"]} style={{ paddingTop: "10px" }}>
                        <Typography.Text>{t("serialIE.providerInfo.warehouseB")}</Typography.Text>
                      </Col>
                      <Col span={3} className={styles["item"]}>
                        <Typography.Text>{t("serialIE.providerInfo.quantity")}</Typography.Text>
                      </Col>
                    </Row>
                    <Spin spinning={loading}>{originNode}</Spin>
                  </>
                ),
                optionLabelProp: "labelOrder",
                onSelect: (value, options) => handleChangeInfo(value, options),
              }}
              onLoadMore={loadMoreData}
              hasMoreData={hasMoreData}
              onSearch={onSearch}
              onChange={handleChange}
            />
          </div>
        )}
      </Col>
      {importType === IMPORT_TYPE.FIRST_TIME_IMPORT
        ? providerInfo && (
            <>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.phone")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{providerInfo?.telephone || "- -"}</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.email")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{providerInfo?.email || "- -"}</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.address")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{providerInfo?.address || "- -"}</Typography.Text>
              </Col>
            </>
          )
        : orderInfo && (
            <>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.seller")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{orderInfo?.seller?.fullName || "- -"}</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.phone")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{orderInfo?.seller?.telephone || "- -"}</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.email")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{orderInfo?.seller?.email || "- -"}</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text className="sub-title">{t("serialIE.providerInfo.warehouse")}</Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>{orderInfo?.exportedWarehouse?.name || "- -"}</Typography.Text>
              </Col>
            </>
          )}
    </Row>
  );
}

export default ProviderInfo;
