import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { useGetSellerForCustomer } from "hooks/seller";
import useDebounce from "hooks/useDebounce";
import { t } from "i18next";
import { SELLER_PAYMENT_METHOD } from "pages/App/Accountant/AccountantList/components/SellerList/constants";
import { formatCurrency, getQuery } from "utils/helperFuncs";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import useSellerGroupOptions from "features/SellerGroup/hooks";
import Img from "assets/images";
import { Link } from "react-router-dom";

function useAgentList({ id }) {
  const GET_QUERY = getQuery();
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedValue = useDebounce(searchTerm);
  const { convertedOptions } = useSellerGroupOptions();
  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || null,
      isActive: GET_QUERY.isActive,
      sellerGroupIDs: id,
      isSeller: true,
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: 10,
    },
  });

  const { loading, data, paginationData } = useGetSellerForCustomer(params);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onChangeParams = (value) => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    });
  };

  const onFilter = (key, value) => {
    onChangeParams && onChangeParams({ [`${key}`]: value });
  };

  const convertPaymentMethod = (method) => {
    switch (method) {
      case SELLER_PAYMENT_METHOD.WALLET:
        return t("seller.paymentMethodWallet");
      case SELLER_PAYMENT_METHOD.DEBT:
        return t("seller.debt");
      default:
        return;
    }
  };

  function onTableChange(pagination) {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };

    setParams(newParams);
  }

  const columns = [
    {
      title: t("common.index"),
      dataIndex: "id",
      render: (_, _record, index) => index + 1,
    },
    {
      title: <span className="table-title">{t("seller.sellerCode")}</span>,
      dataIndex: "code",
      render: (_, record) => (
        <Link to={`/seller/detail/${record?.id}`} className="agent-code">
          {record?.code}
        </Link>
      ),
    },
    {
      title: <span className="table-title">{t("seller.sellerName")}</span>,
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: <span className="table-title">{t("common.address")}</span>,
      dataIndex: "address",
      width: 300,
    },
    {
      title: <span className="table-title">{t("common.level")}</span>,
      dataIndex: "level",
      align: "center",

      render: (_, record) => record?.sellerLevel?.name,
    },
    {
      title: <span className="table-title">{t("seller.sellerPaymentMethod")}</span>,
      dataIndex: "paymentMethod",
      render: (_, record) => convertPaymentMethod(record?.paymentMethod),
    },
    {
      title: <span className="table-title">{t("seller.sellerDebtLimit")}</span>,
      dataIndex: "debtLimit",
      render: (text) => formatCurrency(text),
      align: "right",
    },
    {
      title: <span className="table-title">{t("seller.sellerDebtAge")}</span>,
      dataIndex: "debtAge",
      align: "center",
    },

    {
      title: <span className="table-title">{t("common.status")}</span>,
      dataIndex: "status",
      width: "5%",
      filterDropdown: (
        <FilterMenu onFilter={(value) => onFilter("isActive", value)} type="radio" options={convertedOptions} />
      ),
      render: (_, record) => (
        <Switch
          disabled
          checkedChildren="ON"
          unCheckedChildren="OFF"
          icon={record.isActive === true ? Img.LockedIcon : Img.UnlockedIcon}
          checked={record.isActive === true}
        />
      ),
    },
  ];

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue || null,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  }, [debouncedValue]);

  return {
    columns,
    loading,
    onTableChange,
    paginationData,
    params,
    data,
    handleSearch,
  };
}

export default useAgentList;
