import { Button, Col, Form, Radio, Row, Space, Spin, Typography } from "antd";
import CustomAffix from "components/CustomAffix";
import CustomModal from "components/CustomModal";
import { SelectLoadMore } from "components/CustomSelect";
import SearchableSelect from "components/CustomSelect/SearchableSelect";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import UploadFiles from "components/UploadFiles";
import { DATE_FORMAT, INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { getFile } from "utils/helperFuncs";
import { SubTitleCard, TitleCard } from "../../components/TitleCard";
import { IMPORT_TYPE, convertTotalNumber } from "../../constant";
import SelectProduct from "../CreateSerial/components/SelectProduct";
import ModalWarning from "./ModalCancel";
import TableEdit from "./Table";
import styles from "./index.module.scss";
import useEdit from "./useEdit";

const lableCol = { span: 24, offset: 0 };

const renderSubTotal = (sumSerialByOrder, sumSerialByScanned, sumSerialByLack) => {
  const renderTitle = (x) => {
    if (x === 0) {
      return {
        title: t("serialIE.productInfo.quantitySerial"),
        color: "#052987",
        total: <span style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</span>,
      };
    } else if (x > 0) {
      return {
        title: t("serialIE.productInfo.residual"),
        color: "red",
        total: sumSerialByScanned - sumSerialByOrder,
      };
    } else {
      return {
        title: t("serialIE.productInfo.lack"),
        color: "#ef9351",
        total: sumSerialByLack,
      };
    }
  };
  return (
    <>
      <Col span={12}>
        <Typography.Text style={{ color: renderTitle(sumSerialByScanned - sumSerialByOrder).color }} strong>
          {renderTitle(sumSerialByScanned - sumSerialByOrder).title}
        </Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text style={{ color: renderTitle(sumSerialByScanned - sumSerialByOrder).color }}>
          : {renderTitle(sumSerialByScanned - sumSerialByOrder).total}
        </Typography.Text>
      </Col>
    </>
  );
};

function EditSerialImport() {
  const {
    loading,
    form,
    initialValues,
    handleValuesChange,
    data,
    exportedWarehouseOptions,
    providerOptions,
    handleChangeInfo,
    loadMoreProvider,
    hasMoreProvider,
    onSearch,
    providerInfo,
    handleGetProduct,
    itemsCopy,
    sumSerialByOrder,
    sumSerialByScanned,
    sumSerialByLack,
    handleEdit,
    handleCancelEdit,
    handleRemoveProduct,
  } = useEdit();

  const { platformName } = useGetInfoPlatform();

  const optionRadio = [
    { label: t("serialIE.firstTimeImport"), value: IMPORT_TYPE.FIRST_TIME_IMPORT },
    { label: t("serialIE.return", { platformName }), value: IMPORT_TYPE.RETURN },
    { label: t("serialIE.reImportWarranty"), value: IMPORT_TYPE.RE_IMPORT_WARRANTY },
  ];
  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("serialImportEdit.edit")}
            routes={[
              { path: "/import-serial", name: t("serialIE.serial") },
              { path: "/import-serial", name: t("serialIE.importSerial") },
              { path: "#", name: t("serialImportEdit.edit") },
            ]}
          />
        </Col>
        <Col span={24}>
          <Form layout="horizontal" form={form} initialValues={initialValues} onValuesChange={handleValuesChange}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Card title={<TitleCard value={data?.code} title={t("serialIE.ticketImportSerial")} />}>
                  <Row>
                    <Col span={20}>
                      <Space>
                        <Typography.Text strong>{t("serialIE.importType")}: </Typography.Text>
                        <Radio.Group value={data?.type}>
                          {optionRadio?.map((item) => {
                            return (
                              <Radio disabled key={item.value} value={item.value}>
                                {item.label}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <SearchableSelect
                        formItemOptions={{
                          name: "warehouseID",
                          rules: [
                            {
                              required: true,
                              message: t("serialIE.error.warehouseID"),
                            },
                          ],
                        }}
                        selectOptions={{
                          disabled: true,
                          options: exportedWarehouseOptions,
                          placeholder: t("serialIE.placeholder.selectWarehouse"),
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 8]}>
                        <Col span={12} className={styles["border-right"]}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <SubTitleCard subTitle={t("serialIE.providerInfo.title")} />
                            </Col>
                            <Col span={24}>
                              <SelectLoadMore
                                formItemOptions={{
                                  name: "providerID",
                                  label: <span className="sub-title">{t("serialIE.providerInfo.name")}</span>,
                                  rules: [
                                    {
                                      required: true,
                                      message: t("serialIE.error.provider"),
                                    },
                                  ],
                                }}
                                inputOptions={{
                                  placeholder: t("serialIE.placeholder.selectProvider"),
                                  options: providerOptions,
                                  onSelect: handleChangeInfo,
                                  disabled: true,
                                }}
                                onLoadMore={loadMoreProvider}
                                hasMoreData={hasMoreProvider}
                                onSearch={onSearch}
                              />
                            </Col>
                            <Col span={24}>
                              <FormInput
                                {...{
                                  type: INPUT_TYPE.DATE_PICKER,
                                  formItemOptions: {
                                    label: (
                                      <Typography.Text strong>{t("serialIE.providerInfo.saleDate")}</Typography.Text>
                                    ),
                                    name: "soldDateFromProvider",
                                    rules: [{ required: true, message: t("common.validateRequired") }],
                                  },
                                  inputOptions: {
                                    disabled: true,
                                    placeholder: t("warrantyRequest.create.placeHolder.date"),
                                    format: DATE_FORMAT,
                                  },
                                }}
                              />
                            </Col>
                            <Col span={4}>
                              <Typography.Text className="sub-title">
                                {t("serialIE.providerInfo.phone")}
                              </Typography.Text>
                            </Col>
                            <Col span={20}>
                              <Typography.Text>{providerInfo?.telephone || data?.provider?.telephone}</Typography.Text>
                            </Col>
                            <Col span={4}>
                              <Typography.Text className="sub-title">
                                {t("serialIE.providerInfo.email")}
                              </Typography.Text>
                            </Col>
                            <Col span={20}>
                              <Typography.Text>{providerInfo?.email || data?.provider?.email}</Typography.Text>
                            </Col>
                            <Col span={4}>
                              <Typography.Text className="sub-title">
                                {t("serialIE.providerInfo.address")}
                              </Typography.Text>
                            </Col>
                            <Col span={20}>
                              <Typography.Text>{providerInfo?.address || data?.provider?.address}</Typography.Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row gutter={[16, 8]}>
                            <Col span={24}>
                              <SubTitleCard subTitle={t("serialIE.addInfo.title")} />
                            </Col>
                            <Col span={24}>
                              <FormInput
                                {...{
                                  type: INPUT_TYPE.TEXT_AREA,
                                  formItemOptions: {
                                    label: <Typography.Text strong>{t("warrantyRequest.create.note")}</Typography.Text>,
                                    name: "note",
                                    labelCol: lableCol,
                                  },
                                  inputOptions: {
                                    placeholder: t("warrantyRequest.create.placeHolder.note"),
                                    maxLength: 255,
                                    count: true,
                                  },
                                }}
                              />
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                labelCol={lableCol}
                                name={"fileURLs"}
                                getValueFromEvent={getFile}
                                valuePropName={"fileList"}
                                label={<Typography.Text strong>{t("warrantyRequest.popup.files")}</Typography.Text>}
                                extra={t("warrantyRequest.create.noteAttack")}
                              >
                                <UploadFiles />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card
                  title={
                    <div>
                      <TitleCard title={t("serialIE.productInfo.title")} />
                    </div>
                  }
                >
                  <Row gutter={[16, 8]}>
                    <Col span={12}>
                      <SelectProduct handleGetProduct={handleGetProduct} />
                    </Col>
                    <Col span={24}>
                      <Form.List name="items">
                        {(items, { remove, add }) => {
                          return (
                            <TableEdit
                              data={itemsCopy}
                              handleRemoveProduct={handleRemoveProduct}
                              remove={remove}
                              form={form}
                            />
                          );
                        }}
                      </Form.List>
                    </Col>
                    <Col span={24} xl={18}></Col>
                    <Col span={24} xl={6}>
                      <Row gutter={[]} className={styles["summary"]}>
                        <Col span={12}>{t("serialIE.productInfo.sumProduct")}</Col>
                        <Col span={12}>: {convertTotalNumber(itemsCopy?.length)}</Col>
                        <Col span={12}>{t("serialIE.productInfo.sumSerialByOrder")}</Col>
                        <Col span={12}>: {convertTotalNumber(sumSerialByOrder)}</Col>
                        <Col span={12}>{t("serialIE.productInfo.sumSerialScanned")}</Col>
                        <Col span={12}>: {convertTotalNumber(sumSerialByScanned)}</Col>

                        {renderSubTotal(sumSerialByOrder || 0, sumSerialByScanned || 0, sumSerialByLack || 0)}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <CustomAffix
                  addButton={
                    <CustomModal
                      footer={false}
                      customComponent={<Button>{t("common.cancel")}</Button>}
                      message={<ModalWarning title={t("serialImportEdit.messageWarning")} />}
                      onOke={handleCancelEdit}
                    />
                  }
                  title={t("common.save")}
                  onClick={handleEdit}
                  loading={loading}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
}

export default EditSerialImport;
