import { t } from "i18next";

import { IMPORT_TYPE } from "features/Serial/constant";

import CustomAffix from "components/CustomAffix";
import CustomButton from "components/CustomButton";
import CustomModal from "components/CustomModal";
import ModalWithIconWarning from "components/CustomModalWithIcon/Warning";

function AffixButton({
  onCancelCreate,
  importType,
  handleCreate,
  loadingCreateBtn,
  visible,
  showOffModalConfirmCreate,
}) {
  return (
    <CustomAffix
      showButtonDefault={false}
      addButton={
        <>
          <CustomModal
            {...{
              customComponent: <CustomButton title={t("common.cancel")} />,
              footer: false,
              message: t("serialIE.notify.cancelCreate"),
              centered: true,
              onOke: onCancelCreate,
            }}
          />
          {importType === IMPORT_TYPE.FIRST_TIME_IMPORT ? (
            <CustomButton
              type={"primary"}
              title={t("serialIE.productInfo.warehouse")}
              htmlType={"submit"}
              onClick={handleCreate}
              loading={loadingCreateBtn}
            />
          ) : (
            <CustomModal
              {...{
                customComponent: (
                  <CustomButton
                    type={"primary"}
                    title={t("serialIE.productInfo.warehouse")}
                    htmlType={"submit"}
                    loading={loadingCreateBtn}
                  />
                ),
                footer: false,
                message: <ModalWithIconWarning title={t("serialIE.notify.warningCreate1")} />,
                centered: true,
                onOke: handleCreate,
                visible: visible,
                onCancel: showOffModalConfirmCreate,
                buttonLoading: loadingCreateBtn,
              }}
            />
          )}
        </>
      }
    />
  );
}

export default AffixButton;
