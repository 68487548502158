import React, { useState } from "react";
import { t } from "i18next";
import { Col, Divider, Row, Space, Typography } from "antd";
import CreateButton from "components/CreateButton";
import { RenderRemainingDeliverTime, warrantyStatus } from "features/Warranty/util";
import "./index.scss";
import { WARRANTY_STATUS, warrantyTypeLabel } from "features/Warranty/constant";
import SellerLink from "components/CustomRedirectLink/SellerLink";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import OrderLink from "components/CustomRedirectLink/OrderLink";
import DrawerInfoSerial from "../DrawerInfoSerial";
import { PrimaryButtonOutLine } from "components/PrimaryButton";

const InfoWarranty = ({ warrantyDetail, handleClick }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleOnClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="wrapper-warranty">
      <Row gutter={[16, 8]} justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Space style={{ flexWrap: "wrap" }}>
            <div>
              <Typography.Text strong style={{ fontSize: "16px" }}>
                {t("warranty.warrantyDetail.warrantyCode")}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong style={{ fontSize: "16px", color: "#2246DC" }}>
                {` #${warrantyDetail?.code}` || "--"}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={warrantyStatus[warrantyDetail.status]}>
                {warrantyStatus[warrantyDetail.status]?.text || "--"}
              </Typography.Text>
            </div>
          </Space>
        </Col>
        <Col span={12}>
          <Space style={{ width: "100%", justifyContent: "end" }}>
            {warrantyDetail?.isMigrated && (
              <PrimaryButtonOutLine title={"Xem chi tiết dữ liệu"} onClick={handleOpenDrawer} />
            )}
            <DrawerInfoSerial openDrawer={openDrawer} handleOnClose={handleOnClose} data={warrantyDetail} />
            <CreateButton
              disabled={warrantyDetail?.status === WARRANTY_STATUS.EXPIRED}
              onClick={handleClick}
              title={t("warranty.warrantyList.warrantyClaim")}
            />
          </Space>
        </Col>
      </Row>
      <Divider className="hr" />
      <Row gutter={[16, 8]}>
        <Col xl={12} span={24}>
          <Row gutter={[16, 8]} style={{ borderRight: "1px dashed #9CADC4" }}>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.serial/imei")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyDetail?.serialImei}</Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.productCode")}</Typography.Text>
            </Col>
            <Col span={17}>
              <ProductLink product={warrantyDetail} />
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.productName")}</Typography.Text>
            </Col>
            <Col span={17}>
              {warrantyDetail?.productName === null ? (
                <Typography.Text>{warrantyDetail?.product?.name}</Typography.Text>
              ) : (
                <Typography.Text>{warrantyDetail?.productName}</Typography.Text>
              )}
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.attributeLV3")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyDetail?.category?.name || "--"}</Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.orderCode")}</Typography.Text>
            </Col>
            <Col span={17}>
              <OrderLink order={warrantyDetail} />
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.branch")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyDetail?.branch?.name || "--"}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col xl={12} span={24}>
          <Row gutter={[16, 8]}>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.agentName")}</Typography.Text>
            </Col>
            <Col span={17}>
              {warrantyDetail?.customerName === null ? (
                <SellerLink seller={warrantyDetail} />
              ) : (
                <Typography.Link>{warrantyDetail?.customerName}</Typography.Link>
              )}
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.soldDate")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyDetail?.soldDate}</Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.warrantyType")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyTypeLabel?.[warrantyDetail?.warrantyType]}</Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.totalWarranty")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>
                {warrantyDetail?.warrantyOnSale} {warrantyDetail?.warrantyDateUnit}
              </Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text strong>{t("warranty.warrantyDetail.dateWarranty")}</Typography.Text>
            </Col>
            <Col span={17}>
              <Typography.Text>{warrantyDetail?.warrantyEndDate}</Typography.Text>
              <br />
              <RenderRemainingDeliverTime time={warrantyDetail?.remainingWarrantyDays} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default InfoWarranty;
