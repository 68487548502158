import { gql } from "@apollo/client";

export const GET_REGIONS_LIST = gql`
  query {
    region {
      list {
        id
        name
        code
      }
    }
  }
`;
