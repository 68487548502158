/* eslint-disable react/jsx-no-useless-fragment */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Row, Space, Spin, Typography } from "antd";
import CustomButton from "components/CustomButton";
import PageHeader from "components/PageHeader";
import { SALES_RETURN, importRequestType } from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { ButtonCancelSalesReturn } from "../List/components/ButtonCancelSalesReturn";
import { ButtonCompleteSalesReturn } from "../List/components/ButtonCompleteSalesReturn";
import ButtonManagerApproveSalesReturn from "../List/components/ButtonManagerApproveSalesReturn";
import ButtonManagerRejectSalesReturn from "../List/components/ButtonManagerRejectSalesReturn";
import ButtonTechnicalApproveSalesReturn from "../List/components/ButtonTechnicalApproveSalesReturn";
import ButtonTechnicalRejectSalesReturn from "../List/components/ButtonTechnicalRejectSalesReturn";
import AddInfo from "./components/AddInfo";
import InfoProvider from "./components/InfoProvider";
import PaymentInfo from "./components/PaymentInfo";
import TableProduct from "./components/Table";
import { TitleCard } from "./components/TitleCard";
import "./index.scss";
import useList from "./useList";

function DetailSalesReturn() {
  const {
    data,
    loading,
    form,
    canImportSalesReturnManagerApprove,
    canImportSalesReturnTechnicalApprove,
    canImportSalesReturnUpdate,
    canImportSalesReturnCancel,
    canImportSalesReturnManagerReject,
    canImportSalesReturnTechnicalReject,
    canImportSalesReturnComplete,
  } = useList();
  return (
    <Spin spinning={loading}>
      <div className="detail-sales-return">
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("warehouse.salesReturn.titleDetailSalesReturn")}
              routes={[
                { path: "#", name: t("warehouse.salesReturn.sales") },
                { path: "/sales-return", name: t("warehouse.salesReturn.salesReturn") },
                { path: "/", name: t("warehouse.salesReturn.titleDetailSalesReturn") },
              ]}
            />
          </Col>
          <Col span={24} className="form-head">
            <Row gutter={[16, 8]} style={{ justifyContent: "space-between", padding: "8px" }}>
              <Col span={10}>
                <Row gutter={[16, 8]}>
                  <Col span={4}></Col>
                  <Col span={20}>&nbsp;</Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={20}>
                    <Typography.Text strong>{t("warehouse.create.typeRecommend")} :</Typography.Text>
                    <Typography.Text>{" " + importRequestType[data?.type] || "---"}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}></Row>
              </Col>
              <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space style={{ background: "transparent", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("warehouse.salesReturn.dayCreate")}: {formatDateTime(data?.createdAt) || "--"}
                        &nbsp;{t("common.by")} {data?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text italic style={{ color: "#5B6673" }}>
                        {t("warehouse.salesReturn.dayUpdate")}: {formatDateTime(data?.updatedAt) || "--"}&nbsp;
                        {t("common.by")}{" "}
                        {data?.updatedBy ? data?.updatedBy?.fullname : data?.createdBy?.fullname || "---"}
                      </Typography.Text>
                    </Col>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: "30px" }}>
            <Card
              title={
                <TitleCard
                  title={t("warehouse.salesReturn.salesReturn")}
                  value={data?.code}
                  status={data?.status}
                  isDetail={true}
                  waitingApprovalCount={data?.waitingApprovalCount}
                />
              }
              style={{ flexGrow: 1 }}
              className="card-parent"
            >
              {data?.status === SALES_RETURN.REJECTED && (
                <Col span={24}>
                  <Alert
                    message={
                      <Row>
                        <Typography.Text strong>
                          <Col>{t("warehouse.salesReturn.reasonRejectSalesReturn")}: </Col>
                        </Typography.Text>
                        <Col>
                          <Typography.Text style={{ marginLeft: "4px" }}>{data?.rejectedReason}</Typography.Text>
                        </Col>
                      </Row>
                    }
                    icon={<ExclamationCircleOutlined />}
                    type="error"
                    showIcon
                    style={{ width: "100%", margin: "15px 0 5px 0" }}
                  />
                </Col>
              )}
              {data?.status === SALES_RETURN.WAITING_APPROVAL && data?.waitingApprovalCount >= 2 && (
                <Col span={24}>
                  <Alert
                    message={
                      <Row>
                        <Typography.Text strong>
                          <Col>{t("warehouse.salesReturn.rejectTech")}: </Col>
                        </Typography.Text>
                        <Col>
                          <Typography.Text style={{ marginLeft: "4px" }}>{data?.rejectedReason}</Typography.Text>
                        </Col>
                      </Row>
                    }
                    icon={<ExclamationCircleOutlined />}
                    type="error"
                    showIcon
                    style={{ width: "100%", margin: "15px 0 5px 0" }}
                  />
                </Col>
              )}
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={10} className="card-container-info">
                    <Card
                      title={<TitleCard title={t("warehouse.salesReturn.infoSeller")} />}
                      style={{ flexGrow: 1 }}
                      className="card-children"
                    >
                      <InfoProvider sellerInfo={data?.supplier} data={data} />
                    </Card>
                  </Col>
                  <Col span={7} className="card-container-info">
                    <Card
                      title={<TitleCard title={t("warehouse.salesReturn.infoRecept")} />}
                      style={{ flexGrow: 1 }}
                      className="card-children"
                    >
                      <PaymentInfo data={data} />
                    </Card>
                  </Col>
                  <Col span={7} className="card-container-info">
                    <Card title={<TitleCard title={t("warehouse.create.addInfo")} />} className="card-children">
                      <AddInfo data={data} />
                    </Card>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Card title={<TitleCard title={t("warehouse.create.listProduct")} />} className="card-children">
                      <Col span={24}>
                        <Form.List name="items">
                          {(items, { remove }) => {
                            return (
                              <TableProduct
                                remove={remove}
                                form={form}
                                dataTableProduct={data?.items}
                                type={data?.type}
                              />
                            );
                          }}
                        </Form.List>
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
          <Col span={24}>
            <Card className="form-footer">
              {((data?.status === SALES_RETURN.WAITING_APPROVAL && data?.waitingApprovalCount === 1) ||
                data?.status === SALES_RETURN.REJECTED) &&
                canImportSalesReturnCancel && (
                  <>
                    <ButtonCancelSalesReturn record={data} />
                  </>
                )}
              {canImportSalesReturnManagerReject && data?.status === SALES_RETURN.WAITING_APPROVAL && (
                <Button type="secondary">
                  <ButtonManagerRejectSalesReturn record={data} />
                </Button>
              )}
              {canImportSalesReturnTechnicalReject && data?.status === SALES_RETURN.WAITING_TECHNICAL_CONFIRM && (
                <Button>
                  <ButtonTechnicalRejectSalesReturn record={data} />
                </Button>
              )}
              {canImportSalesReturnManagerApprove && data?.status === SALES_RETURN.WAITING_APPROVAL && (
                <Button type="primary">
                  <ButtonManagerApproveSalesReturn record={data} />
                </Button>
              )}
              {canImportSalesReturnTechnicalApprove && data?.status === SALES_RETURN.WAITING_TECHNICAL_CONFIRM && (
                <Button type="primary">
                  <ButtonTechnicalApproveSalesReturn record={data} />
                </Button>
              )}
              {((data?.status === SALES_RETURN.WAITING_APPROVAL && data?.waitingApprovalCount === 1) ||
                data?.status === SALES_RETURN.REJECTED) &&
                canImportSalesReturnUpdate && (
                  <>
                    <Link to={`/sales-return/edit/${data?.id}`}>
                      <CustomButton type={"primary"} title={t("common.EDIT")} htmlType={"submit"} />
                    </Link>
                  </>
                )}
              {canImportSalesReturnComplete && data?.status === SALES_RETURN.WAITING_FOR_STOCK && (
                <ButtonCompleteSalesReturn record={data} type="primary" />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default DetailSalesReturn;
