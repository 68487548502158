import { Col, Descriptions, Row, Space, Typography } from "antd";
import CustomViewDocumentFiles from "components/CustomViewDocumentFiles";
import { t } from "i18next";

function AddInfo({ dataDetail }) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Col span={24}>
          <Descriptions bordered column={2} size="small" className="add-info-detail-serial-import-notes">
            <Descriptions.Item span={2} label={t("warrantyRequest.create.note")}>
              <Typography.Text>{dataDetail?.note ? dataDetail?.note : "- -"}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item span={1} label={t("warrantyRequest.popup.files")}>
              <Space style={{ flexWrap: "wrap" }}>
                {dataDetail?.fileURLs?.map((fileURL, index) => {
                  return <CustomViewDocumentFiles file={fileURL} key={index} />;
                }) || <Typography.Text italic>{t("common.noFiles")}</Typography.Text>}
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Col>
    </Row>
  );
}

export default AddInfo;
