import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

function OrderLink({ order }) {
  if (order?.orderID) {
    return (
      <Link to={`/order/${order?.orderID}`} target="_blank">
        <Typography.Link strong>{order?.order?.number}</Typography.Link>
      </Link>
    );
  }

  return <Typography.Text>--</Typography.Text>;
}

export default OrderLink;
