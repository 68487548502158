import React from "react";
import { Tag } from "antd";
import "./index.scss";

function CustomTags({ label, handleOnClose }) {
  return (
    <Tag closable onClose={handleOnClose} className="custom-tag">
      {label}
    </Tag>
  );
}

export default CustomTags;
