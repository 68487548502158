import { Form, Space } from "antd";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CAMPAIGN_TYPE,
  COMBO_CAMPAIGN,
  DISCOUNT_TYPE,
  PRODUCT_TYPE,
  PROMOTION_CAMPAIGN_TYPE,
  TYPE_LIMIT,
} from "features/Campaign/constants";
import { convertConditionProductGroups } from "hooks/campaign/converter";
import DiscountMoneyForm from "./DiscountMoneyForm";
import FixedPriceForm from "./FixedPriceForm";
import GiftForm from "./GiftForm";
import ButtonGroup from "./components/ButtonGroup";
import CampaignInfo from "./components/CampaignInfo";
import ConditionInfo from "./components/ConditionInfo";
import MarketingInfo from "./components/MarketingInfo";
import ModalSelectComboCampaign from "./components/ModalSelectComboCampaign";
import OneOnProductForm from "./OneOnProductForm";

import "./index.scss";

const initFormDiscountMoney = {
  type: COMBO_CAMPAIGN.DISCOUNT_MONEY,
  promotionCampaignTiers: [
    {
      type: COMBO_CAMPAIGN.DISCOUNT_MONEY,
      minQuantity: null,
      maxQuantityPerSeller: null,
      discountType: DISCOUNT_TYPE.DISCOUNT_MONEY,
      discountAmount: null,
      budgetAmount: null,
      conditionProductGroups: [{ quantity: null, conditionPromotionProducts: [] }],
      limitType: TYPE_LIMIT.LIMIT,
    },
  ],
};

const initFormFixedPrice = {
  type: COMBO_CAMPAIGN.FIXED_PRICE,
  maxQuantityPerSeller: null,
  budgetProductQuantity: null,
  limitType: TYPE_LIMIT.LIMIT,
  promotionCampaignTiers: [
    {
      conditionProductGroups: [{ quantity: null, conditionPromotionProducts: [] }],
      fixedComboPrice: null,
      type: COMBO_CAMPAIGN.FIXED_PRICE,
    },
  ],
};

const initFormGift = {
  type: COMBO_CAMPAIGN.GIFT,
  maxQuantityPerSeller: null,
  budgetProductQuantity: null,
  limitType: TYPE_LIMIT.LIMIT,
  promotionCampaignTiers: [
    {
      type: COMBO_CAMPAIGN.GIFT,
      maxQuantityPerSeller: null,
      limitType: TYPE_LIMIT.LIMIT,
      conditionProducts: [
        {
          type: PRODUCT_TYPE.CONDITION_PRODUCT,
          quantity: null,
          conditionPromotionProducts: [],
        },
      ],
      conditionGifts: [
        {
          type: PRODUCT_TYPE.GIFT_PRODUCT,
          quantity: null,
          conditionPromotionProducts: [],
        },
      ],
    },
  ],
};

const CampaignForm = ({
  handleValuesChange,
  isApplyWithPromotion,
  promoType,
  initialValues,
  handleCheckIsApplyWithPromo,
  form,
  loadingCheckDuplicateCodes,
  promotionCampaignTiers,
  dataConfirmModal,
  visibleConfirmModal,
  handleOpenConfirmModal,
  handleCancelConfirmModal,
  handleActionCampaign,
  createLoading,
  indexErrorCombo,
  isUpdate,
  dataCampaign,
  loadingUpdate,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [selectedValueComboButtons, setSelectedValueComboButtons] = useState([]);
  const [dataGift, setDataGift] = useState([]);
  const [dataDiscountMoney, setDataDiscountMoney] = useState([]);
  const [dataFixedPrice, setDataFixedPrice] = useState([]);
  const [dataDiscountProduct, setDataDiscountProduct] = useState([]);

  const getDataTiersServer = (type) => {
    const newData = cloneDeep(dataCampaign?.promotionCampaignTierGroups);
    return newData?.filter((item) => item.type === type) || [];
  };

  const handleGetData = (value) => {
    setDataGift(value);
    setDataDiscountMoney(value);
    setDataFixedPrice(value);
  };

  const handleRemoveValueComboSelected = (valueToRemove) => {
    const updatedData = selectedValueComboButtons.filter((item) => item !== valueToRemove);
    setSelectedValueComboButtons(updatedData);
  };

  const handleGetValueComboButtons = (value) => {
    setSelectedValueComboButtons(value);
  };

  const isComboType = (type) => selectedValueComboButtons.includes(type);
  const isComboTypeInServer = (type) => dataCampaign?.promotionCampaignTierGroups?.some((item) => item.type === type);

  const isComboDiscountMoney = isComboType(COMBO_CAMPAIGN.DISCOUNT_MONEY);
  const isComboGift = isComboType(COMBO_CAMPAIGN.GIFT);
  const isComboFixedPrice = isComboType(COMBO_CAMPAIGN.FIXED_PRICE);

  useEffect(() => {
    const newArray = [
      isComboTypeInServer(COMBO_CAMPAIGN.DISCOUNT_MONEY) ? COMBO_CAMPAIGN.DISCOUNT_MONEY : undefined,
      isComboTypeInServer(COMBO_CAMPAIGN.GIFT) ? COMBO_CAMPAIGN.GIFT : undefined,
      isComboTypeInServer(COMBO_CAMPAIGN.FIXED_PRICE) ? COMBO_CAMPAIGN.FIXED_PRICE : undefined,
    ];
    setDataGift(convertConditionProductGroups(dataCampaign?.promotionCampaignTierGroups));
    setDataDiscountMoney(getDataTiersServer(COMBO_CAMPAIGN.DISCOUNT_MONEY));
    setDataFixedPrice(getDataTiersServer(COMBO_CAMPAIGN.FIXED_PRICE));
    setDataDiscountProduct(getDataTiersServer(COMBO_CAMPAIGN.DISCOUNT_PRODUCT));
    setSelectedValueComboButtons(newArray);
  }, [dataCampaign?.promotionCampaignTierGroups]);

  useEffect(() => {
    if (isUpdate) {
      history.push(`${location.pathname}#${dataCampaign?.campaignType}`);
    }
  }, [JSON.stringify(dataCampaign?.campaignType)]);

  return (
    <Form initialValues={initialValues} form={form} colon={false} layout="vertical" onValuesChange={handleValuesChange}>
      <Space direction="vertical" style={{ width: "100%" }} className="custom-collapse">
        <CampaignInfo
          form={form}
          isApplyWithPromotion={isApplyWithPromotion}
          handleCheckIsApplyWithPromo={handleCheckIsApplyWithPromo}
          isUpdate={isUpdate}
        />
        <MarketingInfo form={form} />
        {promoType === PROMOTION_CAMPAIGN_TYPE.TOP_SALES && <ConditionInfo />}
        {isComboDiscountMoney && selectedValueComboButtons?.length ? (
          <DiscountMoneyForm
            dataTiersServer={dataDiscountMoney}
            handleRemoveValueComboSelected={handleRemoveValueComboSelected}
            form={form}
            promotionCampaignTiers={promotionCampaignTiers}
            indexErrorCombo={indexErrorCombo}
            isUpdate={isUpdate}
            onGetDataDiscountMoney={handleGetData}
            initFormDiscountMoney={initFormDiscountMoney}
          />
        ) : null}
        {isComboGift && selectedValueComboButtons?.length ? (
          <GiftForm
            dataTiersServer={dataGift}
            handleRemoveValueComboSelected={handleRemoveValueComboSelected}
            form={form}
            promotionCampaignTiers={promotionCampaignTiers}
            indexErrorCombo={indexErrorCombo}
            isUpdate={isUpdate}
            initFormGift={initFormGift}
            onGetDataGift={handleGetData}
          />
        ) : null}
        {isComboFixedPrice && selectedValueComboButtons?.length ? (
          <FixedPriceForm
            dataTiersServer={dataFixedPrice}
            handleRemoveValueComboSelected={handleRemoveValueComboSelected}
            form={form}
            promotionCampaignTiers={promotionCampaignTiers}
            indexErrorCombo={indexErrorCombo}
            isUpdate={isUpdate}
            initFormFixedPrice={initFormFixedPrice}
            onGetDataFixedPrice={handleGetData}
          />
        ) : null}
        {location.hash.replace(/#/g, "") === CAMPAIGN_TYPE.COMBO_PROMO && (
          <ModalSelectComboCampaign
            form={form}
            initFormDiscountMoney={initFormDiscountMoney}
            initFormFixedPrice={initFormFixedPrice}
            initFormGift={initFormGift}
            onGetValueComboButtons={handleGetValueComboButtons}
            selectedValueComboButtons={selectedValueComboButtons}
            isDiscountMoney={isComboType(COMBO_CAMPAIGN.DISCOUNT_MONEY)}
            isGift={isComboType(COMBO_CAMPAIGN.GIFT)}
            isFixedPrice={isComboType(COMBO_CAMPAIGN.FIXED_PRICE)}
          />
        )}
        {location.hash.replace(/#/g, "") === CAMPAIGN_TYPE.PRODUCT_PROMO && (
          <OneOnProductForm form={form} isUpdate={isUpdate} dataTiersServer={dataDiscountProduct} />
        )}
      </Space>
      <ButtonGroup
        form={form}
        loadingCheckDuplicateCodes={loadingCheckDuplicateCodes}
        data={dataConfirmModal}
        visibleConfirmModal={visibleConfirmModal}
        handleOpenConfirmModal={handleOpenConfirmModal}
        handleCancelConfirmModal={handleCancelConfirmModal}
        handleActionCampaign={handleActionCampaign}
        createLoading={createLoading || loadingUpdate}
        isUpdate={isUpdate}
      />
    </Form>
  );
};

export default CampaignForm;
