import { Col, Row, Skeleton, Tag, Typography } from "antd";
import CustomCard from "components/CustomCard";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import {
  DATA_SOURCE,
  SELLER_TYPE,
  dataSourceLabel,
  dataSourceOptions,
  dataSourceTagColor,
} from "features/PotentialCustomer/constants";
import { t } from "i18next";
import { validator } from "utils/helperFuncs";
import DuplicateValuesWarning from "../DuplicateValuesWarning";

import "./index.scss";

function GeneraInfoCard({
  typePersonal,
  formValues,
  loadingIdNumber,
  loadingTaxCode,
  isDuplicateIdNumber,
  isDuplicateTaxCode,
  handleSearchIdNumber,
  handleSearchTaxCode,
  handleOpenDrawerMulti,
  notBordered,
  dataMulti,
  loadingDetail,
  isDetail,
  valueIdNumber,
  valueTaxCod,
}) {
  return (
    <CustomCard
      title={
        <Typography.Text strong style={{ color: "rgb(5, 41, 135)" }}>
          {t("potentialCustomer.form.generalInfo")}
        </Typography.Text>
      }
      content={
        <Skeleton active loading={loadingDetail}>
          <Row className="content-genera-info">
            <Col span={24}>
              {isDetail ? (
                <Row style={{ flexWrap: "nowrap" }} gutter={[32, 32]}>
                  <Col span={8}>
                    <Typography.Text strong>{t("potentialCustomer.form.dataSource")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Tag color={dataSourceTagColor?.[dataMulti?.dataSource]}>
                      {dataSourceLabel?.[dataMulti?.dataSource]}
                    </Tag>
                  </Col>
                </Row>
              ) : (
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.dataSource")}</Typography.Text>,
                    labelAlign: "left",
                    name: "dataSource",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: true,
                        message: t("common.messageRequiredSelect", {
                          message: t("potentialCustomer.form.dataSource"),
                        }),
                      },
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    options: [DATA_SOURCE.SELLER_APP, DATA_SOURCE.SELLER_WEB].includes(dataMulti?.dataSource)
                      ? [
                          { value: DATA_SOURCE.SELLER_APP, label: t("potentialCustomer.app") },
                          { value: DATA_SOURCE.SELLER_WEB, label: t("potentialCustomer.web") },
                        ]
                      : dataSourceOptions,
                    disabled: [DATA_SOURCE.SELLER_APP, DATA_SOURCE.SELLER_WEB].includes(dataMulti?.dataSource),
                    placeholder: t("potentialCustomer.placeHolder.dataSource"),
                  }}
                />
              )}
            </Col>
            {typePersonal === SELLER_TYPE.PERSONAL && (
              <Col className={notBordered ? "hideBlock" : ""} span={24}>
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.customerName")}</Typography.Text>,
                    labelAlign: "left",
                    name: "fullName",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: true,
                        message: t("common.messageRequiredType", {
                          message: t("potentialCustomer.form.customerName"),
                        }),
                      },
                      validator({
                        type: "space",
                      }),
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.customerName"),
                  }}
                />
              </Col>
            )}
            {typePersonal === SELLER_TYPE.COMPANY && (
              <Col className={notBordered ? "hideBlock" : ""} span={24}>
                <FormInput
                  type={INPUT_TYPE.INPUT}
                  formItemOptions={{
                    label: <Typography.Text strong>{t("potentialCustomer.form.companyName")}</Typography.Text>,
                    labelAlign: "left",
                    name: "fullName",
                    labelCol: { span: 8 },
                    rules: [
                      {
                        required: true,
                        message: t("common.messageRequiredType", {
                          message: t("potentialCustomer.form.companyName"),
                        }),
                      },
                      validator({
                        type: "space",
                      }),
                    ],
                  }}
                  inputOptions={{ ...notBordered, placeholder: t("potentialCustomer.placeHolder.companyName") }}
                />
              </Col>
            )}
            <Col span={24}>
              <FormInput
                formItemOptions={{
                  label: <Typography.Text strong>{t("potentialCustomer.form.taxCode")}</Typography.Text>,
                  labelAlign: "left",
                  name: "vatNumber",
                  labelCol: { span: 8 },
                  ...(formValues?.vatNumber && { hasFeedback: true }),
                  ...(isDuplicateTaxCode && { style: { marginBottom: 0 } }),
                  validateStatus: loadingTaxCode ? "validating" : isDuplicateTaxCode ? "error" : undefined,
                  rules: [
                    {
                      pattern: REGEX.NUMBER_WITH_HYPHEN,
                      message: t("seller.validateMessage.wrongTaxIDNumber"),
                    },
                  ],
                }}
                inputOptions={{
                  ...notBordered,
                  placeholder: t("potentialCustomer.placeHolder.taxCode"),
                  maxLength: 13,
                  onChange: handleSearchTaxCode,
                }}
              />
              {isDuplicateTaxCode && (
                <DuplicateValuesWarning
                  message={t("potentialCustomer.messageDuplicate.taxCode")}
                  fullName={valueTaxCod?.fullName}
                  onClick={handleOpenDrawerMulti}
                  seller={valueTaxCod}
                />
              )}
            </Col>
            {typePersonal === SELLER_TYPE.PERSONAL && (
              <Col span={24}>
                <FormInput
                  formItemOptions={{
                    label: (
                      <div>
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.idNumber")}
                        </Typography.Text>
                        <br />
                        <Typography.Text style={{ textWrap: "pretty" }} strong>
                          {t("potentialCustomer.form.idNumber1")}
                        </Typography.Text>
                      </div>
                    ),
                    labelAlign: "left",
                    name: "idNumber",
                    labelCol: { span: 8 },
                    className: "labelIdNumber",
                    ...(formValues?.idNumber && { hasFeedback: true }),
                    ...(isDuplicateIdNumber && { style: { marginBottom: 0 } }),
                    validateStatus: loadingIdNumber ? "validating" : isDuplicateIdNumber ? "error" : undefined,
                    rules: [
                      validator({
                        type: "number",
                      }),
                    ],
                  }}
                  inputOptions={{
                    ...notBordered,
                    placeholder: t("potentialCustomer.placeHolder.idNumber"),
                    maxLength: 12,
                    onChange: handleSearchIdNumber,
                  }}
                />
                {isDuplicateIdNumber && (
                  <DuplicateValuesWarning
                    span={0}
                    styleRow={{ justifyContent: "flex-end" }}
                    message={t("potentialCustomer.messageDuplicate.idNumber")}
                    fullName={valueIdNumber?.fullName}
                    onClick={handleOpenDrawerMulti}
                    seller={valueIdNumber}
                  />
                )}
              </Col>
            )}
          </Row>
        </Skeleton>
      }
    />
  );
}

export default GeneraInfoCard;
